import React from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';

import { notifications as notificationsPropType } from '../notificationsPropTypes';
import styles from './notificationsMenu.styles';

import NotificationsMenuList from './NotificationsMenuList';

const NotificationsMenu = ({
  notifications,
  limit,
  classes,
  closeMenu,
  isFetchingNotifications,
  fetchNotificationsError,
}) => (
  <div className={classes.notificationsMenu}>
    <div className={classes.notificationsMenuHeader}>
      <h5 className={classes.notificationsMenuTitle}>Notifications</h5>
      <Button
        data-test="notificationsMenuPreferencesButton"
        className={classes.notificationsMenuPreferences}
        component={Link}
        onClick={closeMenu}
        to="/profile/notifications"
      >
        Preferences
      </Button>
    </div>
    <NotificationsMenuList
      notifications={notifications.slice(0, limit)}
      closeMenu={closeMenu}
      fetchingNotifications={isFetchingNotifications}
      notificationsError={fetchNotificationsError}
    />
    <div className={classes.notificationsMenuFooter}>
      <Button
        data-test="notificationsMenuViewAllButton"
        variant="text"
        fullWidth
        className={classes.button}
        component={Link}
        onClick={closeMenu}
        to="/notifications"
      >
        View all notifications{' '}
        <NavigateNextIcon className={classes.viewAllNavigateNextIcon} />
      </Button>
    </div>
  </div>
);

NotificationsMenu.propTypes = {
  notifications: notificationsPropType,
  fetchNotificationsError: PropTypes.instanceOf(Error),
  isFetchingNotifications: PropTypes.bool,
  limit: PropTypes.number,
  classes: PropTypes.objectOf(PropTypes.string),
  closeMenu: PropTypes.func,
};

NotificationsMenu.defaultProps = {
  notifications: [],
  fetchNotificationsError: null,
  isFetchingNotifications: false,
  limit: 5,
  classes: {},
  closeMenu: () => {},
};

export default withStyles(styles)(NotificationsMenu);
