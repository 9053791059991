import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';

import styles from './styles';

const PaymentProcessingOverlay = (props) => {
  const { classes } = props;

  return (
    <div className={classes.paymentProcessingOverlay}>
      <div className={classes.loadingIconContainer}>
        <div className={classes.loadingIconWrapper}>
          <MaterialLoadingIcon />
          {props.processingText}
        </div>
      </div>
    </div>
  );
};

PaymentProcessingOverlay.propTypes = {
  processingText: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(PaymentProcessingOverlay);
