import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

import styles from './styles';

const NutrienPaymentAmountRadioButton = (props) => {
  const {
    classes,
    name,
    value,
    disabled,
    onChange,
    checked,
    ...otherProps
  } = props;
  return (
    <Radio
      className={classNames(classes.root)}
      disableRipple
      color="default"
      checkedIcon={
        <span className={classNames(classes.icon, classes.checkIcon)} />
      }
      icon={<span className={classes.icon} />}
      inputProps={{
        'data-test': otherProps['data-test'],
        checked,
      }}
      name={name}
      value={value}
      checked={Boolean(checked)}
      disabled={Boolean(disabled)}
      onChange={onChange}
      required
    />
  );
};

NutrienPaymentAmountRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  classes: PropTypes.shape({
    root: PropTypes.string,
    checkIcon: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
};

NutrienPaymentAmountRadioButton.defaultProps = {
  disabled: '',
};

export default withStyles(styles)(NutrienPaymentAmountRadioButton);
