import apiAdapter from 'shared/lib/cxhApiService';
// ******** FOR GQL
// import { NDclient } from 'shared/lib/NutrienDataGraphQLProvider';
// import { GET_BANK_TOKENS_BY_ORG_IDS } from 'shared/gql/queries';
import {
  US_PAYMENT_TERMS_AND_CONDITIONS_DOC,
  CAN_PAYMENT_TERMS_AND_CONDITIONS_DOC,
  COUNTRY_CODES,
} from './PaymentConstants';

/**
 * Registers a bank account with Vantiv and returns the tokenized account number
 * @param payload - The register call body
 */
export const registerBankAccount = (payload) => {
  const config = {
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return apiAdapter
    .post(`v1/payments/token/register`, payload, config)
    .then((response) => response.data);
};

/**
 *
 * @param payload
 */
export const saveBankAccount = (payload) => {
  const config = {
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return apiAdapter
    .post(`v1/payments/token`, payload, config)
    .then((response) => response.data);
};

/**
 * Makes a bank account payment
 */
export const bankAccountPayment = (requestPayload) =>
  apiAdapter.post('v1/payments', requestPayload);

/**
 * Submits a scheduled payment
 * */
export const scheduledBankAccountPayment = (scheduledPaymentRequest) =>
  apiAdapter.post('v1/scheduledPayments', scheduledPaymentRequest);

export const getBankName = (routingNumber) =>
  apiAdapter.get(`v1/payments/bank/${routingNumber}`);

// ************* THE GQL VERSION
// export const getSavedBankAccounts = async (organizationIds, countryCode) => {
//   /* the GQL query needs the orgIds as a comma separated string, so we join them with a comma */
//   const orgIDsToString = organizationIds.join(',');

//   /* use NDclient because it feeds through a global singleton apollo client so that we dont lose cache */
//   const responseGQL = await NDclient.query({
//     query: GET_BANK_TOKENS_BY_ORG_IDS,
//     variables: {
//       orgIds: orgIDsToString,
//       countryCode: countryCode || 'USA',
//     },
//   });

//   const responseTransformed = { data: responseGQL.data.getBankTokensByOrgIds };

//   return responseTransformed;
// };

export const getSavedBankAccounts = (organizationIds, countryCode) =>
  apiAdapter.get(
    `v1/payments/token?organizationIds=${organizationIds}${
      countryCode !== undefined ? `&countryCode=${countryCode}` : ''
    }`
  );

export const getTermsAndConditionsContent = (countryCode) => {
  const payload = {
    termsDocument:
      countryCode === COUNTRY_CODES.USA
        ? US_PAYMENT_TERMS_AND_CONDITIONS_DOC
        : CAN_PAYMENT_TERMS_AND_CONDITIONS_DOC,
  };

  return apiAdapter.post('v1/terms/content', payload);
};

export const updateBankAccountAlias = (alias, tokenAccountId) => {
  const payload = { alias };
  return apiAdapter.put(`v1/payments/token/${tokenAccountId}`, payload);
};

export const deleteSavedBankAccount = (tokenId) =>
  apiAdapter.delete(`v1/payments/token/${tokenId}/`);

export const validatePayment = (body) =>
  apiAdapter.post('v1/payments/valid', body);

export const validatePaymentAsPrepay = (body) =>
  apiAdapter.post('v1/payments/prepay/isValid', body);

export const payWithPrepay = (body) =>
  apiAdapter.post('v1/payments/prepay', body);
