import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  allPermits,
  expiredPermits,
  activeChemicalPermits,
  expiringSoonPermits,
  permitsFetchedAtTime as permitsFetchedTime,
  fetchPermitsError,
  isFetchingPermits,
} from './selectors';
import { fetchPermits as fetchPermitsAction } from './actions';
import { permitArray } from './permitsPropTypes';

export const mapStateToProps = (state) => ({
  permits: allPermits(state),
  expiredPermits: expiredPermits(state),
  permitsFetchedAtTime: permitsFetchedTime(state),
  activeChemicalPermits: activeChemicalPermits(state),
  expiringSoonPermits: expiringSoonPermits(state),
  fetchPermitsError: fetchPermitsError(state),
  isFetchingPermits: isFetchingPermits(state),
});

export const mapDispatchToProps = {
  fetchPermits: fetchPermitsAction,
};

/**
 * This is a HoC that will request permits for you if they don't already exist in the store. The goal here is to
 * make it simple for any new components to access permits without needing to make their own requests.
 * @example `const ComponentWithPermits = withPermits(MyComponent)`
 * @param  {React.Component} WrappedComponent The component you want to supply with permit data.
 * @return {React.Component} A React component that renders your component, supplying it with permits data.
 */
export const withPermits = (WrappedComponent) =>
  class WithPermits extends Component {
    componentDidMount() {
      /* eslint-disable-next-line react/prop-types */
      const { fetchPermits, permitsFetchedAtTime } = this.props;

      if (!permitsFetchedAtTime) fetchPermits();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

withPermits.propTypes = {
  permits: permitArray,
  expiredPermits: permitArray,
  expiringSoonPermits: permitArray,
  activeChemicalPermits: permitArray,
  fetchPermits: PropTypes.func,
  permitsFetchedAtTime: PropTypes.number,
  fetchPermitsError: PropTypes.instanceOf(Error),
  isFetchingPermits: PropTypes.bool,
};

withPermits.defaultProps = {
  permits: [],
  expiredPermits: [],
  activeChemicalPermits: [],
  expiringSoonPermits: [],
  fetchPermits: () => {},
  permitsFetchedAtTime: 0,
  fetchPermitsError: undefined,
  isFetchingPermits: false,
};

/**
 * This connects your withPermits component to the redux stor so it can
 * properly dispatch actions.
 * @param  {React.Component} WrappedComponent The component you want to give permits to.
 * @return {React.Component}                  The wrapped component with permits.
 */
const connectedWithPermits = (WrappedComponent) =>
  connect(mapStateToProps, mapDispatchToProps)(withPermits(WrappedComponent));

export default connectedWithPermits;
