import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { match as matchProp } from 'react-router-prop-types';

import NotificationsPage from './NotificationsPage';
import Page404 from '../Page404';

const Notifications = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={NotificationsPage} />
    <Redirect from={`${match.path}/preferences`} to="/profile/notifications" />
    <Route component={Page404} />
  </Switch>
);

Notifications.propTypes = {
  match: matchProp.isRequired,
};

export default Notifications;
