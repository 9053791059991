// dependencies
import React from 'react';
import { Switch } from 'react-router';
import HeaderWithState from 'shared/ui/Header/HeaderWithState';
import AuthenticatedHeader from './Headers/AuthenticatedHeader';
import LoginHeader from './Headers/LoginHeader';
import TermsHeader from './Headers/TermsHeader';
import CartHeader from './Headers/CartHeader';

const Header = () => (
  <Switch>
    <HeaderWithState path="/employee" component={LoginHeader} />
    <HeaderWithState path="/login" component={LoginHeader} />
    <HeaderWithState path="/sign-in" component={LoginHeader} />
    <HeaderWithState path="/accept-terms" component={TermsHeader} />
    <HeaderWithState path="/cart/services" component={CartHeader} />
    <HeaderWithState path="/cart/logistics" component={CartHeader} />
    <HeaderWithState path="/cart/review" component={CartHeader} />
    <HeaderWithState component={AuthenticatedHeader} />
  </Switch>
);

export default Header;
