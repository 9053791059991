import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core';
import OnClickOutside from 'shared/ui/OnClickOutside/OnClickOutside';
import PaymentValidationMessageComponent from 'components/Payments/OtherComponents/ValidationMessage/PaymentValidationMessageComponent';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import { buildClassName } from 'components/Payments/Utils/PaymentUtil';
import { MODAL_TYPES, ModalType } from 'components/Payments/PaymentConstants';
import { MAX_LENGTH } from 'components/Payments/PageComponents/MakeAPayment/MakeAPaymentConstants';

import HelpModalComponent from '../../HelpModal/HelpModalComponent';

import commonStyles from '../../../../../../commonPaymentStyles';

const CANBankAccountInfoComponent = (props) => {
  const {
    formName,
    openModal,
    closeModal,
    keyDownHandler,
    modalState,
    errors,
    onChange,
    onValidate,
    form,
    classes,
  } = props;

  return (
    <div className="CANBankAccountInfoComponent">
      <label className={classes.addPaymentFormLabel} htmlFor="transitNumber">
        Transit Number
        <div
          className={classes.accountInfoHelpLink}
          name="transitNumberModal"
          onClick={openModal}
          onKeyDown={(e) => {
            keyDownHandler(MODAL_TYPES.TRANSIT_NUMBER_MODAL, e);
          }}
          role="link"
          tabIndex="0"
        >
          What's this?
          <OnClickOutside
            clickOutsideHandler={() => {
              closeModal(MODAL_TYPES.TRANSIT_NUMBER_MODAL);
            }}
          >
            <HelpModalComponent
              modalOpen={modalState.transitNumberModal}
              closeModal={() => {
                closeModal(MODAL_TYPES.TRANSIT_NUMBER_MODAL);
              }}
              modalType={ModalType.transitNumberModal}
              modalText="Transit Number"
              modalDescription=""
            />
          </OnClickOutside>
        </div>
      </label>
      <input
        name="transitNumber"
        form={formName}
        type="text"
        className={buildClassName(
          'transitNumber',
          classes.addPaymentFormInput,
          errors,
          classes.error
        )}
        onChange={onChange}
        onBlur={onValidate}
        autoComplete="new-password"
        value={form.transitNumber}
        maxLength={MAX_LENGTH.TRANSIT_NUMBER}
      />
      <PaymentValidationMessageComponent
        errors={errors}
        field="transitNumber"
      />

      <label
        className={classes.addPaymentFormLabel}
        htmlFor="institutionNumber"
      >
        Institution Number
        <div
          className={classes.accountInfoHelpLink}
          name="institutionNumberModal"
          onClick={openModal}
          onKeyDown={(e) => {
            keyDownHandler('institutionNumberModal', e);
          }}
          role="link"
          tabIndex="0"
        >
          What's this?
          <OnClickOutside
            clickOutsideHandler={() => {
              closeModal(MODAL_TYPES.INSTITUTION_NUMBER_MODAL);
            }}
          >
            <HelpModalComponent
              modalOpen={modalState.institutionNumberModal}
              closeModal={() => {
                closeModal(MODAL_TYPES.INSTITUTION_NUMBER_MODAL);
              }}
              modalType={ModalType.institutionNumberModal}
              modalText="Institution Number"
              modalDescription=""
            />
          </OnClickOutside>
        </div>
      </label>
      <input
        name="institutionNumber"
        form={formName}
        type="text"
        className={buildClassName(
          'institutionNumber',
          classes.addPaymentFormInput,
          errors,
          classes.error
        )}
        onChange={onChange}
        maxLength={MAX_LENGTH.INSTUTITION_NUMBER}
        onBlur={onValidate}
        autoComplete="new-password"
        value={form.institutionNumber}
      />
      <PaymentValidationMessageComponent
        errors={errors}
        field="institutionNumber"
      />

      <label className={classes.addPaymentFormLabel} htmlFor="accountNumber">
        Bank Account Number
        <div
          className={classes.accountInfoHelpLink}
          name="canadaAccountModal"
          onClick={openModal}
          onKeyDown={(e) => {
            keyDownHandler(MODAL_TYPES.CANADA_ACCOUNT_MODAL, e);
          }}
          role="link"
          tabIndex="0"
        >
          What's this?
          <OnClickOutside
            clickOutsideHandler={() => {
              closeModal(MODAL_TYPES.CANADA_ACCOUNT_MODAL);
            }}
          >
            <HelpModalComponent
              modalOpen={modalState.canadaAccountModal}
              closeModal={() => {
                closeModal(MODAL_TYPES.CANADA_ACCOUNT_MODAL);
              }}
              modalType={ModalType.accountModal}
              modalText="Bank Account Number"
              modalDescription=""
            />
          </OnClickOutside>
        </div>
      </label>
      <input
        name="accountNumber"
        form={formName}
        type="text"
        className={buildClassName(
          'accountNumber',
          classes.addPaymentFormInput,
          errors,
          classes.error
        )}
        onChange={onChange}
        maxLength={MAX_LENGTH.ACCOUNT_NUMBER_CAN}
        onBlur={onValidate}
        autoComplete="new-password"
        value={form.accountNumber}
      />
      <PaymentValidationMessageComponent
        errors={errors}
        field="accountNumber"
      />

      <label
        className={classes.addPaymentFormLabel}
        htmlFor="confirmAccountNumber"
      >
        Confirm Bank Account Number
      </label>
      <input
        name="confirmAccountNumber"
        form={formName}
        type="text"
        className={buildClassName(
          'confirmAccountNumber',
          classes.addPaymentFormInput,
          errors,
          classes.error
        )}
        onChange={onChange}
        maxLength={MAX_LENGTH.ACCOUNT_NUMBER_CAN}
        onBlur={onValidate}
        onPaste={(e) => e.preventDefault()}
        value={form.confirmAccountNumber}
      />
      <PaymentValidationMessageComponent
        errors={errors}
        field="confirmAccountNumber"
      />
    </div>
  );
};

CANBankAccountInfoComponent.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    institutionNumber: PropTypes.string,
    transitNumber: PropTypes.string,
    accountNumber: PropTypes.string.isRequired,
    confirmAccountNumber: PropTypes.string.isRequired,
  }).isRequired,
  errors: PaymentPropTypes.errors.isRequired, // eslint-disable-line react/no-typos
  onChange: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalState: PropTypes.shape({}).isRequired,
  onValidate: PropTypes.func.isRequired,
  keyDownHandler: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};
export default withStyles(commonStyles)(CANBankAccountInfoComponent);
