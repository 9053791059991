import downloadFile from 'shared/utils/DownloadFile';
import { getAccountActivityData } from 'services/httpServices/accountService';
import accountActivityExportStart from './accountActivityExportStart';
import accountActivityExportSuccess from './accountActivityExportSuccess';
import accountActivityExportError from './accountActivityExportError';

/**
 * Thunk. Sends an API call to request account data in the desired file format.
 * Will dispatch actions associated with the request's status.
 * @param  {String} fileType The file type the user wants their data in (csv or ofx)
 * @param  {String} accountId The id of the account for which the account activity is being retrieved
 * @return {Promise}       The request promise (used for testing)
 */
export default (fileType, accountId) => (dispatch) => {
  dispatch(accountActivityExportStart(fileType));

  return getAccountActivityData(fileType, accountId)
    .then((data) => {
      downloadFile(data, `account-${accountId}.${fileType}`);
      dispatch(accountActivityExportSuccess(fileType));
    })
    .catch((error) => {
      dispatch(accountActivityExportError(error));
      return Promise.reject(error); // Allows other listeners to catch if they want.
    });
};
