import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { isEmployee } from 'shared/utils/tokenUtils';
import EmployeeSessionChecker from './EmployeeSessionChecker';
import { logoutEmployee } from '../EmployeeLogin/actions';

export class EmployeeSessionCheckerContainer extends Component {
  static propTypes = {
    /** Expiration in ms */
    sessionExpiration: PropTypes.number.isRequired,
    showWarningAtMinutes: PropTypes.number,
    logoutEmployee: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showWarningAtMinutes: 2,
  };

  state = {
    sessionIsExpired: false,
    sessionIsExpiring: false,
  };

  componentDidMount() {
    this.intervalId = setInterval(this.checkExpiration, 30000);
    this.checkExpiration();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sessionExpiration !== this.props.sessionExpiration) {
      this.checkExpiration();
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  checkExpiration = () => {
    const { showWarningAtMinutes, sessionExpiration } = this.props;
    const showWarningAtMs = showWarningAtMinutes * 60000;
    if (isEmployee()) {
      if (sessionExpiration < Date.now()) {
        this.setState({ sessionIsExpired: true });
      } else if (sessionExpiration - showWarningAtMs <= Date.now()) {
        this.setState({ sessionIsExpiring: true });
      } else {
        this.setState({ sessionIsExpired: false, sessionIsExpiring: false });
      }
    }
  };

  handleLogout = () => {
    const { logoutEmployee } = this.props; // eslint-disable-line no-shadow
    logoutEmployee();
  };

  render() {
    const { sessionIsExpired, sessionIsExpiring } = this.state;
    return (
      <EmployeeSessionChecker
        isEmployee={isEmployee()}
        sessionIsExpired={sessionIsExpired}
        sessionIsExpiring={sessionIsExpiring}
        onLogoutClick={this.handleLogout}
      />
    );
  }
}

const mapDispatchToProps = { logoutEmployee };

export const mapStateToProps = (state) => ({
  sessionExpiration:
    get(state, 'authentication.session.expiration') * 1000 || Date.now(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeSessionCheckerContainer);
