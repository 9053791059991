/**
 * filters list of customers using given filter String
 * @param {Array} customers an array of customers to filter
 * @param {string} filterPattern string to search for
 */
export default (customers, filterPattern) => {
  if (!filterPattern) return customers;
  return customers.filter((customer) =>
    [
      customer.firstName,
      customer.lastName,
      customer.associatedOrgs.map((org) => org.name),
      customer.associatedOrgs
        .reduce((accts, org) => [...accts, ...org.accounts], [])
        .map((acct) => acct.number),
    ]
      .join(' ')
      .toLowerCase()
      .includes(filterPattern.toLowerCase())
  );
};
