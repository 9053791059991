const validatePassword = (password) => {
  if (password.length < 10) {
    return false;
  }

  let count = 0;
  const special = /[!@#$%^&*()_\-[\]{};':"\\|,.<>/?]/;
  const upper = /[A-Z]/g;
  const lower = /[a-z]/g;

  [special, upper, lower].forEach((regex) => {
    if (regex.test(password)) count += 1;
  });

  return count > 2;
};

export default validatePassword;
