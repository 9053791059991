import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@material-ui/core';
import { privacyPreference } from './billingTabPropTypes';

/**
 *
 * preferences is the array of all preferences, which must be passed to the backend as a collection
 * preference is the specific preference for this particular toggle
 * accountIds are also required to hit the correct endpoint
 */
const BillingPreferenceItem = ({ onChange, preference, labelText }) => (
  <ListItem id={labelText}>
    <ListItemText
      primary={labelText}
      secondary="The setting will be applied to all the linked Nutrien accounts."
    />
    <ListItemSecondaryAction>
      <FormControlLabel
        className={preference.enabled ? 'checked' : ''}
        control={
          <Switch
            color="primary"
            className="preferenceToggle"
            checked={preference.enabled}
            onChange={onChange}
          />
        }
        label=""
      />
    </ListItemSecondaryAction>
  </ListItem>
);

BillingPreferenceItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  preference: privacyPreference.isRequired,
  labelText: PropTypes.string.isRequired,
};

export default BillingPreferenceItem;
