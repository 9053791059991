import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogButton,
  TextField,
  HelperText,
  Input,
  Grid,
  Row,
  Cell,
  Slider,
} from 'nutrien-common-components-react/dist/mdc';
import hideUndefined from 'shared/utils/hideUndefined/hideUndefined';
import currency from 'shared/utils/numberFormatters/currency';
import { formatAsNumber } from 'shared/utils/numberFormatters/stripCurrencySymbols';
import {
  ERROR_MESSAGE,
  RANGE_TYPES,
} from '../shared/constants/amountRangeConstants';
import {
  isInputValid,
  validateInputRange,
} from '../shared/utils/validationRules';

import './styles.scss';

export class InvoiceGrossAmountModal extends Component {
  constructor(props) {
    super(props);

    const domain = [props.minGrossAmount, props.maxGrossAmount];
    const amountRange =
      props.initialSelection.filter((x) => x !== '').length > 0
        ? [...props.initialSelection]
        : domain.map(String);

    this.state = {
      amountRange,
      areInputsValid: true,
      isValidRange: [true, true],
      domain: [...domain],
    };
  }

  handleNegativeInput = (targetValue, rangeType) => {
    const value = hideUndefined(formatAsNumber(targetValue));
    return (value === '-' && this.state.domain[0] === 0) ||
      (value === '-' &&
        this.state.amountRange[rangeType] &&
        this.state.amountRange[rangeType].length >= 1)
      ? ''
      : value;
  };

  updateStateOnInputChange = (updatedRange) => {
    const areInputsValid = validateInputRange(updatedRange);
    if (areInputsValid)
      this.setState({
        amountRange: updatedRange,
        areInputsValid,
      });
    else this.setState({ areInputsValid });
  };

  handleInputChange = (e, rangeType) => {
    const updatedRange = this.state.amountRange;
    updatedRange[rangeType] = this.handleNegativeInput(
      e.currentTarget.value,
      rangeType
    );

    this.updateStateOnInputChange(updatedRange);
  };

  handleOnBlur = (rangeType) => {
    const value = this.state.amountRange[rangeType];
    const isValid = isInputValid(value);
    const updatedIsValidRange = this.state.isValidRange;
    updatedIsValidRange[rangeType] = isValid;

    this.setState({ isValidRange: updatedIsValidRange });
  };

  handleClear = () => {
    this.setState({
      amountRange: ['', ''],
      areInputsValid: true,
      isValidRange: [true, true],
    });
    this.props.onClear();
  };

  sliderUpdate = (update) => {
    const updatedRange = this.state.amountRange;
    updatedRange[RANGE_TYPES.MAX] = this.handleNegativeInput(
      JSON.stringify(update[RANGE_TYPES.MAX]),
      RANGE_TYPES.MAX
    );
    updatedRange[RANGE_TYPES.MIN] = this.handleNegativeInput(
      JSON.stringify(update[RANGE_TYPES.MIN]),
      RANGE_TYPES.MIN
    );
    this.handleOnBlur(RANGE_TYPES.MAX);
    this.handleOnBlur(RANGE_TYPES.MIN);

    this.updateStateOnInputChange(updatedRange);
  };

  handleSubmit = () => {
    this.props.onAccept(this.state.amountRange);
  };

  renderTextField = (rangeType, textLabel, testData) => (
    <TextField
      label={textLabel}
      outlined
      helperText={<HelperText isValidationMessage>{ERROR_MESSAGE}</HelperText>}
    >
      <Input
        value={
          this.state.amountRange[rangeType] !== ''
            ? currency(this.state.amountRange[rangeType], 'negative', false)
            : ''
        }
        onChange={(e) => this.handleInputChange(e, rangeType)}
        onBlur={() => this.handleOnBlur(rangeType)}
        isValid={this.state.isValidRange[rangeType]}
        data-test={testData}
      />
    </TextField>
  );

  render() {
    const { open, onClosed } = this.props;
    return (
      <Dialog
        open={open}
        onClosed={onClosed}
        className="invoice-gross-amount-modal"
        data-test="invoice-gross-amount-modal"
      >
        <DialogTitle>Select Gross Amount Range</DialogTitle>
        <DialogContent>
          <Grid>
            <Row>
              <Cell desktopColumns={6} tabletColumns={8} phoneColumns={4}>
                {this.renderTextField(
                  RANGE_TYPES.MIN,
                  'Minimum $',
                  'grossAmountMin'
                )}
              </Cell>
              <Cell desktopColumns={6} tabletColumns={8} phoneColumns={4}>
                {this.renderTextField(
                  RANGE_TYPES.MAX,
                  'Maximum $',
                  'grossAmountMax'
                )}
              </Cell>
            </Row>
            <Row>
              <Cell columns={12} className="nutrien-slider-bar">
                <Slider
                  domain={this.state.domain}
                  values={this.state.amountRange.map(
                    (value) => parseFloat(value) || 0
                  )}
                  showDomain
                  formatLabels={(value) => currency(value, 'negative', false)}
                  onUpdate={this.sliderUpdate}
                  allowKeyboardControls
                />
              </Cell>
            </Row>
          </Grid>
          <DialogActions>
            <DialogButton action="close">Cancel</DialogButton>
            <DialogButton
              action="accept"
              data-test="grossAmountClear"
              onClick={this.handleClear}
            >
              Clear
            </DialogButton>
            <DialogButton
              action="accept"
              onClick={this.handleSubmit}
              disabled={!this.state.areInputsValid}
              data-test="grossAmountAccept"
            >
              Ok
            </DialogButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

InvoiceGrossAmountModal.propTypes = {
  onAccept: PropTypes.func,
  initialSelection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  open: PropTypes.bool,
  onClosed: PropTypes.func,
  onClear: PropTypes.func,
  maxGrossAmount: PropTypes.number,
  minGrossAmount: PropTypes.number,
};

InvoiceGrossAmountModal.defaultProps = {
  onAccept: () => {},
  initialSelection: ['', ''],
  open: false,
  onClosed: () => {},
  onClear: () => {},
  maxGrossAmount: 100000,
  minGrossAmount: 0,
};

export default InvoiceGrossAmountModal;
