import { loginUser } from 'actions/userActions';
import { push } from 'connected-react-router';
import { preregister, register, registerExisting } from './registrationService';

import {
  RESET_REGISTRATION,
  LOADING_REGISTRATION,
  REGISTER_EXISTING_SUCCESS,
  REGISTER_EXISTING_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  PREREGISTER_START,
  PREREGISTER_REDIRECT,
  PREREGISTER_SUCCESS,
  PREREGISTER_FAILURE,
} from './registrationConstants';

export const resetRegistration = () => ({
  type: RESET_REGISTRATION,
});

export const loadingRegistration = () => ({
  type: LOADING_REGISTRATION,
});

export const preregisterStart = () => ({
  type: PREREGISTER_START,
});

export const preregisterSuccess = () => ({
  type: PREREGISTER_SUCCESS,
});

export const preregisterFailure = (error) => ({
  type: PREREGISTER_FAILURE,
  error,
});

export const preregisterRedirect = (error) => ({
  type: PREREGISTER_REDIRECT,
  error,
});

export const registerExistingSuccess = () => ({
  type: REGISTER_EXISTING_SUCCESS,
});

export const registerExistingFailure = (error) => ({
  type: REGISTER_EXISTING_FAILURE,
  error,
});

export const registerSuccess = () => ({
  type: REGISTER_SUCCESS,
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  error,
});

export const preregisterUser = (data) => (dispatch) => {
  dispatch(preregisterStart());
  return preregister(data)
    .then(() => dispatch(preregisterSuccess()))
    .catch((error) => {
      if (error && error.response && error.response.status === 409) {
        return Promise.resolve()
          .then(() => dispatch(push('/login')))
          .then(() => dispatch(preregisterRedirect(error)));
      }
      return dispatch(preregisterFailure(error));
    });
};

export const registerUser = (data, termsVersion) => (dispatch) => {
  dispatch(loadingRegistration());
  return register(data)
    .then(() => {
      let identityJSON = window.atob(data.identity);
      identityJSON = JSON.parse(identityJSON);
      const loginInfo = {
        username: identityJSON.email,
        password: data.password,
      };
      dispatch(loginUser(loginInfo, '/accounts/link', termsVersion));
      return dispatch(registerSuccess());
    })
    .catch((error) => dispatch(registerFailure(error)));
};

export const registerExistingUser = (data, termsVersion) => (dispatch) => {
  dispatch(loadingRegistration());
  return registerExisting(data)
    .then(() => {
      const username = window.atob(data.identity);
      const loginInfo = {
        username,
        password: data.password,
      };
      dispatch(loginUser(loginInfo, '/', termsVersion));
      return dispatch(registerExistingSuccess());
    })
    .catch((error) => dispatch(registerExistingFailure(error)));
};
