import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import CartStepper from '../CartStepper';
import MobileCartStepper from '../MobileCartStepper';
import CustomApplied from '../../containers/CustomAppliedContainer/CustomAppliedContainer';
import CustomCropCare from '../../containers/CustomCropCareContainer/CustomCropCareContainer';
import checkWhitespaceOnlyString from '../../utils/checkWhitespaceOnlyString';
import CartButtons from '../CartButtons/CartButtons';

import './ServicesPage.scss';

class ServicesPage extends Component {
  constructor(props) {
    super(props);
    const applicationMemo = get(props, 'cart.preferences.applicationMemo', '');

    this.state = {
      applicationMemo,
    };
  }

  componentDidMount = () => {
    const {
      getAddress,
      history,
      location,
      selectedAccount: { number },
      cart,
    } = this.props;

    if (!location.originatedFromCart) {
      history.push('/cart');
    }

    if (number) {
      if (!get(cart, 'addresses.all.updatingAddress', false)) {
        getAddress(number);
      }
    }
  };

  componentWillUnmount = () => {
    const { viewServices } = this.props;
    viewServices();
  };

  updateCustomApplied = (checked) => {
    const { setCustomApplied } = this.props;
    setCustomApplied(checked);
  };

  updateCustomAppliedMemo = (memo) => {
    const applicationMemo = checkWhitespaceOnlyString(memo);
    const { setApplicationMemo } = this.props;
    this.setState({
      applicationMemo,
    });
    setApplicationMemo(applicationMemo);
  };

  handleNext = () => {
    const { applicationMemo } = this.state;
    const { setApplicationMemo, history } = this.props;
    setApplicationMemo(applicationMemo);
    history.push({
      pathname: '/cart/logistics',
      originatedFromCart: true,
    });
  };

  render() {
    const {
      cart: { loading },
      flags: { ecommCustomCropCare },
      selectedAccount: { number },
      history,
    } = this.props;
    if (loading) {
      return <MaterialLoadingIcon />;
    }

    return (
      <div
        className="services-page__outer-cart-page-wrapper"
        data-test="optional-services"
      >
        <CartStepper currentStep="services" accountNumber={number} />
        <h2 className="services-page__cart-headline">Optional Services</h2>
        <CustomApplied
          updateCustomApplied={(checked) => this.updateCustomApplied(checked)}
          updateCustomAppliedMemo={(memo) => this.updateCustomAppliedMemo(memo)}
        />
        {ecommCustomCropCare && <CustomCropCare />}
        <div className="services-page__cart-footer">
          <MobileCartStepper
            currentStep="services"
            history={history}
            accountNumber={number}
          />
          <CartButtons
            accountNumber={number}
            currentStep="services"
            history={history}
          />
        </div>
      </div>
    );
  }
}

ServicesPage.propTypes = {
  location: PropTypes.shape({
    originatedFromCart: PropTypes.bool.isRequired,
  }).isRequired,
  getAddress: PropTypes.func.isRequired,
  setApplicationMemo: PropTypes.func.isRequired,
  setCustomApplied: PropTypes.func.isRequired,
  viewServices: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  cart: PropTypes.shape({
    loading: PropTypes.bool,
    branchAddress: PropTypes.shape({
      address: PropTypes.shape({}),
      updatingBranchAddress: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  selectedAccount: PropTypes.shape({
    number: PropTypes.string,
  }).isRequired,
  flags: PropTypes.shape({ ecommCustomCropCare: PropTypes.bool }).isRequired,
};

export default ServicesPage;
