import fetchLicensesFailure from './fetchLicensesFailure';
import fetchLicensesSuccess from './fetchLicensesSuccess';
import fetchingLicenses from './fetchingLicenses';
import * as licensesService from '../licensesService';

export default () => (dispatch) => {
  dispatch(fetchingLicenses());
  return licensesService
    .get()
    .then((licenses) => dispatch(fetchLicensesSuccess(licenses)))
    .catch((err) => dispatch(fetchLicensesFailure(err)));
};
