import resolveApiEnv, {
  DEV,
  SIT,
  PRE,
  PROD,
} from 'shared/config/resolveApiEnv';

// reflect live environments for Credit Point
const urls = {
  [DEV]: 'https://nutrientst.mysecurecredit.com/crdapp/4.4/en-us/auth0',
  [SIT]: 'https://nutrientst.mysecurecredit.com/crdapp/4.4/en-us/auth0',
  [PRE]: 'https://nutriensit.mysecurecredit.com/crdapp/4.5/en-us/auth0',
  [PROD]: 'https://nutrien.mysecurecredit.com/crdapp/4.0/en-us/auth0',
};

const templateIds = {
  [DEV]: '603431a2b58001306be441c1',
  [SIT]: '603431a2b58001306be441c1',
  [PRE]: '5f8f3c30bcfd282c907d6c65',
  [PROD]: '5fede055b2a151a16276cfb6',
};

export function resolveOcaUrl() {
  const env = resolveApiEnv();
  return urls[env];
}

export function resolveOcaTemplateId() {
  const env = resolveApiEnv();
  return templateIds[env];
}
