import get from 'lodash/get';
import separatePreferenceNameAndCategory from '../utils/separatePreferenceNameAndCategory';

export default (state) => {
  const emailPreferences = get(state, 'user.userData.emails', []); // TODO: Make a user selector to acquire these.
  const phonePreferences = get(state, 'user.userData.phoneNumbers', []);

  // will hold the different preference "categories" (e.g. "Account"),
  // and each key will be an object that contains the preferences of that category
  const ret = {};

  if (emailPreferences.length < 1) {
    return ret;
  }

  /**
   * We need to split the account and order related preferences into separate
   * categories. The front end is already written to support multiple groups
   * but the keys coming from inner store both groups under the ACCOUNT prefix.
   *
   * e.g. (1) ACCOUNT-NEW_PAYMENT, (2) ACCOUNT-ORDER_CONFIRMED
   *
   * This change splits the order related preferences (2) into their own group
   * for only the front-end but keeps the formKey intact so that it doesn't
   * modify anything on inner's end and still properly saves preferences.
   *
   * e.g. ORDER-ORDER_CONFIRMED, formKey is still ACCOUNT-ORDER_CONFIRMED
   */
  const firstEmail = emailPreferences[0];
  const preferences = {};
  Object.entries(firstEmail.preferences).forEach(([key, value]) => {
    if (key.includes('ORDER')) {
      preferences[key.replace('ACCOUNT-', 'ORDER-')] = value;
    } else {
      preferences[key] = value;
    }
  });
  const preferenceKeys = Object.keys(preferences);

  // the keys will be something like GROUP-UNDERSCORE_NAME,
  // we want to group them by GROUP, and rename to UnderScore Name
  preferenceKeys
    .map((key) => ({ oldKey: key, ...separatePreferenceNameAndCategory(key) }))
    .forEach((preferenceNameAndCategory) => {
      let { oldKey } = preferenceNameAndCategory;
      const { category, preferenceName } = preferenceNameAndCategory;

      if (oldKey.includes('ORDER')) {
        oldKey = oldKey.replace('ORDER-', 'ACCOUNT-');
      }

      if (!ret[category]) {
        ret[category] = { [preferenceName]: { emails: {}, phoneNumbers: {} } };
      } else {
        ret[category][preferenceName] = { emails: {}, phoneNumbers: {} };
      }

      emailPreferences.forEach((email) => {
        const value = email.preferences[oldKey];

        ret[category][preferenceName].emails[email.value] = value;
      });

      phonePreferences.forEach((phoneNumber) => {
        const value = phoneNumber.preferences[oldKey];

        ret[category][preferenceName].phoneNumbers[phoneNumber.value] = value;
      });

      ret[category][preferenceName].formKey = oldKey;
    });

  return ret;
};
