import * as constants from '../actions';

const initialState = constants.UPDATE_NICKNAME_STATUSES.NONE;

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.UPDATE_SAVE_NICKNAME_STATUS: {
      return action.payload;
    }
    default:
      return state;
  }
};
