import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AccountingList.css';

// TODO: this needs unit tests!
const AccountingListItem = (props) => {
  const {
    link,
    mobileHeader,
    minSpace,
    maxSpace,
    right,
    noPadding,
    noWrap,
    onClick,
    data,
  } = props;

  if (link && link !== ``) {
    return (
      <td
        key={mobileHeader}
        className={classNames({
          'accounting-list-item__link__min-space': minSpace,
          'overview__type__max-space': maxSpace,
        })}
      >
        <Link to={link} onClick={onClick} className="row-link">
          <div
            className={classNames(
              'link-spacer accounting-list-item-header-mobile-only',
              {
                'accounting-list-item__link__right-align': right,
                'accounting-list-item__link__no-padding': noPadding,
              }
            )}
          >
            {mobileHeader}
          </div>
          <div
            className={classNames('link-spacer accounting-list__link', {
              'accounting-list-item__link__right-align': right,
              'accounting-list-item__noWrap': noWrap,
              'accounting-list-item__link__min-space': minSpace,
              'accounting-list-item__link__no-padding': noPadding,
            })}
          >
            {data}
          </div>
        </Link>
      </td>
    );
  }
  return (
    <td
      className={classNames({
        'accounting-list-item__wrapper__link__no-padding': noPadding,
        'accounting-list-item__link__min-space': minSpace,
        'overview__type__max-space': maxSpace,
        'accounting-list-item__noWrap': noWrap,
      })}
    >
      <div
        className={classNames(
          'link-spacer accounting-list-item-header-mobile-only',
          {
            'accounting-list-item__link__right-align': right,
            'accounting-list-item__link__no-padding': noPadding,
          }
        )}
      >
        {mobileHeader}
      </div>
      <div
        className={classNames('link-spacer', {
          'accounting-list-item__link__right-align': right,
          'accounting-list-item__link__no-padding': noPadding,
        })}
      >
        {data || (mobileHeader ? 'N/A' : '')}
      </div>
    </td>
  );
};

AccountingListItem.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  noPadding: PropTypes.bool,
  minSpace: PropTypes.bool,
  maxSpace: PropTypes.bool,
  right: PropTypes.bool,
  link: PropTypes.string,
  noWrap: PropTypes.bool,
  mobileHeader: PropTypes.string,
  onClick: PropTypes.func,
};

AccountingListItem.defaultProps = {
  data: '',
  noPadding: false,
  minSpace: false,
  maxSpace: false,
  right: false,
  noWrap: false,
  link: null,
  mobileHeader: '',
  onClick: () => {},
};

export default AccountingListItem;
