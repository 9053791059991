import { loginSuccess } from 'components/Authentication/actions/authenticationActions';
import { push } from 'connected-react-router';
import { userFetchFailure } from 'actions/userActions';
import jwtDecode from 'jwt-decode';
import coreAuth from '../../shared/lib/coreAuth';
import { fetchEmployeeInfo } from '../Employee/actions';
import { employeeLoggedIn } from '../Employee/EmployeeLogin/actions';
import { setToken } from '../../shared/utils/tokenUtils';

export const authorizeUser = ({ code, redirectRoute = '/' }) => async (
  dispatch
) => {
  try {
    const {
      data: { token },
    } = await coreAuth.post('v2/oauth2/token', { code });
    const { exp } = jwtDecode(token);

    const session = {
      authorizationToken: token,
      isEmployee: true,
      expiration: exp,
    };
    await setToken(session);
    await dispatch(loginSuccess(session));
    await dispatch(fetchEmployeeInfo());
    await dispatch(employeeLoggedIn());
    dispatch(push(redirectRoute));

    return true;
  } catch (err) {
    if (err.response && err.response.status === 406) {
      dispatch(push('user-support'));
    }
    return dispatch(userFetchFailure(err));
  }
};
