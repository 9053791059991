import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'nutrien-common-components-react/dist/mdc/Button';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import { MaterialLoadingIcon } from 'shared/ui';
import { errorStyling } from 'shared/styles';

import './AccountsTab.scss';

const TableHeadCell = withStyles({ root: { fontWeight: 600 } })(TableCell);

class AccountsTab extends Component {
  constructor(props) {
    super(props);

    const { accounts } = this.props;
    const nicknameEntries = {};
    if (accounts) {
      accounts.forEach((acc) => {
        nicknameEntries[acc.number] = acc.nickname || '';
      });
    }
    this.state = {
      nicknameEntries,
      errorArray: [],
    };
  }

  componentDidMount() {
    const { getAccounts, orgs } = this.props;
    if (this.props.accounts.length === 0) {
      getAccounts(orgs);
    }

    this.resetNicknameEntries();
  }

  componentDidUpdate(prevProps) {
    const { loading: prevStatus } = prevProps;
    const { accounts, loading } = this.props;
    if (!loading && prevStatus) {
      accounts.forEach((acc) => {
        this.setState({ [acc.number]: acc.nickname || '' });
      });
    }
  }

  setErrors = (errorArray) => {
    this.setState({
      errorArray,
    });
  };

  clearErrors = () => {
    if (this.state.errorArray.length > 0) this.setErrors([]);
  };

  checkForErrors = () => {
    const errors = [];
    Object.keys(this.state.nicknameEntries).forEach((account) => {
      if (
        this.state.nicknameEntries[account].length > 255 &&
        !this.state.errorArray.includes('accountNickname')
      ) {
        errors.push([
          'accountNickname',
          'Please ensure account nicknames are 255 characters or less',
        ]);
      }
    });
    return errors;
  };

  updateNicknames() {
    const errors = this.checkForErrors();
    if (errors.length === 0) {
      const { updateAccountNicknames } = this.props;
      updateAccountNicknames(this.state.nicknameEntries);
    } else {
      this.setErrors(errors);
    }
  }

  resetNicknameEntries() {
    const { accounts } = this.props;
    const nicknameEntries = {};
    accounts.forEach((acc) => {
      nicknameEntries[acc.number] = acc.nickname || '';
    });
    this.setState({
      nicknameEntries,
    });
  }

  handleChange = (e, accountNumber) => {
    const nicknameEntries = { ...this.state.nicknameEntries };
    nicknameEntries[accountNumber] = e.target.value;
    this.setState({
      nicknameEntries,
    });
  };

  renderAccounts() {
    return this.props.accounts.map((account) => (
      <TableRow key={account.number} className="accounts-tab__table-row">
        <TableCell className="accounts-tab__mobile-hidden">
          {account.name}
        </TableCell>
        <TableCell className="accounts-tab__desktop-hidden">
          <span className="accounts-tab__mobile-display accounts-tab__mobile-heading">
            Account Name
          </span>
          {account.name}
        </TableCell>

        <TableCell className="accounts-tab__mobile-hidden">
          {account.number}
        </TableCell>
        <TableCell className="accounts-tab__desktop-hidden">
          <span className="accounts-tab__mobile-display accounts-tab__mobile-heading">
            Account Number
          </span>
          {account.number}
        </TableCell>

        <TableCell className="accounts-tab__mobile-hidden">
          <input
            className="accounts-tab__nickname-input"
            value={this.state.nicknameEntries[account.number]}
            onChange={(e) => this.handleChange(e, account.number)}
          />
        </TableCell>
        <TableCell className="accounts-tab__desktop-hidden">
          <span className="accounts-tab__mobile-display accounts-tab__mobile-heading">
            Nickname
          </span>
          <input
            className="accounts-tab__nickname-input"
            value={this.state.nicknameEntries[account.number]}
            onChange={(e) => this.handleChange(e, account.number)}
          />
        </TableCell>
      </TableRow>
    ));
  }

  renderErrors = () =>
    this.state.errorArray ? (
      <div className="errorContainer">
        {this.state.errorArray.map((error) => (
          <div style={errorStyling} key={error[0]}>
            {error[1]}
          </div>
        ))}
      </div>
    ) : null;

  render() {
    const { loading } = this.props;
    return loading ? (
      <MaterialLoadingIcon />
    ) : (
      <div className="accounts-tab">
        <div className="accounts-tab__sectionHeader">Accounts</div>
        <Table className="accounts-tab__table">
          <TableHead className="accounts-tab__mobile-hidden">
            <TableRow className="accounts-tab__table-row">
              <TableHeadCell className="accounts-tab__table-header accounts-tab__mobile-hidden">
                Account Name
              </TableHeadCell>
              <TableHeadCell className="accounts-tab__table-header accounts-tab__mobile-hidden">
                Account Number
              </TableHeadCell>
              <TableHeadCell className="accounts-tab__table-header accounts-tab__mobile-hidden">
                Nickname
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderAccounts()}</TableBody>
        </Table>
        <div className="accounts-tab__button-container">
          <Button
            className="accounts-tab__button"
            secondary
            onClick={() => this.resetNicknameEntries()}
          >
            Cancel
          </Button>
          <Button
            className="accounts-tab__button"
            onClick={() => this.updateNicknames()}
          >
            Save
          </Button>
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}

AccountsTab.propTypes = {
  accounts: PaymentPropTypes.accounts,
  getAccounts: PropTypes.func.isRequired,
  orgs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateAccountNicknames: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

AccountsTab.defaultProps = {
  accounts: [],
};

export default AccountsTab;
