import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

import {
  FullPageBackground,
  bgEmployeeRegistration,
} from 'shared/ui/FullPageBackground';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import FormCard from 'shared/ui/MaterialFormCard';
import LoginSwitchButton from 'components/Login/LoginSwitchButton';

import styles from './styles';
import LoginForm from './LoginForm';

const EmployeeLogin = ({ classes, isLoading, push, location, ...rest }) => (
  <div>
    <FullPageBackground
      image={bgEmployeeRegistration}
      className="full-page-background--farmers-in-field"
    />
    <FormCard>
      <h3>Nutrien Employee Login</h3>
      <hr />
      {isLoading ? (
        <MaterialLoadingIcon />
      ) : (
        <LoginForm classes={classes} {...rest} />
      )}
    </FormCard>
    <LoginSwitchButton push={push} location={location} type="mobile" />
  </div>
);

EmployeeLogin.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
};

export default withStyles(styles)(EmployeeLogin);
