import { connect } from 'react-redux';
import { getQueryStringFromRouter } from 'shared/utils/reduxSelectors';
import ProductLabels from './ProductLabelsComponent';
import { fetchProductLabels } from './ProductLabelsActionCreators';

const mapStateToProps = (state) => ({
  productLabels: state.productLabels,
  user: state.user,
  queryString: getQueryStringFromRouter(state.router),
  accounts: state.accounts,
});

const mapDispatchToProps = {
  fetchProductLabels,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductLabels);
