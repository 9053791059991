import React from 'react';
import T from 'prop-types';
import Button from '@nutrien/uet-react/Button';

const OpenLink = ({ children, link, ...rest }) =>
  window.ReactNativeWebView ? (
    <Button
      variant="text"
      onClick={() => {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            name: 'open_link',
            payload: {
              link,
            },
          })
        );
      }}
      {...rest}
    >
      {children}
    </Button>
  ) : (
    <a href={link} rel="noopener noreferrer" target="_blank" {...rest}>
      {children}
    </a>
  );

OpenLink.propTypes = {
  children: T.node.isRequired,
  link: T.string.isRequired,
};

export default OpenLink;
