import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import UsersTable from './UsersTable';
import fetchUsers from '../actions/fetchUsers';

export class UsersTableContainer extends Component {
  static propTypes = {
    organization: PropTypes.shape({ id: PropTypes.string.isRequired })
      .isRequired,
    fetchUsers: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchUsers(this.props.organization.id);
  }

  render() {
    return <UsersTable {...this.props} />;
  }
}
export const mapDispatchToProps = {
  fetchUsers,
};

export const mapStateToProps = (state) => ({
  organization: state.user.userData.userDetails.associatedOrgs[0],
  users: state.organization.users.all,
  isLoading: state.organization.users.isFetching,
  error: state.organization.users.error,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTableContainer);
