import PropTypes from 'prop-types';

export { default as userPropTypes } from 'shared/config/userPropType';

const accountPropTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  balance: PropTypes.number,
  due: PropTypes.number,
  pastDue: PropTypes.number,
  number: PropTypes.string,
};

export const accountPropType = PropTypes.shape(accountPropTypes);

export const accountsPropTypes = PropTypes.shape({
  status: PropTypes.string,
  list: PropTypes.arrayOf(accountPropType),
});

const filterPropType = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.number,
  attributes: PropTypes.shape({
    name: PropTypes.string,
  }),
  count: PropTypes.number,
});

export const filtersPropType = PropTypes.shape({
  activeIngredient: PropTypes.arrayOf(filterPropType),
  chemical: PropTypes.arrayOf(filterPropType),
  fertilizer: PropTypes.arrayOf(filterPropType),
  manufacturer: PropTypes.arrayOf(filterPropType),
  cropType: PropTypes.arrayOf(filterPropType),
  checkedIds: PropTypes.shape({
    activeIngredient: PropTypes.arrayOf(PropTypes.number),
    chemical: PropTypes.arrayOf(PropTypes.number),
    fertilizer: PropTypes.arrayOf(PropTypes.number),
    manufacturer: PropTypes.arrayOf(PropTypes.number),
    cropType: PropTypes.arrayOf(PropTypes.number),
  }),
});
