import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Box from '@nutrien/uet-react/Box';
import CircularProgress from '@nutrien/uet-react/CircularProgress';
import CheckIcon from '@nutrien/uet-react/icons/Check';
import { makeStyles } from '@nutrien/uet-react/styles';

import { useLegalDocument } from 'hooks/useLegalDocument';
import PageHeader from 'shared/ui/PageHeader/PageHeader';

import {
  FETCHING_LEGAL,
  CALIFORNIA_DISCLOSURE_DOC,
} from '../legalDocumentsConstants';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginBottom: theme.spacing(4),

    '& .anchor': {
      display: 'block',
      position: 'relative',
      top: -theme.spacing(9),
      visibility: 'hidden',
    },
    '& th': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      verticalAlign: 'middle',
      padding: theme.spacing(3),
      border: `1px solid ${theme.palette.gray[600]}`,
    },
    '& td': {
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.gray[400]}`,

      '&.checked': {
        textAlign: 'center',
        color: theme.palette.primary.main,
      },
    },
    '& p': {
      margin: theme.spacing(0),
    },
    '& h2': {
      margin: `0 0 ${theme.spacing(2)}`,
    },
    '& h3': {
      margin: `0 0 ${theme.spacing(2)}`,
    },
  },
}));

const CaliforniaDisclosurePage = () => {
  const { content, status } = useLegalDocument(CALIFORNIA_DISCLOSURE_DOC);
  const classes = useStyles();
  useEffect(() => {
    if (status !== FETCHING_LEGAL) {
      const checkedComponents = Array.from(
        document.getElementsByClassName('checked')
      );
      if (checkedComponents.length) {
        checkedComponents.forEach((element) => {
          ReactDOM.render(<CheckIcon fontSize="large" />, element);
        });
      }
    }
  }, [status]);
  const legalDocumentContent =
    status === FETCHING_LEGAL ? (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    ) : (
      <div
        className={classes.contentContainer}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  return (
    <Box>
      <PageHeader
        title="California Information Sharing Disclosure"
        breadcrumbs={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'California Information Sharing Disclosure',
          },
        ]}
      />
      {legalDocumentContent}
    </Box>
  );
};

export default CaliforniaDisclosurePage;
