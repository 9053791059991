import { connect } from 'react-redux';
import { gtmOnlineOrdersClick } from 'store/middleware/TagManager/gtmActions';
import OrderCard from './OrderCard';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  gtmOnlineOrdersClick,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);
