import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ApiErrorMessage } from 'shared/ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import getInvoiceSummaries from './actions/handleAccountUpdate';
import InvoiceList from './InvoiceList';
import './Invoices.scss';

const selectInvoices = () =>
  createSelector([(state) => state.invoices], (invoices) => invoices);

const Invoices = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const invoices = useSelector(selectInvoices());

  const { accountId } = props;
  const { statusFilter } = queryString.parse(location.search);

  React.useEffect(() => {
    dispatch(getInvoiceSummaries(accountId));
  }, [accountId]);

  const didApiCallFail =
    invoices.status !== 'LOADING' &&
    invoices.status !== 'OK' &&
    invoices.status !== 404;

  const handlePaginationClick = (page) => {
    const { accountId, updateAccountTab } = props;
    queryString.page = page;

    let newQueryString = '';
    const queryArray = Object.entries(queryString);
    queryArray.forEach((item) => {
      newQueryString += `&${item[0]}=${encodeURIComponent(item[1])}`;
    });

    if (newQueryString.length > 0) {
      newQueryString = `?${newQueryString.slice(1)}`;
    }

    updateAccountTab(`invoices${newQueryString}`);

    push(`/accounts/${accountId}/invoices${newQueryString}`);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  if (invoices.status === 'LOADING' && !didApiCallFail)
    return (
      <div className="account-statements--loading-wrapper">
        <CircularProgress />
      </div>
    );

  return (
    <div className="account-statements">
      {didApiCallFail && <ApiErrorMessage entityName="Invoices" />}
      {invoices.status === 'OK' && (
        <InvoiceList
          pageSize={24}
          currentPage={queryString.page}
          statusFilter={statusFilter}
          accountId={accountId}
          handlePaginationClick={handlePaginationClick}
        />
      )}
    </div>
  );
};

Invoices.propTypes = {
  accountId: PropTypes.string.isRequired,
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  queryString: PropTypes.shape({ page: PropTypes.string }),
  updateAccountTab: PropTypes.func,
};

Invoices.defaultProps = {
  queryString: { page: undefined },
  updateAccountTab: () => {},
};

export default Invoices;
