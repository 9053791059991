import { nutrienGreen } from '../../styles';

export default {
  toggleShow: {
    cursor: 'pointer',
    ...nutrienGreen,
    fontSize: '.8rem',
    display: 'inline-block',
  },
};
