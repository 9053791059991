import {
  errorStyling,
  buttonContainerStyles,
  mobileButtonContainerStyles,
  modalContainerStyles,
  arrowLinkStyles,
} from 'shared/styles';

const styles = {
  modalContainer: {
    ...modalContainerStyles,
    minWidth: '400px',
  },
  form: {
    width: '100%',
    margin: '0px',
    '&:focus': {
      outline: 'none',
    },
  },
  negativeMarginTop: {
    marginTop: '-30px',
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  notification: {
    padding: '15px 0px 0px',
  },
  closeModal: {
    cursor: 'pointer',
    display: 'inline-block',
    float: 'right',
  },
  check: {
    marginRight: 10,
  },
  p: {
    padding: '10px 0px',
    margin: '0',
  },
  noSideMargin: {
    marginLeft: 0,
    marginRight: 0,
  },
  buttonContainer: {
    ...mobileButtonContainerStyles,
  },
  buttonContainerSmall: {
    ...mobileButtonContainerStyles,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontWeight: 600,
    fontSize: 14,
    marginTop: 25,
    '& span': {
      marginLeft: 15,
    },
  },
  error: {
    ...errorStyling,
  },
  errorContainer: {
    marginBottom: 20,
  },
  infoDiv: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 600,
    padding: 20,
    backgroundColor: '#f0f0f0',
    margin: '25px 0px',
  },
  infoDivTitle: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.15,
    margin: '0px 10px',
    color: '#828282',
    marginBottom: 7,
    marginTop: 7,
  },
  arrowLink: {
    ...arrowLinkStyles,
    '& > img': {
      width: '1.35em',
      marginBottom: 2,
    },
  },
  arrowLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0 10px',
  },
  titleParagraph: {
    padding: '10px 0px',
    color: '#000000',
    margin: '0px',
  },
  accountAdded: {
    maxWidth: '500px',
    minWidth: '350px',
    width: '100%',
  },
  '@media only screen and (max-width: 500px)': {
    childContainer: {
      '& .mdc-dialog__container': {
        height: '100%',
      },
      '& .mdc-dialog__surface': {
        maxHeight: '100%',
        marginLeft: '0 !important',
        marginRight: '0 !important',
      },
    },
  },
  '@media (min-width: 720px)': {
    buttonContainer: {
      ...buttonContainerStyles,
    },
    buttonContainerSmall: {
      ...buttonContainerStyles,
      width: '90%',
      margin: 'auto',
      float: 'none',
    },
    loading: {
      width: '47%',
      marginTop: 0,
    },
    p: {
      padding: '10px',
    },
    titleParagraph: {
      padding: '10px 0px',
      color: '#000000',
      margin: '0px',
    },
    accountAdded: {
      width: '500px',
    },
  },
};

export default styles;
