import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CircularProgress from '@nutrien/uet-react/CircularProgress';
import useQueryParam from 'hooks/useQueryParam';
import { logout } from '../Authentication/actions/authenticationActions';
import { authorizeUser } from './authorizeActions';

const AuthorizePage = () => {
  const code = useQueryParam('code') || '';
  const eid = useQueryParam('eid') || '';

  const dispatch = useDispatch();

  useEffect(() => {
    if (code) {
      const redirectRoute = eid
        ? `/employee/mirroring?userId=${eid}`
        : '/employee';
      dispatch(authorizeUser({ code, redirectRoute }));
    }
  }, [code, dispatch]);

  if (!code) {
    dispatch(logout());
  }
  return (
    <CircularProgress
      center="x"
      flexContainerProps={{
        p: '20px',
        width: '100%',
        'aria-label': 'Loading',
      }}
    />
  );
};

export default AuthorizePage;
