import { errorStyling } from 'shared/styles';

const styles = (theme) => ({
  error: {
    marginTop: 10,
    ...errorStyling,
  },
  header: {
    flexWrap: 'wrap',
    overflow: 'visible',
  },
  title: {
    padding: 20,
    top: 0,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  hr: {
    margin: 0,
    width: '100%',
  },
  card: {
    top: '10em',
    bottom: 100,
    left: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
  },
  termsCard: {
    margin: '1em auto 0px',
    padding: 0,
    color: theme.palette.text.secondary,
    maxWidth: 900,
    width: '90%',
    height: '100%',
    position: 'relative',
    flex: '1',
    '& h3': {
      marginBottom: 0,
    },
  },
  hasBanner: {
    top: '12em',
    bottom: 94,
    left: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
  },
  termsContent: {
    flex: 0,
    height: 'calc(100% - 73px)',
  },
  acceptTermsCard: {
    color: theme.palette.text.secondary,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    borderTop: '1px solid #cccccc',
    margin: '-4px auto 1em',
    padding: 20,
    position: 'relative',
    maxWidth: 900,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '90%',
    boxSizing: 'border-box',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  button: {
    display: 'block',
    margin: '0 0 0 20px',
  },
  checkbox: {
    margin: 10,
    textAlign: 'center',
    '& .checkbox__description': {
      color: '#464646',
    },
    '& .checkbox__label span': {
      backgroundColor: '#ffffff',
      border: '1px solid #464646',
      top: 1,
      left: -5,
    },
    '& .checkbox__label input': {
      top: 1,
      left: -5,
    },
    '& .checkbox__label input[type="checkbox"]:focus': {
      outline: 'none',
    },
  },
  '@media (max-width: 500px)': {
    card: {
      bottom: 125,
    },
  },
  '@media (max-width: 700px)': {
    acceptTermsCard: {
      padding: '0 2em',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    termsContent: {
      padding: '0px 20px',
    },
    button: {
      margin: '2em 0 2em 2em',
      maxWidth: 210,
    },
    hasBanner: {
      top: '15em',
    },
  },
  '@media (max-width: 390px)': {
    termsCard: {
      '& h3': {
        fontSize: 16,
      },
    },
    hasBanner: {
      top: '16em',
    },
  },
  '@media (max-width: 350px)': {
    hasBanner: {
      top: '17em',
    },
  },
  '@media (max-width: 330px)': {
    hasBanner: {
      top: '18em',
    },
  },
});

export default styles;
