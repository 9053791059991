import React from 'react';
import { Notification } from 'nutrien-common-components-react/dist/mdc';

const DemoEnvWarning = () => (
  <Notification type="warning">
    User Registration is not available on the demo site. <br />
    Click{' '}
    <a
      data-test="prod-reg-link"
      href="https://my.nutrienagsolutions.com/register"
    >
      here
    </a>{' '}
    if you would like to sign up for an account.
  </Notification>
);

export default DemoEnvWarning;
