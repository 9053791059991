/**
 * Material UI doesn't play well with FeatureFlag rendered props component
 * As tabs expects certain props on the child to set active state, this
 * component remedies that, and can be a drop in for Tab when feature flags are needed
 *
 */

import React from 'react';
import { Tab } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';

const FlaggedTab = ({ flagKey, ...props }) => {
  const flags = useFlags();
  const key = flagKey.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
  return flags[key] ? <Tab {...props} /> : null;
};

FlaggedTab.propTypes = {
  flagKey: PropTypes.string.isRequired,
};

/**
 * Alternative to FlaggedTab component where flagKey may be undefined or falsy
 * Feature flag checking is only performed when a flagKey is present and truthy
 */
const MaybeFlaggedTab = ({ flagKey, ...props }) =>
  flagKey ? <FlaggedTab flagKey={flagKey} {...props} /> : <Tab {...props} />;

MaybeFlaggedTab.propTypes = {
  ...FlaggedTab.propTypes,
  flagKey: PropTypes.string,
};

MaybeFlaggedTab.defaultProps = {
  ...FlaggedTab.defaultProps,
  flagKey: '',
};

export default FlaggedTab;
export { FlaggedTab, MaybeFlaggedTab };
