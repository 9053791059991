import { useLocation } from 'react-router-dom';

const useQueryParam = (paramKey) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return params.get(paramKey);
};

export default useQueryParam;
