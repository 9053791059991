import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { currency } from 'shared/utils/numberFormatters';
import { withStyles } from '@nutrien/uet-react/styles';
import Grid from '@nutrien/uet-react/Grid';
import classesNames from 'classnames';
import { MAX_PAYMENT_AMOUNT } from 'const/payments';
import PaymentAmountRadioButton from './PaymentAmountRadioButton';
import { buildClassName } from '../../../../../Utils/PaymentUtil';
import { PAYMENT_AMOUNT_TYPES } from '../../../MakeAPaymentConstants';
import PaymentValidationMessageComponent from '../../../../../OtherComponents/ValidationMessage/PaymentValidationMessageComponent';

import styles from './styles';
import commonStyles from '../../../../../commonPaymentStyles';

const finalStyles = (theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
});

class PaymentAmountComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      negativeBalance: false,
      negativeDue: false,
      amountTypeSelected: null,
      overMaxBalanceDue: false,
      overMaxBalance: false,
    };

    this.handleNewAccount = this.handleNewAccount.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.clearErrorsAndWarnings = this.clearErrorsAndWarnings.bind(this);
  }

  componentDidMount() {
    this.handleNewAccount();
  }

  componentDidUpdate(prevProps) {
    const { currentBalance, amountDue } = this.props;
    if (
      prevProps.currentBalance !== currentBalance ||
      prevProps.amountDue !== amountDue
    ) {
      this.handleNewAccount();
    }
  }

  handleNewAccount() {
    const { currentBalance, amountDue } = this.props;
    const negativeBalance = currentBalance <= 0;
    const negativeDue = amountDue <= 0;
    const overMaxBalanceDue = amountDue >= MAX_PAYMENT_AMOUNT;
    const overMaxBalance = currentBalance >= MAX_PAYMENT_AMOUNT;
    let amtTypeSelected = '';
    if (!negativeDue && !overMaxBalanceDue) {
      amtTypeSelected = PAYMENT_AMOUNT_TYPES.AmountDue;
      this.handleRadioChange(PAYMENT_AMOUNT_TYPES.AmountDue, amountDue);
    } else if (!negativeBalance && !overMaxBalance) {
      amtTypeSelected = PAYMENT_AMOUNT_TYPES.CurrentBalance;
      this.handleRadioChange(
        PAYMENT_AMOUNT_TYPES.CurrentBalance,
        currentBalance
      );
    } else {
      amtTypeSelected = PAYMENT_AMOUNT_TYPES.Other;
      this.handleRadioChange(PAYMENT_AMOUNT_TYPES.Other, '');
    }
    this.setState({
      negativeDue,
      negativeBalance,
      overMaxBalanceDue,
      overMaxBalance,
      amountTypeSelected: amtTypeSelected,
    });
  }

  handleRadioChange(amountType, value) {
    this.clearErrorsAndWarnings();
    this.setState({
      amountTypeSelected: amountType,
    });
    this.props.handleOnChangeAmount({
      target: { name: 'amountEntered', value },
    });
    this.props.handleChange({
      target: { name: 'amountTypeSelected', value: amountType },
    });
  }

  clearErrorsAndWarnings() {
    const { errors, warnings } = this.props;
    errors.amountEntered = [];
    warnings.amountEntered = [];
  }

  render() {
    const {
      handleOnChangeAmount,
      handleOnBlurAmount,
      errors,
      warnings,
      amountEntered,
      currentBalance,
      amountDue,
      classes,
    } = this.props;
    const {
      amountTypeSelected,
      negativeBalance,
      negativeDue,
      overMaxBalance,
      overMaxBalanceDue,
    } = this.state;

    return (
      <div className="Payment-Amount__Container">
        <hr className={classes.addPaymentFormHR} />
        <label
          className={classes.addPaymentFormLabelLarge}
          htmlFor="PaymentAmount"
        >
          Payment Amount
        </label>
        <Grid container className={classes.layout}>
          {/* Current Balance */}
          <Grid item xs={12} sm={6} md={4} className={classes.card}>
            <div
              className={classesNames(
                classes.paymentAmountRadioContainer,
                amountTypeSelected === PAYMENT_AMOUNT_TYPES.CurrentBalance &&
                  classes.paymentAmountRadioContainerSelected,
                (negativeBalance || overMaxBalance) &&
                  classes.paymentAmountRadioContainerDisabled
              )}
              onClick={() => {
                if (!this.state.negativeBalance && !this.state.overMaxBalance) {
                  this.handleRadioChange(
                    PAYMENT_AMOUNT_TYPES.CurrentBalance,
                    currentBalance
                  );
                }
              }}
              role="button"
              tabIndex="-1"
              onKeyPress={() => {}}
              data-test="payment-amount-current-balance"
            >
              <PaymentAmountRadioButton
                checked={
                  this.state.amountTypeSelected ===
                  PAYMENT_AMOUNT_TYPES.CurrentBalance
                }
                onChange={() => {
                  this.handleRadioChange(
                    PAYMENT_AMOUNT_TYPES.CurrentBalance,
                    currentBalance
                  );
                }}
                name="amountType"
                value="CurrentBalance"
                disabled={
                  this.state.negativeBalance || this.state.overMaxBalance
                }
                required
                data-test="payment-amount-current-balance-radio"
              />
              <label
                className={`${classes.accountInfoBlock} pay-amount-option`}
                htmlFor="CurrentBalance"
              >
                <span className={classes.paymentAmountLabel}>
                  Current Balance
                </span>
                <div className={classes.paymentAmountValue}>
                  {currency(currentBalance)}
                </div>
              </label>
            </div>
          </Grid>
          {/* Current Balance Due */}
          <Grid item xs={12} sm={6} md={4} className={classes.card}>
            <div
              className={classesNames(
                classes.paymentAmountRadioContainer,
                amountTypeSelected === PAYMENT_AMOUNT_TYPES.AmountDue &&
                  classes.paymentAmountRadioContainerSelected,
                (negativeDue || overMaxBalanceDue) &&
                  classes.paymentAmountRadioContainerDisabled
              )}
              onClick={() => {
                if (!this.state.negativeDue && !this.state.overMaxBalanceDue) {
                  this.handleRadioChange(
                    PAYMENT_AMOUNT_TYPES.AmountDue,
                    amountDue
                  );
                }
              }}
              role="button"
              tabIndex="-1"
              onKeyPress={() => {}}
              data-test="payment-amount-current-due"
            >
              <PaymentAmountRadioButton
                checked={
                  this.state.amountTypeSelected ===
                    PAYMENT_AMOUNT_TYPES.AmountDue &&
                  !this.state.overMaxBalanceDue
                }
                onChange={() => {
                  this.handleRadioChange(
                    PAYMENT_AMOUNT_TYPES.AmountDue,
                    amountDue
                  );
                }}
                name="amountType"
                value="AmountDue"
                disabled={negativeDue || overMaxBalanceDue}
                required
                data-test="payment-amount-current-due-radio"
              />
              <label
                className={`${classes.accountInfoBlock} pay-amount-option`}
                htmlFor="amountDue"
              >
                <span className={classes.paymentAmountLabel}>
                  Current Balance Due
                </span>
                <div className={classes.paymentAmountValue}>
                  {currency(amountDue)}
                </div>
              </label>
            </div>
          </Grid>
          {/* Other */}
          <Grid item xs={12} sm={12} md={4} className={classes.card}>
            <div
              className={classesNames(
                classes.paymentAmountRadioContainer,
                amountTypeSelected === PAYMENT_AMOUNT_TYPES.Other &&
                  classes.paymentAmountRadioContainerSelected
              )}
              onClick={() => {
                if (amountTypeSelected !== PAYMENT_AMOUNT_TYPES.Other) {
                  this.handleRadioChange(PAYMENT_AMOUNT_TYPES.Other, '');
                }
              }}
              role="button"
              tabIndex="-1"
              onKeyPress={() => {}}
              data-test="payment-amount-other"
            >
              <PaymentAmountRadioButton
                checked={amountTypeSelected === PAYMENT_AMOUNT_TYPES.Other}
                onChange={() => {
                  this.handleRadioChange(PAYMENT_AMOUNT_TYPES.Other, '');
                }}
                name="amountType"
                value="Other"
                required
                data-test="payment-amount-other-radio"
              />
              <label
                className={`${classes.accountInfoBlock} pay-amount-option`}
                htmlFor="Other"
              >
                <span className={classes.paymentAmountLabel}>Other</span>
                <input
                  type="text"
                  className={buildClassName(
                    'amountEntered',
                    classes.paymentAmountFormInput,
                    errors,
                    classes.error
                  )}
                  name="amountEntered"
                  placeholder="$0.00"
                  onChange={handleOnChangeAmount}
                  onBlur={handleOnBlurAmount}
                  onFocus={handleOnChangeAmount}
                  value={
                    amountTypeSelected === PAYMENT_AMOUNT_TYPES.Other
                      ? amountEntered
                      : ''
                  }
                  disabled={amountTypeSelected !== PAYMENT_AMOUNT_TYPES.Other}
                />
              </label>
            </div>
            <div className={classes.paymentAmountMessageContainer}>
              <PaymentValidationMessageComponent
                errors={errors}
                warnings={warnings}
                field="amountEntered"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

PaymentAmountComponent.propTypes = {
  handleOnChangeAmount: PropTypes.func.isRequired,
  handleOnBlurAmount: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  amountEntered: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  currentBalance: PropTypes.number.isRequired,
  amountDue: PropTypes.number.isRequired,
  errors: PropTypes.shape({
    addressLine1: PropTypes.array,
    addressLine2: PropTypes.array,
    amountEntered: PropTypes.array,
    bankAccountNumber: PropTypes.array,
    city: PropTypes.array,
    confirmBankAccountNumber: PropTypes.array,
    corporateName: PropTypes.array,
    country: PropTypes.array,
    cpsAccount: PropTypes.array,
    firstName: PropTypes.array,
    lastName: PropTypes.array,
    phone: PropTypes.array,
    routingNumber: PropTypes.array,
    state: PropTypes.array,
    zip: PropTypes.array,
  }).isRequired,
  warnings: PropTypes.shape({
    addressLine1: PropTypes.array,
    addressLine2: PropTypes.array,
    amountEntered: PropTypes.array,
    bankAccountNumber: PropTypes.array,
    city: PropTypes.array,
    confirmBankAccountNumber: PropTypes.array,
    corporateName: PropTypes.array,
    country: PropTypes.array,
    cpsAccount: PropTypes.array,
    firstName: PropTypes.array,
    lastName: PropTypes.array,
    phone: PropTypes.array,
    routingNumber: PropTypes.array,
    state: PropTypes.array,
    zip: PropTypes.array,
  }).isRequired,
  classes: PropTypes.shape({
    layout: PropTypes.string,
    card: PropTypes.string,
    paymentAmountRadioContainer: PropTypes.string,
    paymentAmountRadioContainerSelected: PropTypes.string,
    paymentAmountRadioContainerDisabled: PropTypes.string,
    addPaymentFormHR: PropTypes.string,
    addPaymentFormLabelLarge: PropTypes.string,
    paymentAmountInputContainer: PropTypes.string,
    paymentAmountInputWarningPadding: PropTypes.string,
    accountInfoBlock: PropTypes.string,
    paymentAmountRadioInput: PropTypes.string,
    paymentAmountLabel: PropTypes.string,
    paymentAmountValue: PropTypes.string,
    paymentAmountFormInput: PropTypes.string,
    error: PropTypes.string,
    paymentAmountMessageContainer: PropTypes.string,
  }).isRequired,
};

export default withStyles(finalStyles)(PaymentAmountComponent);
