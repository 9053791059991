import PropTypes from 'prop-types';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { contactMethodTypes } from '../actions';
import NotificationsPreferencesItem from './NotificationsPreferencesItem';

/**
 * A helper method which will either render the NotificationsPreferencesItem with the given category, key, and value,
 * or which will return null if this particular preference is included in the disabledPreferences array
 * @param {Array{String}} disabledPreferences An array of all preferences which are turned off, which should be off the form CATEGORY_PREFERENCE_NAME
 * @param {String} preferenceCategory The string representing the category (e.g. "Account") written in start case
 * @param {String} preferenceKey The string representing the preference (e.g. "New Invoice") written in start case
 * @param {Object} preferenceObject An object containing keys for each category, which each contain each individual preferences,
 * @param {Function} onPreferenceChange Handler for when a preference is toggled
 * @param {Boolean} emailValue Current value of this preference's email state
 * @param {Boolean} phoneValue Current value of this preference's phone state
 * which contain all emails and phone numbers and their corresponding values for that preference
 */
export const conditionallyRenderPreferenceItem = (
  disabledPreferences,
  preferenceKey,
  onPreferenceChange,
  showInboxToggle,
  showEmailToggle,
  showPhoneToggle,
  inboxValue,
  emailValue,
  phoneValue,
  formKey,
  key
) => {
  if (disabledPreferences.includes(formKey)) {
    return null;
  }

  // it was unclear how to choose which single value to display
  // in the case of multiple emails,
  // so just assume we can derive from the preferred email's preference.
  // additionally, it is unclear how common the multiple emails use case is
  return (
    <NotificationsPreferencesItem
      formKey={formKey}
      onPreferenceChange={onPreferenceChange}
      showInboxToggle={showInboxToggle}
      showEmailToggle={showEmailToggle}
      showPhoneToggle={showPhoneToggle}
      inboxValue={inboxValue}
      emailValue={emailValue}
      phoneValue={phoneValue}
      preferenceKey={preferenceKey}
      onEmailToggle={(value) =>
        onPreferenceChange(contactMethodTypes.email, key, value)
      }
    />
  );
};

export const NotificationsPreferencesItemContainer = ({
  preferenceKey,
  onPreferenceChange,
  showInboxToggle,
  showEmailToggle,
  showPhoneToggle,
  inboxValue,
  emailValue,
  phoneValue,
  formKey,
}) => {
  const { disabledNotificationPreferences } = useFlags();
  const renderPreferenceItem = (disabledPreferences) =>
    conditionallyRenderPreferenceItem(
      disabledPreferences,
      preferenceKey,
      onPreferenceChange,
      showInboxToggle,
      showEmailToggle,
      showPhoneToggle,
      inboxValue,
      emailValue,
      phoneValue,
      formKey
    );
  return (
    <>
      {disabledNotificationPreferences
        ? renderPreferenceItem(disabledNotificationPreferences)
        : renderPreferenceItem([])}
    </>
  );
};

NotificationsPreferencesItemContainer.propTypes = {
  preferenceKey: PropTypes.string.isRequired,
  onPreferenceChange: PropTypes.func.isRequired,
  showInboxToggle: PropTypes.bool,
  showEmailToggle: PropTypes.bool,
  showPhoneToggle: PropTypes.bool,
  inboxValue: PropTypes.bool,
  emailValue: PropTypes.bool.isRequired,
  phoneValue: PropTypes.bool.isRequired,
  formKey: PropTypes.string.isRequired,
};

NotificationsPreferencesItemContainer.defaultProps = {
  showInboxToggle: false,
  showEmailToggle: true,
  showPhoneToggle: true,
  inboxValue: true,
};

export default NotificationsPreferencesItemContainer;
