import get from 'lodash/get';
import { GET_BRANCH_ADDRESS_FAILURE } from '../actionTypes';

export default (error) => {
  const err = get(
    error,
    'message',
    'There was an error retrieving the branch address'
  );
  return {
    type: GET_BRANCH_ADDRESS_FAILURE,
    error: err,
  };
};
