import React, { useState } from 'react';

import PasswordField from './PasswordField';

const PasswordFieldContainer = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <PasswordField
      showPassword={showPassword}
      handleToggleVisibility={handleToggleVisibility}
      {...props}
    />
  );
};

export default PasswordFieldContainer;
