import { connect } from 'react-redux';
import {
  gfaLoad,
  gtmClickProduct,
} from 'store/middleware/TagManager/gtmActions';
import HubPromotion from './HubPromotion';

const mapDispatchToProps = {
  gfaLoad,
  gtmClickProduct,
};

export default connect(null, mapDispatchToProps)(HubPromotion);
