import { CLEAR_FILTERED_PRODUCT, SET_FILTERED_PRODUCTS } from '../actions';

const initialState = { list: [], product: { id: '' } };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERED_PRODUCTS:
      return action.payload;
    case CLEAR_FILTERED_PRODUCT:
      return initialState;
    default:
      return state;
  }
};
