import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Chip, ChipSet, Icon } from 'nutrien-common-components-react/dist/mdc/';
import currency from 'shared/utils/numberFormatters/currency';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from 'nutrien-common-components-react/dist/mdc/Dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'nutrien-common-components-react/dist/mdc/Table';
import closeModal from 'assets/close-modal.svg';
import {
  selectedProductsPropType,
  groupedInvoicedProductsPropType,
} from './productPropTypes';

import './InvoiceProductInfo.scss';

class InvoiceProductInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  getProductChips = (products) =>
    !isEmpty(products) && (
      <ChipSet>
        {products.map((product) => (
          <Chip
            key={product.productId}
            data-test="invoice-product-info-chip"
            id={product.productId}
            label={product.productName}
            removeIcon={
              <Icon
                data-test="invoice-product-info-chip-close-icon"
                icon="cancel"
                onClick={() => this.props.deselectInvoicedProduct(product)}
              />
            }
          />
        ))}
      </ChipSet>
    );

  getDetailsTableRows = () => {
    const { groupedInvoicedProducts } = this.props;
    const rows = [];
    Object.keys(groupedInvoicedProducts).forEach((product) => {
      rows.push(
        <TableRow key={groupedInvoicedProducts[product].productId}>
          <TableCell colSpan={2} className="invoiced-product-name-desktop">
            {groupedInvoicedProducts[product].productName}
          </TableCell>
          <TableCell className="invoiced-product-name-mobile">
            {groupedInvoicedProducts[product].productName}
          </TableCell>
          <TableCell>
            {`${groupedInvoicedProducts[product].quantity.toFixed(2)} ${
              groupedInvoicedProducts[product].quantityUOM
            }`}
          </TableCell>
          <TableCell className="align-text-right">
            {currency(groupedInvoicedProducts[product].totalAmount)}
          </TableCell>
        </TableRow>
      );
    });

    return <>{rows}</>;
  };

  getDetailsTable = () => {
    const { isCanada } = this.props;
    const rows = this.getDetailsTableRows();
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} className="invoiced-product-name-desktop">
              Product
            </TableCell>
            <TableCell className="invoiced-product-name-mobile">
              Product
            </TableCell>
            <TableCell>{isCanada ? 'Quantity' : 'Volume'}</TableCell>
            <TableCell className="align-text-right">Total Invoiced</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  };

  getDetailsDialog = () => (
    <div className="invoice-product-details-modal__container">
      <Dialog open={this.state.isOpen} onClosed={this.toggleModal}>
        <DialogTitle>
          Product Breakdown
          <div
            role="button"
            className="close-button"
            onClick={this.toggleModal}
            onKeyPress={this.toggleModal}
            tabIndex="0"
          >
            <img src={closeModal} alt="close" />
          </div>
        </DialogTitle>
        <DialogContent>{this.getDetailsTable()}</DialogContent>
      </Dialog>
    </div>
  );

  toggleModal = () => {
    if (!this.state.isOpen) {
      this.props.gtmViewInvoicedProductsDetailsModal();
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const {
      totalPriceForInvoicedProducts,
      selectedInvoicedProducts,
      groupedInvoicedProducts,
    } = this.props;
    const { isOpen } = this.state;

    if (isEmpty(selectedInvoicedProducts)) return null;

    return (
      <div>
        <div className="invoiced-product-rollup__container">
          <div className="total-price__container">
            <div className="total-price__title">
              Total Invoiced for Filtered Products
            </div>
            <div className="total-price__amount">
              {currency(totalPriceForInvoicedProducts)}
            </div>
            {!isEmpty(groupedInvoicedProducts) && (
              <div
                className="invoiced-product-rollup__details"
                onClick={this.toggleModal}
                onKeyPress={this.toggleModal}
                role="link"
                tabIndex="0"
              >
                details
                {isOpen && this.getDetailsDialog()}
              </div>
            )}
            <br />
          </div>
        </div>
        <div className="invoiced-product-chips__container">
          <div className="invoiced-product-chips__label">
            Invoices Including:
          </div>
          {this.getProductChips(selectedInvoicedProducts)}
        </div>
      </div>
    );
  }
}

InvoiceProductInfo.propTypes = {
  deselectInvoicedProduct: PropTypes.func.isRequired,
  gtmViewInvoicedProductsDetailsModal: PropTypes.func.isRequired,
  totalPriceForInvoicedProducts: PropTypes.number.isRequired,
  groupedInvoicedProducts: groupedInvoicedProductsPropType.isRequired,
  selectedInvoicedProducts: selectedProductsPropType.isRequired,
  isCanada: PropTypes.bool.isRequired,
};

export default InvoiceProductInfo;
