import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from './StepConnector';

import getStepperBreadCrumbs from '../../utils/GetStepperBreadCrumbs/getStepperBreadCrumbs';

import './styles.scss';

const CartStepper = (props) => {
  const { currentStep } = props;
  const selectedAccountNumber = useSelector(
    (state) => state.selectedAccount.id
  );
  const steps = getStepperBreadCrumbs(selectedAccountNumber);
  const currentIndex = steps.findIndex((step) => step.id === currentStep);
  return (
    <div className="cart-stepper__root">
      <Stepper
        className="cart-stepper__stepper"
        nonLinear
        activeStep={currentIndex + 1}
        connector={<StepConnector />}
      >
        {steps.map((step, index) => {
          return (
            <Step key={step.stepperLabel}>
              <StepButton
                onClick={undefined}
                completed={index < currentIndex + 1}
                disableRipple
                className="cart-stepper__step-button"
              >
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: 'cart-stepper__bubble--active',
                      root: 'cart-stepper__bubble--root',
                      completed: 'cart-stepper__bubble--completed',
                    },
                  }}
                >
                  {step.stepperLabel}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

CartStepper.propTypes = {
  currentStep: PropTypes.string.isRequired,
};

export default CartStepper;
