const cartItemArrayTransformer = (itemArray) => {
  try {
    return itemArray.length
      ? itemArray.map((item) => ({
          product: {
            attributes: {
              account_id: item.accountId,
              branch_id: item.branchId,
              created_at: item.createdAt,
              guid: item.product.id,
              name: item.product.name,
              priceType: item.priceType,
            },
            total_volume: item.totalVolume,
          },
          variant: {
            price: item.unitPrice,
            unit_measure: item.variant.unitMeasure,
            unit_qty: item.variant.unitQty,
            unit_size: item.variant.unitSize,
            digital_variant_name: item.variant.digitalVariantName,
            net_price: item.netPrice,
            id: item.variant.id,
            ars_key: item.variant.id,
            variant_sku: item.variant.id,
            total_volume_options: item.variant.totalVolumeOptions,
            is_bulk: item.variant.isBulk,
          },
          account_id: item.accountId,
          id: item.id,
          variant_sku: item.variant.id,
          total_volume: item.totalVolume,
        }))
      : [];
  } catch (e) {
    console.error('One or more cart items were not formatted properly:', e);
    return [];
  }
};

export default cartItemArrayTransformer;
