import resolveApiEnv, { DEV, SIT, PRE, PROD } from './resolveApiEnv';

const urls = {
  [DEV]: 'https://hub.dev.nutrienagsolutions.com',
  [SIT]: 'https://hub.sit.nutrienagsolutions.com',
  [PRE]: 'https://hub.pre.nutrienagsolutions.com',
  [PROD]: 'https://hub.nutrienagsolutions.com',
};

const resolveHubUrl = () => {
  const env = resolveApiEnv();
  return urls[env];
};

export default resolveHubUrl;
