import React, { Component } from 'react';
import get from 'lodash/get';
import { Button, Card, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ApiErrorMessage, MaterialLoadingIcon } from 'shared/ui';
import UserPropType from 'shared/config/userPropType';
import { FETCHING_USER, USER_FETCHED } from 'shared/config/userConstants';
import gnome from 'assets/gnome.svg';
import { Grid, Row, Cell } from 'nutrien-common-components-react/dist/mdc';
import OrderCard from './OrderCard';
import './RecentOrders.scss';

export class RecentOrders extends Component {
  state = {
    orderFilters: {
      startDate: '1/1/2010',
      endDate: '12/31/2100',
      ordersLimit: '4',
      pageNum: '1',
      sortField: '-orderNumber',
    },
  };

  componentDidMount() {
    const { getOrders, user, selectedAccount, flags } = this.props;
    const { universalHeader } = flags;
    if (universalHeader) {
      if (selectedAccount && selectedAccount.number) {
        getOrders(selectedAccount.number, this.state.orderFilters);
      }
    } else {
      const accountNumber = get(
        user,
        'userData.userDetails.defaultAccountNumber'
      );

      if (user.status === USER_FETCHED) {
        getOrders(accountNumber, this.state.orderFilters);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { getOrders, user, selectedAccount, flags } = this.props;
    const { universalHeader } = flags;
    if (universalHeader) {
      if (prevProps.selectedAccount !== selectedAccount) {
        getOrders(selectedAccount.number, this.state.orderFilters);
      }
    } else {
      const accountNumber = get(
        user,
        'userData.userDetails.defaultAccountNumber'
      );

      if (
        prevProps.user.status === FETCHING_USER &&
        user.status === USER_FETCHED
      ) {
        getOrders(accountNumber, this.state.orderFilters);
      }
    }
  }

  renderRecentOrder() {
    switch (this.props.status) {
      case 'LOADING':
        return <MaterialLoadingIcon />;
      case 'OK':
        if (this.props.orders.length) {
          const { orders } = this.props;
          const sortedOrders = orders.sort((a, b) => b.id - a.id);
          return (
            <Grid className="recent-orders__card-wrapper">
              <Row>
                {sortedOrders.map((order) => (
                  <Cell
                    columns={3}
                    phoneColumns={4}
                    tabletColumns={4}
                    key={order.id}
                  >
                    <OrderCard
                      order={order}
                      data-test="recent-orders__order-card"
                      orderArrayIndex={orders.indexOf(order)}
                    />
                  </Cell>
                ))}
              </Row>
            </Grid>
          );
        }
        return (
          <div className="recent-orders__no-orders-message">
            <img
              src={gnome}
              alt=""
              className="recent-orders__no-orders-gnome"
            />
            There are no online orders available for this account.
          </div>
        );
      case 'ERROR':
        return <ApiErrorMessage entityName="Recent orders" />;
      default:
        return <ApiErrorMessage entityName="Recent orders" />;
    }
  }

  renderLink(renderViewAllLink) {
    const { gtmOnlineOrdersClick } = this.props;
    return renderViewAllLink ? (
      <Link
        to="/orders"
        className="recent-orders__orders-link-wrapper"
        onClick={() => gtmOnlineOrdersClick('View All Orders Link')}
      >
        <Button variant="text">
          View All Online Orders
          <ChevronRightIcon />
        </Button>
      </Link>
    ) : (
      <Link to="/products" className="recent-orders__products-link-wrapper">
        <Button
          variant="contained"
          className="recent-orders__products-link-button"
        >
          Browse Products Catalog
        </Button>
      </Link>
    );
  }

  render() {
    return (
      <div className="recent-orders__container">
        <Card component={Box} p={2}>
          <div className="recent-orders__header">
            <h3>Recent Online Orders</h3>
          </div>
          {this.renderRecentOrder()}
          {this.renderLink(!!this.props.orders.length)}
        </Card>
      </div>
    );
  }
}

RecentOrders.propTypes = {
  user: UserPropType.isRequired,
  flags: PropTypes.shape({ universalHeader: PropTypes.bool }).isRequired,
  selectedAccount: PropTypes.shape({ number: PropTypes.string }),
  getOrders: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  status: PropTypes.string.isRequired,
  gtmOnlineOrdersClick: PropTypes.func.isRequired,
};

RecentOrders.defaultProps = {
  orders: [],
  selectedAccount: {},
};

export default RecentOrders;
