import React from 'react';
import Delete from '@material-ui/icons/Delete';
import propTypes from 'prop-types';
import { TableCell } from 'nutrien-common-components-react/dist/mdc/Table';
import currency from 'shared/utils/numberFormatters/currency';
import { RecurringPaymentPropTypes } from '../PaymentsTab';
import TableRowBuilder from '../TableHelpers/TableRowBuilder';
import { RecurringPaymentFrequencies } from '../../../Payments/PaymentConstants';

const frequencyCodeMap = Object.keys(RecurringPaymentFrequencies).reduce(
  (acc, key) => {
    const { code, name } = RecurringPaymentFrequencies[key];
    if (!acc) {
      return { [code]: name };
    }
    acc[code] = name;
    return acc;
  },
  {}
);

export function getRecurringFrequencyLabel(code) {
  return frequencyCodeMap[code];
}

const columns = [
  {
    propertyName: 'nextPayment',
    className: 'nutrien-table__cell__fixed',
    format: (details) =>
      details.nextPayment
        ? new Date(details.nextPayment).toLocaleDateString()
        : null,
  },
  { propertyName: 'accountId', className: 'nutrien-table__cell__fixed' },
  {
    propertyName: 'paymentType',
    className: 'nutrien-table__cell__fixed',
  },
  {
    propertyName: 'frequency',
    format: (details) =>
      details.frequency ? getRecurringFrequencyLabel(details.frequency) : null,
  },
  {
    propertyName: 'amount',
    format: (details) => (details.amount ? currency(details.amount) : null),
    className: 'payments-table__right-align',
  },
];

const RecurringPaymentsRow = (props) => {
  const { details } = props;

  // TODO: Update to UET components (Table, IconButton, etc)
  return (
    <TableRowBuilder data={details} columns={columns}>
      <TableCell className="payments-table__final-col">
        <button
          className="payments-table__delete-button"
          onClick={() => {
            props.handlePaymentDelete(details);
          }}
          style={{ verticalAlign: 'text-top' }}
        >
          <Delete
            className="cart-item__close-icon"
            onClick={() => {}}
            color="action"
          />
        </button>
      </TableCell>
    </TableRowBuilder>
  );
};

RecurringPaymentsRow.propTypes = {
  details: propTypes.shape(RecurringPaymentPropTypes).isRequired,
  handlePaymentDelete: propTypes.func.isRequired,
};

export default RecurringPaymentsRow;
