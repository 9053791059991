import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CustomApplied from '../../components/CustomApplied';
import * as cartActions from '../../actions/cartActions';

const mapStateToProps = ({ cart, selectedAccount }) => ({
  cart,
  selectedAccount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...cartActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomApplied);
