import get from 'lodash/get';
import { UPDATE_CART_FAILURE } from '../actionTypes';

export default (error) => {
  const err = get(error, 'message', 'There was an error updating the cart');
  return {
    type: UPDATE_CART_FAILURE,
    error: err,
  };
};
