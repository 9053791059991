import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { Pagination } from 'shared/ui';
import { getPage } from 'shared/utils/pagination';
import NoData from '../../../../shared/ui/TableNoData';
import messages from '../../constants/messages';

import useStyles from './styles';

const OrdersTable = (props) => {
  const [page, setPage] = useState(1);
  const classes = useStyles();

  const pageSize = 10;

  const { tableData } = props;

  const paginatedTableData = getPage(tableData, page, pageSize);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHeaderDesktop}>Date</TableCell>
            <TableCell align="left" className={classes.cellHeaderDesktop}>
              Number
            </TableCell>
            <TableCell align="left" className={classes.cellHeaderDesktop}>
              Subtotal
            </TableCell>
            <TableCell align="right" className={classes.cellHeaderDesktop}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!tableData.length && (
            <NoData message={messages.ORDERS_TABLE_NO_DATA} tableCols={4} />
          )}
          {/* eslint-disable-next-line react/prop-types */}
          {tableData.length > 0 &&
            paginatedTableData.map((data) => (
              <TableRow
                className={classes.row}
                data-test="order-table-row"
                key={data.orderNumber}
              >
                <TableCell align="left" className={classes.cell}>
                  <span className={classes.cellHeaderMobile}>Date</span>
                  <span className={classes.orderDate}>
                    {moment(data.orderDate).format('MM/DD/YY')}
                  </span>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <span className={classes.cellHeaderMobile}>Number</span>
                  <span>
                    <Link
                      to={`/orders/${data.orderNumber}`}
                      className={classes.tableLink}
                    >
                      {data.orderNumber}
                    </Link>
                  </span>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <span className={classes.cellHeaderMobile}>Subtotal</span>
                  <span>{numeral(data.orderTotal).format('$0,0.00')}</span>
                </TableCell>
                <TableCell align="right" className={classes.cellEnd}>
                  <span className={classes.cellHeaderMobile}>Status</span>
                  <span>{data.orderStatus}</span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        data-test="documents-orders-pagination"
        current={page}
        total={tableData.length}
        onChange={handleChangePage}
        pageSize={pageSize}
      />
    </>
  );
};

OrdersTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OrdersTable;
