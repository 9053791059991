import PropTypes from 'prop-types';

export const notificationType = PropTypes.string;

export const preference = PropTypes.shape({
  userId: PropTypes.string,
  notificationType: notificationType.isRequired,
  inboxOptOut: PropTypes.bool,
  emailOptIn: PropTypes.bool,
  smsOptIn: PropTypes.bool,
});

export const preferences = PropTypes.objectOf(preference);

export const preferenceSetting = PropTypes.shape({
  key: PropTypes.string,
  label: PropTypes.string,
});

export const preferenceSettings = PropTypes.arrayOf(preferenceSetting);

export const categorizedPreferenceSetting = PropTypes.shape({
  label: PropTypes.string,
  preferences: preferenceSettings,
});

export const categorizedPreferenceSettings = PropTypes.objectOf(
  categorizedPreferenceSetting
);
