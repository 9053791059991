import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getPage } from 'shared/utils/pagination';
import { Pagination } from 'shared/ui';
import { addOneDay } from 'shared/utils/DateHelper';
import AccountDescriptionText from 'shared/ui/AccountDescriptionText';
import PageNotification from 'shared/ui/NotificationHelper/PageNotification';
import PeriodCostTotal from './PeriodCostTotal/PeriodCostTotal';
import { ProductHistoryObjectArrayType } from '../ProductHistoryPropType';
import ShelfSelector from './ShelfSelector';
import TimeframeSelector from './TimeframeSelector';
import CsvDownload from './CsvDownload/CsvDownload';
import ProductHistoryTable from './ProductHistoryTable/ProductHistoryTable';
import browserHistory from '../../../history';
import './ProductHistoryPage.scss';
import PageHeader from '../../../shared/ui/PageHeader/PageHeader';

class ProductHistoryPage extends Component {
  constructor() {
    super();
    this.state = {
      currentPage: 1,
    };
  }

  componentDidMount() {
    const { user, selectedAccount, fetchAssociatedOrgsAction } = this.props;
    if (!selectedAccount.id) {
      fetchAssociatedOrgsAction((user.userDetails || {}).associatedOrgs || []);
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      endDate,
      fetchAssociatedOrgsAction,
      fetchProductHistory,
      push,
      selectedAccount,
      shelfFilter,
      sortedColumn,
      startDate,
      user,
      viewProductHistory,
    } = this.props;
    const {
      selectedAccount: { id: prevAccountId, branchId: prevBranchId },
      shelfFilter: prevShelfFilter,
      startDate: prevStartDate,
      endDate: prevEndDate,
      user: prevUser,
    } = prevProps;

    if (
      prevAccountId !== selectedAccount.id ||
      prevBranchId !== selectedAccount.branchId ||
      prevShelfFilter !== shelfFilter ||
      prevStartDate !== startDate ||
      prevEndDate !== endDate
    ) {
      const apiQueryString = this.buildApiQueryString(startDate === endDate);
      fetchProductHistory(selectedAccount, apiQueryString);
    }

    if (prevProps.sortedColumn !== sortedColumn) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: 1 });
    }

    if (prevAccountId !== selectedAccount.id) {
      push(`/accounts/product-history?acct=${selectedAccount.id}`);
    }

    if (user.id !== prevUser.id) {
      fetchAssociatedOrgsAction((user.userDetails || {}).associatedOrgs || []);
    }
    if (prevShelfFilter !== shelfFilter) {
      viewProductHistory(shelfFilter, startDate, endDate);
    }
  };

  componentWillUnmount() {
    browserHistory.listen((location, action) => {
      if (action === 'PUSH') {
        sessionStorage.removeItem('productHistoryActiveTimeframe');
        sessionStorage.removeItem('productHistoryActiveShelves');
      }
    });
  }

  buildApiQueryString = (sameDay = false) => {
    const { startDate, endDate, shelfFilter } = this.props;
    let modifiedEndDate = endDate;
    if (sameDay) modifiedEndDate = addOneDay(endDate);
    const baseQueryString = `?start_date=${startDate}&end_date=${modifiedEndDate}`;
    if (!shelfFilter) {
      return baseQueryString;
    }
    return `${baseQueryString}&shelves=${shelfFilter}`;
  };

  handlePaginationClick = (page) => {
    this.setState({ currentPage: page });
  };

  renderTopPortion = () => {
    const { selectedAccount, startDate, endDate, shelfFilter } = this.props;

    const accountDescription = (
      <h1 className="orders-list__account-description">
        <span
          className="orders-list__account-description__text"
          data-hj-suppress=""
        >
          <AccountDescriptionText
            key={selectedAccount.id}
            account={selectedAccount}
          />
        </span>
      </h1>
    );
    return (
      <div>
        <PageHeader
          accountSelector={accountDescription}
          title="Product History"
          breadcrumbs={[
            {
              link: '/',
              label: 'Home',
            },
            {
              label: 'Product History',
            },
          ]}
        />
        <div className="product-history__div-filters-container">
          <TimeframeSelector startDate={startDate} endDate={endDate} />
          <ShelfSelector shelfFilter={shelfFilter} />
        </div>
        <div className="product-history__line-break">
          <hr />
        </div>
      </div>
    );
  };

  render() {
    const {
      endDate,
      pageSize,
      productHistoryList,
      selectedAccount,
      sortedColumn,
      startDate,
      shelfFilter,
    } = this.props;
    const { currentPage } = this.state;

    const paginatedList = getPage(productHistoryList, currentPage, pageSize);
    return (
      <div className="product_history__container">
        {this.renderTopPortion()}
        {productHistoryList.length === 0 ? (
          <div className="product_history__no_content">
            There is no product history to display for this account with the
            given timeframe and shelf filters.
          </div>
        ) : (
          <div>
            <PeriodCostTotal list={productHistoryList} />
            <PageNotification
              message="Total cost does not reflect taxes, discounts, or rebates."
              type="WARNING"
              rightLink=""
            />
            <CsvDownload
              productHistoryList={productHistoryList}
              startDate={startDate}
              endDate={endDate}
              shelf={shelfFilter}
            />
            <ProductHistoryTable
              productHistoryList={paginatedList}
              fullProductHistoryList={productHistoryList}
              sortedColumn={sortedColumn}
              selectedAccount={selectedAccount}
            />
            <Pagination
              current={currentPage}
              total={productHistoryList.length}
              onChange={this.handlePaginationClick}
              pageSize={pageSize}
              className="product-history__pagination"
            />
          </div>
        )}
      </div>
    );
  }
}

ProductHistoryPage.propTypes = {
  fetchAssociatedOrgsAction: PropTypes.func.isRequired,
  productHistoryList: ProductHistoryObjectArrayType.isRequired,
  push: PropTypes.func.isRequired,
  sortedColumn: PropTypes.string,
  pageSize: PropTypes.number,
  shelfFilter: PropTypes.string.isRequired,
  fetchProductHistory: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    branchId: PropTypes.string,
    locationCode: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    userDetails: PropTypes.shape({}),
  }),
  viewProductHistory: PropTypes.func.isRequired,
};

ProductHistoryPage.defaultProps = {
  sortedColumn: undefined,
  pageSize: 24,
  user: {},
};

export default ProductHistoryPage;
