import { ADD_TO_CART_SUCCESS } from '../actionTypes';

/**
 * @param {object} data - the response body from POST /cart-items
 * @returns {{type: string, data: object}}
 */
export default (data) => ({
  type: ADD_TO_CART_SUCCESS,
  data,
});
