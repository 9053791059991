import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import FullPage from 'shared/ui/FullPage';
import { FullPageBackground, bg404 } from 'shared/ui/FullPageBackground';
import greenArrows from 'assets/arrows--light-green.svg';
import arrows from 'assets/arrows--white.svg';
import './Page404.css';

const Page404 = (props) => {
  const previousPage = () => {
    props.history.goBack();
  };

  return (
    <div className="page-404">
      <FullPageBackground image={bg404} />
      <div className="page-404__header-spacer">
        <div className="page-404__header-container">
          <h1 className="page-404__header-content">We can 't find this</h1>
        </div>
        <div className="page-404__header-container">
          <h1 className="page-404__header-content">page right now.</h1>
        </div>
        <div className="page-404__header-container">
          <div
            className="page-404__back-button"
            onClick={previousPage}
            onKeyUp={previousPage}
            role="link"
            tabIndex={0}
          >
            <h2>
              Go Back{' '}
              <img className="page-404__link-arrow" src={arrows} alt="" />
            </h2>
          </div>
        </div>
      </div>
      <div className="page-404__content">
        <FullPage>
          <div className="page-404__content-container">
            <h3 className="page-404__content-title">404: Page Not Found</h3>
            <br />
            <p>This page no longer exists or there was an error in the URL.</p>
            <br />
            <p>
              If this issue persists, please{' '}
              <Link to="/contact">
                contact us{' '}
                <img
                  className="page-404__link-arrow"
                  src={greenArrows}
                  alt=""
                />
              </Link>
            </p>
            <br />
            <hr />
            <br />
            <div className="page-404__bottom-link">
              <Link to="/">
                Go to Digital Hub{' '}
                <img
                  className="page-404__link-arrow"
                  src={greenArrows}
                  alt=""
                />
              </Link>
              <div
                onKeyUp={previousPage}
                onClick={previousPage}
                className="page-404__content-back-button"
                role="button"
                tabIndex={0}
              >
                Go back{' '}
                <img
                  className="page-404__link-arrow"
                  src={greenArrows}
                  alt=""
                />
              </div>
            </div>
          </div>
        </FullPage>
      </div>
    </div>
  );
};

Page404.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default Page404;
