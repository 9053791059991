import { useState } from 'react';

import resolveAuth0Client from 'shared/config/resolveAuth0Client';
import coreAuth from 'shared/lib/coreAuth';

export default function useResendEmailVerification() {
  const [alert, setAlert] = useState(null);

  const clearAlert = () => setAlert(null);

  const resendVerification = async () => {
    const { clientId } = resolveAuth0Client();
    try {
      await coreAuth.post(`v3/resend-verification/${clientId}`);
      setAlert({ severity: 'success', message: 'Verification email sent!' });
    } catch {
      setAlert({ severity: 'error', message: 'Failed to send email' });
    }
  };

  return { alert, clearAlert, resendVerification };
}
