import React from 'react';
import PropTypes from 'prop-types';

const HeaderResponsiveMenuIcon = ({ toggleMenu, isMenuOpen, id, ...rest }) => (
  <div
    className="responsive-menu__icon"
    id={id}
    onClick={toggleMenu}
    onKeyPress={toggleMenu}
    role="menuitem"
    tabIndex="0"
    {...rest}
  >
    <div className="mobile-section__button-container">
      <div className={`responsive-menu__button${isMenuOpen ? ' open' : ''}`}>
        <span />
      </div>
    </div>
  </div>
);

HeaderResponsiveMenuIcon.propTypes = {
  id: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

export default HeaderResponsiveMenuIcon;
