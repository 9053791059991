import React from 'react';
import { PropTypes } from 'prop-types';
import Breadcrumbs from '@nutrien/uet-react/Breadcrumbs';
import Link from '@nutrien/uet-react/Link';
import Typography from '@nutrien/uet-react/Typography';
import { Link as RouterLink } from 'react-router-dom';

import { breadcrumbs as breadcrumbsPropType } from './pageHeaderPropTypes';

import './PageHeader.scss';

const PageHeader = ({ title, breadcrumbs, accountSelector }) => (
  <div className="page-header__container" aria-label="breadcrumb">
    <Breadcrumbs maxItems={4} className="page-header__breadcrumbs">
      {breadcrumbs.map((breadcrumb) =>
        breadcrumb.link ? (
          <Link
            key={`${title}-${breadcrumb.label}`}
            to={breadcrumb.link}
            component={RouterLink}
          >
            {breadcrumb.label}
          </Link>
        ) : (
          <Typography key={`${title}-${breadcrumb.label}`}>
            {breadcrumb.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
    {accountSelector || null}
    {title && (
      <h1
        className={`page-header__title ${
          accountSelector ? 'page-header__margin-top' : ''
        }`}
      >
        {title}
      </h1>
    )}
  </div>
);

PageHeader.defaultProps = {
  accountSelector: null,
  title: null,
};

PageHeader.propTypes = {
  accountSelector: PropTypes.element,
  breadcrumbs: breadcrumbsPropType.isRequired,
  title: PropTypes.string,
};

export default PageHeader;
