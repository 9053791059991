import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import {
  getCustomerOrganizations,
  getAccountNumberFromAccount,
} from '../../selectors';
import * as EmployeePropTypes from '../../../EmployeePropTypes';

const styles = {
  root: {
    color: '#00b455',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    marginRight: '90px',
  },
  primary: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondary: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  accountNumber: { color: '#AFAFAF' },
};

const CustomerInfo = ({ customer, classes, ...props }) => {
  const customerOrganizations = getCustomerOrganizations(customer);

  return (
    <ListItemText
      className="customer-info"
      classes={{
        primary: classes.primary,
        root: classes.root,
        secondary: classes.secondary,
      }}
      primary={
        <span className="customer-name" data-hj-suppress="">
          {get(customer, 'firstName', 'Unknown')}{' '}
          {get(customer, 'lastName', 'Customer')}
        </span>
      }
      secondary={
        <>
          <span className="company-name" data-hj-suppress="">
            {customerOrganizations.length > 0
              ? customerOrganizations.map((org) => org.name).join(', ')
              : 'Unknown Company'}
          </span>
          <span className={classnames(classes.accountNumber, 'account-number')}>
            {customerOrganizations
              ? customerOrganizations
                  .map((org) =>
                    org.accounts.map(getAccountNumberFromAccount).join(', ')
                  )
                  .join(', ')
              : 'Unknown account number'}
          </span>
        </>
      }
      {...props}
    />
  );
};

CustomerInfo.propTypes = {
  customer: EmployeePropTypes.customer, // eslint-disable-line react/no-typos
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

CustomerInfo.defaultProps = {
  customer: {},
};

export default withStyles(styles)(CustomerInfo);
