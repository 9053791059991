import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const NetPricingFeatureFlag = (variant, trailingText, flag = false) => {
  const { net_price, price } = variant; // eslint-disable-line camelcase
  const netPrice = numeral(net_price).format('$0,0.00'); // eslint-disable-line camelcase
  const grossPrice = numeral(price).format('$0,0.00');

  return flag ? (
    <>
      {' '}
      {netPrice}
      {trailingText || null}
    </>
  ) : (
    <>
      {grossPrice}
      {trailingText || null}
    </>
  );
};

NetPricingFeatureFlag.propTypes = {
  variant: PropTypes.shape({}).isRequired,
};

export default NetPricingFeatureFlag;
