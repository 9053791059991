import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = {
  root: {
    textAlign: 'center',
  },
  loading: {},
};

export const MirroringPageComponent = ({
  redirectPath,
  userId,
  error,
  classes,
}) => {
  if (error) return <ApiErrorMessage entityName="Customer" />;
  if (!redirectPath || !userId) {
    return (
      <div
        className={classNames(
          classes.root,
          classes.error,
          'mirroring-page-error'
        )}
      >
        `redirectPath` and `userId` must be present in query string to initiate
        mirroring
      </div>
    );
  }
  return (
    <div className={classNames(classes.root, classes.loading)}>
      <CircularProgress />
      <h1>Starting Mirroring</h1>
    </div>
  );
};

MirroringPageComponent.defaultProps = {
  redirectPath: '/',
  userId: undefined,
  error: undefined,
};

MirroringPageComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  redirectPath: PropTypes.string,
  userId: PropTypes.string,
  error: PropTypes.instanceOf(Error),
};

export default withStyles(styles)(MirroringPageComponent);
