import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import currency from 'shared/utils/numberFormatters/currency';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core';
import Notification from '@nutrien/uet-react/Notification';
import {
  ScheduledPaymentsTable,
  ScheduledPaymentsTableMobile,
} from './ScheduledPaymentsTable';
import {
  RecurringPaymentsTable,
  RecurringPaymentsTableMobile,
} from './RecurringPaymentsTable';
import { STATUS } from './Actions/constants';
import './PaymentsTab.scss';
import {
  InvalidPaymentWarning,
  INVALID_USA_ACCOUNT_TEXT,
  INVALID_CANADIAN_ACCOUNT_TEXT,
  INVALID_INTERNATIONAL_ACCOUNT_TEXT,
} from '../../../shared/ui/Payments/InvalidPaymentWarning';

const useStyles = makeStyles({
  disabledLink: {
    color: '#a0a0a0',
    fontSize: '.875rem',
  },
  rightPositionedLink: {
    color: '#a0a0a0',
    fontSize: '.875rem',
    position: 'absolute',
    right: 0,
  },
});

const PaymentsTab = (props) => {
  const {
    deletedPaymentInfo,
    isCanada,
    accountId,
    getRecurringPayments,
    getScheduledPayments,
    loadingStatus,
    scheduledPayments,
    deleteScheduledPayment,
    recurringPayments,
  } = props;
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);
  const [deleteErrorIsRecurring, setDeleteErrorIsRecurring] = useState(false);
  const { canadaPayments, southernOhioDivision } = useFlags();
  const classes = useStyles();
  const selectedAccount = useSelector((state) => state.selectedAccount);
  // TODO: remove usaSapAccount restrictions once SAP is rolled out to entire US market
  const { usaSapAccount, invalidCanadianAccount, invalidInternationalAccount } =
    selectedAccount;

  const handleSameDayError = (isRecurring, showNotification) => {
    setShowDeleteNotification(showNotification);
    setDeleteErrorIsRecurring(isRecurring);
  };

  React.useEffect(() => {
    if (accountId) {
      getScheduledPayments(accountId);
      getRecurringPayments(accountId);
    }
  }, [accountId, getRecurringPayments, getScheduledPayments]);

  if (loadingStatus) {
    return (
      <div className="account-statements--loading-wrapper">
        <CircularProgress />
      </div>
    );
  }

  const scheduledPaymentLinkDisabled =
    (isCanada && !canadaPayments) ||
    selectedAccount.calculatedCustomerType === 'Fuel' ||
    (usaSapAccount && !southernOhioDivision) ||
    invalidCanadianAccount ||
    invalidInternationalAccount;

  const sameDayDeleteErrorMessage = deleteErrorIsRecurring
    ? 'Cannot delete a recurring payment on the Next Payment date. Please contact your Sales Representative or branch with any questions.'
    : 'Cannot delete a scheduled payment on the scheduled payment date. Please contact your Sales Representative or branch with any questions.';

  return (
    <div className="payments-tab__container">
      <Snackbar
        message={
          deletedPaymentInfo.status === STATUS.SUCCESS
            ? `A ${
                deletedPaymentInfo.isRecurring ? 'recurring' : 'scheduled'
              } payment of ${currency(
                deletedPaymentInfo.amount
              )} has been deleted`
            : `Your ${
                deletedPaymentInfo.isRecurring ? 'recurring' : 'scheduled'
              } payment could not be deleted.`
        }
        open={
          deletedPaymentInfo.status === STATUS.SUCCESS ||
          deletedPaymentInfo.status === STATUS.FAILED
        }
        autoHideDuration={4000}
        onClose={() => props.clearDeleteStatus()}
      />

      <div className="payments-tab__link-container">
        {scheduledPaymentLinkDisabled ? (
          <span className={classes.rightPositionedLink}>
            Manage Payment Types
          </span>
        ) : (
          <Link to="/profile/billing" className="payments-tab__link">
            Manage Payment Types
          </Link>
        )}
      </div>
      {usaSapAccount && !southernOhioDivision && (
        <InvalidPaymentWarning
          warning={INVALID_USA_ACCOUNT_TEXT}
          testId="accounts-card-invalid-usa-account-warning"
        />
      )}
      {invalidCanadianAccount && (
        <InvalidPaymentWarning
          warning={INVALID_CANADIAN_ACCOUNT_TEXT}
          testId="accounts-card-invalid-canadian-account-warning"
        />
      )}
      {invalidInternationalAccount && (
        <InvalidPaymentWarning
          warning={INVALID_INTERNATIONAL_ACCOUNT_TEXT}
          testId="accounts-card-invalid-international-account-warning"
        />
      )}
      {showDeleteNotification && (
        <Notification
          variant="warning"
          className="payments-tab__delete-warning"
        >
          {sameDayDeleteErrorMessage}
        </Notification>
      )}
      <div className="payments-tab__section-title">
        <h4 className="payments-tab__section-header">
          Scheduled Payments Activity
        </h4>
        <div className="payments-tab__section-link">
          {scheduledPaymentLinkDisabled ? (
            <span className={classes.disabledLink}>
              + Add a scheduled payment
            </span>
          ) : (
            <Link to="/make-a-secure-payment" data-test="add-sched-pay">
              + Add a scheduled payment
            </Link>
          )}
        </div>
      </div>
      <ScheduledPaymentsTable
        scheduledPayments={scheduledPayments}
        deleteScheduledPayment={deleteScheduledPayment}
        handleSameDayError={handleSameDayError}
      />
      <ScheduledPaymentsTableMobile
        scheduledPayments={scheduledPayments}
        deleteScheduledPayment={deleteScheduledPayment}
        handleSameDayError={handleSameDayError}
      />
      <div className="payments-tab__section-title">
        <h4 className="payments-tab__section-header">
          Recurring Payments Activity
        </h4>
        <div className="payments-tab__section-link">
          {scheduledPaymentLinkDisabled ? (
            <span className={classes.disabledLink}>
              + Add a recurring payment
            </span>
          ) : (
            <Link to="/make-a-secure-payment" data-test="add-recur-pay">
              + Add a recurring payment
            </Link>
          )}
        </div>
      </div>
      <RecurringPaymentsTable
        recurringPayments={recurringPayments}
        deleteScheduledPayment={deleteScheduledPayment}
        handleSameDayError={handleSameDayError}
      />
      <RecurringPaymentsTableMobile
        recurringPayments={recurringPayments}
        deleteScheduledPayment={deleteScheduledPayment}
        handleSameDayError={handleSameDayError}
      />
    </div>
  );
};

export const RecurringPaymentPropTypes = {
  nextPayment: propTypes.date,
  invoiceId: propTypes.string,
  paymentType: propTypes.string,
  frequency: propTypes.string,
  amount: propTypes.number,
};

PaymentsTab.propTypes = {
  scheduledPayments: propTypes.arrayOf(propTypes.shape({})),
  recurringPayments: propTypes.arrayOf(
    propTypes.shape(RecurringPaymentPropTypes)
  ),
  getScheduledPayments: propTypes.func,
  getRecurringPayments: propTypes.func,
  accountId: propTypes.string,
  loadingStatus: propTypes.bool,
  deleteScheduledPayment: propTypes.func,
  deletedPaymentInfo: propTypes.shape({
    status: propTypes.string,
    amount: propTypes.number,
    isRecurring: propTypes.bool,
  }),
  clearDeleteStatus: propTypes.func,
  isCanada: propTypes.bool,
};

PaymentsTab.defaultProps = {
  accountId: undefined,
  loadingStatus: false,
  scheduledPayments: [],
  recurringPayments: [],
  deletedPaymentInfo: { status: undefined, amount: null, isRecurring: false },
  deleteScheduledPayment: () => {},
  getScheduledPayments: () => {},
  getRecurringPayments: () => {},
  clearDeleteStatus: () => {},
  isCanada: false,
};

export default PaymentsTab;
