// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Card, CardContent, Grid, withStyles } from '@material-ui/core';
import LinkAccountButton from 'components/LinkAccount/LinkAccountButton';

// assets
import AccountsImage from 'assets/Prospect_image_accounts.png';
import AgronomyImage from 'assets/Prospect_image_agronomy.png';
import ProductsImage from 'assets/Prospect_image_products.png';

import OpenForBusiness from '../EcommercePromotion/openForBusiness';
import HubPromotion from '../EcommercePromotion/HubPromotion';
import { CreditBanner } from '../../CreditApplication/CreditBanner';

import styles from './styles';

const ProspectLanding = ({ classes }) => {
  const { openForBusiness, digitalHubPromotion } = useFlags();

  return (
    <>
      <div className={classes.openForBusiness}>
        {openForBusiness && !digitalHubPromotion && <OpenForBusiness />}
        {digitalHubPromotion && <HubPromotion />}
      </div>
      <Card
        className={classes.prospectLandingCard}
        data-test="prospect-card-header"
      >
        <CardContent>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>
              Enjoy these benefits by adding your Nutrien retail account.
            </h3>
          </div>
          <div className={classes.callToActionTop}>
            <div className={classes.addAccountButtonContainer}>
              <LinkAccountButton variant="contained" inlineLink />
            </div>
          </div>
          <Grid container wrap="wrap" spacing={0}>
            <Grid item md={4} xs={12}>
              <div className={classes.helpImageContainer}>
                <img
                  src={AccountsImage}
                  alt="Account Activity"
                  className={classes.helpImage}
                />
              </div>
              <h3 className={classes.columnHeader}>Account Activity</h3>
              <p className={classes.columnSubHeader}>
                Make quick and easy online payments, with the option of viewing
                statements and invoices.
              </p>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className={classes.helpImageContainer}>
                <img
                  src={AgronomyImage}
                  alt="Notifications"
                  className={classes.helpImage}
                />
              </div>
              <h3 className={classes.columnHeader}>Agronomy</h3>
              <p className={classes.columnSubHeader}>
                Create crop plans to inform agronomic decisions that maximize
                field performance.
              </p>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className={classes.helpImageContainer}>
                <img
                  src={ProductsImage}
                  alt="Product Usage"
                  className={classes.helpImage}
                />
              </div>
              <h3 className={classes.columnHeader}>Product Usage</h3>
              <p className={classes.columnSubHeader}>
                Review product purchase history and help take the guesswork out
                of planning decisions.
              </p>
            </Grid>
          </Grid>
          <div className={classes.callToActionBottom}>
            <div>
              <p className={classes.callToAction}>
                Add your Nutrien retail account today!
              </p>
            </div>
            <div className={classes.addAccountButtonContainer}>
              <LinkAccountButton variant="contained" inlineLink />
            </div>
          </div>
        </CardContent>
      </Card>
      <CreditBanner />
    </>
  );
};

ProspectLanding.defaultProps = {
  classes: {},
};

ProspectLanding.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(ProspectLanding);
