// dependencies
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Register from './Register';
import ConfirmRegistration from './ConfirmRegistration';
import Page404 from '../Page404';

const Registration = () => (
  <Switch>
    <Route exact path="/register" component={Register} />
    <Route
      exact
      path="/confirm/:identity/:accessCode"
      component={ConfirmRegistration}
    />
    <Route exact path="/getting-started" component={Register} />
    <Route exact path="/update/:email/:eid" component={Register} />
    <Route component={Page404} />
  </Switch>
);

export default Registration;
