import {
  GET_PREFERENCES_FAILURE,
  GET_PREFERENCES_SUCCESS,
  LOADING_PREFERENCES,
} from '../actions/constants';

export default (state = false, action) => {
  switch (action.type) {
    case LOADING_PREFERENCES:
      return true;
    case GET_PREFERENCES_SUCCESS:
    case GET_PREFERENCES_FAILURE:
      return false;
    default:
      return state;
  }
};
