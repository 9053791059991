import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Select } from 'nutrien-common-components-react/dist/mdc/Select';
import get from 'lodash/get';
import { useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageNotification from 'shared/ui/NotificationHelper/PageNotification';

import AccountDescriptionText from 'shared/ui/AccountDescriptionText';
import { Pagination, PaginationNext, PaginationPrev } from 'shared/ui';
import { getPage } from 'shared/utils/pagination';
import MaterialLoadingIcon from 'shared/ui/RebrandedMaterialLoadingIconInline/MaterialLoadingIcon';
import PageHeader from '../../../../shared/ui/PageHeader/PageHeader';
import OrdersTable from './OrdersTable';
import { countOrderStatuses, filterOrders } from './ordersLandingUtils';

import ApiErrorMessage from '../../../../shared/ui/ApiErrorMessage';
import { ordersActions } from '../../actions/actions';

import './OrdersLanding.scss';

const defaultLoadingFragment = () => (
  <div
    className="orders-list__loading-wrapper"
    data-test="orders-loading-wrapper"
  >
    <MaterialLoadingIcon />
  </div>
);

const errorFragment = () => (
  <>
    <ApiErrorMessage
      className="online-orders__error"
      entityName="Online Orders"
      data-test="orders-load-error"
    />
  </>
);

const usSapDisclaimerMessage =
  'While Online Orders is unavailable, please contact your Crop Consultant or local branch for information on product pricing and availability.';

const usSapDisclaimerFragment = () => (
  <PageNotification
    message={usSapDisclaimerMessage}
    key="us-sap-disabled-key"
    type="WARNING"
    linkDescription="Return Home"
    link="/"
    data-test="us-sap-disabled-message"
  />
);

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return {
    width,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(() =>
    getWindowDimensions()
  );

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const paginationFragment = (inputs) => {
  const { currentPage, displayLength, handlePaginationClick, pageSize, width } =
    inputs;

  return (
    <div className="nutrien-orders-pagination-container">
      {width > 768 ? (
        <Pagination
          className="nutrien-orders-desktop-pagination"
          current={currentPage}
          total={displayLength}
          onChange={handlePaginationClick}
          pageSize={pageSize}
        />
      ) : (
        <Pagination
          className="pagination__mobile"
          current={currentPage}
          total={displayLength}
          onChange={handlePaginationClick}
          pageSize={pageSize}
          prevIcon={PaginationPrev}
          nextIcon={PaginationNext}
          simple
        />
      )}
    </div>
  );
};

const OrdersLanding = ({ pageSize }) => {
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const orders = useSelector((state) => state.orders.orders);
  const error = useSelector((state) => state.orders.error);
  const loading = useSelector((state) => state.orders.loading);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { usaSapOrders, westernRegionRestrictProducts } = useFlags();

  const [state, setState] = useState({
    currentPage: 1,
    orderStatusFilter: 'All',
  });
  const dispatch = useDispatch();
  const { currentPage, orderStatusFilter } = state;

  const resetPage = () => {
    if (state.currentPage !== 1) {
      setState({ currentPage: 1 });
    }
  };

  const handlePaginationClick = (page) => {
    setState({ ...state, currentPage: page });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const orderFilters = {
      startDate: '1/1/2010',
      endDate: '12/31/2100',
      ordersLimit: '999',
      sortField: '-orderDate',
    };
    const accountNumber = get(selectedAccount, 'id', '');

    dispatch(ordersActions.getOrders(accountNumber, orderFilters));
    resetPage();
  }, [selectedAccount]);

  if (error) {
    return errorFragment();
  }

  if (loading) {
    return defaultLoadingFragment();
  }

  const handleOrderStatusChange = (e) => {
    setState({ ...state, orderStatusFilter: e.target.value });
  };

  const filteredOrders = filterOrders(orders, orderStatusFilter).sort(
    (a, b) => b.id - a.id
  );
  const hasFilteredOrders = orderStatusFilter.toLowerCase() !== 'all';
  const displayList = hasFilteredOrders ? filteredOrders : orders;
  const displayLength = displayList.length;

  const paginatedList = getPage(displayList, currentPage, pageSize);

  if (paginatedList && paginatedList.length === 0) {
    resetPage();
  }
  const inputs = {
    currentPage,
    displayLength,
    handlePaginationClick,
    pageSize,
    width,
  };

  const accountDescription = (
    <h1 className="orders-list__account-description">
      <span
        className="orders-list__account-description__text"
        data-hj-suppress=""
      >
        <AccountDescriptionText
          key={selectedAccount.id}
          account={selectedAccount}
        />
      </span>
    </h1>
  );

  const ordersFragment = () => (
    <>
      <div className="nutrien-orders__table-details">
        {orders.length ? (
          <div className="order__status-filter-container">
            <Select
              label="Order Status"
              value={orderStatusFilter}
              onChange={(e) => handleOrderStatusChange(e)}
              className="order__status-filter"
            >
              <option value="All">{`All (${orders.length})`}</option>
              <option value="Submitted">
                {`Submitted (${countOrderStatuses(orders, 'submitted')})`}
              </option>
              <option value="Invoiced">
                {`Invoiced (${countOrderStatuses(orders, 'invoiced')})`}
              </option>
              <option value="Confirmed">
                {`Confirmed (${countOrderStatuses(orders, 'confirmed')})`}
              </option>
              <option value="Cancelled">
                {`Cancelled (${countOrderStatuses(orders, 'cancelled')})`}
              </option>
              <option value="Fulfilled">
                {`Fulfilled (${countOrderStatuses(orders, 'fulfilled')})`}
              </option>
            </Select>
          </div>
        ) : null}
      </div>
      <OrdersTable
        orders={orders}
        hasFilteredOrders={hasFilteredOrders}
        filteredOrders={paginatedList}
        selectedAccount={selectedAccount}
        history={history}
      />
      {paginationFragment(inputs)}
    </>
  );

  return (
    <>
      <PageHeader
        accountSelector={accountDescription}
        title="Online Orders"
        breadcrumbs={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'Online Orders',
          },
        ]}
      />
      {(usaSapOrders && selectedAccount.usaSapAccount) ||
      westernRegionRestrictProducts
        ? usSapDisclaimerFragment()
        : ordersFragment()}
    </>
  );
};

OrdersLanding.propTypes = {
  pageSize: PropTypes.number,
};

OrdersLanding.defaultProps = {
  pageSize: 12,
};

export default OrdersLanding;
