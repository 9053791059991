import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { verifySAPAccount } from '../../../../utils/verifySAP';
import ThankYouPageARS from '../../components/ThankYouPage/ThankYouPageARS';
import ThankYouPageSAP from '../../components/ThankYouPage/ThankYouPageSAP';

class ThankYouPage extends PureComponent {
  render() {
    const selectedAccountNumber = get(this.props, 'selectedAccount.number', '');
    const isSAP = verifySAPAccount(selectedAccountNumber);

    return isSAP ? (
      <ThankYouPageSAP {...this.props} />
    ) : (
      <ThankYouPageARS {...this.props} />
    );
  }
}

export { ThankYouPage };

const mapStateToProps = ({ selectedAccount, cart }) => ({
  selectedAccount,
  cart,
});

export default connect(mapStateToProps)(ThankYouPage);
