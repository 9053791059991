import { GTM_ACCOUNT_SELECTION } from '../gtmConstants';

export const accountSelection = (action) => ({
  event: 'ACCOUNT_SELECTION',
  cxh: {
    accountSelectionLocation: action.payload.origin,
  },
});

export const accountSelectionMap = {
  [GTM_ACCOUNT_SELECTION]: accountSelection,
};
