/**
 * Grabs the google tag manager container for the current property off the window
 * This container houses most of the gtm configuration, as well as gives
 *  us access to dataLayer methods.
 *
 * Will return null if a container cannot be found.
 *
 * Possible causes for null container:
 *  - user is using adblocker
 *  - this is being run in a context where window is not defined (e.g. testing?)
 */
export const getGtmContainer = () => {
  const { GTM_CONTAINER_ID, google_tag_manager: gtmConfig } = window || {};

  if (!GTM_CONTAINER_ID || !gtmConfig) {
    return null;
  }

  return gtmConfig[GTM_CONTAINER_ID];
};

export default getGtmContainer;
