const domainIsPermitted = (email) => {
  const emailDomain = email.split('@')[1].toLowerCase();
  if (
    emailDomain === 'nutrien.com' ||
    emailDomain === 'cpsagu.com' ||
    emailDomain === 'agrium.com'
  ) {
    return false;
  }
  return true;
};

export default domainIsPermitted;
