const HOME = { label: 'Home', link: '/' };
const MAKE_A_PAYMENT = {
  label: 'Make a Secure Payment',
};
const ADD_BANK_ACCOUNT = {
  label: 'Add a Bank Account',
};
const PRE_PAY = {
  label: 'Add Funds to Pre-Pay Balance',
};

export const ROUTES_MAP = {
  MAKE_A_PAYMENT_ROUTES: [HOME, MAKE_A_PAYMENT],
  ADD_BANK_ACCOUNT_ROUTES: [HOME, ADD_BANK_ACCOUNT],
  PRE_PAY_ROUTES: [HOME, PRE_PAY],
};
