import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { FETCHING_USER, USER_FETCHED } from 'shared/config/userConstants';
import withUser from 'components/User/withUser';
import NotificationsPreferences from './NotificationsPreferences';
import { updateNotificationPreferences } from './actions';
import {
  notificationPreference as notificationPreferencePropType,
  notificationPreferences as notificationPreferencesPropType,
  categorizedPreferences as categorizedPreferencesPropType,
  notificationsPreferencesStatus as notificationsPreferencesStatusPropType,
} from './notificationsPreferencesPropTypes';
import {
  getNotificationPreferencesStatus,
  getUserEmails,
  getCategorizedPreferences,
  getUserEmailPreferences,
  getUserPhonePreferences,
  getUserPreferences,
} from './selectors';

export class NotificationsPreferencesContainer extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    categorizedPreferences: categorizedPreferencesPropType.isRequired,
    userPreferences: notificationPreferencesPropType.isRequired,
    updateNotificationPreferences: PropTypes.func,
    userStatus: PropTypes.string.isRequired, // provided by `withUser`
    emails: PropTypes.arrayOf(notificationPreferencePropType).isRequired,
    phonePreferences: PropTypes.objectOf(PropTypes.bool).isRequired,
    emailPreferences: PropTypes.objectOf(PropTypes.bool).isRequired,
    notificationsPreferencesStatus:
      notificationsPreferencesStatusPropType.isRequired,
    flags: PropTypes.shape({
      usaSapRestrictOrdersNotifications: PropTypes.bool,
    }).isRequired,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    updateNotificationPreferences: () => {},
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    emailPreferenceChanges: {},
    phonePreferenceChanges: {},
    isPhoneModalOpen: false,
    preferredPhoneNumber: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      emailPreferences,
      phonePreferences,
      notificationsPreferencesStatus,
    } = this.props;
    const {
      emailPreferenceChanges,
      phonePreferenceChanges,
      preferredPhoneNumber,
    } = this.state;

    // Check the relevant application state and stop if nothing has changed.
    if (
      prevState.emailPreferenceChanges === emailPreferenceChanges &&
      prevState.phonePreferenceChanges === phonePreferenceChanges &&
      prevProps.notificationsPreferencesStatus.isUpdating ===
        notificationsPreferencesStatus.isUpdating
    ) {
      return;
    }

    const doChangesDiffer =
      this.doChangesDifferFromPrefs(emailPreferences, emailPreferenceChanges) ||
      this.doChangesDifferFromPrefs(phonePreferences, phonePreferenceChanges) ||
      prevState.preferredPhoneNumber !== preferredPhoneNumber;

    if (
      !notificationsPreferencesStatus.isUpdating &&
      doChangesDiffer &&
      !notificationsPreferencesStatus.error
    ) {
      this.commitPreferenceChangesDebounced();
    }
  }

  doChangesDifferFromPrefs = (prefs, changes) =>
    Object.keys(changes).length &&
    Object.keys(changes).reduce(
      (acc, key) => acc === true || !prefs[key] === changes[key],
      false
    );

  handlePreferredPhoneNumberSubmitSuccess = () => {
    this.setState((prevState) => ({
      isPhoneModalOpen: false,
      phonePreferenceChanges: {
        ...prevState.phonePreferenceChanges,
        ...prevState.phonePreferenceChangesPendingPhoneNumberUpdate,
      },
    }));

    this.setState(() => ({
      phonePreferenceChangesPendingPhoneNumberUpdate: {},
    }));
  };

  handlePreferredPhoneNumberSubmitError = () => {
    this.setState({
      isPhoneModalOpen: false,
      phonePreferenceChangesPendingPhoneNumberUpdate: {},
    });
  };

  togglePhoneModal = (isOpen) => {
    this.setState({
      isPhoneModalOpen: isOpen,
    });
  };

  /**
   * Updates the form state with the updated value from a single preference toggle button.
   * @param  {string} contactMethodType The method type: email or phone.
   * @param  {string} formKey           ex. ACCOUNT-SOME_THING
   * @param  {bool} value               whether the pref is on or off.
   * @return {undefined}
   */
  handlePreferenceChange = (contactMethodType, formKey, value) => {
    if (contactMethodType === 'phone') {
      // Activate the phone modal every time a text toggle is set to true
      if (value) {
        this.togglePhoneModal(true);
        this.setState({
          phonePreferenceChangesPendingPhoneNumberUpdate: {
            // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
            ...this.state.phonePreferenceChangesPendingPhoneNumberUpdate,
            [formKey]: value,
          },
        });
        return;
      }

      this.setState({
        phonePreferenceChanges: {
          // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
          ...this.state.phonePreferenceChanges,
          [formKey]: value,
        },
      });
    }

    if (contactMethodType === 'email') {
      this.setState({
        emailPreferenceChanges: {
          // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
          ...this.state.emailPreferenceChanges,
          [formKey]: value,
        },
      });
    }
  };

  /**
   * Fires action that updates the user's preferences on the server.
   * @return {Promise} The state of the update.
   */
  commitPreferenceChanges = () => {
    const { userPreferences, updateNotificationPreferences } = this.props; // eslint-disable-line no-shadow
    const {
      emailPreferenceChanges,
      phonePreferenceChanges,
      preferredPhoneNumber,
    } = this.state;

    updateNotificationPreferences(
      userPreferences,
      emailPreferenceChanges,
      phonePreferenceChanges,
      preferredPhoneNumber
    ).catch(() => {
      this.setState(() => ({
        emailPreferenceChanges: {},
        phonePreferenceChanges: {},
      }));
    });
  };

  // eslint-disable-next-line react/sort-comp
  commitPreferenceChangesDebounced = debounce(
    this.commitPreferenceChanges,
    2000
  );

  render() {
    const {
      categorizedPreferences,
      emailPreferences,
      phonePreferences,
      notificationsPreferencesStatus,
      userStatus, // notification prefs are acquired when user is acquired.
      emails,
      flags: { usaSapRestrictOrdersNotifications },
    } = this.props;
    const { emailPreferenceChanges, phonePreferenceChanges, isPhoneModalOpen } =
      this.state;
    const allPreferencesExceptOrders = () => {
      const { Order, ...rest } = categorizedPreferences;
      return rest;
    };

    return (
      <NotificationsPreferences
        isFetching={userStatus === FETCHING_USER}
        errorFetching={userStatus !== USER_FETCHED}
        categorizedPreferences={
          usaSapRestrictOrdersNotifications
            ? allPreferencesExceptOrders()
            : categorizedPreferences
        }
        onPreferenceChange={this.handlePreferenceChange}
        emails={emails}
        emailPreferences={emailPreferences}
        phonePreferences={phonePreferences}
        emailPreferenceChanges={emailPreferenceChanges}
        phonePreferenceChanges={phonePreferenceChanges}
        updateError={notificationsPreferencesStatus.updateError}
        isPhoneModalOpen={isPhoneModalOpen}
        togglePhoneModal={this.togglePhoneModal}
        handlePreferredPhoneNumberSubmitSuccess={
          this.handlePreferredPhoneNumberSubmitSuccess
        }
        handlePreferredPhoneNumberSubmitError={
          this.handlePreferredPhoneNumberSubmitError
        }
      />
    );
  }
}

export const mapStateToProps = (state) => ({
  emails: getUserEmails(state),
  userPreferences: getUserPreferences(state),
  emailPreferences: getUserEmailPreferences(state),
  phonePreferences: getUserPhonePreferences(state),
  notificationsPreferencesStatus: getNotificationPreferencesStatus(state),
  categorizedPreferences: getCategorizedPreferences(state),
});

export const mapDispatchToProps = {
  updateNotificationPreferences,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(withUser(NotificationsPreferencesContainer));
