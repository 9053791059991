import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import PageHeader from 'shared/ui/PageHeader/PageHeader';
import ContactForm from './ContactForm';
import OldContact from './OldContact';

import styles from './styles';

const contactForm = () => <ContactForm />;

const oldContact = () => <OldContact />;

const Contact = (props) => {
  const { classes } = props;
  const { feedbackPage } = useFlags();

  return (
    <>
      <PageHeader
        title="Contact Us"
        breadcrumbs={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'Contact Us',
          },
        ]}
      />
      <div className={classes.container}>
        {feedbackPage ? contactForm(classes) : oldContact()}
      </div>
    </>
  );
};

Contact.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Contact);
