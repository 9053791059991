import { LICENSES_FETCH_SUCCESS } from '../actions';

const licenses = (state = [], action) => {
  switch (action.type) {
    case LICENSES_FETCH_SUCCESS:
      return action.licenses;
    default:
      return state;
  }
};

export default licenses;
