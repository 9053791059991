/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, IconButton } from '@material-ui/core';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import { Dialog } from 'shared/ui/MaterialDialog';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import styles from './ModalComponentStyles';

// TODO: Delete this in favor of wrapper in UET
const ModalComponent = (props) => {
  const {
    open,
    classes,
    close,
    titleText,
    bodyText1,
    bodyText2,
    image,
  } = props;

  return (
    <div className="DeleteFailedDialog">
      <Dialog open={open} maxWidth="md">
        <div className={classes.dialog}>
          <IconButton
            onClick={() => {
              close();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                close();
              }
            }}
            tabIndex={0}
            className={classes.closeButton}
          >
            <Icon
              data-test="modal-component-close-icon"
              icon="close"
              type="light-gray"
            />
          </IconButton>
          <img className={classes.warningIcon} src={image} alt="" />
          <div className={classes.titleText}>{titleText}</div>
          <div className={classes.bodyText}>{bodyText1}</div>
          <div className={classes.bodyText}>{bodyText2}</div>
          <MaterialButton
            className={classes.okButton}
            onClick={close}
            value="OK"
          />
        </div>
      </Dialog>
    </div>
  );
};

ModalComponent.propTypes = {
  classes: PropTypes.shape({}),
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  bodyText1: PropTypes.string,
  bodyText2: PropTypes.string,
  image: PropTypes.string.isRequired,
};

ModalComponent.defaultProps = {
  classes: {},
  titleText: null,
  bodyText1: null,
  bodyText2: null,
};

export default withStyles(styles)(ModalComponent);
