import { parse as parseURL } from 'url';
import resolveApiEnv, {
  DEV,
  SIT,
  PRE,
  PROD,
} from 'shared/config/resolveApiEnv';
import resolveApiUrl from 'shared/config/resolveApiUrl';

/**
 * Returns resolveIntercomeKey for env
 * @return {string} intercomKey;
 */
const resolveIntercomKey = () => {
  const apiUrl = resolveApiUrl();
  const host = parseURL(apiUrl).hostname;
  const env = resolveApiEnv(host);
  const keys = {
    [DEV]: 'rdclt3af',
    [SIT]: 'rdclt3af',
    [PRE]: 'rdclt3af',
    [PROD]: 'rdclt3af',
  };
  return keys[env];
};

export default resolveIntercomKey;
