import { createSelector } from 'reselect';
import find from 'lodash/find';
import { CREDIT_APPLICATION_STATUSES } from 'const/creditApplicationStatuses';

const isApproved = (status) => {
  return (
    status === CREDIT_APPLICATION_STATUSES.APPROVED_PENDING_VALIDATION ||
    status === CREDIT_APPLICATION_STATUSES.APPROVED_VALIDATED
  );
};

export const selectCreditStatus = () =>
  createSelector([(state) => state.credit.status], (status) => status);

export const selectApprovedCreditApplication = (creditApplicationId) =>
  createSelector(
    [(state) => state.credit.creditApplications],
    (creditApplications) => {
      return find(creditApplications, (creditApp) => {
        return (
          creditApp.creditApplicationId === creditApplicationId &&
          isApproved(creditApp.status)
        );
      });
    }
  );
