import {
  EMPLOYEE_LOGGED_IN,
  EMPLOYEE_LOGGING_IN,
  EMPLOYEE_LOGIN_FAILURE,
} from '../actions';

export default (state = false, action) => {
  switch (action.type) {
    case EMPLOYEE_LOGGING_IN:
      return true;
    case EMPLOYEE_LOGGED_IN:
    case EMPLOYEE_LOGIN_FAILURE:
      return false;
    default:
      return state;
  }
};
