const buttonLineHeight = '1.5em';
const styles = (theme) => ({
  button: {
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: 0,
    justifyContent: 'flex-end',
    fontWeight: 'normal',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: 'white',
      fontWeight: 'normal',
    },
    height: '48px',
  },
  notificationsMenu: {
    borderRadius: 0,
    maxWidth: '400px',
  },
  notificationsMenuHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
  },
  notificationsMenuFooter: {
    marginTop: 8,
  },
  notificationsMenuTitle: {
    fontSize: '1em',
    lineHeight: buttonLineHeight,
    padding: 8,
    paddingLeft: 16,
    marginBottom: 0,
    marginBlockEnd: 0,
  },
  notificationsMenuPreferences: {
    fontSize: '0.9em',
    color: theme.palette.primary.main,
    textAlign: 'right',
    lineHeight: buttonLineHeight,
    paddingRight: 16,
  },
  viewAllNavigateNextIcon: {
    fontSize: '1.5em',
    lineHeight: buttonLineHeight,
  },
});

export default styles;
