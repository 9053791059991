import React from 'react';
import { Icon } from 'nutrien-common-components-react/dist/mdc';

const MissingLabelWarning = () => (
  <div
    className="product-info__product-label-missing"
    data-test="product-info-product-label-missing"
  >
    <Icon
      icon="Warning"
      type="warning"
      className="product-info__product-label-missing-icon"
      data-test="product-label-icon-warning"
    />
    <span>
      Labels, SDS, and License information are currently unavailable. Contact
      your consultant for more details.
    </span>
  </div>
);

export default MissingLabelWarning;
