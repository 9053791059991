// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import FormCard from 'shared/ui/MaterialFormCard';
import Form from 'shared/ui/MaterialFormCard/Form';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';

import LoginForm from '../LoginForm';
import '../Login.scss';

const LoginV2 = (props) => {
  const { push } = props;

  const loginContent =
    props.fetchingUser === 'FETCHING_USER' ? (
      <MaterialLoadingIcon />
    ) : (
      <LoginForm title="Returning Customer" location={props.location} />
    );

  return (
    <FormCard className="login__two-panel-form-card">
      <h3>Welcome</h3>
      <hr />
      <div className="login__content-container">
        <div className="login__content-container-section">{loginContent}</div>
        <div className="login__content-container-desktop-hr" />
        <hr className="login__content-container-mobile-hr" />
        <div className="login__content-container-section">
          <Form>
            <div className="login__content-container-section-title">
              First visit?
            </div>
            <p className="login__content-container-section-text">
              Enroll online to get started today.
            </p>
            <MaterialButton
              onClick={() => push('/register')}
              id="sign-up-button"
              className="button--long"
              value="Enroll Today"
              type="secondary"
            />
          </Form>
        </div>
      </div>
    </FormCard>
  );
};

LoginV2.propTypes = {
  push: PropTypes.func.isRequired,
  fetchingUser: PropTypes.string.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default LoginV2;
