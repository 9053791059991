import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

import { checkPasswordStrength } from 'shared/utils/formValidation';

import styles from './styles';

const strengthIcon = (passwordScore, classes) => (
  <div
    className={classNames(classes.strengthIcon, {
      [classes.weak]: passwordScore < 2,
      [classes.strong]: passwordScore >= 2,
    })}
  >
    {(passwordScore < 2 && 'WEAK') ||
      (passwordScore === 2 && 'GOOD') ||
      (passwordScore > 2 && 'STRONG')}
  </div>
);

const PasswordStrength = ({ password, classes }) => {
  const passwordScore = checkPasswordStrength(password).score;
  const dynamicClass = passwordScore >= 2 ? classes.strong : classes.weak;

  return (
    <div className={classes.container}>
      <p className={classes.titleText}>Password Strength</p>
      <div className={classes.barContainer}>
        <div
          className={classNames(classes.strengthBar, {
            [dynamicClass]: password !== '' && passwordScore >= 0,
            [classes.grey]: password === '' || passwordScore <= 0,
          })}
        />
        <div
          className={classNames(classes.strengthBar, {
            [dynamicClass]: passwordScore >= 1,
            [classes.grey]: passwordScore < 1,
          })}
        />
        <div
          className={classNames(classes.strengthBar, {
            [dynamicClass]: passwordScore >= 2,
            [classes.grey]: passwordScore < 2,
          })}
        />
        <div
          className={classNames(classes.strengthBar, {
            [dynamicClass]: passwordScore >= 3,
            [classes.grey]: passwordScore < 3,
          })}
        />
        <div
          className={classNames(classes.strengthBar, {
            [dynamicClass]: passwordScore >= 4,
            [classes.grey]: passwordScore < 4,
          })}
        />
      </div>
      {password !== '' && strengthIcon(passwordScore, classes)}
    </div>
  );
};

PasswordStrength.defaultProps = {
  password: '',
  criteria: {},
};

PasswordStrength.propTypes = {
  password: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(PasswordStrength);
