import {
  CLEAR_CUSTOMERS,
  CUSTOMERS_FETCHING,
  CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_FETCH_SUCCESS,
} from '../actions';

export default (state = [], action) => {
  switch (action.type) {
    case CUSTOMERS_FETCH_SUCCESS:
      return action.payload.customers;
    case CLEAR_CUSTOMERS:
    case CUSTOMERS_FETCH_FAILURE:
    case CUSTOMERS_FETCHING:
      return [];
    default:
      return state;
  }
};
