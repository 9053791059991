import all from './allPermits';

export default (state, permitId, growerName) => {
  const permits = all(state);

  if (!permits) {
    return undefined;
  }

  return permits.find(
    (permit) => permit.permitId === permitId && permit.growerName === growerName
  );
};
