import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Error from '@material-ui/icons/Error';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import warningImage from 'assets/warning_red.svg';
import PaymentProcessingOverlay from 'components/Payments/OtherComponents/PaymentProcessingOverlay/PaymentProcessingOverlay';
import { overPaymentAccountLimit } from 'components/Payments/Utils/PaymentUtil';
import ModalComponent from 'components/Payments/OtherComponents/ModalComponent/ModalComponent';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import { UPDATE_BANK_ACCOUNT_ALIAS_FAILURE } from 'components/Payments/PaymentConstants';
import NoPaymentAccountsComponent from './NoPaymentAccounts/NoPaymentAccountsComponent';
import PaymentMethodRows from './PaymentMethodRows/PaymentMethodRows';
import { COUNTRY_CODES } from '../../../../Payments/PaymentConstants';

import './managePaymentMethods.css';

const isUserReceived = (userState) =>
  userState &&
  userState.userDetails &&
  userState.userDetails.associatedOrgs &&
  userState.userDetails.associatedOrgs.length;

class ManagePaymentMethods extends Component {
  state = {
    errorDialogOpen: false,
    filteredPaymentMethods: null,
  };

  componentDidMount() {
    const {
      user,
      getPaymentAccounts,
      getAccounts,
      clearDeleteResponse,
    } = this.props;

    if (isUserReceived(user)) {
      getAccounts();
      getPaymentAccounts();
    }

    clearDeleteResponse();

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const shouldShowErrorDialog =
      (newProps.deleteTokenResponse &&
        newProps.deleteTokenResponse !== this.props.deleteTokenResponse &&
        newProps.deleteTokenResponse !== 200) ||
      (newProps.updateAliasStatus &&
        newProps.updateAliasStatus !== this.props.updateAliasStatus &&
        newProps.updateAliasStatus === UPDATE_BANK_ACCOUNT_ALIAS_FAILURE);

    if (shouldShowErrorDialog) {
      this.setState({ errorDialogOpen: true });
    }

    const shouldFilterAccounts =
      (newProps.hasMultipleCountries &&
        newProps.paymentAccounts.length !== 0 &&
        !this.state.filteredPaymentMethods) ||
      newProps.paymentAccounts !== this.props.paymentAccounts;

    if (shouldFilterAccounts) {
      this.filterAccounts(newProps.paymentAccounts);
    }
  }

  componentDidUpdate(prevProps) {
    if (isUserReceived(this.props.user) && this.props.user !== prevProps.user) {
      this.props.getPaymentAccounts();
      this.props.getAccounts();
    }
  }

  closeDialog = () => {
    this.props.clearDeleteResponse();
    this.props.clearUpdateAliasStatus();
    this.setState({
      errorDialogOpen: false,
    });
  };

  filterAccounts(paymentAccounts) {
    const usAccounts = paymentAccounts.filter(
      (acc) => acc.accountCountryCode === COUNTRY_CODES.USA
    );
    const canAccounts = paymentAccounts.filter(
      (acc) => acc.accountCountryCode === COUNTRY_CODES.CANADA
    );

    this.setState({ filteredPaymentMethods: { usAccounts, canAccounts } });
  }

  navigateToAddBankAccount = () => {
    const { push, updatePaymentForm } = this.props;

    updatePaymentForm({ cpsAccount: null });
    push({
      pathname: '/add-bank-account',
    });
  };

  renderOver15PaymentMethodsError = () => (
    <div className="over-max-payment-methods">
      <Error className="errorIcon" />
      You have reached the limit of 15 saved accounts. Delete at least one of
      the stored bank accounts in order to add a new one.
    </div>
  );

  renderHeader = () => {
    const { paymentAccounts } = this.props;
    const hasOver15PaymentMethods =
      paymentAccounts.length && paymentAccounts.length >= 15;

    return (
      <div className="payment-methods-header">
        <div className="payment-methods-header-title">
          Manage Payment Methods
        </div>

        {overPaymentAccountLimit(paymentAccounts) &&
          this.renderOver15PaymentMethodsError()}

        <div className="payment-method-header-buttons">
          <button
            className="add-bank-account-button"
            disabled={hasOver15PaymentMethods}
            onClick={this.navigateToAddBankAccount}
            style={{ backgroundColor: 'white' }}
          >
            <AddCircleIcon className="add-bank-icon" />
            Add Bank Account
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      push,
      paymentAccounts,
      updatePaymentForm,
      deletePayment,
      shouldDisplaySpinner,
      updatePaymentById,
      hasMultipleCountries,
    } = this.props;
    const hasPaymentMethods = paymentAccounts && paymentAccounts.length > 0;

    if (shouldDisplaySpinner) {
      return <PaymentProcessingOverlay processingText="Loading..." />;
    }

    return (
      <div>
        <ModalComponent
          open={this.state.errorDialogOpen}
          close={this.closeDialog}
          titleText="Oops, Something went wrong."
          bodyText1="Sorry, we’re having a technical issue."
          bodyText2="Try to refresh the page or try again later."
          image={warningImage}
        />

        {hasPaymentMethods ? (
          <div className="payment-methods-container">
            {this.renderHeader()}

            <hr className="payment-methods-separator" />

            <PaymentMethodRows
              paymentAccounts={
                hasMultipleCountries
                  ? this.state.filteredPaymentMethods
                  : paymentAccounts
              }
              hasMultipleCountries={hasMultipleCountries}
              deletePayment={deletePayment}
              updatePaymentById={updatePaymentById}
            />
          </div>
        ) : (
          <div className="payment-methods-container">
            {this.renderHeader()}

            <NoPaymentAccountsComponent
              updatePaymentForm={updatePaymentForm}
              push={push}
            />
          </div>
        )}
      </div>
    );
  }
}

ManagePaymentMethods.propTypes = {
  getAccounts: PropTypes.func.isRequired,
  hasMultipleCountries: PropTypes.bool.isRequired,
  updatePaymentById: PropTypes.func.isRequired,
  deletePayment: PropTypes.func.isRequired,
  getPaymentAccounts: PropTypes.func.isRequired,
  clearDeleteResponse: PropTypes.func.isRequired,
  shouldDisplaySpinner: PropTypes.bool,
  deleteTokenResponse: PropTypes.number,
  updateAliasStatus: PropTypes.string,
  clearUpdateAliasStatus: PropTypes.func.isRequired,
  paymentAccounts: PaymentPropTypes.paymentAccounts,
  user: PaymentPropTypes.user,
  push: PropTypes.func.isRequired,
  updatePaymentForm: PropTypes.func.isRequired,
};

ManagePaymentMethods.defaultProps = {
  paymentAccounts: [],
  user: undefined,
  deleteTokenResponse: null,
  shouldDisplaySpinner: null,
  updateAliasStatus: null,
};

export default ManagePaymentMethods;
