// dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// shared UI
import Form from 'shared/ui/MaterialFormCard/Form';
import PasswordChecker from 'shared/ui/PasswordChecker';
import Helper from 'shared/ui/MaterialFormCard/FormHelper';
import HelperContent from 'shared/ui/PasswordChecker/HelperContent';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import withTerms from 'components/Terms/withTerms';

import {
  validatePassword,
  checkBreachedPassword,
} from 'shared/utils/formValidation';

import styles from 'components/Registration/Register/styles';
import {
  RolePropType,
  RbacRegistrationFormPropType,
} from 'components/Roles/Redux/RoleDelegation/roleDelegationPropTypes';

export class SetPassword extends Component {
  state = {
    passwordFormSubmitted: false,
    password: '',
    passwordConfirm: '',
    showPassword: false,
    showPasswordConfirm: false,
    isPasswordSafe: false,
    modal: false,
  };

  toggleShowPassword = (field) => {
    if (field === 'password') {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    } else if (field === 'passwordConfirm') {
      this.setState((prevState) => ({
        showPasswordConfirm: !prevState.showPasswordConfirm,
      }));
    }
  };

  toggleIsPasswordSafe = (isPasswordSafe) => {
    this.setState({ isPasswordSafe });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handleChange = async (e) => {
    this.props.clearErrors();

    const { onChange } = this.props;
    const { name, value } = e.target;

    if (name === 'password') {
      this.setState({
        password: value,
      });
      if (validatePassword(value)) {
        const isPasswordSafe = await checkBreachedPassword(value);
        this.toggleIsPasswordSafe(isPasswordSafe);
      } else {
        this.toggleIsPasswordSafe(false);
      }
    }

    if (name === 'passwordConfirm') {
      this.setState({
        passwordConfirm: value,
      });
    }

    const trimmedInput = {
      [name]: value.trim(),
    };
    onChange(trimmedInput);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.clearErrors();

    const errors = [];

    if (this.state.password === '' || this.state.passwordConfirm === '') {
      errors.push([0, 'Please enter and confirm a new password.']);
    } else if (this.state.password !== this.state.passwordConfirm) {
      errors.push([1, 'Passwords must match.']);
    }

    const isPasswordSafe = await checkBreachedPassword(this.state.password);
    this.toggleIsPasswordSafe(isPasswordSafe);

    if (
      (this.state.password !== '' && !validatePassword(this.state.password)) ||
      !this.state.isPasswordSafe
    ) {
      errors.push([2, 'Please check that all password requirements are met.']);
    }

    if (errors.length) {
      return this.props.setErrors(errors);
    }

    const { form } = this.props.form;
    const { roleData } = this.props.role;
    const phone = form.phoneNumber === '' ? null : form.phoneNumber;

    const roleDelegationRegistrationData = {
      email: roleData.delegateEmail,
      password: form.password,
      confirmationCode: roleData.confirmationCode,
      firstName: form.firstName,
      lastName: form.lastName,
      phoneNumber: phone,
    };

    const termsVersion = this.props.terms.termsData.data.version;
    return this.props.register(roleDelegationRegistrationData, termsVersion);
  };

  render() {
    const { classes, role } = this.props;
    const { form, formErrors } = this.props.form;

    const submissionError =
      role.error && role.error.response
        ? 'There was an error completing your registration. Please try again, or contact customer support.'
        : null;

    if (this.state.modal) {
      return (
        <Helper
          title="Examples of Common Passwords"
          description="To ensure your data is safe, please refrain from choosing a commonly used password such as, but not limited to, the examples below"
          closeModal={this.toggleModal}
          renderContent={() => <HelperContent />}
        />
      );
    }
    const setPasswordContent = (
      <div>
        <h3>
          Welcome, <span data-hj-suppress="">{form.firstName}!</span>
        </h3>
        <br />
        <h4>Please set your password</h4>
        <hr />
        <Form onSubmit={this.handleSubmit}>
          <PasswordChecker
            password={this.state.password}
            passwordConfirm={this.state.passwordConfirm}
            showPassword={this.state.showPassword}
            showPasswordConfirm={this.state.showPasswordConfirm}
            handleChange={this.handleChange}
            setError={this.props.setErrors}
            toggleShowPassword={this.toggleShowPassword}
            formSubmitted={this.state.passwordFormSubmitted}
            isPasswordSafe={this.state.isPasswordSafe}
            toggleModal={this.toggleModal}
            setInitialPassword
          />
          <div className={classes.error}>
            {submissionError}
            {formErrors.length
              ? formErrors.map((error) => <div key={error[0]}>{error[1]}</div>)
              : null}
          </div>
          <MaterialButton
            id="reset-password-button"
            value="Continue"
            type="submit"
          />
        </Form>
      </div>
    );

    return setPasswordContent;
  }
}

SetPassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  role: RolePropType.isRequired,
  form: RbacRegistrationFormPropType.isRequired,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  terms: PropTypes.shape({
    status: PropTypes.string,
    termsData: PropTypes.shape({
      data: PropTypes.shape({
        version: PropTypes.string,
      }),
    }),
    error: PropTypes.shape({}),
  }).isRequired,
};

export default withTerms(withStyles(styles)(SetPassword));
