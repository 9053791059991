// this should be in a utils file

export default (cartData, variant) => {
  if (cartData) {
    return cartData.find(
      (item) => item.variant_sku === variant.ars_key.toString()
    );
  }
  return undefined;
};
