// dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// shared UI
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import FormCard from 'shared/ui/MaterialFormCard';
import Form from 'shared/ui/MaterialFormCard/Form';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import FormCheckbox from 'shared/ui/MaterialFormCard/Form/FormCheckbox';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';

// utils
import {
  stripSymbolsFromNumber,
  truncateNumber,
  formatPhoneNumber,
} from 'utils/phoneUtils';

import TermsCard from 'components/Registration/Register/TermsCard';
import styles from 'components/Registration/Register/styles';

import {
  RolePropType,
  RbacRegistrationFormPropType,
} from 'components/Roles/Redux/RoleDelegation/roleDelegationPropTypes';

import { FETCHING_USER } from 'shared/config/userConstants';
import {
  SUBMITTING_RBAC_REGISTRATION_FORM,
  RBAC_REGISTRATION_SUCCESS,
} from 'components/Roles/Redux/RoleDelegation/roleDelegationConstants';
import SetPassword from './SetPassword';

class RegisterRoleDelegation extends Component {
  state = {
    formattedPhoneNumber: '',
    displayTerms: false,
    registrationFormSubmitted: false,
  };

  resetForm = () => {
    this.props.resetForm();
    this.setState({ formattedPhoneNumber: '' });
  };

  displayTerms = () => {
    this.setState({
      displayTerms: true,
    });
  };

  closeModal = () => {
    this.setState({
      displayTerms: false,
    });
  };

  acceptTerms = () => {
    this.setState({
      displayTerms: false,
    });
    this.props.onChange({ termsAcceptance: true });
  };

  declineTerms = () => {
    this.setState({
      displayTerms: false,
    });
    this.props.onChange({ termsAcceptance: false });
  };

  formatPhone = () => {
    const formattedPhoneNumber = formatPhoneNumber(
      this.props.form.form.phoneNumber
    );
    this.setState({
      formattedPhoneNumber,
    });
  };

  handleChange = async (e) => {
    this.props.clearErrors();

    const { onChange } = this.props;
    const { name, value } = e.target;

    if (name === 'formattedPhoneNumber') {
      let phoneNumber = stripSymbolsFromNumber(value);
      phoneNumber = truncateNumber(phoneNumber);
      this.setState({
        formattedPhoneNumber: phoneNumber,
      });
      onChange({ phoneNumber });
    } else if (name === 'termsAcceptance') {
      onChange({ termsAcceptance: e.target.checked });
    } else {
      const trimmedInput = {
        [name]: value.trim(),
      };
      onChange(trimmedInput);
    }
  };

  switchViews = () => {
    this.props.clearErrors();
    this.setState({ registrationFormSubmitted: true });
  };

  handleContinueClick = (e) => {
    e.preventDefault();

    const { form } = this.props.form;

    const errors = [];

    if (!form.firstName || !form.lastName) {
      errors.push([0, 'Please enter all required fields.']);
    }

    if (form.phoneNumber && form.phoneNumber.length !== 10) {
      errors.push([1, 'Please enter a 10-digit phone number.']);
    }

    if (!form.termsAcceptance) {
      errors.push([
        2,
        'You must accept the Nutrien Terms of Use and Privacy Policy.',
      ]);
    }

    return errors.length ? this.props.setErrors(errors) : this.switchViews();
  };

  render() {
    const { classes, user, role } = this.props;
    const { form, formErrors } = this.props.form;
    const email = this.props.role.roleData.delegateEmail;

    const termsDescription = (
      <div>
        I accept{' '}
        <div
          className={classes.link}
          onKeyPress={this.displayTerms}
          onClick={this.displayTerms}
          role="link"
          tabIndex="0"
        >
          Nutrien Terms of Use and Privacy Policy
        </div>
        .
      </div>
    );

    const registrationContent = this.state.registrationFormSubmitted ? (
      <SetPassword
        setErrors={this.props.setErrors}
        clearErrors={this.props.clearErrors}
        register={this.props.register}
        onChange={this.props.onChange}
        form={this.props.form}
        role={this.props.role}
        terms={this.props.terms}
      />
    ) : (
      <div>
        <h3>Welcome!</h3>
        <hr />
        <Form onSubmit={this.handleSubmit}>
          <h4>You ' ve Accepted the Role</h4>
          <div className={classes.subheader}>Let 's Set Up Your Account</div>
          <div className={classes.headerContent}>
            You are creating an account for{' '}
            <span className={classes.link}>{email}</span>. <br />
            Please fill in the information below.
          </div>
          <FormTextField
            name="firstName"
            title="First Name"
            value={form.firstName}
            handleChange={this.handleChange}
            autocomplete="given-name"
          />
          <FormTextField
            name="lastName"
            title="Last Name"
            value={form.lastName}
            handleChange={this.handleChange}
            autocomplete="family-name"
          />
          <FormTextField
            name="formattedPhoneNumber"
            title="Mobile Phone"
            subtitle="Optional"
            value={this.state.formattedPhoneNumber}
            handleChange={this.handleChange}
            onBlur={this.formatPhone}
            autocomplete="tel-national"
          />
          <FormCheckbox
            name="termsAcceptance"
            description={termsDescription}
            value={form.termsAcceptance}
            handleChange={this.handleChange}
          />
          <p>
            Note: By accepting above, you are also accepting the new role of
            Administrator.
          </p>
          <div className={classes.error}>
            {formErrors.length
              ? formErrors.map((error) => <div key={error[0]}>{error[1]}</div>)
              : null}
          </div>
          <div className={classes.buttonContainer}>
            <MaterialButton
              onClick={() => this.props.push('/')}
              value="Cancel"
              type="secondary"
            />
            {this.props.role.status === 'LOADING_REGISTER_ROLE_DELEGATION' ? (
              <div className={classes.inlineLoading}>
                <MaterialLoadingIcon size={30} />
                <span>Creating account...</span>
              </div>
            ) : (
              <MaterialButton
                value="Continue"
                onClick={this.handleContinueClick}
              />
            )}
          </div>
        </Form>
      </div>
    );

    if (
      role.status === SUBMITTING_RBAC_REGISTRATION_FORM ||
      role.status === RBAC_REGISTRATION_SUCCESS ||
      user.status === FETCHING_USER
    ) {
      return <MaterialLoadingIcon />;
    }

    return (
      <div>
        <FullPageBackground
          image={bgRegistration}
          className="full-page-background--farmers-pointing"
        />
        {this.state.displayTerms ? (
          <TermsCard
            closeModal={this.closeModal}
            acceptTerms={this.acceptTerms}
            declineTerms={this.declineTerms}
          />
        ) : (
          <FormCard>{registrationContent}</FormCard>
        )}
      </div>
    );
  }
}

RegisterRoleDelegation.propTypes = {
  push: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  role: RolePropType.isRequired,
  form: RbacRegistrationFormPropType.isRequired,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  user: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  terms: PropTypes.shape({
    status: PropTypes.string,
    termsData: PropTypes.shape({
      data: PropTypes.shape({
        version: PropTypes.string,
      }),
    }),
    error: PropTypes.shape({}),
  }).isRequired,
};

export default withStyles(styles)(RegisterRoleDelegation);
