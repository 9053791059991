const styles = {
  dialog: {
    height: '520px',
    width: '800px',
    maxWidth: 'none',
    textAlign: 'center',
  },
  titleText: {
    fontSize: '36px',
    fontWeight: '600',
    lineHeight: '1.94',
    color: '#464646',
    marginTop: '19px',
  },
  closeButton: {
    float: 'right',
    margin: '10px 10px 0 0',
  },
  warningIcon: {
    marginTop: '139px',
    position: 'relative',
    left: '20px',
  },
  bodyText: {
    width: '305px',
    margin: 'auto',
    textAlign: 'center',
  },
  okButton: {
    marginTop: '68px',
    width: '107px',
  },
  '@media (max-width: 900px)': {
    dialog: {
      width: 'auto',
      padding: '0 20px 0 20px',
    },
    bodyText: {
      width: 'auto',
    },
  },
};

export default styles;
