import { connect } from 'react-redux';
import { fetchUser } from 'actions';
import Feedback from './Feedback';

const mapStateToProps = (state) => ({
  user: state.user.userData,
});
export const mapDispatchToProps = {
  fetchUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
