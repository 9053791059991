import {
  GET_PREFERENCES_FAILURE,
  GET_PREFERENCES_SUCCESS,
  LOADING_PREFERENCES,
  UPDATE_PREFERENCE_FAILURE,
  UPDATE_PREFERENCE_SUCCESS,
} from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case GET_PREFERENCES_FAILURE:
    case UPDATE_PREFERENCE_FAILURE:
      return action.payload;
    case GET_PREFERENCES_SUCCESS:
    case UPDATE_PREFERENCE_SUCCESS:
    case LOADING_PREFERENCES:
      return null;
    default:
      return state;
  }
};
