import { makeStyles } from '@nutrien/uet-react/styles';

const useStyles = makeStyles((theme) => ({
  approvedCreditTypography: {
    marginBottom: '24px',
  },
  approvedCreditMaterialCard: {
    [theme.breakpoints.up('600')]: {
      width: '400px',
    },
    marginBottom: '40px',
  },
  approvedCreditTableBody: {
    '& tr > td:last-child': {
      fontWeight: 600,
    },
  },
}));

export default useStyles;
