import get from 'lodash/get';
import { setInvoices } from 'actions/actionCreators';
import * as invoicesService from '../invoicesService';
import clearSelectedInvoices from './clearSelectedInvoices';
import clearSelectedInvoicedProducts from './clearSelectedInvoicedProducts';
/**
 * This thunk action will:
 *  - Set invoices status to loading state
 *  - Clear any selected invoices
 *  - Clear selected invoice products
 *  - Call invoice service to fetch invoice summaries for the given account
 *    - Fetch invoiced products if shouldLoadProducts=true
 * @param {string} id the account id
 * @param {bool} shouldLoadProducts flag to load invoiced products
 */
export default (id) => (dispatch) => {
  dispatch(setInvoices({ status: 'LOADING', list: [], accountId: null }));
  dispatch(clearSelectedInvoices());
  dispatch(clearSelectedInvoicedProducts());
  return invoicesService
    .getInvoiceSummaries(id)
    .then((response) => {
      const { invoiceSummaries } = response;
      dispatch(
        setInvoices({
          status: 'OK',
          list: invoiceSummaries,
          accountId: id,
        })
      );
    })
    .catch((err) => {
      dispatch(
        setInvoices({
          status: get(err, 'response.status', null),
          list: [],
          accountId: id,
        })
      );
    });
};
