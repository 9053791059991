import { createSelector } from 'reselect';

export const selectAccounts = () =>
  createSelector([(state) => state.accounts], (accounts) => accounts);

export const selectSelectedAccount = () =>
  createSelector([(state) => state.selectedAccount], (account) => account);

export const selectAccountActivity = () =>
  createSelector(
    [(state) => state.accountActivity],
    (accountActivity) => accountActivity
  );
