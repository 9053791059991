// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import HelperContent from 'shared/ui/PasswordChecker/HelperContent';
import FormCard from 'shared/ui/MaterialFormCard';
import Helper from 'shared/ui/MaterialFormCard/FormHelper';

import './styles.scss';

const CommonPasswordModal = ({ closeModal }) => (
  <div className="common-password-modal">
    <FormCard>
      <Helper
        title="Examples of Common Passwords"
        description="To ensure your data is safe, please refrain from choosing a commonly used password such as, but not limited to, the examples below"
        closeModal={closeModal}
        renderContent={() => <HelperContent />}
      />
    </FormCard>
  </div>
);

CommonPasswordModal.propTypes = {
  closeModal: PropTypes.func,
};

CommonPasswordModal.defaultProps = {
  closeModal: () => {},
};

export default CommonPasswordModal;
