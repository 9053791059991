import { makeStyles } from '@material-ui/core';

const cellStyling = (theme) => ({
  borderBottom: 'none',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
  padding: '12px',
  width: '50%',
  '@media (max-width: 999px)': {
    textAlign: 'left',
  },
  '@media (min-width: 1000px)': {
    display: 'table-cell',
    width: '20%',
  },
});

const useStyles = makeStyles((theme) => ({
  table: {
    margin: 0,
    width: '100%',
  },
  tableLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    letterSpacing: '.25px',

    '@media (min-width: 1000px)': {
      letterSpacing: 'normal',
    },
  },
  row: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.25)',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '12px',
    width: '100%',

    '@media (min-width: 1000px)': {
      borderBottom: '1px solid #ccc',
      borderRadius: 0,
      boxShadow: 'none',
      display: 'table-row',
      marginTop: 0,
    },
  },
  cell: cellStyling(theme),
  buttonCell: {
    ...cellStyling(theme),
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'stretch',
  },
  actionButton: {
    width: '50%',
    '&:first-child': {
      marginRight: '16px',
    },
    '@media (min-width: 1000px)': {
      width: '20px',
    },
  },
  cellEnd: {
    borderBottom: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    padding: '12px',
    textAlign: 'left',
    width: '50%',

    '@media (min-width: 1000px)': {
      display: 'table-cell',
      width: '20%',
    },
  },
  cellHeaderMobile: {
    color: theme.palette.text.secondary,
    display: 'block',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: 2,

    '@media (min-width: 1000px)': {
      display: 'none',
    },
  },
  contractDate: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    letterSpacing: '.25px',

    '@media (min-width: 1000px)': {
      letterSpacing: 'normal',
    },
  },
  loadMoreButton: {
    marginTop: '16px',
    display: 'flex',
    'justify-content': 'center',
    width: '100%',
  },
}));

export default useStyles;
