// import React from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import BillingTab from './BillingTab';
import getAccountIdsFromAssociatedOrgs from './billingTabSelectors';

export const mapStateToProps = (state) => {
  const { associatedOrgs } = state.user.userData.userDetails;
  const { billingTab } = state.profile;

  return {
    accountIds: getAccountIdsFromAssociatedOrgs(associatedOrgs),
    togglingPreferences: billingTab.fetching,
    errorLoadingPreferences: billingTab.error,
    privacyPreferences: state.user.userData.privacy,
  };
};

export default connect(mapStateToProps)(BillingTab);
