import React from 'react';
import PropTypes from 'prop-types';

const ProductIngredientPercent = ({ ingredient }) => (
  <div
    data-test="product-detail-ingredient-percentage"
    className="product-detail__ingredient-percentage"
  >
    {`${Number.parseFloat(ingredient.percentage).toFixed(2)}%`}
  </div>
);

ProductIngredientPercent.propTypes = {
  ingredient: PropTypes.shape({
    percentage: PropTypes.number,
  }),
};

ProductIngredientPercent.defaultProps = {
  ingredient: {
    percentage: null,
  },
};

export default ProductIngredientPercent;
