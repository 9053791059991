import React, { forwardRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import qs from 'query-string';
import get from 'lodash/get';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import MaterialLoadingIcon from 'shared/ui/RebrandedMaterialLoadingIconInline/MaterialLoadingIcon';
import { WithFetchAccountsContainer } from '../../Shop/containers/containers';
import { gtmShopContractedSku } from '../../../store/middleware/TagManager/gtmActions';
import {
  getContractDetails,
  setContractDetailsSuccess,
} from '../../Documents/actions/documentsActions/documentsActions';
import ContractInfo from './contractInfo/ContractInfo';
import ProductsTable from './contractedProductsTable/ProductsTable';

const useStyles = makeStyles(() => ({
  button: {
    margin: 0,
    width: '140px',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px 0',

    '@media (min-width: 1000px)': {
      paddingTop: '90px',
    },
  },
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  totalContainer: {
    display: 'flex',
    marginTop: '-24px',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    '@media (max-width: 700px)': {
      marginTop: '0px',
    },
  },

  priceText: {
    textAlign: 'right',
    height: '27px',
    fontSize: '16px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#222',

    '@media (min-width: 1000px)': {
      fontSize: '20px',
    },
  },

  priceTextWrapper: {
    paddingRight: '20px',
    '@media (min-width: 1000px)': {
      display: 'table-cell',
      paddingRight: '20px',
    },
  },

  priceTotals: {
    '@media (min-width: 1000px)': {
      display: 'table-cell',
    },
  },
}));

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return {
    width,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(() =>
    getWindowDimensions()
  );

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
const ContractDetailsShopLink = forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

const groupByShelf = (arr, property) =>
  arr.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

const RenderLoading = () => {
  const classes = useStyles();
  const section = (
    <div
      className={classes.loadingWrapper}
      data-test="contract-details-loading"
    >
      <MaterialLoadingIcon />
    </div>
  );
  return section;
};
const renderError = () => {
  const section = (
    <div data-test="contract-details-error">
      <ApiErrorMessage
        entityName="Contract Details"
        customMessage={
          <>
            <div>Try refreshing the page.</div>
            <Link to="/documents/contracts">Back to Contracts</Link>
          </>
        }
      />
    </div>
  );
  return section;
};

const renderNotFound = (contractNumber) => {
  const section = (
    <div data-test="contract-details-not-found-error">
      <ApiErrorMessage
        entityName={`Contract #${contractNumber} is invalid and `}
        customMessage={<Link to="/documents/contracts">Back to Contracts</Link>}
      />
    </div>
  );
  return section;
};

const RenderContent = (data, history, width, acctNumber, contractNumber) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const productsByShelf = groupByShelf(data.products, 'shelf');
  const totalPrice = numeral(
    data.products.reduce((acc, cv) => acc + cv.totalPrice, 0)
  ).format('$0,0.00');
  const totalDue = numeral(data.totalDue).format('$0,0.00');
  return (
    <>
      <ContractInfo
        history={history}
        pageWidth={width}
        contractDetails={data}
      />
      {Object.entries(productsByShelf).map((el) => (
        <ProductsTable shelf={el[0]} products={el[1]} />
      ))}
      <div className={classes.totalContainer}>
        <div className={classes.priceTextWrapper}>
          <div className={classes.priceText}>Total Contract Price</div>
          <div className={classes.priceText}>Total Due</div>
        </div>
        <div className={classes.priceTotals}>
          <div
            className={classes.priceText}
            data-test="contracted-products-table-total-price"
          >
            {totalPrice}
          </div>
          <div className={classes.priceText}>{totalDue}</div>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          component={ContractDetailsShopLink}
          onClick={() =>
            dispatch(
              gtmShopContractedSku(
                '/documents/contracts/:accountId/:contractId'
              )
            )
          }
          className={classes.button}
          data-test="contract-details-orders-button"
          to={`/products?acct=${acctNumber}&cont=${contractNumber}`}
        >
          Shop
        </Button>
      </div>
    </>
  );
};

export function ContractDetails() {
  const dispatch = useDispatch();
  const documentsStatus = useSelector((state) => state.documents.status);
  const contractDetails = useSelector(
    (state) => state.documents.contractDetails
  );
  const contractDetailsStatusCode = useSelector(
    (state) => state.documents.contractDetailsStatusCode
  );
  const history = useHistory();
  const { width } = useWindowDimensions();
  const {
    location: { search },
  } = history;

  const searchParams = qs.parse(search);
  const acctNumber = get(searchParams, 'acct');
  const contractNumber = get(searchParams, 'cont');

  let section;
  useEffect(() => {
    // setting to no data in order to prevent stale details in redux
    dispatch(setContractDetailsSuccess([]));
    dispatch(getContractDetails(acctNumber, contractNumber));
  }, []);

  if (Object.keys(contractDetails).length === 0) {
    section = RenderLoading();
  }
  if (contractDetails.data) {
    section = RenderContent(
      contractDetails.data,
      history,
      width,
      acctNumber,
      contractNumber
    );
  }
  if (documentsStatus === 'ERROR' && contractDetailsStatusCode === 404) {
    return renderNotFound(contractNumber);
  }
  if (documentsStatus === 'ERROR') {
    return renderError();
  }
  return <>{section}</>;
}

export default WithFetchAccountsContainer(ContractDetails);
