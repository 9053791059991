import React, { Component } from 'react';
import { Popover } from '@material-ui/core';
import { NotificationsBadge } from 'components/Notifications';
import NotificationsMenu from 'components/Notifications/NotificationsMenu';

class NotificationDesktopMenuItem extends Component {
  state = {
    notificationLinkEl: null,
  };

  handleShowNotifications = (e) => {
    this.setState({
      notificationLinkEl: e.currentTarget,
    });
  };

  handleCloseNotifications = () => {
    this.setState({ notificationLinkEl: null });
  };

  render() {
    return (
      <div
        className="notification-desktop-menu-item__wrapper"
        data-test="headerNotificationLinkDesktop"
        key="header-notification-link"
      >
        <NotificationsBadge onClick={this.handleShowNotifications} />
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          anchorEl={this.state.notificationLinkEl}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(this.state.notificationLinkEl)}
          onClose={this.handleCloseNotifications}
        >
          <NotificationsMenu closeMenu={this.handleCloseNotifications} />
        </Popover>
      </div>
    );
  }
}

export default NotificationDesktopMenuItem;
