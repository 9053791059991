// styles used by both cancel and add button
const sharedButtonStyle = {
  display: 'inline-block',
  width: 80,
  marginBottom: 0,
};

export default (theme) => ({
  modalContainer: {
    position: 'relative',
    padding: 15,
    minWidth: 550,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      width: '100%',
    },
  },
  modalCloseContainer: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  closeImage: {
    width: 20,
    cursor: 'pointer',
  },
  headerContainer: {
    marginBottom: 30,
  },
  header: {
    marginLeft: 0,
    marginRight: 0,
  },
  subHeader: {
    margin: 0,
    fontSize: 14,
    fontWeight: 'normal',
  },
  form: {
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  formField: {
    marginTop: 20,
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  inputField: {
    width: '80%',
    border: '1px solid #ccc',
    fontSize: 14,
    fontWeight: 'normal',
    padding: '8px 16px',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      width: '100%',
    },
  },
  error: {
    color: 'red',
    fontSize: 12,
    display: 'block',
  },
  buttonContainer: {
    textAlign: 'right',
  },
  cancelButton: {
    ...sharedButtonStyle,
  },
  saveButton: {
    ...sharedButtonStyle,
    marginLeft: 15,
  },
  legalInfoContainer: {
    margin: '40px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  infoIcon: {
    color: '#0093E5',
    marginTop: 5,
  },
  legalInfo: {
    fontWeight: 'bold',
    marginTop: -25,
    marginLeft: 30,
  },
});
