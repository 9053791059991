import {
  NOTIFICATIONS_FETCH_FAILURE,
  NOTIFICATIONS_FETCH_SUCCESS,
} from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case NOTIFICATIONS_FETCH_FAILURE:
      return action.error;
    case NOTIFICATIONS_FETCH_SUCCESS:
      return null;
    default:
      return state;
  }
};
