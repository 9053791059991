import { combineReducers } from 'redux';
import {
  INVITATION_CONFIRMATION_PENDING,
  CONFIRMING_ROLE_INVITATION,
  CONFIRM_ROLE_INVITATION_SUCCESS,
  CONFIRM_ROLE_INVITATION_FAILURE,
  ROLE_DELEGATION_ACCEPTED,
  SUBMITTING_RBAC_REGISTRATION_FORM,
  RBAC_REGISTRATION_SUCCESS,
  RBAC_REGISTRATION_FAILURE,
  CLEAR_RBAC_REGISTRATION_FORM_ERRORS,
} from './roleDelegationConstants';

export const roleData = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_ROLE_INVITATION_SUCCESS:
      return action.payload.data.confirmation;
    case CONFIRM_ROLE_INVITATION_FAILURE:
      return {};
    default:
      return state;
  }
};

export const status = (state = INVITATION_CONFIRMATION_PENDING, action) => {
  switch (action.type) {
    case CONFIRMING_ROLE_INVITATION:
      return CONFIRMING_ROLE_INVITATION;
    case CONFIRM_ROLE_INVITATION_SUCCESS:
      return CONFIRM_ROLE_INVITATION_SUCCESS;
    case CONFIRM_ROLE_INVITATION_FAILURE:
      return CONFIRM_ROLE_INVITATION_FAILURE;
    case ROLE_DELEGATION_ACCEPTED:
      return ROLE_DELEGATION_ACCEPTED;
    case SUBMITTING_RBAC_REGISTRATION_FORM:
      return SUBMITTING_RBAC_REGISTRATION_FORM;
    case RBAC_REGISTRATION_SUCCESS:
      return RBAC_REGISTRATION_SUCCESS;
    case RBAC_REGISTRATION_FAILURE:
      return RBAC_REGISTRATION_FAILURE;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case CONFIRM_ROLE_INVITATION_FAILURE:
    case RBAC_REGISTRATION_FAILURE:
      return action.error;
    case CONFIRMING_ROLE_INVITATION:
    case CONFIRM_ROLE_INVITATION_SUCCESS:
    case ROLE_DELEGATION_ACCEPTED:
    case SUBMITTING_RBAC_REGISTRATION_FORM:
    case RBAC_REGISTRATION_SUCCESS:
    case CLEAR_RBAC_REGISTRATION_FORM_ERRORS:
      return null;
    default:
      return state;
  }
};

const role = combineReducers({
  roleData,
  status,
  error,
});

export default role;
