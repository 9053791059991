import {
  nutrienGreen,
  errorStyling,
  buttonContainerStyles,
  mobileButtonContainerStyles,
} from 'shared/styles';

const styles = {
  subheader: {
    fontWeight: 300,
    margin: '-15px 0px 0px',
    fontSize: 20,
  },
  headerContent: {
    margin: '15px 0px 20px',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.3px',
  },
  link: {
    ...nutrienGreen,
    display: 'inline',
  },
  error: {
    marginTop: 20,
    ...errorStyling,
  },
  subtitleContainer: {
    position: 'relative',
  },
  subtitleText: {
    color: '#828282',
    fontSize: 12,
    fontWeight: 'normal',
    position: 'absolute',
    top: 68,
    left: 9,
  },
  customFieldText: {
    marginBottom: 37,
  },
  signInLink: {
    fontSize: 20,
  },
  inlineLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontWeight: 600,
    fontSize: 14,
    marginTop: 25,
    '& div': {
      padding: 0,
      width: 'auto',
    },
    '& span': {
      marginLeft: 15,
    },
  },
  buttonContainer: {
    marginTop: 15,
    ...mobileButtonContainerStyles,
  },
  '@media (min-width: 720px)': {
    inlineLoading: {
      width: '47%',
      marginTop: 0,
    },
    buttonContainer: {
      ...buttonContainerStyles,
    },
  },
};

export default styles;
