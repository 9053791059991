import resolveApiEnv, {
  DEV,
  SIT,
  PRE,
  PROD,
} from 'shared/config/resolveApiEnv';

export default function resolveAudience() {
  const env = resolveApiEnv();

  switch (env) {
    case DEV:
      return 'nutrien-digital-dev';
    case SIT:
      return 'nutrien-digital-sit';
    case PRE:
      return 'nutrien-digital-pre';
    case PROD:
    default:
      return 'nutrien-digital-prd';
  }
}
