export const EVENT_TRACK = 'EVENT_TRACK';
export const CXH_VIEW_WEATHER = 'CXH_VIEW_WEATHER';
export const CXH_VIEW_ACCOUNT = 'CXH_VIEW_ACCOUNT';
export const GTM_ADD_TO_CART = 'GTM/ADD_TO_CART';
export const GTM_CLICK_FEATURED_PARTNER_APPLE_STORE_XARVIO =
  'GTM/CLICK_FEATURED_PARTNER_APPLE_STORE_XARVIO';
export const GTM_CLICK_FEATURED_PARTNER_GOOGLE_STORE_XARVIO =
  'GTM/CLICK_FEATURED_PARTNER_GOOGLE_STORE_XARVIO';
export const GTM_CLICK_FEATURED_PARTNER_LEARN_MORE =
  'GTM/CLICK_FEATURED_PARTNER_LEARN_MORE';
export const GTM_CLICK_NUTRIEN_LOGO = 'GTM/CLICK_NUTRIEN_LOGO';
export const GTM_CLICK_PLP_ECOMM_PROMO = 'GTM/CLICK_PLP_ECOMM_PROMO';
export const GTM_CROSS_SELLS = 'GTM_CROSS_SELLS';
export const GTM_BRAND_LADDER = 'GTM_BRAND_LADDER';
export const GTM_FMS_BUTTON_SEEN = 'GTM/FMS_BUTTON_SEEN';
export const GTM_INVOICED_PRODUCTS_SEARCH_FOCUS =
  'GTM/INVOICED_PRODUCTS_SEARCH_FOCUS';
export const GTM_INVOICED_PRODUCTS_SEARCH_UPDATE =
  'GTM/INVOICED_PRODUCTS_SEARCH_UPDATE';
export const GTM_INVOICES_DOWNLOAD = 'GTM/INVOICES_DOWNLOAD';
export const GTM_INVOICES_FOCUS_FILTER = 'GTM/INVOICES_FOCUS_FILTER';
export const GTM_INVOICES_VIEW_DETAILS = 'GTM/INVOICES_VIEW_DETAILS';
export const GTM_INVOICES_VIEW_INVOICE = 'GTM/INVOICES_VIEW_INVOICE';
export const GTM_LOG_CART_CHECKOUT_STEP_1 = 'GTM/LOG_CART_CHECKOUT_STEP_1';
export const GTM_REMOVE_FROM_CART = 'GTM/REMOVE_FROM_CART';
export const GTM_UPDATING_CART = 'GTM/UPDATING_CART';
export const GTM_VIEW_PDP = 'GTM/VIEW_PDP';
export const GTM_VIEW_WEATHER = 'GTM_VIEW_WEATHER';
export const GTM_MAKE_INVOICE_PAYMENT = 'GTM/MAKE_INVOICE_PAYMENT';
export const GTM_ACCOUNT_OVERVIEW_INTERACTION =
  'GTM/ACCOUNT_OVERVIEW_INTERACTION';
export const GTM_ACCOUNT_OVERVIEW_FILTER_CHANGE =
  'GTM/ACCOUNT_OVERVIEW_FILTER_CHANGE';
export const GTM_REORDER_TOP_PRODUCT = 'GTM/REORDER_TOP_PRODUCT';
export const GTM_VIEW_PAST_TOP_PRODUCT_PURCHASES =
  'GTM/VIEW_PAST_TOP_PRODUCT_PURCHASES';
export const GTM_VIEW_TOP_PRODUCT_SDS = 'GTM/VIEW_TOP_PRODUCT_SDS';
export const GTM_ONLINE_ORDERS_INTERACTION = 'GTM/ONLINE_ORDERS_INTERACTION';
export const GTM_ACCOUNT_OPEN_ITEMS_INTERACTION =
  'GTM/ACCOUNT_OPEN_ITEMS_INTERACTION';
export const GTM_ACCOUNT_OPEN_ITEMS_FILTER_CHANGE =
  'GTM/ACCOUNT_OPEN_ITEMS_FILTER_CHANGE';
export const GTM_EC_PRICINGPOPOVER = 'GTM/EC_PRICINGPOPOVER';
export const GTM_ACCOUNT_SELECTION = 'GTM/ACCOUNT_SELECTION';
export const GTM_VIEW_CONTRACT_DETAILS = 'GTM/VIEW_CONTRACT_DETAILS';
export const GTM_SHOP_CONTRACTED_SKU = 'GTM/SHOP_CONTRACTED_SKU';
export const GTM_GFA_APP_LOAD = 'GTM/GFA_APP_LOAD';
export const GTM_GFA_SCREEN_VIEW = 'GTM/GFA_SCREEN_VIEW';
export const GTM_GFA_EVENT = 'GTM/GFA_EVENT';
export const GTM_GFA_SHARE = 'GTM/GFA_SHARE';
export const CXH_COMPLIANCE_ACCEPT = 'CXH_COMPLIANCE_ACCEPT';
export const CXH_COMPLIANCE_CANCEL = 'CXH_COMPLIANCE_CANCEL';
export const CXH_COMPLIANCE_OPTOUT = 'CXH_COMPLIANCE_OPTOUT';
export const GTM_VIEW_PRODUCT_LABEL = 'GTM_VIEW_PRODUCT_LABEL';
export const CXH_COMPLIANCE_VIEWTERMSOFSERVICE =
  'CXH_COMPLIANCE_VIEWTERMSOFSERVICE';
export const GTM_LOGISTICS_PAGE = 'GTM_LOGISTICS_PAGE';
export const GTM_SERVICES_PAGE = 'GTM_SERVICES_PAGE';
export const CXH_PRODUCTCATALOG_FILTER = 'CXH_PRODUCTCATALOG_FILTER';
export const CXH_PRODUCTCATALOG_LOAD = 'CXH_PRODUCTCATALOG_LOAD';
export const CXH_PRODUCTCATALOG_SORT = 'CXH_PRODUCTCATALOG_SORT';
export const CXH_PRODUCTCATALOG_CLEAR_FILTERS =
  'CXH_PRODUCTCATALOG_CLEAR_FILTERS';
export const CXH_ONLINECREDIT_APPLICATION = 'CXH_OnlineCredit_Application';
export const CXH_REDIRECT_NEW_WEB = 'CXH_REDIRECT_NEW_WEB';
export const CXH_NUTRIEN_HUB_MOBILE_APP = 'CXH_NUTRIEN_HUB_MOBILE_APP';
export const CXH_NUTRIEN_HUB_GOOGLE_APP = 'CXH_NUTRIEN_HUB_GOOGLE_APP';
export const CXH_NUTRIEN_HUB_APPLE_APP = 'CXH_NEW_NUTRIEN_HUB_APPLE_APP';

/**
 * Mapping of enumerated custom dimensions to meaningful names
 * Also gives singular point of reference for custom dimensions
 *
 * Usage:
 *     { [CUSTOM_DIMENSIONS.variantSku]: 'some data' }
 *     creates -> { dimension8: 'some data' }
 */
export const CUSTOM_DIMENSIONS = {
  variantSku: 'dimension8',
  variantDescription: 'dimension10',
  variantIsPriced: 'dimension11',
};
