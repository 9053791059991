import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import svgTest from '../../../../assets/icn-cust-empty.svg';

const styles = {
  root: {
    width: '50%',
    margin: '10% 25%',
    textAlign: 'center',
    '& *': {
      paddingTop: '17px',
    },
    '& p': {
      lineHeight: '18px',
      fontSize: '16px',
    },
    '@media only screen and (max-width: 576px)': {
      width: 'unset',
      margin: '15% 5%',
    },
  },
};
const EmptyCustomerList = ({ classes, primary, secondary }) => (
  <div className={classes.root}>
    <img src={svgTest} alt="No Customers Icon" />
    <h3>{primary}</h3>
    <p>{secondary}</p>
  </div>
);

EmptyCustomerList.propTypes = {
  primary: PropTypes.node,
  secondary: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

EmptyCustomerList.defaultProps = {
  primary: 'You have no customers enrolled in the Nutrien Digital Hub.',
  secondary:
    'Please talk to your customers about enrolling in the Nutrien Digital Hub.',
};

export default withStyles(styles)(EmptyCustomerList);
