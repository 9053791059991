import React from 'react';
import PropTypes from 'prop-types';

import './ProductImage.scss';

const ProductImage = (props) => {
  const { imgSrc, alt, context, onError } = props;

  return (
    <img
      src={imgSrc}
      alt={alt}
      className={`${context}__image`}
      onError={onError}
    />
  );
};

ProductImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ProductImage;
