import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import MaterialButton from 'shared/ui/RebrandedMaterialButton';
import closeModal from 'assets/close-modal.svg';
import styles from './styles';

const Helper = (props) => {
  const { classes } = props;
  return (
    <div>
      <div className={classes.header}>
        <h3>{props.title}</h3>
        <div
          role="button"
          className={classes.closeModal}
          onClick={props.closeModal}
          onKeyPress={props.closeModal}
          tabIndex="0"
        >
          <img src={closeModal} alt="close" />
        </div>
      </div>
      <div className={classes.description}>{props.description}</div>
      {props.renderContent && props.renderContent()}
      <div className={classes.content}>
        <img src={props.src} alt={props.altText} />
        <MaterialButton value="Got it!" onClick={props.closeModal} />
      </div>
    </div>
  );
};

Helper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  src: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  altText: PropTypes.string,
  renderContent: PropTypes.func,
};

Helper.defaultProps = {
  description: null,
  src: null,
  altText: null,
  renderContent: null,
};

export default withStyles(styles)(Helper);
