import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.label.fontSize,
  },
  link: {
    color: theme.palette.primary.main,
  },
  divider: {
    margin: theme.spacing(0, 0.5),
  },
}));

const BreadCrumbs = ({ history }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <span
          onKeyPress={() => history.push('/')}
          role="button"
          tabIndex="-1"
          onClick={() => history.push('/')}
          className={classes.link}
        >
          Home
        </span>
        <span className={classes.divider}> / </span>
        <span>Products</span>
      </div>
    </>
  );
};

BreadCrumbs.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
};
BreadCrumbs.defaultProps = {
  history: {},
};

export default BreadCrumbs;
