export const addOneYear = (fromDate = new Date()) => {
  let finalDate;
  const month = fromDate.getMonth();
  const dayOfMonth = fromDate.getDate();
  const year = fromDate.getFullYear();

  if (dayOfMonth <= 28 || month !== 1) {
    finalDate = new Date(year + 1, month, dayOfMonth);
  } else {
    // This else will only be triggered if the day is greater than 28 and the month is Feb
    // so no matter what we need to hardcode the day to 28 since leap years only come every 4 years
    finalDate = new Date(year + 1, month, 28);
  }

  return finalDate;
};
