import React, { Component } from 'react';
import get from 'lodash/get';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button } from 'nutrien-common-components-react/dist/mdc';
import MatButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import getStepperBreadCrumbs from '../../utils/GetStepperBreadCrumbs/getStepperBreadCrumbs';

import './MobileCartStepper.scss';

export class MobileCartStepper extends Component {
  updateURL = (currentStep, direction) => {
    const selectedAccountNumber = get(this.props, 'accountNumber', '');
    const steps = getStepperBreadCrumbs(selectedAccountNumber);
    const currentIndex = steps.findIndex((step) => step.id === currentStep);
    const { history } = this.props;
    let newURL;

    if (direction === 'next' && currentIndex < steps.length) {
      newURL = steps[currentIndex + 1].url;
    } else if (direction === 'back' && currentIndex > 0) {
      newURL = steps[currentIndex - 1].url;
    }

    history.push({
      pathname: newURL,
      originatedFromCart: true,
    });
  };

  render() {
    const { loading, currentStep } = this.props;
    const selectedAccountNumber = get(this.props, 'accountNumber', '');
    const steps = getStepperBreadCrumbs(selectedAccountNumber);
    const currentIndex = steps.findIndex((step) => step.id === currentStep);
    let button;

    if (currentStep === steps[steps.length - 1].id) {
      button = (
        <Button
          data-test="mobile-cart-stepper-next-review-button"
          onClick={this.props.handleFinal}
          disabled={loading}
        >
          Submit
        </Button>
      );
    } else {
      button = (
        <MatButton
          size="small"
          onClick={() => this.updateURL(currentStep, 'next')}
          disabled={currentStep === steps[steps.length - 1].id}
        >
          Next
          <KeyboardArrowRight />
        </MatButton>
      );
    }

    return (
      <MobileStepper
        variant="dots"
        steps={steps.length}
        activeStep={currentIndex}
        className="mobile-cart-stepper__stepper"
        nextButton={button}
        backButton={
          <MatButton
            size="small"
            onClick={() => this.updateURL(currentStep, 'back')}
            disabled={currentStep === steps[0].id}
          >
            <KeyboardArrowLeft />
            Back
          </MatButton>
        }
      />
    );
  }
}

MobileCartStepper.propTypes = {
  currentStep: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  handleFinal: PropTypes.func,
  loading: PropTypes.bool,
};

MobileCartStepper.defaultProps = {
  handleFinal: () => {},
  loading: false,
};

export default MobileCartStepper;
