import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import { useFlags } from 'launchdarkly-react-client-sdk';

const HeaderMobileSection = ({ menuItems }) => {
  const flags = useFlags();
  return (
    <div className="mobile-section-container">
      {menuItems.length &&
        menuItems.map((child) => {
          if (child.key === 'products-link' || child.key === 'cart-link') {
            const key = camelCase(child.key);
            return flags[key] ? child : null;
          }
          return child;
        })}
    </div>
  );
};

HeaderMobileSection.propTypes = {
  menuItems: PropTypes.node,
};

HeaderMobileSection.defaultProps = {
  menuItems: null,
};

export default HeaderMobileSection;
