import { isCanada } from 'shared/utils/CountryCode';
import { verifySAPAccount } from 'utils/verifySAP';

const verifyUsePackageUom = (account) => {
  /* eslint-disable */
  if (Object.keys(account).length === 0) {
    return;
  }
  /* eslint-disable */
  const isSAP = verifySAPAccount(account.id);
  const canadian = isCanada(account.locationCode);
  return isSAP && canadian;
};

export default verifyUsePackageUom;
