import PropTypes from 'prop-types';

export const invoice = PropTypes.shape({});

export const invoices = PropTypes.arrayOf(invoice);

export const selectedInvoiceIds = PropTypes.shape({
  totalAmountDue: PropTypes.number,
  paid: PropTypes.arrayOf(PropTypes.string),
  unpaid: PropTypes.arrayOf(PropTypes.string),
});
