/* eslint-disable no-unused-expressions */
import get from 'lodash/get';
import { isCanada } from 'shared/utils/CountryCode';
import ecommApiService from '../../utils/ecommApiService';
import getProductLabelsV2 from '../../../ProductLabels/getProductLabelsV2';
import { getProductIds, injectPricesIntoProducts } from '../../utils/pricing';

import productTransformer from '../../../../utils/transformers/productTransformer';
import productListTransformer from '../../../../utils/transformers/productListTransformer';

import {
  LOADING_PRODUCT,
  LOADING_PRICES_FOR_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_PRICES_FOR_PRODUCT_SUCCESS,
  GET_PRICES_FOR_PRODUCT_FAILURE,
  SET_SELECTED_VARIANT,
  SET_VARIANT_QUANTITY,
  SET_VARIANT_VOLUME,
  GET_SIMILAR_PRODUCTS_SUCCESS,
  GET_SIMILAR_PRODUCTS_FAILURE,
  LOADING_SIMILAR_PRODUCTS,
} from '../constants';
import { gtmViewPDP } from '../../../../store/middleware/TagManager/gtmActions';

export const loadingSimilarProducts = (loading) => ({
  type: LOADING_SIMILAR_PRODUCTS,
  loading,
});

export const getSimilarProductsFailure = (error) => ({
  type: GET_SIMILAR_PRODUCTS_FAILURE,
  error,
});

export const getSimilarProductsSuccess = (data) => ({
  type: GET_SIMILAR_PRODUCTS_SUCCESS,
  productData: data,
});

export const getPricesForProductFailure = () => ({
  type: GET_PRICES_FOR_PRODUCT_FAILURE,
  loadingPrices: false,
});

export const getPricesForSimilarProducts = (account, products) => (
  dispatch
) => {
  const PRICING_URL = 'v2/product-prices';
  const payload = {
    accountId: account.id,
    locationId: account.branchId,
    productId: getProductIds(products),
  };
  const headers = {
    headers: {
      'account-id': account.id,
    },
  };
  return ecommApiService
    .post(PRICING_URL, payload, headers)
    .then((response) => {
      const productsWithPrices = injectPricesIntoProducts(
        account.locationCode,
        products,
        response.data.data
      );
      dispatch(getSimilarProductsSuccess(productsWithPrices));
    })
    .catch(() => {
      dispatch(getPricesForProductFailure());
    });
};

export const getSimilarProducts = (account, params) => (dispatch) => {
  const url = `/v2/products/?fields[product]=id,name,commonName,description,rank,image,enabled,federallyRestricted,commonName,logo,logoMobile,categories,productRestrictedStates,associatedCountry&include=variant,manufacturer,activeIngredient&fields[variant]=name,status,manufacturers,id,agrianId,productId,description,position,image,enabled,unitQty,unitSize,unitMeasure,totalVolumeOptions,isBulk,digitalVariantName&fields[activeIngredient]=id,name,percentage&fields[manufacturer]=id,name&filters[enabled]=true&${params.join(
    '&'
  )}`;

  dispatch(loadingSimilarProducts(true));
  return ecommApiService
    .get(url, {
      headers: { 'location-id': account.branchId, 'account-id': account.id },
    })
    .then((res) => {
      res.data = productListTransformer(res);
      dispatch(getSimilarProductsSuccess(res.data.data));
      dispatch(getPricesForSimilarProducts(account, res.data.data));
    })
    .catch((error) => {
      dispatch(getSimilarProductsFailure(error));
      return error;
    });
};

export const loadingProduct = (loading) => ({
  type: LOADING_PRODUCT,
  loading,
});

export const loadingPricesForProduct = (loadingPrices) => ({
  type: LOADING_PRICES_FOR_PRODUCT,
  loadingPrices,
});

export const getProductSuccess = (data) => ({
  type: GET_PRODUCT_SUCCESS,
  loading: false,
  data,
});

export const getProductFailure = (error) => {
  const err = get(
    error,
    'message',
    'There was an error retrieving this product'
  );
  return {
    type: GET_PRODUCT_FAILURE,
    loading: false,
    error: err,
  };
};

export const getPricesForProductSuccess = (data) => ({
  type: GET_PRICES_FOR_PRODUCT_SUCCESS,
  loadingPrices: false,
  data,
});

export const setSelectedVariant = (variant) => ({
  type: SET_SELECTED_VARIANT,
  variant,
});

export const setVariantQuantity = (selectedQuantity) => ({
  type: SET_VARIANT_QUANTITY,
  selectedQuantity,
});

export const setVariantVolume = (selectedVolume) => ({
  type: SET_VARIANT_VOLUME,
  selectedVolume,
});

export const getPricesForProduct = (
  product,
  account,
  options,
  currentlySelectedVariant = null,
  hidePrice
) => (dispatch) => {
  dispatch(loadingPricesForProduct(true));

  const PRICING_URL = 'v2/product-prices';

  const payload = {
    accountId: account.id,
    locationId: account.branchId,
    productId: [
      ...getProductIds([product]),
      // ...getProductCrossSellIds(product.attributes.cross_sells),
    ],
  };

  const headers = {
    headers: {
      'account-id': account.id,
    },
  };

  return ecommApiService
    .post(PRICING_URL, payload, headers)
    .then((response) => {
      if (hidePrice) {
        dispatch(getPricesForProductFailure());
      } else {
        const [productWithPrices] = injectPricesIntoProducts(
          account.locationCode,
          [product],
          response.data.data
        );
        const variant =
          currentlySelectedVariant ||
          get(productWithPrices, 'attributes.variants[0]');

        // TODO: turn code below back on once cross sells are working properly
        // This is due to existing functionality that is not working with the
        // new product feed.

        // productWithPrices.attributes.cross_sells = injectPricesIntoCrossSells(
        //   account.locationCode,
        //   productWithPrices.attributes.cross_sells,
        //   response.data
        // );

        dispatch(getPricesForProductSuccess(productWithPrices));
        if (get(options, 'origin') === 'Product Detail Page') {
          dispatch(gtmViewPDP(productWithPrices, variant));
        }
      }
    })
    .catch(() => {
      dispatch(getPricesForProductFailure());
    });
};

export const getProduct = (
  id,
  account,
  options,
  currentlySelectedVariant = null,
  localizedCatalog = true,
  localizedCatalogUs = true,
  hidePrice = false
) => (dispatch) => {
  let url = `/v2/products/${id}?fields[product]=id,name,categories,crossSells,description,enabled,rank,federallyRestricted,commonName&include=variant,manufacturer,activeIngredient&fields[variant]=productId,description,id,name,isBulk,status,manufacturers,enabled,position,unitMeasure,unitQty,unitSize,agrianId,digitalVariantName,image,totalVolumeOptions&fields[manufacturer]=id,name&fields[activeIngredient]=id,name,percentage&filters[enabled]=true`;
  if (localizedCatalog && localizedCatalogUs) {
    url = url.concat(`&filters[branchIds]=${account.branchId}`);
  } else {
    account.id.length === 8
      ? (url = url.concat(`&filters[branchIds]=${account.branchId}`))
      : null;
  }
  const canadian = isCanada(account.locationCode);
  dispatch(loadingProduct(true));
  return ecommApiService
    .get(url, {
      headers: { 'location-id': account.branchId, 'account-id': account.id },
    })
    .then(async (res) => {
      res.data = productTransformer(res);
      res.id = res.data.id;

      const variant =
        currentlySelectedVariant || get(res.data, 'attributes.variants[0]');
      dispatch(setSelectedVariant(variant));

      const agrianId = variant.agrian_id;
      if (!canadian && agrianId) {
        const labelRes = await getProductLabelsV2(agrianId, account.id);
        const labelBaseURI = get(labelRes, 'data.meta.baseURI');
        const document = get(labelRes, 'data.data[0].filename');
        res.data.label = `${labelBaseURI}${document}`;
      } else {
        res.data.label = false;
      }

      dispatch(getProductSuccess(res.data));
      dispatch(
        getPricesForProduct(res.data, account, options, variant, hidePrice)
      );
      return res;
    })
    .catch((error) => {
      dispatch(getProductFailure(error));
      return error;
    });
};
