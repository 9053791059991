import React from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';

import withTerms from 'components/Terms/withTerms';
import TermsPropTypes from 'components/Terms/termsPropTypes';
import { FETCHING_TERMS } from 'components/Terms/termsConstants';
import TermsContent from 'shared/ui/TermsContent';

import styles from './styles';

export const TermsCard = ({
  classes,
  terms,
  closeModal,
  acceptTerms,
  declineTerms,
}) => {
  const termsContent =
    terms.status === FETCHING_TERMS ? (
      <MaterialLoadingIcon />
    ) : (
      <TermsContent terms={terms} />
    );

  return (
    <div className={classes.termsCardContainer}>
      <Card className={classes.termsCard}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h3 className={classes.h3}>Terms of Use and Privacy Policy</h3>
            <div
              role="button"
              className={classes.closeModal}
              onClick={closeModal}
              onKeyPress={closeModal}
              tabIndex="0"
            >
              <img src={closeModal} alt="close" />
            </div>
          </div>
          <hr className={classes.hr} />
        </div>
        <div className={classes.termsContent}>{termsContent}</div>
      </Card>
      <Card className={classes.acceptTermsCard}>
        <hr className={classes.noMargin} />
        <div className={classes.buttonContainer}>
          <MaterialButton
            onClick={() => declineTerms('/')}
            value="Decline"
            type="secondary"
          />
          <MaterialButton onClick={() => acceptTerms('/')} value="Accept" />
        </div>
      </Card>
    </div>
  );
};

TermsCard.propTypes = {
  terms: TermsPropTypes.isRequired,
  closeModal: PropTypes.func.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  declineTerms: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

TermsCard.defaultProps = {
  classes: {},
};

export default withTerms(withStyles(styles)(TermsCard));
