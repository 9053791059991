import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Button from '@nutrien/uet-react/Button';
import bankCheckImage from 'assets/bank-check.svg';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog/ConfirmDeleteDialog';

import './paymentMethodRow.css';

class PaymentMethodRow extends Component {
  state = {
    editing: false,
    aliasValue: '',
    confirmDelete: false,
  };

  accountName = (accountLastThree) => {
    const { bankName, alias } = this.props.paymentMethod;
    const accountName = alias || bankName;

    return `${accountName} - ending in ${accountLastThree}`;
  };

  accountDetails = () => {
    const { achAccountType, tokenOwner } = this.props.paymentMethod;
    const { bankAccountType } = tokenOwner;
    return `${bankAccountType} ${achAccountType}`;
  };

  toggleEdit = () => {
    const { editing } = this.state;
    this.setState({
      editing: !editing,
      aliasValue: '',
    });
  };

  confirmDelete = () => {
    this.setState({
      confirmDelete: true,
    });
  };

  cancelDelete = () => {
    this.setState({
      confirmDelete: false,
    });
  };

  handleDelete = () => {
    this.props.deletePayment();
    this.cancelDelete();
  };

  handleUpdateChange = (event) => {
    this.setState({
      aliasValue: event.target.value,
    });
  };

  handleUpdate = (event) => {
    event.preventDefault();
    this.props.updatePayment({
      alias: this.state.aliasValue,
    });
    this.toggleEdit();
  };

  renderEditView = () => {
    const { aliasValue } = this.state;

    return (
      <div className="payment-method-edit-container">
        <form className="payment-method-edit-form" onSubmit={this.handleUpdate}>
          <label htmlFor="edit-account-name">Bank Account Nickname</label>
          <input
            id="edit-account-name"
            onSubmit={this.handleUpdate}
            onChange={this.handleUpdateChange}
            value={aliasValue}
          />
          <div className="edit-form-buttons">
            <Button
              className="edit-cancel"
              id="edit-cancel"
              variant="outlined"
              onClick={this.toggleEdit}
            >
              Cancel
            </Button>
            <Button
              disabled={aliasValue.trim().length === 0}
              id="edit-save"
              className="edit-save"
              variant="contained"
              onClick={this.handleUpdate}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  };

  renderNormalView = () => (
    <div className="payment-row-buttons">
      <button
        id="payment-account-edit"
        className="payment-method-edit"
        onClick={this.toggleEdit}
        style={{ backgroundColor: 'white' }}
      >
        Edit Nickname
      </button>
      <IconButton
        id="payment-account-delete"
        name="paymentAccountDelete"
        onClick={this.confirmDelete}
        aria-label="Delete Payment Method"
        className="payment-method-delete-image"
      >
        <Delete style={{ color: '#a0a0a0' }} />
      </IconButton>
    </div>
  );

  render() {
    const { paymentMethod } = this.props;
    const { accountNumberLastThree } = paymentMethod;
    const { editing } = this.state;

    return (
      <li
        className={editing ? 'payment-method-row open' : 'payment-method-row'}
      >
        <div className="payment-method-name-container">
          <img
            src={bankCheckImage}
            className="payment-method-image"
            alt="Bank acct."
          />
          <div>
            <div
              className="payment-method-name account-name"
              data-hj-suppress=""
            >
              {this.accountName(accountNumberLastThree)}
            </div>
            <div
              className="payment-method-details account-details"
              data-hj-suppress=""
            >
              {this.accountDetails()}
            </div>
          </div>
        </div>

        {editing ? this.renderEditView() : this.renderNormalView()}

        <ConfirmDeleteDialog
          handleDelete={this.handleDelete}
          cancelDelete={this.cancelDelete}
          confirmDelete={this.state.confirmDelete}
        />
      </li>
    );
  }
}

PaymentMethodRow.propTypes = {
  paymentMethod: PaymentPropTypes.paymentAccount.isRequired, // eslint-disable-line react/no-typos
  updatePayment: PropTypes.func.isRequired,
  deletePayment: PropTypes.func.isRequired,
};

export default PaymentMethodRow;
