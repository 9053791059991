import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: { color: theme.palette.grey[400], marginLeft: 24 },
});

const NoElementsMessage = ({ elementName, classes }) => (
  <Typography className={classes.root}>
    There are No {elementName} at This Time
  </Typography>
);

NoElementsMessage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  elementName: PropTypes.string.isRequired,
};

NoElementsMessage.defaultProps = {
  classes: {},
};

export default withStyles(styles)(NoElementsMessage);
