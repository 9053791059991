/* eslint-disable no-script-url */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  TextField,
  Input,
} from 'nutrien-common-components-react/dist/mdc/InputField';
import Button from '@nutrien/uet-react/Button';
import Close from '@nutrien/uet-react/icons/Close';
import Search from '@nutrien/uet-react/icons/Search';
import ShoppingCart from '@nutrien/uet-react/icons/ShoppingCart';

import history from '../../../history';
import './HeaderSearchComponent.scss';

class HeaderSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.headerSearchInputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.searchExpanded &&
      this.props.searchExpanded &&
      this.headerSearchInputRef.current.inputElement.current
    ) {
      this.headerSearchInputRef.current.inputElement.current.focus();
    }
  }

  checkForInputEnter = (event) => {
    if (event.key === 'Enter') {
      this.props.updateAutofillExpand(false, this.handleSearch);
    }
  };

  handleInputChange = (event) => {
    const inputValue = event.target.value;
    this.props.updateSearchQuery(inputValue, () => {
      this.props.handleSearchInput(inputValue);
    });
  };

  handleSearch = () => this.props.searchSubmit(this.props.query);

  handleSearchEvent = (event) => {
    event.preventDefault();
    this.handleSearch();
  };

  handleSearchClear = () => {
    this.props.updateSearchQuery('', () => {
      if (
        history.location &&
        history.location.pathname.substr(0, 9) === '/products'
      ) {
        this.handleSearch();
      }
    });
  };

  selectSingleProduct = (id) => {
    this.props.selectSingleProduct(id);
  };

  truncateNames = (manufacturer, product) => {
    let name = `${product}`.substring(0, 30);
    if (name.length >= 20) {
      name += '...';
    }
    return name;
  };

  handleAutoFillExpand = () => this.props.updateAutofillExpand(true);

  handleBlur = (e) => {
    if (
      e.relatedTarget === null ||
      (e.relatedTarget !== null && e.relatedTarget.className === null) ||
      (e.relatedTarget !== null &&
        e.relatedTarget.className !== 'header-search__autofill-list__item')
    ) {
      this.props.updateAutofillExpand(false);
    }
  };

  autofillShouldShow = () =>
    this.props.autofillResults.items && this.props.autofillResults.items.length;

  render() {
    const {
      autofillResults,
      searchExpanded,
      query,
      autofillExpanded,
      globalSearch,
      accounts,
    } = this.props;
    return (
      // TODO: Don't break keyboard accessibility, uncomment the below and add an onKeyDown handler
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <>
        {accounts.length ? (
          <div
            className={classNames('header-search-field', {
              show: searchExpanded,
              hide: !searchExpanded,
            })}
            role="button"
            tabIndex={0}
            onClick={this.handleAutoFillExpand}
            onBlur={this.handleBlur}
          >
            <form
              className="header-search__form"
              onSubmit={(e) => this.handleSearchEvent(e)}
            >
              <TextField
                className="header-search__textfield"
                data-test="header-search-textfield"
                outlined
                trailingIcon={
                  query.length > 0 && (
                    <Close data-test="header-search-closeicon" />
                  )
                }
                onTrailingIconSelect={this.handleSearchClear}
              >
                <Input
                  ref={this.headerSearchInputRef}
                  data-test="header-search-input"
                  placeholder="Search Products Catalog"
                  value={query}
                  onKeyPress={this.checkForInputEnter}
                  onChange={this.handleInputChange}
                  type="search"
                />
              </TextField>
            </form>
            <div className="search-icon__mobile">
              <Search
                onClick={this.handleSearch}
                data-test="header-search-icon-desktop"
              />
            </div>
            {globalSearch && (
              <div
                className={`header-search__autofill-container ${
                  autofillResults.items &&
                  autofillResults.items.length &&
                  autofillExpanded &&
                  query.length
                    ? 'header-search__autofill-container--visible'
                    : 'header-search__autofill-container--hidden'
                }`}
              >
                <ul>
                  <li className="header-search__autofill-list__header">
                    <ShoppingCart />
                    <span>Products</span>
                  </li>
                  {this.autofillShouldShow() &&
                    autofillResults.items.slice(0, 5).map((result, index) => (
                      <li aria-posinset={index} key={result.deprecatedAeid}>
                        <button
                          onClick={() =>
                            this.selectSingleProduct(result.deprecatedAeid)
                          }
                          className="header-search__autofill-list__item"
                        >
                          <div className="header-search__autofill-icon">
                            <ShoppingCart type="brand-white" />
                          </div>
                          <span>
                            {this.truncateNames(
                              result.manufacturer_name,
                              result.name
                            )}
                          </span>
                        </button>
                      </li>
                    ))}
                </ul>
                <Button
                  className="header-search__autofill-button"
                  margin="none"
                  onClick={this.handleSearch}
                >
                  View all results
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  }
}

HeaderSearchComponent.propTypes = {
  autofillExpanded: PropTypes.bool,
  autofillResults: PropTypes.shape({
    filters: PropTypes.shape({
      activeIngredients: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  globalSearch: PropTypes.bool,
  handleSearchInput: PropTypes.func.isRequired,
  query: PropTypes.string,
  searchExpanded: PropTypes.bool,
  searchSubmit: PropTypes.func.isRequired,
  // selectSingleActiveIngredient: PropTypes.func.isRequired,
  selectSingleProduct: PropTypes.func.isRequired,
  updateAutofillExpand: PropTypes.func.isRequired,
  updateSearchQuery: PropTypes.func.isRequired,
};

HeaderSearchComponent.defaultProps = {
  autofillExpanded: false,
  autofillResults: {
    filters: {
      activeIngredients: [],
    },
    items: [],
  },
  globalSearch: false,
  query: '',
  searchExpanded: false,
};

export default HeaderSearchComponent;
