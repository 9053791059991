import {
  FETCHING_INTERCOM_HASH,
  FETCH_INTERCOM_HASH_FAILURE,
  FETCH_INTERCOM_HASH_SUCCESS,
} from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_INTERCOM_HASH_SUCCESS:
      return action.hash;
    case FETCHING_INTERCOM_HASH:
    case FETCH_INTERCOM_HASH_FAILURE:
      return null;
    default:
      return state;
  }
};
