import React, { Component } from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import { BreadCrumbContainer, BreadCrumb } from 'shared/ui/BreadCrumbs';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { AccountDescriptionText } from 'shared/ui';

import CartStepper from '../CartStepper';
import MobileCartStepper from '../MobileCartStepper';
import CheckoutItem from '../CheckoutItem';
import CartButtons from '../CartButtons/CartButtons';
import { defaultAccountPropTypes } from '../../shared/propTypes';
import NetPriceComponent from '../../../../shared/ui/TotalNetPricingFeatureFlag/NetPriceComponent';

import { NO_PRICE_CART_MESSAGE } from '../../../../shared/constants/ecommMessageText';

import './ReviewPage.scss';

const Loading = () => (
  <div className="jsLoadingWrapper">
    <MaterialLoadingIcon />
  </div>
);

const Error = () => <ApiErrorMessage entityName="The cart" />;

export const CartItems = ({ cartItems, grossSubTotal, netSubTotal }) => {
  const noPrice = [];
  const cartArray = [];
  let fullCart = [];

  cartItems.cartData.forEach((cartItem) => {
    if (!cartItem.variant.price) {
      noPrice.push(cartItem);
    } else {
      cartArray.push(cartItem);
    }
  });

  fullCart = [...noPrice, ...cartArray];

  return (
    <div className="review-cart">
      {fullCart.map((cartItem) => (
        <CheckoutItem cartItem={cartItem} key={cartItem.variant_sku} />
      ))}
      <div className="review-cart__inner-cart-container-bottom">
        <div className="review-cart__cart-container-content-top">
          <span className="review-cart__sub-total-label">Subtotal</span>
          <span className="review-cart__inner-content-label">Tax</span>
          <span className="review-cart__inner-content-label">
            Shipping / Delivery
          </span>
        </div>
        <div className="review-cart__cart-container-content-bottom">
          <span className="review-cart__sub-total">
            {grossSubTotal === 0 || netSubTotal === 0
              ? NO_PRICE_CART_MESSAGE
              : NetPriceComponent(netSubTotal)}
          </span>
          <span className="review-cart__inner-content-data">
            {NO_PRICE_CART_MESSAGE}
          </span>
          <span className="review-cart__inner-content-data">
            {NO_PRICE_CART_MESSAGE}
          </span>
        </div>
      </div>
    </div>
  );
};

export class ReviewPage extends Component {
  state = {
    grossSubTotal: 0,
    netSubTotal: 0,
  };

  componentDidMount() {
    const { selectedAccount } = this.props;
    if (this.checkDefaultAccount()) {
      this.props.getCart(selectedAccount.id);
    }

    if (!isEmpty(selectedAccount)) {
      this.props.getBranchAddress(selectedAccount.id, selectedAccount.branchId);
    }

    const { history, location } = this.props;
    if (!location.originatedFromCart) {
      history.push('/cart');
    }

    this.getSubtotal();
  }

  componentDidUpdate(prevProps) {
    const { selectedAccount } = this.props;
    const { selectedAccount: prevSelectedAccount } = prevProps;

    if (prevProps.selectedAccount.id !== selectedAccount.id) {
      this.props.getCart(selectedAccount.id);
    }

    if (prevSelectedAccount !== selectedAccount) {
      const { getBranchAddress } = this.props;
      getBranchAddress(selectedAccount.id, selectedAccount.branchId);
    }
  }

  getSection() {
    const { cartItems, history, location } = this.props;
    const { loading, error } = cartItems;

    let section;

    // cart is loading
    if (loading) {
      section = <Loading />;
    }
    // there has been a network error
    else if (error) {
      section = <Error />;
    }
    // no data exists -> empty cart
    else if (!location.originatedFromCart) {
      history.push('/cart');
    } else {
      section = (
        <CartItems
          {...this.props}
          grossSubTotal={this.state.grossSubTotal}
          netSubTotal={this.state.netSubTotal}
        />
      );
    }

    return section;
  }

  getSubtotal() {
    const { cartItems } = this.props;
    const { grossSubTotal, netSubTotal } = this.calcSubTotal(
      cartItems.cartData
    );

    this.setState({
      grossSubTotal,
      netSubTotal,
    });
  }

  getPOSection = () => {
    const {
      cartItems: {
        preferences: { poNumber },
      },
    } = this.props;
    const poSection = (
      <div className="review-cart__section-header-wrapper">
        <span className="review-cart__review-detail-headline">
          Purchase Order Number:
        </span>
        <span
          className="review-cart__confirmation-data--inline"
          data-test="product-order-number"
        >
          {poNumber || 'N/A'}
        </span>
      </div>
    );
    return poSection;
  };

  getBranchDetails = () => {
    const {
      cartItems: {
        branchAddress: { address },
      },
    } = this.props;
    let branchDetails;

    if (address && !address.error) {
      branchDetails = (
        <div>
          <h2 className="review-cart__sub-headline">Branch Details</h2>
          <div>
            <span className="review-cart__confirmation-detail-header">
              Name
            </span>
            <span className="review-cart__confirmation-detail-data">
              {address.data.name}
            </span>
          </div>
          <div>
            <span className="review-cart__confirmation-detail-header">
              Branch #
            </span>
            <span className="review-cart__confirmation-detail-data">
              {address.data.id}
            </span>
          </div>
          <div>
            <span className="review-cart__confirmation-detail-header">
              Phone
            </span>
            <span className="review-cart__confirmation-detail-data">
              {address.data.phone}
            </span>
          </div>
        </div>
      );
    } else {
      branchDetails = <div />;
    }
    return branchDetails;
  };

  getCropConsultant = () => {
    const {
      cartItems: {
        selectedAccount: { cropConsultant },
      },
    } = this.props;
    let cropConsultantInfo;

    if (cropConsultant) {
      cropConsultantInfo = (
        <div>
          <h2 className="review-cart__sub-headline">
            Nutrien Ag Solutions Representative
          </h2>
          <div>
            <span className="review-cart__confirmation-detail-header">
              Name
            </span>
            <span className="review-cart__confirmation-detail-data">
              {cropConsultant.firstName} {cropConsultant.lastName}
            </span>
          </div>
          <div>
            <span className="review-cart__confirmation-detail-header">
              Email
            </span>
            <span className="review-cart__confirmation-detail-data">
              {cropConsultant.workEmailAddress}
            </span>
          </div>
          <div>
            <span className="review-cart__confirmation-detail-header">
              Phone
            </span>
            <span className="review-cart__confirmation-detail-data">
              {cropConsultant.workPhone || null}
            </span>
          </div>
        </div>
      );
    } else {
      cropConsultantInfo = <div />;
    }
    return cropConsultantInfo;
  };

  getAccountNumber = () => {
    const { cartItems } = this.props;

    const accountNumber = (
      <div className="review-cart__section-header-wrapper">
        <span className="review-cart__review-detail-headline">Account:</span>
        <div className="review-cart__confirmation-data--inline">
          {' '}
          <span>
            <AccountDescriptionText account={cartItems.selectedAccount} />
          </span>
        </div>
      </div>
    );
    return accountNumber;
  };

  getLogistics = () => {
    const {
      cartItems: {
        isPickup: { pickup },
        addresses: { all },
      },
      cartItems,
    } = this.props;
    const branchAddress = get(
      this.props,
      'cartItems.branchAddress.address',
      {}
    );
    let logistics;

    if (pickup) {
      if (branchAddress.name) {
        logistics = (
          <div>
            <h2 className="review-cart__sub-headline">Logistics</h2>
            <span className="review-cart__review-detail-headline">
              Pick up at
            </span>
            <div className="review-cart__details-blocked-wrapper">
              <span className="review-cart__details-blocked">
                {branchAddress.name}
              </span>
              <span className="review-cart__details-blocked">
                {branchAddress.address}
              </span>
              <span className="review-cart__details-blocked">
                {branchAddress.city} {branchAddress.stateorProvince}
                {branchAddress.zipPostalCode}
              </span>
            </div>
          </div>
        );
      } else {
        logistics = <div />;
      }
    } else if (cartItems.addresses.all && !cartItems.addresses.all.error) {
      const { defaultAddress } = all;
      logistics = (
        <div>
          <h2 className="review-cart__sub-headline">Logistics</h2>
          <span className="review-cart__review-detail-headline">
            Deliver to
          </span>
          <div className="review-cart__details-blocked-wrapper">
            <span className="review-cart__details-blocked">
              {defaultAddress.description}
            </span>
            <span className="review-cart__details-blocked">
              {defaultAddress.addressLineOne}
            </span>
            <span className="review-cart__details-blocked">
              {defaultAddress.city} {defaultAddress.provinceStateValue}{' '}
              {defaultAddress.zipPostalCode}
            </span>
          </div>
        </div>
      );
    } else {
      logistics = <div />;
    }
    return logistics;
  };

  getDeliveryDate = () => {
    const { cartItems } = this.props;
    const { preferences } = cartItems;
    const deliveryDate = (
      <div>
        <span className="review-cart__review-detail-headline">
          Desired {cartItems.isPickup.pickup ? 'Pickup' : 'Delivery'} Date:
        </span>
        <span className="review-cart__confirmation-data--inline">
          {moment(preferences.date, 'MM/DD/YYYY').format('dddd, MMMM DD, YYYY')}
        </span>
        <p className="review-cart__cart-paragraph">
          Your selected date will be reviewed and confirmed by your branch
        </p>
      </div>
    );
    return deliveryDate;
  };

  getMemo = () => {
    const {
      cartItems: { preferences },
    } = this.props;
    const orderMemo = (
      <div>
        <span className="review-cart__review-detail-headline">
          Additional Order Instructions:
        </span>
        <div>
          <p className="review-cart__cart-paragraph" data-test="jsOrderMemo">
            {preferences.orderMemo || 'N/A'}
          </p>
        </div>
      </div>
    );
    return orderMemo;
  };

  calcSubTotal = (cartItems) => {
    let grossSubTotal = 0;
    let netSubTotal = 0;
    let totalGrossPrice;
    let totalNetPrice;
    let grossPrice;
    let netPrice;
    // eslint-disable-next-line consistent-return
    forEach(cartItems, (cartItem) => {
      grossPrice = get(cartItem, 'variant.price', 0);
      netPrice = get(cartItem, 'variant.net_price', 0);
      const { total_volume: totalVolume } = cartItem;

      if (grossPrice) {
        totalGrossPrice = grossPrice * totalVolume;
        grossSubTotal += totalGrossPrice;
      } else {
        grossSubTotal = 0;
        return false;
      }
      if (netPrice) {
        totalNetPrice = netPrice * totalVolume;
        netSubTotal += totalNetPrice;
      } else {
        netSubTotal = 0;
        return false;
      }
    });

    return { grossSubTotal, netSubTotal };
  };

  handleNext = () => {
    const {
      selectedAccount,
      user,
      cartItems,
      postOrder,
      cartItems: {
        addresses,
        isPickup,
        preferences: {
          orderMemo,
          poNumber,
          date,
          isCustomApplied,
          applicationMemo,
          customCropCare,
        },
      },
    } = this.props;
    const selectedAccountId = get(selectedAccount, 'id', null);
    const selectedBranchId = get(selectedAccount, 'branchId', null);

    const data = {
      date,
      orderMemo,
      poNumber,
      isCustomApplied,
      defaultAddress: addresses.all.defaultAddress,
      isPickup: isPickup.pickup,
      selectedAccountId,
      applicationMemo,
      customCropCare,
      selectedBranchId,
    };

    postOrder(data, cartItems.cartData, selectedAccount, user);
    this.props.history.push({
      pathname: '/cart/thank-you',
      originatedFromCart: true,
      cartItems,
    });
  };

  handlePrev = () => {
    this.props.history.push({
      pathname: '/cart/logistics',
      originatedFromCart: true,
    });
  };

  checkDefaultAccount() {
    const { selectedAccount } = this.props;
    return selectedAccount && selectedAccount.id;
  }

  render() {
    const {
      cartItems: {
        loading,
        selectedAccount: { number },
      },
    } = this.props;
    return (
      <div className="review-cart__outer-cart-page-wrapper">
        <CartStepper currentStep="review" accountNumber={number} />
        <h2 className="review-cart__headline">Review</h2>
        <div className="review-cart__section-container">
          <div className="review-cart__outer-detail-section-wrapper">
            <div className="review-cart__detail-section-wrapper-double">
              <div className="review-cart__inner-detail-section-wrapper">
                {this.getPOSection()}
                {this.getBranchDetails()}
              </div>
              <div className="review-cart__outer-account-section-wrapper">
                {this.getAccountNumber()}
                {this.getCropConsultant()}
              </div>
            </div>
            <div className="review-cart__detail-section-wrapper-col">
              <div className="review-cart__edit-button-outer-wrapper">
                <BreadCrumbContainer noPadding>
                  <BreadCrumb link="/cart/logistics" text="Edit" mobile />
                </BreadCrumbContainer>
              </div>
              {this.getLogistics()}
              {this.getDeliveryDate()}
              {this.getMemo()}
            </div>
          </div>
          <div className="review-cart__test">
            <div className="review-cart__cart-section-wrapper">
              <div className="review-cart__inner-cart-section-wrapper">
                <div className="review-cart__edit-button-outer-wrapper-inner">
                  <BreadCrumbContainer noPadding>
                    <BreadCrumb link="/cart" text="Edit" mobile />
                  </BreadCrumbContainer>
                </div>
                <span className="review-cart__sub-headline--inline">
                  Your Cart
                </span>
                {this.getSection()}
              </div>
            </div>
          </div>
          <span className="review-cart__inner-cart-footer">
            Your account will not be charged until all prices and additional
            charges are confirmed.
          </span>
        </div>
        <div className="review-cart__top-cart-footer">
          <span className="review-footer__top-text">
            Please allow up to 1-2 business days for your order to be confirmed
            by your branch.
          </span>
          <span className="review-footer__top-text--small">
            After submitting your order request, your Nutrien Ag Solutions
            branch may contact you to review the order, pricing details, and
            regulatory compliance.
          </span>
        </div>
        <div className="review-cart__cart-footer">
          <MobileCartStepper
            currentStep="review"
            accountNumber={number}
            history={this.props.history}
            handleFinal={() => this.handleNext()}
            loading={loading}
          />
          <CartButtons
            disabled={loading}
            accountNumber={number}
            handleSubmit={this.handleNext}
            currentStep="review"
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}

ReviewPage.propTypes = {
  location: PropTypes.shape({
    originatedFromCart: PropTypes.bool.isRequired,
  }).isRequired,
  getBranchAddress: PropTypes.func.isRequired,
  cartItems: PropTypes.shape().isRequired,
  getCart: PropTypes.func.isRequired,
  selectedAccount: defaultAccountPropTypes.isRequired,
  postOrder: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
  }).isRequired,
};

CartItems.propTypes = {
  cartItems: PropTypes.shape({
    cartData: PropTypes.array.isRequired,
  }).isRequired,
  grossSubTotal: PropTypes.number.isRequired,
  netSubTotal: PropTypes.number.isRequired,
};

export default ReviewPage;
