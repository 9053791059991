import createToggleReducer from 'shared/utils/createToggleReducer';
import {
  CLEAR_CUSTOMERS,
  CUSTOMERS_FETCHING,
  CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_FETCH_SUCCESS,
} from '../actions';

export default createToggleReducer({
  trueActions: [CUSTOMERS_FETCHING],
  falseActions: [
    CLEAR_CUSTOMERS,
    CUSTOMERS_FETCH_FAILURE,
    CUSTOMERS_FETCH_SUCCESS,
  ],
});
