import React, { useEffect, useState, useContext, createContext } from 'react';
import { useSelector } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  selectUser,
  selectUserStatus,
  selectSelectedAccount,
  selectAccounts,
} from 'selectors';
import { PropTypes } from 'prop-types';
import { useAuth } from '@nutrien/fe-domain-utils';
import { FETCHING_USER, NO_USER } from 'shared/config/userConstants';

const LDContext = createContext();

const LaunchDarklyContextProvider = ({ children }) => {
  const ldClient = useLDClient();
  const [customerIsIdentified, setCustomerIsIdentified] = useState(false);
  // grab cached user metadata from Auth0 tokens
  const auth = useAuth();
  const user = useSelector(selectUser());
  const userStatus = useSelector(selectUserStatus);
  const { locationCode } = useSelector(selectSelectedAccount());
  const accounts = useSelector(selectAccounts());

  useEffect(() => {
    const auth0LocationCode =
      auth.user?.['https://nutrienagsolutions.com/country'];
    const email =
      auth.user?.['https://nutrienagsolutions.com/email'] || user.email;
    if (ldClient && userStatus !== FETCHING_USER && userStatus !== NO_USER) {
      const allBranches = Array.from(
        new Set(accounts.list.map((account) => account.branchId))
      );

      const launchDarklyUser = {
        key: email,
        country: auth0LocationCode || locationCode,
        custom: {
          customerType: user?.userDetails?.customerType,
          branchId: user?.userDetails?.defaultBranchId,
          allBranches,
        },
      };

      ldClient
        .identify(launchDarklyUser)
        .finally(() => setCustomerIsIdentified(true));
    }
  }, [ldClient, user, auth.user, userStatus, accounts, locationCode]);

  return (
    <LDContext.Provider value={customerIsIdentified}>
      {children}
    </LDContext.Provider>
  );
};

LaunchDarklyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/*
  README IF USING/UPDATING:
  This hook only accounts for identifying a customer in Launch Darkly
  and does not consider when an employee is mirroring without a
  customer selected.
*/
const useLaunchDarklyIdentifiedCustomer = () => useContext(LDContext);

export { LaunchDarklyContextProvider, useLaunchDarklyIdentifiedCustomer };
