import PropTypes from 'prop-types';

// represents the set of all preferences for a single communication method (e.g. a single email address)
export const notificationPreferencesSet = PropTypes.shape({
  'ACCOUNT-NEW_STATEMENT': PropTypes.bool,
  'ACCOUNT-NEW_INVOICE': PropTypes.bool,
  'ACCOUNT-PAYMENT_DUE': PropTypes.bool,
  'ACCOUNT-PAYMENT_FAILED': PropTypes.bool,
  'ACCOUNT-PAYMENT_PAST_DUE': PropTypes.bool,
  'ACCOUNT-PAYMENT_SUCCESSFUL': PropTypes.bool,
  'ACCOUNT-PAYMENT_PENDING': PropTypes.bool,
});

// represents a single communication method, paired with the set of preferences for that method
export const notificationPreference = PropTypes.shape({
  value: PropTypes.string,
  type: PropTypes.string,
  preferences: notificationPreferencesSet.isRequired,
});

// represents the complete response from the API, grouped by email and phone numbers
export const notificationPreferences = PropTypes.shape({
  emails: PropTypes.arrayOf(notificationPreference),
  phoneNumbers: PropTypes.arrayOf(notificationPreference),
});

/**
 * The values of every email and phoneNumber for a particular preference (e.g. "New Invoice" or "Payment Due")
 * This is the smallest building block of the categorized Preferences selected
 */
export const categorizedPreference = PropTypes.shape({
  emails: PropTypes.objectOf(PropTypes.bool),
  phoneNumbers: PropTypes.objectOf(PropTypes.bool),
});

/**
 * The object associated with a particular "category" (e.g. "Acount"). Its shape looks like this
 * (with potentially many preferences- note that each key's value is of type categorizedPreference:
 *
 * {
 *    'New Invoice': {
 *      emails: {},
 *      phoneNumbers: {}
 *    },
 *    'New Statement': {
 *      emails: {},
 *      phoneNumbers: {}
 *    }
 * }
 */
export const preferenceCategory = PropTypes.objectOf(categorizedPreference);

/* a set of categorized preferences will look something like this
(where "Account" is one of potentially many categories or groupings of preferences- a preferenceCategory,
and "New Invoice" is one of potentially many preferences (each a categorizedPreference object),
and each key in the emails and phoneNumbers objects contain the value for that email or phone for that preference:

{
  Account: {
    'New Invoice': {
      emails: {
        'email_1@email.com': true,
        'email_2@email.com': false,
      },
      phoneNumbers: {
        '(100) 867-5309': false,
        '(200) 867-5309': false,
      },
    },
  },
}

*/
export const categorizedPreferences = PropTypes.objectOf(preferenceCategory);

export const notificationPreferencesError = PropTypes.shape({
  error: PropTypes.error,
  errorType: PropTypes.string,
});

export const notificationsPreferencesStatus = PropTypes.shape({
  isUpdating: PropTypes.bool,
  updateError: notificationPreferencesError,
});
