const styles = {
  container: {
    fontSize: 13,
    height: '100%',
    overflow: 'auto',
    padding: '1em',
    '& p': {
      lineHeight: '18px',
      color: '#828282',
      margin: '16px 0',
    },
    '& h2': {
      fontSize: 20,
      fontWeight: 600,
      margin: '16px 0',
      color: '#464646',
    },
    '& h3': {
      fontSize: 20,
      height: 35,
      fontWeight: 400,
      lineHeight: '1em',
      margin: '16px 0',
      color: '#000',
    },
    '& li': {
      fontSize: 13,
      color: '#828282',
    },
    '& table': {
      display: 'block',
      maxWidth: '100%',
      overflowX: 'scroll',
    },
    '& tr': {
      borderBottom: 'solid 1px #cccccc',
    },
    '& th': {
      minWidth: '100px',
    },
  },
};

export default styles;
