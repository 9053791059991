import { RANGE_TYPES } from '../constants/amountRangeConstants';
import { sanitizeAmountRange } from './filterHelpers';

const isRangeValid = (amountRange) => {
  const range = sanitizeAmountRange(amountRange);

  const minVal = Number(range[RANGE_TYPES.MIN] || -Infinity);
  const maxVal = Number(range[RANGE_TYPES.MAX] || Infinity);

  return minVal <= maxVal;
};

export function isInputValid(input = '') {
  const numberCommas = '^-?([\\d]{1,3},([\\d]{3},)*[\\d]{3}(\\.[\\d]{1,2})?)$';
  const numberNoCommas = '^-?([\\d]+(\\.[\\d]{1,2})?)$';
  const empty = '^$';
  const NUMBER_REG_EX = `${numberCommas}|${numberNoCommas}|${empty}`;

  const regEx = new RegExp(NUMBER_REG_EX);
  return regEx.test(input);
}

export function validateInputRange(range) {
  const areInputsValid = range.reduce(
    (isValid, currentValue) => isInputValid(currentValue) && isValid,
    true
  );

  return areInputsValid && isRangeValid(range);
}
