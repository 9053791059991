import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

const WeatherStoryButton = () => {
  const { weatherStory } = useFlags();
  return (
    <>
      {weatherStory && (
        <Link to="/?showHubModal=true&modalType=weather">
          <Button variant="outlined">Weather Story</Button>
        </Link>
      )}
    </>
  );
};

export default WeatherStoryButton;
