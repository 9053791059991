import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { gtmAccountOpenItemsInteraction } from 'store/middleware/TagManager/gtmActions';
import { exportOpenItems } from 'components/Account/OpenItems/actions';
import { ExportLinksList } from 'shared/ui';

const OpenItemsExportLinksContainer = ({
  accountId,
  handleExportOpenItems,
  gtmEvent,
}) => {
  const exportAction = (fileType) => {
    gtmEvent('Download', `All Data | ${fileType}`);
    return handleExportOpenItems(fileType, accountId);
  };
  return <ExportLinksList exportAction={exportAction} fileTypes={['csv']} />;
};

OpenItemsExportLinksContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  handleExportOpenItems: PropTypes.func.isRequired,
  gtmEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.selectedAccount.id,
});
const mapDispatchToProps = {
  handleExportOpenItems: exportOpenItems,
  gtmEvent: gtmAccountOpenItemsInteraction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenItemsExportLinksContainer);
