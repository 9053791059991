import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import unauthenticatedRoutes from 'shared/config/unauthenticatedRoutes';
import useTerms from 'components/Terms/useTerms';
import { FETCHING_TERMS, TERMS_FETCHED } from 'components/Terms/termsConstants';

export const useTermsService = (termsDocument = 'mvp-cxh-terms') => {
  const { pathname } = useLocation();
  const {
    terms: { termsVersion, status: termsStatus },
    fetchTerms,
    showTermsModal,
    termsAccepted,
  } = useTerms();
  const isUnauthenticatedRoute = useMemo(
    () => unauthenticatedRoutes.includes(pathname),
    [pathname]
  );

  useEffect(() => {
    if (
      termsDocument &&
      !termsVersion &&
      termsStatus !== TERMS_FETCHED &&
      termsStatus !== FETCHING_TERMS &&
      termsStatus !== 'TERMS_FETCH_ERROR'
    ) {
      fetchTerms(termsDocument);
    }
  }, [termsVersion, termsStatus, termsDocument, fetchTerms]);

  useEffect(() => {
    if (isUnauthenticatedRoute || termsAccepted !== false) {
      return;
    }

    showTermsModal();
  }, [termsAccepted, isUnauthenticatedRoute, showTermsModal]);
};

export default useTermsService;
