import axios from 'shared/lib/cxhApiService';

import { TERMS_DOC } from './termsConstants';

export const fetchContent = (termsDocument = TERMS_DOC) =>
  axios.post('v1/terms/content', { termsDocument });

export const updateTermsAcceptance = (
  termsVersion,
  termsDocument = TERMS_DOC
) =>
  axios.post('v1/terms/update', {
    termsVersion,
    termsDocument,
  });

export const validTermsAcceptance = (termsAcceptance, version) =>
  termsAcceptance && version
    ? String(termsAcceptance) === String(version) ||
      // this is a temporary solution to allow version 4 terms acceptance to qualify
      // for version 5 acceptance.
      (String(termsAcceptance) === '4' && String(version) === '5')
    : false;
