import get from 'lodash/get';
import { GET_PRICES_FOR_PRODUCTS_SUCCESS, SEARCH_LOADING } from 'const/product';
import {
  productToImpressionFieldObject,
  accountLocationCodeToCurrency,
} from '../utils';

/**
 * Corresponds to the searchLoading searchAction
 *
 * NOTE: as of CXH-2666, only sending the query count,
 * and not the actual search queries.
 */
export const searchEvent = () => ({
  event: 'ECOM_SearchProducts',
  ecom: {},
});

export const productListPageImpression = (
  action,
  { selectedAccount: { locationCode } }
) => {
  const { data: pricedProductsList } = action;

  const impressions = pricedProductsList.map((product, idx) =>
    productToImpressionFieldObject(
      product,
      get(product, 'attributes.variants[0]'),
      'Product Listing Page',
      idx
    )
  );

  return {
    event: 'EEC_ViewProductImpression',
    ecommerce: {
      currencyCode: accountLocationCodeToCurrency(locationCode),
      impressions,
    },
  };
};

export const productSearchEventsMap = {
  [SEARCH_LOADING]: searchEvent,
  [GET_PRICES_FOR_PRODUCTS_SUCCESS]: productListPageImpression,
};
