import { connect } from 'react-redux';
import isEmployeeView from 'utils/isEmployeeView';
import getLinkedAccounts from 'reducers/userSelectors';
import Landing from './Landing';
import {
  gtmClickFeaturedPartnerLearnMore,
  gtmClickPLPEcommPromo,
} from '../../store/middleware/TagManager/gtmActions';

const mapStateToProps = (state) => ({
  browser: state.browser,
  isEmployeeView: isEmployeeView(state),
  isLinked:
    getLinkedAccounts(state).length !== 0 &&
    state.user.status === 'USER_FETCHED',
  selectedAccount: state.selectedAccount,
});

// Put dispatch to props here
const mapDispatchToProps = {
  gtmClickFeaturedPartnerLearnMore,
  gtmClickPLPEcommPromo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
