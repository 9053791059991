import React, { Component, Fragment } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
} from 'nutrien-common-components-react/dist/mdc';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Chip from '@material-ui/core/Chip';
import Flag from 'assets/contractFlag.svg';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import { REMOVE_FILTER, nameTypes } from '../FilterDropdown/constants';
import {
  sanitizeFilterParams,
  getUrlParameters,
} from '../../utils/filterHelpers';

import './styles.scss';
import styles from './styles';

const filterTypes = [
  'chemicals',
  'fertilizers',
  'activeIngredients',
  'manufacturers',
  'cropTypes',
];

export class FilterPanel extends Component {
  state = {
    showFilterSection: false,
  };

  componentDidUpdate(prevProps) {
    const prevCount = get(prevProps, 'products.meta.totalCount', 0);
    const currentCount = get(this.props, 'products.meta.totalCount', 0);

    if (currentCount > prevCount) {
      this.updateState(currentCount);
    }
  }

  updateState = (currentCount) => {
    this.setState({ unfilteredCount: currentCount });
  };

  handleClearFilters = () => {
    // eslint-disable-next-line no-shadow
    const { history, location, gtmProductListClearFilters } = this.props;
    const search = location.search.substring(1);
    const searchParameters = getUrlParameters(search);

    delete searchParameters.activeIngredients;
    delete searchParameters.chemicals;
    delete searchParameters.fertilizers;
    delete searchParameters.manufacturers;
    delete searchParameters.contTypes;
    const searchParameterKeys = Object.keys(searchParameters);

    const queryParams = searchParameterKeys.map((key) => {
      const value = searchParameters[key];
      return `${key}=${value}`;
    });
    if (this.state.unfilteredCount) {
      gtmProductListClearFilters(this.state.unfilteredCount);
    }
    history.push(`/products?${queryParams.join('&')}`);
  };

  toggleFilterSection = () => {
    const showFilterSection = !this.state.showFilterSection;

    this.setState({
      showFilterSection,
    });
  };

  handleUpdateUrl = (e) => {
    const { history, location } = this.props;
    const { checked } = e.target;
    const search = location.search.substring(1);
    const searchParameters = getUrlParameters(search);
    const clickedId = 'sku';
    if (checked) {
      // Add the id to the url
      searchParameters.contTypes = searchParameters.contTypes
        ? (searchParameters.contTypes += `%2C${clickedId}`)
        : (searchParameters.contTypes = clickedId);
    } else {
      // Remove the id from the url
      const sanitizedParams = sanitizeFilterParams(searchParameters.contTypes, [
        { id: 'sku' },
      ]);
      const indexOfClicked = sanitizedParams.indexOf(clickedId);
      if (indexOfClicked !== -1) {
        sanitizedParams.splice(indexOfClicked, 1);
      }
      searchParameters.contTypes = sanitizedParams.join('%2C');

      if (searchParameters.contTypes === '') {
        delete searchParameters.contTypes;
      }
    }
    const searchParameterKeys = Object.keys(searchParameters);
    const queryParams = searchParameterKeys.map((key) => {
      const value = searchParameters[key];
      return `${key}=${value}`;
    });

    history.push(`/products?${queryParams.join('&')}`);
  };

  // TODO: possibly re-use this in conjunction with FilterDropdown removeFilterId method
  removeFilterId = (id, filterType, name) => {
    const {
      history,
      location,
      products: {
        meta: { filters },
      },
      gtmFilterProducts,
    } = this.props;
    const search = location.search.substring(1);
    const searchParameters = getUrlParameters(search);

    if (searchParameters[filterType]) {
      const sanitizedActiveIngredientParams = sanitizeFilterParams(
        searchParameters[filterType],
        filters[filterType],
        filterType
      );
      const indexOfClicked = sanitizedActiveIngredientParams.indexOf(id);

      if (indexOfClicked !== -1) {
        sanitizedActiveIngredientParams.splice(indexOfClicked, 1);
      }

      searchParameters[filterType] = sanitizedActiveIngredientParams.join(
        '%2C'
      );
    }

    if (!searchParameters[filterType]) {
      delete searchParameters[filterType];
    }

    const searchParameterKeys = Object.keys(searchParameters);

    const queryParams = searchParameterKeys.map((key) => {
      const value = searchParameters[key];
      return `${key}=${value}`;
    });

    gtmFilterProducts(REMOVE_FILTER, `${nameTypes[filterType]} | ${name}`);
    history.push(`/products?${queryParams.join('&')}`);
  };

  renderCheckbox = () => {
    const checkedFilters = get(this.props, 'products.checkedIds.contract', []);
    const checked = checkedFilters.includes('sku');
    const {
      classes,
      location: { search },
      productsLoading,
    } = this.props;

    // if URL contains a filter that is not contract, disable contract filter
    const filterParams = search.split('&');
    const disableContractFilter =
      productsLoading ||
      (filterParams.length > 1 && !filterParams.includes('contTypes=sku'));

    return (
      <FormControlLabel
        value="Contracted by SKU"
        data-test="contract-checkbox"
        classes={{
          label: classes.checkboxLabelTop,
          root: classes.labelRoot,
        }}
        control={
          <Checkbox
            color="primary"
            disabled={disableContractFilter}
            checked={checked}
            onClick={(e) => this.handleUpdateUrl(e)}
          />
        }
        label="Contracted by SKU"
        labelPlacement="end"
      />
    );
  };

  renderFilterSection = () => {
    const {
      products: { checkedIds },
      flags: { ecommContracts, ecommCroptypeFilter },
    } = this.props;
    const { classes, ...noClasses } = this.props;

    return (
      <>
        <div className="dialog-chip-wrapper">
          {filterTypes.map((type) =>
            checkedIds[type] ? this.renderChips(type) : null
          )}
        </div>
        {ecommContracts && (
          <ul className={classes.checkBoxWrapper}>
            <li className={classes.innerCheckboxWrapper}>
              {this.renderCheckbox()}
              <img
                src={Flag}
                alt="Contracted Products Icon"
                className={classes.contractedProductsIcon}
              />
            </li>
          </ul>
        )}
        {filterTypes.map((type) => {
          const filterDropdown = (
            <FilterDropdown
              data-test={`filter-panel-${type}`}
              key={type}
              type={type}
              {...noClasses}
            />
          );

          if (type === 'cropTypes' && !ecommCroptypeFilter) {
            return false;
          }
          return filterDropdown;
        })}
      </>
    );
  };

  renderClearButton = () => (
    <button
      onClick={this.handleClearFilters}
      className="filter-header__button--clear"
      data-test="clear-button"
    >
      Clear All Filters
    </button>
  );

  renderChips = (filterType) => {
    const {
      products,
      location: { search },
    } = this.props;
    const filterList = products.meta.filters[filterType];
    const checkedTypes = [];

    if (!isEmpty(filterList)) {
      filterList.forEach((checkboxType) =>
        search.split('&').forEach((filter) => {
          const value = filter.split('=');
          if (
            value[0] === filterType &&
            value[1]
              .replace(/%20/g, ' ')
              .replace(/~/g, ',')
              .split('%2C')
              .includes(checkboxType.value)
          )
            checkedTypes.push(checkboxType);
        })
      );
    }

    return checkedTypes.map((checkboxType) => {
      const { name, value, count } = checkboxType;

      const filteredId = value.replace(',', '~');
      const { removeFilterId } = this;
      const oversized = name.length > 31;
      const truncated = `${name.substring(0, 28)}...`;
      return (
        <Chip
          key={name}
          label={oversized ? truncated : name}
          onDelete={() => removeFilterId(filteredId, filterType, name)}
          variant={count > 0 ? 'default' : 'outlined'}
          color="primary"
          className={`filter-chip${oversized ? ' oversized' : ''}`}
          data-test="filter-chip"
        />
      );
    });
  };

  renderMobileFilterPanel = () => {
    const { toggleMobileFilterPanel, mobileFilterPanelOpen } = this.props;
    const { handleClearFilters } = this;
    const filterSection = this.renderFilterSection();

    return (
      <Dialog
        open={mobileFilterPanelOpen}
        className="dialog-wrapper--outer"
        scrollable
        onClosed={() => toggleMobileFilterPanel()}
      >
        <DialogContent>
          <div className="dialog-wrapper" data-test="dialog-wrapper">
            <div className="dialog-header">
              <span className="dialog-header__headline">Filters</span>
              <button
                className="dialog-header__clear-button"
                onClick={() => {
                  handleClearFilters();
                  toggleMobileFilterPanel();
                }}
                data-test="dialog-cancel-button"
              >
                Clear Filters
              </button>
            </div>
            {filterSection}
          </div>
        </DialogContent>
        <DialogActions className="dialog-button-wrapper">
          <DialogButton
            onClick={() => {
              toggleMobileFilterPanel();
            }}
            className="dialog-wrapper__button--cancel"
            action="close"
          >
            Cancel
          </DialogButton>
          <DialogButton
            onClick={() => toggleMobileFilterPanel()}
            className="dialog-wrapper__button--apply"
            data-test="dialog-button--apply"
            action="accept"
          >
            Apply
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { mobileFilterPanelOpen } = this.props;
    const filters = get(this.props, 'products.meta.filters', null);

    return (
      <div className="filter-wrapper">
        {mobileFilterPanelOpen && this.renderMobileFilterPanel()}
        <div className="filter-wrapper--desktop">
          <div className="filter-header">
            <span>Filters</span>
            {this.renderClearButton()}
          </div>
          {filters && this.renderFilterSection()}
          <div className="filter-header">{this.renderClearButton()}</div>
        </div>
      </div>
    );
  }
}

FilterPanel.propTypes = {
  location: RouterPropTypes.location.isRequired,
  classes: PropTypes.shape({
    labelRoot: PropTypes.string,
    checkboxLabelTop: PropTypes.string,
    checkBoxWrapper: PropTypes.string,
    innerCheckboxWrapper: PropTypes.string,
    contractedProductsIcon: PropTypes.string,
  }).isRequired,
  history: RouterPropTypes.history.isRequired,
  toggleMobileFilterPanel: PropTypes.func.isRequired,
  mobileFilterPanelOpen: PropTypes.bool.isRequired,
  productsLoading: PropTypes.bool.isRequired,
  gtmProductListClearFilters: PropTypes.func.isRequired,
  products: PropTypes.shape({
    meta: PropTypes.shape({
      filters: PropTypes.shape({
        chemicals: PropTypes.array,
        fertilizers: PropTypes.array,
        activeIngredients: PropTypes.array,
        manufacturers: PropTypes.array,
      }),
    }),
    checkedIds: PropTypes.shape({}),
  }).isRequired,
  flags: PropTypes.shape({
    ecommContracts: PropTypes.bool,
    ecommCroptypeFilter: PropTypes.bool,
  }).isRequired,
  gtmFilterProducts: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
  withLDConsumer()
)(FilterPanel);
