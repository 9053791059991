import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { Checkbox } from 'nutrien-common-components-react/dist/mdc';
import PaymentMethodAlias from 'components/Payments/OtherComponents/PaymentMethodAlias';
import * as PaymentPropTypes from '../../../../PaymentPropTypes';

import styles from './styles';

class SaveAccountComponent extends Component {
  handleChange = (event) => {
    const { name, value } = event.target;

    const updatedValue =
      name === 'savePaymentMethod' ? !this.props.savePaymentMethod : value;

    const updatedEvent = {
      target: {
        name,
        value: updatedValue,
      },
    };

    this.props.onChange(updatedEvent);
  };

  render() {
    const {
      savePaymentMethod,
      errors,
      formName,
      paymentMethodAlias,
      classes,
      validate,
      isRecurring,
    } = this.props;

    return (
      <div className={classes.saveAccount}>
        <div className={classes.storeAccountRowItems}>
          <Checkbox
            name="savePaymentMethod"
            onChange={this.handleChange}
            checked={savePaymentMethod || isRecurring}
            disabled={isRecurring}
            description=""
          />

          <div className={classes.savePaymentMethodText}>
            Store this account number for future payments.
          </div>
        </div>

        {savePaymentMethod || isRecurring ? (
          <PaymentMethodAlias
            errors={errors}
            formName={formName}
            paymentMethodAlias={paymentMethodAlias}
            onChange={this.handleChange}
            validate={validate}
          />
        ) : null}
      </div>
    );
  }
}

/* eslint react/prop-types: 0 */
SaveAccountComponent.propTypes = {
  isRecurring: PropTypes.bool.isRequired,
  errors: PaymentPropTypes.errors.isRequired, // eslint-disable-line react/no-typos
  formName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  paymentMethodAlias: PropTypes.string,
  savePaymentMethod: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

SaveAccountComponent.defaultProps = {
  paymentMethodAlias: '',
};

export default withStyles(styles)(SaveAccountComponent);
