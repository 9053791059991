// here
const singularUnitOfMeasure = {
  BG: 'bag',
  BAG: 'bag',
  GA: 'gallon',
  GAL: 'gallon',
  LB: 'pound',
  OZ: 'ounce',
  OZA: 'ounce acre',
  QT: 'quart',
  default: 'unit',
  AB: 'bulk pack',
  BO: 'bottle',
  BT: 'bottle',
  BOT: 'bottle',
  CA: 'case',
  CV: 'case',
  CS: 'case',
  CO: 'co-pack',
  DR: 'drum',
  JU: 'jug',
  ZJU: 'jug',
  SH: 'shuttle',
  TE: 'tote',
  PH: 'pack',
  PAK: 'pack',
  PAC: 'pack',
  PA: 'pail',
  ZPA: 'pail',
  EA: 'each',
  AC: 'acre',
  ACR: 'acre',
  PK: 'package',
  FT: 'foot',
  FL: 'flask',
  FT3: 'cubic ft',
  LT: 'liter',
  L: 'liter',
  ML: 'milliliter',
  CN: 'can',
  MB: 'mini-bulk',
  PL: 'pallet',
  KE: 'keg',
  KG: 'kilogram',
  GR: 'gram',
  G: 'gram',
  MT: 'metric ton',
  TO: 'metric ton',
  TK: 'tank',
  RL: 'roll',
  BA: 'bale',
  UT: 'units treated',
  PC: 'piece',
  CT: 'carton',
  TN: 'net ton',
  BX: 'box',
  JHR: 'hour',
  PAA: 'pair',
  ST: 'pint',
  ZBX: 'standard seed box',
  ZSU: 'standard seed unit',
  ZMB: 'mini bulk',
};

const pluralUnitOfMeasure = {
  BG: 'bags',
  BAG: 'bags',
  GA: 'gallons',
  GAL: 'gallons',
  LB: 'pounds',
  OZ: 'ounces',
  OZA: 'ounce acres',
  QT: 'quarts',
  default: 'units',
  AB: 'bulk packs',
  BO: 'bottles',
  BT: 'bottles',
  BOT: 'bottles',
  CA: 'cases',
  CV: 'cases',
  CS: 'cases',
  CO: 'co-packs',
  DR: 'drums',
  JU: 'jugs',
  ZJU: 'jugs',
  SH: 'shuttles',
  TE: 'totes',
  PH: 'packs',
  PAK: 'packs',
  PAC: 'packs',
  PA: 'pails',
  ZPA: 'pails',
  EA: 'each',
  AC: 'acres',
  ACR: 'acres',
  PK: 'packages',
  FL: 'flasks',
  FT: 'feet',
  FT3: 'cubic ft',
  LT: 'liters',
  L: 'liters',
  ML: 'milliliters',
  CN: 'cans',
  MB: 'mini-bulks',
  PL: 'pallets',
  KE: 'kegs',
  KG: 'kilograms',
  GR: 'grams',
  G: 'grams ',
  MT: 'metric tons',
  TO: 'metric tons',
  TK: 'tanks',
  RL: 'rolls',
  BA: 'bales',
  UT: 'units treated',
  PC: 'pieces',
  CT: 'cartons',
  TN: 'net tons',
  BX: 'boxes',
  JHR: 'hours',
  PAA: 'pairs',
  ST: 'pints',
  ZBX: 'standard seed boxes',
  ZSU: 'standard seed units',
  ZMB: 'mini bulk',
};

export const getUnitOfMeasureLong = (abreviation, quantity = 1) => {
  const pluralize = quantity === 0 || quantity > 1;
  return pluralize
    ? pluralUnitOfMeasure[abreviation] || pluralUnitOfMeasure.default
    : singularUnitOfMeasure[abreviation] || singularUnitOfMeasure.default;
};

export default singularUnitOfMeasure;
