import { TableCell, TableRow } from '@material-ui/core';
import userPropType from 'shared/config/userPropType';
import React from 'react';

const UsersTableRow = ({ user }) => (
  <TableRow className="users-table-row">
    <TableCell className="users-table-cell-name" data-hj-suppress="">
      {user.firstName} {user.lastName}
    </TableCell>
    <TableCell className="users-table-cell-email">{user.email}</TableCell>
  </TableRow>
);

UsersTableRow.propTypes = {
  user: userPropType.isRequired,
};

export default UsersTableRow;
