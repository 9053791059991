import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import { ApiErrorMessage } from 'shared/ui';
import withPermits from 'components/Permits/withPermits';
import PermitsIcon from 'shared/ui/PermitsIcon';
import './PermitsCard.scss';

export const PermitsCard = ({
  activeChemicalPermits,
  expiredPermits,
  fetchPermitsError,
  isFetchingPermits,
}) => {
  const activePermitsLink = {
    pathname: '/permits',
    state: { filter: 'Active' },
  };

  const expiredPermitsLink = {
    pathname: '/permits',
    state: { filter: 'Inactive' },
  };

  return (
    <Card component={Box} p={2} height="100%">
      {isFetchingPermits && <MaterialLoadingIcon />}
      {fetchPermitsError && <ApiErrorMessage entityName="Permits" />}
      {!fetchPermitsError && !isFetchingPermits && (
        <div className="permits-card__card-content">
          <h5 className="card-title-2">Permits</h5>
          <div
            className="permits-card__content-centered"
            data-test="permitsCardIcon"
          >
            <Link to="/permits">
              <PermitsIcon className="permits-card__icon-huge" />
            </Link>
          </div>
          <div className="permits-card__permit-count-wrapper">
            <div
              className={classNames(
                'permits-card__content-centered',
                'permits-card__permit-count-content'
              )}
            >
              <Link to={activePermitsLink}>
                <h6
                  className="permits-card__permit-count-text"
                  data-test="permitsCardActiveChemicalPermitsCount"
                >
                  {activeChemicalPermits.length}
                </h6>
                <p className="permits-card__permit-count-description">
                  active permits
                </p>
              </Link>
            </div>
            <div
              className={classNames(
                'permits-card__content-centered',
                'permits-card__permit-count-content'
              )}
            >
              <Link to={expiredPermitsLink}>
                <h6
                  className="permits-card__permit-count-text"
                  data-test="permitsCardExpiredPermitsCount"
                >
                  <span>{expiredPermits.length}</span>
                </h6>
                <p className="permits-card__permit-count-description">
                  inactive permits
                </p>
              </Link>
            </div>
          </div>
          <Link to="/permits">
            <span className="permits-card__view-all-link">
              <Button variant="text">
                View All Permits
                <ChevronRightIcon />
              </Button>
            </span>
          </Link>
        </div>
      )}
    </Card>
  );
};

PermitsCard.propTypes = {
  fetchPermitsError: PropTypes.instanceOf(Error),
  activeChemicalPermits: PropTypes.arrayOf(PropTypes.object),
  expiredPermits: PropTypes.arrayOf(PropTypes.object),
  isFetchingPermits: PropTypes.bool,
};

PermitsCard.defaultProps = {
  fetchPermitsError: undefined,
  activeChemicalPermits: [],
  expiredPermits: [],
  isFetchingPermits: false,
};

export default withPermits(PermitsCard);
