import get from 'lodash/get';
import { GET_ADDRESSES_FAILURE } from '../actionTypes';

export default (error) => {
  if (typeof error === 'string')
    return {
      type: GET_ADDRESSES_FAILURE,
      error,
    };

  return {
    type: GET_ADDRESSES_FAILURE,
    error: get(error, 'message', 'There was an error retrieving the address'),
  };
};
