import React from 'react';
import closeModal from 'assets/close-modal.svg';
import FormCard from 'shared/ui/MaterialFormCard';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './cookiesModalStyles';

const CookiesRequiredModal = ({ closeModalFunc, classes }) => (
  <div
    className={classes.modalContainer}
    onClick={closeModalFunc}
    onKeyDown={closeModalFunc}
    role="presentation"
  >
    <FormCard>
      <div className={classes.cookiesModalTitleArea}>
        <p className={classes.cookiesModalTitle}>Please accept cookies</p>
        <div
          role="button"
          onClick={closeModalFunc}
          onKeyPress={closeModalFunc}
          className={classes.closeModal}
          tabIndex="0"
        >
          <img src={closeModal} alt="close" />
        </div>
      </div>
      <p className={classes.cookiesModalDescription}>
        This feature uses cookies in order to provide you with a great user
        experience.
      </p>
      <p className={classes.cookiesModalPreferredBrowser}>
        Note: Nutrien&apos;s preferred browser is Chrome
      </p>
      <div className={classes.cookiesModalButtonsContainer}>
        <MaterialButton
          type="secondary"
          id="sel-cookies-download-chrome"
          value="Download Chrome"
          className={classes.cookiesModalGetChrome}
          onClick={() => {
            window.open('https://www.google.com/chrome/', '_blank');
          }}
        />
        <MaterialButton
          value="Accept Cookies"
          id="sel-cookies-accept-cookies"
          className={classes.cookiesModalAcceptCookies}
          onClick={() => {
            window.open(
              'https://www.whatismybrowser.com/guides/how-to-enable-cookies/auto',
              '_blank'
            );
          }}
        />
      </div>
    </FormCard>
  </div>
);

CookiesRequiredModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeModalFunc: PropTypes.func.isRequired,
};

export default withStyles(styles)(CookiesRequiredModal);
