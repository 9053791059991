// ACTIONS
export const SET_BANK_ACCOUNT_TOKEN = 'SET_BANK_ACCOUNT_TOKEN';
export const FETCH_BANK_NAME_SUCCESS = 'FETCH_BANK_NAME_SUCCESS';
export const FETCH_BANK_NAME_FAIL = 'FETCH_BANK_NAME_FAIL';
export const CLEAR_BANK_NAME = 'CLEAR_BANK_NAME';
export const CLEAR_PAYMENT_STATE = 'CLEAR_PAYMENT_STATE';
export const SET_DATE_INITIATED = 'SET_DATE_INITIATED';
export const CLEAR_SUBMIT_PAYMENT_ERROR_CODE =
  'CLEAR_SUBMIT_PAYMENT_ERROR_CODE';
export const SUBMIT_PAYMENT_IN_PROGRESS = 'SUBMIT_PAYMENT_IN_PROGRESS';
export const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS';
export const SUBMIT_PAYMENT_FAILURE = 'SUBMIT_PAYMENT_FAILURE';
export const REVIEW_PAYMENT_PAGE_PROCESSING_IN_PROGRESS =
  'REVIEW_PAYMENT_PAGE_PROCESSING_IN_PROGRESS';
export const REVIEW_PAYMENT_PAGE_PROCESSING_SUCCESS =
  'REVIEW_PAYMENT_PAGE_PROCESSING_SUCCESS';
export const REVIEW_PAYMENT_PAGE_PROCESSING_FAILURE =
  'REVIEW_PAYMENT_PAGE_PROCESSING_FAILURE';
export const SET_SAVED_BANK_ACCOUNTS = 'SET_SAVED_BANK_ACCOUNTS';
export const SET_SAVED_PAYMENT_METHOD = 'SET_SAVED_PAYMENT_METHOD';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHOD_IN_PROGRESS = 'GET_PAYMENT_METHOD_IN_PROGRESS';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILURE = 'GET_PAYMENT_METHOD_FAILURE';
export const SET_TERMS_AND_CONDITIONS_CONTENT =
  'SET_TERMS_AND_CONDITIONS_CONTENT';
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE';
export const DELETE_BANK_ACCOUNT_IN_PROGRESS =
  'DELETE_BANK_ACCOUNT_IN_PROGRESS';
export const DELETE_BANK_ACCOUNT_RESPONSE_CODE =
  'DELETE_BANK_ACCOUNT_RESPONSE_CODE';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const CLEAR_DELETE_BANK_ACCOUNT_RESPONSE =
  'CLEAR_DELETE_BANK_ACCOUNT_RESPONSE';
export const UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS =
  'UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS';
export const UPDATE_BANK_ACCOUNT_ALIAS_SUCCESS =
  'UPDATE_BANK_ACCOUNT_ALIAS_SUCCESS';
export const UPDATE_BANK_ACCOUNT_ALIAS_FAILURE =
  'UPDATE_BANK_ACCOUNT_ALIAS_FAILURE';
export const CLEAR_UPDATE_ALIAS_STATUS = 'CLEAR_UPDATE_ALIAS_STATUS';
export const DUPLICATE_PAYMENT = 'DUPLICATE_PAYMENT';
export const BANK_ACCOUNT_LIMIT_REACHED = 'BANK_ACCOUNT_LIMIT_REACHED';
export const CLEAR_DUPLICATE_PAYMENT_STATUS = 'CLEAR_DUPLICATE_PAYMENT_STATUS';
export const UPDATE_BANK_ACCOUNT_FORM = 'UPDATE_BANK_ACCOUNT_FORM';
export const CLEAR_BANK_ACCOUNT_FORM = 'CLEAR_BANK_ACCOUNT_FORM';
export const SET_PAYMENT_FORM_ERRORS = 'SET_PAYMENT_FORM_ERRORS';
export const SET_ACCOUNT_COUNTRY_CODE = 'SET_ACCOUNT_COUNTRY_CODE';
export const CLEAR_BANK_ACCOUNT = 'CLEAR_BANK_ACCOUNT';
export const errorTitleText = 'Oops! Something went wrong.';
export const LOADING_DATA_IN_PROGRESS = 'LOADING_DATA_IN_PROGRESS';
export const LOADING_DATA_SUCCESS = 'LOADING_DATA_SUCCESS';
export const LOADING_DATA_FAILURE = 'LOADING_DATA_FAILURE';
export const SET_PAYMENT_ERROR = 'SET_PAYMENT_ERROR';
export const CLEAR_PAYMENT_ERROR = 'CLEAR_PAYMENT_ERROR';
export const SET_SHOW_FORM_ERRORS = 'SET_SHOW_FORM_ERRORS';
export const CLEAR_SHOW_FORM_ERRORS = 'CLEAR_SHOW_FORM_ERRORS';
export const SET_PAYMENT_FLOW_TYPE = 'SET_PAYMENT_FLOW_TYPE';
export const CLEAR_PAYMENT_FLOW_TYPE = 'CLEAR_PAYMENT_FLOW_TYPE';

export const ROUTING_MODAL_DESCRIPTION =
  'Your bank routing number is the first group of numbers at the bottom of your checks.';

export const ACCOUNT_MODAL_DESCRIPTION =
  'Your bank account number is the second group of numbers at the bottom of your checks.';

export const MAKE_PAYMENT_STEPS = {
  PAYMENT_INFO: 1,
  REVIEW: 2,
  CONFIRMATION: 3,
};

export const ModalType = {
  bankAccountModal: 1,
  routingModal: 2,
  accountModal: 3,
  transitNumberModal: 4,
  institutionNumberModal: 5,
};

export const MODAL_TYPES = {
  BANK_ACCOUNT_MODAL: 'bankAccountModal',
  CANADA_ACCOUNT_MODAL: 'canadaAccountModal',
  ROUTING_MODAL: 'routingModal',
  ACCOUNT_MODAL: 'accountModal',
  TRANSIT_NUMBER_MODAL: 'transitNumberModal',
  INSTITUTION_NUMBER_MODAL: 'institutionNumberModal',
};

export const savePaymentErrorMessage =
  'We couldn’t process your payment due to an error. \nPlease try again.';

export const BANK_ACCOUNT_TYPES = {
  PERSONAL: 'Personal',
  CORPORATE: 'Corporate',
};

export const ACH_ACCOUNT_TYPES = {
  CHECKING: 'Checking',
  SAVINGS: 'Savings',
  CORPORATE: 'Corporate',
  CORPORATE_SAVINGS: 'Corp Savings',
};

export const ERROR_TYPES = {
  REGISTER_TOKEN: 'REGISTER_TOKEN',
  DUPLICATE_TRANSACTION: 'DUPLICATE_TRANSACTION',
  OTHER: 'OTHER',
};

export const COUNTRY_CODES = {
  CANADA: 'CAN',
  USA: 'USA',
};

export const COUNTRY_US = {
  name: 'United States',
  code: 'USA',
};

export const COUNTRY_CAN = {
  name: 'Canada',
  code: 'CAN',
};

export const ROUTES = {
  ADD_BANK_ACCOUNT: 'add-bank-account',
  MAKE_A_PAYMENT: 'make-a-secure-payment',
  PRE_PAY_PAYMENT: 'add-funds-to-prepay',
};

export const PAYMENT_FLOW_TYPES = {
  MAKE_A_PAYMENT: 'make-a-payment',
  PRE_PAY_PAYMENT: 'pre-pay',
  INVOICE_PAYMENT: 'invoice',
};

export const DEFAULT_PAYMENT_METHODS = {
  PREPAY: {
    name: 'prepay',
    label: 'Prepay',
    tokenValue: 'prepay',
    featureFlag: 'pay-by-prepay',
  },
  NEW_BANK_ACCOUNT: {
    name: 'newBankAccount',
    label: 'Add New Bank Account',
    tokenValue: 'newBankAccount',
  },
};

export const RecurringPaymentFrequencies = {
  BI_WEEKLY: { code: 'bi-weekly', name: 'Bi-Weekly' },
  MONTHLY: { code: 'monthly', name: 'Monthly' },
};

export const errorNotificationMessage =
  'There were errors with the information you provided. Please correct these issues and re-submit.';

export const TC_MAKING_PAYMENT_HEADER_USA = `Terms & Conditions for Making a Payment`;
export const TC_MAKING_PAYMENT_HEADER_CANADA = `Terms & Conditions for Making a Business Pre-Authorized Debit Payment`;

export const US_PAYMENT_TERMS_AND_CONDITIONS_DOC = 'cxh-us-payments';
export const CAN_PAYMENT_TERMS_AND_CONDITIONS_DOC = 'cxh-can-payments';

export const payByPrepaySavedAccount = 'prepay';
