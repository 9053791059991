import React from 'react';
import useUser from 'hooks/useUser';

const withUser = (Component) => (props) => {
  const user = useUser();

  return <Component {...user} {...props} />;
};

export default withUser;
