import employeeLoggingIn from './employeeLoggingIn';
import loginEmployeeFromSession from './loginEmployeeFromSession';
import employeeLoginFailure from './employeeLoginFailure';
import * as service from '../employeeLoginService';

export default (email, password) => (dispatch) => {
  dispatch(employeeLoggingIn());

  return service
    .post(email, password)
    .then((session) => {
      // TODO: move decoding into service call
      dispatch(loginEmployeeFromSession(session));
    })
    .catch((err) => dispatch(employeeLoginFailure(err)));
};
