import PropTypes from 'prop-types';

export const userPrivacyPreference = PropTypes.shape({
  enabled: PropTypes.bool,
  preference: PropTypes.string,
});

export const userPreferences = PropTypes.shape({
  'ACCOUNT-NEW_INVOICE': PropTypes.bool,
  'ACCOUNT-NEW_STATEMENT': PropTypes.bool,
  'ACCOUNT-ORDER_CANCELLATION': PropTypes.bool,
  'ACCOUNT-ORDER_CONFIRMATION': PropTypes.bool,
  'ACCOUNT-ORDER_FULFILLED': PropTypes.bool,
  'ACCOUNT-ORDER_INVOICED': PropTypes.bool,
  'ACCOUNT-ORDER_SUBMITTED': PropTypes.bool,
  'ACCOUNT-PAYMENT_DUE': PropTypes.bool,
  'ACCOUNT-PAYMENT_FAILED': PropTypes.bool,
  'ACCOUNT-PAYMENT_PAST_DUE': PropTypes.bool,
  'ACCOUNT-PAYMENT_PENDING': PropTypes.bool,
  'ACCOUNT-PAYMENT_SUCCESSFUL': PropTypes.bool,
});

export const user = PropTypes.shape({
  error: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      message: PropTypes.string,
    }),
    status: PropTypes.number,
  }),
  status: PropTypes.string,
  userData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    emails: PropTypes.arrayOf(
      PropTypes.shape({
        preferred: PropTypes.bool,
        type: PropTypes.string,
        value: PropTypes.string,
        preferences: userPreferences,
      })
    ),
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        preferences: userPreferences,
        preferred: PropTypes.bool,
        type: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    privacy: PropTypes.arrayOf(userPrivacyPreference),
  }).isRequired,
});

export default {
  userPrivacyPreference,
  userPreferences,
  user,
};
