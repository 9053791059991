import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import PaymentValidationMessageComponent from '../ValidationMessage/PaymentValidationMessageComponent';
import { buildClassName } from '../../Utils/PaymentUtil';
import * as PaymentPropTypes from '../../PaymentPropTypes';

import styles from './styles';
import commonStyles from '../../commonPaymentStyles';

const finalStyles = (theme) => merge({}, styles, commonStyles(theme));

class PaymentMethodAlias extends Component {
  onValidate = (event) => {
    const { name, value } = event.target;
    const fieldData = { value };
    const { validate } = this.props;

    validate({ field: name, fieldData });
  };

  render() {
    const {
      errors,
      formName,
      paymentMethodAlias,
      classes,
      onChange,
    } = this.props;

    return (
      <div className={classes.paymentMethodAliasContainer}>
        <label
          className={classes.paymentMethodAliasLabel}
          htmlFor="paymentMethodAlias"
        >
          Bank Account Nickname
          <span className={classes.paymentMethodAliasLabelSmall}>
            (Optional)
          </span>
        </label>
        <input
          name="paymentMethodAlias"
          form={formName}
          type="text"
          className={buildClassName(
            'paymentMethodAlias',
            classes.paymentMethodAliasInput,
            errors,
            classes.error
          )}
          onBlur={this.onValidate}
          onChange={onChange}
          value={paymentMethodAlias}
        />
        <PaymentValidationMessageComponent
          errors={errors}
          field="paymentMethodAlias"
        />
      </div>
    );
  }
}

PaymentMethodAlias.propTypes = {
  formName: PropTypes.string,
  errors: PaymentPropTypes.errors.isRequired, // eslint-disable-line react/no-typos
  classes: PropTypes.shape({
    paymentMethodAliasLabelSmall: PropTypes.string,
    paymentMethodAliasLabel: PropTypes.string,
    paymentMethodAliasContainer: PropTypes.string,
    paymentMethodAliasInput: PropTypes.string,
  }).isRequired,
  paymentMethodAlias: PropTypes.string,
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

PaymentMethodAlias.defaultProps = {
  formName: '',
  paymentMethodAlias: '',
};

export default withStyles(finalStyles)(PaymentMethodAlias);
