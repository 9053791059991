// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MaterialChevronLeft from '@material-ui/icons/ChevronLeft';

// stylesheets
import './BreadCrumb.css';

const BreadCrumb = ({ link, text, mobile, leftChevron }) => {
  let Crumb;
  if (link) {
    Crumb = (
      <Link to={link} className="breadcrumb__link__wrapper">
        <span className="breadcrumb__link__separator">/</span>
        {leftChevron && (
          <MaterialChevronLeft className="breadcrumb__link__back-caret" />
        )}
        <span className="breadcrumb_link_item">{text}</span>
      </Link>
    );
  } else {
    Crumb = (
      <span>
        <span className="breadcrumb__link__separator">/</span>
        <span className="breadcrumb_current-location">{text}</span>
      </span>
    );
  }

  return (
    <div className={`breadcrumb ${mobile ? '' : 'breadcrumb__mobile__hide'}`}>
      {Crumb}
    </div>
  );
};

BreadCrumb.defaultProps = {
  link: undefined,
  mobile: false,
  leftChevron: false,
};

BreadCrumb.propTypes = {
  link: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  mobile: PropTypes.bool,
  leftChevron: PropTypes.bool,
};

export default BreadCrumb;
