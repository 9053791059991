import React from 'react';
import get from 'lodash/get';
import numeral from 'numeral';
import isString from 'lodash/isString';
import isNaN from 'lodash/isNaN';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { verifyUsePackageUom } from 'utils/verifyUsePackageUom';
import unitMeasuresLong from '../../utils/unitMeasuresLong';
import { NO_PRICE_MESSAGE } from '../../../../shared/constants/ecommMessageText';
import NoPricePopper from '../../../../shared/ui/NoPricePopper';
import './productPrice.scss';

const hasGoodData = (productVariant, price, uom) => {
  if (isEmpty(productVariant)) {
    return false;
  } // has variants

  if (isNaN(parseInt(price, 10)) || !(parseInt(price, 10) > 0)) {
    return false;
  } // is price valid

  if (!uom || !isString(uom)) {
    return false;
  } // has uom

  return true;
};

const findProductVariantFromAttributes = (variantId, product) => {
  return get(product, 'attributes.variants', []).find(
    (a) => a.id === variantId
  );
};

const findProductFromState = (variantProductId, productData, productsData) => {
  if (productData && productData.id === variantProductId) {
    return productData;
  }
  return productsData.find((a) => a.id === variantProductId);
};

const getVariantPricing = (productVariant, usePackageUom) => {
  const price = get(productVariant, 'net_price');
  const packageUom = get(productVariant, 'packageUom');
  const unitMeasure = get(productVariant, 'unit_measure');
  const uom = usePackageUom ? packageUom : unitMeasure;
  const uomLong = unitMeasuresLong[uom] || uom;
  return { price, uom: uomLong };
};

const showValidPrice = (price, uom) => {
  const grossPrice = numeral(price).format('$0,0.00');
  return (
    <p className="product-price__price-text" data-test="product-price-text">
      <span data-test="product-price">{grossPrice || 'Quote requested'}</span>
      <span className="product-price__unit-text" data-test="unit-text">
        {`per ${uom}`}
      </span>
    </p>
  );
};

const ProductPrice = ({
  variant,
  context,
  contextualNoPriceStyle,
  contextualPriceStyle,
}) => {
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const productData = useSelector((state) => get(state, 'shop.product.data'));
  const productsData = useSelector((state) =>
    get(state, 'shop.products.data', [])
  );

  const product = findProductFromState(
    variant.product_id,
    productData,
    productsData
  );

  const productVariantWithPrice = findProductVariantFromAttributes(
    variant.id,
    product
  );

  const usePackageUom = verifyUsePackageUom(selectedAccount);

  const { price, uom } = getVariantPricing(
    productVariantWithPrice,
    usePackageUom
  );

  const pricingUnavailableMessage = () => (
    <div className={contextualNoPriceStyle} data-test="product-price-text">
      <div className={contextualPriceStyle} data-test="product-no-price">
        <div>
          {NO_PRICE_MESSAGE}
          <NoPricePopper location={context} />
        </div>
      </div>
      <div />
    </div>
  );

  return (
    <div className="product-price__price-wrapper">
      {hasGoodData(productVariantWithPrice, price, uom)
        ? showValidPrice(price, uom)
        : pricingUnavailableMessage()}
    </div>
  );
};

ProductPrice.propTypes = {
  context: PropTypes.string,
  contextualPriceStyle: PropTypes.string,
  contextualNoPriceStyle: PropTypes.string,
  variant: PropTypes.shape({
    unit_measure: PropTypes.string,
    price: PropTypes.number,
    product_id: PropTypes.string,
    net_price: PropTypes.number,
    packageUom: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

ProductPrice.defaultProps = {
  context: '',
  contextualPriceStyle: '',
  contextualNoPriceStyle: '',
};

export default ProductPrice;
