import { combineReducers } from 'redux';
import {
  LINKING_ACCOUNT,
  ACCOUNT_LINK_SUCCESS,
  ACCOUNT_LINK_FAILURE,
  ACCOUNT_FETCH_FAILURE,
  RESET_ACCOUNT_LINK,
  DUPLICATE_ACCOUNT,
} from './accountLinkConstants';

export const accountInfo = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_LINK_SUCCESS:
    case DUPLICATE_ACCOUNT:
      return action.payload;
    case ACCOUNT_LINK_FAILURE:
    case ACCOUNT_FETCH_FAILURE:
    case RESET_ACCOUNT_LINK:
      return {};
    default:
      return state;
  }
};

export const status = (state = 'LINK_ACCOUNT', action) => {
  switch (action.type) {
    case RESET_ACCOUNT_LINK:
      return 'LINK_ACCOUNT';
    case DUPLICATE_ACCOUNT:
      return DUPLICATE_ACCOUNT;
    case LINKING_ACCOUNT:
      return LINKING_ACCOUNT;
    case ACCOUNT_LINK_SUCCESS:
      return ACCOUNT_LINK_SUCCESS;
    case ACCOUNT_LINK_FAILURE:
      return ACCOUNT_LINK_FAILURE;
    case ACCOUNT_FETCH_FAILURE:
      return ACCOUNT_FETCH_FAILURE;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case ACCOUNT_LINK_FAILURE:
    case ACCOUNT_FETCH_FAILURE:
      return action.error;
    case ACCOUNT_LINK_SUCCESS:
    case DUPLICATE_ACCOUNT:
    case RESET_ACCOUNT_LINK:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ accountInfo, status, error });
