import {
  validateMaxAmount,
  isPopulated,
  isPhoneNumber,
  isAlphabetical,
  isAlphaNumeric,
  isMinimumLength,
  isMinimumNameLength,
  isDropdownPopulated,
  isValidPostalCode,
  validateEnteredAmount,
  isMaximumLength,
  areAccountNumbersSame,
  isAmountEnteredLessThanEqualToAccountBalance,
  isAmountEnteredLTECurrentDue,
  isNumeric,
  isNotOnlySpaces,
} from './PaymentValidationRules';

export const mapRuleToErrorMessage = {
  [validateMaxAmount.name]: {
    errorMessage: `Cannot process an online payment of over one million dollars.`,
  },
  [isAlphaNumeric.name]: {
    errorMessage: 'The value entered is not alphanumeric',
  },
  [isAlphabetical.name]: {
    errorMessage: 'The input can only contain letters',
  },
  [isPopulated.name]: {
    errorMessage: 'This field is required',
  },
  [isDropdownPopulated.name]: {
    errorMessage: 'This field is required',
  },
  [isPhoneNumber.name]: {
    errorMessage: 'The given phone number is not valid',
  },
  [isAmountEnteredLessThanEqualToAccountBalance.name]: {
    errorMessage: 'Amount entered is greater than account balance',
  },
  [validateEnteredAmount.name]: {
    errorMessage: 'The Amount must be greater than 0',
  },
  [isMinimumNameLength.name]: {
    errorMessage: 'Must be at least two characters',
  },
  [isMinimumLength.name]: {
    errorMessage: 'Value is shorter than the minimum length',
  },
  [isMaximumLength.name]: {
    errorMessage: 'Value is longer than the maximum length',
  },
  [isValidPostalCode.name]: {
    errorMessage: 'Invalid Postal Code',
  },
  [areAccountNumbersSame.name]: {
    errorMessage: 'The two bank account numbers provided do not match',
  },
  [isNumeric.name]: {
    errorMessage: 'Value contains invalid characters',
  },
  [isNotOnlySpaces.name]: {
    errorMessage: 'Value cannot be only spaces',
  },
};

export const mapRuleToWarningMessage = {
  [isAmountEnteredLTECurrentDue.name]: {
    warningMessage:
      'The payment amount you entered is greater than the minimum payment due. If you have questions about your future balance or finance charges, please review your invoices or contact your branch.',
  },
};

export const mapFieldToRules = {
  cpsAccount: {
    errorRules: [isDropdownPopulated],
  },
  prepayType: {
    errorRules: [isDropdownPopulated],
  },
  addressLine1: {
    errorRules: [isAlphaNumeric, isMinimumLength],
  },
  addressLine2: {
    errorRules: [isAlphaNumeric],
  },
  city: {
    errorRules: [isAlphabetical, isMinimumLength],
  },
  zip: {
    errorRules: [isNumeric, isMinimumLength],
  },
  postalCode: {
    errorRules: [isMinimumLength, isValidPostalCode],
  },
  country: {
    errorRules: [isDropdownPopulated],
  },
  state: {
    errorRules: [isDropdownPopulated],
  },
  phone: {
    errorRules: [isPhoneNumber, isMinimumLength],
  },
  amountEntered: {
    warningRules: [isAmountEnteredLTECurrentDue],
    errorRules: [validateEnteredAmount, validateMaxAmount],
  },
  routingNumber: {
    errorRules: [isMinimumLength, isNumeric],
    warningRules: [],
  },
  bankAccountNumber: {
    errorRules: [isMinimumLength, isNumeric],
    warningRules: [],
  },
  confirmBankAccountNumber: {
    errorRules: [isMinimumLength, isNumeric, areAccountNumbersSame],
    warningRules: [],
  },
  firstName: {
    errorRules: [isMinimumNameLength, isNotOnlySpaces],
    warningRules: [],
  },
  lastName: {
    errorRules: [isMinimumNameLength, isNotOnlySpaces],
    warningRules: [],
  },
  corporateName: {
    errorRules: [isMinimumNameLength, isNotOnlySpaces],
    warningRules: [],
  },
  paymentMethodAlias: {
    errorRules: [isAlphaNumeric],
    warningRules: [],
  },
  transitNumber: {
    errorRules: [isMinimumLength, isNumeric],
    warningRules: [],
  },
  institutionNumber: {
    errorRules: [isMinimumLength, isNumeric],
    warningRules: [],
  },
  accountNumber: {
    errorRules: [isMinimumLength, isNumeric],
    warningRules: [],
  },
  confirmAccountNumber: {
    errorRules: [isMinimumLength, areAccountNumbersSame, isNumeric],
    warningRules: [],
  },
  recurringPaymentFrequency: {
    errorRules: [isDropdownPopulated],
    warningRules: [],
  },
  recurringEndDate: {
    errorRules: [isPopulated],
    warningRules: [],
  },
};

export const mapFieldToMinLength = {
  addressLine1: {
    minLength: 1,
  },
  city: {
    minLength: 1,
  },
  zip: {
    minLength: 5,
  },
  postalCode: {
    minLength: 3,
  },
  phone: {
    minLength: 10,
  },
  routingNumber: {
    minLength: 9,
  },
  bankAccountNumber: {
    minLength: 4,
  },
  confirmBankAccountNumber: {
    minLength: 4,
  },
  firstName: {
    minLength: 2,
  },
  lastName: {
    minLength: 2,
  },
  corporateName: {
    minLength: 2,
  },
  transitNumber: {
    minLength: 5,
  },
  institutionNumber: {
    minLength: 3,
  },
  accountNumber: {
    minLength: 7,
  },
  confirmAccountNumber: {
    minLength: 7,
  },
};

export const SPECIAL_FIELDS_ENUM = {
  AMOUNT_ENTERED: 'amountEntered',
  CONFIRM_BANK_ACCOUNT_NUMBER: 'confirmBankAccountNumber',
  CONFIRM_ACCOUNT_NUMBER: 'confirmAccountNumber',
  ZIP: 'zip',
  POSTAL_CODE: 'postalCode',
};
