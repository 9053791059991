import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AccountingList.css';

// TODO: this class needs unit tests
const AccountingList = ({ headers, title, titleBarContent, children }) => {
  const listHeaders = headers.map((header) => {
    if (typeof header === 'string') {
      return (
        <th className="accounting-list__header" key={header}>
          <div className="accounting-list__header__inner">{header}</div>
        </th>
      );
    }
    const headerClasses = [];
    if (header.align === 'right')
      headerClasses.push('accounting-list__header__right__inner');
    if (header.minSpace === true)
      headerClasses.push('accounting-list-header__link__min-space');
    return (
      <th
        key={header.text}
        className={classNames('accounting-list__header', headerClasses)}
      >
        <div className="accounting-list__header__inner">{header.text}</div>
      </th>
    );
  });

  let headerBar;
  // TODO: The title property is marked as required in the prop types- why are we checking for its existence?
  if (title) {
    headerBar = (
      <div className="header-bar">
        <h3 className="accounting-list-title">{title}</h3>
        <div>{titleBarContent}</div>
      </div>
    );
  }

  return (
    <div className="accounting-list-wrapper">
      {headerBar}
      <table className="accounting-list">
        <thead>
          <tr key={title}>{listHeaders}</tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

const headerObjectPropType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['right']),
  minSpace: PropTypes.bool,
});

AccountingList.propTypes = {
  // it's possible a table may be rendered without any children
  children: PropTypes.node,
  titleBarContent: PropTypes.node,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, headerObjectPropType])
  ).isRequired,
  title: PropTypes.string.isRequired,
};

AccountingList.defaultProps = {
  children: null,
  titleBarContent: null,
};

export default AccountingList;
