import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  accountOverviewMoreInfoIcon: {
    marginLeft: '.5em',
    height: '1em',
  },
  '@media screen and (max-width: 1200px)': {
    overviewPayNowButton: {
      margin: '12px 0',
    },
  },
  overviewInfoCardLink: {
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  overviewInfoCardLinkArrow: {
    height: '.6em',
    marginLeft: 5,
  },
}));
