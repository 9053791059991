import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotificationsBadge from './NotificationsBadge';
import { getUnreadCount } from '../selectors';

import withNotifications from '../withNotifications';

export const NotificationsBadgeContainer = ({ unreadCount, ...rest }) => (
  <NotificationsBadge unreadCount={unreadCount} {...rest} />
);

NotificationsBadgeContainer.propTypes = {
  unreadCount: PropTypes.number,
};

NotificationsBadgeContainer.defaultProps = {
  unreadCount: 0,
};

export const mapStateToProps = (state) => ({
  unreadCount: getUnreadCount(state),
});

export default connect(mapStateToProps)(
  withNotifications(NotificationsBadgeContainer)
);
