// dependencies
import React from 'react';
import PropTypes from 'prop-types';

// shared UI

// shared UI

// components

// stylesheets
import './InfoCard.css';

export const InfoCard = (props) => (
  <div className="info-card">
    <div className="info-card-inner">
      <div className="info-card-title">{props.title}</div>
      {props.children}
    </div>
  </div>
);

export const InfoCardContainer = (props) => (
  <div className="info-card__container">{props.children}</div>
);

InfoCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

InfoCard.defaultProps = {
  children: null,
};

InfoCardContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

InfoCardContainer.defaultProps = {
  children: null,
};
