import React from 'react';
import resolveHubUrl from 'shared/config/resolveHubUrl';

import { Box, Button } from '@material-ui/core';

import './styles.scss';

const UpdateEmailPhoneButton = () => {
  const hubUrl = resolveHubUrl();

  const linkToHubUserSettingsPage = () => {
    window.open(`${hubUrl}/more/settings`, '_blank');
  };

  const LinkOutButton = () => (
    <Box display="flex" paddingTop={2}>
      <Button
        type="submit"
        variant="contained"
        data-test="email-phone-change-button-press"
        onClick={linkToHubUserSettingsPage}
      >
        Update Email and Phone
      </Button>
    </Box>
  );

  return (
    <>
      <div className="box-padding">
        Easily update your e-mail and phone number on the User Settings page of
        your Profile on the new Nutrien HUB.
      </div>
      <LinkOutButton />
    </>
  );
};

UpdateEmailPhoneButton.defaultProps = {};

UpdateEmailPhoneButton.propTypes = {};

export default UpdateEmailPhoneButton;
