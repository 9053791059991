import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import * as validationUtils from 'components/Payments/PageComponents/MakeAPayment/Validations/PaymentValidator';
import AccountDescriptionText from 'shared/ui/AccountDescriptionText';
import CPSAccountDropdownComponent from './CPSAccountDropdownComponent';
import {
  getAccountCountryCode,
  getCountryFromCode,
} from '../../../../components/Payments/Utils/PaymentUtil';
import {
  clearBankAccountForm,
  clearBankName,
  setAccountCountryCode,
  setBankAccountFormErrors,
  updateBankAccountForm,
} from '../../../../components/Payments/Redux/actions/BankAccountFormActions';
import { getSavedBankAccounts } from '../../../../components/Payments/Redux/actions/BankAccountActions';

const CPSAccountDropdownContainer = (props) => {
  const [singleAccountUnset, setState] = useState(true);
  const {
    user,
    accounts,
    getAccounts,
    dropdownLabel,
    chosenAccount,
    selectedAccount,
    formErrors,
    updatePaymentForm,
    clearBankName,
    clearPaymentForm,
    getSavedPaymentMethods,
    setAccountCountryCode,
    search,
    location,
    history,
  } = props;
  const { universalHeader } = useFlags();

  const findAccount = (accounts, accountId) =>
    accounts.find((account) => account.id === accountId);

  const setURLParam = (acctId) => {
    const { pathname: fullPath } = location;

    history.replace({
      pathname: fullPath,
      search: `acct=${acctId}`,
      isActive: true,
    });
  };

  const onChange = (event) => {
    const { code: id } = event;

    const query = new URLSearchParams(search);
    const isRecurringQueryParam = query.get('isRecurring') === 'true';

    // Clear parts of form that are effected by the account changing
    clearPaymentForm(isRecurringQueryParam);
    clearBankName();

    const associatedOrgIds = user.userDetails.associatedOrgs.map(
      (org) => org.id
    );
    const newAccount = findAccount(accounts, id);
    const accountCountryCode =
      newAccount && newAccount.locationCode
        ? newAccount.locationCode
        : getAccountCountryCode(accounts);
    const country = getCountryFromCode(accountCountryCode);

    setAccountCountryCode(accountCountryCode);
    getSavedPaymentMethods(associatedOrgIds, accountCountryCode);

    if (universalHeader && newAccount) {
      setURLParam(newAccount.id);
    }

    updatePaymentForm({
      country,
      cpsAccount: newAccount,
    });
  };

  const retrieveAccounts = (newUser) => {
    const associatedOrgIds = newUser.userDetails.associatedOrgs.map(
      (org) => org.id
    );

    if (chosenAccount) {
      getSavedPaymentMethods(associatedOrgIds, chosenAccount.locationCode);
    }
    getAccounts(newUser.userDetails.associatedOrgs);
  };

  const setCountry = (newAccounts) => {
    const accountCountryCode = chosenAccount
      ? chosenAccount.locationCode
      : getAccountCountryCode(newAccounts);
    const country = getCountryFromCode(accountCountryCode);

    setAccountCountryCode(accountCountryCode);

    updatePaymentForm({
      country,
    });
  };

  useEffect(() => {
    if (user && !accounts) {
      retrieveAccounts(user);
    }

    if (accounts) {
      setCountry(accounts);
    }

    if (universalHeader && selectedAccount && props.location.headerSelector) {
      onChange({
        name: 'cpsAccount',
        code: selectedAccount.id,
      });
    }

    if (
      accounts &&
      accounts.length === 1 &&
      !chosenAccount &&
      singleAccountUnset
    ) {
      onChange({
        name: 'cpsAccount',
        code: accounts[0].id,
      });
      setState({ singleAccountUnset: false });
    }
  }, [user, accounts, chosenAccount, selectedAccount]);

  const getDefaultDropdownValue = (accounts) =>
    accounts.length === 1 ? accounts[0] : { name: 'Choose Account' };

  const validate = (event) => {
    const { name, value } = event.target;
    const {
      formValues,
      formErrors,
      formWarnings,
      setPaymentFormErrors,
    } = props;

    const newState = validationUtils.validateFormField({
      field: name,
      fieldData: { value },
      currentState: {
        form: formValues,
        formErrors,
        formWarnings,
      },
    });

    setPaymentFormErrors(newState.formErrors);
  };

  const transformAccount = (account) => ({
    name: <AccountDescriptionText account={account} />,
    code: account.id,
  });

  const transformAccounts = (accounts) =>
    accounts.map((account) => transformAccount(account));

  const transformedAccounts =
    accounts && accounts.length ? transformAccounts(accounts) : [];

  const getDropdownValue = (account) => {
    const newAccount =
      account && !isEmpty(account)
        ? transformAccount(account)
        : getDefaultDropdownValue(transformedAccounts);

    return newAccount;
  };

  return (
    <CPSAccountDropdownComponent
      accounts={transformedAccounts}
      errors={formErrors}
      label={dropdownLabel}
      value={getDropdownValue(chosenAccount)}
      onChange={onChange}
      validate={validate}
    />
  );
};

const mapStateToProps = (state) => {
  const { bankAccountFormReducer: bankAccountForm } = state.payments;

  return {
    user: state.user.userData,
    accounts: state.accounts.list,
    formValues: bankAccountForm.form,
    formErrors: bankAccountForm.formErrors,
    formWarnings: bankAccountForm.formWarnings,
    selectedAccount: state.selectedAccount,
  };
};

const mapDispatchToProps = {
  clearBankName,
  setAccountCountryCode,
  getAccounts: fetchAssociatedOrgsAction,
  getSavedPaymentMethods: getSavedBankAccounts,
  updatePaymentForm: updateBankAccountForm,
  clearPaymentForm: clearBankAccountForm,
  setPaymentFormErrors: setBankAccountFormErrors,
};

CPSAccountDropdownContainer.propTypes = {
  user: PaymentPropTypes.user,
  accounts: PaymentPropTypes.paymentAccounts,
  chosenAccount: PaymentPropTypes.paymentAccount,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
  }),
  formValues: PaymentPropTypes.paymentForm,
  formErrors: PaymentPropTypes.paymentFormErrors,
  formWarnings: PaymentPropTypes.paymentFormWarnings,
  dropdownLabel: PropTypes.string,
  getAccounts: PropTypes.func.isRequired,
  updatePaymentForm: PropTypes.func.isRequired,
  getSavedPaymentMethods: PropTypes.func.isRequired,
  clearBankName: PropTypes.func.isRequired,
  clearPaymentForm: PropTypes.func.isRequired,
  setAccountCountryCode: PropTypes.func.isRequired,
  setPaymentFormErrors: PropTypes.func.isRequired,
  search: PropTypes.string,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    headerSelector: PropTypes.bool,
  }).isRequired,
};

CPSAccountDropdownContainer.defaultProps = {
  user: null,
  accounts: null,
  chosenAccount: null,
  selectedAccount: null,
  formValues: {},
  formErrors: {},
  formWarnings: {},
  dropdownLabel: '',
  search: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CPSAccountDropdownContainer);
