import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import history from 'history.js';
import createRootReducer from 'reducers';

import GoogleAnalyticsMiddleware from './middleware/TagManager';

const middleware = [
  thunk,
  routerMiddleware(history),
  GoogleAnalyticsMiddleware,
];

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const store = createStore(
  createRootReducer(),
  composeEnhancers(applyMiddleware(...middleware))
);

store.async = {};

export const registerReducer = (name, reducer) => {
  store.async[name] = reducer;
  store.replaceReducer(createRootReducer(store.async));
};

export const persistor = persistStore(store);
if (module.hot) {
  module.hot.accept('../reducers', () =>
    store.replaceReducer(createRootReducer(store.async))
  );
}

export default store;
