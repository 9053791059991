// TODO: Refactor into reducers directory <22-01-19, cpitt> //
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  EMPLOYEE_MIRRORING_FAILED,
  EMPLOYEE_MIRRORING_START,
  EMPLOYEE_MIRRORING_START_FINISHED,
  EMPLOYEE_MIRRORING_STOP,
} from './actions';

export const mirroredUser = (state = {}, action) => {
  const { type, customer } = action;
  switch (type) {
    case EMPLOYEE_MIRRORING_START:
      return customer;
    case EMPLOYEE_MIRRORING_STOP:
    case EMPLOYEE_MIRRORING_FAILED:
      return null;
    default:
      return state;
  }
};

export const isMirroring = (state = false, action) => {
  switch (action.type) {
    case EMPLOYEE_MIRRORING_START:
      return true;
    case EMPLOYEE_MIRRORING_STOP:
    case EMPLOYEE_MIRRORING_FAILED:
    case 'LOGOUT':
      return false;
    default:
      return state;
  }
};

export const beginningMirroring = (state = false, action) => {
  switch (action.type) {
    case EMPLOYEE_MIRRORING_START:
      return true;
    case EMPLOYEE_MIRRORING_START_FINISHED:
    case EMPLOYEE_MIRRORING_FAILED:
      return false;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case EMPLOYEE_MIRRORING_START:
    case EMPLOYEE_MIRRORING_START_FINISHED:
      return null;
    case EMPLOYEE_MIRRORING_FAILED:
      return action.error;
    default:
      return state;
  }
};

export default persistReducer(
  {
    key: 'mirroring',
    storage,
    blacklist: ['error'],
  },
  combineReducers({
    isMirroring,
    mirroredUser,
    beginningMirroring,
    error,
  })
);
