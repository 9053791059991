import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MenuList, withStyles, MenuItem } from '@material-ui/core';
import { ApiErrorMessage, MaterialLoadingIcon } from 'shared/ui';

import { notifications as NotificationsPropType } from '../notificationsPropTypes';
import NotificationsListItem from '../NotificationsList/NotificationsListItem';

const styles = {
  menuList: {
    padding: 0,
    overflow: 'auto',
    maxHeight: '70vh',
  },
  noNotifications: {
    minWidth: '400px',
    padding: '0.5em',
  },
};

const ECOMM_NOTIF_TYPES = [
  'ORDER_CANCELLATION',
  'ORDER_CONFIRMATION',
  'ORDER_FULFILLED',
  'ORDER_INVOICED',
  'ORDER_SUBMITTED',
  'ORDER_STATUS_CHANGE',
];

const NotificationsMenuList = ({
  fetchingNotifications,
  notificationsError,
  notifications,
  classes,
  closeMenu,
}) => {
  const { globalEcommerce } = useFlags();

  let menuContent;
  if (fetchingNotifications) {
    menuContent = <MaterialLoadingIcon />;
  } else if (notificationsError) {
    menuContent = (
      <div className={classes.noNotifications}>
        <ApiErrorMessage
          entityName="Notifications"
          className="notifications-menu-list__error"
        />
      </div>
    );
  } else if (!notifications.length) {
    menuContent = (
      <div
        data-test="notifications-menu-list-no-notifications"
        className={classnames(
          'notifications',
          'notifications-menu-list__no-notifications',
          classes.noNotifications
        )}
      >
        There are no notifications at this time.
      </div>
    );
  } else {
    menuContent = (
      <MenuList className={classes.menuList}>
        {notifications.map((notification) => {
          // Don't show ecomm notifications
          if (ECOMM_NOTIF_TYPES.indexOf(notification.type) !== -1) {
            return (
              <>
                {globalEcommerce && (
                  <MenuItem key={notification.id}>
                    <NotificationsListItem
                      isMenu
                      notification={notification}
                      closeMenu={closeMenu}
                    />
                  </MenuItem>
                )}
              </>
            );
          }
          return (
            <MenuItem key={notification.id}>
              <NotificationsListItem
                isMenu
                notification={notification}
                closeMenu={closeMenu}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    );
  }

  return <div className="notifications-menu-list">{menuContent}</div>;
};

NotificationsMenuList.propTypes = {
  notificationsError: PropTypes.instanceOf(Error),
  notifications: NotificationsPropType,
  fetchingNotifications: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  closeMenu: PropTypes.func,
};

NotificationsMenuList.defaultProps = {
  notifications: [],
  notificationsError: undefined,
  classes: {},
  closeMenu: () => {},
  fetchingNotifications: false,
};

export default withStyles(styles)(NotificationsMenuList);
