import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  ChipSet,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  Icon,
} from 'nutrien-common-components-react/dist/mdc';

class ShelfSelector extends Component {
  state = {
    dialogOpen: false,
    chosenShelves: this.props.shelfFilter,
  };

  componentDidMount() {
    if (sessionStorage.productHistoryActiveShelves && !this.props.shelfFilter) {
      this.props.setNewShelvesForProductHistory(
        sessionStorage.productHistoryActiveShelves
      );
    } else if (this.props.shelfFilter) {
      sessionStorage.setItem(
        'productHistoryActiveShelves',
        this.props.shelfFilter
      );
    }
  }

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  updateChosenShelves = (shelf) => {
    const { chosenShelves } = this.state;

    if (!chosenShelves.includes(shelf)) {
      if (chosenShelves === '') {
        this.setState({ chosenShelves: shelf });
      } else {
        this.setState({ chosenShelves: `${chosenShelves},${shelf}` });
      }
    } else {
      let modifiedShelfList = chosenShelves.replace(`,${shelf}`, '');
      modifiedShelfList = modifiedShelfList.replace(`${shelf},`, '');
      modifiedShelfList = modifiedShelfList.replace(`${shelf}`, '');
      this.setState({ chosenShelves: modifiedShelfList });
    }
  };

  acceptChosenShelves = () => {
    this.setState({ dialogOpen: false });
    sessionStorage.setItem(
      'productHistoryActiveShelves',
      this.state.chosenShelves
    );
    this.props.setNewShelvesForProductHistory(this.state.chosenShelves);
  };

  deleteShelfSelector = (shelf) => {
    const { shelfFilter } = this.props;
    let modifiedShelfList = shelfFilter.replace(`,${shelf}`, '');
    modifiedShelfList = modifiedShelfList.replace(`${shelf},`, '');
    modifiedShelfList = modifiedShelfList.replace(`${shelf}`, '');
    sessionStorage.setItem('productHistoryActiveShelves', modifiedShelfList);
    this.props.setNewShelvesForProductHistory(modifiedShelfList);
  };

  handleClick = () => {
    this.props.setPurchaseFilter('');
    this.acceptChosenShelves();
  };

  renderChip = () => {
    const { shelfFilter } = this.props;

    if (shelfFilter === '') {
      return (
        <ChipSet>
          <Chip
            id="AllShelves"
            backgroundColor="white"
            data-test="product-history-shelf-selector-AllShelves"
            label="All Shelves"
            type="selected"
            onClick={this.openDialog}
          />
        </ChipSet>
      );
    }
    const shelfArray = shelfFilter.split(',');

    return (
      <ChipSet>
        {shelfArray.map((shelf) => (
          <Chip
            id={`filter-${shelf}`}
            key={`filter-${shelf}`}
            backgroundColor="white"
            data-test={`product-history-shelf-selector-${shelf}`}
            label={shelf}
            type="selected"
            onClick={() => {
              this.openDialog();
            }}
            removeIcon={
              <Icon
                onClick={() => this.deleteShelfSelector(shelf)}
                icon="cancel"
              />
            }
          />
        ))}
      </ChipSet>
    );
  };

  renderDialogShelfChip = (shelf) => (
    <Chip
      id={`dialog-${shelf}`}
      backgroundColor={
        !this.state.chosenShelves.includes(shelf) ? 'white' : null
      }
      data-test={`select-${shelf}-chip`}
      label={shelf}
      type="selected"
      onClick={() => this.updateChosenShelves(shelf)}
    />
  );

  renderShelfDialog = () => (
    <Dialog
      className="product-history__shelf-selector-modal"
      data-test="open-shelf-selector-dialog"
      open={this.state.dialogOpen}
      onClosing={() => {
        this.setState({ dialogOpen: false });
      }}
    >
      <DialogTitle>Select Shelf Filters</DialogTitle>
      <DialogContent className="product-history__shelf-filter-dialog-content">
        <ChipSet>
          {this.renderDialogShelfChip('Chemical')}
          {this.renderDialogShelfChip('Fertilizer')}
          {this.renderDialogShelfChip('Seed')}
          {this.renderDialogShelfChip('Application')}
          {this.renderDialogShelfChip('Other')}
        </ChipSet>
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={() => {
            this.setState({ dialogOpen: false });
          }}
          secondary
          data-test="product-history-shelf-selector-close"
          className="product-history__shelf-selector-close"
        >
          Cancel
        </DialogButton>
        <DialogButton
          data-test="product-history-shelf-selector-accept"
          onClick={() => this.handleClick()}
          isDefaultAction
          className="product-history__shelf-selector-accept"
        >
          Accept
        </DialogButton>
      </DialogActions>
    </Dialog>
  );

  render() {
    return (
      <div className="product-history__div-change-filter">
        {this.renderShelfDialog()}
        <p className="product-history__p-change-filter">
          Shelf (
          <span
            className="product-history__span-change-filter"
            onClick={this.openDialog}
            onKeyPress={this.openDialog}
            tabIndex="0"
            role="button"
          >
            Change
          </span>
          )
        </p>
        {this.renderChip()}
      </div>
    );
  }
}

ShelfSelector.propTypes = {
  setNewShelvesForProductHistory: PropTypes.func.isRequired,
  shelfFilter: PropTypes.string.isRequired,
  setPurchaseFilter: PropTypes.func.isRequired,
};

export default ShelfSelector;
