import productTransformer from '../productTransformer';

const productListTransform = (productsResponse) => {
  try {
    return {
      meta: {
        count: productsResponse.data.meta.count,
        totalCount: productsResponse.data.meta.totalCount,
      },
      data: productsResponse.data.data.map((productData) =>
        productTransformer({ data: { data: productData } })
      ),
    };
  } catch (e) {
    console.error('The products were not formatted properly:', e);
    return [];
  }
};

export default productListTransform;
