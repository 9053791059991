import { combineReducers } from 'redux';

import {
  EMPLOYEE_INFO_FETCH_FAILURE,
  EMPLOYEE_INFO_FETCH_SUCCESS,
} from '../actions';

// TODO: Move both of these reducers to the root of the employee domain, they have nothing to do with the header <16-10-18, Cameron Pitt> //
export const info = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_INFO_FETCH_SUCCESS:
      return action.employeeInfo;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case EMPLOYEE_INFO_FETCH_FAILURE:
      return action.error;
    case EMPLOYEE_INFO_FETCH_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ info, error });
