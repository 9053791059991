import { validTermsAcceptance } from 'components/Terms/termsService';
import { TERMS_FETCHED } from 'components/Terms/termsConstants';
import { USER_FETCHED } from 'shared/config/userConstants';
import { isEmployee } from 'shared/utils/tokenUtils';

export const selectUser =
  () =>
  ({ user }) =>
    user?.userData;

export const selectMirroredUser = ({ employee }) =>
  employee?.mirroring?.mirroredUser;

export const selectUserStatus = ({ user }) => user.status;

export const selectEmployee =
  () =>
  ({ employee }) =>
    employee?.user?.info?.userDetails;

export const selectLinkedAccounts =
  () =>
  ({ user }) => {
    const fetched = user?.status === USER_FETCHED;
    if (fetched) {
      const {
        userData: {
          userDetails: { associatedOrgs },
        },
      } = user;
      return associatedOrgs.reduce(
        (orgs, org) => [...orgs, ...org.accounts],
        []
      );
    }
    return [];
  };

export const selectHasLinkedAccount =
  () =>
  ({ user }) =>
    selectLinkedAccounts()({ user }).length > 0;

export const selectUserTermsAccepted =
  () =>
  ({ user, terms }) => {
    return !isEmployee() &&
      terms?.status === TERMS_FETCHED &&
      user?.status === USER_FETCHED &&
      terms?.termsVersion &&
      user?.userData?.userDetails?.termsAcceptance
      ? validTermsAcceptance(
          user.userData.userDetails.termsAcceptance,
          terms.termsVersion
        )
      : null;
  };

export const selectAuth0UserMetadataFetched =
  () =>
  ({ user }) =>
    user?.status === USER_FETCHED &&
    user.userData?.userDetails?.auth0UserMetadataFetched;

export const selectShowLinkAccountModal =
  () =>
  ({ user }) =>
    user?.status === USER_FETCHED &&
    selectAuth0UserMetadataFetched()({ user }) &&
    user.userData?.userDetails?.showLinkAccountModal !== false;
