import { connect } from 'react-redux';
import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import AccountsTab from './AccountsTab';
import { UpdateAccountNicknames, UPDATE_NICKNAME_STATUSES } from './actions';
import { getOrgs } from './selectors';

const mapStateToProps = (state) => ({
  accounts: state.accounts.list,
  orgs: getOrgs(state),
  loading:
    state.accountsTab.updateAccountNicknames ===
      UPDATE_NICKNAME_STATUSES.SAVING || state.accounts.status === 'FETCHING',
});
const mapDispatchToProps = {
  getAccounts: fetchAssociatedOrgsAction,
  updateAccountNicknames: UpdateAccountNicknames,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountsTab);
