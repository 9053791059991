import React from 'react';
import PropTypes from 'prop-types';
import { notifications as notificationsPropType } from '../notificationsPropTypes';

import withNotifications from '../withNotifications';
import NotificationsList from '../NotificationsList';

const NotificationsSummary = ({
  fetchNotificationsError,
  isFetchingNotifications,
  limit,
  notifications,
  ...rest
}) => (
  <div className="notifications-summary">
    <NotificationsList
      fetchingNotifications={isFetchingNotifications}
      notifications={notifications.slice(0, limit)}
      notificationsError={fetchNotificationsError}
      {...rest}
    />
  </div>
);

NotificationsSummary.defaultProps = {
  fetchNotificationsError: null,
  isFetchingNotifications: false,
  limit: 5,
  notifications: [],
};

NotificationsSummary.propTypes = {
  fetchNotificationsError: PropTypes.instanceOf(Error),
  isFetchingNotifications: PropTypes.bool,
  limit: PropTypes.number,
  notifications: notificationsPropType,
};

export default withNotifications(NotificationsSummary);
