import { SET_BROWSER_OK, SET_BROWSER_ERROR } from '../actions/headerActions';

const browserSupport = (
  state = { isSupported: null, browser: null },
  action
) => {
  switch (action.type) {
    case SET_BROWSER_OK:
      return { isSupported: true, browser: null };
    case SET_BROWSER_ERROR:
      return { isSupported: false, browser: null };
    default:
      return state;
  }
};

export default browserSupport;
