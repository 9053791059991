import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { gtmOnlineOrdersClick } from 'store/middleware/TagManager/gtmActions';
import { ordersActions } from '../../Orders/actions/actions';
import RecentOrders from './RecentOrders';

const mapStateToProps = ({ orders, user, selectedAccount }) => ({
  orders: orders.orders,
  status: orders.status,
  user,
  selectedAccount,
});

const mapDispatchToProps = {
  ...ordersActions,
  gtmOnlineOrdersClick,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(RecentOrders);
