import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

import styles from './styles';

export class ExpandingTextContainer extends Component {
  constructor(props) {
    super(props);

    const { limit, text } = props;
    this.state = {
      showExpandOption: text.length > limit,
      expanded: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.setExpandOption(this.props.text, this.props.limit);
    }
  }

  setExpandOption = (text, limit) => {
    this.setState({
      showExpandOption: text.length > limit,
    });
  };

  toggleExpand = () =>
    this.setState((prevState) => ({ expanded: !prevState.expanded }));

  truncateText = (text, limit) => `${text.slice(0, limit)}...`;

  render() {
    const {
      text,
      limit,
      classes,
      className,
      expandingText,
      collapsingText,
    } = this.props;
    const { showExpandOption, expanded } = this.state;
    return (
      <div>
        {/* prettier-ignore */}
        <p
          className={
            classNames(
              'descriptionText', className, { expanded, }
            )
          }
        >
          {showExpandOption && !expanded
            ? this.truncateText(text, limit)
            : text}
        </p>
        {showExpandOption && (
          <span
            role="button"
            tabIndex={0}
            className={classes.toggleShow}
            onClick={this.toggleExpand}
            onKeyPress={this.toggleExpand}
          >
            {expanded ? collapsingText : expandingText}
          </span>
        )}
      </div>
    );
  }
}

ExpandingTextContainer.defaultProps = {
  limit: 300,
  text: '',
  className: null,
  expandingText: 'Show more',
  collapsingText: 'Show less',
};

ExpandingTextContainer.propTypes = {
  limit: PropTypes.number,
  text: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  expandingText: PropTypes.string,
  collapsingText: PropTypes.string,
};

export default withStyles(styles)(ExpandingTextContainer);
