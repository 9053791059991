import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@nutrien/uet-react/Button';
import Grid from '@nutrien/uet-react/Grid';
import Stepper from '@nutrien/uet-react/Stepper';
import Step from '@nutrien/uet-react/Step';
import StepLabel from '@nutrien/uet-react/StepLabel';
import StepConnector from '@nutrien/uet-react/StepConnector';
import WarningIcon from '@material-ui/icons/Warning';
import PrintIcon from '@nutrien/uet-react/icons/Print';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import { NOTIFICATION_TYPES } from 'shared/ui/NotificationHelper';
import PageNotification from 'shared/ui/NotificationHelper/PageNotification';
import PageHeader from 'shared/ui/PageHeader/PageHeader';
import {
  MAKE_PAYMENT_STEPS,
  errorNotificationMessage,
} from 'components/Payments/PaymentConstants';
import {
  InvalidPaymentWarning,
  INVALID_USA_ACCOUNT_TEXT,
  INVALID_CANADIAN_ACCOUNT_TEXT,
  INVALID_INTERNATIONAL_ACCOUNT_TEXT,
} from '../InvalidPaymentWarning';

import {
  ERROR_NAMES,
  ERROR_TYPES,
} from '../../../../components/Payments/Redux/actions/ErrorActions';

import useStyles from './styles';

const PaymentHeader = (props) => {
  const {
    title,
    subNavRoutes,
    paymentError,
    showFormErrors,
    currentPaymentStep,
  } = props;
  const { southernOhioDivision } = useFlags();
  const shouldRenderErrorNotification =
    (paymentError &&
      paymentError.name !== ERROR_NAMES.DUPLICATE_TRANSACTION &&
      paymentError.errorType === ERROR_TYPES.BANNER) ||
    (showFormErrors && !paymentError);
  const {
    paymentHeader,
    paymentHeaderWithError,
    paymentErrorNotification,
    paymentErrorDuplicate,
    paymentErrorDuplicateText,
    printConfirmation,
    printIcon,
    paymentHeaderProgression,
    stepperRoot,
    greyStepIcon,
    greenStepIcon,
    blueStepIcon,
    stepIcon,
    stepConnector,
    paymentsInvalidWarning,
  } = useStyles();
  const headerClass = paymentError ? paymentHeaderWithError : paymentHeader;
  const [activeStep, setActiveStep] = React.useState(0);
  // TODO: remove usaSapAccount restrictions once SAP is rolled out to entire US market
  const { usaSapAccount, invalidCanadianAccount, invalidInternationalAccount } =
    useSelector((state) => state.selectedAccount);

  useEffect(() => {
    switch (currentPaymentStep) {
      case MAKE_PAYMENT_STEPS.INFO:
        setActiveStep(0);
        break;
      case MAKE_PAYMENT_STEPS.REVIEW:
        setActiveStep(1);
        break;
      case MAKE_PAYMENT_STEPS.CONFIRMATION:
        setActiveStep(2);
        break;
      default:
        setActiveStep(0);
        break;
    }
  }, [currentPaymentStep]);

  const stepperClassName = (stepIndex) => {
    if (stepIndex < activeStep) {
      return greyStepIcon;
    }
    if (stepIndex === activeStep) {
      return blueStepIcon;
    }
    return greenStepIcon;
  };

  const renderErrorNotification = () => (
    <PageNotification
      type={NOTIFICATION_TYPES.DANGER}
      message={errorNotificationMessage}
      mobileMessage={errorNotificationMessage}
      className={paymentErrorNotification}
    />
  );

  const renderDuplicatePaymentWarning = () => (
    <div className={paymentErrorDuplicate}>
      <WarningIcon />
      <div className={paymentErrorDuplicateText}>
        A payment for this account in the same amount has been posted today. We
        can&apos;t process a duplicate payment.
      </div>
    </div>
  );

  return (
    <div className={headerClass}>
      {usaSapAccount && !southernOhioDivision && (
        <InvalidPaymentWarning
          warning={INVALID_USA_ACCOUNT_TEXT}
          testId="accounts-card-invalid-usa-account-warning"
        />
      )}
      {invalidCanadianAccount && (
        <InvalidPaymentWarning
          warning={INVALID_CANADIAN_ACCOUNT_TEXT}
          testId="accounts-card-invalid-canadian-account-warning"
        />
      )}
      {invalidInternationalAccount && (
        <InvalidPaymentWarning
          warning={INVALID_INTERNATIONAL_ACCOUNT_TEXT}
          testId="accounts-card-invalid-international-account-warning"
        />
      )}
      <Grid container>
        <Grid lg={10} sm={9} xs={12} item>
          <PageHeader title={title} breadcrumbs={subNavRoutes} />
        </Grid>
        <Grid lg={2} sm={3} xs={12} item>
          {currentPaymentStep === MAKE_PAYMENT_STEPS.CONFIRMATION && (
            <Button
              className={printConfirmation}
              id="print-button"
              onClick={() => window.print()}
            >
              <PrintIcon className={printIcon} />
              Print Confirmation
            </Button>
          )}
        </Grid>
      </Grid>
      <div className={paymentHeaderProgression}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepConnector className={stepConnector} />}
          className={stepperRoot}
        >
          <Step key="Payment Info">
            <StepLabel
              StepIconProps={{
                classes: { root: stepIcon, active: blueStepIcon },
              }}
            >
              Payment Info
            </StepLabel>
          </Step>
          <Step key="Review" className={stepperClassName(1)}>
            <StepLabel
              StepIconProps={{
                classes: { root: stepIcon, active: blueStepIcon },
              }}
            >
              Review
            </StepLabel>
          </Step>
          <Step
            key="Confirmation"
            comleted={currentPaymentStep === MAKE_PAYMENT_STEPS.CONFIRMATION}
            className={stepperClassName(2)}
          >
            <StepLabel StepIconProps={{ classes: { root: stepIcon } }}>
              Confirmation
            </StepLabel>
          </Step>
        </Stepper>
      </div>

      {shouldRenderErrorNotification ? renderErrorNotification() : null}

      {paymentError && paymentError.name === ERROR_NAMES.DUPLICATE_TRANSACTION
        ? renderDuplicatePaymentWarning()
        : null}
    </div>
  );
};

PaymentHeader.propTypes = {
  subNavRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  showFormErrors: PropTypes.bool,
  currentPaymentStep: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  paymentError: PropTypes.shape({
    statusCode: PropTypes.number,
    name: PropTypes.string,
    message: PropTypes.string,
    errorType: PropTypes.string,
  }),
};

PaymentHeader.defaultProps = {
  paymentError: null,
  showFormErrors: false,
};

export default PaymentHeader;
