import axios from 'axios';
import sha1 from 'sha1';

export default (password) => {
  const passHash = sha1(password);
  return axios
    .get(`https://api.pwnedpasswords.com/range/${passHash.slice(0, 5)}`)
    .then((response) => {
      const hashes = {};
      response.data.split('\n').forEach((row) => {
        const hashData = row.split(':');
        const [hashMatch, timesBreached] = hashData;
        hashes[hashMatch] = timesBreached;
      });
      const hashsuffix = passHash.slice(5, 40).toUpperCase();
      const found = hashes[hashsuffix];

      return found === undefined;
    })
    .catch((error) => console.error(error));
};
