import {
  SET_PURCHASES_OK,
  SET_PURCHASES_LOADING,
  SET_PURCHASES_ERROR,
  SET_PURCHASES_FILTER,
} from './PurchasesActions';

const purchases = (
  state = { status: 'LOADING', list: [], error: '', filter: '' },
  action
) => {
  switch (action.type) {
    case SET_PURCHASES_OK:
      return { status: 'OK', list: [...action.payload], error: '' };
    case SET_PURCHASES_LOADING:
      return { status: 'LOADING', list: [], error: '' };
    case SET_PURCHASES_ERROR:
      return { status: 'ERROR', list: [], error: '' };
    case SET_PURCHASES_FILTER:
      return { ...state, filter: action.payload };
    default:
      return state;
  }
};

export default purchases;
