// this file intentionally copies logic from getQueryStringFromRouter,
// which should be considered deprecated, as current selectors should derive from state
import { parse } from 'qs'; // using qs instead of nodes built in queryString for more robust parsing
import get from 'lodash/get';

/**
 * Selects the search string from connected-react-router's state slice and parses
 * it to an object
 * @param {object} state redux slice containing router state
 * @return {object} queryString object
 */
const getQueryStringFromState = (state) => {
  const search = get(state, 'router.location.search', '');

  return parse(search.slice(1));
};

export default getQueryStringFromState;
