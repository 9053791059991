import { errorStyling, linkStyles } from 'shared/styles';

const styles = {
  error: {
    ...errorStyling,
  },
  notificationContainer: {
    marginBottom: 15,
    '& svg': {
      marginTop: 3,
    },
  },
  linkContainer: {
    width: '100%',
    textAlign: 'right',
    marginBottom: 30,
  },
  formCardContainer: {
    position: 'relative',
    zIndex: 200,
  },
  customerLoginContainer: {
    maxWidth: '500px',
    margin: 'auto',
    textAlign: 'right',
    position: 'relative',
    zIndex: 100,
  },
  customerLogin: {
    background: '#ffffff',
    padding: '7px 10px 6px',
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center',
    float: 'right',
    marginRight: 5,
    border: '1px solid #4c9e00',
    borderTop: 0,
  },
  link: {
    ...linkStyles,
    fontSize: 13,
    fontWeight: 400,
    '&:hover': {
      ...linkStyles,
    },
    '&:link': {
      ...linkStyles,
    },
    '&:active': {
      ...linkStyles,
    },
    '&:visited': {
      ...linkStyles,
    },
  },
  signUpLink: {
    fontSize: 20,
  },
};

export default styles;
