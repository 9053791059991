import { connect } from 'react-redux';
import { loginUser, clearUserError, setAlert } from 'actions';
import { fetchTerms } from 'components/Terms/termsActions';
import LoginForm from './LoginForm';

const mapStateToProps = (state) => ({
  user: state.user,
  alert: state.alert,
});
const mapDispatchToProps = { loginUser, clearUserError, setAlert, fetchTerms };

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
