import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LEGAL_FETCHED } from 'components/LegalDocuments/legalDocumentsConstants';
import { fetchLegalDocument } from 'components/LegalDocuments/legalDocumentsActions';
import { selectLegalDocument } from 'selectors';

const useLegalDocument = (document) => {
  const dispatch = useDispatch();
  const { content, status, type, version } = useSelector(selectLegalDocument);
  useEffect(() => {
    if ((document && type !== document) || status !== LEGAL_FETCHED) {
      dispatch(fetchLegalDocument(document));
    }
  }, [dispatch, document, status, type]);

  return {
    content,
    status,
    type,
    version,
  };
};

export default useLegalDocument;
