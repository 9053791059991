import * as actionTypes from '../../actions/constants';

export const initialProductsState = {
  data: [],
  loading: false,
  loadingPrices: false,
  error: null,
};

const {
  GET_RECENT_PRODUCTS_SUCCESS,
  GET_RECENT_PRODUCTS_FAILURE,
  GET_PRICES_FOR_RECENT_PRODUCTS_SUCCESS,
  GET_PRICES_FOR_RECENT_PRODUCTS_FAILURE,
  LOADING_PRICES_FOR_RECENT_PRODUCTS,
} = actionTypes;

export default (state = initialProductsState, action) => {
  switch (action.type) {
    case GET_RECENT_PRODUCTS_SUCCESS: {
      const { data, loading, error, meta } = action;
      return { ...state, data, error, loading, meta };
    }
    case LOADING_PRICES_FOR_RECENT_PRODUCTS: {
      const { loadingPrices } = action;
      return { ...state, loadingPrices };
    }
    case GET_RECENT_PRODUCTS_FAILURE: {
      const { error, loading } = action;
      return { ...state, error, loading };
    }
    case GET_PRICES_FOR_RECENT_PRODUCTS_SUCCESS: {
      const { loadingPrices, data, getPricesError, append } = action;
      let newData = data;
      // Removes last appended products
      // and re-appends products with prices
      if (append) {
        const oldData = state.data;
        newData = oldData;
        const index = oldData.length - data.length;
        newData.splice(index, data.length, ...data);
      }
      return { ...state, loadingPrices, data: newData, getPricesError };
    }
    case GET_PRICES_FOR_RECENT_PRODUCTS_FAILURE: {
      const { loadingPrices, getPricesError } = action;
      return { ...state, loadingPrices, getPricesError };
    }
    default:
      return state;
  }
};
