import { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import * as productActions from 'components/Shop/actions/productActions/productActions';
import { logout } from 'components/Authentication/actions/authenticationActions';
import isEmployeeView from 'utils/isEmployeeView';

export class HeaderContentWrapperContainer extends Component {
  static propTypes = {
    browser: PropTypes.shape({
      isSupported: PropTypes.bool,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    children: PropTypes.func.isRequired,
  };

  state = {
    isMenuOpen: false,
  };

  toggleMenu = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });

  closeMenu = () => this.setState({ isMenuOpen: false });

  render = () =>
    this.props.children(
      this.props,
      this.state.isMenuOpen,
      this.toggleMenu,
      this.closeMenu
    );
}

export const mapStateToProps = (state) => ({
  user: state.user.userData,
  userStatus: state.user.status,
  browser: state.browser,
  isMirroring: state.employee.mirroring.isMirroring,
  location: state.router.location,
  cart: state.cart,
  search: state.search,
  selectedAccount: state.selectedAccount,
  isEmployeeView: isEmployeeView(state),
});

export const mapDispatchToProps = {
  push,
  logout,
  ...productActions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderContentWrapperContainer)
);
