import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getQueryStringFromRouter } from 'shared/utils/reduxSelectors';
import { setOpenItems, setAccountTab } from 'actions/actionCreators';
import {
  gtmAccountOpenItemsFilterChange,
  gtmAccountOpenItemsInteraction,
} from 'store/middleware/TagManager/gtmActions';
import apiAdapter from 'shared/lib/cxhApiService';
import OpenItems from './OpenItems';

// TODO: this should be moved into its own file
const handleAccountUpdate = (id) => (dispatch) => {
  dispatch(setOpenItems({ status: 'LOADING', list: [] }));
  apiAdapter
    .get(`v1/accounts/${id}/openItems`)
    .then((response) => {
      dispatch(setOpenItems({ status: 'OK', list: response.data.openItems }));
    })
    .catch((err) => {
      dispatch(setOpenItems({ status: err.response.status, list: [] }));
    });
};

const updateAccountTab = (tab) => (dispatch) => {
  dispatch(setAccountTab({ tab }));
};

const mapStateToProps = (state) => ({
  account: state.selectedAccount,
  currentPage: parseInt(getQueryStringFromRouter(state.router).page || 1, 10),
  openItems: state.openItems,
  dueFilter: getQueryStringFromRouter(state.router).dueFilter || 'all',
  typeFilter: getQueryStringFromRouter(state.router).typeFilter || 'all',
  queryString: getQueryStringFromRouter(state.router),
});

const mapDispatchToProps = {
  updateAccountTab,
  push,
  handleAccountUpdate,
  gtmAccountOpenItemsFilterChange,
  gtmAccountOpenItemsInteraction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenItems);
