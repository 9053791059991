const styles = () => ({
  filterWrapperHeader: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  checkboxLabel: {
    fontSize: '0.75rem',
  },
});

export default styles;
