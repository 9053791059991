import { getDateRangesUntilTodayInMonths } from 'shared/utils/DateHelper';
import * as invoicesService from '../invoicesService';

export default (accountId, invoiceSummariesLength) => {
  const promises = [];
  const allProducts = [];

  // To avoid adding unnecessary API calls, make separate API calls to gather all of the
  // invoiced products only when the account has a significant amount of invoices.
  // Otherwise make one call to gather all of the invoiced products
  if (invoiceSummariesLength > 2000) {
    const arbitraryOldDate = '1900-01-01T00:00:00Z';
    const minDate = '2016-01-01T00:00:00Z';
    const dateRangesToQuery = [];

    // Add an arbitrary initial range to query to cover really old invoices, if they exist
    // API doesn't return correct results when fromDate is not specified, therefore starting
    // from a really old date.
    dateRangesToQuery.push({
      fromDate: arbitraryOldDate,
      toDate: minDate,
    });

    const dateRanges = getDateRangesUntilTodayInMonths(minDate, 4);

    dateRangesToQuery.push(...dateRanges);

    dateRangesToQuery.forEach((dateRange) => {
      promises.push(
        invoicesService
          .getInvoicedProducts(accountId, dateRange.fromDate, dateRange.toDate)
          .then((response) => {
            allProducts.push(...response.products);
          })
      );
    });
  } else {
    promises.push(
      invoicesService.getInvoicedProducts(accountId).then((response) => {
        allProducts.push(...response.products);
      })
    );
  }

  return Promise.all(promises)
    .then(() => {
      return allProducts;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
