import invoiceLoading from './invoiceLoading';
import invoiceLoadSuccess from './invoiceLoadSuccess';
import invoiceLoadError from './invoiceLoadError';
import { getInvoices } from '../../Account/Invoices/invoicesService';

export default (accountId, invoiceId) => (dispatch) => {
  dispatch(invoiceLoading());

  return getInvoices(accountId, [invoiceId])
    .then((response) => {
      const retrievedInvoice = response[0];
      dispatch(invoiceLoadSuccess(retrievedInvoice));
    })
    .catch((err) => {
      dispatch(invoiceLoadError(err));
      return Promise.reject(err);
    });
};
