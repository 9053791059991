import { push } from 'connected-react-router';
import { setAlert } from 'actions/actionCreators';
import resetPasswordService from './resetPasswordService';

import {
  RESET_PASSWORD_RESET_STATUS,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
} from './passwordResetConstants';

export const passwordResetSuccess = () => ({
  type: PASSWORD_RESET_SUCCESS,
});

export const passwordResetFailure = (error) => ({
  type: PASSWORD_RESET_FAILURE,
  error,
});

export const resetPasswordResetStatus = () => ({
  type: RESET_PASSWORD_RESET_STATUS,
});

export const resetPassword = (data) => (dispatch) =>
  resetPasswordService(data)
    .then(() => {
      dispatch(passwordResetSuccess());
      dispatch(
        setAlert({
          notification: {
            message: 'Your Password Has Been Reset',
            mobileMessage: 'Your Password Has Been Reset',
            type: 'SUCCESS',
          },
        })
      );
      return dispatch(push('/'));
    })
    .catch((error) => {
      dispatch(passwordResetFailure(error));
    });
