import React, { useEffect } from 'react';

import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import { useDispatch } from 'react-redux';
import { ssoLogout } from 'components/Authentication/actions/authenticationActions';

export const SsoLogout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ssoLogout());
  }, [dispatch]);

  return (
    <div>
      <MaterialLoadingIcon id="sso-logout__loading" />
    </div>
  );
};

export default SsoLogout;
