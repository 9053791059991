import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import CloseButton from './CloseButton';

import './SnackbarNotification.scss';

const SnackbarNotification = ({
  onClose,
  message,
  autoHideDuration,
  action,
  ...props
}) => (
  <Snackbar
    className="snackbar-notification"
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    onClose={onClose}
    direction="down"
    autoHideDuration={autoHideDuration}
    {...props}
    message={<div className="snackbar-notification__content">{message}</div>}
    action={action || <CloseButton onClose={onClose} />}
  />
);

SnackbarNotification.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.node,
  autoHideDuration: PropTypes.number,
  action: PropTypes.node,
};

SnackbarNotification.defaultProps = {
  onClose: () => {},
  autoHideDuration: 5000,
  action: null,
  message: null,
};

export default SnackbarNotification;
