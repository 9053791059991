import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

import withPermits from '../withPermits';
import { permit as permitPropType } from '../permitsPropTypes';
import { getPermitByIdAndGrowerName } from '../selectors';
import PermitDetailsPage from './PermitDetailsPage';
import permitTabsEnum from './permitTabsEnum';

export class PermitDetailsPageContainer extends Component {
  state = {
    selectedTab: permitTabsEnum.Chemicals,
  };

  handleChange = (e, value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const { fetchPermitsError, isFetchingPermits, match, permit } = this.props;
    let permitNotFoundError;

    if (!permit && !isFetchingPermits) {
      const { growerName, permitId } = match.params;
      permitNotFoundError = new Error(
        `No permit found for permitId ${permitId} and growerName ${growerName}`
      );
    }

    return (
      <PermitDetailsPage
        permit={permit}
        isFetchingPermits={isFetchingPermits}
        error={fetchPermitsError || permitNotFoundError}
        selectedTab={this.state.selectedTab}
        handleChange={this.handleChange}
      />
    );
  }
}

PermitDetailsPageContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  permit: permitPropType,
  fetchPermitsError: PropTypes.instanceOf(Error),
  isFetchingPermits: PropTypes.bool,
};

PermitDetailsPageContainer.defaultProps = {
  permit: undefined,
  fetchPermitsError: undefined,
  isFetchingPermits: false,
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { permitId, growerName } = match.params;
  const permit = getPermitByIdAndGrowerName(state, permitId, growerName);

  return { permit };
};

export default withPermits(
  withRouter(connect(mapStateToProps)(PermitDetailsPageContainer))
);
