import { connect } from 'react-redux';

import SupportExperience from './SupportExperience';

export const mapStateToProps = (state) => ({
  customers: state.employee.customers.all,
  isFetching: state.employee.customers.isFetching,
});

export default connect(mapStateToProps)(SupportExperience);
