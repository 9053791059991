import {
  PRODUCT_HISTORY_OK,
  PRODUCT_HISTORY_LOADING,
  PRODUCT_HISTORY_ERROR,
  PRODUCT_HISTORY_SORTED,
  PRODUCT_HISTORY_ADDING_TO_CART,
  PRODUCT_HISTORY_ADD_TO_CART_SUCCESS,
  PRODUCT_HISTORY_ADD_TO_CART_FAILURE,
  PRODUCT_HISTORY_NEW_SHELF,
  PRODUCT_HISTORY_NEW_TIMEFRAME,
  PRODUCT_HISTORY_HUBSPOT_OPT_IN_SUCCESS,
  VIEW_PRODUCT_HISTORY,
} from './ProductHistoryActionTypes';

export function setProductHistoryOk(productHistory) {
  return { type: PRODUCT_HISTORY_OK, payload: productHistory };
}

export function setProductHistoryLoading() {
  return { type: PRODUCT_HISTORY_LOADING };
}

export function setProductHistorySorted(sortedProductHistory, sortedColumn) {
  return {
    type: PRODUCT_HISTORY_SORTED,
    payload: sortedProductHistory,
    sortedColumn,
  };
}

export function setProductHistoryAddingToCart(productNumber, shelf) {
  return {
    type: PRODUCT_HISTORY_ADDING_TO_CART,
    payload: [],
    productNumber,
    shelf,
  };
}

export function setProductHistoryError(err) {
  return { type: PRODUCT_HISTORY_ERROR, payload: err };
}

export function setProductHistoryReorderSuccess() {
  return {
    type: PRODUCT_HISTORY_ADD_TO_CART_SUCCESS,
    payload: [],
  };
}

export function setProductHistoryReorderFailure(error, selectedShelf) {
  return {
    type: PRODUCT_HISTORY_ADD_TO_CART_FAILURE,
    payload: [],
    selectedShelf,
    error,
  };
}

export function setShelfForProductHistory(selectedShelves) {
  return {
    type: PRODUCT_HISTORY_NEW_SHELF,
    payload: [],
    selectedShelves,
    error: '',
  };
}

export function setTimeframeForProductHistory(startDate, endDate) {
  return {
    type: PRODUCT_HISTORY_NEW_TIMEFRAME,
    payload: [],
    startDate,
    endDate,
    error: '',
  };
}

export function setHubspotOptInSuccessForProductHistory(shelf) {
  return {
    type: PRODUCT_HISTORY_HUBSPOT_OPT_IN_SUCCESS,
    payload: [],
    error: '',
    shelf,
  };
}

export const viewProductHistory = (shelf, start, end, isDownload = false) => ({
  type: VIEW_PRODUCT_HISTORY,
  shelf,
  start,
  end,
  isDownload,
});
