import { POST_ORDER_SUCCESS } from 'components/Cart/actions/actionTypes';
import {
  cartItemToProductFieldObject,
  gtmCurrency,
  accountLocationCodeToCurrency,
} from '../utils';

/**
 * https://developers.google.com/tag-manager/enhanced-ecommerce#purchase
 * @param action - see POST_ORDER_SUCCESS action creator
 * @returns {{event: string, ecommerce: {currencyCode: string, purchase: *}}}
 */
export const purchase = (action, { selectedAccount: { locationCode } }) => {
  const { data: orderResponse, cartData: cartItems } = action;

  const { lineItems, orderNumber, locationId } = orderResponse.data.attributes;
  const revenue = lineItems
    .map((lineItem) => {
      const { price, quantity } = lineItem.attributes;
      return price * quantity;
    })
    .reduce((a, b) => a + b, 0);

  return {
    event: 'EEC_Purchase',
    order: {
      branchId: locationId,
    },
    ecommerce: {
      currencyCode: accountLocationCodeToCurrency(locationCode),
      purchase: {
        actionField: {
          id: orderNumber,
          affiliation: 'CXH',
          revenue: gtmCurrency(revenue),
        },
        products: cartItems.map((item) => cartItemToProductFieldObject(item)),
      },
    },
  };
};

export const purchaseEventsMap = {
  [POST_ORDER_SUCCESS]: purchase,
};
