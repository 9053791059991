import {
  CXH_REDIRECT_NEW_WEB,
  CXH_NUTRIEN_HUB_MOBILE_APP,
  CXH_NUTRIEN_HUB_GOOGLE_APP,
  CXH_NUTRIEN_HUB_APPLE_APP,
} from '../gtmConstants';

export const redirectToNewWeb = () => {
  return {
    event: 'event_track',
    eventId: 'CXH_REDIRECT_NEW_WEB',
    category: 'Navigation',
    action: 'HUB Redirect - New Web',
  };
};

export const openNutrienHubMobileApp = () => {
  return {
    event: 'event_track',
    eventId: 'CXH_NUTRIEN_HUB_MOBILE_APP',
    category: 'Dialog',
    action: 'HUB Redirect - Mobile App',
  };
};

export const openNutrienHubGoogle = () => {
  return {
    event: 'event_track',
    eventId: 'CXH_NUTRIEN_HUB_GOOGLE_APP',
    category: 'Application',
    action: 'Hub Redirect - Download Google',
  };
};

export const openNutrienHubApple = () => {
  return {
    event: 'event_track',
    eventId: 'CXH_NUTRIEN_HUB_APPLE_APP',
    category: 'Application',
    action: 'Hub Redirect - Download Apple',
  };
};

export const newWebAndMobileEventsMap = {
  [CXH_REDIRECT_NEW_WEB]: redirectToNewWeb,
  [CXH_NUTRIEN_HUB_MOBILE_APP]: openNutrienHubMobileApp,
  [CXH_NUTRIEN_HUB_GOOGLE_APP]: openNutrienHubGoogle,
  [CXH_NUTRIEN_HUB_APPLE_APP]: openNutrienHubApple,
};
