import {
  NOTIFICATIONS_STAGED_FOR_DELETION,
  NOTIFICATIONS_DELETED,
  NOTIFICATIONS_UNDO_DELETION,
} from '../actions';

export default (state = false, action) => {
  switch (action.type) {
    case NOTIFICATIONS_STAGED_FOR_DELETION:
      return true;
    case NOTIFICATIONS_DELETED:
    case NOTIFICATIONS_UNDO_DELETION:
      return false;
    default:
      return state;
  }
};
