import React from 'react';
import T from 'prop-types';
import Box from '@nutrien/uet-react/Box';
import Button from '@nutrien/uet-react/Button';
import Dialog from '@nutrien/uet-react/Dialog';
import DialogActions from '@nutrien/uet-react/DialogActions';
import DialogContent from '@nutrien/uet-react/DialogContent';
import DialogContentText from '@nutrien/uet-react/DialogContentText';
import DialogTitle from '@nutrien/uet-react/DialogTitle';
import { Link } from 'react-router-dom';

const ConfirmationDialog = ({ onClose, open }) => (
  <Dialog maxWidth="xs" open={open} onClose={onClose}>
    <DialogTitle>Thank You</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Nutrien has received your opt-out request. Please allow 15 days for us
        to complete your request.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Link to="/">
        <Box pb={1.5}>
          <Button
            size="large"
            margin="none"
            variant="contained"
            onClick={onClose}
          >
            Return to Home Page
          </Button>
        </Box>
      </Link>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  onClose: T.func.isRequired,
  open: T.bool.isRequired,
};

export default ConfirmationDialog;
