import get from 'lodash/get';
import { GET_STATES_FAILURE } from '../actionTypes';

export default (error) => {
  const err = get(error, 'message', 'There was an error getting states');
  return {
    type: GET_STATES_FAILURE,
    error: err,
  };
};
