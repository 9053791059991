/* eslint-disable no-unused-expressions */
import React, { useEffect, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams, generatePath, useLocation } from 'react-router';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import AccountDescriptionText from 'shared/ui/AccountDescriptionText';
import MaterialLoadingIcon from '../../../shared/ui/RebrandedMaterialLoadingIconInline/MaterialLoadingIcon';
import ContractsTab from './ContractsTab/ContractsTab';
import OrdersTab from './OrdersTab/OrdersTab';
import { WithFetchAccountsContainer } from '../../Shop/containers/containers';
import { contractedProductsActions } from '../../Shop/actions';
import documentsActions from '../actions/actions';

import useStyles from './styles';

const documentsTabValues = ['contracts', 'orders'];

const Documents = ({ match }) => {
  const documents = useSelector((state) => state.documents);
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tabValue } = useParams();

  const setTabContent = (accountNumber) => {
    dispatch(documentsActions.getCustomerOrders(accountNumber));
    dispatch(documentsActions.getOpenContracts(accountNumber));
  };

  const changeTab = (event, newTab) => {
    const newPath = generatePath(match.path, { tabValue: newTab });
    history.push({ ...location, pathname: newPath });
  };

  const backToOverview = () => {
    history.push('/');
  };

  useEffect(() => {
    // if no tabValue change tab to the first tab as a default
    if (!tabValue) {
      changeTab(null, documentsTabValues[0]);
    }
  }, [tabValue, changeTab]);

  useEffect(() => {
    if (selectedAccount.number) {
      setTabContent(selectedAccount.number);
      dispatch(
        contractedProductsActions.getContractedProducts(selectedAccount.number)
      );
    }
    // the below check ensures the customer account is an SAP accounts, if not it redirects to a 404 page
    if (selectedAccount.number && selectedAccount.number.length !== 8) {
      history.replace('notfound');
    }
  }, [selectedAccount]);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    TabPanel.propTypes = {
      children: PropTypes.node.isRequired,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
      index: PropTypes.number.isRequired,
    };

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    );
  };

  return (
    <>
      <div
        onKeyPress={backToOverview}
        role="button"
        tabIndex={0}
        className={classes.backToOverview}
        onClick={backToOverview}
      >
        <Icon
          className={classes.backToOverviewIcon}
          icon="arrowDown"
          type="success"
        />
        <span className={classes.backToOverview}>Home</span>
      </div>
      {selectedAccount.id ? (
        <h1>
          <AccountDescriptionText
            key={selectedAccount.id}
            account={selectedAccount}
          />
        </h1>
      ) : (
        <div>
          <MaterialLoadingIcon />
        </div>
      )}

      <div className={classes.tabsContainer}>
        {/* This can take an action that will fire the get for the relevant data */}
        <Tabs
          value={tabValue || documentsTabValues[0]}
          onChange={changeTab}
          className={classes.tabs}
        >
          <Tab
            value={documentsTabValues[0]}
            label="Contracts"
            aria-controls="documents-tab-contracts"
            data-test="documents-contracts-tab"
            className={`documents__tab ${classes.tab}`}
          />
          <Tab
            value={documentsTabValues[1]}
            label="Orders"
            aria-controls="documents-tab-orders"
            data-test="documents-orders-tab"
            className={`documents__tab ${classes.tab}`}
          />
        </Tabs>
        <hr className={classes.hr} />
        <TabPanel
          value={tabValue}
          index={documentsTabValues[0]}
          className="documents__panel"
        >
          <ContractsTab documents={documents} />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={documentsTabValues[1]}
          className="documents__panel"
        >
          <OrdersTab documents={documents} />
        </TabPanel>
      </div>
    </>
  );
};

Documents.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default WithFetchAccountsContainer(Documents);
