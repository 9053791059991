const countyTransformer = (counties) => {
  try {
    return counties.length
      ? counties.map((county) => ({
          countryId: county.country,
          id: county.id,
          intId: county.intId,
          name: county.name,
          stateId: county.state,
        }))
      : [];
  } catch (e) {
    console.error('One or more counties were not formatted properly');
    return [];
  }
};

export default countyTransformer;
