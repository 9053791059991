import apiAdapter from 'shared/lib/cxhApiService';

const getPurchases = async (accountId, startDate, endDate) => {
  return (
    accountId &&
    apiAdapter.get(
      `v1/accounts/${accountId}/product-history?start_date=${startDate}&end_date=${endDate}`
    )
  );
};

export default getPurchases;
