// represents receiving a response from the PUT endpoint for updating preferences
export const NOTIFICATIONS_PREFERENCES_UPDATE_SUCCESS =
  'NOTIFICATIONS_PREFERENCES_UPDATE_SUCCESS';
export const NOTIFICATIONS_PREFERENCES_UPDATE_FAILURE =
  'NOTIFICATIONS_PREFERENCES_UPDATE_FAILURE';
export const NOTIFICATIONS_PREFERENCES_UPDATE_START =
  'NOTIFICATIONS_PREFERENCES_UPDATE_START';

export const contactMethodTypes = Object.freeze({
  email: 'email',
  phone: 'phone',
  inbox: 'inbox',
});

export const ERROR_TYPES = {
  UPDATE_FAILURE: 'UPDATE_FAILURE',
};
