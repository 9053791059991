import PropTypes from 'prop-types';
import React from 'react';

import { MaterialLoadingIcon } from 'shared/ui';
import { getPage } from 'shared/utils/pagination';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import Pagination from 'shared/ui/Pagination';

import CustomerList from '../../Customers/CustomerList';
import * as EmployeePropTypes from '../../EmployeePropTypes';
import EmptyCustomerList from '../../Customers/CustomerList/EmptyCustomerList';

const GeneralExperience = ({
  displayTitle,
  customers,
  gettingCustomers,
  onFilterChange,
  filter,
  currentPage,
  pageSize,
  onPaginationClick,
  totalCustomers,
  totalFilteredCustomers,
  error,
}) => {
  if (error) return <ApiErrorMessage entityName="Customer" />;
  return (
    <div className="employee-general-experience">
      <div className="customer-list-header">
        {displayTitle && <h3 className="customer-list-title">My Customers</h3>}
        <hr className="header-hr" />
        <input
          className="customer-filter"
          type="text"
          placeholder="Search"
          value={filter}
          onChange={(e) => {
            onFilterChange(e.target.value);
          }}
        />
      </div>
      {(gettingCustomers && <MaterialLoadingIcon />) || (
        <div>
          <CustomerList customers={getPage(customers, currentPage, pageSize)} />
          {(!totalCustomers || !totalFilteredCustomers) && (
            <EmptyCustomerList
              primary={
                totalCustomers
                  ? '0 Results Found'
                  : 'You have no customers enrolled in the Nutrien Digital Hub.'
              }
              secondary={
                totalCustomers
                  ? 'Your search did not match any customers '
                  : 'Please talk to your customers about enrolling in the Nutrien Digital Hub.'
              }
            />
          )}
          <Pagination
            current={currentPage}
            total={totalFilteredCustomers}
            onChange={onPaginationClick}
            pageSize={pageSize}
          />
        </div>
      )}
    </div>
  );
};

GeneralExperience.defaultProps = {
  displayTitle: false,
  customers: [],
  filter: '',
  totalCustomers: 0,
  totalFilteredCustomers: 0,
  currentPage: 1,
  pageSize: 10,
  error: undefined,
};

GeneralExperience.propTypes = {
  displayTitle: PropTypes.bool,
  customers: EmployeePropTypes.customers,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.string,
  gettingCustomers: PropTypes.bool.isRequired,
  totalCustomers: PropTypes.number,
  totalFilteredCustomers: PropTypes.number,
  currentPage: PropTypes.number,
  onPaginationClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  error: PropTypes.instanceOf(Error),
};

export default GeneralExperience;
