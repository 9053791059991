import React from 'react';
import { useSelector } from 'react-redux';
import { AccountDescriptionText } from 'shared/ui';
import './AccountFulfillmentInfo.scss';

const AccountFulfillmentInfo = () => {
  const selectedAccount = useSelector((state) => state.selectedAccount);
  return (
    <div className="account-fulfillment__container">
      <h2 className="account-fulfillment__header">Account</h2>
      <p className="account-fulfillment__account-info" data-hj-suppress="">
        <AccountDescriptionText account={selectedAccount} />
      </p>
      <p
        className="account-fulfillment__account-location-info jsLocationInfo"
        data-hj-suppress=""
      >
        {`${selectedAccount.branchName} #${selectedAccount.branchId}`}
      </p>
    </div>
  );
};

export default AccountFulfillmentInfo;
