import { combineReducers } from 'redux';

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from 'components/Authentication/actions/authenticationConstants';

export const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return true;
    case LOGOUT:
    case LOGIN_FAILURE:
      return false;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return null;
    case LOGIN_FAILURE:
      return action.error;
    default:
      return state;
  }
};

export const session = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.payload;
    case LOGOUT:
    case LOGIN_FAILURE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ session, isAuthenticated, error });
