import { GTM_ONLINE_ORDERS_INTERACTION } from '../gtmConstants';

export const recentOnlineOrdersInteraction = (action) => ({
  event: 'CXH_RecentOnlineOrdersWidget',
  cxh: {
    onlineOrders: {
      interaction: {
        ...action.payload,
      },
    },
  },
});

export const onlineOrdersEventMap = {
  [GTM_ONLINE_ORDERS_INTERACTION]: recentOnlineOrdersInteraction,
};
