import PropTypes from 'prop-types';

export const productPropType = PropTypes.shape({
  invoiceId: PropTypes.string.isRequired,
  invoiceLineNumber: PropTypes.number,
  invoiceDate: PropTypes.string,
  quantity: PropTypes.number,
  quantityUOM: PropTypes.string,
  unitPrice: PropTypes.number,
  price: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productNameRaw: PropTypes.string,
  productCategory: PropTypes.string,
  subCategoryName: PropTypes.string,
  status: PropTypes.number,
  commonName: PropTypes.string,
  agrianProductId: PropTypes.string,
  packSize: PropTypes.number,
  packUnits: PropTypes.number,
  packVolume: PropTypes.number,
});

export const groupedInvoicedProductsPropType = PropTypes.shape({
  productPropType,
});

export const invoicedProductsPropType = PropTypes.arrayOf(productPropType);

export const selectedProductsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    productId: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
  })
);
