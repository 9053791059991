export default (theme) => ({
  savedPaymentMethodsContainer: {
    width: '100%',
    borderTop: '1px solid #cccccc',
    padding: '20px 0 0 0',
  },
  savedPaymentMethodLabelNewAccount: {
    display: 'block',
    color: '#004538',
    position: 'relative',
    height: '20px',
    marginBottom: '25px',
    '& input': {
      marginRight: '15px',
    },
  },
  savedPaymentMethodLabelText: {
    display: 'inline-block',
    position: 'absolute',
    top: '-15px',
    left: '25px',
    right: '0px',
  },
  addNewBank: {
    color: theme.palette.brand.green,
    fontSize: '14px',
    textDecoration: 'underline',
    marginTop: '0.25rem',
  },
  savedPaymentName: {
    color: theme.palette.text.main,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  paymentMethodH2: {
    fontSize: '24px',
    color: theme.palette.text.main,
    marginBottom: '0',
  },
  paymentMethodHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    order: '0',
    marginBottom: '1em',
  },
  paymentMehtodNotification: {
    marginBottom: '35px',
  },
  paymentMethodLink: {
    alignSelf: 'flex-end',
    position: 'relative',
    bottom: '27px',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginBottom: '-20px',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  accountInfoHelpText: {
    margin: '-25px 0 27px 0',
  },
  '@media (max-width: 600px)': {
    paymentMethodLink: {
      order: '1',
      alignSelf: 'flex-start',
      paddingTop: '22px',
    },
  },
  '@media (max-width: 480px)': {
    savedPaymentMethodLabel: {
      fontSize: '20px',
    },
    savedPaymentMethodLabelText: {
      '& span:last-of-type': {
        fontSize: '20px',
      },
    },
    paymentSubHeader: {
      paddingRight: '5px',
    },
  },
});
