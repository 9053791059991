import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, withStyles, ClickAwayListener } from '@material-ui/core';
import DownCaret from '@material-ui/icons/KeyboardArrowDown';
import { INVOICE_STATUSES, INVOICE_FILTER_TYPES } from '../../actions';
import InvoiceSearch from '../InvoiceSearchComponent';

import styles from './styles';

class InvoiceFilterComponent extends Component {
  state = {
    anchorEl: null,
    invoiceSearchOpen: false,
    currentFilteredOptions: this.props.selectionOptions,
  };

  componentDidUpdate(oldProps) {
    if (oldProps.selectionOptions !== this.props.selectionOptions) {
      this.deselectAllOptions();
    }
  }

  deselectAllOptions() {
    const { selectionOptions } = this.props;
    this.setState({ currentFilteredOptions: selectionOptions });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  checkInvoicesForRebateAndDiscount = (invoiceList) => {
    let hasRebates = false;
    let hasDiscounts = false;

    invoiceList.forEach((invoice) => {
      if (
        !hasRebates &&
        invoice.status === INVOICE_STATUSES.DUE_REBATE_AVAILABLE
      )
        hasRebates = true;
      if (
        !hasDiscounts &&
        invoice.status === INVOICE_STATUSES.DUE_DISCOUNT_AVAILABLE
      )
        hasDiscounts = true;
    });

    return { hasRebates, hasDiscounts };
  };

  handleFilterChange = (event) => {
    const {
      push,
      account,
      queryString,
      updateAccountTab,
      clearFilteredProduct,
    } = this.props;
    const { name, value } = event.target;

    if (!value) {
      return;
    }

    if (value === INVOICE_FILTER_TYPES.FILTER_BY_PRODUCT) {
      this.setState({ invoiceSearchOpen: true });
      return;
    }
    clearFilteredProduct();

    this.setState({ invoiceSearchOpen: false });

    queryString[name] = value;

    delete queryString.page;

    let newQueryString = '';
    const queryArray = Object.entries(queryString);

    queryArray.forEach((item) => {
      newQueryString += `&${item[0]}=${encodeURIComponent(item[1])}`;
    });

    if (newQueryString.length > 0) {
      newQueryString = `?${newQueryString.slice(1)}`;
    }

    updateAccountTab(`invoices${newQueryString}`);

    push(`/accounts/${account.id}/invoices${newQueryString}`);

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  filterByProduct = (searchTerm) => {
    const selectionOptions = [...this.props.selectionOptions];

    const lowercaseTerm = searchTerm.toLowerCase();

    const filteredOptions = selectionOptions.filter(
      (option) =>
        option.name.toLowerCase().startsWith(lowercaseTerm) ||
        option.sku.toLowerCase().startsWith(lowercaseTerm)
    );
    this.setState({ currentFilteredOptions: filteredOptions });
  };

  renderDropdownOptions = () => {
    const { invoices } = this.props;
    const rebateAndDiscountCheck = this.checkInvoicesForRebateAndDiscount(
      invoices
    );
    const filters = Object.keys(INVOICE_FILTER_TYPES).sort();

    return filters.map((filterType) => {
      if (
        (filterType === INVOICE_FILTER_TYPES.DUE_DISCOUNT_AVAILABLE &&
          !rebateAndDiscountCheck.hasDiscounts) ||
        (filterType === INVOICE_FILTER_TYPES.DUE_REBATE_AVAILABLE &&
          !rebateAndDiscountCheck.hasRebates)
      ) {
        return null;
      }
      return (
        <MenuItem
          onClick={() => {
            this.handleClose();
            this.handleFilterChange({
              target: {
                value: INVOICE_FILTER_TYPES[filterType],
                name: 'typeFilter',
              },
            });
          }}
          key={filterType}
        >
          {INVOICE_FILTER_TYPES[filterType]}
        </MenuItem>
      );
    });
  };

  render() {
    const { anchorEl, invoiceSearchOpen, currentFilteredOptions } = this.state;
    const { classes, statusFilter } = this.props;

    return (
      <div className={classes.InvoiceFilterComponent}>
        <Button
          aria-owns={anchorEl ? 'sort-by-options-list' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.sortButton}
        >
          <div className={classes.statusText}>
            {statusFilter === 'productsearch'
              ? 'Filter By Product'
              : statusFilter}
          </div>
          <DownCaret
            style={{
              marginLeft: '10px',
              transform: anchorEl ? 'rotate(180deg)' : '',
              transition: 'all 0.25s',
            }}
          />
        </Button>
        <Menu
          id="sort-by-options-list"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          getContentAnchorEl={null}
        >
          {this.renderDropdownOptions()}
        </Menu>
        <ClickAwayListener
          onClickAway={() => {
            this.setState({ invoiceSearchOpen: false });
          }}
        >
          <div>
            {invoiceSearchOpen ? (
              <InvoiceSearch
                selectionOptions={currentFilteredOptions}
                filterSearch={this.filterByProduct}
                close={() => this.setState({ invoiceSearchOpen: false })}
              />
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

InvoiceFilterComponent.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      isSupressed: PropTypes.bool,
      amountDue: PropTypes.number,
    })
  ).isRequired,
  updateAccountTab: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  queryString: PropTypes.shape({}),
  classes: PropTypes.shape({}).isRequired,
  statusFilter: PropTypes.string,
  clearFilteredProduct: PropTypes.func.isRequired,
  selectionOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

InvoiceFilterComponent.defaultProps = {
  queryString: {},
  statusFilter: null,
  selectionOptions: [],
};

export default withStyles(styles)(InvoiceFilterComponent);
