import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { gtmReorderTopProduct } from 'store/middleware/TagManager/gtmActions';
import {
  reorderProduct,
  setProductReorderSuccess,
  setProductReorderFailure,
} from './ReorderOrRequestInfoButtonActions';
import ReorderOrRequestInfoButton from './ReorderOrRequestInfoButton';

const mapStateToProps = (state) => ({
  cart: state.cart,
  productHistory: state.productHistory,
  user: state.user,
  selectedAccount: state.selectedAccount,
  products: state.shop.products,
  accounts: state.accounts,
});

const mapDispatchToProps = {
  reorderProduct,
  setProductReorderSuccess,
  setProductReorderFailure,
  gtmReorderTopProduct,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(ReorderOrRequestInfoButton);
