import { connect } from 'react-redux';
import React from 'react';
import { AuthenticatedComponent } from '@nutrien/fe-domain-utils';
import { logout } from 'components/Authentication/actions/authenticationActions';
import SessionChecker from './SessionCheckerComponent';

const mapStateToProps = (state) => ({ router: state.router });
const mapDispatchToProps = { logout };

const SessionCheckerContainer = (props) => (
  <AuthenticatedComponent>
    <SessionChecker {...props} />
  </AuthenticatedComponent>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionCheckerContainer);
