/**
 * Filters invoices by invoice Id.
 * @param  {Object[]} invoices  Filtered invoices
 * @param  {String} invoiceId   The invoice ID
 * @return {Object[]}           Invoices filtered by the desired Id.
 */
export default (invoices, invoiceId) =>
  invoiceId
    ? invoices.filter((invoice) => invoice.id.includes(invoiceId))
    : invoices;
