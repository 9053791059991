import {
  filterByDate,
  subtractDaysFromDate,
  formatDateToIsoString,
} from './dateFilterUtils';
import { filterChoices } from '../../Filters/InvoiceDateRangeFilter';

const {
  ALL,
  LAST_30_DAYS,
  LAST_60_DAYS,
  YEAR_TO_DATE,
  LAST_YEAR,
} = filterChoices;

export default (invoices, filterChoice) => {
  if (filterChoice instanceof Array) {
    return filterByDate({
      items: invoices,
      filterKey: 'date', // the prop on the invoice to filter on.
      startDateString: filterChoice[0],
      endDateString: filterChoice[1],
    });
  }

  // value will be undefined or a string at this point.
  let startDate;
  let endDate;
  const now = new Date();

  switch (filterChoice) {
    case ALL:
      break; // no start and end date.
    case LAST_30_DAYS:
      startDate = subtractDaysFromDate(now, 30);
      break;
    case LAST_60_DAYS:
      startDate = subtractDaysFromDate(now, 60);
      break;
    case YEAR_TO_DATE: {
      const currentYear = now.getFullYear();
      startDate = formatDateToIsoString(new Date(`${currentYear}-01-01`));
      endDate = formatDateToIsoString(now);
      break;
    }
    case LAST_YEAR: {
      const lastYear = now.getFullYear() - 1;
      startDate = formatDateToIsoString(new Date(`${lastYear}-01-01`));
      endDate = formatDateToIsoString(new Date(`${lastYear}-12-31`));
      break;
    }
    default: {
      break;
    }
  }

  return filterByDate({
    items: invoices,
    filterKey: 'date', // the prop on the invoice to filter on.
    startDateString: startDate,
    endDateString: endDate,
  });
};
