export const errorStyling = {
  color: '#d24242',
  fontSize: 13,
  fontWeight: 200,
};

export const nutrienGreen = {
  color: '#4c9e00',
};

export const linkStyles = {
  lineHeight: '1.15',
  color: '#4c9e00',
  textDecoration: 'none',
  cursor: 'pointer',
};

export const buttonContainerStyles = {
  width: '100%',
  float: 'right',
  marginBottom: 10,
  justifyContent: 'space-between',
  '& button': {
    width: '47%',
  },
};

export const mobileButtonContainerStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0px',
  '& button': {
    width: '100%',
    margin: '10px 0',
  },
};

export const modalContainerStyles = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 99999,
  overflowY: 'scroll',
  background: 'rgba(0, 0, 0, .4)',
  paddingTop: '100px',
};

export const arrowLinkStyles = {
  cursor: 'pointer',
  fontSize: '.85em',
  lineHeight: '1.15',
  textAlign: 'left',
  color: '#4c9e00',
  fontWeight: 600,
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  '& > img': {
    width: '.5em',
    margin: 5,
  },
};

export const arrowLinkContainerStyles = {
  color: '#464646',
  fontSize: '1em',
  height: 27,
  fontWeight: 400,
  textAlign: 'left',
  margin: '15px 5%',
};
