// CART
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILURE = 'GET_CART_FAILURE';
export const LOADING_CART = 'LOADING_CART';
export const UPDATING_CART = 'UPDATING_CART';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE';
export const ADDING_TO_CART = 'ADDING_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
export const SET_CART_QUANTITY = 'SET_CART_QUANTITY';
export const REMOVE_CART_ITEMS = 'REMOVE_CART_ITEMS';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAILURE = 'GET_ADDRESSES_FAILURE';
export const UPDATING_ADDRESS = 'UPDATING_ADDRESS';
export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';
export const SET_PICKUP = 'SET_PICKUP';
export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILURE = 'GET_STATES_FAILURE';
export const SAVE_ADDRESS_SUCCESS = 'SAVE_ADDRESS_SUCCESS';
export const SAVE_ADDRESS_FAILURE = 'SAVE_ADDRESS_FAILURE';
export const GET_BRANCH_ADDRESS_SUCCESS = 'GET_BRANCH_ADDRESS_SUCCESS';
export const GET_BRANCH_ADDRESS_FAILURE = 'GET_BRANCH_ADDRESS_FAILURE';
export const UPDATING_BRANCH_ADDRESS = 'UPDATING_BRANCH_ADDRESS';
export const GET_COUNTIES_SUCCESS = 'GET_COUNTIES_SUCCESS';
export const GET_COUNTIES_FAILURE = 'GET_COUNTIES_FAILURE';
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT ';
export const SET_CUSTOM_CROP_CARE = 'SET_CUSTOM_CROP_CARE';
export const SET_MOST_RECENT_ADDITION = 'SET_MOST_RECENT_ADDITION';
export const VALIDATE_INPUT = 'VALIDATE_INPUT';

// CHECKOUT
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAILURE = 'POST_ORDER_FAILURE';
export const LOADING_ORDER = 'LOADING_ORDER';
export const SET_PICKUP_DATE = 'SET_PICKUP_DATE';
export const SET_ORDER_MEMO = 'SET_ORDER_MEMO';
export const SET_PURCHASE_ORDER_NUMBER = 'SET_PURCHASE_ORDER_NUMBER';
export const SET_CUSTOM_APPLIED = 'SET_CUSTOM_APPLIED';
export const SET_APPLICATION_MEMO = 'SET_APPLICATION_MEMO';

// INTERSTITIAL MODAL
export const UPDATE_SHOW_ORDER_CONDITIONS = 'UPDATE_SHOW_ORDER_CONDITIONS';

export const CHECK_CART_PRODUCTS = 'CHECK_CART_PRODUCTS';
