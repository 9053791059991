import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfiniteDatePicker } from 'nutrien-common-components-react/dist/mdc';
import Modal from 'shared/ui/Modal';
import OnClickOutside from 'shared/ui/OnClickOutside/OnClickOutside';
import SelectedDate from './SelectedDate';

import './DeliveryDate.scss';

class DeliveryDate extends Component {
  state = { datePickerVisible: false };

  componentDidMount() {
    const { setPickupDate, cart } = this.props;
    const {
      isPickup: { pickup },
    } = cart;
    const numOut = pickup ? 2 : 7;

    const defaultDate = moment(new Date(), 'MM/DD/YYYY')
      .add(numOut, 'days')
      .format('MM/DD/YYYY');

    if (!cart.preferences.dateSet) {
      setPickupDate(defaultDate);
    }
  }

  setDatePickerVisibility = (isVisible) =>
    this.setState({ datePickerVisible: isVisible });

  handleSelectButton = (date) => {
    const { setPickupDate } = this.props;
    const selectedDate = moment(date, 'DD-MM-YYYY');
    const deliveryApplicationDate = selectedDate.format('MM/DD/YYYY');
    const dateSet = true;
    setPickupDate(deliveryApplicationDate, dateSet);
    this.setDatePickerVisibility(false);
  };

  render() {
    const { cart } = this.props;
    const { datePickerVisible } = this.state;
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    const hint = cart.preferences.dateSet
      ? moment(cart.preferences.date, 'MM/DD/YYYY').format(
          'dddd, MMMM DD, YYYY'
        )
      : 'Select Date';

    return (
      <>
        <h2 className="delivery-date__header" data-test="delivery-date-wrapper">
          Desired {cart.isPickup.pickup ? 'Pickup' : 'Delivery'} Date
        </h2>
        <div
          className="delivery-date__date-button-wrapper"
          data-test="date-picker-wrapper"
        >
          <p className="delivery-date__legal-header">
            Your selected date will be reviewed and confirmed by your branch.
          </p>
          <SelectedDate
            data-test="delivery-date-selected-date"
            date={hint}
            onClick={() => this.setDatePickerVisibility(true)}
          />
          <OnClickOutside
            clickOutsideHandler={() => this.setDatePickerVisibility(false)}
          >
            <Modal
              data-test="delivery-date-simple-dialog"
              open={datePickerVisible}
            >
              <InfiniteDatePicker
                selected={
                  cart.preferences.dateSet
                    ? new Date(cart.preferences.date)
                    : undefined
                }
                height={500}
                minDate={minDate}
                onSelect={this.handleSelectButton}
              />
            </Modal>
          </OnClickOutside>
        </div>
      </>
    );
  }
}

DeliveryDate.propTypes = {
  cart: PropTypes.shape({
    isPickup: PropTypes.shape({ pickup: PropTypes.bool.isRequired }),
    preferences: PropTypes.shape({
      date: PropTypes.string,
      dateSet: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  setPickupDate: PropTypes.func.isRequired,
};

export default DeliveryDate;
