import React from 'react';
import get from 'lodash/get';
import propTypes from 'prop-types';
import {
  TableCell,
  TableRow,
} from 'nutrien-common-components-react/dist/mdc/Table';

const TableRowBuilder = (props) => {
  const { data, columns } = props;
  return (
    <TableRow className="payments-table__row">
      {columns.map((col) => (
        <TableCell key={col.propertyName} className={col.className}>
          {col.format ? col.format(data) : get(data, col.propertyName, '')}
        </TableCell>
      ))}
      {props.children}
    </TableRow>
  );
};

TableRowBuilder.propTypes = {
  data: propTypes.shape({}).isRequired,
  columns: propTypes.arrayOf(
    propTypes.shape({
      propertyName: propTypes.string.isRequired,
      className: propTypes.string,
      format: propTypes.func,
    })
  ).isRequired,
  children: propTypes.node,
};

TableRowBuilder.defaultProps = {
  children: null,
};

export default TableRowBuilder;
