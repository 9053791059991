import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { gtmAddToCart } from 'store/middleware/TagManager/gtmActions';
// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';
import getItemIfExists from './getItemIfExists';
import cartItemTransformer from '../../../../utils/transformers/cartItemTransformer';

import addingToCart from './addingToCart';
import addToCartFailure from './addToCartFailure';
import addToCartSuccess from './addToCartSuccess';
import updateCart from './updateCart';
import updatingCart from './updatingCart';

// TODO: this should be in a utils file
const constructCartItem = (itemData, totalVolume, cartId) => {
  const { variant } = itemData;
  return {
    variantId: variant.ars_key,
    unitOfMeasure: variant.unit_measure,
    unitPrice: variant.price,
    netPrice: variant.net_price,
    totalVolume: totalVolume || variant.selectedVolume,
    branchId: itemData.branchID,
    userId: itemData.userId,
    accountId: itemData.accountID,
    priceType: variant.price_type || undefined,
    cartId: cartId || undefined,
  };
};

export default (itemData, cartData, options, position) => (dispatch) => {
  const itemInCart = getItemIfExists(cartData, itemData.variant);
  dispatch(updatingCart(true));
  if (!isEmpty(itemInCart)) {
    // if the variant already exists in the cart, concat the volume and update the item
    const updatedItemVolume = (
      +itemInCart.total_volume + +itemData.variant.selectedVolume
    ).toString();
    const updatedItemData = constructCartItem(
      itemData,
      updatedItemVolume,
      itemInCart.id
    );
    const itemPreUpdate = cloneDeep(itemInCart);
    return dispatch(
      updateCart(updatedItemData, itemPreUpdate, options, position)
    );
  }
  const newItemData = constructCartItem(itemData);
  dispatch(addingToCart());
  const { accountID, branchID, userId } = itemData;

  return ecommApiService
    .put('v2/cart-items', newItemData, {
      headers: {
        'account-id': accountID,
        'branch-id': branchID,
        'user-id': userId,
      },
    })
    .then((res) => {
      const cartItem = cartItemTransformer(res.data);
      const cartItemFormattedForGTM = {
        product: cartItem.product,
        variant: cartItem.variant,
        position,
        variantSku: cartItem.variant.variant_sku,
        variantDescription: cartItem.variant.digital_variant_name,
        variantIsPriced: Boolean(cartItem.variant.net_price),
      };
      dispatch(gtmAddToCart(cartItemFormattedForGTM, get(options, 'origin')));

      dispatch(addToCartSuccess(cartItem));
      dispatch(updatingCart(false));
      return res;
    })
    .catch((error) => {
      dispatch(addToCartFailure(error));
      return error;
    });
};
