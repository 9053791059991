export default {
  countryOptionsContainer: {
    display: 'inline-flex',
    width: '100%',
    marginTop: '20px',
  },
  countryOption: {
    width: '350px',
    height: '115px',
    borderRadius: '4px',
    border: '1px solid #cccccc',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:first-of-type': {
      marginRight: '20px',
    },
  },
  countryIcon: {
    height: '25px',
    width: '41px',
  },
  description: {
    fontSize: '24px',
    margin: '0',
    color: '#222222',
  },
  countryText: {
    fontSize: '16px',
    marginLeft: '20px',
    color: '#222222',
  },
  selected: {
    border: '1px solid #4C9E00',
  },
  '@media (max-width: 480px)': {
    countryOptionsContainer: {
      flexWrap: 'wrap',
    },
    countryOption: {
      '&:first-of-type': {
        marginRight: '0',
        marginBottom: '20px',
      },
    },
  },
};
