import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CartHeaderIcon from '../CartHeaderIcon/CartHeaderIcon';

class CartHeaderIconWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
    };
  }

  componentDidMount() {
    this.setQuantity();
  }

  componentDidUpdate(prevProps) {
    const {
      selectedAccount,
      cart,
      setDefaultAccount,
      setGloballySelectedAccount,
      accounts,
      userDetails,
      getCart,
    } = this.props;

    if (isEmpty(selectedAccount) && accounts && accounts.list.length) {
      // If defaultAccountNumber exists in userDetails use this account
      if (userDetails.defaultAccountNumber) {
        // Loop through accounts to find correct index
        const { defaultAccountNumber } = userDetails;
        let accountIndex = null;
        accountIndex = accounts.list.findIndex(
          (account) => account.id.toString() === defaultAccountNumber
        );
        if (accountIndex >= 0) {
          const account = accounts.list[accountIndex];
          setDefaultAccount(account);
          setGloballySelectedAccount(account);
        } else {
          // eslint-disable-next-line no-console
          console.error('No default account set for this account!!!');
        }
      }
    }

    if (prevProps.selectedAccount.id !== selectedAccount.id) {
      getCart(selectedAccount.id);
    }

    if (prevProps.cart.cartData !== cart.cartData) {
      this.setQuantity();
    }
  }

  setQuantity() {
    const {
      cart: { cartData },
    } = this.props;

    const uniqueArray = cartData.filter(
      (cartItem, index) =>
        index ===
        cartData.findIndex((obj) => obj.variant.id === cartItem.variant.id)
    );

    this.setState({ quantity: uniqueArray.length });
  }

  render() {
    const { quantity } = this.state;
    return <CartHeaderIcon quantity={quantity} />;
  }
}

CartHeaderIconWrapper.propTypes = {
  accounts: PropTypes.shape({
    status: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        balance: PropTypes.number,
        due: PropTypes.number,
        pastDue: PropTypes.number,
        number: PropTypes.string,
      })
    ),
  }),
  setGloballySelectedAccount: PropTypes.func.isRequired,
  cart: PropTypes.shape({
    cartData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    status: PropTypes.string,
    itemsInCart: PropTypes.shape({
      quantity: PropTypes.number,
    }),
  }),
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    balance: PropTypes.number,
    due: PropTypes.number,
    pastDue: PropTypes.number,
    number: PropTypes.string,
    branchId: PropTypes.string,
  }),
  getCart: PropTypes.func.isRequired,
  setDefaultAccount: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    defaultAccountNumber: PropTypes.string,
  }),
};

CartHeaderIconWrapper.defaultProps = {
  accounts: {
    list: [],
  },
  cart: {
    data: [],
    itemsInCart: {
      quantity: 0,
    },
  },
  selectedAccount: {},
  userDetails: {},
};

export default CartHeaderIconWrapper;
