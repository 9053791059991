import { createSelector } from 'reselect';
import getUserData from './getUserData';

/**
 * Filter out only the preferences data from the entire userData object.
 */
const getUserPreferences = createSelector(getUserData, (userData) => ({
  firstName: userData.firstName,
  lastName: userData.lastName,
  emails: userData.emails,
  phoneNumber: userData.phoneNumber,
  phoneNumbers: userData.phoneNumbers,
  privacy: userData.privacy,
}));

export default getUserPreferences;
