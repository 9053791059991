import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NoPricePopper from './NoPricePopper';
import { gtmEcPricingPopover } from '../../../store/middleware/TagManager/gtmActions';

const mapStateToProps = (state) => ({
  state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      gtmEcPricingPopover,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NoPricePopper);
