import * as invoicesService from '../invoicesService';
import clearInvoiceData from './clearInvoiceData';
import addInvoiceData from './addInvoiceData';

export default (accountId, invoiceIds) => (dispatch) => {
  dispatch(clearInvoiceData());
  dispatch(addInvoiceData({ status: 'loading', list: [] }));
  return invoicesService.getInvoices(accountId, invoiceIds).then((response) => {
    const productList = [];
    response.forEach((invoice) => {
      invoice.lineItems.forEach((li) => {
        if (!productList.find((product) => product.sku === li.productNumber)) {
          productList.push({
            sku: li.productNumber,
            name: li.productDescription,
          });
        }
      });
    });
    dispatch(
      addInvoiceData({ status: 'done', list: productList, invoices: response })
    );
  });
};
