import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import { getLocalAccessToken } from '@nutrien/fe-domain-utils';

// shared UI
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';

// tools to check for validation
import { isEmployee, removeToken } from 'shared/utils/tokenUtils';

import LoginSwitchButton from './LoginSwitchButton';
import LoginV1 from './LoginV1';
import LoginV2 from './LoginV2';

import './Login.scss';

class Login extends Component {
  componentDidMount() {
    const { push } = this.props;

    // If a token exists in storage, the user is
    // authenticated and shouldn't go to the login page
    const accessToken = getLocalAccessToken();

    if (accessToken) {
      if (isEmployee()) {
        removeToken();
      } else {
        push('/');
      }
    } else {
      removeToken();
    }
  }

  loginComponent = () => {
    const status = get(this.props, 'user.status', '');
    const pathname = get(this.props, 'location.pathname', '');
    return pathname === '/login' ? (
      <LoginV1 fetchingUser={status} {...this.props} />
    ) : (
      <LoginV2 fetchingUser={status} {...this.props} />
    );
  };

  render() {
    const {
      browser: { isSupported },
      isEmployeeView,
      location,
      push,
    } = this.props;
    return (
      <div
        className={classNames({
          'login__container--browser-not-supported':
            !isSupported && !isEmployeeView,
        })}
      >
        <FullPageBackground
          image={bgRegistration}
          className="full-page-background--farmers-pointing"
        />
        {this.loginComponent()}
        <LoginSwitchButton push={push} location={location} type="mobile" />
      </div>
    );
  }
}

Login.propTypes = {
  user: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  fetchTerms: PropTypes.func.isRequired,
  terms: PropTypes.shape({
    status: PropTypes.string,
    termsData: PropTypes.shape({
      data: PropTypes.shape({
        document: PropTypes.string,
        version: PropTypes.string,
        content: PropTypes.string,
      }),
    }),
    error: PropTypes.shape({}),
  }).isRequired,
  push: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  browser: PropTypes.shape({
    isSupported: PropTypes.bool,
  }).isRequired,
  isEmployeeView: PropTypes.bool.isRequired,
};

export default Login;
