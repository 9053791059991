// NATO Country codes: https://en.wikipedia.org/wiki/List_of_NATO_country_codes
// GTM supported currency codes: https://support.google.com/analytics/answer/6205902?hl=en
const locationToCurrency = {
  USA: 'USD',
  CAN: 'CAD',
  BRA: 'BRL',
};

/**
 * Converts a locationCode (stored in accounts) to a Currency (USD, CAD, ...) usable by GTM
 * If country is unknown, uses `USD` as a fallback, and outputs a warning in the developer console
 * @param {Object} locationCode
 */
export const accountLocationCodeToCurrency = (locationCode) => {
  const currency = locationToCurrency[locationCode];
  if (!currency) {
    // eslint-disable-next-line no-console
    console.error(
      `Unknown locationCode : '${locationCode}', will enforce 'USD' instead`
    );
    return 'USD'; // the default in GTM
  }
  return currency;
};
