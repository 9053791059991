import { MixpanelProperties, MixpanelEvents } from '../mixPanelConstants';

export const viewAccount = ({ id, currentDue, balance }) => {
  return null;
  // window.mixpanel.track(MixpanelEvents.VIEW_ACCOUNTS, {
  //   [MixpanelProperties.ACCOUNT_ID]: id,
  //   [MixpanelProperties.CURRENT_DUE]: currentDue,
  //   [MixpanelProperties.CURRENT_BALANCE]: balance,
  // });
};
