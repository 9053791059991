import React from 'react';
import variantOptionName from 'components/Shop/utils/variantOptionName';
import { variantPropType } from 'components/Shop/components/ProductDetail/ProductDetailPropTypes';
import { useUserLocationContext } from '../../utils/contexts/locationCodeContext';
import { CAN } from '../../utils/contexts/locationCodeConstants';

import './SingleVariant.scss';

const SingleVariant = ({ variant }) => {
  const isCanada = useUserLocationContext() === CAN;

  return (
    <div className="single-variant__wrapper">
      <div className="single-variant__text-wrapper " data-test="variant-select">
        {variantOptionName(variant, isCanada)}
      </div>
    </div>
  );
};

SingleVariant.propTypes = {
  variant: variantPropType.isRequired,
};

export { SingleVariant };

export default SingleVariant;
