const styles = {
  container: {
    width: '100%',
    background: '#fff',
    boxShadow: '0 0 4px 0 rgba(0,0,0,.5)',
    padding: '.75em',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 99999,
    marginBottom: '2px',
  },
  message: {
    fontSize: '0.7em',
  },
  subheader: {
    margin: 0,
    lineHeight: '19px',
  },
  browserModalGetChrome: {
    marginBottom: '6px',
    marginRight: '21px',
    marginTop: '10px',
  },
  browserModalUpdateBrowser: {
    marginBottom: '6px',
    marginRight: '21px',
    marginTop: '10px',
  },
  buttonSubtitle: {
    fontSize: '.4em',
  },
  buttonWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: '270px',
    padding: '0 10px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },

  textContainer: {
    padding: '0 10px',
  },

  phone: {
    whiteSpace: 'nowrap',
  },

  '@media(max-width: 768px)': {
    message: {
      fontSize: '0.6em',
      marginBottom: '24px',
      lineHeight: '23px',
    },
    subheader: {
      marginBottom: '20px',
    },
  },
};

export default styles;
