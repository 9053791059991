import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';

import OrdersLanding from './OrdersLanding/OrdersLanding';
import IndividualOrder from './IndividualOrder/IndividualOrder';
import { WithFetchAccountsContainer } from '../../Shop/containers/containers';

const App = () => {
  const selectedAccount = useSelector((state) => state.selectedAccount);
  if (isEmpty(selectedAccount)) {
    return <MaterialLoadingIcon />;
  }

  return (
    <Switch>
      <Route exact path="/orders" component={OrdersLanding} />
      <Route path="/orders/:id" component={IndividualOrder} />
    </Switch>
  );
};

export default WithFetchAccountsContainer(App);
