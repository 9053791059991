export default (theme) => ({
  modalLink: {
    color: theme.palette.primary.main,
    display: 'inline',
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    padding: '0',
    '&:focus': {
      outline: 'none',
    },
  },
  tokenLimitMessage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '61px',
    backgroundColor: 'rgba(248, 79, 79, 0.1)',
    border: `solid 1px ${theme.palette.error.main}`,
    borderRadius: '4px',
    padding: '16px 61px 13px 17px',
    fontSize: '14px',
    marginBottom: '23px',
  },
  warningIcon: {
    fill: theme.palette.error.main,
    marginRight: '17px',
    height: 30,
    width: 30,
  },
});
