import { push } from 'connected-react-router';
import * as validationUtils from '../../PageComponents/MakeAPayment/Validations/PaymentValidator';
import {
  CLEAR_BANK_ACCOUNT_FORM,
  UPDATE_BANK_ACCOUNT_FORM,
  CLEAR_BANK_NAME,
  CLEAR_BANK_ACCOUNT,
  ROUTES,
  FETCH_BANK_NAME_SUCCESS,
  FETCH_BANK_NAME_FAIL,
  SET_PAYMENT_FORM_ERRORS,
  SET_ACCOUNT_COUNTRY_CODE,
  PAYMENT_FLOW_TYPES,
  SET_SAVED_PAYMENT_METHOD,
  DEFAULT_PAYMENT_METHODS,
  SET_PAYMENT_FLOW_TYPE,
  CLEAR_PAYMENT_FLOW_TYPE,
} from '../../PaymentConstants';
import * as paymentService from '../../PaymentService';
import { setBankAccountToken } from './BankAccountActions';
import {
  buildRegisterTokenRequest,
  buildSaveTokenRequest,
} from '../../PaymentRequestFactory';
import {
  checkIfValidPayment,
  loadingDataInProgress,
  loadingDataSuccess,
  loadingDataFailure,
  navigateToReviewPage,
} from './SharedActions';
import {
  clearPaymentError,
  clearShowFormErrorsNotification,
  handlePaymentFailure,
  setShowFormErrorsNotification,
} from './ErrorActions';
import { PAYMENT_AMOUNT_TYPES } from '../../PageComponents/MakeAPayment/MakeAPaymentConstants';
import { getOptionalFormFields } from '../../Utils/FormUtils';

// Form fields that should never be validated
const ALWAYS_OPTIONAL_FIELDS = [
  'notes',
  'addressLine2',
  'paymentMethodAlias',
  'savePaymentMethod',
  'selectedInvoices',
  'discountedAmount',
  'scheduledPayment',
  'isRecurring',
  'amountTypeSelected',
];

export const setPaymentFlowType = (flowType) => ({
  type: SET_PAYMENT_FLOW_TYPE,
  payload: flowType,
});

export const clearPaymentFlowType = () => ({
  type: CLEAR_PAYMENT_FLOW_TYPE,
});

export const updateBankAccountForm = (formData) => ({
  type: UPDATE_BANK_ACCOUNT_FORM,
  payload: formData,
});

export const clearBankAccountForm = (isRecurring) => ({
  type: CLEAR_BANK_ACCOUNT_FORM,
  payload: isRecurring,
});

export const setSavedPaymentMethod = (paymentMethod) => ({
  type: SET_SAVED_PAYMENT_METHOD,
  payload: paymentMethod,
});

export const setBankAccountFormErrors = (errors) => ({
  type: SET_PAYMENT_FORM_ERRORS,
  payload: errors,
});

export const clearBankAccount = () => ({
  type: CLEAR_BANK_ACCOUNT,
});

export const clearBankName = () => (dispatch) =>
  dispatch({ type: CLEAR_BANK_NAME });

export const fetchBankNameFailure = () => (dispatch) =>
  dispatch({ type: FETCH_BANK_NAME_FAIL });

export const fetchBankNameSuccessful = (payload) => (dispatch) =>
  dispatch({ type: FETCH_BANK_NAME_SUCCESS, payload });

export const setAccountCountryCode = (accountCountryCode) => ({
  type: SET_ACCOUNT_COUNTRY_CODE,
  payload: accountCountryCode,
});

export const bankFromRoutingNumber = (routingNumber) => async (dispatch) => {
  try {
    const response = await paymentService.getBankName(routingNumber);

    if (response.data.code === 200) {
      dispatch(fetchBankNameSuccessful(response.data.name));
    } else {
      dispatch(fetchBankNameFailure());
    }
  } catch (error) {
    dispatch(fetchBankNameFailure());
  }
};

/**
 * Handles all the steps needed to move from the /make-a-secure-payment page to the review page for ACH payments
 * @param form - An object containing the ACH form data
 * @param flowType - The type of payment flow the user is in
 */
export const submitMakeASecurePaymentAction =
  ({ form }) =>
  async (dispatch, getState) => {
    const state = getState();
    const registerTokenRequest = buildRegisterTokenRequest(state, form);
    const { accountCountryCode } =
      state.payments.bankAccountFormReducer || form.cpsAccount.locationCode;

    try {
      dispatch(loadingDataInProgress());

      const selectedInvoices = state.invoices.selectedInvoices.unpaid;
      await dispatch(checkIfValidPayment({ form }, selectedInvoices));
      const registerTokenResponse = await paymentService.registerBankAccount(
        registerTokenRequest
      );

      if (registerTokenResponse.message) {
        dispatch(loadingDataSuccess());
        dispatch(setBankAccountToken(registerTokenResponse.litleToken));
        await dispatch(navigateToReviewPage(accountCountryCode));
      } else {
        dispatch(loadingDataFailure());
      }
    } catch (error) {
      dispatch(loadingDataFailure());
      dispatch(handlePaymentFailure(error));
    }
  };

/**
 * Handles all the steps needed to move from the /make-a-secure-payment page to the review page for ACH payments
 * made with a saved bank account
 * @param form - An object containing the payment form data
 * @param flowType - The type of payment flow the user is in
 */
export const submitMakeASecurePaymentWithTokenAction =
  ({ form }) =>
  async (dispatch, getState) => {
    try {
      dispatch(loadingDataInProgress());

      const { savedPaymentMethod } = form;
      const state = getState();
      const selectedInvoices = state.invoices.selectedInvoices.unpaid;
      await dispatch(checkIfValidPayment({ form }, selectedInvoices));

      if (
        savedPaymentMethod.tokenValue !==
        DEFAULT_PAYMENT_METHODS.PREPAY.tokenValue
      ) {
        dispatch(setBankAccountToken(savedPaymentMethod.tokenValue));
      }

      dispatch(setSavedPaymentMethod(savedPaymentMethod));
      dispatch(loadingDataSuccess());

      await dispatch(navigateToReviewPage(form.cpsAccount.locationCode));
    } catch (error) {
      dispatch(loadingDataFailure());
      dispatch(handlePaymentFailure(error));
    }
  };

/**
 * Submits the add bank account form and handles registering and saving the ACH account
 * @param form
 */
export const submitAddBankAccountAction =
  ({ form }) =>
  async (dispatch, getState) => {
    const state = getState();
    const registerTokenRequest = buildRegisterTokenRequest(state, form);

    dispatch(loadingDataInProgress());

    try {
      const registerTokenResponse = await paymentService.registerBankAccount(
        registerTokenRequest
      );

      if (registerTokenResponse.message) {
        dispatch(setBankAccountToken(registerTokenResponse.litleToken));

        const saveTokenRequest = buildSaveTokenRequest(getState());

        await paymentService.saveBankAccount(saveTokenRequest);

        dispatch(loadingDataSuccess());
        dispatch(clearBankAccountForm());
        dispatch(push('/profile/billing'));
      } else {
        dispatch(loadingDataFailure());
      }
    } catch (error) {
      dispatch(loadingDataFailure());
      dispatch(handlePaymentFailure(error));
    }
  };

/**
 * Validates the ACH payment form and calls the correct action to submit the form data and retrieve
 * the data needed for the review page.
 * @param route - The route that the function was called from (can be either make-a-secure-payment or add-bank-account)
 * @param flowType - A String that represents what type of payment flow were in
 */
export const submitACHPaymentFormAction =
  (route, flowType) => (dispatch, getState) => {
    const state = getState();
    const { form, formWarnings, formErrors, accountCountryCode } =
      state.payments.bankAccountFormReducer;

    dispatch(clearPaymentError());
    dispatch(clearShowFormErrorsNotification());

    const currentState = {
      form,
      formErrors,
      formWarnings,
    };

    if (flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT) {
      currentState.form = {
        ...form,
        prepayType: { name: 'Regular' },
        amountTypeSelected: PAYMENT_AMOUNT_TYPES.Other,
      };
    }

    const dynamicallyOptionalFields = getOptionalFormFields(
      currentState.form,
      accountCountryCode
    );
    const optionalFields = ALWAYS_OPTIONAL_FIELDS.concat(
      dynamicallyOptionalFields
    );
    const newState = validationUtils.validateEntireForm({
      currentState,
      optionalFields,
    });

    const canSubmit =
      !validationUtils.hasFormErrors(newState.formErrors, optionalFields) &&
      !validationUtils.hasEmptyFormFields(newState.form, optionalFields);

    if (canSubmit) {
      const newForm = newState.form;
      const path = route.pathname.split('/').pop();

      if (path === ROUTES.ADD_BANK_ACCOUNT) {
        dispatch(submitAddBankAccountAction({ form: newForm }));
      } else if (
        (path === ROUTES.MAKE_A_PAYMENT || path === ROUTES.PRE_PAY_PAYMENT) &&
        newForm.savedPaymentMethod.tokenValue !==
          DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT.name
      ) {
        dispatch(submitMakeASecurePaymentWithTokenAction({ form: newForm }));
      } else {
        dispatch(submitMakeASecurePaymentAction({ form: newForm }));
      }
    } else {
      dispatch(setShowFormErrorsNotification());
      dispatch(setBankAccountFormErrors(newState.formErrors));
    }
  };
