import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import OrdersTable from '../OrdersTable';
import MaterialLoadingIcon from '../../../../shared/ui/RebrandedMaterialLoadingIconInline/MaterialLoadingIcon';

import useStyles from './styles';

const OrdersTab = (props) => {
  const classes = useStyles();
  const {
    documents: { ordersLoading, orders },
  } = props;
  let section;

  const renderLoading = () => {
    const section = (
      <div className={classes.loadingWrapper} data-test="order-tab-loading">
        <MaterialLoadingIcon />
      </div>
    );
    return section;
  };

  const renderError = () => {
    const section = (
      <div data-test="order-tab-error">
        <ApiErrorMessage entityName="Orders" />
      </div>
    );
    return section;
  };

  const renderTable = (tableData) => {
    return <OrdersTable tableData={tableData} />;
  };

  if (ordersLoading) {
    section = renderLoading();
  } else if (orders.data) {
    section = renderTable(orders.data);
  } else {
    section = renderError();
  }

  return (
    <>
      <div className={classes.header}>
        <span className={classes.tabLabel}>Orders</span>
      </div>
      {section}
    </>
  );
};

OrdersTab.propTypes = {
  documents: PropTypes.shape({
    ordersLoading: PropTypes.bool,
    error: PropTypes.shape({}),
    orders: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
};

export default OrdersTab;
