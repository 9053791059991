import React from 'react';
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';
import LinkAccountComponent from './LinkAccountComponent';

export const LinkAccountPage = () => (
  <div>
    <FullPageBackground
      image={bgRegistration}
      className="full-page-background--farmers-pointing"
    />
    <LinkAccountComponent />
  </div>
);

export default LinkAccountPage;
