// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';
import addressTransformer from '../../../../utils/transformers/addressTransformer';

import getBranchAddressFailure from './getBranchAddressFailure';
import getBranchAddressSuccess from './getBranchAddressSuccess';
import updatingBranchAddress from './updatingBranchAddress';

export default (defaultAccountId, branchId) => (dispatch) => {
  dispatch(updatingBranchAddress(true));
  return ecommApiService
    .get(`/v2/branches/${branchId}`, {
      headers: {
        'account-id': defaultAccountId,
      },
    })
    .then((res) => {
      dispatch(updatingBranchAddress(false));
      const branch = addressTransformer(res.data);
      dispatch(getBranchAddressSuccess(branch));
      return res;
    })
    .catch((error) => {
      dispatch(updatingBranchAddress(false));
      dispatch(getBranchAddressFailure(error));
      return error;
    });
};
