import React, { Component } from 'react';
import { shape, string, func, bool, arrayOf } from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import Search from '@nutrien/uet-react/icons/Search';
import AccountCircle from '@nutrien/uet-react/icons/AccountCircle';
import ExpandMore from '@nutrien/uet-react/icons/ExpandMore';
import UserPropType from 'shared/config/userPropType';
import CartHeaderIconContainer from 'components/Cart/containers/CartHeaderIconContainer/CartHeaderIconContainer';
import withUser from 'components/User/withUser';
import EmployeeMirroringHeader from 'components/Employee/Mirroring/EmployeeMirroringHeader';
import {
  HeaderTitle,
  HeaderContentWrapper,
  HeaderSearchComponent,
} from 'shared/ui/HeaderSubComponents';
import BaseHeader from 'shared/ui/Header/Headers/BaseHeader';
import MobileHeader from 'shared/ui/Header/Headers/MobileHeader';
import { HeaderBrowserSupport } from 'shared/ui/Header/withBrowser';
import { getUrlParameters } from 'components/Shop/utils/filterHelpers';
import 'shared/ui/Header/Header.scss';
import './AuthenticatedHeader.scss';
import NotificationDesktopMenuItem from './NotificationDesktopMenuItem';
import AccountMenu from './AccountMenu';
import AccountSelector from '../../../HeaderSubComponents/HeaderAccountSelector/HeaderAccountSelector';

class AuthenticatedHeader extends Component {
  constructor() {
    super();
    this.state = {
      autofillExpanded: false,
      isAccountMenuOpen: false,
      toolsMenuOpen: false,
      searchExpanded: false,
      searchQuery: '',
      pageWidth: window.innerWidth,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.closeMenus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  closeMenus = () => {
    const { closeMenu } = this.props;
    closeMenu();
    this.setState({
      isAccountMenuOpen: false,
      toolsMenuOpen: false,
    });
  };

  searchQueryWasCleared = (prevProps) => {
    const { location } = this.props;
    if (
      location.search &&
      prevProps.location.search &&
      location.search !== prevProps.location.search
    ) {
      if (
        location.search.indexOf('?name') === -1 &&
        prevProps.location.search.indexOf('?name') !== -1
      ) {
        return true;
      }
    }

    return false;
  };

  toggleAccountMenu = (event) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      isAccountMenuOpen: !prevState.isAccountMenuOpen,
    }));
  };

  dismissAccountMenu = () => this.setState({ isAccountMenuOpen: false });

  toggleToolsMenu = () => {
    this.setState((prevState) => ({
      toolsMenuOpen: !prevState.toolsMenuOpen,
    }));
  };

  handleSearchInput = (input) => {
    this.setState({ searchQuery: input });
  };

  handleExpandSearchField = () => {
    const { searchQuery } = this.state;

    if (!searchQuery) {
      this.setState(({ searchExpanded }) => ({
        searchExpanded: !searchExpanded,
      }));
    } else {
      this.handleRouteUpdate(searchQuery);
    }
  };

  handleRouteUpdate = (searchQuery) => {
    const { location, history } = this.props;
    const search = location.search.substring(1);
    const searchParameters = getUrlParameters(search);

    searchParameters.name = searchQuery;

    // logic to properly add or remove relevance sort if search is active
    if (searchParameters.name) {
      if (!searchParameters.sort || searchParameters.sort !== '-_score') {
        searchParameters.sort = '-_score';
      }
    } else if (searchParameters.sort === '-_score') {
      searchParameters.sort = null;
    }

    const searchParameterKeys = Object.keys(searchParameters);

    const queryParams = searchParameterKeys
      .filter((key) => searchParameters[key])
      .map((key) => {
        const value = searchParameters[key];
        return `${key}=${value}`;
      });

    history.push(`/products?${queryParams.join('&')}`);
  };

  handleLogout = (isMirroring) => {
    const { history, logout, closeMenu } = this.props;
    if (isMirroring) {
      return history.push('/employee');
    }
    logout();
    closeMenu();
    return this.setState({
      isAccountMenuOpen: false,
      toolsMenuOpen: false,
      searchExpanded: false,
    });
  };

  updateSearchQuery = (valueText, callback) => {
    this.setState(
      {
        searchQuery: valueText,
        autofillExpanded: !!valueText,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  updateAutofillExpand = (option, callback) => {
    this.setState({ autofillExpanded: option }, callback);
  };

  showProductsTabIfAccountExists = () => {
    const {
      user: { userDetails },
      flags: { globalEcommerce, westernRegionRestrictProducts, usaSapProducts },
    } = this.props;
    return userDetails &&
      userDetails.defaultAccountNumber &&
      globalEcommerce &&
      !westernRegionRestrictProducts &&
      !usaSapProducts ? (
      <>
        <NavLink
          activeClassName="selected"
          className="menu__item"
          to="/products"
          data-test="header-product-link"
        >
          Products
        </NavLink>
      </>
    ) : (
      ''
    );
  };

  updateDimensions() {
    this.setState({
      pageWidth: window.innerWidth,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const queryString = query.get('name');
    if (queryString) {
      this.setState({
        searchExpanded: true,
        searchQuery: queryString,
      });
    }
  }

  render() {
    const {
      browser,
      flags: {
        globalSearch,
        globalEcommerce,
        usaSapCart,
        westernRegionRestrictProducts,
        usaSapRestrictFarmInsights,
        usaSapProducts,
      },
      gtmClickNutrienLogoAction,
      history,
      isEmployeeView,
      isMirroring,
      location,
      user,
      accounts,
      setProductFilters,
      search,
      isMenuOpen,
      toggleMenu,
      selectedAccount,
    } = this.props;
    const {
      pageWidth,
      autofillExpanded,
      searchQuery,
      searchExpanded,
      isAccountMenuOpen,
    } = this.state;
    const mobileBreakpoint = 1280;

    const searchComponent = (
      <HeaderSearchComponent
        autofillExpanded={autofillExpanded}
        autofillResults={search.autofillResults}
        data-test="header-search-component"
        globalSearch={globalSearch}
        handleSearchInput={this.handleSearchInput}
        searchSubmit={(searchRequest) => {
          this.handleRouteUpdate(searchRequest);
        }}
        query={searchQuery}
        searchExpanded={searchExpanded}
        selectSingleActiveIngredient={(type, values) => {
          setProductFilters(type, values);
          history.push(`/products?activeIngredient=${values[0]}`);
        }}
        selectSingleProduct={(id) => history.push(`/products/${id}`)}
        updateAutofillExpand={this.updateAutofillExpand}
        updateSearchQuery={this.updateSearchQuery}
        accounts={accounts}
      />
    );

    return (
      <>
        {isMirroring && <EmployeeMirroringHeader />}
        <HeaderBrowserSupport
          browser={browser}
          isEmployeeView={isEmployeeView}
        />
        <HeaderContentWrapper>
          {pageWidth >= mobileBreakpoint ? (
            <BaseHeader
              isMirroring={isMirroring}
              location={location}
              withLogo={false}
            >
              <div className="header-left">
                <div className="header-logo--flagged">
                  <NavLink
                    activeClassName="selected"
                    to="/"
                    data-test="header-nutrien-logo-link"
                    onClick={() => gtmClickNutrienLogoAction()}
                  >
                    <HeaderTitle titleText="CPS" path={location.pathname} />
                  </NavLink>
                </div>
                <AccountSelector history={history} location={location} />
                <div className="nav-tab__bar">
                  <NavLink
                    activeClassName="selected"
                    className="menu__item"
                    exact
                    key="/"
                    strict
                    to="/"
                  >
                    <span>Overview</span>
                  </NavLink>
                  {this.showProductsTabIfAccountExists()}
                  {accounts.length && !usaSapRestrictFarmInsights ? (
                    <NavLink
                      className="menu__item"
                      to="?showHubModal=true&modalType=insights"
                    >
                      <span>Farm Insights</span>
                    </NavLink>
                  ) : null}
                </div>
              </div>
              <div className="header-right">
                <div className="header__links--desktop">
                  {accounts.length &&
                  !westernRegionRestrictProducts &&
                  !usaSapProducts ? (
                    <div
                      className={`header__search--desktop ${
                        searchExpanded
                          ? 'header__search--desktop-expanded'
                          : null
                      }`}
                      data-test="header-search-container"
                    >
                      <>
                        {searchComponent}
                        <div
                          className={classNames('search-icon', {
                            show: searchExpanded,
                            hide: !searchExpanded,
                          })}
                        >
                          <Search
                            className="header-search__icon-desktop"
                            data-test="header-search__icon-desktop"
                            onClick={this.handleExpandSearchField}
                          />
                        </div>
                      </>
                    </div>
                  ) : null}
                  <div className="icon-nav-section-container">
                    <span className="header-vertical-line" />
                    {accounts.length &&
                    globalEcommerce &&
                    !westernRegionRestrictProducts &&
                    (!usaSapCart || !selectedAccount.usaSapAccount) ? (
                      <NavLink
                        activeClassName="selected"
                        className="menu__item mobile-header__icon-link"
                        data-test="cart-link"
                        to={{
                          pathname: '/cart',
                          state: location.pathname.includes('/products')
                            ? { fromProductListPage: true }
                            : null,
                        }}
                      >
                        <CartHeaderIconContainer location={location} />
                      </NavLink>
                    ) : null}
                    <NotificationDesktopMenuItem />
                    <div
                      className="header-user-container--desktop account-menu-toggle"
                      data-test="accounts-menu-id"
                      onClick={this.toggleAccountMenu}
                      onKeyPress={this.toggleAccountMenu}
                      role="button"
                      tabIndex="0"
                    >
                      <AccountCircle
                        className="profile-icon"
                        fontSize="large"
                      />
                      <ExpandMore />
                    </div>
                  </div>
                </div>
              </div>
            </BaseHeader>
          ) : (
            <MobileHeader
              accounts={accounts}
              handleLogout={this.handleLogout}
              history={history}
              isMenuOpen={isMenuOpen}
              location={location}
              state={this.state}
              toggleMenu={toggleMenu}
              toggleToolsMenu={this.toggleToolsMenu}
              user={user}
              gtmClickNutrienLogoAction={gtmClickNutrienLogoAction}
              isMirroring={isMirroring}
            />
          )}
        </HeaderContentWrapper>
        <AccountMenu
          history={history}
          isMirroring={isMirroring}
          dismissAccountMenu={this.dismissAccountMenu}
          handleLogout={this.handleLogout}
          isAccountMenuOpen={isAccountMenuOpen}
          user={user}
        />

        {usaSapProducts || westernRegionRestrictProducts
          ? null
          : searchComponent}
      </>
    );
  }
}

AuthenticatedHeader.propTypes = {
  accounts: arrayOf(shape({})),
  browser: shape({
    isSupported: bool,
  }).isRequired,
  closeMenu: func.isRequired,
  flags: shape({
    globalSearch: bool,
    universalHeader: bool,
  }).isRequired,
  gtmClickNutrienLogoAction: func.isRequired,
  history: shape({}).isRequired,
  isEmployeeView: bool.isRequired,
  isMenuOpen: bool,
  isMirroring: bool,
  location: shape({
    pathname: string,
    search: string,
  }).isRequired,
  logout: func.isRequired,
  search: shape({
    autofillResults: shape({
      filters: shape({}),
      items: arrayOf(shape({})),
    }),
    query: string,
  }),
  setProductFilters: func.isRequired,
  toggleMenu: func.isRequired,
  user: UserPropType.isRequired,
  selectedAccount: shape({
    usaSapAccount: bool,
  }),
};

AuthenticatedHeader.defaultProps = {
  accounts: [],
  isMenuOpen: false,
  isMirroring: false,
  search: {
    query: '',
  },
  selectedAccount: {
    usaSapAccount: false,
  },
};

export default withUser(AuthenticatedHeader);
