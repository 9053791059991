import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import TopProductsPropType from '../TopProductsPropType';
import TopProductsTableRow from './TopProductsTableRow';

import './TopProductsTable.scss';

const TopProductsTable = (props) => {
  const { globalEcommerce } = useFlags();
  return (
    <table className="top-products__table">
      <tbody>
        <tr className="top-products__table-columns">
          <td className="top-products__table-mobile-display-none">Product #</td>
          <td className="top-products__table-sm-mobile-display-none">Name</td>
          <td className="top-products__table-sm-mobile-display-none">
            SDS & Labels
          </td>
          <td className="top-products__table-sm-mobile-display-none">
            Quantity
          </td>
          <td className="top-products__table-sm-mobile-display-none">Cost</td>
          <td className="top-products__table-sm-mobile-display-none">Date</td>
          {globalEcommerce && (
            <td className="top-products__button-column top-products__table-sm-mobile-display-none" />
          )}
        </tr>
      </tbody>
      <tbody className="top-products__table-content">
        <TopProductsTableRow {...props} />
      </tbody>
    </table>
  );
};

TopProductsTable.propTypes = {
  topProducts: TopProductsPropType.isRequired,
  gtmViewTopProductSDS: PropTypes.func,
};

TopProductsTable.defaultProps = {
  gtmViewTopProductSDS: () => {},
};

export default TopProductsTable;
