import MINIMUM_BULK_QUANTITY from 'shared/constants/minimumBulkQuantity';
import checkInputWithDecimal from '../../Cart/utils/CheckInputWithDecimals/checkInputWithDecimal';
import checkInputInteger from '../../Cart/utils/CheckInputInteger/checkInputInteger';

export const validMinimumQuantity = (min, numToCheck) =>
  parseInt(numToCheck, 10) >= parseInt(min, 10);

export const isValidBulkInput = (name, volume) =>
  name === 'Bulk' ? validMinimumQuantity(MINIMUM_BULK_QUANTITY, volume) : true;

export const isValidPackageInput = (volume) => validMinimumQuantity(1, volume);

export const isValidTotalVolumeInput = (isCanada, value) =>
  isCanada === true
    ? checkInputInteger(value, 1000000000)
    : checkInputWithDecimal(value, 1000000000);
