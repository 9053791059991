export default (invoices, grossAmountRange = []) => {
  if (grossAmountRange.length === 0) return invoices;

  const min = grossAmountRange[0] || -Number.MAX_SAFE_INTEGER;
  const max = grossAmountRange[1] || Number.MAX_SAFE_INTEGER;

  return invoices
    .filter((invoice) => invoice.grossInvoiceAmount >= min)
    .filter((invoice) => invoice.grossInvoiceAmount <= max);
};
