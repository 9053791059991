import { connect } from 'react-redux';
import PaymentsTab from './PaymentsTab';
import {
  GetScheduledPayments,
  DeleteScheduledPayment,
  clearDeleteStatus,
  GetRecurringPayments,
} from './Actions';
import { STATUS } from './Actions/constants';
import { getScheduledPayments } from '../../Payments/Redux/PaymentSelectors';

const mapStateToProps = (state) => ({
  loadingStatus:
    state.paymentsTab.GetScheduledPayments.status === STATUS.FETCHING ||
    state.paymentsTab.GetScheduledPayments.status === STATUS.INITIAL ||
    state.paymentsTab.DeleteScheduledPayment.status === STATUS.DELETING,
  scheduledPayments: getScheduledPayments(state),
  accountId: state.selectedAccount.id,
  deletedPaymentInfo: state.paymentsTab.DeleteScheduledPayment,
  recurringPayments: state.paymentsTab.GetRecurringPayments.data,
  isCanada: state.accounts.list.some((acct) => acct.locationCode === 'CAN'),
});

const mapDispatchToProps = {
  getScheduledPayments: GetScheduledPayments,
  deleteScheduledPayment: DeleteScheduledPayment,
  clearDeleteStatus,
  getRecurringPayments: GetRecurringPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTab);
