import { connect } from 'react-redux';
import isEmployeeView from 'utils/isEmployeeView';
import BannerImages from './BannerImages';

const mapStateToProps = (state) => ({
  userDetails: state.user.userData.userDetails,
  browser: state.browser,
  isEmployeeView: isEmployeeView(state),
});

export default connect(mapStateToProps)(BannerImages);
