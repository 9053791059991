import { arrowLinkStyles, arrowLinkContainerStyles } from 'shared/styles';

const styles = {
  contentContainer: {
    margin: '0 8px',
  },
  p: {
    fontSize: 13,
    fontStyle: 'normal',
    lineHeight: '15px',
    color: '#828282',
    margin: '15px 0 45px',
  },
  h4: {
    margin: '15px 0px 0px',
  },
  arrowLinkContainer: {
    ...arrowLinkContainerStyles,
  },
  arrowLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ...arrowLinkStyles,
    '&hover': {
      ...arrowLinkStyles,
    },
    '&link': {
      ...arrowLinkStyles,
    },
    '&active': {
      ...arrowLinkStyles,
    },
    '&visited': {
      ...arrowLinkStyles,
    },
    '& > img': {
      width: '1.5em',
      marginTop: 1,
    },
  },
};

export default styles;
