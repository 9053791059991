export const typePolicies = {
  Query: {
    fields: {
      getBankTokensByOrgIds: {
        keyArgs: ['tokenId'],
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  TokenAccount: {
    keyFields: ['tokenId'],
  },
};
