import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from 'shared/ui';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';

import styles from './styles';

const ConfirmationModal = ({ classes, closeModal, isOpen, navigate }) => (
  <Dialog open={isOpen} className="confirmation-modal">
    <DialogContent>
      <div className={classes.iconContainer}>
        <EmailIcon className={classes.icon} />
        <PhoneIcon className={classes.icon} />
      </div>
      <DialogContentText className={classes.contentText}>
        Would you like to receive an email or a text message when your
        statements become available?
      </DialogContentText>
    </DialogContent>

    <DialogActions className={classes.buttonContainer}>
      <MaterialButton
        className={classNames(classes.button, classes.skipButton)}
        type="secondary"
        value="Skip For Now"
        onClick={(e) => {
          e.preventDefault();
          closeModal();
        }}
      />

      <MaterialButton
        className={classNames(classes.button, classes.reviewButton)}
        type="primary"
        value="Review Preferences"
        onClick={(e) => {
          e.preventDefault();
          navigate();
        }}
      />
    </DialogActions>
  </Dialog>
);

ConfirmationModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmationModal);
