import get from 'lodash/get';
import { START_CREDIT_APPLICATION } from '../../../../const/credit';
import { mixpanelStartCreditApplication } from '../../MixPanel/eventHandlers/startCreditApplication';
import { EVENT_TRACK, CXH_ONLINECREDIT_APPLICATION } from '../gtmConstants';

export const onlineCreditApplicationStartAnalytics = ({
  payload: { selectedAccount, pathName, redirectUrl },
}) => {
  mixpanelStartCreditApplication(selectedAccount, pathName, redirectUrl);
  return {
    event: EVENT_TRACK,
    eventId: CXH_ONLINECREDIT_APPLICATION,
    category: 'Online Credit Application',
    action: 'Initiate',
    branchId: get(selectedAccount, 'branchId', ''),
    branchName: get(selectedAccount, 'branchName', ''),
    accountName: get(selectedAccount, 'name', ''),
    accountId: get(selectedAccount, 'id', ''),
  };
};

export const creditApplicationEventsMap = {
  [START_CREDIT_APPLICATION]: onlineCreditApplicationStartAnalytics,
};
