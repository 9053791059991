export default (theme) => ({
  addPaymentForm: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: '20px 25px 0 25px',
  },
  addPaymentFormContainer: {
    width: '100%',
  },
  addPaymentFormLabel: {
    color: theme.palette.text.primary,
    display: 'block',
    width: '401px',
    height: '19px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    textAlign: 'left',
    marginBottom: '5px',
    '& span': {
      marginLeft: '5px',
    },
  },
  addPaymentFormInput: {
    width: '401px',
    height: '39px',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #cccccc',
    padding: '5px',
    marginBottom: '20px',
  },
  addPaymentFormHR: {
    display: 'block',
    position: 'relative',
    padding: '0',
    margin: '15px 0 20px 0',
    height: '0',
    width: '100%',
    maxHeight: '0',
    fontSize: '1px',
    lineHeight: '0',
    clear: 'both',
    borderBottom: '1px solid #ffffff',
  },
  accountInfoHelpLink: {
    color: theme.palette.primary.main,
    float: 'right',
    position: 'relative',
    '&:hover': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  },
  accountInfoBlock: {
    display: 'block',
  },
  addPaymentFormLabelLarge: {
    color: '#004538',
    fontSize: '24px',
  },
  cpsAccountDropdownComponent: {
    marginTop: '24px',
  },
  addPaymentFormLabelSmall: {
    color: '#828282',
    fontSize: '12px',
  },
  submitButton: {
    fontSize: '14px',
    width: '181px',
    margin: '0',
    '@media (max-width: 825px)': {
      width: '100%',
      marginBottom: '20px',
      order: 0,
    },
  },
  error: {
    borderColor: [theme.palette.error.main, '!important'],
    marginBottom: '10px',
  },
  errorMessages: {
    paddingBottom: '10px',
    '& p': {
      color: theme.palette.error.main,
      margin: '0',
    },
  },
  warningMessages: {
    padding: '10px 0 10px 0',
    width: '250px',
    position: 'absolute',
    '& p': {
      margin: '0',
      'white-space': 'normal',
    },
  },
  '@media (max-width: 480px)': {
    addPaymentFormLabel: {
      width: '100%',
    },
    addPaymentFormInput: {
      width: '100%',
    },
  },
});
