import React, { Component } from 'react';
import PropTypes from 'prop-types';
import USFlag from 'assets/USFlag.png';
import CANFlag from 'assets/CANflag.png';
import PaymentMethodRow from './PaymentMethodRow/PaymentMethodRow';
import * as PaymentPropTypes from '../../../../../Payments/PaymentPropTypes';

import './paymentMethodRows.css';

class PaymentMethodRows extends Component {
  renderPaymentMethods = (paymentMethods) => {
    const { updatePaymentById, deletePayment } = this.props;

    return paymentMethods.map((paymentMethod) => (
      <PaymentMethodRow
        key={paymentMethod.tokenValue}
        paymentMethod={paymentMethod}
        updatePayment={(updatedPayment) =>
          updatePaymentById(paymentMethod.tokenAccountId, updatedPayment.alias)
        }
        deletePayment={() => deletePayment(paymentMethod.tokenId)}
      />
    ));
  };

  renderPaymentMethodsByCountry = (paymentMethods) => {
    const { usAccounts, canAccounts } = paymentMethods;

    return (
      <div>
        {usAccounts && usAccounts.length !== 0 ? (
          <div>
            <div className="country-label country-header">
              United States Bank Accounts
              <img className="image-icon" src={USFlag} alt="US" />
            </div>

            {this.renderPaymentMethods(usAccounts)}
          </div>
        ) : null}

        {canAccounts && canAccounts.length !== 0 ? (
          <div>
            <div className="country-label country-header">
              Canadian Bank Accounts
              <img className="image-icon" src={CANFlag} alt="CAN" />
            </div>

            {this.renderPaymentMethods(canAccounts)}
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { paymentAccounts, hasMultipleCountries } = this.props;

    if (!paymentAccounts) {
      return null;
    }

    return (
      <ul className="payment-method-rows">
        {hasMultipleCountries
          ? this.renderPaymentMethodsByCountry(paymentAccounts)
          : this.renderPaymentMethods(paymentAccounts)}
      </ul>
    );
  }
}

PaymentMethodRows.propTypes = {
  paymentAccounts: PropTypes.oneOfType([
    PaymentPropTypes.paymentAccounts,
    PropTypes.shape({
      usAccounts: PaymentPropTypes.paymentAccounts,
      canAccounts: PaymentPropTypes.paymentAccounts,
    }),
  ]), // eslint-disable-line react/no-typos
  updatePaymentById: PropTypes.func.isRequired,
  deletePayment: PropTypes.func.isRequired,
  hasMultipleCountries: PropTypes.bool.isRequired,
};

PaymentMethodRows.defaultProps = {
  paymentAccounts: [],
};

export default PaymentMethodRows;
