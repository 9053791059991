import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import AcceptTerms from './AcceptTerms';
import { fetchTerms, updateTermsAcceptance } from '../termsActions';
import TermsPropTypes from '../termsPropTypes';
import { logout } from '../../Authentication/actions/authenticationActions';

const AcceptTermsContainer = ({
  flags: { maintenanceNotification },
  logout,
  push,
  terms,
  updateTermsAcceptance,
}) => (
  <AcceptTerms
    terms={terms}
    push={push}
    maintenanceNotification={maintenanceNotification}
    updateTermsAcceptance={updateTermsAcceptance}
    logout={logout}
  />
);

AcceptTermsContainer.propTypes = {
  terms: TermsPropTypes.isRequired,
  updateTermsAcceptance: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  flags: PropTypes.objectOf(PropTypes.bool),
  logout: PropTypes.func.isRequired,
};

AcceptTermsContainer.defaultProps = {
  flags: { maintenanceNotification: false },
};

const mapStateToProps = (state) => ({
  terms: state.terms,
});

const mapDispatchToProps = {
  updateTermsAcceptance,
  fetchTerms,
  push,
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptTermsContainer);
