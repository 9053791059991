import { errorStyling } from 'shared/styles';

const styles = {
  error: {
    ...errorStyling,
  },
  p: {
    margin: '0px',
  },
  '@media (min-width: 720px)': {
    p: {
      margin: '0px 10px',
    },
  },
};

export default styles;
