import PropTypes from 'prop-types';

export const permit = PropTypes.shape({
  growerName: PropTypes.string,
  permitActive: PropTypes.bool,
  expirationDate: PropTypes.string,
  permitId: PropTypes.string,
  permitee: PropTypes.string,
  issuedBy: PropTypes.string,
  permittedLocation: PropTypes.string,
  federallyRestricted: PropTypes.bool,
  permittedCrops: PropTypes.arrayOf(
    PropTypes.shape({
      cropId: PropTypes.string,
      cropName: PropTypes.string,
    })
  ),
  chemicalNames: PropTypes.arrayOf(
    PropTypes.shape({
      chemicalId: PropTypes.string,
      chemicalName: PropTypes.string,
    })
  ),
});

export const permitArray = PropTypes.arrayOf(permit);
