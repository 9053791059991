import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const MarketsButton = () => {
  const { landingPageMarketsButton } = useFlags();
  return (
    <>
      {landingPageMarketsButton && (
        <Link to="/markets">
          <Button variant="outlined" data-test="markets-button">
            Markets
          </Button>
        </Link>
      )}
    </>
  );
};

export default MarketsButton;
