import PropTypes from 'prop-types';

export const UserDetailsPropType = PropTypes.shape({
  topCropNames: PropTypes.arrayOf(PropTypes.string),
  termsAcceptance: PropTypes.string,
  showOrderConditions: PropTypes.bool,
  associatedOrgs: PropTypes.arrayOf(PropTypes.shape({})),
});

const UserPropType = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  userDetails: UserDetailsPropType,
  emails: PropTypes.arrayOf(PropTypes.shape({})),
  phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  privacy: PropTypes.arrayOf(PropTypes.shape({})),
});

export default UserPropType;
