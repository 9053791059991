// the logic centered around currentDateRange comes from the old filtering UX experience.
// It should eventually be absorbed into the filters reducer,
// and even later will probably be removed entirely,
// for now the logic was taken practically verbatim from InvoicesWrapper where it used to live
// (formerly the inline function "filterInvoicesByDate")
export default (invoices, currentDateRange = {}) => {
  const { startDate, endDate } = currentDateRange;

  if (!startDate && !endDate) {
    return invoices;
  }
  return invoices.filter((invoice) => {
    const dateTime = Number(new Date(invoice.date));
    if (dateTime >= startDate && dateTime <= endDate) {
      return true;
    }
    return false;
  });
};
