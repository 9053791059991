import {
  GTM_ACCOUNT_OVERVIEW_INTERACTION,
  GTM_ACCOUNT_OVERVIEW_FILTER_CHANGE,
} from '../gtmConstants';

export const accountOverviewInteraction = (action) => ({
  event: 'CXH_AccountOverviewInteraction',
  cxh: {
    account: {
      overview: {
        ...action.payload,
      },
    },
  },
});

export const accountOverviewFilterChange = (action) => {
  let userFriendlyLabel;
  switch (action.payload.filterName) {
    case 'typeFilter':
      userFriendlyLabel = 'Filter Dropdown';
      break;
    case 'periodFilter':
      userFriendlyLabel = 'Period Dropdown';
      break;
    default:
      userFriendlyLabel = action.payload.filterName;
  }

  return {
    event: 'CXH_AccountOverviewFilterChange',
    cxh: {
      account: {
        overview: {
          appliedFilter: {
            ...action.payload,
            filterName: userFriendlyLabel,
          },
        },
      },
    },
  };
};

export const accountOverviewEventsMap = {
  [GTM_ACCOUNT_OVERVIEW_INTERACTION]: accountOverviewInteraction,
  [GTM_ACCOUNT_OVERVIEW_FILTER_CHANGE]: accountOverviewFilterChange,
};
