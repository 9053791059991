import {
  DOWNLOAD_INVOICES_IN_PROGRESS,
  DOWNLOAD_INVOICES_SUCCESS,
  DOWNLOAD_INVOICES_FAILURE,
} from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case DOWNLOAD_INVOICES_IN_PROGRESS:
    case DOWNLOAD_INVOICES_SUCCESS:
    case DOWNLOAD_INVOICES_FAILURE:
      return action.type;
    default:
      return state;
  }
};
