import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';

import bg from 'assets/emp_landing_img_shrunk.jpg';

const styles = (theme) => ({
  root: {
    backgroundImage: `url("${bg}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    backgroundSize: 'cover',
    minWidht: 300,
    minHeight: 610,
    left: 0,
    right: 0,
    top: '3.5em',
    zIndex: '-1',
    position: 'absolute',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      background:
        'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.15) 100%)',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 500,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '150%',
      minHeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '175%',
      minHeight: 300,
    },
  },
});

// JSX
const EmployeeBackgroundImage = ({ classes }) => (
  <div className={`${classes.root} ebi-Container`} />
);

EmployeeBackgroundImage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

// Export
export default withStyles(styles)(EmployeeBackgroundImage);
