import {
  ACCOUNT_LINK_SUCCESS,
  VIEW_ACCOUNT,
} from 'components/LinkAccount/accountLinkConstants';
import { viewAccount } from 'store/middleware/MixPanel/eventHandlers/accounts';
import { EVENT_TRACK, CXH_VIEW_ACCOUNT } from '../gtmConstants';

export const linkAccount = () => ({
  event: 'CXH_LinkAccount',
  cxh: {},
});

export const viewAccountSummary = (
  _,
  { selectedAccount: { id, currentDue, balance } }
) => {
  viewAccount({ id, currentDue, balance });
  return null;
  // return {
  //   event: EVENT_TRACK,
  //   eventid: CXH_VIEW_ACCOUNT,
  //   'account id': id,
  //   'current balance': balance,
  //   'currently due': currentDue,
  // };
};

export const accountsEventsMap = {
  [ACCOUNT_LINK_SUCCESS]: linkAccount,
  [VIEW_ACCOUNT]: viewAccountSummary,
};
