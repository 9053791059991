import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import allNotifications from './allNotifications';

export default createSelector(allNotifications, (notifications) =>
  sortBy(notifications, [
    (notification) => new Date(notification.createdAt),
  ]).reverse()
);
