import React, { Fragment } from 'react';
import { Divider, List } from '@material-ui/core';

import * as EmployeePropTypes from '../../EmployeePropTypes';
import CustomerListItem from './CustomerListItem';

const CustomerList = ({ customers }) => (
  <>
    <Divider />
    <List dense disablePadding>
      {customers.map((customer) => (
        <CustomerListItem
          customer={customer}
          key={customer.id || customer.eid}
        />
      ))}
    </List>
  </>
);

CustomerList.defaultProps = {
  customers: [],
};

CustomerList.propTypes = {
  customers: EmployeePropTypes.customers,
};

// Export
export default CustomerList;
