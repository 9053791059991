import React from 'react';
import { bool, shape } from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import classNames from 'classnames';
import sky from 'assets/landing-page-backgrounds/sky.jpg';
import skySmall from 'assets/landing-page-backgrounds/sky-small.jpg';
import { heroPropTypes } from 'shared/ui/Hero/Hero';
import Hero from 'shared/ui/Hero';
import MarketsButton from './MarketsButton';
import WeatherButton from './WeatherButton';
import WeatherStoryButton from './WeatherStoryButton';
import QuickPayButton from './QuickPayButton';

const getDefaultImage = () => {
  if (window.innerWidth <= 480) {
    return skySmall;
  }
  return sky;
};

// TODO: Why is this outside of the appContainer? It messes with all of the styles for top margin throughout the rest of the app because the appContainer has a top margin to accomodate this component
const BannerImage = ({
  heroProps,
  browserSupportFeatureIsOn,
  browser,
  isEmployeeView,
  topNotification,
}) => (
  <div
    className={classNames('banner-images__container', {
      'banner-images__container__unsupported-browser':
        browserSupportFeatureIsOn && !browser.isSupported && !isEmployeeView,
    })}
    style={topNotification ? { top: '10.5em' } : { top: '3.5em' }}
  >
    <div className="outer-banner-image-wrapper">
      <Hero {...heroProps} data-test="hero" />
      <Grid container justify="flex-end" spacing={2} component={Box} py={2}>
        <Grid item>
          <WeatherStoryButton />
        </Grid>
        <Grid item className="weather-button">
          <WeatherButton />
        </Grid>
        <Grid item>
          <MarketsButton />
        </Grid>
        <Grid item>
          <QuickPayButton />
        </Grid>
      </Grid>
    </div>
    <img className="banner-images__image" src={getDefaultImage()} alt="" />
  </div>
);

BannerImage.propTypes = {
  ...heroPropTypes,
  browserSupportFeatureIsOn: bool.isRequired,
  browser: shape({
    isSupported: bool,
  }).isRequired,
  isEmployeeView: bool.isRequired,
  topNotification: bool,
};
BannerImage.defaultProps = {
  topNotification: false,
};

export default BannerImage;
