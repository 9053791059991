import { Avatar, withStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Description from '@material-ui/icons/Description';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import LocalShipping from '@material-ui/icons/LocalShipping';
import LockOutlined from '@material-ui/icons/LockOutlined';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Leaf, Warning, Weather } from './NotificationsCustomIcons';

import { ALERT_NOTIFICATION_TYPES } from '../actions';
import { notificationType as PropTypeNotificationType } from '../notificationsPropTypes';

const style = (theme) => ({
  base: {
    color: '#fff',
    backgroundColor: '#A6A5A6',
  },
  alert: {
    backgroundColor: '#d0021b',
    '& svg': {
      fontSize: '1.5em',
    },
  },
  hidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

export const iconsMap = {
  NEW_INVOICE: Description,
  NEW_STATEMENT: Description,
  NEW_WEATHER_STORY: Weather,
  PAYMENT_DUE: MonetizationOn,
  PAYMENT_PAST_DUE: Warning,
  PAYMENT_OVERDUE: Warning,
  PAYMENT_RECIEVED: MonetizationOn,
  PAYMENT_FAILED: MonetizationOn,
  PAYMENT_APPLIED: MonetizationOn,
  PAYMENT_SUCCESSFUL: MonetizationOn,
  DELIVERY_COMPLETE: LocalShipping,
  APPLICATION_COMPLETE: Description,
  NA: Leaf,
  PASSWORD_CHANGED: LockOutlined,
};

export const NotificationsTypeIcon = ({
  type,
  className,
  classes,
  ...rest
}) => {
  const { notificationsMultiSelect } = useFlags();
  const Icon = iconsMap[type] || iconsMap.NA;
  return (
    <Avatar
      className={classNames(
        'notification-type-icon',
        className,
        classes.base,
        {
          [classes.hidden]: notificationsMultiSelect,
        },
        {
          [classes.alert]: ALERT_NOTIFICATION_TYPES.includes(type),
        }
      )}
      {...rest}
    >
      <Icon />
    </Avatar>
  );
};

NotificationsTypeIcon.defaultProps = {
  classes: {},
  className: '',
};

NotificationsTypeIcon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  type: PropTypeNotificationType.isRequired, // eslint-disable-line react/no-typos
};

export default withStyles(style)(NotificationsTypeIcon);
