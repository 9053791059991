import { LOGOUT } from 'components/Authentication/actions/authenticationConstants';
import { getGtmContainer } from '../utils';

export const clearDataLayer = () => {
  const container = getGtmContainer();
  if (!container) {
    return;
  }
  container.dataLayer.reset();
};

export const logoutEventsMap = {
  [LOGOUT]: clearDataLayer,
};
