export const stripSymbolsFromNumber = (number) =>
  number ? number.replace(/\D*/g, '') : number;

export const truncateNumber = (number) => {
  let finalNumber = number;

  if (number.length > 10) {
    finalNumber = number.substr(0, 10);
  }

  return finalNumber;
};

export const formatPhoneNumber = (number, extraSpace = false) => {
  let sanitizedNumber = '';
  if (number && number.toString().trim() !== '') {
    sanitizedNumber = number.toString().replace(/\W/g, ''); // remove all non-alphanumeric characters, e.g. ( ) - .
  }
  let formattedNumber = sanitizedNumber || '';

  if (sanitizedNumber && sanitizedNumber.length >= 10) {
    const numberWithoutCountryCode =
      sanitizedNumber.length === 11
        ? sanitizedNumber.substr(1)
        : sanitizedNumber;
    const areaCode = numberWithoutCountryCode.substr(0, 3);
    const middleThreeDigits = numberWithoutCountryCode.substr(3, 3);
    const lastFourDigits = numberWithoutCountryCode.substr(6, 4);

    formattedNumber = `(${areaCode})${
      extraSpace ? ' ' : ''
    }${middleThreeDigits}-${lastFourDigits}`;
  }

  return formattedNumber;
};

/**
 * Determines if a number is valid according to the format (###)###-####
 *
 * @param {string} number the value to be validated
 *
 * @returns {{isValid: boolean, error: {null|Error}}}
 * an object containing:
 *     a boolean that represents if the phone number is valid,
 *     and an Error object that will specify the error, or else will be null
 */
export const validatePhoneNumber = (number) => {
  let isValid = true;
  let error = null;

  if (!number) {
    error = new Error('phone number is required'); // eslint
    isValid = false;
  } else if (number.length !== 13 || !number.match(/\(\d{3}\)\d{3}-\d{4}/)) {
    error = new Error('invalid phone number');
    isValid = false;
  }
  return {
    isValid,
    error,
  };
};
