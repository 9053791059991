const ordersTransformer = (orderData) => {
  if (orderData && orderData.data) {
    return {
      data: {
        attributes: {
          ...orderData.data,
          accountId: orderData.data.accountNumber,
          lineItems: orderData.data.products.map((product) => ({
            attributes: {
              ...product,
            },
          })),
        },
      },
    };
  }
  return {};
};

export default ordersTransformer;
