/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Icon } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import systemMaintenance from 'assets/system-maintenance.svg';
import { selectSelectedAccount } from 'selectors/accountSelectors';
import useStyles from './styles';

const MaintenanceNotification = () => {
  const selectedAccount = useSelector(selectSelectedAccount());
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const { maintenanceNotification } = useFlags();
  const { maintenanceActive, message, closable } = maintenanceNotification;

  if (!maintenanceActive) {
    return null;
  }

  // Some messages require a contact link pointing to the crop consultant's email
  let formattedMessage = message;
  if (message.indexOf('/%email_consultant%/contact/%email_consultant%/') > -1) {
    formattedMessage = message.split(
      '/%email_consultant%/contact/%email_consultant%/'
    );
    const contact = selectedAccount.cropConsultant ? (
      <a
        href={`mailto:${selectedAccount.cropConsultant.workEmailAddress}`}
        className={classes.link}
        data-test="crop-consultant-email"
      >
        contact
      </a>
    ) : (
      'contact'
    );
    formattedMessage = (
      <>
        <span>{formattedMessage[0]}</span>
        {contact}
        <span>{formattedMessage[1]}</span>
      </>
    );
  }

  return (
    isOpen && (
      <div className={classes.root} data-test="maintenance-notification">
        <img
          className={classes.icon}
          src={systemMaintenance}
          alt="system maintenance"
        />
        <span
          className={classes.message}
          data-test="maintenance-notification-message"
        >
          {formattedMessage}
        </span>
        {closable && (
          <IconButton
            data-test="maintenance-notification-close"
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.iconButton}
            onClick={() => setIsOpen(false)}
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      </div>
    )
  );
};

export default MaintenanceNotification;
