import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Card from '@nutrien/uet-react/Card';
import Typography from '@nutrien/uet-react/Typography';
import Table from '@nutrien/uet-react/Table';
import TableCell from '@nutrien/uet-react/TableCell';
import TableRow from '@nutrien/uet-react/TableRow';
import TableBody from '@nutrien/uet-react/TableBody';
import isEmpty from 'lodash/isEmpty';
import CircularProgress from '@nutrien/uet-react/CircularProgress';
import { selectApprovedCreditApplication, selectCreditStatus } from 'selectors';
import { getCreditApplications } from '../../../actions/creditActions';
import useStyles from './styles';
import formatDate from '../../../shared/utils/DateHelper/formatMMDDYYWithSlashes';

const ApprovedCreditApp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { approvedApplicationId } = useParams();

  const approvedCreditApplication = useSelector(
    selectApprovedCreditApplication(approvedApplicationId)
  );

  const history = useHistory();
  const fetchStatus = useSelector(selectCreditStatus());

  useEffect(() => {
    if (!approvedCreditApplication) {
      dispatch(getCreditApplications());
    }
  }, [dispatch, approvedApplicationId, approvedCreditApplication]);

  useEffect(() => {
    if (
      (!approvedCreditApplication && fetchStatus === 'SUCCESS') ||
      fetchStatus === 'ERROR'
    ) {
      history.replace('/');
    }
  }, [history, approvedCreditApplication, fetchStatus]);

  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const supportNumber = () =>
    isMobile ? (
      <a href="tel:18334930660">1-833-493-0660</a>
    ) : (
      <a href="tel:18334930660" data-test="support-phone-link">
        1-833-493-0660
      </a>
    );

  if (!approvedCreditApplication || isEmpty(approvedCreditApplication)) {
    return <CircularProgress aria-label="loading" center="x" />;
  }

  const { createdAt, displayProperties = {} } = approvedCreditApplication;

  const {
    applicantName,
    salesPersonName,
    branchName,
    creditLimit = 0,
    accountNumbers,
  } = displayProperties;

  return (
    <div data-test="approved-credit-app">
      <Typography variant="h1" className={classes.approvedCreditTypography}>
        Credit Application Status
      </Typography>
      <Typography variant="h4" className={classes.approvedCreditTypography}>
        You&apos;re Approved!
      </Typography>
      <Typography
        variant="subtitle2"
        className={classes.approvedCreditTypography}
      >
        Your credit application has been conditionally approved pending
        verification, and your account is set up and ready to go. You can now
        order product from your local branch or pay invoices in your customer
        portal account.
      </Typography>
      <Card className={classes.approvedCreditMaterialCard}>
        <Table>
          <TableBody className={classes.approvedCreditTableBody}>
            <TableRow>
              <TableCell>Approved as of</TableCell>
              <TableCell>{formatDate(createdAt)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Applicant Name</TableCell>
              <TableCell>{applicantName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Number</TableCell>
              <TableCell>{accountNumbers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Aggregate Limit</TableCell>
              <TableCell>
                {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(creditLimit)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Crop Consultant</TableCell>
              <TableCell>{salesPersonName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Branch</TableCell>
              <TableCell>{branchName}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <Typography
        variant="subtitle2"
        className={classes.approvedCreditTypography}
      >
        If you have any questions, please don&apos;t hesitate to contact Nutrien
        Financial&apos;s Credit Shared Service Center at&nbsp;
        {supportNumber()}
        &nbsp;or your local Nutrien Ag Solutions branch.
      </Typography>
      <Typography variant="subtitle2">
        <b>Disclaimer:</b>
        &nbsp; To learn more about your payment terms and available special
        finance programs, contact your local branch.
      </Typography>
    </div>
  );
};

export default ApprovedCreditApp;
