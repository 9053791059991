import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import ReviewPageARS from '../../components/ReviewPage/ReviewPageARS';
import ReviewPageSAP from '../../components/ReviewPage/ReviewPageSAP';
import { verifySAPAccount } from '../../../../utils/verifySAP';
import * as cartActions from '../../actions/cartActions';

class ReviewPage extends PureComponent {
  render() {
    const selectedAccountNumber = get(this.props, 'selectedAccount.number', '');

    if (!selectedAccountNumber?.length) {
      return <MaterialLoadingIcon />;
    }

    const isSAP = verifySAPAccount(selectedAccountNumber);

    return isSAP ? (
      <ReviewPageSAP {...this.props} />
    ) : (
      <ReviewPageARS {...this.props} />
    );
  }
}

const mapStateToProps = ({ cart, selectedAccount, user }) => ({
  cartItems: cart,
  selectedAccount,
  user: user.userData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...cartActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPage);
