import { Button, TextField, withStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  textField: {
    marginRight: theme.spacing.unit,
  },
});

export const CustomerLookup = ({ classes, className, onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e.target.lookupInput.value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(classes.root, className, 'customer-lookup')}
    >
      <TextField
        name="lookupInput"
        className={classNames(classes.textField, 'customer-lookup__input')}
        placeholder="Account Number"
        autoFocus
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        label="Lookup Customers"
      />
      <Button type="submit" className="customer-lookup__submit">
        Lookup
      </Button>
    </form>
  );
};

CustomerLookup.defaultProps = { className: undefined };

CustomerLookup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(CustomerLookup);
