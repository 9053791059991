import { combineReducers } from 'redux';
import GetScheduledPayments from './GetScheduledPayments';
import GetRecurringPayments from './GetRecurringPayments';
import DeleteScheduledPayment from './DeleteScheduledPayment';

export default combineReducers({
  GetScheduledPayments,
  DeleteScheduledPayment,
  GetRecurringPayments,
});
