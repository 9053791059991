import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import USFlag from 'assets/USFlag.png';
import CANFlag from 'assets/CANflag.png';
import { COUNTRY_CODES } from '../../PaymentConstants';

import styles from './styles';
import { getCountryFromCode } from '../../Utils/PaymentUtil';

class CountryPickerComponent extends Component {
  selectCountry = (countryCode) => {
    const { setCountry, updatePaymentForm } = this.props;
    const country = getCountryFromCode(countryCode);

    setCountry(countryCode);

    updatePaymentForm({
      country,
    });
  };

  render() {
    const { classes, accountCountryCode } = this.props;
    const message =
      'Are you adding a United States or Canadian based bank account?';

    return (
      <div>
        <p className={classes.description}>{message}</p>

        <div
          className={classes.countryOptionsContainer}
          id="country-picker-container"
        >
          <div
            className={
              accountCountryCode === COUNTRY_CODES.USA
                ? `${classes.countryOption} ${classes.selected}`
                : classes.countryOption
            }
            onClick={() => this.selectCountry(COUNTRY_CODES.USA)}
            onKeyDown={(e) =>
              e.keyCode === 'ENTER'
                ? this.selectCountry(COUNTRY_CODES.USA)
                : null
            }
            role="Button"
          >
            <img
              className={classes.countryIcon}
              src={USFlag}
              alt="us-flag-icon"
            />
            <p className={classes.countryText}>United States Bank Account</p>
          </div>
          <div
            className={
              accountCountryCode === COUNTRY_CODES.CANADA
                ? `${classes.countryOption} ${classes.selected}`
                : classes.countryOption
            }
            onClick={() => this.selectCountry(COUNTRY_CODES.CANADA)}
            onKeyDown={(e) =>
              e.keyCode === 'ENTER'
                ? this.selectCountry(COUNTRY_CODES.USA)
                : null
            }
            role="Button"
          >
            <img
              className={classes.countryIcon}
              src={CANFlag}
              alt="can-flag-icon"
            />
            <p className={classes.countryText}>Canadian Bank Account</p>
          </div>
        </div>
      </div>
    );
  }
}

CountryPickerComponent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  setCountry: PropTypes.func.isRequired,
  accountCountryCode: PropTypes.string,
  updatePaymentForm: PropTypes.func.isRequired,
};

CountryPickerComponent.defaultProps = {
  accountCountryCode: '',
};

export default withStyles(styles)(CountryPickerComponent);
