import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import {
  AuthenticatedRoute,
  LegacyAuthRoute,
} from 'shared/utils/AuthenticatedComponents';
import IntercomFeedbackWrapper from 'shared/ui/Feedback';
import Header from 'shared/ui/Header';
import Footer from 'shared/ui/Footer';
import Landing from 'components/Landing';
import Contact from 'components/Contact';
import Profile from 'components/Profile';
import Partners from 'components/Partners';
import Account from 'components/Account';
import Accounts from 'components/Accounts';
import Invoice from 'components/Invoice';
import PrePayAddFunds from 'components/Account/PrePayAddFunds';
import Feedback from 'components/Feedback';
import Market from 'components/Market';
import Login from 'components/Login';
import LoggedOut from 'components/LoggedOut';
import Authorize from 'components/Authorize';
import SSO from 'components/SSO';
import Auth0NativeLanding from 'components/Auth0NativeLanding';
import PasswordReset from 'components/PasswordReset';
import ForgotPassword from 'components/ForgotPassword';
import DoNotSell from 'components/DoNotSell';
import Registration from 'components/Registration';
import LinkAccountPage from 'components/LinkAccount';
import MakeAPayment from 'components/Payments/PageComponents/MakeAPayment';
import ConfirmPayment from 'components/Payments/PageComponents/ConfirmPayment';
import ReviewPayment from 'components/Payments/PageComponents/ReviewPayment';
import AddBankAccountComponent from 'components/Payments/PageComponents/AddBankAccount';
import Notifications from 'components/Notifications';
import Employee from 'components/Employee';
import Page404 from 'components/Page404';
import MaintenancePage from 'components/MaintenancePage';
import Products from 'components/Shop';
import {
  CaliforniaDisclosurePage,
  PrivacyPage,
} from 'components/LegalDocuments';
import Terms from 'components/Terms';
import RoleAuthorization from 'components/Roles/RoleAuthorization';
import RegisterRoleDelegation from 'components/Roles/RegisterRoleDelegation';
import ProductHistory from 'components/ProductHistory';
import ProductLabels from 'components/ProductLabels';
import EmailVerifiedPopup from 'components/EmailVerifiedPopup';
import Cart from '../Cart/components/Cart/Cart';
import EmployeeHeader from '../Employee/EmployeeHeader';
import FlaggedRoute from '../FlaggedRoute';
import Orders from '../Orders';
import Licenses from '../Licenses';
import Permits from '../Permits';
import Documents from '../Documents';
import Contracts from '../Contracts';
import ApprovedCreditApp from '../CreditApplication/ApprovedCreditApp';
import CreditApplicationRedirect from '../CreditApplication/CreditApplicationRedirect';
import SsoLogout from '../SsoLogout';

import './App.scss';

const ExternalRoutes = ({ match }) => {
  return (
    <div className="app-external-router-container">
      <Switch>
        <Route path={match.url} exact component={EmailVerifiedPopup} />
      </Switch>
    </div>
  );
};
const InternalRoutes = ({ match }) => {
  return (
    <div className="app-router-container">
      <Switch>
        <Route path="/employee" component={EmployeeHeader} />
        <Route component={Header} />
      </Switch>
      <div className="page-container">
        <div className="content-container">
          <Switch>
            <AuthenticatedRoute exact path="/" component={Landing} />
            <FlaggedRoute
              flagKey="markets-route"
              path="/markets"
              component={Market}
            />
            <FlaggedRoute
              authenticated={false}
              flagKey="feedback-page"
              path="/feedback"
              component={Feedback}
            />
            <Route path="/site-maintenance" component={MaintenancePage} />
            <AuthenticatedRoute
              exact
              path="/profile"
              component={() => <Redirect to="/profile/overview" />}
            />
            <AuthenticatedRoute
              exact
              path="/profile/:tabValue"
              component={Profile}
            />
            <AuthenticatedRoute
              exact
              path="/accounts/link"
              component={LinkAccountPage}
            />
            <FlaggedRoute
              exact
              flagKey="accounts-page"
              path="/accounts"
              component={Accounts}
            />
            <AuthenticatedRoute
              exact
              path="/accounts/product-history"
              component={ProductHistory}
            />
            <AuthenticatedRoute
              exact
              path="/accounts/:id"
              component={({ match }) => (
                <Redirect to={`/accounts/${match.params.id}/overview`} />
              )}
            />
            <AuthenticatedRoute
              exact
              path="/accounts/:accountId/invoices/:invoiceId"
              component={Invoice}
            />
            <AuthenticatedRoute
              exact
              path="/accounts/:accountId/add-funds-to-prepay"
              component={PrePayAddFunds}
            />
            <AuthenticatedRoute
              exact
              path="/accounts/:id/:tabValue"
              component={Account}
            />
            <FlaggedRoute
              flagKey="global-ecommerce"
              authenticated
              path="/orders"
              component={Orders}
            />
            <FlaggedRoute
              flagKey="ecomm-contracts"
              authenticated
              exact
              path="/documents/:tabValue(contracts|orders)?"
              component={Documents}
            />
            <AuthenticatedRoute
              exact
              path="/documents/contract"
              component={Contracts}
            />
            <AuthenticatedRoute
              path="/products/:labelId/labels"
              component={ProductLabels}
            />
            <Redirect exact from="/gettingstarted" to="/getting-started" />
            <LegacyAuthRoute
              exact
              path="/getting-started"
              component={Registration}
            />
            <LegacyAuthRoute
              exact
              path="/update/:email/:eid"
              component={Registration}
            />
            <LegacyAuthRoute
              path="/register"
              component={Registration}
              authenticatedRedirect="/profile/overview"
            />
            <LegacyAuthRoute
              path="/confirm/:identity"
              component={Registration}
            />
            <LegacyAuthRoute
              path="/confirm/:identity/:accessCode"
              component={Registration}
            />
            <LegacyAuthRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <FlaggedRoute
              exact
              authenticated
              flagKey="payments"
              path="/make-a-secure-payment"
              component={MakeAPayment}
            />
            <FlaggedRoute
              exact
              authenticated
              flagKey="payments"
              path="/review-payment"
              component={ReviewPayment}
            />
            <FlaggedRoute
              exact
              authenticated
              flagKey="payments"
              path="/confirm-payment"
              component={ConfirmPayment}
            />
            <FlaggedRoute
              exact
              authenticated
              flagKey="payments"
              path="/add-bank-account"
              component={AddBankAccountComponent}
            />
            <AuthenticatedRoute
              path="/notifications"
              component={Notifications}
            />
            <AuthenticatedRoute path="/licenses" component={Licenses} />
            <FlaggedRoute
              flagKey="permits-page"
              path="/permits"
              component={Permits}
            />
            <Route path="/employee" component={Employee} />
            <Route exact path="/sso" component={SSO} />
            <Route
              exact
              path="/auth0-native-landing"
              component={Auth0NativeLanding}
            />
            <Route exact path="/sso-logout" component={SsoLogout} />
            <LegacyAuthRoute exact path="/login" component={Login} />
            <Route path="/logged-out" component={LoggedOut} />
            <LegacyAuthRoute exact path="/sign-in" component={Login} />
            <Route exact path="/authorize" component={Authorize} />
            <Route
              exact
              path="/password-reset/:email/:verificationCode"
              component={PasswordReset}
            />
            <Route exact path="/partners" component={Partners} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/terms-of-use" component={Terms} />
            <Route exact path="/privacy" component={PrivacyPage} />
            <Route
              exact
              path="/california-disclosure"
              component={CaliforniaDisclosurePage}
            />
            <AuthenticatedRoute path="/do-not-sell" component={DoNotSell} />
            {/* Nutrien Financial Redirects */}
            <AuthenticatedRoute
              path="/oca"
              component={CreditApplicationRedirect}
            />
            <FlaggedRoute
              path="/apply-now"
              authenticated
              flagKey="credit-app-apply-now-link"
              component={CreditApplicationRedirect}
            />
            <FlaggedRoute
              path="/credit-application/:approvedApplicationId"
              flagKey="credit-app"
              component={ApprovedCreditApp}
            />
            <AuthenticatedRoute exact path="/accept-terms" component={Terms} />
            <FlaggedRoute
              authenticated={false}
              exact
              flagKey="role-authorization"
              path="/roles/authorization/:confirmationID"
              component={RoleAuthorization}
            />
            <FlaggedRoute
              authenticated={false}
              exact
              flagKey="role-authorization"
              path="/create-account"
              component={RegisterRoleDelegation}
            />
            <FlaggedRoute
              flagKey="global-ecommerce"
              path="/products"
              component={Products}
            />
            <FlaggedRoute
              flagKey="global-ecommerce"
              path="/cart"
              component={Cart}
            />
            <Route component={Page404} />
          </Switch>
          <IntercomFeedbackWrapper />
        </div>
        <Footer />
      </div>
    </div>
  );
};

const AppRouter = () => {
  return (
    <Switch>
      <Route path="/verified" component={ExternalRoutes} />
      <Route path="/" component={InternalRoutes} />
    </Switch>
  );
};

export default AppRouter;
