import * as constants from '../constants';
import { mixPanelAddBranchInfo } from '../../../../store/middleware/MixPanel/eventHandlers/userLogin';

const { SET_GLOBALLY_SELECTED_ACCOUNT } = constants;

export const selectedAccount = (state = {}, action) => {
  switch (action.type) {
    case SET_GLOBALLY_SELECTED_ACCOUNT: {
      const { branchId, branchName } = action.payload;
      mixPanelAddBranchInfo({ branchId, branchName });
      return action.payload;
    }
    default:
      return state;
  }
};
