import { ADD_INVOICE_DATA, CLEAR_INVOICE_DATA } from '../actions';

// TODO: this is actually initialized to look something like:
// { list: [], invoices: [], status: string }
// and list should be renamed to productsList
// and each of those should be separated out into their own reducers and combined here
export default (state = [], action) => {
  switch (action.type) {
    case ADD_INVOICE_DATA:
      return action.payload;
    case CLEAR_INVOICE_DATA:
      return [];
    default:
      return state;
  }
};
