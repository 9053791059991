/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable no-underscore-dangle */

/**
 * This HoC is a noop for non-dev environments.
 * It subscribes to LD's flags and shallow overrides them with
 * what's stored in `localStorage`. It allows us to easily toggle
 * flags from console for testing, without having to go to LD Dashboard.
 * Try in console, `_ldOverride.set('flagName', 'value')`
 */

import React from 'react';
import T from 'prop-types';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import { Provider } from 'launchdarkly-react-client-sdk/lib/context';

import resolveApiEnv, { DEV, SIT } from 'shared/config/resolveApiEnv';

function overrideHoc(WrappedComponent) {
  const env = resolveApiEnv();
  if (env !== DEV && env !== SIT) {
    return WrappedComponent;
  }

  class LDOverride extends React.Component {
    static propTypes = {
      flags: T.object.isRequired,
      ldClient: T.object,
    };

    static defaultProps = {
      ldClient: null,
    };

    constructor(props) {
      super(props);
      this.state = {
        overrideFlags:
          JSON.parse(localStorage.getItem('cxh-flag-override')) || {},
      };
    }

    componentDidMount() {
      window._ldOverride = {
        set: (flag, value) => {
          const { overrideFlags } = this.state;
          this.setState(
            { overrideFlags: { ...overrideFlags, [flag]: value } },
            this.persist
          );
        },
        reset: () => {
          this.setState({ overrideFlags: {} }, this.persist);
        },
        get: (flag) => {
          const { flags } = this.props;
          const { overrideFlags } = this.state;
          const effectiveFlags = { ...flags, ...overrideFlags };

          if (flag) {
            console.log(`${flag}: ${effectiveFlags[flag]}`);
          } else {
            console.group('LD Flags');
            console.table(effectiveFlags);
            console.groupEnd();
          }
        },
      };
    }

    componentWillUnmount() {
      window._ldOverride = null;
    }

    persist() {
      const { overrideFlags } = this.state;

      localStorage.setItem('cxh-flag-override', JSON.stringify(overrideFlags));
    }

    render() {
      const { overrideFlags } = this.state;
      const { flags, ldClient } = this.props;
      const effectiveFlags = { ...flags, ...overrideFlags };

      return (
        <Provider value={{ flags: effectiveFlags, ldClient }}>
          <WrappedComponent {...this.props} />
        </Provider>
      );
    }
  }

  return withLDConsumer()(LDOverride);
}

export default overrideHoc;
