import { combineReducers } from 'redux';
import {
  LOADING_REGISTRATION,
  RESET_REGISTRATION,
  REGISTER_EXISTING_SUCCESS,
  REGISTER_EXISTING_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  PREREGISTER_SUCCESS,
  PREREGISTER_FAILURE,
  PREREGISTER_REDIRECT,
  PREREGISTER_START,
} from './registrationConstants';

export const status = (state = 'REGISTRATION', action) => {
  switch (action.type) {
    case PREREGISTER_START:
      return PREREGISTER_START;
    case RESET_REGISTRATION:
      return 'REGISTRATION';
    case LOADING_REGISTRATION:
      return LOADING_REGISTRATION;
    case REGISTER_EXISTING_FAILURE:
      return REGISTER_EXISTING_FAILURE;
    case REGISTER_FAILURE:
      return REGISTER_FAILURE;
    case PREREGISTER_FAILURE:
      return PREREGISTER_FAILURE;
    case PREREGISTER_REDIRECT:
      return PREREGISTER_REDIRECT;
    case REGISTER_EXISTING_SUCCESS:
      return REGISTER_EXISTING_SUCCESS;
    case REGISTER_SUCCESS:
      return REGISTER_SUCCESS;
    case PREREGISTER_SUCCESS:
      return PREREGISTER_SUCCESS;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case REGISTER_EXISTING_FAILURE:
    case REGISTER_FAILURE:
    case PREREGISTER_FAILURE:
    case PREREGISTER_REDIRECT:
      return action.error;
    case RESET_REGISTRATION:
    case LOADING_REGISTRATION:
    case REGISTER_EXISTING_SUCCESS:
    case REGISTER_SUCCESS:
    case PREREGISTER_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ status, error });
