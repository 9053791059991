import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuList,
  MenuItem,
} from 'nutrien-common-components-react/dist/mdc';
import {
  propType as invoiceFiltersPropType,
  defaultProp as invoiceFiltersDefaultProp,
} from '../../propTypes/invoiceFiltersPropType';
import FilterButton from '../FilterButton';

class InvoiceStatusFilter extends Component {
  constructor(props) {
    super(props);
    this.menuAnchor = React.createRef();
  }

  state = {
    isMenuOpen: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { isMenuOpen } = this.state;
    const { gtmToggleInvoiceFilter } = this.props;

    if (isMenuOpen !== prevState.isMenuOpen) {
      gtmToggleInvoiceFilter('status', isMenuOpen);
    }
  }

  handleSelect = ({ selectedIndices: id }) => {
    const { invoiceOptions, updateInvoiceFilters } = this.props;
    updateInvoiceFilters({ status: invoiceOptions[id] });
    this.setState({ isMenuOpen: false });

    if (this.props.currentPage > 1) this.props.handlePaginationClick(1);
  };

  render() {
    const { invoiceFilters, invoiceOptions } = this.props;
    const value = invoiceFilters.status || 'All';
    let selectedIndex = 0;
    invoiceOptions.forEach((option, index) => {
      if (option === value) selectedIndex = index;
    });

    return (
      <>
        <FilterButton
          onClick={() =>
            this.setState({
              isMenuOpen: !this.state.isMenuOpen,
            })
          }
          ref={this.menuAnchor}
          value={value}
        />
        <Menu
          open={this.state.isMenuOpen}
          onClose={() => this.setState({ isMenuOpen: false })}
          anchorElement={this.menuAnchor.current}
          onSelect={this.handleSelect}
        >
          <MenuList selectedIndex={selectedIndex}>
            {invoiceOptions.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </>
    );
  }
}

InvoiceStatusFilter.propTypes = {
  invoiceFilters: invoiceFiltersPropType,
  updateInvoiceFilters: PropTypes.func,
  invoiceOptions: PropTypes.arrayOf(PropTypes.string),
  currentPage: PropTypes.number,
  handlePaginationClick: PropTypes.func.isRequired,
  gtmToggleInvoiceFilter: PropTypes.func.isRequired,
};

InvoiceStatusFilter.defaultProps = {
  invoiceOptions: [],
  invoiceFilters: invoiceFiltersDefaultProp,
  updateInvoiceFilters: () => {},
  currentPage: 1,
};

export default InvoiceStatusFilter;
