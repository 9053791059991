import React from 'react';
import { Typography } from '@nutrien/uet-react';
import Content from './Content';
import DoNotSellForm from './DoNotSellForm';

const DoNotSell = () => (
  <>
    <Typography variant="h1" gutterBottom>
      Do Not Sell My Personal Information
    </Typography>
    <Typography variant="body2" gutterBottom>
      California Consumer Privacy Act (CCPA)
    </Typography>
    <Content />
    <DoNotSellForm />
  </>
);

DoNotSell.displayName = 'DoNotSell';

export default DoNotSell;
