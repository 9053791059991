import { getUnitOfMeasureLong } from 'components/Shop/utils/unitMeasuresLong';
import capitalizeFirstLetter from 'components/Shop/utils/capitalizeFirstLetter';

// Canada variant option name should be unitOfMeasure (digitalVariantName)
// e.g. Jug (10L)
// digitalVariantName for CAN data has been changed to reflect variant volume

// US variant option name should be quantity x packSize unitOfMeasure
// e.g. 2 x 2.5 GA
// digitalVariantName for US data has always been quantity x packSize unitOfMeasure

export default (option, isCanada = false) => {
  if (!isCanada) {
    return option.is_bulk
      ? 'Bulk'
      : `${option.unit_qty} x ${option.unit_size} ${option.unit_measure}`;
  }
  const capitalizedUnitOfMeasure = capitalizeFirstLetter(
    getUnitOfMeasureLong(option.unit_measure, option.unit_qty)
  );
  if (!option.digital_variant_name || !option.digital_variant_name.trim())
    return capitalizedUnitOfMeasure;
  const allCapsDigitalVariantName = option.digital_variant_name.toUpperCase();
  return `${capitalizedUnitOfMeasure} (${allCapsDigitalVariantName})`;
};
