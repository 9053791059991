import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  Icon,
  Checkbox,
} from 'nutrien-common-components-react/dist/mdc';
import { NUTRIEN_COMPANY_NAME } from 'shared/config/constants';

import './OrderConditionsModal.scss';

class OrderConditionsModal extends Component {
  state = {
    checked: false,
  };

  handleCheckboxChange = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  render() {
    const { handleCloseModal, handleOkButton, open } = this.props;

    return (
      <Dialog
        onClosed={() => handleCloseModal()}
        open={open}
        className="conditions-content-modal"
      >
        <DialogContent>
          <div className="conditions-content-wrapper">
            <Icon
              onClick={() => handleCloseModal()}
              className="conditions-content__close"
              icon="close"
              type="branch-black"
              data-test="order-close"
            />
            <h2 className="conditions-content__headline">
              Let&#39;s Complete Your Order Request
            </h2>

            <p
              className="conditions-content__alert"
              data-test="order-review-pricing-modal"
            >
              After submitting your order request, your {NUTRIEN_COMPANY_NAME}{' '}
              branch may contact you to review the order, pricing details, and
              regulatory compliance.
            </p>
            <p className="conditions-content__tout">
              Please allow 1-2 business days for your order request to be
              confirmed by your branch.
            </p>
            <p className="conditions-content__blurb">
              Please note, changes in market conditions or other circumstances
              may make the information displayed on this portal inaccurate. We
              apologize in advance for any inconvenience.
            </p>
            <span className="conditions-content__contact">
              Have questions?&nbsp;
              <a href="/contact" rel="noreferrer noopener" target="_blank">
                Contact your Nutrien Ag Solutions Representative.
              </a>
            </span>
          </div>
        </DialogContent>
        <DialogActions className="conditions-actions-wrapper">
          <div className="conditions-checkbox-wrapper">
            <Checkbox
              onChange={(e) => this.handleCheckboxChange(e)}
              checked={this.state.checked}
              className="conditions-checkbox"
              data-test="order-checkbox"
            />
            <span className="conditions-checkbox__text">
              Don&#39;t show this message again
            </span>
          </div>
          <div className="conditions-button-wrapper">
            <DialogButton
              onClick={() => handleCloseModal()}
              secondary
              className="conditions-button"
              data-test="order-cancel"
            >
              Cancel
            </DialogButton>
            <DialogButton
              onClick={() => handleOkButton(this.state.checked)}
              className="conditions-button"
              data-test="order-confirm"
            >
              Continue
            </DialogButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

OrderConditionsModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleOkButton: PropTypes.func.isRequired,
};

export default OrderConditionsModal;
