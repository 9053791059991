import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import {
  REQUESTED_MESSAGE,
  NOT_REQUESTED_MESSAGE,
  EMPTY_MESSAGE,
} from '../../../../../shared/constants/ecommMessageText';

import './Services.scss';

const Services = (props) => {
  const { cart } = props;

  let customApplied;
  let theApplicationMemo;

  const attributes = get(cart, 'postOrder.data.data.attributes', {});

  if (!isEmpty(attributes)) {
    customApplied = attributes.isCustomApplied;
    theApplicationMemo = attributes.applicationMemo;
  }

  return (
    <div className="services-wrapper">
      <h2>Services</h2>
      <div className="services-wrapper--inner">
        <div>
          <div className="services-name">Application Services</div>
          <div
            className="services__headline--detail"
            data-test="custom-applied"
          >
            {customApplied ? REQUESTED_MESSAGE : NOT_REQUESTED_MESSAGE}
          </div>
        </div>
        <div className="services-blurb-wrapper">
          <div>
            <div className="services-name">Application Instructions</div>
          </div>
          <p className="services-blurb" data-test="application-instructions">
            {theApplicationMemo || EMPTY_MESSAGE}
          </p>
        </div>
      </div>
    </div>
  );
};

Services.propTypes = {
  cart: PropTypes.shape({
    submittedCartData: PropTypes.array.isRequired,
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        error: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export default Services;
