// dependencies
import React from 'react';
import PropTypes from 'prop-types';

import './FullPage.css';

const FullPage = ({ children, title, noCard }) => (
  <div className={`full-page ${!noCard && 'full-page__card'}`}>
    {title && (
      <div className="full-page-header">
        <h1 className="full-page-title">{title}</h1>
      </div>
    )}
    {children}
  </div>
);

FullPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  title: PropTypes.string,
  noCard: PropTypes.bool,
};

FullPage.defaultProps = {
  children: undefined,
  title: '',
  noCard: false,
};

export default FullPage;
