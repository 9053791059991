import React from 'react';
import { string } from 'prop-types';
import { Icon } from 'nutrien-common-components-react/dist/mdc/';

const OrderStatus = ({ status }) => {
  let color = 'nutrien-green';
  let iconType = 'success';
  switch (status.toLowerCase()) {
    case 'submitted':
      iconType = 'progressCircleOneQuarter';
      break;
    case 'confirmed':
      iconType = 'progressCircleOneHalf';
      break;
    case 'fulfilled':
      iconType = 'progressCircleThreeQuarters';
      break;
    case 'invoiced':
      color = 'success';
      iconType = 'success';
      break;
    case 'cancelled':
      color = 'danger';
      iconType = 'cancelled';
      break;
    default:
      color = 'warning';
  }
  return (
    <div className="orders-list__status">
      <div className="orders-list__status-icon">
        <Icon
          data-test="orders-list-status-icon"
          type={color}
          icon={iconType}
        />
      </div>
      <p className="orders-list__status-text">{status}</p>
    </div>
  );
};

OrderStatus.propTypes = {
  status: string.isRequired,
};

export default OrderStatus;
