export default (invoices, amountDueRange = []) => {
  if (amountDueRange.length === 0) return invoices;

  const min = amountDueRange[0] || -Number.MAX_SAFE_INTEGER;
  const max = amountDueRange[1] || Number.MAX_SAFE_INTEGER;

  return invoices
    .filter((invoice) => invoice.amountDue >= min)
    .filter((invoice) => invoice.amountDue <= max);
};
