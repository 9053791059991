import { combineReducers } from 'redux';
import error from './error';
import hash from './hash';
import status from './status';

export default combineReducers({
  error,
  hash,
  status,
});
