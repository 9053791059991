import React from 'react';
import { makeStyles, Button, Grid, Typography } from '@material-ui/core';
import Card from '@nutrien/uet-react/Card';
import CardContent from '@nutrien/uet-react/CardContent';
import NutrienLogosStacked from 'assets/nutrien-financial-logos-stacked.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedAccount } from 'selectors/accountSelectors';
import {
  resolveOcaUrl,
  resolveOcaTemplateId,
} from 'components/CreditApplication/resolveOcaUrl';
import { startCreditApplication } from 'actions/creditActions';
import { useLocation } from 'react-router-dom';
import { selectEmployee } from 'selectors/employeeSelectors';

const useStyles = makeStyles({
  nutrienFinancialBanner: {
    marginTop: '2rem',
  },
  nutrienFinancialButton: {
    width: '100%',
  },
  nutrienFinancialImgCenter: {
    display: 'block',
    margin: '0 auto',
  },
  nutrienFinancialTypography: {
    margin: '0 1rem',
    '@media only screen and (max-width: 400px)': {
      margin: '0 auto',
    },
    '@media only screen and (max-width: 768px)': {
      textAlign: 'center',
    },
  },
});

export const FinancialBanner = () => {
  const classes = useStyles();
  const ocaUrl = resolveOcaUrl();
  const ocaTemplateId = resolveOcaTemplateId();
  const { creditApp: creditAppFlag } = useFlags();
  const selectedAccount = useSelector(selectSelectedAccount());
  const dispatch = useDispatch();
  const isCanadianAcct = selectedAccount.locationCode === 'CAN';
  const { pathname } = useLocation();
  const { mirroring } = useSelector(selectEmployee());
  const disabled = mirroring.isMirroring;

  if (!creditAppFlag || isCanadianAcct) return null;

  return (
    <Card
      className={classes.nutrienFinancialBanner}
      data-test="financial-banner"
    >
      <CardContent>
        <Grid container alignItems="center" justify="space-evenly" spacing={2}>
          <Grid item xs={12} md={2}>
            <img
              className={classes.nutrienFinancialImgCenter}
              src={NutrienLogosStacked}
              alt="Nutrien Financial Logos Stacked"
              height="64px"
              width="164px"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              className={classes.nutrienFinancialTypography}
              variant="h5"
            >
              Nutrien Financial is here for your product needs with finance
              rates for qualified growers.
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              onClick={() => {
                // track initiate a credit application
                dispatch(startCreditApplication(selectedAccount, pathname));
              }}
              className={classes.nutrienFinancialButton}
              href={`${ocaUrl}?continue=form-new/${ocaTemplateId}`}
              rel="noopener"
              target="_blank"
              variant="contained"
              data-test="financial-banner-apply-now-btn"
              disabled={disabled}
            >
              Apply Now
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
