import {
  SET_PREPAY_HISTORY,
  SET_PREPAY_TRANSACTION,
  CLEAR_PREPAY_TRANSACTION,
  SET_PREPAY_DETAILS,
  CLEAR_PREPAY,
} from '../const/prepay';

export const prepayDetails = (state = { status: null, data: null }, action) => {
  if (action.type === SET_PREPAY_DETAILS) return { ...action.payload };
  if (action.type === CLEAR_PREPAY) return { ...{ status: null, data: null } };
  return state;
};

export const prepayHistory = (state = { status: null, data: [] }, action) => {
  if (action.type === SET_PREPAY_HISTORY) return { ...action.payload };
  if (action.type === CLEAR_PREPAY) return { ...{ status: null, data: [] } };
  return state;
};

export const prepayTransaction = (state = null, action) => {
  if (action.type === SET_PREPAY_TRANSACTION) return { ...action.payload };
  if (action.type === CLEAR_PREPAY_TRANSACTION) return null;
  return state;
};
