// dependencies
import React from 'react';
import PropTypes from 'prop-types';

// stylesheets
import './Button.css';
import BUTTON_CONSTANTS from './buttonConstants';

const handleKeyPress = (e) => {
  if (
    e.which === BUTTON_CONSTANTS.ENTER ||
    e.which === BUTTON_CONSTANTS.SPACEBAR
  ) {
    this.onClick();
  }
};

const Button = (props) => {
  const {
    type,
    onClick,
    onKeyPress,
    className,
    id,
    value,
    role,
    disabled,
  } = props;

  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      onKeyPress={onKeyPress || handleKeyPress}
      disabled={disabled}
      className={`button ${className || ''}`}
      role={role}
    >
      {value}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  role: PropTypes.string,
};

Button.defaultProps = {
  id: '',
  className: '',
  type: '',
  role: 'button',
  value: '',
  disabled: false,
  onClick() {
    return false;
  },
  onKeyPress() {
    return false;
  },
};

export default Button;
