export default (theme) => ({
  amount: {
    fontSize: '32px',
    color: '#464646',
    paddingBottom: '10px',
  },
  colorFilter: {
    opacity: '0.6',
  },
  downCaret: {
    color: theme.palette.primary.main,
    position: 'relative',
    top: '8.5px',
    transform: 'scaleX(1.3)',
    transition: 'all 0.25s',
  },
  discountedAmount: {
    fontSize: '14px',
    color: '#222222',
    opacity: '0.87',
  },
  dropDownSelector: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.primary.main,
    lineHeight: '1.36',
    display: 'inline',
  },
  hidden: {
    visibility: 'hidden',
    position: 'absolute',
    opacity: 0,
  },
  invoiceListsContainer: {
    marginBottom: '41px',
  },
  invoiceTable: {
    transition: 'visibility 0s, opacity 0.5s linear',
  },
  listOpener: {
    cursor: 'pointer',
    width: 'fit-content',
    userSelect: 'none',
  },
  paidText: {
    paddingLeft: '20px',
    fontSize: '14px',
  },
  paymentNotes: {
    borderRadius: '2px',
    border: '1px solid #d7d7d7',
    marginBottom: '30px',
    width: '401px',
    resize: 'none',
    padding: '5px',
    fontSize: '14px',
  },
  rotatedCaret: {
    transform: 'rotate(180deg) scaleX(1.3)',
    top: '6.5px',
  },
  title: {
    fontSize: '24px',
    color: '#222222',
  },
  totalSelected: {
    fontSize: '12px',
    color: theme.palette.text.primary,
  },
  visible: {
    visibility: 'visible',
  },
});
