import { push, goBack } from 'connected-react-router';
import * as paymentService from '../../PaymentService';
import {
  CLEAR_PAYMENT_STATE,
  SET_TERMS_AND_CONDITIONS_CONTENT,
  LOADING_DATA_IN_PROGRESS,
  LOADING_DATA_SUCCESS,
  LOADING_DATA_FAILURE,
  payByPrepaySavedAccount,
} from '../../PaymentConstants';
import { getBankAccountNumberPayload } from '../../Utils/FormUtils';
import {
  updateBankAccountForm,
  clearBankAccount,
} from './BankAccountFormActions';
import { handlePaymentFailure } from './ErrorActions';

export const clearPaymentState = () => ({
  type: CLEAR_PAYMENT_STATE,
});

export const loadingDataInProgress = () => ({ type: LOADING_DATA_IN_PROGRESS });
export const loadingDataSuccess = () => ({ type: LOADING_DATA_SUCCESS });
export const loadingDataFailure = () => ({ type: LOADING_DATA_FAILURE });

export const setTermsAndConditionsContent = (response) => ({
  type: SET_TERMS_AND_CONDITIONS_CONTENT,
  payload: response,
});

export const checkIfValidPayment = ({ form }, invoiceIds) => () => {
  const body = {
    amount: form.amountEntered,
    accountId: form.cpsAccount.id,
    savePaymentMethod: form.savePaymentMethod,
    scheduledPaymentDate: form.scheduledPayment || null,
    invoiceIds,
  };
  if (form.savedPaymentMethod === payByPrepaySavedAccount) {
    return paymentService.validatePaymentAsPrepay(body);
  }
  return paymentService.validatePayment(body);
};

export const goBackAction = () => (dispatch) => {
  dispatch(clearBankAccount());
  dispatch(goBack());
};

export const navigateToReviewPage = (countryCode) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { form } = state.payments.bankAccountFormReducer;

  try {
    dispatch(loadingDataInProgress());

    const accountPayload = getBankAccountNumberPayload({
      form,
      accountCountryCode: countryCode,
    });
    const response = await paymentService.getTermsAndConditionsContent(
      countryCode
    );

    dispatch(updateBankAccountForm(accountPayload));
    dispatch(setTermsAndConditionsContent(response.data));
    dispatch(loadingDataSuccess());
    dispatch(push('/review-payment'));
  } catch (error) {
    dispatch(handlePaymentFailure(error));
    dispatch(loadingDataFailure());
  }
};
