import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { ACTION_TYPES } from '../actions';

const {
  SELECT_INVOICED_PRODUCT,
  DESELECT_INVOICED_PRODUCT,
  CLEAR_SELECTED_INVOICED_PRODUCTS,
} = ACTION_TYPES;

const initialState = {
  productSummaries: [],
};

const selectInvoicedProduct = (state, action) => {
  const { productId } = action.payload;
  const stateCopy = cloneDeep(state);

  const productAlreadyExists = stateCopy.productSummaries.some(
    (element) => element.productId === productId
  );
  if (!productAlreadyExists) stateCopy.productSummaries.push(action.payload);

  return stateCopy;
};

const deselectInvoicedProduct = (state, action) => {
  const { productId } = action.payload;
  const stateCopy = cloneDeep(state);

  remove(
    stateCopy.productSummaries,
    (element) => element.productId === productId
  );

  return stateCopy;
};

const productSummaries = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_INVOICED_PRODUCT:
      return selectInvoicedProduct(state, action);
    case DESELECT_INVOICED_PRODUCT:
      return deselectInvoicedProduct(state, action);
    case CLEAR_SELECTED_INVOICED_PRODUCTS:
      return initialState;
    default:
      return state;
  }
};

export default productSummaries;
