import React from 'react';
import PropTypes from 'prop-types';
import Button from '@nutrien/uet-react/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from 'shared/ui';

const InvoiceDownloadErrorDialog = ({ errorType, onClose, isOpen }) => {
  const title = `The Invoice ${errorType.toUpperCase()} could not be downloaded`;
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please contact your branch to request a copy.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

InvoiceDownloadErrorDialog.propTypes = {
  errorType: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

InvoiceDownloadErrorDialog.defaultProps = {
  errorType: 'csv',
  onClose: () => {},
  isOpen: false,
};

export default InvoiceDownloadErrorDialog;
