import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  ApiErrorMessage,
  MaterialLoadingIcon,
  AccountDescriptionText,
} from 'shared/ui';
import {
  accountPropType,
  invoiceMatchParams,
  invoicePropType,
} from './invoicePropTypes';
import { InvoiceStatuses } from './actions';
import InvoiceDataTable from './InvoiceDataTable';
import InvoiceCards from './InvoiceCards';
import InvoiceExportLinks from './InvoiceExportLinks';
import InvoiceSummary from './InvoiceSummary';
import InvoiceAccountInfo from './InvoiceAccountInfo';
import InvoiceDownloadErrorDialog from './InvoiceDownloadErrorDialog';
import { PAYMENT_FLOW_TYPES } from '../Payments/PaymentConstants';
import PageHeader from '../../shared/ui/PageHeader/PageHeader';

class Invoice extends Component {
  getDownloadErrorType = () => {
    const { csvDownloadError, pdfDownloadError } = this.props;

    if (csvDownloadError) {
      return 'csv';
    }
    if (pdfDownloadError) {
      return 'pdf';
    }

    return undefined;
  };

  handlePaymentClick = (account, invoice) => {
    const {
      push,
      selectInvoice,
      clearInvoices,
      gtmMakeInvoicePayment,
      setPaymentFlowType,
    } = this.props;
    clearInvoices();
    selectInvoice(invoice);
    setPaymentFlowType(PAYMENT_FLOW_TYPES.INVOICE_PAYMENT);

    push({
      pathname: `/make-a-secure-payment`,
      state: {
        account: account.id,
        selectedInvoices: { unpaid: [invoice.id] },
      },
    });

    window.scroll(0, 0);
    gtmMakeInvoicePayment('Click', 'Pay Now Button', invoice.id);
  };

  render() {
    const {
      invoice: invoiceState,
      account,
      invoicedAmountDue,
      handleCloseError,
      csvDownloadError,
      pdfDownloadError,
    } = this.props;
    const { status, invoice } = invoiceState;
    const { accountId } = this.props.match.params;

    return (
      <>
        {status === InvoiceStatuses.LOADING && <MaterialLoadingIcon />}
        {status === InvoiceStatuses.ERROR && (
          <ApiErrorMessage entityName="Invoice" />
        )}
        {status === InvoiceStatuses.OK && (
          <>
            <PageHeader
              title={`Invoice - ${invoice.id}`}
              breadcrumbs={[
                {
                  link: '/',
                  label: 'Home',
                },
                {
                  link: '/accounts',
                  label: 'Accounts',
                },
                {
                  link: `/accounts/${accountId}/invoices?acct=${accountId}`,
                  label: <AccountDescriptionText account={account} />,
                },
                {
                  label: `Invoice - ${invoice.id}`,
                },
              ]}
            />
            <InvoiceAccountInfo account={account} invoice={invoice} />
            {invoice.date && (
              <InvoiceCards
                account={account}
                invoice={invoice}
                handlePaymentClick={this.handlePaymentClick}
              />
            )}
            <InvoiceExportLinks accountId={accountId} invoice={invoice.id} />
            <InvoiceDataTable invoice={invoice} />
            <InvoiceSummary
              invoice={invoice}
              invoicedAmountDue={invoicedAmountDue}
            />
          </>
        )}
        <InvoiceDownloadErrorDialog
          isOpen={csvDownloadError || pdfDownloadError}
          errorType={this.getDownloadErrorType()}
          onClose={handleCloseError}
        />
      </>
    );
  }
}

Invoice.propTypes = {
  invoicedAmountDue: PropTypes.string.isRequired,
  invoice: invoicePropType.isRequired,
  csvDownloadError: PropTypes.bool.isRequired,
  pdfDownloadError: PropTypes.bool.isRequired,
  handleCloseError: PropTypes.func.isRequired,
  account: accountPropType,
  match: invoiceMatchParams.isRequired,
  push: PropTypes.func.isRequired,
  selectInvoice: PropTypes.func.isRequired,
  clearInvoices: PropTypes.func.isRequired,
  gtmMakeInvoicePayment: PropTypes.func.isRequired,
  setPaymentFlowType: PropTypes.func.isRequired,
};

Invoice.defaultProps = {
  account: {
    id: '',
  },
};

export default Invoice;
