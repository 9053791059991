export const LOADING_PREFERENCES = 'LOADING_PREFERENCES';
export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS';
export const GET_PREFERENCES_FAILURE = 'GET_PREFERENCES_FAILURE';
export const UPDATE_PREFERENCE_SUCCESS = 'UPDATE_PREFERENCE_SUCCESS';
export const UPDATE_PREFERENCE_FAILURE = 'UPDATE_PREFERENCE_FAILURE';

export const REGIONAL_PREFERENCES = [
  {
    key: 'CANADA',
    label: 'Canada',
  },
  {
    key: 'CORN_BELT',
    label: 'Corn Belt',
  },
  {
    key: 'NORTHEAST',
    label: 'North East',
  },
  {
    key: 'SOUTH',
    label: 'South',
  },
  {
    key: 'WEST',
    label: 'West',
  },
];

export const BIG_PICTURE_PREFERENCES = [
  {
    key: 'US_GLOBAL_AG_FORECAST',
    label: 'US/Global Ag Forecast',
  },
  {
    key: 'US_LONG_RANGE_FORECAST',
    label: 'US Long-Range Forecast',
  },
];

export const WEATHER_STORY_NOTIFICATION_PREFERENCES = {
  BIG_PICTURE: {
    label: 'Big Picture',
    preferences: BIG_PICTURE_PREFERENCES,
  },
  REGIONAL: {
    label: 'Regional',
    preferences: REGIONAL_PREFERENCES,
  },
};
