import {
  UPDATE_ADD_USER_FORM,
  RESET_ADD_USER_FORM,
  SET_ADD_USER_FORM_ERRORS,
  CLEAR_ADD_USER_FORM_ERRORS,
} from './rbacConstants';

const initialState = {
  form: {
    email: '',
  },
  formErrors: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ADD_USER_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    case SET_ADD_USER_FORM_ERRORS:
      return { ...state, formErrors: { ...action.payload } };
    case CLEAR_ADD_USER_FORM_ERRORS:
      return { ...state, formErrors: initialState.formErrors };
    case RESET_ADD_USER_FORM:
      return {
        ...state,
        form: initialState.form,
        formErrors: initialState.formErrors,
      };
    default:
      return state;
  }
};

export default reducer;
