import React, { Component } from 'react';
import { AccountingList, AccountingListItem } from 'shared/ui';
import {
  addCommas,
  currency,
  formatUnitPrice,
} from 'shared/utils/numberFormatters';
import { invoicePropType } from '../invoicePropTypes';

const headers = [
  'Product Number',
  'Description',
  'Quantity',
  { text: 'Unit Price', align: 'right' },
  { text: 'Sales Tax', align: 'right' },
  { text: 'Total Amount', align: 'right' },
];

class InvoiceDataTable extends Component {
  getMobileHeader = (index) => {
    return headers[index].text || headers[index];
  };

  generateInvoiceLineItems = (invoice) => {
    if (!(invoice.lineItems && invoice.lineItems.length)) {
      return null;
    }

    return invoice.lineItems.map((lineItem) => (
      <tr
        className="invoice__line-item activity-row-mobile"
        key={lineItem.invoiceLineItemNumber}
      >
        {/* TODO: all the ListItems require a 'link' property, but none of the list items were supplied with one
              - should it be an optional parameter? */}
        <AccountingListItem
          data={lineItem.productNumber}
          link=""
          mobileHeader={this.getMobileHeader(0)}
        />
        <AccountingListItem
          data={lineItem.productDescription}
          noPadding
          link=""
          mobileHeader={this.getMobileHeader(1)}
        />
        <AccountingListItem
          data={`${addCommas(lineItem.quantity)} ${lineItem.uom}`}
          noWrap
          link=""
          mobileHeader={this.getMobileHeader(2)}
        />
        <AccountingListItem
          data={formatUnitPrice(lineItem.grossUnitPrice)}
          right
          link=""
          mobileHeader={this.getMobileHeader(3)}
        />
        <AccountingListItem
          data={currency(lineItem.salesTax)}
          right
          link=""
          mobileHeader={this.getMobileHeader(4)}
        />
        <AccountingListItem
          data={currency(lineItem.grossExtendedPrice)}
          right
          link=""
          mobileHeader={this.getMobileHeader(5)}
        />
      </tr>
    ));
  };

  render() {
    const { invoice } = this.props;

    const invoiceLineItems = this.generateInvoiceLineItems(invoice);
    const titleBarContent = <div />;

    return (
      <div>
        <div className="invoice-order-section">
          <h2 className="invoice-order-section-text">Invoiced Details</h2>
        </div>
        <AccountingList
          titleBarContent={titleBarContent}
          title=""
          headers={headers}
        >
          {invoiceLineItems}
        </AccountingList>
        <div className="invoice__line-items-missing">
          {invoice.lineItems ? '' : 'No invoice activity available.'}
        </div>
      </div>
    );
  }
}

InvoiceDataTable.propTypes = {
  invoice: invoicePropType.isRequired,
};

export default InvoiceDataTable;
