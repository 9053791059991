import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogButton,
} from 'nutrien-common-components-react/dist/mdc';
import DateRangePicker from 'shared/ui/DateRangePicker';
import { getYearsFromToday } from 'shared/utils/DateHelper';
import './styles.scss';
import { MINIMUM_DATE } from './constants';

class InvoiceDateRangeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRange:
        props.initialSelection instanceof Array &&
        props.initialSelection[0] &&
        props.initialSelection[1]
          ? {
              startDate: new Date(props.initialSelection[0]),
              endDate: new Date(props.initialSelection[1]),
              key: 'selection',
            }
          : this.defaultState,
    };
  }

  state = {
    dateRange: this.defaultState,
  };

  defaultState = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };

  handleDateRangeChange = (ranges) => {
    if (ranges.selection) this.setState({ dateRange: ranges.selection });
  };

  handleClear = () => {
    this.setState({ dateRange: this.defaultState });
    this.props.onClear();
  };

  render() {
    const {
      initialSelection, // eslint-disable-line no-unused-vars
      onAccept,
      onClose,
      open,
      onClosed,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClosed={onClosed}
        className="invoice-date-range-modal"
      >
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent className="invoice-date-range-modal__content">
          <div className="invoice-date-range-modal__datepicker-container">
            <DateRangePicker
              onChange={this.handleDateRangeChange}
              ranges={[this.state.dateRange]}
              moveOnFirstSelection="true"
              minDate={MINIMUM_DATE}
              maxDate={getYearsFromToday(2).toDate()}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <DialogButton action="cancel" onClick={onClose}>
            Cancel
          </DialogButton>
          <DialogButton action="accept" onClick={this.handleClear}>
            Clear
          </DialogButton>
          <DialogButton
            action="accept"
            onClick={() => {
              onAccept([
                `${this.state.dateRange.startDate}`,
                `${this.state.dateRange.endDate}`,
              ]);
            }}
          >
            Ok
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }
}

InvoiceDateRangeModal.propTypes = {
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  onClear: PropTypes.func,
  initialSelection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  open: PropTypes.bool,
  onClosed: PropTypes.func,
};

InvoiceDateRangeModal.defaultProps = {
  onAccept: () => {},
  onClose: () => {},
  onClear: () => {},
  initialSelection: [],
  open: false,
  onClosed: () => {},
};

export default InvoiceDateRangeModal;
