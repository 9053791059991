// List of Unit of Measures we do not want shown to the customers
const sapUomsConstants = {
  ACR: 'ACR',
  BAG: 'BAG',
  BOT: 'BOT',
  CS: 'CS',
  CV: 'CV',
  DR: 'DR',
  EA: 'EA',
  FT: 'FT',
  FT3: 'FT3',
  G: 'G',
  GLL: 'GLL',
  JHR: 'JHR',
  KG: 'KG',
  L: 'L',
  LB: 'LB',
  MEINS: 'MEINS',
  ML: 'ML',
  OZ: 'OZ',
  OZA: 'OZA',
  PAA: 'PAA',
  PAC: 'PAC',
  PAK: 'PAK',
  QT: 'QT',
  ST: 'ST',
  STD: 'STD',
  TO: 'TO',
  ZBX: 'ZBX',
  ZJU: 'ZJU',
  ZMB: 'ZMB',
  ZPA: 'ZPA',
  ZSU: 'ZSU',
};

const validateSapUnitOfMeasure = (uom) => {
  if (!uom) return null;
  let unit = uom;
  // normalize all entries to Uppercase and remove any whitespace
  unit = unit.toUpperCase().replace(/\s+/g, '');

  if (sapUomsConstants[unit]) {
    return null;
  }
  return unit;
};

export default validateSapUnitOfMeasure;
