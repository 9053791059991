import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPLOYEE_LANDING_FILTER_UPDATE } from '../employeeLandingPageConstants';

export default (state = '', action) => {
  if (action.type === EMPLOYEE_LANDING_FILTER_UPDATE) {
    return action.payload.filterText;
  }
  if (action.type === LOCATION_CHANGE) {
    return '';
  }
  return state;
};
