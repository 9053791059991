import get from 'lodash/get';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import Form from 'shared/ui/MaterialFormCard/Form';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import PageNotification from 'shared/ui/NotificationHelper/PageNotification';
import {
  validateEmail,
  formValidationConstants,
} from 'shared/utils/formValidation';
import './LoginForm.scss';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      displayPassword: false,
      error: '',
    };
  }

  componentDidMount = () => {
    this.handleLoginError(this.props.user.error);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.password !== this.state.password) {
      if (
        this.state.error !== formValidationConstants.ENTER_VALID_EMAIL_ADDRESS
      )
        this.setError('');
    } else if (prevState.email !== this.state.email) {
      this.setError('');
    }

    if (!isEqual(prevProps.initialValues, this.props.initialValues)) {
      // passing initial email value to the form if an existent user
      // tries to register
      // disabling eslint here, https://github.com/airbnb/javascript/issues/1875
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        email: get(this.props.initialValues, 'email'),
      });
    }
  };

  setError = (message) => {
    this.setState({
      error: message,
    });
  };

  handleLoginError = (loginError) => {
    const errorInState = this.state.error;

    if (!(!errorInState && loginError)) return;

    if (get(loginError, 'data.name') === 'DebtStatus') {
      this.setError(formValidationConstants.GENERAL_LOGIN_PROBLEM);
    } else if (loginError.status === 401) {
      this.setError(formValidationConstants.INCORRECT_EMAIL_OR_PASSWORD);
    } else {
      this.setError(formValidationConstants.GENERAL_LOGIN_PROBLEM);
    }
  };

  toggleDisplayValue = (field) => {
    this.setState(() => ({
      [field]: !this.state[field],
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.email === '' || this.state.password === '') {
      return this.setError(
        formValidationConstants.ENTER_EMAIL_ADDRESS_AND_PASSWORD
      );
    }

    if (!validateEmail(this.state.email)) {
      return this.setError(formValidationConstants.ENTER_VALID_EMAIL_ADDRESS);
    }

    const data = {
      username: this.state.email.trim(),
      password: this.state.password.trim(),
    };

    this.props.setAlert({});
    return this.props.loginUser(
      data,
      (this.props.location.state || {}).from || { pathname: '/' }
    );
  };

  handleChange = (e) => {
    const { value, name } = e.target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    let alert;
    if (
      Object.keys(this.props.alert).length !== 0 &&
      this.props.alert.constructor === Object
    ) {
      alert = (
        <div className="login-form__notifaction-container">
          <PageNotification
            message={this.props.alert.notification.message}
            mobileMessage={this.props.alert.notification.mobileMessage}
            type={this.props.alert.notification.type}
            className="page-notification__no-padding"
          />
        </div>
      );
    }

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          {this.props.location.pathname === '/sign-in' && (
            <div className="login-form__content-section-title">
              Returning Customer
            </div>
          )}
          {alert || null}
          <FormTextField
            name="email"
            title="Email Address"
            value={this.state.email}
            handleChange={this.handleChange}
            autocomplete="email"
          />
          <FormTextField
            name="password"
            title="Password"
            value={this.state.password}
            type="password"
            autocomplete="current-password"
            handleChange={this.handleChange}
            toggleShowValue={this.toggleDisplayValue}
            shouldValueShow={this.state.displayPassword}
            toggleValue="displayPassword"
          />
          <div className="login-form__link-container">
            <Link
              to="/forgot-password"
              className="login-form__link-container-link"
            >
              Forgot your password?
            </Link>
          </div>
          <div className="login-form__error" id="loginError">
            {this.state.error}
          </div>
          <MaterialButton
            id="sign-in-button"
            className="button--long"
            value="Sign In"
            type="submit"
          />
        </Form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  user: PropTypes.shape({
    error: PropTypes.shape({}),
  }).isRequired,
  alert: PropTypes.shape({
    notification: PropTypes.shape({
      message: PropTypes.string,
      mobileMessage: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  loginUser: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  initialValues: PropTypes.shape({ email: PropTypes.string }),
};

LoginForm.defaultProps = {
  alert: {},
  initialValues: undefined,
};

export default LoginForm;
