export const LOADING_PRODUCTS = 'LOADING_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const GET_PRICES_FOR_PRODUCTS_SUCCESS =
  'GET_PRICES_FOR_PRODUCTS_SUCCESS';
export const LOADING_PRICES_FOR_PRODUCTS = 'LOADING_PRICES_FOR_PRODUCTS';
export const GET_PRICES_FOR_PRODUCTS_FAILURE =
  'GET_PRICES_FOR_PRODUCTS_FAILURE';

export const GET_CROSS_SELLS_SUCCESS = 'GET_CROSS_SELLS_SUCCESS';
export const GET_CROSS_SELLS_FAILURE = 'GET_CROSS_SELLS_FAILURE';
export const LOADING_CROSS_SELLS = 'LOADING_CROSS_SELLS';

export const GET_RECENT_PRODUCTS_SUCCESS = 'GET_RECENT_PRODUCTS_SUCCESS';
export const GET_RECENT_PRODUCTS_FAILURE = 'GET_RECENT_PRODUCTS_FAILURE';
export const GET_PRICES_FOR_RECENT_PRODUCTS_SUCCESS =
  'GET_PRICES_FOR_RECENT_PRODUCTS_SUCCESS';
export const GET_PRICES_FOR_RECENT_PRODUCTS_FAILURE =
  'GET_PRICES_FOR_RECENT_PRODUCTS_FAILURE';
export const LOADING_PRICES_FOR_RECENT_PRODUCTS =
  'LOADING_PRICES_FOR_RECENT_PRODUCTS';

export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const GET_PRICES_FOR_PRODUCT_SUCCESS = 'GET_PRICES_FOR_PRODUCT_SUCCESS';
export const LOADING_PRICES_FOR_PRODUCT = 'LOADING_PRICES_FOR_PRODUCT';
export const GET_PRICES_FOR_PRODUCT_FAILURE = 'GET_PRICES_FOR_PRODUCT_FAILURE';

export const CHANGE_SEARCH_TERMS = 'CHANGE_SEARCH_TERMS';
export const GET_PRODUCTS_FILTERED = 'GET_PRODUCTS_FILTERED';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const PRODUCTS_ENDPOINT = 'v1/products';
export const SET_SELECTED_VARIANT = 'SET_SELECTED_VARIANT';
export const SET_VARIANT_QUANTITY = 'SET_VARIANT_QUANTITY';
export const SET_VARIANT_VOLUME = 'SET_VARIANT_VOLUME';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SET_DEFAULT_ACCOUNT = 'SET_DEFAULT_ACCOUNT';
export const GET_MORE_PRODUCTS_SUCCESS = 'GET_MORE_PRODUCTS_SUCCESS';
export const LOADING_MORE_PRODUCTS = 'LOADING_MORE_PRODUCTS';
export const GET_MORE_PRODUCTS_FAILURE = 'GET_MORE_PRODUCTS_FAILURE';
export const SET_NEW_CATEGORY = 'SET_NEW_CATEGORY';
export const SET_NEW_SUB_CATEGORY = 'SET_NEW_SUB_CATEGORY';
export const SET_PRODUCT_SORT = 'SET_PRODUCT_SORT';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_FILTERS_SUCCESS = 'GET_PRODUCT_FILTERS_SUCCESS';
export const SET_PRODUCT_FILTERS = 'SET_PRODUCT_FILTERS';
export const TOGGLE_MOBILE_FILTER_PANEL = 'TOGGLE_MOBILE_FILTER_PANEL';

export const GET_SIMILAR_PRODUCTS_SUCCESS = 'GET_SIMILAR_PRODUCTS_SUCCESS';
export const GET_SIMILAR_PRODUCTS_FAILURE = 'GET_SIMILAR_PRODUCTS_FAILURE';
export const LOADING_SIMILAR_PRODUCTS = 'LOADING_SIMILAR_PRODUCTS';

export const UPDATE_SHOW_PRODUCT_MODAL = 'UPDATE_SHOW_PRODUCT_MODAL';
export const DISABLE_SHOW_PRODUCT_MODAL = 'DISABLE_SHOW_PRODUCT_MODAL';

export const GET_CONTRACTED_PRODUCTS_SUCCESS =
  'GET_CONTRACTED_PRODUCTS_SUCCESS';
export const GET_CONTRACTED_PRODUCTS_FAILURE =
  'GET_CONTRACTED_PRODUCTS_FAILURE';
export const LOADING_CONTRACTED_PRODUCTS = 'LOADING_CONTRACTED_PRODUCTS';
