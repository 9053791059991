import { currentAuthContext } from '@nutrien/fe-domain-utils';
import omit from 'lodash/omit';

import coreAuth from 'shared/lib/coreAuth';

const composeUserMetadata = (userMetadata = {}) =>
  omit(userMetadata, ['family_name', 'given_name', 'phone_number']);

const fetchUserInfo = async () => {
  if (!currentAuthContext.isAuth0Active) return null;

  const response = await coreAuth.get(`v3/user-info`);
  return response.data;
};

const updateUserMetadataProps = async (userMetadataProps = {}) => {
  if (!currentAuthContext.isAuth0Active) return null;

  const response = await coreAuth.patch(`v3/user-metadata`, userMetadataProps);
  return composeUserMetadata(response.data.user_metadata);
};

export const coreAuthUserService = {
  fetchUserInfo,
  updateUserMetadataProps,
  composeUserMetadata,
};
