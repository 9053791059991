import {
  UPDATE_EMPLOYEE_LOGIN_EMAIL,
  UPDATE_EMPLOYEE_LOGIN_PASSWORD,
  UPDATE_EMPLOYEE_LOGIN_SHOW_PASSWORD,
} from '../actions';

export default (
  state = { email: '', password: '', showPassword: false },
  action
) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE_LOGIN_EMAIL: {
      const { email } = action;
      return { ...state, email };
    }
    case UPDATE_EMPLOYEE_LOGIN_PASSWORD: {
      const { password } = action;
      return { ...state, password };
    }
    case UPDATE_EMPLOYEE_LOGIN_SHOW_PASSWORD: {
      const { showPassword } = action;
      return { ...state, showPassword };
    }
    default:
      return state;
  }
};
