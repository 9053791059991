import React, { Component } from 'react';
import { Checkbox } from 'nutrien-common-components-react/dist/mdc';
import PropTypes from 'prop-types';
import { ProductHistoryObjectArrayType } from '../../../ProductHistoryPropType';

class ProductHistoryCheckbox extends Component {
  anyCheckedProductHistoryObjects = () =>
    this.props.productHistoryList.some(
      (productHistoryObject) => productHistoryObject.isChecked
    );

  toggleCheckAllCheckboxes = () => {
    const anyCheckedProductHistoryObjects = this.anyCheckedProductHistoryObjects();

    if (anyCheckedProductHistoryObjects) {
      this.props.setProductHistoryObjectsChecked(
        [],
        this.props.productHistoryList
      );
    } else {
      const arrayOfAllProductNumbers = this.props.productHistoryList.map(
        (productHistory) => productHistory.productNumber
      );
      this.props.setProductHistoryObjectsChecked(
        arrayOfAllProductNumbers,
        this.props.productHistoryList
      );
    }
  };

  toggleCheckCheckbox = (productNumber) => {
    const productNumbersToCheck = this.generateCheckedProductsList(
      productNumber
    );
    const { productHistoryList } = this.props;

    this.props.setProductHistoryObjectsChecked(
      productNumbersToCheck,
      productHistoryList
    );
  };

  generateCheckedProductsList = (productNumber) => {
    const productNumbersToCheck = [];
    const { productHistoryList } = this.props;

    productHistoryList.forEach((productHistory) => {
      if (productHistory.isChecked) {
        productNumbersToCheck.push(productHistory.productNumber);
      }
    });

    if (productNumber) {
      const indexOfProductNumber = productNumbersToCheck.indexOf(productNumber);
      if (indexOfProductNumber > -1) {
        productNumbersToCheck.splice(indexOfProductNumber, 1);
      } else {
        productNumbersToCheck.push(productNumber);
      }
    }

    return productNumbersToCheck;
  };

  determineIsIndeterminate = () => {
    const anyCheckedProductHistoryObjects = this.anyCheckedProductHistoryObjects();
    const currentCheckedList = this.generateCheckedProductsList().length;
    const { productHistoryList } = this.props;

    if (
      anyCheckedProductHistoryObjects &&
      currentCheckedList > 0 &&
      currentCheckedList < productHistoryList.length
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { checked, isColumnHeader, productNumber } = this.props;

    if (isColumnHeader) {
      return (
        <Checkbox
          onClick={() => this.toggleCheckAllCheckboxes()}
          checked={this.anyCheckedProductHistoryObjects()}
          indeterminate={this.determineIsIndeterminate()}
          data-test="column-header-checkbox"
        />
      );
    }
    return (
      <Checkbox
        onClick={() => this.toggleCheckCheckbox(productNumber)}
        checked={checked}
        data-test="product-number-checkbox"
      />
    );
  }
}

ProductHistoryCheckbox.propTypes = {
  productHistoryList: ProductHistoryObjectArrayType.isRequired,
  setProductHistoryObjectsChecked: PropTypes.func.isRequired,
  productNumber: PropTypes.number,
  checked: PropTypes.bool,
  isColumnHeader: PropTypes.bool,
};

ProductHistoryCheckbox.defaultProps = {
  productNumber: undefined,
  checked: false,
  isColumnHeader: false,
};

export default ProductHistoryCheckbox;
