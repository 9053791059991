import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { func, string } from 'prop-types';
import { Link } from 'react-router-dom';
import Box from '@nutrien/uet-react/Box';
import Button from '@nutrien/uet-react/Button';
import Card from '@nutrien/uet-react/Card';
import { ApiErrorMessage, MaterialLoadingIcon } from 'shared/ui';
import MiniCart from 'components/Shop/containers/MiniCartContainer/MiniCartContainer';
import RequestInfoModal from '../../ProductHistory/RequestInfoModal';
import TopProductsTable from './TopProductsTable';
import TopProductsPropType from './TopProductsPropType';
import './TopProducts.css';

const TopProducts = ({
  startDate,
  endDate,
  fetchTopProducts,
  topProducts,
  gtmViewTopProductSDS,
  gtmViewPastTopProductPurchases,
}) => {
  const [showCartFailedModal, setShowCartFailedModal] = useState(false);
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const productHistory = useSelector((state) => state.productHistory);

  useEffect(() => {
    if (selectedAccount.id) {
      fetchTopProducts(selectedAccount, startDate, endDate);
    }
  }, [selectedAccount.id]);

  useEffect(() => {
    if (productHistory.status === 'ADD_TO_CART_FAILURE')
      setShowCartFailedModal(true);
  }, [productHistory]);

  const renderTitle = () => {
    const startDateYear = moment(startDate).format('YYYY');
    const startDateMonth = moment(startDate).format('MMMM');
    const endDateYear = moment(endDate).format('YYYY');
    const endDateMonth = moment(endDate).format('MMMM');
    const timeframeTitle = `${startDateMonth} ${startDateYear} – ${endDateMonth} ${endDateYear}`;

    return (
      <div className="product-history-container__title">
        <h3>Your Top Chemicals</h3>
        <p
          className="product-history-container__title-timeframe"
          data-test="product-history-container-title-timeframe"
        >
          {timeframeTitle}
        </p>
      </div>
    );
  };

  const renderLink = () => {
    if (selectedAccount.id) {
      return (
        <div className="top-products__link-container">
          <span>
            <Link
              to="/accounts/product-history"
              onClick={gtmViewPastTopProductPurchases}
            >
              <Button variant="contained" data-test="top-products-link-text">
                View All Past Purchases
              </Button>
            </Link>
          </span>
        </div>
      );
    }
    return null;
  };

  const renderWithContainer = (component) => {
    return (
      <div className="product-history__container">
        <Card component={Box} p={2}>
          {component}
        </Card>
      </div>
    );
  };

  const renderNoProductsError = () => {
    const startDateFormatted = moment(startDate).format('MMMM YYYY');
    const endDateFormatted = moment(endDate).format('MMMM YYYY');
    const contactUsLink = <a href="/contact">contact us</a>;

    const component = (
      <div>
        {renderTitle()}
        <div
          className="top-products__no-products-message"
          data-test="top-products-no-products"
        >
          <p>
            There are no purchases for this account from {startDateFormatted} -{' '}
            {endDateFormatted}
          </p>
          <p>
            If you believe this is incorrect, please {contactUsLink} for help.
          </p>
          {renderLink()}
        </div>
      </div>
    );

    return renderWithContainer(component);
  };

  const renderContactUsError = () => {
    const contactUsLink = <a href="/contact">contact us</a>;

    return renderWithContainer(
      <ApiErrorMessage
        data-test="top-products-api-error-message"
        entityName="Top Products"
        className="top-products__error-message"
        customMessage={<div>Please {contactUsLink} for help.</div>}
      />
    );
  };

  const renderTable = (topProductsResponse) => {
    const component = (
      <div>
        <MiniCart data-test="top-products-mini-cart" />
        {showCartFailedModal ? (
          <RequestInfoModal
            closeModalFunc={() => setShowCartFailedModal(false)}
            selectedProductShelf="error"
            cropConsultant={selectedAccount.cropConsultant}
          />
        ) : null}
        {renderTitle()}
        <TopProductsTable
          topProducts={topProductsResponse}
          gtmViewTopProductSDS={gtmViewTopProductSDS}
          selectedAccount={selectedAccount}
        />
        {renderLink()}
      </div>
    );
    return topProductsResponse.list.length
      ? renderWithContainer(component)
      : null;
  };

  const { status } = topProducts;
  switch (status) {
    case 'LOADING':
      return <MaterialLoadingIcon data-test="top-products-loading-icon" />;
    case 'OK':
      return renderTable(topProducts);
    case 'EMPTY':
      return renderNoProductsError();
    default:
      return renderContactUsError();
  }
};

TopProducts.propTypes = {
  endDate: string.isRequired,
  fetchTopProducts: func.isRequired,
  gtmViewPastTopProductPurchases: func.isRequired,
  gtmViewTopProductSDS: func.isRequired,
  topProducts: TopProductsPropType.isRequired,
  startDate: string.isRequired,
};

export default TopProducts;
