import get from 'lodash/get';
import { verifySAPAccount } from '../../../../utils/verifySAP';
import ecommApiService from '../../utils/ecommApiService';
import { getProductIds, injectPricesIntoProducts } from '../../utils/pricing';

import cxhApiAdapter from '../../../../shared/lib/cxhApiService';

import * as constants from '../constants';

import productListTransformer from '../../../../utils/transformers/productListTransformer';
// import { getContractedProducts } from '../../actions/contractedProductsActions/contractedProductsActions';

const {
  LOADING_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_MORE_PRODUCTS_SUCCESS,
  LOADING_MORE_PRODUCTS,
  GET_MORE_PRODUCTS_FAILURE,
  SET_NEW_CATEGORY,
  SET_NEW_SUB_CATEGORY,
  GET_PRICES_FOR_PRODUCTS_SUCCESS,
  LOADING_PRICES_FOR_PRODUCTS,
  GET_PRICES_FOR_PRODUCTS_FAILURE,
  UPDATE_SHOW_PRODUCT_MODAL,
  DISABLE_SHOW_PRODUCT_MODAL,
  SET_PRODUCT_FILTERS,
  GET_RECENT_PRODUCTS_SUCCESS,
  GET_RECENT_PRODUCTS_FAILURE,
  GET_PRICES_FOR_RECENT_PRODUCTS_SUCCESS,
  GET_PRICES_FOR_RECENT_PRODUCTS_FAILURE,
  LOADING_PRICES_FOR_RECENT_PRODUCTS,
} = constants;

export const loadingProducts = (loading) => ({
  type: LOADING_PRODUCTS,
  error: undefined,
  loading,
});

export const loadingMoreProducts = (loadingMore) => ({
  type: LOADING_MORE_PRODUCTS,
  loadingMore,
});

export const getProductsSuccess = (data, meta) => ({
  type: GET_PRODUCTS_SUCCESS,
  loading: false,
  error: null,
  data,
  meta,
});

export const getRecentProductsSuccess = (data, meta) => ({
  type: GET_RECENT_PRODUCTS_SUCCESS,
  loading: false,
  error: null,
  data,
  meta,
});

export const getMoreProductsSuccess = (data) => ({
  type: GET_MORE_PRODUCTS_SUCCESS,
  loading: false,
  data,
});

export const getRecentProductsFailure = (error) => {
  const err = get(
    error,
    'message',
    'There was an error retrieving recent products'
  );

  return {
    type: GET_RECENT_PRODUCTS_FAILURE,
    loading: false,
    error: err,
  };
};

export const getProductsFailure = (error) => {
  const err = get(error, 'message', 'There was an error retrieving products');

  return {
    type: GET_PRODUCTS_FAILURE,
    loading: false,
    error: err,
  };
};

export const getMoreProductsFailure = (error) => {
  const err = get(error, 'message', 'There was an error retrieving products');
  return {
    type: GET_MORE_PRODUCTS_FAILURE,
    loading: false,
    getMoreProductsError: err,
    loadingMore: false,
  };
};

export const getPricesForRecentProductsSuccess = (data, append) => ({
  type: GET_PRICES_FOR_RECENT_PRODUCTS_SUCCESS,
  loadingPrices: false,
  getPricesError: false,
  data,
  append,
});

export const getPricesForRecentProductsFailure = () => ({
  type: GET_PRICES_FOR_RECENT_PRODUCTS_FAILURE,
  loadingPrices: false,
  getPricesError: true,
});

export const getPricesForProductsSuccess = (data, append) => ({
  type: GET_PRICES_FOR_PRODUCTS_SUCCESS,
  loadingPrices: false,
  getPricesError: false,
  data,
  append,
});

export const loadingPricesForProducts = (bool) => ({
  type: LOADING_PRICES_FOR_PRODUCTS,
  loadingPrices: bool,
});

export const loadingPricesRecentForProducts = (bool) => ({
  type: LOADING_PRICES_FOR_RECENT_PRODUCTS,
  loadingPrices: bool,
});

export const getPricesForProductsFailure = () => ({
  type: GET_PRICES_FOR_PRODUCTS_FAILURE,
  loadingPrices: false,
  getPricesError: true,
});

export const getPricesForProducts = (
  account,
  products,
  append,
  hidePrice,
  isRecent = false
) => (dispatch) => {
  // eslint-disable-next-line no-param-reassign
  account = account || {};

  if (isRecent) {
    dispatch(loadingPricesRecentForProducts(true));
  } else {
    dispatch(loadingPricesForProducts(true));
  }

  const PRICING_URL = 'v2/product-prices';
  const payload = {
    accountId: account.id,
    locationId: account.branchId,
    productId: getProductIds(products),
  };
  const headers = {
    headers: {
      'account-id': account.id || '',
      'location-id': account.branchId || '',
    },
  };
  return ecommApiService
    .post(PRICING_URL, payload, headers)
    .then((response) => {
      if (hidePrice) {
        if (isRecent) {
          dispatch(getPricesForRecentProductsFailure());
        } else {
          dispatch(getPricesForProductsFailure());
        }
      } else {
        const productsWithPrices = injectPricesIntoProducts(
          account.locationCode,
          products,
          response.data.data
        );
        if (isRecent) {
          dispatch(
            getPricesForRecentProductsSuccess(productsWithPrices, append)
          );
        } else {
          dispatch(getPricesForProductsSuccess(productsWithPrices, append));
        }
      }
    })
    .catch(() => {
      if (isRecent) {
        dispatch(getPricesForRecentProductsFailure());
      } else {
        dispatch(getPricesForProductsFailure());
      }
    });
};

const buildProductsURL = (
  terms,
  account,
  crossSellIds,
  getCrossSells,
  flags
) => {
  const { localizedCatalog, localizedCatalogUs } = flags;
  const prefix = terms ? `${terms}&` : '?';
  const addCrossSells = getCrossSells
    ? `&filters[productId]=${crossSellIds}`
    : '';
  const addLocalizedCatalog =
    verifySAPAccount(account.id) ||
    (account.locationCode === 'CAN' && localizedCatalog) ||
    (account.locationCode === 'USA' && localizedCatalogUs)
      ? `&filters[branchIds]=${account.branchId}`
      : '';

  const fields = [
    'id',
    'name',
    'crossSells',
    'commonName',
    'description',
    'rank',
    'image',
    'enabled',
    'federallyRestricted',
    'logo',
    'logoMobile',
    'categories',
    'productRestrictedStates',
    'associatedCountry',
  ].join(',');

  const include = ['variant', 'manufacturer', 'activeIngredient'].join(',');

  const variant = [
    'name',
    'status',
    'manufacturers',
    'id',
    'agrianId',
    'productId',
    'description',
    'position',
    'image',
    'enabled',
    'unitQty',
    'unitSize',
    'unitMeasure',
    'totalVolumeOptions',
    'isBulk',
    'digitalVariantName',
  ].join(',');

  const url = [
    `/v2/products${prefix}`,
    `fields[product]=${fields}`,
    `&include=${include}&fields[variant]=${variant}`,
    `${addCrossSells}`,
    `${addLocalizedCatalog}`,
    `&filters[enabled]=true`,
    `&filters[status]=1`,
    `&metaFilters[type]=manufacturer,fertilizer,chemical,activeIngredient`,
  ].join('');

  return url;
};

export const getProducts = (
  terms,
  account = { id: '', branchId: '', locationCode: '' },
  append,
  getCrossSells,
  crossSellIds,
  flags = { localizedCatalog: false, localizedCatalogUs: false }
) => (dispatch) => {
  const hidePrice = flags.pricingPercentVariation.uponFulfillment;
  const url = buildProductsURL(
    terms,
    account,
    getCrossSells,
    crossSellIds,
    flags
  );
  if (append) {
    dispatch(loadingMoreProducts(true));
  } else {
    dispatch(loadingProducts(true));
  }

  return ecommApiService
    .get(url, {
      transformRequest: [
        (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          headers['account-id'] = account.id || '';
          // eslint-disable-next-line no-param-reassign
          headers['location-id'] = account.branchId || '';

          return data;
        },
      ],
    })
    .then((res) => {
      const product = productListTransformer(res);
      const metaData = res.data.meta;
      // getContractedProducts isn't prod ready
      // if (account.id.length === 8) {
      //   dispatch(getContractedProducts(account.number, '', product));
      // }

      if (append) {
        // If you're loading more products
        // and want to append this response
        // to previous list
        dispatch(getMoreProductsSuccess(product.data, metaData));
        dispatch(getPricesForProducts(account, product.data, true, hidePrice));
      } else {
        dispatch(getProductsSuccess(product.data, metaData));
        dispatch(getPricesForProducts(account, product.data, false, hidePrice));
      }
      return product;
    })
    .catch((error) => {
      if (append) {
        dispatch(getMoreProductsFailure(error));
      } else {
        dispatch(getProductsFailure(error));
      }
      return error;
    });
};

export const getRecentProducts = (
  terms,
  account = { id: '', branchId: '', locationCode: '' },
  append,
  getCrossSells,
  crossSellIds,
  flags
) => (dispatch) => {
  const hidePrice = flags.pricingPercentVariation.uponFulfillment;
  const url = buildProductsURL(
    terms,
    account,
    append,
    getCrossSells,
    crossSellIds,
    flags
  );

  return ecommApiService
    .get(url, {
      transformRequest: [
        (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          headers['account-id'] = account.id || '';
          // eslint-disable-next-line no-param-reassign
          headers['location-id'] = account.branchId || '';

          return data;
        },
      ],
    })
    .then((res) => {
      const recentProducts = productListTransformer(res);
      const metaData = res.data.meta;
      dispatch(getRecentProductsSuccess(recentProducts.data, metaData));
      dispatch(
        getPricesForProducts(
          account,
          recentProducts.data,
          false,
          hidePrice,
          true
        )
      );
      return recentProducts;
    })
    .catch((error) => {
      dispatch(getRecentProductsFailure(error));
      return error;
    });
};

export const setNewCategory = (category) => (dispatch) => {
  dispatch({
    type: SET_NEW_CATEGORY,
    category,
  });
};

export const setNewSubCategory = (subCategory) => (dispatch) => {
  dispatch({
    type: SET_NEW_SUB_CATEGORY,
    subCategory,
  });
};

export const updateShowProductModal = (showModal) => (dispatch) => {
  cxhApiAdapter
    .put('v1/productmodal', { showProductModal: showModal })
    .then((res) => {
      dispatch({
        type: UPDATE_SHOW_PRODUCT_MODAL,
        data: res.data,
      });
      return res;
    })
    .catch((error) => error);
};

export const disableShowProductModal = () => (dispatch) => {
  dispatch({
    type: DISABLE_SHOW_PRODUCT_MODAL,
    data: false,
  });
};

export const setProductFilters = (filterType, values) => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_FILTERS,
    filterType,
    values,
  });
};
