/**
 * Given an array of associated Orgs, each of which contains an array of associated numbers,
 * get the list of all account numbers
 */
export default (associatedOrgs) => {
  // the backend uses the accounts to grab the organization,
  // therefore, it's not necessary to return multiple accoutns from the same org,
  // just grab the first
  const reduceFunction = (accumulator, currentArr) => {
    accumulator.push(currentArr[0]);
    return accumulator;
  };

  // if no associatedOrgs, iterate over an empty array,
  // and if an org has no accounts, return an empty array
  const associatedAccounts = (associatedOrgs || []).map(
    (org) => org.accounts || []
  );
  const selectedAccounts = associatedAccounts.reduce(reduceFunction, []);
  const accountNumbers = selectedAccounts
    // filter out any undefined values
    .filter((account) => account && account.number)
    .map((account) => account.number);
  return accountNumbers;
};
