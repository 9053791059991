import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import numeral from 'numeral';
import { checkTotalInput } from 'utils/checkTotalInput';
import { verifyUsePackageUom } from 'utils/verifyUsePackageUom';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import { isEmployee } from 'shared/utils/tokenUtils/isEmployee';
import { volumeOrQuantity } from 'shared/utils/VolumeOrQuantity/volumeOrQuantity';
import variantOptionName from 'components/Shop/utils/variantOptionName';
import withLocationCode from 'shared/utils/CountryCode/withLocationCode';
import unitMeasuresLong from 'components/Shop/utils/unitMeasuresLong';
import NetPricingFeatureFlag from '../../../../shared/ui/NetPricingFeatureFlag';
import NetPriceComponent from '../../../../shared/ui/TotalNetPricingFeatureFlag/NetPriceComponent';
import { NO_PRICE_CART_MESSAGE } from '../../../../shared/constants/ecommMessageText';
import totalPriceCalculator from '../../utils/TotalPriceCalculator/totalPriceCalculator';
import adjustToFixed from '../../utils/AdjustToFixed/adjustToFixed';
import ProductTotalCountTextbox from '../../../Shop/containers/ProductTotalCountTextboxContainer';
import ProductTotalVolumeDropdown from '../../../Shop/containers/ProductTotalVolumeDropdownContainer';
import ProductTotalVolumeTextbox from '../../../Shop/containers/ProductTotalVolumeTextboxContainer';
import NoPricePopper from '../../../../shared/ui/NoPricePopper';
import validateSapUnitOfMeasure from '../../../Shop/utils/validateSapUnitOfMeasure';

import './CartItem.scss';

export class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      totalPrice: '',
      undoProductRemoval: false,
      undoTimeLimit: 7000,
      value: this.props.cartItem.total_volume || 1,
    };
  }

  componentDidMount() {
    const {
      cartItem,
      cartItem: { total_volume: totalVolume },
    } = this.props;

    this.itemsToBeDeletedQueue = {};
    this.props.removeProduct((oldArray) => [...oldArray, false]);

    let totalPrice;
    let pricePer = get(cartItem, 'variant.price', 0);
    if (pricePer) {
      pricePer = adjustToFixed(pricePer);
      totalPrice = totalPriceCalculator(pricePer, totalVolume);
    }

    this.validate(totalVolume);

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      totalPrice,
      value: totalVolume,
    });
  }

  componentWillUnmount() {
    this.triggerCartItemDeletion();
    this.props.removeProduct((oldArray) => {
      oldArray.pop();
      return [...oldArray];
    });
  }

  validate = (value) => {
    const { cartItem, selectedAccount, isCanada, validateInput } = this.props;

    const usesPackageUom = verifyUsePackageUom(selectedAccount);
    const validationResult = checkTotalInput(
      isCanada,
      usesPackageUom,
      cartItem.variant,
      value
    );

    validateInput(cartItem.id, validationResult);

    return validationResult;
  };

  triggerCartItemDeletion() {
    if (this.timeoutId) {
      const { deleteCartItem } = this.props;
      const deleteQueue = this.itemsToBeDeletedQueue;
      const deletionKeys = Object.keys(deleteQueue);

      clearTimeout(this.timeoutId);
      deletionKeys.forEach((deleteKey) => {
        deleteCartItem(deleteQueue[deleteKey], true);
      });
    }
  }

  calculatePrice = (volume, previousVolume, update) => {
    let totalPrice;
    let pricePer = get(this.props, 'cartItem.variant.price', 0);
    if (pricePer) {
      pricePer = adjustToFixed(pricePer);
    }

    if (pricePer !== 0) {
      totalPrice = pricePer * volume;
      totalPrice = adjustToFixed(totalPrice);

      this.setState({ totalPrice });
      if (update) {
        this.updateCartData(volume, previousVolume);
      }
    }
  };

  handleChange = (newValue, update) => {
    if (!this.state.editing) {
      this.setState({ previousValue: this.state.value, editing: true });
    }

    this.validate(newValue);

    this.setState(
      {
        value: newValue,
      },
      () => this.calculatePrice(newValue, this.state.previousValue, update)
    );
  };

  handleDeleteItem = (cartItem) => {
    const {
      cartItems: {
        // eslint-disable-next-line react/prop-types
        updatingCart: { updating },
      },
      removeProduct,
    } = this.props;
    const { undoProductRemoval } = this.state;
    const employee = isEmployee();

    this.setState({ undoProductRemoval: true });
    removeProduct((oldArray) => [...oldArray, true]);

    if (!updating && !employee && !undoProductRemoval) {
      this.deleteItemCountdown(cartItem);
    }
  };

  deleteItemCountdown = (cartItem) => {
    const { deleteCartItem } = this.props;
    const { undoTimeLimit } = this.state;
    this.itemsToBeDeletedQueue[cartItem.id] = cartItem;

    this.timeoutId = setTimeout(() => {
      deleteCartItem(cartItem);
      delete this.itemsToBeDeletedQueue[cartItem.id];
    }, undoTimeLimit);
  };

  updateCartData = (volume, previousVolume) => {
    // const { account_id: accountID, variant_sku } = this.props.cartItem.product.attributes; // eslint-disable-line
    const { cartItem, userId } = this.props;
    // manually set "previous volume" on the itemPreUpdate, in case
    // there are other http requests to update the cartItem volume that
    // haven't finished yet. This is necessary when the user types a new
    // cartItem volume too quickly.
    const itemPreUpdate = {
      ...cloneDeep(this.props.cartItem),
      total_volume: previousVolume,
    };

    const newItemData = {
      totalVolume: volume,
      variantId: cartItem.variant.id,
      accountId: cartItem.product.attributes.account_id,
      branchId: cartItem.product.attributes.branch_id,
      netPrice: cartItem.variant.net_price,
      unitOfMeasure: cartItem.variant.unit_measure,
      unitPrice: cartItem.variant.price,
      userId,
      priceType: cartItem.variant.price_type || undefined,
      cartId: cartItem.id,
    };

    if (volume) {
      this.props.updateCart(newItemData, itemPreUpdate, {
        origin: 'Edit Cart Quantity',
      });
    }
  };

  handleBlur = (newValue) => {
    this.setState({ editing: false });

    this.validate(newValue);

    this.setState(
      {
        value: newValue,
      },
      () =>
        this.calculatePrice(
          newValue,
          this.state.previousValue || this.state.value,
          true
        )
    );
  };

  restoreProductToCart = (cartItemId) => {
    const { removeProduct } = this.props;
    delete this.itemsToBeDeletedQueue[cartItemId];
    clearTimeout(this.timeoutId);
    removeProduct((oldArray) => {
      oldArray.pop();
      return [...oldArray];
    });
    this.setState({ undoProductRemoval: false });
  };

  renderTotalInputCell = (volumeItem) => {
    const { cartItem, isCanada, selectedAccount } = this.props;
    const isBulk = get(cartItem, 'variant.is_bulk', false);
    const uom = get(cartItem, 'variant.unit_measure', '');
    const usesPackageUom = verifyUsePackageUom(selectedAccount);
    const { value } = this.state;

    const { hasError, errorMessage } = checkTotalInput(
      isCanada,
      usesPackageUom,
      cartItem.variant,
      value
    );

    return (
      <td className="cart-item__table-data">
        <label className="cart-item__header--mobile" htmlFor={volumeItem}>
          {volumeOrQuantity(isCanada, usesPackageUom)}
        </label>
        <DisabledForEmployees>
          <div className="cart-item__input-container">
            <span className="cart-item__input" data-test="cart-item-input">
              {
                // eslint-disable-next-line no-nested-ternary
                isBulk ? (
                  <ProductTotalVolumeTextbox
                    data-test="cart-item-total-volume-textbox"
                    value={value}
                    variant={cartItem.variant}
                    modifiesCartItem
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                ) : usesPackageUom ? (
                  <ProductTotalCountTextbox
                    data-test="cart-item-total-count-textbox"
                    value={value}
                    modifiesCartItem
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                  />
                ) : (
                  <ProductTotalVolumeDropdown
                    data-test="cart-item-total-volume-dropdown"
                    value={value}
                    variant={cartItem.variant}
                    onChange={(newValue) => this.handleChange(newValue, true)}
                  />
                )
              }
            </span>
            {hasError ? (
              <span
                className="cart-item__invalid-input-warning"
                data-test="cart-item-input-error"
              >
                {errorMessage}
              </span>
            ) : (
              <span
                data-test="cart-item-unit-of-measure"
                className="cart-item__unit-of-measure"
              >
                {validateSapUnitOfMeasure(uom)}
              </span>
            )}
          </div>
        </DisabledForEmployees>
      </td>
    );
  };

  renderProductRemovalUndo = () => {
    const { cartItem } = this.props;
    const productName = get(cartItem, 'product.attributes.name', '');
    return (
      <tr
        className="cart-item__table-row--undo-remove fade-out"
        key={cartItem.id}
      >
        <td className="cart-item__table-data--expanded-text" colSpan="5">
          <p>{productName} has been removed from the Cart</p>
        </td>
        <td className="cart-item__undo-wrapper">
          <DisabledForEmployees>
            <Button
              autoFocus
              className="cart-item__inner-table-data"
              data-test="restore-product-to-cart"
              onClick={() => this.restoreProductToCart(cartItem.id)}
              variant="text"
            >
              UNDO
            </Button>
          </DisabledForEmployees>
        </td>
      </tr>
    );
  };

  render() {
    const {
      cartItem,
      isCanada,
      flags: { cartProductLink, displayNetPricing },
    } = this.props;
    const { undoProductRemoval } = this.state;
    const uom = get(cartItem, 'variant.unit_measure', '');
    const productName = get(cartItem, 'product.attributes.name', '');
    const volumeItem = `${productName}Volume`;
    let pricePer = get(cartItem, 'variant.price', '');
    const hrefId = get(cartItem, 'product.id', '');

    const totalNetPrice = cartItem.variant.net_price * cartItem.total_volume;
    const cartItemRowClasses = classNames('cart-item__table-row', {
      hidden: undoProductRemoval,
    });

    if (pricePer) {
      pricePer = adjustToFixed(pricePer);
      pricePer = pricePer > 0 ? numeral(`${pricePer}`).format('$0,0.00') : null;
    }

    Object.assign(cartItem, { totalPrice: this.state.totalPrice });

    if (undoProductRemoval) {
      return this.renderProductRemovalUndo();
    }

    return (
      <tr className={cartItemRowClasses} key={cartItem.id}>
        <td className="cart-item__table-data--full">
          <span className="cart-item__header--mobile">Product Name</span>
          <span
            className="cart-item__text--highlight"
            data-test="cart-item-name"
          >
            {cartProductLink ? (
              <Button href={`/products/${hrefId}`}>{productName}</Button>
            ) : (
              <>{productName}</>
            )}
          </span>
        </td>
        <td className="cart-item__table-data">
          <span className="cart-item__header--mobile">Pack Size</span>
          <span data-test="cart-item-pack-size">
            {variantOptionName(get(cartItem, 'variant'), isCanada)}
          </span>
        </td>
        {this.renderTotalInputCell(volumeItem)}
        <td className="cart-item__table-data">
          <span className="cart-item__header--mobile">Price</span>
          <div data-test="mobile-unit-price">
            {pricePer ? (
              NetPricingFeatureFlag(
                cartItem.variant,
                ` per ${unitMeasuresLong[uom]}`,
                displayNetPricing
              )
            ) : (
              <div>
                Price confirmed prior to{' '}
                <span className="cart-item__no-price-message-span">
                  fulfillment <NoPricePopper location="Cart" />
                </span>
              </div>
            )}
          </div>
        </td>
        <td className="cart-item__table-data">
          <span className="cart-item__header--mobile">Total Price</span>
          <div className="cart-item__numeric-data" data-test="total-price">
            {pricePer ? (
              NetPriceComponent(totalNetPrice)
            ) : (
              <span className="cart-item__unavailable">
                {NO_PRICE_CART_MESSAGE}
              </span>
            )}
          </div>
        </td>
        <td className="cart-item__table-data--wide cart-item__close-wrapper">
          <div className="cart-item__inner-table-data--wide">
            <span className="cart-item__header--mobile-slim">Remove</span>
            <span className="cart-item__numeric-data">
              <DisabledForEmployees>
                <Delete
                  className="cart-item__close-icon"
                  style={{ color: '#a0a0a0' }}
                  onClick={() => this.handleDeleteItem(cartItem)}
                />
              </DisabledForEmployees>
            </span>
          </div>
        </td>
      </tr>
    );
  }
}

CartItem.propTypes = {
  cartItems: PropTypes.shape({
    updatingCart: PropTypes.shape({
      name: PropTypes.string,
      updating: PropTypes.bool,
    }),
  }).isRequired,
  deleteCartItem: PropTypes.func.isRequired,
  cartItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    variant_sku: PropTypes.string,
    account_id: PropTypes.string,
    product: PropTypes.shape({
      attributes: PropTypes.shape({
        account_id: PropTypes.string,
        branch_id: PropTypes.string,
      }),
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    updatingCart: PropTypes.bool,
    total_volume: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    variant: PropTypes.shape({
      net_price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      price: PropTypes.string,
      price_type: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      is_bulk: PropTypes.bool,
      unit_measure: PropTypes.string,
      unit_qty: PropTypes.number,
      unit_size: PropTypes.number,
    }),
  }),
  removeProduct: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  updatingCart: PropTypes.func,
  validateInput: PropTypes.func.isRequired,
  isCanada: PropTypes.bool.isRequired,
  selectedAccount: PropTypes.shape({}).isRequired,
  userId: PropTypes.string.isRequired,
  flags: PropTypes.shape({
    cartProductLink: PropTypes.bool,
    displayNetPricing: PropTypes.bool,
  }),
};

CartItem.defaultProps = {
  cartItem: {
    variant_sku: '',
    account_id: '',
    product: {
      name: '',
    },
    variant: {
      price: '',
      price_type: undefined,
      description: '',
      unit_measure: '',
      unit_qty: 0,
      unit_size: 0,
      net_price: null,
    },
  },
  updatingCart: null,
  flags: { cartProductLink: false, displayNetPricing: false },
};

export default compose(withLocationCode, withLDConsumer())(CartItem);
