export default (theme) => ({
  permitsTable: {
    tableLayout: 'auto',
  },
  warningIconContainer: {
    color: theme.palette.warning.main,
    display: 'table',
    marginRight: 2,
    '& svg': {
      marginLeft: '-.2em',
      paddingTop: 3,
    },
  },
  warningText: {
    color: theme.palette.text.support,
    display: 'table-cell',
    padding: '0 5px',
    verticalAlign: 'middle',
  },
  alertIconContainer: {
    color: theme.palette.error.main,
    display: 'table',
    marginRight: 2,
    '& svg': {
      marginLeft: '-.2em',
      paddingTop: 3,
    },
  },
  displayTable: {
    display: 'table',
  },
  // to be clear, this is for an element that should have display: 'table-cell',
  // not styles applied to <TableCell> elements
  displayTableCell: { display: 'table-cell', verticalAlign: 'middle' },
});

export const selectPermitsFilter = () => ({
  root: {
    width: 120,
    maxHeight: 16,
  },
});

export const tableCellStyles = (theme) => ({
  root: {
    whiteSpace: 'normal',
    color: theme.palette.text.secondary,
  },
});

export const tableHeadCellStyles = {
  ...tableCellStyles,
  root: {
    fontWeight: 600,
  },
};
