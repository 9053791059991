import * as actionTypes from '../../actions/constants';

const initialSearchState = [];

export default (state = initialSearchState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS: {
      const { data } = action;
      return data;
    }
    default:
      return state;
  }
};
