import React from 'react';
import makeStyles from '@nutrien/uet-react/styles/makeStyles';
import { Box, Button, Paper, Typography } from '@nutrien/uet-react';
import DoneIcon from '@nutrien/uet-react/icons/Done';
import { useHistory } from 'react-router-dom';

const EmailVerifiedPopupStyles = makeStyles((theme) => ({
  pageBox: {
    height: '100%',
    backgroundColor: theme.palette.gray[800],
  },

  button: {
    fontWeight: 600,
    fontSize: '16px',
    lineheight: '24px',
    marginTop: 26,
    marginBottom: 40,
  },
  typography: {
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#666666',
    margin: '0px 50px',
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(18),
    minHeight: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'initial',
    },
  },
  paperRoot: {
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  mainBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 130,
  },
  iconDiv: {
    width: 60,
    height: 60,
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.brand.green,
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: 35,
  },
}));

const EmailVerifiedPopup = () => {
  const emailVerifiedPopupStyles = EmailVerifiedPopupStyles();
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={emailVerifiedPopupStyles.pageBox}
    >
      <Box className={emailVerifiedPopupStyles.mainContent}>
        <Box width={391}>
          <Paper
            classes={{ root: emailVerifiedPopupStyles.paperRoot }}
            elevation={2}
          >
            <Box padding={3} className={emailVerifiedPopupStyles.mainBox}>
              <div className={emailVerifiedPopupStyles.iconDiv}>
                <DoneIcon className={emailVerifiedPopupStyles.icon} />
              </div>

              <Typography style={{ fontSize: 27 }}>
                Email Verification
              </Typography>

              <Typography className={emailVerifiedPopupStyles.typography}>
                Your email has been verified and you are now a Nutrien One Hub
                user.
              </Typography>
              <Button
                variant="contained"
                className={emailVerifiedPopupStyles.button}
                onClick={() => history.push('/login?nutrien=true')}
              >
                Continue to Nutrien
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerifiedPopup;
