import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'nutrien-common-components-react/dist/mdc';
import get from 'lodash/get';
import { NUTRIEN_COMPANY_NAME } from 'shared/config/constants';

import './CustomApplied.scss';

export class CustomApplied extends Component {
  constructor(props) {
    super(props);
    const value = get(props, 'cart.preferences.applicationMemo', '');

    this.state = {
      value,
    };
  }

  componentDidMount() {
    const {
      setApplicationMemo,
      setCustomApplied,
      cart: { branchAddress },
    } = this.props;
    const isCustomApplicationAvailable = get(
      branchAddress,
      'address.data.allowCustomAppliedOrders',
      true
    );

    // If user changed account to an account where application services are unavailable
    // after setting the customAppliedMemo with another acccount, reset customAppliedMemo.
    if (!isCustomApplicationAvailable) {
      setApplicationMemo(null);
      setCustomApplied(false);
    }
  }

  handleChange = (e) => {
    const { updateCustomAppliedMemo } = this.props;
    this.setState({ value: e.target.value });
    updateCustomAppliedMemo(e.target.value);
  };

  render() {
    const {
      updateCustomApplied,
      selectedAccount,
      cart: { branchAddress, preferences },
    } = this.props;
    const isCustomApplicationAvailable = get(
      branchAddress,
      'address.data.allowCustomAppliedOrders',
      true
    );
    const cropConsultantEmail = get(
      selectedAccount,
      'cropConsultant.workEmailAddress'
    );

    return (
      <div className="custom-applied__wrapper">
        <h2 className="custom-applied__header">Application Services</h2>
        {isCustomApplicationAvailable ? (
          <div>
            <p
              className="custom-applied__description"
              data-test="application-services-help-description"
            >
              Need help applying or using the products you are reserving today?
              Your Nutrien Ag Solutions Representative will help guide you
              through {NUTRIEN_COMPANY_NAME}&#39; many professional application
              services.
            </p>
            <span className="custom-applied__preference_subheader">
              Are you interested in application services?
            </span>
            <div className="application-instructions__header-wrapper">
              <Checkbox
                onChange={(e) => updateCustomApplied(e.target.checked)}
                data-test="custom-applied-checkbox"
                // eslint-disable-next-line react/prop-types
                checked={preferences.isCustomApplied}
                id="isCustomApplied"
              />
              <span className="custom-applied__preference-label">
                Yes, please inform my Nutrien Ag Solutions Representative I am
                interested
              </span>
            </div>
            {/* eslint-disable-next-line react/prop-types */}
            {preferences.isCustomApplied && (
              <>
                <div
                  className="application-instructions__header-wrapper"
                  data-test="application-instructions-wrapper"
                >
                  <h2 className="application-instructions__subheader">
                    Additional Application Instructions
                  </h2>
                  <span className="application-instructions__text">
                    (Optional)
                  </span>
                </div>
                <textarea
                  onChange={(e) => this.handleChange(e)}
                  placeholder="If you have any additional application details you’d like to share with your branch, please include them here. These details might include farm, field, crop, pest, desired application rate or acres being treated."
                  className="custom-applied__text-area"
                  maxLength="450"
                  data-test="application-instructions-text-area"
                  value={this.state.value}
                />
              </>
            )}
          </div>
        ) : (
          <div>
            <p className="application-unavailable__text">
              Application services are not available for this location.
            </p>
            <p className="application-unavailable__instructions">
              If you have any additional questions or application needs, please{' '}
              <a
                href={`mailto:${cropConsultantEmail}?subject=Questions about custom application`}
              >
                <span className="application-unavailable__mail-to">
                  contact your Nutrien Ag Solutions Representative
                </span>
              </a>{' '}
              or branch.
            </p>
          </div>
        )}
      </div>
    );
  }
}

CustomApplied.propTypes = {
  setCustomApplied: PropTypes.func.isRequired,
  setApplicationMemo: PropTypes.func.isRequired,
  updateCustomApplied: PropTypes.func.isRequired,
  selectedAccount: PropTypes.shape({}).isRequired,
  cart: PropTypes.shape({
    isPickup: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    branchAddress: PropTypes.shape({}),
    preferences: PropTypes.shape({}),
  }).isRequired,
  updateCustomAppliedMemo: PropTypes.func.isRequired,
};

export default CustomApplied;
