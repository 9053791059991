import { GTM_VIEW_WEATHER } from '../../../../components/Weather/actionTypes';

import { EVENT_TRACK, CXH_VIEW_WEATHER } from '../gtmConstants';

import {
  MixpanelEvents,
  MixpanelProperties,
} from '../../MixPanel/mixPanelConstants';

export const viewLocalWeather = () => {
  window.mixpanel.track(MixpanelEvents.VIEW_WEATHER);
  window.mixpanel.people.set_once({
    [MixpanelProperties.FIRST_WEATHER_PAGEVIEW]: new Date().toISOString(),
  });
  window.mixpanel.people.set({
    [MixpanelProperties.LAST_WEATHER_PAGEVIEW]: new Date().toISOString(),
  });
  window.mixpanel.people.increment(
    MixpanelProperties.LIFETIME_WEATHER_PAGEVIEW
  );

  return {
    event: EVENT_TRACK,
    eventId: CXH_VIEW_WEATHER,
  };
};

export const weatherEventsMap = {
  [GTM_VIEW_WEATHER]: viewLocalWeather,
};
