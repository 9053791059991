import XarvioLogo from 'assets/Xarvio_Scouting_logo.svg';

const xarvio = {
  headerTitle: 'Xarvio Digital Farming Solutions',
  imgSrc: `${XarvioLogo}`,
  linkUrl: '/partners',
  productBody:
    'More knowledge, better decisions. Our new xarvio™ SCOUTING app makes it easy to detect in-field stress in seconds just by taking a photo.',
  productBodyHeader: 'The Assistant In Your Pocket.',
  productName: 'Xarvio Scouting',
};

const partners = [xarvio];

export default partners;
