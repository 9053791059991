import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  Checkbox,
} from 'nutrien-common-components-react/dist/mdc';
import NutrienLogo from '../Nutrien/NutrienLogo';

import './styles.scss';

class ProductsModal extends Component {
  state = {
    checked: false,
  };

  handleCheckboxChange = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  handleOkButton = (checked) => {
    const {
      updateShowProductModal,
      handleGetStarted,
      disableShowProductModal,
    } = this.props;
    if (checked) {
      updateShowProductModal(false);
    }
    handleGetStarted();
    disableShowProductModal();
  };

  handleModalClose = () => {
    const { modalClose, disableShowProductModal } = this.props;
    modalClose();
    disableShowProductModal();
  };

  render() {
    const { open, modalOpenedThroughButtonClick } = this.props;
    const { checked } = this.state;

    return (
      <Dialog
        className="products-modal__dialog"
        open={open}
        scrollable
        onClosed={this.handleModalClose}
      >
        <DialogContent>
          <section className="products-modal" data-test="product-modal">
            <div className="products-modal__content">
              <div className="products-modal__logo-wrapper">
                <svg className="products-modal__logo">
                  <NutrienLogo />
                </svg>
              </div>
              <div className="products-modal__content--inner">
                <h1 className="products-modal__header">
                  Welcome to Nutrien Ag Solutions&#8482;
                </h1>
                <p className="products-modal__blurb">
                  You may notice that not all items are currently priced. You
                  can add any product - with or without a price - to your Order
                  Request. Prices will be confirmed when your Nutrien Ag
                  Solutions Representative contacts you to review your order.
                  For items that are priced, your Nutrien Ag Solutions
                  Representative will review pricing for accuracy and may
                  contact you for adjustments before the order is placed.
                </p>
              </div>
            </div>
            {!modalOpenedThroughButtonClick && (
              <div className="products-modal__checkbox-wrapper">
                <Checkbox
                  onChange={(e) => this.handleCheckboxChange(e)}
                  checked={checked}
                  data-test="product-modal-checkbox"
                />
                <span className="products-modal__checkbox-wrapper__blurb">
                  Don&#39;t Show This Again
                </span>
              </div>
            )}
          </section>
        </DialogContent>
        <DialogActions className="products-modal__footer">
          <DialogButton
            onClick={() => this.handleOkButton(checked)}
            className="products-modal__button"
            data-test="product-modal-button"
            action="accept"
          >
            {!modalOpenedThroughButtonClick
              ? 'Get Started'
              : 'Continue Shopping'}
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }
}

ProductsModal.propTypes = {
  open: bool.isRequired,
  handleGetStarted: func.isRequired,
  updateShowProductModal: func.isRequired,
  disableShowProductModal: func.isRequired,
  modalClose: func.isRequired,
  modalOpenedThroughButtonClick: bool,
};

ProductsModal.defaultProps = {
  modalOpenedThroughButtonClick: false,
};

export default ProductsModal;
