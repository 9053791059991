import React from 'react';
import get from 'lodash/get';
import { hasManufacturerFunc, getManufacturerClassName } from '../utils';
import {
  productDataPropType,
  variantPropType,
} from '../../ProductDetailPropTypes';

const ProductInfoHeader = ({ productData, variant }) => {
  const commonName = get(productData, 'attributes.common_name', null);
  const hasManufacturer = hasManufacturerFunc(productData);
  const manufacturerClassName = getManufacturerClassName(hasManufacturer);
  return (
    <div className="product-info__header-container">
      <div className="product-info__inner-header-container">
        <span
          className={manufacturerClassName}
          data-test="product-info-product-manufacturer"
        >
          {hasManufacturer
            ? productData.attributes.manufacturer.name
            : 'No Manufacturer'}
        </span>
        <h1 className="product-info__product-name-header">{variant.name}</h1>
        <span
          className="product-info__common-name"
          data-test="product-info-common-name"
        >
          {commonName || ''}
        </span>
      </div>
    </div>
  );
};

ProductInfoHeader.propTypes = {
  productData: productDataPropType.isRequired,
  variant: variantPropType.isRequired,
};

export default ProductInfoHeader;
