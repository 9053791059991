import { creditApplications } from '../services/httpServices';
import {
  GET_CREDIT_APPLICATION,
  GET_CREDIT_APPLICATION_SUCCESS,
  GET_CREDIT_APPLICATION_FAILURE,
  START_CREDIT_APPLICATION,
} from '../const/credit';

const getCreditApplicationSuccess = (data) => ({
  type: GET_CREDIT_APPLICATION_SUCCESS,
  data,
});

const getCreditApplicationFailure = (error) => ({
  type: GET_CREDIT_APPLICATION_FAILURE,
  error,
});

// only used for tracking when a user starts a credit application
// does not change state
export const startCreditApplication = (
  selectedAccount,
  pathName,
  redirectUrl
) => {
  return {
    type: START_CREDIT_APPLICATION,
    payload: { selectedAccount, pathName, redirectUrl },
  };
};

export const getCreditApplications = () => async (dispatch) => {
  dispatch({ type: GET_CREDIT_APPLICATION });
  try {
    const creditAppList = await creditApplications();
    dispatch(getCreditApplicationSuccess(creditAppList.data));
  } catch (error) {
    dispatch(getCreditApplicationFailure(error));
  }
};
