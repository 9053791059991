import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { productActions } from '../../actions/actions';
import ProductsModal from '../../components/ProductsModal/ProductsModal';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...productActions,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ProductsModal);
