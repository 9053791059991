import { LOCATION_CHANGE } from 'connected-react-router';
import {
  ACH_ACCOUNT_TYPES,
  BANK_ACCOUNT_TYPES,
  COUNTRY_US,
  UPDATE_BANK_ACCOUNT_FORM,
  SET_PAYMENT_FORM_ERRORS,
  SET_ACCOUNT_COUNTRY_CODE,
  CLEAR_BANK_ACCOUNT,
  CLEAR_BANK_ACCOUNT_FORM,
  FETCH_BANK_NAME_SUCCESS,
  FETCH_BANK_NAME_FAIL,
  CLEAR_BANK_NAME,
  CLEAR_PAYMENT_STATE,
  SET_SAVED_PAYMENT_METHOD,
  SET_PAYMENT_FLOW_TYPE,
  CLEAR_PAYMENT_FLOW_TYPE,
} from '../../PaymentConstants';
import { addOneYear } from '../../../../shared/utils/DateHelper/addOneYear';

const initialState = {
  form: {
    cpsAccount: null,
    amountEntered: '',
    amountTypeSelected: '',
    notes: '',
    routingNumber: null,
    addressLine1: '',
    addressLine2: '',
    country: COUNTRY_US,
    state: {},
    city: '',
    zip: '',
    phone: '',
    achAccountType: ACH_ACCOUNT_TYPES.CHECKING,
    bankAccountType: BANK_ACCOUNT_TYPES.PERSONAL,
    corporateName: '',
    firstName: '',
    lastName: '',
    savePaymentMethod: false,
    paymentMethodAlias: '',
    savedPaymentMethod: null,
    transitNumber: '',
    institutionNumber: '',
    bankAccountNumber: '',
    confirmBankAccountNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    selectedInvoices: [],
    scheduledPayment: null,
    isRecurring: false,
    recurringEndDate: addOneYear(),
    recurringPaymentFrequency: {},
  },
  formErrors: {
    cpsAccount: [],
    amountEntered: [],
    routingNumber: [],
    bankAccountNumber: [],
    confirmBankAccountNumber: [],
    addressLine1: [],
    addressLine2: [],
    country: [],
    state: [],
    city: [],
    zip: [],
    phone: [],
    corporateName: [],
    firstName: [],
    lastName: [],
    paymentMethodAlias: [],
    transitNumber: [],
    institutionNumber: [],
    accountNumber: [],
    confirmAccountNumber: [],
    scheduledPayment: [],
    recurringEndDate: [],
    recurringPaymentFrequency: [],
  },
  formWarnings: {
    cpsAccount: [],
    amountEntered: [],
    routingNumber: [],
    bankAccountNumber: [],
    confirmBankAccountNumber: [],
    addressLine1: [],
    addressLine2: [],
    country: [],
    state: [],
    city: [],
    zip: [],
    phone: [],
    corporateName: [],
    firstName: [],
    lastName: [],
    paymentMethodAlias: [],
    scheduledPayment: [],
    recurringEndDate: [],
    recurringPaymentFrequency: [],
  },
  flowType: null,
  bankName: null,
  bankNameFail: false,
  accountCountryCode: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BANK_ACCOUNT_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    case SET_PAYMENT_FORM_ERRORS:
      return { ...state, formErrors: { ...action.payload } };
    case CLEAR_BANK_ACCOUNT_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          isRecurring: action.payload
            ? action.payload
            : initialState.form.isRecurring,
        },
      };
    case CLEAR_BANK_ACCOUNT:
      return {
        ...state,
        form: {
          ...state.form,
          bankAccountNumber: '',
          confirmBankAccountNumber: '',
          accountNumber: '',
          confirmAccountNumber: '',
        },
      };
    case SET_ACCOUNT_COUNTRY_CODE:
      return { ...state, accountCountryCode: action.payload };
    case FETCH_BANK_NAME_SUCCESS:
      return { ...state, bankName: action.payload, bankNameFail: false };
    case FETCH_BANK_NAME_FAIL:
      return { ...state, bankNameFail: true };
    case CLEAR_BANK_NAME:
      return { ...state, bankNameFail: false, bankName: null };
    case SET_SAVED_PAYMENT_METHOD:
      return {
        ...state,
        form: {
          ...state.form,
          savedPaymentMethod: action.payload,
        },
      };
    case SET_PAYMENT_FLOW_TYPE:
      return { ...state, flowType: action.payload };
    case CLEAR_PAYMENT_FLOW_TYPE:
      return { ...state, flowType: null };
    case LOCATION_CHANGE: {
      return action.payload.location.pathname.match(
        /(make-a-secure-payment)|(review-payment)|(confirm-payment)|(add-funds-to-prepay)/g
      )
        ? state
        : initialState;
    }
    case CLEAR_PAYMENT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
