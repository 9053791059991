import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import {
  getPreferences as getPreferencesAction,
  updatePreference as updatePreferenceAction,
  WEATHER_STORY_NOTIFICATION_PREFERENCES,
} from './actions';
import { preferences as preferencesPropType } from './preferencesPropTypes';
import { getPreferencesData, getStatus } from './selectors';
import WeatherNotificationsPreferences from './WeatherNotificationsPreferences';

export class WeatherNotificationsPreferencesContainer extends Component {
  static propTypes = {
    getPreferences: PropTypes.func,
    updatePreference: PropTypes.func,
    preferences: preferencesPropType,
    status: PropTypes.bool,
    flags: PropTypes.shape({ weatherNotificationSettings: PropTypes.bool })
      .isRequired,
  };

  static defaultProps = {
    getPreferences: () => {},
    updatePreference: () => {},
    preferences: {},
    status: false,
  };

  state = {
    preferenceChanges: {},
  };

  componentDidMount() {
    this.props.getPreferences();
  }

  handlePreferenceChange = (contactMethodType, formKey, value) => {
    const { preferences, updatePreference } = this.props;
    const { preferenceChanges } = this.state;

    /* Use updated values if present, default to redux */
    const rest =
      formKey in preferenceChanges
        ? preferenceChanges[formKey]
        : preferences[formKey];

    /* Updated preference object to be sent to notification service */
    const changes = {
      notificationType: formKey,
    };
    const newState = {
      preferenceChanges,
    };

    if (contactMethodType === 'phone') {
      changes.smsOptIn = value;
      newState.preferenceChanges[formKey] = {
        ...rest,
        smsOptIn: value,
      };
    } else if (contactMethodType === 'email') {
      changes.emailOptIn = value;
      newState.preferenceChanges[formKey] = {
        ...rest,
        emailOptIn: value,
      };
    } else if (contactMethodType === 'inbox') {
      changes.inboxOptOut = value;
      newState.preferenceChanges[formKey] = {
        ...rest,
        inboxOptOut: value,
      };
    }

    this.setState(newState);

    /* Asyncronously update preference change */
    updatePreference(changes);
  };

  render() {
    const {
      preferences,
      status,
      flags: { weatherNotificationSettings },
    } = this.props;
    const { preferenceChanges } = this.state;

    return (
      <>
        {weatherNotificationSettings && (
          <WeatherNotificationsPreferences
            isLoading={status}
            preferenceSettings={WEATHER_STORY_NOTIFICATION_PREFERENCES}
            preferences={preferences}
            preferenceChanges={preferenceChanges}
            onPreferenceChange={this.handlePreferenceChange}
          />
        )}
      </>
    );
  }
}

export const mapStateToProps = (state) => ({
  preferences: getPreferencesData(state),
  status: getStatus(state),
});

export const mapDispatchToProps = {
  getPreferences: getPreferencesAction,
  updatePreference: updatePreferenceAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(WeatherNotificationsPreferencesContainer);
