import get from 'lodash/get';
import {
  SET_PAYMENT_ERROR,
  CLEAR_PAYMENT_ERROR,
  SET_SHOW_FORM_ERRORS,
  CLEAR_SHOW_FORM_ERRORS,
} from '../../PaymentConstants';
import { paymentFailure } from './BankAccountTransactionActions';

export const ERROR_TYPES = {
  MODAL: 'MODAL',
  BANNER: 'BANNER',
  FORM_ERROR: 'FORM_ERROR',
};

export const ERROR_NAMES = {
  TOKEN_ALREADY_SAVED: 'TOKEN_ALREADY_SAVED',
  DUPLICATE_TRANSACTION: 'DUPLICATE_TRANSACTION',
  TOKEN_LIMIT_REACHED: 'TOKEN_LIMIT_REACHED',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  INVOICE_SELECTION_LIMIT_REACHED: 'INVOICE_SELECTION_LIMIT_REACHED',
};

export const ERROR_STATUSES = {
  400001: 'TOKEN_ALREADY_SAVED',
  400002: 'DUPLICATE_TRANSACTION',
  400003: 'TOKEN_LIMIT_REACHED',
  500: 'INTERNAL_SERVER_ERROR',
  503: 'SERVICE_UNAVAILABLE',
};

const APPLICATION_ERRORS = {
  INVOICE_SELECTION_LIMIT_REACHED: {
    name: 'INVOICE_SELECTION_LIMIT_REACHED',
    message:
      'We are unable to process more than 100 invoice payments per submission',
    errorType: ERROR_TYPES.BANNER,
  },
};

const NETWORK_ERRORS = {
  BAD_REQUEST_ERROR: {
    statusCode: 400,
    name: 'BAD_REQUEST_ERROR',
    message: '',
    errorType: '',
  },
  FORBIDDEN_SOUTHERN_OHIO: {
    statusCode: 403,
    name: 'FORBIDDEN_ERROR',
    message:
      'CXH payments have been temporarily disabled for Southern Ohio users for maintenance. We apologize for the inconvenience.',
    errorType: ERROR_TYPES.MODAL,
  },
  NOT_FOUND_ERROR: {
    statusCode: 404,
    name: 'NOT_FOUND_ERROR',
    message: 'Resource was not found.',
  },
  INTERNAL_SERVER_ERROR: {
    statusCode: 500,
    name: 'INTERNAL_SERVER_ERROR',
    message: 'An error occurred',
    errorType: ERROR_TYPES.MODAL,
  },
  SERVICE_UNAVAILABLE: {
    statusCode: 503,
    name: 'SERVICE_UNAVAILABLE',
    message: '',
    errorType: ERROR_TYPES.MODAL,
  },
  TIMEOUT_ERROR: {
    statusCode: 504,
    name: 'TIMEOUT_ERROR',
    message: 'A Timeout occurred while processing payment',
    errorType: ERROR_TYPES.MODAL,
  },
  TOKEN_ALREADY_SAVED: {
    statusCode: 400001,
    name: 'TOKEN_ALREADY_SAVED',
    message:
      'You’ve already stored this account for future payments. Please add new account information or select the previously added account to proceed with payment.',
    errorType: ERROR_TYPES.BANNER,
  },
  DUPLICATE_TRANSACTION: {
    statusCode: 400002,
    name: 'DUPLICATE_TRANSACTION',
    message: "You've already made a payment for this amount today",
    errorType: ERROR_TYPES.BANNER,
  },
  TOKEN_LIMIT_REACHED: {
    statusCode: 400003,
    name: 'TOKEN_LIMIT_REACHED',
    message: '',
    errorType: ERROR_TYPES.BANNER,
  },
  INVALID_ROUTING_NUMBER: {
    statusCode: 500001,
    name: 'INVALID_ROUTING_NUMBER',
    message: 'Invalid Bank Routing Number',
    errorType: ERROR_TYPES.FORM_ERROR,
  },
};

export const PAYMENT_ERRORS = {
  ...APPLICATION_ERRORS,
  ...NETWORK_ERRORS,
};

export const setShowFormErrorsNotification = () => ({
  type: SET_SHOW_FORM_ERRORS,
});

export const clearShowFormErrorsNotification = () => ({
  type: CLEAR_SHOW_FORM_ERRORS,
});

export const setPaymentError = (errorObject) => ({
  type: SET_PAYMENT_ERROR,
  payload: errorObject,
});

export const clearPaymentError = () => ({ type: CLEAR_PAYMENT_ERROR });

/**
 * Unpacks an axios error response object
 * @param errorResponse
 * @returns {{httpCode: number, errorCode: number, message: *, name: *}}
 */
const unpackErrorResponse = (errorResponse) => {
  const errorData = errorResponse.response ? errorResponse.response.data : {};
  const cxhErrorCode = get(errorData, 'extra.errorDetails[0].cxhErrorCode');
  const errorDetails = get(errorData, 'extra.errorDetails');

  return {
    httpCode: errorData.statusCode,
    errorCode: cxhErrorCode,
    message: errorData.message,
    errorDetails,
  };
};

const getBadRequestError = (error) => {
  const cxhErrorCode = get(error, 'extra.errorDetails[0].cxhErrorCode');
  // API error response in extra details 400001-400003, 50001
  if (cxhErrorCode) {
    return PAYMENT_ERRORS[ERROR_STATUSES[cxhErrorCode]];
  }
  // API error response server status codes 400, 404, 500, 503, 504
  if (error.errorCode) {
    return PAYMENT_ERRORS[ERROR_STATUSES[error.errorCode]];
  }

  // Generic 400 bad request
  return PAYMENT_ERRORS.BAD_REQUEST_ERROR;
};

export const getErrorObject = (errorResponse) => {
  const error = unpackErrorResponse(errorResponse);
  switch (error.httpCode) {
    case 400: {
      return getBadRequestError(error);
    }
    case 403: {
      return PAYMENT_ERRORS.FORBIDDEN_SOUTHERN_OHIO;
    }
    case 404: {
      return PAYMENT_ERRORS.NOT_FOUND_ERROR;
    }
    case 500: {
      return error.errorCode === 500001
        ? PAYMENT_ERRORS.INVALID_ROUTING_NUMBER
        : PAYMENT_ERRORS.INTERNAL_SERVER_ERROR;
    }
    case 503: {
      return PAYMENT_ERRORS.SERVICE_UNAVAILABLE;
    }
    case 504: {
      return PAYMENT_ERRORS.TIMEOUT_ERROR;
    }
    default: {
      return PAYMENT_ERRORS.INTERNAL_SERVER_ERROR;
    }
  }
};

export const handlePaymentFailure = (error, data) => (dispatch) => {
  const errorObject = getErrorObject(error, data);
  dispatch(setPaymentError(errorObject));
  dispatch(paymentFailure());
};
