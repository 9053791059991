import lowerCase from 'lodash/lowerCase';
import { NOTIFICATION_READ_STATUS_TOGGLED } from 'components/Notifications/actions';

export const clickNotification = (action) => ({
  event: 'CXH_ClickNotification',
  cxh: {
    notificationType: lowerCase(action.notificationType),
  },
});

export const notificationsEventsMap = {
  [NOTIFICATION_READ_STATUS_TOGGLED]: clickNotification,
};
