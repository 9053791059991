import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HeaderContentWrapper = ({ children, className, ...rest }) => (
  <div className={classNames('header-content-wrapper', className)} {...rest}>
    {children}
  </div>
);

HeaderContentWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

HeaderContentWrapper.defaultProps = {
  children: null,
  className: '',
};

export default HeaderContentWrapper;
