import moment from 'moment';
import { ACCOUNT_STATUS_ENUM } from 'shared/ui/AccountsTable/actions/AccountActions';
import {
  ACH_ACCOUNT_TYPES,
  BANK_ACCOUNT_TYPES,
  COUNTRY_CAN,
  COUNTRY_CODES,
  COUNTRY_US,
  DELETE_BANK_ACCOUNT_IN_PROGRESS,
  GET_PAYMENT_METHOD_IN_PROGRESS,
  LOADING_DATA_IN_PROGRESS,
  SUBMIT_PAYMENT_IN_PROGRESS,
  UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS,
} from '../PaymentConstants';
import { FETCHING_USER } from '../../../shared/config/userConstants';

export const buildClassName = (field, initialClass, errorState, errorClass) => {
  const fieldErrors = errorState[field];

  return fieldErrors && fieldErrors.length
    ? `${initialClass} ${errorClass}`
    : initialClass;
};

export const formatBankAccountType = ({ achAccountType, bankAccountType }) => {
  let formattedBankAccountType;

  if (bankAccountType === BANK_ACCOUNT_TYPES.PERSONAL) {
    formattedBankAccountType = achAccountType;
  } else {
    formattedBankAccountType =
      achAccountType === ACH_ACCOUNT_TYPES.CHECKING
        ? ACH_ACCOUNT_TYPES.CORPORATE
        : ACH_ACCOUNT_TYPES.CORPORATE_SAVINGS;
  }

  return formattedBankAccountType;
};

export const formatToUnits = (number, precision) => {
  const abbrev = ['', 'k', 'm', 'b', 't'];
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
  const suffix = abbrev[order];
  return (number / 10 ** (order * 3)).toFixed(precision) + suffix;
};

export const getAccountCountryCode = (accounts) => {
  let countryCode = '';
  let hasMixedAccounts = false;

  if (accounts && accounts.length) {
    const firstAccountsCode = accounts[0].locationCode;

    accounts.forEach((account) => {
      if (firstAccountsCode !== account.locationCode) {
        hasMixedAccounts = true;
      }
    });

    countryCode = hasMixedAccounts ? '' : accounts[0].locationCode;
  }

  return countryCode;
};

export const getCountryFromCode = (countryCode) => {
  let code = COUNTRY_US;

  if (countryCode === COUNTRY_CODES.USA) {
    code = COUNTRY_US;
  } else if (countryCode === COUNTRY_CODES.CANADA) {
    code = COUNTRY_CAN;
  }

  return code;
};

export const getPaymentProcessingText = (currentDate) => {
  const endDate = moment().endOf('month').format('MM/DD/YYYY');

  return endDate === currentDate
    ? 'Since it is the last day of the month, your payment may take 4-9 business days to fully process.'
    : 'It may take up to 7 business days to see the funds pulled from your account.';
};

export const overPaymentAccountLimit = (paymentAccounts) => {
  let overLimit = false;
  if (paymentAccounts.length) {
    const orgIds = paymentAccounts.reduce(
      (list, account) => ({ [account.organizationId]: 0 }),
      {}
    );
    const counts = paymentAccounts.reduce((orgs, c) => {
      const name = c.organizationId;
      /* eslint no-param-reassign: ["error", { "props": false }] */
      orgs[name] += 1;
      return orgs;
    }, orgIds);
    overLimit = Object.keys(counts).find((key) => counts[key] >= 15);
  }
  return overLimit;
};

/**
 * A utility function that checks multiple different status properties in the store and returns true if any of them are in progress,
 * i.e meaning a network call is pending
 * @param state - The current redux store state
 * @returns {boolean} - A boolean indicating if anything is loading or not.
 */
export const isLoading = (state) => {
  const {
    bankAccountReducer: bankAccount,
    bankAccountTransactionReducer: bankAccountTransaction,
    errorReducer: errors,
    sharedReducer: shared,
  } = state.payments;

  return (
    !errors.paymentError &&
    (state.user.status === FETCHING_USER ||
      shared.loadingStatus === LOADING_DATA_IN_PROGRESS ||
      state.accounts.status === ACCOUNT_STATUS_ENUM.FETCHING ||
      bankAccountTransaction.paymentStatus === SUBMIT_PAYMENT_IN_PROGRESS ||
      bankAccount.getPaymentMethodStatus === GET_PAYMENT_METHOD_IN_PROGRESS ||
      bankAccount.deleteTokenStatus === DELETE_BANK_ACCOUNT_IN_PROGRESS ||
      bankAccount.bankAccountAliasStatus ===
        UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS)
  );
};
