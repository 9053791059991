import get from 'lodash/get';
import ecommApiService from '../../utils/ecommApiService';
import * as constants from '../constants';
import findContractedProducts from '../../utils/addContractedProducts';

const {
  GET_CONTRACTED_PRODUCTS_SUCCESS,
  GET_CONTRACTED_PRODUCTS_FAILURE,
  LOADING_CONTRACTED_PRODUCTS,
} = constants;

export const loadingContractedProducts = (loading) => ({
  type: LOADING_CONTRACTED_PRODUCTS,
  loading,
});

export const getContractedProductsSuccess = (data) => ({
  type: GET_CONTRACTED_PRODUCTS_SUCCESS,
  loading: false,
  data,
});

export const getContractedProductsFailure = (error) => {
  const err = get(
    error,
    'message',
    'There was an error retrieving contracted products'
  );
  return {
    type: GET_CONTRACTED_PRODUCTS_FAILURE,
    loading: false,
    error: err,
  };
};

export const getContractedProducts = (accountId, contractNumber, product) => (
  dispatch
) => {
  dispatch(loadingContractedProducts(true));
  return ecommApiService
    .get(
      `/v2/accounts/${accountId}/contractedProducts/${contractNumber || ''}`,
      {
        headers: {
          'account-id': `${accountId}`,
        },
      }
    )
    .then((res) => {
      if (product) {
        findContractedProducts(product.data, res.data);
      }
      dispatch(getContractedProductsSuccess(res.data));
      return res;
    })
    .catch((error) => {
      dispatch(getContractedProductsFailure(error));
    });
};
