import React from 'react';
import {
  Cell,
  Grid,
  Icon,
  Row,
} from 'nutrien-common-components-react/dist/mdc';
import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Button,
  Box,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import partnersList from './FeaturedPartnersList';

import './FeaturedPartner.scss';

const FeaturedPartner = ({
  isProminent,
  partners,
  gtmClickFeaturedPartnerLearnMore,
}) => {
  const renderCard = (partnerProp) => (
    <Card
      className={`${
        isProminent
          ? 'featured-partner__card--prominent'
          : 'featured-partner__card--standard'
      }`}
      key={partnerProp.productName}
    >
      <CardHeader title={partnerProp.headerTitle} component="header" />

      <Link onClick={gtmClickFeaturedPartnerLearnMore} to={partnerProp.linkUrl}>
        <CardMedia
          component={Box}
          className="featured-partner__card-media"
          css={{
            height: '9rem',
            backgroundSize: '50% !important',
            maxWidth: '200px',
            margin: '0 auto',
          }}
          image={partnerProp.imgSrc}
          title={`${partnerProp.productName} Logo`}
        />

        <CardContent>
          <Typography variant="h5" gutterBottom>
            {partnerProp.productBodyHeader}
          </Typography>
          <Typography component="p">{partnerProp.productBody}</Typography>
        </CardContent>

        <div className="featured-partner__card-footer">
          <Button variant="text" data-test="featured-partner__learn-more-link">
            Learn More
            <Icon icon="chevron_right" />
          </Button>
        </div>
      </Link>
    </Card>
  );

  const renderFeaturedPartners = () => {
    if (partners.length > 0) {
      if (isProminent) {
        const partnerProminent = partners[0];

        return renderCard(partnerProminent);
      }

      return (
        <Row>
          <Cell desktopColumns={3} phoneColumns={4} tabletColumns={8}>
            {partners.map((partner) => renderCard(partner))}
          </Cell>
        </Row>
      );
    }

    return (
      <section>
        <p> No featured partners to display</p>
      </section>
    );
  };

  return !isProminent ? (
    <Grid className="featured-partner__container">
      <header>
        <h3 className="featured-partner__header">Featured Partners</h3>
      </header>
      {renderFeaturedPartners()}
    </Grid>
  ) : (
    renderFeaturedPartners()
  );
};

FeaturedPartner.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      headerTitle: PropTypes.string,
      imgSrc: PropTypes.string,
      linkUrl: PropTypes.string,
      productBody: PropTypes.string,
      productBodyHeader: PropTypes.string,
      productName: PropTypes.string,
    })
  ),
  gtmClickFeaturedPartnerLearnMore: PropTypes.func,
  isProminent: PropTypes.bool,
};

FeaturedPartner.defaultProps = {
  partners: partnersList || [],
  isProminent: false,
  gtmClickFeaturedPartnerLearnMore: () => {},
};

export default FeaturedPartner;
