import setFilteredProducts from './setFilteredProducts';

export default (selectedProduct) => (dispatch, getState) => {
  const invoiceList = getState().invoices.invoicesData.invoices;

  const filteredProducts = invoiceList.filter((invoice) =>
    invoice.lineItems.find((li) => li.productNumber === selectedProduct.id)
  );

  dispatch(
    setFilteredProducts({ list: filteredProducts, product: selectedProduct })
  );
};
