const variantTransformer = (variant) => {
  try {
    return {
      agrian_id: variant.agrianId,
      ars_key: variant.id,
      associated_country: undefined,
      description: variant.description,
      digital_variant_name: variant.digitalVariantName,
      enabled: variant.enabled,
      guid: variant.id,
      id: variant.id,
      image: variant.image,
      image_mobile: undefined,
      is_bulk: variant.isBulk,
      position: variant.position,
      product_guid: variant.productId,
      product_id: variant.productId,
      total_volume_options: [...variant.totalVolumeOptions],
      unit_measure: variant.unitMeasure,
      unit_qty: variant.unitQty,
      unit_size: variant.unitSize,
      name: variant.name,
    };
  } catch (e) {
    console.error(
      'The variant was not formatted properly and could not be transformed: ',
      e
    );
    return {};
  }
};

const capitalization = (str) => {
  if (typeof str !== 'string') return '';
  const s = str.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const productTransform = (res) => {
  try {
    const variants = res.data.data.variants.map((variant) =>
      variantTransformer(variant)
    );

    const firstSubCategory =
      res.data.data.categories &&
      res.data.data.categories.length > 0 &&
      res.data.data.categories[0].subCategories.length > 0
        ? res.data.data.categories[0].subCategories[0]
        : {};

    const activeIngredients = res.data.data.activeIngredients
      ? res.data.data.activeIngredients
      : [];
    const manufacturer =
      res.data.data.manufacturers && res.data.data.manufacturers.length > 0
        ? res.data.data.manufacturers[0]
        : {};

    return {
      id: res.data.data.id,
      attributes: {
        active_ingredients: activeIngredients,
        ars_key: undefined,
        associated_country: undefined,
        category_id: firstSubCategory.id,
        common_name: res.data.data.commonName,
        crop_types: undefined,
        description: res.data.data.description,
        enabled: res.data.data.enabled,
        guid: res.data.data.id,
        logo: res.data.data.logo,
        logo_mobile: res.data.data.logoMobile,
        manufacturer,
        manufacturer_id: manufacturer.id,
        manufacturer_name: manufacturer.name,
        name: res.data.data.name,
        product_category: { name: capitalization(firstSubCategory.name) },
        rank: res.data.data.rank,
        federallyRestricted: res.data.data.federallyRestricted,
        short_description: undefined,
        variants: [...variants],
        crossSells: res.data.data.crossSells,
      },
    };
  } catch (e) {
    console.error(
      'The product was not formatted properly and could not be transformed: ',
      e
    );
    return {};
  }
};

export default productTransform;
