export const sortByDescendingCost = (list) =>
  list.sort((a, b) => {
    const costA = a.cost;
    const costB = b.cost;

    if (costA > costB) return -1;
    if (costA < costB) return 1;
    return 0;
  });

export const removeNegativeCosts = (list) =>
  list.filter((product) => product.cost > 0);
