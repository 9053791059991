import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contactDetailsPhone: {
    padding: '14px 0 15px',
    '@media (max-width: 550px)': {
      padding: '18px 12px 0 0',
    },
  },

  contactDetailsPhoneBottom: {
    padding: '0 0 42px',
    '@media (max-width: 550px)': {
      padding: '11px 30px 35px 0',
    },
  },

  contactIcon: {
    height: '11px',
    width: '11px',
    fill: theme.palette.common.black,
  },

  iconEnvelope: {
    height: '12px',
    width: '15px',
    fill: theme.palette.common.black,
  },

  phoneNumber: {
    display: 'flex',
    alignItems: 'center',
  },

  contactMethod: {
    paddingLeft: '8px',
    fontSize: '0.875rem',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },

  email: {
    '@media (max-width: 550px)': {
      letterSpacing: '-.75px',
    },
  },

  contactDetails: {
    paddingLeft: '19px',
    fontSize: '0.875rem',
    color: '#828282',
    maxWidth: '333px',
  },

  contactWrapper: {
    paddingTop: '30px',
    '@media (max-width: 550px)': {
      paddingTop: '15px',
    },
  },

  contactDetailsHeader: {
    fontSize: '0.875rem',
    fontWeight: '700',
  },

  noResultsMessage: {
    paddingBottom: '7px',
  },

  contactInfo: {
    paddingLeft: '7px',
  },
}));

export default useStyles;
