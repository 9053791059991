import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MaterialButton from 'shared/ui/RebrandedMaterialButton';

import './LoginSwitchButton.scss';

const LoginSwitchButton = ({ type, location }) => {
  let buttonText = '';
  let toPath = '';

  switch (location.pathname) {
    case '/login':
      buttonText = 'Nutrien Employee Login';
      toPath = '/employee/login';
      break;
    case '/sign-in':
      buttonText = 'Nutrien Employee Login';
      toPath = '/employee/login';
      break;
    case '/employee/login':
      buttonText = 'Customer Login';
      toPath = '/login';
      break;
    default:
      break;
  }

  return (
    <div
      className={
        type === 'mobile'
          ? 'login-switch-button__mobile-container'
          : 'login-switch-button__container'
      }
    >
      <MaterialButton
        data-test="sign-in-switch-button"
        className="button--long login-switch-button__button"
        value={buttonText}
        type="secondary"
        to={toPath}
        component={Link}
      />
    </div>
  );
};

LoginSwitchButton.propTypes = {
  type: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

LoginSwitchButton.defaultProps = {
  type: 'header',
};

export default LoginSwitchButton;
