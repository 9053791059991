import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classes as classesPropType } from 'utils/propTypes';
import { withStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import KEY_ENUM from 'shared/utils/KeyCodes';
import {
  BANK_ACCOUNT_TYPES,
  ACH_ACCOUNT_TYPES,
  COUNTRY_CODES,
} from '../../../../PaymentConstants';
import PaymentValidationMessageComponent from '../../../../OtherComponents/ValidationMessage/PaymentValidationMessageComponent';
import { buildClassName } from '../../../../Utils/PaymentUtil';
import USBankAccountInfoComponent from './BankAccountInfoComponents/USBankAccountInfo';
import CANBankAccountInfoComponent from './BankAccountInfoComponents/CANBankAccountInfo';
import * as PaymentPropTypes from '../../../../PaymentPropTypes';

import styles from './styles';
import commonStyles from '../../../../commonPaymentStyles';

const finalStyles = (theme) => merge({}, commonStyles(theme), styles);

class AccountInformationComponent extends Component {
  state = {
    modalOpen: {
      routingModal: false,
      accountModal: false,
      canadaAccountModal: false,
      transitNumberModal: false,
      institutionNumberModal: false,
    },
  };

  onValidate = (event) => {
    const { validate } = this.props;
    const { name, value } = event.target;
    const fieldData = { value };

    validate({ field: name, fieldData });
  };

  /** @description Method that prevents users to type in numeric characters */
  preventNumericCharacters = (event) => {
    const regex = /^([^0-9]*)$/;

    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  getPersonalAccountInputs = () => {
    const { form, formName, errors, classes } = this.props;

    if (form.bankAccountType === BANK_ACCOUNT_TYPES.PERSONAL) {
      return (
        <div>
          <label className={classes.addPaymentFormLabel} htmlFor="firstName">
            First Name
          </label>
          <input
            id="firstName"
            name="firstName"
            form={formName}
            type="text"
            className={buildClassName(
              'firstName',
              classes.addPaymentFormInput,
              errors,
              classes.error
            )}
            onBlur={this.onValidate}
            onChange={this.handleChange}
            value={form.firstName}
            maxLength="25"
            onKeyPress={this.preventNumericCharacters}
          />
          <PaymentValidationMessageComponent
            errors={errors}
            field="firstName"
          />

          <label className={classes.addPaymentFormLabel} htmlFor="lastName">
            Last Name
          </label>
          <input
            name="lastName"
            form={formName}
            type="text"
            className={buildClassName(
              'lastName',
              classes.addPaymentFormInput,
              errors,
              classes.error
            )}
            onBlur={this.onValidate}
            onChange={this.handleChange}
            value={form.lastName}
            maxLength="25"
            onKeyPress={this.preventNumericCharacters}
          />
          <PaymentValidationMessageComponent errors={errors} field="lastName" />
        </div>
      );
    }
    return null;
  };

  openModal = (event) => {
    const attribute = event.target.attributes.name;
    const modalType = attribute ? event.target.attributes.name.value : null;

    if (this.state.modalOpen[modalType] !== undefined) {
      const newState = this.state.modalOpen;
      Object.keys(newState).forEach((val) => {
        newState[val] = val === modalType;
      });
      this.setState({ modalOpen: newState });
    }
  };

  closeModal = (modalType) => {
    if (this.state.modalOpen[modalType] !== undefined) {
      const newState = this.state.modalOpen;
      newState[modalType] = false;
      this.setState({ modalOpen: newState });
    }
  };

  keyDownHandler = (dialogType, event) => {
    if (event.key === KEY_ENUM.ENTER) {
      this.openModal(event);
    }
    if (event.key === KEY_ENUM.ESCAPE) {
      this.closeModal(dialogType);
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const currentState = { ...this.state };

    currentState[name] = value;
    this.setState(currentState);
    this.props.onChange(event);
  };

  addCorporateInputs = () => {
    const { formName, errors, form, classes } = this.props;

    if (form.bankAccountType === BANK_ACCOUNT_TYPES.CORPORATE) {
      return (
        <div>
          <label
            className={classes.addPaymentFormLabel}
            htmlFor="corporateName"
          >
            Corporate Name
          </label>
          <input
            name="corporateName"
            form={formName}
            type="text"
            className={buildClassName(
              'corporateName',
              classes.addPaymentFormInput,
              errors,
              classes.error
            )}
            onBlur={this.onValidate}
            onChange={this.handleChange}
            value={form.corporateName}
            maxLength="25"
          />
          <PaymentValidationMessageComponent
            errors={errors}
            field="corporateName"
          />
        </div>
      );
    }
    return null;
  };

  changeBankAccountType = (event) => {
    this.handleChange(event);
    this.props.clearHidden();
  };

  renderBankInfoFields = () => {
    const {
      countryCode,
      form,
      routingNumberError,
      bankName,
      onChange,
      errors,
      getBankName,
      clearBankName,
      formName,
    } = this.props;
    let fields;
    switch (countryCode) {
      case COUNTRY_CODES.CANADA: {
        fields = (
          <CANBankAccountInfoComponent
            form={form}
            routingNumberError={routingNumberError}
            bankName={bankName}
            handleChange={this.handleChange}
            onChange={onChange}
            errors={errors}
            getBankName={getBankName}
            clearBankName={clearBankName}
            openModal={this.openModal}
            modalState={this.state.modalOpen}
            onValidate={this.onValidate}
            closeModal={this.closeModal}
            formName={formName}
            keyDownHandler={this.keyDownHandler}
          />
        );
        break;
      }
      case COUNTRY_CODES.USA: {
        fields = (
          <USBankAccountInfoComponent
            form={form}
            routingNumberError={routingNumberError}
            bankName={bankName}
            handleChange={this.handleChange}
            onChange={onChange}
            errors={errors}
            getBankName={getBankName}
            clearBankName={clearBankName}
            openModal={this.openModal}
            modalState={this.state.modalOpen}
            onValidate={this.onValidate}
            closeModal={this.closeModal}
            formName={formName}
            keyDownHandler={this.keyDownHandler}
          />
        );
        break;
      }
      default:
        fields = 'Invalid Country';
    }
    return fields;
  };

  render() {
    const { formName, form, classes } = this.props;
    return (
      <div className={classes.accountInfo}>
        <div className={classes.bankAccountTypeSection}>
          <label
            className={classes.addPaymentFormLabel}
            htmlFor="bankAccountType"
          >
            Bank Account Type
          </label>

          <label
            className={`${classes.accountInfoBlock} ${classes.accountInfoRadio}`}
            htmlFor="Checking"
          >
            <input
              checked={form.achAccountType === ACH_ACCOUNT_TYPES.CHECKING}
              type="radio"
              form={formName}
              onChange={this.handleChange}
              name="achAccountType"
              value="Checking"
              required
            />
            Checking
          </label>

          <label
            className={`${classes.accountInfoBlock} ${classes.accountInfoRadio}`}
            htmlFor="Savings"
          >
            <input
              checked={form.achAccountType === ACH_ACCOUNT_TYPES.SAVINGS}
              type="radio"
              form={formName}
              onChange={this.handleChange}
              name="achAccountType"
              value="Savings"
            />
            Savings
          </label>
        </div>
        <div className={classes.bankAccountTypeSection}>
          <label
            className={classes.addPaymentFormLabel}
            htmlFor="bankAccountType"
          >
            Is this a corporate or personal account?
          </label>

          <label
            className={`${classes.accountInfoBlock} ${classes.accountInfoRadio}`}
            htmlFor="PersonalAccount"
          >
            <input
              checked={form.bankAccountType === BANK_ACCOUNT_TYPES.PERSONAL}
              type="radio"
              form={formName}
              onChange={this.changeBankAccountType}
              name="bankAccountType"
              value="Personal"
              required
            />
            Personal
          </label>

          <label
            className={`${classes.accountInfoBlock} ${classes.accountInfoRadio}`}
            htmlFor="CorporateAccount"
          >
            <input
              checked={form.bankAccountType === BANK_ACCOUNT_TYPES.CORPORATE}
              type="radio"
              form={formName}
              onChange={this.changeBankAccountType}
              name="bankAccountType"
              value="Corporate"
            />
            Corporate
          </label>
        </div>

        {this.getPersonalAccountInputs()}
        {this.addCorporateInputs()}
        {this.renderBankInfoFields()}
      </div>
    );
  }
}

AccountInformationComponent.propTypes = {
  form: PropTypes.shape({
    bankAccountType: PropTypes.string.isRequired,
    achAccountType: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    corporateName: PropTypes.string,
    routingNumber: PropTypes.string,
    bankAccountNumber: PropTypes.string.isRequired,
    confirmBankAccountNumber: PropTypes.string.isRequired,
  }),
  formName: PropTypes.string.isRequired,
  errors: PaymentPropTypes.errors.isRequired, // eslint-disable-line react/no-typos
  onChange: PropTypes.func.isRequired,
  routingNumberError: PropTypes.bool.isRequired,
  bankName: PropTypes.string,
  validate: PropTypes.func.isRequired,
  getBankName: PropTypes.func.isRequired,
  clearHidden: PropTypes.func.isRequired,
  clearBankName: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  classes: classesPropType.isRequired,
};

AccountInformationComponent.defaultProps = {
  form: {
    firstName: '',
    lastName: '',
    corporateName: '',
    routingNumber: '',
  },
  bankName: '',
};

export default withStyles(finalStyles)(AccountInformationComponent);
