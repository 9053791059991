import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { passwordCriteria } from 'shared/utils/formValidation';
import CommonPasswordModal from 'shared/ui/CommonPasswordModal';

import PasswordCriterion from './PasswordCriterion';
import styles from './styles';

const PasswordCriteria = ({
  classes,
  isPasswordSafe,
  formSubmitted,
  password,
  toggleModal,
  isModalShowing,
}) => {
  const criteria = passwordCriteria(password);

  return (
    <>
      {isModalShowing && <CommonPasswordModal closeModal={toggleModal} />}
      <div className={classes.container}>
        <div className={classes.title}>Your password must contain:</div>
        <PasswordCriterion
          altText="password character length criteria satisfied"
          description="at least 10 characters (no spaces)"
          isCriteriaSatisfied={criteria.charLength}
          formSubmitted={formSubmitted}
        />
        <PasswordCriterion
          altText="password lower case criteria satisfied"
          description="a lowercase letter"
          isCriteriaSatisfied={criteria.lower}
          formSubmitted={formSubmitted}
        />
        <PasswordCriterion
          altText="password upper case criteria satisfied"
          description="an uppercase letter"
          isCriteriaSatisfied={criteria.upper}
          formSubmitted={formSubmitted}
        />
        <PasswordCriterion
          altText="password special character criteria satisfied"
          description="a special character (not '+' or '=')"
          isCriteriaSatisfied={criteria.special}
          formSubmitted={formSubmitted}
        />
        <PasswordCriterion
          altText="no commonly used passwords"
          isCriteriaSatisfied={isPasswordSafe}
          formSubmitted={formSubmitted}
        >
          {' '}
          no commonly used passwords (
          <span
            className={classes.seeExamples}
            onClick={toggleModal}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            See Examples
          </span>
          ){' '}
        </PasswordCriterion>
      </div>
    </>
  );
};

PasswordCriteria.propTypes = {
  isPasswordSafe: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  formSubmitted: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  toggleModal: PropTypes.func.isRequired,
  isModalShowing: PropTypes.bool,
};

PasswordCriteria.defaultProps = {
  classes: {},
  formSubmitted: false,
  isModalShowing: false,
};

export default withStyles(styles)(PasswordCriteria);
