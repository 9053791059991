import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import commonStyles from 'components/Payments/commonPaymentStyles';

const PaymentFormComponent = (props) => (
  <div className={props.classes.addPaymentFormContainer}>
    <form
      className={props.classes.addPaymentForm}
      name="add-payment-form"
      onSubmit={props.onSubmit}
    >
      {props.children}
    </form>
  </div>
);

PaymentFormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    addPaymentFormContainer: PropTypes.string,
    addPaymentForm: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default withStyles(commonStyles)(PaymentFormComponent);
