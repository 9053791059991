import apiAdapter from 'shared/lib/cxhApiService';
import get from 'lodash/get';
/**
 * Gets a list of invoice summaries as json
 * @param accountId - The id of the account the invoices belong to.
 * @returns {*} - A promise containing the invoices
 */
export const getInvoiceSummaries = (accountId) =>
  apiAdapter
    .get(`v1/accounts/${accountId}/invoiceSummaries`)
    .then((response) => response.data);

/**
 * Gets a list of invoice summaries in CSV format. Only supports CSV and OFX (ofx is a csv based format)
 * @param accountId - The account id the invoices belong to.
 * @param type - The type we want the final data to be in options are (CSV or OFX)
 * @returns {*} - A promise containing a byte array of the invoice data
 */
export const getInvoiceSummariesAsCSV = (accountId, type) =>
  apiAdapter
    .get(`v1/accounts/${accountId}/invoiceSummaries/csv?type=${type}`)
    .then((response) => response.data);

/**
 * Gets one or more invoices via the accountId and the invoiceId
 * @param accountId - The id of the account the invoices belong to
 * @param invoiceIds - An array of 1 or more invoice ids to retrieve
 * @returns {*} - A promise containing the invoices
 */
export const getInvoices = (accountId, invoiceIds) =>
  apiAdapter
    .get(`v1/accounts/${accountId}/invoices?invoiceIds=${invoiceIds}`)
    .then((response) => response.data);

/**
 * Gets one or more invoices as a CSV file
 * @param accountId - The account id the invoices belong to.
 * @param invoiceIds - An array of 1 or more invoice ids to retrieve
 * @returns {*} - A promise containing the csv file data
 */
export const getInvoicesAsCSV = (accountId, invoiceIds) =>
  apiAdapter
    .get(
      `v1/accounts/${accountId}/invoices/csv?type=CSV&invoiceIds=${invoiceIds}`
    )
    .then((response) => response.data);

export const getInvoiceAsOFX = (accountId, invoiceId) =>
  apiAdapter
    .get(
      `v1/accounts/${accountId}/invoices/csv?invoiceIds=${invoiceId}&type=ofx`
    )
    .then((resp) => resp.data);

/**
 * Gets an invoice as a pdf
 * @param accountId - The account id the invoices belong to.
 * @param invoiceId - The id of the invoice to retrieve
 * @returns {*} - A promise containing a byte buffer of the invoice pdf data
 */
export const getInvoiceAsPDF = (accountId, invoiceId) => {
  const config = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf',
    },
  };

  return apiAdapter.get(
    `v1/accounts/${accountId}/invoices/${invoiceId}/pdf`,
    config
  );
};

/**
 * Retrieves multiple invoices as a zip of pdf files
 * @param accountId - The account id the invoices belong to.
 * @param invoiceIds - An array of invoice ids to retrieve
 * @returns {*} - A promise containing a byte buffer of the zip data
 */
export const getInvoicesAsPDF = (accountId, invoiceIds) => {
  const config = {
    headers: {
      'Content-Type': 'application/zip',
      Accept: 'application/zip',
    },
    responseType: 'arraybuffer',
  };

  return apiAdapter.get(
    `v1/accounts/${accountId}/invoices/pdf?invoiceIds=${invoiceIds}`,
    config
  );
};

/**
 * Retrieves invoiced products for a given account
 * @param accountId - The account id the invoiced products belong to
 * @returns {*} - A promise containing all invoiced products for the account
 */
export const getInvoicedProducts = (accountId, sinceDate, untilDate) => {
  const queryTokens = [];
  if (sinceDate) {
    queryTokens.push(`sinceDate=${sinceDate}`);
  }
  if (untilDate) {
    queryTokens.push(`untilDate=${untilDate}`);
  }

  return apiAdapter
    .get(`v1/accounts/${accountId}/invoiced-products?${queryTokens.join('&')}`)
    .then((response) => response.data)
    .catch((err) =>
      get(err, 'response.status') === 404
        ? Promise.resolve({ products: [] })
        : Promise.reject(err)
    );
};
