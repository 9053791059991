import {
  PERMITS_FETCHING,
  PERMITS_FETCH_FAILURE,
  PERMITS_FETCH_SUCCESS,
} from '../actions';

export default (state = false, action) => {
  switch (action.type) {
    case PERMITS_FETCHING:
      return true;
    case PERMITS_FETCH_SUCCESS:
    case PERMITS_FETCH_FAILURE:
      return false;
    default:
      return state;
  }
};
