import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { updatePassword } from '../actions';
import PasswordChangeForm from './PasswordChangeForm';

export const mapDispatchToProps = {
  updatePassword,
};

export const mapStateToProps = (state) => ({
  updatePasswordState: get(state, 'profile.profileTab.updatePassword', {}),
});

export class PasswordChangeFormContainer extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    isSubmitted: false,
    isPasswordSafe: false,
    isSnackbarOpen: false,
    isPasswordHelpModalOpen: false,
  };

  componentDidUpdate = (prevProps) => {
    const { updateSuccess } = this.props.updatePasswordState;

    if (
      updateSuccess !== prevProps.updatePasswordState.updateSuccess &&
      updateSuccess
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isSnackbarOpen: true });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      isSubmitted: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updatePassword(this.state.oldPassword, this.state.newPassword);
    this.setState({
      isSubmitted: true,
      oldPassword: '',
      newPassword: '',
    });
  };

  handleClearForm = () => {
    this.setState({
      isSubmitted: false,
      oldPassword: '',
      newPassword: '',
    });
  };

  handleCheckIsPasswordSafe = (isPasswordSafe) => {
    this.setState({ isPasswordSafe });
  };

  toggleSnackbar = () => {
    this.setState((state) => ({ isSnackbarOpen: !state.isSnackbarOpen }));
  };

  togglePasswordHelpModal = () => {
    this.setState((state) => ({
      isPasswordHelpModalOpen: !state.isPasswordHelpModalOpen,
    }));
  };

  render() {
    const { isLoading, error } = this.props.updatePasswordState;

    return (
      <PasswordChangeForm
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleClearForm={this.handleClearForm}
        isSubmitted={this.state.isSubmitted}
        oldPassword={this.state.oldPassword}
        newPassword={this.state.newPassword}
        isLoading={isLoading}
        error={error}
        errorName={get(error, 'response.data.name')}
        isPasswordSafe={this.state.isPasswordSafe}
        handleCheckIsPasswordSafe={this.handleCheckIsPasswordSafe}
        isSnackbarOpen={this.state.isSnackbarOpen}
        toggleSnackbar={this.toggleSnackbar}
        isPasswordHelpModalOpen={this.state.isPasswordHelpModalOpen}
        togglePasswordHelpModal={this.togglePasswordHelpModal}
      />
    );
  }
}

PasswordChangeForm.defaultProps = {};

PasswordChangeFormContainer.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  updatePasswordState: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.objectOf(PropTypes.object),
    updateSuccess: PropTypes.bool,
  }).isRequired,
};

const ConnectedPasswordChangeFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordChangeFormContainer);

export default ConnectedPasswordChangeFormContainer;
