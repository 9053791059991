/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import Intercom from 'react-intercom';

import PropTypes from 'prop-types';
import userPropType from 'shared/config/userPropType';
import resolveIntercomKey from 'shared/config/resolveIntercomKey';
import isEmpty from 'lodash/isEmpty';

const configureUserData = (env, hash, user, userType) => {
  const tempUser = {
    userType,
    env,
  };

  if (!isEmpty(user) && hash) {
    tempUser.email = user.email;
    tempUser.name = `${user.firstName} ${user.lastName}`;
    tempUser.user_id = user.id;
    tempUser.user_hash = hash;
  }

  return tempUser;
};

const IntercomMiddleware = ({
  currentPage,
  env,
  flags: { intercom },
  intercomHash,
  isEmployee,
  user,
  userType,
}) => {
  const userData = configureUserData(env, intercomHash, user, userType);
  const intercomApiKey = resolveIntercomKey();
  const hideComponent = currentPage.includes('/cart/') || isEmployee;

  return (
    <>
      {intercom && !hideComponent && (
        <div className="intercom-container">
          <Intercom appID={intercomApiKey} {...userData} />
        </div>
      )}
    </>
  );
};

IntercomMiddleware.propTypes = {
  currentPage: PropTypes.string,
  env: PropTypes.string.isRequired,
  flags: PropTypes.shape({ intercom: PropTypes.bool }).isRequired,
  intercomHash: PropTypes.string,
  isEmployee: PropTypes.bool,
  user: userPropType.isRequired,
  userType: PropTypes.string.isRequired,
};

IntercomMiddleware.defaultProps = {
  currentPage: '',
  intercomHash: null,
  isEmployee: false,
};

export default IntercomMiddleware;
