import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    padding: '17px',

    '@media (min-width: 600px)': {
      display: 'flex',
      justifyContent: 'space-between',
    },

    '@media (min-width: 1000px)': {
      padding: '10px 17px 17px',
    },
  },
  tabLabel: {
    color: theme.palette.text.primary,
    fontSize: '22px',
    lineHeight: '1.14',

    '@media (min-width: 1000px)': {
      color: theme.palette.common.black,
      fontSize: '24px',
      lineHeight: 'normal',
    },
  },
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
  },
  dropDown: {
    '@media (min-width: 600px)': {
      display: 'flex',
    },

    '@media (min-width: 1000px)': {
      width: '25%',
    },
  },
  dropDownLabel: {
    margin: '10px',
  },
}));

export default useStyles;
