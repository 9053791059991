export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS';
export const NOTIFICATIONS_FETCH_FAILURE = 'NOTIFICATIONS_FETCH_FAILURE';
export const NOTIFICATION_READ_STATUS_TOGGLED =
  'NOTIFICATION_READ_STATUS_TOGGLED';
export const NOTIFICATION_READ_STATUSES_TOGGLED =
  'NOTIFICATION_READ_STATUSES_TOGGLED';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
export const NOTIFICATIONS_FETCH_BEGIN = 'NOTIFICATIONS_FETCH_BEGIN';
export const NOTIFICATIONS_FETCH_END = 'NOTIFICATIONS_FETCH_END';
export const NOTIFICATION_DELETE_SUCCESS = 'NOTIFICATION_DELETE_SUCCESS';
export const NOTIFICATION_DELETE_FAILURE = 'NOTIFICATION_DELETE_FAILURE';
export const NOTIFICATION_DELETE_FAILURE_RESET =
  'NOTIFICATION_DELETE_FAILURE_RESET';
export const NOTIFICATIONS_DELETED = 'NOTIFICATIONS_DELETED';
export const NOTIFICATIONS_STAGED_FOR_DELETION =
  'NOTIFICATIONS_STAGED_FOR_DELETION';
export const NOTIFICATIONS_UNDO_DELETION = 'NOTIFICATIONS_UNDO_DELETION';
export const NOTIFICATION_TYPES = {
  NEW_INVOICE: 'NEW_INVOICE',
  NEW_STATEMENT: 'NEW_STATEMENT',
  PAYMENT_DUE: 'PAYMENT_DUE',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PAYMENT_OVERDUE: 'PAYMENT_OVERDUE',
  PAYMENT_PAST_DUE: 'PAYMENT_PAST_DUE',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
  PAYMENT_SUCCESSFUL: 'PAYMENT_SUCCESSFUL',
  EC_ORDER_RESERVED: 'EC_ORDER_RESERVED',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  NEW_WEATHER_STORY: 'NEW_WEATHER_STORY',
  PAYMENT_SCHEDULED_SUCCESS: 'PAYMENT_SCHEDULED_SUCCESS',
  RECURRING_PAYMENT_SCHEDULED_SUCCESS: 'RECURRING_PAYMENT_SCHEDULED_SUCCESS',
  RECURRING_PAYMENT_SERIES_ENDING: 'RECURRING_PAYMENT_SERIES_ENDING',
  RECURRING_PAYMENT_DELETED: 'RECURRING_PAYMENT_DELETED',
  SCHEDULED_PAYMENT_DELETED: 'SCHEDULED_PAYMENT_DELETED',
  CREDIT_APP_APPROVED: 'CREDIT_APP_APPROVED',
  CREDIT_APP_INCOMPLETE: 'CREDIT_APP_INCOMPLETE',
  CREDIT_APP_SUBMITTED: 'CREDIT_APP_SUBMITTED',
};
export const SELECT_NOTIFICATION = 'SELECT_NOTIFICATION';
export const DESELECT_NOTIFICATION = 'DESELECT_NOTIFICATION';
export const SELECT_ALL_NOTIFICATIONS = 'SELECT_ALL_NOTIFICATIONS';
export const DESELECT_ALL_NOTIFICATIONS = 'DESELECT_ALL_NOTIFICATIONS';
export const CLEAR_SELECTED_NOTIFICATIONS = 'CLEAR_SELECTED_NOTIFICATIONS';

export const ALERT_NOTIFICATION_TYPES = ['PAYMENT_PAST_DUE', 'PAYMENT_OVERDUE'];
export const LINKED_NOTIFICATION_TYPES = {
  NEW_WEATHER_STORY: 'Watch Video',
  NEW_INVOICE: 'View Invoice',
  CREDIT_APP_APPROVED: 'View Details',
  CREDIT_APP_SUBMITTED: 'View Details',
  CREDIT_APP_INCOMPLETE: 'View Details',
};
