/**
 * Determines if an invoice has discount terms.
 * It uses the same ARS's logic for determining whether an invoice
 * has discount terms or not (either an invoice has sliding discounts
 * or total potential discount is not 0).
 *
 * @param  {Object}  invoice Invoice object from CXH-API
 * @return {Boolean}         True if the invoice has discount terms.
 */
export const hasDiscountTerms = (invoice) =>
  !!invoice.hasSlidingDiscount ||
  (!!invoice.totalPotentialDiscount && invoice.totalPotentialDiscount !== 0);
