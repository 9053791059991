import {
  NOTIFICATIONS_PREFERENCES_UPDATE_FAILURE,
  ERROR_TYPES,
} from './constants';

export default (error) => ({
  type: NOTIFICATIONS_PREFERENCES_UPDATE_FAILURE,
  payload: error,
  meta: {
    errorType: ERROR_TYPES.UPDATE_FAILURE,
  },
  error: true,
});
