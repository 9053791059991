import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableSortLabel,
  TableCell,
  TableRow,
  TableHead,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cellHeaderDesktop: {
    borderBottom: '1px solid #ccc',
    color: theme.palette.text.primary,
    display: 'none',
    fontSize: '.875rem',
    fontWeight: 600,
    padding: '12px 16px 12px 12px',

    '@media (min-width: 1000px)': {
      display: 'table-cell',
    },
  },
}));

const ContractsTableHead = ({
  config,
  handleOrder,
  sorting: { order, orderBy },
  sortingEnabled,
}) => {
  const style = useStyles();
  return (
    <TableHead>
      <TableRow>
        {config.map(({ name, align, sortable, key }) => (
          <TableCell
            className={style.cellHeaderDesktop}
            align={align}
            key={`key_${name}`}
          >
            {name}
            {sortingEnabled && sortable && (
              <TableSortLabel
                data-test={`key-${key}`}
                active={orderBy === key}
                direction={order}
                onClick={() => handleOrder(key)}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ContractsTableHead.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      align: PropTypes.string,
      sortable: PropTypes.bool,
      key: PropTypes.string,
    })
  ).isRequired,
  handleOrder: PropTypes.func.isRequired,
  sortingEnabled: PropTypes.bool,
  sorting: PropTypes.shape({
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }),
};

ContractsTableHead.defaultProps = {
  sortingEnabled: false,
  sorting: {},
};

export default ContractsTableHead;
