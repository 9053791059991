import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPage, getQueryStringFromState } from 'shared/utils';
import { gtmInvoicesDownload } from 'store/middleware/TagManager/gtmActions';
import InvoiceList from './InvoiceList';
import {
  clearFilteredProduct,
  exportInvoices,
  downloadInvoiceList,
  DOWNLOAD_INVOICES_IN_PROGRESS,
  setDateFilter,
  updateAccountTab,
} from '../actions';
import { invoices as invoicesPropType } from '../invoicesPropTypes';
import { getFilteredInvoices } from './InvoicesTable/selectors';

export const InvoiceListContainer = (props) => {
  const { filteredInvoices, currentPage, pageSize } = props;
  const { filterableInvoiceTable } = useFlags();
  const paginatedInvoices = getPage(filteredInvoices, currentPage, pageSize);

  const propsPlus = { ...props, paginatedInvoices };

  return (
    <InvoiceList {...propsPlus} showColumnFilters={filterableInvoiceTable} />
  );
};

InvoiceListContainer.propTypes = {
  currentPage: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  filteredInvoices: invoicesPropType.isRequired,
  gtmInvoicesDownload: PropTypes.func.isRequired,
};

InvoiceListContainer.defaultProps = {
  currentPage: 1,
};

const mapStateToProps = (state) => {
  const { invoiceDownloadStatus } = state.invoices;
  const isLoading = invoiceDownloadStatus === DOWNLOAD_INVOICES_IN_PROGRESS;
  const { selectedInvoices } = state.invoices;

  return {
    account: state.selectedAccount,
    currentDateFilter: state.invoices.filterType,
    filteredInvoices: getFilteredInvoices(state),
    filteredProducts: state.invoices.filteredProducts,
    isLoading,
    paymentError: state.payments.errorReducer.paymentError,
    queryString: getQueryStringFromState(state),
    selectedInvoices,
    selectionOptions: state.invoices.invoicesData.list,
  };
};

const mapDispatchToProps = {
  clearFilteredProduct,
  downloadInvoiceList,
  exportInvoices,
  push,
  setDateFilter,
  updateAccountTab,
  gtmInvoicesDownload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceListContainer);
