import { INVOICE_STATUSES } from '../../../../actions';

/**
 * Currently this filter is used by the older filter system and by the _new_ filter system.
 * Once the filterable-invoice-table flag is always set to true, we can refactor this
 * to be much simpler.
 */
export default (
  invoices,
  filters,
  queryString = {},
  filteredProductsList = []
) => {
  const statusFilter = queryString.typeFilter || 'All';

  // TODO: Remove this when old filters are removed.
  if (statusFilter === 'productsearch') {
    return invoices.filter((invoice) =>
      filteredProductsList.find(
        (filteredInvoice) => filteredInvoice.id === invoice.id
      )
    );
  }

  let filteredInvoices = invoices.filter((invoice) => {
    if (invoice.isSupressed) {
      return false;
    }

    const invoiceIsDueAndHasDiscountOrRebate =
      statusFilter === INVOICE_STATUSES.DUE &&
      (invoice.status === INVOICE_STATUSES.DUE_DISCOUNT_AVAILABLE ||
        invoice.status === INVOICE_STATUSES.DUE_REBATE_AVAILABLE);

    // TODO: remove this stanza when the new filter system goes online.
    if (
      statusFilter !== 'All' &&
      statusFilter !== invoice.status &&
      !invoiceIsDueAndHasDiscountOrRebate
    ) {
      return false;
    }

    return true;
  });

  // The following conditional statement is meant to check for the case
  // where a user lands on the page with a status filter of discount/rebate
  // available but doesn't actually have an invoice with that status.
  // Show all invoices.
  if (
    filteredInvoices.length === 0 &&
    (statusFilter === INVOICE_STATUSES.DUE_REBATE_AVAILABLE ||
      statusFilter === INVOICE_STATUSES.DUE_DISCOUNT_AVAILABLE)
  ) {
    filteredInvoices = invoices.filter((invoice) => !invoice.isSupressed);
  }

  return filteredInvoices;
};
