import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, withStyles } from '@material-ui/core';

const style = {
  root: { color: '#f0f0f0', fontSize: '14px' },
};
const Initial = ({ firstName, lastName, classes }) => (
  <Avatar className={`${classes.root} initial-circle`}>
    <span data-hj-suppress="">{firstName.charAt(0) + lastName.charAt(0)}</span>
  </Avatar>
);

Initial.defaultProps = {
  firstName: 'Unknown',
  lastName: 'Customer',
};

Initial.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default withStyles(style)(Initial);
