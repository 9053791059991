import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import './MaterialLoadingIcon.css';

const MaterialLoadingIcon = (props) => (
  <div className="MaterialLoadingIconWrapper">
    <CircularProgress
      {...props}
      size={props.size}
      left={70}
      top={0}
      style={{ color: '#4c9e00' }}
    />
  </div>
);

MaterialLoadingIcon.propTypes = {
  size: PropTypes.number,
};

MaterialLoadingIcon.defaultProps = {
  size: 50,
};

export default MaterialLoadingIcon;
