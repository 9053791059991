import { TOGGLE_MODAL as type } from './constants';

// this method is not exposed to components outside of the onfirmationModal/actions directory,
// because preference and accountIds fields aren't used when closing the modal,
// the exported dispatchers are instead "openModal" and "closeModal" which internally utilizes this method
export default (isOpen, preference, accountIds) => ({
  type,
  isOpen,
  preference,
  accountIds,
});
