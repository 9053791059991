import { difference, isEmpty } from 'lodash';

/**
 * given that the user is on a particular page of an invoice table,
 * returns a bool representing whether all  the invoices on that page are selected
 * @param paginatedInvoiceIds the array of ID's of the invoices on the current page
 * @param selectedInvoiceIds the array of ID's of currently selected Invoices
 * */
export const areAllPaginatedInvoicesSelected = (
  paginatedInvoiceIds,
  selectedInvoiceIds
) => isEmpty(difference(paginatedInvoiceIds, selectedInvoiceIds));
