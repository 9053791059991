import { MixpanelEvents, MixpanelProperties } from '../mixPanelConstants';

export const mixpanelStartCreditApplication = (
  selectedAccount,
  pathName,
  redirectUrl
) => {
  try {
    window.mixpanel.people.set_once(
      MixpanelProperties.FIRST_ONLINE_CREDIT_APPLICATION_INITIATE,
      new Date().toISOString()
    );

    window.mixpanel.people.set(
      MixpanelProperties.LAST_ONLINE_CREDIT_APPLICATION_INITIATE,
      new Date().toISOString()
    );
    window.mixpanel.people.increment(
      MixpanelProperties.LIFETIME_ONLINE_CREDIT_APPLICATION_INITIATE
    );
    window.mixpanel.track(
      MixpanelEvents.INITIATE_CREDIT_APPLICATION,
      {
        [MixpanelProperties.ACCOUNT_ID]: selectedAccount.id,
        [MixpanelProperties.ACCOUNT_NAME]: selectedAccount.name,
        [MixpanelProperties.BRANCH_ID]: selectedAccount.branchId,
        [MixpanelProperties.BRANCH_NAME]: selectedAccount.branchName,
        [MixpanelProperties.VIA]: pathName,
      },
      { send_immediately: true },
      // wait to redirect user until after the event has been tracked
      () => {
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        }
      }
    );
  } catch (error) {
    throw new Error(error);
  }
};
