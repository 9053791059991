import { makeStyles } from '@nutrien/uet-react/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: '1rem',
    width: '100%',
    padding: '1rem',
    [theme.breakpoints.down('840')]: {
      flexDirection: 'column',
    },
  },
  largeImage: {
    margin: '0.25rem',
    width: '100%',
    [theme.breakpoints.between('840', '1024')]: {
      display: 'none',
    },
    [theme.breakpoints.up('1024')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.down('1024')]: {
      margin: 0,
    },
  },
  smallImage: {
    width: '100%',
    margin: '0px auto',
    maxWidth: '320px',
    [theme.breakpoints.up('1024')]: {
      display: 'none',
    },
    [theme.breakpoints.down('840')]: {
      display: 'none',
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: '280px',
    marginLeft: '1rem',
    [theme.breakpoints.down('840')]: {
      margin: '1rem 0',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('840')]: {
      flexDirection: 'column',
      marginTop: '10px',
      width: '100%',
    },
  },
  button: {
    margin: '6px',
    [theme.breakpoints.down('840')]: {
      margin: '6px 0',
      width: '100%',
    },
    [theme.breakpoints.down('340')]: {
      width: 'calc(100% - 24px)',
    },
  },
  text: {
    margin: '.75rem .5rem .5rem 0',
  },
  // Credit Status Specific Classes
  creditAppTable: {
    [theme.breakpoints.down('840')]: {
      display: 'none',
    },
  },
  applyAgainAddIcon: {
    marginRight: '.5rem',
  },
  appStatusHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('500')]: {
      flexDirection: 'column-reverse',
    },
  },
  appStatusApplyAgain: {
    [theme.breakpoints.down('500')]: {
      alignSelf: 'flex-end',
    },
  },
  creditAppLink: {
    fontWeight: 600,
  },
  creditAppLinkDisabled: {
    color: theme.palette.grey.A400,
    fontWeight: 600,
  },
  titleBar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  statusButtonTableCell: {
    paddingRight: '0px !important',
    width: '180px',
  },
  statusButton: {
    minWidth: '148px',
  },
  viewAllAppsButton: {
    width: '220px',
    marginTop: '8px',
    [theme.breakpoints.down('840')]: {
      display: 'none',
    },
  },
  statusChip: {
    height: '18px !important',
    padding: '0 6px',
    '& .MuiChip-labelSmall': {
      marginBottom: '3px',
    },
  },
  statusChipText: {
    fontSize: '10px',
  },
  creditAppDetailsSmall: {
    marginTop: '12px',
    [theme.breakpoints.up('840')]: {
      display: 'none',
    },
  },
  creditAppDetailsSmallName: {
    margin: '8px 0',
  },
  creditAppDetailsSmallNameDisabled: {
    margin: '8px 0',
    color: theme.palette.grey.A400,
    fontWeight: 600,
  },
  creditAppDetailsSmallStatusLabel: {
    marginTop: '16px',
  },
  creditAppDetailsSmallViewDetailsButton: {
    marginTop: '20px',
    [theme.breakpoints.up('840')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
