import * as actionTypes from '../../actions/constants';

const initialCrossSellState = {
  data: [],
  loading: true,
  loadingPrices: false,
  error: null,
};

const {
  GET_CROSS_SELLS_SUCCESS,
  GET_CROSS_SELLS_FAILURE,
  LOADING_CROSS_SELLS,
} = actionTypes;

export default (state = initialCrossSellState, action) => {
  switch (action.type) {
    case LOADING_CROSS_SELLS: {
      const { loading } = action;
      return { ...state, loading };
    }
    case GET_CROSS_SELLS_SUCCESS: {
      const { data, loading, error, meta } = action;
      return { ...state, data, error, loading, meta };
    }
    case GET_CROSS_SELLS_FAILURE: {
      const { error, loading } = action;
      return { ...state, error, loading };
    }
    default:
      return state;
  }
};
