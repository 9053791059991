import * as actionTypes from '../../actions/constants';

const initialProductState = {
  loading: true, // this defaults to true so things view as loading until explicitly changed to false
  loadingPrices: false,
  error: null,
  variant: {
    selectedQuantity: 1,
  },
  similarProducts: {
    loading: false,
    error: null,
    productData: null,
  },
};

export default (state = initialProductState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_SIMILAR_PRODUCTS: {
      const { loading } = action;
      return {
        ...state,
        similarProducts: { ...state.similarProducts, loading },
      };
    }
    case actionTypes.GET_SIMILAR_PRODUCTS_SUCCESS: {
      const { productData } = action;
      return {
        ...state,
        similarProducts: {
          ...state.similarProducts,
          productData,
          loading: false,
        },
      };
    }
    case actionTypes.GET_SIMILAR_PRODUCTS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        similarProducts: { ...state.similarProducts, loading: false, error },
      };
    }
    case actionTypes.LOADING_PRODUCT: {
      const { loading } = action;
      return { ...state, loading };
    }
    case actionTypes.LOADING_PRICES_FOR_PRODUCT: {
      const { loadingPrices } = action;
      return { ...state, loadingPrices };
    }
    case actionTypes.GET_PRODUCT_SUCCESS: {
      const { data, loading } = action;
      return { ...state, data, loading };
    }
    case actionTypes.GET_PRODUCT_FAILURE: {
      const { error, loading } = action;
      return { ...state, error, loading };
    }
    case actionTypes.GET_PRICES_FOR_PRODUCT_SUCCESS: {
      const { data, loadingPrices } = action;
      return { ...state, data, loadingPrices };
    }
    case actionTypes.GET_PRICES_FOR_PRODUCT_FAILURE: {
      const { loadingPrices } = action;
      return { ...state, loadingPrices };
    }
    case actionTypes.SET_SELECTED_VARIANT: {
      const { variant } = action;
      // Stop this from overwriting selected volume
      if (state.variant.selectedVolume) {
        variant.selectedVolume = state.variant.selectedVolume;
      }
      return { ...state, variant: { ...variant } };
    }
    case actionTypes.SET_VARIANT_QUANTITY: {
      const { selectedQuantity } = action;
      return { ...state, variant: { ...state.variant, selectedQuantity } };
    }
    case actionTypes.SET_VARIANT_VOLUME: {
      const { selectedVolume } = action;
      return { ...state, variant: { ...state.variant, selectedVolume } };
    }
    default:
      return state;
  }
};
