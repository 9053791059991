import { persistAccountNicknames } from 'shared/ui/AccountsTable/actions/AccountActions';
import { updateNickname } from '../AccountsTabService';
import {
  UPDATE_NICKNAME_STATUSES,
  UPDATE_SAVE_NICKNAME_STATUS,
} from './constants';

export const updateSaveNickNameStatus = (status) => ({
  type: UPDATE_SAVE_NICKNAME_STATUS,
  payload: status,
});

const validateNickname = (nickname) => (nickname !== '' ? nickname : ' ');

export default (accountNicknameMapping) => async (dispatch, getState) => {
  dispatch(updateSaveNickNameStatus(UPDATE_NICKNAME_STATUSES.SAVING));

  const state = getState();
  const currentNicknameMapping = {};
  const accounts = state.accounts.list;
  accounts.forEach((acc) => {
    currentNicknameMapping[acc.number] = acc.nickname || '';
  });

  try {
    await Promise.all(
      Object.keys(accountNicknameMapping).map((key) => {
        // The backend does not support empty nicknames.
        // If the user wishes to remove their nickname, the workaround is to pass a single space instead.
        const newNickname = validateNickname(accountNicknameMapping[key]);
        if (newNickname !== currentNicknameMapping[key]) {
          return updateNickname(key, newNickname).then(() => {
            currentNicknameMapping[key] = newNickname;
          });
        }
        return Promise.resolve();
      })
    );
    dispatch(updateSaveNickNameStatus(UPDATE_NICKNAME_STATUSES.SUCCESS));
  } catch (e) {
    dispatch(updateSaveNickNameStatus(UPDATE_NICKNAME_STATUSES.ERROR));
  }

  dispatch(persistAccountNicknames(accounts, currentNicknameMapping));
};
