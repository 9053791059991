import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useAuth } from '@nutrien/fe-domain-utils';

import { fetchUser } from 'actions';
import { fetchAuth0UserMetadata } from 'actions/auth0UserActions';
import { selectUser, selectUserStatus } from 'selectors';
import { NO_USER } from 'shared/config/userConstants';

const useUser = () => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const user = useSelector(selectUser());
  const userStatus = useSelector(selectUserStatus);

  useEffect(() => {
    if (isAuthenticated && userStatus === NO_USER) {
      dispatch(fetchUser());
      dispatch(fetchAuth0UserMetadata());
    }
  }, [dispatch, isAuthenticated, userStatus]);

  return { user, userStatus };
};

export default useUser;
