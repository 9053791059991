import {
  setPurchasesLoading,
  setPurchasesOk,
  setPurchasesError,
} from './PurchasesActionCreators';
import getPurchases from './getPurchases';

const fetchPurchases = (defaultAccountNumber, startDate, endDate) => (
  dispatch
) => {
  dispatch(setPurchasesLoading());
  getPurchases(defaultAccountNumber, startDate, endDate)
    .then((response) => {
      if (response.data !== undefined) {
        dispatch(setPurchasesOk(response.data));
      } else {
        dispatch(setPurchasesError());
      }
    })
    .catch(() => {
      dispatch(setPurchasesError());
    });
};

export default fetchPurchases;
