import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  Divider,
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
  MenuList,
  Popper,
  Grow,
  MenuItem,
  Fab,
} from '@material-ui/core';
import Comment from '@material-ui/icons/Comment';
import Email from '@material-ui/icons/Email';
import Announcement from '@material-ui/icons/Announcement';
import Close from '@material-ui/icons/Close';

import ConsultantInfo from './ConsultantInfo';
import './SupportFABStyles.scss';

const SupportFAB = ({ location: { pathname } }) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef();

  const openMenu = () => setOpen(true);

  const closeMenu = () => setOpen(false);

  // Hiding on cart checkout page
  if (pathname.match(/\/(cart\/.*)\/?$/)) return null;

  return (
    <div className="support-fab__container">
      <Fab
        onClick={openMenu}
        className="support-fab__button"
        aria-owns={open ? 'menu-list-grow' : null}
        aria-haspopup="true"
        ref={anchorEl}
      >
        {open ? (
          <Close className="support-fab__button-icon" />
        ) : (
          <Comment className="support-fab__button-icon" />
        )}
      </Fab>
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        transition
        disablePortal
        placement="top-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="menu-list-grow">
            <ClickAwayListener onClickAway={closeMenu}>
              <MenuList
                className="support-fab__menu-list"
                onClick={closeMenu}
                style={{ padding: 0 }}
              >
                <ConsultantInfo />
                <Link to="/contact">
                  <MenuItem
                    onClick={closeMenu}
                    className="support-fab__menu-item"
                  >
                    <ListItemIcon className="support-fab__icon">
                      <Email />
                    </ListItemIcon>
                    <ListItemText
                      className="support-fab__list-item-text"
                      primary="Contact"
                    />
                  </MenuItem>
                </Link>
                <Divider />
                <Link to="/feedback">
                  <MenuItem
                    onClick={closeMenu}
                    className="support-fab__menu-item"
                  >
                    <ListItemIcon className="support-fab__icon">
                      <Announcement />
                    </ListItemIcon>
                    <ListItemText
                      className="support-fab__list-item-text"
                      primary="Feedback"
                    />
                  </MenuItem>
                </Link>
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

SupportFAB.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export { SupportFAB };
export default withRouter(SupportFAB);
