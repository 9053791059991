import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { gtmAccountOverviewInteraction } from 'store/middleware/TagManager/gtmActions';
import get from 'lodash/get';

import { ExportLinksList } from 'shared/ui';
import exportAccountActivity from './actions';

export class ExportAccountActivityContainer extends Component {
  exportAction = (fileType) => {
    const {
      exportAccountActivityAction,
      accountId,
      gtmAccountOverviewInteractionAction,
    } = this.props; // eslint-disable-line no-shadow

    gtmAccountOverviewInteractionAction(
      'Download',
      `Account Activity | ${fileType}`
    );
    return exportAccountActivityAction(fileType, accountId);
  };

  render() {
    return window.ReactNativeWebView ? null : (
      <ExportLinksList
        exportAction={this.exportAction}
        fileTypes={['csv', 'ofx']}
      />
    );
  }
}

ExportAccountActivityContainer.propTypes = {
  exportAccountActivityAction: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  gtmAccountOverviewInteractionAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  exportAccountActivityAction: exportAccountActivity,
  gtmAccountOverviewInteractionAction: gtmAccountOverviewInteraction,
};

const mapStateToProps = (state) => ({
  accountId: get(state, 'selectedAccount.id'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportAccountActivityContainer);
