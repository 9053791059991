import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import LocalPhone from '@material-ui/icons/LocalPhone';
import Email from '@material-ui/icons/Email';
import generalSeed from 'assets/general-seed.svg';
import generalOther from 'assets/general-other.svg';
import generalFert from 'assets/general-fert.svg';
import generalApp from 'assets/general-application.svg';
import error from 'assets/ic-error.svg';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  withWidth,
} from '@material-ui/core';
import Icon from 'nutrien-common-components-react/dist/mdc/Icon';
import PageNotification from 'shared/ui/NotificationHelper/PageNotification';
import hubspotMarketingOptIn from './requestInfoService';
import './RequestInfoModal.scss';

export class RequestInfoModal extends Component {
  state = {
    notifyMeIsChecked: false,
    hubspotCallFailed: false,
  };

  callHubspot = async () => {
    const hubspotParamsObj = {
      email: this.props.userEmail,
      [this.props.selectedProductShelf]: true,
    };

    await hubspotMarketingOptIn(hubspotParamsObj)
      .then(() => {
        this.closeModal();
        this.props.setHubspotOptInSuccess(this.props.selectedProductShelf);
      })
      .catch(() => {
        this.setState({ hubspotCallFailed: true });
      });
  };

  closeModal = () => {
    this.props.closeModalFunc();
  };

  render() {
    const isSmallScreen = this.props.width === 'xs';

    const cropIcons = {
      seed: generalSeed,
      application: generalApp,
      fertilizer: generalFert,
      other: generalOther,
      error,
    };

    const setProductIcon = () => {
      let productIcon;
      if (
        !this.props.selectedProductShelf ||
        this.props.selectedProductShelf.toLowerCase() === 'error'
      ) {
        productIcon = cropIcons.error;
      } else if (
        cropIcons[this.props.selectedProductShelf.toLowerCase()] === undefined
      ) {
        productIcon = cropIcons.other;
      } else {
        productIcon = cropIcons[this.props.selectedProductShelf.toLowerCase()];
      }
      return productIcon;
    };

    const setProductDescription = () => {
      // let productDescription;
      const placeHolder =
        'For more information, please contact your Nutrien Ag Solutions Representative.';
      if (
        !this.props.selectedProductShelf ||
        this.props.selectedProductShelf.toLowerCase() === 'other'
      ) {
        // productDescription =
        // 'For more information, please contact your Nutrien Ag Solutions Representative or we can notify you once this item is available to reorder online.';
      } else if (
        this.props.selectedProductShelf.toLowerCase() === 'application'
      ) {
        // productDescription = `For more information, please contact your Nutrien Ag Solutions Representative or we can notify you once ${this.props.selectedProductShelf.toLowerCase()}s are available to reorder online.`;
      } else if (this.props.selectedProductShelf.toLowerCase() === 'error') {
        // productDescription =
        // 'For more information, please contact your Nutrien Ag Solutions Representative.';
      } else {
        // productDescription = `For more information, please contact your Nutrien Ag Solutions Representative or we can notify you once ${this.props.selectedProductShelf.toLowerCase()} is available to reorder online.`;
      }
      // return productDescription;
      return placeHolder;
    };

    const setProductTitle = () => {
      let productTitle;
      if (
        !this.props.selectedProductShelf ||
        this.props.selectedProductShelf.toLowerCase() === 'other' ||
        this.props.selectedProductShelf.toLowerCase() === 'error'
      ) {
        productTitle = 'This item is not available for reorder.';
      } else if (
        this.props.selectedProductShelf.toLowerCase() === 'application'
      ) {
        productTitle = `${this.props.selectedProductShelf}s are not yet available for reorder.`;
      } else {
        productTitle = `${this.props.selectedProductShelf} is not yet available for reorder.`;
      }
      return productTitle;
    };

    const setCropConsultantName = () => {
      let cropConsultantName;
      if (
        this.props.cropConsultant.firstName &&
        this.props.cropConsultant.lastName
      ) {
        cropConsultantName = (
          <p
            className="request-info-modal__consultant-name"
            data-test="request-info-modal__consultant-name"
          >
            {this.props.cropConsultant.firstName}{' '}
            {this.props.cropConsultant.lastName}
          </p>
        );
      }
      return cropConsultantName;
    };

    const setCropConsultantPhone = () => {
      let cropConsultantPhone;
      if (this.props.cropConsultant.workPhone) {
        cropConsultantPhone = (
          <span
            className="request-info-modal__consultant-contact"
            data-test="request-info-modal__consultant-phone"
          >
            <LocalPhone className="request-info-modal__contact-icon--left" />
            {this.props.cropConsultant.workPhone}
            <br />
          </span>
        );
      }
      return cropConsultantPhone;
    };

    const setCropConsultantEmail = () => {
      let cropConsultantEmail;
      if (this.props.cropConsultant.workEmailAddress) {
        cropConsultantEmail = (
          <span className="request-info-modal__consultant-contact">
            <Email className="request-info-modal__contact-icon--left" />
            <a href={`mailto:${this.props.cropConsultant.workEmailAddress}`}>
              {this.props.cropConsultant.workEmailAddress}
            </a>
          </span>
        );
      }
      return cropConsultantEmail;
    };

    const renderDismissalButtons = () => {
      if (!this.props.selectedProductShelf) {
        return (
          <>
            <Button
              onClick={this.props.closeModalFunc}
              className="request-info-modal__request-info-accept"
              size="large"
            >
              OK
            </Button>
          </>
        );
      }

      return (
        <div className="request-info-modal__request-info-bottom-wrapper--outer">
          {this.props.cropConsultant && (
            <p className="request-info-modal__your-crop-consultant">
              Your Nutrien Ag Solutions Representative
            </p>
          )}
          <div className="request-info-modal__request-info-bottom-wrapper">
            <div className="request-info-modal__crop-consultant-info-wrapper">
              {this.props.cropConsultant && (
                <div className="request-info-modal__crop-consultant-info">
                  <div />
                  {setCropConsultantName()}
                  <p className="request-info-modal__contact-info">
                    {setCropConsultantPhone()}
                    {setCropConsultantEmail()}
                  </p>
                </div>
              )}
            </div>
            <div className="request-info-modal__request-info-button-wrapper">
              {this.props.selectedProductShelf.toLowerCase() ===
              'error' ? null : (
                <Button
                  onClick={this.props.closeModalFunc}
                  className="request-info-modal__request-info-button request-info-modal__cancel-button"
                  color="secondary"
                  data-test="request-info-modal__cancel-button"
                  size="large"
                  variant="outlined"
                >
                  Cancel
                </Button>
              )}
              {this.props.selectedProductShelf.toLowerCase() === 'error' ? (
                <Button
                  onClick={this.props.closeModalFunc}
                  className="request-info-modal__request-info-button request-info-modal__ok-button"
                  data-test="request-info-modal__ok-button--error"
                  size="large"
                  variant="contained"
                >
                  OK
                </Button>
              ) : (
                <Button
                  onClick={this.callHubspot}
                  className="request-info-modal__request-info-button request-info-modal__ok-button"
                  color="primary"
                  data-test="request-info-modal__ok-button"
                  disabled={!this.state.notifyMeIsChecked}
                  size="large"
                  variant="contained"
                >
                  OK
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    };

    return (
      <Dialog
        className="request-info-modal__modal-container"
        open
        role="presentation"
        fullScreen={isSmallScreen}
      >
        <DialogContent className="request-info-modal__request-info-modal-wrapper">
          <div className="request-info-modal__modal-header-wrapper">
            <IconButton
              className="request-info-modal__close-modal"
              aria-label="Click to close Additional Information Request"
              onClick={this.props.closeModalFunc}
              onKeyPress={this.props.closeModalFunc}
              tabIndex={0}
            >
              <Icon className="brand-black" icon="close" />
            </IconButton>
          </div>
          <div>
            {this.state.hubspotCallFailed && (
              <PageNotification
                message="Something went wrong. Please try again."
                type="DANGER"
                rightLink=""
                className="request-info-modal__hubspot-call-failed-notification"
                data-test="request-info-modal__hubspot-call-failed-notification"
              />
            )}
          </div>
          <img
            className="request-info-modal__application-icon"
            src={setProductIcon()}
            alt="application icon"
          />
          <DialogTitle className="request-info-modal__request-info-title-area">
            <p
              className="request-info-modal__request-info-title"
              data-test="request-info-modal__request-info-title"
            >
              {setProductTitle()}
            </p>
          </DialogTitle>
          <DialogContentText
            className="request-info-modal__request-info-description"
            data-test="request-info-modal__request-info-description"
          >
            {setProductDescription()}
          </DialogContentText>
          {this.props.selectedProductShelf.toLowerCase() === 'error' ? null : (
            <div className="request-info-modal__request-info-checkbox" />
          )}
          <hr className="request-info-modal__request-info-HR" />
          {renderDismissalButtons()}
        </DialogContent>
      </Dialog>
    );
  }
}

RequestInfoModal.propTypes = {
  closeModalFunc: PropTypes.func.isRequired,
  setHubspotOptInSuccess: PropTypes.func.isRequired,
  selectedProductShelf: PropTypes.string,
  cropConsultant: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    workEmailAddress: PropTypes.string,
    workPhone: PropTypes.string,
  }),
  userEmail: PropTypes.string.isRequired,
  width: PropTypes.string,
};

RequestInfoModal.defaultProps = {
  cropConsultant: null,
  selectedProductShelf: 'error',
  width: null,
};

export default withWidth()(RequestInfoModal);
