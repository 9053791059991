import { ACTION_TYPES } from 'components/Account/Invoices/actions';
import {
  GTM_INVOICED_PRODUCTS_SEARCH_UPDATE,
  GTM_INVOICED_PRODUCTS_SEARCH_FOCUS,
} from '../gtmConstants';

export const selectInvoicedProduct = (action) => ({
  event: 'CXH_SelectInvoicedProductsSearch',
  cxh: {
    invoices: {
      invoicedProductsSearch: {
        selectedProduct: {
          name: action.payload.productName,
          id: action.payload.productId,
        },
      },
    },
  },
});

export const focusInvoicedProductsSearch = (action) => ({
  event: 'CXH_FocusInvoicedProductsSearch',
  cxh: {
    invoices: {
      invoicedProductsSearch: {
        ...action.payload,
      },
    },
  },
});

export const updateInvoicedProductsSearch = (action) => ({
  event: 'CXH_UpdateInvoicedProductsSearch',
  cxh: {
    invoices: {
      invoicedProductsSearch: {
        ...action.payload,
      },
    },
  },
});

export const invoicedProductsSearchEventsMap = {
  [GTM_INVOICED_PRODUCTS_SEARCH_FOCUS]: focusInvoicedProductsSearch,
  [GTM_INVOICED_PRODUCTS_SEARCH_UPDATE]: updateInvoicedProductsSearch,
  [ACTION_TYPES.SELECT_INVOICED_PRODUCT]: selectInvoicedProduct,
};
