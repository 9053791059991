/**
 * Adds disabled prop to wrapped components if user is employee
 *
 */
import { PropTypes } from 'prop-types';
import React, { Children, cloneElement } from 'react';

import { isEmployee } from '../tokenUtils';

const DisabledForEmployees = ({ children }) => (
  <>
    {Children.map(
      children,
      (child) =>
        child && cloneElement(child, isEmployee() ? { disabled: true } : {})
    )}
  </>
);

DisabledForEmployees.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DisabledForEmployees;
