import React from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const FormCard = (props) => {
  const { children } = props;

  return (
    <div>
      <Card style={{ marginBottom: '2em', marginTop: '2em' }} {...props}>
        <div>{children}</div>
      </Card>
    </div>
  );
};

FormCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(FormCard);
