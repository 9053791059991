import { TOGGLE_MODAL } from '../actions';

export default (state = false, action) => {
  const { type, isOpen } = action;

  switch (type) {
    case TOGGLE_MODAL:
      return isOpen;
    default:
      return state;
  }
};
