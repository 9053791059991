const cartItemTransformer = (data) => {
  try {
    return {
      product: {
        attributes: {
          account_id: data.data.accountId,
          branch_id: data.data.branchId,
          created_at: data.data.createdAt,
          guid: data.data.product.id,
          name: data.data.product.name,
          active_ingredients: data.data.product.activeIngredients,
          categories: data.data.product.categories,
          common_name: data.data.product.commonName,
          manufacturers: data.data.product.manufacturers,
        },
        total_volume: data.data.totalVolume,
      },
      variant: {
        price: data.data.unitPrice,
        unit_measure: data.data.variant.unitMeasure,
        unit_qty: data.data.variant.unitQty,
        unit_size: data.data.variant.unitSize,
        digital_variant_name: data.data.variant.digitalVariantName,
        net_price: data.data.netPrice,
        id: data.data.variant.id,
        is_bulk: data.data.variant.isBulk,
        variant_sku: data.data.variant.id,
        total_volume_options: data.data.variant.totalVolumeOptions,
      },
      account_id: data.data.accountId,
      id: data.data.id,
      total_volume: data.data.totalVolume,
      variant_sku: data.data.variant.id,
    };
  } catch (e) {
    return {};
  }
};

export default cartItemTransformer;
