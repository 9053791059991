import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import {
  MaterialLoadingIcon,
  PasswordCheckerHelperContent,
  PasswordCriteria,
  PasswordField,
} from 'shared/ui';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  Notification,
  Snackbar,
} from 'nutrien-common-components-react/dist/mdc';

import './styles.scss';

// Human-friendly messages for errors that occur while saving a password.
const errorNameToMessage = {
  AuthenticateUserError: 'The current password you entered was incorrect.',
};

// retrieves the proper error message.
const getErrorMessage = (errorName) =>
  errorNameToMessage[errorName] ||
  'There was a problem saving your new password.';

const PasswordChangeForm = ({
  handleSubmit,
  handleChange,
  isSubmitted,
  oldPassword,
  newPassword,
  isLoading,
  error,
  errorName,
  handleClearForm,
  handleCheckIsPasswordSafe,
  isPasswordSafe,
  isSnackbarOpen,
  toggleSnackbar,
  isPasswordHelpModalOpen,
  togglePasswordHelpModal,
}) => (
  <form
    onSubmit={handleSubmit}
    data-test="password-change-form"
    className="password-change-form"
    autoComplete="off"
  >
    <Snackbar
      isOpen={isSnackbarOpen}
      message="Password changed successfully."
      data-test="password-change-form-success-message"
      displayCloseButton
      onClose={toggleSnackbar}
    />
    <Dialog open={isPasswordHelpModalOpen}>
      <DialogTitle>
        Examples of Common Passwords
        <hr />
      </DialogTitle>
      <DialogContent>
        <span className="password-change-form__common-password-modal-content-title">
          To ensure your data is safe, please refrain from choosing a commonly
          used password such as, but not limited to, the examples below
        </span>
        <PasswordCheckerHelperContent />
      </DialogContent>
      <DialogActions>
        <DialogButton action="accept" onClick={togglePasswordHelpModal}>
          Got it!
        </DialogButton>
      </DialogActions>
    </Dialog>
    {isSubmitted && !isLoading && !!error && (
      <Notification
        type="danger"
        data-test="password-change-form-error-message"
        className="password-change-form__error-notification"
      >
        {getErrorMessage(errorName)} Please try again. If you need help, please{' '}
        <a href="/contact">contact support</a>.
      </Notification>
    )}
    {isSubmitted && isLoading && (
      <div>
        <MaterialLoadingIcon />
      </div>
    )}
    {!isLoading && (
      <>
        <Box width={{ sm: '100%', md: '25%' }}>
          <PasswordField
            label="Current Password"
            InputLabelProps={{ required: false }}
            name="oldPassword"
            value={oldPassword}
            onChange={handleChange}
          />
        </Box>
        <Box width={{ sm: '100%', md: '25%' }}>
          <PasswordField
            label="New Password"
            name="newPassword"
            InputLabelProps={{ required: false }}
            value={newPassword}
            onChange={handleChange}
          />
        </Box>

        <PasswordCriteria
          password={newPassword}
          onCheckIsPasswordSafe={handleCheckIsPasswordSafe}
          toggleModal={togglePasswordHelpModal}
        />
        <Box display="flex">
          <Box mr={2}>
            <Button variant="outlined" onClick={handleClearForm}>
              Cancel
            </Button>
          </Box>
          <Button
            type="submit"
            disabled={!isPasswordSafe}
            variant={isPasswordSafe ? 'contained' : 'outlined'}
            data-test="password-change-form-submit"
          >
            Save Password
          </Button>
        </Box>
      </>
    )}
  </form>
);

PasswordChangeForm.defaultProps = {
  isSubmitted: false,
  oldPassword: '',
  newPassword: '',
  isLoading: false,
  error: undefined,
  errorName: undefined,
  handleCheckIsPasswordSafe: () => {},
  isSnackbarOpen: false,
  toggleSnackbar: () => {},
  isPasswordHelpModalOpen: false,
  togglePasswordHelpModal: () => {},
};

PasswordChangeForm.propTypes = {
  handleClearForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  oldPassword: PropTypes.string,
  newPassword: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.objectOf(PropTypes.object),
  errorName: PropTypes.string,
  isPasswordSafe: PropTypes.bool.isRequired,
  handleCheckIsPasswordSafe: PropTypes.func,
  isSnackbarOpen: PropTypes.bool,
  toggleSnackbar: PropTypes.func,
  isPasswordHelpModalOpen: PropTypes.bool,
  togglePasswordHelpModal: PropTypes.func,
};

export default PasswordChangeForm;
