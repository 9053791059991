import React, { useEffect, useState } from 'react';
import Button from '@nutrien/uet-react/Button';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ordersActions } from '../../../../Orders/actions/actions';
import { productActions } from '../../../actions/actions';

import ProductCard from '../../ProductCard/ProductCard';

import useStyles from './styles';

const RecentlyOrdered = (props) => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const recentProducts = useSelector((state) => state.shop.recentProducts);
  const history = useHistory();
  const [uniqueArr, setArr] = useState([]);
  const classes = useStyles();
  const recentlyOrdered = true;
  let section;

  useEffect(() => {
    if (uniqueArr.length) {
      dispatch(
        productActions.getRecentProducts(
          `?page[num]=1&page[count]=30&filters[variantId]=${uniqueArr}`,
          selectedAccount,
          false,
          false,
          '',
          flags
        )
      );
    }
  }, [uniqueArr]);

  useEffect(() => {
    const orderFilters = {
      startDate: '1/1/2010',
      endDate: '12/31/2100',
      ordersLimit: '6',
      pageNum: '1',
      sortField: '-orderNumber',
    };
    if (!orders.length) {
      dispatch(ordersActions.getOrders(selectedAccount.number, orderFilters));
    }
  }, [selectedAccount]);

  useEffect(() => {
    const orderArr = [];

    orders.forEach((order) => {
      orderArr.push(order.attributes.products[0].productId);
    });

    const newUniqueArr = [...new Set(orderArr)];
    setArr(newUniqueArr);
  }, [orders]);

  const handleClick = () => {
    history.push('/orders');
  };

  if (recentProducts.loading) {
    section = (
      <div
        className={classes.loadingWrapper}
        data-test="recently-ordered-loading"
      >
        <MaterialLoadingIcon />
      </div>
    );
  } else if (recentProducts.data && !recentProducts.data.length) {
    return null;
  } else if (recentProducts.data) {
    section = recentProducts.data.map((item, index) => {
      const { id, attributes } = item;
      return (
        <ProductCard
          key={id}
          id={id}
          index={index}
          attributes={attributes}
          handleOpenModal={props.handleOpenModal}
          loadingPrices={recentProducts.loadingPrices}
          recentlyOrdered={recentlyOrdered}
        />
      );
    });
  } else {
    section = (
      <div data-test="recently-ordered-error">
        <ApiErrorMessage entityName="Products" />
      </div>
    );
  }

  return (
    <section
      data-test="recently-ordered-section"
      className={classes.noResultsOuterWrapper}
    >
      <h1>Recently Ordered</h1>
      <div className={classes.noResultsWrapper}>{section}</div>
      <div className={classes.buttonContainer}>
        {recentProducts.data && recentProducts.data.length ? (
          <Button
            className={classes.viewAllOrdersButton}
            onClick={handleClick}
            variant="contained"
            color="primary"
            data-test="view-all-orders-button"
          >
            View All Orders
          </Button>
        ) : null}
      </div>
    </section>
  );
};

RecentlyOrdered.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
};

export default RecentlyOrdered;
