import { GTM_FMS_BUTTON_SEEN } from '../gtmConstants';

export const buttonView = () => ({
  event: 'FMS_ViewFmsButton',
  fms: {},
});

export const fmsButtonEventsMap = {
  [GTM_FMS_BUTTON_SEEN]: buttonView,
};
