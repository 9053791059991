import React from 'react';
import { useDispatch } from 'react-redux';
import T from 'prop-types';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import { OpenLink, OpenPdfLink } from 'shared/utils/NutrienGFA';
import { CAN } from 'components/Shop/utils/contexts/locationCodeConstants';
import { useUserLocationContext } from 'components/Shop/utils/contexts/locationCodeContext';
import { Button } from '@nutrien/uet-react';
import { gtmViewLabel } from 'store/middleware/TagManager/gtmActions';
import MissingLabelWarning from '../MissingLabelWarning';
import { productDataPropType } from '../../ProductDetailPropTypes';

export const CANADIAN_LABEL_SEARCH_SITE =
  'http://pr-rp.hc-sc.gc.ca/ls-re/index-eng.php';

const isLabelMissing = (productData, isCanada) =>
  !productData.label && !isCanada;

const getLabelLink = (productData, isCanada) =>
  isCanada ? CANADIAN_LABEL_SEARCH_SITE : productData.label;

export const ProductInfoButtonContent = ({
  isCanada,
  labelText,
  productData,
}) => {
  const dispatch = useDispatch();
  const handleLabelClick = () => dispatch(gtmViewLabel(productData));
  return (
    <Button
      aria-label="View Label Information as PDF"
      onClick={() => handleLabelClick()}
    >
      <span className="product-info__product-label-pdf-icon">
        <Icon
          data-test="product-label-icon"
          icon={isCanada ? 'open_in_new' : 'picture_as_pdf'}
          type="nutrien-green"
        />
      </span>
      {labelText}
    </Button>
  );
};

const ProductInfoLabel = ({ productData }) => {
  const isCanada = useUserLocationContext() === CAN;
  const label = isCanada ? 'Find Label' : 'View Label as PDF';

  if (isCanada) {
    return (
      <OpenLink link={CANADIAN_LABEL_SEARCH_SITE}>
        <ProductInfoButtonContent
          isCanada
          labelText={label}
          productData={productData}
        />
      </OpenLink>
    );
  }

  return (
    <>
      <h2 className="product-info__product-label-pdf-header">
        Labels, SDS, and Licenses
      </h2>
      {isLabelMissing(productData, isCanada) ? (
        <MissingLabelWarning />
      ) : (
        <OpenPdfLink
          className="product-info__product-label-pdf-link"
          data-test="label-pdf"
          link={getLabelLink(productData, isCanada)}
          analyticsProps={{
            category: 'ProductDetailPage',
            label,
            detail: productData.id,
          }}
        >
          <ProductInfoButtonContent
            labelText={label}
            productData={productData}
          />
        </OpenPdfLink>
      )}
    </>
  );
};

ProductInfoButtonContent.propTypes = {
  isCanada: T.bool,
  labelText: T.string.isRequired,
  productData: productDataPropType.isRequired,
};

ProductInfoButtonContent.defaultProps = {
  isCanada: false,
};

ProductInfoLabel.propTypes = {
  productData: productDataPropType.isRequired,
};

export { ProductInfoLabel };

export default ProductInfoLabel;
