import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLocalAccessToken } from '@nutrien/fe-domain-utils';

import { getToken, removeToken, isEmployee } from 'shared/utils/tokenUtils';
import refreshSession from './refreshSession';

// stylesheets
import './SessionChecker.css';

class SessionChecker extends Component {
  state = {
    shouldDisplayWarning: false,
    lastActivity: Date.now(),
    time: '',
    seconds: 180,
    countdownTimer: null,
    checkTokenInterval: null,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.updateLastActivity);
    this.checkToken();
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location !== prevProps.router.location) {
      this.checkToken();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.updateLastActivity);
    clearInterval(this.state.countdownTimer);
    clearInterval(this.state.checkTokenInterval);
  }

  checkToken = () => {
    if (isEmployee()) {
      return false;
    }

    // initial set-up of check token interval
    if (this.state.checkTokenInterval === null) {
      // check the user's token every 3 minutes
      const checkTokenInterval = setInterval(() => {
        this.checkToken();
      }, 180000);

      // add checkTokenInterval to state
      this.setState({
        checkTokenInterval,
      });
    }

    let token;
    let expiration;
    try {
      token = getLocalAccessToken();
      expiration = getToken('token-expiration');
    } catch (e) {
      removeToken();
      this.props.logout();
    }
    // do not run if warning is already displayed
    if (this.state.shouldDisplayWarning) {
      return false;
    }

    // check that the user has a token
    if (token && expiration) {
      const expirationMilliseconds = expiration * 1000;
      // if the token has expired, logout the user
      if (expirationMilliseconds < Date.now()) {
        this.resetCountdown();
        this.props.logout('/', true);
      }

      // if the token is going to expire within 6 minutes,
      if (expirationMilliseconds - 360000 < Date.now()) {
        // if the user has engaged with the app within the past 5 minutes, refresh the session
        if (Date.now() < this.state.lastActivity + 300000) {
          return refreshSession();
        }
        // if the user hasn't engaged with the app in the past 5 minutes
        // start the countdown and display session timeout warning
        this.startCountdown();
        this.setState({
          shouldDisplayWarning: true,
        });
        return false;
      }
    }
    return null;
  };

  continueSession = () => {
    refreshSession();
    this.resetCountdown();
  };

  updateLastActivity = () => {
    this.setState({
      lastActivity: Date.now(),
    });
  };

  startCountdown = () => {
    const countdownTimer = setInterval(() => {
      this.updateCountdown();
    }, 1000);

    this.setState({
      time: this.secondsToMinutes(this.state.seconds),
      countdownTimer,
    });
  };

  resetCountdown = () => {
    clearInterval(this.state.countdownTimer);
    this.setState({
      seconds: 180,
      shouldDisplayWarning: false,
    });
  };

  secondsToMinutes = (secs) => {
    const min = Math.floor(secs / 60);
    let sec = secs - min * 60;
    sec = sec < 10 ? `0${sec}` : sec;
    return `${min}:${sec}`;
  };

  updateCountdown = () => {
    const seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToMinutes(seconds),
      seconds,
    });

    // If countdown reaches 0, logout and notify the user
    if (seconds === 0) {
      this.resetCountdown();
      this.props.logout('/', true);
    }
  };

  render() {
    if (isEmployee()) {
      return null;
    }

    if (this.state.shouldDisplayWarning && !window.ReactNativeWebView) {
      // allow user refresh session
      return (
        <div className="warning-modal-container" data-test="warning-modal">
          <div className="warning-modal">
            <h4>Still Here?</h4>
            Due to inactivity, your session will expire soon. Please click
            &quot;Continue&quot; if you&apos;d like to keep working.
            <div className="warning-modal__timer">{this.state.time}</div>
            <button
              className="warning-modal-button"
              onClick={this.continueSession}
            >
              Continue
            </button>
          </div>
        </div>
      );
    }

    return null;
  }
}

SessionChecker.propTypes = {
  logout: PropTypes.func.isRequired,
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default SessionChecker;
