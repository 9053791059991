import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { setSelectedVariant } from '../../../actions/productDetailActions/productDetailActions';
import { gtmViewPDP } from '../../../../../store/middleware/TagManager/gtmActions';

export const sortActiveIngredientsByPercentageDesc = (activeIngredients) =>
  sortBy(activeIngredients, [
    (ingredient) => -get(ingredient, 'percentage', 0),
  ]);

export const gtmSetSelectedVariant = (dispatch, productData) => (variant) => {
  dispatch(setSelectedVariant(variant));
  dispatch(gtmViewPDP(productData, variant));
};

export const hasManufacturerFunc = (productData) =>
  !!get(productData, 'attributes.manufacturer.name');

export const getManufacturerClassName = (hasManufacturer) =>
  hasManufacturer
    ? 'product-info__product-manufacturer'
    : 'product-info__product-manufacturer--missing';
