import moment from 'moment';

/**
 * Acquires the permit's expiration date and returns it as a Moment object, set to 12 o'clock midnight (start of day)
 * @param  {object} permit A permit object
 * @return {Moment}        A moment object representing the date.
 */
export const getExpirationDate = (permit) =>
  moment(permit.expirationDate, 'YYYY-M-D').startOf('day');

/**
 * Returns true if the permit will expire in the next 60 days _and_ is not already expired.
 * @param  {object} permit A permit object
 * @return {Boolean}       True if permit is expiring soon, false if already expired, or
 *                         not expiring soon.
 */
export const isPermitExpiringSoon = (permit) => {
  const expirationWarningDate = moment().add(60, 'days').startOf('day');
  const expirationDate = getExpirationDate(permit);

  return (
    expirationWarningDate.isSameOrAfter(expirationDate) &&
    moment().isSameOrBefore(expirationDate)
  );
};

/**
 * Returns true if the permit's expiration date is older than today.
 * @param  {object} permit A permit object
 * @return {Boolean}       Whether or not the permit is expired.
 */
export const isPermitExpired = (permit) => {
  const today = moment().startOf('day');
  const expirationDate = getExpirationDate(permit);
  return expirationDate.isBefore(today);
};
