import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { getToken } from 'shared/utils/tokenUtils';
import { isGFA } from 'shared/utils/NutrienGFA';
import { selectGfaVersion } from 'selectors';

const useIsAuth0Active = () => {
  const flags = useFlags();
  const gfaVersion = useSelector(selectGfaVersion);
  const isMirroring =
    !!Cookies.get('exhSession') || !!getToken('mirrored-user');

  const isNative = isGFA({});
  // version 1.3 of the native wrapper supports Auth0
  // versions older than 1.3 of the native app don't reliably supply a version
  const isNativeAuth0Supported =
    !!gfaVersion &&
    isGFA({
      appVersion: gfaVersion,
      requestedVersion: '>=1.3.0',
    });
  const isAuth0Active = isNative
    ? isNativeAuth0Supported
    : !isMirroring && flags.auth0LoginEnabled;

  return isAuth0Active;
};

export default useIsAuth0Active;
