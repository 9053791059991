import {
  SET_MARKET_OK,
  SET_MARKET_ERROR,
  SET_MARKET_LOADING,
} from './MarketActions';

export function setMarketOk(market) {
  return { type: SET_MARKET_OK, payload: market };
}

export function setMarketLoading() {
  return { type: SET_MARKET_LOADING };
}

export function setMarketError(err) {
  return { type: SET_MARKET_ERROR, payload: err };
}
