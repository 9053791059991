import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    zIndex: 99999,
  },
};

export default withStyles(styles)(Dialog);
export { DialogActions, DialogContent, DialogContentText, DialogTitle };
