import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setPaymentFlowType } from 'components/Payments/Redux/actions/BankAccountFormActions';
import AccountsTable from './AccountsTable';
import { fetchAssociatedOrgsAction } from './actions/AccountActions';
import { updateBankAccountForm } from '../../../components/Payments/Redux/actions/BankAccountFormActions';
import { navigateToAccountPage } from './actions';

import './styles.scss';

const mapStateToProps = (state) => ({
  accounts: state.accounts,
  user: state.user.userData,
});

const mapDispatchToProps = {
  navigateToAccountPage,
  fetchAssociatedOrgsAction,
  push,
  setPaymentFlowType,
  updatePaymentForm: updateBankAccountForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsTable);
