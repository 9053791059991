import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { setPaymentFlowType } from 'components/Payments/Redux/actions/BankAccountFormActions';
import UserPropType from 'shared/config/userPropType';
import { gtmMakeInvoicePayment } from 'store/middleware/TagManager/gtmActions';
import { stateInvoicePropType, invoiceMatchParams } from './invoicePropTypes';
import { handleInvoiceUpdate } from './actions';
import { invoicedAmountDue } from './selectors';
import {
  selectInvoice,
  clearSelectedInvoices,
} from '../Account/Invoices/actions';

import Invoice from './Invoice';

import './Invoice.css';

export class InvoiceContainer extends Component {
  state = {
    pdfDownloading: false,
    csvDownloading: false,
    pdfDownloadError: false,
    csvDownloadError: false,
  };

  componentDidMount() {
    if (this.props.user.id) {
      this.handleInvoiceUpdateWithParams();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.id !== prevProps.user.id && this.props.user.id) {
      this.handleInvoiceUpdateWithParams();
    }
  }

  handleInvoiceUpdateWithParams() {
    // TODO: should we be grabbing invoiceId from the params or from the invoice state object?
    const { accountId, invoiceId } = this.props.match.params;

    this.props.handleInvoiceUpdate(accountId, invoiceId);
  }

  handleCloseError = () => {
    this.setState({ csvDownloadError: false, pdfDownloadError: false });
  };

  render() {
    return (
      <Invoice
        invoiceId={this.props.match.params.invoiceId}
        handleCloseError={this.handleCloseError}
        {...this.state}
        // passes account, invoice, accountTab, selectInvoice, setPaymentFlowType, and push
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.selectedAccount,
  invoice: state.invoice,
  accountTab: state.accountTab,
  user: state.user.userData,
  invoicedAmountDue: invoicedAmountDue(state),
});

const mapDispatchToProps = {
  push,
  handleInvoiceUpdate,
  selectInvoice,
  setPaymentFlowType,
  clearInvoices: clearSelectedInvoices,
  gtmMakeInvoicePayment,
};

InvoiceContainer.propTypes = {
  handleInvoiceUpdate: PropTypes.func.isRequired,
  user: UserPropType.isRequired,
  match: invoiceMatchParams.isRequired,
  invoice: stateInvoicePropType.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceContainer);
