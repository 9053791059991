import apiAdapter from 'shared/lib/cxhApiService';

export default (accountIds, paperlessPreferences) =>
  Promise.all(
    accountIds.map((accountId) =>
      apiAdapter
        .put(`v1/accounts/${accountId}/preferences`, {
          preferences: paperlessPreferences,
        })
        .then((res) => res.data)
    )
  );
