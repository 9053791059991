import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FeedbackFAB from 'shared/ui/Feedback/FeedbackFAB';
import SupportFAB from 'shared/ui/Feedback/SupportFAB';
import { IntercomMiddleware } from 'shared/ui/Feedback/IntercomMiddleware';

const FeedbackWrapper = () => {
  const { supportFab } = useFlags();
  return window.ReactNativeWebView ? (
    <FeedbackFAB />
  ) : (
    <>
      <IntercomMiddleware />
      {supportFab && <SupportFAB />}
    </>
  );
};

export default FeedbackWrapper;
