import get from 'lodash/get';
import {
  setProductHistoryAddingToCart,
  setProductHistoryReorderSuccess,
  setProductHistoryReorderFailure,
} from '../../../ProductHistoryActionCreators';
import { addToCart, updatingCart } from '../../../../Cart/actions/cartActions';
import ecommApiService from '../../../../Shop/utils/ecommApiService';

export const reorderProduct = (cartItem, cartData) => async (dispatch) => {
  dispatch(updatingCart(true));
  const variantId = cartItem.variant.ars_key;
  dispatch(setProductHistoryAddingToCart(variantId, cartItem.shelf));
  const url = `/v2/products?include=variant&fields[variant]=isBulk&filters[variantId]=${variantId}`;
  return ecommApiService
    .get(url, {
      transformRequest: [
        (data, headers) => {
          // eslint-disable-next-line
          headers['account-id'] = cartItem.accountID || '';
          // eslint-disable-next-line
          headers['location-id'] = cartItem.branchID || '';
          return data;
        },
      ],
    })
    .then((res) => {
      const variants = get(res, 'data.data[0].variants', []).filter((v) => !!v);
      const variantObj =
        variants.find((i) => i.id === cartItem.variant.ars_key) || {};
      if (variantObj.isBulk) {
        // eslint-disable-next-line no-param-reassign
        cartItem.variant.selectedVolume = 100;
      }
      dispatch(addToCart(cartItem, cartData, { origin: 'Reorder button' }));
    });
};

export const setProductReorderSuccess = () => (dispatch) => {
  dispatch(setProductHistoryReorderSuccess());
};

export const setProductReorderFailure = (error, selectedShelf) => (
  dispatch
) => {
  dispatch(setProductHistoryReorderFailure(error, selectedShelf));
};
