import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import { Redirect, useHistory } from 'react-router';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import { Icon } from 'nutrien-common-components-react/dist/mdc/';
import numeral from 'numeral';
import { NO_PRICE_CART_MESSAGE } from '../../../../shared/constants/ecommMessageText';
import handlePrint from '../../../../utils/handlePrint';

import OrderTable from './OrderTable';
import MaterialLoadingIcon from '../../../../shared/ui/RebrandedMaterialLoadingIconInline/MaterialLoadingIcon';
import PageHeader from '../../../../shared/ui/PageHeader/PageHeader';

import { ordersActions } from '../../actions/actions';

import './individualOrder.scss';

const IndividualOrder = () => {
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const accounts = useSelector((state) => state.accounts);
  const orders = useSelector((state) => state.orders.orders);
  const loading = useSelector((state) => state.orders.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  const [order, setOrder] = useState([]);

  const setCurrentOrder = (order) => {
    setOrder(order[0]);
  };
  const getCurrentOrder = () => {
    const {
      location: { pathname },
    } = history;
    const orderNumber = pathname.split('/')[2];
    // eslint-disable-next-line react/prop-types
    const match = orders.filter((order) => order.id === orderNumber);

    setCurrentOrder(match);
  };

  useEffect(() => {
    const orderFilters = {
      startDate: '1/1/2010',
      endDate: '12/31/2100',
      ordersLimit: '999',
      sortField: '-orderDate',
    };
    const accountNumber = get(selectedAccount, 'id', '');

    dispatch(ordersActions.getOrders(accountNumber, orderFilters));
  }, [selectedAccount]);

  useEffect(() => {
    if (orders.length && !order.id) {
      getCurrentOrder();
    }
  }, [orders, order]);

  const renderOrders = (order) => {
    const {
      cropConsultant: { firstName, lastName, workEmailAddress },
    } = selectedAccount;
    const {
      attributes: { products, accountNumber },
    } = order;
    const { list } = accounts;
    const relevantAccount = list.find(
      (account) => account.id === accountNumber
    );

    const nonPriced = [];
    const priced = [];
    products.forEach((product) => {
      if (product.netUnitPrice === 0) {
        nonPriced.push(product);
      } else {
        priced.push(product);
      }
    });

    const orderHeading = `Order Request #${order.id}`;

    const section = (
      <section className="order-page-wrapper">
        <PageHeader
          title={orderHeading}
          breadcrumbs={[
            {
              link: '/',
              label: 'Home',
            },
            {
              link: '/orders',
              label: 'Online Orders',
            },
            {
              label: orderHeading,
            },
          ]}
        />
        <div className="orders-page__header">
          <div className="order-page__status-wrapper">
            <span className="order-page__status">
              Status: {order.attributes.orderStatus}
            </span>
          </div>
          <div
            className="orders-page__print-wrapper"
            onClick={handlePrint}
            onKeyPress={handlePrint}
            role="button"
            tabIndex="0"
          >
            <Icon
              icon="print"
              type="success"
              className="orders-page__print-icon"
            />
            <span className="orders-page__print-tout">Print</span>
          </div>
          <div className="orders-page__consultant-wrapper">
            <div className="orders-page__consultant-detail">
              <span className="orders-page__consultant-detail__headline">
                Nutrien Ag Solutions Representative
              </span>
              <span className="orders-page__consultant-detail__info">
                {firstName} {lastName}
              </span>
            </div>
            <div className="orders-page__consultant-detail">
              <span className="orders-page__consultant-detail__headline">
                Email
              </span>
              <span className="orders-page__consultant-detail__info">
                {workEmailAddress}
              </span>
            </div>
          </div>
        </div>
        <div className="orders-page__section">
          <div className="orders-page__info-wrapper">
            <span className="orders-page__info-headline">Order Date</span>
            <span className="orders-page__info--hide-print">
              {moment(order.attributes.orderDate).format('dddd, MMMM DD, YYYY')}
            </span>
            <span className="orders-page__info--print">
              {moment(order.attributes.orderDate).format('MMMM DD, YYYY')}
            </span>
          </div>
          <div className="orders-page__info-wrapper">
            <span className="orders-page__info-headline">Account Name</span>
            <span className="orders-page__info">{relevantAccount.name}</span>
          </div>
          <div className="orders-page__info-wrapper">
            <span className="orders-page__info-headline">Account Number</span>
            <span className="orders-page__info">
              {order.attributes.accountNumber}
            </span>
          </div>
          <div className="orders-page__info-wrapper">
            <span className="orders-page__info-headline">
              Purchase Order Number
            </span>
            <span className="orders-page__info">
              {order.attributes.poNumber ? order.attributes.poNumber : 'N/A'}
            </span>
          </div>
        </div>
        <div className="orders-table-outer-wrapper">
          {priced.length > 0 && (
            <>
              <div className="orders-table__headline-wrapper">
                <span className="orders-table__headline">Priced Items</span>
                <span className="orders-table__price--mobile">
                  {numeral(order.attributes.netOrderTotal).format('$0,0.00')}
                </span>
              </div>
              <OrderTable products={priced} />
              <div className="orders-table__total-wrapper">
                <span
                  className="orders-table__price"
                  data-test="orders-table-price"
                >
                  {numeral(order.attributes.netOrderTotal).format('$0,0.00')}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="orders-table-outer-wrapper">
          {nonPriced.length > 0 && (
            <>
              <div className="orders-table__headline-wrapper">
                <span className="orders-table__headline">Unpriced Items</span>
                <span className="orders-table__price--mobile">
                  {NO_PRICE_CART_MESSAGE}
                </span>
              </div>
              <OrderTable products={nonPriced} />
              <div className="orders-table__total-wrapper">
                <span className="orders-table__price">
                  {NO_PRICE_CART_MESSAGE}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="order-page__contact-wrapper">
          <span>For additional support, please contact:</span>
          <span>digital.support@nutrien.com</span>
        </div>
      </section>
    );

    return section;
  };

  const renderError = () => {
    const section = (
      <div data-test="order-page-error">
        <ApiErrorMessage entityName="Orders" />
      </div>
    );
    return section;
  };

  const renderLoading = () => {
    const section = (
      <div
        data-test="order-page-loading"
        className="order-page__loading-wrapper"
      >
        <MaterialLoadingIcon />
      </div>
    );
    return section;
  };

  const accountNumber = get(
    order,
    'attributes.accountNumber',
    selectedAccount.id
  );
  let section;

  if (loading) {
    section = renderLoading();
  } else if (order && order.id) {
    section = renderOrders(order);
  } else {
    section = renderError();
  }

  if (accountNumber !== selectedAccount.id) return <Redirect to="/orders" />;

  return <>{section}</>;
};

export default IndividualOrder;
