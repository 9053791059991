export const PRODUCT_HISTORY_OK = 'PRODUCT_HISTORY_OK';
export const PRODUCT_HISTORY_LOADING = 'PRODUCT_HISTORY_LOADING';
export const PRODUCT_HISTORY_ERROR = 'PRODUCT_HISTORY_ERROR';
export const PRODUCT_HISTORY_DOWNLOADED_CSV = 'PRODUCT_HISTORY_DOWNLOADED_CSV';
export const PRODUCT_HISTORY_ADDING_TO_CART = 'PRODUCT_HISTORY_ADDING_TO_CART';
export const PRODUCT_HISTORY_SORTED = 'PRODUCT_HISTORY_SORTED';
export const PRODUCT_HISTORY_ADD_TO_CART_SUCCESS =
  'PRODUCT_HISTORY_ADD_TO_CART_SUCCESS';
export const PRODUCT_HISTORY_ADD_TO_CART_FAILURE =
  'PRODUCT_HISTORY_ADD_TO_CART_FAILURE';
export const PRODUCT_HISTORY_NEW_SHELF = 'PRODUCT_HISTORY_NEW_SHELF';
export const PRODUCT_HISTORY_NEW_TIMEFRAME = 'PRODUCT_HISTORY_NEW_TIMEFRAME';
export const PRODUCT_HISTORY_HUBSPOT_OPT_IN_SUCCESS =
  'PRODUCT_HISTORY_HUBSPOT_OPT_IN_SUCCESS';
export const VIEW_PRODUCT_HISTORY = 'VIEW_PRODUCT_HISTORY';
