const styles = {
  root: {
    backgroundColor: '#FFFFFF',
  },
  label: {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    fontSize: 22,
    userSelect: 'none',
  },
  description: {
    marginLeft: 25,
    height: 15,
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.15,
    textAlign: 'left',
    color: '#828282',
  },
  link: {
    color: '#4da400',
  },
};

export default styles;
