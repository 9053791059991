import getNativeContainer from 'shared/utils/getNativeContainer';

const makeNativeRequest = async (requestName, requestPayload) => {
  const container = getNativeContainer();

  return new Promise((resolve, reject) => {
    if (!window.ReactNativeWebView) {
      reject(new Error('React Native Web View not found'));
    }

    const handleResponse = (e) => {
      if (typeof e.data === 'string') {
        const { name: responseName, payload: responsePayload } = JSON.parse(
          e.data
        );
        if (responseName === requestName) {
          container.removeEventListener('message', handleResponse);
          if (responsePayload?.error) {
            reject(new Error(responsePayload.error));
          }
          resolve(responsePayload);
        }
      }
    };
    container.addEventListener('message', handleResponse);

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        name: requestName,
        payload: requestPayload,
      })
    );
  });
};

export default makeNativeRequest;
