import downloadFile from 'shared/utils/DownloadFile';
import * as invoicesService from '../invoicesService';
import downloadInvoicesFailure from './downloadInvoicesFailure';
import downloadInvoicesInProgress from './downloadInvoicesInProgress';
import downloadInvoicesSuccess from './downloadInvoicesSuccess';

/**
 * Downloads a list of invoices as a zip of pdfs
 * @param accountId - The id of the account the invoices belong to
 * @param invoiceIds - The ids of the invoices to download as pdfs
 */
export default (accountId, invoiceIds) => async (dispatch) => {
  dispatch(downloadInvoicesInProgress());

  try {
    let response;

    if (invoiceIds.length > 1) {
      response = await invoicesService.getInvoicesAsPDF(accountId, invoiceIds);

      if (response && response.data) {
        dispatch(downloadInvoicesSuccess());
        downloadFile(
          response.data,
          'invoices.zip',
          'application/zip',
          'invoices-download-id'
        );
      } else {
        dispatch(downloadInvoicesFailure());
      }
    } else {
      response = await invoicesService.getInvoiceAsPDF(
        accountId,
        invoiceIds[0]
      );

      if (response && response.data) {
        dispatch(downloadInvoicesSuccess());
        const invoiceId = invoiceIds[0];

        downloadFile(
          response.data,
          `invoice-${invoiceId}.pdf`,
          'application/pdf',
          'invoices-download-id',
          {
            category: 'Invoices',
            label: 'PDF',
            detail: invoiceId,
          }
        );
      } else {
        dispatch(downloadInvoicesFailure());
      }
    }
  } catch (error) {
    dispatch(downloadInvoicesFailure());
  }
};
