import get from 'lodash/get';
import { gtmCurrency } from './gtmCurrency';
import { CUSTOM_DIMENSIONS } from '../gtmConstants';

/**
 * Converts a product into an ImpressionFieldObject as defined by
 * the Enhanced Ecommerce documentation:
 * https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
 *
 * @param product - The product as returned by the /products endpoint in ecomm api
 * @param listName - Where the product is seen e.g. "Product Listing Page"
 * @param zeroIndexedPosition - position of product, MUST BE ZERO-INDEXED.
 */
export const productToImpressionFieldObject = (
  product,
  variant,
  listName,
  zeroIndexedPosition
) => {
  const position = Number.isInteger(zeroIndexedPosition)
    ? zeroIndexedPosition + 1 // convert the index to 1-indexed for GA reporting
    : undefined;

  const price = gtmCurrency(get(variant, 'price', 0));

  return {
    id: get(product, 'attributes.guid'),
    name: get(product, 'attributes.name'),
    list: listName,
    brand: get(product, 'attributes.manufacturer.name'),
    category: get(product, 'attributes.product_category.name'),
    position,
    variant: get(variant, 'guid'),
    price,
    [CUSTOM_DIMENSIONS.variantDescription]: get(variant, 'description'),
    [CUSTOM_DIMENSIONS.variantIsPriced]: price !== '0.00',
  };
};

export default productToImpressionFieldObject;
