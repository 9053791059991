export const GET_SCHEDULED_PAYMENTS = 'GET_SCHEDULED_PAYMENTS';
export const DELETE_SCHEDULED_PAYMENT = 'DELETE_SCHEDULED_PAYMENT';
export const CLEAR_DELETE_STATUS = 'CLEAR_DELETE_STATUS';
export const GET_RECURRING_PAYMENTS = 'GET_RECURRING_PAYMENTS';

export const STATUS = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  DELETING: 'DELETING',
};
