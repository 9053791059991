export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const LOADING_REGISTRATION = 'LOADING_REGISTRATION';
export const REGISTER_EXISTING_SUCCESS = 'REGISTER_EXISTING_SUCCESS';
export const REGISTER_EXISTING_FAILURE = 'REGISTER_EXISTING_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const PREREGISTER_SUCCESS = 'PREREGISTER_SUCCESS';
export const PREREGISTER_START = 'PREREGISTER_START';
export const PREREGISTER_FAILURE = 'PREREGISTER_FAILURE';
export const PREREGISTER_REDIRECT = 'PREREGISTER_REDIRECT';
