import React from 'react';
import PropTypes from 'prop-types';
import { Card, Box, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ApiErrorMessage, MaterialLoadingIcon } from 'shared/ui';
import withLicenses from 'components/Licenses/withLicenses';
import { licenseArrayPropType } from 'components/Licenses/licensesPropTypes';
import { Icon } from 'nutrien-common-components-react/dist/mdc/';
import './LicensesCard.scss';

export const LicensesCard = ({
  fetchLicensesError,
  isFetchingLicenses,
  licenses,
}) => (
  <Card component={Box} p={2} height="100%">
    {isFetchingLicenses && <MaterialLoadingIcon />}
    {fetchLicensesError && <ApiErrorMessage entityName="Licenses" />}
    {!fetchLicensesError && !isFetchingLicenses && (
      <Link to="/licenses">
        <div className="licenses-card__wrapper">
          <h5 className="card-title-2">Licenses</h5>
          <div
            className="licenses-card__center-content"
            data-test="licensesCardIcon"
          >
            <div className="test">
              <Icon
                icon="description"
                type="nutrien-green"
                className="licenses-card__icon"
              />
            </div>
          </div>
          <div className="licenses-card__license-count-wrapper">
            <div
              className={classNames(
                'licenses-card__center-content',
                'licenses-card__license-count-content'
              )}
            >
              <h6
                className="licenses-card__license-count-text"
                data-test="licensesCardLicenseCount"
              >
                {licenses.length}
              </h6>
              <p className="licenses-card__license-count-description">
                total licenses
              </p>
            </div>
          </div>
          <span className="licenses-card__view-all-link">
            <Button variant="text">
              View All Licenses
              <ChevronRightIcon />
            </Button>
          </span>
        </div>
      </Link>
    )}
  </Card>
);

LicensesCard.propTypes = {
  fetchLicensesError: PropTypes.instanceOf(Error),
  licenses: licenseArrayPropType,
  isFetchingLicenses: PropTypes.bool,
};

LicensesCard.defaultProps = {
  fetchLicensesError: undefined,
  licenses: [],
  isFetchingLicenses: false,
};

export default withLicenses(LicensesCard);
