import { getAccessToken, currentAuthContext } from '@nutrien/fe-domain-utils';

import resolveAudience from 'utils/resolveAuth0Audience';

const authenticationInterceptor = async (config) => {
  if (!currentAuthContext.isAuth0Active) {
    return config;
  }

  const audience = resolveAudience();
  const accessToken = await getAccessToken({ audience });

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `AccessToken ${accessToken}`,
    },
  };
};

export default authenticationInterceptor;
