import React from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import './MaterialLoadingIcon.css';

const MaterialLoadingIconInline = (props) => {
  const { noPadding, ...rest } = props;
  return (
    <div
      className={`${
        noPadding
          ? `MaterialLoadingIconInlineWrapperNoPadding`
          : `MaterialLoadingIconInlineWrapper`
      }`}
    >
      <CircularProgress
        {...rest}
        size="1em"
        left={70}
        top={0}
        thickness={8}
        style={{ color: '#4c9e00' }}
      />
    </div>
  );
};

MaterialLoadingIconInline.propTypes = {
  noPadding: PropTypes.bool,
};

MaterialLoadingIconInline.defaultProps = {
  noPadding: false,
};

export default MaterialLoadingIconInline;
