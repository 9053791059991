export default {
  addNewBankAccountTitle: {
    fontSize: '32px',
    fontWeight: '600',
    width: '100%',
    padding: '20px 0',
  },
  addPaymentHeaderContainer: {
    width: '100%',
    padding: '0 20px 60px 20px',
  },
  addNewBankAccountSubTitle: {
    marginTop: '20px',
    fontSize: '24px',
  },
  addBankAccountFooterContainer: {
    borderTop: '1px solid #cccccc',
    width: '100%',
    margin: '0',
    padding: '10px 0',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  addAccountButton: {
    width: '135px',
    height: '38px',
  },
  cancelButton: {
    width: '135px',
    height: '38px',
    marginRight: '15px',
  },
  errorSeparator: {
    marginTop: '20px',
  },
  maxLimitText: {
    paddingRight: '10px',
  },
  overMaxPaymentAccounts: {
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '17px 0 15px 12px',
    borderRadius: '4px',
    border: 'solid 1px #d24242',
    backgroundColor: 'rgba(248, 79, 79, 0.1)',
    marginBottom: '20px',
  },
  warningIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
  withSelector: {
    minHeight: '400px',
  },
  '@media (max-width: 1050px)': {
    addPaymentHeaderContainer: {
      padding: '0 20px 20px 20px',
    },
  },
  '@media (max-width: 480px)': {
    withSelector: {
      minHeight: ['570px', '!important'],
    },
  },
};
