import { LOCATION_CHANGE } from 'connected-react-router';
import {
  GTM_GFA_EVENT,
  GTM_GFA_SHARE,
  GTM_GFA_SCREEN_VIEW,
} from '../gtmConstants';

export const checkSSO = (data) => {
  if (
    window.ReactNativeWebView &&
    data.payload.location.pathname === '/sso' &&
    !window.dataLayer.some((x) => x.event === 'app_load')
  ) {
    window.dataLayer.push({
      event: 'app_load',
      application: {
        type: 'app',
      },
    });
  }
};

export const handleScreenView = (data) => {
  const { screen } = data.payload;
  if (screen) {
    window.dataLayer.push({
      event: 'page_load',
      page: {
        name: screen,
      },
    });
  }
};

export const handleEvent = (data, type = 'standard') => {
  const { event, eventId, category, action, label, share } = data.payload;
  const shareEventFields = type === 'share' && { share };

  if (event) {
    window.dataLayer.push({
      event,
      eventId,
      category,
      action,
      label,
      ...shareEventFields,
    });
  }
};

export const handleShare = (data) => handleEvent(data, 'share');

export const gfaEventsMap = {
  [LOCATION_CHANGE]: checkSSO,
  [GTM_GFA_SCREEN_VIEW]: handleScreenView,
  [GTM_GFA_EVENT]: handleEvent,
  [GTM_GFA_SHARE]: handleShare,
};
