import linkAccountStyles from '../../LinkAccount/styles';

export default (theme) => ({
  prospectLandingCard: {
    marginBottom: '1rem',
    zIndex: 2,
  },
  headerContainer: {
    display: 'block',
    textAlign: 'center',
  },
  header: {
    marginTop: 16,
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 24,
    },
  },
  callToAction: {
    textAlign: 'center',
    marginTop: 56,
    marginBottom: 32,
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      marginTop: 0,
      marginBottom: 24,
    },
  },
  callToActionTop: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: 24,
    },
  },
  callToActionBottom: {
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  addAccountButtonContainer: linkAccountStyles(theme).linkAccountContainer,
  columnHeader: {
    textAlign: 'center',
  },
  columnSubHeader: {
    fontSize: 16,
    margin: 0,
    textAlign: 'center',
    padding: '0 24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      width: '70%',
      margin: '0 auto',
      marginBottom: 32,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  helpImageContainer: {
    textAlign: 'center',
  },
  helpImage: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  openForBusiness: {
    marginTop: '13em',
    marginBottom: '1rem',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: '12em',
    },
  },
});
