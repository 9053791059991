import orderBy from 'lodash/orderBy';

export default (invoicedProducts) => {
  const foundIds = new Map();
  let result = [];
  invoicedProducts.forEach((element) => {
    if (!foundIds.has(element.productId)) {
      foundIds.set(element.productId, true);

      // Get all invoices that the product appears in, that will be used for
      // filtering
      const invoices = invoicedProducts
        .filter((product) => product.productId === element.productId)
        .map((product) => product.invoiceId);

      result.push({
        productId: element.productId,
        productName: element.productName,
        invoices,
      });
    }
  });

  result = orderBy(result, ['productName', 'productId'], ['asc', 'asc']);

  return result || [];
};
