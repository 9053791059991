import React from 'react';
import PropTypes from 'prop-types';
import { currency } from 'shared/utils/numberFormatters';
import { DateFormatter } from 'shared/ui';
import { invoicePropType } from '../invoicePropTypes';

const InvoiceSummary = ({ invoice, invoicedAmountDue }) => {
  const discountDate = DateFormatter(invoice.discountDate);
  return (
    <div className="accounting-total">
      <table>
        <tbody>
          <tr>
            <th>Invoice Sub Total:</th>
            <td>{currency(invoice.subTotal)}</td>
          </tr>
          <tr>
            <th>Sales Tax:</th>
            <td>{currency(invoice.salesTax)}</td>
          </tr>
          <tr>
            <th>Invoice Total:</th>
            <td>{currency(invoice.total)}</td>
          </tr>
          <tr>
            <th>Less Prepay Used:</th>
            <td>{currency(invoice.lessPrepayUsed)}</td>
          </tr>
          <tr>
            <th>Less Prepay Discount:</th>
            <td>{currency(invoice.lessPrepayDiscount)}</td>
          </tr>
          <tr>
            <th>Gross Invoice Total:</th>
            <td>{currency(invoice.grossInvoiceAmount)}</td>
          </tr>
          {!!invoice.totalPotentialDiscount && (
            <tr data-test="invoice-summary-total-potential-discount">
              <th>
                {`Less Potential Net Discount if paid by ${discountDate}`}:
              </th>
              <td>{currency(invoice.totalPotentialDiscount)}</td>
            </tr>
          )}
          <tr>
            <th>Amount Due (at time of invoice):</th>
            <td data-test="invoice-summary-amount-due">
              {currency(invoicedAmountDue)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

InvoiceSummary.propTypes = {
  invoice: invoicePropType.isRequired,
  invoicedAmountDue: PropTypes.string.isRequired,
};

export default InvoiceSummary;
