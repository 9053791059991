const styles = (theme) => ({
  backButton: {
    fontSize: '14px',
    width: '181px',
    margin: '0 15px 0 0',
    '@media (max-width: 825px)': {
      width: '100%',
      order: '1',
      marginRight: '0',
    },
  },
  hidden: {
    display: 'none',
  },
  hover: {
    background: 'none',
    border: 'none',
    fontSize: '14px',
    fontWeight: '600',
    padding: '0',
    marginLeft: '0',
    display: 'inline-block',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:focus': {
      outline: 'none',
      color: theme.palette.secondary.main,
    },
  },
  imageIcon: {
    marginRight: '10px',
  },
  invoiceText: {
    display: 'inline',
  },
  reviewPayment: {
    width: '100%',
  },
  reviewPaymentContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px',
    width: '100%',
    '& label': {
      top: '4px',
    },
  },
  reviewPaymentCheckingDetails: {
    marginBottom: '35px',
  },
  reviewPaymentDetails: {
    marginBottom: '20px',
  },
  reviewPaymentFooterBottom: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    flexGrow: '2',
    minHeight: '100px',
  },
  reviewPaymentFooterContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    width: '100%',
    borderTop: '1px solid #cccccc',
  },
  reviewPaymentNotesContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  reviewPaymentNotesHeader: {
    color: '#828282',
    fontSize: '12px',
    marginBottom: '10px',
  },
  reviewPaymentSelectedAmount: {
    color: theme.palette.text.primary,
    fontSize: '32px',
    marginBottom: '11px',
  },
  reviewOverpaymentStatement: {
    borderRadius: '4px',
    backgroundColor: 'rgba(2, 113, 234, 0.1)',
    border: 'solid 1px #0271ea',
    padding: '12px',
    marginBottom: '30px',
  },
  termsAndConditionsContainer: {
    justifySelf: 'flex-start',
    display: 'inline-flex',
    flexDirection: 'column',
    flexGrow: '2',
  },
  termsAndConditionsContainerError: {
    flexGrow: '2',
  },
  termsAndConitionsErrorMessage: {
    fontSize: '12px',
    color: theme.palette.error.main,
    padding: '5px 0',
    width: '100%',
  },
  termsAndConditionsLink: {
    background: 'none',
    border: 'none',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '600',
    padding: '0',
    marginLeft: '0',
    display: 'inline-block',
    '&:focus': {
      outline: 'none',
      color: theme.palette.primary.secondary,
    },
  },
  termsAndConditionsLinkContainer: {
    display: 'inline-block',
  },
  termsAndConditionsCheckbox: {
    padding: 0,
    marginRight: 8,
  },
  termsAndConditionsText: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2px',
    width: '100%',
  },
  '@media (max-width: 825px)': {
    reviewPaymentContainer: {
      margin: '20px 20px 44px 20px',
    },
    reviewPaymentFooterBottom: {
      flexDirection: 'column',
    },
    reviewPaymentFooterContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flexGrow: '0',
      justifyContent: 'flex-end',
    },
    termsAndConditionsContainer: {
      width: '100%',
      borderBottom: '1px solid #cccccc',
      marginBottom: '30px',
      marginTop: '0',
      flexGrow: '0',
      justifyContent: 'flex-end',
      paddingBottom: '20px',
      height: '85px',
    },
    termsAndConditionsContainerError: {
      width: '100%',
      borderBottom: '1px solid #cccccc',
      marginBottom: '30px',
      marginTop: '0',
      flexGrow: '0',
      justifyContent: 'flex-end',
      paddingBottom: '20px',
      height: '125px',
    },
  },
  '@media (max-width: 480px)': {
    reviewPaymentContainer: {
      height: 'auto',
      margin: '20px 20px 0 20px',
      paddingBottom: '20px',
    },
  },
  '@media (max-width: 460px)': {
    termsAndConditionsContainer: {
      height: '130px',
      paddingBottom: '30px',
    },
  },
});

export default styles;
