import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import DownCaret from '@material-ui/icons/KeyboardArrowDown';
import merge from 'lodash/merge';
import { currency } from 'shared/utils/numberFormatters';
import { withStyles } from '@material-ui/core';

import { InvoicesTable } from 'components/Account/Invoices';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import { COUNTRY_CODES } from '../../../../PaymentConstants';

import commonStyles from '../../../../commonPaymentStyles';
import styles from './styles';

const finalStyles = (theme) => merge({}, commonStyles(theme), styles(theme));

export class InvoiceAccountInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
    };
  }

  componentDidMount() {
    const {
      onChange,
      selectedInvoices,
      accounts,
      selectedAccount,
      cpsAccount,
      invoices,
      getInvoices,
      getInvoiceDetails,
      fullInvoiceList,
      user,
      getPaymentAccounts,
    } = this.props;

    const selectedAccountInfo = accounts.find(
      (account) => account.id === selectedAccount.id
    );

    if (!cpsAccount) {
      onChange({
        target: { name: 'cpsAccount', value: selectedAccountInfo },
      });
      const associatedOrgIds = user.userDetails.associatedOrgs.map(
        (org) => org.id
      );
      getPaymentAccounts(associatedOrgIds, selectedAccountInfo.locationCode);
    } else if (!invoices) {
      getInvoices(cpsAccount.id);
    }
    if (selectedAccountInfo)
      getInvoiceDetails(selectedAccountInfo.id, selectedInvoices.unpaid);
    onChange({
      target: {
        name: 'amountEntered',
        value: selectedInvoices.totalAmountDue,
      },
    });
    if (!fullInvoiceList) {
      onChange({
        target: {
          name: 'fullInvoiceList',
          value: selectedInvoices,
        },
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    let shouldUpdate = true;
    const { selectedAccount } = this.props;
    if (
      selectedAccount !== nextProps.selectedAccount &&
      !nextProps.selectedAccount
    ) {
      shouldUpdate = false;
    }
    return shouldUpdate;
  }

  componentDidUpdate(prevProps) {
    const { invoices, invoiceDetailsData, selectedInvoices } = prevProps;
    const {
      cpsAccount: currentAccount,
      invoiceDetailsData: currentInvoiceDetailsData,
      getInvoices,
      onChange,
      selectedInvoices: currentSelectedInvoices,
    } = this.props;
    const { calledGetInvoices } = this.state;
    if (!invoices && currentAccount && !calledGetInvoices) {
      getInvoices(currentAccount.id);
    }
    if (
      currentInvoiceDetailsData &&
      (!invoiceDetailsData || invoiceDetailsData !== currentInvoiceDetailsData)
    ) {
      const val = [...currentInvoiceDetailsData]
        .map((invoice) =>
          invoice.discountApplicable ? invoice.availableDiscount : 0
        )
        .reduce((total, current) => total + current);
      onChange({ target: { name: 'discountedAmount', value: val } });
      onChange({
        target: {
          name: 'amountEntered',
          value: currentSelectedInvoices.totalAmountDue - val,
        },
      });
    }

    if (
      currentSelectedInvoices.totalAmountDue !== selectedInvoices.totalAmountDue
    ) {
      onChange({
        target: {
          name: 'amountEntered',
          value: currentSelectedInvoices.totalAmountDue,
        },
      });
    }
  }

  getInvoicesByIdList = (list) => {
    const { invoices } = this.props;
    if (!invoices) {
      return null;
    }
    const result = invoices.filter((invoice) =>
      list.includes(invoice.id.toString())
    );
    return result === [] ? null : result;
  };

  render() {
    const {
      classes,
      selectedInvoices,
      notes,
      onChange,
      discountedAmount,
      cpsAccount,
      fullInvoiceList,
    } = this.props;

    const determinedInvoiceList = fullInvoiceList || selectedInvoices;

    const unpaidInvoiceList = this.getInvoicesByIdList(
      determinedInvoiceList.unpaid
    );
    const paidInvoiceList = this.getInvoicesByIdList(
      determinedInvoiceList.paid
    );
    const { listOpen } = this.state;
    const paidLength = selectedInvoices.paid.length;
    const unpaidLength = selectedInvoices.unpaid.length;

    const accountCountryCode = (cpsAccount || {}).locationCode;

    return (
      <div style={{ width: '100%' }}>
        <div className={classes.title}>Invoice Payment Amount</div>
        <div className={`${classes.amount} InvoicePayAmount`}>
          {discountedAmount
            ? currency(selectedInvoices.totalAmountDue - discountedAmount)
            : currency(selectedInvoices.totalAmountDue)}
        </div>
        {discountedAmount && (
          <div className={classes.discountedAmount}>
            Invoice payment amount includes a {currency(discountedAmount)}{' '}
            invoice discount.
          </div>
        )}
        <div
          className={classes.listOpener}
          onClick={() => {
            this.setState({ listOpen: !listOpen });
          }}
          onKeyPress={() => {
            this.setState({ listOpen: !listOpen });
          }}
          role="button"
          tabIndex="0"
        >
          <div className={`${classes.dropDownSelector} ExpandInvoicesButton`}>
            Show Selected Invoices
          </div>
          <DownCaret
            className={`${classes.downCaret} ${
              listOpen ? classes.rotatedCaret : ''
            }`}
          />
        </div>
        <div className={classes.totalSelected}>
          Total selected due invoices: {unpaidLength}
        </div>
        <div className={classes.totalSelected} hidden={paidLength === 0}>
          You have also selected {paidLength} paid invoice
          {paidLength === 1 ? '' : 's'}. We will not include{' '}
          {paidLength === 1 ? 'it' : 'them'} in this payment.
        </div>
        <div className={classes.invoiceListsContainer}>
          <div
            className={`${classes.invoiceTable} ${
              listOpen ? classes.visible : classes.hidden
            }`}
          >
            {accountCountryCode === COUNTRY_CODES.USA && (
              <div className="invoice-action-discount">
                <InfoIcon style={{ color: '#0093e5', marginRight: '10px' }} />
                Available discounts will be applied during the payment process.
              </div>
            )}
            <InvoicesTable
              invoices={unpaidInvoiceList || []}
              includeGrossAmount={false}
              pageSize={5}
            />
            {selectedInvoices.paid.length > 0 && (
              <div style={{ position: 'relative' }}>
                <div className={classes.paidText}>
                  You have selected the following invoices that cannot be paid.
                  We will not include them in this payment.
                </div>
                <div className={classes.colorFilter}>
                  <InvoicesTable
                    invoices={paidInvoiceList || []}
                    includeGrossAmount={false}
                    pageSize={5}
                    areCheckboxesDisabled
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <label className={`${classes.addPaymentFormLabel}`} htmlFor="notes">
          {`Add a Note `}
          <span className={classes.addPaymentFormLabelSmall}>(Optional)</span>
        </label>
        <textarea
          rows="4"
          cols="50"
          maxLength="500"
          minLength="0"
          name="notes"
          className={classes.paymentNotes}
          placeholder="Please add any notes you'd like to share with our account department"
          onChange={onChange}
          value={notes}
        />
      </div>
    );
  }
}

InvoiceAccountInformation.propTypes = {
  accounts: PaymentPropTypes.accounts,
  classes: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.string,
    discountedAmount: PropTypes.string,
    listOpener: PropTypes.string,
    dropDownSelector: PropTypes.string,
    downCaret: PropTypes.string,
    rotatedCaret: PropTypes.string,
    totalSelected: PropTypes.string,
    invoiceListsContainer: PropTypes.string,
    invoiceTable: PropTypes.string,
    visible: PropTypes.string,
    hidden: PropTypes.string,
    paidText: PropTypes.string,
    colorFilter: PropTypes.string,
    addPaymentFormLabel: PropTypes.string,
    addPaymentFormLabelSmall: PropTypes.string,
    paymentNotes: PropTypes.string,
  }).isRequired,
  selectedInvoices: PropTypes.shape({
    totalAmountDue: PropTypes.number,
    unpaid: PropTypes.arrayOf(PropTypes.string),
    paid: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  selectedAccount: PropTypes.string,
  notes: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      isSupressed: PropTypes.bool,
      amountDue: PropTypes.number,
    })
  ),
  cpsAccount: PaymentPropTypes.cpsAccount,
  getInvoices: PropTypes.func.isRequired,
  invoiceDetailsData: PropTypes.arrayOf(PropTypes.shape({})),
  getInvoiceDetails: PropTypes.func.isRequired,
  discountedAmount: PropTypes.number,
  fullInvoiceList: PropTypes.shape({
    totalAmountDue: PropTypes.number,
    unpaid: PropTypes.arrayOf(PropTypes.string),
    paid: PropTypes.arrayOf(PropTypes.string),
  }),
  user: PaymentPropTypes.user, // eslint-disable-line react/no-typos
  getPaymentAccounts: PropTypes.func.isRequired,
};

InvoiceAccountInformation.defaultProps = {
  accounts: [],
  notes: null,
  cpsAccount: null,
  invoices: null,
  invoiceDetailsData: null,
  discountedAmount: null,
  selectedAccount: null,
  fullInvoiceList: null,
  user: {},
};

export default withStyles(finalStyles)(InvoiceAccountInformation);
