import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { gtmInvoicesDownload } from 'store/middleware/TagManager/gtmActions';
import { downloadInvoiceList } from 'components/Account/Invoices/actions';
import { ExportLinksList } from 'shared/ui';
import { invoicePropType } from '../invoicePropTypes';

export class InvoiceExportLinksContainer extends Component {
  exportAction = (fileType) => {
    const {
      gtmInvoicesDownloadAction,
      downloadInvoiceListAction,
      invoice,
      accountId,
    } = this.props;
    gtmInvoicesDownloadAction('Download Invoice', fileType);

    return downloadInvoiceListAction(fileType, accountId, [invoice.id]);
  };

  render = () => (
    <ExportLinksList
      exportAction={this.exportAction}
      fileTypes={window.ReactNativeWebView ? ['pdf'] : ['pdf', 'csv', 'ofx']}
    />
  );
}

InvoiceExportLinksContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  invoice: invoicePropType.isRequired,
  downloadInvoiceListAction: PropTypes.func.isRequired,
  gtmInvoicesDownloadAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.selectedAccount.id,
  invoice: state.invoice.invoice,
});
const mapDispatchToProps = {
  downloadInvoiceListAction: downloadInvoiceList,
  gtmInvoicesDownloadAction: gtmInvoicesDownload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceExportLinksContainer);
