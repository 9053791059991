import { MixpanelEvents, MixpanelProperties } from '../mixPanelConstants';

export const mixPanelLabels = (attributes, branchId, id, name) => {
  window.mixpanel.track(MixpanelEvents.VIEW_LABEL, {
    [MixpanelProperties.SKU]: attributes?.guid,
    [MixpanelProperties.SHELF]: attributes?.product_category?.name,
    [MixpanelProperties.BRANCH_ID]: branchId,
    [MixpanelProperties.ACCOUNT_ID]: id,
    [MixpanelProperties.ACCOUNT_NAME]: name,
  });
};
