import PropTypes from 'prop-types';

export const account = PropTypes.shape({
  name: PropTypes.string,
  number: PropTypes.string,
  id: PropTypes.string,
});

export const match = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string,
    tabValue: PropTypes.string,
  }),
  url: PropTypes.string,
});
