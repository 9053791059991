import { getOwnerFromState } from './Redux/PaymentSelectors';
import { getAccountDataFromForm } from './Utils/FormUtils';
import { PAYMENT_FLOW_TYPES } from './PaymentConstants';

/**
 * A utility function that goes through all the invoices handed to it and calculates the total amount due and then constructs an array
 * of objects containing the ids and amounts for each invoice
 * @param invoices
 * @returns {*}
 */
const getInvoicesMapping = (invoices) =>
  invoices.reduce(
    (mappingObj, currentInvoice) => {
      mappingObj.invoices.push({
        invoiceId: Number.parseInt(currentInvoice.id, 10),
        amount: currentInvoice.amountDue,
      });

      return { ...mappingObj };
    },
    {
      invoices: [],
    }
  );

/**
 * Builds the request for payments
 * @param state - The redux store state
 * @param flowType - A param specifying the type of payment flow we are in
 * for an example of possible flowTypes see PAYMENT_FLOW_TYPES in agrium-cxh-client/src/components/Payments/PaymentConstants.js
 * @return {Object}
 */
export const buildACHPaymentRequest = (state, flowType) => {
  const { form } = state.payments.bankAccountFormReducer;
  const {
    amountEntered,
    notes,
    cpsAccount,
    savedPaymentMethod,
    selectedInvoices: invoices,
  } = form;
  const { termsAndConditionsResponse } = state.payments.sharedReducer;
  const tAndCVersion = termsAndConditionsResponse.version;

  const invoiceMapping =
    flowType === PAYMENT_FLOW_TYPES.INVOICE_PAYMENT
      ? getInvoicesMapping(invoices)
      : undefined;

  return Object.freeze({
    notes,
    tAndCVersion,
    accountId: cpsAccount.id,
    tokenId: savedPaymentMethod.tokenId,
    amount: parseFloat(amountEntered.toFixed(2)),
    invoices: invoiceMapping ? invoiceMapping.invoices : undefined,
    accountCountryCode: cpsAccount.locationCode,
    prepayType:
      flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT ? 'Regular' : undefined,
  });
};

/**
 * Builds the request object for the save token call
 * @param state - the redux store state
 * @return {Object}
 */
export const buildSaveTokenRequest = (state) => {
  const { vantivToken } = state.payments.bankAccountReducer;
  const { form, bankName, accountCountryCode } =
    state.payments.bankAccountFormReducer;
  const { achAccountType, paymentMethodAlias, savePaymentMethod } = form;
  const { routingNumberValue, bankAccountValue } = getAccountDataFromForm({
    form,
    accountCountryCode,
  });
  const accountLastThree = bankAccountValue.slice(-3);

  return Object.freeze({
    paymentMethodAlias,
    token: {
      achAccountType,
      accountLastThree,
      tokenValue: vantivToken,
      routingNumber: routingNumberValue,
      bankName: bankName || 'Bank Account',
      tokenOwner: getOwnerFromState(state),
    },
    isOnetimeUse: !savePaymentMethod,
  });
};

/**
 * Builds the request object for the register call
 * @param state
 * @param form
 * @return {Object}
 */
export const buildRegisterTokenRequest = (state, form) => {
  const accountCountryCode =
    state.payments.bankAccountFormReducer.accountCountryCode ||
    form.cpsAccount.locationCode;
  const { routingNumberValue, bankAccountValue } = getAccountDataFromForm({
    form,
    accountCountryCode,
  });

  return Object.freeze({
    accountCountryCode,
    accountNumber: bankAccountValue,
    routingNumber: routingNumberValue,
    saveAccount: form.savePaymentMethod,
    accountId: state.selectedAccount.id,
  });
};

export const buildPayWithPrepayRequest = (state, form) => {
  const { termsAndConditionsResponse } = state.payments.sharedReducer;
  return Object.freeze({
    amount: form.amountEntered,
    notes: form.notes,
    tAndCVersion: termsAndConditionsResponse.version,
    accountId: form.cpsAccount.id,
    invoices: getInvoicesMapping(form.selectedInvoices).invoices,
  });
};

/**
 * Builds the request object for scheduled payments
 * @param {*} state
 * @param {*} form
 */
export const buildScheduledPaymentRequest = (state, flowType) => {
  const { termsAndConditionsResponse } = state.payments.sharedReducer;

  const { form } = state.payments.bankAccountFormReducer;
  const { cpsAccount } = form;
  const { selectedInvoices: invoices } = form;

  const invoiceMapping =
    flowType === PAYMENT_FLOW_TYPES.INVOICE_PAYMENT
      ? getInvoicesMapping(invoices)
      : undefined;
  return Object.freeze({
    notes: form.notes,
    prepayType: undefined,
    amount: form.amountEntered,
    accountId: form.cpsAccount.id,
    isRecurring: form.isRecurring,
    paymentFrequency: form.recurringPaymentFrequency.code,
    endDate: form.recurringEndDate,
    dateOfPayment: form.scheduledPayment,
    accountCountryCode: cpsAccount.locationCode,
    tAndCVersion: termsAndConditionsResponse.version,
    invoices: invoiceMapping ? invoiceMapping.invoices : undefined,
    tokenId: form.savedPaymentMethod
      ? form.savedPaymentMethod.tokenId
      : undefined,
  });
};
