import PropTypes from 'prop-types';

const accountPropTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  nickname: PropTypes.string,
  balance: PropTypes.number,
  due: PropTypes.number,
  pastDue: PropTypes.number,
  number: PropTypes.string,
};

export const defaultAccountPropTypes = PropTypes.shape({
  ...accountPropTypes,
  branchId: PropTypes.string,
});

export const accountPropType = PropTypes.shape(accountPropTypes);
