import React from 'react';
import PropTypes from 'prop-types';

import './FullPageBackground.css';

const FullPageBackground = (props) => (
  <div className="full-page-background-container">
    <img
      className={`full-page-background ${props.className || ''}`}
      src={props.image}
      alt=""
    />
  </div>
);

FullPageBackground.propTypes = {
  image: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FullPageBackground.defaultProps = {
  className: '',
};

export default FullPageBackground;
