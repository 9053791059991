import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ReviewPayment from './ReviewPayment';
import {
  setDateInitiated,
  submitCurrentBankAccountPayment,
} from '../../Redux/actions/BankAccountTransactionActions';
import { clearBankAccount } from '../../Redux/actions/BankAccountFormActions';
import { goBackAction } from '../../Redux/actions/SharedActions';
import { getAccountDataFromForm } from '../../Utils/FormUtils';
import * as paymentUtils from '../../Utils/PaymentUtil';
import { clearPaymentError } from '../../Redux/actions/ErrorActions';

const mapStateToProps = (state) => {
  const {
    bankAccountFormReducer: bankAccountForm,
    bankAccountTransactionReducer: bankAccountTransaction,
    sharedReducer: shared,
    errorReducer: errors,
  } = state.payments;
  const { form, accountCountryCode } = bankAccountForm;
  const { bankAccountValue } = getAccountDataFromForm({
    form,
    accountCountryCode,
  });

  const {
    amountEntered,
    achAccountType,
    bankAccountType,
    cpsAccount,
    notes,
    selectedInvoicesById,
    selectedInvoices,
    discountedAmount,
    savedPaymentMethod,
    isRecurring,
    recurringEndDate,
    recurringPaymentFrequency,
  } = form;

  // handle case when bankAccountValue && savedPaymentMethod are undefined
  let bankAccountLastThree = '';

  if (bankAccountValue) {
    bankAccountLastThree = bankAccountValue;
  } else if (savedPaymentMethod) {
    bankAccountLastThree = savedPaymentMethod.accountNumberLastThree;
  }

  const isLoading = paymentUtils.isLoading(state);

  return {
    notes,
    isLoading,
    cpsAccount,
    isRecurring,
    achAccountType,
    bankAccountType,
    recurringEndDate,
    discountedAmount,
    savedPaymentMethod,
    bankAccountLastThree,
    amount: amountEntered,
    recurringPaymentFrequency,
    invoiceData: selectedInvoices,
    paymentError: errors.paymentError,
    flowType: bankAccountForm.flowType,
    showFormErrors: errors.showFormErrors,
    selectedInvoices: selectedInvoicesById,
    paymentStatus: bankAccountTransaction.paymentStatus,
    scheduledPayment: bankAccountForm.form.scheduledPayment,
    termsAndConditionsResponse: shared.termsAndConditionsResponse,
  };
};

const mapDispatchToProps = {
  push,
  setDateInitiated,
  clearBankAccount,
  goBack: goBackAction,
  clearPaymentError,
  submitCurrentPayment: submitCurrentBankAccountPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPayment);
