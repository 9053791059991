import { SET_GFA_VERSION } from './actions';

const nutrienGFAReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return {
      version: '',
    };
  }

  switch (action.type) {
    case SET_GFA_VERSION:
      return {
        ...state,
        version: action.version,
      };
    default:
      return state;
  }
};

export default nutrienGFAReducer;
