/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import get from 'lodash/get';
import Button from '@nutrien/uet-react/Button';
import Box from '@nutrien/uet-react/Box';
import CloseIcon from '@nutrien/uet-react/icons/Close';
import Notification from '@nutrien/uet-react/Notification';
import MINIMUM_BULK_QUANTITY from 'shared/constants/minimumBulkQuantity';
import { verifyUsePackageUom } from 'utils/verifyUsePackageUom';
import { checkTotalInput } from 'utils/checkTotalInput';
import { CAN } from '../../utils/contexts/locationCodeConstants';
import { calculateDisplayPrice } from './MiniCartUtils';
import MiniCartContent from './MiniCartContent';
import miniCartPropTypes from './miniCartPropTypes';

import './styles.scss';

const calculateInitialTotalVolume = (totalVolume, isBulk) =>
  totalVolume || (isBulk && MINIMUM_BULK_QUANTITY);

export class MiniCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCart: false,
      anchorElement: null,
      value: calculateInitialTotalVolume(
        get(props, 'cart.mostRecentAddition.total_volume'),
        get(props, 'cart.mostRecentAddition.is_bulk', false)
      ).toString(),
    };
  }

  // Detect all clicks on the document
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const {
      cart: {
        cartData,
        updatingCart: { updating },
        error,
        mostRecentAddition: { total_volume: totalVolume },
      },
    } = this.props;

    const { showCart } = this.state;

    if (updating && !showCart) {
      this.handleShowCart();
    }

    if (!isEqual(prevProps.cart.cartData, cartData) && totalVolume) {
      this.setTotalVolume(totalVolume);
    }

    if (prevProps.cart.error !== error && error) {
      this.hideCart();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  getDisplayPrice = () =>
    calculateDisplayPrice(this.state.value, this.props.cart);

  setAnchorElement = (element) => {
    if (this.state.anchorElement) {
      return;
    }
    this.setState({ anchorElement: element });
  };

  setTotalVolume = (value) =>
    this.setState({
      value: value.toString(),
    });

  updateCartData = (totalVolume) => {
    const { mostRecentAddition } = this.props.cart;
    const { id: accountId, branchId } = this.props.selectedAccount;
    const { userId } = this.props;

    const newItemData = {
      accountId,
      branchId,
      netPrice: mostRecentAddition.variant.net_price,
      priceType: mostRecentAddition.variant.price_type || undefined,
      totalVolume,
      unitOfMeasure: mostRecentAddition.variant.unit_measure,
      unitPrice: mostRecentAddition.variant.price,
      userId,
      variantId: mostRecentAddition.variant.id,
      cartId: mostRecentAddition.id,
    };

    if (totalVolume) {
      this.props.updateCart(
        newItemData,
        { ...mostRecentAddition },
        {
          origin: 'Edit Mini-Cart Quantity',
        }
      );
    }
  };

  handleShowCart = () => {
    this.setState({
      showCart: true,
    });
  };

  hideCart = () => {
    this.setState({ showCart: false });
  };

  handleToggleCart = () => {
    this.setState((prevState) => ({ showCart: !prevState.showCart }));
  };

  handleClickOutside = (event) => {
    if (
      this.state.anchorElement &&
      !this.state.anchorElement.contains(event.target)
    ) {
      this.setState({ showCart: false });
    }
  };

  render() {
    const {
      history,
      cart: {
        mostRecentAddition,
        updatingCart: { updating },
      },
      cart,
      selectedAccount,
    } = this.props;

    const { value } = this.state;

    const { product } = mostRecentAddition;

    const isCanada = selectedAccount.locationCode === CAN;
    const isBulk = get(mostRecentAddition, 'variant.is_bulk', false);
    const usesPackageUom =
      verifyUsePackageUom(selectedAccount) && !!mostRecentAddition.variant;
    const { hasError, errorMessage } = checkTotalInput(
      isCanada,
      usesPackageUom,
      mostRecentAddition.variant,
      value
    );

    let name;
    if (product) {
      name = product.attributes.name; // eslint-disable-line prefer-destructuring
    }

    const totalNetPrice = mostRecentAddition.variant
      ? mostRecentAddition.variant.net_price * value
      : 0;

    return (
      <div
        className={
          this.state.showCart ? 'mini-cart-wrapper active' : 'mini-cart-wrapper'
        }
      >
        <div
          className={
            this.state.showCart
              ? 'mini-cart active jsMiniCart'
              : 'mini-cart jsMiniCart'
          }
          ref={this.setAnchorElement}
        >
          <div className="mini-cart__header">
            <div>
              <span className="mini-cart__headline">Cart </span>
            </div>
            <CloseIcon
              className="mini-cart__button jsMiniCartButton"
              onClick={() => this.handleToggleCart()}
            />
          </div>
          <div className="mini-cart__notification-container">
            {hasError && (
              <Notification
                variant="error"
                className="mini-cart__notification--error"
              >
                {errorMessage}
              </Notification>
            )}
          </div>
          {updating ? (
            <MaterialLoadingIcon size={35} />
          ) : (
            <MiniCartContent
              data-test="mini-cart-content"
              account={selectedAccount}
              cart={cart}
              productName={name}
              totalVolume={value}
              displayPrice={this.getDisplayPrice()}
              totalNetPrice={totalNetPrice}
              setTotalVolume={this.setTotalVolume}
              isBulk={isBulk}
              usesPackageUom={usesPackageUom}
              updateCartData={this.updateCartData}
            />
          )}
          <Box py={0.5}>
            <Button
              variant="contained"
              onClick={() => history.push('/cart')}
              data-test="go-to-full-cart"
              fullWidth
            >
              View Cart
            </Button>
          </Box>
          <Box py={0.5}>
            <Button
              onClick={() => this.handleToggleCart()}
              variant="outlined"
              data-test="cancel-mini-cart"
              fullWidth
            >
              Continue Shopping
            </Button>
          </Box>
        </div>
        {/*
                // Temporariliy disabled.
                <CrossSells shop={shop} cart={this.props.cart} />
              */}
      </div>
    );
  }
}

MiniCart.propTypes = {
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    locationCode: PropTypes.string,
    balance: PropTypes.number,
    due: PropTypes.number,
    pastDue: PropTypes.number,
    number: PropTypes.string,
    branchId: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  shop: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  cart: miniCartPropTypes.isRequired,
  updateCart: PropTypes.func.isRequired,
};

export default withRouter(MiniCart);
