import store from 'store';

// checks array of userRoles for required role
const roleValidator = (authorizedRoles) => {
  const state = store.getState();
  const userRoles = state.rbac.roles;
  return authorizedRoles.some((role) => userRoles.includes(role));
};

export default roleValidator;
