import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ApiErrorMessage,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MaterialLoadingIcon,
} from 'shared/ui';
import PropTypes from 'prop-types';

import { notifications as NotificationPropType } from '../notificationsPropTypes';
import NotificationsListItem from './NotificationsListItem';

import './NotificationsList.scss';

export const NoNotificationsMessage = () => (
  <div className="notifications notifications__no-notifications">
    There are no notifications at this time.
  </div>
);

const ECOMM_NOTIF_TYPES = [
  'ORDER_CANCELLATION',
  'ORDER_CONFIRMATION',
  'ORDER_FULFILLED',
  'ORDER_INVOICED',
  'ORDER_SUBMITTED',
  'ORDER_STATUS_CHANGE',
];

const NotificationsList = ({
  notifications,
  selectedNotifications,
  notificationsError,
  fetchingNotifications,
  deleteNotificationFailure,
  resetDeleteNotificationFailure,
  selectAllChecked,
  toggleSelect,
}) => {
  const { globalEcommerce } = useFlags();
  return (
    <div className="notifications">
      {fetchingNotifications && <MaterialLoadingIcon />}
      {!!notificationsError && (
        <ApiErrorMessage
          entityName="Notifications"
          className="notifications-list__error"
        />
      )}
      {!notificationsError &&
        !fetchingNotifications &&
        !notifications.length && <NoNotificationsMessage />}
      {!notificationsError && !!notifications.length && (
        <ul className="notifications-list">
          {notifications.map((notification) => {
            const checked =
              selectAllChecked ||
              selectedNotifications.includes(notification.id);
            // Don't show ecomm notifications
            if (ECOMM_NOTIF_TYPES.indexOf(notification.type) !== -1) {
              return (
                <>
                  {globalEcommerce && (
                    <NotificationsListItem
                      key={notification.id}
                      checked={checked}
                      notification={notification}
                      toggleSelect={toggleSelect}
                    />
                  )}
                </>
              );
            }
            return (
              <NotificationsListItem
                key={notification.id}
                checked={checked}
                notification={notification}
                toggleSelect={toggleSelect}
              />
            );
          })}
        </ul>
      )}
      <Dialog
        open={deleteNotificationFailure}
        onClose={resetDeleteNotificationFailure}
        className="notificationDeleteErrorModal"
      >
        <DialogTitle>Oops! Something went wrong.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sorry, we’re having a technical issue. Refresh the page or try again
            later.
          </DialogContentText>
          <DialogActions>
            <Button onClick={resetDeleteNotificationFailure}>OK</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

NotificationsList.propTypes = {
  notificationsError: PropTypes.instanceOf(Error),
  notifications: NotificationPropType,
  selectedNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchingNotifications: PropTypes.bool.isRequired,
  deleteNotificationFailure: PropTypes.bool.isRequired,
  resetDeleteNotificationFailure: PropTypes.func.isRequired,
  toggleSelect: PropTypes.func.isRequired,
  selectAllChecked: PropTypes.bool.isRequired,
};

NotificationsList.defaultProps = {
  notifications: [],
  notificationsError: undefined,
};

export default NotificationsList;
