import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import InfoIcon from '@material-ui/icons/Info';
import { CPSAccountDropdown } from 'shared/ui/Payments';
import PrePayAmountInput from './PrePayAmountInput/PrePayAmountInputContainer';

import './PrePayAccountInformationComponent.css';

class PrePayAccountInformationComponent extends Component {
  renderHeaderLabel = (text) => (
    <label className="cps-account-label-large" htmlFor="CPSAccountInformation">
      {text}
    </label>
  );

  render() {
    const {
      selectedAccount,
      notes,
      onChange,
      validate,
      history,
      location,
    } = this.props;

    return (
      <div className="cps-account-information">
        {this.renderHeaderLabel('Add Funds to Following Account')}

        <CPSAccountDropdown
          chosenAccount={selectedAccount}
          dropdownLabel="Account"
          history={history}
          location={location}
        />

        <div className="pre-pay-info-message-container">
          <InfoIcon
            style={{ color: '#0093e5', position: 'relative', top: '3px' }}
          />
          <div className="pre-pay-info-message-text">
            Some branches require you to sign a contract for the pre-pay funds
            you submit. A representative may reach out to you if this is needed.
          </div>
        </div>

        {selectedAccount ? (
          <div>
            <div className="selected-account-container">
              {this.renderHeaderLabel('Add Amount')}

              <PrePayAmountInput onChange={onChange} validate={validate} />

              <label
                className="cps-account-label cps-account-notes-label"
                htmlFor="notes"
              >
                Add notes for the Branch
                <label
                  className="cps-account-label-small"
                  htmlFor="notesOptional"
                >
                  (Optional)
                </label>
              </label>

              <textarea
                rows="4"
                cols="50"
                maxLength="500"
                minLength="0"
                name="notes"
                className="cps-add-funds-notes"
                onChange={onChange}
                value={notes}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

PrePayAccountInformationComponent.propTypes = {
  selectedAccount: PaymentPropTypes.paymentAccount, // eslint-disable-line react/no-typos
  notes: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

PrePayAccountInformationComponent.defaultProps = {
  notes: '',
  selectedAccount: null,
};

export default PrePayAccountInformationComponent;
