import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TermsPage from './TermsPage';
import TermsPropTypes from '../termsPropTypes';

const TermsPageContainer = ({ terms, currentPath }) => (
  <TermsPage terms={terms} currentPath={currentPath} />
);

TermsPageContainer.propTypes = {
  terms: TermsPropTypes.isRequired,
  currentPath: PropTypes.string.isRequired,
};

const mapStateToProps = ({ terms, router }) => ({
  terms,
  currentPath: router.location.pathname,
});

export default connect(mapStateToProps)(TermsPageContainer);
