import React, { useState } from 'react';
import sortBy from 'lodash/sortBy';
import propTypes from 'prop-types';
import currency from 'shared/utils/numberFormatters/currency';
import Delete from '@material-ui/icons/Delete';
import customFormat from 'shared/utils/DateHelper/customFormat';
import '../ScheduledPaymentsTable/scheduledPaymentsTableMobile.scss';
import moment from 'moment';
import { getRecurringFrequencyLabel } from './RecurringPaymentsRow';
import ScheduledPaymentDeleteModal from '../ScheduledPaymentDeleteModal/ScheduledPaymentDeleteModal';

const RecurringPaymentsTableMobile = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [amount, setAmount] = useState();

  const today = new Date();
  const yesterday = today.setDate(today.getDate() - 1);
  const yesterdayText = customFormat(yesterday, 'MM/DD/YYYY');

  const handlePaymentDelete = (paymentDetails) => {
    props.handleSameDayError(true, false);
    // if nextPayment is on today's date
    if (
      paymentDetails.nextPayment &&
      moment(paymentDetails.nextPayment).isSame(moment(), 'date')
    ) {
      props.handleSameDayError(true, true);
      return;
    }
    setDialogOpen(true);
    setPaymentId(paymentDetails.id);
    setAmount(paymentDetails.amount);
  };

  return (
    <div className="payments-tab-mobile__table-container">
      <ScheduledPaymentDeleteModal
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        deleteScheduledPayment={props.deleteScheduledPayment}
        paymentId={paymentId}
        amount={amount}
        isRecurring
      />
      {!props.recurringPayments || props.recurringPayments.length === 0 ? (
        <section className="payments-tab-mobile__no-payments">
          You don&#39;t have any recurring payments as of {yesterdayText}
        </section>
      ) : (
        sortBy(props.recurringPayments, (element) => element.nextPayment).map(
          (paymentDetails) => (
            <section
              className="payments-table-mobile__entry"
              key={paymentDetails.invoiceId}
            >
              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">
                  Next Payment:
                </span>
                <span className="payments-table-mobile__value">
                  {new Date(paymentDetails.nextPayment).toLocaleDateString()}
                </span>
              </div>
              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">
                  Account ID:
                </span>
                <span className="payments-table-mobile__value">
                  {paymentDetails.accountId}
                </span>
              </div>
              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">
                  Payment Method:
                </span>
                <span className="payments-table-mobile__value">
                  {paymentDetails.paymentType}
                </span>
              </div>

              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">
                  Frequency:
                </span>
                <span className="payments-table-mobile__value">
                  {getRecurringFrequencyLabel(paymentDetails.frequency)}
                </span>
              </div>

              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">Amount:</span>
                <span className="payments-table-mobile__value">
                  {currency(paymentDetails.amount)}
                </span>
              </div>

              <button
                className="payments-table-mobile__delete-button"
                onClick={() => handlePaymentDelete(paymentDetails)}
              >
                <Delete className="cart-item__close-icon" color="action" />
              </button>
            </section>
          )
        )
      )}
    </div>
  );
};

RecurringPaymentsTableMobile.propTypes = {
  recurringPayments: propTypes.arrayOf(propTypes.shape({})).isRequired,
  deleteScheduledPayment: propTypes.func.isRequired,
  handleSameDayError: propTypes.func.isRequired,
};

export default RecurringPaymentsTableMobile;
