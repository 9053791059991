import axios from 'axios';
import resolveApiUrl from 'shared/config/resolveApiUrl';
import resolveApiKey from 'shared/config/resolveApiKey';
import authenticationInterceptor from './authenticationInterceptor';
import mirroringInterceptor from './mirroringInterceptor';
import correlationIdInterceptor from './correlationIdInterceptor';

const headers = {
  'Content-Type': 'application/json',
  Authorization: 'allow',
  'x-api-key': resolveApiKey(resolveApiUrl()),
  'Cache-Control': 'no-cache',
};

const service = axios.create({
  baseURL: resolveApiUrl(),
  headers,
});

// Request interceptors
service.interceptors.request.use(authenticationInterceptor);
service.interceptors.request.use(mirroringInterceptor);
service.interceptors.request.use(correlationIdInterceptor);

// axios.all not currently included axios instance methods
service.all = (requestArray) => axios.all(requestArray);

export default service;
