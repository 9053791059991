import get from 'lodash/get';

export default (invoices, selectedProducts) =>
  selectedProducts && selectedProducts.length > 0
    ? invoices.filter((invoice) =>
        selectedProducts.some((productInfo) =>
          get(productInfo, 'invoices', []).includes(invoice.id)
        )
      )
    : invoices;
