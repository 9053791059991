import * as notificationsService from '../notificationsService';
import notificationsFetchBegin from './notificationsFetchBegin';
import notificationsFetchSuccess from './notificationsFetchSuccess';
import notificationsFetchFailure from './notificationsFetchFailure';
import notificationsFetchEnd from './notificationsFetchEnd';

export default () => (dispatch) =>
  Promise.resolve()
    .then(dispatch(notificationsFetchBegin()))
    .then(
      notificationsService
        .get()
        .then((resp) => dispatch(notificationsFetchSuccess(resp)))
        .catch((err) => dispatch(notificationsFetchFailure(err)))
        .then(() => dispatch(notificationsFetchEnd())) // es6 'finally' breaks vital unit tests, so then is used instead here
    );
