import {
  DYNAMICALLY_OPTIONAL_FIELDS,
  FORM_FIELDS,
  NEW_BANK_ACCOUNT,
  PAYMENT_AMOUNT_TYPES,
} from '../PageComponents/MakeAPayment/MakeAPaymentConstants';
import {
  BANK_ACCOUNT_TYPES,
  COUNTRY_CODES,
  DEFAULT_PAYMENT_METHODS,
} from '../PaymentConstants';

export const getOptionalFormFields = (form, accountCountryCode) => {
  let fields = [];
  const {
    bankAccountType,
    amountTypeSelected,
    savedPaymentMethod,
    cpsAccount,
    isRecurring,
  } = form;

  fields = fields.concat(
    bankAccountType === BANK_ACCOUNT_TYPES.CORPORATE
      ? DYNAMICALLY_OPTIONAL_FIELDS[BANK_ACCOUNT_TYPES.CORPORATE]
      : DYNAMICALLY_OPTIONAL_FIELDS[BANK_ACCOUNT_TYPES.PERSONAL]
  );

  if (
    savedPaymentMethod &&
    savedPaymentMethod.tokenValue !== NEW_BANK_ACCOUNT
  ) {
    fields = fields.concat(FORM_FIELDS);
  } else if (
    savedPaymentMethod &&
    savedPaymentMethod.tokenValue === NEW_BANK_ACCOUNT
  ) {
    fields = fields.concat(DYNAMICALLY_OPTIONAL_FIELDS[accountCountryCode]);
  }

  if (
    !amountTypeSelected ||
    amountTypeSelected !== PAYMENT_AMOUNT_TYPES.Other
  ) {
    fields = fields.concat(DYNAMICALLY_OPTIONAL_FIELDS.Amount);
  }

  if (cpsAccount) {
    fields = fields.concat(
      DYNAMICALLY_OPTIONAL_FIELDS[cpsAccount.locationCode]
    );
  } else {
    fields.push('cpsAccount');
  }

  if (!isRecurring) {
    fields = fields.concat([
      ...DYNAMICALLY_OPTIONAL_FIELDS.recurringPaymentFrequency,
      ...DYNAMICALLY_OPTIONAL_FIELDS.recurringEndDate,
    ]);
  }

  return fields;
};

/**
 * A helper utility that gets the bank account number and routing number values from the bank account form dynamically via the country code passed in
 * @param form - The current state of the bank account form
 * @param accountCountryCode - The country code for the account the payment is being made against
 * @returns {{routingNumberValue: string, bankAccountValue: *}}
 */
export const getAccountDataFromForm = ({ form, accountCountryCode }) => {
  const routingNumberValue =
    accountCountryCode === COUNTRY_CODES.CANADA
      ? `0${form.institutionNumber}${form.transitNumber}`
      : form.routingNumber;
  const bankAccountValue =
    accountCountryCode === COUNTRY_CODES.CANADA
      ? form.accountNumber
      : form.bankAccountNumber;

  return {
    routingNumberValue,
    bankAccountValue,
  };
};

/**
 * A helper utility that gets the bank account value, truncates it to the last three digits and returns the value in an object meant to be used by the updateBankAccountForm action
 * @param form - The current state of the bank account form
 * @param accountCountryCode - The country code for the account the payment is being made against
 * @returns {*}
 */
export const getBankAccountNumberPayload = ({ form, accountCountryCode }) => {
  const { bankAccountValue } = getAccountDataFromForm({
    form,
    accountCountryCode,
  });

  const accountLastThree =
    form.savedPaymentMethod === DEFAULT_PAYMENT_METHODS.PREPAY ||
    form.savedPaymentMethod === DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT
      ? bankAccountValue.slice(-3)
      : form.savedPaymentMethod.accountNumberLastThree;

  return accountCountryCode === COUNTRY_CODES.CANADA
    ? { accountNumber: accountLastThree }
    : { bankAccountNumber: accountLastThree };
};
