import { connect } from 'react-redux';
import { gtmToggleInvoiceFilter } from 'store/middleware/TagManager/gtmActions';
import InvoideIdFilter from './InvoiceIdFilter';
import withInvoiceFilters from '../withInvoiceFilters';

const mapDispatchToProps = {
  gtmToggleInvoiceFilter,
};

export default connect(
  null,
  mapDispatchToProps
)(withInvoiceFilters(InvoideIdFilter));
