import {
  mapFieldToMinLength,
  mapRuleToErrorMessage,
  mapRuleToWarningMessage,
  mapFieldToRules,
  SPECIAL_FIELDS_ENUM,
} from './ValidationConstants';
import { COUNTRY_CODES } from '../../../PaymentConstants';
import { validateMaxAmount } from './PaymentValidationRules';

const VALIDATION_TYPES = {
  WARNING: {
    messageKey: 'warningMessage',
    lookup: mapRuleToWarningMessage,
  },
  ERROR: {
    messageKey: 'errorMessage',
    lookup: mapRuleToErrorMessage,
  },
};

/**
 * Constructs the field data object used by the validateFormField function for any given field
 * @param field - The field to construct the dataField object for
 * @param fieldValue - The value of the field which will be contained in the returned object
 * @param currentState - The current state of the component.
 * @returns fieldData - An object to be used when validating the given field.
 */
const constructFieldData = (field, fieldValue, currentState) => {
  let finalData;
  const formValues = currentState.form;
  switch (field) {
    case SPECIAL_FIELDS_ENUM.AMOUNT_ENTERED: {
      const selectedCPSAccount = formValues.cpsAccount;
      finalData = {
        amountEntered: fieldValue,
        accountBalance: selectedCPSAccount ? selectedCPSAccount.balance : null,
        currentDue: selectedCPSAccount ? selectedCPSAccount.currentDue : 0,
      };
      break;
    }
    case SPECIAL_FIELDS_ENUM.CONFIRM_BANK_ACCOUNT_NUMBER: {
      finalData = {
        value: fieldValue,
        bankAccountNumber: formValues.bankAccountNumber,
      };
      break;
    }
    case SPECIAL_FIELDS_ENUM.CONFIRM_ACCOUNT_NUMBER: {
      finalData = {
        value: fieldValue,
        bankAccountNumber: formValues.accountNumber,
      };
      break;
    }
    default:
      finalData = { value: fieldValue };
  }

  return finalData;
};

const constuctFieldName = (field, form) => {
  let fieldName;

  switch (field) {
    case SPECIAL_FIELDS_ENUM.ZIP: {
      fieldName =
        form.country.code === COUNTRY_CODES.CANADA
          ? SPECIAL_FIELDS_ENUM.POSTAL_CODE
          : field;
      break;
    }
    default:
      fieldName = field;
  }
  return fieldName;
};

const shouldSkipWarningValidation = (formErrors) =>
  formErrors.amountEntered.some((val) => val.rule === validateMaxAmount.name);

/**
 * Takes a collection of rules and a collection of values and runs the values against each rule to see if
 * any fail validation.
 * @param rules - a collection of rules to reduce down into a collection of errors
 * @param values - a collection of values to run against each validation rule
 * @param validationType - An object containing the lookup object to use and the message key
 * @returns An array error objects
 */
const applyRules = (rules, values, validationType) =>
  rules.reduce((collection, currentRule) => {
    const result = currentRule(values);
    const { messageKey } = validationType;

    if (!result) {
      collection.push({
        rule: currentRule.name,
        message:
          currentRule.name === validateMaxAmount.name
            ? `${validationType.lookup[currentRule.name][messageKey]}`
            : validationType.lookup[currentRule.name][messageKey],
      });
    }

    return collection;
  }, []);

/**
 * Takes a key representing a form field, some data for the field and the current form state and returns the new form
 * state after all the validation rules for that field have been run against the data.
 * @param field - The key for our form field
 * @param fieldData - An object containing at the very least a value property which is the fields value to validate against
 *                   Some fields will also have other data properties in here
 * @param currentState - The current state of the component
 * @returns newState - Returns an object representing the forms new state after running all the validation rules for the field
 *                     against its values
 */
export const validateFormField = ({ field, fieldData, currentState }) => {
  const finalFieldData = constructFieldData(
    field,
    fieldData.value,
    currentState
  );

  const finalFieldName = constuctFieldName(field, currentState.form);

  const validationRules = mapFieldToRules[finalFieldName];
  const newState = { ...currentState };

  if (!validationRules) {
    return newState;
  }

  const minLengthValidation = mapFieldToMinLength[finalFieldName];

  if (field === 'paymentMethodAlias' && !fieldData.value) {
    return newState;
  }

  if (minLengthValidation) {
    finalFieldData.minLength = minLengthValidation.minLength;
  }

  newState.formErrors[field] = applyRules(
    validationRules.errorRules,
    finalFieldData,
    VALIDATION_TYPES.ERROR
  );

  if (
    validationRules.warningRules &&
    !shouldSkipWarningValidation(newState.formErrors)
  ) {
    newState.formWarnings[field] = applyRules(
      validationRules.warningRules,
      finalFieldData,
      VALIDATION_TYPES.WARNING
    );
  } else {
    newState.formWarnings[field] = [];
  }

  return newState;
};

/**
 * Validates the entire form one field at a time and returns the formState after all the rules for all the form fields have
 * been run
 * @param currentState - The currentState of the form before validation
 * @param optionalFields - An array of keys representing optional fields that the validator should skip.
 * @returns newState - An object representing the new state of the form after all the field validations have occurred.
 */
export const validateEntireForm = ({ currentState, optionalFields }) => {
  const formValues = currentState.form;
  let newState = { ...currentState };

  Object.keys(formValues)
    .filter((field) => !optionalFields.includes(field))
    .forEach((key) => {
      const fieldValue = formValues[key];
      const fieldData = { field: key, value: fieldValue };
      const stateAfterValidation = validateFormField({
        field: key,
        fieldData,
        currentState: newState,
      });

      newState = { ...newState, ...stateAfterValidation };
    });

  return newState;
};

/**
 *
 * @param formErrors
 * @param optionalFields
 */
export const hasFormErrors = (formErrors, optionalFields) =>
  Object.keys(formErrors).find((key) => {
    const value = formErrors[key];

    return value.length && !optionalFields.includes(key);
  });

/**
 *
 * @param form
 * @param optionalFields
 */
export const hasEmptyFormFields = (form, optionalFields) =>
  Object.keys(form).find((key) => {
    const value = form[key];

    return !value && !optionalFields.includes(key);
  });
