const styles = {
  container: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    zIndex: 99999,
    background: 'rgb(240,238,238,0.7)',
  },
  loadingIconContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    height: '100%',
  },
  label: {
    width: '100%',
    textAlign: 'center',
    color: '#464646',
    fontWeight: 600,
    fontSize: 14,
    marginTop: -5,
  },
};

export default styles;
