import React, { useState } from 'react';
import propTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import Delete from '@material-ui/icons/Delete';
import currency from 'shared/utils/numberFormatters/currency';
import customFormat from 'shared/utils/DateHelper/customFormat';
import moment from 'moment';
import ScheduledPaymentDeleteModal from '../ScheduledPaymentDeleteModal/ScheduledPaymentDeleteModal';

import './scheduledPaymentsTableMobile.scss';

const ScheduledPaymentsTableMobile = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [amount, setAmount] = useState();

  const today = new Date();
  const yesterday = today.setDate(today.getDate() - 1);
  const yesterdayText = customFormat(yesterday, 'MM/DD/YYYY');

  const handlePaymentDelete = (paymentDetails) => {
    props.handleSameDayError(false, false);
    // if scheduledDate is on today's date
    if (
      paymentDetails.scheduledDate &&
      moment(paymentDetails.scheduledDate).isSame(moment(), 'date')
    ) {
      props.handleSameDayError(false, true);
      return;
    }
    setDialogOpen(true);
    setPaymentId(paymentDetails.scheduledPaymentId);
    setAmount(paymentDetails.amount);
  };

  return (
    <div className="payments-tab-mobile__table-container">
      <ScheduledPaymentDeleteModal
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        deleteScheduledPayment={props.deleteScheduledPayment}
        paymentId={paymentId}
        amount={amount}
      />
      {!props.scheduledPayments || props.scheduledPayments.length === 0 ? (
        <section className="payments-tab-mobile__no-payments">
          You don&#39;t have any scheduled payments as of {yesterdayText}
        </section>
      ) : (
        sortBy(props.scheduledPayments, (element) => element.scheduledDate).map(
          (paymentDetails) => (
            <section
              className="payments-table-mobile__entry"
              key={paymentDetails.invoiceId}
            >
              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">
                  Scheduled Date:
                </span>
                <span className="payments-table-mobile__value">
                  {new Date(paymentDetails.scheduledDate).toLocaleDateString()}
                </span>
              </div>
              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">
                  Invoice ID:
                </span>
                <span className="payments-table-mobile__value">
                  {paymentDetails.invoiceIds &&
                  paymentDetails.invoiceIds.length > 1
                    ? 'Multiple'
                    : paymentDetails.invoiceIds}
                </span>
              </div>
              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">
                  Payment Method:
                </span>
                <span className="payments-table-mobile__value">
                  {paymentDetails.paymentType}
                </span>
              </div>
              <div className="payments-table-mobile__row">
                <span className="payments-table-mobile__header">Amount:</span>
                <span className="payments-table-mobile__value">
                  {currency(paymentDetails.amount)}
                </span>
              </div>
              <button
                className="payments-table-mobile__delete-button"
                onClick={() => handlePaymentDelete(paymentDetails)}
              >
                <Delete
                  className="cart-item__close-icon"
                  onClick={() => {}}
                  color="action"
                />
              </button>
            </section>
          )
        )
      )}
    </div>
  );
};

ScheduledPaymentsTableMobile.propTypes = {
  scheduledPayments: propTypes.arrayOf(propTypes.shape({})).isRequired,
  deleteScheduledPayment: propTypes.func.isRequired,
  handleSameDayError: propTypes.func.isRequired,
};

export default ScheduledPaymentsTableMobile;
