import {
  GTM_CLICK_FEATURED_PARTNER_APPLE_STORE_XARVIO,
  GTM_CLICK_FEATURED_PARTNER_GOOGLE_STORE_XARVIO,
  GTM_CLICK_FEATURED_PARTNER_LEARN_MORE,
} from '../gtmConstants';

export const clickAppleStoreXarvio = () => ({
  event: 'EC_ClickFPAppleStoreXarvio',
});

export const clickGoogleStoreXarvio = () => ({
  event: 'EC_ClickFPGoogleStoreXarvio',
});

export const clickFeatPartnerLearnMore = () => ({
  event: 'EC_ClickFPLearnMore',
});

export const featuredPartnerEventMap = {
  [GTM_CLICK_FEATURED_PARTNER_APPLE_STORE_XARVIO]: clickAppleStoreXarvio,
  [GTM_CLICK_FEATURED_PARTNER_GOOGLE_STORE_XARVIO]: clickGoogleStoreXarvio,
  [GTM_CLICK_FEATURED_PARTNER_LEARN_MORE]: clickFeatPartnerLearnMore,
};
