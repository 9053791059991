import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { gtmInvoicesDownload } from 'store/middleware/TagManager/gtmActions';
import get from 'lodash/get';

import { ExportLinksList } from 'shared/ui';
import { exportInvoices } from '../../actions';

export class ExportInvoicesContainer extends Component {
  exportAction = (fileType) => {
    const { exportInvoices, gtmInvoicesDownload, accountId } = this.props; // eslint-disable-line no-shadow

    gtmInvoicesDownload('Download All', fileType);
    return exportInvoices(fileType, accountId);
  };

  render() {
    return window.ReactNativeWebView ? null : (
      <ExportLinksList
        exportAction={this.exportAction}
        fileTypes={['csv', 'ofx']}
        className="export-invoices"
      />
    );
  }
}

ExportInvoicesContainer.propTypes = {
  exportInvoices: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  gtmInvoicesDownload: PropTypes.func.isRequired,
};

const mapDispatchToProps = { exportInvoices, gtmInvoicesDownload };

const mapStateToProps = (state) => ({
  accountId: get(state, 'selectedAccount.id'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportInvoicesContainer);
