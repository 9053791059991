import togglePreference from './createTogglePreferenceAction';
import togglingPreference from './togglingPreference';
import togglePreferenceSuccess from './togglePreferenceSuccess';
import togglePreferenceFailure from './togglePreferenceFailure';

import put from '../billingTabService';

export default (preference, value, preferences, accountIds) => (dispatch) => {
  dispatch(togglePreference(preferences, preference, value));
  dispatch(togglingPreference());

  const indexToUpdate = preferences.findIndex(
    (pref) => pref.preference === preference.preference
  );
  const updatedPreferences = [...preferences];
  updatedPreferences[indexToUpdate] = { ...preference, enabled: value };

  return put(accountIds, updatedPreferences)
    .then((resp) => {
      // each account is updated with the same values, and so each should return the same response,
      // so just take the first element of the array, and unwrap the return value so it's just the array
      const successAction = togglePreferenceSuccess(resp[0].preferences);
      return dispatch(successAction);
    })
    .catch((err) => {
      dispatch(togglePreferenceFailure(err));
    });
};
