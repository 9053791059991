import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageNotification from 'shared/ui/NotificationHelper';
import LicensesTable from '../LicensesTable';
import PageHeader from '../../../shared/ui/PageHeader/PageHeader';

const LicensesPage = () => {
  const { usaSapLicenses: restrictLicensesTable } = useFlags();

  return (
    <div id="licenses-page">
      <PageHeader
        title="Licenses"
        breadcrumbs={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'Licenses',
          },
        ]}
      />
      {restrictLicensesTable ? (
        <PageNotification
          className="licensing-restrict_usa-sap"
          data-test="licensing-restrict__notification--usa-sap-license"
          link="/"
          linkDescription="Return Home"
          message="While the Licenses Page is unavailable, please contact your Crop Consultant or local branch for information on product licenses."
          type="WARNING"
        />
      ) : (
        <LicensesTable className="licenses-table" />
      )}
    </div>
  );
};

export default LicensesPage;
