import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { shape, string } from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import { isEmployee as isEmployeeFunc } from 'shared/utils/tokenUtils/isEmployee';
import handleBackToResults from '../../utils/handleBackToResults';
import shouldUpdateClickFunc from '../../utils/shouldUpdateClick';
import EmployeeNotificationBar from '../../../Cart/components/EmployeeNotificationBar';
import { getProduct } from '../../actions/productDetailActions/productDetailActions';
import MiniCart from '../../containers/MiniCartContainer/MiniCartContainer';
import ProductInfo from './ProductInfo';
import sortVariantDropDown from '../../utils/sortVariantDropDown';

import './ProductDetailStyles.scss';

export const shouldUseExistingVariant = (selectedProduct, product) => {
  let doesVariantExist = false;
  selectedProduct.forEach((variant) => {
    if (variant.guid === product.variant.guid) {
      doesVariantExist = true;
    }
  });
  return doesVariantExist;
};

const Loading = (
  <div
    className="loading-wrapper jsLoadingWrapper"
    data-test="product-detail-loading"
  >
    <MaterialLoadingIcon />
  </div>
);

const ProductDetail = ({ history, location, match }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const product = useSelector((state) => get(state, 'shop.product', {}));
  const selectedAccount = useSelector((state) => state.selectedAccount);

  const {
    localizedCatalog,
    localizedCatalogUs,
    pricingPercentVariation,
  } = useFlags();

  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const [cartIsUpdating, setCartIsUpdating] = useState(false);

  const {
    params: { id },
  } = match;

  // retrieve the product
  useEffect(() => {
    if (selectedAccount.id) {
      let variants = get(location, 'state.attributes.variants', []);
      variants = sortVariantDropDown(variants);
      const currentlySelectedVariant =
        location.state && shouldUseExistingVariant(variants, product)
          ? product.variant
          : null;
      dispatch(
        getProduct(
          id,
          selectedAccount,
          {
            origin: 'Product Detail Page',
          },
          currentlySelectedVariant,
          localizedCatalog,
          localizedCatalogUs,
          get(pricingPercentVariation, 'uponFulfillment')
        )
      );
    }
  }, [id, selectedAccount.id]);

  // prevent click while updating cart
  useEffect(() => {
    const currentlyUpdating = get(cart, 'updatingCart.updating', false);
    const shouldUpdateClick = shouldUpdateClickFunc(
      currentlyUpdating,
      cartIsUpdating,
      hasBeenClicked
    );

    if (shouldUpdateClick) {
      setHasBeenClicked(false);
    }
    setCartIsUpdating(currentlyUpdating);
  }, [cart.updatingCart.updating]);

  const isEmployee = isEmployeeFunc();
  const attributes = get(product, 'data.attributes', {});
  const { loading, error } = product;
  const waiting = loading || !selectedAccount.id;

  const handleBack = () => {
    const values = { location, history };
    handleBackToResults(values);
  };

  return (
    <section
      className={classNames({
        'product-detail__employee-wrapper': isEmployee,
      })}
    >
      <MiniCart history={history} data-test="product-detail-mini-cart" />
      <div
        onKeyPress={handleBack}
        role="button"
        tabIndex={0}
        className="product-detail__back-to-results"
        onClick={handleBack}
        data-test="back-to-products-breadcrumb"
      >
        <Icon
          className="product-detail__back-to-results-icon"
          icon="arrowDown"
          type="success"
        />
        <span className="product-detail__back-to-results">
          Back to Products
        </span>
      </div>

      <EmployeeNotificationBar data-test="product-detail-employee-notification-bar" />
      {waiting && Loading}

      {!isEmpty(attributes) && !waiting ? (
        <ProductInfo
          hasBeenClicked={hasBeenClicked}
          setHasBeenClicked={setHasBeenClicked}
          history={history}
          data-test="product-detail-product-info"
        />
      ) : (
        error && (
          <ApiErrorMessage
            data-test="product-detail-api-error-message"
            entityName="This product"
          />
        )
      )}
    </section>
  );
};

ProductDetail.propTypes = {
  history: shape({}).isRequired,
  location: shape({}).isRequired,
  match: shape({
    params: shape({
      id: string,
    }),
  }).isRequired,
};

export default ProductDetail;
