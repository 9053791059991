import { SET_INVOICES } from '../../../../const/invoices';

export default (state = null, action) => {
  switch (action.type) {
    case SET_INVOICES:
      return action.payload.accountId;
    default:
      return state;
  }
};
