import resolveApiEnv, {
  DEV,
  SIT,
  PRE,
  PROD,
} from 'shared/config/resolveApiEnv';

/**
 * Returns api key for API env
 * @return {string} apiKey;
 */
const resolveApiKey = () => {
  const env = resolveApiEnv();
  const keys = {
    [DEV]: 'oVCBxonYQ9aVmWZBdJ1nu6ysvkNwhMEO65S4gZUP',
    [SIT]: 'tzlcaHvksT9w5cX3NR6qc4yHePxYymB44xr08xD9',
    [PRE]: 'Zx0sc59lUO3dJaJ3CPAtC1Me19MJpwq75nIUiewR',
    [PROD]: 'QTziIHe6DLaLiz4NxzM1w8BiRdGJAgL32Z6mCEmq',
  };
  return keys[env];
};

export default resolveApiKey;
