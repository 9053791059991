import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { productDetailActions } from '../../actions/actions';
import * as cartActions from '../../../Cart/actions/cartActions';
import VariantOptions from '../../components/VariantOptions/VariantOptions';

const mapStateToProps = ({ selectedAccount, cart, shop, user }) => ({
  selectedAccount,
  cart,
  products: shop.products,
  product: shop.product,
  user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...cartActions,
      ...productDetailActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VariantOptions);
