import {
  SET_BANK_ACCOUNT_TOKEN,
  SET_SAVED_BANK_ACCOUNTS,
  UPDATE_BANK_ACCOUNT_ALIAS_FAILURE,
  UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS,
  UPDATE_BANK_ACCOUNT_ALIAS_SUCCESS,
  CLEAR_UPDATE_ALIAS_STATUS,
  DELETE_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT_IN_PROGRESS,
  DELETE_BANK_ACCOUNT_RESPONSE_CODE,
  DELETE_BANK_ACCOUNT_SUCCESS,
  CLEAR_DELETE_BANK_ACCOUNT_RESPONSE,
  CLEAR_PAYMENT_STATE,
  BANK_ACCOUNT_LIMIT_REACHED,
  GET_PAYMENT_METHOD_FAILURE,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_IN_PROGRESS,
} from '../../PaymentConstants';

const initialState = {
  vantivToken: null,
  bankAccountAliasStatus: null,
  deleteTokenStatus: null,
  paymentAccounts: [],
  deleteTokenResponse: null,
  overMaxPaymentAccountCount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BANK_ACCOUNT_TOKEN:
      return { ...state, vantivToken: action.payload.token };
    case UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS:
      return {
        ...state,
        bankAccountAliasStatus: UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS,
      };
    case UPDATE_BANK_ACCOUNT_ALIAS_SUCCESS:
      return {
        ...state,
        bankAccountAliasStatus: UPDATE_BANK_ACCOUNT_ALIAS_SUCCESS,
      };
    case UPDATE_BANK_ACCOUNT_ALIAS_FAILURE:
      return {
        ...state,
        bankAccountAliasStatus: UPDATE_BANK_ACCOUNT_ALIAS_FAILURE,
      };
    case CLEAR_UPDATE_ALIAS_STATUS:
      return { ...state, bankAccountAliasStatus: null };
    case SET_SAVED_BANK_ACCOUNTS:
      return { ...state, paymentAccounts: action.payload };
    case DELETE_BANK_ACCOUNT_IN_PROGRESS:
      return { ...state, deleteTokenStatus: DELETE_BANK_ACCOUNT_IN_PROGRESS };
    case DELETE_BANK_ACCOUNT_SUCCESS:
      return { ...state, deleteTokenStatus: DELETE_BANK_ACCOUNT_SUCCESS };
    case DELETE_BANK_ACCOUNT_FAILURE:
      return { ...state, deleteTokenStatus: DELETE_BANK_ACCOUNT_FAILURE };
    case DELETE_BANK_ACCOUNT_RESPONSE_CODE:
      return { ...state, deleteTokenResponse: action.payload };
    case CLEAR_DELETE_BANK_ACCOUNT_RESPONSE:
      return { ...state, deleteTokenResponse: null };
    case GET_PAYMENT_METHOD_IN_PROGRESS:
      return {
        ...state,
        getPaymentMethodStatus: GET_PAYMENT_METHOD_IN_PROGRESS,
      };
    case GET_PAYMENT_METHOD_SUCCESS:
      return { ...state, getPaymentMethodStatus: GET_PAYMENT_METHOD_SUCCESS };
    case GET_PAYMENT_METHOD_FAILURE:
      return { ...state, getPaymentMethodStatus: GET_PAYMENT_METHOD_FAILURE };
    case BANK_ACCOUNT_LIMIT_REACHED:
      return {
        ...state,
        overMaxPaymentAccountCount: BANK_ACCOUNT_LIMIT_REACHED,
      };
    case CLEAR_PAYMENT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
