import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TermsPropTypes from 'components/Terms/termsPropTypes';
import styles from './styles';

const TermsContent = ({ terms, classes }) => (
  <div
    className={classes.container}
    dangerouslySetInnerHTML={{
      __html: terms.termsData.data?.content || '',
    }}
  />
);

TermsContent.propTypes = {
  terms: TermsPropTypes.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(TermsContent);
