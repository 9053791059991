import React from 'react';
import Dialog from '@nutrien/uet-react/Dialog';
import DialogTitle from '@nutrien/uet-react/DialogTitle';
import DialogContent from '@nutrien/uet-react/DialogContent';
import DialogContentText from '@nutrien/uet-react/DialogContentText';
import DialogActions from '@nutrien/uet-react/DialogActions';
import Button from '@nutrien/uet-react/Button';
import useTerms from 'components/Terms/useTerms';
import { useAuth } from '@nutrien/fe-domain-utils';

const TermsModal = () => {
  const {
    terms: {
      termsVersion,
      modal: { open, route },
    },
    acceptTerms,
    declineTerms,
  } = useTerms();

  const auth = useAuth();
  /* eslint-disable-next-line camelcase */
  return auth.isAuth0Active && !auth.user?.email_verified ? null : (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={() => declineTerms()}
      disableBackdropClick
      disableEscapeKeyDown
      data-test="dialog"
    >
      <DialogTitle>Updated Terms of Use</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Our <a href="/accept-terms">Terms of Use and Privacy Policy</a> have
          been updated. Please review and accept or decline these terms.
        </DialogContentText>
        {/* <DialogContentText>
          If you are a California resident and would like to opt-out of having
          your information sold, please visit the following link:{' '}
          <a href="">Do Not Sell My Personal Information</a>
        </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className="button"
          onClick={() => declineTerms()}
          data-test="declineBtn"
        >
          Decline
        </Button>
        <Button
          variant="contained"
          className="button"
          onClick={() => acceptTerms(termsVersion, route)}
          data-test="acceptBtn"
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsModal;
