import PropTypes from 'prop-types';

export const notificationType = PropTypes.string;

export const notification = PropTypes.shape({
  id: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  type: notificationType.isRequired,
  entityId: PropTypes.string,
  isRead: PropTypes.bool.isRequired,
  variables: PropTypes.object,
});

export const notifications = PropTypes.arrayOf(notification);
