import { combineReducers } from 'redux';

import all from './all';
import error from './error';
import deleteNotificationFailure from './deleteNotificationFailure';
import fetching from './fetching';
import fetchedAt from './fetchedAt';
import selected from './selected';
import stagedForDeletion from './stagedForDeletion';

export default combineReducers({
  all,
  error,
  fetching,
  deleteNotificationFailure,
  fetchedAt,
  selected,
  stagedForDeletion,
});
