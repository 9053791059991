import {
  CXH_PRODUCTCATALOG_FILTER,
  CXH_PRODUCTCATALOG_LOAD,
  CXH_PRODUCTCATALOG_SORT,
  CXH_PRODUCTCATALOG_CLEAR_FILTERS,
} from '../gtmConstants';

export const handleFilterProducts = (action) => {
  const { modification, label } = action.payload;
  return {
    event: 'event_track',
    eventId: 'CXH_ProductCatalog_Filter',
    category: 'Product Catalog',
    action: modification, // 'Add Filter' || 'Remove Filter'
    label, // ex: 'Active Ingredient | Dicamba'
  };
};

export const handleLoadMoreProducts = (action) => {
  const { value } = action.payload;
  return {
    event: 'event_track',
    eventId: 'CXH_ProductCatalog_Load',
    category: 'Product Catalog',
    action: 'Load',
    label: 'Load More Products',
    value, // Populate with the number of results currently available
  };
};

export const handleProductListSort = (action) => {
  const { label, value } = action.payload;
  return {
    event: 'event_track',
    eventId: 'CXH_ProductCatalog_Sort',
    category: 'Product Catalog',
    action: 'Sort',
    label, // Populate with the text shown in the dropdown of the selected sort option.
    value, // Populate with the number of results available after the sort has been added or removed
  };
};

export const handleProductListClearFilters = (action) => {
  const { value } = action.payload;
  return {
    event: 'event_track',
    eventId: 'CXH_ProductCatalog_Clear_Filters',
    category: 'Product Catalog',
    action: 'Remove Filter',
    label: 'Clear All Filters',
    value,
  };
};

export const PLPEventMap = {
  [CXH_PRODUCTCATALOG_FILTER]: handleFilterProducts,
  [CXH_PRODUCTCATALOG_LOAD]: handleLoadMoreProducts,
  [CXH_PRODUCTCATALOG_SORT]: handleProductListSort,
  [CXH_PRODUCTCATALOG_CLEAR_FILTERS]: handleProductListClearFilters,
};
