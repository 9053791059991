import React from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { withStyles } from '@nutrien/uet-react/styles';
import Radio from '@nutrien/uet-react/Radio';
import Grid from '@nutrien/uet-react/Grid';
import classNames from 'classnames';

import thisStyles from './styles';

const SavedPaymentMethod = ({ classes, paymentMethod, styles }) => {
  const flags = useFlags();
  if (paymentMethod.featureFlag && !flags[paymentMethod.featureFlag]) {
    return null;
  }

  const bankAccountText = !paymentMethod.name
    ? `${paymentMethod.alias || paymentMethod.bankName} (ending in ${
        paymentMethod.accountNumberLastThree
      })`
    : paymentMethod.label;

  return (
    <Grid item md={12} data-test="saved-payment-method">
      <label className={classes.layout}>
        <Radio
          className={classes.radioButton}
          disableRipple
          checkedIcon={
            <span className={classNames(classes.icon, classes.checkIcon)} />
          }
          icon={<span className={classes.icon} />}
          name="savedPaymentMethod"
          value={paymentMethod.tokenValue}
        />
        <div className={classNames(classes.label, styles)}>
          <span>{bankAccountText}</span>
        </div>
      </label>
    </Grid>
  );
};

SavedPaymentMethod.propTypes = {
  classes: PropTypes.shape({
    label: PropTypes.string,
    layout: PropTypes.string,
    radioButton: PropTypes.string,
    icon: PropTypes.string,
    checkIcon: PropTypes.string,
  }).isRequired,
  styles: PropTypes.string,
  paymentMethod: PropTypes.shape({
    alias: PropTypes.string,
    bankName: PropTypes.string,
    accountNumberLastThree: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    featureFlag: PropTypes.string,
    tokenValue: PropTypes.string,
  }).isRequired,
};

SavedPaymentMethod.defaultProps = {
  styles: '',
};

export default withStyles(thisStyles)(SavedPaymentMethod);
