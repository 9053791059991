export const ADD_FILTER = 'Add Filter';
export const REMOVE_FILTER = 'Remove Filter';

export const nameTypes = {
  chemicals: 'Chemical',
  fertilizers: 'Fertilizer',
  activeIngredients: 'Active Ingredient',
  manufacturers: 'Manufacturer',
  cropTypes: 'Crop Type',
};
