export default ({ palette }) => {
  return {
    layout: {
      flexWrap: 'inherit',
    },
    card: {
      flexGrow: 0,
      flexShrink: 1,
    },
    paymentAmountMessageContainer: {
      maxWidth: '100%',
      position: 'relative',
    },
    paymentAmountInputContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    paymentAmountRadioContainer: {
      flexGrow: '1',
      backgroundColor: palette.grey['50'],
      border: `solid 1px ${palette.grey['200']}`,
      borderRadius: '4px',
      lineHeight: 'normal',
      minHeight: '115px',
      maxHeight: '200px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      maxWidth: '350px',
      width: '100%',
    },
    paymentAmountRadioContainerSelected: {
      outline: '0',
      border: `solid 1px ${palette.brand.green}`,
    },
    paymentAmountRadioContainerDisabled: {
      color: 'gray',
    },
    paymentAmountFormInput: {
      width: 'auto',
      height: '39px',
      borderRadius: '4px',
      backgroundColor: palette.background.default,
      border: `solid 1px ${palette.grey['200']}`,
      position: 'relative',
    },
    paymentAmountLabel: {
      color: palette.grey.A900,
    },
    paymentAmountValue: {
      fontSize: '2.0rem',
    },
    paymentAmountRadioInput: {
      position: 'relative',
    },
    accountInfoBlock: {
      display: 'grid',
    },
    error: {
      borderColor: `${palette.error.main} !important`,
      marginBottom: 0,
    },
    '@media screen and (min-width: 1500px)': {
      layout: {
        flexWrap: 'inherit',
      },
      card: {
        marginRight: '0px',
      },
      paymentAmountRadioContainer: {
        marginBottom: '10px',
      },
      paymentAmountInputWarningPadding: {
        marginBottom: '55px',
      },
    },
    '@media screen and (max-width: 1024px)': {
      card: {
        width: '32%',
        marginRight: '10px',
      },
    },
    '@media screen and (max-width: 800px)': {
      card: {
        width: '100%',
        marginBottom: '20px',
      },
      layout: {
        flexWrap: 'wrap',
      },
    },
    '@media screen and (max-width: 375px)': {
      layout: {
        flexWrap: 'wrap',
      },
      card: {
        width: '100%',
        marginBottom: '20px',
      },
      paymentAmountValue: {
        fontSize: '1.5rem',
      },
    },
    '@media screen and (max-width: 320px)': {
      layout: {
        flexWrap: 'wrap',
      },
      card: {
        width: '100%',
        marginBottom: '20px',
      },
      paymentAmountValue: {
        fontSize: '1rem',
      },
    },
  };
};
