import React from 'react';
import PropTypes from 'prop-types';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import { buildClassName } from '../../../../Payments/Utils/PaymentUtil';
import PaymentValidationMessageComponent from '../../../../Payments/OtherComponents/ValidationMessage/PaymentValidationMessageComponent';

import './PrePayAmountInputComponent.css';

const PrePayAmountInput = (props) => (
  <div>
    <label className="amount-label" htmlFor="amountEntered">
      Amount
    </label>
    <input
      type="text"
      name="amountEntered"
      placeholder="$0.00"
      className={buildClassName(
        'amountEntered',
        'amount-entered',
        props.errors,
        'error'
      )}
      onChange={props.handleOnChangeAmount}
      onBlur={props.handleOnBlurAmount}
      onFocus={props.handleOnChangeAmount}
      value={props.amountEntered}
    />
    <div className="cpa-payment-amount-message">
      <PaymentValidationMessageComponent
        errors={props.errors}
        warnings={props.warnings}
        field="amountEntered"
      />
    </div>
  </div>
);

PrePayAmountInput.propTypes = {
  errors: PaymentPropTypes.paymentFormErrors, // eslint-disable-line react/no-typos
  warnings: PaymentPropTypes.paymentFormWarnings, // eslint-disable-line react/no-typos
  amountEntered: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleOnChangeAmount: PropTypes.func.isRequired,
  handleOnBlurAmount: PropTypes.func.isRequired,
};

PrePayAmountInput.defaultProps = {
  errors: {},
  warnings: {},
};

export default PrePayAmountInput;
