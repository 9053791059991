import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Icon } from 'nutrien-common-components-react/dist/mdc/';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import { accountStatuses } from '../../../actions/constants';
import './InvoiceSearch.css';

class InvoiceSearchComponent extends Component {
  state = { searchTerm: '' };

  componentWillUnmount() {
    this.props.filterSearch('');
  }

  getProductList = () => {
    const {
      selectionOptions,
      selectProductFilter,
      currentlySelected,
      close,
      push,
      accountId,
    } = this.props;

    return selectionOptions.map((option) => (
      <ListItem
        key={option.sku}
        style={{ borderBottom: '1px solid lightgray' }}
      >
        <input
          type="radio"
          name="invoice-search-selection"
          value={option.sku}
          onClick={(e) => {
            push(`/accounts/${accountId}/invoices?typeFilter=productsearch`);
            selectProductFilter({ id: e.target.value });
            setTimeout(() => close(), 500);
          }}
          checked={currentlySelected === option.sku}
          onChange={() => {}}
        />
        <div className="invoice-search-option-container">
          <div>{option.name}</div>
          <div className="invoice-search-sku">{option.sku}</div>
        </div>
      </ListItem>
    ));
  };

  render() {
    const { searchTerm } = this.state;
    const { filterSearch, getProductListStatus } = this.props;

    return (
      <div className="invoice-search-container">
        <div className="invoice-search-absolute">
          <div className="invoice-search-title">Filter By Product</div>
          <div className="invoice-search-field-wrapper">
            <input
              type="search"
              margin="normal"
              label="Product name or #"
              value={searchTerm}
              onChange={(e) => {
                this.setState({ searchTerm: e.target.value });
                filterSearch(e.target.value);
              }}
              className="invoice-search-text-field"
            />
            {searchTerm === '' ? null : (
              <Icon
                data-test="invoice-search-close-icon"
                icon="close"
                onClick={() => {
                  this.setState({ searchTerm: '' });
                  filterSearch('');
                }}
              />
            )}
            <SearchIcon
              style={{
                position: 'relative',
                top: '8px',
                border: '1px solid lightgray',
                height: '28px',
                width: '28px',
              }}
            />
          </div>
          {getProductListStatus === accountStatuses.LOADING ? (
            <MaterialLoadingIcon />
          ) : null}
          <List
            style={{
              maxHeight: 400,
              overflow: 'auto',
              backgroundColor: '#f8f8f8',
              borderRadius: '4px',
            }}
          >
            {this.getProductList()}
          </List>
        </div>
      </div>
    );
  }
}

InvoiceSearchComponent.propTypes = {
  selectionOptions: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, sku: PropTypes.string })
  ).isRequired,
  filterSearch: PropTypes.func.isRequired,
  selectProductFilter: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  currentlySelected: PropTypes.string,
  push: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  getProductListStatus: PropTypes.string.isRequired,
};

InvoiceSearchComponent.defaultProps = {
  currentlySelected: '',
};

export default InvoiceSearchComponent;
