import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReCAPTCHA from 'react-google-recaptcha';
import resolveApiEnv from 'shared/config/resolveApiEnv';
import { withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import axios from 'shared/lib/cxhApiService';
import Card from '@nutrien/uet-react/Card';
import { Link } from 'react-router-dom';
import Form from 'shared/ui/MaterialFormCard/Form';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import { validateEmail } from 'shared/utils/formValidation';
import { BreadCrumb, BreadCrumbContainer } from 'shared/ui/BreadCrumbs';
import { formatPhoneNumber } from 'utils/phoneUtils';
import ChevronRight from '@material-ui/icons/ChevronRight';
import UserPropType from 'shared/config/userPropType';
import './Feedback.css';
import styles from './styles';
import {
  SUBJECT_REQUIRED,
  TEN_OR_MORE_CHARACTERS_REQUIRED,
  VALID_EMAIL_REQUIRED,
  LAST_NAME_REQUIRED,
  FIRST_NAME_REQUIRED,
  FILL_OUT_HIGHLIGHTED_FIELDS,
} from './FeedbackConstants';

class Feedback extends Component {
  state = {
    firstName: this.props.user.firstName,
    firstNameError: '',
    lastName: this.props.user.lastName,
    lastNameError: '',
    email: this.props.user.email,
    emailError: '',
    phone: this.props.user.phoneNumber,
    subject: '',
    subjectError: '',
    message: '',
    messageError: '',
    showOverallError: false,
    successfullySubmitted: this.props.successfullySubmitted,
    overallErrorMessage: FILL_OUT_HIGHLIGHTED_FIELDS,
    recaptchaRef: React.createRef(),
  };

  componentDidMount() {
    if (
      !this.props.user.firstName ||
      !this.props.user.lastName ||
      !this.props.user.email
    ) {
      this.props.fetchUser().then((response) => {
        this.setUserInfo(response.data);
      });
    }
  }

  setUserInfo = (userInfo) => {
    if (userInfo === undefined || userInfo === null) return;
    this.setState({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
    });
    if (userInfo.phoneNumber !== null) {
      this.setState({
        phone: userInfo.phoneNumber,
      });
    }
  };

  resolveApiEnvironment = () => resolveApiEnv();

  sendEmail = (googleRecaptchaKey) => {
    if (this.hasErrorOrEmptyField()) this.applyFormErrors();
    // TODO: this should be a service method,
    // and it should be called via a dispatched action
    return axios
      .post('/v1/email/support', {
        parameters: {
          googleRecaptchaKey,
          isFeedback: true,
          subject: this.state.subject,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone || '',
          message: this.state.message,
        },
      })
      .catch(() => {
        this.setState({
          overallErrorMessage:
            'Oops, something went wrong. Try again in a few minutes.',
        });
        this.setState({ showOverallError: true });
        this.state.recaptchaRef.current.reset();
      })
      .then(() => {
        if (!this.state.showOverallError) {
          this.setState({ successfullySubmitted: true });
        }
      });
  };

  applyFormErrors = () => {
    if (this.state.email === '' || this.state.email === undefined) {
      this.setState({ emailError: VALID_EMAIL_REQUIRED });
    }
    if (this.state.firstName === '' || this.state.firstName === undefined) {
      this.setState({ firstNameError: FIRST_NAME_REQUIRED });
    }
    if (this.state.lastName === '' || this.state.lastName === undefined) {
      this.setState({ lastNameError: LAST_NAME_REQUIRED });
    }
    if (this.state.subject === '') {
      this.setState({ subjectError: SUBJECT_REQUIRED });
    }
    if (this.state.message === '') {
      this.setState({ messageError: TEN_OR_MORE_CHARACTERS_REQUIRED });
    }

    this.setState({ overallErrorMessage: FILL_OUT_HIGHLIGHTED_FIELDS });
    this.setState({ showOverallError: true });
  };

  // TODO: should be a util method
  validateInput = (e) => {
    const { value, name } = e.target;

    if (name === 'email' && value && !validateEmail(value)) {
      this.setState({ emailError: VALID_EMAIL_REQUIRED });
    } else if (name === 'email' && value.trim() === '') {
      this.setState({ emailError: VALID_EMAIL_REQUIRED });
    } else if (name === 'email' && value && validateEmail(value)) {
      this.setState({ emailError: '' });
    }

    if (name === 'firstName' && value.length < 1) {
      this.setState({ firstNameError: FIRST_NAME_REQUIRED });
    } else if (name === 'firstName' && value.length >= 1) {
      this.setState({ firstNameError: '' });
    }

    if (name === 'lastName' && value.length < 1) {
      this.setState({ lastNameError: LAST_NAME_REQUIRED });
    } else if (name === 'lastName' && value.length >= 1) {
      this.setState({ lastNameError: '' });
    }

    if (name === 'subject' && value.length < 1) {
      this.setState({ subjectError: SUBJECT_REQUIRED });
    } else if (name === 'subject' && value.length >= 1) {
      this.setState({ subjectError: '' });
    }

    if (name === 'message' && value.length < 10) {
      this.setState({ messageError: TEN_OR_MORE_CHARACTERS_REQUIRED });
    } else if (name === 'message' && value.length >= 10) {
      this.setState({ messageError: '' });
    }
  };

  handleChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  determineClasses = (errorState) => {
    const { classes } = this.props;
    const classObject = {
      input: classes.feedbackInput,
    };

    if (errorState !== '') {
      classObject.input = classes.redBorder;
    }

    classObject.textField = classes.feedbackTextField;
    return classObject;
  };

  hasErrorOrEmptyField = () => {
    if (
      this.state.firstNameError === '' &&
      this.state.lastNameError === '' &&
      this.state.emailError === '' &&
      this.state.messageError === '' &&
      this.state.subjectError === '' &&
      this.state.firstName !== '' &&
      this.state.lastName !== '' &&
      this.state.email !== '' &&
      this.state.message !== '' &&
      this.state.subject !== ''
    ) {
      return false;
    }
    return true;
  };

  formatPhone = () => {
    this.setState({ phone: formatPhoneNumber(this.state.phone) });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.hasErrorOrEmptyField()) {
      this.setState({ showOverallError: false });
      this.state.recaptchaRef.current.execute();
    } else {
      this.applyFormErrors();
    }
  };

  render() {
    const { classes } = this.props;
    const feedbackForm = (
      <div>
        <Card noPadding>
          <BreadCrumbContainer>
            <BreadCrumb text="Home" link="/" />
            <BreadCrumb text="Feedback" />
          </BreadCrumbContainer>
          <div style={{ margin: '6px 20px' }}>
            <hr />
          </div>
          <div className="feedback__page">
            <div className="feedback__title-container">
              <h2>Feedback</h2>
              <h4 className="feedback__subtitle">Do You Have Feedback?</h4>
              <p className="feedback__description">
                We want your input so we can improve and bring you the features
                you need.
              </p>
            </div>
            {this.state.showOverallError ? (
              <div
                className="overall-error-container"
                style={{
                  border: '1px solid #D24242',
                  backgroundColor: '#feeded',
                }}
              >
                <WarningIcon className="feedback__overall-warning-icon" />
                <span className="feedback__overall-error-message">
                  {this.state.overallErrorMessage}
                </span>
              </div>
            ) : (
              ''
            )}
            <div className="feedback__form">
              <Form onSubmit={this.handleSubmit}>
                <FormTextField
                  name="firstName"
                  title="First Name"
                  value={this.state.firstName}
                  handleChange={this.handleChange}
                  error={this.state.firstNameError}
                  classes={this.determineClasses(this.state.firstNameError)}
                  onBlur={this.validateInput}
                  autocomplete="firstName"
                />
                <FormTextField
                  name="lastName"
                  title="Last Name"
                  value={this.state.lastName}
                  error={this.state.lastNameError}
                  handleChange={this.handleChange}
                  classes={this.determineClasses(this.state.lastNameError)}
                  onBlur={this.validateInput}
                  autocomplete="lastName"
                />
                <FormTextField
                  name="email"
                  title="Email Address"
                  value={this.state.email}
                  handleChange={this.handleChange}
                  classes={this.determineClasses(this.state.emailError)}
                  error={this.state.emailError}
                  onBlur={this.validateInput}
                  autocomplete="email"
                />
                <label htmlFor="phone" className="feedback__phone-label">
                  Phone Number{' '}
                  <span className="feedback__optional-span">(Optional)</span>
                </label>
                <FormTextField
                  name="phone"
                  title=""
                  placeholder="(   ) ___-____"
                  value={this.state.phone}
                  handleChange={this.handleChange}
                  onBlur={this.formatPhone}
                  classes={{
                    input: classes.feedbackInput,
                    textField: classes.feedbackTextField,
                  }}
                  autocomplete="phoneNumber"
                />
                <FormTextField
                  name="subject"
                  title="Subject"
                  value={this.state.subject}
                  error={this.state.subjectError}
                  handleChange={this.handleChange}
                  onBlur={this.validateInput}
                  classes={this.determineClasses(this.state.subjectError)}
                  autocomplete="subject"
                />
                <div className="feedback__message-container">
                  <label htmlFor="message" className="feedback__message-label">
                    Message
                  </label>
                  <textarea
                    name="message"
                    value={this.state.message}
                    onChange={this.handleChange}
                    className={
                      this.state.messageError === ''
                        ? 'feedback__message-textarea'
                        : 'feedback__message-textarea feedback__red-border'
                    }
                    onBlur={this.validateInput}
                    placeholder="Provide a short response"
                  />
                  {this.state.messageError === '' ? (
                    ''
                  ) : (
                    <span className="message-error">
                      {this.state.messageError}
                    </span>
                  )}
                </div>
                <ReCAPTCHA
                  ref={this.state.recaptchaRef}
                  size="invisible"
                  sitekey="6LcUCXMUAAAAAHUK3XUmB7yynvF0aN_2UDt82oRJ"
                  onChange={this.sendEmail}
                  badge="bottomleft"
                />
                <div className="feedback__submit-button-container">
                  <MaterialButton
                    type="submit"
                    value="Submit"
                    className="feedback__submit-button"
                    style={{ width: '127px' }}
                  />
                </div>
              </Form>
            </div>
          </div>
        </Card>
      </div>
    );
    const feedbackConfirmation = (
      <div>
        <Card className="feedback__confirmation-card">
          <BreadCrumbContainer>
            <BreadCrumb text="Home" link="/" />
            <BreadCrumb text="Feedback" />
          </BreadCrumbContainer>
          <div style={{ margin: '6px 20px' }}>
            <hr />
          </div>
          <div className="feedback__page">
            <div className="feedback__title-container">
              <h2>Thank you for the feedback!</h2>
              <h4 className="feedback__subtitle">
                You are helping us make our site better.
              </h4>
            </div>
            <Link className="feedback__home-link" to="/">
              Back to Home <ChevronRight />
            </Link>
          </div>
        </Card>
      </div>
    );
    if (this.state.successfullySubmitted) {
      window.scroll(0, 0);
      return feedbackConfirmation;
    }
    return feedbackForm;
  }
}

Feedback.propTypes = {
  user: UserPropType,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fetchUser: PropTypes.func.isRequired,
  successfullySubmitted: PropTypes.bool,
};

Feedback.defaultProps = {
  user: {},
  successfullySubmitted: false,
};

export default withStyles(styles)(Feedback);
