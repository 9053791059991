import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'nutrien-common-components-react/dist/mdc';

import './styles.scss';

const renderValueForButton = (value) => {
  if (typeof value === 'string') {
    return value;
  }

  if (value instanceof Array) {
    if (!value.length) {
      return '';
    }

    if (value.length === 1) {
      return `${value[0]}`;
    }

    if (value.length === 2) {
      if (value[0] === '' && value[1] === '') {
        return `All`;
      }
      if (value[0] === '' && value[1] !== '') {
        return `<= ${value[1]}`;
      }
      if (value[0] !== '' && value[1] === '') {
        return `>= ${value[0]}`;
      }
    }

    return `${value[0]} - ${value[1]}`;
  }

  return '';
};

// TODO: rename this domain to 'FilterButton' - it will be used for all filters that need a header button.
// <Icon className="invoicelist-filter-button__icon" icon="calendar" />
const FilterButton = forwardRef(({ onClick, value, icon }, ref) => (
  <button className="invoicelist-filter-button" onClick={onClick} ref={ref}>
    <span className="invoicelist-filter-button__value">
      {renderValueForButton(value)}
    </span>
    <Icon className="invoicelist-filter-button__icon" icon={icon} />
  </button>
));

FilterButton.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  icon: PropTypes.string,
};

FilterButton.defaultProps = {
  onClick: () => {},
  value: '',
  icon: 'arrowdown',
};
export default FilterButton;
