import { useEffect } from 'react';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import noop from 'lodash/noop';

const callIfDefined = (func) => defaultTo(func, noop)();

const handleClickCurried = (node, onInsideClick, onOutsideClick) => (e) => {
  const current = get(node, 'current');

  if (!current) {
    return;
  }

  if (current.contains(e.target)) {
    callIfDefined(onInsideClick);
    return;
  }

  callIfDefined(onOutsideClick);
};

const useOutsideClickHandler = ({ node, onOutsideClick, onInsideClick }) => {
  const handleClick = handleClickCurried(node, onInsideClick, onOutsideClick);
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
};

export default useOutsideClickHandler;
