import { combineReducers } from 'redux';

import products from './productsReducer/productsReducer';
import product from './productDetailReducer/productDetailReducer';
import search from './searchReducer/searchReducer';
import sort from './sortReducer/sortReducer';
import categories from './categoryReducer/categoryReducer';
import filters from './filterReducer/filterReducer';
import productCrossSells from './crossSellsReducer/crossSellsReducer';
import recentProducts from './recentProductsReducer/recentProductsReducer';

export const shopReducer = combineReducers({
  products,
  product,
  search,
  sort,
  categories,
  filters,
  productCrossSells,
  recentProducts,
});
