import { SET_INVOICE_DATE_RANGE } from '../actions';

export default (state = 'All', action) => {
  switch (action.type) {
    case SET_INVOICE_DATE_RANGE:
      return action.payload.filterType;
    default:
      return state;
  }
};
