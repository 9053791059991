import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Grid } from 'nutrien-common-components-react/dist/mdc/Grid';

import HubPromotion from './HubPromotion';

import './styles.scss';

const EcommercePromotion = () => {
  const isMobile = useMediaQuery('(max-width: 839px)'); // phones

  return (
    <Grid
      style={{ margin: isMobile ? -16 : -25 }}
      data-test="ecomm-promo-title"
    >
      <HubPromotion />
    </Grid>
  );
};

export default EcommercePromotion;
