let zxcvbnService = () => ({ score: 0 });

const zxcvbnInterval = setInterval(() => {
  if (window.zxcvbn) {
    zxcvbnService = window.zxcvbn;
    clearInterval(zxcvbnInterval);
  }
}, 100);

export default (password) => zxcvbnService(password);
