import get from 'lodash/get';
import { GET_CART_FAILURE } from '../actionTypes';

export default (error) => {
  const err = get(error, 'message', 'There was an error retrieving the cart');
  return {
    type: GET_CART_FAILURE,
    loading: false,
    error: err,
  };
};
