import PropTypes from 'prop-types';

export const accountPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  number: PropTypes.string,
});

export const accountActivityElementPropType = PropTypes.shape({
  id: PropTypes.string,
  transactionDate: PropTypes.string,
  transactionDescription: PropTypes.string,
  type: PropTypes.string,
  originalAmount: PropTypes.number,
  dueDate: PropTypes.string,
  balance: PropTypes.number,
});

export const accountActivityPropType = PropTypes.arrayOf(
  accountActivityElementPropType
);

export const accountTabPropType = PropTypes.shape({
  tab: PropTypes.string,
});

export const invoiceMatchParams = PropTypes.shape({
  params: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    invoiceId: PropTypes.string.isRequired,
  }).isRequired,
});

export const invoicePropType = PropTypes.shape({
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      invoiceLineItemNumber: PropTypes.number,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      orderNumber: PropTypes.string,
      productDescription: PropTypes.string,
      quantity: PropTypes.number,
      uom: PropTypes.string,
      grossUnitPrice: PropTypes.number,
      salesTax: PropTypes.number,
      grossExtendedPrice: PropTypes.number,
    })
  ),
  id: PropTypes.string,
  date: PropTypes.string,
  dueDate: PropTypes.string,
  amountDue: PropTypes.number,
  status: PropTypes.string,
  availableDiscount: PropTypes.number,
  deliveryDate: PropTypes.string,
  orderNumber: PropTypes.string,
  salesRep: PropTypes.string,
  shipVia: PropTypes.string,
  poNumber: PropTypes.string,
  subTotal: PropTypes.number,
  salesTax: PropTypes.number,
  total: PropTypes.number,
  lessPrepayUsed: PropTypes.number,
  lessPrepayDiscount: PropTypes.number,
  grossInvoiceAmount: PropTypes.number,
  discountApplicable: PropTypes.bool,
  totalPotentialDiscount: PropTypes.number,
  hasSlidingDiscount: PropTypes.bool,
  invoiceFile: PropTypes.shape({
    downloadLink: PropTypes.string,
  }),
});

export const stateInvoicePropType = PropTypes.shape({
  status: PropTypes.string.isRequired,
  invoice: invoicePropType,
});
