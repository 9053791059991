import React from 'react';
import PropTypes from 'prop-types';
import getUnitOfMeasureLabel from 'components/Shop/utils/getUnitOfMeasureLabel';
import withLocationCode from 'shared/utils/CountryCode/withLocationCode';
import OrderItem from '../OrderItem/OrderItem';
import getPriceLabel from '../../../utils/getPriceLabel';

import './orderTable.scss';

const OrderTable = ({ products, isCanada }) => (
  <table className="order-table" data-test="order-cart-table">
    <tbody className="order-table__body">
      <tr className="order-table__row">
        <th
          className="order-table__header"
          data-test="order-table-header-description"
        >
          Description
        </th>
        <th className="order-table__header" data-test="order-table-header-uom">
          {getUnitOfMeasureLabel(isCanada)}
        </th>
        <th
          className="order-table__header"
          data-test="order-table-header-quantity"
        >
          Order Quantity
        </th>
        <th
          className="order-table__header"
          data-test="order-table-header-price"
        >
          {getPriceLabel(isCanada)}
        </th>
        <th
          className="order-table__header--last"
          data-test="order-table-header-subtotal"
        >
          Subtotal
        </th>
      </tr>
      {products.map((product) => (
        <OrderItem
          product={product}
          key={product.productId}
          isCanada={isCanada}
        />
      ))}
    </tbody>
  </table>
);

OrderTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCanada: PropTypes.bool.isRequired,
};

export { OrderTable };

export default withLocationCode(OrderTable);
