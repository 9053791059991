const styles = {
  redBorder: {
    borderColor: '#D24242',
    maxWidth: '682px',
  },
  feedbackInput: {
    paddingLeft: '12px',
    maxWidth: '682px',
  },
  feedbackTextField: {
    maxWidth: '682px',
  },
};

export default styles;
