import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import Tab from '@nutrien/uet-react/Tab';
import Tabs from '@nutrien/uet-react/Tabs';
import Divider from '@nutrien/uet-react/Divider';
import { fetchAssociatedOrgsAction as getAccounts } from 'shared/ui/AccountsTable/actions/AccountActions';
import { selectAccounts, selectSelectedAccount, selectUser } from 'selectors';
import { setAccountTab } from '../../actions';
import { AccountDescriptionText } from '../../shared/ui';
import PageHeader from '../../shared/ui/PageHeader/PageHeader';
import Overview from './Overview';
import StatementsContainer from './Statements';
import Invoices from './Invoices';
import OpenItems from './OpenItems';
import PrePay from './PrePay';
import { PaymentsTab } from './Payments';
import './account.scss';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: 24,
  },
  tabLabel: {
    color: theme.palette.text,
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(selectUser());
  const { pathname, search } = useLocation();
  const { id: paramsId } = useParams();
  const accounts = useSelector(selectAccounts());
  const selectedAccount = useSelector(selectSelectedAccount());
  const tabValue = useSelector((state) => state.accountTab);

  const { userDetails } = user;
  const { status, list } = accounts;
  const { id, nickname, name } = selectedAccount;

  const pushCorrectedUrl = React.useCallback(() => {
    const { acct: queryId } = queryString.parse(search);
    if (id !== paramsId) {
      dispatch(
        push({
          pathname: `/accounts/${queryId}/overview`,
          search: `?acct=${queryId}`,
        })
      );
    }
  }, [dispatch, id, paramsId, search]);

  React.useEffect(() => {
    if (status === 'FETCHING') return;

    if (!list || !list.length) {
      dispatch(getAccounts((userDetails || {}).associatedOrgs || []));
    }
  }, [dispatch, status, list, userDetails]);

  React.useEffect(() => {
    if (id) pushCorrectedUrl();
  }, [pushCorrectedUrl, id, pathname]);

  React.useEffect(() => {
    if (!tabValue || pathname.includes('overview'))
      dispatch(setAccountTab('overview'));
  }, [dispatch, tabValue, pathname]);

  if (!status || status === 'FETCHING') return null;

  if (!id || id !== paramsId) return null;

  return (
    <>
      {(nickname || name) && (
        <PageHeader
          title={`${nickname || name} - ${id}`}
          breadcrumbs={[
            { link: '/', label: 'Home' },
            { link: '/accounts', label: 'Accounts' },
            { label: <AccountDescriptionText account={selectedAccount} /> },
          ]}
        />
      )}
      <Tabs
        value={tabValue || 'overview'}
        className="account-tabs"
        data-test="account-tabs"
        onChange={(_, newValue) => dispatch(setAccountTab(newValue))}
        variant="scrollable"
        scrollButtons="off"
      >
        <Tab
          value="overview"
          component={Link}
          label="Overview"
          to={`/accounts/${id}/overview`}
          data-test="account-tab-overview"
          className={classes.tabLabel}
        />
        <Tab
          value="statements"
          component={Link}
          label="Statements"
          to={`/accounts/${id}/statements`}
          data-test="account-tabs-statements"
          className={classes.tabLabel}
        />
        <Tab
          value="invoices"
          component={Link}
          label="Invoices"
          to={`/accounts/${id}/invoices`}
          data-test="account-tabs-invoices"
          className={classes.tabLabel}
        />
        <Tab
          value="open-items"
          component={Link}
          label="Open Items"
          to={`/accounts/${id}/open-items`}
          data-test="account-tab-open-items"
          className={classes.tabLabel}
        />
        <Tab
          value="pre-pay"
          component={Link}
          label="Pre-Pay"
          to={`/accounts/${id}/pre-pay`}
          data-test="account-tab-pre-pay"
          className={classes.tabLabel}
        />
        <Tab
          value="payments"
          component={Link}
          label="Payments"
          data-test="account-tab-payments"
          to={`/accounts/${id}/payments`}
          className={classes.tabLabel}
        />
      </Tabs>
      <Divider className={classes.divider} />
      <Switch>
        <Route path="/accounts/:id/overview">
          <Overview />
        </Route>
        <Route exact path="/accounts/:id/statements">
          <StatementsContainer accountId={id} {...props} />
        </Route>
        <Route exact path="/accounts/:id/invoices">
          <Invoices accountId={id} {...props} />
        </Route>
        <Route exact path="/accounts/:id/open-items">
          <OpenItems />
        </Route>
        <Route exact path="/accounts/:id/pre-pay">
          <PrePay accountId={id} {...props} />
        </Route>
        <Route exact path="/accounts/:id/payments">
          <PaymentsTab accountId={id} recurringPayments={[]} {...props} />
        </Route>
      </Switch>
    </>
  );
};

Account.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

Account.defaultProps = {
  history: {
    push: () => {},
  },
};

export default Account;
