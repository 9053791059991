const itemsInsideCard = [
  'dropdownButton',
  'dropdown-button',
  'dropdown__option-name',
  'variant-options__add-to-cart-button',
  'product-price__no-price-message-button-plp',
  'variant-options__prices-loader',
];

export const checkForClassName = (element, className) => {
  if (element.className.split(' ').indexOf('product-link') >= 0) {
    return false;
  }

  if (element.className.split(' ').indexOf(className) >= 0) {
    return true;
  }
  // if the element doesnt fall into either category but has a parent element, run the function again to check for the class
  // this way we are sure of the intended click target
  return (
    element.parentNode &&
    element.parentNode.className &&
    checkForClassName(element.parentNode, className)
  );
};

export const setClickArea = (element, id, history, attributes) => {
  element.preventDefault();
  let clickedOnInnerElement;

  if (
    element.target.className &&
    typeof element.target.className === 'string'
  ) {
    clickedOnInnerElement = itemsInsideCard.map((itemInsideCard) => {
      return checkForClassName(element.target, itemInsideCard);
    });
  }

  if (clickedOnInnerElement && !clickedOnInnerElement.includes(true)) {
    history.push({
      pathname: `/products/${id}`,
      state: { fromProductListPage: true, attributes },
    });
  }
};
