import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const MarketTable = (props) => {
  const { classes, commodities } = props;

  const renderCommodities = (commoditiesArray) =>
    commoditiesArray.map((commodity) => (
      <tr key={commodity.symbol} className={classes.tableRow}>
        <td>{commodity.symbol}</td>
        <td className={classes.alignRight}>{commodity.last}</td>
        <td className={classes.alignRight}>{commodity.previous}</td>
        {parseFloat(commodity.change) < 0 ? (
          <td className={classes.alignRight}>
            <span className={classes.redText}>{commodity.change}</span>
          </td>
        ) : (
          <td className={classes.alignRight}>
            <span className={classes.greenText}>{commodity.change}</span>
          </td>
        )}
        <td className={classes.alignRight}>{commodity.open}</td>
        <td className={classes.alignRight}>{commodity.close}</td>
        <td className={classes.alignRight}>{commodity.high}</td>
        <td className={classes.alignRight}>{commodity.low}</td>
      </tr>
    ));

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            <th className={classes.alignRight}>Last</th>
            <th className={classes.alignRight}>Previous</th>
            <th className={classes.alignRight}>Change</th>
            <th className={classes.alignRight}>Open</th>
            <th className={classes.alignRight}>Close</th>
            <th className={classes.alignRight}>High</th>
            <th className={classes.alignRight}>Low</th>
          </tr>
        </thead>
        <tbody>{renderCommodities(commodities)}</tbody>
      </table>
    </div>
  );
};

MarketTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  commodities: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      last: PropTypes.string,
      previous: PropTypes.string,
      change: PropTypes.string,
      open: PropTypes.string,
      close: PropTypes.string,
      high: PropTypes.string,
      low: PropTypes.string,
    })
  ).isRequired,
};

export default withStyles(styles)(MarketTable);
