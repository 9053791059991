import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  checkBreachedPassword,
  validatePassword,
} from 'shared/utils/formValidation';

import PasswordCriteria from './PasswordCriteria';

class PasswordCriteriaContainer extends Component {
  static propTypes = {
    onCheckIsPasswordSafe: PropTypes.func,
    password: PropTypes.string.isRequired,
    // pass in to override the default implementation of replacing the PasswordCriteria component with a modal
    // because it overrides toggleModal, this also means this container's isModalShowing won't get toggled,
    // and no further logic is needed to be tweaked in the PasswordCriteria
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    onCheckIsPasswordSafe: () => {},
    toggleModal: undefined,
  };

  state = {
    isPasswordSafe: false,
    isModalShowing: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.checkPasswordIsSafe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.password !== this.props.password) {
      this.checkPasswordIsSafe();
    }
  }

  toggleModal = () => {
    this.setState({
      isModalShowing: !this.state.isModalShowing,
    });
  };

  checkPasswordIsSafe() {
    const { password } = this.props;
    if (validatePassword(password)) {
      checkBreachedPassword(password).then((isPasswordSafe) => {
        this.setState({ isPasswordSafe });
        this.props.onCheckIsPasswordSafe(isPasswordSafe);
      });
    } else {
      this.setState({ isPasswordSafe: false });
      this.props.onCheckIsPasswordSafe(false);
    }
  }

  render() {
    return (
      <PasswordCriteria
        {...this.props}
        isPasswordSafe={this.state.isPasswordSafe}
        toggleModal={this.props.toggleModal || this.toggleModal}
        isModalShowing={this.state.isModalShowing}
      />
    );
  }
}

export default PasswordCriteriaContainer;
