import { INVOICES_UPDATE_FILTERS } from './constants';

/**
 * This is used to update the invoice filters object in the redux store. You supply a basic object of your filter state,
 * and it will get shallow-merged into the invoice filters redux store.
 * @param  {Object} filters a basic key/value pair object you want merged into the filter object.
 * @return {undefined}
 */
export default (filters) => ({
  type: INVOICES_UPDATE_FILTERS,
  payload: filters,
});
