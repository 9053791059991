import get from 'lodash/get';
import numeral from 'numeral';
import MINIMUM_BULK_QUANTITY from 'shared/constants/minimumBulkQuantity';
import adjustToFixed from '../../../Cart/utils/AdjustToFixed/adjustToFixed';

const calculateDisplayPrice = (totalVolume, cart) => {
  const variant = get(cart, 'mostRecentAddition.variant');

  if (!variant) {
    return '0';
  }

  let totalPrice;
  let pricePer = variant.price;

  if (pricePer) {
    pricePer = adjustToFixed(pricePer);
    totalPrice = pricePer * totalVolume;
    totalPrice = adjustToFixed(totalPrice);
    return numeral(totalPrice).format('$0,0.00');
  }

  return '0';
};

const getProductInfo = (mostRecentAddition, isCanadian) => {
  const productCategory = get(
    mostRecentAddition.product.attributes,
    'categories[0].subCategories[0].name'
  );
  let activeIngredient;
  if (isCanadian) {
    activeIngredient = mostRecentAddition.product.attributes.common_name;
  } else {
    activeIngredient = get(
      mostRecentAddition.product.attributes,
      'active_ingredients[0].name'
    );
  }
  return [productCategory, activeIngredient].join(' / ');
};

const isValidBulkInput = (value) => value >= MINIMUM_BULK_QUANTITY;

export { calculateDisplayPrice, getProductInfo, isValidBulkInput };
