// This function takes a number and returns a number representing the amount of decimal places
export default function getDecimalPlaces(num, maxPrecision = 4) {
  if (typeof num !== 'number') return null;

  let x = Math.abs(num);

  let i = 0;
  while (x % 1 > 0 && i < maxPrecision) {
    i += 1;
    x *= 10;
  }
  return i;
}
