import { errorStyling, mobileButtonContainerStyles } from 'shared/styles';

const styles = {
  title: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  link: {
    float: 'right',
    fontSize: 14,
    marginTop: 3,
    marginBottom: 10,
  },
  error: {
    ...errorStyling,
  },
  buttonContainer: {
    margin: '20px 0px 35px',
    ...mobileButtonContainerStyles,
  },
  hrMarginOverride: {
    margin: '-20px 0 0',
  },
  '@media (min-width: 720px)': {
    buttonContainer: {
      margin: '20px 30px 35px',
    },
    link: {
      float: 'left',
    },
  },
};

export default styles;
