import {
  GTM_VIEW_CONTRACT_DETAILS,
  GTM_SHOP_CONTRACTED_SKU,
} from '../gtmConstants';

export const viewContractDetails = () => ({
  event: 'VIEW_CONTRACT_DETAILS',
});

export const shopContractedSku = (action) => ({
  event: 'SHOP_CONTRACTED_SKU',
  cxh: {
    shopContractedSkuClickLocation: action.payload.origin,
  },
});

export const documentsMap = {
  [GTM_VIEW_CONTRACT_DETAILS]: viewContractDetails,
  [GTM_SHOP_CONTRACTED_SKU]: shopContractedSku,
};
