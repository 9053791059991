import {
  buttonContainerStyles,
  mobileButtonContainerStyles,
} from 'shared/styles';

const styles = (theme) => ({
  '@global': {
    p: {
      margin: '0px 0px',
      fontSize: 13,
    },
  },
  container: {
    margin: '0px 10px',
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.14,
    margin: '0px 0px 15px',
    color: theme.palette.text.primary,
  },
  list: {
    margin: '15px 0px 30px -25px',
    '& li': {
      fontSize: 13,
      fontWeight: 300,
      color: '#828282',
    },
  },
  bold: {
    fontWeight: 600,
  },
  buttonContainer: {
    ...mobileButtonContainerStyles,
    padding: '30px 0px 0px',
  },
  '@media (min-width: 720px)': {
    buttonContainer: {
      ...buttonContainerStyles,
    },
  },
});

export default styles;
