import { Button } from 'nutrien-common-components-react/dist/mdc/Button/Button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'nutrien-common-components-react/dist/mdc/Table';
import { arrayOf, func, shape, string } from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import withLocationCode from 'shared/utils/CountryCode/withLocationCode';
import FilteredOrders from './FilteredOrders';

import '../OrdersLanding.scss';

const OrdersTable = ({ orders, filteredOrders, history }) =>
  orders && orders.length ? (
    <>
      <Table className="orders-list__list" data-test="orders-table">
        <TableHead className="orders-list__table-mobile-hidden">
          <TableRow>
            <TableCell className="orders-list__table-header-cell orders-list__table-order-id-column">
              Order ID
            </TableCell>
            <TableCell className="orders-list__table-header-cell orders-list__table-date-column">
              Date
            </TableCell>
            <TableCell className="orders-list__table-header-cell orders-list__table-subtotal-column">
              Subtotal
            </TableCell>
            <TableCell className="orders-list__table-header-cell orders-list__table-status-column orders-list__table-status-column-header ">
              Status
            </TableCell>
            <TableCell className="orders-list__table-header-cell orders-list__table-button-column orders-list__table-button-column-header " />
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.length && (
            <FilteredOrders filteredOrders={filteredOrders} history={history} />
          )}
        </TableBody>
      </Table>
      <div className="orders-list__note-container">
        Note: This screen only shows orders made online.
      </div>
    </>
  ) : (
    <>
      <div className="orders-list__empty-message-container">
        <p data-test="order-load-message">
          There are no online orders available for this account.
        </p>
      </div>
      <Link to="/products" className="orders-list__products-link-wrapper">
        <Button
          variant="contained"
          className="orders-list__products-link-button"
        >
          Browse Products Catalog
        </Button>
      </Link>
      <div className="orders-list__note-container">
        Note: This screen only shows orders made online.
      </div>
    </>
  );

OrdersTable.propTypes = {
  history: shape({ push: func }).isRequired,
  orders: arrayOf(
    shape({
      id: string,
    })
  ),
  filteredOrders: arrayOf(
    shape({
      id: string,
    })
  ),
};

OrdersTable.defaultProps = {
  orders: [],
  filteredOrders: [],
};

export { OrdersTable };

export default withLocationCode(OrdersTable);
