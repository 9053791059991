// dependencies
import { withStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './styles';

const FormCardToggleView = (props) => {
  const { title, link, linkDescription, classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <Link className={classes.link} to={link}>
        {linkDescription}
      </Link>
    </div>
  );
};

FormCardToggleView.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkDescription: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(FormCardToggleView);
