import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Badge, useTheme } from '@material-ui/core';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

export const CartHeaderIcon = ({ quantity }) => {
  const { palette } = useTheme();

  return (
    <IconButton
      data-test="cart-header-icon"
      aria-label={`${quantity} item in cart`}
    >
      <Badge badgeContent={quantity} color="error" data-test="badge-icon-badge">
        <ShoppingCart style={{ color: palette.text.primary }} />
      </Badge>
    </IconButton>
  );
};

CartHeaderIcon.propTypes = {
  quantity: PropTypes.number,
};

CartHeaderIcon.defaultProps = {
  quantity: 0,
};

export default CartHeaderIcon;
