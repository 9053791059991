export default {
  messageTextArea: {
    borderColor: '#ccc',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '2px',
    maxWidth: '682px',
    minHeight: '135px',
    paddingLeft: '12px',
    paddingTop: '9px',
    resize: 'none',
  },
  redBorder: {
    borderColor: '#D24242',
    maxWidth: '682px',
  },
  feedbackInput: {
    paddingLeft: '12px',
    maxWidth: '682px',
  },
  feedbackTextField: {
    maxWidth: '682px',
  },

  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  messageLabel: {
    fontWeight: 600,
    fontSize: '14px',
  },
  supportContainer: {
    margin: '0',
  },
  contactSubtitle: {
    fontSize: '16px',
    margin: '20px 0 5px 0',
  },
  contactBackLink: {
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
  },
};
