import {
  GTM_ACCOUNT_OPEN_ITEMS_FILTER_CHANGE,
  GTM_ACCOUNT_OPEN_ITEMS_INTERACTION,
  GTM_ACCOUNT_OVERVIEW_FILTER_CHANGE,
  GTM_ACCOUNT_OVERVIEW_INTERACTION,
  GTM_ACCOUNT_SELECTION,
  GTM_ADD_TO_CART,
  GTM_CLICK_FEATURED_PARTNER_APPLE_STORE_XARVIO,
  GTM_CLICK_FEATURED_PARTNER_GOOGLE_STORE_XARVIO,
  GTM_CLICK_FEATURED_PARTNER_LEARN_MORE,
  GTM_CLICK_NUTRIEN_LOGO,
  GTM_CLICK_PLP_ECOMM_PROMO,
  GTM_CROSS_SELLS,
  GTM_BRAND_LADDER,
  GTM_EC_PRICINGPOPOVER,
  GTM_FMS_BUTTON_SEEN,
  GTM_GFA_APP_LOAD,
  GTM_GFA_EVENT,
  GTM_GFA_SHARE,
  GTM_GFA_SCREEN_VIEW,
  GTM_INVOICED_PRODUCTS_SEARCH_FOCUS,
  GTM_INVOICED_PRODUCTS_SEARCH_UPDATE,
  GTM_INVOICES_DOWNLOAD,
  GTM_INVOICES_FOCUS_FILTER,
  GTM_INVOICES_VIEW_DETAILS,
  GTM_INVOICES_VIEW_INVOICE,
  GTM_MAKE_INVOICE_PAYMENT,
  GTM_ONLINE_ORDERS_INTERACTION,
  GTM_REMOVE_FROM_CART,
  GTM_REORDER_TOP_PRODUCT,
  GTM_SHOP_CONTRACTED_SKU,
  GTM_UPDATING_CART,
  GTM_VIEW_CONTRACT_DETAILS,
  GTM_VIEW_PRODUCT_LABEL,
  GTM_VIEW_PAST_TOP_PRODUCT_PURCHASES,
  GTM_VIEW_PDP,
  GTM_VIEW_TOP_PRODUCT_SDS,
  CXH_COMPLIANCE_ACCEPT,
  CXH_COMPLIANCE_CANCEL,
  CXH_COMPLIANCE_OPTOUT,
  CXH_COMPLIANCE_VIEWTERMSOFSERVICE,
  CXH_PRODUCTCATALOG_FILTER,
  CXH_PRODUCTCATALOG_LOAD,
  CXH_PRODUCTCATALOG_SORT,
  CXH_PRODUCTCATALOG_CLEAR_FILTERS,
  CXH_ONLINECREDIT_APPLICATION,
  CXH_REDIRECT_NEW_WEB,
  CXH_NUTRIEN_HUB_MOBILE_APP,
  CXH_NUTRIEN_HUB_GOOGLE_APP,
  CXH_NUTRIEN_HUB_APPLE_APP,
} from './gtmConstants';

export const gtmStartOnlineCreditApplication = (selectedAccount) => ({
  type: CXH_ONLINECREDIT_APPLICATION,
  payload: { selectedAccount },
});

export const gtmViewContractDetails = () => ({
  type: GTM_VIEW_CONTRACT_DETAILS,
});

export const gtmShopContractedSku = (origin) => ({
  type: GTM_SHOP_CONTRACTED_SKU,
  payload: { origin },
});

export const gtmEcPricingPopover = (origin) => ({
  type: GTM_EC_PRICINGPOPOVER,
  payload: { origin },
});

export const gtmAccountSelection = (origin) => ({
  type: GTM_ACCOUNT_SELECTION,
  payload: { origin },
});

export const gtmFmsButtonSeen = () => ({ type: GTM_FMS_BUTTON_SEEN });

export const gtmClickNutrienLogo = () => ({
  type: GTM_CLICK_NUTRIEN_LOGO,
});

export const gtmClickPLPEcommPromo = () => ({
  type: GTM_CLICK_PLP_ECOMM_PROMO,
});

export const gtmClickAppleXarvioApp = () => ({
  type: GTM_CLICK_FEATURED_PARTNER_APPLE_STORE_XARVIO,
});

export const gtmClickGoogleXarvioApp = () => ({
  type: GTM_CLICK_FEATURED_PARTNER_GOOGLE_STORE_XARVIO,
});

export const gtmClickFeaturedPartnerLearnMore = () => ({
  type: GTM_CLICK_FEATURED_PARTNER_LEARN_MORE,
});

export const gtmLogRemoveFromCart = ({ cartItem }) => ({
  type: GTM_REMOVE_FROM_CART,
  payload: { cartItem },
});

export const gtmAddToCart = (cartItem, origin) => ({
  type: GTM_ADD_TO_CART,
  payload: {
    cartItem,
    origin,
  },
});

export const gtmUpdatingCart = (newItemData, itemPreUpdate, options) => ({
  type: GTM_UPDATING_CART,
  payload: { newItemData, itemPreUpdate, options },
});

export const gtmViewPDP = (product, variantWithPrices) => ({
  type: GTM_VIEW_PDP,
  payload: { product, variantWithPrices },
});

export const gtmToggleInvoiceFilter = (type, value) => ({
  type: GTM_INVOICES_FOCUS_FILTER,
  payload: {
    type,
    value,
  },
});

export const gtmInvoicesDownload = (label, fileType) => ({
  type: GTM_INVOICES_DOWNLOAD,
  payload: {
    label,
    fileType,
  },
});

export const gtmMakeInvoicePayment = (action, label, value) => ({
  type: GTM_MAKE_INVOICE_PAYMENT,
  payload: {
    action,
    label,
    value,
  },
});

export const gtmViewInvoicedProductsDetailsModal = () => ({
  type: GTM_INVOICES_VIEW_DETAILS,
});

export const gtmViewInvoice = (invoiceId) => ({
  type: GTM_INVOICES_VIEW_INVOICE,
  payload: { invoiceId },
});

export const gtmAccountOverviewInteraction = (action, label) => ({
  type: GTM_ACCOUNT_OVERVIEW_INTERACTION,
  payload: {
    action,
    label,
  },
});

export const gtmAccountOverviewFilterChange = (filterName, value) => ({
  type: GTM_ACCOUNT_OVERVIEW_FILTER_CHANGE,
  payload: {
    filterName,
    value,
  },
});

export const gtmAccountOpenItemsInteraction = (action, label) => ({
  type: GTM_ACCOUNT_OPEN_ITEMS_INTERACTION,
  payload: {
    action,
    label,
  },
});

export const gtmAccountOpenItemsFilterChange = (filterName, value) => ({
  type: GTM_ACCOUNT_OPEN_ITEMS_FILTER_CHANGE,
  payload: {
    filterName,
    value,
  },
});

export const gtmUpdateInvoicedProductsSearch = (query) => ({
  type: GTM_INVOICED_PRODUCTS_SEARCH_UPDATE,
  payload: { query },
});

export const gtmFocusInvoicedProductsSearch = (focused) => ({
  type: GTM_INVOICED_PRODUCTS_SEARCH_FOCUS,
  payload: { focused },
});

export const gtmReorderTopProduct = () => ({
  type: GTM_REORDER_TOP_PRODUCT,
});

export const gtmViewPastTopProductPurchases = () => ({
  type: GTM_VIEW_PAST_TOP_PRODUCT_PURCHASES,
});

export const gtmViewTopProductSDS = () => ({
  type: GTM_VIEW_TOP_PRODUCT_SDS,
});

export const gtmOnlineOrdersClick = (label) => ({
  type: GTM_ONLINE_ORDERS_INTERACTION,
  payload: {
    action: 'Click',
    label,
  },
});

export const gfaLoad = () => ({
  type: GTM_GFA_APP_LOAD,
});

export const gfaScreenView = (screen) => ({
  type: GTM_GFA_SCREEN_VIEW,
  payload: {
    screen,
  },
});

export const gfaEvent = (data) => ({
  type: GTM_GFA_EVENT,
  payload: data,
});

export const gfaShare = (data) => ({
  type: GTM_GFA_SHARE,
  payload: data,
});

export const gtmClickBrandLadder = () => ({
  type: GTM_BRAND_LADDER,
});
export const gtmClickCrossSells = () => ({
  type: GTM_CROSS_SELLS,
});

export const gtmClickProduct = (value) => (dispatch) => {
  if (value === ' brandLadder') dispatch(gtmClickBrandLadder());
  if (value === 'crossSells') dispatch(gtmClickCrossSells());
};

export const gtmLoadMoreProductsClick = (value) => ({
  type: CXH_PRODUCTCATALOG_LOAD,
  payload: {
    value,
  },
});

export const gtmProductListSort = (label, value) => {
  const productSortOptions = [
    'Product name, A-Z',
    'Product name, Z-A',
    'Popularity, most to least',
    'Popularity, least to most',
  ];

  if (productSortOptions.includes(label))
    return {
      type: CXH_PRODUCTCATALOG_SORT,
      payload: {
        label,
        value,
      },
    };
  return { type: null };
};

export const gtmFilterProducts = (modification, label) => ({
  type: CXH_PRODUCTCATALOG_FILTER,
  payload: {
    modification,
    label,
  },
});

export const gtmProductListClearFilters = (value) => ({
  type: CXH_PRODUCTCATALOG_CLEAR_FILTERS,
  payload: {
    value,
  },
});

export const gtmViewTermsOfService = () => ({
  type: CXH_COMPLIANCE_VIEWTERMSOFSERVICE,
});

export const gtmAcceptTermsOfService = () => ({
  type: CXH_COMPLIANCE_ACCEPT,
});

export const gtmCancelTermsOfService = () => ({
  type: CXH_COMPLIANCE_CANCEL,
});

export const gtmDoNotSellInfo = () => ({
  type: CXH_COMPLIANCE_OPTOUT,
});

export const gtmViewLabel = (productData) => ({
  type: GTM_VIEW_PRODUCT_LABEL,
  payload: productData,
});

export const gtmRedirectToNewWeb = () => ({
  type: CXH_REDIRECT_NEW_WEB,
});

export const gtmNutrienHubDialog = () => ({
  type: CXH_NUTRIEN_HUB_MOBILE_APP,
});

export const gtmNutrienHubAndroidApp = () => ({
  type: CXH_NUTRIEN_HUB_GOOGLE_APP,
});

export const gtmNutrienHubAppleApp = () => ({
  type: CXH_NUTRIEN_HUB_APPLE_APP,
});
