import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@nutrien/uet-react/styles';
import Warning from '@material-ui/icons/Warning';

// Dependent Components
import PageNotification from 'shared/ui/NotificationHelper/PageNotification';

// Constants
import { NOTIFICATION_TYPES } from 'shared/ui/NotificationHelper';
import { PAYMENT_ERRORS } from '../../../../../Redux/actions/ErrorActions';
import { tokenAlreadySavedText } from '../../../MakeAPaymentConstants';

// Component specific styles
import styles from './styles';

const PaymentMethodErrorMessage = (props) => {
  const { error, classes, openDialog } = props;

  switch (error.statusCode) {
    case PAYMENT_ERRORS.TOKEN_ALREADY_SAVED.statusCode: {
      return (
        <PageNotification
          type={NOTIFICATION_TYPES.DANGER}
          message={tokenAlreadySavedText}
          mobileMessage={tokenAlreadySavedText}
          className="payment-error-notification"
        />
      );
    }
    case PAYMENT_ERRORS.TOKEN_LIMIT_REACHED.statusCode: {
      return (
        <div
          className={classes.tokenLimitMessage}
          data-test="saved-payment-error-account-limit"
        >
          <Warning className={classes.warningIcon} />
          <div>
            You have reached the limit of 15 stored accounts. You must delete
            one of the{' '}
            <button className={classes.modalLink} onClick={openDialog}>
              stored bank accounts
            </button>{' '}
            on file to proceed with your payment.
          </div>
        </div>
      );
    }
    default:
      return null;
  }
};

PaymentMethodErrorMessage.propTypes = {
  error: PropTypes.shape({
    statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  classes: PropTypes.shape({
    modalLink: PropTypes.string,
    tokenLimitMessage: PropTypes.string,
    warningIcon: PropTypes.string,
  }).isRequired,
  openDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(PaymentMethodErrorMessage);
