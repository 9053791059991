import PropTypes from 'prop-types';

export const productHistoryObject = PropTypes.shape({
  cost: PropTypes.number,
  description: PropTypes.string,
  latestInvoiceDate: PropTypes.string,
  productNumber: PropTypes.number,
  extended: PropTypes.bool,
  quantity: PropTypes.number,
  sdsAndLabels: PropTypes.string,
  shelf: PropTypes.string,
  uom: PropTypes.string,
  isChecked: PropTypes.bool,
});

export const ProductHistoryObjectArrayType = PropTypes.arrayOf(
  productHistoryObject
);

export const ProductHistoryPropType = PropTypes.shape({
  error: PropTypes.string,
  status: PropTypes.string,
  list: ProductHistoryObjectArrayType,
});
