import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    '@media only screen and (max-width: 900px)': {
      margin: '24px 0 0 0',
    },
  },
  tabs: {
    indicatorColor: 'primary',
  },
  tab: {
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  hr: {
    marginTop: 0,
  },
  backToOverviewIcon: {
    transform: 'rotate(90deg)',
  },
  backToOverview: {
    alignItems: 'center',
    color: '#4c9e00',
    display: 'inline-flex',
    fontSize: '12px',
    fontWeight: '400',

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
