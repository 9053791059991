import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BaseHeader from '../BaseHeader';

const CartHeader = ({ isMirroring, location }) => (
  <BaseHeader
    location={location}
    isMirroring={isMirroring}
    displayForAllScreenSizes
  >
    <span data-test="product-request-title" className="checkout">
      Order Request
    </span>
    {location.pathname === '/cart/review' ? (
      <div className="header-content__cart--review" />
    ) : (
      <Link
        data-test="edit-cart-button"
        className="signout"
        to="/cart"
        role="button"
        tabIndex="0"
      >
        Edit cart
      </Link>
    )}
  </BaseHeader>
);

CartHeader.propTypes = {
  isMirroring: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

CartHeader.defaultProps = {
  isMirroring: false,
};

export default CartHeader;
