import React from 'react';
import {
  SupportNumber,
  SupportEmail,
  SendFeedback,
} from 'shared/ui/SupportInfo';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import oldContactStyles from './OldContactStyles';

const OldContact = (props) => {
  const { classes } = props;

  return (
    <div>
      <div className={props.classes.container}>
        <h4>Get Support</h4>
        <p className={classes.p}>
          Helpful experts at Nutrien Ag Solutions are available to answer your
          questions.
        </p>

        <SupportNumber />
        <SupportEmail />

        <br />

        <SendFeedback />
      </div>
    </div>
  );
};

OldContact.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(oldContactStyles)(OldContact);
