import { getUserType } from 'shared/utils/tokenUtils';
import get from 'lodash/get';

export const sendLoadUserEvent = async (userData) => {
  const loadApplicationTypeForWebUsers = !window.ReactNativeWebView
    ? { application: { type: 'web' } }
    : {};

  const eventData = {
    event: 'DATA_LoadUser',
    ...loadApplicationTypeForWebUsers,
    user: {
      userId: get(userData, 'id'),
      organizationId: get(userData, 'userDetails.associatedOrgs[0].id'),
      defaultBranchId: get(userData, 'userDetails.defaultBranchId'),
    },
  };

  const userType = await getUserType();
  if (userType) {
    // Do not overwrite any existing userType value in the dataLayer
    // if the user type cannot be determined.
    eventData.user.userType = userType;
  }

  return eventData;
};

export default sendLoadUserEvent;
