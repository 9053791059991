import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  noResultsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  noResultsOuterWrapper: {
    width: '100%',
  },
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  viewAllOrdersButton: {
    margin: '20px 0px !important',
    minHeight: '40px',
    width: '186px',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
