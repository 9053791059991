import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import {
  selectUserTermsAccepted,
  selectHasLinkedAccount,
  selectShowLinkAccountModal,
} from 'selectors/userSelectors';
import { useAuth } from '@nutrien/fe-domain-utils';
import useDismissLinkAccountPrompt from 'hooks/useDismissLinkAccountPrompt';
import styles from '../styles';
import LinkAccountComponent from '../LinkAccountComponent';

const LinkAccountPrompt = ({ classes, onClose }) => {
  const auth = useAuth();
  const userTermsAccepted = useSelector(selectUserTermsAccepted());
  const hasLinkedAccount = useSelector(selectHasLinkedAccount());
  /* eslint-disable-next-line camelcase */
  const emailVerified = !!auth.user?.email_verified;
  const showLinkAccountModal = useSelector(selectShowLinkAccountModal());
  const displayable = () =>
    emailVerified &&
    userTermsAccepted &&
    !hasLinkedAccount &&
    showLinkAccountModal;

  const { dismiss } = useDismissLinkAccountPrompt();
  const [isOpen, setIsOpen] = useState(true);
  const closeModal = (e) => {
    setIsOpen(false);
    const fireOnClose = () => {
      if (typeof onClose === 'function') onClose();
    };
    const isDismiss = e?.target?.innerText;
    if (isDismiss) {
      dismiss();
    } else {
      fireOnClose();
    }
  };
  return displayable() && isOpen ? (
    <div className={classes.linkAccountContainer}>
      <LinkAccountComponent
        modal
        isOpen={isOpen}
        closeModal={closeModal}
        accountLinked={() => {}}
      />
    </div>
  ) : null;
};

LinkAccountPrompt.defaultProps = {
  classes: {},
  onClose: null,
};

LinkAccountPrompt.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  onClose: PropTypes.func,
};

export default withStyles(styles)(LinkAccountPrompt);
