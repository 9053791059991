import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  stripSymbolsFromNumber,
  formatPhoneNumber,
  validatePhoneNumber,
} from 'utils/phoneUtils';
import {
  notificationPreferences as notificationPreferencesPropType,
  notificationsPreferencesStatus as notificationsPreferencesStatusPropType,
} from '../notificationsPreferencesPropTypes';
import {
  getUserPreferredPhoneNumber,
  getUserPreferences,
  getNotificationPreferencesStatus,
} from '../selectors';
import { updateNotificationPreferences } from '../actions';
import PhoneModal from './PhoneModal';

export class PhoneModalContainer extends Component {
  state = {
    phoneNumber: '',
    inputValueError: null,
  };

  handleChange = (event) => {
    const { value } = event.target;
    const formattedValue = formatPhoneNumber(stripSymbolsFromNumber(value));
    const validityObj = validatePhoneNumber(formattedValue);
    this.setState({
      inputValueError: validityObj.isValid ? null : validityObj.error,
      phoneNumber: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { phoneNumber } = this.state;
    const {
      onSubmit,
      onSubmitError,
      onSubmitSuccess,
      updateNotificationPreferences, // eslint-disable-line no-shadow
      userPreferences,
    } = this.props;
    const preferredNumber = stripSymbolsFromNumber(phoneNumber);

    onSubmit(preferredNumber);
    updateNotificationPreferences(userPreferences, {}, {}, preferredNumber)
      .then((res) => {
        onSubmitSuccess(res);
      })
      .catch((err) => {
        onSubmitError(err);
      });
  };

  closeModal = () => {
    this.setState({
      inputValueError: null,
      phoneNumber: this.props.preferredPhoneNumber,
    });
    this.props.toggleModal(false);
  };

  render() {
    const {
      preferredPhoneNumber,
      isOpen,
      notificationPreferencesStatus,
    } = this.props;
    const props = {
      closeModal: this.closeModal,
      onSubmit: this.handleSubmit,
      onChange: this.handleChange,
      isInAddMode: !preferredPhoneNumber,
      error: this.state.inputValueError,
      phoneNumber: this.state.phoneNumber,
      currentPreferredPhoneNumber: preferredPhoneNumber,
      isSubmitting: notificationPreferencesStatus.isUpdating,
      onSubmitError: notificationPreferencesStatus.updateError,
      isOpen,
    };

    return <PhoneModal {...props} />;
  }
}

PhoneModalContainer.propTypes = {
  preferredPhoneNumber: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitError: PropTypes.func,
  updateNotificationPreferences: PropTypes.func.isRequired,
  userPreferences: notificationPreferencesPropType.isRequired,
  notificationPreferencesStatus:
    notificationsPreferencesStatusPropType.isRequired,
};

PhoneModalContainer.defaultProps = {
  preferredPhoneNumber: null,
  isOpen: false,
  toggleModal: () => {},
  onSubmit: () => {},
  onSubmitSuccess: () => {},
  onSubmitError: () => {},
};

export const mapStateToProps = (state) => ({
  preferredPhoneNumber: getUserPreferredPhoneNumber(state),
  userPreferences: getUserPreferences(state),
  notificationPreferencesStatus: getNotificationPreferencesStatus(state),
});

export const mapDispatchToProps = {
  updateNotificationPreferences,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneModalContainer);
