import React from 'react';
import { bool } from 'prop-types';
import { Icon } from 'nutrien-common-components-react/dist/mdc';

import './RestrictedWarning.scss';

const RestrictedWarning = ({ isRestricted }) =>
  isRestricted ? (
    <div className="restricted-warning" data-test="restricted-warning">
      <Icon
        icon="Warning"
        type="warning"
        className="restricted-warning__icon"
      />
      <span data-test="restricted-warning-text">
        Purchasing this product may require a license.
      </span>
    </div>
  ) : null;

RestrictedWarning.propTypes = {
  isRestricted: bool,
};

RestrictedWarning.defaultProps = {
  isRestricted: false,
};

export default RestrictedWarning;
