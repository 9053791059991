import { setLocalAccessToken } from '@nutrien/fe-domain-utils';
import storage from 'shared/lib/storage';
// a wrapper around local storage that stringifies the given value,
// then saves it to localStorage
const setItem = (key, value) => {
  storage.setItem(key, JSON.stringify(value));
};

const setToken = (token) => {
  if (token) {
    if (token.authorizationToken) {
      setLocalAccessToken(token.authorizationToken);
    }
    if (token.refreshToken) {
      setItem('refresh-token', token.refreshToken);
    }
    if (token.expiration) {
      setItem('token-expiration', token.expiration);
    }
    if (token.isEmployee) {
      setItem('is-employee', token.isEmployee);
    }
    if (token.fsrAuthToken) {
      setItem('fsr-token', token.fsrAuthToken);
    }
    if (token.mirroredUser) {
      setItem('mirrored-user', token.mirroredUser);
    }
    return true;
  }
  return false;
};

export default setToken;
