import { push } from 'connected-react-router';
import {
  setAccountActivity,
  setAccountTab,
  setPrepayDetails,
  setPrepayHistory,
} from 'actions';
import { setGloballySelectedAccount } from 'actions/accountSelectorActions';
import {
  getAccountActivity,
  getAccountById,
  getPrepayDetails,
  getPrepayHistory,
} from 'services/httpServices/accountService';
import { accountStatuses } from './constants';
import { handleAccountUpdate } from '../Invoices/actions';

const isDifferentAccount = (currentlyLoadedInvoicesAccountId, accountId) =>
  currentlyLoadedInvoicesAccountId !== accountId;

/**
 * Updates invoices and the current tab name on the store
 * @param {string} tab Name of the tab in the Account overview page
 * @param {string} accountId the account id
 */
export const updateAccountTab = (tab, accountId) => (dispatch, getState) => {
  dispatch(setAccountTab({ tab }));

  const currentlyLoadedInvoicesAccountId = getState().invoices.accountId;

  if (isDifferentAccount(currentlyLoadedInvoicesAccountId, accountId)) {
    dispatch(handleAccountUpdate(accountId, tab === 'invoices'));
  }
};

export const changeSelectedAccount = ({ account, accountTab }) => (
  dispatch
) => {
  dispatch(handleAccountUpdate(account.id, accountTab === 'invoices'));
  dispatch(setGloballySelectedAccount(account));
  dispatch(push(`/accounts/${account.id}/${accountTab}`));
};

export const getAccountDetails = (id) => (dispatch) => {
  getAccountById(id).then((response) => {
    dispatch(setGloballySelectedAccount(response));
  });

  /* Set account activity to LOADING before fetching list */
  dispatch(
    setAccountActivity({
      status: 'LOADING',
      list: [],
    })
  );

  return getAccountActivity(id)
    .then((response) => {
      dispatch(
        setAccountActivity({
          status: accountStatuses.OK,
          list: response.accountActivityItems,
        })
      );
    })
    .catch(() => {
      dispatch(setAccountActivity({ status: accountStatuses.ERR, list: [] }));
    });
};

export const getPrepayInfo = (id) => (dispatch) => {
  dispatch(setPrepayDetails({ status: accountStatuses.LOADING }));
  return getPrepayDetails(id)
    .then((response) => {
      dispatch(
        setPrepayDetails({ status: accountStatuses.OK, data: response })
      );
    })
    .catch(() => {
      dispatch(setPrepayDetails({ status: accountStatuses.ERR }));
    });
};

export const getPrepayHistoryInfo = (id, starDate, endDate) => (dispatch) => {
  dispatch(setPrepayHistory({ status: accountStatuses.LOADING }));
  return getPrepayHistory(id, starDate, endDate)
    .then((response) => {
      if (response.length < 1) {
        dispatch(setPrepayHistory({ status: accountStatuses.OK, data: [] }));
      } else {
        dispatch(
          setPrepayHistory({ status: accountStatuses.OK, data: response })
        );
      }
    })
    .catch(() => {
      dispatch(setPrepayHistory({ status: accountStatuses.ERR }));
    });
};
