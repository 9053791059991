export const countOrderStatuses = (orders, status) =>
  orders.filter(
    (order) =>
      order.attributes.orderStatus.toLowerCase() === status.toLowerCase()
  ).length;

export const filterOrders = (orders, orderStatusFilter) =>
  orders.filter(
    (order) =>
      orderStatusFilter === 'All' ||
      order.attributes.orderStatus === orderStatusFilter
  );
