export default {
  allocatedText: {
    fontSize: '14px',
    display: 'inline-block',
  },
  amountColumnTitle: {
    textAlign: 'right',
  },
  asOfDate: {
    fontSize: '14px',
    display: 'inline-block',
    marginRight: '10px',
  },
  contactText: {
    color: '#464646',
    fontSize: '14px',
  },
  zeroStateNotification: {
    width: '100%',
    fontSize: '14px',
  },
  currentBalanceContainer: {
    width: '375px',
    height: '115px',
    backgroundColor: '#4c9e00',
    border: '1px solid #438b00',
    borderRadius: '4px',
    margin: '0 0 32px 25px',
    paddingLeft: '14px',
    color: 'white',
  },
  currentBalanceFooter: { display: 'flex', justifyContent: 'space-between' },
  currentBalanceTitle: {
    fontSize: '16px',
    marginTop: '11px',
  },
  currentBalanceValue: {
    fontSize: '32px',
  },
  currentBalanceCenterRow: {
    display: 'flex',
    alignItems: 'center',
  },
  prePayButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    paddingRight: '10px',
  },
  descriptionColumn: {
    minWidth: '200px',
    width: '99%',
    paddingLeft: '25px',
  },
  descriptionText: {
    minWidth: '200px',
    fontSize: '14px',
    paddingLeft: '25px',
  },
  descriptionTextNoPadding: {
    minWidth: '200px',
    fontSize: '14px',
  },
  endText: {
    fontSize: '14px',
    color: '#464646',
  },
  evenNumberRow: {
    backgroundColor: '#F7F7F7',
  },
  invisible: {
    display: 'none !important',
  },
  prePayDisabledMoreInfoIcon: {
    marginLeft: '.5em',
    height: '1em',
  },
  prepayPendingContainer: {
    display: 'flex',
    border: '1px solid #f1c02a',
    borderRadius: '4px',
    padding: '17px',
    marginBottom: '40px',
    backgroundColor: 'rgba(248, 222, 79, 0.1)',
    alignItems: 'center',
    margin: '0 25px',
  },
  prepayPendingText: {
    marginLeft: '17px',
  },
  prePayTitle: {
    margin: '0 0 24px 25px',
    fontSize: '24px',
    fontWeight: 'normal',
    display: 'inline-block',
  },
  rowItem: {
    color: '#464646',
    borderBottom: '1px solid #cccccc',
  },
  rowText: {
    fontSize: '14px',
    textAlign: 'right',
  },
  rowTextLeftAligned: {
    fontSize: '14px',
    textAlign: 'left',
    paddingLeft: '20px',
  },
  rowTextLast: {
    fontSize: '14px',
    textAlign: 'right',
    paddingRight: '22px',
  },
  historyRowTextLeftAligned: {
    fontSize: '14px',
    textAlign: 'left',
    paddingLeft: '20px',
  },
  historyRowTextLast: {
    fontSize: '14px',
    textAlign: 'right',
    paddingRight: '22px',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '0 0 20px 0',
  },
  tableFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '40px',
  },
  yearSelector: {
    borderRadius: '2px',
    display: 'inline-block',
    float: 'right',
  },
  yearSelectorDropdown: {
    marginRight: '21px',
    width: '133px',
    border: '1px solid #cccccc',
    fontSize: '16px',
  },
  yearSelectorTitle: {
    marginRight: '5px',
    display: 'inline',
    fontWeight: '600',
    fontSize: '14px',
    color: '#222222',
  },
  rowMobileHeaderText: {
    display: 'none',
  },
  displayNone: {
    display: 'none',
  },
  '@global': {
    th: {
      borderBottom: '1px solid #cccccc',
      paddingBottom: '15px',
      fontWeight: '600',
      lineHeight: '1.14',
    },
    td: {
      height: '48px',
    },
  },
  '@media(max-width: 850px)': {
    contactText: {
      textAlign: 'center',
    },
    descriptionColumn: {
      maxWidth: '300px',
    },
    descriptionTextNoPadding: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
      padding: '0px',
    },
    tableContainer: {
      overflowX: 'auto',
    },
  },
  '@media(max-width: 608px)': {
    yearSelector: {
      display: 'block',
      float: 'none',
      position: 'relative',
      bottom: '17px',
      left: '17px',
    },
    yearSelectorDropdown: {
      width: '90%',
      margin: '5px 0 0 8px',
    },
    yearSelectorTitle: {
      display: 'block',
    },
    descriptionTextNoPadding: {
      maxWidth: '525px',
    },
    mobileHidden: {
      display: 'none',
    },
    rowItem: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 'auto',
      borderBottom: '1px solid #cccccc',
      padding: '0px 12px',
      justifyContent: 'space-between',
    },
    rowMobileHeaderText: {
      display: 'block',
      fontWeight: '600',
    },
    rowText: {
      flex: '1 0 33%',
      textAlign: 'left',
      padding: '0px 10px 10px 10px',
    },
    rowTextLeftAligned: {
      flex: '1 0 100%',
      textAlign: 'left',
      padding: '0px 10px 10px 10px',
    },
    rowTextLast: {
      flex: '1 0 33%',
      textAlign: 'left',
      padding: '0px 10px 10px 10px',
    },
    historyRowTextLeftAligned: {
      flex: '1 0 50%',
      textAlign: 'left',
      padding: '0px 10px 10px 10px',
    },
    historyRowTextLast: {
      flex: '1 0 50%',
      textAlign: 'left',
      padding: '0px 10px 10px 10px',
    },
    mobileDisplayBlock: {
      display: 'block',
    },
  },
  '@media(max-width: 520px)': {
    scrollIndicator: {
      display: 'block',
    },
    descriptionTextNoPadding: {
      maxWidth: '350px',
    },
  },
  '@media(max-width: 430px)': {
    currentBalanceContainer: {
      width: 'calc(100% - 50px)',
      height: 'auto',
      paddingBottom: '10px',
    },
    prePayButtonContainer: {
      justifyContent: 'flex-start',
    },
    currentBalanceCenterRow: {
      flexWrap: 'wrap',
    },
    descriptionTextNoPadding: {
      maxWidth: '300px',
    },
  },
  '@media(max-width: 400px)': {
    rowText: {
      flex: '1 0 100%',
    },
    rowTextLast: {
      flex: '1 0 100%',
    },
    descriptionTextNoPadding: {
      maxWidth: '280px',
    },
  },
  '@media(max-width: 350px)': {
    descriptionTextNoPadding: {
      maxWidth: '240px',
    },
    historyRowTextLast: {
      flex: '1 0 100%',
    },
  },
};
