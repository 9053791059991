import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialButtonYellow from 'shared/ui/MaterialButtonYellow';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core';
import Notification from '@nutrien/uet-react/Notification';
import { currency } from 'shared/utils/numberFormatters';
import Tooltip from '@nutrien/uet-react/Tooltip';
import HelpIcon from 'assets/help.svg';
import { availableYears } from '../actions';
import styles from './styles';
import DisabledForEmployees from '../../../shared/utils/DisabledForEmployees/DisabledForEmployees';
import { PAYMENT_FLOW_TYPES } from '../../Payments/PaymentConstants';

const PrePay = (props) => {
  const detailsHeaders = ['Description', 'Allocated', 'Invoiced', 'Available'];
  const historyHeaders = ['Type', 'Amount'];
  const yearToDate = new Date().getFullYear() - 1;
  const [prePayBalance, setPrePayBalance] = useState(null);
  const [allocatedSum, setAllocatedSum] = useState(null);
  const [selectedYear, setSelectedYear] = useState(yearToDate);
  const { canadaPrepay } = useFlags();
  const {
    prepayDetails,
    accountId,
    getPrepayInfo,
    getPrepayHistory,
    prepayHistory,
    prepayHistoryStatus,
    prepayStatus,
    prepayTransaction,
    clearPrepay,
    clearPrepayTransaction,
    push,
    setPaymentFlowType,
    classes,
    flags: { prepayAddFunds },
  } = props;
  const startDate = `${selectedYear}-01-01`;
  const endDate = `${parseInt(selectedYear, 10) + 1}-01-01`;
  // the below code can be removed once universalSelector flag is turned on in prod
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const isCanada = selectedAccount.locationCode === 'CAN';

  const isInvalidAccount =
    selectedAccount.invalidCanadianAccount ||
    selectedAccount.invalidInternationalAccount;

  const setPrepaySums = (newPrepayDetails) => {
    if (newPrepayDetails) {
      const newPrePayBalance = newPrepayDetails
        .map((item) => item.available)
        .reduce((total, value) => total + value, 0);
      const newAllocatedSum = newPrepayDetails
        .map((item) => item.allocated)
        .reduce((total, value) => total + value, 0);
      setPrePayBalance(newPrePayBalance);
      setAllocatedSum(newAllocatedSum);
    }
  };

  const addFundsShouldBeDisabled =
    !prepayAddFunds ||
    selectedAccount.calculatedCustomerType === 'Fuel' ||
    isInvalidAccount;

  // TODO: remove usaSapAccount restriction once pre-pay is enabled for USA SAP accounts
  const addFundsShouldBeHidden =
    (isCanada && !canadaPrepay) || selectedAccount.usaSapAccount;

  const handleYearChange = (event) => {
    const { value } = event.target;
    const newStartDate = `${value}-01-01`;
    const newEndDate = `${parseInt(value, 10) + 1}-01-01`;
    getPrepayHistory(accountId, newStartDate, newEndDate);
    setSelectedYear(value);
  };

  const navigateToAddPrepay = () => {
    setPaymentFlowType(PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT);
    push({
      pathname: `/accounts/${accountId}/add-funds-to-prepay`,
      state: {
        accountId,
      },
    });
  };

  const renderPrePayDetails = () =>
    prepayDetails.map((item, index) => (
      <tr
        className={`${classes.rowItem} ${
          index % 2 ? classes.evenNumberRow : ''
        } prePayDetailsRow`}
        data-test="pre-pay-details-row"
        key={item.allocationID}
      >
        <td className={classes.rowTextLeftAligned}>
          <div className={classes.rowMobileHeaderText}>{detailsHeaders[0]}</div>
          <div
            className={classes.descriptionTextNoPadding}
            data-test="pre-pay-details-row-description"
          >
            {item.description}
          </div>
        </td>
        <td className={classes.rowText}>
          <div className={classes.rowMobileHeaderText}>{detailsHeaders[1]}</div>
          <div data-test="pre-pay-details-row-allocated">
            {currency(item.allocated)}
          </div>
        </td>
        <td className={classes.rowText}>
          <div className={classes.rowMobileHeaderText}>{detailsHeaders[2]}</div>
          <div data-test="pre-pay-details-row-invoiced">
            {currency(item.invoiced)}
          </div>
        </td>
        <td className={classes.rowTextLast}>
          <div className={classes.rowMobileHeaderText}>{detailsHeaders[3]}</div>
          <div data-test="pre-pay-details-row-available">
            {currency(item.available)}
          </div>
        </td>
      </tr>
    ));

  const renderPrepayHistory = () =>
    prepayHistory.map((item, index) => (
      <tr
        className={`${classes.rowItem} ${
          index % 2 ? classes.evenNumberRow : ''
        }`}
        key={item.startDate + item.shelf}
      >
        <td className={classes.historyRowTextLeftAligned}>
          <div className={classes.rowMobileHeaderText}>{historyHeaders[0]}</div>
          <div data-test="pre-pay-history-row-shelf">{item.shelf}</div>
        </td>
        <td className={classes.historyRowTextLast}>
          <div className={classes.rowMobileHeaderText}>{historyHeaders[1]}</div>
          <div data-test="pre-pay-history-row-allocated-amount">
            {currency(item.allocatedAmount)}
          </div>
        </td>
      </tr>
    ));

  const renderDropdownValues = () =>
    availableYears().map((year) => (
      <option value={year} key={year} data-test="pre-pay-year-selector-item">
        {year}
      </option>
    ));

  useEffect(() => {
    setPrepaySums(prepayDetails);
    getPrepayInfo(accountId);

    getPrepayHistory(accountId, startDate, endDate);
  }, [accountId]);

  useEffect(() => {
    setPrepaySums(prepayDetails);
  }, [prepayDetails]);

  useEffect(() => {
    return () => {
      clearPrepay();
      clearPrepayTransaction();
    };
  }, []);

  if (prepayStatus === 'loading' || prepayHistoryStatus === 'loading') {
    return (
      <div className="account-statements--loading-wrapper">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {prepayTransaction && prepayTransaction.accountId === accountId ? (
        <div
          className={classes.prepayPendingContainer}
          data-test="pre-pay-pending-container"
        >
          <InfoIcon
            style={{ color: '#f1c02a', height: '24px', width: '27px' }}
            data-test="pre-pay-pending-info-icon"
          />
          <div data-test="prepay-message" className={classes.prepayPendingText}>
            {`${currency(
              prepayTransaction.amountEntered
            )} has been submitted to the Pre-Pay balance on ${
              prepayTransaction.date
            }.
            It may take a few days to process and for the Pre Pay balance to
            update.`}
          </div>
        </div>
      ) : null}
      <div className={`${classes.currentBalanceContainer} info-card-container`}>
        <div className={`${classes.currentBalanceTitle} info-card-title`}>
          <span>Current Pre-Pay Balance</span>
          {selectedAccount.usaSapAccount && (
            <Tooltip
              title="Pre-Pay is under construction, and the ability to add funds is currently disabled. If you have any questions, please contact your Crop Consultant."
              placement="top-start"
              enterTouchDelay={0}
              leaveTouchDelay={7000}
              data-test="pre-pay-hidden-tooltip"
            >
              <img
                src={HelpIcon}
                className={classes.prePayDisabledMoreInfoIcon}
                data-test="pre-pay-hidden-tooltip-icon"
                alt="click for more information"
              />
            </Tooltip>
          )}
        </div>

        <div className={classes.currentBalanceCenterRow}>
          <div className={`${classes.currentBalanceValue} info-card-balance`}>
            {currency(prePayBalance || 0)}
          </div>

          <div className={classes.prePayButtonContainer}>
            {addFundsShouldBeHidden ? null : (
              <DisabledForEmployees>
                <MaterialButtonYellow
                  className="make-payment-button"
                  style={{ margin: '12px 0' }}
                  onClick={navigateToAddPrepay}
                  disabled={addFundsShouldBeDisabled}
                >
                  Add Funds
                </MaterialButtonYellow>
              </DisabledForEmployees>
            )}
          </div>
        </div>

        <div className={classes.currentBalanceFooter}>
          <div className={`${classes.allocatedText} info-card-allocated`}>
            Allocated: {currency(allocatedSum || 0)}
          </div>
          <div className={`${classes.asOfDate} info-card-asOfDate`}>
            As of: {new Date().toLocaleDateString()}
          </div>
        </div>
      </div>
      <h2 className={classes.prePayTitle}>
        {`${moment().year()} Current Pre-Pay`}
      </h2>
      <table className={classes.tableContainer}>
        <thead className={classes.mobileHidden}>
          <tr>
            <th className={classes.rowTextLeftAligned}>{detailsHeaders[0]}</th>
            <th className={classes.rowText}>{detailsHeaders[1]}</th>
            <th className={classes.rowText}>{detailsHeaders[2]}</th>
            <th className={classes.rowTextLast}>{detailsHeaders[3]}</th>
          </tr>
        </thead>
        <tbody>{prepayDetails ? renderPrePayDetails() : null}</tbody>
      </table>
      <div className={classes.tableFooter}>
        {prepayDetails && prepayDetails.length > 0 ? (
          <div className={classes.tableFooter}>
            <div className={classes.endText}>End of Current Pre-Pay</div>
            <div className={classes.contactText}>
              Please contact your local branch if any of the pre-pay values are
              not accurate.
            </div>
          </div>
        ) : (
          <Notification
            variant="info"
            className={classes.zeroStateNotification}
            data-test="pre-pay-details-zero-state"
          >
            You have no Current Pre-Pay Items to display.
          </Notification>
        )}
      </div>
      <h2 className={classes.prePayTitle}>Pre-Pay Payment History</h2>
      <div className={classes.yearSelector}>
        <p className={classes.yearSelectorTitle}>Period: </p>
        <select
          name="yearSelector"
          className={classes.yearSelectorDropdown}
          data-test="pre-pay-year-selector"
          value={selectedYear}
          onChange={handleYearChange}
        >
          {renderDropdownValues()}
        </select>
      </div>
      <table className={classes.tableContainer}>
        <thead>
          <tr>
            <th className={classes.rowTextLeftAligned}>{historyHeaders[0]}</th>
            <th className={classes.rowTextLast}>{historyHeaders[1]}</th>
          </tr>
        </thead>
        {prepayHistory && (
          <tbody className={classes.mobileDisplayBlock}>
            {renderPrepayHistory()}
          </tbody>
        )}
      </table>
      <div className={classes.tableFooter}>
        {prepayHistory && prepayHistory.length > 0 ? (
          <div className={classes.endText}>End of Pre-Pay Payment History</div>
        ) : (
          <Notification
            variant="info"
            className={classes.zeroStateNotification}
            data-test="pre-pay-history-zero-state"
          >
            You have no Pre-Pay History to display.
          </Notification>
        )}
      </div>
    </div>
  );
};

PrePay.propTypes = {
  push: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  prepayDetails: PropTypes.arrayOf(
    PropTypes.shape({
      allocationID: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      description: PropTypes.string,
      default: PropTypes.string,
      allocated: PropTypes.number,
      invoiced: PropTypes.number,
      available: PropTypes.number,
      committed: PropTypes.number,
      classification: PropTypes.string,
    })
  ),
  prepayHistory: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      shelf: PropTypes.string,
      allocatedAmount: PropTypes.number,
    })
  ),
  prepayStatus: PropTypes.string,
  getPrepayInfo: PropTypes.func.isRequired,
  getPrepayHistory: PropTypes.func.isRequired,
  prepayHistoryStatus: PropTypes.string,
  clearPrepay: PropTypes.func.isRequired,
  prepayTransaction: PropTypes.shape({
    date: PropTypes.string,
    amountEntered: PropTypes.number,
    accountId: PropTypes.string,
  }),
  clearPrepayTransaction: PropTypes.func.isRequired,
  setPaymentFlowType: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    prepayAddFunds: PropTypes.bool,
    canadaReadOnly: PropTypes.bool,
    canadaPayments: PropTypes.bool,
  }).isRequired,
};

PrePay.defaultProps = {
  prepayDetails: null,
  prepayHistory: null,
  prepayStatus: null,
  prepayHistoryStatus: null,
  prepayTransaction: null,
};

export default withStyles(styles)(PrePay);
