export default (theme) => ({
  exportContainer: {
    margin: '0 0 16px 20px',
    fontSize: '13px',
    height: 32,
  },
  exportOption: {
    width: 64,
    borderRight: '1px solid #ccc',
    fontWeight: 'bold',
    '&:last-child': {
      borderRight: 0,
    },
  },
  exportButton: {
    border: 0,
    background: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
