import React from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MaterialButtonYellow } from 'shared/ui';
import {
  Card,
  CardContent,
} from 'nutrien-common-components-react/dist/mdc/Card';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees/DisabledForEmployees';
import currency from 'shared/utils/numberFormatters/currency';
import { accountPropType, invoicePropType } from '../invoicePropTypes';
import { hasDiscountTerms } from './invoiceCardUtils';
import { INVOICE_STATUSES } from '../../Account/Invoices/actions/constants';
import paidInvoice from '../../../assets/PaidInvoice.svg';

function getAmountDueLabel(invoice) {
  return hasDiscountTerms(invoice) && invoice.discountApplicable
    ? 'Discounted Amount Due'
    : 'Amount Due';
}

function isInvoicePayable(invoice) {
  return (
    invoice &&
    invoice.status !== INVOICE_STATUSES.PAID &&
    invoice.status !== INVOICE_STATUSES.PENDING &&
    invoice.status !== INVOICE_STATUSES.SCHEDULED &&
    invoice.amountDue > 0
  );
}

export const AmountDueCard = ({ account, invoice, handlePaymentClick }) => {
  const { payments, invoicesCheckbox, southernOhioDivision } = useFlags();
  const isCanada = account.locationCode === 'CAN';
  // TODO: remove usaSapAccount restrictions once SAP is rolled out to entire US market
  const isInvalidAccount =
    (account.usaSapAccount && !southernOhioDivision) ||
    account.invalidCanadianAccount ||
    account.invalidInternationalAccount;

  const amountDueShouldBeDisabled =
    account.calculatedCustomerType === 'Fuel' ||
    isInvalidAccount ||
    !payments ||
    !(invoicesCheckbox && isInvoicePayable(invoice));

  return (
    <Card
      className={
        invoice.status === INVOICE_STATUSES.PAID
          ? 'invoice-card invoice-card-paid'
          : 'invoice-card'
      }
    >
      <CardContent className="invoice-card__content">
        <p
          className={
            invoice.status === INVOICE_STATUSES.PAID
              ? 'invoice-card__subtitle invoice-card__subtitle-green'
              : 'invoice-card__subtitle'
          }
          data-test="invoice-amount-due-card-label"
        >
          {invoice.status === INVOICE_STATUSES.PAID
            ? 'Amount Due'
            : getAmountDueLabel(invoice)}
        </p>
        <div className="invoice-card__amount-and-action">
          <h1
            className="invoice-card__title"
            data-test="invoice-amount-due-card-title"
          >
            {currency(invoice.amountDue)}
          </h1>
          <div className="invoice-card-actions">
            {!isCanada && (
              <DisabledForEmployees>
                <MaterialButtonYellow
                  data-test="invoice-amount-due-card-pay-btn"
                  onClick={() => handlePaymentClick(account, invoice)}
                  disabled={amountDueShouldBeDisabled}
                >
                  Pay Now
                </MaterialButtonYellow>
              </DisabledForEmployees>
            )}
          </div>
        </div>
        {invoice.status === INVOICE_STATUSES.PAID && invoice.amountDue === 0 ? (
          <img
            src={paidInvoice}
            alt="paid invoice img"
            className="paid-invoice-stamp"
          />
        ) : (
          <p className="invoice-card__subtitle">Status: {invoice.status}</p>
        )}
      </CardContent>
    </Card>
  );
};

AmountDueCard.propTypes = {
  account: accountPropType.isRequired,
  invoice: invoicePropType.isRequired,
  handlePaymentClick: PropTypes.func,
};

AmountDueCard.defaultProps = {
  handlePaymentClick: () => {},
};

export default AmountDueCard;
