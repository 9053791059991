import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';

const ExpirationCell = ({
  isExpiringSoon,
  isExpired,
  expirationDate,
  classes,
}) => (
  <div className={classes.displayTable}>
    {isExpiringSoon && (
      <span className={classes.warningIconContainer}>
        <InfoIcon />
        <p className={classes.warningText}>expiring on </p>
      </span>
    )}
    {isExpired && (
      <span className={classes.alertIconContainer}>
        <WarningIcon />
        <p className={classes.warningText}>expired on </p>
      </span>
    )}
    <span className={classes.displayTableCell}>
      {expirationDate.format('M/D/YYYY')}
    </span>
  </div>
);

ExpirationCell.propTypes = {
  isExpiringSoon: PropTypes.bool,
  isExpired: PropTypes.bool,
  expirationDate: PropTypes.instanceOf(moment).isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

ExpirationCell.defaultProps = {
  isExpiringSoon: false,
  isExpired: false,
  classes: {},
};

export default ExpirationCell;
