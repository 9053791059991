import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  propType as invoiceFiltersPropType,
  defaultProp as invoiceFiltersDefaultProp,
} from '../../propTypes/invoiceFiltersPropType';
import InvoiceGrossAmountFilter from './InvoiceGrossAmountFilter';
import { sanitizeAmountRange } from '../shared/utils/filterHelpers';
import withInvoiceFilters from '../withInvoiceFilters';

const filterKey = 'grossAmount';
const defaultValue = ['', ''];

export class InvoiceGrossAmountFilterContainer extends Component {
  handleConfirmAmountFilter = (amountRange) => {
    let sanitizedAmountRange = sanitizeAmountRange(amountRange);

    const isEmpty = sanitizedAmountRange.every((amount) => amount === '');
    if (isEmpty) sanitizedAmountRange = []; // need empty array for filtering

    this.props.updateInvoiceFilters({
      [filterKey]: sanitizedAmountRange,
    });

    if (this.props.currentPage > 1) this.props.handlePaginationClick(1);
  };

  handleClearAmountFilter = () => {
    this.props.updateInvoiceFilters({
      [filterKey]: [],
    });
  };

  render() {
    const { invoiceFilters, maxGrossAmount, minGrossAmount } = this.props;
    let value = invoiceFilters[filterKey] || [];
    if (value.length === 0) value = defaultValue;

    return (
      <InvoiceGrossAmountFilter
        value={value}
        onConfirm={this.handleConfirmAmountFilter}
        onClear={this.handleClearAmountFilter}
        data-test="grossAmountModal"
        maxGrossAmount={maxGrossAmount}
        minGrossAmount={minGrossAmount}
      />
    );
  }
}

InvoiceGrossAmountFilterContainer.propTypes = {
  invoiceFilters: invoiceFiltersPropType,
  updateInvoiceFilters: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  handlePaginationClick: PropTypes.func.isRequired,
  maxGrossAmount: PropTypes.number,
  minGrossAmount: PropTypes.number,
};

InvoiceGrossAmountFilterContainer.defaultProps = {
  invoiceFilters: invoiceFiltersDefaultProp,
  currentPage: 1,
  maxGrossAmount: 100000,
  minGrossAmount: 0,
};

export default withInvoiceFilters(InvoiceGrossAmountFilterContainer);
