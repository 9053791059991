import { combineReducers } from 'redux';
import role from './roleReducer';

import {
  RESET_RBAC_REGISTRATION_FORM,
  UPDATE_RBAC_REGISTRATION_FORM,
  SET_RBAC_REGISTRATION_FORM_ERRORS,
  CLEAR_RBAC_REGISTRATION_FORM_ERRORS,
} from './roleDelegationConstants';

const initialState = {
  form: {
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
    termsAcceptance: false,
  },
  formErrors: [],
};

export const form = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RBAC_REGISTRATION_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    case SET_RBAC_REGISTRATION_FORM_ERRORS:
      return { ...state, formErrors: [...action.payload] };
    case CLEAR_RBAC_REGISTRATION_FORM_ERRORS:
      return { ...state, formErrors: initialState.formErrors };
    case RESET_RBAC_REGISTRATION_FORM:
      return {
        ...state,
        form: initialState.form,
        formErrors: initialState.formErrors,
      };
    default:
      return state;
  }
};

export default combineReducers({
  form,
  role,
});
