// dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Menu, MenuItem, withStyles } from '@material-ui/core';

import { classes } from 'utils/propTypes';
import { HeaderResponsiveMenuIcon, HeaderTitle } from 'shared/ui';

import LoginSwitchButton from 'components/Login/LoginSwitchButton';
import isEmployee from 'shared/utils/tokenUtils/isEmployee';

// stylesheets
import 'shared/ui/Header/Header.scss';
import './employeeHeader.css';

const styles = (theme) => {
  const displayNoneOnSmallScreens = {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      // the headerSpacer's border is still visible even when the elemtn itself is display none,
      // and username had no border, so no harm in adding it there
      border: 'none',
    },
  };

  return {
    menu: {
      zIndex: 99999,
      position: 'absolute',
      top: 35,
      left: 215,
      [theme.breakpoints.down('sm')]: {
        left: 10,
      },
    },
    menuIcon: {
      width: 30 /* on customer side, icon is always 30px wide, no matter the screen size */,
    },
    headerSpacer: displayNoneOnSmallScreens,
    username: displayNoneOnSmallScreens,
    accountMenuToggle: {
      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
      },
    },
  };
};

class Header extends Component {
  state = {
    isMenuOpen: false,
    anchorEl: null,
  };

  componentDidUpdate(prevProps) {
    // TODO: move this into the Container,
    // and don't use setState, use actions instead
    if (this.props.currentPath !== prevProps.currentPath) {
      // this is technically just a way to trick eslint,
      // as calling setState here will throw an error, but calling a method which internally sets state won't cause any issues,
      // however, we should move to actions anyways and that can be handled when the logic is moved to the container
      this.closeMenu();
    }
  }

  // TODO don't use setState, use actions instead
  openMenu = (event) => {
    this.setState({
      isMenuOpen: true,
      anchorEl: event.currentTarget,
    });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null, isMenuOpen: false });
  };

  toggleMenu = (event) => {
    if (this.state.isMenuOpen) {
      this.closeMenu();
    } else {
      this.openMenu(event);
    }
  };

  signOut = () => {
    this.closeMenu();
    this.props.logout();
  };

  render() {
    const { isMenuOpen, anchorEl } = this.state;
    const { classes, user, isMirroring } = this.props; // eslint-disable-line no-shadow
    const { toggleMenu } = this;

    return (
      <div className="header-wrapper employee">
        <div className="header">
          <div className="header-content--all-widths">
            <div className="header-logo">
              <NavLink activeClassName="selected" to="/Employee">
                <HeaderTitle
                  titleText="Nutrien Ag Solutions"
                  subTitleText="Employee"
                  path={this.props.currentPath}
                />
              </NavLink>
            </div>
            {this.props.currentPath === '/employee/login' && (
              <LoginSwitchButton location={this.props.location} />
            )}
            {isEmployee() && (
              <div
                onClick={toggleMenu}
                onKeyPress={toggleMenu}
                className={classNames(
                  'account-menu-toggle mobile-section-container',
                  classes.accountMenuToggle
                )}
                role="button"
                tabIndex="0"
              >
                <div
                  className={classNames(
                    'header__user-button__name',
                    classes.username
                  )}
                  data-hj-suppress=""
                >
                  {`${user.userName || ''}`}
                </div>

                {
                  // when mirroring the customer header is visible, and this icon is irrelevant
                  !isMirroring && (
                    <>
                      <HeaderResponsiveMenuIcon
                        id="responsive-hamburger-menu"
                        // the hard-coded "menu" has styles in the .css file, but classes.menu helps with the styles object defined above
                        className={classNames(
                          'menu',
                          'employee-menu',
                          classes.menuIcon
                        )}
                        toggleMenu={this.toggleMenu}
                        isMenuOpen={isMenuOpen}
                      />
                      <Menu
                        className={classes.menu}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                      >
                        <MenuItem onClick={this.signOut}>Sign Out</MenuItem>
                      </Menu>
                    </>
                  )
                }
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  classes: classes.isRequired,
  user: PropTypes.shape({
    userName: PropTypes.string,
  }).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  currentPath: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  isMirroring: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Header);
