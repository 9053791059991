import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { getQueryStringFromRouter } from 'shared/utils/reduxSelectors';
import { startMirroring } from '../actions';
import MirroringPage from './MirroringPage';
import setToken from '../../../../shared/utils/tokenUtils/setToken';

export class MirroringPageContainer extends Component {
  static propTypes = {
    redirectPath: PropTypes.string,
    userId: PropTypes.string,
    startMirroringFn: PropTypes.func.isRequired,
  };

  static defaultProps = {
    redirectPath: undefined,
    userId: undefined,
  };

  componentDidMount() {
    const { redirectPath, userId, startMirroringFn } = this.props;
    startMirroringFn({ id: userId }, redirectPath);
    setToken({ mirroredUser: userId });
  }

  render() {
    return <MirroringPage {...this.props} />;
  }
}

const mapDispatchToProps = {
  startMirroringFn: startMirroring,
};

const mapStateToProps = (state) => ({
  error: state.employee.mirroring.error,
  redirectPath: getQueryStringFromRouter(state.router).redirectPath,
  userId: getQueryStringFromRouter(state.router).userId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MirroringPageContainer);
