import apiAdapter from 'shared/lib/cxhApiService';
import { get } from 'lodash';
import { put as preferencePut } from '../notificationsPreferencesService';
import updatePreferredPhoneNumber from '../utils/updatePreferredPhoneNumber';

import notificationsPreferencesUpdateStart from './notificationsPreferencesUpdateStart';
import notificationsPreferencesUpdateSuccess from './notificationsPreferencesUpdateSuccess';
import notificationsPreferencesUpdateFailure from './notificationsPreferencesUpdateFailure';

/**
 * Updates the notification preferences (email and phone) for a user, based on the
 * changes supplied.
 * @param  {object} userPreferences        The user's current preferences.
 * @param  {object} emailPreferenceChanges Changes the user has made to their email preferences.
 * @example
 *  {
 *    ACCOUNT-SOMETHING-HERE: true
 *  }
 * @param  {object} phonePreferenceChanges Changes the user has made to their phone preferences.
 * @return {promise} Promise reflecting the state of the PUT request.
 */
export default (
    userPreferences,
    emailPreferenceChanges,
    phonePreferenceChanges,
    preferredPhoneNumber
  ) =>
  (dispatch) => {
    const { email, phoneNumber, firstName, lastName, privacy } =
      userPreferences;
    let payload = {
      email,
      phoneNumber,
      firstName,
      lastName,
      privacy,
      emails: userPreferences.emails.map((emailPref) => ({
        ...emailPref,
        preferences: {
          ...emailPref.preferences,
          ...emailPreferenceChanges,
        },
      })),
      phoneNumbers: userPreferences.phoneNumbers.map((phonePref) => ({
        ...phonePref,
        preferences: {
          ...phonePref.preferences,
          ...phonePreferenceChanges,
        },
      })),
    };

    const currentPreferredNumber = (
      get(userPreferences, 'phoneNumbers').find((pref) => pref.preferred) || {}
    ).value;

    const shouldUpdatePreferredNumber =
      preferredPhoneNumber &&
      !!(currentPreferredNumber !== preferredPhoneNumber);

    if (shouldUpdatePreferredNumber) {
      payload = updatePreferredPhoneNumber(payload, preferredPhoneNumber);
    }

    return Promise.resolve()
      .then(() => dispatch(notificationsPreferencesUpdateStart))
      .then(() => {
        // If preferred phone number was updated, that has to hit the USER
        // endpoint first before updating notifications preferences.
        if (shouldUpdatePreferredNumber) {
          return apiAdapter
            .put(`v1/user/`, payload)
            .then(() => preferencePut(payload));
        }
        return preferencePut(payload);
      })
      .then((responsePreferences) =>
        dispatch(notificationsPreferencesUpdateSuccess(responsePreferences))
      )
      .catch((err) => {
        dispatch(notificationsPreferencesUpdateFailure(err));
        return Promise.reject(err);
      });
  };
