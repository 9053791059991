// dependencies
import React from 'react';
import PropTypes from 'prop-types';

// shared UI

// components

// stylesheets
import './DataList.css';

const DataList = (props) => (
  <div className="data-list-container">{props.children}</div>
);

const DataItem = (props) => (
  <div
    className={`data-list-item ${
      props.full ? 'data-list-item__mobile__full' : ''
    }`}
  >
    <h3 className="data-list-item-title">{props.title}</h3>
    <p className="data-list-item-data" data-hj-suppress="">
      {props.data}
    </p>
  </div>
);

DataList.propTypes = {
  children: PropTypes.node.isRequired,
};

DataItem.propTypes = {
  full: PropTypes.bool,
  title: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
};

DataItem.defaultProps = {
  full: false,
  data: undefined,
};

export { DataList, DataItem };
