import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';

import './ConsultantInfoStyles.scss';

const ConsultantInfo = () => {
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const { cropConsultant } = selectedAccount;
  if (!cropConsultant) return null;
  const { firstName, lastName, workEmailAddress, workPhone } = cropConsultant;

  return (
    <>
      <div className="consultant-info__consultant-info-container">
        <div className="consultant-info__consultant-info-wrapper">
          {firstName && lastName && (
            <div className="consultant-info__crop-consultant">
              <p
                className="consultant-info__consultant-name"
                data-test="consultant-info__consultant-name"
              >
                {firstName} {lastName}
              </p>
              <p className="consultant-info__consultant-info">
                Your Nutrien Ag Solutions Representative
              </p>
            </div>
          )}
        </div>
        {workEmailAddress && (
          <div className="consultant-info__consultant-contact-container">
            <Email className="consultant-info__consultant-info-icon" />
            <p
              className="consultant-info__consultant-email"
              data-test="consultant-info__consultant-email"
            >
              <a
                href={`mailto:${workEmailAddress}`}
                className="consultant-img__link"
              >
                {workEmailAddress}
              </a>
            </p>
          </div>
        )}
        <br />
        {workPhone && (
          <div className="consultant-info__consultant-contact-container">
            <Phone className="consultant-info__consultant-info-icon" />
            <p
              className="consultant-info__consultant-phone"
              data-test="consultant-info__consultant-phone"
            >
              {workPhone}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default ConsultantInfo;
