import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUserSSO } from 'actions';
import {
  gfaEvent,
  gfaScreenView,
  gfaShare,
} from 'store/middleware/TagManager/gtmActions';
import { setGFAVersion } from 'components/NutrienGFA/actions';
import { validateToken } from 'shared/utils/tokenUtils';
import { user } from 'components/User/userProps';
import storage from 'shared/lib/storage';

// const ios = /iphone|ipad/ig.test(window.navigator.userAgent);
const android = /android/gi.test(window.navigator.userAgent);
const container = android ? document : window;

class SSO extends React.Component {
  componentDidMount() {
    container.addEventListener('message', (event) =>
      this.handleMessage(event.data)
    );
    this.sendMessage({
      name: 'authentication_check',
      payload: storage.getItem('persist:authentication'),
    });
  }

  componentWillUnmount() {
    container.removeEventListener('message', (event) =>
      this.handleMessage(event.data)
    );
  }

  handlers = {
    // Sets authentication details passed in from GFA
    set_authentication: async (auth) => {
      const { session, user } = auth;
      try {
        const isValid = await validateToken({ user, session });
        if (isValid) {
          this.props.loginUserSSO({ user, session }, '/');
          if (this.props.user.status === 'USER_FETCHED') {
            this.sendMessage({
              name: 'authenticated',
              payload: this.props.user,
            });
          }
        }
      } catch (error) {
        this.sendMessage({
          name: 'error',
          payload: error,
        });
      }
    },
    track_event: (data) => {
      this.props.gfaEvent(data);
    },
    track_screen_view: (data) => {
      const { screen } = data;
      this.props.gfaScreenView(screen);
    },
    track_share: (data) => {
      this.props.gfaShare(data);
    },
    set_gfa_version: (data) => {
      this.props.setGFAVersion(data);
    },
  };

  handleMessage = (rawMessage) => {
    // rawMessage contains the stringified message sent from GFA
    if (typeof rawMessage === 'string') {
      try {
        const message = JSON.parse(rawMessage);
        const { name, payload } = message;

        // Get a handler for the message name
        const handler = this.handlers[name];

        // Execute the handler with the message payload
        if (handler) {
          handler(payload);
        }
      } catch (error) {
        this.sendMessage({
          name: 'error',
          payload: error,
        });
      }
    }
  };

  sendMessage = (payload) => {
    // Only send a message if it's wrapped by React Native
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(payload));
    }
  };

  render() {
    // This Component's function is to communicate with the mobile wrapper.
    // Therefore no UI is needed..
    return null;
  }
}

SSO.propTypes = {
  user: user.isRequired,
  loginUserSSO: propTypes.func.isRequired,
  gfaEvent: propTypes.func.isRequired,
  gfaScreenView: propTypes.func.isRequired,
  gfaShare: propTypes.func.isRequired,
  setGFAVersion: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginUserSSO,
  gfaEvent,
  gfaScreenView,
  gfaShare,
  setGFAVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(SSO);
