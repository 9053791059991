import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  TableRow,
  TableCell,
  Checkbox,
} from 'nutrien-common-components-react/dist/mdc';
import DateFormatter from 'shared/ui/DateFormatter/DateFormatter';
import currency from 'shared/utils/numberFormatters/currency';
import InvoiceStatusLabel from './InvoiceStatusLabel';

const renderMobileView = (propsCopy) => {
  const {
    disabled,
    date,
    accountId,
    id,
    grossAmount,
    amountDue,
    dueDate,
    status,
    includeGrossAmount,
    gtmViewInvoiceAction,
  } = propsCopy;

  return (
    <TableRow style={disabled ? { background: '#f5f5f5' } : {}}>
      <div className="invoices-table__row-mobile">
        <TableCell className="invoices-table__list-item-mobile" colSpan={2}>
          <span className="invoices-table__mobile-heading">Date</span>
          {DateFormatter(date)}
        </TableCell>
        {disabled || !includeGrossAmount ? (
          <TableCell className="invoices-table__list-item-mobile" colSpan={2}>
            <span className="invoices-table__mobile-heading">Invoice ID</span>
            {id}
          </TableCell>
        ) : (
          <TableCell className="invoices-table__list-item-mobile" colSpan={2}>
            <span className="invoices-table__mobile-heading">Invoice ID</span>
            <Link
              className="invoices-table__list-item-link"
              to={`/accounts/${accountId}/invoices/${id}`}
              onClick={() => gtmViewInvoiceAction(id)}
            >
              {id}
            </Link>
          </TableCell>
        )}
        {includeGrossAmount && (
          <TableCell className="invoices-table__list-item-mobile" colSpan={2}>
            <span className="invoices-table__mobile-heading">Gross Amount</span>
            {currency(grossAmount)}
          </TableCell>
        )}
        <TableCell className="invoices-table__list-item-mobile" colSpan={2}>
          <span className="invoices-table__mobile-heading">Amount Due</span>
          {currency(amountDue)}
        </TableCell>
        <TableCell className="invoices-table__list-item-mobile" colSpan={2}>
          <span className="invoices-table__mobile-heading">Due Date</span>
          {DateFormatter(dueDate)}
        </TableCell>
        <TableCell className="invoices-table__list-item-mobile" colSpan={2}>
          <span className="invoices-table__mobile-heading">Status</span>
          <InvoiceStatusLabel status={status} />
        </TableCell>
      </div>
    </TableRow>
  );
};

const DesktopView = (propsCopy) => {
  const {
    disabled,
    date,
    accountId,
    id,
    grossAmount,
    amountDue,
    dueDate,
    status,
    onChange,
    checked,
    includeGrossAmount,
    gtmViewInvoiceAction,
  } = propsCopy;
  const { creditedInvoicesCheckbox, invoicesCheckbox } = useFlags();
  const rowCheckbox =
    amountDue < 0 ? creditedInvoicesCheckbox : invoicesCheckbox;
  return (
    <TableRow
      style={disabled ? { background: '#f5f5f5' } : {}}
      selected={checked}
    >
      {rowCheckbox ? (
        <TableCell padding="checkbox" colSpan={1}>
          <Checkbox
            onChange={() =>
              onChange({
                id: `${id}`,
                status,
                amountDue,
              })
            }
            checked={checked}
            disabled={disabled}
            value={`${id}`}
          />
        </TableCell>
      ) : (
        <TableCell />
      )}
      <TableCell colSpan={2}>{DateFormatter(date)}</TableCell>
      {disabled || !includeGrossAmount ? (
        <TableCell colSpan={2}>{id}</TableCell>
      ) : (
        <TableCell colSpan={2}>
          <Link
            className="invoices-table__list-item-link"
            to={`/accounts/${accountId}/invoices/${id}`}
            onClick={() => gtmViewInvoiceAction(id)}
          >
            {id}
          </Link>
        </TableCell>
      )}
      {includeGrossAmount && (
        <TableCell colSpan={2}>{currency(grossAmount)}</TableCell>
      )}
      <TableCell colSpan={2}>{currency(amountDue)}</TableCell>
      <TableCell colSpan={2}>{DateFormatter(dueDate)}</TableCell>
      <TableCell colSpan={2}>
        <InvoiceStatusLabel status={status} />
      </TableCell>
    </TableRow>
  );
};

const InvoiceListItem = (props) => {
  const propsCopy = cloneDeep(props);
  return props.isMobile ? (
    renderMobileView(propsCopy)
  ) : (
    <DesktopView {...propsCopy} />
  );
};

InvoiceListItem.defaultProps = {
  disabled: false,
  accountId: null,
  grossAmount: null,
  includeGrossAmount: true,
};

InvoiceListItem.propTypes = {
  disabled: PropTypes.bool,
  date: PropTypes.string.isRequired,
  accountId: PropTypes.string,
  id: PropTypes.string.isRequired,
  grossAmount: PropTypes.number,
  amountDue: PropTypes.number.isRequired,
  dueDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  includeGrossAmount: PropTypes.bool,
  gtmViewInvoiceAction: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default InvoiceListItem;
