import { push } from 'connected-react-router';

import { getAccessToken } from '@nutrien/fe-domain-utils';

import {
  NO_USER,
  FETCHING_USER,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  CLEAR_USER_ERROR,
} from 'shared/config/userConstants';
import { UPDATE_ROLES } from 'components/Profile/rbacRedux/rbacConstants';
import { setToken, removeToken } from 'shared/utils/tokenUtils';
import { setAlert, setAccounts } from 'actions/actionCreators';
import {
  updateTermsAcceptance,
  showTermsModal,
} from 'components/Terms/termsActions';
import debtStatus from 'shared/utils/debtStatus';
import { selectUserTermsAccepted } from 'selectors/userSelectors';

import { logout } from '../components/Authentication/actions/authenticationActions';
import { loginSuccess } from '../components/Authentication/actions';

import * as userService from './userService';

export const loadingUser = () => ({
  type: FETCHING_USER,
});

export const noUser = () => ({
  type: NO_USER,
});

export const userLoginSuccess = (user) => ({
  type: USER_LOGIN_SUCCESS,
  payload: user,
});

export const userLoginFailure = (error) => ({
  type: USER_LOGIN_FAILURE,
  error,
});

export const userFetchSuccess = (user, auth0Login) => ({
  type: USER_FETCH_SUCCESS,
  payload: { user, auth0Login },
});

export const userFetchFailure = (error) => ({
  type: USER_FETCH_FAILURE,
  error,
});

export const clearUserError = () => ({
  type: CLEAR_USER_ERROR,
});

export const setRoles = (roleArray) => ({
  type: UPDATE_ROLES,
  payload: roleArray,
});

export const handleDebtStatus = () => async (dispatch) => {
  const debtStatusMessage =
    'You are currently locked out of the Digital Hub due to an issue with your account status. Please contact the Customer Service Department by calling 1-866-261-2033 to receive assistance regarding your account.';

  if (await getAccessToken()) {
    removeToken();
  }

  dispatch(
    setAlert({
      notification: {
        message: debtStatusMessage,
        mobileMessage: debtStatusMessage,
        type: 'DANGER',
      },
    })
  );

  return dispatch(logout('/logged-out/account-issue'));
};

const setUserRole = (user) => (dispatch) => {
  const {
    userDetails: { associatedOrgs },
  } = user;

  if (Array.isArray(associatedOrgs) && associatedOrgs.length) {
    if (
      associatedOrgs[0].orgOwnerId &&
      associatedOrgs[0].orgOwnerId === user.id
    ) {
      return dispatch(setRoles(['owner']));
    }
    return dispatch(setRoles(['admin']));
  }

  return null;
};

export const loginUser = (
  { username, password },
  route,
  acceptTermsVersion = null
) => async (dispatch, getState) => {
  dispatch(loadingUser());

  try {
    const response = await userService.login({
      username,
      password,
      includeUserData: true,
    });

    const {
      data: { user, session },
    } = response;

    if (debtStatus(user.userDetails.associatedOrgs)) {
      dispatch(await handleDebtStatus());
      return;
    }
    setToken(session); // TODO: delete this
    if (acceptTermsVersion) {
      await dispatch(updateTermsAcceptance(acceptTermsVersion, route));
    }

    dispatch(setUserRole(user));
    dispatch(loginSuccess(session));
    dispatch(userLoginSuccess(user));

    if (selectUserTermsAccepted()(getState()) === false) {
      dispatch(showTermsModal(route));
    } else if (route) {
      dispatch(push(route));
    }
  } catch (error) {
    if (error.message.includes('ValidationError')) {
      // If they aren't force logged out, refreshing the page skips the error
      dispatch(logout());
      throw new Error(error);
    }

    dispatch(userLoginFailure(error));
  }
};

export const loginUserSSO = (
  { user = { userDetails: {} }, session },
  route,
  acceptTermsVersion = null
) => async (dispatch, getState) => {
  if (debtStatus(user.userDetails.associatedOrgs)) {
    dispatch(await handleDebtStatus());
    return;
  }
  setToken(session);
  if (acceptTermsVersion) {
    await dispatch(updateTermsAcceptance(acceptTermsVersion, route));
  }

  dispatch(setUserRole(user));
  dispatch(loginSuccess(session));
  dispatch(userLoginSuccess(user));

  // Start a new GA session.
  if (window.ReactNativeWebView) {
    window.ga('send', 'pageview', { sessionControl: 'start' });
  }

  if (selectUserTermsAccepted()(getState()) === false) {
    dispatch(showTermsModal(route));
  } else if (route) {
    dispatch(push(route));
  }
};

export const fetchUser = () => async (dispatch, getState) => {
  dispatch(loadingUser());
  dispatch(setAccounts({ status: 'LOADING', list: [] }));

  try {
    const response = await userService.findUser();
    const { data: user } = response;

    if (debtStatus(user.userDetails.associatedOrgs)) {
      return dispatch(await handleDebtStatus());
    }
    dispatch(setUserRole(user));

    const { auth0Login } = getState().user;
    dispatch(userFetchSuccess(response, auth0Login));
    return response;
  } catch (error) {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      return dispatch(noUser());
    }
    return dispatch(userFetchFailure(error));
  }
};
