export default (theme) => ({
  saveAccount: {
    width: '100%',
    marginBottom: '0',
    '& label': {
      top: '8px',
    },
  },
  savePaymentMethodText: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.text.primary,
    paddingBottom: '2px',
  },
  checkboxLabel: {
    marginBottom: '0',
  },
  storeAccountRowItems: {
    display: 'flex',
    alignItems: 'center',
  },
});
