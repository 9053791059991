import { setProductHistoryOk } from '../../../ProductHistoryActionCreators';

export const setProductHistoryObjectsChecked = (
  productNumbersToCheck,
  uncheckedProductHistoryList
) => (dispatch) => {
  const checkedProductHistoryList = uncheckedProductHistoryList.map(
    (productHistory) => {
      if (productNumbersToCheck.includes(productHistory.productNumber)) {
        // eslint-disable-next-line no-param-reassign
        productHistory.isChecked = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        productHistory.isChecked = false;
      }
      return productHistory;
    }
  );
  dispatch(setProductHistoryOk(checkedProductHistoryList));
};
