import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Dropdown from 'shared/ui/Dropdown';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import ContractsTable from '../ContractsTable';
import { contractStatuses } from './constants';

import MaterialLoadingIcon from '../../../../shared/ui/RebrandedMaterialLoadingIconInline/MaterialLoadingIcon';

import useStyles from './styles';

const ContractsTab = (props) => {
  const {
    documents: { contractsLoading, contracts },
  } = props;
  const classes = useStyles();
  let section;
  const [filter, setFilter] = useState({ name: 'All Contracts' });
  const [contractsData, filterContracts] = useState(contracts.data);

  useEffect(() => {
    if (contracts.data) {
      if (filter.name === 'All Contracts') {
        filterContracts(contracts.data);
      } else {
        const filteredContracts = contracts.data.filter(
          (contract) => contract.contractStatus === filter.name
        );
        filterContracts(filteredContracts);
      }
    }
  }, [filter]);

  const renderLoading = () => {
    const section = (
      <div className={classes.loadingWrapper} data-test="contracts-tab-loading">
        <MaterialLoadingIcon />
      </div>
    );
    return section;
  };

  const renderError = () => {
    const section = (
      <div data-test="contracts-tab-error">
        <ApiErrorMessage entityName="Contracts" />
      </div>
    );
    return section;
  };

  const renderTable = (tableData) => {
    return <ContractsTable tableData={tableData} />;
  };

  if (contractsLoading) {
    section = renderLoading();
  } else if (contracts.data) {
    section = renderTable(contractsData);
  } else {
    section = renderError();
  }

  return (
    <>
      <div className={classes.header}>
        <span className={classes.tabLabel}>Contracts</span>
        <div className={classes.dropDown}>
          <h5 className={classes.dropDownLabel}>Filter</h5>
          <Dropdown
            data-test="contract-filter-dropdown"
            options={contractStatuses}
            selectedValue={filter}
            placeholder="All Contracts"
            onChange={(value) => setFilter(value)}
            validate={noop}
            shouldHavePlaceHolder={false}
            dropdownName="contractFilterOptions"
          />
        </div>
      </div>
      {section}
    </>
  );
};

ContractsTab.propTypes = {
  documents: PropTypes.shape({
    contractsLoading: PropTypes.bool,
    error: PropTypes.shape({}),
    contracts: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
};

export default ContractsTab;
