const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

// ARS returns date strings as 'YYYY-MM-DDT00:00:00' but our CCL DateModal returns 'YYYY-MM-DD' by default.
// This appends the T time if necessary.
export const appendTimeToDateString = (dateString) =>
  dateString.includes('T') ? dateString : `${dateString}T00:00:00`;

const zeroPad = (num) => {
  const formattedNumber = typeof num !== 'number' ? parseInt(num, 10) : num;

  return formattedNumber < 10 ? `0${formattedNumber}` : `${formattedNumber}`;
};

/**
 * nutrien-style-guide currently returns a date as 'n/j/Y', or month/day/year, where months and days get no leading 0's.
 * This will convert that date to and ISO 8601 string (YYYY-MM-DD) instead, so it can be parsed by JS correctly.
 * https://tc39.github.io/ecma262/#sec-date-time-string-format
 * @param  {string} badDate n/j/Y date string.
 * @return {string}         YYYY-MM-DD string.
 */
export const monthDayYearToIsoDateString = (badDate) => {
  const [month, day, year] = badDate.split('/');
  return `${year}-${zeroPad(month)}-${zeroPad(day)}`;
};

/**
 * Takes a JS date object and returns an ISO-formatted string, compatible with the invoice date filter.
 * @param  {Date} dateObject JS Date object
 * @return {String}          Filter-compatible ISO-formatted date string
 */
export const formatDateToIsoString = (dateObject) =>
  `${dateObject.getUTCFullYear()}-${zeroPad(
    dateObject.getUTCMonth() + 1
  )}-${zeroPad(dateObject.getUTCDate())}T00:00:00`;

/**
 * Takes a date object or a timestamp string and returns a timestamp string compatible with these date functions.
 * Compatible Arguments:
 *  * ISO String: 'YYYY-MM-DD'
 *  * Date object.
 * @return {string}
 */
const dateOrIsoStringToUnixTimestamp = (date) =>
  date instanceof Date
    ? new Date(formatDateToIsoString(date)).getTime()
    : new Date(appendTimeToDateString(date)).getTime();

/**
 * Takes an ISO Date string, subtracts X days, then returns a new ISO date string with the newly calculated time
 * @param  {string|Date} date date to subtract from
 * @param  {Number} daysToSubtract Number of days to subtract
 * @return {string}                New ISO-formatted date string with the subtracted days.
 */
export const subtractDaysFromDate = (date, daysToSubtract) => {
  const timestamp = dateOrIsoStringToUnixTimestamp(date);
  const millisecondsToSubtract = MILLISECONDS_IN_DAY * daysToSubtract;
  return formatDateToIsoString(new Date(timestamp - millisecondsToSubtract));
};

/**
 * Takes an ISO Date string, adds X days, then returns a new ISO date string with the newly calculated time
 * @param  {string|Date} date date to subtract from
 * @param  {Number} daysToSubtract Number of days to subtract
 * @return {string}                New ISO-formatted date string with the subtracted days.
 */
export const addDaysToDate = (date, daysToAdd) => {
  const timestamp = dateOrIsoStringToUnixTimestamp(date);
  const millisecondsToAdd = MILLISECONDS_IN_DAY * daysToAdd;
  return formatDateToIsoString(new Date(timestamp + millisecondsToAdd));
};

/**
 * Filters an array of objects by date.
 * ASSUMPTIONS: dates are strings in the format of 'YYYY-MM-DD' and optionally 'YY-MM-DDT:00:00:00'
 * @example
     const filteredItems = filterByDate({
       items: [{ date: '2018-09-01' }, { date: '2019-01-01' }],
       filterKey: 'date',
       startDateString: '2018-06-07',
       endDateString: '2018-12-01',
     });

     // returns [{ date: '2018-09-01' }]
 * @param  {Object[]} objects       Array of objects you want to filter
 * @param  {String} filterKey       The key inside the object you want to filter on. This prop should contain a
 *                                  DATE STRING in the format listed above.
 * @param  {String} startDateString The start date to filter on. Every date older than this will be filtered out.
 * @param  {String} endDateString   The end date to filter on. Every date newer than this will be filtered out.
 * @return {Object[]}               Filtered array of objects.
 */
export const filterByDate = ({
  items,
  filterKey,
  startDateString,
  endDateString,
}) => {
  let formattedStartDateString = startDateString;
  let formattedEndDateString = endDateString;

  // support n/j/Y strings supplied by nutrien common component date picker.
  if (startDateString && startDateString.includes('/')) {
    formattedStartDateString = monthDayYearToIsoDateString(startDateString);
  }

  // support n/j/Y strings supplied by nutrien common component date picker.
  if (endDateString && endDateString.includes('/')) {
    formattedEndDateString = monthDayYearToIsoDateString(endDateString);
  }

  const startTimestamp = formattedStartDateString
    ? new Date(appendTimeToDateString(formattedStartDateString)).getTime()
    : undefined;
  const endTimestamp = formattedEndDateString
    ? new Date(appendTimeToDateString(formattedEndDateString)).getTime()
    : undefined;

  return items.filter((item) => {
    const itemTimestamp = item[filterKey]
      ? new Date(appendTimeToDateString(item[filterKey])).getTime()
      : undefined;

    // If no item timestamp, filter it out.
    if (!itemTimestamp) {
      return false;
    }

    // If the filter has no settings, return everything.
    if (!startTimestamp && !endTimestamp) {
      return true;
    }

    // NOTE: At this point, we must have at least one timestamp, either start or end.

    // If no start time, then assume we have an end time and filter out everything >= the end.
    if (!startTimestamp) {
      return itemTimestamp <= endTimestamp;
    }

    // if no end timestamp, then assume we have a start time and filter out everything <= the start
    if (!endTimestamp) {
      return itemTimestamp >= startTimestamp;
    }

    // At this point, we must have both a start and an end date. Filter on both.
    return itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp;
  });
};
