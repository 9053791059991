// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
// shared UI

// components
import ArrowLink from 'shared/ui/ArrowLink';

// stylesheets
import './PageNotification.css';
import { NOTIFICATION_TYPES } from './index';

const PageNotification = (props) => {
  let arrowLink;
  if (props.link && props.rightLink) {
    arrowLink = (
      <div className="arrow-link__right">
        <ArrowLink
          inline
          link={props.link}
          linkDescription={props.linkDescription}
          onClick={props.onClick}
        />
      </div>
    );
  } else if (props.link) {
    arrowLink = (
      <ArrowLink
        inline
        link={props.link}
        linkDescription={props.linkDescription}
        onClick={props.onClick}
      />
    );
  }

  let notificationClass;
  let NotificationIcon;
  let iconStyle;
  switch (props.type) {
    case NOTIFICATION_TYPES.DANGER:
      notificationClass = 'danger-notification';
      NotificationIcon = WarningIcon;
      iconStyle = { color: '#d24242' };
      break;
    case NOTIFICATION_TYPES.WARNING:
      notificationClass = 'warning-notification';
      NotificationIcon = InfoIcon;
      iconStyle = { color: '#f1c02a' };
      break;
    case NOTIFICATION_TYPES.SUCCESS:
      notificationClass = 'success-notification';
      NotificationIcon = InfoIcon;
      iconStyle = { color: '#00b455' };
      break;
    default:
      notificationClass = 'standard-notification';
  }

  const notification = (
    <div className={notificationClass}>
      <div className="page-notification__message">
        {NotificationIcon && (
          <NotificationIcon
            className="page-notification-icon"
            style={iconStyle}
          />
        )}
        <span className="page-notification__message__text">
          {props.message}
        </span>
        <span className="page-notification__message__text--mobile">
          {props.mobileMessage || props.message}
        </span>
      </div>{' '}
      {arrowLink}
    </div>
  );

  return (
    <div className={`page-notification ${props.className || ''}`}>
      {notification}
    </div>
  );
};

const messageType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({}),
]);

PageNotification.propTypes = {
  link: PropTypes.string,
  rightLink: PropTypes.string,
  linkDescription: PropTypes.string,
  message: messageType.isRequired,
  mobileMessage: messageType,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

PageNotification.defaultProps = {
  className: '',
  link: '',
  rightLink: '',
  linkDescription: '',
  type: '',
  mobileMessage: null,
  onClick: () => {},
};

export default PageNotification;
