import { createSelector } from 'reselect';
import { getQueryStringFromState } from 'shared/utils';
import getFilteredProductsList from './getFilteredProductsList';
import getFilters from './getFilters';
import getInvoices from '../../../selectors/getInvoices';
import getDateRange from '../../../selectors/getDateRange'; // TODO: remove when old date picker is removed.
import {
  oldFilterInvoicesByStatus,
  oldFilterInvoicesByDateRange,
  filterInvoicesByStatus,
  filterInvoicesById,
  filterInvoicesByInvoiceDate,
  filterInvoicesByDueDate,
  filterInvoicesByGrossAmount,
  filterInvoicesByAmountDue,
  filterInvoicesBySelectedProducts,
} from './filters';

export default createSelector(
  getFilters,
  getInvoices,
  getQueryStringFromState,
  getFilteredProductsList,
  getDateRange, // TODO: remove when old date picker is removed.
  (filters, invoices, queryString, filteredProductsList, dateRange) => {
    let filteredInvoices = invoices;

    // OLD filter by status TODO: remove when no longer in use.
    filteredInvoices = oldFilterInvoicesByStatus(
      filteredInvoices,
      null,
      queryString,
      filteredProductsList
    );

    // OLD filter by date range. TODO: remove when no longer in use.
    filteredInvoices = oldFilterInvoicesByDateRange(
      filteredInvoices,
      dateRange
    );

    // NEW filter by status
    filteredInvoices = filterInvoicesByStatus(filteredInvoices, filters.status);

    // filter by invoice Id
    filteredInvoices = filterInvoicesById(filteredInvoices, filters.invoiceId);

    // filter by invoice date
    filteredInvoices = filterInvoicesByInvoiceDate(
      filteredInvoices,
      filters.invoiceDate
    );

    // filter by due date range
    filteredInvoices = filterInvoicesByDueDate(
      filteredInvoices,
      filters.dueDate
    );

    // filter by gross amount range
    filteredInvoices = filterInvoicesByGrossAmount(
      filteredInvoices,
      filters.grossAmount
    );

    // filter by amount due range
    filteredInvoices = filterInvoicesByAmountDue(
      filteredInvoices,
      filters.amountDue
    );

    // filter by selected invoiced products
    filteredInvoices = filterInvoicesBySelectedProducts(
      filteredInvoices,
      filteredProductsList
    );

    return filteredInvoices;
  }
);
