import React from 'react';
import PropTypes from 'prop-types';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';

import './ProcessingOverlay.css';

const Overlay = ({ text }) => (
  <div className="processing-overlay">
    <div className="loading-icon-container">
      <div className="loading-icon-wrapper">
        <MaterialLoadingIcon />
        {text}
      </div>
    </div>
  </div>
);

Overlay.propTypes = {
  text: PropTypes.string,
};

Overlay.defaultProps = {
  text: 'Processing...',
};

export default Overlay;
