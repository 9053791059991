import { get } from '../intercomService';
import fetchFailure from './fetchFailure';
import fetchingHash from './fetchingHash';
import fetchSuccess from './fetchSuccess';

export default () => (dispatch) => {
  dispatch(fetchingHash());
  return get()
    .then((response) =>
      dispatch(fetchSuccess(response.attributes.intercomHash))
    )
    .catch((error) => dispatch(fetchFailure(error)));
};
