import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { NO_INSTRUCTIONS_MESSAGE } from '../../../../../shared/constants/ecommMessageText';

import './OrderMemo.scss';

const OrderMemo = (props) => {
  const { cart } = props;

  const memo = get(cart, 'postOrder.data.data.attributes.orderMemo', false);

  return (
    <div>
      <h2>Additional Instructions</h2>
      <div>
        <p className="order-memo__blurb" data-test="order-memo-blurb">
          {memo || NO_INSTRUCTIONS_MESSAGE}
        </p>
      </div>
    </div>
  );
};

OrderMemo.propTypes = {
  cart: PropTypes.shape({
    submittedCartData: PropTypes.array.isRequired,
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        error: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export default OrderMemo;
