import {
  PRODUCT_HISTORY_OK,
  PRODUCT_HISTORY_LOADING,
  PRODUCT_HISTORY_ERROR,
  PRODUCT_HISTORY_SORTED,
  PRODUCT_HISTORY_ADDING_TO_CART,
  PRODUCT_HISTORY_ADD_TO_CART_FAILURE,
  PRODUCT_HISTORY_ADD_TO_CART_SUCCESS,
  PRODUCT_HISTORY_NEW_SHELF,
  PRODUCT_HISTORY_NEW_TIMEFRAME,
  PRODUCT_HISTORY_HUBSPOT_OPT_IN_SUCCESS,
} from './ProductHistoryActionTypes';

const productHistory = (
  state = { status: 'LOADING', list: [], error: '' },
  action
) => {
  switch (action.type) {
    case PRODUCT_HISTORY_OK:
      return { status: 'OK', list: [...action.payload], error: '' };
    case PRODUCT_HISTORY_LOADING:
      return { status: 'LOADING', list: [], error: '' };
    case PRODUCT_HISTORY_ERROR:
      return { status: 'ERROR', list: [], error: action.payload };
    case PRODUCT_HISTORY_SORTED:
      return {
        status: 'SORTED',
        list: [],
        sortedList: [...action.payload],
        sortedColumn: action.sortedColumn,
        error: '',
      };
    case PRODUCT_HISTORY_ADDING_TO_CART:
      return {
        status: 'ADDING_TO_CART',
        list: [],
        productNumber: action.productNumber,
        shelf: action.shelf,
        error: '',
      };
    case PRODUCT_HISTORY_ADD_TO_CART_FAILURE:
      return {
        status: 'ADD_TO_CART_FAILURE',
        list: [],
        selectedShelf: action.selectedShelf,
        error: action.error,
      };
    case PRODUCT_HISTORY_ADD_TO_CART_SUCCESS:
      return {
        status: 'ADD_TO_CART_SUCCESS',
        list: [],
        error: '',
      };
    case PRODUCT_HISTORY_NEW_SHELF:
      return {
        status: 'NEW_SHELVES',
        list: [],
        selectedShelves: action.selectedShelves,
        error: '',
      };
    case PRODUCT_HISTORY_NEW_TIMEFRAME:
      return {
        status: 'NEW_TIMEFRAME',
        list: [],
        startDate: action.startDate,
        endDate: action.endDate,
        error: '',
      };
    case PRODUCT_HISTORY_HUBSPOT_OPT_IN_SUCCESS:
      return {
        status: 'HUBSPOT_OPT_IN_SUCCESS',
        list: [],
        error: '',
        shelf: action.shelf,
      };
    default:
      return state;
  }
};

export default productHistory;
