export const DROPDOWN_LIST_ENTRIES = [
  {
    index: 0,
    name: 'Product name, A-Z',
    query: 'name',
  },
  {
    index: 1,
    name: 'Product name, Z-A',
    query: '-name',
  },
  {
    index: 2,
    name: 'Popularity, most to least',
    query: 'rank',
  },
  {
    index: 3,
    name: 'Popularity, least to most',
    query: '-rank',
  },
];

export const DROPDOWN_LIST_ENTRIES_AFTER_SEARCH = [
  {
    index: 0,
    name: 'Relevance',
    query: '-_score',
  },
  {
    index: 1,
    name: 'Product name, A-Z',
    query: 'name',
  },
  {
    index: 2,
    name: 'Product name, Z-A',
    query: '-name',
  },
  {
    index: 3,
    name: 'Popularity, most to least',
    query: 'rank',
  },
  {
    index: 4,
    name: 'Popularity, least to most',
    query: '-rank',
  },
];
