import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { fetchTerms } from 'components/Terms/termsActions';
import isEmployeeView from 'utils/isEmployeeView';
import { Login } from '.';

const mapStateToProps = (state) => ({
  user: state.user,
  terms: state.terms,
  location: state.router.location,
  browser: state.browser,
  isEmployeeView: isEmployeeView(state),
  registration: state.registration,
});

const mapDispatchToProps = {
  push,
  fetchTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
