import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';

import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import NoLicensesMessage from './NoLicensesMessage';

const TableHeadCell = withStyles({ root: { fontWeight: 600 } })(TableCell);

const LicensesTable = ({ licenses, isLoading, error }) => {
  if (isLoading) return <MaterialLoadingIcon />;
  if (error) return <ApiErrorMessage entityName="Licenses" />;
  if (!licenses.length) return <NoLicensesMessage />;
  return (
    <Table className="licenses-table">
      <TableHead>
        <TableRow>
          <TableHeadCell>License Name</TableHeadCell>
          <TableHeadCell>License Number</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {licenses.map((license) => (
          <TableRow
            className="licenses-table-row"
            key={`${license.licenseNo}-${license.accountId}`}
          >
            <TableCell>{license.name}</TableCell>
            <TableCell>{license.licenseNo}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

LicensesTable.defaultProps = {
  licenses: [],
  error: undefined,
  isLoading: false,
};

LicensesTable.propTypes = {
  licenses: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.instanceOf(Error),
  isLoading: PropTypes.bool,
};

export default LicensesTable;
