import React from 'react';
import { withStyles } from '@material-ui/core';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import PropTypes from 'prop-types';
import styles from './styles';

const HeaderBrowserSupport = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.textContainer}>
      <h2 className={classes.message}>
        You are using an unsupported browser. Please update your browser for a
        better experience.
      </h2>
      <p className={classes.subheader}>
        Need help? Contact support at
        {` `}
        <a href="mailto:DigitalSupport@nutrien.com">
          DigitalSupport@nutrien.com
        </a>{' '}
        or{' '}
        <a href="tel:+18772414463" className={classes.phone}>
          1-877-241-4463
        </a>
      </p>
    </div>
    <div className={classes.buttonContainer}>
      <div className={classes.buttonWrapper}>
        <MaterialButton
          value="Update Now"
          id="sel-update-browser"
          className={classes.browserModalUpdateBrowser}
          onClick={() => {
            window.open('https://updatemybrowser.org/', '_blank');
          }}
        />
      </div>
      <div className={classes.buttonWrapper}>
        <MaterialButton
          type="secondary"
          value="Download Chrome"
          id="sel-download-chrome"
          className={classes.browserModalGetChrome}
          onClick={() => {
            window.open('https://www.google.com/chrome/', '_blank');
          }}
        />
        <p className={classes.buttonSubtitle}>
          Note: Nutrien 's preferred browser is Chrome.
        </p>
      </div>
    </div>
  </div>
);

HeaderBrowserSupport.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(HeaderBrowserSupport);
