import { connect } from 'react-redux';
import {
  handleAccountUpdate,
  getInvoices,
} from 'components/Account/Invoices/actions';
import { getSavedBankAccounts } from 'components/Payments/Redux/actions/BankAccountActions';
import InvoiceAccountInformation from './InvoiceAccountInformation';

const mapStateToProps = (state) => ({
  accounts: state.accounts.list,
  selectedAccount: state.selectedAccount,
  selectedInvoices: state.invoices.selectedInvoices,
  invoices: state.invoices.list,
  invoiceDetailsData: state.payments.invoicesData,
  discountedAmount: state.payments.bankAccountFormReducer.form.discountedAmount,
  fullInvoiceList: state.payments.bankAccountFormReducer.form.fullInvoiceList,
  user: state.user.userData,
});
const mapDispatchToProps = {
  getInvoices: handleAccountUpdate,
  getInvoiceDetails: getInvoices,
  getPaymentAccounts: getSavedBankAccounts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceAccountInformation);
