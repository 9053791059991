import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectHasLinkedAccount,
  selectShowLinkAccountModal,
} from 'selectors/userSelectors';
import LinkAccountPrompt from 'components/LinkAccount/LinkAccountPrompt';
import { startCreditApplication } from 'actions/creditActions';
import {
  selectAccounts,
  selectSelectedAccount,
} from 'selectors/accountSelectors';
import isEmpty from 'lodash/isEmpty';
import { resolveOcaUrl, resolveOcaTemplateId } from '../resolveOcaUrl';

const CreditApplicationRedirect = () => {
  const location = useLocation();
  const hasLinkedAccount = useSelector(selectHasLinkedAccount());
  const showLinkAccountModal = useSelector(selectShowLinkAccountModal());
  const ocaUrl = resolveOcaUrl();
  const ocaTemplateId = resolveOcaTemplateId();
  const redirectPath = location.pathname.replace('/oca', '');
  const redirectUrl = `${redirectPath}${location.search}`;
  const dispatch = useDispatch();
  const selectedAccount = useSelector(selectSelectedAccount());
  const { status } = useSelector(selectAccounts());

  let fullOcaUrl;

  if (/apply-now/.test(location.pathname)) {
    fullOcaUrl = `${ocaUrl}?continue=form-new/${ocaTemplateId}`;
  } else {
    fullOcaUrl = `${ocaUrl}${redirectUrl}`;
  }

  // need to support redirect for cases where
  // user closes the prompt rather than dismissing
  const redirect = useCallback(() => {
    window.location.replace(fullOcaUrl);
  }, [fullOcaUrl]);

  /*
  This function dispatches an action that is used to track the start of a credit application and to redirect the user.
  The redirect happens as a callback function of the mixpanel tracking, to ensure it completes before redirecting.
  */
  const redirectAndTrack = useCallback(() => {
    if (/apply-now/.test(location.pathname)) {
      dispatch(
        startCreditApplication(selectedAccount, location.pathname, fullOcaUrl)
      );
    } else {
      redirect();
    }
  }, [dispatch, fullOcaUrl, location.pathname, redirect, selectedAccount]);

  const doesNotNeedLinkAccountPrompt = showLinkAccountModal === false;
  const hasLinkedAccountLoaded =
    hasLinkedAccount && status === 'OK' && !isEmpty(selectedAccount);

  useEffect(() => {
    if (hasLinkedAccountLoaded || doesNotNeedLinkAccountPrompt) {
      redirectAndTrack();
    }
  }, [hasLinkedAccountLoaded, doesNotNeedLinkAccountPrompt, redirectAndTrack]);

  if (hasLinkedAccount) {
    return null;
  }
  return <LinkAccountPrompt onClose={redirectAndTrack} />;
};

export default CreditApplicationRedirect;
