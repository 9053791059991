export const DEV = 'DEV';
export const SIT = 'SIT';
export const PRE = 'PRE';
export const PROD = 'PROD';

const envs = {
  localhost: DEV,
  'my.dev.nutrienagsolutions.com': DEV,
  'my.sit.nutrienagsolutions.com': SIT,
  'my.pre.nutrienagsolutions.com': PRE,
  'my.demo.nutrienagsolutions.com': PRE,
  'my.nutrienagsolutions.com': PROD,
};

const resolveApiEnv = () =>
  process.env.REACT_APP_ENVIRONMENT || envs[window.location.hostname] || DEV;

export default resolveApiEnv;
