import { combineReducers } from 'redux';
import {
  defaultRole,
  defaultPermissions,
} from 'shared/config/defaultAccessControl';
import {
  ACCESS_DELEGATION,
  DELEGATING_ACCESS,
  DELEGATE_ACCESS_SUCCESS,
  DELEGATE_ACCESS_FAILURE,
  RESET_ACCESS_DELEGATION,
  UPDATE_PERMISSIONS,
  UPDATE_ROLES,
} from './rbacConstants';

export const roles = (state = defaultRole, action) => {
  switch (action.type) {
    case UPDATE_ROLES:
      return action.payload;
    default:
      return state;
  }
};

export const permissions = (state = defaultPermissions, action) => {
  switch (action.type) {
    case UPDATE_PERMISSIONS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const status = (state = 'ACCESS_DELEGATION', action) => {
  switch (action.type) {
    case ACCESS_DELEGATION:
      return ACCESS_DELEGATION;
    case DELEGATING_ACCESS:
      return DELEGATING_ACCESS;
    case DELEGATE_ACCESS_SUCCESS:
      return DELEGATE_ACCESS_SUCCESS;
    case DELEGATE_ACCESS_FAILURE:
      return DELEGATE_ACCESS_FAILURE;
    case RESET_ACCESS_DELEGATION:
      return ACCESS_DELEGATION;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case DELEGATE_ACCESS_FAILURE:
      return action.error;
    case ACCESS_DELEGATION:
    case DELEGATING_ACCESS:
    case DELEGATE_ACCESS_SUCCESS:
    case RESET_ACCESS_DELEGATION:
      return null;
    default:
      return state;
  }
};

export default combineReducers({ roles, permissions, status, error });
