import {
  SET_TOP_PRODUCTS_OK,
  SET_TOP_PRODUCTS_LOADING,
  SET_TOP_PRODUCTS_ERROR,
  SET_TOP_PRODUCTS_EMPTY,
} from './TopProductsActions';

const recentProducts = (
  state = { status: 'LOADING', list: [], error: '' },
  action
) => {
  switch (action.type) {
    case SET_TOP_PRODUCTS_OK:
      return { status: 'OK', list: [...action.payload], error: '' };
    case SET_TOP_PRODUCTS_LOADING:
      return { status: 'LOADING', list: [], error: '' };
    case SET_TOP_PRODUCTS_ERROR:
      return { status: 'ERROR', list: [], error: action.payload };
    case SET_TOP_PRODUCTS_EMPTY:
      return { status: 'EMPTY', list: [], error: action.payload };
    default:
      return state;
  }
};

export default recentProducts;
