import React from 'react';

import './BranchDetails.scss';

const BranchDetails = (props) => {
  const {
    cart: {
      branchAddress: { address },
    },
  } = props;

  let branchDetails;

  if (address) {
    branchDetails = (
      <div data-test="branch-wrapper" className="branch-wrapper">
        <div>
          <div className="branch-details__subheader">Branch #</div>
          <div className="branch-details__detail">{address.data.id}</div>
        </div>
        <div>
          <div className="branch-details__subheader">Branch Name</div>
          <div className="branch-details__detail">{address.data.name}</div>
        </div>
        <div>
          <div className="branch-details__subheader">Address</div>
          <div className="branch-details__detail">
            {address.data.address}, {address.data.city},{' '}
            {address.data.stateorProvince}, {address.data.zipPostalCode}
          </div>
        </div>
        <div>
          <div className="branch-details__subheader">Phone</div>
          <div className="branch-details__detail">{address.data.phone}</div>
        </div>
      </div>
    );
  } else {
    branchDetails = <div />;
  }
  return branchDetails;
};

export default BranchDetails;
