import { INVOICE_LOADING, INVOICE_LOADED } from '../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case INVOICE_LOADING:
      return {};
    case INVOICE_LOADED:
      return action.error ? {} : action.payload;
    default:
      return state;
  }
};
