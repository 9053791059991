import React from 'react';
import {
  AccountDescriptionText,
  DateFormatter,
  DataList,
  DataItem,
} from 'shared/ui';
import hideUndefined from 'shared/utils/hideUndefined/hideUndefined';
import { invoicePropType, accountPropType } from '../invoicePropTypes';

const getDeliveryDateBlock = (deliveryDate) =>
  deliveryDate === 'multiple items'
    ? deliveryDate
    : DateFormatter(deliveryDate);

const InvoiceAccountInfo = ({ account, invoice }) => (
  <div className="invoice-account-info-container">
    <DataList>
      <DataItem
        title="Account"
        data={<AccountDescriptionText account={account} />}
        full
      />
      <DataItem
        title="Delivery Date"
        data={hideUndefined(getDeliveryDateBlock(invoice.deliveryDate))}
      />
      <DataItem
        title="Order Number"
        data={`${hideUndefined(invoice.orderNumber)}`}
      />
      <DataItem title="Sales Rep" data={`${hideUndefined(invoice.salesRep)}`} />
      <DataItem title="Ship Via" data={`${hideUndefined(invoice.shipVia)}`} />
      <DataItem
        title="Purchase Order Number"
        data={`${invoice.poNumber || 'N/A'}`}
      />
    </DataList>
  </div>
);

InvoiceAccountInfo.propTypes = {
  invoice: invoicePropType.isRequired,
  account: accountPropType.isRequired,
};

export default InvoiceAccountInfo;
