import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import resolveApiEnv from 'shared/config/resolveApiEnv';
import axios from 'shared/lib/cxhApiService';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import Form from 'shared/ui/MaterialFormCard/Form';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ChevronRight from '@material-ui/icons/ChevronRight';
import WarningIcon from '@material-ui/icons/Warning';
import { formatPhoneNumber } from 'utils/phoneUtils';
import { validateEmail } from 'shared/utils/formValidation';
import PropTypes from 'prop-types';
import UserPropType from 'shared/config/userPropType';
import classNames from 'classnames';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import { SupportNumber, SupportEmail } from 'shared/ui/SupportInfo';
import {
  SUBJECT_REQUIRED,
  TEN_OR_MORE_CHARACTERS_REQUIRED,
  VALID_EMAIL_REQUIRED,
  LAST_NAME_REQUIRED,
  FIRST_NAME_REQUIRED,
  FILL_OUT_HIGHLIGHTED_FIELDS,
} from './ContactConstants';
import styles from './styles';

class ContactForm extends Component {
  state = {
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    email: this.props.user.email,
    phone: this.props.user.phoneNumber,
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    subject: '',
    subjectError: '',
    message: '',
    messageError: '',
    showOverallError: false,
    successfullySubmitted: false,
    overallErrorMessage: FILL_OUT_HIGHLIGHTED_FIELDS,
    recaptchaRef: React.createRef(),
  };

  componentDidMount() {
    if (
      !this.props.user.firstName ||
      !this.props.user.lastName ||
      !this.props.user.email
    ) {
      this.props.fetchUser().then((response) => {
        this.setUserInfo(response.data);
      });
    }
  }

  setUserInfo = (userInfo) => {
    if (userInfo === undefined || userInfo === null) return;
    this.setState({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
    });
    if (userInfo.phoneNumber !== null) {
      this.setState({
        phone: userInfo.phoneNumber,
      });
    }
  };

  handleChange = (e) => {
    const { target } = e;
    const { name } = target;

    this.setState({
      [name]: target.value,
    });
  };

  hasErrorOrEmptyField = () => {
    if (
      this.state.firstNameError === '' &&
      this.state.lastNameError === '' &&
      this.state.emailError === '' &&
      this.state.messageError === '' &&
      this.state.subjectError === '' &&
      this.state.firstName !== '' &&
      this.state.lastName !== '' &&
      this.state.email !== '' &&
      this.state.message !== '' &&
      this.state.subject !== ''
    ) {
      return false;
    }
    return true;
  };

  determineClasses = (errorState) => {
    const { classes } = this.props;
    const classObject = {
      input: classes.feedbackInput,
    };

    if (errorState !== '') {
      classObject.input = classes.redBorder;
    }

    classObject.textField = classes.feedbackTextField;
    return classObject;
  };

  formatPhone = () => {
    this.setState({ phone: formatPhoneNumber(this.state.phone) });
  };

  resolveApiEnvironment = () => resolveApiEnv();

  applyFormErrors = () => {
    if (this.state.email === '' || this.state.email === undefined) {
      this.setState({ emailError: VALID_EMAIL_REQUIRED });
    }
    if (this.state.firstName === '' || this.state.firstName === undefined) {
      this.setState({ firstNameError: FIRST_NAME_REQUIRED });
    }
    if (this.state.lastName === '' || this.state.lastName === undefined) {
      this.setState({ lastNameError: LAST_NAME_REQUIRED });
    }
    if (this.state.subject === '') {
      this.setState({ subjectError: SUBJECT_REQUIRED });
    }
    if (this.state.message === '') {
      this.setState({ messageError: TEN_OR_MORE_CHARACTERS_REQUIRED });
    }

    this.setState({ overallErrorMessage: FILL_OUT_HIGHLIGHTED_FIELDS });
    this.setState({ showOverallError: true });
  };

  sendEmail = (googleRecaptchaKey) => {
    if (this.hasErrorOrEmptyField()) this.applyFormErrors();
    // TODO: this should be in a service call, and it should be part of an action that is dispatched
    return axios
      .post('/v1/email/support', {
        parameters: {
          googleRecaptchaKey,
          isFeedback: false,
          subject: this.state.subject,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone || '',
          message: this.state.message,
        },
      })
      .catch(() => {
        this.setState({
          overallErrorMessage:
            'Oops, something went wrong. Try again in a few minutes.',
        });
        this.setState({ showOverallError: true });
        this.state.recaptchaRef.current.reset();
      })
      .then(() => {
        if (!this.state.showOverallError) {
          this.setState({ successfullySubmitted: true });
        }
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.hasErrorOrEmptyField()) {
      this.setState({ showOverallError: false });
      this.state.recaptchaRef.current.execute();
    } else {
      this.applyFormErrors();
    }
  };

  validateInput = (e) => {
    const { value, name } = e.target;

    if (name === 'email' && value && !validateEmail(value)) {
      this.setState({ emailError: VALID_EMAIL_REQUIRED });
    } else if (name === 'email' && value.trim() === '') {
      this.setState({ emailError: VALID_EMAIL_REQUIRED });
    } else if (name === 'email' && value && validateEmail(value)) {
      this.setState({ emailError: '' });
    }

    if (name === 'firstName' && value.length < 1) {
      this.setState({ firstNameError: FIRST_NAME_REQUIRED });
    } else if (name === 'firstName' && value.length >= 1) {
      this.setState({ firstNameError: '' });
    }

    if (name === 'lastName' && value.length < 1) {
      this.setState({ lastNameError: LAST_NAME_REQUIRED });
    } else if (name === 'lastName' && value.length >= 1) {
      this.setState({ lastNameError: '' });
    }

    if (name === 'subject' && value.length < 1) {
      this.setState({ subjectError: SUBJECT_REQUIRED });
    } else if (name === 'subject' && value.length >= 1) {
      this.setState({ subjectError: '' });
    }

    if (name === 'message' && value.length < 10) {
      this.setState({ messageError: TEN_OR_MORE_CHARACTERS_REQUIRED });
    } else if (name === 'message' && value.length >= 10) {
      this.setState({ messageError: '' });
    }
  };

  render() {
    const { classes } = this.props;

    const contactConfirmation = (
      <div className="contact__confirmation">
        <div>
          <div className="contact__title-container">
            <h2>Thank you for contacting us!</h2>
            <h4 className={classes.contactSubtitle}>
              You are helping us make our site better.
            </h4>
          </div>
          <Link className={classes.contactBackLink} to="/">
            Back to Home <ChevronRight style={{ fontSize: '16px' }} />
          </Link>
        </div>
      </div>
    );

    if (this.state.successfullySubmitted) {
      window.scroll(0, 0);
      return contactConfirmation;
    }

    const contactForm = (
      <div className="contact-form">
        <h4>Get Support</h4>
        <p className={classes.p}>
          Helpful experts at Nutrien Ag Solutions are available to answer your
          questions.
        </p>
        <div className={classes.supportContainer}>
          <SupportNumber />
          <SupportEmail />
        </div>
        <br />
        <h4>Contact Us Directly</h4>
        <p className={classes.p}>
          How can we help? Please fill out this form for our support team.
        </p>
        {this.state.showOverallError ? (
          <div
            className="overall-error-container"
            style={{
              border: '1px solid #D24242',
              backgroundColor: '#feeded',
            }}
          >
            <WarningIcon className="feedback__overall-warning-icon" />
            <span className="feedback__overall-error-message">
              {this.state.overallErrorMessage}
            </span>
          </div>
        ) : (
          ''
        )}
        <Form onSubmit={this.handleSubmit}>
          <FormTextField
            handleChange={this.handleChange}
            value={this.state.firstName}
            title="First Name"
            name="firstName"
            type="text"
            classes={this.determineClasses(this.state.firstNameError)}
            error={this.state.firstNameError}
            onBlur={this.validateInput}
            autocomplete="firstName"
          />
          <FormTextField
            handleChange={this.handleChange}
            title="Last Name"
            value={this.state.lastName}
            name="lastName"
            type="text"
            classes={this.determineClasses(this.state.lastNameError)}
            error={this.state.lastNameError}
            onBlur={this.validateInput}
            autocomplete="lastName"
          />
          <FormTextField
            handleChange={this.handleChange}
            title="Email"
            value={this.state.email}
            name="email"
            type="text"
            classes={this.determineClasses(this.state.emailError)}
            error={this.state.emailError}
            onBlur={this.validateInput}
            autocomplete="email"
          />
          <FormTextField
            name="phone"
            title="Phone Number"
            subtitle="Optional"
            placeholder="(   ) ___-____"
            value={this.state.phone}
            handleChange={this.handleChange}
            onBlur={this.formatPhone}
            classes={{
              input: classes.feedbackInput,
              textField: classes.feedbackTextField,
            }}
            autocomplete="phoneNumber"
          />
          <FormTextField
            handleChange={this.handleChange}
            title="Subject"
            value={this.state.subject}
            name="subject"
            type="text"
            classes={this.determineClasses(this.state.subjectError)}
            error={this.state.subjectError}
            onBlur={this.validateInput}
            autocomplete="subject"
          />
          <div className={classes.messageContainer}>
            <label htmlFor="message" className="feedback__message-label">
              Message
            </label>
            <textarea
              name="message"
              value={this.state.message}
              onChange={this.handleChange}
              className={classNames({
                [`${classes.redBorder}`]: this.state.messageError !== '',
                [`${classes.messageTextArea}`]: true,
              })}
              onBlur={this.validateInput}
              placeholder="Provide a short response"
            />
            {this.state.messageError === '' ? (
              ''
            ) : (
              <span className="message-error">{this.state.messageError}</span>
            )}
          </div>
          <ReCAPTCHA
            ref={this.state.recaptchaRef}
            size="invisible"
            sitekey="6LcUCXMUAAAAAHUK3XUmB7yynvF0aN_2UDt82oRJ"
            onChange={this.sendEmail}
            badge="bottomleft"
          />
          <MaterialButton
            type="submit"
            value="Submit"
            className="feedback__submit-button"
            style={{ width: '127px' }}
          />
        </Form>
      </div>
    );

    return contactForm;
  }
}

ContactForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fetchUser: PropTypes.func.isRequired,
  user: UserPropType,
};

ContactForm.defaultProps = {
  user: {},
};

export default withStyles(styles)(ContactForm);
export { ContactForm };
