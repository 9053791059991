import { connect } from 'react-redux';
import { getQueryStringFromRouter } from 'shared/utils';
import { push } from 'connected-react-router';
import Invoices from './InvoicesWrapper';
import {
  handleAccountUpdate,
  updateAccountTab,
  clearSelectedInvoices,
  clearFilteredProduct,
} from './actions';

export const mapStateToProps = (state) => {
  const queryString = getQueryStringFromRouter(state.router);

  return {
    currentPage: parseInt(queryString.page || 1, 10),
    statusFilter: queryString.typeFilter || 'All',
    queryString,
    invoicesStatus: state.invoices.status,
    account: state.selectedAccount,
    accounts: state.accounts,
    invoices: state.invoices,
    filteredProductList: state.invoices.filteredProducts.list,
    invoicesLoaded: !!state.invoices.list,
  };
};

export const mapDispatchToProps = {
  push,
  handleAccountUpdate,
  updateAccountTab,
  clearSelectedInvoices,
  clearFilteredProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
