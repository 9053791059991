import React from 'react';
import { arrayOf, bool, node, oneOfType } from 'prop-types';

import './Modal.scss';

const Modal = ({ children, open }) =>
  open && (
    <div className="modal__container" data-test="modal-wrapper">
      {children}
    </div>
  );

Modal.propTypes = {
  open: bool.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

Modal.defaultProps = {
  open: false,
};

export default Modal;
