import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import Page404 from 'components/Page404';
import PermitsPage from './PermitsPage';
import PertmitDetailsPage from './PermitDetailsPage';

const Permits = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/`}>
      <PermitsPage />
    </Route>
    <Route exact path={`${match.path}/:permitId/growers/:growerName`}>
      <PertmitDetailsPage />
    </Route>
    <Route>
      <Page404 />
    </Route>
  </Switch>
);

Permits.defaultProps = {};

Permits.propTypes = { match: ReactRouterPropTypes.match.isRequired };

export default Permits;
