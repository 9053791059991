import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { isGFA } from 'shared/utils/NutrienGFA';
import makeNativeRequest from 'shared/utils/makeNativeRequest';
import getNativeContainer from 'shared/utils/getNativeContainer';
import { setGFAVersion } from 'components/NutrienGFA/actions';
import useIsAuth0Active from 'hooks/useIsAuth0Active';

const useNativeAuth = () => {
  const container = getNativeContainer();
  const dispatch = useDispatch();
  const [accessToken, setAccessToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  const isAuth0Active = useIsAuth0Active();

  const getAccessTokenSilently = useCallback(
    async (options) => {
      if (accessToken) {
        return accessToken;
      }

      setIsLoading(true);
      try {
        const {
          accessToken: nativeAccessToken,
        } = await makeNativeRequest('getAccessTokenSilently', { options });
        setAccessToken(nativeAccessToken);
        setIsLoading(false);
        setIsAuthenticated(true);
        return nativeAccessToken;
      } catch (error) {
        setIsLoading(false);
        setIsAuthenticated(false);
        return null;
      }
    },
    [accessToken]
  );

  const logout = useCallback(async () => {
    try {
      await makeNativeRequest('logout');
      // rely on the native wrapper webview refresh to clear this hooks state
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const handleMessage = (e) => {
      if (typeof e.data === 'string') {
        const data = JSON.parse(e.data);
        switch (data.name) {
          case 'logged-in': {
            const { auth } = data.payload;
            const idToken = jwtDecode(auth.idToken);
            setAccessToken(auth.accessToken);
            setUser(idToken);
            setIsAuthenticated(true);
            break;
          }
          case 'set_gfa_version':
            dispatch(setGFAVersion(data.payload));
            break;
          default:
        }
      }
    };
    container.addEventListener('message', handleMessage);

    return () => container.removeEventListener('message', handleMessage);
  }, [container, dispatch]);

  if (!isAuth0Active) {
    // do not override auth utils if Auth0 is not supported or if not rendered in Native App
    return {};
  }

  return {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect: () => {},
    logout,
    user,
  };
};

export default !isGFA({}) ? () => ({}) : useNativeAuth;
