// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';

import addressTransformer from '../../../../utils/transformers/addressTransformer';

import saveAddressSuccess from './saveAddressSuccess';
import saveAddressFailure from './saveAddressFailure';

export default (address, defaultAccountId) => (dispatch) =>
  // TODO: this should be its own service method
  ecommApiService
    .post('/v2/shipping-addresses', address, {
      headers: {
        'account-id': defaultAccountId,
      },
    })
    .then((res) => {
      const address = addressTransformer(res.data);
      dispatch(saveAddressSuccess(address));
      return res;
    })
    .catch((error) => {
      dispatch(saveAddressFailure(error));
      return error;
    });
