import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import ProductIngredientPercent from './ProductIngredientPercent';

import './ActiveIngredients.scss';

const ActiveIngredients = ({ activeIngredients }) => {
  if (isEmpty(activeIngredients)) {
    return null;
  }

  const listItems = map(activeIngredients, (ingredient) => (
    <li key={ingredient.name}>
      <div
        className="product-detail__ingredient"
        data-test="product-detail__ingredient"
      >
        <div
          className={`${
            ingredient.percentage ? 'product-detail__ingredient-name' : ''
          }`}
        >
          {ingredient.name.toUpperCase()}
        </div>
        {ingredient.percentage && (
          <ProductIngredientPercent ingredient={ingredient} />
        )}
      </div>
    </li>
  ));

  return (
    <div className="active-ingredients" data-test="active-ingredients">
      <h2
        className="active-ingredients__label"
        data-test="active-ingredients-label"
      >
        Active Ingredient
      </h2>
      <ul
        className="active-ingredients__list"
        data-test="active-ingredients-list"
      >
        {listItems}
      </ul>
    </div>
  );
};

ActiveIngredients.propTypes = {
  activeIngredients: arrayOf(shape({ name: string, percentage: number })),
};

ActiveIngredients.defaultProps = {
  activeIngredients: [],
};

export default ActiveIngredients;
