import get from 'lodash/get';
import {
  gtmUpdatingCart,
  gtmAddToCart,
} from 'store/middleware/TagManager/gtmActions';

// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';
import updateCartFailure from './updateCartFailure';
import updateCartSuccess from './updateCartSuccess';
import updatingCart from './updatingCart';
import cartItemTransformer from '../../../../utils/transformers/cartItemTransformer';

export default (newItemData, itemPreUpdate, options, position) => (dispatch) =>
  ecommApiService
    .put('/v2/cart-items', newItemData, {
      headers: {
        'account-id': newItemData.accountId,
        'branch-id': newItemData.branchId,
        'user-id': newItemData.userId,
      },
    })
    .then((res) => {
      dispatch(gtmUpdatingCart(newItemData, itemPreUpdate, options));
      const item = res.data.data;
      const cartItemFormattedForGTM = {
        product: get(itemPreUpdate, 'product'),
        variant: get(itemPreUpdate, 'variant'),
        position,
        variantSku: get(item, 'variantId'),
        variantDescription: get(itemPreUpdate, 'variant.digital_variant_name'),
        variantIsPriced: !!itemPreUpdate.net_price,
      };
      cartItemFormattedForGTM.product.total_volume = get(
        item,
        'totalVolume',
        ''
      );
      dispatch(gtmAddToCart(cartItemFormattedForGTM, get(options, 'origin')));

      dispatch(updateCartSuccess(cartItemTransformer(res.data)));
      dispatch(updatingCart(false));
      return res;
    })
    .catch((error) => {
      dispatch(updateCartFailure(error));
      dispatch(updatingCart(false));
      return error;
    });
