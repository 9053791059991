import React from 'react';
import PropTypes from 'prop-types';

const PermitsIcon = ({ className }) => (
  <svg
    width="71px"
    height="62px"
    viewBox="0 0 71 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M70.705,19.918 L70.704,19.918 L70.704,26.778 L29.984,26.778 L29.984,19.918 L70.705,19.918 Z M70.705,61.082 L9.623,61.082 L9.623,54.222 L70.705,54.222 L70.705,61.082 Z M70.705,37.07 L70.705,43.93 L9.623,43.93 L9.623,37.07 L70.705,37.07 Z M10.997,20.347 L31.192,0.112 L33.882,2.81 L10.997,25.759 L0.337,15.068 L3.045,12.372 L10.997,20.347 Z" />
  </svg>
);

PermitsIcon.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ),
  ]),
};

PermitsIcon.defaultProps = {
  className: undefined,
};

export default PermitsIcon;
