export const accountStatuses = {
  OK: 'OK',
  ERR: 'err',
  LOADING: 'loading',
};

export const SET_ACCOUNT = 'SET_ACCOUNT';

export const yearLowerLimit = 2016;

export const availableYears = () => {
  let currentYear = new Date().getFullYear();
  const years = [];
  for (currentYear; currentYear >= yearLowerLimit; currentYear -= 1) {
    years.push(currentYear);
  }
  return years;
};

export const prepayTypeOptions = [
  { name: 'Regular', code: 'Regular' },
  { name: 'Incentive', code: 'Incentive' },
];
