import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MINIMUM_BULK_QUANTITY from 'shared/constants/minimumBulkQuantity';
import TopProductsPropType from '../TopProductsPropType';
import ReorderOrRequestInfoButton from '../../../ProductHistory/ProductHistoryPage/ProductHistoryTable/ReorderOrRequestInfoButton';
import {
  removeNegativeCosts,
  sortByDescendingCost,
} from './TopProductsTableRowUtils';

import './TopProductsTable.scss';

const TopProductsTableRow = ({
  topProducts: { list },
  gtmViewTopProductSDS,
  selectedAccount,
}) => {
  const { globalEcommerce, canadaReadOnly } = useFlags();
  const costSortedProductList = sortByDescendingCost(list);
  const costSortedProductListWithoutNegativeCosts = removeNegativeCosts(
    costSortedProductList
  );

  return costSortedProductListWithoutNegativeCosts.map((product) => (
    <tr key={product.productNumber} className="top-products__table-row">
      <td className="top-products__table-mobile-display-none">
        {product.productNumber}
      </td>
      <td className="top-products__table-row-item">
        <span className="top-products__table-item--mobile-display">Name</span>
        {product.description}
      </td>
      <td className="top-products__table-row-item">
        <span className="top-products__table-item--mobile-display">
          SDS &amp; Labels
        </span>
        {product.sdsAndLabels ? (
          <Link
            data-test="topProductsSdsAndLabelsLink"
            to={`${product.sdsAndLabels.substring(
              0,
              product.sdsAndLabels.length - 1
            )}?acct=${selectedAccount.number}`}
            onClick={gtmViewTopProductSDS}
          >
            SDS & Labels
          </Link>
        ) : (
          'Not available'
        )}
      </td>
      <td className="top-products__table-row-item">
        <span className="top-products__table-item--mobile-display">
          Quantity
        </span>
        {`${product.quantity} ${product.uom}`}
      </td>
      <td className="top-products__table-row-item">
        <span className="top-products__table-item--mobile-display">Cost</span>
        <span data-test="product-cost-format">
          {`$${product.cost.toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
          })}`}
        </span>
      </td>
      <td className="top-products__table-row-item">
        <span className="top-products__table-item--mobile-display">
          Last Invoiced
        </span>
        {moment(product.latestInvoiceDate).format('MM/DD/YY')}
      </td>
      {globalEcommerce && (
        <td className="top-products__table-row-item top-products__table-button">
          {canadaReadOnly ? null : (
            <ReorderOrRequestInfoButton
              data-test="reorder-button"
              extended={product.extended}
              productNumber={product.productNumber}
              shelf={product.shelf}
              description={product.description}
              quantity={
                product.isBulk ? MINIMUM_BULK_QUANTITY : product.quantity
              }
              unitOfMeasure={product.uom}
              status={product.status}
            />
          )}
        </td>
      )}
    </tr>
  ));
};

TopProductsTableRow.propTypes = {
  topProducts: TopProductsPropType.isRequired,
  gtmViewTopProductSDS: PropTypes.func.isRequired,
};

export default TopProductsTableRow;
