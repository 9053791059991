import {
  RESET_PASSWORD_RESET_STATUS,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
} from './passwordResetConstants';

const passwordReset = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_RESET_SUCCESS:
      return { payload: PASSWORD_RESET_SUCCESS };
    case PASSWORD_RESET_FAILURE:
      return action.error.response;
    case RESET_PASSWORD_RESET_STATUS:
      return {};
    default:
      return state;
  }
};

export default passwordReset;
