import * as actionTypes from '../../actions/constants';

export const initialContractedProductsState = {
  data: [],
  loading: true,
  error: null,
};

const {
  GET_CONTRACTED_PRODUCTS_SUCCESS,
  GET_CONTRACTED_PRODUCTS_FAILURE,
  LOADING_CONTRACTED_PRODUCTS,
} = actionTypes;

export default (state = initialContractedProductsState, action) => {
  switch (action.type) {
    case LOADING_CONTRACTED_PRODUCTS: {
      const { loading } = action;
      return { ...state, loading, data: [], error: null };
    }
    case GET_CONTRACTED_PRODUCTS_SUCCESS: {
      const { data } = action;
      return { ...state, data, loading: false, error: null };
    }
    case GET_CONTRACTED_PRODUCTS_FAILURE: {
      const { error } = action;
      return { ...state, error, loading: false, data: [] };
    }
    default:
      return state;
  }
};
