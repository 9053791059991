import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageNotification from 'shared/ui/NotificationHelper';
import PermitsTable from '../PermitsTable';
import PageHeader from '../../../shared/ui/PageHeader/PageHeader';

const PermitsPage = () => {
  const { usaSapLicenses: restrictPermitsTable } = useFlags();

  return (
    <div id="permits-page">
      <PageHeader
        title="Permits"
        breadcrumbs={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'Permits',
          },
        ]}
      />
      {restrictPermitsTable ? (
        <PageNotification
          link="/"
          linkDescription="Return Home"
          message="While the Permits Page is unavailable, please contact your Crop Consultant or local branch for information on product permits."
          type="WARNING"
        />
      ) : (
        <PermitsTable className="permits-table" />
      )}
    </div>
  );
};

PermitsPage.propTypes = {};

export default PermitsPage;
