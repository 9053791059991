import downloadFile from 'shared/utils/DownloadFile';
import * as invoicesService from '../invoicesService';

import exportInvoiceError from './exportInvoiceError';
import exportInvoiceStart from './exportInvoiceStart';
import exportInvoiceSuccess from './exportInvoiceSuccess';

export default (fileType, accountId) => (dispatch) => {
  dispatch(exportInvoiceStart(fileType));

  return invoicesService
    .getInvoiceSummariesAsCSV(accountId, fileType)
    .then((data) => {
      downloadFile(data, `account-${accountId}.${fileType}`);
      dispatch(exportInvoiceSuccess(fileType));
    })
    .catch((error) => {
      dispatch(exportInvoiceError(error));
      return Promise.reject(error); // Allows other listeners to catch if they want.
    });
};
