export const GET_CUSTOMER_ORDERS_SUCCESS = 'GET_CUSTOMER_ORDERS_SUCCESS';
export const GET_CUSTOMER_ORDERS_FAILURE = 'GET_CUSTOMER_ORDERS_FAILURE';
export const LOADING_CUSTOMER_ORDERS = 'LOADING_CUSTOMER_ORDERS';

export const GET_OPEN_CONTRACTS_SUCCESS = 'GET_OPEN_CONTRACTS_SUCCESS';
export const GET_OPEN_CONTRACTS_FAILURE = 'GET_OPEN_CONTRACTS_FAILURE';
export const LOADING_OPEN_CONTRACTS = 'LOADING_OPEN_CONTRACTS';
export const GET_CONTRACT_DETAILS_SUCCESS = 'GET_CONTRACT_DETAILS_SUCCESS';
export const GET_CONTRACT_DETAILS_FAILURE = 'GET_CONTRACT_DETAILS_FAILURE';
export const LOADING_CONTRACT_DETAILS = 'LOADING_CONTRACT_DETAILS';

export const SET_SELECTED_DOCUMENTS_ACCOUNT = 'SET_SELECTED_DOCUMENTS_ACCOUNT';
