const SiteRedirect = () => {
  const host = window.location.hostname;
  const urls = {
    'myportal.dev.cpsagu.com': 'https://my.dev.nutrienagsolutions.com',
    'myportal.sit.cpsagu.com': 'https://my.sit.nutrienagsolutions.com',
    'myportal.pre.cpsagu.com': 'https://my.pre.nutrienagsolutions.com',
    'myportal.demo.cpsagu.com': 'https://my.demo.nutrienagsolutions.com',
    'myportal.cpsagu.com': 'https://my.nutrienagsolutions.com',
  };
  return urls[host] ? window.location.replace(urls[host]) : null;
};

export default SiteRedirect;
