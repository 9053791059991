import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  cell: {
    borderBottom: `1px solid ${theme.palette.grey.A200}`,
    padding: theme.spacing(4),
  },
}));

const TableNoData = ({ message, tableCols }) => {
  const classes = useStyles();
  return (
    <TableCell colSpan={tableCols} className={classes.cell} align="center">
      {message}
    </TableCell>
  );
};

TableNoData.propTypes = {
  message: PropTypes.string.isRequired,
  tableCols: PropTypes.number,
};

TableNoData.defaultProps = {
  tableCols: 1,
};

export default TableNoData;
