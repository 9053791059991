import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import PageNotification from 'shared/ui/NotificationHelper';
import { LicensesTable } from '../../../Licenses';
import styles from '../../styles';
import './LicensingTab.scss';

const LicensingTab = (props) => {
  const { classes } = props;
  const { usaSapLicenses: restrictLicensesTable } = useFlags();

  return (
    <>
      <div className={classes.sectionContainer}>
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Licensing</div>
        </div>
      </div>
      {restrictLicensesTable ? (
        <PageNotification
          className="licensing-restrict_usa-sap"
          data-test="licensing-restrict__notification--usa-sap-license"
          link="/"
          linkDescription="Return Home"
          message="While the Licenses Page is unavailable, please contact your Crop Consultant or local branch for information on product licenses."
          type="WARNING"
        />
      ) : (
        <LicensesTable data-test="licensing-table__licensing-tab--usa-sap-license" />
      )}
    </>
  );
};

LicensingTab.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(LicensingTab);
