import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OnClickOutside extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.clickOutsideHandler();
    }
  };

  render() {
    const { children } = this.props;

    return <div ref={this.setWrapperRef}>{children}</div>;
  }
}

OnClickOutside.propTypes = {
  children: PropTypes.element.isRequired,
  clickOutsideHandler: PropTypes.func.isRequired,
};

export default OnClickOutside;
