import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import './HelperContent.css';

const PasswordSuggestions = ({ classes }) => (
  <div className="password-suggestion__modal__helper__content">
    <p className={classes.boldText}>
      <b>Examples of commonly used passwords:</b>
    </p>
    <ul>
      <li className={classes.listElement}>P@ssw0rd</li>
      <li className={classes.listElement}>football</li>
      <li className={classes.listElement}>1234567890</li>
      <li className={classes.listElement}>password</li>
      <li className={classes.listElement}>qwertyuio</li>
    </ul>
    <p className={classes.boldText}>
      <b>Tips for creating a strong password:</b>
    </p>
    <ul>
      <li className={classes.listElement}>
        Do not use dictionary words or combinations of dictionary words
      </li>
      <li className={classes.listElement}>
        Do not rely on obvious substitutions (P@ssword where &apos;a&apos; is
        <br />
        <span>replaced with &apos;@&apos;)</span>
      </li>
      <li className={classes.listElement}>
        Do not include your name or email in the password
      </li>
    </ul>
  </div>
);

PasswordSuggestions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(PasswordSuggestions);
