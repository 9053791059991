import get from 'lodash/get';
import { ADD_TO_CART_FAILURE } from '../actionTypes';

export default (error) => {
  const err = get(
    error,
    'message',
    'There was an errror adding products to the cart'
  );
  return {
    type: ADD_TO_CART_FAILURE,
    error: err,
  };
};
