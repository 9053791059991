import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@nutrien/uet-react/Box';
import Card from '@nutrien/uet-react/Card';
import Button from '@nutrien/uet-react/Button';
import Link from '@nutrien/uet-react/Link';
import Typography from '@nutrien/uet-react/Typography';
import BannerImage from 'assets/nutrienFinancialBanner.png';
import SmallBannerImage from 'assets/nutrienFinancialBannerSmall.png';
import { selectEmployee } from 'selectors/employeeSelectors';
import { selectSelectedAccount } from 'selectors';
import { startCreditApplication } from 'actions/creditActions';
import { useLocation } from 'react-router-dom';
import { resolveOcaUrl, resolveOcaTemplateId } from '../resolveOcaUrl';
import useStyles from '../styles';

// This part of the CreditBanner displays a promotion for Nutrien financial
const CreditPromo = () => {
  const classes = useStyles();
  const ocaUrl = resolveOcaUrl();
  const ocaTemplateId = resolveOcaTemplateId();

  const { mirroring } = useSelector(selectEmployee());
  const disabled = mirroring.isMirroring;

  const dispatch = useDispatch();
  const account = useSelector(selectSelectedAccount());
  const { pathname } = useLocation();

  return (
    <Card className={classes.root} data-test="credit-promo-card">
      <img
        src={BannerImage}
        className={classes.largeImage}
        alt="Online Credit Application Logo"
      />
      <img
        src={SmallBannerImage}
        className={classes.smallImage}
        alt="Online Credit Application Logo"
      />
      <Box className={classes.textWrapper}>
        <Typography variant="h3">Buy Now, Pay Later!</Typography>
        <Typography className={classes.text} variant="h6">
          From monthly payment options to custom solutions tailored just for you
          - we’re here for you. Contact your local Nutrien Ag Solutions Crop
          Consultant to learn more about our competitive finance offers.
        </Typography>
        <Box className={classes.buttonWrapper}>
          {mirroring.isMirroring ? (
            <>
              <Button
                disabled={disabled}
                className={classes.button}
                variant="outlined"
              >
                View Past Applications
              </Button>
              <Button
                disabled={disabled}
                className={classes.button}
                variant="contained"
              >
                Apply Now
              </Button>
            </>
          ) : (
            <>
              <Link
                href={`${ocaUrl}?continue=list`}
                target="_blank"
                rel="noopener"
                data-test="credit-promo-past-apps-btn"
              >
                <Button className={classes.button} variant="outlined">
                  View Past Applications
                </Button>
              </Link>
              <Link
                onClick={() => {
                  // track initiate a credit application
                  dispatch(startCreditApplication(account, pathname));
                }}
                href={`${ocaUrl}?continue=form-new/${ocaTemplateId}`}
                target="_blank"
                rel="noopener"
                data-test="credit-promo-apply-now-btn"
              >
                <Button className={classes.button} variant="contained">
                  Apply Now
                </Button>
              </Link>
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default CreditPromo;
