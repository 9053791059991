import { parse as parseURL } from 'url';
import resolveApiEnv, {
  DEV,
  SIT,
  PRE,
  PROD,
} from 'shared/config/resolveApiEnv';
import resolveApiUrl from 'shared/config/resolveApiUrl';

/**
 * Returns resolveLaunchDarklyKey for env
 * @return {string} launchDarklyKey;
 */
const resolveLaunchDarklyKey = () => {
  const apiUrl = resolveApiUrl();
  const host = parseURL(apiUrl).hostname;
  const env = resolveApiEnv(host);
  const keys = {
    [DEV]: '5b43be902b999c2a15dcdc86',
    [SIT]: '5b45199b69ced52c62f8308a',
    [PRE]: '5b4519beb3a8332c65a19454',
    [PROD]: '5b43be902b999c2a15dcdc87',
  };
  return keys[env];
};

export default resolveLaunchDarklyKey;
