import { bool, number, string, func, shape } from 'prop-types';

export const productDataPropType = shape({
  attributes: shape({
    name: string,
    federallyRestricted: bool,
    percentage: number,
    common_name: string,
    associated_country: string,
  }),
});

export const variantPropType = shape({ digital_variant_name: string });

const ProductDetailPropTypes = {
  addToCart: func.isRequired,
  cart: shape({
    updatingCart: shape({
      updating: bool.isRequired,
    }).isRequired,
  }).isRequired,
  setSelectedVariant: func.isRequired,
  gtmViewPDP: func.isRequired,
  location: shape({}).isRequired,
  history: shape({}).isRequired,
  selectedAccount: shape({
    id: string,
    name: string,
    balance: number,
    due: number,
    pastDue: number,
    number: string,
    branchId: string,
    manufacturer: shape({
      name: string,
    }),
  }),
  match: shape({
    params: shape({
      id: string,
    }),
  }).isRequired,
  getProduct: func.isRequired,
  product: shape({
    loading: bool,
    loadingPrices: bool,
    data: productDataPropType,
    error: string,
    variant: variantPropType,
  }),
};

export const ProductDetailDefaultProps = {
  selectedAccount: {},
  product: {
    loading: false,
    loadingPrices: false,
    error: null,
    data: {},
  },
};

export default ProductDetailPropTypes;
