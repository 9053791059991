import React from 'react';
import PropTypes from 'prop-types';
import { volumeOrQuantity } from 'shared/utils/VolumeOrQuantity/volumeOrQuantity';
import withLocationCode from 'shared/utils/CountryCode/withLocationCode';
import CheckoutItem from '../../../containers/ReceiptItemContainer/ReceiptItemContainer';

import './ShoppingCartTable.scss';

const ShoppingCartTable = ({ cartItems, isCanada }) => (
  <table className="shopping-cart-table" data-test="shopping-cart-table">
    <tbody className="shopping-cart-table__body">
      <tr className="shopping-cart-table__row">
        <th className="shopping-cart-table__header--first">Description</th>
        <th className="shopping-cart-table__header">Pack Size</th>

        <th className="shopping-cart-table__header">
          {volumeOrQuantity(isCanada)}
        </th>
        <th className="shopping-cart-table__header">Price</th>
        <th className="shopping-cart-table__header--last">Subtotal</th>
      </tr>
      {cartItems.map((cartItem) => (
        <CheckoutItem cartItem={cartItem} key={cartItem.variant_sku} />
      ))}
    </tbody>
  </table>
);

ShoppingCartTable.propTypes = {
  cartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCanada: PropTypes.bool.isRequired,
};

export { ShoppingCartTable };

export default withLocationCode(ShoppingCartTable);
