import { errorStyling } from 'shared/styles';

const styles = {
  textField: {
    marginBottom: 25,
    width: '100%',
    maxWidth: 500,
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    display: 'block',
    width: '100%',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.14,
    letterSpacing: 'normal',
    textAlign: 'left',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#828282',
    marginLeft: 2,
  },
  helper: {
    color: '#4c9e00',
    float: 'right',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  input: {
    width: '100%',
    height: 40,
    borderRadius: 2,
    backgroundColor: '#ffffff',
    border: 'solid 1px #cccccc',
    padding: 5,
    '&:focus': { outline: 'none' },
    '&:active': { outline: 'none' },
  },
  inputWithIcon: {
    position: 'relative',
    padding: 0,
    '& span': {
      position: 'absolute',
      top: 7,
      right: 15,
      width: 25,
      height: 25,
      cursor: 'pointer',
    },
  },
  description: {
    fontSize: 13,
    fontWeight: 200,
    lineHeight: '1.15',
    color: '#828282',
    margin: '10px 0',
  },
  error: {
    ...errorStyling,
  },
};

export default styles;
