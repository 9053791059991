import accountNumberImg from 'assets/account_number.png';
import statementTokenImg from 'assets/statement_token.png';

const helpers = {};

helpers.accountNumber = {
  title: 'Account Number',
  description:
    'Your 7 digit account number is located in the top right corner of your statement.',
  src: accountNumberImg,
  altText: 'Account Number',
};

helpers.statementToken = {
  title: 'Statement Token',
  description:
    'Your Statement Token is located on the bottom right corner of your statement. It will be a combination of numbers and letters and is case sensitive.',
  src: statementTokenImg,
  altText: 'Statement Token',
};

export default helpers;
