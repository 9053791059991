import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Button, Card, useMediaQuery } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Settings from '@material-ui/icons/Settings';
import Notification from '@nutrien/uet-react/Notification';

import AccountsTable from 'shared/ui/AccountsTable';
import LinkAccountButton from 'components/LinkAccount/LinkAccountButton';
import PaymentDisabledNotification from 'shared/ui/Payments/PaymentDisabledNotification/PaymentDisabledNotificationComponent';
import UserLocationContext from '../Shop/utils/contexts/locationCodeContext';
import { CAN } from '../Shop/utils/contexts/locationCodeConstants';
import PageHeader from '../../shared/ui/PageHeader/PageHeader';
import {
  InvalidPaymentWarning,
  INVALID_USA_ACCOUNT_TEXT,
  INVALID_CANADIAN_ACCOUNT_TEXT,
  INVALID_INTERNATIONAL_ACCOUNT_TEXT,
} from '../../shared/ui/Payments/InvalidPaymentWarning';

const AccountsCard = (props) => {
  const {
    accounts,
    selectedAccount,
    renderBreadcrumbs,
    renderFooter,
    truncRows,
  } = props;
  const {
    accountsTableAccountPreferencesLink,
    payments,
    accountsPage,
    accountPaidInvoiceStamp,
    universalHeader,
    canadaReadOnly,
    canadaPayments,
    southernOhioDivision,
  } = useFlags();
  // TODO: remove usaSapAccount restrictions once SAP is rolled out to entire US market
  const usaSapAccount = accounts.list.find((account) => account.usaSapAccount);

  const invalidCanadianAccount = accounts.list?.find(
    (account) => account.invalidCanadianAccount
  );

  const invalidInternationalAccount = accounts.list?.find(
    (account) => account.invalidInternationalAccount
  );

  const shouldUseMedia = useMediaQuery('(min-width:1081px)');

  const settings = (
    <>
      {accountsTableAccountPreferencesLink &&
        (accounts.list.length > 0 ? (
          <div className="accounts-header__container-preferences">
            <Link to="profile/accounts" className="link__account-link">
              <Settings />
              {renderBreadcrumbs && (
                <span className="link__account-link-text">
                  Account Settings
                </span>
              )}
            </Link>
          </div>
        ) : null)}
    </>
  );

  const renderWithContainer = (component) => {
    return <div className="accounts__container">{component}</div>;
  };

  const hasFuelAccounts = accounts.list.some(
    (account) => account.calculatedCustomerType === 'Fuel'
  );

  const component = (
    <>
      {renderBreadcrumbs && (
        <PageHeader
          title="Accounts"
          breadcrumbs={[
            {
              link: '/',
              label: 'Home',
            },
            {
              label: 'Accounts',
            },
          ]}
        />
      )}
      <div className="accounts-header__container">
        {renderBreadcrumbs ? (
          settings
        ) : (
          <h3>
            Accounts
            {settings}
          </h3>
        )}
        {payments !== undefined && !payments && (
          <PaymentDisabledNotification noArrow data-test="payments" />
        )}
        <LinkAccountButton inlineLink />
      </div>

      {hasFuelAccounts && (
        <Notification
          variant="warning"
          data-test="accounts-card-fuel-warning"
          className="accounts-card__fuel-warning"
        >
          Fuel balances are not available on the Digital Hub. To pay fuel
          invoices please remit cheques to Nutrien Ag Solutions Canada Inc CC:
          Fuel Division 13131 Lake Fraser Drive SE Calgary Alberta T2J 7E8 or
          contact your local branch. Payment and account information is
          available for all other products.
        </Notification>
      )}

      {usaSapAccount && !southernOhioDivision && (
        <InvalidPaymentWarning
          warning={INVALID_USA_ACCOUNT_TEXT}
          testId="accounts-card-invalid-usa-account-warning"
        />
      )}

      {invalidCanadianAccount && (
        <InvalidPaymentWarning
          warning={INVALID_CANADIAN_ACCOUNT_TEXT}
          testId="accounts-card-invalid-canadian-warning"
        />
      )}

      {invalidInternationalAccount && (
        <InvalidPaymentWarning
          warning={INVALID_INTERNATIONAL_ACCOUNT_TEXT}
          testId="accounts-card-invalid-international-warning"
        />
      )}

      <AccountsTable
        {...props}
        isCanada={selectedAccount.locationCode === CAN}
        flags={{
          accountPaidInvoiceStamp,
          payments,
          universalHeader,
          canadaReadOnly,
          canadaPayments,
          southernOhioDivision,
        }}
      />

      {renderFooter === true &&
        truncRows !== null &&
        accounts.list.length > truncRows &&
        accountsPage && (
          <Box pt={1}>
            <Link
              data-test="accounts-page"
              to="accounts"
              className="link__account-link"
            >
              <Button>
                View All {accounts.list.length} Accounts
                <ChevronRight />
              </Button>
            </Link>
          </Box>
        )}
    </>
  );

  const shouldRenderCard = !renderBreadcrumbs && shouldUseMedia;

  return shouldRenderCard
    ? renderWithContainer(
        // eslint-disable-next-line react/jsx-indent
        <UserLocationContext.Provider value={selectedAccount.locationCode}>
          <Card component={Box} p={2}>
            {component}
          </Card>
        </UserLocationContext.Provider>
      )
    : renderWithContainer(component);
};

AccountsCard.propTypes = {
  truncRows: PropTypes.number,
  renderFooter: PropTypes.bool,
  renderBreadcrumbs: PropTypes.bool,
  accounts: PropTypes.shape({
    status: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        balance: PropTypes.number,
        due: PropTypes.number,
        pastDue: PropTypes.number,
        number: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  selectedAccount: PropTypes.shape({
    locationCode: PropTypes.string,
  }).isRequired,
};

AccountsCard.defaultProps = {
  truncRows: null,
  renderFooter: false,
  renderBreadcrumbs: false,
};

export default AccountsCard;
