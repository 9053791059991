import get from 'lodash/get';
import MINIMUM_BULK_QUANTITY from 'shared/constants/minimumBulkQuantity';
import { volumeOrQuantity } from 'shared/utils/VolumeOrQuantity/volumeOrQuantity';
import { getUnitOfMeasureLong } from 'components/Shop/utils/unitMeasuresLong';
import {
  isValidBulkInput,
  isValidPackageInput,
} from 'components/Shop/utils/validateInput';

const checkTotalInput = (isCanada, usesPackageUom, variant, value) => {
  let hasError = false;
  let errorMessage = '';

  const isBulk = get(variant, 'is_bulk', false);
  const validBulkInput = isValidBulkInput(
    get(variant, 'digital_variant_name', ''),
    value
  );

  const validPackageInput = isValidPackageInput(value);

  hasError =
    (isBulk && !validBulkInput) || (usesPackageUom && !validPackageInput);

  if (hasError) {
    const typeLabel = volumeOrQuantity(isCanada, usesPackageUom);
    // eslint-disable-next-line no-nested-ternary
    const typeMinimum = isBulk ? MINIMUM_BULK_QUANTITY : usesPackageUom ? 1 : 1;
    const uom = get(variant, 'unit_measure', '');
    const typeUOMLabel = getUnitOfMeasureLong(uom, typeMinimum);
    errorMessage = `${typeLabel} must be ${typeMinimum} ${typeUOMLabel} or more.`;
  }
  return { hasError, errorMessage };
};

export default checkTotalInput;
