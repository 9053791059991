import React, { useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';
import noop from 'lodash/noop';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dropdown from 'shared/ui/Dropdown';
import helper from './SortDropdownContainerHelper';
import {
  DROPDOWN_LIST_ENTRIES,
  DROPDOWN_LIST_ENTRIES_AFTER_SEARCH,
} from './SortDropdownConstants';

import './SortDropdownContainer.scss';

const SortDropdownContainer = ({ location, history }) => {
  const products = useSelector((state) => state.shop);
  const code = helper.getInitialFilterIndexFromProps(products, location);
  const options = map(
    helper.getSearch(location) && helper.isSearchActive(location)
      ? DROPDOWN_LIST_ENTRIES_AFTER_SEARCH
      : DROPDOWN_LIST_ENTRIES,
    (e) => ({
      code: `${e.index}`,
      name: e.name,
      query: e.query,
    })
  );
  const initialValue = find(options, { code: `${code}` });
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const optionsWithSelected = map(options, (option) => ({
    ...option,
    selected: selectedValue.code === option.code,
  }));

  const handleChange = (option) => {
    const selectedItem = find(options, { code: `${option.code}` });
    helper.changeRouteToSelectedSort(selectedItem, history, location);
    setSelectedValue(selectedItem);
  };

  return (
    <div className="sort-dropdown-container__dropdown">
      <Dropdown
        data-test="sort-dropdown-container-dropdown"
        selectedValue={selectedValue}
        onChange={handleChange}
        options={optionsWithSelected}
        shouldHavePlaceHolder={false}
        placeholder="Sort by"
        dropdownName="Sort by"
        drop
        validate={noop}
      />
    </div>
  );
};

SortDropdownContainer.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export { SortDropdownContainer };

export default withRouter(SortDropdownContainer);
