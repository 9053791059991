import { connect } from 'react-redux';
import {
  registerUser,
  registerExistingUser,
  resetRegistration,
} from '../registrationActions';
import ConfirmRegistration from './ConfirmRegistration';

const mapStateToProps = (state) => ({
  user: state.user,
  terms: state.terms,
  registration: state.registration,
});
const mapDispatchToProps = {
  registerUser,
  registerExistingUser,
  resetRegistration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRegistration);
