import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core';
import {
  warnings as warningPropTypes,
  errors as errorPropTypes,
} from '../../PaymentPropTypes';
import commonStyles from '../../commonPaymentStyles';
import specificStyles from './styles';

const styles = (theme) => ({
  ...commonStyles(theme),
  ...specificStyles(theme),
});

const PaymentValidationMessageComponent = (props) => {
  const { field, warnings, errors, className, classes } = props;

  if (!warnings[field] && !errors[field]) return null;

  return (
    <div className={className || classes.messageContainer}>
      {errors[field]?.length > 0 && (
        <div
          className={`${classes.errorMessages} error-messages`}
          data-test="payment-validate-message-error"
        >
          {errors[field]?.map((error) => (
            <p key={error.rule} className={classes.errorText}>
              {error.message}
            </p>
          ))}
        </div>
      )}
      {warnings[field]?.length > 0 && (
        <div
          className={classNames('warning-messages', classes.warningMessages)}
          data-test="payment-validate-message-warning"
        >
          {warnings[field]?.map((warning) => (
            <>
              {field === 'amountEntered' && <WarningIcon />}
              <Box mx={1}>
                <p key={warning.rule} className={classes.warningText}>
                  {warning.message}
                </p>
              </Box>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

PaymentValidationMessageComponent.propTypes = {
  field: PropTypes.string.isRequired,
  errors: errorPropTypes.isRequired,
  warnings: warningPropTypes,
  className: PropTypes.string,
  classes: PropTypes.shape({
    errorText: PropTypes.string,
    errorMessages: PropTypes.string,
    warningText: PropTypes.string,
    warningMessages: PropTypes.string,
    messageContainer: PropTypes.string,
    linkStyle: PropTypes.string,
  }).isRequired,
};

PaymentValidationMessageComponent.defaultProps = {
  className: '',
  warnings: {},
};

export default withStyles(styles)(PaymentValidationMessageComponent);
