import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const EmployeeSessionChecker = ({
  isEmployee,
  sessionIsExpired,
  sessionIsExpiring,
  onLogoutClick,
}) => {
  if (!isEmployee) return null;
  if (sessionIsExpiring || sessionIsExpired)
    return (
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Session {sessionIsExpired ? 'Expired' : 'Expiring'}!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session is {sessionIsExpired ? 'expired' : 'expiring'}. You
            will need to sign in again to continue using the site.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onLogoutClick} autoFocus color="primary">
            Sign in
          </Button>
        </DialogActions>
      </Dialog>
    );
  return null;
};

EmployeeSessionChecker.defaultProps = {};

EmployeeSessionChecker.propTypes = {
  isEmployee: PropTypes.bool.isRequired,
  sessionIsExpiring: PropTypes.bool.isRequired,
  sessionIsExpired: PropTypes.bool.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default EmployeeSessionChecker;
