import moment from 'moment';

/**
 * Takes a date string and returns true if the given date is in the future and false otherwise
 * @param {*} datestring
 */
const isFutureDate = (datestring) => {
  const today = new Date();
  const givenDate = new Date(datestring);

  return moment(today).isBefore(givenDate);
};

export default isFutureDate;
