import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import UserPropType from 'shared/config/userPropType';
import { isEmployee } from 'shared/utils/tokenUtils';
import { notifications as notificationPropType } from './notificationsPropTypes';
import { fetchNotifications as fetchNotificationsAction } from './actions';
import {
  fetchNotificationsError,
  getNotificationsByDate,
  isFetchingNotifications,
  notificationsFetchedAtTime,
} from './selectors';

// when multiple instances of components wrapped in withNotifications were on a page,
// multiple API calls were being made, even when checking notificationsFetchedAtTime
// for a non-zero value. Thus, use this variable to essentially create a lock on API calls,
// the second instance won't fire once this is set to true
let isFetching = false;

// List of ecomm notification types so that we can filter down the notification count for users
// That have ecomm feature flagged off
const ECOMM_NOTIF_TYPES = [
  'ORDER_CANCELLATION',
  'ORDER_CONFIRMATION',
  'ORDER_FULFILLED',
  'ORDER_INVOICED',
  'ORDER_SUBMITTED',
  'ORDER_STATUS_CHANGE',
];

/**
 * This is a HoC that will request notifications for you if they don't already exist in the store. The goal here is to
 * make it simple for any new components to access notifications without needing to make their own requests.
 * @example `const ComponentWithNotifications = withNotifications(MyComponent)`
 * @param  {React.Component} WrappedComponent The component you want to supply with notifications data.
 * @return {React.Component} A React component that renders your component, supplying it with notifications data.
 */
export const withNotifications = (WrappedComponent) =>
  class WithNotifications extends Component {
    static propTypes = {
      fetchNotifications: PropTypes.func,
      fetchNotificationsError: PropTypes.instanceOf(Error),
      isFetchingNotifications: PropTypes.bool,
      notifications: notificationPropType,
      notificationsFetchedAtTime: PropTypes.number,
      shouldFetch: PropTypes.bool,
      unreadCount: PropTypes.number,
      user: UserPropType,
      flags: PropTypes.shape({ globalEcommerce: PropTypes.bool }),
    };

    static defaultProps = {
      fetchNotifications: () => {},
      fetchNotificationsError: null,
      isFetchingNotifications: false,
      notifications: [],
      notificationsFetchedAtTime: 0,
      shouldFetch: true,
      unreadCount: 0,
      user: {},
      flags: {},
    };

    componentDidMount() {
      const {
        fetchNotifications,
        // eslint-disable-next-line no-shadow
        notificationsFetchedAtTime,
      } = this.props;

      if (!isFetching && !notificationsFetchedAtTime) {
        isFetching = true;
        fetchNotifications().then(() => {
          isFetching = false;
        });
      }

      // As an employee mirroring accounts, fetch notifications when switching between customers
      if (isEmployee()) {
        fetchNotifications();
      }
    }

    componentDidUpdate(prevProps) {
      // TODO: check if this is really necessary. The only time the user's ID should change
      // is during logout/login, which already clears the redux state and navigates off of any page that shows notifications.
      const { user: prevUser } = prevProps;
      const { fetchNotifications, user } = this.props;

      // using lodash as it can also be NaN not just undefined, null, etc.
      if (has(prevUser, 'id') && prevUser.id !== user.id) {
        fetchNotifications();
      }
    }

    render() {
      const {
        user,
        fetchNotifications,
        // eslint-disable-next-line no-shadow
        notificationsFetchedAtTime,
        flags: { globalEcommerce },
        ...rest
      } = this.props;

      // FEATURE FLAG TO REMOVE ECOMM NOTIFICATIONS FROM COUNT
      if (globalEcommerce) {
        return <WrappedComponent {...rest} />;
      }
      let newCount = this.props.unreadCount;
      const ecommNotifications = this.props.notifications.filter(
        (notif) => ECOMM_NOTIF_TYPES.indexOf(notif.type) !== -1
      );
      newCount -= ecommNotifications.length;
      // Can happen if all notifications are ECOMM
      if (newCount < 0) {
        newCount = 0;
      }
      rest.unreadCount = newCount;
      return <WrappedComponent {...rest} />;
    }
  };

export const mapStateToProps = (state) => ({
  notifications: getNotificationsByDate(state),
  fetchNotificationsError: fetchNotificationsError(state),
  isFetchingNotifications: isFetchingNotifications(state),
  notificationsFetchedAtTime: notificationsFetchedAtTime(state),
  user: state.user.userData,
});

export const mapDispatchToProps = {
  fetchNotifications: fetchNotificationsAction,
};

/**
 * This connects your withNotificationss component to the redux store so it can
 * properly dispatch actions.
 * @param  {React.Component} WrappedComponent The component you want to give notifications to.
 * @return {React.Component}                  The wrapped component with notifications.
 */
const connectedWithNotifications = (WrappedComponent) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withNotifications(WrappedComponent));

export default connectedWithNotifications;
