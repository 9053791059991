import { updatePassword } from '../updatePasswordService';
import updatePasswordFailure from './updatePasswordFailure';
import updatePasswordSuccess from './updatePasswordSuccess';
import isSubmitting from './isSubmitting';

export default (oldPassword, newPassword) => (dispatch) => {
  dispatch(isSubmitting());
  updatePassword(oldPassword, newPassword)
    .then(() => dispatch(updatePasswordSuccess()))
    .catch((err) => dispatch(updatePasswordFailure(err)));
};
