import apiAdapter from 'shared/lib/cxhApiService';

// both the PUT and GET receive the same response and must perform the same data shaping,
// thus it was put into a helper method
const extractPreferences = (res) => {
  // firstName and lastName are required in the PUT action, or else we'll get an error. So include them!
  const {
    firstName,
    lastName,
    phoneNumber,
    emails,
    phoneNumbers,
    privacy,
  } = res.data;

  const ret = {
    firstName,
    lastName,
    phoneNumber,
    emails,
    phoneNumbers,
    privacy,
  };

  return ret;
};

/**
 * A true PUT that will delete entities if they are not present!
 * (e.g. just sending the emails array because only an email preference was updated will delete the user's phone numbers)
 * @param {emails: [], phoneNumbers: [], privacy: []} preferences
 *
 * TODO: Inner-api initially supported both creating/updating email addresses
 * and phonenumbers and preferences in one PUT to users/ something changed at
 * inner and now separate calls are needed.This needs to be split out and
 * fixed in the component and actions so that updating the email and or phone
 * number occurs in a different api/action call so that users/ PUT  requests
 * are only made when the phone-number or email changes and not on every preference call
 */
export const put = (preferences) =>
  apiAdapter.put('v1/user/preferences', preferences).then(extractPreferences);

export const get = () =>
  apiAdapter.get(`v1/user/find`).then(extractPreferences);

export const remove = (id) =>
  apiAdapter
    .delete(`v1/notificationsPreferences/${id}`)
    .then((res) => res.data);
