import { GTM_LOG_CART_CHECKOUT_STEP_1 } from 'store/middleware/TagManager/gtmConstants';

/**
 * @param Object[] products - Array of products from the cart
 * @returns {{type: string, payload: {products: *}}}
 */
export default (products) => ({
  type: GTM_LOG_CART_CHECKOUT_STEP_1,
  payload: { products },
});
