import semver from 'semver';

export const isGFA = ({ appVersion, requestedVersion }) => {
  if (appVersion && requestedVersion) {
    return (
      window.ReactNativeWebView &&
      semver.satisfies(appVersion, requestedVersion)
    );
  }

  return window.ReactNativeWebView || false;
};

export const postMessage = (name, payload) => {
  if (isGFA()) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        name,
        payload,
      })
    );
  }
};

export { default as gtmGfaEventPropType } from './gtmGfaEventPropType';
export { default as OpenLink } from './OpenLink';
export { default as OpenPdfLink } from './OpenPdfLink';
