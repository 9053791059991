import React, { Fragment } from 'react';

export default () => (
  <>
    <defs>
      <linearGradient
        x1="38.9834244%"
        y1="91.0519719%"
        x2="61.7883424%"
        y2="6.67743969%"
        id="linearGradient-1"
      >
        <stop stopColor="#4C9E00" offset="0.3966346%" />
        <stop stopColor="#4C9E00" offset="0.6292156%" />
        <stop stopColor="#86D500" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="HelpfulModal-Copy" transform="translate(-61.000000, -48.000000)">
        <g id="N-nutrien" transform="translate(61.000000, 48.000000)">
          <path
            d="M101.363636,1 L79,89.1186944 C83.5743802,100.821958 87.9793388,110.632047 94.7561983,117 L119.491736,20.4480712 L119.661157,19.5875371 C119.830579,18.3827893 120,17.3501484 120,16.1454006 C120,7.1958457 111.867769,1.17210682 101.363636,1"
            id="Path"
            fill="#000000"
          />
          <path
            d="M16.84375,55 L0,119 L23.375,119 L33,81.9653333 C28.703125,69.6773333 24.234375,60.2906667 16.84375,55"
            id="Path"
            fill="#000000"
          />
          <path
            d="M82.3114754,104.168919 C81.295082,102.278064 76.3825137,92.651893 72.147541,79.4159083 C68.0819672,66.3518195 64.0163934,50.1936044 61.9836066,41.5988091 C57.9180328,24.2373227 51.1420765,8.25100346 30.9836066,2.06275087 C25.0546448,0.343791811 13.5355191,0 6.59016393,0 C3.20218579,0 1,0 1,0 C1,0 15.568306,9.4542748 23.8688525,25.0968022 C29.1202186,35.0667647 30.3060109,43.8334559 31.1530055,46.7556863 C47.4153005,47.0994781 54.1912568,54.6628979 63.5081967,71.6805926 C65.5409836,75.4623025 67.7431694,79.2440124 69.7759563,82.8538264 C69.7759563,82.8538264 69.7759563,82.8538264 69.7759563,82.8538264 C69.7759563,82.8538264 69.7759563,82.8538264 69.7759563,82.8538264 C66.8961749,78.212637 63.5081967,73.0557598 60.4590164,68.9302581 C53.6830601,59.6478792 45.0437158,50.881188 31.6612022,50.5373962 C28.6120219,50.5373962 27.5956284,50.5373962 26.4098361,50.5373962 C25.2240437,50.5373962 23.5300546,50.5373962 18.7868852,50.5373962 C33.1857923,58.7883997 34.3715847,79.0721165 43.5191257,92.1362053 C51.989071,104.168919 59.273224,114.310777 80.6174863,118.436279 C83.6666667,118.951967 90.6120219,120.155238 94,119.983342 C89.7650273,116.373528 86.2076503,111.044755 82.3114754,104.168919 Z"
            id="Path"
            fill="url(#linearGradient-1)"
          />
        </g>
      </g>
    </g>
  </>
);
