import { deleteScheduledPayment } from '../PaymentsTabService';
import { DELETE_SCHEDULED_PAYMENT, STATUS } from './constants';
import GetScheduledPayments from './GetScheduledPayments';
import GetRecurringPayments from './GetRecurringPayments';

const updateDeleteScheduledPaymentStatus = (payload) => ({
  type: DELETE_SCHEDULED_PAYMENT,
  payload,
});

export default (id, amount, isRecurring) => async (dispatch, getState) => {
  dispatch(
    updateDeleteScheduledPaymentStatus({
      status: STATUS.DELETING,
      id,
      amount,
      isRecurring,
    })
  );
  try {
    await deleteScheduledPayment(id, isRecurring);
    dispatch(
      updateDeleteScheduledPaymentStatus({
        status: STATUS.SUCCESS,
        id,
        amount,
        isRecurring,
      })
    );
    const accIds = getState().selectedAccount.id;

    dispatch(GetScheduledPayments(accIds));
    dispatch(GetRecurringPayments(accIds));
  } catch (e) {
    dispatch(
      updateDeleteScheduledPaymentStatus({
        status: STATUS.FAILED,
        id,
        amount,
        isRecurring,
      })
    );
  }
};
