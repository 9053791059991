import {
  getPastDate,
  getFirstDateOfCurrentMonth,
  getFirstDateOfPreviousMonth,
  getLastDateOfPreviousMonth,
  getFirstDateOfCurrentYear,
  getFirstDateOfPreviousYear,
  getLastDateOfPreviousYear,
  formatYyyyMmDd,
  formatTodaysDate,
} from 'shared/utils/DateHelper';
import { setTimeframeForProductHistory } from '../../ProductHistoryActionCreators';
import { ALL_TIME_START_DATE } from '../../ProductHistoryConstants';

export const setNewTimeframeForProductHistory = (
  timeframe,
  startDate,
  endDate
) => (dispatch) => {
  const timeframeLowerCase = timeframe.toLowerCase();
  let calculatedStartDate;
  let calculatedEndDate;

  switch (timeframeLowerCase) {
    case 'allavailable':
      calculatedStartDate = ALL_TIME_START_DATE;
      break;
    case 'thismonth':
      calculatedStartDate = getFirstDateOfCurrentMonth();
      break;
    case 'last30days':
      calculatedStartDate = getPastDate(0, 0, 30);
      break;
    case 'lastmonth':
      calculatedStartDate = getFirstDateOfPreviousMonth();
      calculatedEndDate = getLastDateOfPreviousMonth();
      break;
    case 'thisyear':
      calculatedStartDate = getFirstDateOfCurrentYear();
      break;
    case 'lastyear':
      calculatedStartDate = getFirstDateOfPreviousYear();
      calculatedEndDate = getLastDateOfPreviousYear();
      break;
    case 'custom':
      calculatedStartDate = formatYyyyMmDd(startDate);
      calculatedEndDate = formatYyyyMmDd(endDate);
      break;
    default:
      calculatedStartDate = getFirstDateOfPreviousYear();
      calculatedEndDate = getLastDateOfPreviousYear();
      break;
  }

  if (calculatedEndDate === undefined) calculatedEndDate = formatTodaysDate();
  dispatch(
    setTimeframeForProductHistory(calculatedStartDate, calculatedEndDate)
  );
};
