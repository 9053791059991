import moment from 'moment';

const getPastDate = (
  years = 0,
  months = 0,
  days = 0,
  formatString = 'YYYYMMDD'
) =>
  moment()
    .subtract(years, 'years')
    .subtract(months, 'months')
    .subtract(days, 'days')
    .format(formatString);

export default getPastDate;
