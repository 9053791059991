import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import PageNotification from 'shared/ui/NotificationHelper';
import PropTypes from 'prop-types';
import PurchaseOrderNumber from '../../containers/PurchaseOrderNumberContainer/PurchaseOrderNumberContainer';
import OrderConditionsModal from '../OrderConditionsModal';

import './CartContent.scss';

const CartContent = ({
  children,
  handleCloseModal,
  handleOkButton,
  showOrderConditionsModal,
  updatePurchaseOrderNumber,
  usaSapAccount,
}) => {
  const { usaSapCart, westernRegionRestrictProducts } = useFlags();

  if ((usaSapCart && usaSapAccount) || westernRegionRestrictProducts) {
    return (
      <PageNotification
        className="cart_usa-sap"
        data-test="cart__notification--usa-sap"
        link="/"
        linkDescription="Return Home"
        message="While the Cart is unavailable, please contact your Crop Consultant or local branch for information on product pricing and availability."
        type="WARNING"
      />
    );
  }

  return (
    <>
      <div className="cart__purchase-order-wrapper">
        <PurchaseOrderNumber
          updatePurchaseOrderNumber={(number) =>
            updatePurchaseOrderNumber(number)
          }
        />
      </div>
      {children}
      <OrderConditionsModal
        open={showOrderConditionsModal}
        handleCloseModal={() => handleCloseModal()}
        handleOkButton={(checked) => handleOkButton(checked)}
      />
    </>
  );
};

CartContent.propTypes = {
  children: PropTypes.node.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleOkButton: PropTypes.func.isRequired,
  usaSapAccount: PropTypes.bool.isRequired,
  showOrderConditionsModal: PropTypes.bool.isRequired,
  updatePurchaseOrderNumber: PropTypes.func.isRequired,
};

export default withLDConsumer()(CartContent);
