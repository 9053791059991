export default {
  accountRow: {
    paddingLeft: '16px',
    borderBottom: '1px solid #cccccc',
    padding: '10px 0 12px 0',
    minWidth: '480px',
    alignItems: 'center',
  },
  accountsTable: {
    width: '100%',
    margin: 0,
  },
  closeButtonContainer: {
    display: 'flex',
    flexDirenction: 'row',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    position: 'relative',
    right: '0',
    '&:focus': {
      outline: 'none',
    },
  },
  image: {
    margin: '0 15px 0 15px',
  },
  deleteButtonContainer: { alignSelf: 'flex-end' },
  okButton: {
    width: '102px',
    position: 'relative',
    right: '24px',
  },
  paymentAccountListContainer: {
    height: '395px',
    overflow: 'scroll',
  },
  titleText: {
    fontSize: '24px',
    padding: '5px 0 0 20px',
    background: 'white',
    minWidth: '480px',
    '&:after': {
      content: '""',
      padding: 0,
      width: '95%',
      height: '1px',
      display: 'block',
      background: '#cccccc',
      marginTop: '16px',
    },
  },
};
