const styles = {
  errorContainer: {
    position: 'relative',
  },
  spacer: {
    marginBottom: '50px',
  },
};

export default styles;
