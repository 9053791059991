import React, { Fragment } from 'react';
import find from 'lodash/find';
import map from 'lodash/map';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import Dropdown from 'shared/ui/Dropdown';
import { variantPropType } from 'components/Shop/components/ProductDetail/ProductDetailPropTypes';
import variantOptionName from '../../utils/variantOptionName';
import { useUserLocationContext } from '../../utils/contexts/locationCodeContext';
import { CAN } from '../../utils/contexts/locationCodeConstants';

import '../../../../shared/styles/index.scss';

const VariantsDropdown = ({
  label,
  selectedVariant,
  setSelectedVariant,
  variants,
}) => {
  const selectedVariantId = selectedVariant.guid;

  const isCanada = useUserLocationContext() === CAN;

  const options = map(variants, (option) => ({
    code: option.guid,
    name: variantOptionName(option, isCanada),
    selected: option.guid === selectedVariantId,
  }));
  const selectedValue = find(options, { code: selectedVariantId });
  const handleChange = (option) => {
    const variantFromSelectedOption = find(variants, { guid: option.code });
    setSelectedVariant(variantFromSelectedOption);
  };
  return (
    <>
      {label && <label className="control-label">Pack Size</label>}
      {variants.length ? (
        <Dropdown
          data-test="variants-dropdown-dropdown"
          selectedValue={selectedValue}
          onChange={handleChange}
          options={options}
          shouldHavePlaceHolder={false}
          placeholder="variantsDropdown"
          dropdownName="variantsDropdown"
          validate={noop}
        />
      ) : (
        <p>Variants Not Available</p>
      )}
    </>
  );
};

VariantsDropdown.propTypes = {
  label: PropTypes.string,
  selectedVariant: PropTypes.shape({
    guid: PropTypes.string,
  }).isRequired,
  setSelectedVariant: PropTypes.func.isRequired,
  variants: PropTypes.arrayOf(variantPropType).isRequired,
};

VariantsDropdown.defaultProps = {
  label: undefined,
};

export { VariantsDropdown };

export default VariantsDropdown;
