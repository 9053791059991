import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, IconButton } from '@material-ui/core';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import { Dialog } from 'shared/ui/MaterialDialog';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import bankCheckImage from 'assets/bank-check.svg';
import PaymentProcessingOverlay from 'components/Payments/OtherComponents/PaymentProcessingOverlay/PaymentProcessingOverlay';
import { DELETE_BANK_ACCOUNT_IN_PROGRESS } from 'components/Payments/PaymentConstants';
import styles from './styles';

const StoredBankAccountDialog = (props) => {
  const {
    classes,
    onClose,
    deletePaymentMethod,
    user,
    paymentAccounts,
    deletePaymentMethodStatus,
    clearPaymentError,
    ...parentProps
  } = props;
  return props.paymentAccounts ? (
    <Dialog {...parentProps}>
      {deletePaymentMethodStatus === DELETE_BANK_ACCOUNT_IN_PROGRESS ? (
        <PaymentProcessingOverlay processingText="Deleting..." />
      ) : null}

      <div className={classes.titleText}>Stored Bank Accounts</div>
      <div className={classes.paymentAccountListContainer}>
        <table className={classes.accountsTable}>
          <tbody>
            {paymentAccounts.map((paymentMethod) => (
              <tr className={classes.accountRow} key={paymentMethod.tokenId}>
                <td>
                  <img className={classes.image} src={bankCheckImage} alt="" />
                </td>
                <td>
                  <div>
                    {paymentMethod.alias
                      ? paymentMethod.alias
                      : paymentMethod.bankName}{' '}
                    - ending in {paymentMethod.accountNumberLastThree}
                  </div>
                </td>
                <td>
                  <div className={classes.deleteButtonContainer}>
                    <IconButton
                      onClick={() => {
                        const orgs = user.userDetails.associatedOrgs.map(
                          (org) => org.id
                        );
                        deletePaymentMethod(paymentMethod.tokenId, orgs);
                        clearPaymentError();
                      }}
                      className={classes.deleteButton}
                    >
                      <Icon icon="close" />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.closeButtonContainer}>
        <MaterialButton
          value="Ok"
          className={classes.okButton}
          onClick={onClose}
          disabled={
            deletePaymentMethodStatus === DELETE_BANK_ACCOUNT_IN_PROGRESS
          }
        />
      </div>
    </Dialog>
  ) : null;
};

StoredBankAccountDialog.propTypes = {
  paymentAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  deletePaymentMethodStatus: PropTypes.string,
  clearPaymentError: PropTypes.func.isRequired,
};

StoredBankAccountDialog.defaultProps = {
  paymentAccounts: null,
  deletePaymentMethodStatus: null,
};

export default withStyles(styles)(StoredBankAccountDialog);
