import React from 'react';
import { withRouter } from 'react-router-dom';
import theme from '@nutrien/uet-theme';
import { ThemeProvider as MuiThemeProvider } from '@nutrien/uet-react/styles';
import Fab from '@nutrien/uet-react/Fab';
import Comment from '@material-ui/icons/Comment';

import './FABStyles.css';

const handleClick = () => {
  // Only send a message if it's wrapped by React Native
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ name: 'feedback', payload: null })
    );
  }
};

const FeedbackFAB = () => {
  const path = window.location.pathname;
  return path.includes('/cart/') ? null : (
    <div className="support-fab__container">
      <MuiThemeProvider theme={theme}>
        <Fab color="primary" onClick={handleClick}>
          <Comment />
        </Fab>
      </MuiThemeProvider>
    </div>
  );
};

export { FeedbackFAB };
export default withRouter(FeedbackFAB);
