import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import * as roleDelegationActions from '../Redux/RoleDelegation/roleDelegationActions';

import RegisterRoleDelegation from './RegisterRoleDelegation';

export const mapStateToProps = (state) => ({
  user: state.user,
  form: state.roleDelegation.form,
  role: state.roleDelegation.role,
  terms: state.terms,
});

export const mapDispatchToProps = {
  push,
  register: roleDelegationActions.registerRoleDelegation,
  onChange: roleDelegationActions.updateRbacRegistrationFormAction,
  resetForm: roleDelegationActions.resetRbacRegistrationFormAction,
  setErrors: roleDelegationActions.setRbacRegistrationFormErrorsAction,
  clearErrors: roleDelegationActions.clearRbacRegistrationFormErrorsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterRoleDelegation);
