// dependencies
import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// shared UI
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';
import Button from 'shared/ui/Button';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import TermsContent from 'shared/ui/TermsContent';

import { FETCHING_TERMS, TERMS_FETCHED } from '../termsConstants';

import TermsPropTypes from '../termsPropTypes';

// stylesheets
import styles from './styles';

const AcceptTerms = ({
  classes,
  maintenanceNotification,
  terms,
  updateTermsAcceptance,
  logout,
}) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (terms.status !== TERMS_FETCHED) {
      return false;
    }

    return updateTermsAcceptance(terms.termsVersion, '/');
  };

  const handleDecline = (e) => {
    e.preventDefault();
    return logout();
  };

  const style = maintenanceNotification ? 'hasBanner' : 'card';

  const termsContent =
    terms.status === FETCHING_TERMS ? (
      <MaterialLoadingIcon />
    ) : (
      <TermsContent terms={terms} />
    );

  return (
    <div>
      <FullPageBackground
        image={bgRegistration}
        className="full-page-background--farmers-pointing"
      />
      <div className={classes[style]}>
        <Card className={classes.termsCard}>
          <div className={classes.header}>
            <div className={classes.title}>
              <h3 className={classes.h3}>Terms of Use and Privacy Policy</h3>
            </div>
            <hr className={classes.hr} />
          </div>
          <div data-test="terms-content" className={classes.termsContent}>
            {termsContent}
          </div>
        </Card>
        <Card className={classes.acceptTermsCard}>
          <Button
            id="decline-terms__button"
            className={`${classes.button} button--reverse-color button--short`}
            value="Decline"
            onClick={handleDecline}
          />
          <Button
            id="accept-terms__button"
            className={`${classes.button} button--short`}
            value="Accept"
            onClick={handleSubmit}
          />
        </Card>
      </div>
    </div>
  );
};

AcceptTerms.propTypes = {
  terms: TermsPropTypes.isRequired,
  updateTermsAcceptance: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  maintenanceNotification: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

export default withStyles(styles)(AcceptTerms);
