import { setProductHistorySorted } from '../../../ProductHistoryActionCreators';

export const sortProductHistoryTable = (field, order, productHistoryList) => (
  dispatch
) => {
  const sortedList = productHistoryList.sort((x, y) => {
    let sortInt = 1;
    if (order === 'descending') sortInt = -1;
    if (x[field] < y[field]) return sortInt;
    if (x[field] > y[field]) return -sortInt;
    return 0;
  });
  dispatch(setProductHistorySorted(sortedList, field));
};
