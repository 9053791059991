import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tabs } from '@material-ui/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Switch, Link } from 'react-router-dom';
import { FlaggedTab } from 'shared/utils/FeatureFlaggedTab';
import { MaterialLoadingIcon } from 'shared/ui';
import AuthorizedComponent from 'shared/utils/AuthorizedComponents';
import { fetchAssociatedOrgsAction } from '../../shared/ui/AccountsTable/actions/AccountActions';
import FlaggedRoute from '../FlaggedRoute';
import {
  ProfileTab,
  AccountsTab,
  LicensingTab,
  BillingTab,
  NotificationsTab,
  UsersTab,
} from './Tabs';
import './ProfileComponent.scss';
import PageHeader from '../../shared/ui/PageHeader/PageHeader';

const Profile = ({ match }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const { profileTabBar } = useFlags();
  const { tabValue } = match.params;
  const baseUrl = match.url.split(tabValue)[0];

  useEffect(() => {
    dispatch(
      fetchAssociatedOrgsAction((user.userDetails || {}).associatedOrgs || [])
    );
  }, [user.userDetails]);

  const tabConfig = [
    {
      label: 'Profile',
      value: 'overview',
      flagKey: 'profile-page-profile-tab',
      component: ProfileTab,
    },
    {
      label: 'Accounts',
      value: 'accounts',
      flagKey: 'profile-page-accounts-tab',
      component: AccountsTab,
    },
    {
      label: 'Licensing',
      value: 'licensing',
      flagKey: 'profile-page-licensing-tab',
      component: LicensingTab,
    },
    {
      label: 'Billing',
      value: 'billing',
      flagKey: 'profile-page-billing-tab',
      component: BillingTab,
    },
    {
      label: 'Notifications',
      value: 'notifications',
      flagKey: 'profile-page-notifications-tab',
      component: NotificationsTab,
    },
    {
      label: 'Users',
      value: 'users',
      flagKey: 'profile-page-users-tab',
      component: UsersTab,
    },
  ];

  const renderTabs = () => {
    const flaggedTab = (tab) => (
      <FlaggedTab
        flagKey={tab.flagKey}
        key={tab.value}
        value={tab.value}
        label={tab.label}
        component={Link}
        to={`${baseUrl}${tab.value}`}
        className="profile-component__profile-tab"
        classes={{ selected: 'profile-component__tab--selected' }}
      />
    );

    return (
      <Tabs
        variant="scrollable"
        scrollButtons="off"
        value={tabValue}
        className="profile-component__profile-tabs"
      >
        {tabConfig.map((tab) => {
          if (tab.value === 'users') {
            return (
              <AuthorizedComponent
                value={tab.value}
                key={tab.value}
                roles={['owner']}
              >
                {flaggedTab(tab)}
              </AuthorizedComponent>
            );
          }
          return flaggedTab(tab);
        })}
      </Tabs>
    );
  };

  if (!user.userDetails) return <MaterialLoadingIcon />;

  return (
    <>
      <PageHeader
        title={`${user.firstName} ${user.lastName}`}
        breadcrumbs={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'Profile & Settings',
          },
        ]}
      />
      {profileTabBar ? renderTabs() : <hr />}
      <Switch>
        {tabConfig.map((tab) => (
          <FlaggedRoute
            key={tab.value}
            flagKey={tab.flagKey}
            exact
            path={`${baseUrl}${tab.value}`}
            component={tab.component}
          />
        ))}
      </Switch>
    </>
  );
};

Profile.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Profile;
