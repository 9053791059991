import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    borderColor: theme.palette.error.secondary,
    borderRadius: '4px',
    backgroundColor: theme.palette.error.secondary,
    color: theme.palette.error.text,
    zIndex: 100,
    position: 'relative',
    padding: theme.spacing(),
    width: '100%',
    '@media print': {
      display: 'none',
    },
  },
  icon: {
    height: '21px',
    width: '21px',
    margin: theme.spacing(),
  },
  message: {
    fontSize: '.875rem',
    lineHeight: '20px',
  },
  link: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.error.text,
  },
}));

export default useStyles;
