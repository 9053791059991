import React, { Component } from 'react';
import currency from 'shared/utils/numberFormatters/currency';
import { arrayOf, shape, func } from 'prop-types';
import { Button } from '@material-ui/core';
import { Icon } from 'nutrien-common-components-react/dist/mdc/';
import {
  TableCell,
  TableRow,
} from 'nutrien-common-components-react/dist/mdc/Table';
import { Link } from 'react-router-dom';
import { formatYYMMDDWithSlashes } from '../../../../../../shared/utils/DateHelper';
import OrderStatus from './OrderStatus';

class FilteredOrders extends Component {
  noOrders = (
    <TableRow
      key="noFilteredOrders"
      data-test="orders-table-no-filtered-orders"
    >
      <TableCell colSpan={8} className="orders-table__no-filtered-orders">
        <Icon type="warning" icon="warning" />
        <span className="orders-table__no-filtered-orders-text">
          No orders with this status are available.
        </span>
      </TableCell>
    </TableRow>
  );

  handleRoute = (id) => {
    const { history } = this.props;
    history.push(`/orders/${id}`);
  };

  confirmIfDollarValue = (netOrderTotal) => {
    if (netOrderTotal === 0) {
      return 'TBD';
    }
    return currency(netOrderTotal);
  };

  renderTableCells = (orderNumber, orderDate, netOrderTotal, orderStatus) => [
    <TableCell
      key={`${orderDate}${orderNumber}`}
      className="orders-list__table-item orders-list__table-orderId-column"
    >
      <span className="orders-list__table-item-mobile-heading">Order ID</span>
      <Link
        className="orders-list__table-orderId-link"
        to={`/orders/${orderNumber}`}
      >
        {orderNumber}
      </Link>
    </TableCell>,
    <TableCell key={orderNumber} className="orders-list__table-item">
      <span className="orders-list__table-item-mobile-heading">Date</span>
      {formatYYMMDDWithSlashes(orderDate)}
    </TableCell>,
    <TableCell
      key="subtotal"
      className="orders-list__table-item orders-list__table-subtotal-column"
    >
      <span className="orders-list__table-item-mobile-heading">Subtotal</span>
      {this.confirmIfDollarValue(netOrderTotal)}
    </TableCell>,
    <TableCell
      key="status"
      className="orders-list__table-item orders-list__table-status-column"
    >
      <span className="orders-list__table-item-mobile-heading">Status</span>
      <OrderStatus status={orderStatus} />
    </TableCell>,
    <TableCell
      key={`${orderDate}${netOrderTotal}`}
      className="orders-list__table-item orders-list__table-button-column"
    >
      <Button
        className="orders-list__table-button"
        variant="outlined"
        onClick={() => this.handleRoute(orderNumber)}
      >
        View Order
      </Button>
    </TableCell>,
  ];

  render() {
    const { filteredOrders } = this.props;

    return filteredOrders.length
      ? filteredOrders.map((order) => {
          const {
            orderNumber,
            orderStatus,
            netOrderTotal,
            orderDate,
          } = order.attributes;
          return (
            <TableRow
              key={`${orderNumber}${orderDate}`}
              data-test="orders-table-row"
            >
              {this.renderTableCells(
                orderNumber,
                orderDate,
                netOrderTotal,
                orderStatus
              )}
            </TableRow>
          );
        })
      : [this.noOrders];
  }
}

FilteredOrders.propTypes = {
  filteredOrders: arrayOf(shape({})),
  history: shape({ push: func }).isRequired,
};

FilteredOrders.defaultProps = {
  filteredOrders: [],
};

export default FilteredOrders;
