import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { selectProductFilter } from '../../actions';
import InvoiceSearchComponent from './InvoiceSearchComponent';

const mapStateToProps = (state) => ({
  accountId: state.selectedAccount.id,
  currentlySelected: state.invoices.filteredProducts.product.id,
  getProductListStatus: state.invoices.invoicesData.status,
});

const mapDispatchToProps = { selectProductFilter, push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceSearchComponent);
