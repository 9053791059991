import React from 'react';
import PropTypes from 'prop-types';
import BaseHeader from '../BaseHeader';

const TermsHeader = ({ browser, isMirroring, location, logout }) => {
  const handleLogout = () => {
    logout();
  };
  return (
    <BaseHeader
      browser={browser}
      location={location}
      isMirroring={isMirroring}
      displayForAllScreenSizes
    >
      <div
        className="signout"
        onClick={handleLogout}
        onKeyPress={handleLogout}
        role="button"
        tabIndex="0"
        data-test="signout-button"
      >
        Sign out
      </div>
    </BaseHeader>
  );
};

TermsHeader.propTypes = {
  browser: PropTypes.shape({
    isSupported: PropTypes.bool,
  }).isRequired,
  isMirroring: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

TermsHeader.defaultProps = {
  isMirroring: false,
};

export default TermsHeader;
