import { combineReducers } from 'redux';

import {
  FETCHING_LEGAL,
  LEGAL_FETCH_FAILURE,
  LEGAL_FETCH_SUCCESS,
  LEGAL_FETCHED,
  LEGAL_FETCH_ERROR,
} from './legalDocumentsConstants';

export const content = (state = null, action) => {
  switch (action.type) {
    case LEGAL_FETCH_SUCCESS:
      return action.payload.content;
    case LEGAL_FETCH_FAILURE:
      return null;
    default:
      return state;
  }
};

export const type = (state = null, action) => {
  switch (action.type) {
    case LEGAL_FETCH_SUCCESS:
      return action.payload.document;
    case LEGAL_FETCH_FAILURE:
      return null;
    default:
      return state;
  }
};

export const version = (state = null, action) => {
  switch (action.type) {
    case LEGAL_FETCH_SUCCESS:
      return action.payload.version;
    case LEGAL_FETCH_FAILURE:
      return null;
    default:
      return state;
  }
};

export const status = (state = '', action) => {
  switch (action.type) {
    case FETCHING_LEGAL:
      return FETCHING_LEGAL;
    case LEGAL_FETCH_SUCCESS:
      return LEGAL_FETCHED;
    case LEGAL_FETCH_FAILURE:
      return LEGAL_FETCH_ERROR;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case LEGAL_FETCH_FAILURE:
      return action.error;
    case LEGAL_FETCH_SUCCESS:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  content,
  type,
  version,
  status,
  error,
});
