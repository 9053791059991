import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import HeaderContentWrapper from './HeaderContentWrapper';
import HeaderContentWrapperContainer from './HeaderContentWrapperContainer';

export const HeaderWithState = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={() => (
      <HeaderContentWrapperContainer>
        {(props, isMenuOpen, toggleMenu, closeMenu) => (
          <HeaderContentWrapper
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            closeMenu={closeMenu}
            component={Component}
            {...props}
          />
        )}
      </HeaderContentWrapperContainer>
    )}
  />
);

HeaderWithState.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default withRouter(HeaderWithState);
