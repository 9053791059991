// dependencies
import React, { Component } from 'react';

// shared UI
import { AccountingList, AccountingListItem } from 'shared/ui';
import { currency } from 'shared/utils/numberFormatters';
import DateFormatter from 'shared/ui/DateFormatter';
import Pagination from 'shared/ui/Pagination';
import { getPage } from 'shared/utils/pagination';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import MaterialLoadingIconInline from 'shared/ui/MaterialLoadingIconInline';
import Button from '@nutrien/uet-react/Button';
import Dialog from '@nutrien/uet-react/Dialog';
import DialogContent from '@nutrien/uet-react/DialogContent';
import DialogContentText from '@nutrien/uet-react/DialogContentText';
import DialogTitle from '@nutrien/uet-react/DialogTitle';
import DialogActions from '@nutrien/uet-react/DialogActions';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import PropTypes from 'prop-types';
import axios from 'shared/lib/cxhApiService';
import PaginationNext from 'shared/ui/PaginationNext';
import PaginationPrev from 'shared/ui/PaginationPrev';

// shared utils
import downloadFile from 'shared/utils/DownloadFile';

// assets
import PdfIcon from '@material-ui/icons/PictureAsPdf';
// stylesheets
import './Statements.css';

class Statements extends Component {
  state = {
    downloading: null,
    downloadError: false,
  };

  getMobileHeader = (headers, index) => {
    return headers[index].text || headers[index];
  };

  // TODO: this logic should be extracted out of this component,
  // handling state should be the container's job, HTTP requests should be in a service,
  // everything should rely on actions to trigger change, etc.
  handlePdfDownload = (link, date, id) => {
    if (this.state.downloading === null) {
      this.setState({
        downloading: id,
      });

      const config = {
        headers: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
      };

      return axios
        .get(link, config)
        .then((response) => {
          this.setState({
            downloading: null,
          });

          this.props.handleDownload();
          downloadFile(
            response.data,
            `statement-${date}.pdf`,
            'application/pdf',
            'statement-download-id',
            {
              category: 'Statements',
              label: 'Download PDF',
            }
          );
        })
        .catch(() => {
          this.setState({
            downloading: null,
            downloadError: true,
          });
        });
    }
    return Promise.resolve();
  };

  closeDownloadError = () => {
    this.setState({ downloadError: false });
  };

  render() {
    const { statementSummaries, currentPage, pageSize } = this.props;
    const paginatedStatementSummaries = getPage(
      statementSummaries.list,
      currentPage,
      pageSize
    );

    const headers = [
      { text: 'Date', minSpace: true },
      { text: 'Amount Due', align: 'right', noPadding: true },
      { text: 'Document' },
      '',
    ];

    const statementList = paginatedStatementSummaries.map((statement) => (
      <tr className="activity-row activity-row-mobile" key={statement.id}>
        <AccountingListItem
          data={DateFormatter(statement.date)}
          mobileHeader={this.getMobileHeader(headers, 0)}
        />
        <AccountingListItem
          data={currency(statement.amountDue, '', true)}
          right
          mobileHeader={this.getMobileHeader(headers, 1)}
        />
        <AccountingListItem
          data={
            <div
              className={`
                ${!this.state.downloading && 'statements__pdf-link '}
                ${
                  this.state.downloading !== null &&
                  'statements__pdf-link__disabled '
                }
                ${
                  this.state.downloading === statement.id &&
                  'statements__pdf-link__loading '
                }
              `}
              onClick={() => {
                this.handlePdfDownload(
                  statement.statementFile.downloadLink,
                  statement.date,
                  statement.id
                );
              }}
              onKeyUp={() => {
                this.handlePdfDownload(
                  statement.statementFile.downloadLink,
                  statement.date,
                  statement.id
                );
              }}
              role="link"
              tabIndex="-1"
            >
              <PdfIcon /> Download PDF
              {this.state.downloading === statement.id && (
                <MaterialLoadingIconInline noPadding />
              )}
            </div>
          }
          noPadding
          mobileHeader={this.getMobileHeader(headers, 2)}
        />
        <AccountingListItem data="" right />
      </tr>
    ));

    const statementsTable = (
      <div>
        {this.props.statementSummaries.list.length > 0 && (
          <AccountingList title="All Statements" headers={headers}>
            {statementList}
          </AccountingList>
        )}
        <div className="end-of-activity">
          {this.props.statementSummaries.list.length > 0
            ? 'End of Statements.'
            : 'You have no Statements to display.'}
        </div>
        <Pagination
          className="notifications-page__pagination pagination__standard"
          current={this.props.currentPage}
          total={this.props.statementSummaries.list.length}
          onChange={this.props.onPaginationClick}
          pageSize={this.props.pageSize}
        />

        <Pagination
          className="pagination__mobile"
          current={this.props.currentPage}
          total={this.props.statementSummaries.list.length}
          onChange={this.props.onPaginationClick}
          pageSize={this.props.pageSize}
          prevIcon={PaginationPrev}
          nextIcon={PaginationNext}
          simple
        />
      </div>
    );

    const statementListContent = () => {
      switch (statementSummaries.status) {
        case 'LOADING':
          return <MaterialLoadingIcon />;
        case 'OK':
          return statementsTable;
        case 404:
          return (
            <div className="end-of-activity">
              You have no Statements to display.
            </div>
          );
        default:
          return <ApiErrorMessage entityName="Statements" />;
      }
    };

    const errorModal = (
      <Dialog open={this.state.downloadError} onClose={this.closeDownloadError}>
        <DialogTitle>There was an error fetching this PDF.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Download could not be completed. If this error persists please
            contact an administrator.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.closeDownloadError}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <div className="account-statements">
        {statementListContent()}
        {errorModal}
      </div>
    );
  }
}

Statements.propTypes = {
  statementSummaries: PropTypes.shape({
    status: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        amountDue: PropTypes.number,
        date: PropTypes.string,
      })
    ),
  }).isRequired,
  onPaginationClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleDownload: PropTypes.func.isRequired,
};

export default Statements;
