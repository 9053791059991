import { customFormat } from 'shared/utils/DateHelper';

export const createProductHistoryPageProps = (
  filter,
  productHistory,
  selectedAccount,
  state
) => {
  const {
    cachedProductHistoryList,
    setCachedProductHistoryList,
    shelfFilter,
    setShelfFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sortedColumn,
    setSortedColumn,
  } = state;

  // initialize productHistoryPageProps
  const productHistoryPageProps = {
    productHistoryList: productHistory.list,
    sortedColumn,
    selectedAccount,
    startDate,
    endDate,
    shelfFilter,
  };

  // update productHistoryPageProps
  switch (productHistory.status) {
    // Api returns 404 if no results for the search are found, this will allow the top portion of the page to render
    case 'ERROR':
    case 'OK':
      if (
        JSON.stringify(productHistory.list) !==
        JSON.stringify(cachedProductHistoryList)
      ) {
        setCachedProductHistoryList(productHistory.list);
      }
      // hack to filter out later date info in same-day scenario
      // this will be removed when inner can serve a same-day product history
      if (endDate === startDate) {
        productHistoryPageProps.productHistoryList = productHistoryPageProps.productHistoryList.filter(
          (prodHistory) =>
            prodHistory.latestInvoiceDate ===
            customFormat(startDate, 'YYYY-MM-DD')
        );
      }
      if (filter) {
        productHistoryPageProps.productHistoryList = productHistoryPageProps.productHistoryList.filter(
          (prodHistory) =>
            prodHistory.shelf.toUpperCase() === filter.toUpperCase()
        );
      }
      break;
    case 'SORTED':
      if (
        JSON.stringify(productHistory.sortedList) !==
        JSON.stringify(cachedProductHistoryList)
      ) {
        setCachedProductHistoryList(productHistory.sortedList);
      }
      if (sortedColumn !== productHistory.sortedColumn) {
        setSortedColumn(productHistory.sortedColumn);
      }
      productHistoryPageProps.sortedColumn = productHistory.sortedColumn;
      productHistoryPageProps.productHistoryList = productHistory.sortedList;
      break;
    case 'ADDING_TO_CART':
    case 'ADD_TO_CART_SUCCESS':
    case 'ADD_TO_CART_FAILURE':
    case 'HUBSPOT_OPT_IN_SUCCESS':
      productHistoryPageProps.productHistoryList = cachedProductHistoryList;
      break;
    case 'NEW_SHELVES':
      if (productHistory.selectedShelves !== shelfFilter) {
        setShelfFilter(productHistory.selectedShelves);
      }
      productHistoryPageProps.productHistoryList = cachedProductHistoryList;
      productHistoryPageProps.shelfFilter = productHistory.selectedShelves;
      break;
    case 'NEW_TIMEFRAME':
      if (
        productHistory.startDate !== startDate ||
        productHistory.endDate !== endDate
      ) {
        setStartDate(productHistory.startDate);
        setEndDate(productHistory.endDate);
      }

      // edge case where same timeframe is chosen twice
      if (
        productHistory.startDate === startDate &&
        productHistory.endDate === endDate
      ) {
        productHistoryPageProps.productHistoryList = cachedProductHistoryList;
      }

      productHistoryPageProps.startDate = productHistory.startDate;
      productHistoryPageProps.endDate = productHistory.endDate;
      break;
    default:
      break;
  }

  return productHistoryPageProps;
};
