import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import {
  propType as invoiceFiltersPropType,
  defaultProp as invoiceFiltersDefaultProp,
} from '../../propTypes/invoiceFiltersPropType';
import './invoiceIdFilter.scss';

export const InvoiceIdFilter = ({
  invoiceFilters,
  updateInvoiceFilters,
  currentPage,
  handlePaginationClick,
  gtmToggleInvoiceFilter,
}) => {
  const [state, setState] = useState({
    value: invoiceFilters.invoiceId,
  });

  const triggerGTMFocusAction = (value) =>
    gtmToggleInvoiceFilter('invoiceId', value);

  // Delay switching pages so the user can finish typing
  const debounceHandlePaginationClick = debounce(handlePaginationClick, 1000);

  const handleInvoiceIdFilterChange = (e) => {
    const { value } = e.target;
    setState({ value });
    updateInvoiceFilters({ invoiceId: value });
    if (currentPage > 1) {
      debounceHandlePaginationClick(1);
    }
  };

  const handInvoiceIdFilterFocus = () => triggerGTMFocusAction(true);

  // If the user de-fouces the input go ahead and switch pages
  const handInvoiceIdFilterBlur = () => {
    debounceHandlePaginationClick.flush();
    triggerGTMFocusAction(false);
  };

  return (
    <div>
      <input
        className="invoice-id-filter__input"
        value={state.value}
        onChange={handleInvoiceIdFilterChange}
        onFocus={handInvoiceIdFilterFocus}
        onBlur={handInvoiceIdFilterBlur}
        type="text"
      />
    </div>
  );
};

InvoiceIdFilter.propTypes = {
  invoiceFilters: invoiceFiltersPropType,
  updateInvoiceFilters: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  handlePaginationClick: PropTypes.func.isRequired,
  gtmToggleInvoiceFilter: PropTypes.func.isRequired,
};

InvoiceIdFilter.defaultProps = {
  invoiceFilters: invoiceFiltersDefaultProp,
  currentPage: 1,
};

export default InvoiceIdFilter;
