export default (theme) => ({
  helpModalContainer: {
    position: 'absolute',
    width: '500px',
    height: '320px',
    bottom: '30px',
    zIndex: '200',
    borderRadius: '5px',
    background: 'white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
    padding: '14px 15px 0 20px',
  },
  helpModalArrowCover: {
    overflow: 'hidden',
    width: '30px',
    height: '5px',
    background: 'white',
    zIndex: '200',
    position: 'absolute',
    top: '315px',
    left: '25px',
  },
  helpModalSvg: {
    position: 'absolute',
    top: '320px',
    left: '25px',
    filter: 'drop-shadow( 0px 1px 2px #000 )',
  },
  helpModalSvgPolygon: {
    fill: 'white',
  },
  helpModalClose: {
    float: 'right',
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  helpModalTitle: {
    display: 'inline',
    color: theme.palette.text.primary,
    fontSize: '20px',
  },
  helpModalImage: {
    marginTop: '19px',
    width: '324px',
  },
  helpModalDescription: {
    marginTop: '35px',
    color: theme.palette.text.secondary,
  },
  '@media (max-width: 480px)': {
    helpModalContainer: {
      position: 'fixed',
      top: '30%',
      left: '3%',
      width: '94%',
      borderRadius: '0',
      padding: '14px',
    },
    helpModalDarkBg: {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, .5)',
      zIndex: '100',
    },
    helpModalTitle: {
      paddingLeft: '2px',
    },
    helpModalSvg: {
      display: 'none',
    },
    helpModalArrowCover: {
      display: 'none',
    },
  },
});
