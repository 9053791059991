import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { push } from 'connected-react-router';
import { setPaymentFlowType } from 'components/Payments/Redux/actions/BankAccountFormActions';
import { clearPrepay, clearPrepayTransaction } from 'actions/actionCreators';
import { getPrepayInfo, getPrepayHistoryInfo } from '../actions';
import PrePay from './PrePay';

const mapStateToProps = (state) => ({
  prepayDetails: state.prepayDetails.data,
  prepayStatus: state.prepayDetails.status,
  prepayHistory: state.prepayHistory.data,
  prepayHistoryStatus: state.prepayHistory.status,
  prepayTransaction: state.prepayTransaction,
});

const mapDispatchToProps = {
  push,
  getPrepayInfo,
  getPrepayHistory: getPrepayHistoryInfo,
  clearPrepay,
  setPaymentFlowType,
  clearPrepayTransaction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(PrePay);
