const styles = (theme) => ({
  header: {
    flexWrap: 'wrap',
    overflow: 'visible',
  },
  title: {
    padding: 20,
    top: 0,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  hr: {
    margin: 0,
    width: '100%',
  },
  termsContent: {
    padding: 0,
    flex: 0,
    height: '90%',
  },
  closeModal: {
    cursor: 'pointer',
  },
  termsCardContainer: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 99999,
    background: 'rgba(0, 0, 0, .4)',
  },
  termsCard: {
    margin: '80px auto 0px',
    padding: 0,
    color: theme.palette.text.secondary,
    maxWidth: 800,
    width: '90%',
    height: '100%',
    position: 'relative',
    flex: '1',
  },
  acceptTermsCard: {
    width: '90%',
    margin: '-4px auto 110px',
    padding: 0,
    position: 'relative',
    maxWidth: 800,
  },
  acceptTerms: {
    height: 75,
    width: '100%',
    background: 'white',
  },
  h3: {
    marginBottom: 0,
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    padding: '15px 20px',
    '& button': {
      width: '100%',
      margin: '7px 0',
    },
  },
  noMargin: {
    margin: 0,
  },
  '@media (min-width: 720px)': {
    header: {
      padding: '20px 20px 0px',
    },
    title: {
      padding: 0,
    },
    termsContent: {
      padding: '0px 20px',
    },
    buttonContainer: {
      padding: '10px 30px',
      width: 425,
      float: 'right',
      '& button': {
        width: '46%',
        marginLeft: '4%',
      },
    },
    h3: {
      marginBottom: 16,
    },
  },
});

export default styles;
