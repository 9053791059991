// dependencies
import React from 'react';
import { Route, Switch } from 'react-router';

import AcceptTerms from './AcceptTerms';
import TermsPage from './TermsPage';
import Page404 from '../Page404';

const Terms = () => (
  <Switch>
    <Route exact path="/accept-terms" component={AcceptTerms} />
    <Route exact path="/terms-of-use" component={TermsPage} />
    <Route exact path="/privacy" component={TermsPage} />
    <Route component={Page404} />
  </Switch>
);

export default Terms;
