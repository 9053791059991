// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = {
  form: {
    width: '100%',
    margin: '28px 0px 0px',
  },

  '@media (min-width: 720px)': {
    form: {
      width: '90%',
      margin: '28px 5% 0px',
    },
  },
};

const Form = (props) => {
  const { classes } = props;

  return (
    <form className={classes.form} onSubmit={props.onSubmit}>
      {props.children}
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

Form.defaultProps = {
  onSubmit: null,
};

export default withStyles(styles)(Form);
