import { connect } from 'react-redux';
import moment from 'moment';
import {
  gtmViewTopProductSDS,
  gtmViewPastTopProductPurchases,
} from 'store/middleware/TagManager/gtmActions';
import { fetchTopProducts, resetTopProducts } from './fetchTopProducts';
import TopProducts from './TopProducts';

const mapStateToProps = (state) => ({
  startDate: moment()
    .subtract(14, 'months')
    .startOf('month')
    .format('YYYYMMDD'),
  endDate: moment().subtract(10, 'months').endOf('month').format('YYYYMMDD'),
  topProducts: state.topProducts,
  cart: state.cart,
  selectedAccount: state.selectedAccount,
});

const mapDispatchToProps = {
  fetchTopProducts,
  gtmViewTopProductSDS,
  gtmViewPastTopProductPurchases,
  resetTopProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopProducts);
