import React, { PureComponent, Fragment } from 'react';
import CircularProgress from 'nutrien-common-components-react/dist/mdc/Progress';
import Radio from 'nutrien-common-components-react/dist/mdc/Radio';
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from 'nutrien-common-components-react/dist/mdc/Dialog';
import { Button } from 'nutrien-common-components-react/dist/mdc';
import Icon from 'nutrien-common-components-react/dist/mdc/Icon';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import AddressForm from './AddressForm';
import {
  pickup,
  delivery,
  addressError,
} from '../../constants/deliveryAddress';

import './DeliveryAddress.scss';

class DeliveryAddress extends PureComponent {
  constructor(props) {
    super(props);
    let pickupOrDelivery = pickup;
    const {
      cart: { isPickup },
    } = props;
    if (!isPickup.pickup) {
      pickupOrDelivery = delivery;
    }

    this.state = {
      selectedValue: '0',
      changeOpen: false,
      pickupOrDelivery,
    };
  }

  componentDidMount() {
    const { cart, getAddress, selectedAccount } = this.props;
    const addressesMissing = get(cart, 'addresses.all.data', []).length === 0;

    if (!isEmpty(selectedAccount) && addressesMissing) {
      if (!get(cart, 'addresses.all.updatingAddress', false)) {
        getAddress(selectedAccount.id);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      cart: { addresses },
      selectedAccount,
    } = this.props;
    const { selectedAccount: prevSelectedAccount } = prevProps;
    if (prevSelectedAccount !== selectedAccount) {
      const { getAddress, getBranchAddress } = this.props;
      getBranchAddress(selectedAccount.id, selectedAccount.branchId);

      if (!addresses.all) {
        getAddress(selectedAccount.id);
      }
    }
  }

  componentWillUnmount() {
    const { setPickup } = this.props;
    const { pickupOrDelivery } = this.state;
    const isPickup = pickupOrDelivery && pickupOrDelivery === pickup;

    setPickup(isPickup);
  }

  getDefaultAddress = () => {
    const {
      cart: { addresses },
    } = this.props;
    const { all } = addresses;

    let section;
    // Don't show if "Pick-up at Branch" radio button is selected
    if (this.state.pickupOrDelivery === pickup) return null;
    // Show address if available
    if (!isEmpty(addresses) && addresses.all) {
      const { defaultAddress } = all;
      // Error
      if (addresses.all.error) {
        return (
          <span className="delivery-address__form-error">
            Error, please try again
          </span>
        );
      }
      section = (
        <>
          <div className="delivery-address__text">
            {defaultAddress.description}
          </div>
          <div className="delivery-address__text">
            {defaultAddress.addressLineOne}
          </div>
          {defaultAddress.addressLineTwo && (
            <div className="delivery-address__text">
              {defaultAddress.addressLineTwo}
            </div>
          )}
          <div className="delivery-address__text">
            {defaultAddress.city}, {defaultAddress.provinceStateValue}{' '}
            {defaultAddress.zipPostalCode}
          </div>
        </>
      );
    }
    // Loading
    else {
      section = (
        <div
          className="delivery-address__loading-wrapper"
          data-test="delivery-address-loading-wrapper"
        >
          <CircularProgress />
        </div>
      );
    }

    return section;
  };

  getBranchAddressSection = () => {
    let section;
    // Hide if "Deliver to Shipping Address is selected"
    if (this.state.pickupOrDelivery !== pickup) return null;

    const loading = get(
      this.props,
      'cart.branchAddress.updatingBranchAddress',
      false
    );
    const branchDetails = get(
      this.props,
      'cart.branchAddress.address.data',
      {}
    );

    if (loading) {
      section = (
        <div
          className="delivery-address__loading-wrapper"
          data-test="delivery-address-loading-wrapper"
        >
          <CircularProgress />
        </div>
      );
    } else if (branchDetails.name) {
      section = (
        <>
          <h2 className="delivery-address__text--emphasized">Branch</h2>
          <div className="delivery-address__text">
            {`${branchDetails.name} #${branchDetails.id}`}
          </div>
          <div className="delivery-address__text">{branchDetails.address}</div>
          <div className="delivery-address__text">
            {`${branchDetails.city}, ${branchDetails.stateorProvince} ${branchDetails.zipPostalCode}`}
          </div>
        </>
      );
    } else {
      section = (
        <span className="delivery-address__form-error">
          Error, please try again
        </span>
      );
    }
    return section;
  };

  getDialogSection = () => {
    const {
      cart: { addresses },
    } = this.props;
    const data = get(addresses, 'all.data', []);
    const updatingAddress = get(addresses, 'all.updatingAddress', false);

    let section;

    if (!updatingAddress && data.length) {
      // Error
      if (addresses.all.error) {
        return (
          <span className="delivery-address__form-error">
            Error, please try again
          </span>
        );
      }

      section = (
        <>
          {data.map((address, i) => {
            const theIndex = i.toString();
            // create a unique identifier
            const placeHolder = `${theIndex}`;
            if (i === 0) {
              return (
                <Fragment key="a">
                  <h2 className="delivery-address__cart-sub-headline" key="b">
                    Shipping Address
                  </h2>
                  {this.getRadioSection(address, placeHolder)}
                </Fragment>
              );
            }

            return (
              <Fragment key={placeHolder}>
                {i === 1 && data.length > 1 ? (
                  <h2 className="delivery-address__cart-sub-headline" key="c">
                    Other Addresses
                  </h2>
                ) : null}
                {this.getRadioSection(address, placeHolder)}
              </Fragment>
            );
          })}
        </>
      );
    } else {
      section = (
        <div className="delivery-address__loading-wrapper jsLoadingWrapper">
          <CircularProgress />
        </div>
      );
    }

    return section;
  };

  getRadioSection = (address, placeHolder) => {
    const section = (
      <div
        className="delivery-address__outer-section-wrapper"
        key={address.addressLineOne}
      >
        <div className="delivery-address__radio-wrap">
          <Radio
            disabled={this.state.pickupOrDelivery === pickup}
            checked={this.state.selectedValue === placeHolder}
            onChange={this.handleRadioSelect}
            value={placeHolder}
            name={address.description}
            aria-label={placeHolder}
            className="delivery-address__radio"
          />
          <div className="delivery-address__address-wrapper">
            <div key={address.zipPostalCode} className="delivery-address__text">
              {address.description}
            </div>
            <div
              key={address.addressLineOne}
              className="delivery-address__text"
            >
              {address.addressLineOne}
            </div>
            <div key={address.city} className="delivery-address__text">
              {address.city}, {address.provinceStateValue}{' '}
              {address.zipPostalCode}
            </div>
          </div>
        </div>
      </div>
    );

    return section;
  };

  handleRadioSelect = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  handlePickupOrDelivery = (event) => {
    const { setPickup, setPickupDate } = this.props;
    let isPickup = false;
    if (event.target.value === pickup) {
      isPickup = true;
    }
    let numOut = 7;

    if (isPickup) {
      numOut = 2;
    }
    const defaultDate = moment(new Date(), 'MM/DD/YYYY')
      .add(numOut, 'days')
      .format('MM/DD/YYYY');

    this.setState({ pickupOrDelivery: event.target.value });
    setPickup(isPickup);

    if (!this.props.cart.preferences.dateSet) {
      setPickupDate(defaultDate);
    }
  };

  handleAddressSelection = () => {
    const {
      setDefaultAddress,
      cart: {
        addresses: { all },
      },
    } = this.props;
    const selected = parseInt(this.state.selectedValue, 10);
    const defaultAddress = all.data[selected];

    this.handleAddressCancel();
    setDefaultAddress(defaultAddress);
  };

  handleAddressCancel = () => {
    this.setState({ changeOpen: false });
  };

  handleOpen = () => {
    this.setState({ changeOpen: true });
  };

  handlePickupOnly = () => {
    this.setState({ pickupOrDelivery: 'pickup' });
  };

  render() {
    const section = this.getDefaultAddress();
    const dialogSection = this.getDialogSection();
    const branchSection = this.getBranchAddressSection();
    const {
      cart: { addresses },
      selectedAccount,
      getStates,
      saveNewAddress,
      getCounties,
      cart,
      errorLocation,
    } = this.props;

    const requirePickup = get(
      cart,
      'branchAddress.address.data.requireCustomerPickup',
      false
    );

    const pickupHeader = () => {
      if (!requirePickup) {
        return (
          <h2
            className="delivery-address__cart-sub-headline"
            data-test="pickup-or-deliver-header"
          >
            Pick Up or Delivery?
          </h2>
        );
      }
      return (
        <h2
          className="delivery-address__cart-sub-headline"
          data-test="pickup-or-deliver-header"
        >
          Pick Up
        </h2>
      );
    };
    const requirePickupButtons = () => {
      const disablePickupButton =
        errorLocation === addressError.BOTH ||
        errorLocation === addressError.PICKUP;
      const disableDeliveryButton =
        errorLocation === addressError.BOTH ||
        errorLocation === addressError.DELIVERY;

      const checkedPickupButton =
        !disablePickupButton && this.state.pickupOrDelivery === pickup;
      const checkedDeliveryButton =
        !disableDeliveryButton && !checkedPickupButton;

      if (!requirePickup) {
        return (
          <>
            <div className="delivery-address__radio-wrapper--secondary">
              <div
                className="delivery-address__radio-wrap"
                data-test="pickup-or-deliver-buttons-pickup"
              >
                <Radio
                  disabled={disablePickupButton}
                  checked={checkedPickupButton}
                  onChange={this.handlePickupOrDelivery}
                  value="pickup"
                  name="pickup"
                  label="Pick up at Branch"
                  className="delivery-address__pickup-radio"
                />
              </div>
            </div>
            {this.state.pickupOrDelivery === pickup && (
              <div className="delivery-address__inner-detail-section-wrapper">
                <div className="delivery-address__default-wrapper">
                  {branchSection}
                </div>
              </div>
            )}
            <div className="delivery-address__radio-wrapper--secondary">
              <div
                className="delivery-address__radio-wrap"
                data-test="pickup-or-deliver-buttons-delivery"
              >
                <Radio
                  disabled={disableDeliveryButton}
                  checked={checkedDeliveryButton}
                  onChange={this.handlePickupOrDelivery}
                  value="delivery"
                  name="delivery"
                  label="Deliver to Shipping Address"
                  className="delivery-address__address-radio"
                />
              </div>
            </div>
          </>
        );
      }
      this.handlePickupOnly();
      return (
        <>
          {this.state.pickupOrDelivery === pickup && (
            <div className="delivery_address__inner-detail-section-wrapper">
              <div className="delivery_address__default-address-wrapper">
                {branchSection}
              </div>
            </div>
          )}
        </>
      );
    };
    return (
      <div className="delivery-address delivery-address__detail-section-wrapper">
        <h2 className="delivery-address__cart-headline">Logistics</h2>
        {pickupHeader()}
        {requirePickupButtons()}
        {this.state.pickupOrDelivery === pickup ? null : (
          <div className="delivery-address__inner-detail-section-wrapper">
            <div className="delivery-address__default-address-wrapper">
              <span className="delivery-address__text--emphasized">
                Shipping Address
              </span>
              {section}
              <AddressForm
                addresses={addresses}
                getStates={getStates}
                saveNewAddress={saveNewAddress}
                selectedAccount={selectedAccount}
                getCounties={getCounties}
              />
            </div>
            <div className="delivery-address__change-button-outer-wrapper">
              {addresses.all &&
                addresses.all.data &&
                addresses.all.data.length > 1 && (
                  <button
                    className="delivery-address__change-button"
                    data-test="cart-logistics-change-address-toggle"
                    onClick={() => this.handleOpen()}
                  >
                    Change
                  </button>
                )}
            </div>
            <Dialog
              open={this.state.changeOpen}
              onClosing={this.handleAddressCancel}
            >
              <DialogHeader
                title="Change Shipping Address"
                rightNode={
                  <Icon
                    icon="close"
                    onClick={this.handleAddressCancel}
                    className="delivery-address__close-icon"
                  />
                }
              />
              <DialogContent>
                {dialogSection}
                <div className="delivery-address__button-wrapper">
                  <Button
                    secondary
                    className="delivery-address__address-button-cancel jsAddressCancel"
                    onClick={() => this.handleAddressCancel()}
                    margin="8"
                    data-test="cart-logistics-change-address-cancel"
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="delivery__address-button"
                    data-test="cart-logistics-change-address-confirm"
                    onClick={() => this.handleAddressSelection()}
                    margin="8"
                    size="large"
                  >
                    Use This Address
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

DeliveryAddress.propTypes = {
  addresses: PropTypes.shape({}),
  getAddress: PropTypes.func.isRequired,
  getBranchAddress: PropTypes.func.isRequired,
  setDefaultAddress: PropTypes.func.isRequired,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    balance: PropTypes.number,
    due: PropTypes.number,
    pastDue: PropTypes.number,
    number: PropTypes.string,
    branchId: PropTypes.string,
  }).isRequired,
  cart: PropTypes.shape({
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
        defaultAddress: PropTypes.shape({
          description: PropTypes.string,
          addressLineOne: PropTypes.string,
          addressLineTwo: PropTypes.string,
          city: PropTypes.string,
          provinceStateValue: PropTypes.string,
          zipPostalCode: PropTypes.string,
        }),
        error: PropTypes.string,
      }),
    }),
    isPickup: PropTypes.shape({
      pickup: PropTypes.bool,
    }),
    branchAddress: PropTypes.shape({
      address: PropTypes.shape({
        data: PropTypes.shape({
          requireCustomerPickup: PropTypes.bool,
        }),
      }),
      error: PropTypes.string,
    }),
    preferences: PropTypes.shape({
      dateSet: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  setPickup: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  getCounties: PropTypes.func.isRequired,
  saveNewAddress: PropTypes.func.isRequired,
  setPickupDate: PropTypes.func.isRequired,
  errorLocation: PropTypes.string.isRequired,
};

DeliveryAddress.defaultProps = {
  addresses: {},
};

export default DeliveryAddress;
