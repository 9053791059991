// access delegation
export const ACCESS_DELEGATION = 'ACCESS_DELEGATION';
export const DELEGATING_ACCESS = 'DELEGATING_ACCESS';
export const DELEGATE_ACCESS_SUCCESS = 'DELEGATE_ACCESS_SUCCESS';
export const DELEGATE_ACCESS_FAILURE = 'DELEGATE_ACCESS_FAILURE';
export const RESET_ACCESS_DELEGATION = 'RESET_ACCESS_DELEGATION';

// add user form
export const RESET_ADD_USER_FORM = 'RESET_ADD_USER_FORM';
export const UPDATE_ADD_USER_FORM = 'UPDATE_ADD_USER_FORM';
export const SET_ADD_USER_FORM_ERRORS = 'SET_ADD_USER_FORM_ERRORS';
export const CLEAR_ADD_USER_FORM_ERRORS = 'CLEAR_ADD_USER_FORM_ERRORS';

// permissions
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';
export const UPDATE_ROLES = 'UPDATE_ROLES';
