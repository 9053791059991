import { loginSuccess } from 'components/Authentication/actions/authenticationActions';
import { setToken } from 'shared/utils/tokenUtils';

import { fetchEmployeeInfo } from '../../actions';
import employeeLoggedIn from './employeeLoggedIn';

// this method is called when the session information has
// already been provided through the URL, no need to hit the server
// with creds to retrieve the session data
export default (session) => (dispatch) =>
  Promise.resolve()
    .then(() => setToken(session))
    .then(() => dispatch(loginSuccess(session)))
    .then(() => dispatch(fetchEmployeeInfo()))
    .then(() => dispatch(employeeLoggedIn()));
