import React from 'react';
import { NotificationsBadge } from 'components/Notifications';
import { NavLink } from 'react-router-dom';

const NotificationMobileMenuItem = () => (
  <NavLink
    data-test="headerNotificationLinkMobile"
    activeClassName="selected"
    className="menu__item"
    to="/notifications"
  >
    <NotificationsBadge className="mobile-header__notification-button" />
  </NavLink>
);

export default NotificationMobileMenuItem;
