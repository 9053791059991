import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@nutrien/uet-react/Typography';
import { makeStyles } from '@material-ui/core';

import currency from 'shared/utils/numberFormatters/currency';
import DateFormatter from 'shared/ui/DateFormatter';
import { InfoCard } from 'shared/ui/InfoCard';

const useStyles = makeStyles(() => ({
  prepayBalanceTypography: {
    fontWeight: 500,
  },
}));

const TotalPrepayBalanceCard = ({ account }) => {
  const classes = useStyles();
  return (
    account.prepayBalance !== 0 &&
    account.prepayBalance !== undefined && (
      <InfoCard
        title={<Typography component="span">Total Prepay Balance</Typography>}
      >
        <Typography
          variant="h1"
          data-test="total-prepay-balance"
          color="inherit"
          className={classes.prepayBalanceTypography}
        >
          {currency(account.prepayBalance, '')}
        </Typography>
        <p className="info-card-subtitle">As of: {DateFormatter('today')}</p>
      </InfoCard>
    )
  );
};

TotalPrepayBalanceCard.propTypes = {
  account: PropTypes.shape({
    prepayBalance: PropTypes.number.isRequired,
  }).isRequired,
};

export default TotalPrepayBalanceCard;
