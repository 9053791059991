import React from 'react';
import { string, func } from 'prop-types';
import { Button, Icon } from 'nutrien-common-components-react/dist/mdc';

import './SelectedDate.scss';

const SelectedDate = ({ date, onClick }) => (
  <div>
    <Button
      className="selected-date__button"
      data-test="selected-date-button"
      onClick={onClick}
      secondary
      size="medium"
      margin="small"
    >
      <span className="selected-date__date-text">{date} </span>
      <Icon icon="calendar" type="success" />
    </Button>
  </div>
);

SelectedDate.propTypes = {
  date: string.isRequired,
  onClick: func.isRequired,
};

export default SelectedDate;
