export default {
  downloadButton: {
    border: '1px solid lightgray',
    height: '32px',
    padding: '4px 12px',
    minHeight: '32px',
    width: '155px',
    display: 'flex',
    justifyContent: 'space-between',
    '&:focus': {
      outline: '2px solid lightgray',
    },
  },
  paper: {
    width: '16%',
  },
  '@media(max-width: 825px)': {
    DownloadOptionsComponent: {
      width: '100%',
    },
    downloadButton: {
      width: '100%',
    },
    paper: {
      width: '91%',
    },
  },
};
