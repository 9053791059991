import React from 'react';
import { WithFetchAccountsContainer } from 'components/Shop/containers/containers';
import { Route, Switch } from 'react-router';
import FlaggedRoute from '../../../FlaggedRoute';
import CartWrapper from '../CartWrapper';
import LogisticsPageContainer from '../../containers/LogisticsPageContainer/LogisticsPageContainer';
import ServicesPageContainer from '../../containers/ServicesPageContainer/ServicesPageContainer';
import ReviewPageContainer from '../../containers/ReviewPageContainer/ReviewPageContainer';
import ThankYouPageContainer from '../../containers/ThankYouPageContainer/ThankYouPageContainer';
import Page404 from '../../../Page404';

export function Cart() {
  return (
    <Switch>
      <Route exact path="/cart" component={CartWrapper} />
      <FlaggedRoute
        data-test="logistics-path"
        exact
        flagKey="global-ecommerce"
        path="/cart/logistics"
        component={LogisticsPageContainer}
      />
      <FlaggedRoute
        data-test="services-path"
        exact
        flagKey="global-ecommerce"
        path="/cart/services"
        component={ServicesPageContainer}
      />
      <FlaggedRoute
        data-test="review-path"
        exact
        flagKey="global-ecommerce"
        path="/cart/review"
        component={ReviewPageContainer}
      />
      <FlaggedRoute
        data-test="thank-you-path"
        exact
        flagKey="global-ecommerce"
        path="/cart/thank-you"
        component={ThankYouPageContainer}
      />
      <Route component={Page404} />
    </Switch>
  );
}

export default WithFetchAccountsContainer(Cart);
