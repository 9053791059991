import { gtmLogRemoveFromCart } from 'store/middleware/TagManager/gtmActions';

// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';

import deleteCartItemFailure from './deleteCartItemFailure';
import deleteCartItemSuccess from './deleteCartItemSuccess';
import updatingCart from './updatingCart';

export default (item, silentUpdate = false) => (dispatch) => {
  if (!silentUpdate) dispatch(updatingCart(true));

  return ecommApiService
    .delete(`/v2/cart-items/${item.id}`, {
      headers: {
        'account-id': item.product.attributes.account_id,
      },
    })
    .then((res) => {
      dispatch(gtmLogRemoveFromCart({ cartItem: item }));
      dispatch(deleteCartItemSuccess(item));
      dispatch(updatingCart(false));
      return res;
    })
    .catch((error) => {
      dispatch(deleteCartItemFailure(error));
      dispatch(updatingCart(false));
      return error;
    });
};
