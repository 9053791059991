// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';

import styles from './styles';

const FormTextField = (props) => {
  const { label, classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.loadingIconContainer}>
        <MaterialLoadingIcon size={35} />
        <div className={classes.label}>{label}</div>
      </div>
    </div>
  );
};

FormTextField.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

FormTextField.defaultProps = {
  label: 'Loading...',
};

export default withStyles(styles)(FormTextField);
