export default {
  cpsAccountInformation: {
    width: '100%',
  },
  notesLabel: {
    marginTop: '30px',
  },
  paymentNotes: {
    borderRadius: '2px',
    border: '1px solid #d7d7d7',
    marginBottom: '30px',
    width: '401px',
    resize: 'none',
    padding: '5px',
  },
  selectedCPSAccountContainer: {},
  '@media (max-width: 480px)': {
    selectedCPSAccountContainer: {
      '& textarea': {
        width: '100%',
      },
    },
  },
};
