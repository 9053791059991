import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Popper from '@nutrien/uet-react/Popper';
import qs from 'query-string';
import { fetchAssociatedOrgsAction as getAccounts } from 'shared/ui/AccountsTable/actions/AccountActions';
import { setGloballySelectedAccount } from 'actions/accountSelectorActions';
import disabledHeaderAccountSelectorHelper from './disabledHeaderAccountSelectorHelper';
import styles from './styles';

function HeaderAccountSelector(props) {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.accounts);
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const user = useSelector((state) =>
    get(state, 'user.userData', { userDetails: {} })
  );
  const { classes, history, location } = props;
  const shouldBeDisabled = disabledHeaderAccountSelectorHelper(
    location.pathname
  );
  const mutableAccts = [...accounts.list];
  const selectedAcctIndex = accounts.list.findIndex(
    (account) => account.id === selectedAccount.id
  );
  // Remove selected account from array and then put it back as the first element.
  mutableAccts.splice(selectedAcctIndex, 1);
  mutableAccts.unshift(selectedAccount);
  const [selectedAcctNum, setSelectedAcctNum] = useState(
    selectedAccount.number || get(user, 'userDetails.defaultAccountNumber')
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const useToggle = () => {
    const [disabledPopperAnchorElement, setEl] = useState(null);
    const toggleDisabledPopper = (event) => {
      setEl(disabledPopperAnchorElement ? null : event.currentTarget);
    };

    return [disabledPopperAnchorElement, toggleDisabledPopper];
  };

  const [disabledPopperAnchorElement, toggleDisabledPopper] = useToggle();

  const setURL = useCallback(
    (acctNum) => {
      const currentParams = qs.parse(window.location.search);

      // eslint-disable-next-line react/prop-types
      history.replace({
        ...location,
        search: qs.stringify({ ...currentParams, acct: acctNum }),
        isActive: true,
        headerSelector: true,
      });
    },
    [history, location]
  );
  const getSelectedAccount = useCallback(
    (number) => accounts.list.find((account) => account.number === number),
    [accounts.list]
  );
  const handleChange = (event) => {
    const updatedAccount = getSelectedAccount(event.target.value);
    dispatch(setGloballySelectedAccount(updatedAccount));
    setSelectedAcctNum(event.target.value);
    setURL(event.target.value);
  };

  const getSection = () => {
    switch (accounts.status) {
      case 'NO_ACCOUNT':
        return null;
      case 'FETCHING':
      case 'LOADING':
        return (
          <div
            data-test="account-selector-loading"
            className={classes.loadingWrapper}
          >
            <CircularProgress size={24} />
          </div>
        );
      case selectedAcctNum && 'OK':
        return (
          <>
            <Select
              data-test="header-account-selector-select"
              value={selectedAcctNum}
              onChange={handleChange}
              disableUnderline
              disabled={shouldBeDisabled}
              variant="standard"
              inputProps={{
                name: 'accountInfo',
                id: 'accounts',
              }}
              classes={{ disabled: classes.disabledHeaderAccountSelector }}
            >
              {mutableAccts.map((account) => {
                const name = account.nickname ? account.nickname : account.name;
                return (
                  account.number && (
                    <MenuItem value={account.number} key={account.number}>
                      {account.name && `${name} - ${account.number}`}
                    </MenuItem>
                  )
                );
              })}
            </Select>
            <div
              className={
                shouldBeDisabled
                  ? classes.disabledHeaderAccountSelectorOverlay
                  : null
              }
              onMouseEnter={!isMobile ? toggleDisabledPopper : null}
              onMouseLeave={!isMobile ? toggleDisabledPopper : null}
              onKeyDown={!isMobile ? toggleDisabledPopper : null}
              onClick={isMobile ? toggleDisabledPopper : null}
              tabIndex={0}
              role="button"
              data-test="disabled-header-account-selector-overlay"
              aria-label="Disabled Account Selector"
            />
            <Popper
              open={!!disabledPopperAnchorElement}
              anchorEl={disabledPopperAnchorElement}
              onClose={toggleDisabledPopper}
              placement="bottom"
              className={classes.disabledPopper}
              message="Farm Insights uses your Farms and Fields to navigate. You are unable to change your account on this page."
              data-test="disabled-header-account-selector-popper"
            />
          </>
        );
      case 'ERROR':
      default:
        return (
          <div
            data-test="account-selector-error"
            className={classes.accountSelectorError}
          >
            There was an error, please try again
          </div>
        );
    }
  };
  const accountNumber = qs.parse(location.search).acct;
  const fallbackNumber =
    selectedAccount.id || get(user, 'userDetails.defaultAccountNumber');
  useEffect(() => {
    if (accounts.status === 'LOADING') {
      dispatch(getAccounts((user.userDetails || {}).associatedOrgs || []));
    }
    let updatedAccount;
    if (accountNumber && accountNumber !== selectedAccount.id) {
      updatedAccount = getSelectedAccount(accountNumber);
    } else if (fallbackNumber && !selectedAccount.id) {
      updatedAccount = getSelectedAccount(fallbackNumber);
    }
    if (!accountNumber && fallbackNumber) {
      setURL(fallbackNumber);
    }
    if (updatedAccount) {
      dispatch(setGloballySelectedAccount(updatedAccount));
      setSelectedAcctNum(updatedAccount.number);
    }
  }, [
    dispatch,
    accounts,
    user.userDetails,
    location,
    accountNumber,
    fallbackNumber,
    selectedAccount.id,
    getSelectedAccount,
    setURL,
  ]);
  if (
    accounts.status === 'NO_ACCOUNTS' ||
    (!accountNumber && !fallbackNumber)
  ) {
    return null;
  }
  return (
    <form className={classes.root} autoComplete="off">
      <FormControl
        className={
          accounts.status === 'FETCHING'
            ? classes.formControlFetching
            : classes.formControl
        }
        fullWidth
      >
        <InputLabel
          htmlFor="accounts"
          shrink
          className={classes.formControlLabel}
        >
          Account
        </InputLabel>
        {getSection()}
      </FormControl>
    </form>
  );
}
HeaderAccountSelector.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({}),
  }).isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    formControl: PropTypes.string,
    formControlFetching: PropTypes.string,
    formControlLabel: PropTypes.string,
    loadingWrapper: PropTypes.string,
    accountLoadingIcon: PropTypes.string,
    accountSelectorError: PropTypes.string,
    disabledHeaderAccountSelector: PropTypes.string,
    disabledHeaderAccountSelectorOverlay: PropTypes.string,
    disabledPopper: PropTypes.string,
  }).isRequired,
};
export default withStyles(styles)(HeaderAccountSelector);
