import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  label: {
    textTransform: 'capitalize',
  },
  root: {
    backgroundColor: '#ffe000',
    height: '32px',
    minHeight: '32px',
    padding: '0 16px',
    color: '#222',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#fff600',
      boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 3px 5px 0 rgba(0,0,0,0.20)',
    },
    '&$disabled': {
      background: '#E0E0E0',
    },
  },
  disabled: {
    color: '#A6A6A6',
    background: '#E0E0E0',
  },
};

const MaterialButton = (props) => <Button {...props}>{props.children}</Button>;

MaterialButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(MaterialButton);
