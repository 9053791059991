import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';
import Notification from '@nutrien/uet-react/Notification';
import Select from '@nutrien/uet-react/Select';
import MenuItem from '@nutrien/uet-react/MenuItem';
import FormControl from '@nutrien/uet-react/FormControl';
import InputLabel from '@nutrien/uet-react/InputLabel';
import Pagination from '@nutrien/uet-react/lab/Pagination';
import Button from '@nutrien/uet-react/Button';
import {
  gtmAccountOverviewInteraction,
  gtmAccountOverviewFilterChange,
} from 'store/middleware/TagManager/gtmActions';
import { currency } from 'shared/utils/numberFormatters';
import moment from 'moment';
import DateFormatter from 'shared/ui/DateFormatter';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import PageNotification from 'shared/ui/NotificationHelper/PageNotification';
import PaymentDisabledNotification from 'shared/ui/Payments/PaymentDisabledNotification';

import { AccountingList, AccountingListItem } from 'shared/ui';
import { selectSelectedAccount, selectAccountActivity } from 'selectors';
import { viewAccount } from 'components/LinkAccount/accountLinkActions';
import { getAccountDetails } from '../actions/AccountActions';
import { INVOICE_STATUSES } from '../Invoices/actions';
import { updateInvoiceFilters } from '../Invoices/InvoiceList/InvoicesTable/actions';
import ExportAccountActivity from '../ExportAccountActivity';
import BalanceCards from './BalanceCards';

import useStyles from './styles';

const initialFilterState = {
  page: 1,
  periodFilter: 0,
  typeFilter: 'all',
};

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'setPage':
      return { ...state, page: action.value };
    case 'setPeriodFilter':
      return { ...state, periodFilter: action.value };
    case 'setTypeFilter':
      return { ...state, typeFilter: action.value };
    case 'resetState':
      return initialFilterState;
    default:
      throw new Error('unknown action.type passed to filter useReducer');
  }
};

const NO_ACTIVITY_FOUND =
  'No Activity in the last 30 days - Access statements to view historical account activity.';
const NO_ACTIVITY_FILTERED =
  'No Activity to display in this period. Select a different period.';
const END_OF_ACTIVITY = 'End of Account Activity.';

const Overview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id: paramsId } = useParams();
  const flags = useFlags();

  const account = useSelector(selectSelectedAccount());
  const accountActivity = useSelector(selectAccountActivity());
  const pageSize = 25;

  const [localState, localDispatch] = React.useReducer(
    filterReducer,
    initialFilterState
  );

  // TODO: this should be in a separate component
  const tableHeaders = [
    { text: 'Date', minSpace: true },
    { text: 'Transaction ID', minSpace: true },
    'Type and Description',
    { text: 'Amount', align: 'right', minSpace: true },
    { text: 'Due Date', minSpace: true },
    'Prepay',
  ];

  // This thing is ugly as heck, but I only changed what was necessary due to scope and stuff like that. Move it to a separate component at least
  const getRidOfThisGrotesquerie = () => {
    const today = new Date();
    const arr = [];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    arr.push({
      label: `Since Last Statement`,
      firstDay: new Date(today.getFullYear(), today.getMonth(), 1),
      lastDay: today,
    });

    arr.push({
      label: `All`,
      firstDay: new Date(0),
      lastDay: new Date(8640000000000000),
    });

    for (let i = 1; i < 25; i += 1) {
      const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const month = monthNames[d.getMonth()];
      const year = d.getFullYear();
      const dateRangeFirstDay = new Date(d.getFullYear(), d.getMonth(), 1);
      const dateRangeLastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
      const lastDayDate = dateRangeLastDay.getDate();
      const dateRangeObject = {
        label: `${month} 1, ${year} - ${month} ${lastDayDate}, ${year}`,
        firstDay: dateRangeFirstDay,
        lastDay: dateRangeLastDay,
      };
      arr.push(dateRangeObject);
    }

    return arr;
  };

  const lineItemMonthArray = getRidOfThisGrotesquerie();

  // TODO: get rid of this nasty stuff or put it in a separate component
  const lineItemMonth = lineItemMonthArray[localState.periodFilter];
  let firstDate;
  let lastDate;

  if (lineItemMonth) {
    firstDate = lineItemMonth.firstDay;
    lastDate = lineItemMonth.lastDay;
  }

  const refetchAccountActivity = React.useCallback(() => {
    if (account.id === paramsId) {
      localDispatch({ type: 'resetState' });
      dispatch(getAccountDetails(paramsId));
      dispatch(viewAccount());
    }
  }, [dispatch, account.id, paramsId]);

  React.useEffect(() => {
    refetchAccountActivity();
  }, [refetchAccountActivity, pathname]);

  if (!account) return null;

  const onPaginationClick = (event, newPage) => {
    event.preventDefault();
    localDispatch({ type: 'setPage', value: newPage });
  };

  const getMobileHeader = (headers, index) => {
    return headers[index].text || headers[index];
  };

  const handleFilterChange = (event, onChangeValue) => {
    const { value, name } = event.target;

    localDispatch({ type: 'setPage', value: 1 });

    if (name === 'typeFilter') {
      localDispatch({ type: 'setTypeFilter', value });
    } else {
      localDispatch({ type: 'setPeriodFilter', value });
    }

    dispatch(gtmAccountOverviewFilterChange(name, onChangeValue.key));
  };

  const deductionNotification = () => {
    const isCanadian = account.locationCode === 'CAN';
    const adjustedDate = DateFormatter(account.discountDate);
    const dueDate = moment(adjustedDate).format('MMMM Do, YYYY');
    const discountOrRebateAmount = currency(
      isCanadian
        ? account.currentAvailableRebates
        : account.currentAvailableDiscounts,
      'default'
    );
    const discountOrRebateText = isCanadian ? 'rebate' : 'discount';
    const dueDateText = isCanadian
      ? 'the last business day of February 2021'
      : dueDate;
    const notificationMessage = `A ${discountOrRebateText} of ${discountOrRebateAmount} is available on your currently due balance (seen below) if invoices and finance charges are paid on or before the due date (${dueDateText}).`;

    const discountOrRebateOffered = isCanadian
      ? account.rebatesOffered
      : account.discountsOffered;
    return (
      discountOrRebateOffered &&
      account.currentDue > 0 && (
        <PageNotification
          message={notificationMessage}
          key="discount-key"
          type="WARNING"
          linkDescription="View Details"
          link={`/accounts/${account.id}/invoices?typeFilter=${INVOICE_STATUSES.DUE_DISCOUNT_AVAILABLE}`}
          rightLink=""
        />
      )
    );
  };

  const filteredAccountActivity = accountActivity.list.filter((item) => {
    const itemDate = new Date(item.transactionDate);
    const isItemPrepay = item.prepayCode > 0;
    const { typeFilter } = localState;

    if (
      (typeFilter === 'Discount Earned' ||
        typeFilter === 'Credit Invoice' ||
        typeFilter === 'Invoice' ||
        typeFilter === 'Payment' ||
        typeFilter === 'Account Adjustment' ||
        typeFilter === 'Transfer') &&
      !item.type.includes(typeFilter)
    )
      return false;

    if (item.isSupressed) return false;
    if (itemDate < firstDate) return false;
    if (itemDate > lastDate) return false;
    if (typeFilter === 'Prepay' && !isItemPrepay) return false;
    if (typeFilter === 'NonPrepay' && isItemPrepay) return false;

    return true;
  });

  const paginatedFilteredAccountActivity = filteredAccountActivity.slice(
    (localState.page - 1) * pageSize,
    localState.page * pageSize
  );

  const listItems = paginatedFilteredAccountActivity.map((row, index) => {
    const prepay = row.prepayCode > 0 ? 'yes' : 'no';
    const i = index;
    let rowLink;

    if (
      row.type === 'Discount Earned' ||
      row.type === 'Credit Invoice' ||
      row.type === 'Invoice'
    ) {
      rowLink = `/accounts/${account.id}/invoices/${row.id}`;
    } else {
      rowLink = ``;
    }

    if (row.type === 'credit') {
      return (
        <tr className="activity-row" key={`${row.id}-${i}`}>
          <AccountingListItem
            data={DateFormatter(row.transactionDate)}
            mobileHeader={getMobileHeader(tableHeaders, 0)}
          />
          <AccountingListItem
            data={`${row.type} - ${row.transactionDescription}`}
            mobileHeader={getMobileHeader(tableHeaders, 2)}
            maxSpace
            noWrap
          />
          <AccountingListItem
            mobileHeader={getMobileHeader(tableHeaders, 1)}
            data={row.id}
            maxSpace
          />
          <AccountingListItem
            data={currency(row.originalAmount, 'credit', true)}
            mobileHeader={getMobileHeader(tableHeaders, 3)}
            minSpace
            right
          />
          <AccountingListItem
            data={DateFormatter(row.dueDate)}
            mobileHeader={getMobileHeader(tableHeaders, 4)}
          />
          <AccountingListItem
            mobileHeader={getMobileHeader(tableHeaders, 5)}
            data={prepay}
          />
        </tr>
      );
    }
    return (
      <tr
        className="activity-row activity-row-clickable activity-row-mobile"
        key={`${row.id}-${i}`}
      >
        <AccountingListItem
          data={DateFormatter(row.transactionDate)}
          mobileHeader={getMobileHeader(tableHeaders, 0)}
          minSpace
        />
        <AccountingListItem
          link={rowLink}
          mobileHeader={getMobileHeader(tableHeaders, 1)}
          data={row.id}
        />
        <AccountingListItem
          data={`${row.type} - ${row.transactionDescription}`}
          mobileHeader={getMobileHeader(tableHeaders, 2)}
          maxSpace
          noWrap
        />
        <AccountingListItem
          data={currency(row.originalAmount, '', true)}
          mobileHeader={getMobileHeader(tableHeaders, 3)}
          minSpace
          right
        />
        <AccountingListItem
          data={DateFormatter(row.dueDate)}
          mobileHeader={getMobileHeader(tableHeaders, 4)}
          minSpace
        />
        <AccountingListItem
          mobileHeader={getMobileHeader(tableHeaders, 5)}
          data={prepay}
        />
      </tr>
    );
  });

  const lineItemMonthList = lineItemMonthArray.map((row, index) => (
    <MenuItem key={row.label} value={index}>
      {row.label}
    </MenuItem>
  ));

  const titleBarContent = (
    <div className={classes.overviewFilterTitle}>
      <FormControl className={classes.overviewFilterLineItems}>
        <InputLabel id="filter" className={classes.filterLabel}>
          Filter
        </InputLabel>
        <Select
          labelId="filter"
          name="typeFilter"
          defaultValue="all"
          value={localState.typeFilter ?? 'all'}
          onChange={handleFilterChange}
        >
          <MenuItem key="option-all" value="all">
            All
          </MenuItem>
          <MenuItem key="option-invoices" value="Invoice">
            Invoices
          </MenuItem>
          <MenuItem key="option-credit" value="Credit Invoice">
            Credit Invoices
          </MenuItem>
          <MenuItem key="option-payment" value="Payment">
            Payment
          </MenuItem>
          <MenuItem key="option-discount" value="Discount Earned">
            Discount Earned
          </MenuItem>
          <MenuItem key="option-transfer" value="Transfer">
            Transfer
          </MenuItem>
          <MenuItem key="option-adjusment" value="Account Adjustment">
            Account Adjustment
          </MenuItem>
          <MenuItem key="option-prepay" value="Prepay">
            Prepay Items Only
          </MenuItem>
          <MenuItem key="option-nonprepay" value="NonPrepay">
            Non-Prepay Items Only
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.overviewFilterLineItems}>
        <InputLabel className={classes.filterLabel} id="period">
          Period
        </InputLabel>
        <Select
          labelId="period"
          name="periodFilter"
          defaultValue={0}
          value={localState.periodFilter}
          onChange={handleFilterChange}
        >
          {lineItemMonthList}
        </Select>
      </FormControl>
    </div>
  );

  const activityNotificationMessage = () => {
    if (accountActivity.list.length === 0) {
      return NO_ACTIVITY_FOUND;
    }
    if (filteredAccountActivity.length === 0) {
      return NO_ACTIVITY_FILTERED;
    }
    if (filteredAccountActivity.length <= pageSize * localState.page) {
      return END_OF_ACTIVITY;
    }
    return '';
  };

  let overdueNotification;
  if (account.pastDue > 0) {
    overdueNotification = (
      <PageNotification
        message={`${currency(
          account.pastDue
        )} is past due and included in your Currently Due amount of ${currency(
          account.currentDue
        )}.`}
        type="DANGER"
        linkDescription="View Details"
        link={`/accounts/${account.id}/invoices`}
        rightLink=""
        onClick={() => {
          dispatch(
            gtmAccountOverviewInteraction(
              'Click',
              'Past Due | View Details Link'
            )
          );
          dispatch(updateInvoiceFilters({ status: 'Past Due' }));
        }}
      />
    );
  }

  const pageCount = Math.ceil(filteredAccountActivity.length / pageSize);

  const handlePrevPaginationClick = () => {
    localDispatch({
      type: 'setPage',
      value: localState.page - 1,
    });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleNextPaginationClick = () => {
    localDispatch({
      type: 'setPage',
      value: localState.page + 1,
    });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  const recentActivityTable = (
    <>
      {overdueNotification}
      {deductionNotification()}
      <BalanceCards account={account} />

      <div className="account-info-container">
        <div className="export-activity-section">
          <ExportAccountActivity />
          <div className="transaction-message">
            <p className={classes.accountOverviewRecentMessage}>
              Recent transactions may take 48 hours to post.
            </p>
          </div>
        </div>
        {!flags.payments && <PaymentDisabledNotification />}
      </div>

      <AccountingList
        title="Account Activity"
        headers={tableHeaders}
        titleBarContent={titleBarContent}
      >
        {listItems}
      </AccountingList>

      {activityNotificationMessage() === NO_ACTIVITY_FOUND && (
        <div className="no-account-activity">
          <Notification variant="info" data-test="account-activity-not-found">
            {NO_ACTIVITY_FOUND}
          </Notification>
        </div>
      )}

      {activityNotificationMessage() === NO_ACTIVITY_FILTERED && (
        <div className="no-account-activity">
          <Notification
            variant="info"
            data-test="account-activity-filtered-not-found"
          >
            {NO_ACTIVITY_FILTERED}
          </Notification>
        </div>
      )}

      {activityNotificationMessage() === END_OF_ACTIVITY && (
        <div className="end-of-activity">{END_OF_ACTIVITY}</div>
      )}

      {filteredAccountActivity.length > pageSize && (
        <div className={classes.paginationContainer}>
          <Button
            onClick={handlePrevPaginationClick}
            disabled={localState.page === 1}
            className={classes.paginationButton}
          >
            Prev
          </Button>
          <Pagination
            page={localState.page}
            total={filteredAccountActivity.length}
            onChange={onPaginationClick}
            count={pageCount}
            className={classes.pagination}
            shape="rounded"
            color="primary"
            hidePrevButton
            hideNextButton
          />
          <Button
            onClick={handleNextPaginationClick}
            disabled={localState.page === pageCount}
            className={classes.paginationButton}
          >
            Next
          </Button>
        </div>
      )}
    </>
  );

  let overviewContent;
  switch (accountActivity.status) {
    case 'LOADING':
      overviewContent = <MaterialLoadingIcon />;
      break;
    case 'OK':
      overviewContent = recentActivityTable;
      break;
    default:
      overviewContent = <ApiErrorMessage entityName="Recent Activity" />;
      break;
  }

  return <div className="account-overview">{overviewContent}</div>;
};

Overview.defaultProps = {
  flags: {},
  ldClient: {},
};

Overview.propTypes = {
  flags: PropTypes.shape({
    payments: PropTypes.bool,
    canadaPayments: PropTypes.bool,
  }),
  ldClient: PropTypes.shape({}),
};

export default withLDConsumer()(Overview);
