import { arrayOf, number, shape, string, bool } from 'prop-types';

export const TopProductsPropType = shape({
  status: string,
  list: arrayOf(
    shape({
      productNumber: number,
      description: string,
      sdsAndLabels: string,
      shelf: string,
      quantity: number,
      cost: number,
      uom: string,
      latestInvoiceDate: string,
      extended: bool,
    })
  ),
});

export default TopProductsPropType;
