import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import moment from 'moment';

import './DeliveryDate.scss';

const DeliveryDate = (props) => {
  const { cart } = props;

  let deliveryDate;
  let isCustomerPickup;

  const attributes = get(cart, 'postOrder.data.data.attributes', {});

  if (!isEmpty(attributes)) {
    isCustomerPickup = attributes.customerPickup;
    deliveryDate = attributes.deliveryApplicationDate;
  }

  return (
    <div>
      <div className="delivery__headline" data-test="delivery-headline">
        {isCustomerPickup ? 'Pickup' : 'Delivery'} Date
      </div>
      <div className="delivery__headline--detail">
        {moment(deliveryDate, 'YYYY-MM-DD').format('dddd, MMMM DD, YYYY')}
      </div>
    </div>
  );
};

DeliveryDate.propTypes = {
  cart: PropTypes.shape({
    submittedCartData: PropTypes.array.isRequired,
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        error: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export default DeliveryDate;
