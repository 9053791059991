import React from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MINIMUM_BULK_QUANTITY from 'shared/constants/minimumBulkQuantity';
import {
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Table,
} from 'nutrien-common-components-react/dist/mdc/Table';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { currency } from 'shared/utils/numberFormatters';
import { customFormat } from 'shared/utils/DateHelper';
import SdsAndLabelsLink from './SdsAndLabelsLink';
import ProductHistoryCheckbox from './ProductHistoryCheckbox';
import ReorderOrRequestInfoButton from './ReorderOrRequestInfoButton';

import SortableColumn from './SortableColumn';
import { ProductHistoryObjectArrayType } from '../../ProductHistoryPropType';

/*
    This is a temporary hack to stop the user from reordering returnable containers.
    A tech improvement ticket has been logged to clean this up - https://agrium.atlassian.net/browse/EC-1830
  */
const shouldShowReorderButton = (description) =>
  description !== 'RETURNABLE CONTAINERS';

const ProductHistoryTable = ({
  fullProductHistoryList,
  productHistoryList,
  sortedColumn,
  selectedAccount,
}) => {
  const {
    canadaReadOnly,
    globalEcommerce,
    usaSapOrders,
    westernRegionRestrictProducts,
  } = useFlags();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // tablets and phones
  const isMobileXs = useMediaQuery('(max-width: 480px)'); // phones

  const isCustomBlend = (product) =>
    product.description &&
    product.description.toUpperCase() === 'CUSTOM BLEND MATERIAL';
  const formatPrice = (product) =>
    isCustomBlend(product) ? 'See below' : currency(product.cost);

  const renderTableCellMobile = (header, value, columnClass) => (
    <TableCell
      className={`product-history__table-${columnClass} product-history__table-list-item-mobile`}
    >
      <span className="product-history__table-mobile-heading">{header}</span>
      <span className="product-history__table-mobile-value">{value}</span>
    </TableCell>
  );

  const renderButton = (productHistory) =>
    shouldShowReorderButton(productHistory.description) && !canadaReadOnly ? (
      <ReorderOrRequestInfoButton
        data-test={`product-history-reorder-or-requestInfo-button-${productHistory.productNumber}`}
        productNumber={productHistory.productNumber}
        extended={productHistory.extended}
        shelf={productHistory.shelf}
        status={
          !westernRegionRestrictProducts && !usaSapOrders
            ? productHistory.status
            : 0
        }
        description={productHistory.description}
        quantity={
          productHistory.isBulk
            ? MINIMUM_BULK_QUANTITY
            : productHistory.quantity
        }
        unitOfMeasure={productHistory.uom}
      />
    ) : null;

  const renderTableRowMobile = (productHistory, selectedAccount) => (
    <>
      {renderTableCellMobile('Name', productHistory.description, 'name')}
      {renderTableCellMobile(
        'SDS & Labels',
        <SdsAndLabelsLink
          relativeLink={productHistory.sdsAndLabels}
          selectedAccount={selectedAccount}
        />,
        'sdsAndLabels'
      )}
      {renderTableCellMobile('Shelf', productHistory.shelf, 'shelf')}
      {renderTableCellMobile(
        'Quantity',
        `${productHistory.quantity} ${productHistory.uom}`,
        'quantity'
      )}
      {renderTableCellMobile('Cost', formatPrice(productHistory), 'cost')}
      {renderTableCellMobile(
        'Last Invoiced',
        customFormat(productHistory.latestInvoiceDate, 'M/D/YYYY'),
        'date'
      )}
      {globalEcommerce && (
        <TableCell
          data-test={`product-history-reorder-or-requestInfo-button-flag-${productHistory.productNumber}`}
          className="product-history__table-button-container product-history__table-list-item-mobile"
        >
          {renderButton(productHistory)}
        </TableCell>
      )}
    </>
  );

  /**
   * This is a temporary solution to make this table responsive for the mobile app release.
   * Will be removed and refactored once the mobile responsive UET tables are ready and released.
   */
  const mapProductHistoryListMobile = () =>
    productHistoryList.map((productHistory) => (
      <TableRow key={productHistory.productNumber}>
        {isMobileXs ? (
          renderTableRowMobile(productHistory, selectedAccount)
        ) : (
          <div className="product-history__table-row-mobile">
            {renderTableRowMobile(productHistory, selectedAccount)}
          </div>
        )}
      </TableRow>
    ));

  const mapProductHistoryListDesktop = () =>
    productHistoryList.map((productHistory) => (
      <TableRow key={productHistory.productNumber}>
        <TableCell className="product-history__table-checkbox">
          <ProductHistoryCheckbox
            productHistoryList={fullProductHistoryList}
            productNumber={productHistory.productNumber}
            checked={productHistory.isChecked}
          />
        </TableCell>
        <TableCell className="product-history__table-product-number">
          {productHistory.productNumber}
        </TableCell>
        <TableCell className="product-history__table-description">
          {productHistory.description}
        </TableCell>
        <TableCell className="product-history__table-sdsAndLabels">
          <SdsAndLabelsLink
            relativeLink={productHistory.sdsAndLabels}
            selectedAccount={selectedAccount}
          />
        </TableCell>
        <TableCell className="product-history__table-shelf">
          {productHistory.shelf}
        </TableCell>
        <TableCell className="product-history__table-quantity">
          {`${productHistory.quantity} ${productHistory.uom}`}
        </TableCell>
        <TableCell className="product-history__table-cost">
          {formatPrice(productHistory)}
        </TableCell>
        <TableCell className="product-history__table-date">
          {customFormat(productHistory.latestInvoiceDate, 'M/D/YYYY')}
        </TableCell>

        {globalEcommerce && (
          <TableCell
            data-test={`product-history-reorder-or-requestInfo-button-flag-${productHistory.productNumber}`}
          >
            {renderButton(productHistory)}
          </TableCell>
        )}
      </TableRow>
    ));

  const renderSortableColumn = (name, sortField) => (
    <SortableColumn
      name={name}
      sortField={sortField}
      productHistoryList={fullProductHistoryList}
      caretVisible={sortedColumn === sortField}
    />
  );

  return (
    <div className="product-history__table">
      <Table>
        {isMobile ? null : (
          <TableHead>
            <TableRow className="product-history__table-column-headers">
              <TableCell className="product-history__table-checkbox">
                <ProductHistoryCheckbox
                  productHistoryList={fullProductHistoryList}
                  isColumnHeader
                />
              </TableCell>
              <TableCell className="product-history__table-product-number">
                {renderSortableColumn('Product #', 'productNumber')}
              </TableCell>
              <TableCell>
                {renderSortableColumn('Name', 'description')}
              </TableCell>
              <TableCell className="product-history__table-sdsAndLabels">
                {renderSortableColumn('SDS & Labels', 'sdsAndLabels')}
              </TableCell>
              <TableCell className="product-history__table-shelf">
                {renderSortableColumn('Shelf', 'shelf')}
              </TableCell>
              <TableCell className="product-history__table-quantity">
                {renderSortableColumn('Quantity', 'quantity')}
              </TableCell>
              <TableCell className="product-history__table-cost-column">
                {renderSortableColumn('Cost', 'cost')}
              </TableCell>
              <TableCell className="product-history__table-date">
                {renderSortableColumn('Last Invoice', 'latestInvoiceDate')}
              </TableCell>
              {globalEcommerce && (
                <TableCell
                  data-test="product-history-button-column"
                  className="product-history__table-button-column"
                />
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {isMobile
            ? mapProductHistoryListMobile()
            : mapProductHistoryListDesktop()}
        </TableBody>
      </Table>
    </div>
  );
};

ProductHistoryTable.propTypes = {
  selectedAccount: PropTypes.shape({}),
  productHistoryList: ProductHistoryObjectArrayType.isRequired,
  fullProductHistoryList: ProductHistoryObjectArrayType.isRequired,
  sortedColumn: PropTypes.string,
};

ProductHistoryTable.defaultProps = {
  sortedColumn: '',
  selectedAccount: {},
};

export default ProductHistoryTable;
