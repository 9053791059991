import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as cartActions from '../../actions/cartActions';

import PurchaseOrderNumber from '../../components/PurchaseOrderNumber';

const mapStateToProps = ({ cart }) => ({
  cartItems: cart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...cartActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderNumber);
