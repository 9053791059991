import {
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATION_READ_STATUS_TOGGLED,
  NOTIFICATION_UPDATE,
  NOTIFICATION_READ_STATUSES_TOGGLED,
  NOTIFICATIONS_DELETED,
  NOTIFICATIONS_STAGED_FOR_DELETION,
  NOTIFICATIONS_UNDO_DELETION,
} from '../actions';
import markNotificationRead from './markNotificationRead';
import updateNotificationsReadStatuses from './updateNotificationsReadStatuses';
import updateNotification from './updateNotification';
import deleteNotification from './deleteNotification';
import deleteNotifications from './deleteNotifications';

const initialState = {
  current: [],
  prev: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_FETCH_SUCCESS:
      return { current: action.notifications };
    case NOTIFICATION_READ_STATUS_TOGGLED:
      return { current: markNotificationRead(state.current, action) };
    case NOTIFICATION_READ_STATUSES_TOGGLED:
      return {
        current: updateNotificationsReadStatuses(state.current, action),
      };
    case NOTIFICATION_UPDATE:
      return { current: updateNotification(state.current, action) };
    case NOTIFICATION_DELETE_SUCCESS:
      return { current: deleteNotification(state.current, action) };
    case NOTIFICATIONS_STAGED_FOR_DELETION:
      return {
        current: deleteNotifications(state.current, action),
        prev: state.current,
      };
    case NOTIFICATIONS_DELETED:
      return {
        current: deleteNotifications(state.current, action),
        prev: null,
      };
    case NOTIFICATIONS_UNDO_DELETION:
      return { current: state.prev, prev: null };
    default:
      return state;
  }
};
