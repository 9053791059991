const styles = {
  listElement: {
    color: '#828282',
    margin: '10px 0',
    fontSize: '13px',
    fontWeight: 200,
    lineHeight: 1.15,
  },
  boldText: {
    color: '#000',
    fontWeight: 'bold',
  },
};

export default styles;
