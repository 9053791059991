import { connect } from 'react-redux';

import { fetchCustomersByAccount } from '../actions';
import CustomerLookup from './CustomerLookup';

const mapDispatchToProps = {
  onSubmit: fetchCustomersByAccount,
};

export default connect(undefined, mapDispatchToProps)(CustomerLookup);
