import {
  setTopProductsLoading,
  setTopProductsOk,
  setTopProductsError,
  setTopProductsEmpty,
} from './TopProductsActionCreators';
import { getPricesForProducts } from '../../Shop/actions/productActions/productActions';
import getTopProductsWithinTwoMonthsLastYear from './getTopProductsWithinTwoMonthsLastYear';

const productPricesResponseTransform = (topProductsResponse) => {
  const productPricesDataArray = [];
  topProductsResponse.forEach((data) => {
    productPricesDataArray.push({
      attributes: {
        variants: [
          {
            ars_key: data.productNumber.toString(),
          },
        ],
      },
    });
  });
  return productPricesDataArray;
};

export const fetchTopProducts = (account, startDate, endDate) => (dispatch) => {
  dispatch(setTopProductsLoading());
  return getTopProductsWithinTwoMonthsLastYear(
    account.id,
    startDate,
    endDate,
    account.branchId
  )
    .then((response) => {
      if (response.data.length === 0) {
        dispatch(setTopProductsEmpty());
      }

      if (response.data.length > 0) {
        const productPricesDataArray = productPricesResponseTransform(
          response.data
        );
        dispatch(getPricesForProducts(account, productPricesDataArray));
        dispatch(setTopProductsOk(response.data));
      }

      if (response.data === undefined) {
        dispatch(setTopProductsError());
      }
    })
    .catch((e) => {
      if (/status code 404/.test(e.message)) {
        dispatch(setTopProductsEmpty());
      } else {
        dispatch(setTopProductsError());
      }
    });
};

export const resetTopProducts = () => (dispatch) => {
  dispatch(setTopProductsLoading());
};
