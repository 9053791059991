import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';

import CustomerList from '../../Customers/CustomerList';
import CustomerLookup from '../../Customers/CustomerLookup';

const styles = {
  root: {
    padding: '2em 0',
  },
  lookupForm: {
    marginBottom: '1em',
  },
};

export const SupportExperience = ({
  classes,
  displayTitle,
  isFetching,
  customers,
}) => (
  <div className={classNames('employee-support-experience', classes.root)}>
    {displayTitle && <h3>Customer Lookup</h3>}
    <div className={classes.lookupForm}>
      <CustomerLookup />
    </div>
    {isFetching && <MaterialLoadingIcon />}
    <CustomerList customers={customers} />
  </div>
);

SupportExperience.defaultProps = {
  displayTitle: false,
  isFetching: false,
  customers: [],
};

SupportExperience.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  displayTitle: PropTypes.bool,
  isFetching: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(SupportExperience);
