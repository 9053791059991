import * as actionTypes from '../../actions/constants';

const initialSearchState = {
  query: '',
  searchEntered: false,
};

export default (state = initialSearchState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_QUERY: {
      const { query } = action;
      return {
        ...state,
        query,
        searchEntered: true,
      };
    }
    case actionTypes.RESET_SEARCH: {
      return {
        ...state,
        query: '',
        searchEntered: false,
      };
    }
    default:
      return state;
  }
};
