import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@nutrien/uet-react/Tooltip';
import Typography from '@material-ui/core/Typography';

import { gtmAccountOverviewInteraction } from 'store/middleware/TagManager/gtmActions';
import currency from 'shared/utils/numberFormatters/currency';
import DateFormatter from 'shared/ui/DateFormatter';
import { InfoCard } from 'shared/ui/InfoCard';
import HelpIcon from 'assets/help.svg';
import useStyles from '../styles';

const TotalBalanceCard = ({ account }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const availableItemType =
    account.locationCode === 'CAN' ? 'rebates' : 'discounts';
  const tooltipTitle = `This is the total account balance, including available ${availableItemType} and excluding prepay items.`;

  const currentBalanceCardTitle = (
    <>
      <Typography component="span">Current Total Non-Prepay Balance</Typography>
      <Tooltip
        title={tooltipTitle}
        placement="top-start"
        enterTouchDelay={0}
        leaveTouchDelay={7000}
        data-test="total-non-prepay-balance-tooltip"
        onOpen={() =>
          dispatch(
            gtmAccountOverviewInteraction(
              'Hover',
              'Current Total Non-Prepay Balance | Question Mark'
            )
          )
        }
      >
        <img
          src={HelpIcon}
          className={classes.accountOverviewMoreInfoIcon}
          alt="click for more information"
          data-test="total-non-prepay-balance-tooltip-icon"
        />
      </Tooltip>
    </>
  );

  return (
    <InfoCard title={currentBalanceCardTitle}>
      <h1 className="info-card-h1" data-test="total-non-prepay-balance">
        {currency(account.balance, '')}
      </h1>
      <p className="info-card-subtitle">As of: {DateFormatter('today')}</p>
    </InfoCard>
  );
};

TotalBalanceCard.propTypes = {
  account: PropTypes.shape({
    balance: PropTypes.number.isRequired,
    locationCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default TotalBalanceCard;
