import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: { color: theme.palette.grey[400] },
});

const NoPermitsMessage = ({ permitFilter, classes }) => {
  if (permitFilter === 'All')
    return (
      <Typography align="center" className={classes.root}>
        There are no Permits at this time.
      </Typography>
    );

  return (
    <Typography align="center" className={classes.root}>
      {`There are no ${permitFilter} Permits at this time.`}
    </Typography>
  );
};

NoPermitsMessage.propTypes = {
  permitFilter: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

NoPermitsMessage.defaultProps = {
  permitFilter: '',
  classes: {},
};

export default withStyles(styles)(NoPermitsMessage);
