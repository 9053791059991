import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { fetchEmployeeInfo } from '../actions';
import { logoutEmployee } from '../EmployeeLogin/actions';
import Header from './EmployeeHeader';

export class HeaderContainer extends Component {
  componentDidMount() {
    if (!this.props.user.userName) {
      this.props.fetchEmployeeInfo();
    }
  }

  render() {
    return <Header {...this.props} logout={this.props.logoutEmployee} />;
  }
}

HeaderContainer.propTypes = {
  user: PropTypes.shape({
    userName: PropTypes.string,
  }).isRequired,
  browser: PropTypes.shape({ isSupported: PropTypes.bool }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  fetchEmployeeInfo: PropTypes.func.isRequired,
  logoutEmployee: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  let userName = null;

  const { employee } = state;
  const { isLoggedIn } = employee.employeeLogin;
  const { isMirroring } = employee.mirroring;
  const { userDetails } = employee.user.info;

  if (userDetails) {
    const { username } = userDetails;
    if (username.includes('\\')) {
      userName = username.split('\\', 2)[1]; // eslint-disable-line prefer-destructuring
    } else {
      userName = username;
    }
  }

  return {
    user: {
      userName,
    },
    browser: state.browser,
    isLoggedIn,
    location: state.router.location,
    currentPath: get(state, 'router.location.pathname'),
    isMirroring,
  };
};

export const mapDispatchToProps = { fetchEmployeeInfo, logoutEmployee, push };

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
