const styles = (theme) => ({
  submitButton: {
    width: '200px',
    margin: 'auto',
  },
  noAccountsContainer: {
    '@media(max-width: 480px)': {
      padding: '15px',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '100px',
    '@media(max-width: 480px)': {
      marginTop: '50px',
    },
  },
  content: {
    width: '407px',
    margin: '20px auto',
    '& div': {
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#828282',
    },
    '@media(max-width: 480px)': {
      width: '100%',
    },
  },
  managePaymentHeader: {
    fontSize: '32px',
    fontWeight: '600',
    color: theme.palette.text.secondary,
    marginBottom: '15px',
  },
});

export default styles;
