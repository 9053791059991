import cloneDeep from 'lodash/cloneDeep';

import { combineReducers } from 'redux';
import { PRODUCT_HISTORY_ADD_TO_CART_FAILURE } from 'components/ProductHistory/ProductHistoryActionTypes';
import * as actionTypes from '../../actions/actionTypes';

const {
  LOADING_CART,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,
  UPDATING_CART,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_SUCCESS,
  REMOVE_CART_ITEMS,
  GET_ADDRESSES_FAILURE,
  GET_ADDRESSES_SUCCESS,
  UPDATING_ADDRESS,
  SET_DEFAULT_ADDRESS,
  SET_PICKUP,
  GET_COUNTIES_SUCCESS,
  GET_COUNTIES_FAILURE,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
  SAVE_ADDRESS_SUCCESS,
  SAVE_ADDRESS_FAILURE,
  GET_BRANCH_ADDRESS_SUCCESS,
  GET_BRANCH_ADDRESS_FAILURE,
  UPDATING_BRANCH_ADDRESS,
  SET_SELECTED_ACCOUNT,
  LOADING_ORDER,
  POST_ORDER_SUCCESS,
  POST_ORDER_FAILURE,
  SET_PICKUP_DATE,
  SET_ORDER_MEMO,
  SET_PURCHASE_ORDER_NUMBER,
  SET_CUSTOM_APPLIED,
  SET_APPLICATION_MEMO,
  SET_CUSTOM_CROP_CARE,
  VALIDATE_INPUT,
} = actionTypes;

export const status = (state = '', action) => {
  if (actionTypes[action.type]) return action.type;
  return state;
};

export const branchAddress = (state = {}, action) => {
  switch (action.type) {
    case GET_BRANCH_ADDRESS_SUCCESS: {
      const { data } = action;
      const address = {
        data,
        error: null,
      };
      return {
        ...state,
        address,
      };
    }
    case GET_BRANCH_ADDRESS_FAILURE: {
      const { error } = action;
      const address = {
        ...state.address,
        error,
      };
      return { ...state, address };
    }
    case UPDATING_BRANCH_ADDRESS: {
      const { updating } = action;
      return { ...state, updatingBranchAddress: updating };
    }
    default:
      return state;
  }
};

export const mostRecentAddition = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CART_SUCCESS: {
      return action.data;
    }
    case ADD_TO_CART_SUCCESS: {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export const addresses = (state = {}, action) => {
  switch (action.type) {
    case GET_ADDRESSES_SUCCESS: {
      const { data } = action;
      const all = {
        ...state.all,
        data,
        defaultAddress: data[0],
        error: null,
      };
      return {
        ...state,
        all,
      };
    }
    case GET_ADDRESSES_FAILURE: {
      const { error } = action;
      const all = {
        ...state.all,
        error,
      };
      return { ...state, all };
    }
    case UPDATING_ADDRESS: {
      const { updating } = action;
      const all = {
        ...state.all,
        updatingAddress: updating,
      };
      return { ...state, all };
    }
    case SET_DEFAULT_ADDRESS: {
      const { data } = action;
      const all = {
        ...state.all,
        defaultAddress: data,
      };
      return { ...state, all };
    }
    case GET_STATES_SUCCESS: {
      const { data } = action;
      const stateList = {
        data,
        error: null,
      };
      return { ...state, stateList };
    }
    case GET_STATES_FAILURE: {
      const { error } = action;
      const stateList = {
        ...state.stateList,
        error,
      };
      return { ...state, stateList };
    }
    case GET_COUNTIES_SUCCESS: {
      const { data } = action;
      const countyList = {
        data,
        error: null,
      };
      return { ...state, countyList };
    }
    case GET_COUNTIES_FAILURE: {
      const { error } = action;
      const countyList = {
        ...state.countyList,
        error,
      };
      return { ...state, countyList };
    }
    case SAVE_ADDRESS_SUCCESS: {
      const { data } = action;
      const all = {
        ...state.all,
        defaultAddress: data,
        error: null,
      };
      return { ...state, all };
    }
    case SAVE_ADDRESS_FAILURE: {
      const { error } = action;
      const all = {
        ...state.all,
        error,
      };
      return { ...state, all };
    }
    default:
      return state;
  }
};

export const cartData = (state = [], action) => {
  switch (action.type) {
    case GET_CART_SUCCESS: {
      const { data } = action;
      return [].concat(data);
    }
    case UPDATE_CART_SUCCESS: {
      const { data } = action;
      const { id } = data;
      const index = state.findIndex((item) => item.id === id);
      const newState = [].concat(state);
      newState[index] = {
        ...newState[index],
        ...data,
      };
      return newState;
    }
    case VALIDATE_INPUT: {
      const {
        payload: { id, validationResult },
      } = action;
      const index = state.findIndex((item) => item.id === id);
      const newState = [].concat(state);
      newState[index] = {
        ...newState[index],
        validationResult,
      };
      return newState;
    }
    case DELETE_ITEM_SUCCESS: {
      return state.filter(
        (item) => item.variant.id !== action.payload.variant.id
      );
    }
    case ADD_TO_CART_SUCCESS: {
      const cartItem = {
        id: null,
        account_id: null,
        variant_sku: null,
        product_name: null,
        variant_size: null,
        uom: null,
        quantity: null,
        price_per_unit: null,
        total_price: null,
        created_at: null,
        updated_at: null,
        ...action.data,
      };
      return [...state, cartItem];
    }
    case REMOVE_CART_ITEMS: {
      return [];
    }
    default:
      return state;
  }
};

export const cartLoading = (state = true, action) => {
  switch (action.type) {
    case LOADING_CART: {
      return action.loading;
    }
    case GET_CART_SUCCESS: {
      return action.loading;
    }
    case GET_CART_FAILURE: {
      return action.loading;
    }
    case DELETE_ITEM_FAILURE: {
      return action.loading;
    }
    default:
      return state;
  }
};

export const selectedAccount = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const cartError = (state = null, action) => {
  switch (action.type) {
    case UPDATE_CART_FAILURE:
    case DELETE_ITEM_FAILURE:
    case ADD_TO_CART_FAILURE:
      return action.error;

    case LOADING_CART:
      return null;

    default:
      return state;
  }
};

export const isPickup = (state = { pickup: true }, action) => {
  switch (action.type) {
    case SET_PICKUP: {
      const { pickup } = action;
      return { ...state, pickup };
    }
    default:
      return state;
  }
};

export const preferences = (state = { isCustomApplied: false }, action) => {
  switch (action.type) {
    case REMOVE_CART_ITEMS: {
      return {
        ...state,
        date: null,
        dateSet: false,
        orderMemo: null,
        applicationMemo: null,
        poNumber: null,
        isCustomApplied: false,
        customCropCare: false,
      };
    }
    case SET_PICKUP_DATE: {
      const { date, dateSet } = action;
      return { ...state, date, dateSet };
    }
    case SET_ORDER_MEMO: {
      const { orderMemo } = action;
      return { ...state, orderMemo };
    }
    case SET_APPLICATION_MEMO: {
      const { applicationMemo } = action;
      return { ...state, applicationMemo };
    }
    case SET_PURCHASE_ORDER_NUMBER: {
      const { poNumber } = action;
      return { ...state, poNumber };
    }
    case SET_CUSTOM_APPLIED: {
      const { isCustomApplied } = action;
      return { ...state, isCustomApplied };
    }
    case SET_CUSTOM_CROP_CARE: {
      const { customCropCare } = action;
      return { ...state, customCropCare };
    }
    default:
      return state;
  }
};

export const postOrder = (state = [], action) => {
  switch (action.type) {
    case LOADING_ORDER: {
      const { loading } = action;
      return { ...state, loading };
    }
    case POST_ORDER_SUCCESS: {
      const { data, loading } = action;
      return { ...state, data, loading, error: null };
    }
    case POST_ORDER_FAILURE: {
      const { error, loading } = action;
      return { ...state, error, loading };
    }
    default:
      return state;
  }
};

export const submittedCartData = (state = [], action) => {
  switch (action.type) {
    case POST_ORDER_SUCCESS: {
      return cloneDeep(action.cartData);
    }
    case POST_ORDER_FAILURE: {
      return [];
    }
    default:
      return state;
  }
};

export const updatingCart = (state = { updating: false }, action) => {
  switch (action.type) {
    case UPDATING_CART: {
      const { updating } = action;
      return { ...state, updating };
    }
    case PRODUCT_HISTORY_ADD_TO_CART_FAILURE: {
      return { ...state, updating: false };
    }
    default:
      return state;
  }
};

export default combineReducers({
  status,
  cartData,
  loading: cartLoading,
  error: cartError,
  addresses,
  isPickup,
  selectedAccount,
  branchAddress,
  preferences,
  postOrder,
  submittedCartData,
  updatingCart,
  mostRecentAddition,
});
