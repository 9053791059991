import React from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import HeaderMobileSection from './HeaderMobileSectionComponent';
import HeaderIconNav from './HeaderIconNavComponent';
import HeaderResponsiveMenuIcon from './HeaderResponsiveMenuIconComponent';

const HeaderResponsiveMenuContent = ({
  isMenuOpen,
  isAccountMenuOpen,
  menuItems,
  iconMenuItems,
  user,
  handleLogout,
  toggleMenu,
  displayHamburgerMenu,
  selectedAccount,
  accounts,
  ...rest
}) => {
  const { usaSapOrders, westernRegionRestrictProducts } = useFlags();

  return (
    <div className={classNames('menu', { 'open-menu': isMenuOpen })} {...rest}>
      <div className="mobile-section-header">Navigation</div>
      <HeaderMobileSection menuItems={menuItems} />
      <HeaderIconNav iconMenuItems={iconMenuItems} />
      <div className="header-user-container--mobile">
        <div className="mobile-section-header">Manage profile</div>
        {(user.firstName || user.userName) && (
          <div className="header-user">
            <span className="header-user__info">
              <div className="header-user__info__name" data-hj-suppress="">
                {`${user.firstName || user.userName || ''} ${
                  user.lastName || ''
                }`}
              </div>
              <div className="header-user__info__email" data-hj-suppress="">
                {`${user.email || ''}`}
              </div>
              <div className="header-user__info__view-profile">
                <Link
                  to={`/profile/overview${
                    selectedAccount.id ? `?acct=${selectedAccount.id}` : ''
                  }`}
                >
                  View Profile
                </Link>
              </div>
              {accounts.length &&
              (!usaSapOrders ||
                !westernRegionRestrictProducts ||
                (usaSapOrders && !selectedAccount.usaSapAccount)) ? (
                <div className="header-user__info__view-orders">
                  <Link to="/orders">View Online Orders</Link>
                </div>
              ) : null}
            </span>
            <br />
          </div>
        )}
        <span className="header-vertical-line" />
        <NavLink
          activeClassName="selected"
          className="menu__item menu__item--mobile-only"
          key="/contact"
          to="/contact"
        >
          Contact Us
        </NavLink>
        <div
          id="logout-button--mobile"
          className="logout-button--mobile menu__item"
          onClick={handleLogout}
          onKeyPress={handleLogout}
          role="menuitem"
          tabIndex="0"
        >
          Sign Out
        </div>
      </div>

      <span className="header-vertical-line" />
      <div
        onClick={toggleMenu}
        onKeyPress={toggleMenu}
        className="header-user-container--desktop account-menu-toggle"
        role="button"
        tabIndex="0"
      >
        <div className="header__user-button__name" data-hj-suppress="">
          {`${user.firstName || user.userName || ''} ${user.lastName || ''}`}
        </div>
        {displayHamburgerMenu && (
          <HeaderResponsiveMenuIcon
            id="desktop-hamburger-menu"
            toggleMenu={toggleMenu}
            isMenuOpen={isAccountMenuOpen}
          />
        )}
      </div>
    </div>
  );
};

HeaderResponsiveMenuContent.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  isAccountMenuOpen: PropTypes.bool.isRequired,
  menuItems: PropTypes.node,
  iconMenuItems: PropTypes.node,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
  }).isRequired,
  handleLogout: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  displayHamburgerMenu: PropTypes.bool,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    usaSapAccount: PropTypes.bool,
  }).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

HeaderResponsiveMenuContent.defaultProps = {
  menuItems: null,
  iconMenuItems: null,
  displayHamburgerMenu: true,
};

export default HeaderResponsiveMenuContent;
