import { push } from 'connected-react-router';
import * as service from './roleDelegationService';
import * as constants from './roleDelegationConstants';

// Actions for validating the role delegation invitation is valid
export const confirmingRoleInvitation = () => ({
  type: constants.CONFIRMING_ROLE_INVITATION,
});

export const confirmRoleInvitationSuccess = (data) => ({
  type: constants.CONFIRM_ROLE_INVITATION_SUCCESS,
  payload: data,
});

export const confirmRoleInvitationFailure = (error) => ({
  type: constants.CONFIRM_ROLE_INVITATION_FAILURE,
  error,
});

export const confirmRoleInvitation = (confirmationID) => (dispatch) => {
  dispatch(confirmingRoleInvitation());
  return service
    .confirmInvitation(confirmationID)
    .then((res) => dispatch(confirmRoleInvitationSuccess(res)))
    .catch((error) => dispatch(confirmRoleInvitationFailure(error)));
};

// Actions for accepting / denying the role delegation invitation
export const roleDelegationAccepted = () => ({
  type: constants.ROLE_DELEGATION_ACCEPTED,
});

export const acceptRoleDelegation = () => (dispatch) => {
  dispatch(roleDelegationAccepted());
  return dispatch(push('/create-account'));
};

// RBAC Registration Form Actions
export const updateRbacRegistrationFormAction = (formData) => ({
  type: constants.UPDATE_RBAC_REGISTRATION_FORM,
  payload: formData,
});

export const setRbacRegistrationFormErrorsAction = (errors) => ({
  type: constants.SET_RBAC_REGISTRATION_FORM_ERRORS,
  payload: errors,
});

export const clearRbacRegistrationFormErrorsAction = () => ({
  type: constants.CLEAR_RBAC_REGISTRATION_FORM_ERRORS,
});

export const resetRbacRegistrationFormAction = () => ({
  type: constants.RESET_RBAC_REGISTRATION_FORM,
});

// RBAC Registration Actions
export const submittingRbacRegistrationForm = () => ({
  type: constants.SUBMITTING_RBAC_REGISTRATION_FORM,
});

export const rbacRegistrationSuccess = () => ({
  type: constants.RBAC_REGISTRATION_SUCCESS,
});

export const rbacRegistrationFailure = (error) => ({
  type: constants.RBAC_REGISTRATION_FAILURE,
  error,
});

export const registerRoleDelegation = (data) => (dispatch) => {
  dispatch(submittingRbacRegistrationForm());
  return service
    .registerRoleDelegation(data)
    .then(() => {
      // at this point, the user has been created in inner and cognito, and linked to the organization
      dispatch(rbacRegistrationSuccess());
      // redirect the user to the home page to trigger Auth0 login
      dispatch(push('/'));
    })
    .catch((error) => dispatch(rbacRegistrationFailure(error)));
};
