// TODO: update the import for these constants when they can
// be safely removed from the header
import * as constants from '../shared/ui/Header/constants';

const { SET_GLOBALLY_SELECTED_ACCOUNT } = constants;

export const setGloballySelectedAccount = (selectedAccount) => (dispatch) => {
  if (!selectedAccount.usaSapAccount) {
    const usaSapAccount =
      selectedAccount.id.length === 8 && selectedAccount.locationCode === 'USA';
    const invalidCanadianAccount =
      selectedAccount.id.length !== 8 && selectedAccount.locationCode === 'CAN';
    const invalidInternationalAccount =
      selectedAccount.locationCode !== 'USA' &&
      selectedAccount.locationCode !== 'CAN';
    dispatch({
      type: SET_GLOBALLY_SELECTED_ACCOUNT,
      payload: {
        ...selectedAccount,
        usaSapAccount,
        invalidCanadianAccount,
        invalidInternationalAccount,
      },
    });
  } else {
    dispatch({
      type: SET_GLOBALLY_SELECTED_ACCOUNT,
      payload: selectedAccount,
    });
  }
};
