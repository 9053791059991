import { connect } from 'react-redux';

import { fetchCustomers } from '../../../Customers/actions';
import CustomerSwitcher from './CustomerSwitcher';

const mapStateToProps = (state) => ({
  customers: state.employee.customers.all,
  mirroredUser: state.employee.mirroring.mirroredUser,
});

const mapDispatchToProps = { fetchCustomers };

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSwitcher);
