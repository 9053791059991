import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import LinkAccountComponent from '../LinkAccountComponent';

const LinkAccountButton = ({ accountLinked, inlineLink, variant }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  return (
    <div>
      <Button variant={variant} onClick={toggleModal}>
        {inlineLink && (
          <>
            <Add />
            &nbsp;
          </>
        )}
        Add an Account
      </Button>
      {isOpen && (
        <LinkAccountComponent
          modal
          isOpen
          closeModal={toggleModal}
          accountLinked={accountLinked}
        />
      )}
    </div>
  );
};

LinkAccountButton.propTypes = {
  inlineLink: PropTypes.bool,
  variant: PropTypes.string,
  accountLinked: PropTypes.func, // Called when account linking finished
};

LinkAccountButton.defaultProps = {
  inlineLink: false,
  variant: 'text',
  accountLinked: () => {},
};

export default LinkAccountButton;
