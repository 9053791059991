export default {
  searchMenuItem: {
    height: '100px',
  },
  sortButton: {
    border: '1px solid lightgray',
    width: '125px',
    height: '32px',
    minHeight: '32px',
    padding: '4px 12px',
    position: 'relative',
    right: '16px',
    justifyContent: 'space-between',
    '&:focus': {
      outline: '2px solid lightgray',
    },
  },
  paper: {
    width: '20%',
  },
  statusText: {
    display: 'inline',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '@media(max-width: 825px)': {
    InvoiceFilterComponent: {
      width: '100%',
    },
    sortButton: {
      width: '100%',
      right: 0,
      marginBottom: '10px',
    },
    paper: {
      width: '91%',
    },
  },
};
