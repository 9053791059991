import * as termsService from 'components/Terms/termsService';

import {
  FETCHING_LEGAL,
  LEGAL_FETCH_FAILURE,
  LEGAL_FETCH_SUCCESS,
} from './legalDocumentsConstants';

export const loadingLegalDocument = () => ({
  type: FETCHING_LEGAL,
});

export const legalDocumentFetchSuccess = (legalData) => ({
  type: LEGAL_FETCH_SUCCESS,
  payload: legalData,
});

export const legalDocumentFetchFailure = (error) => ({
  type: LEGAL_FETCH_FAILURE,
  error,
});

export const fetchLegalDocument = (legalDocument) => (dispatch) => {
  dispatch(loadingLegalDocument());
  return termsService
    .fetchContent(legalDocument)
    .then((response) => dispatch(legalDocumentFetchSuccess(response.data)))
    .catch((error) => dispatch(legalDocumentFetchFailure(error)));
};
