import { combineEventMaps } from 'store/middleware/utils';
import { farmInsightsAnalytics } from '@nutrien/farm-insights-client/dist/redux/middleware/analytics';

import { accountsEventsMap } from './accountMiddleware';
import { accountOpenItemsEventsMap } from './accountOpenItemsMiddleware';
import { accountOverviewEventsMap } from './accountOverviewMiddleware';
import { accountSelectionMap } from './accountSelectionMiddleware';
import { cartEventsMap } from './cartMiddleware';
import { checkoutEventsMap } from './checkoutMiddleware';
import { complianceEventsMap } from './complianceMiddleware';
import { documentsMap } from './documentsMiddleware';
import { featuredPartnerEventMap } from './featuredPartnersMiddleware';
import { fmsButtonEventsMap } from './featureFlagMiddleware';
import { gfaEventsMap } from './gfaMiddleware';
import { invoicedProductsSearchEventsMap } from './invoicedProductsSearchMiddleware';
import { invoiceEventsMap } from './invoiceMiddleware';
import { logoutEventsMap } from './logoutMiddleware';
import { navigationEventsMap } from './navigationMiddleware';
import { notificationsEventsMap } from './notificationMiddleware';
import { onlineOrdersEventMap } from './onlineOrdersMiddleware';
import { pageViewEventsMap } from './pageViewMiddleware';
import { paginationEventsMap } from './paginationMiddleware';
import { paymentEventsMap } from './paymentMiddleware';
import { PDPEventMap } from './PDPMiddleware';
import { PLPEventMap } from './PLPMiddleware';
import { paperlessPreferencesEventsMap } from './preferencesMiddleware';
import { priceMissingPopoverMap } from './priceMissingPopoverMiddleware';
import { productSearchEventsMap } from './productSearchMiddleware';
import { PromoEventMap } from './promoMiddleware';
import { purchaseEventsMap } from './purchaseMiddleware';
import { statementsEventsMap } from './statementsMiddleware';
import { topProductsEventsMap } from './topProductsMiddleware';
import { userEventsMap } from './userMiddleware';
import { weatherEventsMap } from './weatherMiddleware';
import { permitEventMap } from './permitMiddleware';
import { creditApplicationEventsMap } from './startCreditApplicationMiddleware';
import { newWebAndMobileEventsMap } from './newWebExperienceMiddleware';

export const eventsMap = combineEventMaps(
  accountOpenItemsEventsMap,
  accountOverviewEventsMap,
  accountSelectionMap,
  accountsEventsMap,
  cartEventsMap,
  checkoutEventsMap,
  complianceEventsMap,
  documentsMap,
  farmInsightsAnalytics,
  featuredPartnerEventMap,
  fmsButtonEventsMap,
  gfaEventsMap,
  invoicedProductsSearchEventsMap,
  invoiceEventsMap,
  logoutEventsMap,
  navigationEventsMap,
  notificationsEventsMap,
  onlineOrdersEventMap,
  pageViewEventsMap,
  paginationEventsMap,
  paperlessPreferencesEventsMap,
  paymentEventsMap,
  creditApplicationEventsMap,
  PDPEventMap,
  permitEventMap,
  PLPEventMap,
  priceMissingPopoverMap,
  productSearchEventsMap,
  PromoEventMap,
  purchaseEventsMap,
  statementsEventsMap,
  topProductsEventsMap,
  userEventsMap,
  weatherEventsMap,
  newWebAndMobileEventsMap
);

export default eventsMap;
