import browserIsSupported from 'shared/utils/browserIsSupported';
import { setBrowserOk, setBrowserError } from './headerActionCreators';

export const SET_BROWSER_OK = 'SET_BROWSER_OK';
export const SET_BROWSER_ERROR = 'SET_BROWSER_ERROR';

export const getBrowserSupport = () => (dispatch) => {
  if (browserIsSupported()) {
    dispatch(setBrowserOk());
  } else {
    dispatch(setBrowserError());
  }
};
