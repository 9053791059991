/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import Grid from '@nutrien/uet-react/Grid';
import Box from '@nutrien/uet-react/Box';
import Card from '@nutrien/uet-react/Card';
import CardContent from '@nutrien/uet-react/CardContent';
import WarningIcon from '@material-ui/icons/Warning';
import merge from 'lodash/merge';
import { currency, stripCurrencySymbols } from 'shared/utils/numberFormatters';
import { MIN_PAYMENT_AMOUNT } from 'const/payments';
import PaymentAmount from './PaymentAmount';
import * as PaymentPropTypes from '../../../../PaymentPropTypes';

import styles from './styles';
import commonStyles from '../../../../commonPaymentStyles';
import CPSAccountDropdownComponent from '../../../../../../shared/ui/Payments/CPSAccountDropdown/CPSAccountDropdownContainer';

const finalStyles = (theme) => merge({}, commonStyles(theme), styles);

class CPSAccountInformationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amountEntered: props.amountEntered
        ? this.formatAmount(props.amountEntered)
        : '',
    };
  }

  onValidate = (event) => {
    const { name, value } = event.target;
    const fieldData = { value };
    const { validate } = this.props;

    validate({ field: name, fieldData });
  };

  handleOnChangeAmount = (event) => {
    const { name, value } = event.target;
    const strippedValue = stripCurrencySymbols(value);
    const updatedEvent = {
      target: {
        name,
        value: strippedValue,
      },
    };
    this.handleChange(updatedEvent);
  };

  handleOnBlurAmount = (event) => {
    let updatedValue;
    const { name, value } = event.target;
    const strippedValue = stripCurrencySymbols(value);

    if (strippedValue && !Number.isNaN(strippedValue)) {
      const roundedValue = this.roundAmount(strippedValue);
      updatedValue = roundedValue >= MIN_PAYMENT_AMOUNT ? roundedValue : 0;
    } else {
      updatedValue = 0;
    }

    const updatedEvent = {
      target: {
        name,
        value: updatedValue,
      },
    };

    this.onValidate(updatedEvent);
    if (updatedValue !== 0) this.handleAmountEntered(updatedEvent);
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const currentState = { ...this.state };
    const { onChange } = this.props;

    if (name === 'amountEntered') {
      currentState.amountEntered = value;

      this.setState(currentState);

      onChange({
        target: {
          name,
          value: value ? Number(this.roundAmount(value)) : value,
        },
      });
    } else {
      onChange(event);
    }
  };

  roundAmount = (amount) =>
    Number.parseFloat(amount.toString().replace(/,/g, '')).toFixed(2);

  formatAmount = (amount) => {
    const rawAmount = stripCurrencySymbols(amount);
    return currency(this.roundAmount(rawAmount));
  };

  handleAmountEntered = (event) => {
    const currentState = { ...this.state };
    currentState.amountEntered = currency(event.target.value);

    this.setState(currentState);
  };

  render() {
    const {
      errors,
      warnings,
      selectedCPSAccount,
      notes,
      classes,
      amountTypeSelected,
      dropdownLabel,
      search,
      history,
      location,
    } = this.props;

    const { amountEntered } = this.state;

    const isCanada =
      selectedCPSAccount && selectedCPSAccount.locationCode === 'CAN';

    return (
      <div className={classes.cpsAccountInformation}>
        <label
          className={classes.addPaymentFormLabelLarge}
          htmlFor="CPSAccountInformation"
        >
          Select Account To Pay
        </label>

        <Grid container>
          <Grid lg={5} xs={12} item>
            <CPSAccountDropdownComponent
              chosenAccount={selectedCPSAccount}
              dropdownLabel={dropdownLabel}
              search={search}
              history={history}
              location={location}
            />
          </Grid>
        </Grid>

        {selectedCPSAccount ? (
          <div className={classes.selectedCPSAccountContainer}>
            <PaymentAmount
              handleOnChangeAmount={this.handleOnChangeAmount}
              handleOnBlurAmount={this.handleOnBlurAmount}
              handleChange={this.handleChange}
              errors={errors}
              warnings={warnings}
              amountEntered={amountEntered}
              amountTypeSelected={amountTypeSelected}
              currentBalance={selectedCPSAccount.balance}
              amountDue={selectedCPSAccount.currentDue}
              accountId={selectedCPSAccount.id}
            />
            <label
              className={`${classes.addPaymentFormLabel} ${classes.notesLabel}`}
              htmlFor="notes"
            >
              Add a Note
              <label
                className={classes.addPaymentFormLabelSmall}
                htmlFor="notesOptional"
              >
                (Optional)
              </label>
            </label>
            <textarea
              rows="4"
              cols="50"
              maxLength="255"
              minLength="0"
              name="notes"
              className={classes.paymentNotes}
              placeholder="Please specify the invoice number and the amount to be applied to the invoice for the payment you are providing."
              onChange={this.handleChange}
              value={notes}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

CPSAccountInformationComponent.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  amountEntered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  notes: PropTypes.string,
  selectedCPSAccount: PaymentPropTypes.selectedCPSAccount, // eslint-disable-line react/no-typos
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PaymentPropTypes.errors.isRequired, // eslint-disable-line react/no-typos
  warnings: PaymentPropTypes.warnings.isRequired, // eslint-disable-line react/no-typos
  classes: PropTypes.shape({
    cpsAccountInformation: PropTypes.string,
    addPaymentFormLabelLarge: PropTypes.string,
    selectedCPSAccountContainer: PropTypes.string,
    addPaymentFormLabel: PropTypes.string,
    notesLabel: PropTypes.string,
    addPaymentFormLabelSmall: PropTypes.string,
    paymentNotes: PropTypes.string,
  }).isRequired,
  amountTypeSelected: PropTypes.string,
  dropdownLabel: PropTypes.string,
  search: PropTypes.string,
};

CPSAccountInformationComponent.defaultProps = {
  selectedCPSAccount: undefined,
  amountEntered: undefined,
  notes: '',
  dropdownLabel: '',
  amountTypeSelected: '',
  search: null,
};

export default withStyles(finalStyles)(CPSAccountInformationComponent);
