import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import arrow from 'assets/chevron-right--green.svg';
import styles from './styles';

const EmailConfirmation = (props) => {
  const { classes, email } = props;
  return (
    <div>
      <h3>Thank you for signing up.</h3>
      <hr />
      <div className={classes.contentContainer}>
        <h4 className={classes.h4}>Please confirm your email.</h4>
        <div className={classes.p}>
          {`We've sent a verification email to ${email}. Please click
      the link in the message to confirm you have access to this email.`}
          <br />
          <br />
          {`As
      part of the sign-up process, you'll have the opportunity to add your
      current accounts to the Customer Portal. Adding accounts gives you the
      ability to receive paperless statements and invoices, make online
      payments, and much more.`}
        </div>
      </div>
      <hr />
      <div className={classes.contentContainer}>
        <h4 className={classes.h4}>Didn't receive the email?</h4>
        <div
          onClick={props.onClick}
          onKeyPress={props.onClick}
          role="button"
          tabIndex="-1"
          className={classes.arrowLink}
        >
          Resend Email <img src={arrow} alt="arrow right" />
        </div>
      </div>
    </div>
  );
};

EmailConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

EmailConfirmation.defaultProps = {
  classes: {},
};

export default withStyles(styles)(EmailConfirmation);
