import { string, object } from 'yup';

export const mixPanelTrackLogin = (userData) => {
  const schema = object().shape({
    id: string().required(),
    userDetails: object()
      .shape({
        customerType: string(),
      })
      .required(),
  });

  try {
    schema.validateSync(userData);
    window.mixpanel.identify(userData.id);

    window.mixpanel.people.set_once({
      $created: new Date().toISOString(),
    });

    window.mixpanel.people.set({
      $last_login: new Date().toISOString(),
      'user - type': 'Customer',
      'customer type': userData.userDetails.customerType,
    });

    window.mixpanel.people.increment('# of logins', 1);

    window.mixpanel.register({
      'user - type': 'Customer',
      'last login': new Date().toISOString(),
      'customer type': userData.userDetails.customerType,
      'platform - type': 'CXHW',
    });

    window.mixpanel.track('login');
  } catch (error) {
    throw new Error(error);
  }
};

export const mixPanelAddBranchInfo = ({ branchName, branchId }) => {
  // eslint-disable-next-line no-param-reassign
  if (branchId === null) branchId = '0000';
  // eslint-disable-next-line no-param-reassign
  branchName = branchName || 'Fuel';
  const schema = object().shape({
    branchName: string().required(),
    branchId: string(),
  });
  try {
    schema.validateSync({ branchName, branchId });
    window.mixpanel.people.set_once({
      'user branch name': branchName,
      'user branch id': branchId,
    });
  } catch (error) {
    throw new Error(error);
  }
};
