import React from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useDispatch } from 'react-redux';
import { string } from 'prop-types';
import { viewProductHistory } from 'components/ProductHistory/ProductHistoryActionCreators';
import { ProductHistoryObjectArrayType } from '../../ProductHistoryPropType';
import { downloadProductCsv } from './CsvDownloadActions';

const DownloadCsvIcon = ({ productHistoryList, startDate, endDate, shelf }) => {
  const dispatch = useDispatch();
  const filterCheckedProducts = () =>
    productHistoryList.filter((productHistory) => productHistory.isChecked);

  const anyCheckedProductHistoryObjects = () =>
    productHistoryList.some(
      (productHistoryObject) => productHistoryObject.isChecked
    );

  const handleDownload = () => {
    if (anyCheckedProductHistoryObjects()) {
      dispatch(viewProductHistory(shelf, startDate, endDate, true));
      return downloadProductCsv(
        filterCheckedProducts(),
        startDate,
        endDate,
        shelf
      );
    }
    return null;
  };

  return (
    <div
      onClick={() => handleDownload()}
      onKeyUp={() => handleDownload()}
      role="presentation"
      className={`product-history__div-download-csv${
        anyCheckedProductHistoryObjects() ? '' : '-disabled'
      }`}
    >
      <DownloadIcon data-test="csv-download-icon" />
      <span>CSV</span>
    </div>
  );
};

DownloadCsvIcon.propTypes = {
  productHistoryList: ProductHistoryObjectArrayType.isRequired,
  startDate: string.isRequired,
  endDate: string.isRequired,
  shelf: string,
};

DownloadCsvIcon.defaultProps = {
  shelf: '',
};

export default DownloadCsvIcon;
