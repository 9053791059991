import React from 'react';

import './ThankYouFooter.scss';

const ThankYouFooter = () => (
  <div className="thank-you-footer">
    <div>
      It may take up to 1 or 2 business days for us to process this request.
    </div>
    <div>
      Taxes and delivery costs will be confirmed prior to placing the order.
    </div>
    <div>Subtotal only represents priced products.</div>
  </div>
);

export default ThankYouFooter;
