import { GET_RECURRING_PAYMENTS, STATUS } from './constants';
import { getScheduledPayments } from '../PaymentsTabService';

const updateRecurringPaymentStatus = (status, data = []) => ({
  type: GET_RECURRING_PAYMENTS,
  payload: { status, data },
});

export default (accountIds) => async (dispatch) => {
  dispatch(updateRecurringPaymentStatus(STATUS.FETCHING));

  try {
    const result = await getScheduledPayments(accountIds, true);
    dispatch(updateRecurringPaymentStatus(STATUS.SUCCESS, result.data));
  } catch (err) {
    dispatch(updateRecurringPaymentStatus(STATUS.FAILED));
  }
};
