import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Grid } from '@material-ui/core';
import PermitsCard from '../PermitsCard';
import PurchasesCardContainer from '../PurchasesCard/PurchasesCardContainer';
import LicensesCard from '../LicensesCard';

const FileCabinet = () => {
  const { usaSapLicenses: restrictLicensesAndPermits } = useFlags();

  return (
    <Grid container spacing={2} className="file-cabinet-container">
      {restrictLicensesAndPermits ? (
        <Grid item xs={12} sm={4}>
          <PurchasesCardContainer data-test="purchase-card-container__file-cabinet--usa-sap-license" />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={4}>
            <PurchasesCardContainer data-test="purchase-card-container__file-cabinet--usa-sap-license" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LicensesCard data-test="licenses-card__file-cabinet--usa-sap-license" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PermitsCard data-test="permits-card__file-cabinet--usa-sap-license" />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FileCabinet;
