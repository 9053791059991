import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  gtmUpdateInvoicedProductsSearch,
  gtmFocusInvoicedProductsSearch,
} from 'store/middleware/TagManager/gtmActions';
import {
  selectInvoicedProduct,
  deselectInvoicedProduct,
  getInvoicedProducts,
} from '../../actions';
import InvoicedProductsSearch from './InvoicedProductsSearch';
import { getInvoicedProductsOptions, getInvoices } from '../../selectors';
import { getFilteredProductsList } from '../InvoicesTable/selectors';

export class InvoicedProductsSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoicedProductsOptions: [],
      isLoading: true,
      isMounted: true,
    };
  }

  /**
   * This has been added in as a short term fix to load invoiced product information for the invoice product search.
   * There is an issue, when loading large accounts(~15K+ invoiced products) where storing the data in either redux
   * or the parent component causes large performance issues to the point that the page is unusable.
   *
   * This is a short term fix loading the data directly into the component state in order to make the page usable again.
   *
   * TODO: Future state - A more long term solution needs to be implemented such as search/pagination on the DB/API side.
   *
   * This is related to: https://agrium.atlassian.net/browse/CXH-4202
   */
  componentDidMount() {
    const { accountId, invoiceSummariesLength } = this.props;
    if (this.state.isMounted)
      getInvoicedProducts(accountId, invoiceSummariesLength).then((resp) => {
        this.setState({
          invoicedProductsOptions: getInvoicedProductsOptions(resp),
          isLoading: false,
        });
      });
  }

  componentWillUnmount() {
    this.setState({
      isMounted: false,
      isLoading: true,
    });
  }

  render() {
    const propsToPass = {
      ...this.props,
      invoicedProductsOptions: this.state.invoicedProductsOptions,
      isLoading: this.state.isLoading,
    };

    return <InvoicedProductsSearch {...propsToPass} />;
  }
}

const mapStateToProps = (state) => ({
  selectedInvoicedProducts: getFilteredProductsList(state),
  invoiceSummariesLength: getInvoices(state).length,
});

export const mapDispatchToProps = {
  selectInvoicedProduct,
  deselectInvoicedProduct,
  gtmUpdateInvoicedProductsSearch,
  gtmFocusInvoicedProductsSearch,
};

InvoicedProductsSearchContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  invoiceSummariesLength: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicedProductsSearchContainer);
