import React, { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedAccount } from 'selectors/accountSelectors';
import isEmpty from 'lodash/isEmpty';
import { getCreditApplications } from '../../../actions/creditActions';
import CreditPromo from '../CreditPromo';
import CreditStatus from '../CreditStatus';

// This conditionally displays credit application promotional material
// or the most recent credit application on the landing and prospect landing page.
const CreditBanner = () => {
  const dispatch = useDispatch();
  const { creditApp: creditAppFlag } = useFlags();
  const account = useSelector(selectSelectedAccount());
  const userData = useSelector((state) => state.user.userData);
  const creditApplication = useSelector(
    (state) => state.credit.creditApplications[0]
  );

  const { locationCode } = account;
  useEffect(() => {
    if (userData) {
      dispatch(getCreditApplications());
    }
  }, [dispatch, userData]);

  if (!creditAppFlag || locationCode === 'CAN') return null;

  if (
    !isEmpty(creditApplication) &&
    creditApplication?.displayProperties?.applicantName
  ) {
    return <CreditStatus creditApplication={creditApplication} />;
  }
  return <CreditPromo />;
};

export default CreditBanner;
