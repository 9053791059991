import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InfiniteDatePicker } from 'nutrien-common-components-react/dist/mdc';
import PaymentValidationMessageComponent from 'components/Payments/OtherComponents/ValidationMessage/PaymentValidationMessageComponent';
import { useFlags } from 'launchdarkly-react-client-sdk';
import OnClickOutside from '../../../../shared/ui/OnClickOutside/OnClickOutside';
import * as PaymentPropTypes from '../../PaymentPropTypes';
import customFormat from '../../../../shared/utils/DateHelper/customFormat';

import './paymentDatepicker.scss';

const PaymentDatepicker = (props) => {
  const { paymentDatepickerBlackout = {} } = useFlags();

  const buildDatestring = (selectedDate, noDefault, today) => {
    let dateString;

    if (selectedDate) {
      dateString = customFormat(
        new Date(selectedDate).toDateString(),
        'ddd MMM DD, YYYY'
      );
    } else if (noDefault) {
      dateString = '';
    } else {
      dateString = customFormat(today.toDateString(), 'ddd MMM DD, YYYY');
    }
    return dateString;
  };

  const {
    selectedDate,
    onChange,
    inputName,
    formPropertyName,
    noDefault,
    errors,
    validate,
  } = props;
  const form = useSelector(
    (state) => state.payments.bankAccountFormReducer.form
  );
  const today = new Date();
  const tomorrow = new Date().setDate(today.getDate() + 1);
  const dateString = buildDatestring(selectedDate, noDefault, today);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const toggleDatePicker = () => setDatePickerOpen(!datePickerOpen);
  const inputClassname = datePickerOpen
    ? 'payment-datepicker-input open'
    : 'payment-datepicker-input';

  const blackoutDates = React.useMemo(() => {
    if (paymentDatepickerBlackout.enabled) {
      const blkOutDts = (paymentDatepickerBlackout.blackoutDates || []).map(
        (date) => new Date(date)
      );

      return blkOutDts;
    }

    return [];
  }, [paymentDatepickerBlackout]);

  return (
    <div className="payment-datepicker-container">
      <input
        type="text"
        name={inputName}
        placeholder="MM/DD/YYYY"
        className={classnames(inputClassname, {
          error: errors && errors[inputName] && errors[inputName].length,
        })}
        onFocus={toggleDatePicker}
        onBlur={(event) => {
          const { name, value } = event.target;
          const fieldData = { value };

          validate({ field: name, fieldData });
        }}
        value={dateString}
        readOnly
      />
      <PaymentValidationMessageComponent
        errors={errors}
        className="dropdown-message-container"
        data-test="dropdown-message-container"
        field={inputName}
      />
      {datePickerOpen && (
        <OnClickOutside
          clickOutsideHandler={toggleDatePicker}
          data-test="test-clickoutside"
        >
          <InfiniteDatePicker
            height={500}
            minDate={form.isRecurring ? new Date(tomorrow) : today}
            data-test="infinite-datepicker"
            selected={
              form.isRecurring
                ? selectedDate || new Date(tomorrow)
                : selectedDate || today
            }
            onSelect={(dtPickerDate) => {
              onChange({
                target: {
                  name: formPropertyName,
                  value: dtPickerDate,
                },
              });
            }}
            disabledDates={blackoutDates || []}
          />
        </OnClickOutside>
      )}
      {datePickerOpen && <div className="datepicker-overlay" />}
    </div>
  );
};

PaymentDatepicker.propTypes = {
  inputName: PropTypes.string.isRequired,
  formPropertyName: PropTypes.string.isRequired,
  noDefault: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PaymentPropTypes.paymentFormErrors.isRequired,
};

PaymentDatepicker.defaultProps = {
  selectedDate: null,
  noDefault: false,
};

export default PaymentDatepicker;
