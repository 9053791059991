export default {
  addPaymentNextButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  makeAPaymentNoAccount: {
    height: '300px',
  },
  makeAPaymentFormWrapper: {
    width: '100%',
    paddingLeft: '20px',
  },
  '@media (max-width: 480px)': {
    addPaymentFormInput: {
      width: '100%',
    },
    nextButton: {
      width: '100%',
    },
  },
};
