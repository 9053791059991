import { SvgIcon } from '@material-ui/core';
import React from 'react';

export const Weather = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        stroke="white"
        strokeWidth="1"
        d="M 15.32,14.58
           C 15.32,14.58 12.57,18.11 12.57,18.11
             12.57,18.11 14.40,18.11 14.40,18.11
             14.40,18.11 14.40,21.77 14.40,21.77
             14.40,21.77 17.11,17.75 17.11,17.75
             17.11,17.75 15.32,17.75 15.32,17.75
             15.32,17.75 15.32,14.58 15.32,14.58 Z
           M 14.26,4.59
           C 14.26,4.59 12.75,6.09 12.75,6.09M 2.70,16.04
           C 2.70,16.04 4.20,14.55 4.20,14.55M 0.30,10.31
           C 0.30,10.31 2.43,10.31 2.43,10.31M 2.70,4.59
           C 2.70,4.59 4.20,6.09 4.20,6.09M 8.48,2.23
           C 8.48,2.23 8.48,4.33 8.48,4.33M 9.52,12.32
           C 9.52,12.32 10.27,12.32 10.27,12.32
             10.33,11.28 10.75,10.33 11.39,9.59
             11.61,9.34 11.86,9.11 12.14,8.91
             11.57,7.46 10.15,6.43 8.48,6.43
             6.31,6.43 4.55,8.17 4.55,10.31
             4.55,11.92 5.53,13.29 6.94,13.89
             7.41,12.96 8.39,12.32 9.51,12.32
             9.52,12.32 9.52,12.32 9.52,12.32
             9.52,12.32 9.52,12.32 9.52,12.32 Z
           M 6.94,13.89
           C 6.73,14.27 6.62,14.71 6.62,15.18
             6.62,15.18 6.62,15.18 6.62,15.19
             6.62,15.22 6.62,15.25 6.62,15.28
             6.62,16.76 7.82,17.95 9.31,17.95
             9.34,17.95 9.37,17.95 9.40,17.95
             9.40,17.95 10.27,17.95 10.27,17.95M 19.38,17.95
           C 19.38,17.95 20.06,17.95 20.06,17.95
             20.09,17.95 20.13,17.95 20.16,17.95
             22.11,17.95 23.69,16.39 23.70,14.47
             23.70,14.47 23.70,14.04 23.70,14.04
             23.70,12.06 22.07,10.45 20.06,10.45
             20.06,10.45 20.06,10.45 20.06,10.45
             20.06,10.45 19.11,10.45 19.11,10.45
             18.32,8.98 16.75,7.98 14.96,7.98
             14.28,8.77 14.89,6.64 14.89,7.76
             14.89,8.35 11.93,8.53 12.14,9.05"
      />
    </SvgIcon>
  );
};

export const Leaf = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        stroke="white"
        strokeWidth="1"
        d="M 18.03,9.14
        C 18.03,9.14 23.40,10.42 23.00,14.72
        23.49,13.93 23.76,13.00 23.76,12.01
        23.76,11.62 23.72,11.25 23.64,10.88M 1.36,15.52
        C 1.36,15.52 -1.46,9.89 4.23,5.07
        9.91,0.25 17.16,3.10 18.35,3.97
        19.54,4.83 22.93,6.41 23.64,10.91
        23.64,10.91 23.64,9.01 19.93,8.82
        16.21,8.62 13.85,13.60 10.46,16.67
        7.07,19.75 2.81,16.20 2.81,16.20M 10.93,7.13
        C 10.93,7.13 -0.12,12.41 3.44,22.36"
      />
    </SvgIcon>
  );
};

export const Warning = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z" />
    </SvgIcon>
  );
};
