import { connect } from 'react-redux';
import {
  gtmFilterProducts,
  gtmProductListClearFilters,
} from '../../../../store/middleware/TagManager/gtmActions';
import FilterPanel from '../../components/FilterPanel/FilterPanel';

const mapStateToProps = ({ shop, selectedAccount, cart }) => ({
  products: shop.products,
  categories: shop.categories,
  product: shop.product,
  search: shop.search,
  sort: shop.sort,
  productsLoading: shop.products.loading,
  selectedAccount,
  cart,
});

const mapDispatchToProps = { gtmFilterProducts, gtmProductListClearFilters };

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
