import { LOCATION_CHANGE } from 'connected-react-router';
import {
  CLEAR_PAYMENT_STATE,
  SET_SHOW_FORM_ERRORS,
  CLEAR_SHOW_FORM_ERRORS,
  SET_PAYMENT_ERROR,
  CLEAR_PAYMENT_ERROR,
} from '../../PaymentConstants';

const initialState = {
  paymentError: null,
  showFormErrors: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_FORM_ERRORS:
      return { ...state, showFormErrors: true };
    case SET_PAYMENT_ERROR:
      return { ...state, paymentError: action.payload };
    case CLEAR_PAYMENT_ERROR:
      return { ...state, paymentError: null };
    case CLEAR_SHOW_FORM_ERRORS:
      return { ...state, showFormErrors: false };
    case CLEAR_PAYMENT_STATE:
      return initialState;
    case LOCATION_CHANGE: {
      return action.payload.location.pathname.match(
        /(review-payment)|(confirm-payment)/g
      )
        ? state
        : initialState;
    }
    default:
      return state;
  }
};

export default reducer;
