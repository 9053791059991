import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import checkWhitespaceOnlyString from '../../utils/checkWhitespaceOnlyString';
import './OrderMemo.scss';

class OrderMemo extends Component {
  constructor(props) {
    super(props);
    const {
      cart: { preferences },
    } = props;
    let value = '';

    if (preferences.orderMemo) {
      value = preferences.orderMemo;
    }

    this.state = {
      value,
    };
  }

  handleChange = (e) => {
    const { setOrderMemo } = this.props;
    const value = checkWhitespaceOnlyString(e.target.value);
    this.setState({ value });
    setOrderMemo(value);
  };

  render() {
    return (
      <>
        <div className="instructions-header">
          <label className="instructions-header__headline" htmlFor="orderMemo">
            Additional Order Instructions
          </label>
          <span className="instructions-header__detail">(Optional)</span>
        </div>
        <textarea
          onChange={(e) => this.handleChange(e)}
          maxLength="450"
          className="order-memo-instructions"
          placeholder=" If you have any additional order details you’d like to share with your branch, please include them here. These details might include multiple delivery locations, split invoicing and unique product requests."
          value={this.state.value}
          id="orderMemo"
          data-test="order-memo-instructions"
        />
      </>
    );
  }
}

OrderMemo.propTypes = {
  cart: PropTypes.shape({
    preferences: PropTypes.shape({
      orderMemo: PropTypes.string,
    }),
  }).isRequired,
  setOrderMemo: PropTypes.func.isRequired,
};

export default OrderMemo;
