import downloadFile from 'shared/utils/DownloadFile';
import * as invoicesService from '../invoicesService';
import downloadInvoicesFailure from './downloadInvoicesFailure';
import downloadInvoicesInProgress from './downloadInvoicesInProgress';
import downloadInvoicesSuccess from './downloadInvoicesSuccess';

export default (accountId, invoiceIds) => async (dispatch) => {
  dispatch(downloadInvoicesInProgress());

  try {
    const response = await invoicesService.getInvoicesAsCSV(
      accountId,
      invoiceIds
    );

    if (response) {
      dispatch(downloadInvoicesSuccess());
      downloadFile(response, `account-${accountId}-selected.csv`);
    } else {
      dispatch(downloadInvoicesFailure());
    }
  } catch (error) {
    dispatch(downloadInvoicesFailure());
  }
};
