import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import {
  deleteSavedBankAccount,
  getSavedBankAccounts,
  registerBankAccount,
} from '../../Redux/actions/BankAccountActions';
import {
  clearPaymentError,
  clearShowFormErrorsNotification,
} from '../../Redux/actions/ErrorActions';
import {
  setAccountCountryCode,
  updateBankAccountForm,
  clearBankAccountForm,
  submitACHPaymentFormAction,
  clearBankName,
  bankFromRoutingNumber,
  submitMakeASecurePaymentWithTokenAction,
  setBankAccountFormErrors,
  setPaymentFlowType,
} from '../../Redux/actions/BankAccountFormActions';
import MakeAPayment from './MakeAPayment';
import * as paymentUtils from '../../Utils/PaymentUtil';

const mapStateToProps = (state) => {
  const {
    bankAccountReducer: bankAccount,
    bankAccountFormReducer: bankAccountForm,
    sharedReducer: shared,
    errorReducer: errors,
  } = state.payments;

  const isLoading = paymentUtils.isLoading(state);

  return {
    isLoading,
    flowType: bankAccountForm.flowType,
    token: bankAccount.vantivToken,
    user: state.user.userData,
    accounts: state.accounts.list,
    bankName: bankAccountForm.bankName,
    paymentError: errors.paymentError,
    states: shared.states,
    routingNumberError: bankAccountForm.bankNameFail,
    savedPaymentMethods: bankAccount.paymentAccounts,
    deletePaymentMethodStatus: shared.deleteTokenStatus,
    formValues: bankAccountForm.form,
    showFormErrors: errors.showFormErrors,
    formErrors: bankAccountForm.formErrors,
    formWarnings: bankAccountForm.formWarnings,
    cpsAccount: bankAccountForm.form.cpsAccount,
    selectedPaymentMethod: bankAccountForm.form.savedPaymentMethod,
    selectedInvoices: state.invoices.selectedInvoices,
    invoices: state.invoices.list,
    scheduledPayment: bankAccountForm.form.scheduledPayment,
    recurringEndDate: bankAccountForm.form.recurringEndDate,
    isRecurring: bankAccountForm.form.isRecurring,
    recurringPaymentFrequency: bankAccountForm.form.recurringPaymentFrequency,
  };
};

const mapDispatchToProps = {
  push,
  clearBankName,
  clearPaymentError,
  setAccountCountryCode,
  deleteSavedBankAccount,
  setPaymentFlowType,
  clearShowFormErrorsNotification,
  registerTokenAction: registerBankAccount,
  getBankName: bankFromRoutingNumber,
  getAccounts: fetchAssociatedOrgsAction,
  getPaymentAccounts: getSavedBankAccounts,
  submitMakeAPaymentWithSavedPaymentMethod: submitMakeASecurePaymentWithTokenAction,
  updatePaymentForm: updateBankAccountForm,
  clearPaymentForm: clearBankAccountForm,
  setPaymentFormErrors: setBankAccountFormErrors,
  submitPaymentForm: submitACHPaymentFormAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeAPayment);
