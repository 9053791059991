// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';

import getCountiesSuccess from './getCountiesSuccess';
import getCountiesFailure from './getCountiesFailure';
import { countyTransformer } from '../../../../utils/transformers/geographyTransformers';

export default (countryId, stateId, id) => (dispatch) =>
  // this should be in its own service call. getCounties, and should map res => res.data before passing it along
  ecommApiService
    .get(`/v2/geography/${countryId}/${stateId}/counties`, {
      headers: {
        'account-id': id,
      },
    })
    .then((res) => {
      const transformedCounties = countyTransformer(res.data.data);
      dispatch(getCountiesSuccess(transformedCounties));
      return res;
    })
    .catch((error) => {
      dispatch(getCountiesFailure(error));
      return error;
    });
