import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import CookiesRequiredModal from './CookiesRequiredModal';

class WeatherButton extends Component {
  constructor(props) {
    super(props);
    this.toggleCookiesModal = this.toggleCookiesModal.bind(this);
    this.cookiesEnabled = this.cookiesEnabled.bind(this);
    this.navigateToWeather = this.navigateToWeather.bind(this);
    this.state = {
      isCookiesModalEnabled: this.props.isCookiesModalEnabled,
    };
  }

  toggleCookiesModal = () => {
    this.setState((state) => ({
      isCookiesModalEnabled: !state.isCookiesModalEnabled,
    }));
  };

  cookiesEnabled = () => {
    let { cookieEnabled } = navigator;
    if (!cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }
    return cookieEnabled;
  };

  navigateToWeather = () => {
    const { history } = this.props;
    if (!this.cookiesEnabled()) {
      this.toggleCookiesModal();
    } else {
      history.push('/?showHubModal=true&modalType=weather');
    }
  };

  render() {
    const { landingPageWeatherButton } = this.props.flags;
    return (
      <>
        {landingPageWeatherButton && (
          <Button
            onClick={this.navigateToWeather}
            variant="outlined"
            data-test="local-weather-button"
          >
            Local Weather
          </Button>
        )}
        {this.state.isCookiesModalEnabled && (
          <CookiesRequiredModal
            modal
            closeModalFunc={this.toggleCookiesModal}
          />
        )}
      </>
    );
  }
}

WeatherButton.defaultProps = {
  isCookiesModalEnabled: false,
};

WeatherButton.propTypes = {
  flags: PropTypes.shape({
    landingPageWeatherButton: PropTypes.bool,
  }).isRequired,
  isCookiesModalEnabled: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export { WeatherButton };

export default WeatherButton;
