export default (theme) => ({
  pageTitle: {
    marginTop: '20px',
    marginBottom: '44px',
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: '24px',
    paddingBottom: '8px',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '16px',
    },
  },
  sectionSubheading: {
    fontSize: 18,
    fontWeight: 600,
    paddingTop: '16px',
    paddingBottom: '8px',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '16px',
    },
  },
  errorMessage: {
    width: '100%',
  },
  listItem: {
    color: theme.palette.text.secondary,
  },
});
