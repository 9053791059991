import { NOTIFICATIONS_FETCH_BEGIN, NOTIFICATIONS_FETCH_END } from '../actions';

export default (state = false, action) => {
  switch (action.type) {
    case NOTIFICATIONS_FETCH_BEGIN:
      return true;
    case NOTIFICATIONS_FETCH_END:
      return false;
    default:
      return state;
  }
};
