import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: theme.palette.warning.secondary,
    color: theme.palette.warning.text,
    position: 'fixed',
    padding: theme.spacing(),
    width: '100%',
    '@media print': {
      display: 'none',
    },
    zIndex: '99',
    [theme.breakpoints.up('1280')]: {
      marginTop: '14px',
    },
  },
  icon: {
    height: '21px',
    width: '21px',
    margin: theme.spacing(),
  },
  message: {
    fontSize: '.875rem',
    lineHeight: '20px',
  },
  link: {
    fontWeight: 'bold',
    color: theme.palette.warning.text,
  },
  spacer: {
    '@media print': {
      display: 'none',
    },
  },
}));

export default useStyles;
