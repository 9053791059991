import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push as pushFunction } from 'connected-react-router';
import debounce from 'lodash/debounce';
import Button from '@nutrien/uet-react/Button';
import SnackbarNotification from 'shared/ui/SnackbarNotification/SnackbarNotification';
import { getQueryStringFromRouter } from 'shared/utils/reduxSelectors';

import {
  notificationsStagedForDeletion,
  notificationsUndoDeletion,
  deleteNotifications,
  selectNotification,
  deselectNotification,
  selectAllNotifications,
  deselectAllNotifications,
  toggleNotificationReadStatuses,
} from '../actions';
import { getSelectedNotifications, isStagedForDeletion } from '../selectors';

import { notifications as notificationsPropType } from '../notificationsPropTypes';
import NotificationsPage from './NotificationsPage';
import withNotifications from '../withNotifications';

export class NotificationsPageContainer extends Component {
  static propTypes = {
    notifications: notificationsPropType,
    selectedNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchNotificationsError: PropTypes.instanceOf(Error),
    isFetchingNotifications: PropTypes.bool,
    currentPage: PropTypes.number.isRequired,
    push: PropTypes.func.isRequired,
    showSnackbar: PropTypes.bool.isRequired,
    notificationsStagedForDeletion: PropTypes.func.isRequired,
    notificationsUndoDeletion: PropTypes.func.isRequired,
    deleteNotifications: PropTypes.func.isRequired,
    selectNotification: PropTypes.func.isRequired,
    selectAllNotifications: PropTypes.func.isRequired,
    deselectNotification: PropTypes.func.isRequired,
    deselectAllNotifications: PropTypes.func.isRequired,
    toggleNotificationReadStatuses: PropTypes.func.isRequired,
  };

  static defaultProps = {
    notifications: [],
    fetchNotificationsError: null,
    isFetchingNotifications: false,
  };

  toggleSelect = (id) => {
    const {
      selectNotification, // eslint-disable-line no-shadow
      deselectNotification, // eslint-disable-line no-shadow
      selectedNotifications,
    } = this.props;

    if (selectedNotifications.includes(id)) {
      deselectNotification(id);
    } else {
      selectNotification(id);
    }
  };

  deleteNotificationsDebounced = debounce(this.props.deleteNotifications, 5000);

  handleDelete = (ids) => {
    this.props.notificationsStagedForDeletion(ids);
    this.deleteNotificationsDebounced(ids);
  };

  handleUndo = () => {
    this.deleteNotificationsDebounced.cancel();
    this.props.notificationsUndoDeletion();
  };

  handlePaginationClick = (page) => {
    const { push } = this.props;
    push(`/notifications?page=${page}`);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  renderSnackbar() {
    const { showSnackbar, selectedNotifications } = this.props;
    return (
      <SnackbarNotification
        open={showSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={`${selectedNotifications.length} notifications deleted`}
        action={
          <>
            <Button color="secondary" size="small" onClick={this.handleUndo}>
              UNDO
            </Button>
          </>
        }
      />
    );
  }

  render() {
    const {
      notifications,
      fetchNotificationsError,
      currentPage,
      isFetchingNotifications,
      selectedNotifications,
      selectAllNotifications, // eslint-disable-line no-shadow
      deselectAllNotifications, // eslint-disable-line no-shadow
      toggleNotificationReadStatuses, // eslint-disable-line no-shadow
    } = this.props;
    return (
      <>
        <NotificationsPage
          notifications={notifications}
          selectedNotifications={selectedNotifications}
          notificationsError={fetchNotificationsError}
          onDelete={this.handleDelete}
          onMarkAsRead={toggleNotificationReadStatuses}
          onPaginationClick={this.handlePaginationClick}
          currentPage={currentPage}
          fetchingNotifications={isFetchingNotifications}
          selectAllNotifications={selectAllNotifications}
          deselectAllNotifications={deselectAllNotifications}
          toggleSelect={this.toggleSelect}
        />
        {this.renderSnackbar()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: parseInt(getQueryStringFromRouter(state.router).page || 1, 10),
  selectedNotifications: getSelectedNotifications(state),
  showSnackbar: isStagedForDeletion(state),
});

const mapDispatchToProps = {
  push: pushFunction,
  notificationsStagedForDeletion,
  notificationsUndoDeletion,
  deleteNotifications,
  deselectNotification,
  deselectAllNotifications,
  selectNotification,
  selectAllNotifications,
  toggleNotificationReadStatuses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(NotificationsPageContainer));
