import React from 'react';
import PropTypes from 'prop-types';
import Form from 'shared/ui/MaterialFormCard/Form';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';

import { errorData as errorPropType } from './employeeLoginPropTypes';

const LoginForm = ({
  classes,
  email,
  password,
  showPassword,
  error,
  handleSubmit,
  handleChange,
  toggleDisplayValue,
}) => (
  <div>
    <Form onSubmit={handleSubmit}>
      <FormTextField
        name="email"
        title="Email Address"
        value={email}
        handleChange={handleChange}
        autocomplete="email"
      />
      <FormTextField
        name="password"
        title="Password"
        value={password}
        type="password"
        autocomplete="current-password"
        handleChange={handleChange}
        toggleShowValue={toggleDisplayValue}
        shouldValueShow={showPassword}
      />
      <div className={classes.error} id="loginError">
        {error && error.message}
      </div>
      <MaterialButton
        id="sign-in-button"
        className="button--long"
        value="Sign In"
        type="submit"
      />
    </Form>
  </div>
);

LoginForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  showPassword: PropTypes.bool,
  error: errorPropType,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  toggleDisplayValue: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  email: '',
  password: '',
  showPassword: false,
  error: null,
};

export default LoginForm;
