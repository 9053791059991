import { TOGGLE_PRIVACY_PREFERENCE } from 'components/Profile/Tabs/BillingTab/actions';

/**
 * This event is not configured or hooked up to anything in GTM.
 * Leaving this function in the codebase, however, as a proof of concept
 * for how I would start to implement the datalayer for
 * paperless statement option toggling.
 */
export const togglePaperlessStatement = ({ value }) => ({
  event: 'CXH_SetPaperlessPreference',
  cxh: {
    paperlessPreferences: {
      statement: {
        optedIn: value,
      },
    },
  },
});

export const paperlessPreferencesEventsMap = {
  [TOGGLE_PRIVACY_PREFERENCE]: togglePaperlessStatement,
};
