import axios from 'shared/lib/cxhApiService';
import { setToken } from 'shared/utils/tokenUtils';

const refreshSession = () =>
  axios
    .post('v1/user/refresh')
    .then((response) => {
      setToken(response.data);
    })
    .catch((err) => console.log(err));

export default refreshSession;
