import isEmpty from 'lodash/isEmpty';
import { MAX_PAYMENT_AMOUNT } from 'const/payments';

export function isNumeric(values) {
  const regex = /^[0-9]*$/g;
  const { value } = values;

  return value ? regex.test(values.value) : false;
}

export function isAlphabetical(values) {
  const regex = /^[a-zA-Z ]*$/g;
  const { value } = values;

  return value ? regex.test(values.value) : false;
}

export function isAlphaNumeric(values) {
  const regex = /^[a-zA-Z0-9.'\- ]*$/g;
  const { value } = values;

  return value ? regex.test(value.replace(' ', '')) : false;
}

export function isMinimumLength(values) {
  const { value, minLength } = values;
  return value && minLength ? value.length >= minLength : false;
}

export function isMinimumNameLength(values) {
  return isMinimumLength(values);
}

export function isNotOnlySpaces(values) {
  const { value } = values;
  return value.length > 0 ? value.trim().length : true;
}

export function isValidPostalCode(values) {
  const regex =
    /^([a-zA-Z][0-9][a-zA-Z]$)|([a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$)/g;
  const { value } = values;

  return value ? regex.test(value) : false;
}

export function isMaximumLength(values) {
  const { value, maxLength } = values;

  return value && maxLength ? value.length <= maxLength : false;
}

export function isPopulated(values) {
  const { value } = values;

  return value ? value !== null && value !== undefined && value !== '' : false;
}

export function isDropdownPopulated(values) {
  const { value } = values;

  return value && !isEmpty(value);
}

export function isPhoneNumber(values) {
  const { value } = values;

  return value ? isNumeric({ value }) : false;
}

export function isAmountEnteredLTECurrentDue(values) {
  return values.amountEntered <= values.currentDue;
}

export function isAmountEnteredLessThanEqualToAccountBalance(values) {
  const isNegative = values.accountBalance < 0;
  let result;
  if (!values.amountEntered) {
    result = true;
  } else if (isNegative) {
    result = false;
  } else {
    result = values.amountEntered <= values.accountBalance;
  }
  return result;
}

export function validateEnteredAmount(values) {
  return values.amountEntered > 0;
}

export function validateMaxAmount(values) {
  return Number(values.amountEntered) <= Number(MAX_PAYMENT_AMOUNT);
}

export function areAccountNumbersSame(values) {
  const { value, bankAccountNumber } = values;
  return bankAccountNumber === value;
}
