import {
  NOTIFICATIONS_PREFERENCES_UPDATE_START,
  NOTIFICATIONS_PREFERENCES_UPDATE_FAILURE,
  NOTIFICATIONS_PREFERENCES_UPDATE_SUCCESS,
} from '../actions';

export default (state = false, action) => {
  switch (action.type) {
    case NOTIFICATIONS_PREFERENCES_UPDATE_START:
      return true;
    case NOTIFICATIONS_PREFERENCES_UPDATE_SUCCESS:
    case NOTIFICATIONS_PREFERENCES_UPDATE_FAILURE:
      return false;
    default:
      return state;
  }
};
