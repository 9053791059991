import React from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';

import './ApiErrorMessage.css';

export const ApiErrorMessage = ({
  entityName,
  className,
  customMessage,
  ...rest
}) => (
  <div className={`api-error-message ${className || ''}`} {...rest}>
    <WarningIcon className="api-error-message__icon" />
    <div className="api-error-message__message">
      {`${entityName} could not be retrieved. `}
      <br />
      {customMessage}
    </div>
  </div>
);

ApiErrorMessage.defaultProps = {
  className: undefined,
  customMessage: 'Try refreshing the page.',
};

ApiErrorMessage.propTypes = {
  className: PropTypes.string,
  /** The object or collection that the api failed to fetch */
  entityName: PropTypes.string.isRequired,
  customMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ApiErrorMessage;
