const styles = (theme) => ({
  container: {
    margin: '0px 20px 20px',
  },
  hr: {
    margin: '20px 0px',
  },
  p: {
    color: theme.palette.text.secondary,
  },
});

export default styles;
