import { combineReducers } from 'redux';
import {
  FETCHING_TERMS,
  TERMS_FETCH_FAILURE,
  TERMS_FETCH_SUCCESS,
  TERMS_FETCHED,
  TERMS_SHOW_MODAL,
  UPDATE_TERMS_ACCEPTANCE_SUCCESS,
} from './termsConstants';

export const termsData = (state = {}, action) => {
  switch (action.type) {
    case TERMS_FETCH_SUCCESS:
      return action.payload.terms;
    case TERMS_FETCH_FAILURE:
      return {};
    default:
      return state;
  }
};

export const termsDocument = (state = null, action) => {
  switch (action.type) {
    case TERMS_FETCH_SUCCESS:
      return action.payload.terms.data.document;
    case TERMS_FETCH_FAILURE:
      return null;
    default:
      return state;
  }
};

export const termsVersion = (state = null, action) => {
  switch (action.type) {
    case TERMS_FETCH_SUCCESS:
      return action.payload.terms.data.version;
    default:
      return state;
  }
};

export const status = (state = '', action) => {
  switch (action.type) {
    case FETCHING_TERMS:
      return FETCHING_TERMS;
    case TERMS_FETCH_SUCCESS:
      return TERMS_FETCHED;
    case TERMS_FETCH_FAILURE:
      return 'TERMS_FETCH_ERROR';
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case TERMS_FETCH_FAILURE:
      return action.error;
    case TERMS_FETCH_SUCCESS:
      return null;
    default:
      return state;
  }
};

const defaultModalState = { open: false };

export const modal = (state = defaultModalState, { type, payload }) => {
  switch (type) {
    case TERMS_SHOW_MODAL:
      return {
        open: true,
        route: payload,
      };
    case UPDATE_TERMS_ACCEPTANCE_SUCCESS:
      return defaultModalState;
    default:
      return state || defaultModalState;
  }
};

export default combineReducers({
  termsData,
  termsDocument,
  termsVersion,
  status,
  error,
  modal,
});
