import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchProductHistory } from '../ProductHistoryActions';
import { viewProductHistory } from '../ProductHistoryActionCreators';
import { fetchAssociatedOrgsAction } from '../../../shared/ui/AccountsTable/actions/AccountActions';
import ProductHistoryPage from './ProductHistoryPage';

const mapStateToProps = (state) => ({
  accounts: state.accounts,
  user: state.user.userData,
});

const mapDispatchToProps = {
  fetchAssociatedOrgsAction,
  fetchProductHistory,
  push,
  viewProductHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductHistoryPage);
