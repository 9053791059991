import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { fetchLicenses } from '../actions';
import LicensesTable from './LicensesTable';

export class LicensesTableContainer extends Component {
  static propTypes = {
    fetchLicenses: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchLicenses();
  }

  render() {
    return <LicensesTable {...this.props} />;
  }
}

const mapDispatchToProps = { fetchLicenses };

const mapStateToProps = (state) => ({
  isLoading: state.licenses.isFetching,
  licenses: state.licenses.all,
  error: state.licenses.error,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicensesTableContainer);
