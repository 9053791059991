import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';
import {
  linkAccount,
  resetAccountLink,
  duplicateAccount,
} from '../accountLinkActions';

import LinkAccountComponent from './LinkAccountComponent';
import { UpdateAccountNicknames } from '../../Profile/Tabs/AccountsTab/actions';

const mapStateToProps = (state, ownProps) => ({
  user: state.user.userData,
  accountLink: state.accountLink,
  accountLinked: ownProps.accountLinked,
});
const mapDispatchToProps = {
  linkAccount,
  resetAccountLink,
  push,
  duplicateAccount,
  updateAccountNicknames: UpdateAccountNicknames,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(LinkAccountComponent);
