import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import {
  deleteSavedBankAccount,
  clearDeleteBankAccountResponse,
  updateBankAccountAlias,
  clearUpdateBankAccountAlias,
  getSavedBankAccounts,
} from '../../../../Payments/Redux/actions/BankAccountActions';
import { COUNTRY_CODES } from '../../../../Payments/PaymentConstants';
import { updateBankAccountForm } from '../../../../Payments/Redux/actions/BankAccountFormActions';
import ManagePaymentMethods from './ManagePaymentMethods';
import * as paymentUtils from '../../../../Payments/Utils/PaymentUtil';

const getAssociatedOrgIds = (associatedOrgs) =>
  associatedOrgs.map((org) => org.id);

export const mapStateToProps = (state) => {
  const { bankAccountReducer: bankAccount } = state.payments;
  const shouldDisplaySpinner = paymentUtils.isLoading(state);

  return {
    shouldDisplaySpinner,
    paymentAccounts: bankAccount.paymentAccounts,
    user: state.user.userData,
    getUserStatus: state.user.status,
    getPaymentAccountStatus: bankAccount.getPaymentMethodStatus,
    getAccountStatus: state.accounts.status,
    deletePaymentMethodStatus: bankAccount.deleteTokenStatus,
    deleteTokenResponse: bankAccount.deleteTokenResponse,
    updateAliasStatus: bankAccount.bankAccountAliasStatus,
    accounts: state.accounts.list,
  };
};

export const mapDispatchToProps = {
  getPaymentAccountsAction: getSavedBankAccounts,
  updatePaymentByIdAction: updateBankAccountAlias,
  deletePaymentMethod: deleteSavedBankAccount,
  clearDeleteResponse: clearDeleteBankAccountResponse,
  clearUpdateAliasStatus: clearUpdateBankAccountAlias,
  push,
  getAccountsAction: fetchAssociatedOrgsAction,
  updatePaymentForm: updateBankAccountForm,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { paymentAccounts } = stateProps;

  let usAccounts = false;
  let canAccounts = false;

  paymentAccounts.forEach((paymentAccount) => {
    if (
      paymentAccount.accountCountryCode === COUNTRY_CODES.USA &&
      !usAccounts
    ) {
      usAccounts = true;
    }
    if (
      paymentAccount.accountCountryCode === COUNTRY_CODES.CANADA &&
      !canAccounts
    ) {
      canAccounts = true;
    }
  });

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    hasMultipleCountries: usAccounts && canAccounts,
    getPaymentAccounts: () => {
      const associatedOrgIds = getAssociatedOrgIds(
        stateProps.user.userDetails.associatedOrgs
      );
      dispatchProps.getPaymentAccountsAction(associatedOrgIds);
    },
    getAccounts: () => {
      dispatchProps.getAccountsAction(
        stateProps.user.userDetails.associatedOrgs
      );
    },
    deletePayment: (tokenId) => {
      const associatedOrgIds = getAssociatedOrgIds(
        stateProps.user.userDetails.associatedOrgs
      );
      dispatchProps.deletePaymentMethod(tokenId, associatedOrgIds);
    },
    updatePaymentById: (accountTokenId, alias) => {
      const associatedOrgIds = getAssociatedOrgIds(
        stateProps.user.userDetails.associatedOrgs
      );
      dispatchProps.updatePaymentByIdAction(
        accountTokenId,
        alias,
        associatedOrgIds
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ManagePaymentMethods);
