/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func, shape } from 'prop-types';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import endsWith from 'lodash/endsWith';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmployee } from 'shared/utils/tokenUtils/isEmployee';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import { useMediaQuery, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import PageNotification from 'shared/ui/NotificationHelper';
import Notification from '@nutrien/uet-react/Notification';
import AccountSalesOffice from '../AccountSalesOffice';
import BreadCrumb from '../Breadcrumb';
import EmployeeNotificationBar from '../../../Cart/components/EmployeeNotificationBar';
import FilterPanel from '../../containers/FilterPanelContainer/FilterPanelContainer';
import MiniCart from '../../containers/MiniCartContainer/MiniCartContainer';
import NoResults from './NoResults/NoResults';
import NutrienFinancialCard from '../NutrienFinancialCard/NutrienFinancialCard';
import ProductCard from '../ProductCard/ProductCard';
import ProductsModal from '../../containers/ProductsModalContainer/ProductsModalContainer';
import SortDropdown from '../../containers/SortDropdownContainer';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import { gtmLoadMoreProductsClick } from '../../../../store/middleware/TagManager/gtmActions';
import { setSearchQuery } from '../../actions/searchActions/searchActions';
import {
  setProductFilters,
  getProducts,
} from '../../actions/productActions/productActions';
import { setProductSort } from '../../actions/sortActions/sortActions';

import {
  sanitizeFilterParams,
  getUrlParameters,
  paramValueToArray,
  convertComma,
} from '../../utils/filterHelpers';

import './ProductListGrid.scss';

const filterTypes = [
  'chemicals',
  'fertilizers',
  'activeIngredients',
  'manufacturers',
  'cropTypes',
  'contTypes',
];

export const ProductListGrid = ({ location: { search: params }, history }) => {
  const employeeWrapper = isEmployee();
  let products = useSelector((state) => state.shop.products);
  let {
    data: productData,
    loadingMore,
    noMoreProducts,
    getMoreProductsError,
    loading,
    error,
    category,
    loadingPrices,
  } = products;
  const dispatch = useDispatch();
  const flags = useFlags();
  let {
    ecommercePricingModal,
    creditApp: creditAppFlag,
    usaSapProducts,
    westernRegionRestrictProducts,
  } = flags;
  let { query } = useSelector((state) => state.shop.search);
  let contractedProducts = useSelector((state) => state.contractedProducts);
  let { showProductModal } = useSelector(
    (state) => state.user.userData.userDetails
  );
  let cart = useSelector((state) => state.cart);
  let selectedAccount = useSelector((state) => state.selectedAccount);
  const isCanadianAcct = selectedAccount.locationCode === 'CAN';
  let [pageNum, setpageNum] = useState(1);
  let [modalOpen, setmodalOpen] = useState(showProductModal);
  let [mobileFilterPanelOpen, setMobileFilterPanelOpen] = useState(false);
  let [pricingModalButtonClicked, setPricingModalButtonClicked] =
    useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isFinancialCardVisible = !isCanadianAcct && creditAppFlag;

  useEffect(() => {
    if (params) {
      setpageNum(1);
      loadProducts();
    }
  }, [selectedAccount, params]);

  useEffect(() => {
    if (pageNum !== 1) {
      loadProducts(true);
    }
  }, [pageNum]);

  const getSortParams = () => {
    const search = params.substring(1);
    const searchParameters = getUrlParameters(search);

    if (!searchParameters.name && searchParameters.sort === '-_score') {
      delete searchParameters.sort;
      handleSearchSortQueryChange(search);
    }

    const queryParams = [];
    if (searchParameters.sort) {
      const { sort: sortParams } = searchParameters;
      if (validateSortName(sortParams)) {
        queryParams.push(`sort=${sortParams}`);
      }
    }
    return queryParams;
  };

  const validateSortName = (sort) => {
    const validNames = ['-_score', 'name', '-name', 'rank', '-rank'];
    if (validNames.indexOf(sort) !== -1) {
      return true;
    }
    return false;
  };

  const getContractParams = () => {
    const search = params.substring(1);
    const contractParams = getUrlParameters(search);
    const queryParams = [];

    if (contractParams.contTypes) {
      queryParams.push(`contTypes=${contractParams.contTypes}`);
    }
    return queryParams;
  };

  const getSearchParams = () => {
    const search = params.substring(1);
    const searchParameters = getUrlParameters(search);
    const queryParams = [];

    if (searchParameters.name) {
      const { name: nameParams } = searchParameters;
      queryParams.push(`search[name]=${nameParams}`);
    }
    return queryParams;
  };

  const getFilterParams = () => {
    const filters = get(products, 'meta.filters', null);
    const search = params.substring(1);
    const searchParameters = getUrlParameters(search);
    const queryParams = [];
    if (filters) {
      filterTypes.forEach((type) => {
        if (searchParameters[type]) {
          const urlParams = searchParameters[type];
          const sanitizedParams = sanitizeFilterParams(
            urlParams,
            filters[type],
            type
          );
          if (sanitizedParams) {
            const newParams = sanitizedParams.map((param) =>
              convertComma(param)
            );
            if (type === 'cropTypes') {
              // get guids based on ids
              const newArr = [];
              filters[type].forEach((obj) => {
                if (newParams.indexOf(obj.id) !== -1) {
                  newArr.push(obj.attributes.guid);
                }
              });

              queryParams.push(`filters[cropType]=${newArr.join(',')}`);
            } else if (type === 'contTypes') {
              processContractTypesParams(
                searchParameters,
                newParams,
                queryParams
              );
            }
          }
        }
      });
    }
    return queryParams;
  };

  const getContractSKUs = (contractNumber) => {
    let contractedProductsData = get(contractedProducts, 'data.data.items', []);

    if (contractNumber) {
      contractedProductsData = contractedProductsData.filter(
        (cp) => cp.contractNumber === contractNumber
      );
    }
    const skus = contractedProductsData.map((cp) => cp.productId);
    return [...new Set(skus)];
  };

  const setSearchName = (nameParam) => {
    const nameValue = nameParam[0];
    dispatch(setSearchQuery(nameValue));
  };

  const setFilter = (type, param) => {
    let paramValue;
    if (!isEmpty(param)) {
      // eslint-disable-next-line prefer-destructuring
      paramValue = param[0].split('=')[1];
    } else {
      paramValue = [];
    }
    const arrayValue = paramValueToArray(paramValue);
    dispatch(setProductFilters(type, arrayValue));
  };

  const setSort = (sortParam) => {
    if (!isEmpty(sortParam)) {
      const sortParamValue = sortParam[0].split('=')[1];

      dispatch(setProductSort(sortParamValue));
    }
  };

  const processContractTypesParams = (
    searchParameters,
    newParams,
    queryParams
  ) => {
    if (newParams.includes('sku')) {
      const contParam = get(searchParameters, 'cont', '');
      const skus = getContractSKUs(contParam);
      queryParams.push(`filters[variantId]=${skus.join(',')}`);
    }
  };

  // Sorting by relevancy should only be active if also searching. When searching is closed, massage the query to the correct state
  const handleSearchSortQueryChange = (search) => {
    let newSearch = search;
    newSearch = newSearch.replace('sort=-_score', '');
    newSearch = newSearch[0] === '&' ? newSearch.substring(1) : newSearch;
    newSearch =
      newSearch[newSearch.length - 1] === '&'
        ? newSearch.slice(0, -1)
        : newSearch;
    dispatch(setSearchQuery('test: ', newSearch));
    history.push(`/products?${search}`);
  };

  const toggleMobileFilterPanel = () => {
    mobileFilterPanelOpen = !mobileFilterPanelOpen;
    setMobileFilterPanelOpen(mobileFilterPanelOpen);
  };

  const validateAndSanitizeParams = () => {
    const allQueryParams = buildQueryParams();
    parseQueryParams(allQueryParams);

    return `?${allQueryParams.join('&')}`;
  };

  const parseQueryParams = (allQueryParams) => {
    const sortParam = allQueryParams.filter((param) => param.includes('sort'));
    const activeIngredientParam = allQueryParams.filter(
      (param) =>
        param.includes('activeIngredient') || param.includes('ActiveIngredient')
    );

    const manufacturerParam = allQueryParams.filter(
      (param) =>
        param.includes('manufacturer') || param.includes('Manufacturer')
    );

    const cropTypeParam = allQueryParams.filter(
      (param) => param.includes('cropType') || param.includes('CropType')
    );

    const chemicalParam = allQueryParams.filter((param) =>
      param.includes('filters[categoryChemicalName]')
    );

    const fertilizerParam = allQueryParams.filter((param) =>
      param.includes('filters[categoryFertilizerName]')
    );

    const nameParam = allQueryParams.filter((param) => param.includes('name'));

    const contractParams = allQueryParams.filter((param) =>
      param.includes('contTypes')
    );

    setSort(sortParam);
    setFilter('chemicals', chemicalParam);
    setFilter('fertilizers', fertilizerParam);
    setFilter('activeIngredients', activeIngredientParam);
    setFilter('manufacturers', manufacturerParam);
    setFilter('cropTypes', cropTypeParam);
    setFilter('contract', contractParams);
    setSearchName(nameParam, allQueryParams);
  };

  const buildQueryParams = () => {
    const filterParams = getFilterParams();
    const sortParams = getSortParams();
    const searchParams = getSearchParams();
    const contractParams = getContractParams();
    let queryParams = [`page[num]=${pageNum}`, 'page[count]=30'];
    queryParams = [
      ...queryParams,
      ...sortParams,
      ...filterParams,
      ...searchParams,
      ...contractParams,
    ];
    return queryParams;
  };

  const handleGetStarted = () => {
    setmodalOpen(false);
  };

  const handleOpenModal = () => {
    setmodalOpen(false);
    setPricingModalButtonClicked(true);
    setmodalOpen(false);
    setPricingModalButtonClicked(true, () => {
      setmodalOpen(true);
    });
  };

  const handleLoadMoreClick = (totalResults) => {
    loadMoreProducts();
    dispatch(gtmLoadMoreProductsClick(totalResults));
  };

  const buildMoreProductsButton = (totalCount) => {
    const dontShowLoadMore = productData.length < 30 || noMoreProducts;

    let section = (
      <div className="product-list-grid__loading-more-wrapper">
        {loadingMore ? (
          <MaterialLoadingIcon />
        ) : (
          <Button
            onClick={() => handleLoadMoreClick(totalCount)}
            title="Load More"
            data-test="load-more-button"
          >
            Load More Products
          </Button>
        )}
      </div>
    );
    if (dontShowLoadMore) {
      section = null;
    }
    if (getMoreProductsError) {
      section = <ApiErrorMessage entityName="More Products" />;
    }
    return (
      <div className="product-list-grid__loading-more-wrapper">{section}</div>
    );
  };

  const buildContent = () => {
    let innerContent = (
      <div
        className="product-list-grid__loading-wrapper"
        data-test="product-list-grid-loading-wrapper"
      >
        <MaterialLoadingIcon />
      </div>
    );

    const { meta } = products;
    const totalCount = meta ? meta.totalCount : 0;
    const recentlyOrdered = false;

    // Logic needs to be tested
    const { subCategory } = products;
    let mainCategory = 'All Products';

    if (category && !subCategory) {
      mainCategory = `All ${capitalizeFirstLetter(category)}s`;
    }
    if (productData.length && !loading) {
      let cards = {};
      if (mainCategory === 'SEED') {
        cards = null;
      } else if (mainCategory === 'FERTILIZER') {
        // TODO
        cards = null;
      } else {
        cards = productData.map((item, index) => {
          const { id, attributes } = item;
          return (
            <ProductCard
              index={index}
              key={id}
              id={id}
              attributes={attributes}
              cart={cart}
              handleOpenModal={handleOpenModal}
              loadingPrices={loadingPrices}
              recentlyOrdered={recentlyOrdered}
            />
          );
        });
      }
      innerContent = (
        <div
          className="product-list-grid__outer-wrapper"
          data-test="product-list-wrapper"
        >
          <div className="product-list-grid__results-header-wrapper">
            <div className="product-list-grid__results-header">
              <span
                data-test="category-name"
                className="product-list-grid__category-name"
              >
                {mainCategory}
              </span>
              <span>{`${totalCount} Results`}</span>
            </div>
            <SortDropdown />
            <Button
              onClick={() => toggleMobileFilterPanel()}
              className="product-list-grid__mobile-filter"
              variant="contained"
            >
              Filter
            </Button>
          </div>
          <div
            className="product-list-grid__product-list-wrapper"
            data-test="product-list-grid-wrapper"
          >
            {cards}
            {buildMoreProductsButton(totalCount)}
          </div>
        </div>
      );
    }

    if (error) {
      innerContent = <ApiErrorMessage entityName="Products" />;
    }

    if (!productData.length && !loading) {
      innerContent = <>{buildNoResults()}</>;
    }

    if (
      (usaSapProducts && selectedAccount.usaSapAccount) ||
      westernRegionRestrictProducts
    ) {
      innerContent = <>{buildProductsDisabled()}</>;
    }

    return <>{innerContent}</>;
  };

  const buildProductsDisabled = () => {
    return (
      <section className="product-list-grid__products-disabled-wrapper">
        <h1 data-test="products-disabled-title">Product Catalog Disabled</h1>
        <PageNotification
          className="cart_usa-sap product-info-grid__usa-sap-notification"
          data-test="product-catalog-disabled-usa-sap-warning"
          link="/"
          linkDescription="Return Home"
          message="While the Product Catalog is unavailable, please contact your Crop
              Consultant or local branch for information on product pricing and
              availability."
          type="WARNING"
        />
      </section>
    );
  };

  const buildNoResults = () => {
    return (
      <section className="product-list-grid__no-results-wrapper">
        {query ? (
          <NoResults query={query} handleOpenModel={handleOpenModal} />
        ) : (
          <h1 data-test="no-results-filters">
            No Results Found With Current Filters
          </h1>
        )}
      </section>
    );
  };

  const pricingModalClose = () => {
    setPricingModalButtonClicked(false);
  };

  const loadProducts = (isLoadMore = false) => {
    let parameters = validateAndSanitizeParams();
    const account = selectedAccount;

    const search = window.location.search.split('&');
    search.shift();
    const urlParameters = search.reduce((acc, filter) => {
      let values = filter.split('=');
      if (endsWith(values[0], 's')) {
        let newValue = values[0].split('');
        newValue.pop();
        values[0] = newValue.join('');
      }
      const arrayValue = values[1].replace(/%2C/g, ',');
      let string;
      switch (values[0]) {
        case 'activeIngredient':
          string = `&filters[${values[0]}]=${arrayValue}`;
          break;
        case 'manufacturer':
          string = `&filters[${values[0]}Name]=${arrayValue}`;
          break;
        default:
          string = `&filters[category${capitalize(
            values[0]
          )}Name]=${arrayValue}`;
          break;
      }

      if (!parameters.includes(string.split('&')[1])) return acc.concat(string);
      return acc;
    }, '');

    parameters = parameters.concat(urlParameters);

    if (account.id && Object.values(flags).length > 0) {
      dispatch(getProducts(parameters, account, isLoadMore, false, '', flags));
    }
  };

  const loadMoreProducts = () => {
    setpageNum(pageNum + 1);
  };

  return (
    <div
      className={
        employeeWrapper
          ? 'product-list-grid__outer-shop-wrapper--employee'
          : 'product-list-grid__outer-shop-wrapper'
      }
      data-test="outer-shop-wrapper"
    >
      <EmployeeNotificationBar />
      <MiniCart history={history} />
      {pricingModalButtonClicked && (
        <ProductsModal
          open
          handleGetStarted={handleGetStarted}
          modalClose={pricingModalClose}
          modalOpenedThroughButtonClick
        />
      )}
      {ecommercePricingModal && (
        <ProductsModal
          open={modalOpen}
          handleGetStarted={handleGetStarted}
          modalOpenedThroughButtonClick={pricingModalButtonClicked}
          modalClose={pricingModalClose}
        />
      )}
      <BreadCrumb
        history={history}
        category={products.category}
        subCategory={products.subCategory}
      />
      <AccountSalesOffice salesOffice={selectedAccount.branchName} />
      <div className="product-list-grid__outer-plp-wrapper">
        <div data-test="filter-panel-and-financial-card">
          <FilterPanel
            toggleMobileFilterPanel={toggleMobileFilterPanel}
            mobileFilterPanelOpen={mobileFilterPanelOpen}
          />
          {!isMobile && isFinancialCardVisible && <NutrienFinancialCard />}
        </div>
        {buildContent()}
      </div>
      {isMobile && isFinancialCardVisible && <NutrienFinancialCard />}
    </div>
  );
};

ProductListGrid.propTypes = {
  location: shape({}).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default ProductListGrid;
