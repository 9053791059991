import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@nutrien/uet-react';
import { customFormat } from 'shared/utils/DateHelper';

import './DateRangePicker.scss';

const DateRangePicker = (props) => {
  const { dateRange, onChange, ...rest } = props;
  const [startDate, handleStartDateChange] = useState(dateRange.start);
  const [endDate, handleEndDateChange] = useState(dateRange.end);

  const updateDateRange = (newDateRange) => onChange(newDateRange);

  const updateStartDate = (date) => {
    handleStartDateChange(date);
    updateDateRange({
      start: customFormat(date, 'YYYYMMDD'),
      end: customFormat(endDate, 'YYYYMMDD'),
    });
  };

  const updateEndDate = (date) => {
    handleEndDateChange(date);
    updateDateRange({
      start: customFormat(startDate, 'YYYYMMDD'),
      end: customFormat(date, 'YYYYMMDD'),
    });
  };

  return (
    <div className="date-range-picker__container">
      <DatePicker
        label="From"
        onChange={(date) => updateStartDate(date.toDate())}
        value={startDate}
        {...rest}
      />
      <DatePicker
        label="To"
        onChange={(date) => updateEndDate(date.toDate())}
        value={endDate}
        {...rest}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
      .isRequired,
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
      .isRequired,
  }).isRequired,
};

export default DateRangePicker;
