import apiAdapter from 'shared/lib/cxhApiService';

export const getAccountById = (id) =>
  apiAdapter.get(`v1/accounts/${id}`).then((res) => {
    const isCanadian = res.data.locationCode === 'CAN';
    if (isCanadian) {
      res.data.balance += res.data.availableRebate + res.data.availableDiscount;
      res.data.currentDue +=
        res.data.currentAvailableRebates + res.data.currentAvailableDiscounts;
    }
    return res.data;
  });

export const getAccountAddress = (accountId) =>
  apiAdapter
    .get(`v2/shipping-addresses`, { headers: { 'account-id': accountId } })
    .then((result) => result.data);

export const getAccountActivity = (id) =>
  apiAdapter.get(`v1/accounts/${id}/activity`).then((res) => res.data);

export const getPrepayDetails = (id) =>
  apiAdapter.get(`v1/accounts/${id}/prepay`).then((res) => res.data);

export const getPrepayHistory = (id, startDate, endDate) =>
  apiAdapter
    .get(`v1/accounts/${id}/prepay/history`, { params: { startDate, endDate } })
    .then((response) => response.data);

/**
 * Sends a request for account data in the desired file format.
 * @param  {String} fileType The file type the user wants their data in (csv or qfx)
 * @param  {String} accountId The user's account id
 * @return {Promise}         Axios promise
 */
export const getAccountActivityData = (fileType, accountId) =>
  apiAdapter
    .get(`v1/accounts/${accountId}/activity/data?type=${fileType}`)
    .then((res) => res.data);
