import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from 'nutrien-common-components-react/dist/mdc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Pagination,
  PaginationNext,
  PaginationPrev,
  PageNotification,
} from 'shared/ui';
import { useTheme, useMediaQuery } from '@material-ui/core';
import InvoiceDateRangeFilter from './Filters/InvoiceDateRangeFilter';
import DueDateRangeFilter from './Filters/DueDateRangeFilter';

import { invoices as invoicesPropType } from '../../invoicesPropTypes';
import InvoiceListItem from './InvoiceListItem';
import InvoiceIdFilter from './Filters/InvoiceIdFilter';
import InvoiceStatusFilter from './Filters/InvoiceStatusFilter';
import InvoiceGrossAmountFilter from './Filters/InvoiceGrossAmountFilter';
import InvoiceAmountDueFilter from './Filters/InvoiceAmountDueFilter';
import { INVOICE_DOWNLOAD_LIMIT } from './constants';

import './styles.scss';

const mapInvoicesByAttribute = (invoices, attribute) =>
  invoices
    .filter((invoice) => !invoice.isSupressed)
    .map((invoice) => invoice[attribute]);

export const getMaxAmount = (invoices, attribute) =>
  Math.ceil(Math.max(...mapInvoicesByAttribute(invoices, attribute)));

export const getMinAmount = (invoices, attribute) =>
  Math.floor(Math.min(...mapInvoicesByAttribute(invoices, attribute)));

const InvoicesTable = ({
  accountId,
  allSelectedInvoiceIds,
  areCheckboxesDisabled: areCheckboxesDisabledProp,
  currentPage,
  handlePaginationClick,
  includeGrossAmount,
  invoices,
  allInvoices,
  pageSize,
  paginatedInvoices,
  selectAllChecked,
  selectAllInvoices,
  deselectAllInvoices,
  showColumnFilters,
  toggleInvoice,
  gtmViewInvoiceAction,
}) => {
  const theme = useTheme();
  const { invoicesCheckbox } = useFlags();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Modified to allow the user to only select 24 (one page) of invoices
  // at a time to download due to server limitations
  // See: https://agrium.atlassian.net/browse/COMM-10632
  const areCheckboxesDisabled =
    areCheckboxesDisabledProp ||
    allSelectedInvoiceIds.length >= INVOICE_DOWNLOAD_LIMIT;

  return (
    <>
      {/* Invoice selection has been capped at 24 max due to server limitations. See: https://agrium.atlassian.net/browse/COMM-10632 */}
      {selectAllChecked && (
        <PageNotification
          className="invoice-selection-cap-notification"
          message="Selecting all invoices only applies to this page. It does not select invoices on any other page."
          type="WARNING"
        />
      )}
      {/* Invoice selection has been capped at 24 max due to server limitations. See: https://agrium.atlassian.net/browse/COMM-10632 */}
      {allSelectedInvoiceIds.length >= INVOICE_DOWNLOAD_LIMIT && (
        <PageNotification
          className="invoice-selection-cap-notification"
          message="You have reached the maximum number of selected invoices."
          type="WARNING"
        />
      )}
      <Table className="invoices-list">
        {!isMobile ? (
          <TableHead className="invoice-header">
            <TableRow>
              {invoicesCheckbox ? (
                <TableCell
                  padding="checkbox"
                  colSpan={1}
                  className="invoices-list__select-all-checkbox-cell"
                >
                  <Checkbox
                    checked={selectAllChecked}
                    id="select-all-checkbox"
                    onChange={(event) => {
                      if (event.target.checked) {
                        selectAllInvoices();
                      } else {
                        deselectAllInvoices();
                      }
                    }}
                    disabled={!selectAllChecked && areCheckboxesDisabled}
                  />
                </TableCell>
              ) : (
                <TableCell />
              )}
              <TableCell className="header-label" colSpan={2}>
                <span>Date</span>
                {showColumnFilters && (
                  <InvoiceDateRangeFilter
                    currentPage={currentPage}
                    handlePaginationClick={handlePaginationClick}
                  />
                )}
              </TableCell>
              <TableCell className="header-label" colSpan={2}>
                <span>Invoice ID</span>
                {showColumnFilters && (
                  <InvoiceIdFilter
                    currentPage={currentPage}
                    handlePaginationClick={handlePaginationClick}
                  />
                )}
              </TableCell>

              {includeGrossAmount && (
                <TableCell className="header-label" colSpan={2}>
                  <span>Gross Amount</span>
                  {showColumnFilters && (
                    <InvoiceGrossAmountFilter
                      maxGrossAmount={getMaxAmount(
                        allInvoices,
                        'grossInvoiceAmount'
                      )}
                      minGrossAmount={getMinAmount(
                        allInvoices,
                        'grossInvoiceAmount'
                      )}
                      currentPage={currentPage}
                      handlePaginationClick={handlePaginationClick}
                    />
                  )}
                </TableCell>
              )}
              <TableCell className="header-label" colSpan={2}>
                <span>Amount Due</span>
                {showColumnFilters && (
                  <InvoiceAmountDueFilter
                    maxAmountDue={getMaxAmount(allInvoices, 'amountDue')}
                    minAmountDue={getMinAmount(allInvoices, 'amountDue')}
                  />
                )}
              </TableCell>
              <TableCell className="header-label" colSpan={2}>
                <span>Due Date</span>
                {showColumnFilters && (
                  <DueDateRangeFilter
                    currentPage={currentPage}
                    handlePaginationClick={handlePaginationClick}
                  />
                )}
              </TableCell>
              <TableCell className="header-label" colSpan={2}>
                <span>Status</span>
                {showColumnFilters && (
                  <InvoiceStatusFilter
                    currentPage={currentPage}
                    handlePaginationClick={handlePaginationClick}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {paginatedInvoices.length
            ? paginatedInvoices.map((invoice) => {
                const checked = allSelectedInvoiceIds.includes(`${invoice.id}`);
                return (
                  <InvoiceListItem
                    date={invoice.date}
                    accountId={accountId}
                    id={invoice.id}
                    grossAmount={invoice.grossInvoiceAmount}
                    amountDue={invoice.amountDue}
                    dueDate={invoice.dueDate}
                    status={invoice.status}
                    key={invoice.id}
                    onChange={toggleInvoice}
                    checked={checked}
                    includeGrossAmount={includeGrossAmount}
                    disabled={!checked && areCheckboxesDisabled}
                    gtmViewInvoiceAction={gtmViewInvoiceAction}
                    isMobile={isMobile}
                  />
                );
              })
            : null}
        </TableBody>
      </Table>
      <div className="invoices-pagination-container">
        <Pagination
          className="invoices-desktop-pagination"
          current={currentPage}
          total={invoices.length}
          onChange={handlePaginationClick}
          pageSize={pageSize}
        />
        <Pagination
          className="pagination__mobile"
          current={currentPage}
          total={invoices.length}
          onChange={handlePaginationClick}
          pageSize={pageSize}
          prevIcon={PaginationPrev}
          nextIcon={PaginationNext}
          simple
        />
      </div>
    </>
  );
};

InvoicesTable.propTypes = {
  accountId: PropTypes.string,
  allSelectedInvoiceIds: PropTypes.arrayOf(PropTypes.string),
  areCheckboxesDisabled: PropTypes.bool,
  currentPage: PropTypes.number,
  handlePaginationClick: PropTypes.func.isRequired,
  includeGrossAmount: PropTypes.bool,
  invoices: invoicesPropType,
  allInvoices: invoicesPropType.isRequired,
  pageSize: PropTypes.number,
  paginatedInvoices: invoicesPropType,
  selectAllChecked: PropTypes.bool,
  selectAllInvoices: PropTypes.func.isRequired,
  deselectAllInvoices: PropTypes.func.isRequired,
  showColumnFilters: PropTypes.bool,
  toggleInvoice: PropTypes.func.isRequired,
  gtmViewInvoiceAction: PropTypes.func.isRequired,
};

InvoicesTable.defaultProps = {
  accountId: undefined,
  allSelectedInvoiceIds: [],
  areCheckboxesDisabled: false,
  currentPage: 1,
  includeGrossAmount: true,
  invoices: [],
  pageSize: 24,
  paginatedInvoices: [],
  selectAllChecked: false,
  showColumnFilters: false,
};

export default InvoicesTable;
