import jwtDecode from 'jwt-decode';
import { getLocalAccessToken } from '@nutrien/fe-domain-utils';

export const validToken = () => !!getLocalAccessToken();

export const validateToken = (token) => {
  const { session, user } = token;
  const { authorizationToken, expiration } = session;

  // verify that the token's userId matches the user given
  const decodedToken = jwtDecode(authorizationToken);
  if (decodedToken.userId !== user.id) {
    throw new Error('Invalid User Token');
  }

  // verify that the token is not expired
  const exp = decodedToken.exp || expiration;
  if (exp * 1000 < new Date()) {
    throw new Error('Token Is Expired');
  }

  return true;
};
