import React from 'react';
import { connect } from 'react-redux';
import UserPropType from 'shared/config/userPropType';
import withNotifications from '../withNotifications';
import NotificationsMenu from './NotificationsMenu';

export const NotificationsMenuContainer = (props) => (
  <NotificationsMenu {...props} />
);

NotificationsMenuContainer.propTypes = {
  user: UserPropType,
};

NotificationsMenuContainer.defaultProps = {
  user: {},
};

export const mapStateToProps = (state) => ({
  user: state.user.userData,
});

export default connect(mapStateToProps)(
  withNotifications(NotificationsMenuContainer)
);
