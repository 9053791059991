import React from 'react';
import { Route, Switch } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { shape, string } from 'prop-types';

import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import FlaggedRoute from '../../FlaggedRoute';
import { WithFetchAccountsContainer } from '../containers/containers';
import ProductDetail from './ProductDetail';
import { accountsPropTypes, userPropTypes } from '../shared/propTypes';
import UserLocationContext from '../utils/contexts/locationCodeContext';
import ProductListGrid from './ProductListGrid/ProductListGrid';

export const App = ({ selectedAccount, accounts, user }) => {
  if (
    (accounts && accounts.list && !accounts.list.length) ||
    (user && isEmpty(user.userDetails)) ||
    isEmpty(selectedAccount)
  ) {
    return <MaterialLoadingIcon />;
  }

  return (
    <UserLocationContext.Provider value={selectedAccount.locationCode}>
      <Switch>
        <Route exact path="/products" component={ProductListGrid} />
        <FlaggedRoute
          exact
          flagKey="global-ecommerce"
          path="/products/:id"
          component={ProductDetail}
        />
      </Switch>
    </UserLocationContext.Provider>
  );
};

App.defaultProps = {
  accounts: { list: [] },
  selectedAccount: {},
  user: null,
};

App.propTypes = {
  accounts: accountsPropTypes,
  selectedAccount: shape({
    locationCode: string,
  }),
  user: userPropTypes,
};

export default WithFetchAccountsContainer(App);
