// TODO: this entire directory should be in shared/ui,
// but currently work is being done by multiple devs, so for now it'll stay,
// until a single develop can safely move and test
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fileTypesList } from './exportLinksPropTypes';
import ExportLinksList from './ExportLinksList';
import ExportLink from './ExportLink';

// this is a deviation from our usual Container classes-
// A Higher Order Component that does not derive any value from state,
// nor does it have any dispatchers it receives through connect,
// but it does generate properties to pass to the ExportLinksList element
export default class ExportLinksListContainer extends Component {
  constructor(props) {
    super(props);

    let loadingValues = {};

    props.fileTypes.forEach((fileType) => {
      loadingValues = { ...loadingValues, [fileType]: false };
    });

    this.state = {
      showErrorMessage: false,
      loadingValues,
    };
  }

  handleClickExport = (e) => {
    const fileType = e.target.value;
    const { exportAction } = this.props;

    this.setState({
      showErrorMessage: false,
      loadingValues: { ...this.state.loadingValues, [fileType]: true },
    });
    return exportAction(fileType)
      .then(() => {
        this.setState({
          loadingValues: { ...this.state.loadingValues, [fileType]: false },
        });
      })
      .catch((error) => {
        this.setState({
          showErrorMessage: true,
          loadingValues: { ...this.state.loadingValues, [fileType]: false },
        });
        return Promise.reject(error);
      });
  };

  handleNotificationClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showErrorMessage: false });
  };

  render() {
    const { fileTypes } = this.props;
    return (
      <ExportLinksList
        showErrorMessage={this.state.showErrorMessage}
        onNotificationClose={this.handleNotificationClose}
      >
        {fileTypes.map((fileType) => (
          <ExportLink
            key={fileType}
            exportType={fileType}
            onClickExport={this.handleClickExport}
            isLoading={this.state.loadingValues[fileType]}
          />
        ))}
      </ExportLinksList>
    );
  }
}

ExportLinksListContainer.propTypes = {
  // The action to be fired when a link is clicked- it should require only the file type as input
  exportAction: PropTypes.func.isRequired,
  // An array of file types for which links should be rendered (e.g. ['pdf', 'csv'])
  fileTypes: fileTypesList.isRequired,
};
