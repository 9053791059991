// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import KEY_ENUM from 'shared/utils/KeyCodes';

import hide from 'assets/visibility--hide.svg';
import show from 'assets/visibility--show.svg';

import styles from './styles';

// TODO: This is a high priority to update to UET (causing many design inconsistencies!)
const FormTextField = (props) => {
  const {
    name,
    title,
    subtitle,
    helper,
    helperDescription,
    value,
    autocomplete,
    type,
    handleChange,
    onBlur,
    toggleShowValue,
    shouldValueShow,
    toggleValue,
    description,
    error,
    classes,
    placeholder,
    maxLength,
  } = props;

  const inputWithIcon = (
    <div className={classes.inputWithIcon}>
      <input
        className={classes.input}
        id={name}
        type={shouldValueShow ? 'text' : 'password'}
        name={name}
        value={value}
        autoComplete={autocomplete}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      <span
        role="button"
        tabIndex="-1"
        onKeyPress={() => toggleShowValue(toggleValue)}
        onClick={() => toggleShowValue(toggleValue)}
      >
        <img src={shouldValueShow ? hide : show} alt="toggle show value" />
      </span>
    </div>
  );

  let input;
  if (shouldValueShow !== null) {
    input = inputWithIcon;
  } else {
    input = (
      <input
        className={classes.input}
        id={name}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        autoComplete={autocomplete}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    );
  }

  return (
    <div className={classes.textField}>
      <label htmlFor={name} className={classes.label} id={`${name}Label`}>
        {title}{' '}
        {subtitle ? (
          <span className={classes.subtitle}>{`(${subtitle})`}</span>
        ) : null}
        {helperDescription ? (
          <span
            id={name}
            className={classes.helper}
            onClick={helper}
            onKeyPress={(e) => {
              e.stopPropagation();
              if (e.key === KEY_ENUM.ENTER) {
                helper();
              }
            }}
            role="button"
            tabIndex="0"
          >
            {helperDescription}
          </span>
        ) : null}
      </label>
      {input}
      {description ? (
        <div className={classes.description}>{description}</div>
      ) : null}
      {error ? <span className={classes.error}>{error}</span> : null}
    </div>
  );
};

FormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  helper: PropTypes.func,
  helperDescription: PropTypes.string,
  value: PropTypes.string,
  autocomplete: PropTypes.string,
  toggleShowValue: PropTypes.func,
  shouldValueShow: PropTypes.bool,
  toggleValue: PropTypes.string,
  type: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  description: PropTypes.string,
  error: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
};

FormTextField.defaultProps = {
  placeholder: '',
  subtitle: null,
  helper: null,
  helperDescription: null,
  value: '',
  autocomplete: null,
  onBlur: null,
  toggleShowValue: null,
  shouldValueShow: null,
  toggleValue: null,
  type: 'text',
  description: null,
  error: null,
  maxLength: null,
};

export default withStyles(styles)(FormTextField);
