export default {
  bankAccountTypeSection: {
    marginBottom: '30px',
  },
  accountInfo: {
    width: '100%',
  },
  accountInfoTitle: {
    // todo is this still needed?
    fontSize: '22px',
    height: '33px',
    marginBottom: '20px',
  },
  accountInfoRadio: {
    color: '#004538',
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
    height: '14px',
    marginBottom: '20px',
    '& input[type="radio"]': {
      margin: '0 10px 0 0',
    },
  },
  accountInfoBankName: {
    paddingLeft: '10px',
    fontSize: '10px',
    height: '16px',
    paddingBottom: '25px',
    marginTop: '-15px',
  },
  accountInfoImage: {
    height: '24px',
    width: '17px',
    marginRight: '5px',
  },
  '@media (max-width: 480px)': {
    billingInfoShareText: {
      width: 'auto',
    },
  },
};
