import React, { useEffect } from 'react';
import T from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth, AuthenticatedComponent } from '@nutrien/fe-domain-utils';
import VerifyEmail from 'components/VerifyEmail';
import LinkAccountPrompt from 'components/LinkAccount/LinkAccountPrompt';

export const AuthenticatedRoute = ({
  component: Component,
  redirect,
  validator,
  ...rest
}) => {
  const auth = useAuth();
  const location = useLocation();

  // redirect to Auth0 login page if enabled and not authenticated
  useEffect(() => {
    if (auth.isAuth0Active && !auth.isAuthenticated && !auth.isLoading) {
      auth.loginWithRedirect({
        appState: { returnTo: location.pathname + location.search },
      });
    }
  }, [auth, location]);

  const unauthenticatedComponent = auth.isAuth0Active ? null : (
    <Redirect to={{ pathname: redirect, state: { from: location } }} />
  );

  if (!validator()) {
    return unauthenticatedComponent;
  }

  const includeLinkAccountPrompt = () => {
    return auth.isAuth0Active && location.pathname !== '/oca';
  };

  return (
    <AuthenticatedComponent unAuthenticatedComponent={unauthenticatedComponent}>
      {
        /* eslint-disable-next-line camelcase */
        !auth.isAuth0Active || auth.user?.email_verified ? (
          <Route
            {...rest}
            render={(props) => (
              <>
                {includeLinkAccountPrompt() && <LinkAccountPrompt />}
                <Component {...props} />
              </>
            )}
          />
        ) : (
          <VerifyEmail />
        )
      }
    </AuthenticatedComponent>
  );
};

AuthenticatedRoute.propTypes = {
  redirect: T.string,
  component: T.oneOfType([T.element, T.func, T.node, T.object]).isRequired,
  validator: T.func,
};

AuthenticatedRoute.defaultProps = {
  redirect: '/login',
  validator: () => true,
};

export default AuthenticatedRoute;
