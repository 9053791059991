import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import lockIcon from 'assets/icn-lock.svg';
import { encryptionHelpText } from '../../PageComponents/MakeAPayment/MakeAPaymentConstants';

import styles from './styles';

const PaymentSubHeader = (props) => (
  <div className={props.classes.paymentSubHeader} name="paymentSubHeader">
    <img
      className={props.classes.accountInfoHelpImage}
      name="accountInfoHelpImage"
      alt="lockIcon"
      src={lockIcon}
    />
    <p
      className={props.classes.accountInfoEncryptionText}
      name="accountInfoEncryptionText"
    >
      {encryptionHelpText}
    </p>
  </div>
);

PaymentSubHeader.propTypes = {
  classes: PropTypes.shape({
    paymentSubHeader: PropTypes.string,
    accountInfoHelpImage: PropTypes.string,
    accountInfoEncryptionText: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(PaymentSubHeader);
