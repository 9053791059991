export default (theme) => ({
  headerText: {
    fontWeight: 600,
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: 20,
    },
  },
  headerGrid: {
    marginBottom: 20,
  },
  gridHeader: {
    color: theme.palette.text.support,
    fontSize: 12,
  },
  gridContent: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  checkImg: {
    width: 17.6,
    height: 13.4,
    verticalAlign: 'inherit',
  },
  // always applied to the tab, whether it's selected or unselected
  tabRoot: {
    color: theme.palette.text.support,
    '& span': {
      fontSize: 14,
    },
  },
  // only applied to selected tab
  tabSelected: { color: theme.palette.text.secondary, fontWeight: 600 },
});
