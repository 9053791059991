import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { Notification } from 'nutrien-common-components-react/dist/mdc';
import PageNotification from 'shared/ui/NotificationHelper';
import Button from '@material-ui/core/Button';
import { checkTotalInput } from 'utils/checkTotalInput';
import { verifyUsePackageUom } from 'utils/verifyUsePackageUom';
import ExpandingTextContainer from 'shared/ui/ExpandingTextContainer';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import { volumeOrQuantity } from 'shared/utils/VolumeOrQuantity/volumeOrQuantity';
import loadingDots from 'assets/loading-dots.gif';
import { Link } from 'react-router-dom';

import SingleVariant from 'components/Shop/components/SingleVariant/SingleVariant';
import { addToCart } from '../../../../Cart/actions/cartActions';
import { isValidBulkInput } from '../../../utils/validateInput';
import sortVariantDropDown from '../../../utils/sortVariantDropDown';
import ProductTotalCountTextbox from '../../../containers/ProductTotalCountTextboxContainer';
import ProductTotalVolumeDropdown from '../../../containers/ProductTotalVolumeDropdownContainer';
import ProductTotalVolumeTextbox from '../../../containers/ProductTotalVolumeTextboxContainer';
import ProductPrice from '../../ProductPrice/ProductPrice';
import VariantsDropdown from '../../VariantsDropdown';
import ProductImage from '../../ProductImage';

// import SimilarProductsContainer from '../../../containers/SimilarProductsContainer/SimilarProductsContainer';
// import ComplementaryProductsContainer from '../../../containers/ComplementaryProductsContainer/ComplementaryProductsContainer';
import ActiveIngredients from './ActiveIngredients';
import RestrictedWarning from './RestrictedWarning';
import ProductInfoLabel from './ProductInfoLabel';
import ProductInfoHeader from './ProductInfoHeader';
import validateSapUnitOfMeasure from '../../../utils/validateSapUnitOfMeasure';

import {
  sortActiveIngredientsByPercentageDesc,
  gtmSetSelectedVariant,
} from './utils';
import { useUserLocationContext } from '../../../utils/contexts/locationCodeContext';
import { CAN } from '../../../utils/contexts/locationCodeConstants';

import './ProductInfo.scss';
import '../../../../../shared/styles/index.scss';

const SAFETY_TEXT =
  'Always read and follow label directions. In addition, adhere to all State and local regulations governing the use of these products. If you have additional questions regarding the product or application, please contact your Nutrien Ag Solutions Representative.';

const TotalInputType = ({ usesPackageUom, selectedVariant }) => {
  const {
    is_bulk: isBulk,
    // eslint-disable-next-line camelcase
    total_volume_options,
  } = selectedVariant;

  // eslint-disable-next-line no-nested-ternary
  return isBulk ? (
    <ProductTotalVolumeTextbox
      data-test="product-info-total-volume-textbox"
      modifiesVariant
      variant={selectedVariant}
    />
  ) : usesPackageUom ? (
    <ProductTotalCountTextbox
      data-test="product-info-total-count-textbox"
      modifiesVariant
    />
  ) : (
    <ProductTotalVolumeDropdown
      data-test="product-info-total-volume-dropdown"
      variant={{ total_volume_options }}
    />
  );
};

const ProductInfo = ({ hasBeenClicked, setHasBeenClicked }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const product = useSelector((state) => state.shop.product);
  const selectedAccount = useSelector((state) => state.selectedAccount);

  const isCanada = useUserLocationContext() === CAN;
  const { canadaReadOnly, usaSapProducts, westernRegionRestrictProducts } =
    useFlags();
  const updatingCart = get(cart, 'updatingCart', {});
  const name = get(product, 'data.attributes.name', '');
  const selectedVariant = get(product, 'variant', {});
  const { selectedVolume, unit_measure: uom } = selectedVariant;

  const hasContract = false; // TODO: get from contracts;

  const usesPackageUom = verifyUsePackageUom(selectedAccount);

  const { hasError, errorMessage } = checkTotalInput(
    isCanada,
    usesPackageUom,
    selectedVariant,
    selectedVolume
  );

  const typeLabel = volumeOrQuantity(isCanada, usesPackageUom);

  const productData = get(product, 'data', {});
  const {
    variants,
    active_ingredients: activeIngredients,
    federallyRestricted,
    // crossSells,
  } = productData.attributes;
  const sortedVariant = sortVariantDropDown(variants);

  const sortedActiveIngredients =
    sortActiveIngredientsByPercentageDesc(activeIngredients);

  const gtmSetSelectedVariantWithParams = gtmSetSelectedVariant(
    dispatch,
    productData
  );

  const addVariantToCart = debounce((e, attributes, variant) => {
    e.persist();
    if (
      !isValidBulkInput(variant.digital_variant_name, variant.selectedVolume)
    ) {
      return;
    }

    const itemData = {
      attributes,
      accountID: selectedAccount.id,
      variant,
      branchID: selectedAccount.branchId,
    };

    dispatch(
      addToCart(itemData, cart.cartData, { origin: 'Product Detail Page' })
    );
    setHasBeenClicked(true);
  }, 250);
  return (
    <div className="product-info__white-background">
      {hasError && (
        <div className="product-detail__notification-container">
          <Notification
            type="danger"
            className="product-detail__notification--error"
            data-test="product-detail-notification-error"
          >
            {errorMessage}
          </Notification>
        </div>
      )}
      <section className="product-info__detail-container">
        <div
          className={classNames('product-info__image-column', {
            'has-contract': hasContract,
          })}
        >
          {hasContract ? (
            <div
              data-test="product-info__contracted-banner"
              className="product-info__contracted-banner"
            >
              CONTRACTED
            </div>
          ) : null}
          <ProductImage
            imgSrc={selectedVariant.image}
            alt={`${name} LOGO`}
            context="product-detail"
          />
          <p className="product-info__safety-blurb">{SAFETY_TEXT}</p>
        </div>

        <div className="product-info__detail-container-wrapper">
          {((usaSapProducts && selectedAccount.usaSapAccount) ||
            westernRegionRestrictProducts) && (
            <PageNotification
              className="cart_usa-sap product-info-grid__usa-sap-notification"
              data-test="product-info-disabled-usa-sap-warning"
              link="/"
              linkDescription="Return Home"
              message="While the Product Catalog is unavailable, please contact your Crop
              Consultant or local branch for information on product pricing and
              availability."
              type="WARNING"
            />
          )}
          <ProductInfoHeader
            productData={productData}
            variant={selectedVariant}
          />
          <div className="product-info__detail-row">
            <div className="product-info__detail-column">
              <div>
                {productData.attributes.description.length ? (
                  <>
                    <h2 className="product-info__product-description-title">
                      Product Description
                    </h2>
                    <ExpandingTextContainer
                      className="product-info__product-description"
                      data-test="product-info-product-description"
                      text={productData.attributes.description}
                      limit={300}
                      expandingText="Show full description"
                      collapsingText="Hide full description"
                    />
                  </>
                ) : null}
                <ActiveIngredients
                  activeIngredients={sortedActiveIngredients}
                />
                <ProductInfoLabel productData={productData} />
                <RestrictedWarning
                  data-test="product-info-restricted-warning"
                  isRestricted={federallyRestricted}
                />
                <p className="product-info__safety-blurb--mobile">
                  {SAFETY_TEXT}
                </p>
              </div>
            </div>

            <div className="product-info__detail-column--right">
              <div className="inner-product-info-container">
                {product.loadingPrices ? (
                  <div
                    className="product-info__product-prices-loading-wrapper"
                    data-test="product-info-product-prices-loading-wrapper"
                  >
                    <img
                      className="product-info__product-prices-loading"
                      src={loadingDots}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <ProductPrice
                    variant={selectedVariant}
                    context="Product Detail Page"
                    contextualNoPriceStyle="product-price__no-price-wrapper"
                    contextualPriceStyle="product-price__no-price-message-pdp"
                  />
                )}
                <div className="product-info__product-options">
                  <div className="product-info__variants-wrapper ">
                    {variants && variants.length <= 1 ? (
                      <div>
                        <span className="product-detail__pack-size-label">
                          Pack Size
                        </span>
                        <SingleVariant
                          data-test="product-info-single-variant"
                          variant={variants[0]}
                        />
                      </div>
                    ) : (
                      <VariantsDropdown
                        data-test="product-detail-variants-dropdown"
                        label="Pack Size"
                        variants={sortedVariant}
                        selectedVariant={selectedVariant}
                        setSelectedVariant={gtmSetSelectedVariantWithParams}
                        isCanada={isCanada}
                      />
                    )}
                  </div>
                  <div className="product-info__total-input">
                    <span className="product-info__total-input--input-label">
                      {typeLabel}
                    </span>
                    <div className="product-info__total-input--input">
                      <TotalInputType
                        usesPackageUom={usesPackageUom}
                        selectedVariant={selectedVariant}
                      />
                      <span className="product-info__total-input--input-units">
                        {validateSapUnitOfMeasure(uom)}
                      </span>
                    </div>
                  </div>
                </div>
                {canadaReadOnly ? null : (
                  <DisabledForEmployees>
                    <Button
                      className="product-info__add-to-cart-button"
                      data-test="product-info-add-to-cart-button"
                      disabled={
                        (updatingCart && hasBeenClicked) ||
                        product.loadingPrices ||
                        hasError ||
                        westernRegionRestrictProducts ||
                        (usaSapProducts && selectedAccount.usaSapAccount)
                      }
                      onClick={(e) =>
                        addVariantToCart(e, productData.attributes, {
                          ...selectedVariant,
                        })
                      }
                      variant="contained"
                    >
                      Add to Cart
                    </Button>
                  </DisabledForEmployees>
                )}
              </div>
              <div
                className="product-options__input-message-container"
                data-test="product-options-input-message-container"
              >
                {hasError && (
                  <div
                    className="product-options__input-message--error"
                    data-test="product-options-input-message-error"
                  >
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  <section className="product-info__complementary-products-container"> */}
      {/*  <ComplementaryProductsContainer */}
      {/*    crossSells={crossSells} */}
      {/*    history={history} */}
      {/*  /> */}
      {/* </section> */}
      {/*  <section> */}
      {/*    <SimilarProductsContainer history={history} /> */}
      {/*  </section> */}
    </div>
  );
};

TotalInputType.propTypes = {
  selectedVariant: PropTypes.shape({
    is_bulk: PropTypes.bool,
    total_volume_options: PropTypes.array,
  }).isRequired,
  usesPackageUom: PropTypes.bool.isRequired,
};

ProductInfo.propTypes = {
  // history: PropTypes.shape({}).isRequired,
  hasBeenClicked: PropTypes.bool.isRequired,
  setHasBeenClicked: PropTypes.func.isRequired,
};

export { ProductInfo, TotalInputType };

export default ProductInfo;
