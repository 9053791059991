import React, { useState, useEffect } from 'react';
import { func, string, bool } from 'prop-types';
import classNames from 'classnames';

import { isValidPackageInput } from '../../utils/validateInput';

import './styles.scss';

const ProductTotalCountTextbox = ({
  value,
  modifiesVariant,
  modifiesCartItem,
  onKeyPress,
  onBlur,
  onChange,
  setVariantVolume,
  setTotalVolume,
  updateCartData,
}) => {
  const [count, setCount] = useState(value || 1);

  const setQuantity = (quantity) => {
    setCount(quantity);
    if (modifiesVariant && setVariantVolume) {
      setVariantVolume(quantity.toString());
    } else if (modifiesCartItem && setTotalVolume) {
      setTotalVolume(quantity.toString());
    }
  };

  const handleBlur = (e) => {
    let {
      target: { value: newValue },
    } = e;
    const inputValue = newValue.toString();

    if (!inputValue.trim().length || inputValue === '0' || newValue < 1) {
      newValue = 1;
    }

    setQuantity(newValue.toString());

    if (modifiesCartItem && isValidPackageInput(newValue) && updateCartData) {
      updateCartData(newValue.toString());
    }

    if (onBlur) onBlur(newValue);
  };

  const handleChange = (e) => {
    const {
      target: { value: newValue },
    } = e;
    const valid = isValidPackageInput(newValue);

    setQuantity(newValue);

    if (modifiesCartItem && valid && updateCartData) {
      updateCartData(newValue);
    }

    if (onChange) onChange(newValue, valid);
  };

  useEffect(() => {
    if (value !== count) {
      setQuantity(count);
    }
  }, []);

  return (
    <input
      data-test="total-count-input"
      className={classNames(
        { 'total-count__input--error': !isValidPackageInput(count) },
        'total-count__input'
      )}
      label="Count"
      value={count}
      type="number"
      min="1"
      step="1"
      onKeyPress={onKeyPress}
      onChange={handleChange}
      onBlur={handleBlur}
      validate={null}
    />
  );
};

ProductTotalCountTextbox.propTypes = {
  value: string,
  modifiesVariant: bool,
  modifiesCartItem: bool,
  onChange: func,
  onBlur: func,
  onKeyPress: func.isRequired,
  setVariantVolume: func,
  setTotalVolume: func,
  updateCartData: func,
};

ProductTotalCountTextbox.defaultProps = {
  value: null,
  modifiesVariant: false,
  modifiesCartItem: false,
  onChange: null,
  onBlur: null,
  setVariantVolume: null,
  setTotalVolume: null,
  updateCartData: null,
};

export default ProductTotalCountTextbox;
