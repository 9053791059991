import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import KEY_ENUM from 'shared/utils/KeyCodes';
import useOutsideClickHandler from 'utils/useOutsideClickHandler';
import Dropdown from './Dropdown';

const DropdownContainer = ({ onChange, validate, dropdownName, ...rest }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = () => setOpen(false);

  useOutsideClickHandler({
    node: wrapperRef,
    onOutsideClick: handleClickOutside,
  });

  const toggleDropdown = () => setOpen(!open);

  const selectOption = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    toggleDropdown();

    const finalValue = value.code === 'placeholder' ? {} : value;

    validate({ target: { value: finalValue, name: dropdownName } });
    onChange(finalValue);
  };

  const toggleDropdownByKeyPress = (event) => {
    if (event.key === KEY_ENUM.ENTER) {
      toggleDropdown();
    }
  };

  const selectOptionByKeyPress = (event, option) => {
    if (event.key === KEY_ENUM.ENTER) {
      selectOption(option);
    }
  };

  return (
    <Dropdown
      {...rest}
      toggleDropdownByKeyPress={toggleDropdownByKeyPress}
      selectOptionByKeyPress={selectOptionByKeyPress}
      selectOption={selectOption}
      toggleDropdown={toggleDropdown}
      open={open}
      wrapperRef={wrapperRef}
    />
  );
};

DropdownContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  dropdownName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      code: PropTypes.string,
      selected: PropTypes.bool,
    })
  ),
  placeholder: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
  shouldHavePlaceHolder: PropTypes.bool,
  selectedValue: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    code: PropTypes.string,
  }),
};

DropdownContainer.defaultProps = {
  options: [],
  errors: [],
  selectedValue: {},
  shouldHavePlaceHolder: true,
};

export default DropdownContainer;
