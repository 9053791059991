import {
  SET_PRODUCT_LABELS_OK,
  SET_PRODUCT_LABELS_LOADING,
  SET_PRODUCT_LABELS_ERROR,
} from './ProductLabelsActions';

import getProductLabels from './getProductLabels';

export function setProductLabelsOk(recentProducts) {
  return { type: SET_PRODUCT_LABELS_OK, payload: recentProducts };
}

export function setProductLabelsLoading() {
  return { type: SET_PRODUCT_LABELS_LOADING };
}

export function setProductLabelsError() {
  return { type: SET_PRODUCT_LABELS_ERROR };
}

export const fetchProductLabels = (productID, accountID) => (dispatch) => {
  dispatch(setProductLabelsLoading());
  getProductLabels(productID, accountID)
    .then((response) => dispatch(setProductLabelsOk(response)))
    .catch((error) => dispatch(setProductLabelsError(error)));
};
