const checkWhitespaceOnlyString = (str) => {
  let returnStr = str;

  if (!str.replace(/\s/g, '').length) {
    // string only contained whitespace (ie. spaces, tabs or line breaks)
    returnStr = null;
  }

  return returnStr;
};

export default checkWhitespaceOnlyString;
