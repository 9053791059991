import { push } from 'connected-react-router';
import { fetchUser } from 'actions';
import { setDefaultAccount } from '../../../Shop/actions/defaultAccountActions';
import mirroringFailed from './mirroringFailed';
import mirroringStartUpCompleted from './mirroringStartUpCompleted';
import mirroringStarted from './mirroringStarted';

export default (customer, redirectPath = '/') => (dispatch) => {
  dispatch(mirroringStarted(customer));

  dispatch(fetchUser())
    .then((resp) => dispatch(mirroringStarted(resp.data)))
    .then(() => dispatch(setDefaultAccount({})))
    .then(() => dispatch(push(redirectPath)))
    .then(() => dispatch(mirroringStartUpCompleted()))
    .catch((error) => dispatch(mirroringFailed(error)));
};
