import { push } from 'connected-react-router';
import {
  getLocalAccessToken,
  currentAuthContext,
} from '@nutrien/fe-domain-utils';
import { isEmployee, removeToken } from 'shared/utils/tokenUtils';
import { resolveCoreAuthApiUrl } from 'shared/lib/coreAuth/resolveCoreAuthApiUrl';
// eslint-disable-next-line import/no-cycle
import { persistor } from 'store';
import jwtDecode from 'jwt-decode';

import { resolveExhClientUrl } from 'utils/resolveExhClientUrl';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from './authenticationConstants';

export const loginSuccess = (session) => ({
  type: LOGIN_SUCCESS,
  payload: session,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  error,
});

export const logout = (
  redirectUrl = '/logged-out',
  sessionTimeout = false
) => async (dispatch) => {
  await persistor.purge();

  const localAccessToken = getLocalAccessToken();
  let isSsoLogin = false;
  if (localAccessToken) {
    const decodedToken = jwtDecode(localAccessToken);
    isSsoLogin = !!decodedToken.attributes?.isSsoLogin;
  }
  removeToken(); // TODO: Create Middleware to sync token and session redux state

  const isUserEmployee = isEmployee();
  if (isUserEmployee && isSsoLogin) {
    // is employee sso, need to clear out exh
    window.location.replace(`${resolveExhClientUrl()}/sso-logout`);
  } else if (!currentAuthContext.isAuth0Active) {
    dispatch({ type: LOGOUT });
  }

  persistor.persist();
  if (sessionTimeout) {
    sessionStorage.setItem('timeout', true);
  }

  // If we are hosted in GFA we need to tell the app to logout
  if (window.ReactNativeWebView) {
    const messageName =
      redirectUrl === '/site-maintenance'
        ? 'site_maintenance'
        : 'account_logout';

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        name: messageName,
        payload: {
          sessionTimeout,
        },
      })
    );
    // ... and end the GA session.
    window.ga('send', 'pageview', { sessionControl: 'end' });
  }

  if (currentAuthContext.isAuth0Active) {
    currentAuthContext.logout({
      returnTo: `${window.location.origin}${redirectUrl}`,
    });
  } else {
    let ssoRedirectUrl = redirectUrl;
    if (window.ReactNativeWebView) {
      ssoRedirectUrl = '/sso';
    }
    dispatch(push(ssoRedirectUrl));
  }
  window.localStorage.setItem('nutrien:user:logout', true); // force this for other tabs on the same domain
};

export const ssoLogout = () => () => {
  removeToken();
  return window.location.replace(`${resolveCoreAuthApiUrl()}v2/openid/logout`);
};
