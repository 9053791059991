import axios from 'axios';

import resolveCoreAuthApiUrl from './resolveCoreAuthApiUrl';
import authenticationInterceptor from './authenticationInterceptor';
import correlationIdInterceptor from '../cxhApiService/correlationIdInterceptor';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-store',
};

const service = axios.create({
  baseURL: resolveCoreAuthApiUrl(),
  headers,
});

// Request interceptors
service.interceptors.request.use(correlationIdInterceptor);
service.interceptors.request.use(authenticationInterceptor);

// axios.all not currently included axios instance methods
service.all = (requestArray) => axios.all(requestArray);
export default service;
