import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Tab as MaterialTab, Tabs, withStyles } from '@material-ui/core';

import {
  ApiErrorMessage,
  BreadCrumb,
  BreadCrumbContainer,
  MaterialLoadingIcon,
} from 'shared/ui';
import checkImg from 'assets/check--green.svg';
import { classes as classesPropType } from 'utils/propTypes';

import styles from './styles';
import { getExpirationDate } from '../permitUtils';
import permitTabsEnum from './permitTabsEnum';
import { permit as permitPropType } from '../permitsPropTypes';
import { viewSinglePermit } from '../actions/viewSinglePermit';
import PermitDetailsList from './PermitDetailsList';

const getTabClasses = (classes) => ({
  root: classes.tabRoot,
  selected: classes.tabSelected,
});
// though it would make sense to
const Tab = (value, classes) => (
  <MaterialTab
    label={value}
    value={value}
    classes={getTabClasses(classes)}
    data-test="permit-details-tab"
  />
);

const PermitDetailsPage = ({
  permit,
  isFetchingPermits,
  error,
  classes,
  selectedTab,
  handleChange,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(permit)) dispatch(viewSinglePermit(permit));
  }, [permit, dispatch]);

  return (
    <div id="permits-details-page">
      <BreadCrumbContainer noPadding>
        <BreadCrumb link="/" text="Home" />
        <BreadCrumb link="/Permits" text="Permits" />
        <BreadCrumb text={`Permit ${permit.permitId}`} />
      </BreadCrumbContainer>
      {error && <ApiErrorMessage entityName="Permits" />}
      {
        // if the permit wasn't found, there's a brief moment when both error and loading icon were visible,
        // this creates a better user experience to hide the loading if there is an error
        !error && isFetchingPermits && <MaterialLoadingIcon />
      }
      {!error && !isFetchingPermits && (
        <>
          <h1 className={classes.headerText}>Permit {permit.permitId}</h1>

          <div className={classes.headerGrid}>
            <Grid container>
              <Grid
                item
                md={2}
                xs={6}
                className={classes.gridItem}
                data-test="permit-details-header-grid-item"
              >
                <span className={classes.gridHeader}>Status</span>
                <div className={classes.gridContent}>
                  {permit.permitActive && (
                    <img
                      src={checkImg}
                      alt="permit active"
                      className={classes.checkImg}
                    />
                  )}
                  {permit.permitActive ? 'Active' : 'Inactive'}
                </div>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
                className={classes.gridItem}
                data-test="permit-details-header-grid-item"
              >
                <span className={classes.gridHeader}>Expiration Date</span>
                <div className={classes.gridContent}>
                  {getExpirationDate(permit).format('M/D/YYYY')}
                </div>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
                className={classes.gridItem}
                data-test="permit-details-header-grid-item"
              >
                <span className={classes.gridHeader}>Permit ID</span>
                <div className={classes.gridContent}>{permit.permitId}</div>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
                className={classes.gridItem}
                data-test="permit-details-header-grid-item"
              >
                <span className={classes.gridHeader}>Permitted Location</span>
                <div className={classes.gridContent}>
                  {permit.permittedLocation}
                </div>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
                className={classes.gridItem}
                data-test="permit-details-header-grid-item"
              >
                <span className={classes.gridHeader}>Issued By</span>
                <div className={classes.gridContent}>{permit.issuedBy}</div>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
                className={classes.gridItem}
                data-test="permit-details-header-grid-item"
              >
                <span className={classes.gridHeader}>Restricted</span>
                <div className={classes.gridContent}>
                  {permit.federallyRestricted ? 'Yes' : 'No'}
                </div>
              </Grid>
            </Grid>
          </div>
          <Tabs value={selectedTab} onChange={handleChange}>
            {Tab(permitTabsEnum.Chemicals, classes)}
            {Tab(permitTabsEnum.Crops, classes)}
          </Tabs>
          {selectedTab === permitTabsEnum.Chemicals && (
            <PermitDetailsList
              header="Permitted Chemicals"
              infoText="The following are the permitted chemicals for this permit."
              elements={(permit.chemicals || []).map(
                (chemical) => chemical.chemicalName
              )}
              elementName={permitTabsEnum.Chemicals}
            />
          )}
          {selectedTab === permitTabsEnum.Crops && (
            <PermitDetailsList
              header="Permitted Crops"
              infoText="The following are the permitted crops for this permit."
              elements={(permit.permittedCrops || []).map(
                (crop) => crop.cropName
              )}
              elementName={permitTabsEnum.Crops}
            />
          )}
        </>
      )}
    </div>
  );
};

PermitDetailsPage.propTypes = {
  permit: permitPropType,
  error: PropTypes.instanceOf(Error),
  isFetchingPermits: PropTypes.bool,
  classes: classesPropType,
  selectedTab: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

PermitDetailsPage.defaultProps = {
  permit: {},
  isFetchingPermits: false,
  error: undefined,
  classes: {},
};

export default withStyles(styles)(PermitDetailsPage);
