import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DownCaret from '@material-ui/icons/KeyboardArrowDown';
import withStyles from '@material-ui/core/styles/withStyles';
import DateRangePicker from 'shared/ui/DateRangePicker';
import Button from '@nutrien/uet-react/Button';
import Dialog from '@nutrien/uet-react/Dialog';
import DialogContent from '@nutrien/uet-react/DialogContent';
import DialogTitle from '@nutrien/uet-react/DialogTitle';
import DialogActions from '@nutrien/uet-react/DialogActions';

import { getYearsFromToday } from 'shared/utils/DateHelper';
import styles from './styles';
import { DATE_RANGE_FILTERS, MINIMUM_DATE } from '../../../../actions';

class DateRangeFilter extends Component {
  state = {
    anchorEl: null,
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
    customDateDialogOpen: false,
  };

  setDate = (ranges) => {
    if (ranges.selection) this.setState({ dateRange: ranges.selection });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderDropdownOptions = () =>
    Object.keys(DATE_RANGE_FILTERS).map((key) => (
      <MenuItem
        key={key}
        onClick={() => {
          this.handleClose();

          if (DATE_RANGE_FILTERS[key] === DATE_RANGE_FILTERS.CUSTOM) {
            this.setState({ customDateDialogOpen: true });
          } else {
            this.props.setDateFilter(DATE_RANGE_FILTERS[key]);
          }
        }}
      >
        {DATE_RANGE_FILTERS[key]}
      </MenuItem>
    ));

  render() {
    const { anchorEl, customDateDialogOpen, dateRange } = this.state;
    const { classes, currentDateSelection } = this.props;
    const { startDate, endDate } = dateRange;

    return (
      <div className={classes.dateRangeContainer}>
        <Dialog open={customDateDialogOpen}>
          <DialogTitle>Select Date Range</DialogTitle>
          <DialogContent>
            <DateRangePicker
              onChange={this.setDate}
              ranges={[this.state.dateRange]}
              minDate={MINIMUM_DATE}
              maxDate={getYearsFromToday(2).toDate()}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => this.setState({ customDateDialogOpen: false })}
              className={classes.dialogCancelButton}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({ customDateDialogOpen: false });
                this.props.setDateFilter(
                  DATE_RANGE_FILTERS.CUSTOM,
                  Number(startDate),
                  Number(endDate)
                );
              }}
              variant="contained"
              className={classes.dialogConfirmButton}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          aria-owns={anchorEl ? 'date-options-list' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.dropDownButton}
        >
          <div className={classes.dateSelectionText}>
            {currentDateSelection}
          </div>
          <DownCaret
            style={{
              marginLeft: '10px',
              transform: anchorEl ? 'rotate(180deg)' : '',
              transition: 'all 0.25s',
            }}
          />
        </Button>
        <Menu
          id="date-options-list"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          getContentAnchorEl={null}
          classes={{ paper: classes.paper }}
        >
          {this.renderDropdownOptions()}
        </Menu>
      </div>
    );
  }
}

DateRangeFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  currentDateSelection: PropTypes.string.isRequired,
  setDateFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(DateRangeFilter);
