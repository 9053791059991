import apiAdapter from 'shared/lib/cxhApiService';

export const put = (id, changes) =>
  apiAdapter.put(`v1/notifications/${id}`, changes).then((res) => res.data);

export const putAll = (changes) =>
  apiAdapter.put(`v1/notifications/all`, changes).then((res) => res.data);

export const putBatch = (changes) =>
  apiAdapter.put(`v1/notifications/batch`, changes).then((res) => res.data);

export const get = () =>
  apiAdapter.get(`v1/notifications`).then((res) => res.data.notifications);

export const remove = (id) =>
  apiAdapter.delete(`v1/notifications/${id}`).then((res) => res.data);

export const removeAll = () =>
  apiAdapter.delete(`v1/notifications/all`).then((res) => res.data);

export const removeBatch = (changes) =>
  apiAdapter
    .delete(`v1/notifications/batch`, { data: changes })
    .then((res) => res.data);

export const getPreferences = () =>
  apiAdapter.get('v1/notifications/preferences').then((res) => res.data);

export const putPreference = (changes) =>
  apiAdapter
    .put('v1/notifications/preferences', changes)
    .then((res) => res.data);
