import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HeaderContentWrapper = ({
  component: Component,
  browser,
  isEmployeeView,
  ...props
}) => (
  <div
    data-test="header-wrapper"
    className={classNames('header-wrapper', {
      'open-menu': props.isMenuOpen,
      'header-wrapper--browser-not-supported':
        !browser.isSupported && !isEmployeeView,
      mirroring: props.isMirroring,
    })}
  >
    <div className="header">
      <Component
        isMenuOpen={props.isMenuOpen}
        toggleMenu={props.toggleMenu}
        closeMenu={props.closeMenu}
        {...props}
      />
    </div>
  </div>
);

HeaderContentWrapper.propTypes = {
  browser: PropTypes.shape({
    isSupported: PropTypes.bool,
  }).isRequired,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  isMirroring: PropTypes.bool.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isEmployeeView: PropTypes.bool.isRequired,
};

export default HeaderContentWrapper;
