import { getByAccount } from '../customerService';
import customersFetchFailure from './customersFetchFailure';
import customersFetchSuccess from './customersFetchSuccess';
import customersFetching from './customersFetching';

export default (accountId) => async (dispatch) => {
  dispatch(customersFetching());
  try {
    const customers = await getByAccount(accountId);
    return dispatch(customersFetchSuccess(customers));
  } catch (err) {
    return dispatch(customersFetchFailure(err));
  }
};
