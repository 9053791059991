import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { NOTIFICATION_TYPES, PageNotification } from 'shared/ui';
import Notification from '@nutrien/uet-react/Notification';
import { ERROR_NAMES } from 'components/Payments/Redux/actions/ErrorActions';
import PaymentProcessingOverlay from 'components/Payments/OtherComponents/PaymentProcessingOverlay/PaymentProcessingOverlay';

import ExportInvoices from './ExportInvoices';
import InvoiceFilter from './InvoiceFilter/InvoiceFilterComponent';
import DownloadOptionsComponent from './DownloadOptions';
import InvoiceProductInfo from './InvoiceProductInfo';
import InvoicesTable from './InvoicesTable';
import PayInvoiceButton from './PayInvoiceButton';
import InvoicedProductsSearch from './InvoicedProductsSearch';

import './invoiceList.css';
import DateRangeFilter from './InvoicesTable/Filters/DateRangeFilter/DateRangeFilter';

import {
  invoices as invoicesPropType,
  selectedInvoiceIds,
} from '../invoicesPropTypes';

class InvoiceList extends Component {
  get invoiceSelected() {
    const { selectedInvoices } = this.props;
    return selectedInvoices.unpaid.length + selectedInvoices.paid.length > 0;
  }

  handleDownloadClick = (fileType) => {
    const {
      accountId,
      selectedInvoices,
      downloadInvoiceList,
      gtmInvoicesDownload,
    } = this.props;

    const allSelectedInvoices = selectedInvoices.unpaid.concat(
      selectedInvoices.paid
    );
    downloadInvoiceList(fileType.toLowerCase(), accountId, allSelectedInvoices);
    gtmInvoicesDownload('Download Selected', fileType.toLowerCase());
  };

  render() {
    const {
      filteredInvoices,
      account,
      isLoading,
      queryString,
      currentPage,
      pageSize,
      statusFilter,
      updateAccountTab,
      push,
      accountId,
      currentDateFilter,
      paymentError,
      selectionOptions,
      clearFilteredProduct,
      paginatedInvoices,
      showColumnFilters,
      flags: { invoiceProductSearch, downloadInvoiceSelection, canadaReadOnly },
    } = this.props;

    const showErrorMessage =
      paymentError &&
      paymentError.name === ERROR_NAMES.INVOICE_SELECTION_LIMIT_REACHED;
    const onLastPage = filteredInvoices.length <= pageSize * currentPage;

    return (
      <>
        {!!isLoading && (
          <PaymentProcessingOverlay processingText="Downloading invoices in progress..." />
        )}
        {showErrorMessage && (
          <PageNotification
            type={NOTIFICATION_TYPES.DANGER}
            message={paymentError.message}
            mobileMessage={paymentError.message}
            className="invoice-error-notification"
          />
        )}

        <div className="account__download-link-container">
          <ExportInvoices />
        </div>
        <div className="invoice-list-container">
          <div>
            <div className="invoice-header-bar">
              <h3 className="accounting-list-title">Invoices</h3>
              <div className="invoice-actions">
                {showColumnFilters || (
                  <>
                    <div className="invoice-period-label">Period</div>
                    <DateRangeFilter
                      currentDateSelection={currentDateFilter}
                      setDateFilter={this.props.setDateFilter}
                    />
                    <div className="invoice-sort-label">Sort By</div>
                    <InvoiceFilter
                      push={push}
                      statusFilter={statusFilter}
                      invoices={paginatedInvoices}
                      clearFilteredProduct={clearFilteredProduct}
                      updateAccountTab={updateAccountTab}
                      account={account}
                      queryString={queryString}
                      selectionOptions={selectionOptions}
                    />
                  </>
                )}

                <div className="account-actions__container">
                  <div className="account__invoiced-products-search-container">
                    {invoiceProductSearch && (
                      <InvoicedProductsSearch
                        accountId={accountId}
                        data-test="products-search"
                      />
                    )}
                  </div>
                </div>
                <div className="invoice-selection-options">
                  {
                    // break out into its own component
                    !!this.invoiceSelected &&
                      !window.ReactNativeWebView &&
                      downloadInvoiceSelection && (
                        <DownloadOptionsComponent
                          downloadInvoices={(fileType) =>
                            this.handleDownloadClick(fileType)
                          }
                          data-test="download-button"
                        />
                      )
                  }
                  {canadaReadOnly ? null : (
                    <PayInvoiceButton accountId={accountId} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <InvoiceProductInfo
            accountCountryCode={account.locationCode}
            filteredInvoices={filteredInvoices}
            accountId={accountId}
          />
          <InvoicesTable
            accountId={accountId}
            currentPage={currentPage}
            showColumnFilters={showColumnFilters}
            handlePaginationClick={this.props.handlePaginationClick}
            invoices={filteredInvoices}
          />
          {paginatedInvoices.length === 0 ? (
            <div className="no-account-invoices">
              <Notification
                variant="info"
                data-test="account-invoices-not-found"
              >
                No invoices found.
              </Notification>
            </div>
          ) : (
            onLastPage && (
              <div className="end-of-activity">End of Invoices.</div>
            )
          )}
        </div>
      </>
    );
  }
}

InvoiceList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  accountId: PropTypes.string,
  queryString: PropTypes.shape({}),
  updateAccountTab: PropTypes.func,
  statusFilter: PropTypes.string,
  account: PropTypes.shape({
    id: PropTypes.string,
    locationCode: PropTypes.string,
  }),
  currentPage: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  push: PropTypes.func,
  filteredInvoices: invoicesPropType.isRequired,
  paginatedInvoices: invoicesPropType.isRequired,
  // TODO: in the name of wholly accurate naming,
  // this should be selectedInvoiceIds, as it only contains the id's of the selected invoices,
  // not invoice objects themselves
  selectedInvoices: selectedInvoiceIds,
  setDateFilter: PropTypes.func.isRequired,
  downloadInvoiceList: PropTypes.func.isRequired,
  currentDateFilter: PropTypes.string,
  paymentError: PropTypes.shape({
    statusCode: PropTypes.number,
    name: PropTypes.string,
    message: PropTypes.string,
    errorType: PropTypes.string,
  }),
  selectionOptions: PropTypes.arrayOf(PropTypes.shape({})),
  filteredProducts: PropTypes.shape({}),
  clearFilteredProduct: PropTypes.func.isRequired,
  showColumnFilters: PropTypes.bool,
  // TODO: this shouldn't be passed from the InvoicesWrapper, but it was too much work to remove as part of CXH-3417
  handlePaginationClick: PropTypes.func,
  gtmInvoicesDownload: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    invoiceProductSearch: PropTypes.bool,
    downloadInvoiceSelection: PropTypes.bool,
    canadaReadOnly: PropTypes.bool,
  }).isRequired,
};

InvoiceList.defaultProps = {
  account: {},
  accountId: null,
  currentPage: 1,
  selectedInvoices: {
    totalAmountDue: 0,
    paid: [],
    unpaid: [],
  },
  push: () => {},
  statusFilter: '',
  updateAccountTab: () => {},
  queryString: {},
  currentDateFilter: null,
  paymentError: null,
  filteredProducts: { list: [], product: {} },
  selectionOptions: [],
  showColumnFilters: false,
  handlePaginationClick: () => {},
};

export default withLDConsumer()(InvoiceList);
