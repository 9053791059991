import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import FullPage from 'shared/ui/FullPage';
import PropTypes from 'prop-types';
import ReviewErrorImage from 'assets/review-error-500.svg';
import PaymentHeader from 'shared/ui/Payments/PaymentHeader/PaymentHeader';
import * as PaymentPropTypes from '../../PaymentPropTypes';
import AccountInformation from './SubComponents/AccountInformation';
import BillingInformation from './SubComponents/BillingInformation';
import CPSAccountInformation from './SubComponents/CPSAccountInformation';
import InvoiceAccountInformation from './SubComponents/InvoiceAccountInformation';
import * as validationUtils from './Validations/PaymentValidator';
import PaymentProcessingOverlay from '../../OtherComponents/PaymentProcessingOverlay/PaymentProcessingOverlay';
import {
  MAKE_PAYMENT_STEPS,
  errorTitleText,
  PAYMENT_FLOW_TYPES,
  DEFAULT_PAYMENT_METHODS,
} from '../../PaymentConstants';
import {
  genericErrorTitle,
  genericErrorBody,
  serviceUnavailableErrorMessage,
} from './MakeAPaymentConstants';
import ModalComponent from '../../OtherComponents/ModalComponent';
import SavedPaymentMethods from './SubComponents/SavedPaymentAccounts/SavedPaymentMethods';
import AddPaymentFormComponent from '../../OtherComponents/PaymentForm/PaymentFormComponent';
import SaveAccount from './SubComponents/SaveAccount';
import {
  getAccountCountryCode,
  getCountryFromCode,
} from '../../Utils/PaymentUtil';

import styles from './styles';
import commonStyles from '../../commonPaymentStyles';
import { ROUTES_MAP } from '../../../../shared/ui/Payments/PaymentHeader/constants';
import { ERROR_NAMES, ERROR_TYPES } from '../../Redux/actions/ErrorActions';
import SchedulePayment from '../../OtherComponents/SchedulePayment/SchedulePayment';

const finalStyles = (theme) => merge({}, commonStyles(theme), styles);

class MakeAPayment extends Component {
  componentDidMount() {
    const { accounts, flowType, setPaymentFlowType } = this.props;
    if (accounts.length && !flowType) {
      setPaymentFlowType(PAYMENT_FLOW_TYPES.MAKE_A_PAYMENT);
    }

    window.scroll(0, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      paymentError: newPaymentError,
      accounts: newAccounts,
      formValues,
      formErrors,
      setPaymentFormErrors,
    } = this.props;

    const { accounts, paymentError, updatePaymentForm, setAccountCountryCode } =
      prevProps;

    const hasReceivedAccounts = newAccounts && newAccounts !== accounts;

    if (hasReceivedAccounts) {
      const accountCountryCode = formValues.cpsAccount
        ? formValues.cpsAccount.locationCode
        : getAccountCountryCode(newAccounts);
      const country = getCountryFromCode(accountCountryCode);

      setAccountCountryCode(accountCountryCode);

      updatePaymentForm({
        country,
      });
    }
    if (
      newPaymentError &&
      newPaymentError !== paymentError &&
      newPaymentError.name !== ERROR_NAMES.TOKEN_ALREADY_SAVED
    ) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });

      if (newPaymentError.errorType === ERROR_TYPES.FORM_ERROR) {
        const accountCountryCode = formValues.cpsAccount
          ? formValues.cpsAccount.locationCode
          : getAccountCountryCode(newAccounts);
        const country = getCountryFromCode(accountCountryCode);
        const errors = { ...formErrors };
        if (country.code === 'USA') {
          errors.routingNumber = [{ message: 'Invalid Routing Number' }];
        } else {
          errors.transitNumber = [{ message: 'Invalid Transit Number' }];
          errors.institutionNumber = [
            { message: 'Invalid Institution Number' },
          ];
        }
        setPaymentFormErrors(errors);
      }
    }
  }

  componentWillUnmount() {
    const {
      clearBankName,
      clearPaymentError,
      clearShowFormErrorsNotification,
    } = this.props;

    clearBankName();
    clearPaymentError();
    clearShowFormErrorsNotification();
  }

  getInvoicesByIdList = (list) => {
    const { invoices } = this.props;
    if (!invoices) {
      return null;
    }
    return invoices.filter((invoice) => list.includes(invoice.id.toString()));
  };

  submitPaymentDetails = (event) => {
    event.preventDefault();

    const {
      location,
      submitPaymentForm,
      invoices,
      flowType,
      selectedInvoices,
    } = this.props;
    if (invoices && selectedInvoices) {
      this.handleChange({
        target: {
          name: 'selectedInvoices',
          value: this.getInvoicesByIdList(selectedInvoices.unpaid),
        },
      });

      this.handleChange({
        target: {
          name: 'selectedInvoicesById',
          value: selectedInvoices,
        },
      });
    }

    submitPaymentForm(location, flowType);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  clearHiddenFormErrors = () => {
    const currentState = { ...this.props.formErrors };

    currentState.firstName = [];
    currentState.lastName = [];
    currentState.corporateName = [];

    this.props.setPaymentFormErrors(currentState);
  };

  validateFormField = ({ field, fieldData }) => {
    const { formValues, formErrors, formWarnings, setPaymentFormErrors } =
      this.props;
    const newState = validationUtils.validateFormField({
      field,
      fieldData,
      currentState: {
        form: formValues,
        formWarnings,
        formErrors,
      },
    });

    setPaymentFormErrors(newState.formErrors);
  };

  handleChange = (event) => {
    const { updatePaymentForm } = this.props;
    const { name, value } = event.target;
    updatePaymentForm({
      [name]: value,
    });
  };

  render() {
    const formName = 'add-payment-form';
    const {
      accounts,
      isLoading,
      getPaymentMethodStatus,
      classes,
      formValues,
      formErrors,
      formWarnings,
      cpsAccount,
      showFormErrors,
      paymentError,
      clearPaymentError,
      deleteSavedBankAccount,
      selectedPaymentMethod,
      deletePaymentMethodStatus,
      user,
      flowType,
      scheduledPayment,
      isRecurring,
      recurringEndDate,
      recurringPaymentFrequency,
      location,
      history,
    } = this.props;

    const headerTitle = 'Make Payment';

    return (
      <div className={classes.addPaymentFormContainer}>
        <ModalComponent
          image={ReviewErrorImage}
          open={
            !!(paymentError && paymentError.errorType === ERROR_TYPES.MODAL)
          }
          close={clearPaymentError}
          titleText={
            paymentError &&
            paymentError.name === ERROR_NAMES.SERVICE_UNAVAILABLE
              ? errorTitleText
              : genericErrorTitle
          }
          bodyText1={
            paymentError &&
            paymentError.name === ERROR_NAMES.SERVICE_UNAVAILABLE
              ? serviceUnavailableErrorMessage
              : genericErrorBody
          }
        />

        <FullPage noCard>
          <PaymentHeader
            title={headerTitle}
            subNavRoutes={ROUTES_MAP.MAKE_A_PAYMENT_ROUTES}
            currentPaymentStep={MAKE_PAYMENT_STEPS.PAYMENT_INFO}
            paymentError={paymentError}
            showFormErrors={showFormErrors}
          />

          <AddPaymentFormComponent onSubmit={this.submitPaymentDetails}>
            {flowType === PAYMENT_FLOW_TYPES.INVOICE_PAYMENT ? (
              <InvoiceAccountInformation
                onChange={this.handleChange}
                notes={formValues.notes}
                cpsAccount={cpsAccount}
              />
            ) : (
              <CPSAccountInformation
                accounts={accounts}
                errors={formErrors}
                onChange={this.handleChange}
                amountEntered={formValues.amountEntered}
                amountTypeSelected={formValues.amountTypeSelected}
                notes={formValues.notes}
                selectedCPSAccount={cpsAccount}
                validate={this.validateFormField}
                warnings={formWarnings}
                search={location.search}
                location={location}
                history={history}
              />
            )}

            <SchedulePayment
              onChange={this.handleChange}
              validate={this.validateFormField}
              scheduledPayment={scheduledPayment}
              recurringEndDate={recurringEndDate}
              recurringPaymentFrequency={recurringPaymentFrequency}
              isRecurring={isRecurring}
              errors={formErrors}
              flowType={flowType}
              amountTypeSelected={formValues.amountTypeSelected}
            />
            {cpsAccount ? (
              <SavedPaymentMethods
                flowType={flowType}
                onChange={this.handleChange}
                clearPaymentError={clearPaymentError}
                getPaymentMethodStatus={getPaymentMethodStatus}
                deletePaymentMethod={deleteSavedBankAccount}
                deletePaymentMethodStatus={deletePaymentMethodStatus}
                user={user}
                paymentError={paymentError}
                cpsAccount={cpsAccount}
              />
            ) : null}

            {selectedPaymentMethod &&
            selectedPaymentMethod.tokenValue ===
              DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT.tokenValue &&
            cpsAccount ? (
              <div className={classes.makeAPaymentFormWrapper}>
                <AccountInformation
                  bankName={this.props.bankName}
                  clearHidden={this.clearHiddenFormErrors}
                  clearBankName={this.props.clearBankName}
                  errors={formErrors}
                  formName={formName}
                  getBankName={this.props.getBankName}
                  form={formValues}
                  onChange={this.handleChange}
                  routingNumberError={this.props.routingNumberError}
                  validate={this.validateFormField}
                  countryCode={cpsAccount.locationCode}
                />
                <BillingInformation
                  errors={formErrors}
                  addressLine1={formValues.addressLine1}
                  addressLine2={formValues.addressLine2}
                  city={formValues.city}
                  zip={formValues.zip}
                  phone={formValues.phone}
                  onChange={this.handleChange}
                  selectedCountry={formValues.country}
                  selectedState={formValues.state}
                  validate={this.validateFormField}
                />
                <SaveAccount
                  errors={formErrors}
                  formName={formName}
                  onChange={this.handleChange}
                  paymentMethodAlias={formValues.paymentMethodAlias}
                  savePaymentMethod={formValues.savePaymentMethod}
                  validate={this.validateFormField}
                  countryCode={cpsAccount.locationCode}
                  isRecurring={isRecurring}
                />
              </div>
            ) : (
              <div className={classes.makeAPaymentNoAccount} />
            )}
            <hr className={classes.addPaymentFormHR} />
            <div className={classes.addPaymentNextButtonContainer}>
              <MaterialButton
                id="next-button"
                value="Next, Review"
                type="submit"
                style={{ width: 'auto' }}
                className={classes.nextButton}
              />
            </div>
          </AddPaymentFormComponent>
        </FullPage>

        {isLoading ? (
          <PaymentProcessingOverlay processingText="Loading..." />
        ) : null}
      </div>
    );
  }
}

MakeAPayment.propTypes = {
  history: PropTypes.shape({}).isRequired,
  formValues: PaymentPropTypes.paymentForm.isRequired,
  formWarnings: PaymentPropTypes.paymentFormWarnings.isRequired,
  formErrors: PaymentPropTypes.paymentFormErrors.isRequired,
  updatePaymentForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getBankName: PropTypes.func.isRequired,
  clearBankName: PropTypes.func.isRequired,
  bankName: PropTypes.string,
  routingNumberError: PropTypes.bool,
  getPaymentMethodStatus: PropTypes.string,
  user: PaymentPropTypes.user,
  setAccountCountryCode: PropTypes.func.isRequired,
  accounts: PaymentPropTypes.accounts,
  classes: PropTypes.shape({
    addPaymentFormContainer: PropTypes.string.isRequired,
    makeAPaymentFormWrapper: PropTypes.string.isRequired,
    makeAPaymentNoAccount: PropTypes.string.isRequired,
    addPaymentFormHR: PropTypes.string.isRequired,
    addPaymentNextButtonContainer: PropTypes.string.isRequired,
    nextButton: PropTypes.string.isRequired,
  }).isRequired,
  setPaymentFormErrors: PropTypes.func.isRequired,
  submitPaymentForm: PropTypes.func.isRequired,
  cpsAccount: PaymentPropTypes.cpsAccount,
  deleteSavedBankAccount: PropTypes.func.isRequired,
  deletePaymentMethodStatus: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  clearShowFormErrorsNotification: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.shape({
    unpaid: PropTypes.arrayOf(PropTypes.string),
    paid: PropTypes.arrayOf(PropTypes.string),
  }),
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  clearPaymentError: PropTypes.func.isRequired,
  showFormErrors: PropTypes.bool.isRequired,
  paymentError: PropTypes.shape({
    statusCode: PropTypes.number,
    name: PropTypes.string,
    message: PropTypes.string,
    errorType: PropTypes.string,
  }),
  selectedPaymentMethod: PropTypes.oneOfType([
    PaymentPropTypes.paymentAccount,
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  flowType: PropTypes.string,
  setPaymentFlowType: PropTypes.func.isRequired,
  scheduledPayment: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  recurringEndDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  isRecurring: PropTypes.bool.isRequired,
  recurringPaymentFrequency: PropTypes.shape({}),
};

MakeAPayment.defaultProps = {
  accounts: [],
  bankName: '',
  flowType: null,
  scheduledPayment: null,
  recurringEndDate: null,
  routingNumberError: false,
  user: {},
  paymentError: null,
  getPaymentMethodStatus: null,
  cpsAccount: null,
  deletePaymentMethodStatus: null,
  selectedPaymentMethod: null,
  selectedInvoices: null,
  invoices: [],
  recurringPaymentFrequency: {},
};

export default withStyles(finalStyles)(MakeAPayment);
