import {
  UPDATE_PASSWORD_SUBMITTING,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
} from '../actions';

export default (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PASSWORD_SUCCESS:
    case UPDATE_PASSWORD_SUBMITTING:
      return null;
    case UPDATE_PASSWORD_FAILURE:
      return payload; // payload represents the error per FSA guidelines
    default:
      return state;
  }
};
