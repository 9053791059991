/* eslint-disable no-use-before-define */
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import { getUrlParameters } from 'components/Shop/utils/filterHelpers';
import {
  DROPDOWN_LIST_ENTRIES,
  DROPDOWN_LIST_ENTRIES_AFTER_SEARCH,
} from './SortDropdownConstants';

const getSearch = (location) => location.search.substring(1);

const isSearchActive = (location) =>
  location.search.substring(1).indexOf('name=') !== -1;

const returnMatchingSortIndex = (list, query, defaultIndex) => {
  const matchingItem = findLast(list, (item) => item.query === query);
  const matchingItemIndex = get(matchingItem, 'index', defaultIndex);
  return matchingItemIndex;
};

const getInitialFilterIndexFromProps = (products, location) => {
  const DEFAULT_ITEM_INDEX = 2;

  if (helper.getSearch(location) && products.sort.values) {
    const productValue = products.sort.values[0];

    if (helper.isSearchActive(location)) {
      const DEFAULT_SEARCH_ITEM_INDEX = 0;

      return helper.returnMatchingSortIndex(
        DROPDOWN_LIST_ENTRIES_AFTER_SEARCH,
        productValue,
        DEFAULT_SEARCH_ITEM_INDEX
      );
    }

    return helper.returnMatchingSortIndex(
      DROPDOWN_LIST_ENTRIES,
      productValue,
      DEFAULT_ITEM_INDEX
    );
  }

  return DEFAULT_ITEM_INDEX;
};

const changeRouteToSelectedSort = (selectedItem, history, location) => {
  const search = helper.getSearch(location);
  const searchParameters = getUrlParameters(search);
  searchParameters.sort = selectedItem.query;
  const searchParameterKeys = Object.keys(searchParameters);
  const queryParams = searchParameterKeys.map((key) => {
    const value = searchParameters[key];
    return `${key}=${value}`;
  });
  history.push(`/products?${queryParams.join('&')}`);
};

const helper = {
  getSearch,
  isSearchActive,
  returnMatchingSortIndex,
  getInitialFilterIndexFromProps,
  changeRouteToSelectedSort,
};

export default helper;
