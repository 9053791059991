import { combineReducers } from 'redux';
import error from './error';
import preferencesData from './preferencesData';
import status from './status';

export default combineReducers({
  error,
  preferencesData,
  status,
});
