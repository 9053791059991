import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Dialog } from 'shared/ui';
import closeSvg from 'assets/close-modal.svg';

import PhoneModalContent from './PhoneModalContent';
import styles from './styles';

const PhoneModal = ({
  classes,
  isOpen,
  isInAddMode,
  closeModal,
  isSubmitting,
  submitError,
  currentPreferredPhoneNumber,
  ...rest
}) => {
  const titleText = isInAddMode
    ? 'No mobile phone number added'
    : 'Confirm Mobile Phone Number';

  const contentProps = {
    isInAddMode,
    closeModal,
    isSubmitting,
    currentPreferredPhoneNumber,
    ...rest,
  };

  return (
    <Dialog open={isOpen} className="phone-modal">
      <div className={classes.modalContainer}>
        <div className={classes.modalCloseContainer}>
          <img
            className={classes.closeImage}
            src={closeSvg}
            alt="Close"
            title="Close Modal"
            onClick={closeModal}
            role="presentation"
            onKeyDown={() => {}}
          />
        </div>
        <h4 className={classNames('title', classes.header)}>{titleText}</h4>
        <PhoneModalContent {...contentProps} />
      </div>
    </Dialog>
  );
};

PhoneModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isInAddMode: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  submitError: PropTypes.bool,
  currentPreferredPhoneNumber: PropTypes.string,
};

PhoneModal.defaultProps = {
  isSubmitting: false,
  submitError: false,
  currentPreferredPhoneNumber: undefined,
};

export default withStyles(styles)(PhoneModal);
