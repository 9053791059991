import {
  CLEAR_DELETE_BANK_ACCOUNT_RESPONSE,
  CLEAR_UPDATE_ALIAS_STATUS,
  DELETE_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT_IN_PROGRESS,
  DELETE_BANK_ACCOUNT_RESPONSE_CODE,
  DELETE_BANK_ACCOUNT_SUCCESS,
  GET_PAYMENT_METHOD_FAILURE,
  GET_PAYMENT_METHOD_IN_PROGRESS,
  GET_PAYMENT_METHOD_SUCCESS,
  SET_BANK_ACCOUNT_TOKEN,
  SET_SAVED_BANK_ACCOUNTS,
  BANK_ACCOUNT_LIMIT_REACHED,
  UPDATE_BANK_ACCOUNT_ALIAS_FAILURE,
  UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS,
  UPDATE_BANK_ACCOUNT_ALIAS_SUCCESS,
} from '../../PaymentConstants';
import { buildRegisterTokenRequest } from '../../PaymentRequestFactory';
import * as paymentService from '../../PaymentService';

export const setBankAccountToken = (token) => ({
  type: SET_BANK_ACCOUNT_TOKEN,
  payload: { token },
});

export const setBankAccountLimitReached = () => ({
  type: BANK_ACCOUNT_LIMIT_REACHED,
});

export const updateBankAccountAliasInProgress = () => ({
  type: UPDATE_BANK_ACCOUNT_ALIAS_IN_PROGRESS,
});

export const updateBankAccountAliasSuccess = () => ({
  type: UPDATE_BANK_ACCOUNT_ALIAS_SUCCESS,
});

export const updateBankAccountAliasFailure = () => ({
  type: UPDATE_BANK_ACCOUNT_ALIAS_FAILURE,
});

export const clearUpdateBankAccountAlias = () => ({
  type: CLEAR_UPDATE_ALIAS_STATUS,
});

export const deleteBankAccountInProgress = () => ({
  type: DELETE_BANK_ACCOUNT_IN_PROGRESS,
});

export const deleteBankAccountSuccess = () => ({
  type: DELETE_BANK_ACCOUNT_SUCCESS,
});

export const deleteBankAccountFailure = () => ({
  type: DELETE_BANK_ACCOUNT_FAILURE,
});

export const setDeleteBankAccountResponse = (response) => ({
  type: DELETE_BANK_ACCOUNT_RESPONSE_CODE,
  payload: response,
});

export const clearDeleteBankAccountResponse = () => ({
  type: CLEAR_DELETE_BANK_ACCOUNT_RESPONSE,
});

export const setSavedBankAccounts = (paymentAccounts) => ({
  type: SET_SAVED_BANK_ACCOUNTS,
  payload: paymentAccounts,
});

export const getPaymentMethodInProgress = () => ({
  type: GET_PAYMENT_METHOD_IN_PROGRESS,
});

export const getPaymentMethodSuccess = () => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
});

export const getPaymentMethodFailure = () => ({
  type: GET_PAYMENT_METHOD_FAILURE,
});

/**
 * The action to register a new bank account
 * @param form - The bank account form data
 */
export const registerBankAccount =
  ({ form }) =>
  (dispatch, getState) => {
    const state = getState();
    const registerTokenRequest = buildRegisterTokenRequest(state, form);

    return paymentService.registerBankAccount(registerTokenRequest);
  };

/**
 * Retrieves the users saved bank accounts
 * @param organizationIds - A collection of organization Id's to retrieve accounts for
 * @param countryCode - The country code associated with the accounts
 */
export const getSavedBankAccounts =
  (organizationIds, countryCode = undefined) =>
  async (dispatch) => {
    dispatch(getPaymentMethodInProgress());

    try {
      const response = await paymentService.getSavedBankAccounts(
        organizationIds,
        countryCode
      );
      const sortedPaymentMethods = response.data
        .sort((accountA, accountB) => {
          const dateA = new Date(accountA.createdDate);
          const dateB = new Date(accountB.createdDate);

          return dateA - dateB;
        })
        .reverse();

      dispatch(setSavedBankAccounts(sortedPaymentMethods));
      dispatch(getPaymentMethodSuccess());
    } catch (error) {
      dispatch(getPaymentMethodFailure());
    }
  };

/**
 * Deletes a saved bank account
 * @param tokenId - The Id for the tokenized account number
 * @param orgs - a collection of org id's the account belongs to
 */
export const deleteSavedBankAccount = (tokenId, orgs) => async (dispatch) => {
  dispatch(deleteBankAccountInProgress());

  try {
    const response = await paymentService.deleteSavedBankAccount(tokenId);

    dispatch(setDeleteBankAccountResponse(response.status));
    dispatch(deleteBankAccountSuccess());
    dispatch(getSavedBankAccounts(orgs));
  } catch (error) {
    const status = error.response ? error.response.status : 500;
    dispatch(setDeleteBankAccountResponse(status));
    dispatch(deleteBankAccountFailure());
  }
};

/**
 * Updates the alias associated with a saved bank account
 * @param tokenAccountId - The id for the account the saved bank account is associated with
 * @param alias - The new alias for the saved bank account
 * @param orgs - the organization ids the account belongs to
 */
export const updateBankAccountAlias =
  (tokenAccountId, alias, orgs) => async (dispatch) => {
    dispatch(updateBankAccountAliasInProgress());

    try {
      await paymentService.updateBankAccountAlias(alias, tokenAccountId);

      dispatch(updateBankAccountAliasSuccess());
      dispatch(getSavedBankAccounts(orgs));
    } catch (error) {
      dispatch(updateBankAccountAliasFailure());
    }
  };
