import {
  LICENSES_FETCHING,
  LICENSES_FETCH_FAILURE,
  LICENSES_FETCH_SUCCESS,
} from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case LICENSES_FETCH_FAILURE:
      return action.error;
    case LICENSES_FETCH_SUCCESS:
    case LICENSES_FETCHING:
      return null;
    default:
      return state;
  }
};
