import { SET_INVOICE_DATE_RANGE } from '../actions';

const initialState = {
  startDate: undefined,
  endDate: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_DATE_RANGE:
      return action.payload.data;
    default:
      return state;
  }
};
