import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@nutrien/uet-react/Dialog';
import DialogContent from '@nutrien/uet-react/DialogContent';
import DialogContentText from '@nutrien/uet-react/DialogContentText';
import DialogTitle from '@nutrien/uet-react/DialogTitle';
import DialogActions from '@nutrien/uet-react/DialogActions';
import Button from '@nutrien/uet-react/Button';

import './confirmDeleteDialog.css';

const ConfirmDeleteDialog = (props) => (
  <Dialog
    open={props.confirmDelete}
    onClose={props.cancelDelete}
    aria-labelledby="delete-dialog-title"
    aria-describedby="delete-dialog-description"
  >
    <DialogTitle id="delete-dialog-title" className="delete-dialog-title">
      <span className="red">Delete Saved Bank Account?</span>
    </DialogTitle>
    <DialogContent>
      <DialogContentText
        id="delete-dialog-description"
        className="delete-dialog-description"
      >
        This can't be undone.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        id="delete-dialog-cancel"
        name="deleteDialogCancel"
        variant="outlined"
        onClick={props.cancelDelete}
      >
        Cancel
      </Button>
      <Button
        id="delete-dialog-confirm-delete"
        onClick={props.handleDelete}
        variant="contained"
      >
        Yes, Delete
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDeleteDialog.propTypes = {
  confirmDelete: PropTypes.bool.isRequired,
  cancelDelete: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ConfirmDeleteDialog;
