const US_ZIP_CODE_STR = '(^\\d{5}$)|(^\\d{5}-\\d{4}$)';
const CAN_ZIP_CODE_STR = '^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$';

const US_ZIP_CODE_REGEXP = new RegExp(US_ZIP_CODE_STR);
const CAN_ZIP_CODE_REGEXP = new RegExp(CAN_ZIP_CODE_STR);
const ZIP_CODE_REGEXP = new RegExp(
  `(${US_ZIP_CODE_STR})|(${CAN_ZIP_CODE_STR})`
);

export const isValidUsZipCode = (zipCode) => US_ZIP_CODE_REGEXP.test(zipCode);

export const isValidCanadianPostalCode = (postalCode) =>
  CAN_ZIP_CODE_REGEXP.test(postalCode);

export const validCanadianOrUSZipCode = ZIP_CODE_REGEXP;
