import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import PropTypes from 'prop-types';

import './SnackbarNotification.scss';

const CloseButton = ({ onClose }) => (
  <IconButton
    key="close"
    onClick={onClose}
    aria-label="Close"
    color="inherit"
    className="snackbar-notification__close-button"
  >
    <Icon icon="close" type="light-gray" />
  </IconButton>
);

CloseButton.propTypes = {
  onClose: PropTypes.func,
};

CloseButton.defaultProps = {
  onClose: () => {},
};

export default CloseButton;
