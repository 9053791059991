import {
  USER_FETCH_SUCCESS,
  USER_LOGIN_SUCCESS,
} from 'shared/config/userConstants';
import { sendLoadUserEvent } from '../utils';
import { mixPanelTrackLogin } from '../../MixPanel/eventHandlers/userLogin';

export const userLoginSuccess = (actionData) => {
  const { payload: userData } = actionData;

  mixPanelTrackLogin(userData);
  return sendLoadUserEvent(userData);
};

export const userFetchSuccess = (actionData) => {
  const {
    payload: {
      user: { data: userData },
      auth0Login,
    },
  } = actionData;
  if (auth0Login) {
    mixPanelTrackLogin(userData);
  }
  return sendLoadUserEvent(userData);
};

/**
 * Note: Since these events are only for loading user-scoped data,
 * it's ok for them to be fired multiple times if that's
 * what the app is doing
 */
export const userEventsMap = {
  [USER_LOGIN_SUCCESS]: userLoginSuccess,
  [USER_FETCH_SUCCESS]: userFetchSuccess,
};
