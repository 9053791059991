import delegateAccessService from './rbacService';

import {
  DELEGATING_ACCESS,
  DELEGATE_ACCESS_SUCCESS,
  DELEGATE_ACCESS_FAILURE,
  RESET_ACCESS_DELEGATION,
} from './rbacConstants';

export const resetAccessDelegation = () => ({
  type: RESET_ACCESS_DELEGATION,
});

export const delegatingAccess = () => ({
  type: DELEGATING_ACCESS,
});

export const delegateAccessSuccess = () => ({
  type: DELEGATE_ACCESS_SUCCESS,
});

export const delegateAccessFailure = (error) => ({
  type: DELEGATE_ACCESS_FAILURE,
  error,
});

export const delegateAccess = (email) => (dispatch) => {
  dispatch(delegatingAccess());
  return delegateAccessService({ email })
    .then(() => {
      dispatch(delegateAccessSuccess());
    })
    .catch((error) => {
      dispatch(delegateAccessFailure(error));
    });
};
