export default (theme) => ({
  buttonContainer: {
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    '@media (min-width: 500px)': {
      minWidth: '420px',
    },
  },
  additionalInformation: {
    margin: '40px 0 20px 0',
    display: 'inline-flex',
    flexFlow: 'column nowrap',
    '& p': {
      display: 'flex',
      alignItems: 'center',
      '& span': {
        color: '#0093e5',
        fontSize: '24px',
        marginRight: '5px',
      },
    },
  },
  finishButton: {
    width: '200px',
  },
  checkImage: {
    width: '25px',
    height: '13px',
    marginRight: '10px',
  },
  confirmationPageTitle: {
    marginTop: '20px 0',
  },
  confirmationItem: {
    display: 'flex',
    color: '#004538',
    fontSize: '24px',
    width: '100%',
    fontWeight: 'inherit',
    marginBottom: '8px',
  },
  confirmationItemTitle: {
    margin: '0',
    height: '17px',
    width: '170px',
    marginRight: '30px',
    display: 'inline-block',
    color: '#828282',
    fontSize: '12px',
    fontWeight: 'inherit',
  },
  confirmationItemValue: {
    margin: '0',
    height: '17px',
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  confirmPaymentPage: {
    '& h1': {
      color: '#222',
      fontSize: '24px',
      width: '100%',
    },
    '& h1.scheduled-payment-title': {
      fontSize: '16px',
      width: '100%',
    },
  },
  confirmPaymentPageSection: {
    margin: '0 20px',
    width: '100%',
  },
  confirmPaymentBody: {
    width: '100%',
  },
  confirmPaymentFooter: {
    width: '100%',
    padding: '20px 10px',
    justifyContent: 'space-between',
    '@media(min-width: 900px)': {
      minWidth: '420px',
    },
    display: 'inline-flex',
    '& div': {
      display: 'inline-flex',
    },
  },
  invoiceList: {
    display: 'inline-block',
    width: '70%',
    verticalAlign: 'center',
    height: 'auto',
  },
  invoiceTitle: {
    display: 'inline-block',
    verticalAlign: 'center',
  },
  printIcon: {
    marginRight: '6px',
  },
  anotherPaymentButton: {
    fontSize: '14px',
    width: '200px',
  },
  '@media(max-width: 740px)': {
    invoiceList: {
      width: '50%',
    },
  },
  '@media(max-width: 480px)': {
    confirmPaymentFooter: {
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: '10px',
      '& div': {
        display: 'inline-flex',
        flexWrap: 'wrap-reverse',
        width: '100%',
      },
      '& button': {
        width: '100%',
        margin: '10px 0',
      },
    },
    confirmationItem: {
      flexFlow: 'row wrap',
      marginBottom: '15px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    confirmationItemTitle: {
      display: 'block',
      width: '100%',
    },
    confirmationItemValue: {},
    anotherPaymentButton: {
      marginRight: 0,
    },
    buttonContainer: {
      flexWrap: 'no-wrap',
      minWidth: '100%',
    },
  },
});
