import get from 'lodash/get';

import ecommApiService from '../../../Shop/utils/ecommApiService';
import { ordersArrayTransformer } from '../../../../utils/transformers/ordersTransformer';

import * as constants from '../constants';

const { LOADING_ORDERS, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE } = constants;

export const loadingOrders = (loading) => ({
  type: LOADING_ORDERS,
  loading,
});

export const getOrdersSuccess = (data) => ({
  type: GET_ORDERS_SUCCESS,
  loading: false,
  data,
});

export const getOrdersFailure = (error) => {
  const err = get(error, 'message', 'There was an error retrieving orders');
  return {
    type: GET_ORDERS_FAILURE,
    loading: false,
    error: err,
  };
};

export const getOrders = (accountId, orderFilters) => (dispatch) => {
  const {
    startDate,
    endDate,
    ordersLimit,
    pageNum,
    sortField,
    orderStatus,
  } = orderFilters;

  dispatch(loadingOrders(true));
  let ordersQuery = `v2/orders?accountId=${accountId}&date[start]=${startDate}&date[end]=${endDate}&page[size]=${ordersLimit}`;

  if (pageNum) {
    ordersQuery += `&page[num]=${pageNum}`;
  }

  if (sortField) {
    ordersQuery += `&sortField=${sortField}`;
  }
  if (orderStatus) {
    ordersQuery += `&orderStatus=${orderStatus}`;
  }

  return ecommApiService
    .get(ordersQuery, {
      headers: {
        'account-id': accountId || '',
      },
    })
    .then((res) => {
      const orders = ordersArrayTransformer(res.data);
      dispatch(getOrdersSuccess(orders));
      return res;
    })
    .catch((error) => {
      dispatch(getOrdersFailure(error));
      return error;
    });
};
