import React from 'react';
import Icon from 'nutrien-common-components-react/dist/mdc/Icon';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import './NoPricePopper.scss';

const NoPricePopper = ({ location, gtmEcPricingPopover }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const overPopup = location.toUpperCase() === 'MINICART';

  const handleClickPopper = (event) => {
    if (!open) {
      gtmEcPricingPopover(location);
    }
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleOnMouseEnter = (event) => {
    gtmEcPricingPopover(location);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClosePopper = () => {
    setOpen(false);
  };

  const handleArrowRef = (node) => {
    setArrowRef(node);
  };

  return (
    <div className="no-price__wrapper">
      <button
        className="no-price__message-button"
        data-test="no-price-button"
        onClick={handleClickPopper}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleClosePopper}
        tabIndex={0}
      >
        <Icon icon="help" className="no-price__message-icon" />
      </button>
      <Popper
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopper}
        disablePortal={false}
        className={
          overPopup ? 'no-price__message-popper-z' : 'no-price__message-popper'
        }
        data-test="no-price-message-popper"
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className="no-price__message-popper-arrow" ref={handleArrowRef} />
        <Paper className="no-price__message-popper-paper">
          <div>
            Not all products are priced. You can still place an order request
            for these products through the portal. Prices will be confirmed when
            your Nutrien Ag Solutions Representative contacts you to review your
            order.
          </div>
        </Paper>
      </Popper>
    </div>
  );
};

NoPricePopper.propTypes = {
  location: PropTypes.string.isRequired,
  gtmEcPricingPopover: PropTypes.func.isRequired,
};

export default NoPricePopper;
