import React, { Fragment, Component } from 'react';
import { Button } from 'nutrien-common-components-react/dist/mdc';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import getSteppersBreadCrumbs from '../../utils/GetStepperBreadCrumbs/getStepperBreadCrumbs';

import './styles.scss';

class CartButtons extends Component {
  handleUpdateURL = (URL) => {
    this.props.history.push({
      pathname: `${URL}`,
      originatedFromCart: true,
    });
  };

  render() {
    const { disabled, currentStep, handleSubmit } = this.props;
    const selectedAccountNumber = get(this.props, 'accountNumber', '');
    const steps = getSteppersBreadCrumbs(selectedAccountNumber);
    const currentIndex = steps.findIndex((step) => step.id === currentStep);
    return (
      <>
        {steps[currentIndex - 1] && (
          <Button
            className="cart-page__button"
            data-test={`${currentStep}-page-back`}
            secondary
            onClick={() => this.handleUpdateURL(steps[currentIndex - 1].url)}
            disabled={disabled}
          >
            Back, {steps[currentIndex - 1].breadCrumbLabel}
          </Button>
        )}
        {currentStep === 'review' ? (
          <Button
            className="cart-page__button"
            data-test={`${currentStep}-page-submit`}
            onClick={() => handleSubmit()}
            disabled={disabled}
          >
            Submit Order Request
          </Button>
        ) : (
          steps[currentIndex + 1] && (
            <Button
              className="cart-page__button"
              data-test={`${currentStep}-next`}
              onClick={() => this.handleUpdateURL(steps[currentIndex + 1].url)}
              disabled={disabled}
            >
              Next, {steps[currentIndex + 1].breadCrumbLabel}
            </Button>
          )
        )}
      </>
    );
  }
}

CartButtons.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func,
  currentStep: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CartButtons.defaultProps = {
  handleSubmit: () => {},
  disabled: false,
};

export default CartButtons;
