import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import Dropdown from 'shared/ui/Dropdown';
import PropTypes from 'prop-types';
import {
  stripSymbolsFromNumber,
  formatPhoneNumber,
  truncateNumber,
} from 'utils/phoneUtils';
import { STATES_AND_PROVINCES } from 'const/statesAndProvinces';
import PaymentValidationMessageComponent from '../../../../OtherComponents/ValidationMessage/PaymentValidationMessageComponent';
import {
  MAX_PHONE_LENGTH,
  STATE_LABELS,
  ZIP_FIELD_LENGTH,
} from '../../MakeAPaymentConstants';
import { buildClassName } from '../../../../Utils/PaymentUtil';
import { COUNTRY_CODES } from '../../../../PaymentConstants';
import * as PaymentPropTypes from '../../../../PaymentPropTypes';

import styles from './styles';
import commonStyles from '../../../../commonPaymentStyles';
import './BillingInformation.scss';

const finalStyles = (theme) => merge({}, commonStyles(theme), styles);

class BillingInformationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formattedPhone: '',
    };

    if (props.phone) {
      this.state.formattedPhone = formatPhoneNumber(props.phone);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.phone !== this.props.phone && nextProps.phone.length === 0) {
      this.setState({ formattedPhone: formatPhoneNumber(nextProps.phone) });
    }
  }

  onChangeHandler = (event) => {
    const { onChange } = this.props;
    const { name, value } = event.target;
    const currentState = { ...this.state };

    if (name === 'phone') {
      const updatedEvent = {
        target: {
          name,
          value: truncateNumber(stripSymbolsFromNumber(value)),
        },
      };

      currentState.formattedPhone = value;
      this.setState(currentState);

      onChange(updatedEvent);
    } else {
      onChange(event);
    }
  };

  onValidate = (event) => {
    const { value } = event.target;
    const field = event.target.name;
    const { validate } = this.props;

    if (field === 'addressLine2' && !value) {
      return;
    }

    validate({ field, fieldData: { value } });
  };

  phoneBlurHandler = (event) => {
    const value = stripSymbolsFromNumber(event.target.value);
    const { validate } = this.props;

    validate({ field: 'phone', fieldData: { value } });

    this.stripAndFormatNumber();
  };

  selectCountry = (event) => {
    const countryCode = event.target.value ? event.target.value.code : '';
    const { onChange } = this.props;

    this.setState({ selectedCountry: countryCode });

    // clear the state if the country is changing
    onChange({ target: { name: 'state', value: {} } });

    // change the country
    onChange(event);
  };

  stripAndFormatNumber = () => {
    const { errors } = this.props;
    const enteredPhoneValue = this.state.formattedPhone;
    const strippedPhoneValue = stripSymbolsFromNumber(enteredPhoneValue);
    const formattedPhoneValue =
      enteredPhoneValue &&
      !errors.phone.length &&
      enteredPhoneValue.length >= MAX_PHONE_LENGTH
        ? formatPhoneNumber(strippedPhoneValue)
        : enteredPhoneValue;

    const currentState = { ...this.state };
    currentState.formattedPhone = formattedPhoneValue;

    this.setState(currentState);
  };

  renderStatesDropdown = (selectedCountry, states, errors) => {
    const { selectedState, classes } = this.props;
    const statesByCountry = states ? states[selectedCountry.code] : [];
    const stateLabel =
      selectedCountry && selectedCountry.code === COUNTRY_CODES.CANADA
        ? STATE_LABELS.PROVINCE
        : STATE_LABELS.STATE;

    return (
      <div className="states-section">
        <label className={classes.addPaymentFormLabel} htmlFor="state">
          {stateLabel}
        </label>
        <div className="billing-information__dropdown-wrapper">
          <Dropdown
            placeholder={`Select ${stateLabel}`}
            options={statesByCountry}
            selectedValue={selectedState}
            dropdownName="state"
            errors={errors.state}
            id="state"
            validate={this.onValidate}
            onChange={(value) =>
              this.onChangeHandler({ target: { name: 'state', value } })
            }
          />
        </div>
        <PaymentValidationMessageComponent
          errors={errors}
          className="dropdown-message-container"
          field="state"
        />
      </div>
    );
  };

  render() {
    const {
      errors,
      selectedCountry,
      addressLine1,
      addressLine2,
      city,
      zip,
      classes,
    } = this.props;
    const { formattedPhone } = this.state;
    const zipCodeLabel =
      selectedCountry && selectedCountry.code === COUNTRY_CODES.CANADA
        ? 'Postal Code'
        : 'Zip';
    const zipMaxLength =
      selectedCountry && selectedCountry.code === COUNTRY_CODES.CANADA
        ? ZIP_FIELD_LENGTH.CAN
        : ZIP_FIELD_LENGTH.US;

    return (
      <div className={classes.billingInfo}>
        <p className={`${classes.billingInfoShareText} billing-info-text`}>
          Bank Account Billing Information
        </p>
        <label className={classes.addPaymentFormLabel} htmlFor="addressLine1">
          Address Line 1
        </label>
        <input
          type="text"
          className={buildClassName(
            'addressLine1',
            classes.addPaymentFormInput,
            errors,
            classes.error
          )}
          id="addressLine1"
          name="addressLine1"
          maxLength="35"
          onBlur={this.onValidate}
          onChange={this.onChangeHandler}
          value={addressLine1}
        />
        <PaymentValidationMessageComponent
          errors={errors}
          field="addressLine1"
        />

        <label className={classes.addPaymentFormLabel} htmlFor="addressLine2">
          Address Line 2
          <span className={classes.addPaymentFormLabelSmall}>(Optional)</span>
        </label>
        <input
          type="text"
          className={buildClassName(
            'addressLine2',
            classes.addPaymentFormInput,
            errors,
            classes.error
          )}
          maxLength="35"
          id="addressLine2"
          name="addressLine2"
          onBlur={this.onValidate}
          onChange={this.onChangeHandler}
          value={addressLine2}
        />
        <PaymentValidationMessageComponent
          errors={errors}
          field="addressLine2"
        />

        <label className={classes.addPaymentFormLabel} htmlFor="country">
          Country
        </label>
        <div className="billing-information__dropdown-wrapper">
          <Dropdown
            placeholder="Select a Country"
            options={[
              { name: 'United States', code: COUNTRY_CODES.USA },
              { name: 'Canada', code: COUNTRY_CODES.CANADA },
            ]}
            id="country"
            selectedValue={selectedCountry}
            dropdownName="country"
            errors={errors.country}
            validate={this.onValidate}
            onChange={(value) => {
              this.selectCountry({ target: { name: 'country', value } });
              if (zip || errors.zip.length !== 0) {
                this.onValidate({
                  target: {
                    name: 'zip',
                    value: zip,
                  },
                });
              }
            }}
          />
        </div>
        <PaymentValidationMessageComponent
          errors={errors}
          className="dropdown-message-container"
          field="country"
        />

        <label className={classes.addPaymentFormLabel} htmlFor="city">
          City
        </label>
        <input
          type="text"
          className={buildClassName(
            'city',
            classes.addPaymentFormInput,
            errors,
            classes.error
          )}
          id="city"
          maxLength="35"
          name="city"
          onBlur={this.onValidate}
          onChange={this.onChangeHandler}
          value={city}
        />
        <PaymentValidationMessageComponent errors={errors} field="city" />

        {this.renderStatesDropdown(
          selectedCountry,
          STATES_AND_PROVINCES,
          errors
        )}

        <label className={classes.addPaymentFormLabel} htmlFor="zip">
          {zipCodeLabel}
        </label>
        <input
          type="text"
          className={buildClassName(
            'zip',
            classes.addPaymentFormInput,
            errors,
            classes.error
          )}
          id="zip"
          maxLength={zipMaxLength}
          name="zip"
          onBlur={this.onValidate}
          onChange={this.onChangeHandler}
          value={zip}
        />
        <PaymentValidationMessageComponent errors={errors} field="zip" />

        <label className={classes.addPaymentFormLabel} htmlFor="phone">
          Phone Number
        </label>
        <input
          type="text"
          className={buildClassName(
            'phone',
            classes.addPaymentFormInput,
            errors,
            classes.error
          )}
          id="phone"
          name="phone"
          placeholder="xxx-xxx-xxxx"
          maxLength="15"
          onBlur={this.phoneBlurHandler}
          onChange={this.onChangeHandler}
          value={formattedPhone}
        />
        <PaymentValidationMessageComponent errors={errors} field="phone" />
      </div>
    );
  }
}

BillingInformationComponent.propTypes = {
  errors: PaymentPropTypes.errors.isRequired, // eslint-disable-line react/no-typos
  onChange: PropTypes.func.isRequired,
  selectedCountry: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  selectedState: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  phone: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  validate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    addPaymentFormInput: PropTypes.string,
    addPaymentFormLabel: PropTypes.string,
    addPaymentFormLabelSmall: PropTypes.string,
    billingInfo: PropTypes.string,
    billingInfoShareText: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};

BillingInformationComponent.defaultProps = {
  selectedCountry: {},
  selectedState: {},
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  zip: '',
};

export default withStyles(finalStyles)(BillingInformationComponent);
