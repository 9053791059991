import { createSelector } from 'reselect';
import getInvoices from '../../../selectors/getInvoices';

export default createSelector(getInvoices, (invoices) =>
  Object.keys(
    invoices.reduce((acc, invoice) => {
      acc[invoice.status] = invoice;
      return acc;
    }, {})
  )
);
