import { push } from 'connected-react-router';

import { gtmViewTermsOfService } from 'store/middleware/TagManager/gtmActions';

import * as termsService from './termsService';

import {
  UPDATE_TERMS_ACCEPTANCE_SUCCESS,
  UPDATE_TERMS_ACCEPTANCE_FAILURE,
  FETCHING_TERMS,
  TERMS_FETCH_SUCCESS,
  TERMS_FETCH_FAILURE,
  TERMS_SHOW_MODAL,
} from './termsConstants';

export const openTermsModal = (route) => ({
  type: TERMS_SHOW_MODAL,
  payload: route,
});

export const updateTermsAcceptanceSuccess = (version) => ({
  type: UPDATE_TERMS_ACCEPTANCE_SUCCESS,
  payload: version,
});

export const updateTermsAcceptanceFailure = (error) => ({
  type: UPDATE_TERMS_ACCEPTANCE_FAILURE,
  error,
});

export const updateTermsAcceptance = (version, route) => (dispatch) =>
  termsService
    .updateTermsAcceptance(version)
    .then(() => {
      dispatch(updateTermsAcceptanceSuccess(version));
      return route && dispatch(push(route));
    })
    .catch((error) => dispatch(updateTermsAcceptanceFailure(error)));

export const loadingTerms = () => ({
  type: FETCHING_TERMS,
});

export const termsFetchSuccess = (terms) => ({
  type: TERMS_FETCH_SUCCESS,
  payload: { terms },
});

export const termsFetchFailure = (error) => ({
  type: TERMS_FETCH_FAILURE,
  error,
});

export const fetchTerms = (termsDocument) => (dispatch) => {
  dispatch(loadingTerms());
  return termsService
    .fetchContent(termsDocument)
    .then((response) => dispatch(termsFetchSuccess(response)))
    .catch((error) => dispatch(termsFetchFailure(error)));
};

export const showTermsModal = (route) => (dispatch) => {
  dispatch(gtmViewTermsOfService());
  return dispatch(openTermsModal(route));
};
