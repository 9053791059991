export default (theme) => ({
  linkAccountContainer: {
    textAlign: 'center',
    '& button': {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      '& button': {
        width: '100%',
      },
    },
  },
});
