import getDecimalPlaces from './getDecimalPlaces';

// This function takes a number, and a returns a string (or 'null' if it recieves bad input).
// If the number has less than 2 decimal places, it will return in fixed '0.00' format.
// If the number has 2 decimal places or more, all places will be shown.
export default function formatUnitPrice(num) {
  if (typeof num !== 'number' && typeof num !== 'string') return null;

  const money = typeof num === 'string' ? Number(num) : num;

  if (Number.isNaN(money)) return null;

  const places = getDecimalPlaces(money);

  return places !== null && places < 2 ? money.toFixed(2) : String(money);
}
