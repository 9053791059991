import React, { Component, Fragment } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';

import './PurchaseOrderNumber.scss';

class PurchaseOrderNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: get(props, 'cartItems.preferences.poNumber', null),
    };
  }

  handleChange = (value) => {
    const { updatePurchaseOrderNumber } = this.props;
    const isAlphaNumeric = /^[a-zA-Z\d ]*$/;
    if (isAlphaNumeric.test(value) && value.length <= 100) {
      updatePurchaseOrderNumber(value);
      this.setState({
        inputValue: value,
      });
    }
  };

  render() {
    return (
      <>
        <div className="po__wrapper">
          <label className="po__label" htmlFor="poNumber">
            <span className="po__label__text" data-test="po-label">
              Purchase Order Number
            </span>
            <DisabledForEmployees>
              <input
                value={this.state.inputValue || ''}
                onChange={(e) => this.handleChange(e.target.value)}
                placeholder="Optional"
                className="po__number-input jsPurchaseOrderNumberInput"
                id="poNumber"
                maxLength="15"
              />
            </DisabledForEmployees>
          </label>
        </div>
      </>
    );
  }
}

PurchaseOrderNumber.propTypes = {
  updatePurchaseOrderNumber: PropTypes.func.isRequired,
};

export default PurchaseOrderNumber;
