import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { ProductHistoryObjectArrayType } from '../../../ProductHistoryPropType';

class SortableColumn extends Component {
  state = {
    order: 'ascending',
  };

  handleClick = () => {
    this.props.sortProductHistoryTable(
      this.props.sortField,
      this.state.order,
      this.props.productHistoryList
    );

    if (this.state.order === 'ascending') {
      this.setState({ order: 'descending' });
    } else if (this.state.order === 'descending') {
      this.setState({ order: 'ascending' });
    }
  };

  render() {
    if (this.props.caretVisible) {
      return (
        <div
          role="presentation"
          onClick={() => this.handleClick()}
          data-test="sortable-column-caret-visible"
          className="product-history-table__sortable-column"
        >
          {this.props.name}
          {this.state.order === 'ascending' ? (
            <ArrowDropUp />
          ) : (
            <ArrowDropDown />
          )}
        </div>
      );
    }
    return (
      <div
        role="presentation"
        onClick={() => this.handleClick()}
        data-test="sortable-column"
        className="product-history-table__sortable-column"
      >
        {this.props.name}
      </div>
    );
  }
}

SortableColumn.propTypes = {
  name: PropTypes.string.isRequired,
  sortField: PropTypes.string.isRequired,
  sortProductHistoryTable: PropTypes.func.isRequired,
  productHistoryList: ProductHistoryObjectArrayType.isRequired,
  caretVisible: PropTypes.bool.isRequired,
};

export default SortableColumn;
