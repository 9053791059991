import cxhApiAdapter from 'shared/lib/cxhApiService';
import { UPDATE_SHOW_ORDER_CONDITIONS } from '../actionTypes';

export default (showOrderConditions) => (dispatch) =>
  // TODO: this should be its own service call
  cxhApiAdapter
    .put(`v1/orderconditions`, { showOrderConditions })
    .then((res) => {
      // TODO: this should be its own action creator, to align with current patterns
      dispatch({
        type: UPDATE_SHOW_ORDER_CONDITIONS,
        data: res.showOrderConditions,
      });
      return res;
    })
    // TODO: should dispatch error actions upon error
    .catch((error) => error);
