import React from 'react';
import { InfoCard } from 'shared/ui';
import { currency } from 'shared/utils/numberFormatters';
import { ProductHistoryObjectArrayType } from '../../ProductHistoryPropType';

const PeriodCostTotal = (props) => {
  const calculateSumOfProductHistory = () => {
    if (props.list.length === 0) {
      return 0;
    }
    return props.list.reduce((a, b) => {
      if (
        b.description &&
        b.description.toUpperCase() === 'CUSTOM BLEND MATERIAL'
      ) {
        return a;
      }
      return a + b.cost;
    }, 0);
  };

  return (
    <div
      className="product-history__period-cost-total"
      data-test="product-history__period-cost-total"
    >
      <InfoCard title="Period Cost Total">
        <h1 className="info-card-h1">
          {currency(calculateSumOfProductHistory(), '')}
        </h1>
      </InfoCard>
    </div>
  );
};

PeriodCostTotal.propTypes = {
  list: ProductHistoryObjectArrayType.isRequired,
};

export default PeriodCostTotal;
