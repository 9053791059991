import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import * as cartActions from '../../actions/cartActions';

import CartItem from '../../components/CartItem/CartItem';

const mapStateToProps = ({ cart, selectedAccount, user, accounts }) => ({
  selectedAccount,
  accounts,
  cartItems: cart,
  userDetails: user.userData.userDetails,
  userId: user.userData.id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...cartActions,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(CartItem);
