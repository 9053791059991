import PropTypes from 'prop-types';

export const tokenOwner = PropTypes.shape({
  accountType: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyName: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
  country: PropTypes.string,
});

export const paymentAccount = PropTypes.shape({
  tokenValue: PropTypes.string,
  accountNumberLastThree: PropTypes.string,
  bankAccountType: PropTypes.string,
  bankName: PropTypes.string,
  alias: PropTypes.string,
  createdDate: PropTypes.string,
  tokenOwner,
});

export const paymentAccounts = PropTypes.arrayOf(paymentAccount);

export const accounts = PropTypes.arrayOf(
  PropTypes.shape({
    address: PropTypes.shape({
      address: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      state: PropTypes.string,
      zipcode: PropTypes.string,
    }),
    balance: PropTypes.number,
    due: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    nickName: PropTypes.string,
    number: PropTypes.string,
    pastDue: PropTypes.number,
  })
);

export const cpsAccount = PropTypes.shape({
  availableDiscount: PropTypes.number,
  availableRebate: PropTypes.number,
  balance: PropTypes.number,
  currentDue: PropTypes.number,
  discountDate: PropTypes.string,
  discountsOffered: PropTypes.bool,
  due: PropTypes.number,
  futureDue: PropTypes.number,
  id: PropTypes.string,
  latestStatementDate: PropTypes.string,
  name: PropTypes.string,
  number: PropTypes.string,
  pastDue: PropTypes.number,
  prepayBalance: PropTypes.number,
  rebatesOffered: PropTypes.bool,
  statementDate: PropTypes.string,
});

export const cpsAccounts = PropTypes.arrayOf(cpsAccount);

export const user = PropTypes.shape({
  token: PropTypes.shape({
    authToken: PropTypes.string,
    refreshToken: PropTypes.string,
    identityToken: PropTypes.string,
  }),
  id: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  userDetails: PropTypes.shape({
    role: PropTypes.number,
    termsAcceptance: PropTypes.string,
    associatedOrgs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
});

export const history = PropTypes.shape({
  action: PropTypes.string,
  block: PropTypes.func,
  createHref: PropTypes.func,
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  length: PropTypes.number,
  listen: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  push: PropTypes.func,
  replace: PropTypes.func,
});

export const errors = PropTypes.shape({
  cpsAccount: PropTypes.array,
  amountEntered: PropTypes.array,
  routingNumber: PropTypes.array,
  bankAccountNumber: PropTypes.array,
  confirmBankAccountNumber: PropTypes.array,
  addressLine1: PropTypes.array,
  addressLine2: PropTypes.array,
  country: PropTypes.array,
  state: PropTypes.array,
  city: PropTypes.array,
  zip: PropTypes.array,
  phone: PropTypes.array,
  corporateName: PropTypes.array,
  firstName: PropTypes.array,
  lastName: PropTypes.array,
  paymentMethodAlias: PropTypes.array,
});

export const warnings = PropTypes.shape({
  addressLine1: PropTypes.array,
  addressLine2: PropTypes.array,
  amountEntered: PropTypes.array,
  bankAccountNumber: PropTypes.array,
  city: PropTypes.array,
  confirmBankAccountNumber: PropTypes.array,
  corporateName: PropTypes.array,
  country: PropTypes.array,
  cpsAccount: PropTypes.array,
  firstName: PropTypes.array,
  lastName: PropTypes.array,
  phone: PropTypes.array,
  routingNumber: PropTypes.array,
  state: PropTypes.array,
  zip: PropTypes.array,
});

export const selectedCPSAccount = PropTypes.shape({
  availableDiscount: PropTypes.number,
  availableRebate: PropTypes.number,
  balance: PropTypes.number,
  currentDue: PropTypes.number,
  discountDate: PropTypes.string,
  discountsOffered: PropTypes.bool,
  due: PropTypes.number,
  futureDue: PropTypes.number,
  id: PropTypes.string,
  latestStatementDate: PropTypes.string,
  name: PropTypes.string,
  number: PropTypes.string,
  pastDue: PropTypes.number,
  prepayBalance: PropTypes.number,
  rebatesOffered: PropTypes.bool,
  statementDate: PropTypes.string,
});

export const paymentForm = PropTypes.shape({
  cpsAccount,
  amountEntered: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  notes: PropTypes.string,
  routingNumber: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  country: PropTypes.shape({ code: PropTypes.string, name: PropTypes.string }),
  state: PropTypes.shape({}),
  city: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
  bankAccountType: PropTypes.string,
  achAccountType: PropTypes.string,
  corporateName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  savePaymentMethod: PropTypes.bool,
  paymentMethodAlias: PropTypes.string,
  savedPaymentMethod: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  transitNumber: PropTypes.string,
  institutionNumber: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  confirmBankAccountNumber: PropTypes.string,
  accountNumber: PropTypes.string,
  confirmAccountNumber: PropTypes.string,
});

export const paymentFormErrors = PropTypes.shape({
  cpsAccount: PropTypes.arrayOf(PropTypes.object),
  amountEntered: PropTypes.arrayOf(PropTypes.object),
  routingNumber: PropTypes.arrayOf(PropTypes.object),
  bankAccountNumber: PropTypes.arrayOf(PropTypes.object),
  confirmBankAccountNumber: PropTypes.arrayOf(PropTypes.object),
  addressLine1: PropTypes.arrayOf(PropTypes.object),
  addressLine2: PropTypes.arrayOf(PropTypes.object),
  country: PropTypes.arrayOf(PropTypes.object),
  state: PropTypes.arrayOf(PropTypes.object),
  city: PropTypes.arrayOf(PropTypes.object),
  zip: PropTypes.arrayOf(PropTypes.object),
  phone: PropTypes.arrayOf(PropTypes.object),
  corporateName: PropTypes.arrayOf(PropTypes.object),
  firstName: PropTypes.arrayOf(PropTypes.object),
  lastName: PropTypes.arrayOf(PropTypes.object),
  paymentMethodAlias: PropTypes.arrayOf(PropTypes.object),
  transitNumber: PropTypes.arrayOf(PropTypes.object),
  institutionNumber: PropTypes.arrayOf(PropTypes.object),
  accountNumber: PropTypes.arrayOf(PropTypes.object),
  confirmAccountNumber: PropTypes.arrayOf(PropTypes.object),
});

export const paymentFormWarnings = PropTypes.shape({
  cpsAccount: PropTypes.arrayOf(PropTypes.object),
  amountEntered: PropTypes.arrayOf(PropTypes.object),
  routingNumber: PropTypes.arrayOf(PropTypes.object),
  bankAccountNumber: PropTypes.arrayOf(PropTypes.object),
  confirmBankAccountNumber: PropTypes.arrayOf(PropTypes.object),
  addressLine1: PropTypes.arrayOf(PropTypes.object),
  addressLine2: PropTypes.arrayOf(PropTypes.object),
  country: PropTypes.arrayOf(PropTypes.object),
  state: PropTypes.arrayOf(PropTypes.object),
  city: PropTypes.arrayOf(PropTypes.object),
  zip: PropTypes.arrayOf(PropTypes.object),
  phone: PropTypes.arrayOf(PropTypes.object),
  corporateName: PropTypes.arrayOf(PropTypes.object),
  firstName: PropTypes.arrayOf(PropTypes.object),
  lastName: PropTypes.arrayOf(PropTypes.object),
  paymentMethodAlias: PropTypes.arrayOf(PropTypes.object),
});

export const paymentError = PropTypes.shape({
  statusCode: PropTypes.number,
  name: PropTypes.string,
  message: PropTypes.string,
  errorType: PropTypes.string,
});

export const location = PropTypes.shape({
  pathname: PropTypes.string,
  state: PropTypes.object,
});
