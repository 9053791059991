import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Checkbox from '@nutrien/uet-react/Checkbox';
import Card from '@nutrien/uet-react/Card';
import Button from '@nutrien/uet-react/Button';
import IconButton from '@nutrien/uet-react/IconButton';
import Tooltip from '@nutrien/uet-react/Tooltip';
import Delete from '@material-ui/icons/Delete';
import moment from 'moment';

import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import * as NotificationPropTypes from '../../notificationsPropTypes';
import NotificationTypeIcon from '../../NotificationsTypeIcon';
import {
  ALERT_NOTIFICATION_TYPES,
  LINKED_NOTIFICATION_TYPES,
} from '../../actions';
import constructRoute from '../../utils/constructRoute';

import { useStyles } from './styles';

const NotificationsListItem = ({
  checked,
  closeMenu,
  isMenu,
  notification,
  onDelete,
  toggleSelect,
  ...rest
}) => {
  const { notificationsMultiSelect } = useFlags();

  const classes = useStyles(isMenu);

  const { createdAt, description, isRead, type } = notification;

  const handleDelete = () => {
    onDelete(notification.id);
  };
  const handleSelect = () => {
    toggleSelect(notification.id);
  };

  // TODO: Use @nutrien/uet-react/Card because it has a "status" prop for the left border coloring
  return (
    <Card
      component="div"
      className={classNames(
        classes.listItem,
        !isRead && 'unread',
        !isRead && classes.unread
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <div className={classes.summary}>
        {!isMenu && notificationsMultiSelect && (
          <div className={classes.checkboxContainer}>
            <Checkbox
              data-test="notification-checkbox"
              onChange={() => handleSelect()}
              checked={checked}
            />
          </div>
        )}
        <NotificationTypeIcon
          type={type}
          className={classes.notificationTypeIcon}
        />
        <div className={classes.summaryContentContainer}>
          <div className={classes.summaryContent}>
            <div
              data-test="notification-description"
              className={classNames(
                classes.summaryDescription,
                ALERT_NOTIFICATION_TYPES.includes(type) && 'alert',
                ALERT_NOTIFICATION_TYPES.includes(type) && classes.alert
              )}
            >
              <span className={classes.descriptionText}>{description}</span>
            </div>
          </div>
          <div className={classes.actionsContainer}>
            {Object.keys(LINKED_NOTIFICATION_TYPES).includes(type) && (
              <Link
                to={constructRoute(notification)}
                className={classes.linkButton}
                onClick={() => isMenu && closeMenu()}
              >
                <Button variant="outlined">
                  {LINKED_NOTIFICATION_TYPES[type]}
                </Button>
              </Link>
            )}
            <div className={classes.summaryDate} data-test="notification-date">
              {moment(createdAt).fromNow()}
            </div>
            <div className={classes.buttonContainer}>
              <DisabledForEmployees>
                <Tooltip title="Delete">
                  <IconButton
                    className={classes.deleteBtn}
                    onClick={handleDelete}
                    aria-label="Delete Notification"
                    size="small"
                  >
                    <Delete color="action" />
                  </IconButton>
                </Tooltip>
              </DisabledForEmployees>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

NotificationsListItem.defaultProps = {
  closeMenu: () => {},
  toggleSelect: () => {},
  checked: false,
  isMenu: false,
};

NotificationsListItem.propTypes = {
  checked: PropTypes.bool,
  closeMenu: PropTypes.func,
  isMenu: PropTypes.bool,
  notification: NotificationPropTypes.notification.isRequired,
  onDelete: PropTypes.func.isRequired,
  toggleSelect: PropTypes.func,
};

export default NotificationsListItem;
