export default ({ palette }) => {
  return {
    errorMessages: {
      paddingBottom: 0,
    },
    errorText: {
      color: palette.error.main,
    },
    warningMessages: {
      display: 'flex',
      position: 'relative',
      background: palette.warning.secondary,
      padding: 12,
      margin: 0,
    },
    warningText: {
      margin: 0,
    },
  };
};
