import { INVOICES_UPDATE_FILTERS } from 'components/Account/Invoices/InvoiceList/InvoicesTable/actions';
import { ACTION_TYPES } from 'components/Account/Invoices/actions';
import {
  GTM_INVOICES_DOWNLOAD,
  GTM_INVOICES_FOCUS_FILTER,
  GTM_INVOICES_VIEW_DETAILS,
  GTM_INVOICES_VIEW_INVOICE,
  GTM_MAKE_INVOICE_PAYMENT,
} from '../gtmConstants';

const SELECT_ALL_INVOICES_TYPE = 'all';
const SELECT_INVOICE_TYPE = 'individual';

export const updateInvoiceFilters = (action) =>
  Object.keys(action.payload).map((filterName) => ({
    event: 'CXH_UpdateInvoiceFilters',
    cxh: {
      invoices: {
        appliedFilters: {
          type: filterName,
          value: action.payload[filterName],
        },
      },
    },
  }));

export const focusInvoiceFilters = (action) => ({
  event: 'CXH_FocusInvoiceFilters',
  cxh: {
    invoices: {
      focusedFilters: {
        ...action.payload,
      },
    },
  },
});

export const downloadInvoices = (action) => ({
  event: 'CXH_DownloadInvoices',
  cxh: {
    invoices: {
      downloads: {
        ...action.payload,
      },
    },
  },
});

export const makeInvoicePayment = (action) => ({
  event: 'CXH_MakeInvoicePayment',
  cxh: {
    invoices: {
      payments: {
        ...action.payload,
      },
    },
  },
});

export const selectInvoice = (action) => {
  const type =
    action.type === ACTION_TYPES.SELECT_ALL_INVOICES
      ? SELECT_ALL_INVOICES_TYPE
      : SELECT_INVOICE_TYPE;
  return {
    event: 'CXH_SelectInvoice',
    cxh: {
      invoices: {
        interaction: {
          selectInvoice: {
            type,
          },
        },
      },
    },
  };
};

export const viewInvoice = (action) => ({
  event: 'CXH_ViewInvoice',
  cxh: {
    invoices: {
      interaction: {
        viewInvoice: {
          ...action.payload,
        },
      },
    },
  },
});

export const viewInvoicedProductsDetailsModal = () => ({
  event: 'CXH_ViewInvoicedProductsDetails',
});

export const invoiceEventsMap = {
  [GTM_INVOICES_DOWNLOAD]: downloadInvoices,
  [GTM_INVOICES_FOCUS_FILTER]: focusInvoiceFilters,
  [GTM_MAKE_INVOICE_PAYMENT]: makeInvoicePayment,
  [INVOICES_UPDATE_FILTERS]: updateInvoiceFilters,
  [ACTION_TYPES.SELECT_INVOICE]: selectInvoice,
  [ACTION_TYPES.SELECT_ALL_INVOICES]: selectInvoice,
  [GTM_INVOICES_VIEW_DETAILS]: viewInvoicedProductsDetailsModal,
  [GTM_INVOICES_VIEW_INVOICE]: viewInvoice,
};
