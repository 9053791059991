import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import KEY_ENUM from 'shared/utils/KeyCodes';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import { IconButton } from '@material-ui/core';

import './TermsAndConditionsModalStyles.scss';

const TermsAndConditionsModal = (props) => (
  <div>
    <div
      className={`terms-conditions-modal__modal-container terms-conditions-modal ${
        props.visible
          ? 'visible'
          : 'terms-conditions-modal__modal-container--hide'
      }`}
    >
      <div className="terms-conditions-modal__modal-header">
        <h2 className="terms-conditions-modal__modal-header-h2">
          {props.header}
        </h2>
        <IconButton
          className="terms-conditions-modal__button--close"
          aria-label="Click to close Terms and Conditions"
          onClick={() => props.toggleTermsAndConditions(false)}
          onKeyPress={(event) => {
            if (event.key === KEY_ENUM.ESCAPE || event.key === KEY_ENUM.ENTER) {
              props.toggleTermsAndConditions(false);
            }
          }}
          tabIndex="0"
        >
          <Icon icon="close" />
        </IconButton>
      </div>
      <div className="terms-conditions-modal__modal-body terms-conditions-content">
        <p dangerouslySetInnerHTML={{ __html: props.body }} />
      </div>
      <div className="terms-conditions-modal__modal-footer">
        <MaterialButton
          className="ok-button"
          type="button"
          tabIndex="0"
          onKeyPress={(event) => {
            if (event.key === KEY_ENUM.ESCAPE || event.key === KEY_ENUM.ENTER) {
              props.toggleTermsAndConditions(false);
            }
          }}
          onClick={() => props.toggleTermsAndConditions(false)}
          value="Got it, thanks."
        />
      </div>
    </div>
    <div
      className={
        props.visible
          ? 'terms-conditions-modal__overlay visible'
          : 'terms-conditions-modal__overlay terms-conditions-modal__modal-container--hide'
      }
    />
  </div>
);

TermsAndConditionsModal.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  toggleTermsAndConditions: PropTypes.func.isRequired,
};

export default TermsAndConditionsModal;
