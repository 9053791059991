import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useAuth } from '@nutrien/fe-domain-utils';
import { HeaderTitle } from 'shared/ui/HeaderSubComponents';
import 'shared/ui/Header/Header.scss';
import { withBrowser } from 'shared/ui/Header/withBrowser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectUser } from 'selectors';
import useIsInMaintenance from 'hooks/useIsInMaintenance';
import { gtmClickNutrienLogo } from '../../../../../store/middleware/TagManager/gtmActions';

const BaseHeader = ({
  children,
  className,
  withLogo,
  displayForAllScreenSizes,
  location,
}) => {
  const { canadaBlackOut, isSiteDownForMaintenance } = useFlags();
  const dispatch = useDispatch();
  const auth = useAuth();
  const user = useSelector(selectUser());
  const isInMaintenance = useIsInMaintenance();
  const country = auth.user?.['https://nutrienagsolutions.com/country'];

  const redirect = () => dispatch(push('/'));

  useEffect(() => {
    if (
      location.pathname === '/site-maintenance' &&
      !isSiteDownForMaintenance
    ) {
      redirect();
    }
  }, [location.pathname, isSiteDownForMaintenance]);

  useEffect(() => {
    if (Object.keys(user).length < 1) return;
    if (isInMaintenance) return;
    if ((country === 'CAN' && canadaBlackOut) || isSiteDownForMaintenance) {
      dispatch(push('/site-maintenance'));
    }
  }, [
    canadaBlackOut,
    dispatch,
    location,
    isSiteDownForMaintenance,
    user,
    auth,
    isInMaintenance,
  ]);

  return (
    <>
      <div
        className={classNames(
          className,
          { 'header-content': !displayForAllScreenSizes },
          { 'header-content--all-widths': displayForAllScreenSizes }
        )}
        data-test={
          displayForAllScreenSizes
            ? 'header-content--all-widths'
            : 'header-content'
        }
      >
        {withLogo && (
          <div className="header-logo">
            <NavLink
              activeClassName="selected"
              to="/"
              onClick={() => gtmClickNutrienLogo()}
            >
              <HeaderTitle titleText="CPS" path={location.pathname} />
            </NavLink>
          </div>
        )}
        {children}
      </div>
    </>
  );
};

BaseHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  withLogo: PropTypes.bool,
  displayForAllScreenSizes: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

BaseHeader.defaultProps = {
  children: null,
  className: '',
  withLogo: true,
  displayForAllScreenSizes: false,
};

export default withBrowser(BaseHeader);
