import { getLocalAccessToken } from '@nutrien/fe-domain-utils';
import getToken from './getToken';

export const isEmployee = () => {
  const token = getLocalAccessToken();
  const employeeToken = getToken('is-employee');

  if (token && employeeToken) {
    return !!employeeToken;
  }
  return false;
};

export default isEmployee;
