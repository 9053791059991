import React, { PureComponent, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/flow';
import { Icon, Notification } from 'nutrien-common-components-react/dist/mdc';
import WarningIcon from '@material-ui/icons/Warning';
import { Button, Box } from '@material-ui/core';
import Alert from '@nutrien/uet-react/lab/Alert';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import { isEmployee } from 'shared/utils/tokenUtils/isEmployee';
import { volumeOrQuantity } from 'shared/utils/VolumeOrQuantity/volumeOrQuantity';
import handleBackToResults from 'components/Shop/utils/handleBackToResults';
import withLocationCode from 'shared/utils/CountryCode/withLocationCode';
import TotalNetPricingFeatureFlag from 'shared/ui/TotalNetPricingFeatureFlag';
import ecommApiService from '../../../Shop/utils/ecommApiService';

import getStepperBreadCrumbs from '../../utils/GetStepperBreadCrumbs/getStepperBreadCrumbs';
import CartItemContainer from '../../containers/CartItemContainer/CartItemContainer';
import CartContent from '../CartContent';
import { UserDetailsPropType } from '../../../../shared/config/userPropType';
import EmployeeNotificationBar from '../EmployeeNotificationBar';

import './CartWrapper.scss';

export const calcSubTotal = (cartItems, priceType) => {
  if (
    cartItems.find((item) => !get(item, `variant.${priceType}`, 0)) !==
    undefined
  ) {
    return 0;
  }
  return cartItems.reduce(
    (acc, item) =>
      acc + get(item, `variant.${priceType}`, 0) * item.total_volume,
    0
  );
};

export const calcQuantity = (cartItems) => {
  let runningCount = 0;
  let totalCount;

  cartItems.forEach((cartItem) => {
    totalCount = cartItem.quantity;
    runningCount += totalCount;
  });

  return runningCount;
};

const Loading = () => (
  <div className="loading-wrapper">
    <MaterialLoadingIcon />
  </div>
);

const Error = () => <ApiErrorMessage entityName="The cart" />;

export const EmptyCart = ({ history }) => (
  <div className="empty-cart__wrapper">
    <h1 className="empty-cart__header" data-test="empty-cart-header">
      Your cart is empty.
    </h1>
    <p className="empty-cart__message">
      Please add items to your cart before submitting for fulfillment.
    </p>
    <Button
      variant="contained"
      onClick={() => history.push('/products')}
      className="empty-cart__button"
    >
      Explore Products
    </Button>
  </div>
);

export const ShoppingCartTable = ({
  cartItems,
  location,
  isCanada,
  removeProduct,
}) => (
  <table className="shopping-cart-table__table" data-test="shopping-cart-table">
    <tbody className="shopping-cart-table__body">
      <tr className="shopping-cart-table__row">
        <th className="shopping-cart-table__header">Product Name</th>
        <th className="shopping-cart-table__header">Pack Size</th>
        <th className="shopping-cart-table__header">
          {volumeOrQuantity(isCanada)}
        </th>
        <th className="shopping-cart-table__header">Price</th>
        <th className="shopping-cart-table__header--total">Total Price</th>
        <th className="shopping-cart-table__header">
          <span className="shopping-cart-table__close-header">Close</span>
        </th>
      </tr>
      {cartItems.map((cartItem) => (
        <CartItemContainer
          cartItem={cartItem}
          key={cartItem.variant_sku}
          location={location}
          removeProduct={removeProduct}
        />
      ))}
    </tbody>
  </table>
);

export const CartItems = ({
  cartItems,
  grossSubTotal,
  netSubTotal,
  reserveOrder,
  location,
  isCanada,
  confirmationRequired,
}) => {
  const { canadaReadOnly, westernRegionRestrictProducts } = useFlags();
  const [removingProduct, removeProduct] = useState([]);
  const noPrice = [];
  const cartArray = [];
  let fullCart = [];
  const invalidItems = cartItems.cartData.filter((item) =>
    get(item, 'validationResult.hasError', false)
  );
  const errorMessages = [
    ...new Set(
      invalidItems.map((item) => get(item, 'validationResult.errorMessage', ''))
    ),
  ];

  cartItems.cartData.forEach((cartItem) => {
    if (!cartItem.variant.price) {
      noPrice.push(cartItem);
    } else {
      cartArray.push(cartItem);
    }
  });

  fullCart = [...noPrice, ...cartArray];

  const subtotal =
    netSubTotal || grossSubTotal
      ? TotalNetPricingFeatureFlag(netSubTotal, grossSubTotal)
      : 'TBD';

  const displayErrorMessages = () => {
    return errorMessages.map(
      (errorMessage) =>
        errorMessage && (
          <Notification
            type="danger"
            className="cart__notification"
            data-test="cart__notification--error"
          >
            {errorMessage}
          </Notification>
        )
    );
  };

  return (
    <>
      {canadaReadOnly || westernRegionRestrictProducts ? null : (
        <DisabledForEmployees>
          <Box my={2} display={{ xs: 'block', sm: 'none' }}>
            <Button
              className="cart__button--secondary"
              data-test="reserve-order-button"
              variant="contained"
              onClick={reserveOrder}
              disabled={removingProduct.slice(-1)[0] || confirmationRequired}
            >
              Request Order
            </Button>
          </Box>
        </DisabledForEmployees>
      )}
      <div>
        {!isEmpty(invalidItems) && displayErrorMessages()}
        <ShoppingCartTable
          cartItems={fullCart}
          location={location}
          isCanada={isCanada}
          removeProduct={removeProduct}
        />

        <div className="cart__container">
          <div className="cart__inner-cart-container--bottom">
            <span className="cart__pricing-warning">
              Your account will not be charged until all prices and additional
              charges are confirmed.
            </span>
            <div className="cart__totals-wrapper">
              <div className="cart__totals-row--bold">
                <span className="cart__totals-row-header">Subtotal</span>
                <span>{subtotal}</span>
              </div>
              <div className="cart__totals-row">
                <span className="cart__totals-row-header">Tax</span>
                <span>TBD</span>
              </div>
              <div className="cart__totals-row">
                <span className="cart__totals-row-header">Shipping</span>
                <span>TBD</span>
              </div>
            </div>
            {canadaReadOnly || westernRegionRestrictProducts ? null : (
              <DisabledForEmployees>
                <Button
                  className="cart__button--primary"
                  data-test="cart-button-reserve"
                  variant="contained"
                  onClick={reserveOrder}
                  disabled={
                    removingProduct.slice(-1)[0] || confirmationRequired
                  }
                >
                  Request Order
                </Button>
              </DisabledForEmployees>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export class CartWrapperSAP extends PureComponent {
  state = {
    totalCount: 0,
    grossSubTotal: 0,
    showOrderConditionsModal: false,
    poNumber: null,
    netSubTotal: 0,
    showNotification: false,
    deletedCartItems: [],
    confirmationRequired: false,
  };

  componentDidMount() {
    const { selectedAccount, cartItems } = this.props;
    if (this.checkDefaultAccount()) {
      this.props.getCart(selectedAccount.id);
      if (!get(cartItems, 'addresses.all.updatingAddress', false)) {
        this.props.getAddress(selectedAccount.id);
      }
      this.props.getBranchAddress(selectedAccount.id, selectedAccount.branchId);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedAccount, cartItems } = this.props;
    if (prevProps.selectedAccount.id !== selectedAccount.id) {
      this.props.getCart(selectedAccount.id);
    }

    const newTotal = cartItems.cartData.reduce((accu, item) => {
      const updatedTotal = accu + item.quantity;
      return updatedTotal;
    }, 0);

    if (newTotal !== this.state.totalCount) {
      this.getQuantity();
      this.getSubtotal();
    }
  }

  getSection(reserveOrder, isCanada) {
    const { cartItems, deleteCartItem, updateCart, history, location } =
      this.props;
    const { cartData, loading, error, updatingCart } = cartItems;
    const { confirmationRequired } = this.state;
    let section;

    // cart is loading
    if (loading) {
      section = <Loading data-test="cart-wrapper-loading" />;
    }
    // there has been a network error
    else if (error) {
      section = <Error data-test="cart-wrapper-error" />;
    }
    // no data exists -> empty cart
    else if (!cartData.length) {
      section = <EmptyCart history={history} />;
    } else {
      section = (
        <CartItems
          cartItems={cartItems}
          grossSubTotal={this.state.grossSubTotal}
          netSubTotal={this.state.netSubTotal}
          deleteCartItem={deleteCartItem}
          updateCart={updateCart}
          reserveOrder={reserveOrder}
          updatingCart={updatingCart}
          location={location}
          isCanada={isCanada}
          data-test="cart-wrapper-data"
          confirmationRequired={confirmationRequired}
        />
      );
    }

    return section;
  }

  getQuantity() {
    const { cartItems } = this.props;
    const runningCount = calcQuantity(cartItems.cartData);

    this.setState({
      totalCount: runningCount,
    });
  }

  getSubtotal() {
    const { cartItems } = this.props;
    const grossSubTotal = parseFloat(calcSubTotal(cartItems.cartData, 'price'));
    const netSubTotal = parseFloat(
      calcSubTotal(cartItems.cartData, 'net_price')
    );

    this.setState({
      grossSubTotal,
      netSubTotal,
    });
  }

  checkDefaultAccount() {
    const { selectedAccount } = this.props;
    return selectedAccount && selectedAccount.id;
  }

  handleRemoveInvlalidCartItems = (invalidItems) => {
    const { deleteCartItem } = this.props;
    let deletedCartItems;

    this.setState({
      showNotification: true,
      confirmationRequired: true,
    });

    invalidItems.forEach((item) => {
      deletedCartItems = this.state.deletedCartItems.concat(
        item.product.attributes.name
      );

      this.setState({
        deletedCartItems,
      });

      deleteCartItem(item, true);
    });
  };

  validateProducts = (currentCartItems) => {
    const {
      selectedAccount,
      cartItems,
      userDetails: { showOrderConditions },
    } = this.props;
    const variantIDS = currentCartItems.join(',');
    const validProducts = [];
    const deletableItems = [];

    ecommApiService
      .get(
        `/v2/products?fields[variant]=productId&filters[variantId]=${variantIDS}&filters[branchId]=${selectedAccount.branchId}`,
        {
          headers: {
            'account-id': selectedAccount.id,
          },
        }
      )
      .then((res) => {
        res.data.data.forEach((item) => {
          item.variants.forEach((variant) => {
            if (variant.branchIds.includes(selectedAccount.branchId)) {
              validProducts.push(variant.id);
            }
          });
        });

        const notExtended = currentCartItems.filter(
          (valid) => !validProducts.includes(valid)
        );

        cartItems.cartData.forEach((cartItem) => {
          notExtended.forEach((item) => {
            if (cartItem.variant.id === item) {
              deletableItems.push(cartItem);
            }
          });
        });

        if (deletableItems.length > 0) {
          this.handleRemoveInvlalidCartItems(deletableItems);
        } else {
          // eslint-disable-next-line no-unused-expressions
          showOrderConditions === true
            ? this.setState({ showOrderConditionsModal: true })
            : this.handleRoutePage();
        }
      });
  };

  // eslint-disable-next-line consistent-return
  reserveOrder = () => {
    const { cartItems, gtmLogCartCheckoutStep1 } = this.props;
    const currentCartItems = [];
    const invalidItems = cartItems.cartData.filter((item) =>
      get(item, 'validationResult.hasError', false)
    );
    if (!isEmpty(invalidItems)) {
      return null;
    }
    this.sendPONumber();
    gtmLogCartCheckoutStep1(cartItems.cartData);

    cartItems.cartData.forEach((item) => {
      currentCartItems.push(item.variant.id);
    });

    this.validateProducts(currentCartItems);
  };

  sendPONumber() {
    const { poNumber } = this.state;
    const { setPurchaseOrderNumber } = this.props;

    setPurchaseOrderNumber(poNumber);
  }

  handleCloseModal() {
    this.setState({
      showOrderConditionsModal: false,
    });
  }

  handleCloseAlert = () => {
    this.setState({
      showNotification: false,
      confirmationRequired: false,
    });
  };

  handleOkButton = (checked) => {
    if (checked) {
      this.props.updateShowOrderConditions(false);
    }
    this.sendPONumber();
    this.handleRoutePage();
  };

  handleRoutePage = () => {
    const {
      selectedAccount,
      accounts: { list },
    } = this.props;
    const selectedOrDefault = !isEmpty(selectedAccount)
      ? selectedAccount
      : list[0];
    const accountNumber = get(selectedOrDefault, 'id', '');
    const steps = getStepperBreadCrumbs(accountNumber);
    const pathname = get(steps, '[0].url', '');

    this.props.history.push({
      pathname,
      originatedFromCart: true,
    });
  };

  updatePurchaseOrderNumber = (purchaseOrderNumber) => {
    let poNumber = purchaseOrderNumber;
    if (!purchaseOrderNumber.replace(/\s/g, '').length) {
      // string only contained whitespace (ie. spaces, tabs or line breaks)
      poNumber = null;
    }

    this.setState(
      {
        poNumber,
      },
      () => {
        this.sendPONumber();
      }
    );
  };

  handleBackToResults = () => handleBackToResults(this.props);

  render() {
    const {
      selectedAccount,
      accounts: { list },
      isCanada,
    } = this.props;
    const employeeWrapper = isEmployee();
    // eslint-disable-next-line no-nested-ternary
    const selectedOrDefault = !isEmpty(selectedAccount)
      ? selectedAccount
      : list !== undefined
      ? list[0]
      : null;
    const { showNotification, deletedCartItems } = this.state;
    const deleteCartItemsString = deletedCartItems.join(', ');

    return (
      <section
        className={employeeWrapper ? 'cart__wrapper-employee' : 'cart__wrapper'}
      >
        <Button
          className="cart__back-to-results-wrapper"
          onClick={this.handleBackToResults}
          variant="text"
        >
          <Icon
            className="cart__back-to-results-icon"
            icon="arrowDown"
            type="success"
          />
          Back to products
        </Button>
        {showNotification ? (
          <Alert
            onClose={() => this.handleCloseAlert()}
            severity="warning"
            className="cart__warning"
            icon={<WarningIcon className="cart__icon" />}
          >
            <div data-test="cart-removal-warning">
              The following products are no longer sold by your branch and have
              been removed from your cart:
              <span className="cart__warning-item">
                {deleteCartItemsString}
              </span>
            </div>
          </Alert>
        ) : null}
        <EmployeeNotificationBar />
        <div className="cart__header--container">
          <div>
            <h1 className="cart__headline">Cart</h1>
          </div>
          <div className="account-fulfillment__container">
            <h2 className="account-fulfillment__header">Account</h2>
            <p className="account-fulfillment__account-info">
              {selectedOrDefault &&
                `${selectedOrDefault.name} - ${selectedOrDefault.number}`}
            </p>
            <p className="account-fulfillment__account-location-info jsLocationInfo">
              {selectedOrDefault &&
                `${selectedOrDefault.branchName} #${selectedOrDefault.branchId}`}
            </p>
          </div>
        </div>
        <CartContent
          handleCloseModal={this.handleCloseModal}
          handleOkButton={this.handleOkButton}
          usaSapAccount={selectedAccount.usaSapAccount}
          showOrderConditionsModal={this.state.showOrderConditionsModal}
          updatePurchaseOrderNumber={this.updatePurchaseOrderNumber}
        >
          {this.getSection(this.reserveOrder, isCanada)}
        </CartContent>
      </section>
    );
  }
}

CartWrapperSAP.propTypes = {
  location: PropTypes.shape({}).isRequired,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    balance: PropTypes.number,
    due: PropTypes.number,
    pastDue: PropTypes.number,
    number: PropTypes.string,
    branchId: PropTypes.string,
    usaSapAccount: PropTypes.bool,
  }),
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  cartItems: PropTypes.shape({
    cartData: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    updatingCart: PropTypes.shape({
      updating: PropTypes.bool.isRequired,
    }).isRequired,
    preferences: PropTypes.shape({
      poNumber: PropTypes.string,
      validBulkInput: PropTypes.bool,
    }),
  }).isRequired,
  getAddress: PropTypes.func.isRequired,
  getBranchAddress: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  deleteCartItem: PropTypes.func.isRequired,
  setPurchaseOrderNumber: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  userDetails: UserDetailsPropType,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  updateShowOrderConditions: PropTypes.func.isRequired,
  gtmLogCartCheckoutStep1: PropTypes.func.isRequired,
  isCanada: PropTypes.bool.isRequired,
};

EmptyCart.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

CartItems.propTypes = {
  confirmationRequired: PropTypes.bool,
  cartItems: PropTypes.shape({
    cartData: PropTypes.array,
  }).isRequired,
  grossSubTotal: PropTypes.number.isRequired,
  netSubTotal: PropTypes.number.isRequired,
  reserveOrder: PropTypes.func.isRequired,
  updatingCart: PropTypes.shape({
    updating: PropTypes.bool.isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  isCanada: PropTypes.bool.isRequired,
};

ShoppingCartTable.propTypes = {
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({}),
    }).isRequired
  ).isRequired,
  location: PropTypes.shape({}).isRequired,
  isCanada: PropTypes.bool.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

CartWrapperSAP.defaultProps = {
  userDetails: {
    showOrderConditions: true,
  },
  accounts: [
    {
      list: [],
    },
  ],
  selectedAccount: {},
};

CartItems.defaultProps = {
  confirmationRequired: false,
};

export default flow(withRouter, withLocationCode)(CartWrapperSAP);
