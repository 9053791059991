import * as actionTypes from '../../actions/constants';

const initialProductsState = {
  data: [],
  loading: true,
  loadingPrices: false,
  error: null,
  category: null,
  subCategory: null,
  checkedIds: {
    activeIngredient: [],
    chemical: [],
    fertilizer: [],
    manufacturer: [],
    cropType: [],
    contract: [],
  },
};

const {
  LOADING_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_MORE_PRODUCTS_SUCCESS,
  LOADING_MORE_PRODUCTS,
  GET_MORE_PRODUCTS_FAILURE,
  SET_NEW_CATEGORY,
  SET_NEW_SUB_CATEGORY,
  LOADING_PRICES_FOR_PRODUCTS,
  GET_PRICES_FOR_PRODUCTS_SUCCESS,
  GET_PRICES_FOR_PRODUCTS_FAILURE,
  SET_PRODUCT_FILTERS,
  GET_CROSS_SELLS_SUCCESS,
  GET_CROSS_SELLS_FAILURE,
} = actionTypes;

export default (state = initialProductsState, action) => {
  switch (action.type) {
    case LOADING_PRODUCTS: {
      const { loading } = action;
      return { ...state, loading };
    }
    case GET_PRODUCTS_SUCCESS: {
      const { data, loading, error, meta } = action;
      let noMoreProducts = false;
      if (data.length < 30) {
        noMoreProducts = true;
      }
      return { ...state, data, error, loading, meta, noMoreProducts };
    }
    case GET_PRODUCTS_FAILURE: {
      const { error, loading } = action;
      return { ...state, error, loading };
    }
    case GET_MORE_PRODUCTS_FAILURE: {
      const { loading, getMoreProductsError, loadingMore } = action;
      return { ...state, loading, getMoreProductsError, loadingMore };
    }
    case GET_MORE_PRODUCTS_SUCCESS: {
      const { data, loading, loadingMore } = action;
      const oldData = state.data;
      let noMoreProducts = false;
      if (data.length < 30) {
        noMoreProducts = true;
      }
      return {
        ...state,
        data: [...oldData, ...data],
        loading,
        loadingMore,
        noMoreProducts,
      };
    }
    case LOADING_MORE_PRODUCTS: {
      const { loadingMore } = action;
      return { ...state, loadingMore };
    }
    case SET_NEW_CATEGORY: {
      const { category } = action;
      return { ...state, category };
    }
    case SET_NEW_SUB_CATEGORY: {
      const { subCategory } = action;
      return { ...state, subCategory };
    }
    case LOADING_PRICES_FOR_PRODUCTS: {
      const { loadingPrices } = action;
      return { ...state, loadingPrices };
    }
    case GET_PRICES_FOR_PRODUCTS_SUCCESS: {
      const { loadingPrices, data, getPricesError, append } = action;
      let newData = data;
      // Removes last appended products
      // and re-appends products with prices
      if (append) {
        const oldData = state.data;
        newData = oldData;
        const index = oldData.length - data.length;
        newData.splice(index, data.length, ...data);
      }
      return { ...state, loadingPrices, data: newData, getPricesError };
    }
    case SET_PRODUCT_FILTERS: {
      const { values, filterType } = action;
      return {
        ...state,
        checkedIds: { ...state.checkedIds, [filterType]: values },
      };
    }
    case GET_PRICES_FOR_PRODUCTS_FAILURE: {
      const { loadingPrices, getPricesError } = action;
      return { ...state, loadingPrices, getPricesError };
    }
    case GET_CROSS_SELLS_SUCCESS: {
      const { data, loading, error, meta } = action;
      return { ...state, data, error, loading, meta };
    }
    case GET_CROSS_SELLS_FAILURE: {
      const { error, loading } = action;
      return { ...state, error, loading };
    }
    default:
      return state;
  }
};
