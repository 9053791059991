const passwordCriteria = (password) => {
  const criteria = {};
  criteria.charLength = password.length >= 10;
  criteria.special = /[!@#$%^&*()_\-[\]{};':"\\|,.<>/?]/.test(password);
  criteria.lower = /[a-z]/g.test(password);
  criteria.upper = /[A-Z]/g.test(password);

  return criteria;
};

export default passwordCriteria;
