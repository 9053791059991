import PropTypes from 'prop-types';

export const RolePropType = PropTypes.shape({
  roleData: PropTypes.shape({}),
  status: PropTypes.string,
  error: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      message: PropTypes.string,
    }),
    status: PropTypes.number,
  }),
});
export const RbacRegistrationFormPropType = PropTypes.shape({
  form: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    password: PropTypes.string,
    phone: PropTypes.string,
    termsAcceptance: PropTypes.boolean,
  }),
  formErrors: PropTypes.array,
});
