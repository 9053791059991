import axios from 'shared/lib/cxhApiService';

// Register the user on the system
export const registerRoleDelegation = ({
  email,
  password,
  confirmationCode,
  firstName,
  lastName,
  phoneNumber,
}) =>
  axios.post('v1/user/registerRoleDelegation', {
    email,
    password,
    confirmationCode,
    firstName,
    lastName,
    phoneNumber,
  });

// Checks that the guid is a valid confirmation id
export const confirmInvitation = (guid) =>
  axios.get(`v1/role/confirmation/${guid}`);
