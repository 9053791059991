// dependencies
import React from 'react';
import { withStyles } from '@material-ui/core';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NUTRIEN_COMPANY_NAME } from 'shared/config/constants';
import { getCurrentYear } from 'shared/utils/DateHelper';

import DoNotSellLink from './DoNotSellLink';

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    bottom: 0,
  },
  footer: {
    fontSize: 14,
    background: '#222222',
    textAlign: 'center',
    padding: '1.1em 1.5em',
    '@media print': {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  topText: {
    lineHeight: '1em',
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',
    margin: '1em 0',
    padding: 0,
    '& li': {
      padding: '0 10px',
      borderLeft: '1px solid #828282',
      '& a': {
        fontSize: 12,
        fontWeight: 600,
        color: '#4c9e00',
      },
    },
    '& li:first-child': {
      borderLeft: 'none',
    },
  },
  bottomText: {
    fontSize: 11,
    color: '#929292',
    lineHeight: '1em',
  },
  legalText: {
    color: '#929292',
    fontSize: 11,
  },
};

const Footer = (props) => {
  const {
    classes,
    location: { pathname: path },
  } = props;

  // Hide footer on cart checkout page
  if (path.match(/\/(cart\/logistics|cart\/services|cart\/review)\/?/))
    return null;

  return (
    <div className={classes.container}>
      <div className={classes.footer} id="siteFooter">
        <span className={classes.legalText}>
          All of the trademarks and service marks displayed are marks of their
          respective owners.
        </span>
        <ul className={classes.topText}>
          <DoNotSellLink />
          <li className={classes.firstLi}>
            <NavLink activeClassName="selected" to="/terms-of-use">
              Terms of Use
            </NavLink>
          </li>
          <li>
            <a
              rel="noreferrer"
              href="https://www.nutrien.com/nutrien-privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <NavLink activeClassName="selected" to="/contact">
              Contact Us
            </NavLink>
          </li>
        </ul>
        <div className={classes.bottomText}>
          © {NUTRIEN_COMPANY_NAME}&#8482; {getCurrentYear()}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(withStyles(styles)(Footer));
