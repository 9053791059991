import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setGloballySelectedAccount } from 'actions/accountSelectorActions';
import setDefaultAccount from '../../../Shop/actions/defaultAccountActions/defaultAccountActions';

import * as cartActions from '../../actions/cartActions';
import CartHeaderIconWrapper from '../../components/CartHeaderIconWrapper/CartHeaderIconWrapper';

const mapStateToProps = ({ accounts, cart, selectedAccount, user }) => ({
  accounts,
  selectedAccount,
  cart,
  userDetails: user.userData.userDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...cartActions, setDefaultAccount, setGloballySelectedAccount },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartHeaderIconWrapper);
