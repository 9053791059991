import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import { Redirect } from 'react-router';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';

import { FullPage, PaymentHeader } from 'shared/ui';
import { ROUTES_MAP } from 'shared/ui/Payments/PaymentHeader/constants';
import Button from '@nutrien/uet-react/Button';
import Checkbox from '@nutrien/uet-react/Checkbox';

import { currency } from 'shared/utils/numberFormatters';
import bankCheck from 'assets/bank-check.svg';
import icnWarning from 'assets/icn-warning.svg';
import icnWallet from 'assets/review-error-500.svg';
import KEY_ENUM from 'shared/utils/KeyCodes';
import information from 'assets/information.svg';
import * as PaymentPropTypes from '../../PaymentPropTypes';
import {
  MAKE_PAYMENT_STEPS,
  savePaymentErrorMessage,
  TC_MAKING_PAYMENT_HEADER_USA,
  TC_MAKING_PAYMENT_HEADER_CANADA,
  COUNTRY_CODES,
  errorTitleText,
  SUBMIT_PAYMENT_SUCCESS,
  PAYMENT_FLOW_TYPES,
  DEFAULT_PAYMENT_METHODS,
  RecurringPaymentFrequencies,
} from '../../PaymentConstants';
import { getPaymentProcessingText } from '../../Utils/PaymentUtil';
import TermsAndConditionsModal from '../../OtherComponents/TermsAndConditionsModal/TermsAndConditionsModal';
import PaymentProcessingOverlay from '../../OtherComponents/PaymentProcessingOverlay/PaymentProcessingOverlay';
import ModalComponent from '../../OtherComponents/ModalComponent';

import styles from './styles';
import commonStyles from '../../commonPaymentStyles';
import { ERROR_TYPES } from '../../Redux/actions/ErrorActions';

const finalStyles = (theme) => merge({}, commonStyles(theme), styles(theme));

class ReviewPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateInitiated: '',
      showTermsAndConditions: false,
      termsAndConditions: {
        checked: false,
        showError: false,
      },
      highlightTAndCs: false,
      isSubmitting: props.isLoading,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { setDateInitiated } = this.props;
    const chargingDate = this.formatDate(new Date());

    window.popstate = this.goBack;

    this.setState({ dateInitiated: chargingDate });

    setDateInitiated(chargingDate);
  }

  componentDidUpdate() {
    const { paymentError } = this.props;
    const { isSubmitting } = this.state;
    // setState is acceptable in componentDidUpdate() if wrapped in a conditional
    // https://reactjs.org/docs/react-component.html#componentdidupdate
    // eslint-disable-next-line react/no-did-update-set-state
    if (paymentError && isSubmitting) this.setState({ isSubmitting: false });
  }

  getPaymentHeaderProps = (flowType) => {
    const isPrepayflow = flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT;
    return isPrepayflow
      ? {
          routes: ROUTES_MAP.PRE_PAY_ROUTES,
        }
      : {
          routes: ROUTES_MAP.MAKE_A_PAYMENT_ROUTES,
        };
  };

  formatDate = (date) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  };

  buildContentMessage = () => {
    const {
      flowType,
      cpsAccount,
      achAccountType,
      bankAccountType,
      recurringEndDate,
      isRecurring,
      scheduledPayment,
      savedPaymentMethod,
      recurringPaymentFrequency,
    } = this.props;
    const { dateInitiated } = this.state;
    const chargeMessage =
      savedPaymentMethod.tokenValue ===
      DEFAULT_PAYMENT_METHODS.PREPAY.tokenValue
        ? `will be taken from your Prepay Funds`
        : `will be charged to your ${bankAccountType} ${achAccountType} account`;

    const dateString = scheduledPayment
      ? new Date(scheduledPayment).toDateString()
      : dateInitiated;

    const makeAPaymentMessage = (
      <div>
        {chargeMessage} on <strong>{dateString}</strong> and applied to{' '}
        <strong>
          {cpsAccount ? `${cpsAccount.name} - ${cpsAccount.number}` : ''}
        </strong>{' '}
        account
      </div>
    );

    const prePayPaymentMessage = (
      <div>
        will be added to your{' '}
        <strong>
          {cpsAccount ? `${cpsAccount.name} - ${cpsAccount.number}` : ''}
        </strong>{' '}
        on <strong>{dateString}</strong> to the pre-pay balance. The money will
        be withdrawn from your {bankAccountType} {achAccountType} account
      </div>
    );

    const recurringFrequency =
      recurringPaymentFrequency.name ===
      RecurringPaymentFrequencies.BI_WEEKLY.name
        ? 'other week'
        : 'month';
    const recurringPaymentMessage = (
      <div>
        will be charged to the following account every{' '}
        <strong>{recurringFrequency}</strong> beginning on{' '}
        <strong>{dateString}</strong> and{' '}
        <strong>ending on {new Date(recurringEndDate).toDateString()}</strong>{' '}
        and applied to{' '}
        <strong>
          {cpsAccount ? `${cpsAccount.name} - ${cpsAccount.number}` : ''}
        </strong>{' '}
        account
      </div>
    );

    let finalMessage;
    if (flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT) {
      finalMessage = prePayPaymentMessage;
    } else if (isRecurring && recurringPaymentFrequency) {
      finalMessage = recurringPaymentMessage;
    } else {
      finalMessage = makeAPaymentMessage; // this is for both regular payments and non recurring scheduled payments
    }

    return finalMessage;
  };

  goBack = (event) => {
    event.preventDefault();

    const { clearBankAccount, push, selectedInvoices, cpsAccount, flowType } =
      this.props;

    clearBankAccount();

    if (flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT) {
      push({ pathname: `/accounts/${cpsAccount.id}/add-funds-to-prepay` });
    } else if (flowType === PAYMENT_FLOW_TYPES.MAKE_A_PAYMENT) {
      push({ pathname: `/make-a-secure-payment` });
    } else {
      push({
        pathname: '/make-a-secure-payment',
        state: {
          flowType,
          selectedInvoices,
          account: cpsAccount.id,
        },
      });
    }
  };

  submitPaymentHandler = () => {
    const { submitCurrentPayment, flowType } = this.props;
    const { termsAndConditions, isSubmitting } = this.state;

    if (termsAndConditions.checked && !isSubmitting) {
      this.setState({ isSubmitting: true });
      submitCurrentPayment(flowType);
    } else {
      this.setState({
        termsAndConditions: { showError: true, checked: false },
      });
    }
  };

  toggleTermsAndConditions = (flag) => {
    this.setState({ showTermsAndConditions: flag });
    if (!flag) {
      this.backButtonRef.focus();
    }
  };

  renderNotes = (notes) => {
    const { classes } = this.props;
    return (
      <div className={classes.reviewPaymentNotesContainer}>
        <div className={classes.reviewPaymentNotesHeader}>
          Notes for Associated Location
        </div>
        <div className="review-payment__notes">{notes}</div>
      </div>
    );
  };

  renderErrorModal = () => {
    const { paymentError, clearPaymentError } = this.props;
    return (
      <ModalComponent
        open={paymentError && paymentError.errorType === ERROR_TYPES.MODAL}
        close={() => clearPaymentError()}
        titleText={errorTitleText}
        bodyText1={
          paymentError.name === 'FORBIDDEN_ERROR'
            ? paymentError.message
            : savePaymentErrorMessage
        }
        image={icnWallet}
      />
    );
  };

  renderInvoices = () => {
    const { invoiceData } = this.props;

    const map = invoiceData.map(
      (val) => ` ${val.id}: ${currency(val.amountDue)}`
    );
    return map.join();
  };

  renderOverPaymentNotification = (amount, cpsAccount, classes) => (
    <div className={classes.reviewOverpaymentStatement}>
      <img
        className={classes.imageIcon}
        src={information}
        alt="informationIcon"
      />
      The payment amount you entered is greater than the minimum payment due. If
      you have questions about your future balance or finance charges, please
      review your invoices or contact your branch.
    </div>
  );

  render() {
    const {
      notes,
      goBack,
      amount,
      classes,
      flowType,
      isLoading,
      cpsAccount,
      paymentError,
      paymentStatus,
      showFormErrors,
      achAccountType,
      bankAccountType,
      discountedAmount,
      savedPaymentMethod,
      bankAccountLastThree,
      termsAndConditionsResponse,
      flags: { southernOhioDivision },
    } = this.props;

    const {
      termsAndConditions,
      highlightTAndCs,
      isSubmitting,
      showTermsAndConditions,
    } = this.state;
    const shouldRedirect =
      (paymentStatus && paymentStatus === SUBMIT_PAYMENT_SUCCESS) ||
      !amount ||
      !flowType ||
      !termsAndConditionsResponse;

    if (shouldRedirect) {
      return <Redirect to="/make-a-secure-payment" />;
    }

    const savedPaymentMethodIsNotDefault =
      savedPaymentMethod &&
      savedPaymentMethod.tokenValue !==
        DEFAULT_PAYMENT_METHODS.PREPAY.tokenValue &&
      savedPaymentMethod.tokenValue !==
        DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT.tokenValue;

    const displayedAccountType = savedPaymentMethodIsNotDefault
      ? savedPaymentMethod.tokenOwner.bankAccountType
      : bankAccountType;
    const displayedBankAccountType = savedPaymentMethodIsNotDefault
      ? savedPaymentMethod.achAccountType
      : achAccountType;
    const currentDate = moment().format('MM/DD/YYYY');

    const shouldRenderNotification =
      amount &&
      cpsAccount &&
      amount > cpsAccount.currentDue &&
      flowType !== PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT;

    // TODO: remove usaSapAccount restrictions once SAP is rolled out to entire US market
    const paymentDisabled =
      (cpsAccount.usaSapAccount && !southernOhioDivision) ||
      cpsAccount.invalidCanadianAccount ||
      cpsAccount.invalidInternationalAccount;

    const headerTitle =
      flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT
        ? 'Add Funds To Pre-Pay Balance'
        : 'Make Payment';
    const contentTitle =
      flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT
        ? 'Review'
        : 'Review Payment';
    const contentMessage = this.buildContentMessage();
    const paymentHeaderProps = this.getPaymentHeaderProps(flowType);
    const bankAccountDetails =
      savedPaymentMethod.tokenValue ===
      DEFAULT_PAYMENT_METHODS.PREPAY.tokenValue ? (
        ''
      ) : (
        <div
          className={classes.reviewPaymentCheckingDetails}
          name="bankAccountDetails"
        >
          <img
            className={classes.imageIcon}
            src={bankCheck}
            alt="bankCheckIcon"
          />
          <strong>
            {displayedAccountType} {displayedBankAccountType} Account Ending In{' '}
            {bankAccountLastThree}
          </strong>
        </div>
      );

    return (
      <div className={classes.reviewPayment}>
        {!!paymentError && this.renderErrorModal()}
        <FullPage noCard>
          <PaymentHeader
            title={headerTitle}
            subNavRoutes={paymentHeaderProps.routes}
            currentPaymentStep={MAKE_PAYMENT_STEPS.REVIEW}
            paymentError={paymentError}
            showFormErrors={showFormErrors}
          />

          <div className={classes.reviewPaymentContainer}>
            <div
              className={`${classes.addPaymentFormLabelLarge} review-payment-header`}
            >
              {contentTitle}
            </div>
            {shouldRenderNotification &&
              this.renderOverPaymentNotification(amount, cpsAccount, classes)}
            <div
              className={classes.reviewPaymentSelectedAmount}
              name="selectedAmount"
            >
              {currency(amount)}
            </div>
            <div
              className={classes.reviewPaymentDetails}
              name="paymentInformation"
            >
              {contentMessage}
              {flowType === PAYMENT_FLOW_TYPES.INVOICE_PAYMENT
                ? ' for the following Invoices: '
                : ''}
              {flowType === PAYMENT_FLOW_TYPES.INVOICE_PAYMENT && (
                <div className={classes.invoiceText}>
                  <strong className="InvoiceList">
                    {this.renderInvoices()}
                  </strong>
                  .
                  {discountedAmount && (
                    <div className={classes.invoiceText}>
                      {' '}
                      The invoice payment total{' '}
                      <strong>
                        includes a {currency(discountedAmount)} invoice
                        discount.
                      </strong>
                    </div>
                  )}
                </div>
              )}
            </div>
            {bankAccountDetails}
            <div
              className={classes.reviewPaymentCheckingDetails}
              name="processingInformation"
            >
              <img
                className={classes.imageIcon}
                src={information}
                alt="informationIcon"
              />
              {getPaymentProcessingText(currentDate)}
            </div>
            <div className={classes.reviewPaymentFooterContainer}>
              {notes ? this.renderNotes(notes) : null}

              <div
                className={classes.reviewPaymentFooterBottom}
                name="reviewPaymentFooter"
              >
                <div
                  className={
                    termsAndConditions.showError
                      ? classes.termsAndConditionsContainerError
                      : classes.termsAndConditionsContainer
                  }
                >
                  <div
                    className={classes.termsAndConditionsText}
                    name="termsAndConditionsText"
                  >
                    <Checkbox
                      name="review-checkbox"
                      errorClass={termsAndConditions.showError ? 'error' : ''}
                      onChange={() =>
                        this.setState({
                          termsAndConditions: {
                            checked: !termsAndConditions.checked,
                            showError: false,
                          },
                        })
                      }
                      value={false}
                      description=""
                      className={classes.termsAndConditionsCheckbox}
                    />
                    <div>
                      I agree to{' '}
                      <div
                        className={classes.termsAndConditionsLinkContainer}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          this.toggleTermsAndConditions(true);
                        }}
                        onKeyPress={(e) => {
                          e.stopPropagation();
                          if (e.key === KEY_ENUM.ENTER) {
                            window.scrollTo(0, 0);
                            this.toggleTermsAndConditions(true);
                          }
                        }}
                        role="button"
                        tabIndex="0"
                        name="termsAndConditionsLink"
                      >
                        <div
                          className={
                            highlightTAndCs
                              ? classes.hover
                              : classes.termsAndConditionsLink
                          }
                          onMouseEnter={() => {
                            if (!highlightTAndCs) {
                              this.setState({ highlightTAndCs: true });
                            }
                          }}
                          onMouseLeave={() => {
                            if (highlightTAndCs)
                              this.setState({ highlightTAndCs: false });
                          }}
                        >
                          Terms & Conditions
                        </div>{' '}
                        <div
                          className={
                            highlightTAndCs
                              ? classes.hover
                              : classes.termsAndConditionsLink
                          }
                          onMouseEnter={() => {
                            if (!highlightTAndCs)
                              this.setState({ highlightTAndCs: true });
                          }}
                          onMouseLeave={() => {
                            if (highlightTAndCs)
                              this.setState({ highlightTAndCs: false });
                          }}
                        >
                          for Making
                        </div>{' '}
                        <div
                          className={
                            highlightTAndCs
                              ? classes.hover
                              : classes.termsAndConditionsLink
                          }
                          onMouseEnter={() => {
                            if (!highlightTAndCs)
                              this.setState({ highlightTAndCs: true });
                          }}
                          onMouseLeave={() => {
                            if (highlightTAndCs)
                              this.setState({ highlightTAndCs: false });
                          }}
                        >
                          {cpsAccount &&
                          cpsAccount.locationCode === COUNTRY_CODES.USA
                            ? 'a Payment'
                            : 'a Business Pre-Authorized Debit Payment'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      termsAndConditions.showError
                        ? classes.termsAndConitionsErrorMessage
                        : classes.hidden
                    }
                    name="termsAndConditionsErrorMessage"
                  >
                    <img
                      className={classes.imageIcon}
                      src={icnWarning}
                      alt="iconWarning"
                    />{' '}
                    Please agree to Terms and Conditions for Making a Payment.
                  </div>
                </div>
                <Button
                  id="back-button"
                  onClick={goBack}
                  buttonRef={(ref) => {
                    this.backButtonRef = ref;
                  }}
                  variant="outlined"
                  className={classes.backButton}
                  name="backButton"
                >
                  Back, Make Changes
                </Button>
                <Button
                  id="submit-button"
                  type="submit"
                  variant="contained"
                  onClick={this.submitPaymentHandler}
                  className={classes.submitButton}
                  disabled={isSubmitting || paymentDisabled}
                  name="submitButton"
                >
                  {flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT
                    ? `Add ${currency(amount)}`
                    : 'Submit Payment'}
                </Button>
              </div>
            </div>
          </div>
        </FullPage>
        <TermsAndConditionsModal
          toggleTermsAndConditions={this.toggleTermsAndConditions}
          visible={showTermsAndConditions}
          header={
            cpsAccount && cpsAccount.locationCode === COUNTRY_CODES.USA
              ? TC_MAKING_PAYMENT_HEADER_USA
              : TC_MAKING_PAYMENT_HEADER_CANADA
          }
          body={termsAndConditionsResponse.content}
        />
        {isLoading && (
          <PaymentProcessingOverlay processingText="Submitting Payment..." />
        )}
      </div>
    );
  }
}

ReviewPayment.propTypes = {
  paymentStatus: PropTypes.string,
  achAccountType: PropTypes.string,
  amount: PropTypes.number.isRequired,
  bankAccountLastThree: PropTypes.string,
  bankAccountType: PropTypes.string,
  savedPaymentMethod: PropTypes.oneOfType([
    PaymentPropTypes.paymentAccount,
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      tokenValue: PropTypes.string,
    }),
  ]), // eslint-disable-line react/no-typos
  cpsAccount: PaymentPropTypes.cpsAccount.isRequired, // eslint-disable-line react/no-typos
  selectedInvoices: PropTypes.shape({}),
  push: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired,
  setDateInitiated: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  submitCurrentPayment: PropTypes.func.isRequired,
  termsAndConditionsResponse: PropTypes.shape({
    content: PropTypes.string,
    version: PropTypes.string,
  }),
  classes: PropTypes.shape({
    addPaymentFormLabelLarge: PropTypes.string,
    backButton: PropTypes.string,
    hidden: PropTypes.string,
    hover: PropTypes.string,
    imageIcon: PropTypes.string,
    invoiceText: PropTypes.string,
    reviewOverpaymentStatement: PropTypes.string,
    reviewPayment: PropTypes.string,
    reviewPaymentCheckingDetails: PropTypes.string,
    reviewPaymentContainer: PropTypes.string,
    reviewPaymentDetails: PropTypes.string,
    reviewPaymentFooterBottom: PropTypes.string,
    reviewPaymentFooterContainer: PropTypes.string,
    reviewPaymentNotesContainer: PropTypes.string,
    reviewPaymentNotesHeader: PropTypes.string,
    reviewPaymentSelectedAmount: PropTypes.string,
    submitButton: PropTypes.string,
    termsAndConditionsContainer: PropTypes.string,
    termsAndConditionsContainerError: PropTypes.string,
    termsAndConditionsLink: PropTypes.string,
    termsAndConditionsLinkContainer: PropTypes.string,
    termsAndConditionsCheckbox: PropTypes.string,
    termsAndConditionsText: PropTypes.string,
    termsAndConitionsErrorMessage: PropTypes.string,
  }).isRequired,
  clearBankAccount: PropTypes.func.isRequired,
  invoiceData: PropTypes.arrayOf(PropTypes.shape({})),
  discountedAmount: PropTypes.number,
  goBack: PropTypes.func.isRequired,
  paymentError: PropTypes.shape({
    statusCode: PropTypes.number,
    name: PropTypes.string,
    message: PropTypes.string,
    errorType: PropTypes.string,
  }),
  isRecurring: PropTypes.bool.isRequired,
  clearPaymentError: PropTypes.func.isRequired,
  showFormErrors: PropTypes.bool.isRequired,
  scheduledPayment: PropTypes.instanceOf(Date),
  recurringEndDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  flowType: PropTypes.string.isRequired,
  recurringPaymentFrequency: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  flags: PropTypes.shape({ southernOhioDivision: PropTypes.bool }).isRequired,
};

ReviewPayment.defaultProps = {
  paymentError: null,
  paymentStatus: null,
  scheduledPayment: null,
  termsAndConditionsResponse: null,
  savedPaymentMethod: undefined,
  invoiceData: [],
  selectedInvoices: {},
  discountedAmount: null,
  bankAccountLastThree: null,
  achAccountType: null,
  bankAccountType: null,
  recurringPaymentFrequency: null,
};

export default compose(
  withLDConsumer(),
  withStyles(finalStyles)
)(ReviewPayment);
