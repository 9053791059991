const shouldUpdateClick = (
  currentlyUpdating,
  previouslyUpdating,
  hasBeenClicked
) => {
  if (
    currentlyUpdating !== previouslyUpdating &&
    !currentlyUpdating &&
    hasBeenClicked
  ) {
    return true;
  }
  return false;
};

export default shouldUpdateClick;
