import { USERS_FETCH_FAILURE, USERS_FETCH_SUCCESS } from '../actions';

export default (state = [], action) => {
  switch (action.type) {
    case USERS_FETCH_SUCCESS:
      return action.users;
    case USERS_FETCH_FAILURE:
      return [];
    default:
      return state;
  }
};
