// dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

// shared UI
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';
import FormCard from 'shared/ui/MaterialFormCard';
import Form from 'shared/ui/MaterialFormCard/Form';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import MaterialButton from 'shared/ui/RebrandedMaterialButton';

// services
import sendPasswordResetEmail from './sendPasswordResetEmail';

import styles from './styles';

class ForgotPassword extends Component {
  state = {
    email: '',
    formSubmitted: false,
    errorMessage: '',
  };

  setErrorMessage = (message) => {
    this.setState({
      errorMessage: message,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.email === '') {
      this.setErrorMessage('Please Enter Your Email Address');
      return false;
    }

    const username = {
      username: this.state.email.trim(),
    };

    return sendPasswordResetEmail(username)
      .then(() => {
        this.setState({
          formSubmitted: true,
        });
      })
      .catch((err) => {
        if (err.response.data.name === 'UserNotFoundException') {
          this.setState({
            formSubmitted: true,
          });
        } else if (err.response.data.name === 'LimitExceededException') {
          this.setErrorMessage('Too many attempts. Please try again later.');
        } else {
          this.setErrorMessage(
            'Oops! Something went wrong. Please try again later.'
          );
        }
      });
  };

  handleResetForm = () => {
    this.setState({
      email: '',
      formSubmitted: false,
      errorMessage: '',
    });
  };

  handleChange = (e) => {
    if (this.state.errorMessage !== '') {
      this.setErrorMessage('');
    }

    const { target } = e;
    const { value, name } = target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { classes } = this.props;
    let forgotPasswordContent;
    if (this.state.formSubmitted) {
      forgotPasswordContent = (
        <div>
          <h4>We just sent you a reset password email.</h4>
          <p>
            Please check your {this.state.email} email for instructions. You
            will only receive email if there is a valid account associated with
            the email address you entered.
          </p>
          <div className={classes.buttonContainer}>
            <MaterialButton
              id="resend-link-button"
              value="Resend link"
              onClick={this.handleSubmit}
              type="submit"
            />
            <MaterialButton
              id="password-reset-button"
              type="secondary"
              value="Try a different email"
              onClick={this.handleResetForm}
            />
          </div>
          <hr />
          <div className={classes.hrMarginOverride}>
            <h4>Didn 't receive the email?</h4>
            <p>
              If you don’t see our email, check your spam folder or try a
              different email address.
            </p>
          </div>
        </div>
      );
    } else {
      forgotPasswordContent = (
        <div>
          <h4>Please enter your email address.</h4>
          <Form onSubmit={this.handleSubmit}>
            <FormTextField
              name="email"
              title="Email Address"
              value={this.state.email}
              handleChange={this.handleChange}
              description="Enter the email address you used for sign-in on this site."
            />
            <div className={classes.error} id="errorMessage">
              {this.state.errorMessage}
            </div>
            <MaterialButton value="Submit" type="submit" />
          </Form>
        </div>
      );
    }
    return (
      <div>
        <FullPageBackground
          image={bgRegistration}
          className="full-page-background--farmers-pointing"
        />
        <FormCard>
          <div className={classes.title}>
            <h3>Password Reset</h3>
            <Link to="/login" className={classes.link}>
              Know your password?
            </Link>
          </div>
          <hr />
          {forgotPasswordContent}
        </FormCard>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ForgotPassword);
