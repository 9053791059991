import { getEmployeeInfo } from '../employeeService';
import employeeInfoFetchFailure from './employeeInfoFetchFailure';
import employeeInfoFetchSuccess from './employeeInfoFetchSuccess';
import employeeInfoFetching from './employeeInfoFetching';

export default () => (dispatch) => {
  dispatch(employeeInfoFetching());
  return getEmployeeInfo()
    .then((employeeInfo) => dispatch(employeeInfoFetchSuccess(employeeInfo)))
    .catch((err) => dispatch(employeeInfoFetchFailure(err)));
};
