import { combineReducers } from 'redux';
import * as bankAccountActions from './Redux/actions/BankAccountActions';
import * as bankAccountFormActions from './Redux/actions/BankAccountFormActions';
import * as sharedPaymentActions from './Redux/actions/SharedActions';
import * as paymentErrorActions from './Redux/actions/ErrorActions';
import * as BankAccountTransactionActions from './Redux/actions/BankAccountTransactionActions';
import bankAccountReducer from './Redux/reducers/BankAccountReducer';
import bankAccountFormReducer from './Redux/reducers/BankAccountFormReducer';
import errorReducer from './Redux/reducers/ErrorReducer';
import bankAccountTransactionReducer from './Redux/reducers/BankAccountTransactionReducer';
import sharedReducer from './Redux/reducers/SharedReducer';
import MakeAPayment from './PageComponents/MakeAPayment';

const paymentsReducer = combineReducers({
  errorReducer,
  sharedReducer,
  bankAccountReducer,
  bankAccountFormReducer,
  bankAccountTransactionReducer,
});

export {
  MakeAPayment,
  bankAccountActions,
  bankAccountFormActions,
  sharedPaymentActions,
  paymentErrorActions,
  BankAccountTransactionActions,
  paymentsReducer,
};
