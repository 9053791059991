import get from 'lodash/get';
import { accountLocationCodeToCurrency } from '.';
/**
 * Most of the checkout steps are just based on pageviews and
 * don't require additional data from redux.
 *
 * Note: these pageview-triggered events are triggered in the codebase
 *  instead of in GTM configuration to allow for automated testing.
 *
 * @param {Number} stepNumber 1-indexed step in the checkout flow
 * @param {String} expectedPath pageview location that triggers the event
 * @returns {Function} a redux-beacon middleware handler
 */
export const pageviewCheckoutStepFactory = (stepNumber, expectedPath) => (
  pageViewAction,
  { selectedAccount: { locationCode } }
) => {
  const pathName = get(pageViewAction, 'payload.location.pathname');
  if (pathName !== expectedPath) {
    return null;
  }
  return {
    event: `EEC_CheckoutStep${stepNumber}`,
    ecommerce: {
      currencyCode: accountLocationCodeToCurrency(locationCode),
      checkout: { actionField: { step: stepNumber } },
    },
  };
};

export default pageviewCheckoutStepFactory;
