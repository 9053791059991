import { verifySAPAccount } from '../../../../utils/verifySAP';

const getStepperBreadCrumbs = (selectedAccountNumber) => {
  const isSAP = verifySAPAccount(selectedAccountNumber);
  const SAPSteps = [
    {
      id: 'logistics',
      stepperLabel: 'LOGISTICS',
      breadCrumbLabel: 'Logistics',
      url: '/cart/logistics',
    },
    {
      id: 'review',
      stepperLabel: 'REVIEW & SUBMIT',
      breadCrumbLabel: 'Review & Submit',
      url: '/cart/review',
    },
  ];
  const ARSSteps = [
    {
      id: 'services',
      stepperLabel: 'SERVICES',
      breadCrumbLabel: 'Services',
      url: '/cart/services',
    },
    {
      id: 'logistics',
      stepperLabel: 'LOGISTICS',
      breadCrumbLabel: 'Logistics',
      url: '/cart/logistics',
    },
    {
      id: 'review',
      stepperLabel: 'REVIEW & SUBMIT',
      breadCrumbLabel: 'Review & Submit',
      url: '/cart/review',
    },
  ];
  return isSAP ? SAPSteps : ARSSteps;
};

export default getStepperBreadCrumbs;
