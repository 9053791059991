import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBrowserSupport } from 'shared/ui/Header/actions/headerActions';

export const withBrowser = (WrappedComponent) =>
  class wrappedComponent extends Component {
    static propTypes = {
      getBrowserSupport: PropTypes.func.isRequired,
      browser: PropTypes.shape({
        isSupported: PropTypes.bool,
      }).isRequired,
    };

    componentDidMount() {
      if (this.props.browser.isSupported === null) {
        this.props.getBrowserSupport();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

const mapDispatchToProps = {
  getBrowserSupport,
};

const mapStateToProps = (state) => ({
  browser: state.browser,
});

export default (WrappedComponent) =>
  connect(mapStateToProps, mapDispatchToProps)(withBrowser(WrappedComponent));
