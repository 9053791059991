import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  initials: {
    height: '32px',
    width: '32px',
    fontSize: '14px',
    color: '#EEEEEE',
  },
  menuItem: { padding: '1.25em' },
  customerName: { fontWeight: 'bold', color: '#00b455;' },
  companyName: { color: '#464646' },
};

const CustomerSwitcherItem = ({ customer, selected, classes }) => (
  <MenuItem
    className={classes.menuItem}
    selected={selected}
    component={Link}
    to={{ pathname: '/employee/mirroring', search: `?userId=${customer.id}` }}
  >
    <ListItemAvatar className={classes.initials}>
      <Avatar>
        {customer.firstName.charAt(0)}
        {customer.lastName.charAt(0)}
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      classes={{
        primary: classes.customerName,
        secondary: classes.companyName,
      }}
      primary={`${customer.firstName} ${customer.lastName}`}
      secondary={(customer.associatedOrgs || [])
        .map((org) => org.name)
        .join(', ')}
    />
  </MenuItem>
);

CustomerSwitcherItem.defaultProps = { classes: {}, selected: false };

CustomerSwitcherItem.propTypes = {
  customer: PropTypes.shape({}).isRequired,
  selected: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(CustomerSwitcherItem);
