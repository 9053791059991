import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuList,
  MenuItem,
} from 'nutrien-common-components-react/dist/mdc';
import FilterButton from '../FilterButton';
import InvoiceDateRangeModal from '../InvoiceDateRangeModal';
import { filterChoices } from './constants';

const { ALL, NEXT_30_DAYS } = filterChoices;

class DueDateRangeFilter extends Component {
  constructor(props) {
    super(props);
    this.menuAnchor = React.createRef();
  }

  state = {
    isModalOpen: false,
    isMenuOpen: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { isMenuOpen } = this.state;
    const { gtmToggleInvoiceFilter } = this.props;

    if (isMenuOpen !== prevState.isMenuOpen) {
      gtmToggleInvoiceFilter('dueDate', isMenuOpen);
    }
  }

  toggleDatePicker = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { value, onConfirm, onClear } = this.props;
    const { isModalOpen, isMenuOpen } = this.state;
    return (
      <>
        <FilterButton
          onClick={() => this.setState({ isMenuOpen: !isMenuOpen })}
          ref={this.menuAnchor}
          value={value}
          icon="calendar"
        />

        <Menu
          open={isMenuOpen}
          onClose={() => this.setState({ isMenuOpen: false })}
          anchorElement={this.menuAnchor.current}
        >
          <MenuList selectedIndex={0}>
            <MenuItem onClick={() => onConfirm(ALL)}>All</MenuItem>
            <MenuItem onClick={() => onConfirm(NEXT_30_DAYS)}>
              Next 30 Days
            </MenuItem>
            <MenuItem onClick={() => this.setState({ isModalOpen: true })}>
              Custom Date
            </MenuItem>
          </MenuList>
        </Menu>
        <InvoiceDateRangeModal
          onAccept={onConfirm}
          onClear={onClear}
          initialSelection={value}
          open={isModalOpen}
          onClosed={() => {
            this.setState({ isModalOpen: false });
          }}
        />
      </>
    );
  }
}

DueDateRangeFilter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onConfirm: PropTypes.func,
  onClear: PropTypes.func,
  gtmToggleInvoiceFilter: PropTypes.func.isRequired,
};

DueDateRangeFilter.defaultProps = {
  value: undefined,
  onConfirm: () => {},
  onClear: () => {},
};

export default DueDateRangeFilter;
