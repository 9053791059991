// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';
import getAddressFailure from './getAddressFailure';
import getAddressSuccess from './getAddressSuccess';
import updatingAddress from './updatingAddress';

export default (defaultAccountId) => (dispatch) => {
  // TODO: this should be in its own service method
  dispatch(updatingAddress(true));
  return ecommApiService
    .get('/v2/shipping-addresses', {
      headers: {
        'account-id': defaultAccountId,
      },
    })
    .then((res) => {
      dispatch(updatingAddress(false));
      dispatch(getAddressSuccess(res.data.data));
      return res;
    })
    .catch((error) => {
      dispatch(updatingAddress(false));
      dispatch(getAddressFailure(error));
      return error;
    });
};
