import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FormCard from 'shared/ui/MaterialFormCard';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';

import { RolePropType } from '../Redux/RoleDelegation/roleDelegationPropTypes';
import {
  ROLE_DELEGATION_ACCEPTED,
  INVITATION_CONFIRMATION_PENDING,
  CONFIRM_ROLE_INVITATION_SUCCESS,
  CONFIRM_ROLE_INVITATION_FAILURE,
} from '../Redux/RoleDelegation/roleDelegationConstants';

import './RoleAuthorization.css';
import styles from './styles';

class RoleAuthorization extends Component {
  componentDidMount() {
    const { role, confirmRoleInvitation } = this.props;
    if (role.status === INVITATION_CONFIRMATION_PENDING) {
      confirmRoleInvitation(this.props.match.params.confirmationID);
    }
  }

  render() {
    const { classes, acceptRoleDelegation, role } = this.props;
    const pageTitle = 'Role Invitation';

    const acceptRole = (
      <div>
        <div>
          <div className={classes.title}>
            <h3>{pageTitle}</h3>
          </div>
        </div>
        <hr />
        <div className={classes.container}>
          <p className={classes.label}>Administrator Role: Accepted</p>
          <ul className={`role-authorization__ul ${classes.list}`}>
            <li>
              The organization owner may elect to revoke this role at any time.
            </li>
            <li>We will notify the Organization Owner of your decision.</li>
            <li>We will keep you informed of changes affecting your role.</li>
          </ul>
          <p className={classes.label}>Next Step</p>
          <p>
            To access the CXH Portal in your new role, you will be required to
            create a secured login account. Please click{' '}
            <span className={classes.bold}>continue</span> to complete the last
            step.
          </p>
          <div className={classes.buttonContainer}>
            <MaterialButton
              value="Continue"
              onClick={acceptRoleDelegation}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    );

    const invitationNotFound = (
      <div>
        <div>
          <div className={classes.title}>
            <h3>Invalid Role Invitation</h3>
          </div>
        </div>
        <hr />
        <div className={classes.container}>
          <h4>
            This link has expired or is otherwise invalid. Please contact
            customer support for additional assistance.
          </h4>
        </div>
      </div>
    );

    const formContent = () => {
      if (
        role.status === CONFIRM_ROLE_INVITATION_SUCCESS ||
        role.status === ROLE_DELEGATION_ACCEPTED
      ) {
        return acceptRole;
      }
      if (role.status === CONFIRM_ROLE_INVITATION_FAILURE) {
        return invitationNotFound;
      }
      return (
        <div>
          <div className={classes.title}>
            <h3>{pageTitle}</h3>
          </div>
          <hr />
          <MaterialLoadingIcon />
        </div>
      );
    };

    return (
      <div>
        <FullPageBackground
          image={bgRegistration}
          className="full-page-background--farmers-pointing"
        />
        <FormCard>{formContent()}</FormCard>
      </div>
    );
  }
}

RoleAuthorization.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  match: PropTypes.shape({
    params: PropTypes.shape({ confirmationID: PropTypes.string }),
  }).isRequired,
  confirmRoleInvitation: PropTypes.func.isRequired,
  acceptRoleDelegation: PropTypes.func.isRequired,
  role: RolePropType.isRequired,
};

RoleAuthorization.defaultProps = {
  classes: {},
};

export default withStyles(styles)(RoleAuthorization);
