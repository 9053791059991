import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTheme from '@nutrien/uet-react/styles/useTheme';
import useMediaQuery from '@nutrien/uet-react/useMediaQuery';
import PropTypes from 'prop-types';
import Typography from '@nutrien/uet-react/Typography';
import Card from '@nutrien/uet-react/Card';
import Button from '@nutrien/uet-react/Button';
import Chip from '@nutrien/uet-react/Chip';
import MuiLink from '@nutrien/uet-react/Link';
import Table from '@nutrien/uet-react/Table';
import TableHead from '@nutrien/uet-react/TableHead';
import TableCell from '@nutrien/uet-react/TableCell';
import TableRow from '@nutrien/uet-react/TableRow';
import TableBody from '@nutrien/uet-react/TableBody';
import AddSvg from '@nutrien/uet-react/icons/Add';
import KeyboardArrowRightSvg from '@nutrien/uet-react/icons/KeyboardArrowRight';
import BannerImage from 'assets/nutrienFinancialBanner.png';
import SmallBannerImage from 'assets/nutrienFinancialBannerSmall.png';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import { selectSelectedAccount } from 'selectors';
import { selectEmployee } from 'selectors/employeeSelectors';
import { CREDIT_APPLICATION_STATUSES } from 'const/creditApplicationStatuses';
import { startCreditApplication } from 'actions/creditActions';
import { useLocation } from 'react-router-dom';
import { resolveOcaUrl, resolveOcaTemplateId } from '../resolveOcaUrl';
import useStyles from '../styles';

// This shows the status of the most recent credit application on the CreditBanner

const CreditStatus = (props) => {
  const classes = useStyles();
  const {
    creditApplication: {
      status = 'unknown',
      creditApplicationId = null,
      displayProperties = {},
    },
  } = props;
  const { mirroring } = useSelector(selectEmployee());
  const account = useSelector(selectSelectedAccount());
  const ocaUrl = resolveOcaUrl();
  const ocaTemplateId = resolveOcaTemplateId();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const theme = useTheme();
  const aboveMd = useMediaQuery(theme.breakpoints.up('960'));

  const applicantName =
    displayProperties?.applicantName ?? 'Click here for full details.';
  let statusText;
  let statusColor;

  switch (status) {
    case CREDIT_APPLICATION_STATUSES.APPROVED_PENDING_VALIDATION:
    case CREDIT_APPLICATION_STATUSES.APPROVED_VALIDATED:
      statusText = 'Approved';
      statusColor = 'primary';
      break;
    case CREDIT_APPLICATION_STATUSES.SUBMITTED_PENDING:
      statusText = 'Pending';
      statusColor = 'default';
      break;
    case CREDIT_APPLICATION_STATUSES.AWAITING_ADDITIONAL_INFORMATION:
      statusText = 'Action Required';
      statusColor = 'warning';
      break;
    default:
      // Add a space between capital letters
      statusText = status.replace(/([A-Z])/g, ' $1').trim();
      statusColor = 'default';
      break;
  }

  const statusChipText = (
    <Typography variant="inherit" className={classes.statusChipText}>
      {statusText}
    </Typography>
  );

  const detailsLink =
    statusText === 'Approved'
      ? `/credit-application/${creditApplicationId}`
      : `${ocaUrl}?continue=form/${creditApplicationId}`;

  const viewDetails = () => {
    if (mirroring.isMirroring && statusText !== 'Approved') {
      return <></>;
    }

    return (
      <MuiLink
        href={detailsLink}
        {...(statusText !== 'Approved'
          ? { target: '_blank', rel: 'noopener' }
          : {})}
        data-test="view-details-link"
      >
        <Button
          variant="contained"
          className={
            aboveMd
              ? classes.statusButton
              : classes.creditAppDetailsSmallViewDetailsButton
          }
          data-test="view-details-button"
        >
          View Details
        </Button>
      </MuiLink>
    );
  };

  return (
    <Card className={classes.root} data-test="credit-status-card">
      <img
        src={BannerImage}
        alt="Online Credit Application Status Logo"
        className={classes.largeImage}
      />
      <img
        src={SmallBannerImage}
        alt="Online Credit Application Status Logo"
        className={classes.smallImage}
      />
      <div className={classes.textWrapper}>
        <div className={classes.appStatusHeader}>
          <Typography variant="h3">Recent Application Status</Typography>
          <DisabledForEmployees>
            {mirroring.isMirroring ? (
              <Button className={classes.appStatusApplyAgain}>
                <AddSvg className={classes.applyAgainAddIcon} />
                Apply Again
              </Button>
            ) : (
              <MuiLink
                onClick={() => {
                  // track initiate a credit application
                  dispatch(startCreditApplication(account, pathname));
                }}
                href={`${ocaUrl}?continue=form-new/${ocaTemplateId}`}
                target="_blank"
                rel="noopener"
                data-test="credit-status-apply-again-btn"
              >
                <Button className={classes.appStatusApplyAgain}>
                  <AddSvg className={classes.applyAgainAddIcon} />
                  Apply Again
                </Button>
              </MuiLink>
            )}
          </DisabledForEmployees>
        </div>
        {aboveMd ? (
          <Table size="small" className={classes.creditAppTable}>
            <TableHead>
              <TableRow>
                <TableCell>Application Name</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {mirroring.isMirroring ? (
                    <Typography
                      variant="subtitle2"
                      className={classes.creditAppLinkDisabled}
                    >
                      {applicantName}
                    </Typography>
                  ) : (
                    <MuiLink
                      href={detailsLink}
                      {...(statusText !== 'Approved'
                        ? { target: '_blank', rel: 'noopener' }
                        : {})}
                      className={classes.creditAppLink}
                    >
                      {applicantName}
                    </MuiLink>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Chip
                    size="small"
                    className={classes.statusChip}
                    color={statusColor}
                    label={statusChipText}
                  />
                </TableCell>
                <TableCell
                  className={classes.statusButtonTableCell}
                  align="right"
                >
                  {viewDetails()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <>
            <div className={classes.creditAppDetailsSmall}>
              <Typography variant="h5">Application Name</Typography>
              {mirroring.isMirroring ? (
                <Typography
                  variant="subtitle2"
                  className={classes.creditAppDetailsSmallNameDisabled}
                >
                  {applicantName}
                </Typography>
              ) : (
                <MuiLink
                  className={classes.creditAppDetailsSmallName}
                  href={detailsLink}
                  {...(statusText !== 'Approved'
                    ? { target: '_blank', rel: 'noopener' }
                    : {})}
                >
                  {applicantName}
                </MuiLink>
              )}
              <Typography
                variant="h5"
                className={classes.creditAppDetailsSmallStatusLabel}
              >
                Status
              </Typography>
              <Chip
                size="small"
                className={classes.statusChip}
                color={statusColor}
                label={statusChipText}
              />
            </div>
            {viewDetails()}
          </>
        )}
        <DisabledForEmployees>
          {mirroring.isMirroring ? (
            <Button
              className={classes.viewAllAppsButton}
              data-test="view-past-applications-button"
            >
              View All Past Applications
              <KeyboardArrowRightSvg />
            </Button>
          ) : (
            <MuiLink
              href={`${ocaUrl}?continue=list`}
              target="_blank"
              rel="noopener"
            >
              <Button
                className={classes.viewAllAppsButton}
                data-test="view-past-applications-button"
              >
                View All Past Applications
                <KeyboardArrowRightSvg />
              </Button>
            </MuiLink>
          )}
        </DisabledForEmployees>
      </div>
    </Card>
  );
};

CreditStatus.propTypes = {
  creditApplication: PropTypes.shape({
    id: PropTypes.number,
    creditApplicationId: PropTypes.string,
    customerEmail: PropTypes.string,
    status: PropTypes.string,
    url: PropTypes.string,
    applicationProperties: PropTypes.object,
    displayProperties: PropTypes.object,
  }).isRequired,
};

export default CreditStatus;
