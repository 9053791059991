import resolveApiEnv, { DEV, SIT, PRE, PROD } from './resolveApiEnv';

const config = {
  [DEV]: {
    domain: 'auth.dev.nutrienagsolutions.com',
    clientId: 'MsV254VIgy0irtY2itEYs7g3sw5pcxIj',
  },
  [SIT]: {
    domain: 'auth.sit.nutrienagsolutions.com',
    clientId: '754J0s1t23kmtYB0HC7dV9PTjO4gHeEY',
  },
  [PRE]: {
    domain: 'auth.pre.nutrienagsolutions.com',
    clientId: 'SZ6RMcnCrysmR3KHv35ysizb8kdIEMmT',
  },
  [PROD]: {
    domain: 'auth.nutrienagsolutions.com',
    clientId: 'pMBn63ZeoVAz1zsUEIr7L41gqs44dn5R',
  },
};

export default function resolveAuth0Client() {
  const env = resolveApiEnv();
  return config[env];
}
