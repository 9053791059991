import {
  FETCHING_AUTH0_USER_METADATA,
  FETCH_AUTH0_USER_METADATA_SUCCESS,
  FETCH_AUTH0_USER_METADATA_FAILURE,
  UPDATING_AUTH0_USER_METADATA,
  UPDATE_AUTH0_USER_METADATA_SUCCESS,
  UPDATE_AUTH0_USER_METADATA_FAILURE,
} from 'shared/config/userConstants';
import { coreAuthUserService } from 'shared/lib/coreAuth';

export const fetchingUserMetadata = () => ({
  type: FETCHING_AUTH0_USER_METADATA,
});

export const fetchUserMetadataSuccess = (data) => ({
  type: FETCH_AUTH0_USER_METADATA_SUCCESS,
  data,
});

export const fetchUserMetadataFailure = (error) => ({
  type: FETCH_AUTH0_USER_METADATA_FAILURE,
  error,
});

export const updatingUserMetadata = () => ({
  type: UPDATING_AUTH0_USER_METADATA,
});

export const updateUserMetadataSuccess = (data) => ({
  type: UPDATE_AUTH0_USER_METADATA_SUCCESS,
  data,
});

export const updateUserMetadataFailure = (error) => ({
  type: UPDATE_AUTH0_USER_METADATA_FAILURE,
  error,
});

export const fetchAuth0UserMetadata = () => async (dispatch) => {
  dispatch(fetchingUserMetadata());
  try {
    const data = await coreAuthUserService.fetchUserInfo();
    const metadata = coreAuthUserService.composeUserMetadata(
      data.user_metadata
    );
    dispatch(
      fetchUserMetadataSuccess({
        ...metadata,
        ...{ auth0UserMetadataFetched: true },
      })
    );
  } catch (error) {
    dispatch(fetchUserMetadataFailure(error));
  }
};

export const updateAuth0UserMetadata = (userMetadataProps = {}) => async (
  dispatch
) => {
  dispatch(updatingUserMetadata());
  try {
    const data = await coreAuthUserService.updateUserMetadataProps(
      userMetadataProps
    );
    dispatch(updateUserMetadataSuccess(data));
  } catch (error) {
    dispatch(updateUserMetadataFailure(error));
  }
};
