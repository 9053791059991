import React, { Component } from 'react';
import { func, shape, string, number, oneOfType } from 'prop-types';
import Dropdown from 'shared/ui/Dropdown';
import noop from 'lodash/noop';

import './styles.scss';

class ProductTotalVolumeDropdown extends Component {
  constructor(props) {
    super(props);
    const {
      value,
      variant: { total_volume_options: totalVolumeOptions },
    } = this.props;

    const volume = value || totalVolumeOptions[0];
    const selectedVolume = this.formatDropdownOptions(volume);
    this.props.setVariantVolume(selectedVolume.name);

    this.state = {
      selectedVolume,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      variant: { total_volume_options: totalVolumeOptions },
    } = this.props;

    const {
      variant: { total_volume_options: totalVolumeOptionsPrev },
    } = prevProps;
    if (
      JSON.stringify(totalVolumeOptions) !==
      JSON.stringify(totalVolumeOptionsPrev)
    ) {
      const selectedVolume = this.formatDropdownOptions(totalVolumeOptions[0]);
      this.props.setVariantVolume(selectedVolume.name);
      // eslint-disable-next-line
      this.setState({
        selectedVolume,
      });
    }
  }

  formatDropdownOptions = (variant, selectedVolume = null) => ({
    code: `${variant}-variantOption`,
    name: Number(variant),
    selected: variant === selectedVolume,
  });

  constructDropdownOptions = (selectedVolume) =>
    this.props.variant.total_volume_options.map((variant) =>
      this.formatDropdownOptions(variant, selectedVolume.name)
    );

  render() {
    const { selectedVolume } = this.state;
    const { onChange } = this.props;
    const dropDownOptions = this.constructDropdownOptions(selectedVolume);
    return (
      <Dropdown
        data-test="total-volume-input-dropdown"
        options={dropDownOptions}
        selectedValue={selectedVolume}
        shouldHavePlaceHolder={false}
        onChange={(value) => {
          this.setState({
            selectedVolume: value,
          });
          if (onChange) {
            onChange(value.name);
          }
          this.props.setVariantVolume(value.name);
        }}
        validate={noop}
        placeholder="totalVolumeOptions"
        dropdownName="totalVolumeOptions"
      />
    );
  }
}

ProductTotalVolumeDropdown.propTypes = {
  value: oneOfType([string, number]),
  onChange: func,
  variant: shape({}).isRequired,
  setVariantVolume: func.isRequired,
};

ProductTotalVolumeDropdown.defaultProps = {
  value: null,
  onChange: null,
};

export default ProductTotalVolumeDropdown;
