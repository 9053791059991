const stateTransformer = (states) => {
  try {
    return states.length
      ? states.map((state) => ({
          countryId: state.country,
          id: state.id,
          name: state.name,
          intId: state.intId,
        }))
      : [];
  } catch (e) {
    console.error(
      'One or more geographical states were not formatted properly'
    );
    return [];
  }
};

export default stateTransformer;
