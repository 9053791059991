import {
  SET_PRODUCT_LABELS_OK,
  SET_PRODUCT_LABELS_LOADING,
  SET_PRODUCT_LABELS_ERROR,
} from './ProductLabelsActions';

const productLabels = (state = { status: 'LOADING', list: {} }, action) => {
  switch (action.type) {
    case SET_PRODUCT_LABELS_OK:
      return { status: 'OK', list: { ...action.payload } };
    case SET_PRODUCT_LABELS_LOADING:
      return { status: 'LOADING', list: {} };
    case SET_PRODUCT_LABELS_ERROR:
      return { status: 'ERROR', list: {} };
    default:
      return state;
  }
};

export default productLabels;
