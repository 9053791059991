import { connect } from 'react-redux';
import { setNewShelvesForProductHistory } from './ShelfSelectorActions';
import ShelfSelector from './ShelfSelector';
import setPurchaseFilter from '../../../Landing/PurchasesCard/setPurchaseFilters';

const mapDispatchToProps = {
  setNewShelvesForProductHistory,
  setPurchaseFilter,
};

export default connect(null, mapDispatchToProps)(ShelfSelector);
