// TODO: selectors should be updated to derive directly from state.
// therefore, uses of this method should be phased out and replaced with usage of
// getQueryStringFromState
import { parse } from 'qs'; // using qs instead of nodes built in queryString for more robust parsing
/**
 * Selects the search string from connected-react-router's state slice and parses
 * it to an object
 * @param {object} routerState redux slice containing router state
 * @return {object} queryString object
 */
const getQueryStringFromRouter = (routerState) =>
  parse(routerState.location.search.slice(1));

export default getQueryStringFromRouter;
