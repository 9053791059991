import PropTypes from 'prop-types';

export const RbacPropType = PropTypes.shape({
  status: PropTypes.string,
  error: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      message: PropTypes.string,
    }),
    status: PropTypes.number,
  }),
});

export const AddUserFormPropType = PropTypes.shape({
  form: PropTypes.shape({ email: PropTypes.string }),
  formErrors: PropTypes.shape({ email: PropTypes.string }),
});
