export const MixpanelEvents = {
  LOGISTICS_COMPLETE: 'cart logistics complete',
  PAYMENT_INFORMATION: 'payment information',
  PAYMENT_START: 'payment start',
  VIEW_ACCOUNTS: 'view account summary',
  VIEW_PRODUCT_HISTORY: 'view product history',
  SERVICES_COMPLETE: 'cart services complete',
  VIEW_LABEL: 'view product label',
  VIEW_LICENSES: 'view licenses',
  VIEW_MARKET: 'view market page',
  VIEW_PERMIT: 'view single permit',
  VIEW_PERMITS: 'view all permits',
  VIEW_WEATHER: 'view local weather page',
  INITIATE_CREDIT_APPLICATION: 'online credit application initiate',
};

export const MixpanelProperties = {
  ACCOUNT_ID: 'account id',
  ACCOUNT_NAME: 'account name',
  APPLICATION_MEMO: 'application instructions',
  APPLICATION: 'application services',
  BRANCH_ID: 'branch id',
  BRANCH_NAME: 'branch name',
  BRANCH_PICKUP: 'branch pickup',
  CART_ITEMS: 'number of cart items',
  CURRENT_DUE: 'currently due',
  CURRENT_BALANCE: 'current balance',
  DIVISION_NAME: 'division name',
  DOWNLOAD: 'download',
  FIRST_LOGISTICS_PAGEVIEW: 'first /cart/logistics pageview',
  FIRST_MARKET_VIEW: 'fist market view date',
  FIRST_PAYMENT_START: 'first payment start',
  FIRST_PERMIT_VIEW: 'first permit view',
  FIRST_SERVICES_PAGEVIEW: 'first /cart/services pageview',
  FIRST_WEATHER_PAGEVIEW: 'first weather pageview',
  FIRST_ONLINE_CREDIT_APPLICATION_INITIATE:
    'first online credit application initiate',
  LAST_LOGISTICS_PAGEVIEW: 'last /cart/logistics pageview',
  LAST_MARKET_VIEW: 'last market view date',
  LAST_PAYMENT_START: 'last payment start',
  LAST_PERMIT_VIEW: 'last permit view',
  LAST_SERVICES_PAGEVIEW: 'last /cart/services pageview',
  LAST_WEATHER_PAGEVIEW: 'last weather pageview',
  LAST_ONLINE_CREDIT_APPLICATION_INITIATE:
    'last online credit application initiate',
  LICENSE_NAMES: 'license names',
  LICENSE_NUMBERS: 'license numbers',
  LIFETIME_LOGISTIC_PAGEVIEW: 'lifetime /cart/logistics pageviews',
  LIFETIME_MARKET_VIEW: 'life time market views',
  LIFETIME_PAYMENT_START: 'lifetime payment start',
  LIFETIME_PERMIT_VIEW: 'lifetime permit view',
  LIFETIME_SERVICES_PAGEVIEW: 'lifetime /cart/services pageviews',
  LIFETIME_WEATHER_PAGEVIEW: 'lifetime weather pageviews',
  LIFETIME_ONLINE_CREDIT_APPLICATION_INITIATE:
    'lifetime online credit application initiate',
  NOTE_ADDED: 'added note',
  ORDER_MEMO: 'notes',
  PAYMENT_AMOUNT: 'payment amount',
  PAYMENT_METHOD: 'payment method',
  PAYMENT_TYPE: 'payment type',
  PICKUP_DATE: 'pickup date',
  RECUR_END_DATE: 'recurring - end date',
  RECUR_FREQ: 'recurring - frequency',
  RECUR_PAYMENT: 'recurring payment',
  REGION_NAME: 'region name',
  SCHEDULED_DATE: 'scheduled date',
  SHELF: 'shelf',
  SKU: 'sku',
  TIMEFRAME: 'timeframe',
  ISSUED: 'issued by',
  EXPIRATION: 'expiration',
  STATUS: 'status',
  VIA: 'via',
};

export const VIEW_LOGISTICS = 'VIEW_LOGISTICS';
export const VIEW_SERVICES = 'VIEW_SERVICES';
