import { LOCATION_CHANGE } from 'connected-react-router';
import { EVENT_TRACK, GTM_CLICK_NUTRIEN_LOGO } from '../gtmConstants';
import { SET_MARKET_OK } from '../../../../components/Market/MarketActions';
import { VIEW_PRODUCT_HISTORY } from '../../../../components/ProductHistory/ProductHistoryActionTypes';

import {
  MixpanelProperties,
  MixpanelEvents,
} from '../../MixPanel/mixPanelConstants';

export const clickNutrienLogo = () => ({
  event: EVENT_TRACK,
  eventid: 'CXH_Navigation_NutrienLogo',
  category: 'Navigation',
  action: 'Nutrien Logo',
});

export const viewMarket = () => {
  window.mixpanel.track(MixpanelEvents.VIEW_MARKET);
  window.mixpanel.people.set_once({
    [MixpanelProperties.FIRST_MARKET_VIEW]: new Date().toISOString(),
  });

  window.mixpanel.people.set({
    [MixpanelProperties.LAST_MARKET_VIEW]: new Date().toISOString(),
  });

  window.mixpanel.people.increment(MixpanelProperties.LIFETIME_MARKET_VIEW);

  return {
    event: EVENT_TRACK,
    eventid: 'CXH_View_Market',
    'last market view date': new Date().toISOString(),
  };
};

export const viewPermits = () => {
  window.mixpanel.track(MixpanelEvents.VIEW_PERMITS);
  window.mixpanel.people.set_once({
    [MixpanelProperties.FIRST_PERMIT_VIEW]: new Date().toISOString(),
  });

  window.mixpanel.people.set({
    [MixpanelProperties.LAST_PERMIT_VIEW]: new Date().toISOString(),
  });

  window.mixpanel.people.increment(MixpanelProperties.LIFETIME_PERMIT_VIEW);

  return {
    event: EVENT_TRACK,
    eventid: 'CXH_View_Permits',
    'last permits view date': new Date().toISOString(),
  };
};

export const viewProductHistory = (action) => {
  const { start, end, shelf, isDownload } = action;
  const dates = start && end ? `${start} - ${end}` : 'YTD';

  window.mixpanel.track(MixpanelEvents.VIEW_PRODUCT_HISTORY, {
    [MixpanelProperties.TIMEFRAME]: dates,
    [MixpanelProperties.SHELF]: shelf || 'All',
    [MixpanelProperties.DOWNLOAD]: isDownload || false,
  });
  return {
    event: EVENT_TRACK,
    eventid: 'CXH_View_Poduct_History',
    shelf,
    download: isDownload || false,
    'time frame': `${start} - ${end}` || 'YTD',
  };
};

export const viewLicenses = ({ licenses }) => {
  if (licenses.all.length) {
    window.mixpanel.track(MixpanelEvents.VIEW_LICENSES, {
      [MixpanelProperties.LICENSE_NAMES]: licenses.all.map((lic) => lic.name),
      [MixpanelProperties.LICENSE_NUMBERS]: licenses.all.map(
        (lic) => lic.licenseNo
      ),
    });

    return {
      event: EVENT_TRACK,
      eventid: 'CXH_View_Licenses',
      'license names': licenses.all.map((lic) => lic.name),
      'license numbers': licenses.all.map((lic) => lic.licenseNo),
      'last permits view date': new Date().toISOString(),
    };
  }
  return null;
};

export const determinePath = (action, stateIn) => {
  if (window.location.search !== '') {
    switch (window.location.pathname) {
      case '/permits':
        return viewPermits();
      case '/accounts/product-history':
        return viewProductHistory(action);
      case '/licenses':
        return viewLicenses(stateIn);
      default:
        return null;
    }
  }
  return null;
};

export const navigationEventsMap = {
  [GTM_CLICK_NUTRIEN_LOGO]: clickNutrienLogo,
  [SET_MARKET_OK]: viewMarket,
  [LOCATION_CHANGE]: determinePath,
  [VIEW_PRODUCT_HISTORY]: determinePath,
};
