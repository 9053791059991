import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FilterButton from '../FilterButton';
import InvoiceGrossAmountModal from '../InvoiceGrossAmountModal';

class InvoiceGrossAmountFilter extends Component {
  constructor(props) {
    super(props);
    this.menuAnchor = React.createRef();

    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const {
      value,
      onConfirm,
      onClear,
      maxGrossAmount,
      minGrossAmount,
    } = this.props;
    const { isModalOpen } = this.state;

    return (
      <>
        <FilterButton
          onClick={() => this.setState({ isModalOpen: !isModalOpen })}
          ref={this.menuAnchor}
          value={value}
          icon="filter"
        />

        {isModalOpen && (
          <InvoiceGrossAmountModal
            maxGrossAmount={maxGrossAmount}
            minGrossAmount={minGrossAmount}
            onAccept={onConfirm}
            onClear={onClear}
            initialSelection={value}
            open={isModalOpen}
            onClosed={() => {
              this.setState({ isModalOpen: false });
            }}
          />
        )}
      </>
    );
  }
}

InvoiceGrossAmountFilter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onConfirm: PropTypes.func,
  onClear: PropTypes.func,
  maxGrossAmount: PropTypes.number,
  minGrossAmount: PropTypes.number,
};

InvoiceGrossAmountFilter.defaultProps = {
  value: ['', ''],
  onConfirm: () => {},
  onClear: () => {},
  maxGrossAmount: 100000,
  minGrossAmount: 0,
};

export default InvoiceGrossAmountFilter;
