import difference from 'lodash/difference';
import union from 'lodash/union';
import {
  SELECT_NOTIFICATION,
  DESELECT_NOTIFICATION,
  SELECT_ALL_NOTIFICATIONS,
  DESELECT_ALL_NOTIFICATIONS,
  CLEAR_SELECTED_NOTIFICATIONS,
  NOTIFICATIONS_DELETED,
} from '../actions/constants';

const selectedNotifications = (state = [], action) => {
  switch (action.type) {
    case SELECT_NOTIFICATION:
      return [...state, action.notificationId];
    case DESELECT_NOTIFICATION:
      return state.filter((id) => id !== action.notificationId);
    case SELECT_ALL_NOTIFICATIONS:
      return union(state, action.notificationIds);
    case NOTIFICATIONS_DELETED:
    case DESELECT_ALL_NOTIFICATIONS:
      return difference(state, action.notificationIds);
    case CLEAR_SELECTED_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

export default selectedNotifications;
