// rbac registration form
export const RESET_RBAC_REGISTRATION_FORM = 'RESET_RBAC_REGISTRATION_FORM';
export const UPDATE_RBAC_REGISTRATION_FORM = 'UPDATE_RBAC_REGISTRATION_FORM';
export const SET_RBAC_REGISTRATION_FORM_ERRORS =
  'SET_RBAC_REGISTRATION_FORM_ERRORS';
export const CLEAR_RBAC_REGISTRATION_FORM_ERRORS =
  'CLEAR_RBAC_REGISTRATION_FORM_ERRORS';

// confirmation of role invitation
export const INVITATION_CONFIRMATION_PENDING =
  'INVITATION_CONFIRMATION_PENDING';
export const CONFIRMING_ROLE_INVITATION = 'CONFIRMING_ROLE_INVITATION';
export const CONFIRM_ROLE_INVITATION_SUCCESS =
  'CONFIRM_ROLE_INVITATION_SUCCESS';
export const CONFIRM_ROLE_INVITATION_FAILURE =
  'CONFIRM_ROLE_INVITATION_FAILURE';
export const ROLE_DELEGATION_ACCEPTED = 'ROLE_DELEGATION_ACCEPTED';

// rbac registration
export const SUBMITTING_RBAC_REGISTRATION_FORM =
  'SUBMITTING_RBAC_REGISTRATION_FORM';
export const RBAC_REGISTRATION_SUCCESS = 'RBAC_REGISTRATION_SUCCESS';
export const RBAC_REGISTRATION_FAILURE = 'RBAC_REGISTRATION_FAILURE';
