import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import PageHeader from 'shared/ui/PageHeader/PageHeader';
import UserPropType from 'shared/config/userPropType';
import { OpenPdfLink } from 'shared/utils/NutrienGFA';

import './ProductLabels.css';

class ProductLabels extends Component {
  componentDidMount() {
    const number = window.location.search.split('=')[1];
    const { labelId } = this.props.match.params;
    if (number) this.props.fetchProductLabels(labelId, number);
  }

  componentDidUpdate(prevProps) {
    const number = window.location.search.split('=')[1];
    if (
      this.props.user.status === 'USER_FETCHED' &&
      prevProps.user.status === 'FETCHING_USER'
    ) {
      const { labelId } = this.props.match.params;
      if (number) this.props.fetchProductLabels(labelId, number);
    }
  }

  renderTableData = () => {
    const productLabels = this.props.productLabels.list.data.labels;

    return productLabels.map((item) => {
      const detail = item.url.split('pdfs/')[1] || item.url;

      const gtmGfaEventProps = {
        category: 'ProductLabels',
        label: item.url,
        detail,
      };

      return (
        <tr className="product-labels__item" key={item.url}>
          <td>
            <OpenPdfLink link={item.url} analyticsProps={gtmGfaEventProps}>
              {item.url}
            </OpenPdfLink>
          </td>
        </tr>
      );
    });
  };

  renderTable = () => (
    <div>
      <table className="product-labels_table">
        <tbody>{this.renderTableData()}</tbody>
      </table>
    </div>
  );

  renderNoDocuments = () => (
    <div>
      <table className="product-labels_table">
        <tbody>
          <tr className="product-labels__item">
            <td>
              <p>No documents available.</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  renderContent = () => {
    const { status } = this.props.productLabels;
    switch (status) {
      case 'LOADING':
        return <MaterialLoadingIcon />;
      case 'OK':
        return this.renderTable();
      default:
        return this.renderNoDocuments();
    }
  };

  render() {
    return (
      <>
        <PageHeader
          title="Available Documents"
          breadcrumbs={[
            {
              link: '/',
              label: 'Home',
            },
            {
              label: 'Product Labels',
            },
          ]}
        />
        <div className="product-labels__container">{this.renderContent()}</div>
      </>
    );
  }
}

ProductLabels.propTypes = {
  queryString: PropTypes.shape({
    page: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      labelId: PropTypes.string,
    }),
  }).isRequired,
  fetchProductLabels: PropTypes.func.isRequired,
  user: UserPropType.isRequired,
  productLabels: PropTypes.shape({
    status: PropTypes.string,
    list: PropTypes.shape({
      data: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.any),
      }),
    }),
  }).isRequired,
};

export default ProductLabels;
