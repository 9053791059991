import React from 'react';
import PropTypes from 'prop-types';
import roleValidator from 'shared/utils/AuthorizationValidators';

const AuthorizedComponent = ({
  children,
  failureComponent,
  validator,
  roles,
  ...rest
}) => {
  // pass through additional props passed to the parent through to the children.
  const newChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...rest });
    }
    return child;
  });

  return validator(roles) ? newChildren : failureComponent;
};

AuthorizedComponent.propTypes = {
  failureComponent: PropTypes.node,
  children: PropTypes.node,
  validator: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
};

AuthorizedComponent.defaultProps = {
  failureComponent: null,
  children: null,
  validator: roleValidator,
  roles: [],
};

export default AuthorizedComponent;
