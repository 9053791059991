export default {
  INCORRECT_EMAIL_OR_PASSWORD: 'Incorrect email and/or password.',
  GENERAL_LOGIN_PROBLEM:
    'There was a problem logging you in. Please try again later.',
  ENTER_EMAIL_ADDRESS_AND_PASSWORD:
    'Please enter an email address and password.',
  ENTER_VALID_EMAIL_ADDRESS: 'Please enter a valid email address.',
  ONLY_USE_EMPLOYEE_PORTAL:
    'Employee email addresses may only be used on the employee portal.',
};
