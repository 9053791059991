import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateInvoiceFilters } from '../../actions';
import getFilters from '../../selectors/getFilters'; // NOTE: had to use direct import due to odd Babel circular dependency issues
import {
  propType as invoiceFiltersPropType,
  defaultProp as invoiceFiltersDefaultProp,
} from '../../propTypes/invoiceFiltersPropType';

/**
 * Higher Order Component (HoC) that provides a component with invoice filter actions and store data.
 * @param  {Component} WrappedComponent Component to provide invoiceFilter data to.
 * @return {Component}                  The enhanced component.
 */
export const withInvoiceFilters = (WrappedComponent) => {
  const WithInvoiceFilters = ({
    updateInvoiceFilters, // eslint-disable-line no-shadow
    invoiceFilters,
    ...props
  }) => (
    <WrappedComponent
      {...props}
      updateInvoiceFilters={updateInvoiceFilters}
      invoiceFilters={invoiceFilters}
    />
  );

  WithInvoiceFilters.propTypes = {
    updateInvoiceFilters: PropTypes.func,
    invoiceFilters: invoiceFiltersPropType,
  };

  WithInvoiceFilters.defaultProps = {
    updateInvoiceFilters: () => {},
    invoiceFilters: invoiceFiltersDefaultProp,
  };

  return WithInvoiceFilters;
};

export const mapStateToProps = (state) => ({
  invoiceFilters: getFilters(state),
});

export const mapDispatchToProps = {
  updateInvoiceFilters,
};

export default (WrappedComponent) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withInvoiceFilters(WrappedComponent));
