import { string, object } from 'yup';
import { MixpanelEvents, MixpanelProperties } from '../mixPanelConstants';

export const mixpanelPaymentFlowStart = (selectedAccount) => {
  const schema = object().shape({
    branchId: string().required(),
    branchName: string().required(),
    id: string().required(),
    nickname: string(),
  });

  try {
    schema.validateSync(selectedAccount);

    window.mixpanel.track(MixpanelEvents.PAYMENT_START, {
      [MixpanelProperties.BRANCH_ID]: selectedAccount.branchId,
      [MixpanelProperties.BRANCH_NAME]: selectedAccount.branchName,
      [MixpanelProperties.ACCOUNT_ID]: selectedAccount.id,
      [MixpanelProperties.ACCOUNT_NAME]: selectedAccount.name,
    });

    window.mixpanel.people.set_once({
      [MixpanelProperties.FIRST_PAYMENT_START]: new Date().toISOString(),
    });

    window.mixpanel.people.set({
      [MixpanelProperties.LAST_PAYMENT_START]: new Date().toISOString(),
    });

    window.mixpanel.people.increment(MixpanelProperties.LIFETIME_PAYMENT_START);
  } catch (error) {
    throw new Error(error);
  }
};

export const mixpanelPaymentFlowComplete = ({ selectedAccount, payments }) => {
  const {
    bankAccountFormReducer: {
      form: {
        isRecurring,
        notes,
        recurringEndDate,
        recurringPaymentFrequency,
        scheduledPayment,
        achAccountType,
        bankAccountType,
      },
    },
    bankAccountTransactionReducer: { amountEntered },
  } = payments;

  window.mixpanel.track(MixpanelEvents.PAYMENT_INFORMATION, {
    [MixpanelProperties.ACCOUNT_ID]: selectedAccount.id,
    [MixpanelProperties.ACCOUNT_NAME]: selectedAccount.name,
    [MixpanelProperties.BRANCH_ID]: selectedAccount.branchId,
    [MixpanelProperties.BRANCH_NAME]: selectedAccount.branchName,
    [MixpanelProperties.NOTE_ADDED]: !!notes.length,
    [MixpanelProperties.PAYMENT_AMOUNT]: amountEntered,
    [MixpanelProperties.PAYMENT_METHOD]: bankAccountType,
    [MixpanelProperties.PAYMENT_TYPE]: achAccountType,
    [MixpanelProperties.RECUR_END_DATE]: recurringEndDate,
    [MixpanelProperties.RECUR_FREQ]: recurringPaymentFrequency?.code,
    [MixpanelProperties.RECUR_PAYMENT]: isRecurring,
    [MixpanelProperties.SCHEDULED_DATE]:
      scheduledPayment || new Date().toISOString(),
  });

  window.mixpanel.people.set_once({
    [MixpanelProperties.FIRST_PAYMENT_START]: new Date().toISOString(),
  });

  window.mixpanel.people.set({
    [MixpanelProperties.LAST_PAYMENT_START]: new Date().toISOString(),
  });

  window.mixpanel.people.increment(MixpanelProperties.LIFETIME_PAYMENT_START);
};
