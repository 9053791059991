export const FIRST_NAME_REQUIRED = 'Your first name is required';
export const LAST_NAME_REQUIRED = 'Your last name is required';
export const STREET_ADDRESS_REQUIRED = 'Your street address is required';
export const CITY_REQUIRED = 'Your city is required';
export const ZIP_REQUIRED = 'Your zip code is required';
export const ZIP_NOT_VALID = 'Please enter a valid zip code';
export const POSTAL_CODE_REQUIRED = 'Your postal code is required';
export const POSTAL_CODE_NOT_VALID = 'Please enter a valid postal code';
export const COUNTY_REQUIRED = 'Your county is required';
export const NICKNAME_REQUIRED = 'Your account nickname is required';
export const STATE_REQUIRED = 'Your state is required';
export const PROVINCE_REQUIRED = 'Your province is required';
