/* eslint-disable no-useless-escape */
/*
 * This regex is used to check if the value is already in one of the currency formats.
 * It checks that whole numbers are properly split by commas, that the number has 2 decimal places and that
 * it follows our currency format.
 * Valid Formats are:
 * Positive Value: $1,000.00
 * Negative Value: -$1,000.00
 * Credit Value: ($1,000.00)
 */
export const isCurrency = (value) =>
  new RegExp(
    '^\\$[0-9]{1,3}(?:,?[0-9]{3})*\\.[0-9]{2}|^\\(\\$[0-9]{1,3}(?:,?[0-9]{3})*\\.[0-9]{2}\\)|^\\-\\$[0-9]{1,3}(?:,?[0-9]{3})*\\.[0-9]{2}'
  ).test(value);

const currency = (number = 0.0, type = '', cents = true) => {
  if (isCurrency(number)) {
    return number;
  }

  let currencyType;
  let isNegative = false;
  if (type === '') {
    currencyType = number < 0 ? 'credit' : type;
  } else if (type === 'negative') {
    isNegative = new RegExp('[-]+.*').test(number);
    currencyType = 'negative';
  } else {
    currencyType = 'default';
  }

  let floatedNumber;
  if (number) {
    floatedNumber = number;
  } else {
    floatedNumber = 0;
  }
  if (cents) {
    if (typeof number === 'number') {
      floatedNumber = Math.abs(number).toFixed(2);
    }
  }

  switch (currencyType) {
    case 'credit':
      return `($${floatedNumber
        .toString()
        .replace('-', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`;
    case 'negative':
      return isNegative
        ? `-$${floatedNumber
            .toString()
            .replace('-', '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : `$${floatedNumber
            .toString()
            .replace('-', '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    default:
      return `$${floatedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
};

export default currency;
