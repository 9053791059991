import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { EMPTY_MESSAGE } from '../../../../../shared/constants/ecommMessageText';

const PoNumber = (props) => {
  const { cart } = props;

  const thePoNumber = get(
    cart,
    'postOrder.data.data.attributes.poNumber',
    null
  );

  return (
    <div className="detail-wrapper">
      <div className="detail-name">Purchase Order #</div>
      <div
        className="po-section__header--detail jsProductOrderNumber"
        data-test="po-number"
      >
        {thePoNumber || EMPTY_MESSAGE}
      </div>
    </div>
  );
};

PoNumber.propTypes = {
  cart: PropTypes.shape({
    submittedCartData: PropTypes.array.isRequired,
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        error: PropTypes.string,
      }),
    }).isRequired,
    branchAddress: PropTypes.shape({
      address: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default PoNumber;
