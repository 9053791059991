import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { isCanada } from 'shared/utils/CountryCode/isCanada';
import { connect } from 'react-redux';
import { USA } from '../../../../components/Shop/utils/contexts/locationCodeConstants';

const DEFAULT_LOCATION_CODE = USA;

/*
  Assumes SET_DEFAULT_ACCOUNT action has been dispatched. The enhanced component will pass loading = false
  once the default account info has been loaded.
*/
const withLocationCode = (WrappedComponent) => {
  const EnhancedComponent = ({ selectedAccount, ...rest }) => {
    const locationCode = get(
      selectedAccount,
      'locationCode',
      DEFAULT_LOCATION_CODE
    );
    return (
      <WrappedComponent
        locationCode={locationCode}
        /*
        For now Canada is the only location that has different business logic,
        so we pass this boolean for convenience. In the future if we had
        different logic for 3+ countries, we would just switch on the locationCode.
      */
        isCanada={isCanada(locationCode)}
        loading={!selectedAccount}
        selectedAccount={selectedAccount}
        {...rest}
      />
    );
  };

  EnhancedComponent.propTypes = {
    selectedAccount: PropTypes.shape({ locationCode: PropTypes.string }),
    accounts: PropTypes.arrayOf(PropTypes.shape({})),
  };

  EnhancedComponent.defaultProps = {
    selectedAccount: undefined,
    accounts: [],
  };

  return EnhancedComponent;
};

const mapStateToProps = ({ selectedAccount, accounts }) => ({
  selectedAccount,
  accounts: accounts.list,
});

export { withLocationCode };

export default (WrappedComponent) =>
  connect(mapStateToProps)(withLocationCode(WrappedComponent));
