import React from 'react';
import PropTypes from 'prop-types';
import checkImg from 'assets/bank-check.svg';
import Button from '@nutrien/uet-react/Button';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const NoPaymentAccountsComponent = ({ classes, updatePaymentForm, push }) => (
  <div className={classes.noAccountsContainer}>
    <div className={classes.contentContainer}>
      <img src={checkImg} alt="bank check" />
      <div className={classes.content}>
        <div>You have no bank accounts.</div>
        <div>Add a bank account to make the payment process</div>
        <div>faster and easier for you.</div>
      </div>
      <Button
        className={classes.submitButton}
        onClick={() => {
          updatePaymentForm({ cpsAccount: null });
          push({
            pathname: '/add-bank-account',
          });
        }}
      >
        Add New Bank Account
      </Button>
    </div>
  </div>
);

NoPaymentAccountsComponent.propTypes = {
  classes: PropTypes.shape({
    noAccountsContainer: PropTypes.string,
    managePaymentHeader: PropTypes.string,
    contentContainer: PropTypes.string,
    content: PropTypes.string,
    submitButton: PropTypes.string,
  }).isRequired,
  updatePaymentForm: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

export default withStyles(styles)(NoPaymentAccountsComponent);
