import React from 'react';
import { DateFormatter } from 'shared/ui';
import {
  Card,
  CardContent,
} from 'nutrien-common-components-react/dist/mdc/Card';
import { invoicePropType } from '../invoicePropTypes';
import { hasDiscountTerms } from './invoiceCardUtils';

const InvoicedDateCard = ({ invoice }) => (
  <Card className="invoice-card">
    <CardContent className="invoice-card__content">
      <p className="invoice-card__subtitle">Invoiced Date</p>
      <h1 className="invoice-card__title" data-test="invoiced-date-card-title">
        {DateFormatter(invoice.date)}
      </h1>
      {hasDiscountTerms(invoice) && (
        <p
          className="invoice-card__subtitle"
          data-test="invoiced-date-card-due-date"
        >
          Due Date: {DateFormatter(invoice.dueDate)}
        </p>
      )}
    </CardContent>
  </Card>
);

InvoicedDateCard.propTypes = {
  invoice: invoicePropType.isRequired,
};

export default InvoicedDateCard;
