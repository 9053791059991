import get from 'lodash/get';
import {
  cartItemToProductFieldObject,
  accountLocationCodeToCurrency,
} from '../utils';
import {
  GTM_ADD_TO_CART,
  GTM_REMOVE_FROM_CART,
  GTM_UPDATING_CART,
} from '../gtmConstants';

/**
 * In the case of cart updates,
 *    quantityAdded is the amount of item added to the cart
 *    cartItem.quantity is the final quantity after the update.
 * We want to send along the *difference in quantity*,
 *
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce#cart
 * @param {object} action.payload.cartItem - Line item from an order
 * @param {string} action.payload.origin - String identifier that says where the user added to cart from
 * @param {number} [action.payload.quantityAdded] - used when updating a cart item instead of adding a new one
 * @returns {object}
 */
export const addToCart = (action, { selectedAccount: { locationCode } }) => {
  const { cartItem, quantityAdded, origin } = action.payload;
  const productObject = cartItemToProductFieldObject(cartItem);

  if (quantityAdded === 0) {
    // no point recording this event if nothing was added
    return null;
  }

  if (quantityAdded) {
    productObject.quantity = quantityAdded;
  }

  if (origin === 'Product Detail Page') {
    return window.dataLayer.push({
      event: 'eec_add',
      eventId: 'CXH_ProductDetailPage_AddtoCart',
      category: 'Product Detail Page',
      action: 'Add To Cart',
      ecommerce: {
        currencyCode: accountLocationCodeToCurrency(locationCode),
        add: {
          actionField: {
            list: origin,
          },
          products: [productObject],
        },
      },
    });
  }
  return window.dataLayer.push({
    event: 'eec_add',
    eventId: 'CXH_ProductCatalog_QuickAdd',
    category: 'Product Catalog',
    action: 'Quick Add',
    ecommerce: {
      currencyCode: accountLocationCodeToCurrency(locationCode),
      add: {
        actionField: {
          list: origin,
        },
        products: [productObject],
      },
    },
  });
};

/**
 * In the case of cart updates,
 *    quantityRemoved is the amount of item removed from the cart
 *    cartItem.quantity is the final quantity after the update.
 * We want to send along the *difference in quantity*,
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce#cart
 */
export const removeFromCart = (
  action,
  { selectedAccount: { locationCode } }
) => {
  const {
    payload: { cartItem },
    quantityRemoved,
  } = action;

  const productObject = cartItemToProductFieldObject(cartItem);
  if (quantityRemoved === 0) {
    // No point recording this event if nothing was removed
    return null;
  }
  if (quantityRemoved) {
    productObject.quantity = quantityRemoved;
  }

  return {
    event: 'EEC_RemoveFromCart',
    ecommerce: {
      currencyCode: accountLocationCodeToCurrency(locationCode),
      remove: {
        actionField: {},
        products: [productObject],
      },
    },
  };
};

/**
 * Event tracking happens before the http request instead of after (UPDATE_CART_SUCCESS)
 * because we want to avoid race conditions
 */
export const updatingCart = (
  { payload: { newItemData, itemPreUpdate, options } },
  state
) => {
  const origin = get(options, 'origin');
  const newQuantity = parseInt(
    get(newItemData, 'quantity')
      ? get(newItemData, 'quantity')
      : get(newItemData, 'totalVolume'),
    10
  );

  const oldQuantity = parseInt(
    get(itemPreUpdate, 'quantity')
      ? get(itemPreUpdate, 'quantity')
      : get(itemPreUpdate, 'total_volume'),
    10
  );

  // create the expected version of how the item will be after the PUT update
  const assumedNewItem = { ...itemPreUpdate, ...newItemData };
  if (newQuantity > oldQuantity) {
    // eslint-disable-next-line no-use-before-define
    return exportDefault.addToCart(
      {
        payload: {
          cartItem: assumedNewItem,
          quantityAdded: newQuantity - oldQuantity,
          origin,
        },
      },
      state
    );
  }

  if (newQuantity < oldQuantity) {
    return removeFromCart(
      {
        payload: { cartItem: assumedNewItem },
        quantityRemoved: oldQuantity - newQuantity,
      },
      state
    );
  }

  // Quantity hasn't changed. Theoretically should never happen here.
  // Don't send an event.
  return null;
};

export const cartEventsMap = {
  [GTM_ADD_TO_CART]: addToCart,
  [GTM_REMOVE_FROM_CART]: removeFromCart,
  [GTM_UPDATING_CART]: updatingCart,
};

const exportDefault = {
  addToCart,
};

export default exportDefault;
