const styles = {
  description: {
    fontSize: 13,
    fontWeight: 200,
    lineHeight: '1.15',
    color: '#828282',
    margin: '20px 10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    textAlign: 'center',
    padding: '0px 26px',
    '& button': {
      margin: '40px 0px 25px',
    },
  },
  closeModal: {
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
};

export default styles;
