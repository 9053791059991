import store from 'store';

const mirroringInterceptor = (config) => {
  const { mirroredUser } = store.getState().employee.mirroring;
  const skippedPaths = /v1\/(employee|user\/login)/;
  if (skippedPaths.test(config.url)) return config;
  if (mirroredUser) {
    return {
      ...config,
      headers: { ...config.headers, 'mirrored-user': mirroredUser.id },
    };
  }
  return config;
};

export default mirroringInterceptor;
