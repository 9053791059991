import { Card, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Tab from '@nutrien/uet-react/Tab';
import Tabs from '@nutrien/uet-react/Tabs';
import Hero from 'shared/ui/Hero';

import EmployeeBackgroundImage from './EmployeeBackgroundImage';
import GeneralExperience from './GeneralExperience';
import SupportExperience from './SupportExperience';
import TabPanel from './TabPanel';
import './EmployeeLandingPage.css';

const EmployeeLandingPage = ({
  toggleCustomerView,
  // TODO: determine if salesRep only shows customer lookup
  // eslint-disable-next-line no-unused-vars
  salesRep,
  supportRep,
  supportRepImpersonatingSalesRep,
}) => {
  const [activeValue, setActiveValue] = useState(0);

  const handleChange = (_, newValue) => {
    setActiveValue(newValue);
  };

  const showSupport = supportRep || supportRepImpersonatingSalesRep;

  return (
    <div className="landing-container">
      <Hero className="employee-landing__hero" textLineTwo="Employee Portal" />
      <EmployeeBackgroundImage />
      <Card className="customer-list">
        <CardContent>
          <Tabs value={activeValue} onChange={handleChange}>
            <Tab
              onClick={() => toggleCustomerView({ showCustomers: true })}
              label="My Customers"
            />
            {showSupport && (
              <Tab
                onClick={() => toggleCustomerView({ showCustomers: false })}
                label="Customer Lookup"
              />
            )}
          </Tabs>
          <TabPanel value={activeValue} index={0}>
            <GeneralExperience />
          </TabPanel>
          {showSupport && (
            <TabPanel value={activeValue} index={1}>
              <SupportExperience />
            </TabPanel>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

EmployeeLandingPage.propTypes = {
  toggleCustomerView: PropTypes.func.isRequired,
  salesRep: PropTypes.bool.isRequired,
  supportRep: PropTypes.bool.isRequired,
  supportRepImpersonatingSalesRep: PropTypes.bool.isRequired,
};

export default EmployeeLandingPage;
