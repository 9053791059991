import React from 'react';
import Typography from '@material-ui/core/Typography';

const NoLicensesMessage = () => (
  <Typography align="center" variant="body2">
    There are No Licenses at This Time
  </Typography>
);

export default NoLicensesMessage;
