import React from 'react';
import { useTerms, useTermsAccepted, useTermsActions } from '../useTerms';

export default (WrappedComponent) => (props) => {
  const terms = useTerms();
  const termsAccepted = useTermsAccepted();
  const { fetchTerms, acceptTerms, declineTerms } = useTermsActions();

  return (
    <WrappedComponent
      {...{
        ...props,
        terms,
        termsAccepted,
        fetchTerms,
        acceptTerms,
        declineTerms,
      }}
    />
  );
};
