import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { getLocalAccessToken } from '@nutrien/fe-domain-utils';

import { selectEmployee } from 'selectors';
import jwtDecode from 'jwt-decode';
import { clearCustomers, fetchCustomers } from '../Customers/actions';
import EmployeeLandingPage from './EmployeeLandingPage';
import { endMirroring } from '../Mirroring/actions';

export const EmployeeLandingPageContainer = () => {
  const employee = useSelector(selectEmployee());
  const isSupport = get(employee, 'attributes.support');
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const authToken = getLocalAccessToken();
    const decryptedToken = jwtDecode(authToken);
    setToken(decryptedToken);
    dispatch(endMirroring());
  }, [dispatch]);

  // support === true and provider === adfs -> customer lookup is shown
  const supportRep = isSupport && token && token.provider === 'adfs';
  // support === false and provider === fsr -> my customers is shown
  const salesRep = !isSupport && token && token.provider === 'fsr';
  // support === true and provider === fsr -> both tabs should be available
  const supportRepImpersonatingSalesRep =
    isSupport && token && token.provider === 'fsr';

  const toggleCustomerView = ({ showCustomers }) =>
    showCustomers ? dispatch(fetchCustomers()) : dispatch(clearCustomers());

  return (
    token && (
      <EmployeeLandingPage
        toggleCustomerView={toggleCustomerView}
        supportRep={supportRep}
        salesRep={salesRep}
        supportRepImpersonatingSalesRep={supportRepImpersonatingSalesRep}
      />
    )
  );
};

export default EmployeeLandingPageContainer;
