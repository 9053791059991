const styles = {
  container: {
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 'semi-bold',
    margin: '10px 0px',
  },
  title: {
    color: '#828282',
    marginLeft: 2,
  },
  link: {
    color: '#50a200',
  },
};

export default styles;
