import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import get from 'lodash/get';

import { HEADER_LOGO } from 'const/logo';
import formatTodaysDate from 'shared/utils/DateHelper/formatTodaysDate';
import handlePrint from '../../../../utils/handlePrint';

import ShoppingCartItems from './ShoppingCartItems';
import BranchDetails from './BranchDetails';
import CropConsultant from './CropConsultant';
import OrderMemo from './OrderMemo';
import Services from './Services';
import Logistics from './Logistics';
import PoNumber from './PoNumber';
import DeliveryDate from './DeliveryDate';
import ThankYouFooter from './ThankYouFooter';

import './ThankYouPage.scss';

export class ThankYouPageARS extends Component {
  componentDidMount() {
    const {
      history,
      location: { originatedFromCart },
    } = this.props;

    if (!originatedFromCart) {
      history.push('/cart');
    }
  }

  render() {
    const { cart } = this.props;
    const {
      postOrder: { loading, error },
    } = cart;
    const customerPickup = get(
      cart,
      'postOrder.data.data.attributes.customerPickup',
      false
    );

    const theOrderNumber = get(
      cart,
      'postOrder.data.data.attributes.orderNumber',
      ''
    );

    const {
      id: accountId,
      name: accountName,
      cropConsultant,
    } = this.props.selectedAccount;

    let section;

    // cart is loading
    if (loading) {
      section = (
        <div data-test="loading-wrapper">
          <MaterialLoadingIcon />
        </div>
      );
    }
    // there has been a network error
    else if (error) {
      section = (
        <ApiErrorMessage entityName="The cart" data-test="thank-you-error" />
      );
    } else {
      section = (
        <section
          className="thank-you-page-wrapper"
          data-test="thank-you-section"
        >
          <div className="main-page-wrapper">
            <h1 className="thank-you-page__headline">Thank you!</h1>
            <h2 className="thank-you-page__headline" data-test="order-number">
              {theOrderNumber
                ? `Order request #${theOrderNumber} has been submitted`
                : `Your Order request has been submitted`}
            </h2>
            <p className="thank-you-page__blurb">
              Now that you have submitted your order request, your Nutrien
              branch may contact you.
            </p>
            <div className="button-wrapper">
              <LocalPrintshopIcon className="button--print" />
              <button className="button--confirm" onClick={handlePrint}>
                Print Order Request Details
              </button>
            </div>
          </div>
          <div id="printSection" className="print-section-wrapper">
            <div className="print-section-header">
              <div className="print-section-header__left">
                <div className="print-section-header__logo">
                  <img
                    src={HEADER_LOGO}
                    alt="Nutrien Ag Solutions Digital Hub"
                  />
                </div>
                {theOrderNumber ? (
                  <h2>Order Request # {theOrderNumber}</h2>
                ) : null}
              </div>
              <div className="print-section-header__right">
                <CropConsultant cropConsultant={cropConsultant} />
              </div>
            </div>
            <div className="print-section-details">
              <div className="detail-wrapper">
                <div className="detail-name">Submitted On</div>
                <div className="detail-text">
                  {formatTodaysDate('MMMM D, YYYY')}
                </div>
              </div>
              <div className="detail-wrapper">
                <div className="detail-name">Account Name</div>
                <div className="detail-text">{accountName}</div>
              </div>
              <div className="detail-wrapper">
                <div className="detail-name">Account #</div>
                <div className="detail-text">{accountId}</div>
              </div>
              <PoNumber cart={cart} />
            </div>
            <ShoppingCartItems cart={cart} />
            <div className="print-section-bottom">
              <div>
                <div className="print-section--column">
                  <Services cart={cart} />
                </div>
                <div className="print-section--column">
                  <h2>Delivery Logistics</h2>
                  <div className="inner-print-section">
                    <div id="delivery-details">
                      <div className="delivery-details__header">
                        <span className="delivery-details__checkmark" />
                        {customerPickup
                          ? 'Pick-up at branch'
                          : 'Ship to customer'}
                      </div>
                      {customerPickup ? (
                        <BranchDetails cart={cart} />
                      ) : (
                        <Logistics cart={cart} />
                      )}
                      <DeliveryDate cart={cart} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="print-section-bottom--sec-row">
                <div className="print-section--column">
                  <OrderMemo cart={cart} />
                </div>
                <div className="print-section--column">
                  <ThankYouFooter />
                </div>
              </div>
            </div>
          </div>
          <div className="print-section-support">
            <div>For additional support, please contact:</div>
            <div className="print-section-support__email">
              digital.support@nutrien.com
            </div>
          </div>
        </section>
      );
    }
    return <>{section}</>;
  }
}

ThankYouPageARS.propTypes = {
  location: PropTypes.shape({
    originatedFromCart: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  cart: PropTypes.shape({
    submittedCartData: PropTypes.array.isRequired,
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        error: PropTypes.string,
      }),
    }).isRequired,
    postOrder: PropTypes.shape({
      error: PropTypes.string,
      loading: PropTypes.bool,
    }),
  }).isRequired,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    cropConsultant: PropTypes.shape({}),
  }).isRequired,
};

export default ThankYouPageARS;
