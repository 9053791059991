import {
  SET_TOP_PRODUCTS_OK,
  SET_TOP_PRODUCTS_LOADING,
  SET_TOP_PRODUCTS_ERROR,
  SET_TOP_PRODUCTS_ADDING_TO_CART,
  SET_TOP_PRODUCTS_EMPTY,
} from './TopProductsActions';
import { addToCart } from '../../Cart/actions/cartActions';

export function setTopProductsOk(topProducts) {
  return { type: SET_TOP_PRODUCTS_OK, payload: topProducts };
}

export function setTopProductsLoading() {
  return { type: SET_TOP_PRODUCTS_LOADING };
}

export function setTopProductsError() {
  return { type: SET_TOP_PRODUCTS_ERROR };
}

export function setTopProductsEmpty() {
  return { type: SET_TOP_PRODUCTS_EMPTY };
}

export const setTopProductsAddingToCart = (product, userId, cartData) => (
  dispatch
) => {
  const cartItem = {
    accountID: userId,
    attributes: { name: product.description },
    variant: {
      ars_key: product.productNumber.toString(),
      selectedVolume: product.packSize,
    },
  };

  dispatch(addToCart(cartItem, cartData, { origin: 'Top Products' }));
  return {
    type: SET_TOP_PRODUCTS_ADDING_TO_CART,
    payload: cartItem,
  };
};
