const styles = {
  root: {
    width: 500,
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
    padding: 18,
    color: '#222222',
    position: 'relative',
    '& h4': {
      margin: '15px 0px',
    },
    '& h3': {
      marginBottom: 18,
      color: '#222222',
      display: 'inline-block',
    },
    '& hr': {
      margin: '0 0 35px',
    },
  },
};

export default styles;
