import {
  SET_ACCOUNT_ACTIVITY,
  SET_ACCOUNTS,
  SET_ACCOUNT_TAB,
  SET_STATEMENT_SUMMARIES,
  SET_OPEN_ITEMS,
  SET_ASSOCIATED_ORGS,
} from 'const/account';

export const accounts = (
  state = { status: 'LOADING', list: [] },
  action = {}
) => {
  if (action.type === SET_ACCOUNTS) {
    return { ...action.payload };
  }
  return state;
};

// eslint-disable-next-line camelcase
export const accountActivity = (
  state = { status: 'LOADING', list: [] },
  action = {}
) => {
  if (action.type === SET_ACCOUNT_ACTIVITY) return { ...action.payload };
  return state;
};

// eslint-disable-next-line camelcase
export const accountTab = (state = {}, action = {}) => {
  return action.type === SET_ACCOUNT_TAB ? action.payload : state;
};

export const statementSummaries = (
  state = { status: 'LOADING', list: [] },
  action
) => {
  if (action.type === SET_STATEMENT_SUMMARIES) return { ...action.payload };
  return state;
};

export const openItems = (state = { status: 'LOADING', list: [] }, action) => {
  if (action.type === SET_OPEN_ITEMS) return { ...action.payload };
  return state;
};

export const associatedOrgs = (state = [], action) => {
  if (action.type === SET_ASSOCIATED_ORGS) return [...action.payload];
  return state;
};
