import React from 'react';
import AccountsCard from '../AccountsCard';

const Accounts = () => (
  <div className="accounts__page">
    <AccountsCard renderBreadcrumbs />
  </div>
);

export default Accounts;
