import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  propType as invoiceFiltersPropType,
  defaultProp as invoiceFiltersDefaultProp,
} from '../../propTypes/invoiceFiltersPropType';
import InvoiceAmountDueFilter from './InvoiceAmountDueFilter';
import { sanitizeAmountRange } from '../shared/utils/filterHelpers';
import withInvoiceFilters from '../withInvoiceFilters';

const filterKey = 'amountDue';
const defaultValue = ['', ''];

export class InvoiceAmountDueFilterContainer extends Component {
  handleConfirmAmountFilter = (amountRange) => {
    let sanitizedAmountRange = sanitizeAmountRange(amountRange);
    const isEmpty = sanitizedAmountRange.every((amount) => amount === '');
    if (isEmpty) sanitizedAmountRange = []; // need empty array for filtering

    this.props.updateInvoiceFilters({
      [filterKey]: sanitizedAmountRange,
    });
  };

  handleClearAmountFilter = () => {
    this.props.updateInvoiceFilters({
      [filterKey]: [],
    });
  };

  render() {
    const { invoiceFilters, maxAmountDue, minAmountDue } = this.props;
    let value = invoiceFilters[filterKey] || [];
    if (value.length === 0) value = defaultValue;

    return (
      <InvoiceAmountDueFilter
        value={value}
        onConfirm={this.handleConfirmAmountFilter}
        onClear={this.handleClearAmountFilter}
        data-test="amountDueModal"
        maxAmountDue={maxAmountDue}
        minAmountDue={minAmountDue}
      />
    );
  }
}

InvoiceAmountDueFilterContainer.propTypes = {
  invoiceFilters: invoiceFiltersPropType,
  updateInvoiceFilters: PropTypes.func.isRequired,
  maxAmountDue: PropTypes.number,
  minAmountDue: PropTypes.number,
};

InvoiceAmountDueFilterContainer.defaultProps = {
  invoiceFilters: invoiceFiltersDefaultProp,
  maxAmountDue: 100000,
  minAmountDue: 0,
};

export default withInvoiceFilters(InvoiceAmountDueFilterContainer);
