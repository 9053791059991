import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SdsAndLabelsLink = (props) => {
  const { selectedAccount, relativeLink } = props;
  if (relativeLink === '') return 'N/A';
  return (
    <Link
      data-test="sds-and-labels-link"
      to={`${relativeLink.slice(0, relativeLink.length - 1)}?acct=${
        selectedAccount.number
      }`}
      className="product-history__table-link"
    >
      SDS & Labels
    </Link>
  );
};

SdsAndLabelsLink.propTypes = {
  relativeLink: PropTypes.string.isRequired,
  selectedAccount: PropTypes.shape({
    number: PropTypes.string,
  }),
};

SdsAndLabelsLink.defaultProps = {
  selectedAccount: {},
};

export default SdsAndLabelsLink;
