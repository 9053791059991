import { startCase } from 'lodash';

/**
 *
 * @param {string} stringToConvert A string of the form ONE_TWO_THREE
 *
 * @returns a string of space-delineated words, each capitalized (e.g. "One Two Three")
 */
const convertFromSnakeUppercase = (stringToConvert) =>
  startCase(stringToConvert.toLowerCase());

/**
 *
 * @param {string} preferenceAndCategory a string of the format CATEGORY-PREFERENCE_NAME
 *
 * @returns {{category: string, preferenceName: string}} A pair of string values,
 * the category and preferenceName, with any underscores removed, and properly capitalized
 * in Pascal case
 */
export default (preferenceAndCategory) => {
  const splitPreferenceKey = preferenceAndCategory.split('-');

  return {
    category: convertFromSnakeUppercase(splitPreferenceKey[0]),
    preferenceName: convertFromSnakeUppercase(splitPreferenceKey[1]),
  };
};
