import { connect } from 'react-redux';
import {
  confirmRoleInvitation,
  acceptRoleDelegation,
} from '../Redux/RoleDelegation/roleDelegationActions';

import RoleAuthorization from './RoleAuthorization';

export const mapStateToProps = (state) => ({
  role: state.roleDelegation.role,
});

export const mapDispatchToProps = {
  confirmRoleInvitation,
  acceptRoleDelegation,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleAuthorization);
