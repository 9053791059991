import React from 'react';
import PropTypes from 'prop-types';
import './HeroHeaderStyles.scss';

const HeroHeader = ({ textLineOne, firstName, lastName }) => (
  <div className="hero-header__text" data-test="hero-header__text">
    {textLineOne}
    <span className="hero-header__name">
      {firstName}
      &nbsp;
      {lastName}
    </span>
  </div>
);

HeroHeader.defaultProps = {
  textLineOne: '',
  firstName: null,
  lastName: null,
};

HeroHeader.propTypes = {
  textLineOne: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default HeroHeader;
