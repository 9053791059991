import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CA } from 'const/statesAndProvinces';
import { selectSelectedAccount } from 'selectors';
import { useBillingAddress } from 'hooks/useBillingAddress';

const DoNotSellLink = () => {
  const { id: selectedAccountId } = useSelector(selectSelectedAccount());
  const billingAddress = useBillingAddress(selectedAccountId);
  const { code: californiaCode } = CA;

  const isCaliforniaUser =
    billingAddress?.provinceStateValue === californiaCode;

  return selectedAccountId && isCaliforniaUser ? (
    <li>
      <NavLink activeClassName="selected" to="/do-not-sell">
        Do Not Sell My Personal Information
      </NavLink>
    </li>
  ) : null;
};

export default DoNotSellLink;
