import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HeroHeader from './HeroHeader';

import './HeroStyles.scss';

export const Hero = ({ textLineOne, firstName, lastName }) => (
  <div className="hero__header-text-container">
    <HeroHeader
      className="hero__header-text"
      textLineOne={textLineOne}
      firstName={firstName}
      lastName={lastName}
    />
  </div>
);

Hero.defaultProps = {
  textLineOne: 'Welcome, ',
  firstName: null,
  lastName: null,
};

export const heroPropTypes = {
  textLineOne: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

Hero.propTypes = heroPropTypes;

const mapStateToProps = (state) => ({
  firstName: state.user.userData.firstName,
  lastName: state.user.userData.lastName,
});

export default connect(mapStateToProps, null)(Hero);
