// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';
import getStatesFailure from './getStatesFailure';
import getStatesSuccess from './getStatesSuccess';
import { stateTransformer } from '../../../../utils/transformers/geographyTransformers';

export default (country, id) => (dispatch) =>
  ecommApiService
    .get(`/v2/geography/${country}/states`, {
      headers: {
        'account-id': id,
      },
    })
    .then((res) => {
      const transformedStates = stateTransformer(res.data.data);
      dispatch(getStatesSuccess(transformedStates));
      return res;
    })
    .catch((error) => {
      dispatch(getStatesFailure(error));
      return error;
    });
