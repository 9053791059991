/**
 * Returns string of full api URL
 * @return {string} api url
 */

import resolveApiEnv, { DEV, SIT, PRE, PROD } from './resolveApiEnv';

const urls = {
  [DEV]: 'https://cxh-api.dev.cps-core.com/',
  [SIT]: 'https://cxh-api.sit.cps-core.com/',
  [PRE]: 'https://cxh-api.pre.cps-core.com/',
  [PROD]: 'https://cxh-api.cps-core.com/',
};

const resolveApiUrl = () => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_CXH_API_URI
  ) {
    return process.env.REACT_APP_CXH_API_URI;
  }

  const env = resolveApiEnv();
  return urls[env];
};

export default resolveApiUrl;
