import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getPage } from 'shared/utils/pagination';

import {
  invoices as invoicesPropType,
  selectedInvoiceIds as selectedInvoiceIdsPropType,
} from '../../invoicesPropTypes';

// generate a new function that internally calls getPage with the correct invoices and pageSize,
// but which only requires the 'page' input. Used by the other generate functions
const generateSingleArgGetPage = (invoices, pageSize) => (page) =>
  getPage(invoices, page, pageSize);

// Another helper function. given the current page and the modifiedGetPage (that requires only 1 argument),
// generates the object to pass into the useState hook
const generateInitialState = (currentPage, singleArgGetPage) => ({
  page: currentPage,
  paginatedInvoices: singleArgGetPage(currentPage),
});

// Another helper function. given the state object and setState function generated by the useState hook,
// as well as the modified getPage function and the handlePaginationClickAction,
// generate a method which, when it receives a page arg, updates state and fires handlePaginationClickAction with correct input
const generatePaginationClick = (
  setState,
  state,
  singleArgGetPage,
  handlePaginationClickAction
) => (page) => {
  setState({
    ...state,
    page,
    paginatedInvoices: singleArgGetPage(page),
  });
  handlePaginationClickAction(page);
};

// the helper function that combines the functionality of all previous helper functions.
// this is the contract called by the withPaginatedInvoices functionalComponent,
// to generate any props that aren't simply passed from the HoC to the wrapped component.
// any arguments passed in willhave been extracted from the original props object,
// and so invoices and pageSize are included in the returned object, as they are used
// both to generate some properties, but also required by the wrapped component.
const generateProps = (
  state,
  setState,
  singleArgGetPage,
  invoices,
  pageSize,
  handlePaginationClickAction
) => {
  const handlePaginationClick = generatePaginationClick(
    setState,
    state,
    singleArgGetPage,
    handlePaginationClickAction
  );

  return {
    currentPage: state.page,
    paginatedInvoices: state.paginatedInvoices,
    handlePaginationClick,
    invoices,
    pageSize,
  };
};

const propTypes = {
  currentPage: PropTypes.number,
  handlePaginationClick: PropTypes.func,
  invoices: invoicesPropType,
  pageSize: PropTypes.number,
  selectedInvoiceIds: selectedInvoiceIdsPropType,
};

const defaultProps = {
  currentPage: 1,
  handlePaginationClick: () => {},
  invoices: [],
  pageSize: 24,
  selectedInvoiceIds: {
    totalAmountDue: 0,
    paid: [],
    unpaid: [],
  },
};

/**
 * Higher Order Component (HoC) that provides a component with paginated  Invoices, and updates the currentPage automatically on pagination
 * @param  {Component} WrappedComponent Component to provide paginatedInvoices to.
 * @return {Component}                  The enhanced component.
 */
export const WithPaginatedInvoices = (WrappedComponent) => {
  const InsideWithPaginatedInvoices = ({
    currentPage,
    handlePaginationClick: handlePaginationClickAction,
    invoices,
    pageSize,
    selectedInvoiceIds,
    ...otherProps
  }) => {
    const singleArgGetPage = generateSingleArgGetPage(invoices, pageSize);
    const initialState = generateInitialState(currentPage, singleArgGetPage);
    const [state, setState] = useState(initialState);

    useEffect(() => {
      setState(initialState); // Ensure component state is updated if the filtered invoices update.
    }, [invoices]);

    const props = generateProps(
      state,
      setState,
      singleArgGetPage,
      invoices,
      pageSize,
      handlePaginationClickAction
    );

    return <WrappedComponent {...props} {...otherProps} />;
  };

  InsideWithPaginatedInvoices.propTypes = { ...propTypes };

  InsideWithPaginatedInvoices.defaultProps = { ...defaultProps };

  return InsideWithPaginatedInvoices;
};

export const mapStateToProps = (state) => ({
  selectedInvoiceIds: state.invoices.selectedInvoices,
});

export default (WrappedComponent) =>
  connect(mapStateToProps)(WithPaginatedInvoices(WrappedComponent));
