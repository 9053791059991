// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// shared UI

// components

// assets
import arrows from 'assets/arrows--light-green.svg';

// stylesheets
import './ArrowLink.css';

const ArrowLink = (props) => {
  if (props.inline) {
    return (
      <span>
        <Link
          to={props.link}
          className="arrow-link__link"
          onClick={props.onClick}
        >
          {props.linkDescription}
          <img src={arrows} alt="double arrows" />
        </Link>
      </span>
    );
  }

  return (
    <div className="arrow-link">
      {props.title}
      <div>
        <Link to={props.link} className="arrow-link__link">
          {props.linkDescription}
          <img src={arrows} alt="double arrows" />
        </Link>
      </div>
    </div>
  );
};

ArrowLink.propTypes = {
  inline: PropTypes.bool,
  title: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkDescription: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ArrowLink.defaultProps = {
  inline: false,
  title: '',
  onClick: () => {},
};

export default ArrowLink;
