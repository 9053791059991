import { customFormat } from 'shared/utils/DateHelper';

export function sanitizeAmountRange(amountRange) {
  return amountRange.map((amount) => amount.split(',').join(''));
}

export function formatDateRange(dateRange, formatString) {
  if (dateRange instanceof Array)
    return dateRange.map((date) => customFormat(new Date(date), formatString));

  return dateRange;
}
