import { connect } from 'react-redux';
import isEmployeeView from 'utils/isEmployeeView';
import LoginHeader from './LoginHeader';

const mapStateToProps = (state) => ({
  browser: state.browser,
  isEmployeeView: isEmployeeView(state),
});

export default connect(mapStateToProps)(LoginHeader);
