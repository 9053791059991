import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setPaymentFlowType } from 'components/Payments/Redux/actions/BankAccountFormActions';
import {
  clearPaymentError,
  setPaymentError,
} from 'components/Payments/Redux/actions/ErrorActions';

import PayInvoiceButton from './PayInvoiceButton';

export const mapStateToProps = (state) => {
  const { selectedInvoices } = state.invoices;

  return {
    countryCode: state.selectedAccount.locationCode,
    selectedInvoiceIds: selectedInvoices,
    totalAmountDue: selectedInvoices.totalAmountDue,
    unpaidInvoiceSelected: selectedInvoices.unpaid.length > 0,
  };
};

export const mapDispatchToProps = {
  clearPaymentError,
  push,
  setPaymentFlowType,
  setPaymentError,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayInvoiceButton);
