import React from 'react';
import PropTypes from 'prop-types';
import PaymentValidationMessageComponent from 'components/Payments/OtherComponents/ValidationMessage/PaymentValidationMessageComponent';
import Dropdown from '../../../../shared/ui/Dropdown/DropdownContainer';
import { RecurringPaymentFrequencies } from '../../PaymentConstants';
import PaymentDatepicker from './PaymentDatepicker';

import './recurringPayment.scss';

const frequencies = [
  RecurringPaymentFrequencies.BI_WEEKLY,
  RecurringPaymentFrequencies.MONTHLY,
];

const RecurringPaymentSection = (props) => {
  const {
    recurringEndDate,
    errors,
    validate,
    onChange,
    recurringPaymentFrequency,
  } = props;
  // Note, in order for the validation and onChange handlers to work correctly for the dropdown/input
  // these values must be the same as they appear in the redux state for the payment form
  const inputName = 'recurringEndDate';
  const dropdownName = 'recurringPaymentFrequency';

  return (
    <div className="recurring-payment-section">
      <h2>Recurring Payment</h2>

      <div className="recurring-payment-inner-section">
        <label htmlFor={dropdownName}>
          Frequency
          <Dropdown
            name="recurringFrequencyDropdown"
            options={frequencies}
            dropdownName={dropdownName}
            placeholder="Select"
            errors={errors.recurringPaymentFrequency}
            validate={(event) => {
              const { name, value } = event.target;
              const fieldData = { value };

              validate({ field: name, fieldData });
            }}
            onChange={({ code, name }) =>
              onChange({
                target: { name: dropdownName, value: { code, name } },
              })
            }
            selectedValue={recurringPaymentFrequency}
            additionalDropdownButtonClass="recurring-payment-dropdown-override"
            shouldHavePlaceHolder
          />
          <PaymentValidationMessageComponent
            errors={errors}
            className="dropdown-message-container"
            field={dropdownName}
          />
        </label>
        <label className="schedule-payment-input-label" htmlFor={inputName}>
          End Date
          <PaymentDatepicker
            noDefault
            onChange={onChange}
            inputName={inputName}
            formPropertyName={inputName}
            selectedDate={recurringEndDate}
            errors={errors}
            validate={validate}
          />
        </label>
      </div>
    </div>
  );
};

RecurringPaymentSection.propTypes = {
  errors: PropTypes.shape({
    recurringPaymentFrequency: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  recurringPaymentFrequency: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  recurringEndDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

RecurringPaymentSection.defaultProps = {
  recurringEndDate: null,
};

export default RecurringPaymentSection;
