import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

import './paymentDisabledNotification.css';

const PaymentDisabledNotification = (props) => (
  <div
    className={
      props.noArrow
        ? 'payment-disabled-notification'
        : 'payment-disabled-notification with-arrow'
    }
    data-test="account-overview-payment-disabled-notification"
  >
    <InfoIcon style={{ color: '#f1c02a' }} />
    <p>
      Online payments are currently disabled. This should be fixed in the next
      24 hours
    </p>
  </div>
);

PaymentDisabledNotification.propTypes = {
  noArrow: PropTypes.bool,
};

PaymentDisabledNotification.defaultProps = {
  noArrow: null,
};

export default PaymentDisabledNotification;
