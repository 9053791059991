const stripCurrencySymbols = (number) =>
  typeof number === 'string' ? number.replace(/[^0-9.,-]+/g, '') : number;

export const formatAsNumber = (number) => {
  const fixedNegativeNumber =
    typeof number === 'string' && number.includes('-')
      ? `-${number.replace('-', '')}`
      : number;
  return typeof fixedNegativeNumber === 'string'
    ? fixedNegativeNumber.replace(/[^0-9.-]+/g, '')
    : fixedNegativeNumber;
};

export const stripMoneySymbol = (value) =>
  typeof value === 'string' ? value.replace('$', '') : value;

export default stripCurrencySymbols;
