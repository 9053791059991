import {
  CXH_COMPLIANCE_ACCEPT,
  CXH_COMPLIANCE_CANCEL,
  CXH_COMPLIANCE_OPTOUT,
  CXH_COMPLIANCE_VIEWTERMSOFSERVICE,
} from '../gtmConstants';

export const viewTermsOfService = () => ({
  event: CXH_COMPLIANCE_VIEWTERMSOFSERVICE,
  action: 'View Terms of Service',
  category: 'Compliance',
});

export const acceptTermsOfService = () => ({
  event: CXH_COMPLIANCE_ACCEPT,
  action: 'Accept',
  category: 'Compliance',
});

export const cancelTermsOfService = () => ({
  event: CXH_COMPLIANCE_CANCEL,
  action: 'Cancel',
  category: 'Compliance',
});

export const doNotSellInfo = () => ({
  event: CXH_COMPLIANCE_OPTOUT,
  action: 'Opt Out',
  category: 'Compliance',
});

export const complianceEventsMap = {
  [CXH_COMPLIANCE_OPTOUT]: doNotSellInfo,
  [CXH_COMPLIANCE_ACCEPT]: acceptTermsOfService,
  [CXH_COMPLIANCE_CANCEL]: cancelTermsOfService,
  [CXH_COMPLIANCE_VIEWTERMSOFSERVICE]: viewTermsOfService,
};
