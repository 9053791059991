import React, { Fragment, Component } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { verifyUsePackageUom } from 'utils/verifyUsePackageUom';
import MINIMUM_BULK_QUANTITY from 'shared/constants/minimumBulkQuantity';
import VariantsDropdown from 'components/Shop/components/VariantsDropdown/VariantsDropdown';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import loadingDots from 'assets/loading-dots.gif';
import { variantPropType } from 'components/Shop/components/ProductDetail/ProductDetailPropTypes';
import SingleVariant from 'components/Shop/components/SingleVariant/SingleVariant';
import ProductPrice from '../ProductPrice';
import './VariantOptions.scss';

class VariantOptions extends Component {
  getVariantForProduct = () => {
    let selectedVariant = this.props.attributes.variants[0];
    this.props.attributes.variants.forEach((variant) => {
      if (variant.guid === this.props.product.variant.guid) {
        selectedVariant = this.props.product.variant;
      }
    });
    return selectedVariant;
  };

  addVariantToCart = debounce((e, variant) => {
    const {
      addToCart,
      cart,
      selectedAccount,
      attributes,
      user,
      gtmOrigin,
      index,
    } = this.props;
    const position = index + 1;
    // eslint-disable-next-line no-nested-ternary
    const selectedVolume = variant.is_bulk
      ? `${MINIMUM_BULK_QUANTITY}`
      : verifyUsePackageUom(selectedAccount)
      ? '1'
      : variant.unit_size;
    const itemData = {
      attributes,
      userId: user.userData.id,
      accountID: selectedAccount.id,
      branchID: selectedAccount.branchId,
      variant: { ...variant, selectedVolume },
    };
    addToCart(itemData, cart.cartData, { origin: gtmOrigin }, position);
  }, 250);

  handleChange = (selectedVariant) => {
    this.props.setSelectedVariant(selectedVariant);
  };

  checkLoading = () => {
    const {
      cart: { updatingCart },
      loadingPrices,
      recentlyOrdered,
    } = this.props;

    const button =
      loadingPrices || !updatingCart ? (
        <div>
          <img
            src={loadingDots}
            alt="loading price"
            className="variant-options__prices-loader"
            data-test="prices-loader-button"
          />
        </div>
      ) : (
        <Button
          className="variant-options__add-to-cart-button"
          data-test="add-to-cart-button"
          disabled={!updatingCart || loadingPrices}
          onClick={(e) => this.addVariantToCart(e, this.getVariantForProduct())}
          variant="contained"
        >
          <ShoppingCart
            className="variant-options__shopping-cart-icon"
            type="brand-white"
          />
          {recentlyOrdered ? 'Reorder' : 'Quick Add'}
        </Button>
      );

    return button;
  };

  render() {
    const {
      attributes,
      handleOpenModal,
      gtmOrigin,
      loadingPrices,
      flags: { canadaReadOnly },
    } = this.props;
    const { variants } = attributes;

    return (
      <>
        {loadingPrices ? (
          <div>
            <img
              src={loadingDots}
              alt="loading price"
              className="variant-options__prices-loader"
              data-test="pricesLoader"
            />
          </div>
        ) : (
          <ProductPrice
            contextualPriceStyle="product-price__no-price-message-plp"
            variant={this.getVariantForProduct()}
            context={gtmOrigin}
            loadingPrices={loadingPrices}
            handleOpenModal={handleOpenModal}
          />
        )}
        {variants.length <= 1 ? (
          <SingleVariant variant={variants[0]} />
        ) : (
          <VariantsDropdown
            data-test="variants-options-dropdown"
            variants={variants}
            selectedVariant={this.getVariantForProduct()}
            setSelectedVariant={this.handleChange}
          />
        )}
        <DisabledForEmployees>
          {canadaReadOnly ? null : this.checkLoading()}
        </DisabledForEmployees>
      </>
    );
  }
}

VariantOptions.propTypes = {
  recentlyOrdered: PropTypes.bool,
  attributes: PropTypes.shape({
    variants: PropTypes.arrayOf(variantPropType).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  loadingPrices: PropTypes.bool.isRequired,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    balance: PropTypes.number,
    due: PropTypes.number,
    pastDue: PropTypes.number,
    number: PropTypes.string,
    branchId: PropTypes.string,
    manufacturer: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  cart: PropTypes.shape({
    cartData: PropTypes.array,
    updatingCart: PropTypes.shape({
      updating: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  addToCart: PropTypes.func.isRequired,
  products: PropTypes.shape({
    loadingPrices: PropTypes.bool.isRequired,
  }).isRequired,
  product: PropTypes.shape({
    variant: PropTypes.object,
  }).isRequired,
  handleOpenModal: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userData: PropTypes.shape({
      id: PropTypes.string,
    }),
    userName: PropTypes.string,
  }).isRequired,
  setSelectedVariant: PropTypes.func.isRequired,
  gtmOrigin: PropTypes.string.isRequired,
  flags: PropTypes.shape({ canadaReadOnly: PropTypes.bool }).isRequired,
};

VariantOptions.defaultProps = {
  recentlyOrdered: false,
  handleOpenModal: PropTypes.func,
};

export default withLDConsumer()(VariantOptions);
