import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useFlags } from 'launchdarkly-react-client-sdk';
import unitMeasuresLong from 'components/Shop/utils/unitMeasuresLong';
import { volumeOrQuantity } from 'shared/utils/VolumeOrQuantity/volumeOrQuantity';
import variantOptionName from 'components/Shop/utils/variantOptionName';
import validateSapUnitOfMeasure from 'components/Shop/utils/validateSapUnitOfMeasure';
import withLocationCode from 'shared/utils/CountryCode/withLocationCode';
import NetPricingFeatureFlag from 'shared/ui/NetPricingFeatureFlag';
import TotalNetPricingFeatureFlag from 'shared/ui/TotalNetPricingFeatureFlag';
import { NO_PRICE_MESSAGE } from '../../../../shared/constants/ecommMessageText';

import './checkoutItem.scss';

export const CheckoutItem = ({ cartItem, isCanada }) => {
  const { displayNetPricing } = useFlags();
  const packSize = variantOptionName(cartItem.variant, isCanada);
  const { total_volume: totalVolume } = cartItem;
  const uom = get(cartItem, 'variant.unit_measure', '');
  const productName = get(cartItem, 'product.attributes.name', '');
  const pricePer = get(cartItem, 'variant.price', '');
  const grossPrice = get(cartItem, 'variant.price', '') * totalVolume;
  const netPrice = get(cartItem, 'variant.net_price', '') * totalVolume;
  const pricePerUnitText = validateSapUnitOfMeasure(uom) ? ` / ${uom}` : '';

  return (
    <div>
      <div className="checkout-item__divider">
        <div>
          <span
            data-test="reviewProductName"
            className="checkout-item__text--highlight"
          >
            {productName}
          </span>
          <div className="checkout-item__row">
            <span className="checkout-item__row-header">Pack Size</span>
            <span className="checkout-item__data" data-test="pack-size">
              {packSize}
            </span>
          </div>
          <div className="checkout-item__row">
            <span className="checkout-item__row-header">
              Price per {unitMeasuresLong[uom]}
            </span>
            <span className="checkout-item__data" data-test="price-per-unit">
              {pricePer > 0 ? (
                NetPricingFeatureFlag(
                  cartItem.variant,
                  `${pricePerUnitText}`,
                  displayNetPricing
                )
              ) : (
                <div className="checkout-item__no-price-container">
                  {NO_PRICE_MESSAGE}
                </div>
              )}
            </span>
          </div>
          <div className="checkout-item__row">
            <span
              className="checkout-item__row-header"
              data-test="checkout-item-volume-or-quantity-label"
            >
              {volumeOrQuantity(isCanada)}
            </span>{' '}
            <span className="checkout-item__data">
              {totalVolume} {validateSapUnitOfMeasure(uom)}
            </span>
          </div>
        </div>
        <div className="checkout-item__total-price-wrapper">
          <span className="checkout-item__data" data-test="total-price">
            {grossPrice || netPrice
              ? TotalNetPricingFeatureFlag(netPrice, grossPrice)
              : null}
          </span>
        </div>
      </div>
    </div>
  );
};

CheckoutItem.propTypes = {
  cartItem: PropTypes.shape({
    variant: PropTypes.shape({}),
    total_volume: PropTypes.number,
  }).isRequired,
  isCanada: PropTypes.bool.isRequired,
};

export default withLocationCode(CheckoutItem);
