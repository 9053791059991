import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBillingAddress } from 'selectors';

import { getAddress } from 'components/Cart/actions/cartActions';

const useBillingAddress = (selectedAccountId) => {
  const dispatch = useDispatch();
  const billingAddress = useSelector(selectBillingAddress);
  useEffect(() => {
    if (!billingAddress && selectedAccountId) {
      dispatch(getAddress(selectedAccountId));
    }
  }, [dispatch, billingAddress, selectedAccountId]);

  return billingAddress;
};

export default useBillingAddress;
