// dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { PasswordStrengthBar } from 'shared/ui';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';

import {
  invalidCharacters,
  passwordCriteria,
} from 'shared/utils/formValidation';

import PasswordCriteria from './PasswordCriteria';

import styles from './styles';

export const confirmPassword = (props) => {
  if (props.password === '' || props.passwordConfirm === '') {
    return;
  }
  if (props.password !== props.passwordConfirm) {
    props.setError('Passwords must match.');
  }
  if (invalidCharacters(props.password)) {
    props.setError('Password cannot contain +, =, or spaces');
  }
};

const PasswordChecker = (props) => {
  const {
    classes,
    handleChange,
    password,
    setInitialPassword,
    showPassword,
    toggleShowPassword,
    ...rest
  } = props;

  const criteria = passwordCriteria(password);

  return (
    <div data-test="password-checker">
      <PasswordCriteria password={password} {...rest} />
      <div className={classes.errorContainer}>
        <FormTextField
          name="password"
          title={setInitialPassword ? 'Password' : 'New Password'}
          value={password}
          type={showPassword ? 'text' : 'password'}
          autocomplete="current-password"
          handleChange={handleChange}
          toggleShowValue={toggleShowPassword}
          shouldValueShow={showPassword}
          toggleValue="password"
        />
        <PasswordStrengthBar password={password} criteria={criteria} />
      </div>
      <div className={classes.spacer} />
      <FormTextField
        name="passwordConfirm"
        title={
          props.setInitialPassword ? 'Confirm Password' : 'Confirm New Password'
        }
        value={props.passwordConfirm}
        type={props.showPassword ? 'text' : 'password'}
        autocomplete="current-password"
        handleChange={props.handleChange}
        toggleShowValue={props.toggleShowPassword}
        shouldValueShow={props.showPasswordConfirm}
        onBlur={() => confirmPassword(props)}
        toggleValue="passwordConfirm"
      />
    </div>
  );
};

PasswordChecker.propTypes = {
  password: PropTypes.string.isRequired,
  passwordConfirm: PropTypes.string.isRequired,
  showPassword: PropTypes.bool.isRequired,
  showPasswordConfirm: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setInitialPassword: PropTypes.bool,
};

PasswordChecker.defaultProps = {
  setInitialPassword: false,
};

export default withStyles(styles)(PasswordChecker);
