import get from 'lodash/get';
import ecommApiService from '../../../Shop/utils/ecommApiService';

import {
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_CUSTOMER_ORDERS_FAILURE,
  LOADING_CUSTOMER_ORDERS,
  GET_OPEN_CONTRACTS_SUCCESS,
  GET_OPEN_CONTRACTS_FAILURE,
  LOADING_OPEN_CONTRACTS,
  SET_SELECTED_DOCUMENTS_ACCOUNT,
  GET_CONTRACT_DETAILS_SUCCESS,
  GET_CONTRACT_DETAILS_FAILURE,
  LOADING_CONTRACT_DETAILS,
} from '../constants';

export const loadingCustomerOrders = (loading) => ({
  type: LOADING_CUSTOMER_ORDERS,
  loading,
});

export const getCustomerOrdersSuccess = (data) => ({
  type: GET_CUSTOMER_ORDERS_SUCCESS,
  loading: false,
  data,
});

export const getCustomerOrdersFailure = (error) => {
  const err = get(error, 'message', 'There was an error retrieving orders');
  return {
    type: GET_CUSTOMER_ORDERS_FAILURE,
    loading: false,
    error: err,
    statusCode: error.statusCode,
  };
};

export const getCustomerOrders = (accountId) => (dispatch) => {
  dispatch(loadingCustomerOrders(true));
  return ecommApiService
    .get(
      `v2/orders?accountId=${accountId}&date[start]‌=1/1/2016&date[end]‌=12/31/2119&page[size]‌=999&sortField=-orderDate&OrderSource=DIG-CXH`,
      {
        headers: {
          'account-id': `${accountId}` || '',
        },
      }
    )
    .then((res) => {
      dispatch(getCustomerOrdersSuccess(res.data));
      return res;
    })
    .catch((error) => {
      dispatch(getCustomerOrdersFailure(error));
    });
};

// Open Contract Actions
export const loadingOpenContracts = (loading) => ({
  type: LOADING_OPEN_CONTRACTS,
  loading,
});

export const getOpenContractsSuccess = (data) => ({
  type: GET_OPEN_CONTRACTS_SUCCESS,
  loading: false,
  data,
});

export const getOpenContractsFailure = (error) => {
  const err = get(error, 'message', 'There was an error retrieving contracts');
  return {
    type: GET_OPEN_CONTRACTS_FAILURE,
    loading: false,
    error: err,
    statusCode: error.statusCode,
  };
};

export const getOpenContracts = (accountId) => (dispatch) => {
  dispatch(loadingOpenContracts(true));
  return ecommApiService
    .get(`/v2/accounts/${accountId}/contracts`, {
      headers: {
        'account-id': `${accountId}`,
      },
    })
    .then((res) => {
      dispatch(getOpenContractsSuccess(res.data));
      return res;
    })
    .catch((error) => {
      dispatch(getOpenContractsFailure(error));
    });
};

export const setSelectedDocumentsAccount = (account) => ({
  type: SET_SELECTED_DOCUMENTS_ACCOUNT,
  account,
});

export const setContractDetailsSuccess = (data) => ({
  type: GET_CONTRACT_DETAILS_SUCCESS,
  contractDetailsLoading: false,
  data,
});

export const setContractDetailsFailure = (error) => {
  const err = get(
    error,
    'message',
    'There was an error retrieving the details of this contract'
  );
  const statusCode = get(error, 'response.data.statusCode');
  return {
    type: GET_CONTRACT_DETAILS_FAILURE,
    contractDetailsLoading: false,
    error: err,
    statusCode,
  };
};

export const loadingContractDetails = () => ({
  type: LOADING_CONTRACT_DETAILS,
  contractDetailsLoading: true,
});

export const getContractDetails = (accountId, contractId) => (dispatch) => {
  dispatch(loadingContractDetails(true));
  return ecommApiService
    .get(`/v2/accounts/${accountId}/contracts/${contractId}`, {
      headers: {
        'account-id': `${accountId}`,
      },
    })
    .then((res) => {
      dispatch(setContractDetailsSuccess(res.data));
      return res;
    })
    .catch((err) => dispatch(setContractDetailsFailure(err)));
};
