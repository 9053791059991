import {
  CLEAR_PAYMENT_STATE,
  SET_TERMS_AND_CONDITIONS_CONTENT,
  LOADING_DATA_SUCCESS,
  LOADING_DATA_FAILURE,
  LOADING_DATA_IN_PROGRESS,
} from '../../PaymentConstants';

const initialState = {
  states: {},
  loadingStatus: null,
  termsAndConditionsResponse: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TERMS_AND_CONDITIONS_CONTENT:
      return { ...state, termsAndConditionsResponse: action.payload };
    case LOADING_DATA_IN_PROGRESS: {
      return { ...state, loadingStatus: LOADING_DATA_IN_PROGRESS };
    }
    case LOADING_DATA_SUCCESS:
      return { ...state, loadingStatus: LOADING_DATA_SUCCESS };
    case LOADING_DATA_FAILURE:
      return { ...state, loadingStatus: LOADING_DATA_FAILURE };
    case CLEAR_PAYMENT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
