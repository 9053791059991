import { setGloballySelectedAccount } from 'actions/accountSelectorActions';
import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import {
  setProductHistoryLoading,
  setProductHistoryOk,
  setProductHistoryError,
} from './ProductHistoryActionCreators';
import {
  getProductHistory,
  getAccountForProductHistory,
} from './ProductHistoryService';
import { getPricesForProducts } from '../Shop/actions/productActions/productActions';
import { getCart } from '../Cart/actions/cartActions';

const productPricesResponseTransform = (productHistoryResponse) => {
  const productPricesDataArray = [];
  productHistoryResponse.forEach((data) => {
    productPricesDataArray.push({
      attributes: {
        variants: [
          {
            ars_key: data.productNumber.toString(),
          },
        ],
      },
    });
  });
  return productPricesDataArray;
};

export const fetchProductHistory = (
  account,
  queryString = ''
  // eslint-disable-next-line consistent-return
) => async (dispatch) => {
  dispatch(setProductHistoryLoading());
  try {
    const productHistory = await getProductHistory(
      account.id,
      account.branchId,
      queryString
    );
    dispatch(setProductHistoryOk(productHistory));
    const productPricesDataArray = productPricesResponseTransform(
      productHistory
    );
    dispatch(getPricesForProducts(account, productPricesDataArray));
  } catch (error) {
    return dispatch(setProductHistoryError(error.message));
  }
};

export const setAccountForProductHistory = (accountId) => async (dispatch) => {
  const account = await getAccountForProductHistory(accountId);
  dispatch(setGloballySelectedAccount(account));
};

export const getCartForProductHistory = (accountId) => (dispatch) => {
  dispatch(getCart(accountId));
};

export const productHistoryOk = (productHistoryList) => (dispatch) => {
  dispatch(setProductHistoryOk(productHistoryList));
};

export const getAccounts = (user) => (dispatch) => {
  dispatch(fetchAssociatedOrgsAction(user.userData.userDetails.associatedOrgs));
};
