import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

// shared UI
import withTerms from 'components/Terms/withTerms';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import PasswordChecker from 'shared/ui/PasswordChecker';
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';
import FormCard from 'shared/ui/MaterialFormCard';
import Form from 'shared/ui/MaterialFormCard/Form';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import Helper from 'shared/ui/MaterialFormCard/FormHelper';
import HelperContent from 'shared/ui/PasswordChecker/HelperContent';

// components
import {
  validatePassword,
  checkBreachedPassword,
} from 'shared/utils/formValidation';
import { FETCHING_USER } from 'shared/config/userConstants';
import {
  LOADING_REGISTRATION,
  REGISTER_SUCCESS,
  REGISTER_EXISTING_SUCCESS,
} from '../registrationConstants';
import styles from './styles';

export class ConfirmRegistration extends Component {
  state = {
    password: '',
    passwordConfirm: '',
    showPassword: false,
    showPasswordConfirm: false,
    formSubmitted: false,
    error: '',
    isPasswordSafe: false,
    modal: false,
  };

  setError = (type, message) => {
    this.setState({
      [type]: message,
    });
  };

  toggleShowPassword = (field) => {
    if (field === 'password') {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    } else if (field === 'passwordConfirm') {
      this.setState((prevState) => ({
        showPasswordConfirm: !prevState.showPasswordConfirm,
      }));
    }
  };

  clearErrors = () => {
    if (this.props.registration.error) this.props.resetRegistration();
    if (this.state.error !== null) this.setError('error', null);
  };

  handleChange = async (e) => {
    const { target } = e;
    const { value, name } = target;

    if (name === 'password') {
      if (validatePassword(value)) {
        const isPasswordSafe = await checkBreachedPassword(this.state.password);
        this.toggleIsPasswordSafe(isPasswordSafe);
      } else {
        this.toggleIsPasswordSafe(false);
      }
    }

    this.clearErrors();

    this.setState({
      [name]: value,
    });
  };

  toggleIsPasswordSafe = (isPasswordSafe) => {
    this.setState({ isPasswordSafe });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      formSubmitted: true,
    });

    if (this.state.password === '' || this.state.passwordConfirm === '') {
      return this.setError('error', 'Please enter and confirm a new password.');
    }

    if (this.state.password !== this.state.passwordConfirm) {
      return this.setError('error', 'Passwords must match.');
    }

    const isPasswordSafe = await checkBreachedPassword(this.state.password);
    this.toggleIsPasswordSafe(isPasswordSafe);

    if (
      (this.state.password !== '' && !validatePassword(this.state.password)) ||
      !this.state.isPasswordSafe
    ) {
      return this.setError(
        'error',
        'Please check that all password requirements are met.'
      );
    }

    const registerData = {
      password: this.state.password,
      identity: this.props.match.params.identity,
      accessCode: this.props.match.params.accessCode,
    };

    const termsVersion = this.props.terms.termsData.data.version;
    const identity = window.atob(this.props.match.params.identity);
    return identity.indexOf('firstName') !== -1
      ? this.props.registerUser(registerData, termsVersion)
      : this.props.registerExistingUser(registerData, termsVersion);
  };

  render() {
    const { classes, registration, user } = this.props;

    if (this.state.modal) {
      return (
        <div>
          <FullPageBackground
            image={bgRegistration}
            className="full-page-background--farmers-pointing"
          />
          <FormCard>
            <Helper
              title="Examples of Common Passwords"
              description="To ensure your data is safe, please refrain from choosing a commonly used password such as, but not limited to, the examples below"
              closeModal={this.toggleModal}
              renderContent={() => <HelperContent />}
            />
          </FormCard>
        </div>
      );
    }

    if (
      registration.status === LOADING_REGISTRATION ||
      registration.status === REGISTER_SUCCESS ||
      registration.status === REGISTER_EXISTING_SUCCESS ||
      user.status === FETCHING_USER
    ) {
      return <MaterialLoadingIcon />;
    }

    let submissionError;
    if (registration.error) {
      if (registration.error.response.data.name === 'UsernameExistsException') {
        submissionError =
          'There is already an account registered with this username.';
      } else if (registration.error.response.status) {
        submissionError =
          registration.error.response.status === 400
            ? 'Your registration link is no longer valid. Please restart your registration process.'
            : 'There was an error completing your registration. Please try again, or contact customer support.';
      }
    }

    return (
      <div>
        <FullPageBackground
          image={bgRegistration}
          className="full-page-background--farmers-pointing"
        />
        <FormCard>
          <h3>Email verified. Welcome!</h3>
          <hr />
          <h4>Please set your password</h4>
          <p className={classes.p}>
            Your security is important to us. Please take a moment to reset your
            password.
          </p>
          <Form onSubmit={this.handleSubmit}>
            <PasswordChecker
              password={this.state.password}
              passwordConfirm={this.state.passwordConfirm}
              showPassword={this.state.showPassword}
              showPasswordConfirm={this.state.showPasswordConfirm}
              handleChange={this.handleChange}
              setError={this.setError}
              toggleShowPassword={this.toggleShowPassword}
              formSubmitted={this.state.formSubmitted}
              isPasswordSafe={this.state.isPasswordSafe}
              toggleModal={this.toggleModal}
              setInitialPassword
            />
            <div className={classes.error} id="error">
              {this.state.error || submissionError}
            </div>
            <MaterialButton
              id="reset-password-button"
              value="Continue"
              type="submit"
            />{' '}
          </Form>
        </FormCard>
      </div>
    );
  }
}

ConfirmRegistration.propTypes = {
  registerExistingUser: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  resetRegistration: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      identity: PropTypes.string,
      accessCode: PropTypes.string,
    }),
  }).isRequired,
  registration: PropTypes.shape({
    error: PropTypes.shape({
      response: PropTypes.shape({
        data: PropTypes.shape({
          name: PropTypes.string,
        }),
        status: PropTypes.number,
      }),
    }),
    status: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  terms: PropTypes.shape({
    status: PropTypes.string,
    termsData: PropTypes.shape({
      data: PropTypes.shape({
        version: PropTypes.string,
      }),
    }),
    error: PropTypes.shape({}),
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withTerms(withStyles(styles)(ConfirmRegistration));
