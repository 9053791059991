import React from 'react';
import moment from 'moment';
import useStyles from '../styles';

const InnerContent = ({ details, account }) => {
  const classes = useStyles();
  const section = (
    <div className={classes.contractInfoContractDetails}>
      <div className={classes.contractDetailsColLeft}>
        <div className={classes.keyValueWrapper}>
          <div className={classes.detailKey}>Created On</div>
          <div className={classes.detailValue}>
            {moment(details.createdOn, 'YYYY-MM-DD').format('MM/DD/YYYY')}
          </div>
        </div>
        <div className={classes.keyValueWrapperPadded}>
          <div className={classes.detailKey}>Valid From</div>
          <div className={classes.detailValue}>
            {moment(details.validFromDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
          </div>
        </div>
        <div className={classes.keyValueWrapperPadded}>
          <div className={classes.detailKey}>Valid Until</div>
          <div className={classes.detailValue}>
            {moment(details.validToDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
          </div>
        </div>
        <div className={classes.keyValueWrapperPadded}>
          <div className={classes.detailKey}>Contract Type</div>
          <div className={classes.detailValue}>{details.orderReason}</div>
        </div>
      </div>
      <div className={classes.contractDetailsColRight}>
        <div className={classes.keyValueWrapper}>
          <div className={classes.detailKey}>Status</div>
          <div className={classes.detailValue}>{details.contractStatus}</div>
        </div>
        <div className={classes.keyValueWrapperPadded}>
          <div className={classes.detailKey}>Representative</div>
          <div className={classes.detailValue} data-test="crop-consultant">
            {`${account.cropConsultant.firstName} ${account.cropConsultant.lastName}`}
          </div>
        </div>
        <div className={classes.keyValueWrapperPadded}>
          <div className={classes.detailKey}>Branch</div>
          <div className={classes.detailValue} data-test="branch">
            {`${account.branchName} #${account.branchId}`}
          </div>
        </div>
      </div>
    </div>
  );

  return section;
};

export default InnerContent;
