const styles = () => ({
  checkBoxWrapper: {
    listStyle: 'none',
    padding: '0 0 0 24px',
    position: 'relative',

    '&:after': {
      borderBottom: `1px solid #ccc`,
      bottom: 0,
      content: '""',
      left: 0,
      margin: '0 auto',
      position: 'absolute',
      right: 0,
      width: '90%',
    },
    '&:before': {
      borderTop: `1px solid #ccc`,
      content: '""',
      left: 0,
      margin: '0 auto',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '90%',
    },
  },
  innerCheckboxWrapper: {
    display: 'flex',
    padding: '12px 0',
  },
  contractedProductsIcon: {
    alignSelf: 'center',
    height: '22px',
    width: '22px',
  },
  checkboxLabelTop: {
    color: '#000',
    fontSize: '1rem',
    fontWeight: 600,
  },
  labelRoot: {
    marginRight: '8px',
  },
});

export default styles;
