import { combineReducers } from 'redux';
import { reducer as employeeInfo } from '../EmployeeHeader';
import { reducer as employeeLogin } from '../EmployeeLogin';
import customers from '../Customers/reducers';
import landingPage from '../EmployeeLandingPage/reducers';
import { reducers as mirroring } from '../Mirroring';

export default combineReducers({
  user: employeeInfo,
  mirroring,
  customers,
  landingPage,
  employeeLogin,
});
