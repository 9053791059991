import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import { connect } from 'react-redux';

import { closeModal } from './actions';
import ConfirmationModal from './ConfirmationModal';
import { togglePreference } from '../actions';
import {
  privacyPreference,
  privacyPreferences,
  accountIds,
} from '../billingTabPropTypes';

export const ConfirmationModalContainer = ({
  closeModal, // eslint-disable-line no-shadow
  push, // eslint-disable-line no-shadow
  togglePreference, // eslint-disable-line no-shadow,
  preference,
  accountIds, // eslint-disable-line no-shadow
  preferences,
  ...rest
}) => {
  // the actions to be fired when the modal is closed,
  // given an alias just to not confuse the different versions of closeModal
  const closeModalActions = () => {
    closeModal();
    togglePreference(preference, true, preferences, accountIds);
  };

  const navigate = () =>
    togglePreference(preference, true, preferences, accountIds)
      .then(() => {
        push('/profile/notifications');
        closeModal();
      })
      .catch(() => {
        closeModal();
      });

  return (
    <ConfirmationModal
      closeModal={closeModalActions}
      navigate={navigate}
      {...rest}
    />
  );
};

ConfirmationModalContainer.propTypes = {
  // state properties
  isOpen: PropTypes.bool.isRequired,
  preferences: privacyPreferences.isRequired,
  preference: privacyPreference,
  accountIds: accountIds.isRequired,
  // dispatch properties
  closeModal: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  togglePreference: PropTypes.func.isRequired,
};

ConfirmationModalContainer.defaultProps = {
  preference: null,
};

export const mapStateToProps = (state) => {
  const { confirmationModal } = state.profile.billingTab;
  const { isOpen, apiData } = confirmationModal;
  const { preference, accountIds } = apiData; // eslint-disable-line no-shadow

  return {
    isOpen,
    preferences: state.user.userData.privacy,
    preference,
    accountIds,
  };
};

export const mapDispatchToProps = {
  push,
  closeModal,
  togglePreference,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationModalContainer);
