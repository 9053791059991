import {
  PERMITS_FETCHING,
  PERMITS_FETCH_FAILURE,
  PERMITS_FETCH_SUCCESS,
} from '../actions';

const permits = (state = [], action) => {
  switch (action.type) {
    case PERMITS_FETCH_SUCCESS:
      return action.payload;
    case PERMITS_FETCHING:
    case PERMITS_FETCH_FAILURE:
      return [];
    default:
      return state;
  }
};

export default permits;
