import {
  filterByDate,
  addDaysToDate,
  formatDateToIsoString,
} from './dateFilterUtils';
import { filterChoices } from '../../Filters/DueDateRangeFilter';

const { ALL, NEXT_30_DAYS } = filterChoices;

export default (invoices, filterChoice) => {
  if (filterChoice instanceof Array) {
    return filterByDate({
      items: invoices,
      filterKey: 'dueDate', // the prop on the invoice to filter on.
      startDateString: filterChoice[0],
      endDateString: filterChoice[1],
    });
  }

  // value will be undefined or a string at this point.
  let startDate;
  let endDate;
  const now = new Date();

  switch (filterChoice) {
    case NEXT_30_DAYS:
      startDate = formatDateToIsoString(now);
      endDate = addDaysToDate(now, 30);
      break;
    case ALL:
    default: {
      break;
    }
  }

  return filterByDate({
    items: invoices,
    filterKey: 'dueDate', // the prop on the invoice to filter on.
    startDateString: startDate,
    endDateString: endDate,
  });
};
