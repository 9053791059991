import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress } from '@material-ui/core';
import { nutrienGreen } from 'shared/styles';

const styles = {
  svg: {
    ...nutrienGreen,
  },
};

const MaterialLoadingIcon = (props) => (
  <CircularProgress {...props} size={props.size} left={70} top={0} />
);

MaterialLoadingIcon.propTypes = {
  size: PropTypes.number,
};

MaterialLoadingIcon.defaultProps = {
  size: 50,
};

export default withStyles(styles)(MaterialLoadingIcon);
