import { InvoiceStatuses, INVOICE_LOADING, INVOICE_LOADED } from '../actions';

export default (state = InvoiceStatuses.LOADING, action) => {
  switch (action.type) {
    case INVOICE_LOADING:
      return InvoiceStatuses.LOADING;
    case INVOICE_LOADED:
      return action.error ? InvoiceStatuses.ERROR : InvoiceStatuses.OK;
    default:
      return state;
  }
};
