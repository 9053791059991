import adjustToFixed from '../AdjustToFixed/adjustToFixed';

const totalPriceCalculator = (pricePer, totalVolume) => {
  let totalPrice;
  totalPrice = Number(pricePer) * Number(totalVolume);
  totalPrice = adjustToFixed(totalPrice);

  return totalPrice;
};

export default totalPriceCalculator;
