import { getAccessToken } from '@nutrien/fe-domain-utils';
import { isEmployee } from './isEmployee';

/**
 * Returns the user type as a string,
 * or null if the user type cannot be determined.
 */
export const getUserType = async () => {
  const accessToken = await getAccessToken();
  if (accessToken) {
    return (await isEmployee()) ? 'employee' : 'customer';
  }
  return null;
};

export default getUserType;
