import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

const HeaderSpacer = ({ isMirroring, browser }) => {
  const { browserSupportHeader } = useFlags();
  return (
    <div>
      <div
        className={isMirroring ? 'header-spacer mirroring' : 'header-spacer'}
      />
      {browserSupportHeader && (
        <div
          className={
            !browser.isSupported
              ? 'header-spacer__browser-not-supported'
              : 'header-spacer__browser-is-supported'
          }
        />
      )}
    </div>
  );
};

HeaderSpacer.propTypes = {
  isMirroring: PropTypes.bool,
  browser: PropTypes.shape({ isSupported: PropTypes.bool }).isRequired,
};

HeaderSpacer.defaultProps = {
  isMirroring: false,
};

export default HeaderSpacer;
