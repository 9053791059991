import { makeStyles } from '@material-ui/core';

export default makeStyles({
  accountOverviewRecentMessage: {
    marginBottom: 20,
    fontWeight: 200,
  },
  overviewFilterTitle: {
    display: 'flex',
  },
  overviewFilterLineItems: {
    minWidth: '250px',
    marginRight: 20,
    marginLeft: 'auto',
  },
  filterLabel: {
    fontWeight: '600',
    paddingRight: '0.5rem',
    fontSize: '18px',
    position: 'relative',
    textAlign: 'left',
    left: '-13px',
  },
  '@media screen and (max-width: 768px)': {
    overviewFilterTitle: {
      flexDirection: 'column',
    },
  },
  '@media screen and (max-width: 1000px)': {
    overviewFilterTitle: {
      padding: '30px 0 10px 0',
    },
    overviewFilterLineItems: {
      width: '100%',
      marginBottom: 16,
    },
  },
  '@media screen and (max-width: 1200px)': {
    overviewPayNowButton: {
      margin: '12px 0',
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  pagination: {
    marginTop: 2,
  },
  paginationButton: {
    padding: '6px 0px',
    width: 48,
    minWidth: 48,
  },
});
