import { arrayOf, number, shape, string, oneOfType } from 'prop-types';

const miniCartPropTypes = shape({
  cartData: arrayOf(
    shape({
      id: number.isRequired,
    }).isRequired
  ).isRequired,
  mostRecentAddition: shape({
    total_volume: oneOfType([string, number]),
    id: number,
    account_id: string,
    variant_sku: string,
    quantity: number,
    variant: shape({
      price: oneOfType([string, number]),
    }),
  }),
  error: string,
});

export default miniCartPropTypes;
