import React, { useEffect } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { useAuth } from '@nutrien/fe-domain-utils';
import Box from '@nutrien/uet-react/Box';
import Button from '@nutrien/uet-react/Button';
import Card from '@nutrien/uet-react/Card';
import CardContent from '@nutrien/uet-react/CardContent';
import Typography from '@nutrien/uet-react/Typography';
import Notification from '@nutrien/uet-react/Notification';

import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';

export default function LoggedOut() {
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.replace('/');
    }
  }, [isAuthenticated, history]);

  return (
    <div>
      <FullPageBackground
        image={bgRegistration}
        className="full-page-background--farmers-pointing"
      />
      <Box maxWidth="500px" margin="5rem auto" width="100%">
        <Card>
          <CardContent>
            <Switch>
              <Route path="/logged-out/account-issue">
                <Notification variant="error">
                  <Typography>
                    You are currently locked out of the Digital Hub due to an
                    issue with your account status. Please contact the Customer
                    Service Department by calling 1-866-261-2033 to receive
                    assistance regarding your account.
                  </Typography>
                </Notification>
              </Route>
              <Route>
                <Typography>
                  You have successfully signed out. Thanks for visiting us!
                </Typography>
              </Route>
            </Switch>
            <Box width="100%" marginTop="2rem">
              <Link to="/">
                <Button variant="contained" fullWidth>
                  Sign in again
                </Button>
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}
