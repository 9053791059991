export const NO_USER = 'NO_USER';
export const FETCHING_USER = 'FETCHING_USER';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE';
export const USER_REGISTRATION_FAILURE = 'USER_REGISTRATION_FAILURE';
export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';
export const USER_FETCHED = 'USER_FETCHED';
export const UPDATE_SHOW_ORDER_CONDITIONS = 'UPDATE_SHOW_ORDER_CONDITIONS';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';
export const FETCHING_AUTH0_USER_METADATA = 'FETCHING_AUTH0_USER_METADATA';
export const FETCH_AUTH0_USER_METADATA_SUCCESS =
  'FETCH_AUTH0_USER_METADATA_SUCCESS';
export const FETCH_AUTH0_USER_METADATA_FAILURE =
  'FETCH_AUTH0_USER_METADATA_FAILURE';
export const UPDATING_AUTH0_USER_METADATA = 'UPDATING_AUTH0_USER_METADATA';
export const UPDATE_AUTH0_USER_METADATA_SUCCESS =
  'UPDATE_AUTH0_USER_METADATA_SUCCESS';
export const UPDATE_AUTH0_USER_METADATA_FAILURE =
  'UPDATE_AUTH0_USER_METADATA_FAILURE';
export const AUTH0_REDIRECT = 'AUTH0_REDIRECT';
