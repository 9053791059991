import get from 'lodash/get';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'shared/lib/cxhApiService';
import { push } from 'connected-react-router';
import { getQueryStringFromRouter } from 'shared/utils/reduxSelectors';
import { setStatements } from 'actions/actionCreators';
import PropTypes from 'prop-types';
import { dispatchDownloadStatementAction } from './statementsActions';

// components
import Statements from './Statements';

// stylesheets
import './Statements.css';

export class StatementsContainer extends Component {
  state = {
    account: '',
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { handleAccountUpdate, accountId } = this.props;
    handleAccountUpdate(accountId);
    this.setState({
      account: this.props.accountId,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProp) {
    if (nextProp.accountId !== this.state.account) {
      const { handleAccountUpdate } = this.props;
      handleAccountUpdate(this.props.accountId);
      this.setState({
        account: this.props.accountId,
      });
    }
  }

  handlePaginationClick = (page) => {
    // eslint-disable-next-line no-shadow
    const { push } = this.props;
    push(`/accounts/${this.props.accountId}/statements?page=${page}`);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  render() {
    const pageSize = 24;

    return (
      <Statements
        statementSummaries={this.props.statementSummaries}
        onPaginationClick={this.handlePaginationClick}
        currentPage={this.props.currentPage}
        pageSize={pageSize}
        accountId={this.props.accountId}
        handleDownload={this.props.handleDownload}
      />
    );
  }
}

StatementsContainer.propTypes = {
  handleAccountUpdate: PropTypes.func,
  handleDownload: PropTypes.func,
  accountId: PropTypes.string.isRequired,
  push: PropTypes.func,
  statementSummaries: PropTypes.shape({
    status: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        amountDue: PropTypes.number,
        date: PropTypes.string,
      })
    ),
  }),
  currentPage: PropTypes.number,
};

StatementsContainer.defaultProps = {
  push: () => {},
  handleDownload: () => {},
  statementSummaries: undefined,
  handleAccountUpdate: () => {},
  currentPage: null,
};

export const handleAccountUpdate = (id) => (dispatch) => {
  dispatch(setStatements({ status: 'LOADING', list: [] }));
  return axios
    .get(`v1/accounts/${id}/statements`)
    .then((response) => {
      dispatch(
        setStatements({
          status: 'OK',
          list: response.data.statementSummaries || [],
        })
      );
    })
    .catch((err) => {
      dispatch(
        setStatements({
          status: get(err, 'err.response.status', 'ERROR'),
          list: [],
        })
      );
      // eslint-disable-next-line no-console
      console.error(err);
    });
};

const mapStateToProps = (state) => ({
  currentPage: parseInt(getQueryStringFromRouter(state.router).page || 1, 10),
  statementSummaries: state.statementSummaries,
});

export const mapDispatchToProps = {
  push,
  handleAccountUpdate,
  handleDownload: dispatchDownloadStatementAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatementsContainer);
