import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { AuthenticatedRoute } from 'shared/utils/AuthenticatedComponents';
import isEmployee from 'shared/utils/tokenUtils/isEmployee';
import Page404 from 'components/Page404';
import EmployeeLogin from './EmployeeLogin';
import { MirroringPage } from './Mirroring';
import EmployeeLandingPageContainer from './EmployeeLandingPage';

const Employee = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={`${path}/`}
        component={EmployeeLandingPageContainer}
        redirect={`${match.path}/login`}
        validator={isEmployee}
      />
      <AuthenticatedRoute
        exact
        path={`${path}/mirroring`}
        component={MirroringPage}
        redirect={`${match.path}/login`}
        validator={isEmployee}
      />
      <Route exact path={`${path}/login`} component={EmployeeLogin} />
      <Route component={Page404} />
    </Switch>
  );
};

Employee.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default Employee;
