export const ADD_INVOICE_DATA = 'ADD_INVOICE_DATA';
export const CLEAR_FILTERED_PRODUCT = 'CLEAR_FILTERED_PRODUCT';
export const CLEAR_INVOICE_DATA = 'CLEAR_INVOICE_DATA';
export const DOWNLOAD_INVOICES_IN_PROGRESS = 'DOWNLOAD_INVOICES_IN_PROGRESS';
export const DOWNLOAD_INVOICES_SUCCESS = 'DOWNLOAD_INVOICES_SUCCESS';
export const DOWNLOAD_INVOICES_FAILURE = 'DOWNLOAD_INVOICES_FAILURE';
export const INVOICES_EXPORT_START = 'INVOICES_EXPORT_START';
export const INVOICES_EXPORT_SUCCESS = 'INVOICES_EXPORT_SUCCESS';
export const INVOICES_EXPORT_ERROR = 'INVOICES_EXPORT_ERROR';
export const SET_FILTERED_PRODUCTS = 'SET_FILTERED_PRODUCTS';
export const SET_INVOICE_DATE_RANGE = 'SET_INVOICE_DATE_RANGE';
export const ADD_INVOICED_PRODUCTS_DATA = 'ADD_INVOICED_PRODUCTS_DATA';
export const CLEAR_INVOICED_PRODUCTS_DATA = 'CLEAR_INVOICED_PRODUCTS_DATA';
export const INVOICED_PRODUCTS_ERROR = 'INVOICED_PRODUCTS_ERROR';
export const MINIMUM_DATE = new Date('January 1, 2010 00:00:00');

export const INVOICE_STATUSES = Object.freeze({
  DUE_DISCOUNT_AVAILABLE: 'Due - Discount Available',
  DUE_REBATE_AVAILABLE: 'Due - Rebate Available',
  PAST_DUE: 'Past Due',
  DUE: 'Due',
  NOT_YET_DUE: 'Not Yet Due',
  PAID: 'Paid',
  PENDING: 'Pending',
  SCHEDULED: 'Scheduled',
});

export const INVOICE_FILTER_TYPES = {
  ...INVOICE_STATUSES,
  ALL: 'All',
  /* Commented out for now until refactor work is finished */
  // FILTER_BY_PRODUCT: 'Filter by Product',
};

export const ACTION_TYPES = Object.freeze({
  SELECT_INVOICE: 'SELECT_INVOICE',
  DESELECT_INVOICE: 'DESELECT_INVOICE',
  CLEAR_SELECTED_INVOICES: 'CLEAR_SELECTED_INVOICES',
  SELECT_ALL_INVOICES: 'SELECT_ALL_INVOICES',
  DESELECT_ALL_INVOICES: 'DESELECT_ALL_INVOICES',
  SELECT_INVOICED_PRODUCT: 'SELECT_INVOICED_PRODUCT',
  DESELECT_INVOICED_PRODUCT: 'DESELECT_INVOICED_PRODUCT',
  CLEAR_SELECTED_INVOICED_PRODUCTS: 'CLEAR_SELECTED_INVOICED_PRODUCTS',
});

export const DATE_RANGE_FILTERS = {
  ALL: 'All',
  LAST_30: 'Last 30 Days',
  LAST_60: 'Last 60 Days',
  LAST_90: 'Last 90 Days',
  YEAR_TO_DATE: 'Year-to-date',
  LAST_YEAR: 'Last Year',
  CUSTOM: 'Custom Date',
};
