import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { selectUserStatus } from 'selectors/userSelectors';
import { USER_FETCHED } from 'shared/config/userConstants';
import { getAccountById } from 'services/httpServices/accountService';
import { setAccounts } from '../../../../actions/actionCreators';
import { verifyContainsCountry } from '../../../utils/CountryCode/withLocationCode/verifyContainsCountry';

export const ACCOUNT_STATUS_ENUM = {
  FETCHING: 'FETCHING',
  OK: 'OK',
  NO_ACCOUNT: 'NO_ACCOUNT',
};

const addAccountsToStore = (accounts) => setAccounts(accounts);

const getAccounts = (res) =>
  res
    .reduce((acc, val) => acc.concat(val), [])
    .filter((val) => val !== undefined);

const handlePromise = (dispatch, orgs, loadAccountData) => {
  Promise.all(orgs.map((org) => loadAccountData(org.accounts)))
    .then((res) => {
      const accounts = getAccounts(res);
      if (accounts.length) {
        const verifyCanada = verifyContainsCountry(accounts, 'CAN');
        if (verifyCanada) sessionStorage.setItem('isCanada', true);
      }
      if (orgs.some((org) => org.accounts.length > 0) && accounts.length < 1) {
        return dispatch(
          addAccountsToStore({ status: ACCOUNT_STATUS_ENUM.FETCHING, list: [] })
        );
      }
      return dispatch(
        addAccountsToStore({ status: ACCOUNT_STATUS_ENUM.OK, list: accounts })
      );
    })
    .catch(() => {
      dispatch(
        addAccountsToStore({ status: ACCOUNT_STATUS_ENUM.NO_ACCOUNT, list: [] })
      );
    });
};

const sanitizeNickname = (nickname) => {
  if (!nickname || nickname.trim() === '') return null;

  return nickname;
};

/**
 * Modifies the accounts' nicknames with the provided nicknames and stores them. This
 * is used to show the immediate result to the user when they change the nicknames in the
 * /profile/accounts page.
 * @param {array} accounts - list of accounts to update
 * @param {map} nicknames - map of accountNumber:nickname for updated accounts
 */
export const persistAccountNicknames = (accounts, nicknames) => (dispatch) => {
  const modifiedAccounts = [];
  accounts.forEach((account) => {
    const clonedAccount = cloneDeep(account);

    // Need to ensure that empty and whitespace only strings are stored in the
    // store as nulls. We detect whether the nickname or the account number to be shown
    // by checking if the nickname value is null or not
    clonedAccount.nickname = sanitizeNickname(get(nicknames, account.number));
    modifiedAccounts.push(clonedAccount);
  });

  return dispatch(
    addAccountsToStore({
      status: ACCOUNT_STATUS_ENUM.OK,
      list: modifiedAccounts,
    })
  );
};

export const fetchAssociatedOrgsAction = (orgs) => (dispatch, getState) => {
  const userStatus = selectUserStatus(getState());

  if (
    userStatus !== USER_FETCHED ||
    getState().accounts?.status === 'FETCHING'
  ) {
    return;
  }

  dispatch(
    addAccountsToStore({ status: ACCOUNT_STATUS_ENUM.FETCHING, list: [] })
  );

  const loadAccountData = (arr) =>
    Promise.all(
      arr.map((account) =>
        getAccountById(account.number)
          .then((response) => response)
          .catch((err) => console.error(err))
      )
    );

  handlePromise(dispatch, orgs, loadAccountData);
};
