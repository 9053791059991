/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { isCanada } from 'shared/utils/CountryCode';
import { gtmViewInvoicedProductsDetailsModal } from 'store/middleware/TagManager/gtmActions';
import InvoiceProductInfo from './InvoiceProductInfo';
import { deselectInvoicedProduct, getInvoicedProducts } from '../../actions';
import {
  invoicedProductsPropType,
  selectedProductsPropType,
} from './productPropTypes';
import { invoices as invoicesPropType } from '../../invoicesPropTypes';
import { getInvoices } from '../../selectors';
import { getFilteredProductsList } from '../InvoicesTable/selectors';

export const getTotalPriceForInvoicedProducts = (groupedInvoicedProducts) => {
  let totalPrice = 0;
  Object.keys(groupedInvoicedProducts).forEach((key) => {
    totalPrice += groupedInvoicedProducts[key].totalAmount;
  });
  return totalPrice;
};

const checkIfProductIsInFilteredInvoices = (product, filteredInvoices) =>
  filteredInvoices.findIndex((invoice) => invoice.id === product.invoiceId) !==
  -1;

const checkIfProductIsSelected = (selectedProducts, product) =>
  selectedProducts.findIndex((prod) => prod.productId === product.productId) !==
  -1;

export const mapInvoicedProducts = (invoicedProducts) =>
  invoicedProducts.map((product) => ({
    productId: product.productId,
    invoiceId: product.invoiceId,
    totalAmount: product.price + product.salesTax,
    productName: product.productName,
    quantity: product.quantity,
    quantityUOM: product.quantityUOM,
  }));

const updateGroupedInvoicedProducts = (
  groupedInvoicedProducts,
  invoicedProduct
) => {
  if (groupedInvoicedProducts[invoicedProduct.productId]) {
    groupedInvoicedProducts[invoicedProduct.productId].quantity +=
      invoicedProduct.quantity;
    groupedInvoicedProducts[invoicedProduct.productId].totalAmount +=
      invoicedProduct.totalAmount;
  } else {
    groupedInvoicedProducts[invoicedProduct.productId] = invoicedProduct;
  }
  return groupedInvoicedProducts;
};

const groupInvoicedProductsByProductName = (
  mappedInvoicedProducts,
  selectedProducts,
  filteredInvoices
) =>
  mappedInvoicedProducts.reduce((groupedInvoicedProducts, product) => {
    if (
      checkIfProductIsSelected(selectedProducts, product) &&
      checkIfProductIsInFilteredInvoices(product, filteredInvoices)
    ) {
      groupedInvoicedProducts = updateGroupedInvoicedProducts(
        groupedInvoicedProducts,
        product
      );
    }
    return groupedInvoicedProducts;
  }, {});

export const getInvoicedProductsGroupedByProductName = (
  invoicedProducts,
  selectedProducts,
  filteredInvoices
) => {
  const mappedInvoiceProducts = mapInvoicedProducts(invoicedProducts);
  return groupInvoicedProductsByProductName(
    mappedInvoiceProducts,
    selectedProducts,
    filteredInvoices
  );
};

export class InvoiceProductInfoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoicedProductsOptions: [],
      isLoading: true,
    };
  }

  /**
   * This has been added in as a short term fix to load invoiced product information for the invoice product search.
   * There is an issue, when loading large accounts(~15K+ invoiced products) where storing the data in either redux
   * or the parent component causes large performance issues to the point that the page is unusable.
   *
   * This component also needs access to a list of all invoiced products in order to calculate the rollup total costs of
   * selected invoiced products. Since the data has been moved into the InvoicedProductsSearchContainer instead of Redux
   * this component no longer has access to that data and must also store it locally.
   *
   * This is a short term fix loading the data directly into the component state in order to make the page usable again.
   *
   * TODO: Future state - A more long term solution needs to be implemented such as search/pagination on the DB/API side.
   *
   * This is related to: https://agrium.atlassian.net/browse/CXH-4202
   */
  componentDidMount() {
    const { accountId, invoiceSummariesLength } = this.props;
    getInvoicedProducts(accountId, invoiceSummariesLength).then((resp) => {
      this.setState({
        invoicedProductsOptions: resp,
        isLoading: false,
      });
    });
  }

  componentWillUnmount() {
    this.setState({
      isLoading: true,
      invoicedProductsOptions: [],
    });
  }

  render() {
    const {
      selectedInvoicedProducts,
      filteredInvoices,
      accountCountryCode,
      flags: { invoiceProductSearch },
    } = this.props;

    const groupedInvoicedProducts = getInvoicedProductsGroupedByProductName(
      this.state.invoicedProductsOptions,
      selectedInvoicedProducts,
      filteredInvoices
    );
    const totalPriceForInvoicedProducts = getTotalPriceForInvoicedProducts(
      groupedInvoicedProducts
    );

    const subProps = {
      deselectInvoicedProduct: this.props.deselectInvoicedProduct,
      gtmViewInvoicedProductsDetailsModal: this.props
        .gtmViewInvoicedProductsDetailsModal,
      groupedInvoicedProducts,
      totalPriceForInvoicedProducts,
      selectedInvoicedProducts,
      isCanada: isCanada(accountCountryCode),
      invoicedProductsOptions: this.state.invoicedProductsOptions,
      isLoading: this.state.isLoading,
    };

    return <>{invoiceProductSearch && <InvoiceProductInfo {...subProps} />}</>;
  }
}

const mapStateToProps = (state) => ({
  selectedInvoicedProducts: getFilteredProductsList(state),
  invoiceSummariesLength: getInvoices(state).length,
});

const mapDispatchToProps = {
  deselectInvoicedProduct,
  gtmViewInvoicedProductsDetailsModal,
};

InvoiceProductInfoContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  accountCountryCode: PropTypes.string.isRequired,
  filteredInvoices: invoicesPropType.isRequired,
  invoicedProductsOptions: invoicedProductsPropType,
  selectedInvoicedProducts: selectedProductsPropType,
  deselectInvoicedProduct: PropTypes.func.isRequired,
  gtmViewInvoicedProductsDetailsModal: PropTypes.func.isRequired,
  invoiceSummariesLength: PropTypes.number.isRequired,
  flags: PropTypes.shape({ invoiceProductSearch: PropTypes.bool }).isRequired,
};

InvoiceProductInfoContainer.defaultProps = {
  invoicedProductsOptions: [],
  selectedInvoicedProducts: [],
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(InvoiceProductInfoContainer);
