import {
  GTM_ACCOUNT_OPEN_ITEMS_INTERACTION,
  GTM_ACCOUNT_OPEN_ITEMS_FILTER_CHANGE,
} from '../gtmConstants';

export const accountOpenItemsInteraction = (action) => ({
  event: 'CXH_OpenItemsInteraction',
  cxh: {
    account: {
      openItems: {
        ...action.payload,
      },
    },
  },
});

export const accountOpenItemsFilterChange = (action) => {
  let userFriendlyLabel;
  switch (action.payload.filterName) {
    case 'typeFilter':
      userFriendlyLabel = 'Type Filter';
      break;
    case 'dueFilter':
      userFriendlyLabel = 'Status Filter';
      break;
    default:
      userFriendlyLabel = action.payload.filterName;
  }

  return {
    event: 'CXH_OpenItemsFilterChange',
    cxh: {
      account: {
        openItems: {
          appliedFilter: {
            ...action.payload,
            filterName: userFriendlyLabel,
          },
        },
      },
    },
  };
};

export const accountOpenItemsEventsMap = {
  [GTM_ACCOUNT_OPEN_ITEMS_INTERACTION]: accountOpenItemsInteraction,
  [GTM_ACCOUNT_OPEN_ITEMS_FILTER_CHANGE]: accountOpenItemsFilterChange,
};
