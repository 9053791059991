import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from './Pagination';

import { dispatchPaginateAction } from './paginationActions';

export const PaginationContainer = ({ onPaginate, ...rest }) => (
  <Pagination onPaginate={onPaginate} {...rest} />
);

PaginationContainer.propTypes = {
  onPaginate: PropTypes.func.isRequired,
};

const mapStateToProp = () => ({});

export const mapDispatchToProps = {
  onPaginate: dispatchPaginateAction,
};

export default connect(mapStateToProp, mapDispatchToProps)(PaginationContainer);
