import React from 'react';
import { DateRange } from 'react-date-range';
import PropTypes from 'prop-types';

import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
import './DateRangePicker.css';

const DateRangePicker = (props) => (
  <DateRange
    moveRangeOnFirstSelection={false}
    onChange={props.onChange}
    ranges={props.ranges}
    className="DateRangePicker"
    minDate={props.minDate}
    maxDate={props.maxDate}
  />
);

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      key: PropTypes.string,
    })
  ).isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
};

export default DateRangePicker;
