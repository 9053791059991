import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import resolveAgribleUrl from 'shared/config/resolveAgribleUrl';

import './AccountMenu.scss';

const AccountMenu = ({
  accounts,
  isAccountMenuOpen,
  dismissAccountMenu,
  isMirroring,
  handleLogout,
  user,
  isCanada,
}) => {
  const flags = useFlags();
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const handleWindowClick = (event) => {
    // The test to ensure className is a string is to prevent an error when Material UI components
    // are clicked. Material UI comppnents set an Object as className
    const { className } = event.target;
    if (typeof className !== 'string' || !/account-menu/.test(className)) {
      if (isAccountMenuOpen) {
        dismissAccountMenu();
      }
    }
  };

  window.onclick = handleWindowClick;

  return (
    <div
      className={`account-menu-container${
        isAccountMenuOpen ? ' open-account-menu' : ''
      }`}
    >
      {isAccountMenuOpen && (
        <div className="account-menu">
          <div className="arrow-up" />
          <div className="account-menu__box-shadow">
            <div className="account-menu--white">
              <div className="account-menu-user">
                <div className="account-menu-item header-user__info">
                  <div
                    className="account-menu-item header-user__info__name"
                    data-hj-suppress=""
                  >
                    {`${user.firstName || user.userName || ''} ${
                      user.lastName || ''
                    }`}
                  </div>
                  <div
                    className="account-menu-item header-user__info__email"
                    data-hj-suppress=""
                  >
                    {`${user.email || ''}`}
                  </div>
                  <div className="account-menu__link">
                    <Link
                      to={`/profile/overview${
                        accounts.selectedAccount
                          ? `?acct=${accounts.selectedAccount.id}`
                          : ''
                      }`}
                      data-test="account-menu-view-profile"
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              </div>
              {accounts.length &&
              flags.globalEcommerce &&
              (!flags.usaSapOrders ||
                (flags.usaSapOrders && !selectedAccount?.usaSapAccount)) &&
              !flags.westernRegionRestrictProducts ? (
                <div>
                  <div className="account-menu-section">
                    <div className="account-menu__link">
                      <Link to="/orders" data-test="account-menu-view-orders">
                        View Online Orders
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="account-menu-section">
                <div className="account-menu__link">
                  <Link to="/contact" data-test="account-menu-contact-us">
                    Contact Us
                  </Link>
                </div>
              </div>
              {flags.cxhAgribleSignOnLink && (
                <div className="account-menu-section">
                  <div className="account-menu__link">
                    <a
                      href={`${resolveAgribleUrl()}/grower-dashboard`}
                      data-test="account-menu-agrible-login"
                    >
                      Go to Agrible
                    </a>
                  </div>
                </div>
              )}
              {/* remove isCanada condition when documents section is enabled to all countries users */}
              {isCanada && flags.ecommContracts && (
                <div className="account-menu-section">
                  <div className="account-menu__link">
                    <Link
                      to="/documents"
                      data-test="account-menu-view-documents"
                    >
                      View Documents
                    </Link>
                  </div>
                </div>
              )}
              <div className="account-menu-section">
                <div
                  className="account-menu__link"
                  data-test="account-menu-sign-out"
                  onClick={() => handleLogout(isMirroring)}
                  onKeyPress={() => handleLogout(isMirroring)}
                  role="button"
                  tabIndex="0"
                >
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AccountMenu.propTypes = {
  user: PropTypes.shape({
    token: PropTypes.shape({
      authToken: PropTypes.string,
      refreshToken: PropTypes.string,
      identityToken: PropTypes.string,
    }),
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    userDetails: PropTypes.shape({
      role: PropTypes.number,
      termsAcceptance: PropTypes.string,
      associatedOrgs: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    userName: PropTypes.string,
  }).isRequired,
  isAccountMenuOpen: PropTypes.bool.isRequired,
  dismissAccountMenu: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isCanada: PropTypes.bool,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    usaSapAccount: PropTypes.bool,
  }).isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      selectedAccount: PropTypes.shape({
        id: PropTypes.string,
        usaSapAccount: PropTypes.bool,
      }),
    })
  ).isRequired,
  isMirroring: PropTypes.bool,
};

AccountMenu.defaultProps = {
  isCanada: false,
  isMirroring: false,
};

export default AccountMenu;
