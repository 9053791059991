import React, { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { typePolicies } from 'shared/gql/typePolicies';

import T from 'prop-types';
import { createHubApolloClient } from '@nutrien/fe-domain-utils';

export const GetNutrienDataClient = () => {
  if (!window.nutrienDataClient) {
    window.nutrienDataClient = createHubApolloClient({
      name: 'cxh-client',
      typePolicies,
    });
  }

  return window.nutrienDataClient;
};

export const NDclient = GetNutrienDataClient();

export default function NutrienDataGraphQLProvider({ children }) {
  const client = useMemo(GetNutrienDataClient, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

NutrienDataGraphQLProvider.propTypes = {
  children: T.node.isRequired,
};
