import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuList,
  MenuItem,
} from 'nutrien-common-components-react/dist/mdc';
import FilterButton from '../FilterButton';
import InvoiceDateRangeModal from '../InvoiceDateRangeModal';
import { filterChoices } from './constants';

class InvoiceDateRangeFilter extends Component {
  constructor(props) {
    super(props);
    this.menuAnchor = React.createRef();
  }

  state = {
    isModalOpen: false,
    isMenuOpen: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { isMenuOpen } = this.state;
    const { gtmToggleInvoiceFilter } = this.props;

    if (isMenuOpen !== prevState.isMenuOpen) {
      gtmToggleInvoiceFilter('invoiceDate', isMenuOpen);
    }
  }

  toggleDatePicker = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  // Handles the choice a user may make from the date range filter drop down.
  handleSelect = ({ value: choice }) => {
    if (choice !== 'CUSTOM') this.props.onConfirm(filterChoices[choice]);
  };

  render() {
    const { value, onConfirm, onClear } = this.props;
    const { isModalOpen, isMenuOpen } = this.state;
    return (
      <>
        <FilterButton
          onClick={() => this.setState({ isMenuOpen: !isMenuOpen })}
          ref={this.menuAnchor}
          value={value}
          icon="calendar"
        />

        <Menu
          open={isMenuOpen}
          onClose={() => this.setState({ isMenuOpen: false })}
          anchorElement={this.menuAnchor.current}
          onSelect={this.handleSelect}
        >
          <MenuList selectedIndex={0}>
            <MenuItem value="ALL">All</MenuItem>
            <MenuItem value="LAST_30_DAYS">Last 30 Days</MenuItem>
            <MenuItem value="LAST_60_DAYS">Last 60 Days</MenuItem>
            <MenuItem value="YEAR_TO_DATE">Year-to-date</MenuItem>
            <MenuItem value="LAST_YEAR">Last Year</MenuItem>
            <MenuItem
              value="CUSTOM"
              onClick={() => this.setState({ isModalOpen: true })}
            >
              Custom Date
            </MenuItem>
          </MenuList>
        </Menu>
        <InvoiceDateRangeModal
          onAccept={onConfirm}
          onClear={onClear}
          initialSelection={typeof value === 'string' ? [] : value}
          open={isModalOpen}
          onClosed={() => {
            this.setState({ isModalOpen: false });
          }}
        />
      </>
    );
  }
}

InvoiceDateRangeFilter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onConfirm: PropTypes.func,
  onClear: PropTypes.func,
  gtmToggleInvoiceFilter: PropTypes.func.isRequired,
};

InvoiceDateRangeFilter.defaultProps = {
  value: undefined,
  onConfirm: () => {},
  onClear: () => {},
};

export default InvoiceDateRangeFilter;
