import { useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import isFunction from 'lodash/isFunction';

import * as termsActions from 'components/Terms/termsActions';
import {
  gtmAcceptTermsOfService,
  gtmCancelTermsOfService,
} from 'store/middleware/TagManager/gtmActions';
import { selectUserTermsAccepted } from 'selectors/userSelectors';
import { logout } from 'components/Authentication/actions';

export const useTermsAccepted = () => useSelector(selectUserTermsAccepted());

export const useTermsActions = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      ...Object.entries(termsActions).reduce(
        (actions, [key, action]) => ({
          ...actions,
          ...(isFunction(action) && {
            [key]: (...args) => dispatch(action(...args)),
          }),
        }),
        {}
      ),
      acceptTerms: (...args) => {
        dispatch(gtmAcceptTermsOfService());
        dispatch(termsActions.updateTermsAcceptance(...args));
      },
      declineTerms: (route = '/') => {
        dispatch(gtmCancelTermsOfService());
        dispatch(logout(route));
      },
    }),
    [dispatch]
  );
};

export const useTerms = () => useSelector(({ terms }) => terms, shallowEqual);

export default () => ({
  terms: useTerms(),
  termsAccepted: useTermsAccepted(),
  ...useTermsActions(),
});
