import { combineReducers } from 'redux';
import {
  NO_USER,
  FETCHING_USER,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  CLEAR_USER_ERROR,
  USER_FETCHED,
  USER_FETCH_ERROR,
  FETCH_AUTH0_USER_METADATA_SUCCESS,
  UPDATE_AUTH0_USER_METADATA_SUCCESS,
  FETCH_AUTH0_USER_METADATA_FAILURE,
  UPDATE_AUTH0_USER_METADATA_FAILURE,
  AUTH0_REDIRECT,
} from 'shared/config/userConstants';
import { UPDATE_TERMS_ACCEPTANCE_SUCCESS } from 'components/Terms/termsConstants';
import { TOGGLE_PRIVACY_PREFERENCE_SUCCESS } from 'components/Profile/Tabs/BillingTab/actions';
import { NOTIFICATIONS_PREFERENCES_UPDATE_SUCCESS } from 'components/Notifications/NotificationsPreferences/actions/constants';
import { UPDATE_SHOW_ORDER_CONDITIONS } from 'components/Cart/actions/actionTypes';
import {
  UPDATE_SHOW_PRODUCT_MODAL,
  DISABLE_SHOW_PRODUCT_MODAL,
} from 'components/Shop/actions/constants';

export const userData = (state = {}, action) => {
  switch (action.type) {
    case USER_FETCH_SUCCESS:
      window.localStorage.setItem(
        'nutrien:user:id',
        action.payload.user.data.id
      );
      return action.payload.user.data;
    case USER_LOGIN_SUCCESS:
      return action.payload;
    case USER_FETCH_FAILURE:
    case USER_LOGIN_FAILURE:
    case NO_USER:
      return {};
    case UPDATE_TERMS_ACCEPTANCE_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          termsAcceptance: action.payload,
        },
      };
    case UPDATE_SHOW_ORDER_CONDITIONS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          showOrderConditions: action.data,
        },
      };
    case UPDATE_SHOW_PRODUCT_MODAL:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          showProductModal: action.data,
        },
      };
    case DISABLE_SHOW_PRODUCT_MODAL:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          showProductModal: false,
        },
      };
    case TOGGLE_PRIVACY_PREFERENCE_SUCCESS: {
      return {
        ...state,
        privacy: action.privacyPreferences,
      };
    }
    case NOTIFICATIONS_PREFERENCES_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.preferences,
      };
    case FETCH_AUTH0_USER_METADATA_SUCCESS:
    case UPDATE_AUTH0_USER_METADATA_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export const status = (state = 'NO_USER', action) => {
  switch (action.type) {
    case FETCHING_USER:
    case NO_USER:
      return action.type;
    case USER_LOGIN_SUCCESS:
    case USER_FETCH_SUCCESS:
      return USER_FETCHED;
    case USER_FETCH_FAILURE:
    case USER_LOGIN_FAILURE:
      return USER_FETCH_ERROR;
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case USER_FETCH_SUCCESS:
    case USER_LOGIN_SUCCESS:
    case CLEAR_USER_ERROR:
    case NO_USER:
      return null;
    case USER_FETCH_FAILURE:
    case USER_LOGIN_FAILURE:
    case FETCH_AUTH0_USER_METADATA_FAILURE:
    case UPDATE_AUTH0_USER_METADATA_FAILURE:
      if (action.error.response) return action.error.response;
      return action.error;
    default:
      return state;
  }
};

export const auth0Login = (state = false, action) => {
  switch (action.type) {
    case AUTH0_REDIRECT:
      return true;
    default:
      return state;
  }
};

export default combineReducers({ userData, status, error, auth0Login });
