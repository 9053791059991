import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import KEY_ENUM from 'shared/utils/KeyCodes';

// dependencies
import './FormCheckbox.css';
import styles from './styles';

class FormCheckbox extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value)
      this.checkboxRef.checked = nextProps.value;
  }

  render() {
    const {
      name,
      value,
      handleChange,
      description,
      errorClass,
      classes,
    } = this.props;

    return (
      <label htmlFor={name} className="form-card-checkbox">
        <input
          type="checkbox"
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          onKeyPress={(e) => {
            e.stopPropagation();
            if (e.key === KEY_ENUM.ENTER) {
              e.target.checked = !e.target.checked;
              handleChange({ target: { name, value } });
            }
          }}
          role="button"
          tabIndex="0"
          ref={(ref) => {
            this.checkboxRef = ref;
          }}
          checked={value ? 'checked' : ''}
        />
        {this.props.errorClass ? <span className={errorClass} /> : <span />}
        <div className={classes.description}>{description}</div>
      </label>
    );
  }
}

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  description: PropTypes.node.isRequired,
  errorClass: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

FormCheckbox.defaultProps = {
  errorClass: '',
};

export default withStyles(styles)(FormCheckbox);
