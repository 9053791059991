import downloadFile from 'shared/utils/DownloadFile';
import * as invoicesService from '../invoicesService';
import downloadInvoicesFailure from './downloadInvoicesFailure';
import downloadInvoicesInProgress from './downloadInvoicesInProgress';
import downloadInvoicesSuccess from './downloadInvoicesSuccess';

export default (accountId, invoiceIds) => (dispatch) => {
  dispatch(downloadInvoicesInProgress());
  return invoicesService
    .getInvoiceAsOFX(accountId, invoiceIds)
    .then((response) => {
      if (response) {
        dispatch(downloadInvoicesSuccess());
        return downloadFile(response, `account-${accountId}-selected.ofx`);
      }
      return dispatch(downloadInvoicesFailure());
    })
    .catch(() => dispatch(downloadInvoicesFailure()));
};
