import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';

import PurchasesCard from './PurchasesCard';

import fetchPurchases from './fetchPurchases';
import { setPurchasesFilter } from './PurchasesActionCreators';
import { setNewTimeframeForProductHistory } from '../../ProductHistory/ProductHistoryPage/TimeframeSelector/TimeframeSelectorActions';

const mapStateToProps = (state) => ({
  user: state.user,
  purchases: state.purchases,
  selectedAccount: state.selectedAccount,
});

const mapDispatchToProps = {
  fetchPurchases,
  setPurchasesFilter,
  setNewTimeframeForProductHistory,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(PurchasesCard);
