/* eslint-disable no-nested-ternary */
import { verifyUsePackageUom } from 'utils/verifyUsePackageUom';

const flatten = require('lodash/flatten');
const each = require('lodash/each');
const get = require('lodash/get');

const getProductIds = (data) =>
  flatten(
    data.map((product) =>
      product.attributes.variants.map((variant) => variant.ars_key)
    )
  );

const getProductCrossSellIds = (data) =>
  flatten(
    data.map((product) => product.variants.map((variant) => variant.ars_key))
  );

const filterByCategory = (options, data) => {
  let result;
  each(options, (value, key) => {
    result = data.filter(
      (p) => get(p, key, '').toLowerCase() === value.toLowerCase()
    );
  });
  return result;
};

const matchAndAdd = (locationCode, variant, productsWithPrices) => {
  let matchedProd = productsWithPrices.find(
    (product) => product.productId === variant.ars_key
  );

  if (!matchedProd) {
    matchedProd = { accountId: '', price: -1, netPrice: -1 };
  }
  const usePackageUom = verifyUsePackageUom({
    id: matchedProd.accountId,
    locationCode,
  });

  if (usePackageUom) {
    // eslint-disable-next-line no-param-reassign
    variant.total_volume_options =
      // eslint-disable-next-line no-undef
      variant.total_volume_options.map((_val, index) => index + 1);
    matchedProd.netPrice = matchedProd.packageUomPrice;
  }

  // Products without price represented with -1, map to null
  const price = matchedProd.price < 0 ? null : matchedProd.price;
  // eslint-disable-next-line camelcase
  const net_price =
    // matchedProd.quotePrice > 0
    //   ? matchedProd.quotePrice
    //   : matchedProd.netPrice < 0
    //   ? null
    //   : matchedProd.netPrice;
    matchedProd.netPrice < 0 ? null : matchedProd.netPrice;
  const price_type = matchedProd.type; // eslint-disable-line camelcase
  const quote = matchedProd.quoteId ? true : null;
  const quoteId = matchedProd.quoteId ? matchedProd.quoteId : '';

  return {
    ...variant,
    price,
    net_price,
    price_type,
    quote,
    quoteId,
    packageUom: matchedProd.packageUom,
  };
};

const matchAndAddCrossSell = (locationCode, crossSell, productsWithPrices) => ({
  ...crossSell,
  variants: crossSell.variants.map((variant) =>
    matchAndAdd(locationCode, variant, productsWithPrices)
  ),
});

const injectPricesIntoProducts = (locationCode, products, pricesResponse) => {
  const pricesResponseWithNullsRemoved = pricesResponse.filter(
    (product) => product.price !== null
  );
  return products.map((product) => ({
    ...product,
    attributes: {
      ...product.attributes,
      variants: product.attributes.variants.map((variant) =>
        matchAndAdd(locationCode, variant, pricesResponseWithNullsRemoved)
      ),
    },
  }));
};

const injectPricesIntoCrossSells = (
  locationCode,
  crossSells,
  pricesResponse
) => {
  const pricesResponseWithNullsRemoved = pricesResponse.filter(
    (p) => p !== null
  );
  return crossSells.map((crossSell) =>
    matchAndAddCrossSell(
      locationCode,
      crossSell,
      pricesResponseWithNullsRemoved
    )
  );
};

const removeDisabledProducts = (products) =>
  products.filter((product) => {
    if (product.enabled) {
      product.variants = product.variants.filter((variant) => variant.enabled); // eslint-disable-line

      return product.variants.length;
    }
    return undefined;
  });

export {
  getProductIds,
  getProductCrossSellIds,
  filterByCategory,
  injectPricesIntoProducts,
  injectPricesIntoCrossSells,
  matchAndAdd,
  removeDisabledProducts,
};
