import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider as MuiThemeProvider } from '@nutrien/uet-react/styles';
import theme from '@nutrien/uet-theme';
import { MuiPickersUtilsProvider } from '@nutrien/uet-react';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import resolveAudience from 'utils/resolveAuth0Audience';
import resolveApiUrl from 'shared/config/resolveApiUrl';
import resolveAuth0Client from 'shared/config/resolveAuth0Client';
import { AUTH0_REDIRECT } from 'shared/config/userConstants';

import history from 'history.js';
import store, { persistor } from 'store';

import AppWrapper from './App';

const AppContainer = () => {
  return (
    <div className="App">
      <Auth0Provider
        {...resolveAuth0Client()}
        redirectUri={window.location.origin}
        audience={resolveAudience(resolveApiUrl())}
        scope="profile"
        onRedirectCallback={({ returnTo }) => {
          store.dispatch({ type: AUTH0_REDIRECT, payload: { returnTo } });
          if (returnTo) {
            history.replace(returnTo);
          }
        }}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <CssBaseline />
                  <ScrollToTop />
                  <AppWrapper />
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </Auth0Provider>
    </div>
  );
};

export default AppContainer;
