import { GTM_EC_PRICINGPOPOVER } from '../gtmConstants';

export const pricingPopover = (action) => ({
  event: 'EC_PricingPopover',
  cxh: {
    pricingPopoverLocation: action.payload.origin,
  },
});

export const priceMissingPopoverMap = {
  [GTM_EC_PRICINGPOPOVER]: pricingPopover,
};
