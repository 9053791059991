import React from 'react';
import { Route } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import LicensesPage from './LicensesPage';

const Licenses = ({ match }) => (
  <Route exact path={`${match.path}/`} component={LicensesPage} />
);

Licenses.propTypes = { match: ReactRouterPropTypes.match.isRequired };

export default Licenses;
