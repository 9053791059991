import React from 'react';
import propTypes from 'prop-types';
import Delete from '@material-ui/icons/Delete';
import { TableCell } from 'nutrien-common-components-react/dist/mdc/Table';
import currency from 'shared/utils/numberFormatters/currency';
import TableRowBuilder from '../TableHelpers/TableRowBuilder';

const columns = [
  {
    propertyName: 'scheduledDate',
    className: 'nutrien-table__cell__fixed',
    format: (details) =>
      details.scheduledDate
        ? new Date(details.scheduledDate).toLocaleDateString()
        : null,
  },
  {
    propertyName: 'invoiceId',
    format: (details) =>
      details.invoiceIds && details.invoiceIds.length > 1
        ? 'Multiple'
        : details.invoiceIds,
    className: 'nutrien-table__cell__fixed',
  },
  {
    propertyName: 'paymentType',
    className: 'nutrien-table__cell__fixed',
  },
  {},
  {
    propertyName: 'amount',
    format: (details) => (details.amount ? currency(details.amount) : null),
    className: 'payments-table__right-align',
  },
];

const ScheduledPaymentRow = (props) => {
  const { details } = props;

  return (
    <TableRowBuilder data={details} columns={columns}>
      <TableCell className="payments-table__final-col">
        <button
          className="payments-table__delete-button"
          onClick={() => {
            props.handlePaymentDelete(details);
          }}
          style={{ verticalAlign: 'text-top' }}
        >
          <Delete
            className="cart-item__close-icon"
            onClick={() => {}}
            color="action"
          />
        </button>
      </TableCell>
    </TableRowBuilder>
  );
};

const ScheduledPaymentDetailsPropTypes = {
  scheduledPaymentId: propTypes.string,
  invoiceId: propTypes.number,
  paymentType: propTypes.string,
  amount: propTypes.string,
  scheduledDate: propTypes.date,
};

ScheduledPaymentRow.propTypes = {
  details: propTypes.shape(ScheduledPaymentDetailsPropTypes).isRequired,
  handlePaymentDelete: propTypes.func.isRequired,
};

export default ScheduledPaymentRow;
