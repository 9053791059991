import { LOCATION_CHANGE } from 'connected-react-router';
import combineEvents from '@redux-beacon/combine-events';
import {} from '../../../../components/Cart/actions/actionTypes';
import {
  cartItemToProductFieldObject,
  pageviewCheckoutStepFactory,
  accountLocationCodeToCurrency,
} from '../utils';
import {
  GTM_LOG_CART_CHECKOUT_STEP_1,
  GTM_LOGISTICS_PAGE,
  GTM_SERVICES_PAGE,
  EVENT_TRACK,
} from '../gtmConstants';
import {
  MixpanelEvents,
  MixpanelProperties,
  VIEW_LOGISTICS,
  VIEW_SERVICES,
} from '../../MixPanel/mixPanelConstants';

/*
Checkout step 1: click the reserve order button which loads up the modal
 */
export const cartCheckoutStep1 = (
  { payload: { products } },
  { selectedAccount: { locationCode } }
) => {
  const productObjects = products.map((product) =>
    cartItemToProductFieldObject(product)
  );
  return {
    event: 'EEC_CheckoutStep1',
    ecommerce: {
      currencyCode: accountLocationCodeToCurrency(locationCode),
      checkout: {
        actionField: {
          step: 1,
        },
        products: productObjects,
      },
    },
  };
};

export const cartCheckoutStep2 = pageviewCheckoutStepFactory(
  2,
  '/cart/services'
);

export const cartLogisticsView = (_, { selectedAccount, cart }) => {
  const { cartData, preferences, isPickup } = cart;
  window.mixpanel.track(MixpanelEvents.LOGISTICS_COMPLETE, {
    [MixpanelProperties.BRANCH_ID]: selectedAccount.branchId,
    [MixpanelProperties.BRANCH_NAME]: selectedAccount.branchName,
    [MixpanelProperties.ACCOUNT_ID]: selectedAccount.id,
    [MixpanelProperties.ACCOUNT_NAME]: selectedAccount.name,
    [MixpanelProperties.CART_ITEMS]: cartData.length,
    [MixpanelProperties.PICKUP_DATE]: preferences.date,
    [MixpanelProperties.BRANCH_PICKUP]: !!isPickup,
    [MixpanelProperties.ORDER_MEMO]: preferences.orderMemo,
  });

  window.mixpanel.people.set_once({
    [MixpanelProperties.FIRST_LOGISTICS_PAGEVIEW]: new Date().toISOString(),
  });

  window.mixpanel.people.set({
    [MixpanelProperties.LAST_LOGISTICS_PAGEVIEW]: new Date().toISOString(),
  });
  window.mixpanel.people.increment(
    MixpanelProperties.LIFETIME_LOGISTIC_PAGEVIEW
  );

  return {
    event: EVENT_TRACK,
    eventId: GTM_LOGISTICS_PAGE,
    'branch id': selectedAccount.branchId,
    'branch name': selectedAccount.branchName,
    'account id': selectedAccount.id,
    'account name': selectedAccount.name,
    'number of cart items': cartData.length,
    'pickup date': preferences.date,
    'branch pickup': !!isPickup,
    notes: preferences.orderMemo,
  };
};

export const cartServicesView = (action, { cart, selectedAccount }) => {
  const { preferences } = cart;
  window.mixpanel.track(MixpanelEvents.SERVICES_COMPLETE, {
    [MixpanelProperties.BRANCH_ID]: selectedAccount.branchId,
    [MixpanelProperties.BRANCH_NAME]: selectedAccount.branchName,
    [MixpanelProperties.ACCOUNT_ID]: selectedAccount.id,
    [MixpanelProperties.ACCOUNT_NAME]: selectedAccount.name,
    [MixpanelProperties.APPLICATION]: preferences.isCustomApplied,
    ...(!!preferences.isCustomApplied && {
      [MixpanelProperties.APPLICATION_MEMO]: preferences.applicationMemo,
    }),
  });

  window.mixpanel.people.set_once({
    [MixpanelProperties.FIRST_SERVICES_PAGEVIEW]: new Date().toISOString(),
  });

  window.mixpanel.people.set({
    [MixpanelProperties.LAST_SERVICES_PAGEVIEW]: new Date().toISOString(),
  });

  window.mixpanel.people.increment(
    MixpanelProperties.LIFETIME_SERVICES_PAGEVIEW
  );

  return {
    event: EVENT_TRACK,
    eventId: GTM_SERVICES_PAGE,
    'branch id': selectedAccount.branchId,
    'branch name': selectedAccount.branchName,
    'account id': selectedAccount.id,
    'application services': preferences.isCustomApplied,
    ...(!!preferences.isCustomApplied && {
      'application instructions': preferences.applicationMemo,
    }),
  };
};

export const cartCheckoutStep3 = pageviewCheckoutStepFactory(
  3,
  '/cart/logistics'
);
export const cartCheckoutStep4 = pageviewCheckoutStepFactory(4, '/cart/review');

export const checkoutEventsMap = {
  [VIEW_LOGISTICS]: cartLogisticsView,
  [VIEW_SERVICES]: cartServicesView,
  [GTM_LOG_CART_CHECKOUT_STEP_1]: cartCheckoutStep1,
  [LOCATION_CHANGE]: combineEvents(
    cartCheckoutStep2,
    cartCheckoutStep3,
    cartCheckoutStep4
  ),
};
