export const defaultRole = ['admin'];

export const defaultPermissions = {
  notifications: {
    read: true,
    delete: true,
  },
  accounts: {
    create: true,
    read: true,
    update: true,
  },
  invoices: {
    create: true,
    read: true,
    update: true,
  },
  statements: {
    create: true,
    read: true,
    update: true,
  },
  licensing: {
    read: true,
  },
  sdsAndLabels: {
    read: true,
  },
  mirroring: {
    fullParity: true,
  },
};
