import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import numeral from 'numeral';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { checkTotalInput } from 'utils/checkTotalInput';
import { volumeOrQuantity } from 'shared/utils/VolumeOrQuantity/volumeOrQuantity';
import { getUnitOfMeasureLong } from 'components/Shop/utils/unitMeasuresLong';
import { getProductInfo } from 'components/Shop/components/MiniCart/MiniCartUtils';
import miniCartPropTypes from 'components/Shop/components/MiniCart/miniCartPropTypes';
import variantOptionName from 'components/Shop/utils/variantOptionName';
import validateSapUnitOfMeasure from 'components/Shop/utils/validateSapUnitOfMeasure';
import NetPricingFeatureFlag from 'shared/ui/NetPricingFeatureFlag';
import TotalNetPricingFeatureFlag from 'shared/ui/TotalNetPricingFeatureFlag';
import { NO_PRICE_SECONDARY_MESSAGE } from 'shared/constants/ecommMessageText';
import ProductTotalVolumeDropdown from '../../../../containers/ProductTotalVolumeDropdownContainer';
import ProductTotalVolumeTextbox from '../../../../containers/ProductTotalVolumeTextboxContainer';
import ProductTotalCountTextbox from '../../../../containers/ProductTotalCountTextboxContainer';
import { useUserLocationContext } from '../../../../utils/contexts/locationCodeContext';
import { CAN } from '../../../../utils/contexts/locationCodeConstants';

import './styles.scss';

const TotalInputType = ({
  isBulk,
  mostRecentAddition,
  setTotalVolume,
  totalVolume,
  updateCartData,
  usesPackageUom,
}) => {
  // eslint-disable-next-line no-nested-ternary
  return isBulk ? (
    <ProductTotalVolumeTextbox
      data-test="minicart-item-total-volume-textbox"
      variant={mostRecentAddition.variant}
      value={totalVolume}
      modifiesCartItem
      setTotalVolume={setTotalVolume}
      updateCartData={updateCartData}
    />
  ) : usesPackageUom ? (
    <ProductTotalCountTextbox
      data-test="minicart-item-total-count-textbox"
      value={totalVolume}
      modifiesCartItem
      setTotalVolume={setTotalVolume}
      updateCartData={updateCartData}
    />
  ) : (
    <ProductTotalVolumeDropdown
      data-test="minicart-item-total-volume-dropdown"
      variant={mostRecentAddition.variant}
      value={totalVolume}
      onChange={(value) => {
        setTotalVolume(value);
        updateCartData(value);
      }}
    />
  );
};

const getPricePer = (mostRecentAddition) => {
  const pricePer = Number(get(mostRecentAddition, 'variant.price', 0));
  if (!pricePer) {
    return false;
  }
  return numeral(`${pricePer}`).format('$0,0.00');
};

const MiniCartItem = ({
  cart: { mostRecentAddition },
  displayPrice,
  totalNetPrice,
  setTotalVolume,
  totalVolume,
  updateCartData,
  isBulk,
  usesPackageUom,
}) => {
  const { displayNetPricing } = useFlags();
  const isCanada = useUserLocationContext() === CAN;

  if (!mostRecentAddition.product || !mostRecentAddition.variant) return null;

  const { hasError, errorMessage } = checkTotalInput(
    isCanada,
    usesPackageUom,
    mostRecentAddition.variant,
    totalVolume
  );

  const typeLabel = volumeOrQuantity(isCanada, usesPackageUom);
  const uom = get(mostRecentAddition, 'variant.unit_measure', '');
  const typeUOMLabel = getUnitOfMeasureLong(uom);

  const productInfo = getProductInfo(mostRecentAddition, isCanada);

  const pricePer = getPricePer(mostRecentAddition);

  const unitOfMeasureLabel = 'Pack Size';

  const unitOfMeasureValue = variantOptionName(
    get(mostRecentAddition, 'variant'),
    isCanada
  );

  return (
    <div className="mini-cart__item" data-test="mini-cart-item">
      <div className="mini-cart__item__row">
        <span className="mini-cart__item-header">Category</span>
        <span className="mini-cart__item-data">{productInfo}</span>
      </div>
      <div className="mini-cart__item__row">
        <span
          className="mini-cart__item-header"
          data-test="mini-cart-pack-size"
        >
          {unitOfMeasureLabel}
        </span>
        <span
          className="mini-cart__item-data"
          data-test="mini-cart-cart-item-data"
        >
          {unitOfMeasureValue}
        </span>
      </div>
      <div className="mini-cart__item__row--centered">
        <span
          className="mini-cart__item-header"
          data-test="mini-cart-volume-or-quantity"
        >
          {typeLabel}
        </span>
        <span className="mini-cart__item-input">
          <span className="mini-cart__item-input--input">
            <TotalInputType
              isBulk={isBulk}
              mostRecentAddition={mostRecentAddition}
              setTotalVolume={setTotalVolume}
              totalVolume={totalVolume}
              updateCartData={updateCartData}
              usesPackageUom={usesPackageUom}
            />
          </span>
          <span className="mini-cart__unit-of-measure-text">
            {validateSapUnitOfMeasure(uom)}
          </span>
        </span>
      </div>
      <div className="mini-cart__input-message-container">
        {hasError && (
          <span
            className="mini-cart__input-message--error"
            data-test="mini-cart__input-message--error"
          >
            {errorMessage}
          </span>
        )}
      </div>
      <div className="mini-cart__item__row">
        <span className="mini-cart__item-header">Price</span>
        <span
          className="mini-cart__item-data"
          data-test="mini-cart__item-price-per"
        >
          {pricePer
            ? NetPricingFeatureFlag(
                mostRecentAddition.variant,
                ` per ${typeUOMLabel}`,
                displayNetPricing
              )
            : NO_PRICE_SECONDARY_MESSAGE}
        </span>
      </div>
      <div className="mini-cart__item__row">
        <span className="mini-cart__item-header">Total Price</span>
        <div
          className="mini-cart__item-data"
          data-test="mini-cart__item-display-price"
        >
          {pricePer ? (
            TotalNetPricingFeatureFlag(totalNetPrice, displayPrice)
          ) : (
            <span className="mini-cart__item-data--unavailable">
              {NO_PRICE_SECONDARY_MESSAGE}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

TotalInputType.propTypes = {
  isBulk: PropTypes.bool.isRequired,
  mostRecentAddition: PropTypes.shape({
    variant: PropTypes.shape({
      net_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      unit_measure: PropTypes.string,
      unit_qty: PropTypes.number,
      unit_size: PropTypes.number,
      is_bulk: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  setTotalVolume: PropTypes.func.isRequired,
  totalVolume: PropTypes.string.isRequired,
  updateCartData: PropTypes.func.isRequired,
  usesPackageUom: PropTypes.bool.isRequired,
};

MiniCartItem.propTypes = {
  cart: miniCartPropTypes.isRequired,
  displayPrice: PropTypes.string,
  totalNetPrice: PropTypes.number.isRequired,
  setTotalVolume: PropTypes.func.isRequired,
  totalVolume: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  updateCartData: PropTypes.func.isRequired,
  isBulk: PropTypes.bool.isRequired,
  usesPackageUom: PropTypes.bool.isRequired,
};

MiniCartItem.defaultProps = {
  displayPrice: '0',
};

export { MiniCartItem, TotalInputType };

export default MiniCartItem;
