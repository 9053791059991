import { BANK_ACCOUNT_TYPES, COUNTRY_CODES } from '../../PaymentConstants';

export const serviceUnavailableErrorMessage =
  'We are having technical issues, please try to place a payment later.';

export const genericErrorTitle = 'There was an error.';

export const genericErrorBody =
  'Please try again or contact your local branch to complete a payment.';

export const encryptionHelpText =
  'All payment transactions utilize encryption to keep your payment and account data safe.';

export const tokenAlreadySavedText = `You’ve already stored this account for future payments. 
Please add new account information or select the previously added account to proceed with payment.`;

export const optionalFields = ['firstName', 'lastName', 'addressLine2'];

export const MAX_ROUTING_LENGTH = 9;
export const MAX_BANK_ACCOUNT_NUMBER_LENGTH = 17;
export const MAX_PHONE_LENGTH = 10;

export const MAX_LENGTH = {
  ROUTING: 9,
  ACCOUNT_NUMBER_US: 17,
  ACCOUNT_NUMBER_CAN: 12,
  TRANSIT_NUMBER: 5,
  INSTUTITION_NUMBER: 3,
  PHONE: 10,
};

export const RADIO_BUTTON_FIELDS = {
  ACCOUNT_TYPE: 'accountType',
  BANK_ACCOUNT_TYPE: 'bankAccountType',
};

export const DYNAMICALLY_OPTIONAL_FIELDS = {
  [BANK_ACCOUNT_TYPES.CORPORATE]: ['firstName', 'lastName'],
  [BANK_ACCOUNT_TYPES.PERSONAL]: ['corporateName'],
  Amount: ['amountEntered'],
  recurringPaymentFrequency: ['recurringPaymentFrequency'],
  recurringEndDate: ['recurringEndDate'],
  [COUNTRY_CODES.CANADA]: [
    'bankAccountNumber',
    'confirmBankAccountNumber',
    'routingNumber',
  ],
  [COUNTRY_CODES.USA]: [
    'transitNumber',
    'institutionNumber',
    'accountNumber',
    'confirmAccountNumber',
  ],
};

export const NEW_BANK_ACCOUNT = 'newBankAccount';

export const FORM_FIELDS = [
  'routingNumber',
  'bankAccountNumber',
  'confirmBankAccountNumber',
  'addressLine1',
  'addressLine2',
  'country',
  'state',
  'city',
  'zip',
  'phone',
  'firstName',
  'lastName',
  'corporateName',
  'transitNumber',
  'institutionNumber',
  'accountNumber',
  'confirmAccountNumber',
];

export const PAYMENT_AMOUNT_TYPES = {
  CurrentBalance: 'CurrentBalance',
  AmountDue: 'AmountDue',
  Other: 'Other',
};

export const STATE_LABELS = {
  STATE: 'State',
  PROVINCE: 'Province',
};

export const ZIP_FIELD_LENGTH = {
  CAN: '7',
  US: '5',
};
