import resolveApiEnv, {
  DEV,
  SIT,
  PRE,
  PROD,
} from 'shared/config/resolveApiEnv';

const urls = {
  [DEV]: 'https://api.dev.cps-core.com/core-auth/',
  [SIT]: 'https://api.sit.cps-core.com/core-auth/',
  [PRE]: 'https://api.pre.cps-core.com/core-auth/',
  [PROD]: 'https://api.cps-core.com/core-auth/',
};

export const resolveCoreAuthApiUrl = () => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_CORE_AUTH_API_URI
  ) {
    return process.env.REACT_APP_CORE_AUTH_API_URI;
  }

  const env = resolveApiEnv();
  return urls[env];
};

export default resolveCoreAuthApiUrl;
