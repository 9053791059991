import { LOGOUT } from 'components/Authentication/actions/authenticationConstants';

import { EMPLOYEE_INFO_FETCH_SUCCESS } from '../../actions';
import { EMPLOYEE_LOGGED_IN } from '../actions';

export default (state = false, action) => {
  switch (action.type) {
    case EMPLOYEE_LOGGED_IN:
    case EMPLOYEE_INFO_FETCH_SUCCESS:
      return true;
    case LOGOUT:
      return false;
    default:
      return state;
  }
};
