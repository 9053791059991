import React from 'react';
import PropTypes from 'prop-types';
import Card from '@nutrien/uet-react/Card';
import { connect } from 'react-redux';
import downloadAppStore from 'assets/download_AppStore.png';
import downloadGooglePlay from 'assets/download_GooglePlay.png';
import xarvioApp from 'assets/partners_xarvio.jpg';
import {
  gtmClickAppleXarvioApp,
  gtmClickGoogleXarvioApp,
} from '../../store/middleware/TagManager/gtmActions';
import './Partners.scss';
import PageHeader from '../../shared/ui/PageHeader/PageHeader';

const Partners = ({
  handleGtmClickAppleXarvioApp,
  handleGtmClickGoogleXarvioApp,
}) => (
  <div className="partners">
    <PageHeader
      title="Nutrien Featured Digital Partners"
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Partners',
        },
      ]}
    />
    <Card>
      <h2 className="partners__subheader">Xarvio Scouting App</h2>
      <div className="partners__content-container">
        <div className="partners__content-container__left">
          <img
            className="partners__banner-image"
            src={xarvioApp}
            alt="Xarvio Scouting App"
          />
        </div>
        <div className="partners__content-container__right">
          <h3>Get Scouting Now!</h3>
          Your smartphone can now tell you which weeds and diseases you are
          seeing. Using image recognition technology, you simply take a picture
          and the Scouting App will tell you in seconds what weeds or diseases
          you are looking at and map the location. Get a head start on weed and
          disease control with the easy-to-use Scouting App. Simply download it
          for free from the App Store or Google Play and see for yourself!
          <div className="partners__download-button-container">
            <a
              href="https://apps.apple.com/us/app/scouting/id1297988349"
              onClick={handleGtmClickAppleXarvioApp}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                data-test="partners-xarvio-app-store-download"
                src={downloadAppStore}
                alt="Download on the App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.bayer.cs.scouting&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              onClick={handleGtmClickGoogleXarvioApp}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                data-test="partners-xarvio-google-play-download"
                src={downloadGooglePlay}
                alt="Get it on Google Play"
              />
            </a>
          </div>
        </div>
      </div>
    </Card>
  </div>
);

const mapDispatchToProps = {
  gtmClickAppleXarvioApp,
  gtmClickGoogleXarvioApp,
};

Partners.propTypes = {
  handleGtmClickAppleXarvioApp: PropTypes.func.isRequired,
  handleGtmClickGoogleXarvioApp: PropTypes.func.isRequired,
};

export default connect(undefined, mapDispatchToProps)(Partners);
