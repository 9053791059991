import get from 'lodash/get';
import { CUSTOM_DIMENSIONS } from '../gtmConstants';
import { gtmCurrency } from './gtmCurrency';

/**
 * Converts a cartItem into a ProductFieldObject as defined by
 * the Enhanced Ecommerce documentation:
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#product-data
 *
 * This function represents the initial transform,
 * so any advanced logic of modifications to the productFieldObject
 * (like setting a different quantity)
 * should happen outside of this function so that it stays general.
 *
 * @param {Object} cartItem - a "line item" in the cart
 */
export const cartItemToProductFieldObject = (cartItem) => {
  const price = gtmCurrency(get(cartItem, 'variant.net_price', 0)); // eslint-disable-line camelcase
  const quantity = get(cartItem, 'quantity')
    ? get(cartItem, 'quantity')
    : get(cartItem, 'product.total_volume');

  return {
    id: get(cartItem, 'product.attributes.guid'),
    name: get(cartItem, 'product.attributes.name'),
    price,
    brand: get(cartItem, 'product.attributes.manufacturers[0].name', undefined),
    category: get(cartItem, 'product.attributes.categories[0].name', undefined),
    variant: get(cartItem, 'variantSku'),
    quantity,
    position: get(cartItem, 'position', undefined),
    [CUSTOM_DIMENSIONS.variantSku]: get(cartItem, 'variantSku'),
    [CUSTOM_DIMENSIONS.variantDescription]: get(cartItem, 'variantDescription'),
    [CUSTOM_DIMENSIONS.variantIsPriced]: price !== '0.00',
  };
};

export default cartItemToProductFieldObject;
