import { createSelector } from 'reselect';
import { get } from 'lodash';
import currency from 'shared/utils/numberFormatters/currency';
import getInvoice from './getInvoice';

export default createSelector(getInvoice, (invoice) => {
  if (!invoice) {
    return '';
  }

  const originalAmountDue = get(invoice, 'originalAmountDue', 0);

  return currency(originalAmountDue);
});
