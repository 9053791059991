// dependencies
import React, { Component } from 'react';

// shared UI
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import PageHeader from 'shared/ui/PageHeader/PageHeader';

import TermsPropTypes from '../termsPropTypes';

// stylesheets
import './TermsPage.css';

class TermsPage extends Component {
  get termsContent() {
    const { terms } = this.props;
    if (terms.status === 'FETCHING_TERMS') {
      return <MaterialLoadingIcon />;
    }

    if (terms.status === 'TERMS_FETCH_ERROR') {
      return (
        <div className="terms-of-use">
          There was an error fetching the most recent Terms of Use. Please try
          again later.
        </div>
      );
    }

    return (
      <div
        className="terms-of-use"
        dangerouslySetInnerHTML={{ __html: terms.termsData?.data?.content }}
      />
    );
  }

  render() {
    return (
      <>
        <PageHeader
          title="General Terms and Conditions for the Nutrien Ag Solutions Digital Hub, Nutrien Ag Solutions HUB and Agrible® Platforms"
          breadcrumbs={[
            {
              link: '/',
              label: 'Home',
            },
            {
              label:
                'General Terms and Conditions for the Nutrien Ag Solutions Digital Hub, Nutrien Ag Solutions HUB and Agrible® Platforms',
            },
          ]}
        />
        {this.termsContent}
      </>
    );
  }
}

TermsPage.propTypes = {
  terms: TermsPropTypes.isRequired,
};

export default TermsPage;
