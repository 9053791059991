export default (iso) => {
  let date;

  /**
   * Dates were one day behind in some timezones due to
   * differences between how Safari and other browsers
   * implement Date.parse. Some of the dates being passed
   * into here don't specify times/timezones which gets set
   * to T00:00:00 by default. Unlike Chrome, Firefox, etc.,
   * Safari assumes that these dates are UTC instead of local.
   *
   * As a result, Safari parses using UTC and THEN converts it
   * to local time (e.g. GMT-0400), which takes it from the
   * actual day at midnight to 8pm the day before.
   *
   * iso: 2017-03-20T00:00:00
   * parsed date: Sun Mar 19 2017 20:00:00 GMT-0400 (Eastern Daylight Time)
   *
   * Replacing the dashes with slashes in the date before parsing fixes it.
   */
  if (iso === 'today') {
    date = new Date();
  } else if (!iso) {
    return undefined;
  } else {
    date = new Date(iso.replace(/-/g, '/').replace(/T.+/, ''));
  }

  if (iso !== '0') {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  return date;
};
