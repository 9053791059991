import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import checkMarkImage from 'assets/check.svg';
import commonStyles from 'components/Payments/commonPaymentStyles';
import OnClickOutside from 'shared/ui/OnClickOutside/OnClickOutside';
import PaymentValidationMessageComponent from 'components/Payments/OtherComponents/ValidationMessage/PaymentValidationMessageComponent';
import HelpModalComponent from '../../HelpModal/HelpModalComponent';
import {
  ROUTING_MODAL_DESCRIPTION,
  ACCOUNT_MODAL_DESCRIPTION,
  ModalType,
  MODAL_TYPES,
} from '../../../../../../PaymentConstants';
import { MAX_LENGTH } from '../../../../MakeAPaymentConstants';
import { buildClassName } from '../../../../../../Utils/PaymentUtil';
import * as PaymentPropTypes from '../../../../../../PaymentPropTypes';

import accountStyles from '../../styles';

const finalStyles = (theme) => merge({}, commonStyles(theme), accountStyles);

class USBankAccountInfoComponent extends Component {
  addBankName = () => {
    const { routingNumberError, errors, bankName, classes } = this.props;

    if (routingNumberError && errors.routingNumber.length === 0) {
      return (
        <div className={classes.errorMessages}>
          <p>The Routing Number is not valid please try again</p>
        </div>
      );
    }
    if (!bankName || routingNumberError) {
      return null;
    }
    return (
      <div className={`${classes.accountInfoBankName} bank-name`}>
        <img
          src={checkMarkImage}
          className={classes.accountInfoImage}
          alt="Ok"
        />
        {bankName}
      </div>
    );
  };

  checkBank = (event) => {
    if (event.target.value.length === MAX_LENGTH.ROUTING) {
      this.props.getBankName(event.target.value);
    } else {
      this.props.clearBankName();
    }
  };

  render() {
    const {
      errors,
      formName,
      form,
      onChange,
      openModal,
      modalState,
      onValidate,
      keyDownHandler,
      classes,
    } = this.props;

    return (
      <div className="USBankAccountInfoComponent">
        <label
          className={classes.addPaymentFormLabel}
          htmlFor="bankAccountNumber"
        >
          Bank Account Number
          <div
            className={classes.accountInfoHelpLink}
            name="accountModal"
            onClick={openModal}
            onKeyDown={(e) => {
              keyDownHandler(MODAL_TYPES.ACCOUNT_MODAL, e);
            }}
            role="link"
            tabIndex="0"
          >
            What's this?
            <OnClickOutside
              clickOutsideHandler={() => {
                this.props.closeModal(MODAL_TYPES.ACCOUNT_MODAL);
              }}
            >
              <HelpModalComponent
                modalOpen={modalState.accountModal}
                closeModal={() => {
                  this.props.closeModal(MODAL_TYPES.ACCOUNT_MODAL);
                }}
                modalType={ModalType.bankAccountModal}
                modalText="Bank Account Number"
                modalDescription={ACCOUNT_MODAL_DESCRIPTION}
              />
            </OnClickOutside>
          </div>
        </label>
        <input
          name="bankAccountNumber"
          form={formName}
          type="text"
          className={buildClassName(
            'bankAccountNumber',
            classes.addPaymentFormInput,
            errors,
            classes.error
          )}
          onChange={onChange}
          maxLength={MAX_LENGTH.ACCOUNT_NUMBER_US}
          onBlur={onValidate}
          autoComplete="new-password"
          value={form.bankAccountNumber}
        />
        <PaymentValidationMessageComponent
          errors={errors}
          field="bankAccountNumber"
        />

        <label
          className={classes.addPaymentFormLabel}
          htmlFor="confirmBankAccountNumber"
        >
          Confirm Bank Account Number
        </label>
        <input
          name="confirmBankAccountNumber"
          form={formName}
          type="text"
          className={buildClassName(
            'confirmBankAccountNumber',
            classes.addPaymentFormInput,
            errors,
            classes.error
          )}
          onChange={onChange}
          maxLength={MAX_LENGTH.ACCOUNT_NUMBER_US}
          onBlur={onValidate}
          onPaste={(e) => e.preventDefault()}
          value={form.confirmBankAccountNumber}
        />
        <PaymentValidationMessageComponent
          errors={errors}
          field="confirmBankAccountNumber"
        />

        <label className={classes.addPaymentFormLabel} htmlFor="routingNumber">
          Routing Number
          <div
            className={classes.accountInfoHelpLink}
            name="routingModal"
            onClick={openModal}
            onKeyDown={(e) => {
              keyDownHandler(MODAL_TYPES.ROUTING_MODAL, e);
            }}
            role="link"
            tabIndex="0"
          >
            What's this?
            <OnClickOutside
              clickOutsideHandler={() => {
                this.props.closeModal(MODAL_TYPES.ROUTING_MODAL);
              }}
            >
              <HelpModalComponent
                modalOpen={modalState.routingModal}
                name="routingModal"
                closeModal={() => {
                  this.props.closeModal(MODAL_TYPES.ROUTING_MODAL);
                }}
                modalType={ModalType.routingModal}
                modalText="Bank Routing Number"
                modalDescription={ROUTING_MODAL_DESCRIPTION}
              />
            </OnClickOutside>
          </div>
        </label>
        <input
          name="routingNumber"
          form={formName}
          type="text"
          className={buildClassName(
            'routingNumber',
            `${classes.addPaymentFormInput} ${classes.accountInfoBlock}`,
            errors,
            classes.error
          )}
          onBlur={(e) => {
            onValidate(e);
            this.checkBank(e);
          }}
          value={form.routingNumber || ''}
          onChange={onChange}
          maxLength={MAX_LENGTH.ROUTING}
        />
        <PaymentValidationMessageComponent
          errors={errors}
          field="routingNumber"
        />
        {this.addBankName()}
      </div>
    );
  }
}

USBankAccountInfoComponent.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    routingNumber: PropTypes.string,
    bankAccountNumber: PropTypes.string.isRequired,
    confirmBankAccountNumber: PropTypes.string.isRequired,
  }).isRequired,
  errors: PaymentPropTypes.errors.isRequired, // eslint-disable-line react/no-typos
  onChange: PropTypes.func.isRequired,
  routingNumberError: PropTypes.bool.isRequired,
  bankName: PropTypes.string,
  getBankName: PropTypes.func.isRequired,
  clearBankName: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalState: PropTypes.shape({}).isRequired,
  onValidate: PropTypes.func.isRequired,
  keyDownHandler: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

USBankAccountInfoComponent.defaultProps = {
  bankName: '',
};

export default withStyles(finalStyles)(USBankAccountInfoComponent);
