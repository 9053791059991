import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import { currency, stripCurrencySymbols } from 'shared/utils/numberFormatters';
import { MIN_PAYMENT_AMOUNT } from 'const/payments';
import PrePayAmountInput from './PrePayAmountInputComponent';

class PrePayAmountInputContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountEntered: props.amountEntered
        ? this.formatAmount(props.amountEntered)
        : '',
    };
  }

  onValidate = (event) => {
    const { name, value } = event.target;
    const fieldData = { value };
    const { validate } = this.props;

    validate({ field: name, fieldData });
  };

  handleOnChangeAmount = (event) => {
    const { name, value } = event.target;
    const strippedValue = stripCurrencySymbols(value);

    const updatedEvent = {
      target: {
        name,
        value: strippedValue,
      },
    };
    this.handleChange(updatedEvent);
  };

  handleOnBlurAmount = (event) => {
    let updatedValue;
    const { name, value } = event.target;
    const strippedValue = stripCurrencySymbols(value);

    if (strippedValue && !Number.isNaN(strippedValue)) {
      const roundedValue = Number.parseFloat(strippedValue).toFixed(2);
      updatedValue = roundedValue >= MIN_PAYMENT_AMOUNT ? roundedValue : 0;
    } else {
      updatedValue = 0;
    }

    const updatedEvent = {
      target: {
        name,
        value: updatedValue,
      },
    };

    this.onValidate(updatedEvent);
    this.handleAmountEntered(updatedEvent);
  };

  handleChange = (event) => {
    const { onChange } = this.props;
    const { name, value } = event.target;
    const currentState = { ...this.state };

    currentState.amountEntered = value;

    this.setState(currentState);

    onChange({
      target: {
        name,
        value: value ? Number(Number.parseFloat(value).toFixed(2)) : value,
      },
    });
  };

  formatAmount = (amount) => {
    const rawAmount = stripCurrencySymbols(amount);
    return currency(Number.parseFloat(rawAmount).toFixed(2));
  };

  handleAmountEntered = (event) => {
    const currentState = { ...this.state };
    currentState.amountEntered = currency(event.target.value);

    this.setState(currentState);
  };

  render() {
    const { errors, warnings } = this.props;

    return (
      <PrePayAmountInput
        errors={errors}
        warnings={warnings}
        amountEntered={this.state.amountEntered}
        handleOnChangeAmount={this.handleOnChangeAmount}
        handleOnBlurAmount={this.handleOnBlurAmount}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { bankAccountFormReducer: bankAccountForm } = state.payments;

  return {
    amountEntered: bankAccountForm.form.amountEntered,
    errors: bankAccountForm.formErrors,
  };
};

const mapDispatchToProps = {};

PrePayAmountInputContainer.propTypes = {
  amountEntered: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PaymentPropTypes.paymentFormErrors, // eslint-disable-line react/no-typos
  warnings: PaymentPropTypes.paymentFormWarnings, // eslint-disable-line react/no-typos
};

PrePayAmountInputContainer.defaultProps = {
  errors: {},
  warnings: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrePayAmountInputContainer);
