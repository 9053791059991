import { makeStyles } from '@nutrien/uet-react/styles';

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    '@media (min-width: 600px)': {
      minWidth: 500,
    },
    '@media (max-width: 600px)': {
      paddingLeft: 0,
    },
  },
  stepIcon: {
    color: theme.palette.grey[100],
    '& text': {
      fill: theme.palette.grey[500],
    },
  },
  blueStepIcon: {
    color: `${theme.palette.info.main} !important`,
    '& text': {
      fill: `${theme.palette.info.contrastText} !important`,
    },
  },
  stepConnector: {
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  paymentHeader: {
    width: '100%',
    margin: '0 20px',
    minHeight: '230px',
    '@media (max-width: 480px)': {
      minHeight: '260px',
    },
  },
  paymentHeaderWithError: {
    width: '100%',
    margin: '0 20px',
    borderBottom: '1px solid #cccccc',
    minHeight: '340px',
    padding: '0',
  },
  pageNotification: {
    padding: '0',
  },
  paymentHeaderTitle: {
    color: '#004538',
    fontSize: '32px',
    margin: '20px 0',
    '@media (max-width: 480px)': {
      fontSize: '24px',
    },
  },
  printConfirmation: {
    paddingLeft: 0,
    '@media (min-width: 900px)': {
      marginTop: 12,
      paddingLeft: 8,
    },
  },
  paymentHeaderProgression: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '30px auto 58px auto',
    '& span': {
      margin: '0 5px',
    },
  },
  paymentHeaderStepContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& span': {
      fontSize: '14px',
    },
  },
  paymentHeaderSeparator: {
    height: '40px',
    margin: '0 5px',
    position: 'relative',
    width: '100px',
    '&:before': {
      borderBottom: '1px #C5C5C5 solid',
      content: "''",
      height: '100%',
      position: 'absolute',
      transform: 'translateY(-45%)',
      width: '100%',
    },
  },
  paymentHeaderCircle: {
    border: 'solid 1px #d4d4d4',
    borderRadius: '20px',
    display: 'inline-block',
    height: '40px',
    margin: '0 5px',
    width: '40px',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '40px',
  },
  paymentHeaderCircleCurrent: {
    backgroundColor: '#464646',
    border: 'solid 1px #464646',
    color: 'white',
  },
  paymentErrorNotification: {
    marginBottom: '55px',
    padding: '0',
  },
  paymentErrorDuplicate: {
    display: 'flex',
    border: '1px solid #d24242',
    borderRadius: '4px',
    padding: '17px',
    marginBottom: '40px',
    backgroundColor: 'rgba(248, 79, 79, 0.1)',
  },
  paymentErrorDuplicateText: {
    marginLeft: '17px',
  },
  '@media (max-width: 740px)': {
    paymentHeaderStepContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      whiteSpace: 'nowrap',
      '& span': {
        position: 'absolute',
        top: '40px',
        whiteSpace: 'nowrap',
      },
    },
    paymentHeaderSeparator: {
      margin: '0',
      width: '77px',
      '&:before': {
        width: '100%',
      },
    },
    paymentHeaderCircle: {
      margin: '0',
    },
    paymentsInvalidWarning: {
      marginBottom: 10,
    },
  },
}));

export default useStyles;
