// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';
import getCartSuccess from './getCartSuccess';
import getCartFailure from './getCartFailure';
import loadingCart from './loadingCart';
import cartItemArrayTransformer from '../../../../utils/transformers/cartItemTransformer/cartItemArrayTransformer';

export default (defaultAccountId) => (dispatch) => {
  dispatch(loadingCart(true));
  return ecommApiService
    .get('/v2/cart-items', {
      headers: {
        'account-id': defaultAccountId,
      },
    })
    .then((res) => {
      const transformedCart = cartItemArrayTransformer(res.data.data);
      dispatch(getCartSuccess(transformedCart));
      return res;
    })
    .catch((error) => {
      dispatch(getCartFailure(error));
      return error;
    });
};
