import usersFetchFailure from './usersFetchFailure';
import usersFetchSuccess from './usersFetchSuccess';
import usersFetching from './usersFetching';
import * as usersService from '../usersService';

export default (orgId) => (dispatch) => {
  dispatch(usersFetching());
  return usersService
    .get(orgId)
    .then((resp) => dispatch(usersFetchSuccess(resp)))
    .catch((error) => dispatch(usersFetchFailure(error)));
};
