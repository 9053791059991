import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { bindActionCreators, compose } from 'redux';
import get from 'lodash/get';
import * as cartActions from '../../actions/cartActions';
import { productActions } from '../../../Shop/actions';

import CartWrapperARS from '../../components/CartWrapper/CartWrapperARS';
import CartWrapperSAP from '../../components/CartWrapper/CartWrapperSAP';

class CartWrapper extends PureComponent {
  render() {
    const system = get(this.props, 'selectedAccount.system');

    return system === 'SAP' ? (
      <CartWrapperSAP {...this.props} />
    ) : (
      <CartWrapperARS {...this.props} />
    );
  }
}

const mapStateToProps = ({ cart, user, selectedAccount, accounts }) => ({
  selectedAccount,
  accounts,
  cartItems: cart,
  userDetails: user.userData.userDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...cartActions, ...productActions }, dispatch);

export { CartWrapper };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(CartWrapper);
