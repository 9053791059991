import { DATE_RANGE_FILTERS } from './constants';
import { getDateRange } from '../invoicesUtils';

import setDateRange from './setDateRange';

export default (filterType, startDate = undefined, endDate = undefined) => (
  dispatch
) => {
  if (filterType === DATE_RANGE_FILTERS.CUSTOM) {
    dispatch(
      setDateRange(
        { startDate, endDate },
        `${new Date(startDate).toLocaleDateString()} - ${new Date(
          endDate
        ).toLocaleDateString()}`
      )
    );
  } else {
    dispatch(setDateRange(getDateRange(filterType), filterType));
  }
};
