export default (theme) => ({
  infoText: {
    color: theme.palette.text.secondary,
    display: 'block',
    fontSize: 14,
    margin: '28px 0 23px',
  },
  header: {
    fontSize: 14,
    marginLeft: 24,
    fontWeight: '600',
  },
  listItem: {
    fontSize: 14,
    // "MuiListItem gutters" adds 16px padding on either side,
    // which misaligns the items with the header. So override it here
    paddingLeft: 24,
    '&:first-child': {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      backgroundClip: 'padding-box',
    },
  },
});
