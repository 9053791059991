const styles = {
  hrContainer: {
    margin: '6px 20px',
  },
  titleArea: {
    margin: '0 20px',
  },
  titleAndLinkWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '100px',
  },
  weatherButtonContainer: {
    marginBottom: '14px',
  },
  weatherLink: {
    display: 'flex',
    marginTop: '10px',
  },
  subtitle: {
    margin: 0,
    fontStyle: 'italic',
    color: '#464646',
  },
  disclaimers: {
    textAlign: 'right',
    paddingTop: 10,
  },
  disclaimer: {
    padding: '10px',
  },
  weatherLinkIcon: {
    width: '17px',
    'margin-top': '-4px',
  },
};

export default styles;
