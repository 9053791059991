import { mixPanelLabels } from 'store/middleware/MixPanel/eventHandlers/products';
import {
  productToImpressionFieldObject,
  accountLocationCodeToCurrency,
} from '../utils';
import {
  EVENT_TRACK,
  GTM_VIEW_PDP,
  GTM_VIEW_PRODUCT_LABEL,
} from '../gtmConstants';

export const viewPDPImpression = (
  action,
  { selectedAccount: { locationCode } }
) => {
  const { product, variantWithPrices } = action.payload;

  const list = 'Product Detail Page';
  return {
    event: 'EEC_ViewPDP',
    ecommerce: {
      currencyCode: accountLocationCodeToCurrency(locationCode),
      detail: {
        actionField: {
          list,
        },
        products: [
          productToImpressionFieldObject(product, variantWithPrices, list),
        ],
      },
    },
  };
};

export const viewProductLabel = (
  { payload: { attributes } },
  { selectedAccount: { branchId, id, name } }
) => {
  mixPanelLabels(attributes, branchId, id, name);
  return {
    event: EVENT_TRACK,
    eventid: GTM_VIEW_PRODUCT_LABEL,
    sku: attributes?.guid,
    shelf: attributes?.product_category?.name,
    'branch id': branchId,
    'account id': id,
    'account name': name,
  };
};

export const PDPEventMap = {
  [GTM_VIEW_PDP]: viewPDPImpression,
  [GTM_VIEW_PRODUCT_LABEL]: viewProductLabel,
};
