import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import resolveApiEnv from 'shared/config/resolveApiEnv';
import userPropType from 'shared/config/userPropType';
import { fetchIntercomHash } from './actions';

import IntercomMiddleware from './IntercomMiddleware';
import { INTERCOM_HASH_NOT_LOADED } from './actions/constants';

const getEnvironment = () => {
  // In order to be consistent with naming conventions accross applications we need to
  // convert to lower case and prod->prd in order to match the nutrien-parameters repo.
  const env = resolveApiEnv().toLowerCase();
  return env === 'PROD' ? 'prd' : env;
};

export const IntercomMiddlewareContainer = ({
  fetchIntercomHash, // eslint-disable-line no-shadow
  intercom,
  user,
  ...rest
}) => {
  useEffect(() => {
    if (intercom.status === INTERCOM_HASH_NOT_LOADED && user.id) {
      fetchIntercomHash();
    }
  }, [fetchIntercomHash, intercom.status, user.id]);

  return (
    <IntercomMiddleware user={user} intercomHash={intercom.hash} {...rest} />
  );
};

/**
 * Customer is hardcoded as we are only loading the intercom app for customers.
 * In the future if intercom is loaded for employees this would need to be a variable
 * dependent on if a customer or employee logs in.
 */
const mapStateToProps = (state) => ({
  currentPage:
    state.router && state.router.location ? state.router.location.pathname : '',
  env: getEnvironment(),
  intercom: state.intercom,
  isEmployee: !!state.employee.user.info.id,
  user: state.user.userData,
  userType: 'customer',
});

const mapDispatchToProps = {
  fetchIntercomHash,
};

IntercomMiddlewareContainer.propTypes = {
  fetchIntercomHash: PropTypes.func.isRequired,
  intercom: PropTypes.shape({
    status: PropTypes.string.isRequired,
    hash: PropTypes.string,
  }).isRequired,
  user: userPropType.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(IntercomMiddlewareContainer);
