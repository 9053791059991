import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import accountFormatType, { getAccountData } from 'utils/accountUtils';
import { account as accountPropType } from 'components/Account/accountPropTypes';

const AccountDescriptionText = ({ account }) => {
  const { accountNickname } = useFlags();
  return (
    <>
      {accountNickname ? (
        <>
          {getAccountData(
            account,
            ['nickname', 'number'],
            ['name', 'number'],
            accountFormatType.DASH,
            true
          )}
        </>
      ) : (
        <>
          {getAccountData(
            account,
            ['name', 'number'],
            null,
            accountFormatType.DASH
          )}
        </>
      )}
    </>
  );
};
AccountDescriptionText.propTypes = {
  account: accountPropType.isRequired,
};

export default AccountDescriptionText;
