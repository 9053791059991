import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import DisabledForEmployees from 'shared/utils/DisabledForEmployees';
import { RebrandedMaterialButton as MaterialButton } from 'shared/ui';
import { currency } from 'shared/utils/numberFormatters';

import { COUNTRY_CODES } from 'components/Payments/PaymentConstants';
import { PAYMENT_ERRORS } from 'components/Payments/Redux/actions/ErrorActions';
import { selectedInvoiceIds as selectedInvoiceIdsPropType } from '../../invoicesPropTypes';
import { PAYMENT_FLOW_TYPES } from '../../../../Payments/PaymentConstants';

const PayInvoiceButton = ({
  accountId,
  clearPaymentError,
  countryCode,
  push,
  selectedInvoiceIds,
  setPaymentError,
  totalAmountDue,
  setPaymentFlowType,
  unpaidInvoiceSelected,
}) => {
  const { southernOhioDivision } = useFlags();
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const isInvalidAccount =
    // TODO: remove usaSapAccount restrictions once SAP is rolled out to entire US market
    (selectedAccount.usaSapAccount && !southernOhioDivision) ||
    selectedAccount.invalidCanadianAccount ||
    selectedAccount.invalidInternationalAccount;

  if (countryCode !== COUNTRY_CODES.USA || !unpaidInvoiceSelected) {
    return null;
  }
  const buttonShouldBeDisabled =
    selectedAccount.calculatedCustomerType === 'Fuel' ||
    isInvalidAccount ||
    totalAmountDue < 0;

  return (
    <DisabledForEmployees>
      <MaterialButton
        className="invoice-action-button"
        disabled={buttonShouldBeDisabled}
        value={`Pay ${currency(totalAmountDue)}`}
        onClick={() => {
          if (selectedInvoiceIds.unpaid.length > 100) {
            setPaymentError(PAYMENT_ERRORS.INVOICE_SELECTION_LIMIT_REACHED);
          } else {
            clearPaymentError();
            setPaymentFlowType(PAYMENT_FLOW_TYPES.INVOICE_PAYMENT);
            push({
              pathname: '/make-a-secure-payment',
              state: {
                selectedInvoices: selectedInvoiceIds,
                account: accountId,
              },
            });
          }
        }}
      />
    </DisabledForEmployees>
  );
};

PayInvoiceButton.propTypes = {
  accountId: PropTypes.string,
  clearPaymentError: PropTypes.func,
  countryCode: PropTypes.string,
  push: PropTypes.func,
  selectedInvoiceIds: selectedInvoiceIdsPropType,
  setPaymentError: PropTypes.func,
  totalAmountDue: PropTypes.number,
  unpaidInvoiceSelected: PropTypes.bool,
  setPaymentFlowType: PropTypes.func.isRequired,
};

PayInvoiceButton.defaultProps = {
  accountId: '',
  clearPaymentError: PropTypes.func,
  countryCode: 'USA',
  push: PropTypes.func,
  selectedInvoiceIds: {
    totalAmountDue: 0,
    paid: [],
    unpaid: [],
  },
  setPaymentError: PropTypes.func,
  totalAmountDue: 0,
  unpaidInvoiceSelected: PropTypes.bool,
};

export default PayInvoiceButton;
