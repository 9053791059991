import { TOGGLE_MODAL } from '../actions';

const defaultState = { preference: null, accountIds: [] };

export default (state = defaultState, action) => {
  const { type, preference, accountIds, isOpen } = action;

  switch (type) {
    case TOGGLE_MODAL:
      return isOpen ? { ...state, preference, accountIds } : defaultState;
    default:
      return state;
  }
};
