import localStorageMemory from 'localstorage-memory';

/**
 * returns a storage adapter
 *
 */
export const getStorage = () => {
  try {
    // test if localStorage is available
    const mod = 'cxh-client-storage-test';
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return localStorage;
  } catch (e) {
    // fallback to localStorageMemory if localStorage is inaccessible
    return localStorageMemory;
  }
};

export default getStorage();
