import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { MaterialLoadingIcon, ApiErrorMessage } from 'shared/ui';
import { Snackbar } from 'nutrien-common-components-react/dist/mdc';
import MiniCart from 'components/Shop/containers/MiniCartContainer/MiniCartContainer';
import { formatTodaysDate } from 'shared/utils/DateHelper';
import { fetchProductHistory, productHistoryOk } from './ProductHistoryActions';
import { ProductHistoryPageContainer as ProductHistoryPage } from './ProductHistoryPage';
import RequestInfoModal from './RequestInfoModal';
import { createProductHistoryPageProps } from './ProductHistoryHelper';
import getFirstDateOfCurrentYear from '../../shared/utils/DateHelper/getFirstDateOfCurrentYear';

const ProductHistory = () => {
  // redux store
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const productHistory = useSelector((state) => state.productHistory);
  const filter = useSelector((state) => get(state, 'purchases.filter', ''));
  const cropConsultant = get(selectedAccount, 'cropConsultant');
  const userEmail = useSelector((state) => get(state, 'user.userData.email'));

  // redux dispatch
  const dispatch = useDispatch();

  // router history
  const history = useHistory();

  // state
  const [cachedProductHistoryList, setCachedProductHistoryList] = useState([]);
  const [shelfFilter, setShelfFilter] = useState(filter);
  const [startDate, setStartDate] = useState(
    productHistory.startDate || getFirstDateOfCurrentYear()
  );
  const [endDate, setEndDate] = useState(
    productHistory.endDate || formatTodaysDate()
  );
  const [sortedColumn, setSortedColumn] = useState('');
  const state = {
    cachedProductHistoryList,
    setCachedProductHistoryList,
    shelfFilter,
    setShelfFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sortedColumn,
    setSortedColumn,
  };

  useEffect(() => {
    let queryString = `?start_date=${startDate}&end_date=${endDate}`;
    if (filter) queryString += `&shelves=${filter}`;
    dispatch(fetchProductHistory(selectedAccount, queryString));
  }, [startDate, endDate, filter, selectedAccount, dispatch]);

  const productHistoryPageProps = createProductHistoryPageProps(
    filter,
    productHistory,
    selectedAccount,
    state
  );

  switch (productHistory.status) {
    case 'LOADING':
      return <MaterialLoadingIcon data-test="product-history-loading" />;
    // Api returns 404 if no results for the search are found, this will allow the top portion of the page to render
    case 'ERROR':
    case 'OK':
    case 'SORTED':
    case 'NEW_SHELVES':
    case 'NEW_TIMEFRAME':
      return (
        <ProductHistoryPage
          data-test="product-history-page"
          {...productHistoryPageProps}
        />
      );
    case 'ADDING_TO_CART':
    case 'ADD_TO_CART_SUCCESS':
      return (
        <>
          <ProductHistoryPage
            data-test="product-history-page"
            {...productHistoryPageProps}
          />
          <MiniCart data-test="product-history-mini-cart" history={history} />
        </>
      );
    case 'ADD_TO_CART_FAILURE':
      return (
        <>
          <ProductHistoryPage
            data-test="product-history-page"
            {...productHistoryPageProps}
          />
          <RequestInfoModal
            closeModalFunc={() =>
              dispatch(productHistoryOk(cachedProductHistoryList))
            }
            selectedProductShelf={
              productHistory.selectedShelf.toLowerCase() === 'chemical'
                ? 'error'
                : productHistory.selectedShelf
            }
            cropConsultant={cropConsultant}
            userEmail={userEmail}
            data-test="product-history-request-info-modal"
          />
        </>
      );
    case 'HUBSPOT_OPT_IN_SUCCESS':
      return (
        <>
          <Snackbar
            data-test="product-history-snackbar"
            timeout={5000}
            isOpen
            message={`You will receive an email when ${productHistory.shelf} products are available`}
          />
          <ProductHistoryPage
            data-test="product-history-page"
            {...productHistoryPageProps}
          />
        </>
      );
    default:
      return (
        <ApiErrorMessage
          data-test="product-history-error"
          entityName="Product History"
        />
      );
  }
};

export default ProductHistory;
