import { combineReducers } from 'redux';

import accountId from './accountId';
import dateRange from './dateRange';
import filteredProducts from './filteredProducts';
import { filters } from '../InvoiceList/InvoicesTable/reducers';
import filterType from './filterType';
import invoiceDownloadStatus from './invoiceDownloadStatus';
import invoicesData from './invoicesData';
import list from './list';
import invoicedProducts from './invoicedProducts';
import selectedInvoicedProducts from './selectedInvoicedProducts';
import selectedInvoices from './selectedInvoices';
import status from './status';

export default combineReducers({
  accountId,
  list,
  selectedInvoices,
  status,
  invoiceDownloadStatus,
  dateRange,
  filterType,
  invoicesData,
  invoicedProducts,
  selectedInvoicedProducts,
  filteredProducts,
  filters,
});
