import { connect } from 'react-redux';

import {
  delegateAccess,
  resetAccessDelegation,
} from 'components/Profile/rbacRedux/rbacActions';
import * as addUserFormActions from 'components/Profile/rbacRedux/addUserFormActions';

import AddUser from './AddUser';

export const mapStateToProps = (state) => ({
  rbac: state.rbac,
  form: state.addUserForm,
});

export const mapDispatchToProps = {
  delegateAccess,
  resetAccessDelegation,
  onChange: addUserFormActions.updateAddUserFormAction,
  resetForm: addUserFormActions.resetAddUserFormAction,
  setErrors: addUserFormActions.setAddUserFormErrorsAction,
  clearErrors: addUserFormActions.clearAddUserFormErrorsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
