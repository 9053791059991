// TODO: refactor to have fewer and/or named props
export default (
  fileData,
  fileName,
  fileType = 'text/csv',
  linkId = '',
  analyticsProps
) => {
  const newBlob = new Blob([fileData], { type: fileType });
  const url = window.URL.createObjectURL(newBlob);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, fileName);
    return;
  }

  if (window.ReactNativeWebView) {
    const reader = new FileReader();

    const onReaderLoaded = () => {
      const data = reader.result;

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          name: 'display_file',
          payload: {
            type: 'application/pdf',
            data,
            analyticsProps,
          },
        })
      );

      reader.removeEventListener('load', onReaderLoaded);
    };

    reader.addEventListener('load', onReaderLoaded);

    reader.readAsDataURL(newBlob);
    return;
  }

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  if (linkId !== '') link.setAttribute('id', linkId);
  document.body.appendChild(link);
  link.click();
  if (window.navigator.userAgent.match('CriOS')) {
    window.location.href = url;
  }
};
