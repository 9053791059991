import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FullPage from 'shared/ui/FullPage/index';
import Button from '@nutrien/uet-react/Button';
import Check from '@nutrien/uet-react/icons/Check';
import Grid from '@nutrien/uet-react/Grid';
import withStyles from '@nutrien/uet-react/styles/withStyles';
import moment from 'moment';
import { Redirect } from 'react-router';
import PaymentHeader from 'shared/ui/Payments/PaymentHeader/PaymentHeader';
import currency from 'shared/utils/numberFormatters/currency';
import { ROUTES_MAP } from 'shared/ui/Payments/PaymentHeader/constants';
import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import { selectUser } from 'selectors';
import styles from './styles';
import {
  MAKE_PAYMENT_STEPS,
  ACH_ACCOUNT_TYPES,
  BANK_ACCOUNT_TYPES,
  PAYMENT_FLOW_TYPES,
  payByPrepaySavedAccount,
  RecurringPaymentFrequencies,
} from '../../PaymentConstants';
import { getPaymentProcessingText } from '../../Utils/PaymentUtil';
import * as PaymentPropTypes from '../../PaymentPropTypes';

const ConfirmPayment = ({
  account,
  achAccountType,
  amount,
  bankAccountLastThree,
  bankAccountType,
  classes,
  clearPaymentFlowType,
  clearPaymentForm,
  clearPaymentState,
  confirmationNumber,
  dateInitiated,
  discountedAmount,
  flowType,
  history,
  isRecurring,
  notes,
  recurringEndDate,
  recurringPaymentFrequency,
  savedPaymentMethod,
  scheduledPayment,
  selectedInvoices,
}) => {
  const user = useSelector(selectUser());
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      clearPaymentState();
      clearPaymentFlowType();
      clearPaymentForm();
    };
  }, [clearPaymentState, clearPaymentFlowType, clearPaymentForm]);

  const buildScheduledPaymentConfirmationMessage = () => {
    let message;
    const scheduledPaymentDateString = new Date(
      scheduledPayment
    ).toDateString();

    if (isRecurring) {
      message = 'Thank you! Your payment has been scheduled.';
    } else {
      message = `Thank you! Your payment has been scheduled for ${scheduledPaymentDateString}.`;
    }

    return message;
  };

  const buildInvoiceConfirmationMessage = () => {
    let message;

    if (savedPaymentMethod === payByPrepaySavedAccount) {
      message = scheduledPayment
        ? `Your request for Payment with your Prepay Funds has been scheduled for ${new Date(
            scheduledPayment
          ).toDateString()}. Please contact your branch with any questions.`
        : 'Your request for Payment with your Prepay Funds has been submitted. Please contact your branch with any questions';
    } else {
      message = scheduledPayment
        ? buildScheduledPaymentConfirmationMessage()
        : 'Thank you! Your payment has been submitted.';
    }

    return message;
  };

  const buildDefaultPaymentConfirmationMessage = () => {
    return scheduledPayment
      ? buildScheduledPaymentConfirmationMessage()
      : 'Your payment was submitted.';
  };

  const buildScheduledAndRecurringText = () => {
    const scheduledPaymentDateString = new Date(
      scheduledPayment
    ).toDateString();
    const endDateString = new Date(recurringEndDate).toDateString();
    const recurrenceFrequency =
      recurringPaymentFrequency.name ===
      RecurringPaymentFrequencies.BI_WEEKLY.name
        ? 'other week'
        : 'month';

    const message =
      `Your payment has been scheduled for ${scheduledPaymentDateString} and will ` +
      `recurr every ${recurrenceFrequency} ending on ${endDateString}`;

    return message;
  };

  const buildPrepayConfirmationMessage = () => {
    return scheduledPayment
      ? buildScheduledPaymentConfirmationMessage()
      : 'Thank You! The funded amount has been submitted.';
  };

  const getTitleText = () => {
    switch (flowType) {
      case PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT:
        return buildPrepayConfirmationMessage();
      case PAYMENT_FLOW_TYPES.INVOICE_PAYMENT:
        return buildInvoiceConfirmationMessage();
      default:
        return buildDefaultPaymentConfirmationMessage();
    }
  };

  const renderInvoiceValues = () => {
    const map = selectedInvoices.map(
      (val) => ` ${val.invoiceId} (${currency(val.amount)})`
    );
    return map.join().substr(1);
  };

  const shouldRedirect =
    (!scheduledPayment && !confirmationNumber) ||
    !bankAccountLastThree ||
    !flowType;

  if (shouldRedirect) {
    return <Redirect to="/make-a-secure-payment" />;
  }

  const cpsAccountId = account.id;
  const currentDate = moment().format('MM/DD/YYYY');
  const headerTitle =
    flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT
      ? 'Add Funds to Pre-Pay Balance'
      : 'Make Payment';

  const handleClick = () => {
    dispatch(
      fetchAssociatedOrgsAction((user.userDetails || {}).associatedOrgs || [])
    );
    clearPaymentState();
    clearPaymentForm();
    history.push('/');
  };

  return (
    <div className={classes.confirmPaymentPage} name="confirmPaymentPage">
      <FullPage noCard>
        <PaymentHeader
          title={headerTitle}
          subNavRoutes={ROUTES_MAP.MAKE_A_PAYMENT_ROUTES}
          currentPaymentStep={MAKE_PAYMENT_STEPS.CONFIRMATION}
        />
        <section className={classes.confirmPaymentPageSection}>
          <h1 className={classes.confirmationPageTitle}>{getTitleText()}</h1>

          <dl className={classes.confirmPaymentBody} name="confirmPaymentBody">
            {!scheduledPayment && (
              <div className={classes.confirmationItem} name="confirmationItem">
                <dt
                  className={classes.confirmationItemTitle}
                  name="confirmationTitle"
                >
                  Payment confirmation #
                </dt>
                <dd
                  className={classes.confirmationItemValue}
                  name="confirmationValue"
                >
                  {confirmationNumber}
                </dd>
              </div>
            )}
            <div className={classes.confirmationItem} name="confirmationItem">
              <dt
                className={classes.confirmationItemTitle}
                name="confirmationTitle"
              >
                To Account
              </dt>
              <dd
                className={classes.confirmationItemValue}
                name="confirmationValue"
              >
                {account.name}
              </dd>
            </div>

            {flowType === PAYMENT_FLOW_TYPES.INVOICE_PAYMENT ? (
              <div className={classes.confirmationItem} name="confirmationItem">
                <dt
                  className={`${classes.confirmationItemTitle} ${classes.invoiceTitle}`}
                  name="confirmationTitle"
                >
                  Invoices
                </dt>
                <dd
                  className={`${classes.confirmationItemValue} ${classes.invoiceList}`}
                  name="confirmationValue"
                >
                  {renderInvoiceValues()}
                </dd>
              </div>
            ) : null}

            <div className={classes.confirmationItem} name="confirmationItem">
              <dd
                className={classes.confirmationItemTitle}
                name="confirmationTitle"
              >
                {savedPaymentMethod === payByPrepaySavedAccount
                  ? 'Prepay Amount Applied'
                  : 'Amount Added'}
              </dd>
              <dt
                className={classes.confirmationItemValue}
                name="confirmationValue"
              >
                {currency(amount)}
                {discountedAmount
                  ? ` — Includes a ${currency(
                      discountedAmount
                    )} invoice discount.`
                  : null}
              </dt>
            </div>
            <div className={classes.confirmationItem} name="confirmationItem">
              <dd
                className={classes.confirmationItemTitle}
                name="confirmationTitle"
              >
                Date
              </dd>
              <dt
                className={classes.confirmationItemValue}
                name="confirmationValue"
              >
                {dateInitiated}
              </dt>
            </div>
            <div className={classes.confirmationItem} name="confirmationItem">
              <dd
                className={classes.confirmationItemTitle}
                name="confirmationTitle"
              >
                Payment Method
              </dd>
              <dt
                className={classes.confirmationItemValue}
                name="confirmationValue"
              >
                {savedPaymentMethod === payByPrepaySavedAccount ? null : (
                  <Check className={classes.checkImage} />
                )}
                {savedPaymentMethod === payByPrepaySavedAccount
                  ? 'Prepay Funds'
                  : `${bankAccountType} ${achAccountType.toLowerCase()} account ending in ${bankAccountLastThree}`}
              </dt>
            </div>
            {notes ? (
              <div className={classes.confirmationItem} name="confirmationItem">
                <dd
                  className={classes.confirmationItemTitle}
                  name="confirmationTitle"
                >
                  Notes For Associated Location
                </dd>
                <dt
                  className={classes.confirmationItemValue}
                  name="confirmationValue"
                >
                  {notes}
                </dt>
              </div>
            ) : null}
            <Grid
              container
              component="article"
              className={classes.additionalInformation}
              name="additionalInformation"
              spacing={1}
            >
              <Grid container item xs={12} wrap="nowrap">
                <Grid item xs={10}>
                  {scheduledPayment && isRecurring
                    ? buildScheduledAndRecurringText()
                    : getPaymentProcessingText(currentDate)}
                </Grid>
              </Grid>
              <Grid container item xs={12} wrap="nowrap">
                <Grid item xs={10}>
                  Payments submitted after 6:00 PM MST on the last day of the
                  month will show a posting date of the following day.
                </Grid>
              </Grid>
            </Grid>
          </dl>

          <div
            className={classes.confirmPaymentFooter}
            name="confirmPaymentFooter"
          >
            <div className={classes.buttonContainer}>
              {flowType !== PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT ? (
                <Button
                  className={classes.anotherPaymentButton}
                  id="make-another-payment-button"
                  variant="outlined"
                  onClick={() => {
                    clearPaymentForm();
                    clearPaymentState();
                    clearPaymentFlowType();

                    if (flowType === PAYMENT_FLOW_TYPES.INVOICE_PAYMENT) {
                      history.push(
                        `/accounts/${cpsAccountId}/invoices?acct=${cpsAccountId}`
                      );
                    } else {
                      history.push(
                        `/accounts/${cpsAccountId}/overview?acct=${cpsAccountId}`
                      );
                    }
                  }}
                >
                  Make Another Payment
                </Button>
              ) : null}
              <Button
                id="done-button"
                variant="contained"
                className={classes.finishButton}
                onClick={handleClick}
              >
                Thanks, I&apos;m Done
              </Button>
            </div>
          </div>
        </section>
      </FullPage>
    </div>
  );
};

ConfirmPayment.propTypes = {
  classes: PropTypes.shape().isRequired,
  confirmationNumber: PropTypes.string,
  account: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  amount: PropTypes.number,
  achAccountType: PropTypes.string,
  bankAccountType: PropTypes.string,
  dateInitiated: PropTypes.string,
  bankAccountLastThree: PropTypes.string,
  notes: PropTypes.string,
  clearPaymentState: PropTypes.func.isRequired,
  history: PaymentPropTypes.history.isRequired, // eslint-disable-line react/no-typos
  clearPaymentForm: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      amountDue: PropTypes.number,
      date: PropTypes.string,
      discountDate: PropTypes.string,
      dueDate: PropTypes.string,
      grossInvoiceAmount: PropTypes.number,
      id: PropTypes.number,
      isSupressed: PropTypes.bool,
      originalAmountDue: PropTypes.number,
      status: PropTypes.string,
      terms: PropTypes.string,
    })
  ),
  discountedAmount: PropTypes.number,
  savedPaymentMethod: PropTypes.string,
  scheduledPayment: PropTypes.instanceOf(Date),
  isRecurring: PropTypes.bool.isRequired,
  recurringEndDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  recurringPaymentFrequency: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  clearPaymentFlowType: PropTypes.func.isRequired,
  flowType: PropTypes.string.isRequired,
};

ConfirmPayment.defaultProps = {
  confirmationNumber: '',
  account: PropTypes.shape({
    accountName: '',
    accountId: '',
  }),
  amount: 0.0,
  achAccountType: ACH_ACCOUNT_TYPES.CHECKING,
  bankAccountType: BANK_ACCOUNT_TYPES.PERSONAL,
  dateInitiated: '',
  bankAccountLastThree: '',
  notes: '',
  selectedInvoices: [],
  discountedAmount: null,
  savedPaymentMethod: null,
  scheduledPayment: null,
  recurringPaymentFrequency: null,
};

export default withStyles(styles)(ConfirmPayment);
