/* eslint-disable react/jsx-one-expression-per-line */
// dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// shared UI
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';
import FormCard from 'shared/ui/MaterialFormCard';
import Form from 'shared/ui/MaterialFormCard/Form';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import FormCheckbox from 'shared/ui/MaterialFormCard/Form/FormCheckbox';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import FormCardToggleView from 'shared/ui/MaterialFormCard/FormCardToggleView';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import PageNotification from 'shared/ui/NotificationHelper/PageNotification';
// utils
import {
  validateEmail,
  domainIsPermitted,
  formValidationConstants,
} from 'shared/utils/formValidation';
import {
  stripSymbolsFromNumber,
  truncateNumber,
  formatPhoneNumber,
} from 'utils/phoneUtils';

import {
  PREREGISTER_FAILURE,
  PREREGISTER_START,
} from '../registrationConstants';
import EmailConfirmation from './EmailConfirmation';
import TermsCard from './TermsCard';
import DemoEnvWarning from './DemoEnvWarning';
import styles from './styles';

class Register extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    formattedPhoneNumber: '',
    termsAcceptance: false,
    errors: [],
    displayTerms: false,
    formSubmitted: false,
  };

  setErrors = (errorArray) => {
    this.setState({
      errors: errorArray,
    });
  };

  clearErrors = () => {
    const { errors } = this.state;
    const { registration, resetRegistration } = this.props;
    if (registration.error !== null) resetRegistration();
    if (errors.length) this.setErrors([]);
    this.setState({
      formSubmitted: false,
    });
  };

  displayTerms = () => {
    this.setState({
      displayTerms: true,
    });
  };

  closeModal = () => {
    this.setState({
      displayTerms: false,
    });
  };

  acceptTerms = () => {
    this.setState({
      displayTerms: false,
      termsAcceptance: true,
    });
  };

  declineTerms = () => {
    this.setState({
      displayTerms: false,
      termsAcceptance: false,
    });
  };

  formatPhone = () => {
    const { phoneNumber } = this.state;
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    this.setState({
      formattedPhoneNumber,
    });
  };

  handleChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (name === 'formattedPhoneNumber') {
      let phoneNumber = stripSymbolsFromNumber(value);
      phoneNumber = truncateNumber(phoneNumber);
      this.setState({
        phoneNumber,
      });
    }

    this.clearErrors();

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      email,
      firstName,
      lastName,
      phoneNumber,
      termsAcceptance,
    } = this.state;

    const { preregisterUser } = this.props;

    const errors = [];
    if (!firstName || !lastName || !email) {
      errors.push([0, 'Please enter all required fields.']);
    }

    if (email) {
      if (!validateEmail(email)) {
        errors.push([1, formValidationConstants.ENTER_VALID_EMAIL_ADDRESS]);
      } else if (!domainIsPermitted(email)) {
        errors.push([1, formValidationConstants.ONLY_USE_EMPLOYEE_PORTAL]);
      }
    }

    if (phoneNumber && phoneNumber.length !== 10) {
      errors.push([2, 'Please enter a 10-digit phone number.']);
    }

    if (!termsAcceptance) {
      errors.push([
        3,
        'You must accept the Nutrien Terms of Use and Privacy Policy.',
      ]);
    }

    if (errors.length) {
      return this.setErrors(errors);
    }

    const phone = phoneNumber === '' ? null : phoneNumber;
    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.toLowerCase().trim(),
      phoneNumber: phone,
    };

    this.setState({
      formSubmitted: true,
    });

    return preregisterUser(data);
  };

  render() {
    const {
      displayTerms,
      email,
      errors,
      firstName,
      lastName,
      formattedPhoneNumber,
      formSubmitted,
      phoneNumber,
      termsAcceptance,
    } = this.state;
    const {
      classes,
      isDemoEnv,
      preregisterUser,
      registration,
      user,
    } = this.props;
    let submissionError;

    if (user.error) {
      submissionError =
        user.error.data.name === 'UsernameExistsException'
          ? 'This account has already been registered.'
          : 'There was an error completing your registration.';
    }
    const isBusy = registration.status === PREREGISTER_START;
    const registrationFailed = registration.status === PREREGISTER_FAILURE;

    const termsDescription = (
      <div>
        I accept{' '}
        <div
          className={classes.link}
          onKeyPress={this.displayTerms}
          onClick={this.displayTerms}
          role="link"
          tabIndex="0"
        >
          Nutrien Terms of Use and Privacy Policy
        </div>
        .
      </div>
    );

    let registrationContent;
    if (formSubmitted && !(isBusy || registrationFailed)) {
      registrationContent = (
        <EmailConfirmation
          registration={registration}
          email={email}
          onClick={() =>
            preregisterUser({
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              email: email.trim(),
              phoneNumber: phoneNumber === '' ? null : phoneNumber,
            })
          }
        />
      );
    } else {
      registrationContent = (
        <div>
          <h3>Sign Up</h3>
          <hr />
          {isDemoEnv && <DemoEnvWarning />}
          <Form onSubmit={this.handleSubmit}>
            {registrationFailed ? (
              <div className={classes.notificationContainer}>
                <PageNotification
                  message="There was an error registering your account. Please try again later."
                  type="DANGER"
                  className="page-notification__no-padding"
                />
                <br />
              </div>
            ) : null}
            <FormTextField
              name="firstName"
              title="First Name"
              value={firstName}
              handleChange={this.handleChange}
              maxLength="25"
              autocomplete="given-name"
            />
            <FormTextField
              name="lastName"
              title="Last Name"
              value={lastName}
              handleChange={this.handleChange}
              maxLength="30"
              autocomplete="family-name"
            />
            <div className={classes.subtitleContainer}>
              <FormTextField
                name="email"
                title="Email"
                value={email}
                handleChange={this.handleChange}
                autocomplete="email"
                classes={{ textField: classes.customFieldText }}
              />
              <p className={classes.subtitleText}>
                (Legacy MyCPS users use the same email you used for MyCPS login)
              </p>
            </div>
            <FormTextField
              name="formattedPhoneNumber"
              title="Mobile Phone"
              subtitle="Optional"
              value={formattedPhoneNumber}
              handleChange={this.handleChange}
              onBlur={this.formatPhone}
              autocomplete="tel-national"
            />
            <FormCheckbox
              name="termsAcceptance"
              description={termsDescription}
              value={termsAcceptance}
              handleChange={this.handleChange}
            />
            <div className={classes.error} id="error">
              {errors.length
                ? errors.map((err) => <div key={err[0]}>{err[1]}</div>)
                : null}
              {submissionError && (
                <div key="submission-error">{submissionError}</div>
              )}
            </div>
            <MaterialButton
              id="registration-button"
              value="Sign Up"
              type="submit"
              onClick={this.handleSubmit}
              disabled={isDemoEnv || isBusy}
            />
            <FormCardToggleView
              title="Have a Customer Portal account?"
              link="/login"
              classes={{ link: classes.signInLink }}
              linkDescription="Sign In"
            />
          </Form>
        </div>
      );
    }

    const content =
      user.status === 'FETCHING_USER' ? (
        <MaterialLoadingIcon />
      ) : (
        <div>{registrationContent}</div>
      );

    return (
      <div>
        <FullPageBackground
          image={bgRegistration}
          className="full-page-background--farmers-pointing"
        />
        {displayTerms ? (
          <TermsCard
            closeModal={this.closeModal}
            acceptTerms={this.acceptTerms}
            declineTerms={this.declineTerms}
          />
        ) : (
          <FormCard>{content}</FormCard>
        )}
      </div>
    );
  }
}

Register.propTypes = {
  // TODO - extract user propTypes object into its own file
  user: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
        message: PropTypes.string,
      }),
      status: PropTypes.number,
    }),
  }).isRequired,
  registration: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.shape({
      response: PropTypes.shape({}),
    }),
  }).isRequired,
  resetRegistration: PropTypes.func.isRequired,
  preregisterUser: PropTypes.func.isRequired,
  isDemoEnv: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

Register.defaultProps = {
  isDemoEnv: false,
};

export default withStyles(styles)(Register);
