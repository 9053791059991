import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import { ROUTES_MAP } from 'shared/ui/Payments/PaymentHeader/constants';
import PaymentHeader from 'shared/ui/Payments/PaymentHeader/PaymentHeader';
import ReviewErrorImage from 'assets/review-error-500.svg';
import SchedulePayment from 'components/Payments/OtherComponents/SchedulePayment/SchedulePayment';
import {
  DEFAULT_PAYMENT_METHODS,
  errorTitleText,
  MAKE_PAYMENT_STEPS,
  PAYMENT_FLOW_TYPES,
} from '../../Payments/PaymentConstants';
import FullPage from '../../../shared/ui/FullPage/index';
import {
  paymentError as paymentErrorPropType,
  paymentForm,
  paymentFormWarnings,
  paymentFormErrors,
  cpsAccount as cpsAccountPropType,
  user as userPropType,
  paymentAccounts,
  paymentAccount as paymentAccountPropType,
  location as locationPropType,
} from '../../Payments/PaymentPropTypes';
import SavedPaymentMethods from '../../Payments/PageComponents/MakeAPayment/SubComponents/SavedPaymentAccounts/SavedPaymentMethods';
import {
  genericErrorBody,
  genericErrorTitle,
  serviceUnavailableErrorMessage,
} from '../../Payments/PageComponents/MakeAPayment/MakeAPaymentConstants';
import AccountInformation from '../../Payments/PageComponents/MakeAPayment/SubComponents/AccountInformation';
import BillingInformation from '../../Payments/PageComponents/MakeAPayment/SubComponents/BillingInformation';
import SaveAccount from '../../Payments/PageComponents/MakeAPayment/SubComponents/SaveAccount';
import AddPaymentFormComponent from '../../Payments/OtherComponents/PaymentForm/PaymentFormComponent';
import * as validationUtils from '../../Payments/PageComponents/MakeAPayment/Validations/PaymentValidator';
import PrePayAccountInformationComponent from './PrePayAccountInformation/PrePayAccountInformationComponent';
import PaymentProcessingOverlay from '../../Payments/OtherComponents/PaymentProcessingOverlay/PaymentProcessingOverlay';
import './PrePayAddFunds.css';
import ModalComponent from '../../Payments/OtherComponents/ModalComponent';
import {
  ERROR_NAMES,
  ERROR_TYPES,
} from '../../Payments/Redux/actions/ErrorActions';

class PrePayAddFunds extends Component {
  componentDidMount() {
    const { flowType, setPaymentFlowType } = this.props;

    // If no flowType is set, default it to pre-pay payment
    if (!flowType) {
      setPaymentFlowType(PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT);
    }

    window.scroll(0, 0);
  }

  clearHiddenFormErrors = () => {
    const { setPaymentFormErrors, formErrors } = this.props;
    const currentState = { ...formErrors };

    currentState.firstName = [];
    currentState.lastName = [];
    currentState.corporateName = [];

    setPaymentFormErrors(currentState);
  };

  handleChange = (event) => {
    const { updatePaymentForm } = this.props;
    const { name, value } = event.target;

    updatePaymentForm({
      [name]: value,
    });
  };

  validateFormField = ({ field, fieldData }) => {
    const {
      formValues,
      formErrors,
      formWarnings,
      setPaymentFormErrors,
    } = this.props;

    const newState = validationUtils.validateFormField({
      field,
      fieldData,
      currentState: {
        form: formValues,
        formWarnings,
        formErrors,
      },
    });

    setPaymentFormErrors(newState.formErrors);
  };

  submitPrepayDetails = (event) => {
    event.preventDefault();

    const { location, submitPaymentForm } = this.props;

    submitPaymentForm(location, PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  render() {
    const {
      user,
      push,
      bankName,
      formValues,
      cpsAccount,
      formErrors,
      inProgress,
      getBankName,
      clearBankName,
      routingNumberError,
      savedPaymentMethods,
      getPaymentMethodStatus,
      paymentError,
      clearPaymentError,
      showFormErrors,
      selectedPaymentMethod,
      deleteSavedBankAccount,
      deletePaymentMethodStatus,
      scheduledPayment,
      isRecurring,
      flowType,
      history,
      location,
    } = this.props;

    const formName = 'add-payment-form';

    return (
      <div className="add-pre-pay-funds-container">
        <ModalComponent
          image={ReviewErrorImage}
          open={
            !!(paymentError && paymentError.errorType === ERROR_TYPES.MODAL)
          }
          close={clearPaymentError}
          titleText={
            paymentError &&
            paymentError.name === ERROR_NAMES.SERVICE_UNAVAILABLE
              ? errorTitleText
              : genericErrorTitle
          }
          bodyText1={
            paymentError &&
            paymentError.name === ERROR_NAMES.SERVICE_UNAVAILABLE
              ? serviceUnavailableErrorMessage
              : genericErrorBody
          }
        />

        <FullPage noCard>
          <PaymentHeader
            title="Add Funds to Pre-Pay Balance"
            subNavRoutes={ROUTES_MAP.PRE_PAY_ROUTES}
            currentPaymentStep={MAKE_PAYMENT_STEPS.PAYMENT_INFO}
            paymentError={paymentError}
            showFormErrors={showFormErrors}
          />

          <AddPaymentFormComponent onSubmit={this.submitPrepayDetails}>
            <PrePayAccountInformationComponent
              notes={formValues.notes}
              selectedAccount={cpsAccount}
              onChange={this.handleChange}
              validate={this.validateFormField}
              history={history}
              location={location}
            />

            <SchedulePayment
              onChange={this.handleChange}
              scheduledPayment={scheduledPayment}
              isRecurring={isRecurring}
              flowType={flowType}
              errors={formErrors}
              amountTypeSelected={formValues.amountTypeSelected}
            />

            {cpsAccount ? (
              <SavedPaymentMethods
                push={push}
                user={user}
                headerTitle="Add Funds From Your Bank"
                hideManageLink
                onChange={this.handleChange}
                paymentError={paymentError}
                paymentMethods={savedPaymentMethods}
                clearPaymentError={clearPaymentError}
                deletePaymentMethod={deleteSavedBankAccount}
                getPaymentMethodStatus={getPaymentMethodStatus}
                selectedPaymentMethod={selectedPaymentMethod}
                deletePaymentMethodStatus={deletePaymentMethodStatus}
                cpsAccount={cpsAccount}
              />
            ) : null}

            {selectedPaymentMethod &&
            selectedPaymentMethod.tokenValue ===
              DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT.tokenValue &&
            cpsAccount ? (
              <div className="pre-pay-form-wrapper">
                <AccountInformation
                  bankName={bankName}
                  clearHidden={this.clearHiddenFormErrors}
                  clearBankName={clearBankName}
                  errors={formErrors}
                  formName={formName}
                  getBankName={getBankName}
                  form={formValues}
                  onChange={this.handleChange}
                  routingNumberError={routingNumberError}
                  validate={this.validateFormField}
                  countryCode={cpsAccount.locationCode}
                />
                <BillingInformation
                  errors={formErrors}
                  addressLine1={formValues.addressLine1}
                  addressLine2={formValues.addressLine2}
                  city={formValues.city}
                  zip={formValues.zip}
                  phone={formValues.phone}
                  onChange={this.handleChange}
                  selectedCountry={formValues.country}
                  selectedState={formValues.state}
                  validate={this.validateFormField}
                />
                <SaveAccount
                  errors={formErrors}
                  formName={formName}
                  onChange={this.handleChange}
                  paymentMethodAlias={formValues.paymentMethodAlias}
                  savePaymentMethod={formValues.savePaymentMethod}
                  validate={this.validateFormField}
                  countryCode={cpsAccount.locationCode}
                />
              </div>
            ) : (
              <div className="no-account" />
            )}

            <hr className="pre-pay-divider" />

            <div className="next-button-container">
              <MaterialButton
                id="next-button"
                value="Next, Review"
                type="submit"
                style={{ width: 'auto' }}
              />
            </div>
          </AddPaymentFormComponent>
        </FullPage>

        {inProgress ? (
          <PaymentProcessingOverlay processingText="Verifying Information..." />
        ) : null}
      </div>
    );
  }
}

PrePayAddFunds.propTypes = {
  location: locationPropType.isRequired,
  history: PropTypes.shape({}).isRequired,
  submitPaymentForm: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  formValues: paymentForm.isRequired,
  formWarnings: paymentFormWarnings.isRequired,
  formErrors: paymentFormErrors.isRequired,
  bankName: PropTypes.string,
  cpsAccount: cpsAccountPropType,
  user: userPropType,
  push: PropTypes.func.isRequired,
  setPaymentFormErrors: PropTypes.func.isRequired,
  updatePaymentForm: PropTypes.func.isRequired,
  getBankName: PropTypes.func.isRequired,
  clearBankName: PropTypes.func.isRequired,
  routingNumberError: PropTypes.bool,
  savedPaymentMethods: paymentAccounts,
  getPaymentMethodStatus: PropTypes.string,
  deleteSavedBankAccount: PropTypes.func.isRequired,
  deletePaymentMethodStatus: PropTypes.string,
  clearPaymentError: PropTypes.func.isRequired,
  paymentError: paymentErrorPropType,
  selectedPaymentMethod: PropTypes.oneOfType([
    paymentAccountPropType,
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  showFormErrors: PropTypes.bool.isRequired,
  scheduledPayment: PropTypes.instanceOf(Date),
  isRecurring: PropTypes.bool.isRequired,
  flowType: PropTypes.string,
  setPaymentFlowType: PropTypes.func.isRequired,
};

PrePayAddFunds.defaultProps = {
  paymentError: null,
  scheduledPayment: null,
  savedPaymentMethods: null,
  cpsAccount: null,
  bankName: null,
  user: null,
  selectedPaymentMethod: null,
  deletePaymentMethodStatus: '',
  routingNumberError: false,
  getPaymentMethodStatus: null,
  flowType: null,
};

export default PrePayAddFunds;
