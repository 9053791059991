import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { verifyUsePackageUom } from 'utils/verifyUsePackageUom';
import { getUnitOfMeasureLong } from 'components/Shop/utils/unitMeasuresLong';
import getUnitOfMeasureLabel from 'components/Shop/utils/getUnitOfMeasureLabel';
import { NO_PRICE_CART_MESSAGE } from '../../../../../shared/constants/ecommMessageText';
import getPriceLabel from '../../../utils/getPriceLabel';

import './orderItem.scss';

const OrderItem = (props) => {
  const selectedAccount = useSelector((state) => state.selectedAccount);
  const useLongFormUomLabel = verifyUsePackageUom(selectedAccount);

  const { product, isCanada } = props;
  const {
    orderQuantity,
    packageUom,
    productName,
    netTotalPrice,
    netUnitPrice,
  } = product;
  let { packageSize } = product;

  // if Canadian account, use long-form UOM label, else use short-form, e.g, 'liters' vs. 'L'
  if (useLongFormUomLabel) {
    packageSize = packageSize.replace(
      packageUom,
      getUnitOfMeasureLong(packageUom)
    );
  }

  const formattedSubtotal = netTotalPrice
    ? numeral(netTotalPrice).format('$0,0.00')
    : `${NO_PRICE_CART_MESSAGE}`;

  const formattedUnitPrice = netUnitPrice
    ? numeral(netUnitPrice).format('$0,0.00')
    : `${NO_PRICE_CART_MESSAGE}`;

  return (
    <tr className="order-item__wrapper" data-test="order-item">
      <td className="order-item__table-data">
        <span className="order-item__header--mobile" data-test="product-header">
          Product Name
        </span>
        <span
          className="order-item__product-text"
          data-test="product-description"
        >
          {productName}
        </span>
      </td>
      <td className="order-item__table-data">
        <span className="order-item__header--mobile" data-test="uom-label">
          {getUnitOfMeasureLabel(isCanada)}
        </span>
        <span className="order-item__purchase-text" data-test="order-pack-size">
          {packageSize}
        </span>
      </td>
      <td className="order-item__table-data">
        <span className="order-item__header--mobile">Order Quantity</span>
        <span data-test="order-quantity">{orderQuantity}</span>
      </td>
      <td className="order-item__table-data">
        <span className="order-item__header--mobile" data-test="price-label">
          {getPriceLabel(isCanada)}
        </span>
        <div data-test="price-per-unit">{formattedUnitPrice}</div>
      </td>
      <td className="order-item__tabla-data--last">
        <span className="order-item__header--mobile">Subtotal</span>
        <div className="order-item__numeric-data" data-test="subtotal">
          {formattedSubtotal}
        </div>
      </td>
    </tr>
  );
};

OrderItem.propTypes = {
  product: PropTypes.shape({
    orderQuantity: PropTypes.number,
    packageSize: PropTypes.string,
    packageUom: PropTypes.string,
    productName: PropTypes.string,
    netTotalPrice: PropTypes.number,
    netUnitPrice: PropTypes.number,
  }).isRequired,
  isCanada: PropTypes.bool.isRequired,
};

export default OrderItem;
