import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chip, ChipSet } from 'nutrien-common-components-react/dist/mdc';
import { customFormat } from 'shared/utils/DateHelper';
import { ALL_TIME_START_DATE } from '../../ProductHistoryConstants';
import TimeFrameDialog from './TimeframeDialog';

class TimeframeSelector extends Component {
  state = {
    dialogOpen: false,
    dateRange: { start: this.props.startDate, end: this.props.endDate },
    showDatepicker: false,
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleRangeChange = (dateRange) => this.setState({ dateRange });

  acceptChosenTimeframe = (timeframe, startDate = '', endDate = '') => {
    this.setState({ dialogOpen: false, showDatepicker: false });
    this.props.setNewTimeframeForProductHistory(timeframe, startDate, endDate);
  };

  handleCloseTimeframeSelectorDialog = () =>
    this.setState({
      showDatepicker: false,
      dialogOpen: false,
    });

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleSelectTimeFrame = () => {
    const { showDatepicker, dateRange } = this.state;
    if (showDatepicker) {
      const { start, end } = dateRange;
      this.acceptChosenTimeframe('custom', start, end);
      this.setState({ showDatepicker: false, dialogOpen: false });
    }
  };

  handleShowDatePicker = () => this.setState({ showDatepicker: true });

  renderTimeframeText = () => {
    const { startDate, endDate } = this.props;
    if (startDate === ALL_TIME_START_DATE) {
      return 'All Available';
    }
    return `${customFormat(startDate, 'M/D/YYYY')} - ${customFormat(
      endDate,
      'M/D/YYYY'
    )}`;
  };

  render() {
    const { dateRange, dialogOpen, showDatepicker } = this.state;
    return (
      <div className="product-history__div-change-filter">
        <TimeFrameDialog
          acceptChosenTimeframe={this.acceptChosenTimeframe}
          dateRange={dateRange}
          dialogOpen={dialogOpen}
          handleCloseTimeframeSelectorDialog={
            this.handleCloseTimeframeSelectorDialog
          }
          handleDialogClose={this.handleDialogClose}
          handleRangeChange={this.handleRangeChange}
          handleSelectTimeFrame={this.handleSelectTimeFrame}
          handleShowDatePicker={this.handleShowDatePicker}
          showDatepicker={showDatepicker}
        />
        <p className="product-history__p-change-filter">
          Timeframe (
          <span
            className="product-history__span-change-filter"
            onClick={this.openDialog}
            onKeyPress={this.openDialog}
            tabIndex="0"
            role="button"
          >
            Change
          </span>
          )
        </p>
        <ChipSet>
          <Chip
            id="product-history-timeframe-selector"
            label={this.renderTimeframeText()}
            onClick={this.openDialog}
            data-test="product-history-timeframe-selector"
            className="product-history__material-chip-filter"
            backgroundColor="white"
            type="selected"
          />
        </ChipSet>
      </div>
    );
  }
}

TimeframeSelector.propTypes = {
  setNewTimeframeForProductHistory: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default TimeframeSelector;
