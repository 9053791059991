import {
  Divider,
  List,
  ListItem,
  Typography,
  withStyles,
} from '@material-ui/core';
import get from 'lodash/get';
import { Snackbar } from 'nutrien-common-components-react/dist/mdc';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { ApiErrorMessage, MaterialLoadingIcon } from 'shared/ui';
import { ERROR_TYPES } from './actions/constants';
import { ERROR_MESSAGES } from './constants';
import NotificationsPreferencesItem from './NotificationsPreferencesItem';
import {
  categorizedPreferences as CategorizedPreferencesPropType,
  notificationPreference as notificationPreferencePropType,
  notificationPreferencesError as notificationPreferencesErrorPropType,
} from './notificationsPreferencesPropTypes';
import PhoneModal from './PhoneModal';
import styles from './styles';
import WeatherStoryPreferencesContainer from './WeatherNotificationsPreferences/WeatherNotificationsPreferencesContainer';

const ECOMM_NOTIF_TYPES = [
  'Order Confirmation',
  'Order Submitted',
  'Order Fulfilled',
  'Order Invoiced',
  'Order Cancellation',
];

const renderErrorMessage = (errorType) => {
  const message = ERROR_MESSAGES[errorType] || ERROR_MESSAGES.default;
  return (
    <>
      {message} Please try again. If you need help, please{' '}
      <Link to="/contact">contact support</Link>.
    </>
  );
};

const NotificationsPreferences = ({
  classes,
  isFetching,
  errorFetching,
  updateError,
  categorizedPreferences,
  onPreferenceChange,
  emails,
  emailPreferences,
  phonePreferences,
  emailPreferenceChanges,
  phonePreferenceChanges,
  handlePreferredPhoneNumberSubmitSuccess,
  handlePreferredPhoneNumberSubmitError,
  isPhoneModalOpen,
  togglePhoneModal,
}) => {
  const { globalEcommerce } = useFlags();

  if (isFetching) {
    return <MaterialLoadingIcon />;
  }

  if (errorFetching) {
    return (
      <ApiErrorMessage
        entityName="Notification preferences"
        className={classes.errorMessage}
      />
    );
  }

  return (
    <>
      <Typography
        id="notifications-method-heading"
        className={classes.sectionTitle}
        gutterBottom
      >
        Notification Methods
      </Typography>
      <List id="notifications-email-list" disablePadding>
        <Divider />
        {emails.map((email) => (
          <div key={email.value}>
            <ListItem divider classes={{ root: classes.listItem }}>
              {email.value || 'Email Not Available'}
            </ListItem>
          </div>
        ))}
      </List>
      {Object.keys(categorizedPreferences).map((preferenceCategory) => (
        <Fragment key={preferenceCategory}>
          <Typography
            id={`account-preferences-heading-${preferenceCategory}`}
            className={classes.sectionTitle}
            gutterBottom
          >
            {`${preferenceCategory} Notification Settings`}
          </Typography>
          <Divider />
          <List disablePadding>
            {Object.keys(categorizedPreferences[preferenceCategory]).map(
              (preferenceKey) => {
                const preferenceItem =
                  categorizedPreferences[preferenceCategory][preferenceKey];

                // use changed ui state first, fall back to redux.
                const emailValue =
                  emailPreferenceChanges[preferenceItem.formKey] !== undefined
                    ? emailPreferenceChanges[preferenceItem.formKey]
                    : emailPreferences[preferenceItem.formKey];

                // use changed ui state first, fall back to redux.
                let phoneValue = false;

                if (
                  phonePreferenceChanges[preferenceItem.formKey] !== undefined
                ) {
                  phoneValue = phonePreferenceChanges[preferenceItem.formKey];
                } else {
                  phoneValue = phonePreferences[preferenceItem.formKey];
                }

                const preferenceItemComponent = (
                  <NotificationsPreferencesItem
                    key={preferenceItem.formKey}
                    formKey={preferenceItem.formKey}
                    emailValue={emailValue}
                    phoneValue={phoneValue}
                    preferenceKey={preferenceKey}
                    onPreferenceChange={onPreferenceChange}
                  />
                );
                // Hide ecomm preferences
                if (ECOMM_NOTIF_TYPES.indexOf(preferenceKey) !== -1) {
                  return (
                    <span
                      key={preferenceItem.formKey}
                      data-test="ecommerce-related-preferences"
                    >
                      {globalEcommerce && preferenceItemComponent}
                    </span>
                  );
                }
                return preferenceItemComponent;
              }
            )}
          </List>
        </Fragment>
      ))}
      <WeatherStoryPreferencesContainer />
      <PhoneModal
        onSubmitSuccess={handlePreferredPhoneNumberSubmitSuccess}
        onSubmitError={handlePreferredPhoneNumberSubmitError}
        isOpen={isPhoneModalOpen}
        toggleModal={togglePhoneModal}
      />
      <Snackbar
        timeout={5000}
        isOpen={
          updateError && updateError.errorType === ERROR_TYPES.UPDATE_FAILURE
        }
        message={renderErrorMessage(get(updateError, 'errorType'))}
      />
    </>
  );
};

NotificationsPreferences.defaultProps = {
  classes: {},
  updateError: false,
  isFetching: false,
  errorFetching: false,
  isPhoneModalOpen: false,
  togglePhoneModal: () => {},
  handlePreferredPhoneNumberSubmitSuccess: () => {},
  handlePreferredPhoneNumberSubmitError: () => {},
};

NotificationsPreferences.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  updateError: notificationPreferencesErrorPropType,
  isFetching: PropTypes.bool,
  errorFetching: PropTypes.bool,
  categorizedPreferences: CategorizedPreferencesPropType.isRequired,
  onPreferenceChange: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(notificationPreferencePropType).isRequired,
  emailPreferences: PropTypes.objectOf(PropTypes.bool).isRequired,
  phonePreferences: PropTypes.objectOf(PropTypes.bool).isRequired,
  emailPreferenceChanges: PropTypes.objectOf(PropTypes.bool).isRequired,
  phonePreferenceChanges: PropTypes.objectOf(PropTypes.bool).isRequired,
  isPhoneModalOpen: PropTypes.bool,
  togglePhoneModal: PropTypes.func,
  handlePreferredPhoneNumberSubmitSuccess: PropTypes.func,
  handlePreferredPhoneNumberSubmitError: PropTypes.func,
};

export default withStyles(styles)(NotificationsPreferences);
