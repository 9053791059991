// TODO No logic in this file
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'history.js';

import { reducer as intercom } from 'shared/ui/Feedback/IntercomMiddleware';
import farmInsights from '@nutrien/farm-insights-client/dist/redux/reducers';
import farmTree from '@nutrien/farm-tree-navigation/dist/redux/reducers';
import { geospatialRootReducer as geospatial } from '@nutrien/geospatial';
import { reducer as legalDocument } from 'components/LegalDocuments';
import { reducer as weatherNotificationsPreferences } from 'components/Notifications/NotificationsPreferences/WeatherNotificationsPreferences';
import { reducer as notificationsPreferences } from 'components/Notifications/NotificationsPreferences';
import { reducer as notifications } from 'components/Notifications';
import { paymentsReducer as payments } from 'components/Payments';
import { shopReducer as shop } from 'components/Shop/reducers/shopReducer';
import contractedProducts from 'components/Shop/reducers/contractedProductsReducer/contractedProductsReducer';
import documents from 'components/Documents/reducers/documentsReducer/documentsReducer';
import { reducer as terms } from 'components/Terms';
import { reducer as accountLink } from 'components/LinkAccount/LinkAccountComponent';
import { reducer as passwordReset } from 'components/PasswordReset';
import { reducer as registration } from 'components/Registration';
import { reducer as employee } from 'components/Employee';
import {
  rbacReducer as rbac,
  addUserFormReducer as addUserForm,
} from 'components/Profile/rbacRedux';
import { roleDelegationReducer as roleDelegation } from 'components/Roles/Redux/RoleDelegation';
import browser from 'shared/ui/Header/reducers/browserReducer';
import { selectedAccount } from 'shared/ui/Header/reducers/accountSelectorReducer';
import productHistory from 'components/ProductHistory/ProductHistoryReducer';
import market from 'components/Market/MarketReducer';
import topProducts from 'components/Landing/TopProducts/TopProductsReducer';
import purchases from 'components/Landing/PurchasesCard/PurchasesReducer';
import productLabels from 'components/ProductLabels/ProductLabelsReducer';
// NOTE: had to import reducer directly from path instead of domain index due to Jest creating a circular dependency when running tests
import profile from 'components/Profile/Tabs/tabsReducer';
import { reducer as invoice } from 'components/Invoice';
import { reducer as invoices } from 'components/Account/Invoices';
import { reducer as paymentsTab } from 'components/Account/Payments';
import licenses from 'components/Licenses/reducers';
import cart from 'components/Cart/reducers/cartReducer/cartReducer';
import orders from 'components/Orders/reducers/reducers';

import { LOGOUT } from 'components/Authentication/actions/authenticationConstants';
import { reducer as permits } from 'components/Permits';
import organization from 'components/Organization/reducers';
import { reducer as accountsTab } from 'components/Profile/Tabs/AccountsTab';
import authentication from 'components/Authentication/reducers/authenticationReducer';
import nutrienGFA from 'components/NutrienGFA/reducers';
import credit from './creditReducer';
import user from './userReducers';

import {
  accounts,
  accountActivity,
  accountTab,
  statementSummaries,
  openItems,
  associatedOrgs,
} from './accountsReducer';
import {
  prepayDetails,
  prepayHistory,
  prepayTransaction,
} from './prepayReducers';
import { alert } from './alertReducer';

// please keep this list alphabetical, for ease of manual searching
export const createReducers = (asyncReducers) =>
  combineReducers({
    accounts,
    accountActivity,
    accountLink,
    accountTab,
    accountsTab,
    addUserForm,
    alert,
    associatedOrgs,
    authentication,
    browser,
    cart,
    contractedProducts,
    credit,
    documents,
    employee,
    farmInsights,
    farmTree,
    geospatial,
    intercom,
    invoice,
    invoices,
    legalDocument,
    licenses,
    market,
    notifications,
    notificationsPreferences,
    nutrienGFA,
    openItems,
    orders,
    organization,
    passwordReset,
    payments,
    paymentsTab,
    permits,
    prepayDetails,
    prepayHistory,
    prepayTransaction,
    productHistory,
    productLabels,
    profile,
    purchases,
    rbac,
    registration,
    roleDelegation,
    router: connectRouter(history),
    selectedAccount,
    shop,
    statementSummaries,
    terms,
    topProducts,
    user,
    weatherNotificationsPreferences,
    ...asyncReducers,
  });

const createRootReducer = (asyncReducers) => (state, action) => {
  // Clear all state on logout
  if (action.type === LOGOUT) return createReducers(asyncReducers)({}, action);
  return createReducers(asyncReducers)(state, action);
};

export default createRootReducer;
