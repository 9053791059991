import accountFormatType from 'utils/accountUtils';

export const accountHeaders = [
  {
    header: 'Description',
    primaryColumns: ['nickname'],
    backupColumns: ['name', 'number'],
    type: 'link',
    colSpan: 2,
    formatType: accountFormatType.DASH,
  },
  {
    header: 'Current Balance',
    primaryColumns: ['balance'],
    type: 'double',
    colSpan: 1,
    align: 'right',
  },
  {
    header: 'Prepay Balance',
    primaryColumns: ['prepayBalance'],
    type: 'double',
    colSpan: 1,
    align: 'right',
  },
  {
    header: 'Currently Due',
    primaryColumns: ['currentDue'],
    type: 'double',
    colSpan: 1,
    align: 'right',
  },
];
