import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'redux';

import { redirectToEXHLogin } from 'utils/redirectToEXHLogin';
import {
  loginEmployee,
  loginEmployeeFromSession,
  updateEmployeeLoginEmail,
  updateEmployeeLoginPassword,
  updateEmployeeLoginShowPassword,
} from './actions';
import EmployeeLogin from './EmployeeLogin';

export class EmployeeLoginContainer extends Component {
  loginFromExhCookie() {
    const {
      isLoggedIn,
      loginEmployeeFromSession, // eslint-disable-line no-shadow
      flags: { employeeLoginRedirectsToExh },
    } = this.props;

    /* redirect user to EXH login if no cookie is found */
    const exhSession = Cookies.get('exhSession');
    if (!exhSession) {
      return employeeLoginRedirectsToExh && redirectToEXHLogin();
    }

    const {
      authToken: authorizationToken,
      fsrAuthToken,
      expiryDate: expiration,
    } = JSON.parse(exhSession);
    Cookies.remove('exhSession');

    if (!isLoggedIn && authorizationToken && expiration) {
      return loginEmployeeFromSession({
        authorizationToken,
        fsrAuthToken,
        expiration,
        isEmployee: true,
      });
    }

    return employeeLoginRedirectsToExh && redirectToEXHLogin();
  }

  renderRedirect() {
    const { location } = this.props;
    const queryParams = parse(location.search.substr(1));

    const redirectPath = Cookies.get('cxhRedirectPath');
    Cookies.remove('cxhRedirectPath');

    const fromLocation = {
      pathname: '/employee',
      ...(parse(queryParams.state).from || (location.state || {}).from), // get from url or router state
    };

    return <Redirect to={redirectPath || fromLocation} />;
  }

  renderLoginPage() {
    const {
      updateEmployeeLoginEmail, // eslint-disable-line no-shadow
      updateEmployeeLoginPassword, // eslint-disable-line no-shadow
      updateEmployeeLoginShowPassword, // eslint-disable-line no-shadow
      loginEmployee, // eslint-disable-line no-shadow
      email,
      password,
      showPassword,
      ...rest
    } = this.props;

    const handleChange = (e) => {
      const { target } = e;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;

      let ret = null;

      if (name === 'email') {
        ret = updateEmployeeLoginEmail(value);
      } else if (name === 'password') {
        ret = updateEmployeeLoginPassword(value);
      }

      return ret;
    };

    const toggleDisplayValue = () =>
      updateEmployeeLoginShowPassword(!showPassword);

    const handleSubmit = (e) => {
      e.preventDefault();
      return loginEmployee(email, password);
    };

    return (
      <EmployeeLogin
        handleChange={handleChange}
        toggleDisplayValue={toggleDisplayValue}
        handleSubmit={handleSubmit}
        email={email}
        password={password}
        showPassword={showPassword}
        {...rest}
      />
    );
  }

  render() {
    const {
      isLoggedIn,
      flags: { employeeLoginRedirectsToExh },
    } = this.props;

    if (isLoggedIn) {
      return this.renderRedirect();
    }

    this.loginFromExhCookie();
    return !employeeLoginRedirectsToExh && this.renderLoginPage();
  }
}

EmployeeLoginContainer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  loginEmployeeFromSession: PropTypes.func.isRequired,
  updateEmployeeLoginEmail: PropTypes.func.isRequired,
  updateEmployeeLoginPassword: PropTypes.func.isRequired,
  updateEmployeeLoginShowPassword: PropTypes.func.isRequired,
  loginEmployee: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  showPassword: PropTypes.bool.isRequired,
  flags: PropTypes.shape({
    employeeLoginRedirectsToExh: PropTypes.bool,
  }),
};

EmployeeLoginContainer.defaultProps = {
  flags: { employeeLoginRedirectsToExh: false },
};

export const mapStateToProps = (state) => {
  const {
    error,
    isLoading,
    isLoggedIn,
    loginForm,
  } = state.employee.employeeLogin;

  return {
    error,
    isLoggedIn,
    isLoading,
    alert: state.alert,
    // loginForm contains email, password, showPassword fields
    ...loginForm,
  };
};

const mapDispatchToProps = {
  push,
  loginEmployee,
  loginEmployeeFromSession,
  updateEmployeeLoginEmail,
  updateEmployeeLoginPassword,
  updateEmployeeLoginShowPassword,
};

export const ConnectedEmployeeLoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeLoginContainer);

export default compose(
  withRouter,
  withLDConsumer()
)(ConnectedEmployeeLoginContainer);
