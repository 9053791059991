import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Badge, useTheme } from '@material-ui/core';
import NotificationImportant from '@material-ui/icons/NotificationImportant';

const NotificationsBadge = ({ unreadCount, onClick }) => {
  const { palette } = useTheme();

  return (
    <IconButton
      aria-label={`${unreadCount} unread notifications`}
      onClick={onClick}
      data-test="notifications-badge-button"
    >
      <Badge
        badgeContent={unreadCount}
        color="error"
        data-test="notifications-badge"
      >
        <NotificationImportant style={{ color: palette.text.primary }} />
      </Badge>
    </IconButton>
  );
};

NotificationsBadge.defaultProps = {
  unreadCount: 0,
  onClick: undefined,
};

NotificationsBadge.propTypes = {
  unreadCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default NotificationsBadge;
