import cloneDeep from 'lodash/cloneDeep';
import { LOCATION_CHANGE } from 'connected-react-router';

import { ACTION_TYPES, INVOICE_STATUSES } from '../actions';

const {
  CLEAR_SELECTED_INVOICES,
  DESELECT_INVOICE,
  SELECT_ALL_INVOICES,
  DESELECT_ALL_INVOICES,
  SELECT_INVOICE,
} = ACTION_TYPES;

// TODO: these values should just come from selectors
// and seectedInvoices should simply be about adding and removing Ids
const initialState = {
  totalAmountDue: 0,
  paid: [],
  unpaid: [],
};

const deselectInvoice = (status, id, amountDue, selectedInvoicesCopy) => {
  const tempSelectedInvoicesCopy = selectedInvoicesCopy;
  if (status === INVOICE_STATUSES.PAID || status === INVOICE_STATUSES.PENDING) {
    tempSelectedInvoicesCopy.paid = selectedInvoicesCopy.paid.filter(
      (invoiceId) => invoiceId !== id
    );
  } else {
    const difference = selectedInvoicesCopy.totalAmountDue - amountDue;
    tempSelectedInvoicesCopy.totalAmountDue =
      Math.round(difference * 1e2) / 1e2;

    tempSelectedInvoicesCopy.unpaid = selectedInvoicesCopy.unpaid.filter(
      (invoiceId) => invoiceId !== id
    );
  }
  return tempSelectedInvoicesCopy;
};

const selectInvoice = (status, id, amountDue, selectedInvoicesCopy) => {
  const tempSelectedInvoicesCopy = selectedInvoicesCopy;
  if (status === INVOICE_STATUSES.PAID || status === INVOICE_STATUSES.PENDING) {
    tempSelectedInvoicesCopy.paid.push(id.toString());
  } else {
    tempSelectedInvoicesCopy.totalAmountDue =
      Math.round((selectedInvoicesCopy.totalAmountDue + amountDue) * 1e2) / 1e2;
    selectedInvoicesCopy.unpaid.push(id.toString());
  }
  return tempSelectedInvoicesCopy;
};

// a helper function that is only invoked when the action.type is SELECT_INVOICE,
// and which returns the appropriately copied/modified state object
const selectInvoiceHelper = (state, action) => {
  const { status, id, amountDue } = action.payload;
  const selectedInvoicesCopy = cloneDeep(state);
  return selectInvoice(status, id, amountDue, selectedInvoicesCopy);
};

// a helper function that is only invoked when the action.type is DESELECT_INVOICE,
// and which returns the appropriately copied/modified state object
const deselectInvoiceHelper = (state, action) => {
  const { status, id, amountDue } = action.payload;
  const selectedInvoicesCopy = cloneDeep(state);
  return deselectInvoice(status, id, amountDue, selectedInvoicesCopy);
};

// a helper function that is only invoked when the action.type is SELECT_ALL_INVOICES,
// and which returns the appropriately copied/modified state object
const selectAllInvoicesHelper = (state, action) => {
  let selectedInvoicesCopy = state ? cloneDeep(state) : initialState;
  const invoicesList = action.payload;

  invoicesList.forEach((invoice) => {
    const { status, id, amountDue } = invoice;
    if (
      !(
        selectedInvoicesCopy.unpaid.includes(invoice.id.toString()) ||
        selectedInvoicesCopy.paid.includes(invoice.id.toString())
      )
    ) {
      selectedInvoicesCopy = selectInvoice(
        status,
        id,
        amountDue,
        selectedInvoicesCopy
      );
    }
  });

  return selectedInvoicesCopy;
};

// a helper function that is only invoked when the action.type is DESELECT_ALL_INVOICES,
// and which returns the appropriately copied/modified state object
const deselectAllInvoicesHelper = (state, action) => {
  let selectedInvoicesCopy = state ? cloneDeep(state) : initialState;
  const invoicesList = action.payload;

  invoicesList.forEach((invoice) => {
    const { status, id, amountDue } = invoice;

    selectedInvoicesCopy = deselectInvoice(
      status,
      id,
      amountDue,
      selectedInvoicesCopy
    );
  });

  return selectedInvoicesCopy;
};

const selectedInvoices = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_INVOICE:
      return selectInvoiceHelper(state, action);
    case DESELECT_INVOICE:
      return deselectInvoiceHelper(state, action);
    case SELECT_ALL_INVOICES:
      return selectAllInvoicesHelper(state, action);
    case DESELECT_ALL_INVOICES:
      return deselectAllInvoicesHelper(state, action);
    case LOCATION_CHANGE: {
      const match = action.payload.location.pathname.match(
        /(accounts\/\d*\/invoices)|(make-a-secure-payment)|(review-payment)/g
      );

      return match === null ? initialState : state;
    }
    case CLEAR_SELECTED_INVOICES:
      return initialState;
    default:
      return state;
  }
};

export default selectedInvoices;
