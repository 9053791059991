import { NOTIFICATION_READ_STATUS_TOGGLED as type } from './constants';

export default (notification) => {
  const { id, type: notificationType } = notification;

  return {
    type,
    id,
    notificationType,
  };
};
