// dependencies
import React from 'react';
import PropTypes from 'prop-types';

// stylesheets
import './BreadCrumbContainer.css';

const BreadCrumbContainer = (props) => (
  <div
    className={`BreadCrumbContainer ${
      props.noPadding ? 'BreadCrumbContainer__no-padding' : ''
    }`}
  >
    {props.children}
  </div>
);

BreadCrumbContainer.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
};

BreadCrumbContainer.defaultProps = {
  noPadding: false,
};

export default BreadCrumbContainer;
