import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as productDetailActions from '../../actions/productDetailActions/productDetailActions';
import ProductTotalVolumeDropdown from '../../components/ProductTotalVolumeDropdown/ProductTotalVolumeDropdown';

const mapStateToProps = ({ shop }) => ({
  product: shop.product,
  selectedVariant: shop.selectedVariant,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...productDetailActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTotalVolumeDropdown);
