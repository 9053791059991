import {
  EMPLOYEE_LOGIN_FAILURE,
  UPDATE_EMPLOYEE_LOGIN_EMAIL,
  UPDATE_EMPLOYEE_LOGIN_PASSWORD,
} from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE_LOGIN_EMAIL:
    case UPDATE_EMPLOYEE_LOGIN_PASSWORD:
      return null;
    case EMPLOYEE_LOGIN_FAILURE:
      return action.error;
    default:
      return state;
  }
};
