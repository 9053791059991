import React from 'react';
import PropTypes from 'prop-types';

const HeaderIconNav = ({ iconMenuItems }) => {
  return (
    <div data-test="header-icon-nav" className="icon-nav-section-container">
      {iconMenuItems.length &&
        iconMenuItems.map((child) => {
          return child;
        })}
    </div>
  );
};

HeaderIconNav.propTypes = {
  iconMenuItems: PropTypes.node,
};

HeaderIconNav.defaultProps = {
  iconMenuItems: null,
};

export default HeaderIconNav;
