import React from 'react';
import PropTypes from 'prop-types';

import { InfoCardContainer } from 'shared/ui/InfoCard';
import TotalNonPrepayBalanceCard from './TotalNonPrepayBalanceCard';
import TotalPrepayBalanceCard from './TotalPrepayBalanceCard';
import CurrentDueCard from './CurrentDueCard';

const BalanceCards = ({ account }) => {
  return (
    <InfoCardContainer>
      <TotalNonPrepayBalanceCard account={account} />
      <TotalPrepayBalanceCard account={account} />
      <CurrentDueCard account={account} />
    </InfoCardContainer>
  );
};

BalanceCards.propTypes = {
  account: PropTypes.shape({}).isRequired,
};

export default BalanceCards;
