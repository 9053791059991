export default {
  paymentProcessingOverlay: {
    position: 'fixed',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    backgroundColor: 'rgba(240,238,238, 0.78)',
    zIndex: 100,
  },
  loadingIconContainer: {
    position: 'relative',
    height: '100%',
  },
  loadingIconWrapper: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    height: '50px',
    margin: 'auto',
    textAlign: 'center',
  },
};
