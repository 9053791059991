import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import {
  Select as MuiSelect,
  MenuItem,
  Table,
  TableBody,
  TableCell as MaterialUiTableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';

import { ApiErrorMessage, MaterialLoadingIcon } from 'shared/ui';

import withPermits from '../withPermits';
import NoPermitsMessage from './NoPermitsMessage';
import {
  getExpirationDate,
  isPermitExpiringSoon,
  isPermitExpired,
} from '../permitUtils';
import styles, {
  selectPermitsFilter,
  tableHeadCellStyles,
  tableCellStyles,
} from './styles';
import ExpirationCell from './ExpirationCell';

const Select = withStyles(selectPermitsFilter)(MuiSelect);
const TableHeadCell = withStyles(tableHeadCellStyles)(MaterialUiTableCell);
const TableCell = withStyles(tableCellStyles)(MaterialUiTableCell);

export const PermitsTable = ({
  permits,
  classes,
  isFetchingPermits,
  fetchPermitsError,
}) => {
  const { state: routerState } = useLocation();

  const [permitFilter, setPermitFilter] = useState(
    routerState?.filter ?? 'All'
  );
  const [permitList, setPermitList] = useState([...permits]);

  useEffect(() => {
    const filteredPermits = permits.filter((permit) => {
      switch (permitFilter) {
        case 'All':
          return permit;
        case 'Active':
          return permit.permitActive;
        case 'Inactive':
          return !permit.permitActive;
        default:
          return permit;
      }
    });

    setPermitList([...filteredPermits]);
  }, [permits, permitFilter]);

  if (isFetchingPermits) return <MaterialLoadingIcon />;

  if (fetchPermitsError) return <ApiErrorMessage entityName="Permits" />;

  if (!isFetchingPermits && !permitList.length)
    return (
      <>
        <h6>Permit Status Filter</h6>
        <Select
          value={permitFilter}
          onChange={(e) => setPermitFilter(e.target.value)}
        >
          <MenuItem value="All">Display All</MenuItem>
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </Select>
        <NoPermitsMessage permitFilter={permitFilter} />
      </>
    );

  return (
    <>
      <h6>Permit Status Filter</h6>
      <Select
        value={permitFilter}
        onChange={(e) => setPermitFilter(e.target.value)}
      >
        <MenuItem value="All">Display All</MenuItem>
        <MenuItem value="Active">Active</MenuItem>
        <MenuItem value="Inactive">Inactive</MenuItem>
      </Select>
      <Table className={classNames('permits-table', classes.permitsTable)}>
        <TableHead>
          <TableRow>
            <TableHeadCell>Permit ID</TableHeadCell>
            <TableHeadCell>Issued By</TableHeadCell>
            <TableHeadCell>Expiration Date</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permitList.map((permit, index) => {
            const { permitId, growerName } = permit;
            // permits can be shared between growers, it seems,
            // so use a key that will be unique so React won't complain
            const key = `${growerName}${permitId}_${index}`;

            return (
              <TableRow
                className={classNames(classes.row, 'permits-table-row')}
                key={key}
                testId={key}
              >
                <TableCell>
                  <Link
                    to={{
                      pathname: `/permits/${permitId}/growers/${growerName}`,
                      state: { permit },
                    }}
                  >
                    {permitId}
                  </Link>
                </TableCell>
                <TableCell>{permit.issuedBy}</TableCell>
                <TableCell>
                  <ExpirationCell
                    isExpired={isPermitExpired(permit)}
                    isExpiringSoon={isPermitExpiringSoon(permit)}
                    expirationDate={getExpirationDate(permit)}
                    classes={classes}
                  />
                </TableCell>
                <TableCell>
                  {permit.permitActive ? 'Active' : 'Inactive'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

PermitsTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  permits: PropTypes.arrayOf(PropTypes.object),
  fetchPermitsError: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.string),
  ]),
  isFetchingPermits: PropTypes.bool,
};

PermitsTable.defaultProps = {
  classes: {},
  fetchPermitsError: undefined,
  isFetchingPermits: false,
  permits: [],
};

export default withStyles(styles)(withPermits(PermitsTable));
