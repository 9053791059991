import * as constants from '../constants';

const { RESET_SEARCH, SET_SEARCH_QUERY } = constants;

export const resetSearch = () => ({
  type: RESET_SEARCH,
});

export const setSearchQuery = (query) => {
  return {
    type: SET_SEARCH_QUERY,
    query,
  };
};

export default setSearchQuery;
