import fetchPermitsFailure from './fetchPermitsFailure';
import fetchPermitsSuccess from './fetchPermitsSuccess';
import fetchingPermits from './fetchingPermits';
import * as permitsService from '../permitsService';

export default () => (dispatch) => {
  dispatch(fetchingPermits());
  return permitsService
    .get()
    .then((permits) => dispatch(fetchPermitsSuccess(permits)))
    .catch((err) => dispatch(fetchPermitsFailure(err)));
};
