import PropTypes from 'prop-types';

const terms = PropTypes.shape({
  status: PropTypes.string,
  termsData: PropTypes.shape({
    document: PropTypes.string,
    version: PropTypes.string,
    content: PropTypes.string,
  }),
  error: PropTypes.shape({}),
});

export default terms;
