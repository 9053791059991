import {
  SET_PURCHASES_OK,
  SET_PURCHASES_LOADING,
  SET_PURCHASES_ERROR,
  SET_PURCHASES_FILTER,
} from './PurchasesActions';

export function setPurchasesOk(purchases) {
  return { type: SET_PURCHASES_OK, payload: purchases };
}

export function setPurchasesLoading() {
  return { type: SET_PURCHASES_LOADING };
}

export function setPurchasesError() {
  return { type: SET_PURCHASES_ERROR };
}

export const setPurchasesFilter = (payload) => ({
  type: SET_PURCHASES_FILTER,
  payload,
});
