import PropTypes from 'prop-types';

export const privacyPreference = PropTypes.shape({
  preference: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
});

export const privacyPreferences = PropTypes.arrayOf(privacyPreference);

export const accountIds = PropTypes.arrayOf(PropTypes.string);
