import React from 'react';
import {
  Card,
  CardContent,
} from 'nutrien-common-components-react/dist/mdc/Card';

import currency from 'shared/utils/numberFormatters/currency';
import { DateFormatter } from 'shared/ui';
import { invoicePropType } from '../invoicePropTypes';
import { hasDiscountTerms } from './invoiceCardUtils';

export const DueDateCard = ({ invoice }) => (
  <Card className="invoice-card">
    <CardContent>
      <p className="invoice-card__subtitle">
        {
          hasDiscountTerms(invoice) && invoice.discountApplicable
            ? 'Discount Due Date' // discount available and applicable
            : 'Due Date' // either expired discount or no discount terms
        }
      </p>
      <h1 className="invoice-card__title">
        {
          hasDiscountTerms(invoice) && invoice.discountApplicable
            ? DateFormatter(invoice.discountDate) // discount available and applicable
            : DateFormatter(invoice.dueDate) // either expired discount or no discount terms
        }
      </h1>
      {hasDiscountTerms(invoice) && (
        <p className="invoice-card__subtitle">
          {invoice.discountApplicable
            ? `Available Discount ${currency(invoice.availableDiscount)}`
            : `Discount Expired on ${DateFormatter(invoice.discountDate)}`}
        </p>
      )}
    </CardContent>
  </Card>
);

DueDateCard.propTypes = {
  invoice: invoicePropType.isRequired,
};

export default DueDateCard;
