import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { findKey } from 'lodash';
import closeModalX from 'assets/close-modal.svg';
import bankAccountNumberImage from 'assets/accountNumber.png';
import accountNumberImage from 'assets/canada-account-number.png';
import institutionNumberImage from 'assets/institution-number.png';
import transitNumberImage from 'assets/transit-number.png';

import routingNumberImage from 'assets/routingNumber.png';
import { ModalType } from '../../../../../PaymentConstants';

import styles from './styles';

const ModalTypeToImage = {
  [ModalType.bankAccountModal]: bankAccountNumberImage,
  [ModalType.routingModal]: routingNumberImage,
  [ModalType.accountModal]: accountNumberImage,
  [ModalType.institutionNumberModal]: institutionNumberImage,
  [ModalType.transitNumberModal]: transitNumberImage,
};

const HelpModalComponent = (props) => {
  if (!props.modalOpen) {
    return null;
  }
  const { classes } = props;
  const image = ModalTypeToImage[props.modalType];
  const modalType = findKey(ModalType, (val) => val === props.modalType);
  return (
    <div>
      <div className={classes.helpModalDarkBg} />
      <div className={classes.helpModalContainer}>
        <div className={classes.helpModalArrowCover} />
        <svg height="20" width="30" className={classes.helpModalSvg}>
          <polygon
            points="0,0 15,10, 30,0"
            className={classes.helpModalSvgPolygon}
          />
        </svg>
        <div className={classes.helpModalTitle}>{props.modalText}</div>
        <div
          className={classes.helpModalClose}
          style={{
            backgroundImage: `url(${closeModalX})`,
          }}
          onClick={props.closeModal}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              props.closeModal(modalType);
            }
          }}
          role="button"
          tabIndex={0}
        />
        <img className={classes.helpModalImage} src={image} alt="error" />
        <div className={classes.helpModalDescription}>
          {props.modalDescription}
        </div>
      </div>
    </div>
  );
};

HelpModalComponent.propTypes = {
  modalOpen: PropTypes.bool,
  modalType: PropTypes.number.isRequired,
  modalText: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalDescription: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

HelpModalComponent.defaultProps = {
  modalOpen: false,
};

export default withStyles(styles)(HelpModalComponent);
