/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@nutrien/uet-react/Box';
import Card from '@nutrien/uet-react/Card';
import { Grid, Row, Cell } from 'nutrien-common-components-react/dist/mdc/Grid';

import NutrienSign from '../../../assets/nutrien-ag-solutions-retail-farm-centre.jpg';

import './openStyles.scss';

const OpenForBusiness = () => {
  return (
    <Card component={Box} height="100%" p={2} data-test="covid-card">
      <Grid className="ecomm-open__grid">
        <Row>
          <Cell desktopColumns={4} tabletColumns={8} phoneColumns={4}>
            <img src={NutrienSign} alt="Nutrien Branch" />
          </Cell>
          <Cell desktopColumns={8} tabletColumns={8} phoneColumns={4}>
            <Grid className="ecomm-open__text-container">
              <Row>
                <Cell columns={12}>
                  <h2 data-test="ecomm-open-title">
                    Nutrien Ag Solutions<span>&trade;</span> - Open for Business
                    to Serve You
                  </h2>
                </Cell>
              </Row>
              <Row className="ecomm-open__text-row">
                <Cell columns={12}>
                  <p className="ecomm-open__text-p-top">
                    We continue to monitor developments and follow protocols in
                    response to COVID-19 for the safety and health of our
                    employees and customers. Please contact your Nutrien
                    representative for information specific to your local branch
                    or learn more about{' '}
                    <a
                      className="ecomm-open__text-p-link"
                      href="https://www.nutrien.com/customers"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Nutrien’s COVID-19 plan for customers.
                    </a>
                  </p>
                </Cell>
              </Row>
            </Grid>
          </Cell>
        </Row>
      </Grid>
    </Card>
  );
};

export default OpenForBusiness;
