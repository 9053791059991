import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  expansionPanel: {
    margin: '12px 0',
  },
  heading: {
    fontWeight: 600,
  },
  icon: {
    fill: theme.palette.common.black,
  },
  contractInfoAccountName: {
    fontWeight: 600,
    fontSize: '24px',
    color: theme.palette.text.primary,
    '@media (max-width: 700px)': {
      fontSize: '20px',
    },
  },
  contractInfoContractNumber: {
    marginTop: '11px',
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  contractInfoContractDetails: {
    display: 'flex',
    marginTop: '35px',
    marginBottom: '85px',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      marginTop: '0px',
      marginBottom: '20px',
    },
  },
  contractDetailsColLeft: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    '@media (max-width: 700px)': {
      width: '100%',
    },
  },
  contractDetailsColRight: {
    display: 'flex',
    marginLeft: '20px',
    flexDirection: 'column',
    '@media (max-width: 700px)': {
      width: '100%',
      marginLeft: 0,
      paddingTop: '20px',
    },
  },
  detailKey: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    marginRight: '24px',
    width: '100px',
    '@media (max-width: 700px)': {
      fontSize: '12px',
    },
  },
  detailValue: {
    fontSize: '18px',
    color: theme.palette.text.primary,
    '@media (max-width: 700px)': {
      fontSize: '16px',
    },
  },
  keyValueWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  keyValueWrapperPadded: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '20px',
  },
  backToDocuments: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '12px',
    fontWeight: 400,
    marginLeft: '-6px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  backToDocumentsIcon: {
    transform: 'rotate(90deg)',
    textAlign: 'left',
  },
  backToDocumentsText: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '12px',
    fontWeight: 400,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
