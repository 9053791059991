import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Icon } from 'nutrien-common-components-react/dist/mdc/';
import MiniCartItem from './MiniCartItem';
import miniCartPropTypes from '../miniCartPropTypes';
import NoPricePopper from '../../../../../shared/ui/NoPricePopper';
import { NO_PRICE_MESSAGE } from '../../../../../shared/constants/ecommMessageText';

import './styles.scss';

const MiniCartContent = ({
  productName,
  cart,
  displayPrice,
  totalNetPrice,
  setTotalVolume,
  totalVolume,
  updateCartData,
  isBulk,
  usesPackageUom,
}) => {
  const unpricedVariant = !Number(
    get(cart, 'mostRecentAddition.variant.net_price', null)
  );

  return (
    <div className="mini-cart__content active">
      <div className="mini-cart__content__inner-wrapper">
        <Icon type="success" icon="success" className="mini-cart__icon" />
        <div className="mini-cart__blurb">
          <span
            className="mini-cart__highlight"
            data-test="mini-cart-product-name"
          >
            {productName}
          </span>{' '}
          <span>has been added to your cart.</span>
        </div>
      </div>
      {unpricedVariant && (
        <div className="mini-cart__no-price-container">
          <div
            className="mini-cart__no-price-message"
            data-test="mini-cart__no-price-message"
          >
            {NO_PRICE_MESSAGE}
            <NoPricePopper location="Minicart" />
          </div>
        </div>
      )}
      {cart.cartData.length && (
        <MiniCartItem
          cart={cart}
          displayPrice={displayPrice}
          totalNetPrice={totalNetPrice}
          setTotalVolume={setTotalVolume}
          totalVolume={totalVolume}
          updateCartData={updateCartData}
          isBulk={isBulk}
          usesPackageUom={usesPackageUom}
        />
      )}
    </div>
  );
};

MiniCartContent.propTypes = {
  productName: PropTypes.string,
  cart: miniCartPropTypes.isRequired,
  displayPrice: PropTypes.string.isRequired,
  totalNetPrice: PropTypes.number.isRequired,
  setTotalVolume: PropTypes.func.isRequired,
  totalVolume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateCartData: PropTypes.func.isRequired,
  isBulk: PropTypes.bool.isRequired,
  usesPackageUom: PropTypes.bool.isRequired,
};

MiniCartContent.defaultProps = {
  productName: '',
  totalVolume: '0',
};

export default MiniCartContent;
