import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Loading from 'shared/ui/MaterialLoadingIcon';
import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import cbotLogo from 'assets/CBOTLogo_44.png';
import delayedImage from 'assets/ail_delayed.gif';
import dtnLogo from 'assets/dtn_32x32.png';
import MarketTable from './MarketTable';
import styles from './styles';
import PageHeader from '../../shared/ui/PageHeader/PageHeader';

const renderContent = (market, classes) => {
  switch (market.status) {
    case 'OK':
      return (
        <div className="market-table">
          <div className={classes.titleArea}>
            <p className={classes.subtitle}>
              {`Updated on ${market.timestamp}`}
            </p>
          </div>
          <MarketTable commodities={market.commodities} />
        </div>
      );
    case 'LOADING':
      return <Loading />;
    default:
      return <ApiErrorMessage entityName="Market" />;
  }
};

const renderDisclaimers = (classes) => (
  <div className={classes.disclaimers}>
    <img src={dtnLogo} alt="" className={classes.disclaimer} />
    <img src={cbotLogo} alt="" className={classes.disclaimer} />
    <img src={delayedImage} alt="" className={classes.disclaimer} />
  </div>
);

const Market = (props) => {
  const { classes, market } = props;
  return (
    <div className="market">
      <PageHeader
        title="Markets"
        breadcrumbs={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'Markets',
          },
        ]}
      />
      {renderContent(market, classes)}
      {renderDisclaimers(classes)}
    </div>
  );
};

Market.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  market: PropTypes.shape({
    commodities: PropTypes.arrayOf(
      PropTypes.shape({
        symbol: PropTypes.string,
        last: PropTypes.string,
        previous: PropTypes.string,
        change: PropTypes.string,
        open: PropTypes.string,
        close: PropTypes.string,
        high: PropTypes.string,
        low: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default withStyles(styles)(Market);
