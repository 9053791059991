import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import filter from 'lodash/filter';
import Flag from 'assets/priorityProduct.svg';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import sortVariantDropDown from '../../utils/sortVariantDropDown';
import { setClickArea } from '../../utils/setClickArea';
import VariantOptions from '../../containers/VariantOptionsContainer/VariantOptionsContainer';
import './ProductCard.scss';
import ProductImage from '../ProductImage';

const getProductInfo = (attributes) => {
  const productCategory = get(attributes, 'product_category.name');
  const commonName = get(attributes, 'common_name', null);

  return commonName
    ? filter([productCategory, commonName]).join(' / ')
    : productCategory;
};

const ProductCard = ({
  attributes,
  id,
  handleOpenModal,
  loadingPrices,
  index,
  recentlyOrdered,
}) => {
  sortVariantDropDown(attributes.variants);
  const productInfo = getProductInfo(attributes);
  const history = useHistory();

  /* PROMOTIONAL PRODUCTS: whenever a promotion is run that requires a product to get rendered with special treatment
   * then just turn on the 'promotion-active' LaunchDarkly flag,
   * add that product's productId (the guid id that comes back in the products data call) to the 'promotion-products' LaunchDarkly flag. That flag is an array.
   * and then that product's card will get rendered with a green border, a denim and white dollar sign triangle icon in the upper left corner,
   * and the text that you put in the 'promotion-message' LaunchDarkly flag will render in denim above the product title.
   * when your promo is over, just turn off the 'promotion-active' flag but leave the rest of this promotional product code in place.
   * NOTE: to get a product to have priority placement in the product data results requires a change to the ESB repo.
   * SEE: Promotional/Priority Products section in the ReadMe.md for details.
   */
  const { promotionActive, promotionMessage, promotionProducts } = useFlags();
  const isPromotionalProduct = (productId) => {
    return promotionActive && promotionProducts.includes(productId);
  };

  return (
    <div className="product-wrapper" data-test="product-wrapper">
      <a
        href={`/products/${id}`}
        onClick={(element) => setClickArea(element, id, history, attributes)}
        className="product-link"
      >
        {isPromotionalProduct(id) && (
          <img
            src={Flag}
            alt="Contracted Products Icon"
            className="promotional-product-icon"
          />
        )}
        <div
          className={`product-wrapper__inner-wrapper ${
            isPromotionalProduct(id) ? 'promotional-product' : ''
          }`}
          data-test="product-wrapper-inner-wrapper"
        >
          {isPromotionalProduct(id) && (
            <div>
              <span
                className="promotional-product-label"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: promotionMessage }}
              />
            </div>
          )}
          <h2 className="product-wrapper__product-name" title={attributes.name}>
            {attributes.name}
          </h2>
          <h2
            className="product-wrapper__manufacturer-name"
            title={productInfo}
            data-test="product-info"
          >
            {productInfo}
          </h2>
          <div className="product-wrapper__image-container">
            <ProductImage
              imgSrc={attributes.variants[0].image}
              alt={attributes.name}
              context="product-card"
            />
          </div>
          <div className="product-variant-options__container">
            <VariantOptions
              index={index}
              data-test="product-variant-options"
              attributes={attributes}
              handleOpenModal={handleOpenModal}
              gtmOrigin="Product List Page"
              loadingPrices={loadingPrices}
              recentlyOrdered={recentlyOrdered}
            />
          </div>
        </div>
      </a>
    </div>
  );
};

ProductCard.propTypes = {
  recentlyOrdered: PropTypes.bool,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  attributes: PropTypes.shape({
    name: PropTypes.string,
    product_logo: PropTypes.string,
    manufacturer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    variants: PropTypes.PropTypes.arrayOf(
      PropTypes.shape({
        agrian_id: PropTypes.string,
        ars_key: PropTypes.string,
        digital_variant_name: PropTypes.string,
        guid: PropTypes.string,
        position: PropTypes.string,
        image: PropTypes.string,
      })
    ),
  }).isRequired,
  handleOpenModal: PropTypes.func,
  loadingPrices: PropTypes.bool,
};

ProductCard.defaultProps = {
  recentlyOrdered: false,
  handleOpenModal: () => {},
  loadingPrices: false,
};

export default ProductCard;
