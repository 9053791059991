import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AccountingList,
  AccountingListItem,
  ApiErrorMessage,
  DateFormatter,
  MaterialLoadingIcon,
  Pagination,
  PaginationNext,
  PaginationPrev,
} from 'shared/ui';

import { currency } from 'shared/utils/numberFormatters';
import { getPage } from 'shared/utils/pagination';
import Notification from '@nutrien/uet-react/Notification';
import OpenItemsExportLinks from './OpenItemsExportLinks';

import './OpenItems.css';

class OpenItems extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { handleAccountUpdate, account } = this.props;
    if (account.id) {
      handleAccountUpdate(account.id);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { handleAccountUpdate, account } = this.props;
    if (
      nextProps.account.id !== account.id ||
      (nextProps.account.id && !account.id)
    ) {
      handleAccountUpdate(nextProps.account.id);
    }
  }

  // This is to get the header information out of the header constant
  // as the array contains different types.
  getMobileHeader = (headers, index) => {
    return headers[index].text || headers[index];
  };

  handlePaginationClick = (page) => {
    const { push, account, queryString, updateAccountTab } = this.props;
    let newQueryString = '';
    queryString.page = page;
    const queryArray = Object.entries(queryString);
    queryArray.forEach((item) => {
      newQueryString += `&${item[0]}=${item[1]}`;
    });

    if (newQueryString.length > 0) {
      newQueryString = `?${newQueryString.slice(1)}`;
    }

    updateAccountTab(`open-items${newQueryString}`);

    push(`/accounts/${account.id}/open-items${newQueryString}`);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  handleFilterChange = (event) => {
    const {
      push,
      account,
      queryString,
      updateAccountTab,
      gtmAccountOpenItemsFilterChange,
    } = this.props;
    const { value, name, options, selectedIndex } = event.target;
    queryString[name] = value;
    delete queryString.page;
    let newQueryString = '';

    const queryArray = Object.entries(queryString);
    queryArray.forEach((item) => {
      newQueryString += `&${item[0]}=${item[1]}`;
    });

    if (newQueryString.length > 0) {
      newQueryString = `?${newQueryString.slice(1)}`;
    }

    gtmAccountOpenItemsFilterChange(name, options[selectedIndex].text);

    updateAccountTab(`open-items${newQueryString}`);

    push(`/accounts/${account.id}/open-items${newQueryString}`);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  render() {
    const {
      account,
      typeFilter,
      dueFilter,
      currentPage,
      openItems,
    } = this.props;

    const headers = [
      'Date',
      'Transaction ID',
      'Type',
      { text: 'Amount', align: 'right' },
      'Due Date',
    ];

    const pageSize = 24;
    const today = new Date();
    const thisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const filteredOpenItems = openItems.list.filter((item) => {
      const itemDue = new Date(item.dueDate);
      const itemType = item.type;
      if (dueFilter !== 'all' && item.dueDate === null) return false;
      if (dueFilter === 'currentDue' && itemDue > thisMonth) return false;
      if (dueFilter === 'overDue' && itemDue > today) return false;
      if (dueFilter === 'notYetDue' && itemDue < thisMonth) return false;
      if (typeFilter === 'invoice' && itemType !== 'Invoice') return false;
      if (typeFilter === 'creditInvoice' && itemType !== 'Credit Invoice')
        return false;
      if (typeFilter === 'payment' && itemType !== 'Payment') return false;
      if (
        typeFilter === 'accountAdjustment' &&
        itemType !== 'Account Adjustment'
      )
        return false;
      if (typeFilter === 'discountTaken' && itemType !== 'Discount Taken')
        return false;
      return true;
    });

    const paginatedOpenItems = getPage(
      filteredOpenItems,
      currentPage,
      pageSize
    );

    const onLastPage = filteredOpenItems.length <= pageSize * currentPage;

    const openItemsList = paginatedOpenItems.map((item) => {
      const { id, date, type, openAmount, dueDate } = item;

      return (
        <tr className="activity-row activity-row-mobile" key={item.id}>
          <AccountingListItem
            data={DateFormatter(date)}
            mobileHeader={this.getMobileHeader(headers, 0)}
          />
          {type === 'Invoice' || type === 'Credit Invoice' ? (
            <AccountingListItem
              data={id}
              link={`/accounts/${this.props.account.id}/invoices/${id}`}
              data-test="transaction-id-selector"
              onClick={() =>
                this.props.gtmAccountOpenItemsInteraction(
                  'Click',
                  'Transaction ID'
                )
              }
              mobileHeader={this.getMobileHeader(headers, 1)}
            />
          ) : (
            <AccountingListItem
              data={id}
              mobileHeader={this.getMobileHeader(headers, 1)}
            />
          )}
          <AccountingListItem
            data={type}
            mobileHeader={this.getMobileHeader(headers, 2)}
          />
          <AccountingListItem
            data={currency(openAmount)}
            right
            mobileHeader={this.getMobileHeader(headers, 3)}
          />
          <AccountingListItem
            data={DateFormatter(dueDate)}
            mobileHeader={this.getMobileHeader(headers, 4)}
          />
        </tr>
      );
    });

    const titleBarContent = (
      <div className="account__filter-title">
        <p className="account-list__filter-label">Type:</p>
        <select
          name="typeFilter"
          className="filter-line-items"
          value={typeFilter}
          onChange={this.handleFilterChange}
        >
          <option value="all">All Items</option>
          <option value="invoice">Invoice</option>
          <option value="creditInvoice">Credit Invoice</option>
          <option value="payment">Payment</option>
          <option value="accountAdjustment">Account Adjustment</option>
          <option value="discountTaken">Discount Earned</option>
        </select>
        <p className="account-list__filter-label">Status:</p>
        <select
          name="dueFilter"
          className="filter-line-items"
          value={dueFilter}
          onChange={this.handleFilterChange}
        >
          <option value="all">Open</option>
          <option value="currentDue">Currently Due</option>
          <option value="overDue">Past Due</option>
          <option value="notYetDue">Future Due</option>
        </select>
      </div>
    );

    const openItemsTable = (
      <div>
        <div className="account__download-link-container">
          {!window.ReactNativeWebView && (
            <OpenItemsExportLinks accountId={account.id} />
          )}
        </div>
        <AccountingList
          title="Open Items"
          headers={headers}
          titleBarContent={titleBarContent}
        >
          {openItemsList}
        </AccountingList>
        {filteredOpenItems.length === 0 ? (
          <div className="no-account-open-items">
            <Notification
              variant="info"
              data-test="account-openItems-not-found"
            >
              No open items found.
            </Notification>
          </div>
        ) : (
          onLastPage && (
            <div className="end-of-open-items">End of Open Items.</div>
          )
        )}
        <Pagination
          className="open-items-page__pagination pagination__standard"
          current={currentPage}
          total={filteredOpenItems.length}
          onChange={this.handlePaginationClick}
          pageSize={pageSize}
        />

        <Pagination
          className="pagination__mobile"
          current={currentPage}
          total={filteredOpenItems.length}
          onChange={this.handlePaginationClick}
          pageSize={pageSize}
          prevIcon={PaginationPrev}
          nextIcon={PaginationNext}
          simple
        />
      </div>
    );

    let openItemsContent;
    switch (openItems.status) {
      case 'LOADING':
        openItemsContent = <MaterialLoadingIcon />;
        break;
      case 'OK':
        openItemsContent = openItemsTable;
        break;
      case 404:
        openItemsContent = (
          <div className="end-of-activity">
            You have no Open Items to display.
          </div>
        );
        break;
      default:
        openItemsContent = <ApiErrorMessage entityName="Open Items" />;
        break;
    }

    return <div className="open-items">{openItemsContent}</div>;
  }
}

OpenItems.defaultProps = {
  push: () => {},
  currentPage: 1,
  dueFilter: 'all',
  typeFilter: 'all',
  account: { id: undefined },
  queryString: { page: null },
  openItems: { status: 'LOADING', list: [] },
  gtmAccountOpenItemsFilterChange: () => {},
  gtmAccountOpenItemsInteraction: () => {},
  handleAccountUpdate: () => {},
  updateAccountTab: () => {},
};

OpenItems.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
  }),
  push: PropTypes.func,
  queryString: PropTypes.shape({ page: PropTypes.number }),
  updateAccountTab: PropTypes.func,
  currentPage: PropTypes.number,
  dueFilter: PropTypes.string,
  typeFilter: PropTypes.string,
  openItems: PropTypes.shape({
    // TODO: refactor openItems actions and reducers so the HTTP code for errors is stored in a separate field
    // this property should not serve two distinct purposes, either it's a "general" flag, or the HTTP code, or perhaps an object with both a string status and a number code
    status: PropTypes.oneOfType([
      PropTypes.oneOf(['LOADING', 'OK']),
      PropTypes.number,
    ]),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.string,
        type: PropTypes.string,
        openAmount: PropTypes.number,
        dueDate: PropTypes.string,
      })
    ),
  }),
  handleAccountUpdate: PropTypes.func,
  gtmAccountOpenItemsFilterChange: PropTypes.func,
  gtmAccountOpenItemsInteraction: PropTypes.func,
};

export default OpenItems;
