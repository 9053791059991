/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Icon,
} from 'nutrien-common-components-react/dist/mdc';
import WarningIcon from '@material-ui/icons/Warning';
import Add from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import get from 'lodash/get';
import './AddressForm.scss';

import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  STREET_ADDRESS_REQUIRED,
  CITY_REQUIRED,
  ZIP_REQUIRED,
  ZIP_NOT_VALID,
  COUNTY_REQUIRED,
  NICKNAME_REQUIRED,
  STATE_REQUIRED,
  PROVINCE_REQUIRED,
  POSTAL_CODE_NOT_VALID,
  POSTAL_CODE_REQUIRED,
} from 'const/addressErrorConstants';
import {
  isValidUsZipCode,
  isValidCanadianPostalCode,
} from 'utils/zipCodeValidation';

class AddressForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCountyID: '',
      selectedState: '',
      selectedCounty: '',
      selectedStateID: '',
      addressOpen: false,
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      streetAddressOne: '',
      streetAddressError: '',
      streetAddressTwo: '',
      city: '',
      cityError: '',
      zip: '',
      zipError: '',
      county: '',
      countyError: '',
      selectedStateError: '',
      nickname: '',
      nicknameError: '',
      showOverallError: false,
    };
  }

  componentDidMount() {
    this.setStateMenuItems();
  }

  setStateMenuItems() {
    const {
      addresses: { stateList },
    } = this.props;

    if (stateList && stateList.data.length > 1) {
      return stateList.data.map((state) => (
        <MenuItem value={state.name} key={state.id}>
          {state.name}
        </MenuItem>
      ));
    }

    return null;
  }

  setCountyMenuItems() {
    const countyList = get(this.props, 'addresses.countyList.data', undefined);

    if (countyList && countyList.length >= 1) {
      return countyList.map((county) => (
        <MenuItem value={county.name} key={county.id}>
          {county.name}
        </MenuItem>
      ));
    }

    return null;
  }

  setCanadianCounty = async (selectedStateID) => {
    const { getCounties, selectedAccount } = this.props;
    const counties = await getCounties(
      'CAN',
      selectedStateID,
      selectedAccount.id
    );
    const selectedCounty = counties.data.data[counties.data.data.length - 1];
    this.setState({
      selectedCounty: selectedCounty.name,
      selectedCountyID: selectedCounty.id,
      countyError: '',
    });
  };

  handleStateDropdownChange = async (event) => {
    const { getCounties, addresses, selectedAccount } = this.props;
    const selectedState = event.target.value;
    const matches = addresses.stateList.data.filter(
      (state) => state.name === event.target.value
    );
    const selectedStateID = matches[0].id;

    if (selectedAccount.locationCode === 'CAN') {
      this.setCanadianCounty(selectedStateID);
    } else {
      getCounties(
        selectedAccount.locationCode,
        selectedStateID,
        selectedAccount.id
      );
    }

    this.setState({
      selectedState,
      selectedStateID,
      selectedStateError: '',
    });
  };

  handleCountyDropdownChange = (event) => {
    const selectedCounty = event.target.value;
    const { addresses } = this.props;
    const { countyList } = addresses;

    if (countyList.data.length >= 1) {
      const matches = countyList.data.filter(
        (county) => county.name === event.target.value
      );
      const selectedCountyID = matches[0].id;

      this.setState({
        selectedCounty,
        selectedCountyID,
        countyError: '',
      });
    }
  };

  handleChange = (e) => {
    const { target } = e;
    const { name } = target;

    this.setState({
      [name]: target.value,
    });

    this.verifyField(e);
  };

  handleOpenAddress = () => {
    this.setState({ addressOpen: true });
    this.handleGetStates();
  };

  handleCloseAddress = () => {
    this.setState({ addressOpen: false });
  };

  handleAddressConfirm = () => {
    if (!this.hasErrorOrEmptyField()) {
      this.setState({ showOverallError: false });
      this.handleCloseAddress();
      this.handleSubmit();
    } else {
      this.applyFormErrors();
    }
  };

  applyFormErrors = () => {
    const { selectedAccount } = this.props;
    if (this.state.firstName === '' || this.state.firstName === undefined) {
      this.setState({ firstNameError: FIRST_NAME_REQUIRED });
    }
    if (this.state.lastName === '' || this.state.lastName === undefined) {
      this.setState({ lastNameError: LAST_NAME_REQUIRED });
    }
    if (
      this.state.streetAddressOne === '' ||
      this.state.streetAddressOne === undefined
    ) {
      this.setState({ streetAddressError: STREET_ADDRESS_REQUIRED });
    }
    if (this.state.city === '' || this.state.city === undefined) {
      this.setState({ cityError: CITY_REQUIRED });
    }
    if (
      this.state.selectedState === '' ||
      this.state.selectedState === undefined
    ) {
      if (selectedAccount.locationCode === 'CAN') {
        this.setState({ selectedStateError: PROVINCE_REQUIRED });
      } else {
        this.setState({ selectedStateError: STATE_REQUIRED });
      }
    }
    if (this.state.zip === '' || this.state.zip === undefined) {
      if (selectedAccount.locationCode === 'CAN') {
        this.setState({ zipError: POSTAL_CODE_REQUIRED });
      } else {
        this.setState({ zipError: ZIP_REQUIRED });
      }
    }
    if (
      this.state.selectedCountyID === '' ||
      this.state.selectedCountyID === undefined
    ) {
      this.setState({ countyError: COUNTY_REQUIRED });
    }
    if (this.state.countryId === '' || this.state.county === undefined) {
      this.setState({ countyError: COUNTY_REQUIRED });
    }
    if (this.state.nickname === '' || this.state.nickname === undefined) {
      this.setState({ nicknameError: NICKNAME_REQUIRED });
    }

    this.setState({ showOverallError: true });
  };

  handleSubmit = () => {
    const { saveNewAddress, selectedAccount } = this.props;
    const data = {
      type: 'shipping',
      addressLineOne: this.state.streetAddressOne,
      addressLineTwo: this.state.streetAddressTwo,
      addressLineThree: '',
      city: this.state.city,
      provinceStateValue: this.state.selectedStateID,
      countyValue: this.state.selectedCounty,
      countryValue: selectedAccount.locationCode,
      zipPostalCode: this.state.zip,
      description: this.state.nickname,
    };

    saveNewAddress(data, selectedAccount.id);
  };

  hasErrorOrEmptyField = () => {
    if (
      this.state.firstNameError === '' &&
      this.state.lastNameError === '' &&
      this.state.streetAddressError === '' &&
      this.state.cityError === '' &&
      this.state.zipError === '' &&
      this.state.countyError === '' &&
      this.state.nicknameError === '' &&
      this.state.firstName !== '' &&
      this.state.lastName !== '' &&
      this.state.streetAddressOne !== '' &&
      this.state.city !== '' &&
      this.state.zip !== '' &&
      this.state.selectedCountyID !== '' &&
      this.state.nickname !== '' &&
      this.state.selectedState !== ''
    ) {
      return false;
    }
    return true;
  };

  handleGetStates = () => {
    const { selectedAccount, getStates } = this.props;
    getStates(selectedAccount.locationCode, selectedAccount.id);
  };

  /**
   * Check for any required validation exception.
   * @param {string} input Any string entry
   * @returns boolean
   */
  isInvalidEntry = (input) => {
    // Empty space are not allowed
    return input.length < 1 || /^\s*$/g.test(input);
  };

  verifyField = (e) => {
    const { value, name } = e.target;
    const { selectedAccount } = this.props;

    if (name === 'firstName' && this.isInvalidEntry(value)) {
      this.setState({
        firstNameError: FIRST_NAME_REQUIRED,
      });
    } else if (name === 'firstName' && value.length >= 1) {
      this.setState({
        firstNameError: '',
      });
    }

    if (name === 'lastName' && this.isInvalidEntry(value)) {
      this.setState({
        lastNameError: LAST_NAME_REQUIRED,
      });
    } else if (name === 'lastName' && value.length >= 1) {
      this.setState({
        lastNameError: '',
      });
    }

    if (name === 'streetAddressOne' && this.isInvalidEntry(value)) {
      this.setState({
        streetAddressError: STREET_ADDRESS_REQUIRED,
      });
    } else if (name === 'streetAddressOne' && value.length >= 1) {
      this.setState({
        streetAddressError: '',
      });
    }

    if (name === 'city' && this.isInvalidEntry(value)) {
      this.setState({
        cityError: CITY_REQUIRED,
      });
    } else if (name === 'city' && value.length >= 1) {
      this.setState({
        cityError: '',
      });
    }

    if (name === 'zip' && this.isInvalidEntry(value)) {
      if (selectedAccount.locationCode === 'CAN') {
        this.setState({ zipError: POSTAL_CODE_REQUIRED });
      } else {
        this.setState({ zipError: ZIP_REQUIRED });
      }
    } else if (name === 'zip' && value.length >= 1) {
      let isValid;
      if (selectedAccount.locationCode === 'CAN') {
        isValid = isValidCanadianPostalCode(value);
      } else {
        isValid = isValidUsZipCode(value);
      }
      if (!isValid) {
        if (selectedAccount.locationCode === 'CAN') {
          this.setState({
            zipError: POSTAL_CODE_NOT_VALID,
          });
        } else {
          this.setState({
            zipError: ZIP_NOT_VALID,
          });
        }
      } else {
        this.setState({ zipError: '' });
      }
    }

    if (name === 'nickname' && this.isInvalidEntry(value)) {
      this.setState({
        nicknameError: NICKNAME_REQUIRED,
      });
    } else if (name === 'nickname' && value.length >= 1) {
      this.setState({
        nicknameError: '',
      });
    }
  };

  render() {
    const { selectedAccount } = this.props;
    const {
      firstNameError,
      lastNameError,
      streetAddressError,
      cityError,
      zipError,
      countyError,
      selectedStateError,
      nicknameError,
      selectedState,
      selectedCounty,
    } = this.state;

    return selectedAccount.locationCode === 'CAN' ? null : (
      <div className="address-form__outer-dialog-wrapper">
        <button
          className="address-form__add-button"
          onClick={() => this.handleOpenAddress()}
          data-test="add-address"
        >
          <Add className="address-form__add-icon" />
          <span className="address-form__add-new-address-text">
            Add New Address
          </span>
        </button>
        <Dialog
          open={this.state.addressOpen}
          onClose={this.handleCloseAddress}
          className="address-form__dialog"
        >
          <div className="address-form__dialog-header">
            <DialogTitle>Add New Address</DialogTitle>
            <Icon
              className="address-form__dialog-header--close-icon"
              icon="close"
              onClick={this.handleCloseAddress}
            />
          </div>
          <DialogContent className="address-form__dialog-content">
            {this.state.showOverallError ? (
              <div
                className="address-form__overall-error-container"
                data-test="overall-error-wrapper"
              >
                <WarningIcon className="address-form__feedback-warning-icon" />
                <span className="address-form__feedback-error-message">
                  You must fill out the highlighted fields to continue
                </span>
              </div>
            ) : (
              ''
            )}
            <div className="address-form__inner-dialog-wrapper">
              <div className="address-form__fieldset">
                <div className="address-form__inner-fieldset-wrapper">
                  <label
                    className="address-form__form-label address-form__dialog--first-name"
                    htmlFor="first-name"
                  >
                    First Name
                  </label>
                  <input
                    value={this.state.firstName}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.verifyField(e)}
                    id="first-name"
                    name="firstName"
                    className={
                      this.state.firstNameError
                        ? 'address-form__input-error'
                        : 'address-form__input'
                    }
                    data-test="address-form-first-name-input"
                  />
                  {firstNameError.length ? (
                    <span className="address-form__form-error">
                      {firstNameError}
                    </span>
                  ) : null}
                </div>
                <div className="address-form__inner-fieldset-wrapper">
                  <label
                    className="address-form__form-label address-form__dialog--last-name"
                    htmlFor="last-name"
                  >
                    Last Name
                  </label>
                  <input
                    value={this.state.lastName}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.verifyField(e)}
                    id="last-name"
                    name="lastName"
                    className={
                      this.state.lastNameError
                        ? 'address-form__input-error'
                        : 'address-form__input'
                    }
                    data-test="address-form-last-name-input"
                  />
                  {lastNameError.length ? (
                    <span className="address-form__form-error">
                      {lastNameError}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="address-form__fieldset-col">
                <label className="address-form__form-label" htmlFor="country">
                  Country
                </label>
                <input
                  value={
                    selectedAccount.locationCode === 'CAN'
                      ? 'Canada'
                      : 'United States'
                  }
                  id="country"
                  name="country"
                  readOnly
                  className={
                    this.state.hasError
                      ? 'address-form__input-error'
                      : 'address-form__input'
                  }
                  data-test="address-form-country-input"
                />
              </div>
              <div className="address-form__fieldset-col">
                <label
                  className="address-form__form-label"
                  htmlFor="street-address-one"
                >
                  Street Address One
                </label>
                <input
                  value={this.state.streetAddressOne}
                  onChange={(e) => this.handleChange(e)}
                  onBlur={(e) => this.verifyField(e)}
                  id="street-address-one"
                  name="streetAddressOne"
                  className={
                    this.state.streetAddressError
                      ? 'address-form__input-error'
                      : 'address-form__input'
                  }
                  data-test="address-form-street-address-one"
                />
                {streetAddressError.length ? (
                  <span className="address-form__form-error">
                    {streetAddressError}
                  </span>
                ) : null}
              </div>
              <div className="address-form__fieldset-col">
                <label
                  className="address-form__form-label"
                  htmlFor="street-address-two"
                >
                  Street Address Two
                  <span className="address-form__dialog-label--optional">
                    (Optional)
                  </span>
                </label>
                <input
                  value={this.state.streetAddressTwo}
                  onChange={(e) => this.handleChange(e)}
                  onBlur={(e) => this.verifyField(e)}
                  id="street-address-two"
                  name="streetAddressTwo"
                  className="address-form__input"
                  data-test="address-form__street-address-two-input"
                />
              </div>
              <div>
                <FormControl className="address-form__dropdown-wrapper">
                  <InputLabel shrink htmlFor="menu-state">
                    {selectedAccount.locationCode === 'CAN'
                      ? 'Province'
                      : 'State'}
                  </InputLabel>
                  <Select
                    id="state"
                    onChange={this.handleStateDropdownChange}
                    displayEmpty
                    inputProps={{
                      id: 'state',
                      name: 'state',
                      MenuProps: {
                        style: { zIndex: '999999' },
                      },
                    }}
                    value={selectedState}
                  >
                    <MenuItem value="" key="defaultState" selected disabled>
                      <em>
                        {selectedAccount.locationCode === 'CAN'
                          ? 'Select a Province'
                          : 'Select a State'}
                      </em>
                    </MenuItem>
                    {this.setStateMenuItems()}
                  </Select>
                </FormControl>
                {selectedStateError.length ? (
                  <span className="address-form__form-error">
                    {selectedStateError}
                  </span>
                ) : null}
              </div>
              <div className="address-form__fieldset">
                <div className="address-form__inner-fieldset-wrapper">
                  <label className="address-form__form-label" htmlFor="city">
                    City
                  </label>
                  <input
                    value={this.state.city}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.verifyField(e)}
                    id="city"
                    name="city"
                    className={`${
                      this.state.cityError
                        ? 'address-form__input-error'
                        : 'address-form__input'
                    }`}
                    data-test="address-form__city-input"
                  />
                  {cityError.length ? (
                    <span className="address-form__form-error">
                      {cityError}
                    </span>
                  ) : null}
                </div>
                <div className="address-form__inner-fieldset-wrapper">
                  <label className="address-form__form-label" htmlFor="zip">
                    {selectedAccount.locationCode === 'CAN'
                      ? 'Postal Code'
                      : 'Zip'}
                  </label>
                  <input
                    value={this.state.zip}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.verifyField(e)}
                    id="zip"
                    name="zip"
                    className={`${
                      this.state.zipError
                        ? 'address-form__input-error'
                        : 'address-form__input'
                    }`}
                    data-test="address-form__zip-input"
                  />
                  {zipError.length ? (
                    <span className="address-form__form-error">{zipError}</span>
                  ) : null}
                </div>
              </div>
              <div>
                {selectedAccount.locationCode !== 'CAN' && (
                  <div>
                    <FormControl className="address-form__dropdown-wrapper">
                      <InputLabel shrink htmlFor="menu-state">
                        County
                      </InputLabel>
                      <Select
                        id="county"
                        onChange={this.handleCountyDropdownChange}
                        displayEmpty
                        inputProps={{
                          id: 'county',
                          name: 'county',
                          MenuProps: {
                            style: { zIndex: '999999' },
                          },
                        }}
                        value={selectedCounty}
                      >
                        <MenuItem
                          value=""
                          key="defaultCounty"
                          selected
                          disabled
                        >
                          <em>Select a County</em>
                        </MenuItem>
                        {this.setCountyMenuItems()}
                      </Select>
                    </FormControl>
                    {countyError.length !== 0 && (
                      <span className="address-form__form-error">
                        {countyError}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="address-form__fieldset-col">
                <label className="address-form__form-label" htmlFor="nickname">
                  Nickname
                </label>
                <input
                  value={this.state.nickname}
                  onChange={(e) => this.handleChange(e)}
                  onBlur={(e) => this.verifyField(e)}
                  id="nickname"
                  name="nickname"
                  className={`${
                    this.state.nicknameError
                      ? 'address-form__input-error'
                      : 'address-form__input'
                  } jsNicknameInput`}
                />
                {nicknameError.length ? (
                  <span className="address-form__form-error">
                    {nicknameError}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="address-form__address-button-wrapper">
              <Button
                className="address-form__address-button-cancel"
                data-test="address-form-cancel-add-address"
                onClick={() => this.handleCloseAddress()}
                secondary
              >
                Cancel
              </Button>
              <Button
                className="address-form__address-button"
                onClick={() => this.handleAddressConfirm()}
                data-test="address-form-address-confirm"
              >
                Use This Address
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

AddressForm.propTypes = {
  addresses: PropTypes.shape({
    stateList: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          length: PropTypes.number,
          map: PropTypes.func,
          filter: PropTypes.func,
        })
      ),
    }),
    countyList: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          length: PropTypes.number,
          filter: PropTypes.func,
        })
      ),
    }),
  }).isRequired,
  getStates: PropTypes.func.isRequired,
  selectedAccount: PropTypes.shape({
    locationCode: PropTypes.string,
    id: PropTypes.string,
  }),
  saveNewAddress: PropTypes.func.isRequired,
  getCounties: PropTypes.func.isRequired,
};

AddressForm.defaultProps = {
  selectedAccount: {
    locationCode: 'USA',
    id: '1234566',
  },
};

export default AddressForm;
