import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { MaterialLoadingIcon } from 'shared/ui';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import { stripSymbolsFromNumber, formatPhoneNumber } from 'utils/phoneUtils';

import styles from './styles';

const PhoneModalContent = ({
  classes,
  isSubmitting,
  isInAddMode,
  error,
  phoneNumber,
  closeModal,
  onSubmit,
  onChange,
  currentPreferredPhoneNumber,
}) => {
  const [isDirty, setIsDirty] = React.useState(false);

  const currentPhoneNumber = isDirty
    ? phoneNumber
    : phoneNumber || currentPreferredPhoneNumber;

  if (isSubmitting) {
    return <MaterialLoadingIcon />;
  }

  const formattedPhoneNumber = formatPhoneNumber(
    stripSymbolsFromNumber(currentPreferredPhoneNumber),
    true
  );

  const paragraphText = isInAddMode
    ? 'Please add a mobile phone number for notification preferences.'
    : `We have ${formattedPhoneNumber} on record, however we'd like to make sure that this is your mobile phone number in order to receive text notifications appropriately.`;
  const inputLabel = `${isInAddMode ? '' : 'Edit '}Mobile Phone Number`;

  return (
    <>
      <p className={classes.subHeader}>{paragraphText}</p>
      <form onSubmit={onSubmit} noValidate className={classes.form}>
        <div className={classes.formField}>
          <label
            htmlFor="notifications-preferences-phone-modal"
            className={classes.inputLabel}
          >
            {inputLabel}
          </label>
          <input
            id="notifications-preferences-phone-modal"
            className={classes.inputField}
            required
            name="name"
            type="tel"
            maxLength="13"
            value={currentPhoneNumber}
            placeholder="(   )___-____"
            onChange={(e) => {
              if (!isDirty) {
                setIsDirty(true);
              }
              onChange(e);
            }}
            onBlur={() => {}}
          />
          {error && <span className={classes.error}>{error.message}</span>}
        </div>

        <div className={classes.legalInfoContainer}>
          <InfoIcon className={classes.infoIcon} />
          <p className={classes.legalInfo}>
            Carrier provider text messages rates may apply.
          </p>
        </div>
        <div className={classes.buttonContainer}>
          <MaterialButton
            type="secondary"
            value="Cancel"
            className={classes.cancelButton}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          />
          <MaterialButton
            className={classes.saveButton}
            disabled={(!currentPreferredPhoneNumber && !phoneNumber) || !!error}
            type="submit"
            value="Update"
          />
        </div>
      </form>
    </>
  );
};

PhoneModalContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  isSubmitting: PropTypes.bool,
  isInAddMode: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Error),
  phoneNumber: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  currentPreferredPhoneNumber: PropTypes.string,
};

PhoneModalContent.defaultProps = {
  classes: {},
  error: null,
  isSubmitting: false,
  currentPreferredPhoneNumber: undefined,
};

export default withStyles(styles)(PhoneModalContent);
