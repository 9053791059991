import React from 'react';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import { isEmployee } from 'shared/utils/tokenUtils/isEmployee';
import './employeeNotificationBar.scss';

const EmployeeNotificationBar = () => {
  const employee = isEmployee();
  return (
    <>
      {employee && (
        <div
          className="employee-notification-bar__wrapper"
          data-test="employee-bar"
        >
          <Icon
            className="employee-notification-bar__employee-warning-icon"
            icon="info"
            type="warning"
          />
          <span>
            In client mirroring mode you cannot order items without being logged
            in as the client.
          </span>
        </div>
      )}
    </>
  );
};

export default EmployeeNotificationBar;
