const styles = (theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '120px',
    marginLeft: 12,
    '@media(min-width: 500px)': {
      borderLeft: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      padding: '0 8px',
      width: '200px',
      '@media (min-width: 1280px)': {
        padding: 0,
      },
    },
  },
  disabledHeaderAccountSelector: {
    backgroundColor: 'white',
  },
  disabledHeaderAccountSelectorOverlay: {
    backgroundColor: '#DDD',
    width: '100%',
    position: 'absolute',
    top: -15,
    opacity: '.5',
    zIndex: 5,
    height: 67,
    left: -3,
    '@media only screen and (min-width: 500px)': {
      width: 198,
      left: -8,
      '@media only screen and (max-width:  1280px)': {
        height: 78,
        top: -19,
        left: -16,
      },
    },
  },
  disabledPopper: {
    width: '80%',
    zIndex: 1000,
    top: '-8px !important',
    '@media only screen and (min-width: 500px)': {
      width: '50%',
    },
    '@media only screen and (min-width: 1280px)': {
      width: '25%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    paddingLeft: 8,
  },
  formControlFetching: {
    alignItems: 'center',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlLabel: {
    left: '-6px',
    top: '8px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '46px',
    justifyContent: 'center',
    width: '48px',
  },
  accountSelectorError: {
    color: theme.palette.error.text,
    fontSize: '0.6rem',
    fontWeight: '600',
    paddingTop: '12px',
  },
});

export default styles;
