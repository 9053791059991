// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from '@material-ui/core';
import field from '../../assets/field-of-dreams.jpg';

const useStyles = makeStyles((theme) => ({
  siteMaintenanceBackground: {
    backgroundImage: `url(${field})`,
    backgroundSize: 'cover',
    minHeight: '100%',
    minWidth: '100%',
    position: 'fixed',
    left: '0',
    top: '0',
  },

  siteMaintenance: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },

  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '496px',
    background: 'white',
    '@media (max-width: 550px)': {
      width: '94%',
    },
  },

  maintenanceMessage: {
    padding: '42px 56px 24px',
    margin: '0',
    fontSize: '1.5rem',
    '@media (max-width: 550px)': {
      padding: '28px 34px 0 34px',
      fontSize: '1.125rem',
      fontWeight: '600',
      lineHeight: '1.22',
      paddingBottom: '0',
    },
  },

  assistanceMessage: {
    fontSize: '14px',
    padding: '0 56px 24px',
    '@media (max-width: 550px)': {
      padding: '16px 34px 0 30px',
      fontSize: '.875rem',
      lineHeight: '1.29',
    },
  },

  contactDetailsPhone: {
    padding: '0 56px 15px',
    '@media (max-width: 550px)': {
      padding: '18px 12px 0 35px',
    },
  },

  contactDetailsPhoneBottom: {
    padding: '0 56px 42px',
    '@media (max-width: 550px)': {
      padding: '11px 30px 35px 35px',
    },
  },

  contactIcon: {
    height: '11px',
    width: '11px',
    fill: theme.palette.common.black,
  },

  iconEnvelope: {
    height: '12px',
    width: '15px',
    fill: theme.palette.common.black,
  },

  phoneNumber: {
    display: 'flex',
    alignItems: 'center',
  },

  contactMethod: {
    paddingLeft: '8px',
    fontSize: '0.875rem',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },

  email: {
    '@media (max-width: 550px)': {
      letterSpacing: '-.75px',
    },
  },

  contactDetails: {
    paddingLeft: '19px',
    fontSize: '0.875rem',
    color: '#828282',
  },
}));

export default useStyles;
