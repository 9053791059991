const topAndBottomSpacing = 20;

export default Object.seal({
  iconContainer: {
    textAlign: 'center',
    marginTop: topAndBottomSpacing,
    marginBottom: 10,
  },
  icon: {
    color: '#4c9e00',
    marginRight: 5,
    marginLeft: 5,
  },
  contentText: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginBottom: topAndBottomSpacing,
    textAlign: 'center',
  },
  button: {
    maxWidth: '40%',
    marginRight: '6%',
  },
});
