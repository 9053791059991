import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import NotificationsPreferences from 'components/Notifications/NotificationsPreferences';

import styles from '../../styles';

const NotificationsTab = (props) => {
  const { classes } = props;

  return (
    <div>
      <div className={classes.sectionContainer}>
        <div className={classes.section}>
          <NotificationsPreferences />
        </div>
      </div>
    </div>
  );
};

NotificationsTab.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(NotificationsTab);
