import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router';
import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import PrePayAddFunds from './PrePayAddFunds';
import {
  bankFromRoutingNumber,
  clearBankAccountForm,
  clearBankName as clearBankNameAction,
  setAccountCountryCode,
  setBankAccountFormErrors,
  submitACHPaymentFormAction,
  updateBankAccountForm,
  setPaymentFlowType,
} from '../../Payments/Redux/actions/BankAccountFormActions';
import {
  deleteSavedBankAccount,
  getSavedBankAccounts,
  registerBankAccount,
} from '../../Payments/Redux/actions/BankAccountActions';
import * as paymentUtils from '../../Payments/Utils/PaymentUtil';
import {
  clearPaymentError as clearPaymentErrorAction,
  clearShowFormErrorsNotification as clearShowFormErrorsNotificationAction,
} from '../../Payments/Redux/actions/ErrorActions';
import {
  cpsAccounts,
  cpsAccount as cpsAccountPropType,
  location as locationPropType,
  paymentError as paymentErrorPropType,
} from '../../Payments/PaymentPropTypes';

class PrePayAddFundsContainer extends Component {
  componentDidMount() {
    this.clearAllErrors();
  }

  componentDidUpdate(prevProps) {
    const { paymentError: newPaymentError } = this.props;

    if (newPaymentError && newPaymentError !== prevProps.paymentError) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  clearAllErrors = () => {
    const {
      clearBankName,
      clearPaymentError,
      clearShowFormErrorsNotification,
    } = this.props;
    clearBankName();
    clearPaymentError();
    clearShowFormErrorsNotification();
  };

  findAccount = (accounts, accountId) =>
    accounts.find((account) => account.id === accountId);

  parseAccountIdFromUrl = () => {
    const { location } = this.props;
    const { pathname: fullurl } = location;
    const regex = /([0-9])\w+/;

    return fullurl.match(regex)[0];
  };

  render() {
    const {
      location,
      accounts,
      cpsAccount,
      updatePaymentForm,
      flags: { prepayAddFunds },
    } = this.props;
    // should be a selector
    const accountId =
      location.state && location.state.accountId
        ? location.state.accountId
        : this.parseAccountIdFromUrl();

    if (!cpsAccount) {
      // should be a selector
      const selectedAccount = this.findAccount(accounts, accountId);

      if (selectedAccount) {
        updatePaymentForm({ cpsAccount: selectedAccount });
      }
    }

    return (
      <>
        {prepayAddFunds ? (
          <PrePayAddFunds {...this.props} accountId={accountId} />
        ) : (
          <Redirect to="/" />
        )}
      </>
    );
  }
}

PrePayAddFundsContainer.propTypes = {
  clearShowFormErrorsNotification: PropTypes.func,
  accounts: cpsAccounts,
  location: locationPropType.isRequired,
  paymentError: paymentErrorPropType,
  clearBankName: PropTypes.func.isRequired,
  clearPaymentError: PropTypes.func.isRequired,
  cpsAccount: cpsAccountPropType,
  updatePaymentForm: PropTypes.func.isRequired,
  flags: PropTypes.shape({ prepayAddFunds: PropTypes.bool }).isRequired,
};

PrePayAddFundsContainer.defaultProps = {
  clearShowFormErrorsNotification: () => {},
  accounts: [],
  paymentError: null,
  cpsAccount: null,
};

const mapStateToProps = (state) => {
  const {
    bankAccountReducer: bankAccount,
    bankAccountFormReducer: bankAccountForm,
    sharedReducer: shared,
    errorReducer: errors,
  } = state.payments;

  const inProgress = paymentUtils.isLoading(state);

  return {
    inProgress,
    states: shared.states,
    bankName: bankAccountForm.bankName,
    user: state.user.userData,
    accounts: state.accounts.list,
    paymentError: errors.paymentError,
    getPaymentMethodStatus: bankAccount.getPaymentMethodStatus,
    deletePaymentMethodStatus: shared.deleteTokenStatus,
    formValues: bankAccountForm.form,
    formErrors: bankAccountForm.formErrors,
    showFormErrors: errors.showFormErrors,
    formWarnings: bankAccountForm.formWarnings,
    cpsAccount: bankAccountForm.form.cpsAccount,
    savedPaymentMethods: bankAccount.paymentAccounts,
    routingNumberError: bankAccountForm.bankNameFail,
    selectedPaymentMethod: bankAccountForm.form.savedPaymentMethod,
    scheduledPayment: bankAccountForm.form.scheduledPayment,
    isRecurring: bankAccountForm.form.isRecurring,
  };
};

const mapDispatchToProps = {
  push,
  clearBankName: clearBankNameAction,
  clearPaymentError: clearPaymentErrorAction,
  deleteSavedBankAccount,
  setPaymentFlowType,
  registerTokenAction: registerBankAccount,
  getBankName: bankFromRoutingNumber,
  getAccounts: fetchAssociatedOrgsAction,
  getSavedPaymentMethods: getSavedBankAccounts,
  updatePaymentForm: updateBankAccountForm,
  clearPaymentForm: clearBankAccountForm,
  setPaymentFormErrors: setBankAccountFormErrors,
  submitPaymentForm: submitACHPaymentFormAction,
  clearShowFormErrorsNotification: clearShowFormErrorsNotificationAction,
  setAccountCountryCode,
};

export default compose(
  withLDConsumer(),
  connect(mapStateToProps, mapDispatchToProps)
)(PrePayAddFundsContainer);
