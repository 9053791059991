import React from 'react';
import './CropConsultant.scss';

const CropConsultant = (props) => {
  const { cropConsultant } = props;
  let cropConsultantInfo;

  if (cropConsultant && Object.entries(cropConsultant).length !== 0) {
    cropConsultantInfo = (
      <div data-test="consultant-wrapper" className="consultant-wrapper">
        <div className="consultant__row">
          <span className="consultant__subheader">
            Nutrien Ag Solutions Representative
          </span>
          <span className="consultant__data">
            {cropConsultant.firstName} {cropConsultant.lastName}
          </span>
        </div>
        <div className="consultant__row">
          <span className="consultant__subheader">Email</span>
          <span className="consultant__data">
            {cropConsultant.workEmailAddress}
          </span>
        </div>
        {cropConsultant.workPhone && (
          <div className="consultant__row">
            <span className="consultant__subheader">Phone</span>
            <span className="consultant__data">
              {cropConsultant.workPhone || null}
            </span>
          </div>
        )}
      </div>
    );
  } else {
    cropConsultantInfo = <div />;
  }
  return cropConsultantInfo;
};

export default CropConsultant;
