import React, { forwardRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { Pagination } from 'shared/ui';
import { getPage } from 'shared/utils/pagination';
import { useDispatch } from 'react-redux';
import tableHeadConfig from './tableHeadConfig';
import ContractsTableHead from './ContractsTableHead';
import NoData from '../../../../shared/ui/TableNoData';
import messages from '../../constants/messages';
import {
  gtmViewContractDetails,
  gtmShopContractedSku,
} from '../../../../store/middleware/TagManager/gtmActions';

import useStyles from './styles';

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return {
    width,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(() =>
    getWindowDimensions()
  );

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const ContractsTable = (props) => {
  const startingPageSize = 10;
  const [pageNum, setPage] = useState(1);
  const [pageSizeNum, setPageSize] = useState(startingPageSize);
  const { width: pageWidth } = useWindowDimensions();

  const { page, pageSize } =
    pageWidth > 1000
      ? { page: pageNum, pageSize: startingPageSize }
      : { page: 1, pageSize: pageSizeNum };

  const loadMore = () => {
    const nextSize = pageSizeNum + startingPageSize;
    setPage(1);
    setPageSize(nextSize);
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const desc = (a, b, key) => {
    if (b[key] < a[key]) {
      return -1;
    }
    if (b[key] > a[key]) {
      return 1;
    }
    return 0;
  };

  const buildCacheKey = (direction, key) => `${direction}_${key}`;

  const getSorting = (direction, key) => {
    return direction === 'desc'
      ? (a, b) => desc(a, b, key)
      : (a, b) => -desc(a, b, key);
  };

  const stableSort = (cacheKey, array, cmp) => {
    if (!stableSort.cache) stableSort.cache = {};
    if (stableSort.cache[cacheKey]) {
      return stableSort.cache[cacheKey];
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const direction = cmp(a[0], b[0]);
      return direction || a[1] - b[1];
    });
    const result = stabilizedThis.map((el) => el[0]);
    stableSort.cache[cacheKey] = result;
    return result;
  };

  const ViewContractOrShopLink = forwardRef((props, ref) => (
    <Link innerRef={ref} {...props} />
  ));

  const dispatch = useDispatch();
  const classes = useStyles();
  const [sorting, setSorting] = useState({
    order: 'desc',
    orderBy: 'createdOn',
  });
  const { tableData } = props;
  const handleOrder = (column) => {
    const isDesc = sorting.orderBy === column && sorting.order === 'desc';
    setSorting({ order: isDesc ? 'asc' : 'desc', orderBy: column });
  };

  const isContractFulfilled = (data) => {
    return data.available > 0 ? (
      <Button
        variant="contained"
        color="primary"
        component={ViewContractOrShopLink}
        onClick={() => dispatch(gtmShopContractedSku('/documents/contract'))}
        className={classes.actionButton}
        to={`/products?acct=${data.accountId}&cont=${data.contractNumber}`}
        data-test="contract-shop-button"
      >
        Shop
      </Button>
    ) : (
      <Button
        variant="contained"
        color="primary"
        disabled
        className={classes.actionButton}
        data-test="contract-fulfilled-button"
      >
        Fulfilled
      </Button>
    );
  };

  const sortedRows = stableSort(
    buildCacheKey(sorting.order, sorting.orderBy),
    tableData,
    getSorting(sorting.order, sorting.orderBy)
  );

  const paginatedRows = getPage(sortedRows, page, pageSize);

  const renderPagination = () => {
    if (pageWidth > 1000) {
      return (
        <Pagination
          data-test="documents-contracts-pagination"
          current={page}
          total={tableData.length}
          onChange={handleChangePage}
          pageSize={pageSize}
        />
      );
    }
    if (tableData.length > pageSize) {
      return (
        <Button
          data-test="contracts-load-more"
          className={classes.loadMoreButton}
          onClick={loadMore}
        >
          Load More Contracts
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <Table className={classes.table}>
        <ContractsTableHead
          config={tableHeadConfig}
          handleOrder={handleOrder}
          sorting={sorting}
          order={sorting.order}
          orderBy={sorting.orderBy}
          sortingEnabled={tableData.length !== 0}
        />
        <TableBody>
          {/* eslint-disable-next-line react/prop-types */}
          {!tableData.length && (
            <NoData
              message={messages.CONTRACTS_TABLE_NO_DATA}
              tableCols={tableHeadConfig.length}
            />
          )}
          {tableData.length &&
            paginatedRows.map((data, index) => (
              <TableRow
                className={classes.row}
                data-test="contracts-table-row"
                // eslint-disable-next-line react/no-array-index-key
                key={`contractsTableRow_${data.contractNumber}_${index}`}
              >
                <TableCell align="left" className={classes.cell}>
                  <span className={classes.cellHeaderMobile}>Date</span>
                  <span
                    data-test="date-created-on"
                    className={classes.contractDate}
                  >
                    {moment(data.createdOn).format('MM/DD/YY')}
                  </span>
                </TableCell>
                <TableCell align="left" className={classes.cell}>
                  <span className={classes.cellHeaderMobile}>Number</span>
                  <span data-test="contract-number">
                    <Link
                      to={`/documents/contract?acct=${data.accountId}&cont=${data.contractNumber}`}
                      className={classes.tableLink}
                      data-test="contract-link"
                    >
                      {data.contractNumber}
                    </Link>
                  </span>
                </TableCell>
                <TableCell className={classes.cellEnd}>
                  <span className={classes.cellHeaderMobile}>Status</span>
                  <span>{data.contractStatus}</span>
                </TableCell>
                <TableCell align="right" className={classes.cell}>
                  <span className={classes.cellHeaderMobile}>Subtotal</span>
                  <span data-test="available">
                    {numeral(data.available).format('$0,0.00')}
                  </span>
                </TableCell>
                <TableCell align="right" className={classes.buttonCell}>
                  {data.orderReason === 'Prepay – SKU (Price Lock)' &&
                  data.contractStatus === 'Completely processed' ? (
                    isContractFulfilled(data)
                  ) : (
                    <div className={classes.actionButton} />
                  )}
                  <Button
                    variant="outlined"
                    component={ViewContractOrShopLink}
                    color="primary"
                    to={`/documents/contract?acct=${data.accountId}&cont=${data.contractNumber}`}
                    className={classes.actionButton}
                    onClick={() => dispatch(gtmViewContractDetails)}
                    data-test="contract-view-button"
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {renderPagination()}
    </>
  );
};

ContractsTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
};
ContractsTable.defaultProps = {
  tableData: [],
};

export default ContractsTable;
