import {
  buttonContainerStyles,
  mobileButtonContainerStyles,
  modalContainerStyles,
} from 'shared/styles';

const styles = (theme) => ({
  modalContainer: {
    ...modalContainerStyles,
  },
  title: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 30,
    fontSize: 21,
    fontWeight: 600,
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.14,
    marginBottom: 10,
  },
  p: {
    margin: '0px 0px',
    fontSize: 13,
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
  notification: {
    padding: 0,
  },
  spacer: {
    margin: '0px 0px 40px',
  },
  closeModal: {
    cursor: 'pointer',
  },
  addUserButton: {
    width: 'auto',
  },
  buttonContainer: {
    ...mobileButtonContainerStyles,
    maxWidth: 'none',
  },
  buttonContainerSmall: {
    ...mobileButtonContainerStyles,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: 14,
    '& span': {
      marginLeft: 10,
    },
  },
  '@media (min-width: 720px)': {
    buttonContainer: {
      ...buttonContainerStyles,
      maxWidth: 311,
    },
    buttonContainerSmall: {
      ...buttonContainerStyles,
      width: '90%',
      margin: 'auto',
      float: 'none',
    },
  },
});

export default styles;
