import api from 'shared/lib/cxhApiService';
import isValidAccountNumber from 'shared/utils/isValidAccountNumber';

export const getByAccount = async (accountNumber) => {
  if (!accountNumber) throw new Error('Account number Required');
  if (!isValidAccountNumber(accountNumber))
    throw new Error('Account Number is Not Valid');

  const response = await api.get(`v1/accounts/${accountNumber}/users`);
  return response.data.users;
};

export const get = () =>
  api.get('v1/employee/customers').then((res) => res.data);
