export const accountFormatType = {
  DASH: 'dash',
};

function checkAccountForValue(account, fields, isNickname) {
  let valueExists = false;
  fields.forEach((field) => {
    if (isNickname && account.nickname !== null && account.nickname !== '')
      valueExists = true;
    if (!isNickname && account[field] !== null && account[field] !== '')
      valueExists = true;
  });
  return valueExists;
}

function concatByDash(account, fields) {
  return fields.map((field) => account[field]).join(' - ');
}

function concatenateFields(account, fields, formatType) {
  switch (formatType) {
    case accountFormatType.DASH:
      return concatByDash(account, fields);
    default:
      return concatByDash(account, fields);
  }
}

export function formatAccountData(account, fields, formatType) {
  if (!account || !fields) {
    return '';
  }
  if (fields && fields.length > 1) {
    return concatenateFields(account, fields, formatType);
  }

  return fields ? account[fields[0]] : '';
}

/**
 * Check the account to see if it has a specific set of fields.
 * If so use that, otherwise default to the backup fields if they exists.
 * Otherwise, return an empty string.
 *
 * EX 1 - Data in primary field exists:
 *
 * account = { nickname: 'Nicknamed Account', name: 'my account', number: '1234';}
 * primaryFields = ['nickname'],
 * backupFields = ['name', 'number'],
 * formatType = accountFormatType.DASH

 * Return: "Nicknamed Account"

 * EX 2 - Data in primary field doesn't exist, therefore fall back to backup field data:

 * account = { nickname: '', name: 'my account', number: '1234';}
 * primaryFields = ['nickname'],
 * backupFields = ['name', 'number'],
 * formatType = accountFormatType.DASH

 * Return: "my account - 1234"
 * */

export function getAccountData(
  account,
  primaryFields,
  backupFields,
  formatType,
  isNickname
) {
  if (!account.name || !primaryFields) return '';
  return checkAccountForValue(account, primaryFields, isNickname)
    ? formatAccountData(account, primaryFields, formatType)
    : formatAccountData(account, backupFields, formatType);
}

export default getAccountData;
