import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { classes as classesPropType } from 'utils/propTypes';
import { List, ListItem, withStyles } from '@material-ui/core';

import styles from './styles';
import NoElementsMessage from './NoElementsMessage';

export const PermitDetailsList = ({
  header,
  infoText,
  elements,
  classes,
  elementName,
}) => (
  <>
    <p className={classes.infoText}>{infoText}</p>
    <h5 className={classes.header}>{header}</h5>
    <List>
      {
        // despite having a default value, if the permit's chemicals or crops were null, it was overriding the empty array value,
        // and an error was thrown. It made more sense to centralize the logic here than have each invoker do || [] or similar.
        !!elements &&
          !!elements.length &&
          elements.sort().map((element) => (
            <ListItem key={element} className={classes.listItem} divider>
              {element}
            </ListItem>
          ))
      }
      {(!elements || !elements.length) && (
        <NoElementsMessage elementName={elementName} />
      )}
    </List>
  </>
);

PermitDetailsList.propTypes = {
  header: PropTypes.string,
  infoText: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.string),
  elementName: PropTypes.string,
  classes: classesPropType,
};

PermitDetailsList.defaultProps = {
  header: '',
  infoText: '',
  elements: [],
  elementName: '',
  classes: {},
};

export default withStyles(styles)(PermitDetailsList);
