const styles = {
  container: { margin: 0, padding: 0, overflow: 'auto' },
  alignRight: {
    textAlign: 'right',
  },
  redText: {
    color: '#f13b39',
  },
  greenText: {
    color: '#4c9e00',
  },
  table: {
    margin: '0 0 20px',
    padding: 0,
    width: '100%',
    '& th': {
      padding: '10px 20px',
      fontWeight: 600,
      color: '#464646',
    },
    '& tbody': {
      '& tr': {
        borderTop: '1px solid #ccc',
        '& td': {
          padding: '12px 20px',
          fontWeight: 300,
        },
        '&:last-child': {
          borderBottom: '1px solid #ccc',
        },
      },
    },
  },
  titleContainer: {
    margin: '40px 20px 20px',
    '& h2': {
      fontWeight: 300,
    },
  },
};

export default styles;
