import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import getNativeContainer from 'shared/utils/getNativeContainer';
import {
  gfaEvent,
  gfaScreenView,
  gfaShare,
} from 'store/middleware/TagManager/gtmActions';
import { USER_FETCHED } from 'shared/config/userConstants';
import useUser from 'hooks/useUser';

const container = getNativeContainer();

const Auth0Sso = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, userStatus } = useUser();

  const handleMessage = useCallback(
    (e) => {
      const { data: rawMessage } = e;
      if (typeof rawMessage === 'string') {
        const message = JSON.parse(rawMessage);
        const { name, payload } = message;

        switch (name) {
          case 'track_event':
            dispatch(gfaEvent(payload));
            break;
          case 'track_screen_view':
            dispatch(gfaScreenView(payload.screen));
            break;
          case 'track_share':
            dispatch(gfaShare(payload));
            break;
          default:
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    container.addEventListener('message', handleMessage);
    return () => container.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  useEffect(() => {
    if (window.ReactNativeWebView && userStatus === USER_FETCHED) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          name: 'user_fetched',
          payload: {
            ...user,
            status: userStatus,
          },
        })
      );
      history.push('/');
    }
  }, [history, user, userStatus]);

  return null;
};

export default Auth0Sso;
