import { SET_INVOICES } from '../const/invoices';
import { SET_ALERT } from '../const/alert';
import {
  SET_ASSOCIATED_ORGS,
  SET_ACCOUNTS,
  SET_ACCOUNT_ACTIVITY,
  SET_STATEMENT_SUMMARIES,
  SET_ACCOUNT_TAB,
  SET_OPEN_ITEMS,
} from '../const/account';

import {
  SET_PREPAY_DETAILS,
  SET_PREPAY_HISTORY,
  SET_PREPAY_TRANSACTION,
  CLEAR_PREPAY,
  CLEAR_PREPAY_TRANSACTION,
} from '../const/prepay';

export function setAssociatedOrgs(orgs) {
  return { type: SET_ASSOCIATED_ORGS, payload: orgs };
}

export function setAccounts(accounts) {
  const list = accounts.list.map((account) => {
    const usaSapAccount =
      account.id.length === 8 && account.locationCode === 'USA';
    const invalidCanadianAccount =
      (account.id.length !== 8 && account.locationCode) === 'CAN';
    const invalidInternationalAccount =
      account.locationCode !== 'USA' && account.locationCode !== 'CAN';
    return {
      ...account,
      usaSapAccount,
      invalidCanadianAccount,
      invalidInternationalAccount,
    };
  });
  const updatedAccounts = { ...accounts };
  updatedAccounts.list = list;
  return { type: SET_ACCOUNTS, payload: updatedAccounts };
}

export function setAccountTab(accountTab) {
  return { type: SET_ACCOUNT_TAB, payload: accountTab };
}

export function setAccountActivity(accountActivity) {
  return { type: SET_ACCOUNT_ACTIVITY, payload: accountActivity };
}

export function setInvoices(invoices) {
  return { type: SET_INVOICES, payload: invoices };
}

export function setStatements(statements) {
  return { type: SET_STATEMENT_SUMMARIES, payload: statements };
}

export function setOpenItems(openItems) {
  return { type: SET_OPEN_ITEMS, payload: openItems };
}

export function setAlert(alert) {
  return { type: SET_ALERT, payload: alert };
}

export function setPrepayDetails(prepayInfo) {
  return { type: SET_PREPAY_DETAILS, payload: prepayInfo };
}

export function setPrepayHistory(prepayHistory) {
  return { type: SET_PREPAY_HISTORY, payload: prepayHistory };
}

export function setPrepayTransaction(transaction) {
  return { type: SET_PREPAY_TRANSACTION, payload: transaction };
}

export function clearPrepayTransaction() {
  return { type: CLEAR_PREPAY_TRANSACTION };
}

export function clearPrepay() {
  return { type: CLEAR_PREPAY };
}
