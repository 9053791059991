import React, { useState } from 'react';
import propTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import {
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Table,
} from 'nutrien-common-components-react/dist/mdc/Table';
import customFormat from 'shared/utils/DateHelper/customFormat';
import moment from 'moment';
import RecurringPaymentsRow from './RecurringPaymentsRow';
import { RecurringPaymentPropTypes } from '../PaymentsTab';
import ScheduledPaymentDeleteModal from '../ScheduledPaymentDeleteModal/ScheduledPaymentDeleteModal';

const RecurringPaymentsTable = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [amount, setAmount] = useState();
  const today = new Date();
  const yesterday = today.setDate(today.getDate() - 1);
  const yesterdayText = customFormat(yesterday, 'MM/DD/YYYY');

  const handlePaymentDelete = (paymentDetails) => {
    props.handleSameDayError(true, false);
    // if nextPayment is on today's date
    if (
      paymentDetails.nextPayment &&
      moment(paymentDetails.nextPayment).isSame(moment(), 'date')
    ) {
      props.handleSameDayError(true, true);
      return;
    }
    setDialogOpen(true);
    setPaymentId(paymentDetails.id);
    setAmount(paymentDetails.amount);
  };

  return (
    <div className="payments-tab__table-container">
      <ScheduledPaymentDeleteModal
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        deleteScheduledPayment={props.deleteScheduledPayment}
        paymentId={paymentId}
        amount={amount}
        isRecurring
      />
      {!props.recurringPayments || props.recurringPayments.length === 0 ? (
        <div className="payments-tab__no-payments">
          You have no Recurring Payments scheduled as of {yesterdayText}
        </div>
      ) : (
        <Table className="payments-table">
          <TableHead>
            <TableRow className="payments-table__row">
              <TableCell className="payments-table__header nutrien-table__cell__fixed">
                Next Payment
              </TableCell>
              <TableCell className="payments-table__header nutrien-table__cell__fixed">
                Account ID
              </TableCell>
              <TableCell className="payments-table__header nutrien-table__cell__fixed ">
                Payment Method
              </TableCell>
              <TableCell className="payments-table__header">
                Frequency
              </TableCell>
              <TableCell className="payments-table__header payments-table__right-align">
                Amount
              </TableCell>
              <TableCell className="payments-table__header payments-table__final-col" />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(
              props.recurringPayments,
              (element) => element.nextPayment
            ).map((paymentDetails) => (
              <RecurringPaymentsRow
                key={paymentDetails.invoiceId}
                details={paymentDetails}
                deleteRecurringPayment={props.deleteScheduledPayment}
                handlePaymentDelete={handlePaymentDelete}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

RecurringPaymentsTable.propTypes = {
  recurringPayments: propTypes.arrayOf(
    propTypes.shape(RecurringPaymentPropTypes)
  ).isRequired,
  deleteScheduledPayment: propTypes.func.isRequired,
  handleSameDayError: propTypes.func.isRequired,
};

export default RecurringPaymentsTable;
