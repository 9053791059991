import PropTypes from 'prop-types';

export const account = PropTypes.shape({
  number: PropTypes.string,
  debtStatus: PropTypes.string,
});

export const organization = PropTypes.shape({
  name: PropTypes.string,
  eid: PropTypes.string,
  accounts: PropTypes.arrayOf(account),
});

export const customer = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  associatedOrgs: PropTypes.arrayOf(organization),
});

export const customers = PropTypes.arrayOf(customer);
