import * as notificationsService from '../../../notificationsService';
import getPreferencesFailure from './getPreferencesFailure';
import getPreferencesSuccess from './getPreferencesSuccess';
import loadingPreferences from './loadingPreferences';

export default () => (dispatch) => {
  dispatch(loadingPreferences());
  return notificationsService
    .getPreferences()
    .then((response) => dispatch(getPreferencesSuccess(response)))
    .catch((error) => dispatch(getPreferencesFailure(error)));
};
