import moment from 'moment';

/**
 * Calculates and returns an array of date ranges until today's date.
 * @param startDate - string date representing the initial date to start the ranges from
 * @param increment - the length of each increment in terms of months
 */
const getDateRangesUntilTodayInMonths = (startDate, increment) => {
  const maxDate = moment().utc().add(1, 'days');
  const dateRanges = [];

  let fromDate = moment(startDate).utc();
  while (fromDate.isBefore(maxDate)) {
    const dateInThreeMonths = fromDate.clone().add(increment, 'months');
    dateRanges.push({
      fromDate: fromDate.format(),
      toDate: dateInThreeMonths.format(),
    });

    fromDate = dateInThreeMonths.clone().add(1, 'days');
  }

  return dateRanges;
};

export default getDateRangesUntilTodayInMonths;
