import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import UserPropType from 'shared/config/userPropType';
import { withStyles } from '@material-ui/core';
import { useAuth } from '@nutrien/fe-domain-utils';
import { formatPhoneNumber } from 'utils/phoneUtils';
import PasswordChangeForm from './UpdatePassword/PasswordChangeForm';
import UpdateEmailPhoneButton from './UpdateEmailPhone/UpdateEmailPhoneButton';

import styles from '../../styles';

const ProfileTab = (props) => {
  const { isAuth0Active } = useAuth();
  const { changePasswordForm } = useFlags();
  const { classes } = props;
  const formattedPhone = formatPhoneNumber(props.user.phoneNumber);
  return (
    <div>
      <div className={classes.sectionContainer}>
        {props.user.userDetails && (
          <div className={classes.section}>
            <div className={`${classes.sectionTitle} ptOrganization`}>
              Organization
            </div>
            {props.user.userDetails.associatedOrgs[0] ? (
              <div className="sel-OrgName" data-hj-suppress="">
                {props.user.userDetails.associatedOrgs[0].name}
              </div>
            ) : (
              <div className={`sel-Unknown ${classes.emptyField}`}>Unknown</div>
            )}
          </div>
        )}
        <div className={classes.section}>
          <div className={`${classes.sectionTitle} ptFullName`}>Full Name</div>
          <span data-hj-suppress="">
            {props.user.firstName} {props.user.lastName}
          </span>
        </div>
        <div className={classes.section}>
          <div className={`${classes.sectionTitle} ptMobilePhone`}>
            Mobile Phone
          </div>
          <span data-hj-suppress="">
            {props.user.phoneNumber ? (
              <a
                className="sel-PhoneNumber"
                href={`tel:${props.user.phoneNumber}`}
              >
                {formattedPhone}
              </a>
            ) : (
              <div className={`sel-Unknown ${classes.emptyField}`}>Unknown</div>
            )}
          </span>
        </div>
        <div className={classes.section}>
          <div className={`${classes.sectionTitle} ptEmail`}>Email</div>
          <a
            className="sel-Email"
            href={`mailto:${props.user.email}`}
            data-hj-suppress=""
          >
            {props.user.email}
          </a>
        </div>
        {changePasswordForm && !isAuth0Active && (
          <div className={classes.section}>
            <hr />
            <div className={`${classes.sectionTitle} ptPassword`}>
              Change Password
            </div>
            <span>Update your unique password to protect your account</span>
            <PasswordChangeForm />
          </div>
        )}
      </div>
      <hr />
      <div>
        <UpdateEmailPhoneButton />
      </div>
    </div>
  );
};

ProfileTab.propTypes = {
  user: UserPropType.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ProfileTab);
