import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import numeral from 'numeral';

import ShoppingCartTable from '../ShoppingCartTable';

import { NO_PRICE_CART_MESSAGE } from '../../../../../shared/constants/ecommMessageText';

import './ShoppingCartItems.scss';

const calcSubTotal = (cartItems) =>
  cartItems.reduce((subTotal, cartItem) => {
    const price = get(cartItem, 'variant.net_price', 0);
    const { total_volume: totalVolume } = cartItem;

    if (price) {
      return subTotal + price * totalVolume;
    }
    return subTotal;
  }, 0);

const ShoppingCartItems = (props) => {
  const { cart } = props;
  const subTotal = calcSubTotal(props.cart.submittedCartData);
  const nonPriced = [];
  const priced = [];

  return (
    <div>
      {cart.submittedCartData.forEach((cartItem) => {
        if (cartItem.variant.price && cartItem.variant.price !== '0') {
          priced.push(cartItem);
        } else {
          nonPriced.push(cartItem);
        }
      })}
      <div className="shopping-cart-table-outer-wrapper priced">
        <h2>Priced Items</h2>
        <ShoppingCartTable cartItems={priced} />
        <div className="shopping-cart-item__total-wrapper">
          <span className="shopping-cart-item__total" data-test="total-volume">
            {numeral(subTotal).format('$0,0.00')}
          </span>
        </div>
      </div>
      <div className="shopping-cart-table-outer-wrapper">
        <h2>Unpriced Items</h2>
        <ShoppingCartTable cartItems={nonPriced} />
        <div className="shopping-cart-item__total-wrapper">
          <span className="shopping-cart-item__total">
            {NO_PRICE_CART_MESSAGE}
          </span>
        </div>
      </div>
    </div>
  );
};

ShoppingCartItems.propTypes = {
  cart: PropTypes.shape({
    submittedCartData: PropTypes.array.isRequired,
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        error: PropTypes.string,
      }),
    }).isRequired,
    branchAddress: PropTypes.shape({
      address: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ShoppingCartItems;
