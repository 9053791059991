const unauthenticatedRoutes = [
  '/accept-terms',
  '/privacy',
  '/contact',
  '/terms-of-use',
  '/login',
  '/getting-started',
  '/forgot-password',
  '/password-reset',
  '/sso-logout',
  '/logged-out',
];

export default unauthenticatedRoutes;
