import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { Checkbox } from 'nutrien-common-components-react/dist/mdc';
import { PageNotification } from 'shared/ui';
import PaymentDatepicker from './PaymentDatepicker';
import { PAYMENT_FLOW_TYPES } from '../../PaymentConstants';
import RecurringPaymentSection from './RecurringPaymentSection';
import * as PaymentPropTypes from '../../PaymentPropTypes';
import { PAYMENT_AMOUNT_TYPES } from '../../PageComponents/MakeAPayment/MakeAPaymentConstants';

import './schedulePayment.scss';

const SchedulePayment = (props) => {
  const {
    scheduledPayment,
    isRecurring,
    onChange,
    validate,
    errors,
    recurringEndDate,
    recurringPaymentFrequency,
    flowType,
    amountTypeSelected,
  } = props;
  const { usaSapBlackoutDatePicker, scheduledPayments } = useFlags();
  const inputName = 'schedulePaymentInput';
  const today = new Date();
  const tomorrow = new Date().setDate(today.getDate() + 1);
  return (
    <>
      {scheduledPayments && (
        <div className="schedule-payment-container">
          <div className="schedule-payment-section">
            <h2>Schedule Payment</h2>
            {usaSapBlackoutDatePicker && (
              <PageNotification
                link="/"
                data-test="schedule-payment-blackout-warning"
                linkDescription="Return Home"
                className="schedule-payment-blackout-notification"
                message="Online payments will be disabled from October 31st to November
3rd due to the move to SAP. Any payments scheduled during this
time will be moved to November 4th. Please contact your crop
consultant if you need to make a payment during this period."
                type="WARNING"
              />
            )}
            <div className="schedule-payment-content">
              <div className="schedule-input-container">
                <label
                  className="schedule-payment-input-label"
                  htmlFor={inputName}
                >
                  Scheduled Date
                </label>
                <PaymentDatepicker
                  onChange={onChange}
                  inputName={inputName}
                  data-test="payment-datepicker"
                  formPropertyName="scheduledPayment"
                  selectedDate={scheduledPayment}
                  errors={errors}
                  validate={validate}
                />
              </div>
              {flowType !== PAYMENT_FLOW_TYPES.INVOICE_PAYMENT &&
                amountTypeSelected === PAYMENT_AMOUNT_TYPES.Other && (
                  <label
                    className="recurring-payment-checkbox-label"
                    htmlFor="recurringPaymentCheckbox"
                  >
                    <Checkbox
                      data-test="recurringPaymentCheckbox"
                      name="recurringPaymentCheckbox"
                      checked={isRecurring}
                      onChange={(event) => {
                        onChange({
                          target: {
                            name: 'scheduledPayment',
                            value: event.target.checked
                              ? new Date(tomorrow)
                              : new Date(),
                          },
                        });
                        onChange({
                          target: {
                            name: 'isRecurring',
                            value: event.target.checked,
                          },
                        });
                      }}
                      label="Make this payment recurring"
                      description="Make this payment recurring"
                    />
                    Make this payment recurring
                  </label>
                )}
            </div>

            {isRecurring &&
              flowType !== PAYMENT_FLOW_TYPES.INVOICE_PAYMENT &&
              amountTypeSelected === PAYMENT_AMOUNT_TYPES.Other && (
                <RecurringPaymentSection
                  errors={errors}
                  onChange={onChange}
                  validate={validate}
                  recurringEndDate={recurringEndDate}
                  recurringPaymentFrequency={recurringPaymentFrequency}
                />
              )}
          </div>
        </div>
      )}
    </>
  );
};

SchedulePayment.propTypes = {
  isRecurring: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  scheduledPayment: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  recurringEndDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  recurringPaymentFrequency: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  errors: PaymentPropTypes.paymentFormErrors.isRequired,
  flowType: PropTypes.string,
  amountTypeSelected: PropTypes.string,
};

SchedulePayment.defaultProps = {
  scheduledPayment: null,
  recurringEndDate: null,
  flowType: null,
  amountTypeSelected: 'AmountDue',
};

export default SchedulePayment;
