import storage from 'shared/lib/storage';

const getToken = (type) => {
  const token = storage.getItem(type);
  try {
    return JSON.parse(token);
  } catch (e) {
    return undefined;
  }
};
export default getToken;
