import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Pagination from 'shared/ui/Pagination';
import { getPage } from 'shared/utils/pagination';
import Button from '@nutrien/uet-react/Button';
import Checkbox from '@nutrien/uet-react/Checkbox';
import DisabledForEmployees from 'shared/utils/DisabledForEmployees';

import NotificationsList from '../NotificationsList';
import * as NotificationsPropTypes from '../notificationsPropTypes';
import PageHeader from '../../../shared/ui/PageHeader/PageHeader';

import './NotificationsPage.scss';

const NotificationsPage = ({
  notifications,
  notificationsError,
  onDelete,
  onMarkAsRead,
  onPaginationClick,
  currentPage,
  pageSize,
  fetchingNotifications,
  selectedNotifications,
  selectAllNotifications,
  deselectAllNotifications,
  toggleSelect,
}) => {
  const {
    notificationsMultiSelect,
    notificationsBatchDelete,
    notificationsMultiSelectStatusBar,
    profilePageNotificationsTab,
  } = useFlags();
  const paginatedNotifications = getPage(notifications, currentPage, pageSize);
  const paginatedNotificationIds = paginatedNotifications.map(
    (notification) => notification.id
  );
  const selectAllOnPageChecked = isEmpty(
    difference(paginatedNotificationIds, selectedNotifications)
  );
  const indeterminate = !isEmpty(
    intersection(paginatedNotificationIds, selectedNotifications)
  );
  const allNotificationsSelected =
    selectedNotifications.length === notifications.length;

  return (
    <div className="notifications-page">
      <PageHeader
        title="Notifications"
        breadcrumbs={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'Notifications',
          },
        ]}
      />
      {notificationsMultiSelectStatusBar && !!selectedNotifications.length && (
        <div className="notifications-page__selection-status-bar">
          <span className="notifications-page__selection-status-bar__text">
            You have selected <span>{selectedNotifications.length}</span>
            {selectedNotifications.length === 1
              ? ' notification '
              : ' notifications '}
            out of {notifications.length}.
          </span>
          <Button
            data-test="notifications-page-select-all-button"
            className="notifications-page__selection-status-bar__select-all"
            onClick={() => {
              const notificationIds = notifications.map(
                (notification) => notification.id
              );
              if (allNotificationsSelected) {
                deselectAllNotifications(notificationIds);
              } else {
                selectAllNotifications(notificationIds);
              }
            }}
          >
            {allNotificationsSelected ? 'Deselect' : 'Select'} all{' '}
            {notifications.length}
            {notifications.length === 1 ? ' notification' : ' notifications'}.
          </Button>
        </div>
      )}
      <div className="notifications-page__title-container">
        {!!notifications.length && notificationsMultiSelect && (
          <div className="notifications-page__preferences-selection-container">
            <div className="notifications-page__preferences-checkbox">
              <Checkbox
                data-test="notifications-page-select-all-checkbox"
                checked={selectAllOnPageChecked}
                indeterminate={indeterminate}
                onChange={(event) => {
                  if (!indeterminate && event.target.checked) {
                    selectAllNotifications(paginatedNotificationIds);
                  } else {
                    deselectAllNotifications(paginatedNotificationIds);
                  }
                }}
              />
            </div>
            {!!selectedNotifications.length && (
              <div className="notifications-page__preferences-selection-options">
                <DisabledForEmployees>
                  <Button
                    data-test="notifications-page-mark-as-read-button"
                    className="notifications-page__preferences-mark-as-read"
                    onClick={() =>
                      onMarkAsRead(
                        selectedNotifications,
                        true,
                        allNotificationsSelected
                      )
                    }
                  >
                    Mark as Read
                  </Button>
                  {notificationsBatchDelete && (
                    <Button
                      data-test="notifications-page-delete-button"
                      className="notifications-page__preferences-delete"
                      onClick={() =>
                        onDelete(
                          selectedNotifications,
                          allNotificationsSelected
                        )
                      }
                    >
                      Delete
                    </Button>
                  )}
                </DisabledForEmployees>
              </div>
            )}
          </div>
        )}
        {profilePageNotificationsTab && (
          <div className="notifications-page__preferences-link-container">
            <Link
              to="/profile/notifications"
              className="notifications-page__preferences-link"
            >
              <span className="preferences-link-text">
                Notification Preferences&nbsp;
              </span>
              <ChevronRight
                style={{ width: '19px' }}
                className="chevronRight-icon"
              />
            </Link>
          </div>
        )}
      </div>
      <NotificationsList
        notifications={paginatedNotifications}
        selectedNotifications={selectedNotifications}
        notificationsError={notificationsError}
        fetchingNotifications={fetchingNotifications}
        selectAllChecked={selectAllOnPageChecked}
        toggleSelect={toggleSelect}
      />
      <Pagination
        className="notifications-page__pagination"
        current={currentPage}
        total={notifications.length}
        onChange={onPaginationClick}
        pageSize={pageSize}
      />
    </div>
  );
};

NotificationsPage.defaultProps = {
  notifications: [],
  notificationsError: undefined,
  onPaginationClick: () => {},
  currentPage: 1,
  pageSize: 24,
};

NotificationsPage.propTypes = {
  notifications: NotificationsPropTypes.notifications, // eslint-disable-line react/no-typos
  notificationsError: PropTypes.instanceOf(Error),
  onDelete: PropTypes.func.isRequired,
  onMarkAsRead: PropTypes.func.isRequired,
  onPaginationClick: PropTypes.func,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  fetchingNotifications: PropTypes.bool.isRequired,
  selectedNotifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectAllNotifications: PropTypes.func.isRequired,
  deselectAllNotifications: PropTypes.func.isRequired,
  toggleSelect: PropTypes.func.isRequired,
};

export default NotificationsPage;
