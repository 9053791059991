import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    margin: 0,
    width: '100%',
  },
  tableLink: {
    fontWeight: 600,
  },
  row: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.25)',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '12px',
    width: '100%',

    '@media (min-width: 1000px)': {
      borderBottom: '1px solid #ccc',
      borderRadius: 0,
      boxShadow: 'none',
      display: 'table-row',
      marginTop: 0,
    },
  },
  cell: {
    borderBottom: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    padding: '12px',
    width: '50%',

    '@media (min-width: 1000px)': {
      display: 'table-cell',
      width: '25%',
    },
  },
  cellEnd: {
    borderBottom: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    padding: '12px',
    textAlign: 'left',
    width: '50%',

    '@media (min-width: 1000px)': {
      display: 'table-cell',
      textAlign: 'right',
      width: '25%',
    },
  },
  cellHeaderDesktop: {
    borderBottom: '1px solid #ccc',
    color: theme.palette.text.primary,
    display: 'none',
    fontSize: '.875rem',
    fontWeight: 600,
    padding: '12px 16px 12px 12px',

    '@media (min-width: 1000px)': {
      display: 'table-cell',
    },
  },
  cellHeaderMobile: {
    color: theme.palette.text.primary,
    display: 'block',
    fontSize: '.8rem',
    fontWeight: 600,
    paddingBottom: '12px',

    '@media (min-width: 1000px)': {
      display: 'none',
    },
  },
  orderDate: {
    fontWeight: 800,
  },
}));

export default useStyles;
