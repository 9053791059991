import PAGINATION from 'shared/ui/Pagination/paginationConstants';

export const trackPagination = (action) => ({
  event: 'COMMON_Paginate',
  common: {
    paginationNum: action.pageNumber,
  },
});

export const paginationEventsMap = {
  [PAGINATION]: trackPagination,
};
