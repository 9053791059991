import { getAccessToken, currentAuthContext } from '@nutrien/fe-domain-utils';

import resolveAudience from 'utils/resolveAuth0Audience';
import { getToken } from 'shared/utils/tokenUtils';

// Paths that we don't want to check the token on
const unauthenticatedPaths = [
  'v1/user/login',
  'v1/user/register',
  'v1/user/passwordreset',
  'v1/user/forgotpassword',
  'v1/user/validate',
  'v1/terms/content',
  'v1/user/preregister',
  'v1/user/register',
  'v1/user/registerExisting',
  'v1/user/registerRoleDelegation',
  'v1/employee/login',
];

const validTokenInterceptor = async (config) => {
  if (config.url.indexOf('v1/user/old?username') !== -1) return config;
  if (config.url.indexOf('v1/role/confirmation') !== -1) return config;
  if (config.url.indexOf('v1/email') !== -1) return config;
  if (config.url.indexOf('v1/recaptcha') !== -1) return config;
  if (unauthenticatedPaths.includes(config.url)) return config;

  const audience = resolveAudience();
  const accessToken = await getAccessToken({ audience });

  if (currentAuthContext.isAuth0Active) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `AccessToken ${accessToken}`,
      },
    };
  }

  const refreshToken = getToken('refresh-token');
  const ret = {
    ...config,
    headers: {
      ...config.headers,
      'auth-token': accessToken,
      'refresh-token': refreshToken,
    },
  };

  return ret;
};

export default validTokenInterceptor;
