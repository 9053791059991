import get from 'lodash/get';
import { POST_ORDER_FAILURE } from '../actionTypes';

export default (error) => {
  const err = get(
    error,
    'message',
    'There was an error while posting the order'
  );
  return {
    type: POST_ORDER_FAILURE,
    loading: false,
    error: err,
  };
};
