import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import ErrorBanner from 'shared/ui/ErrorBanner';

import { defaultAccountPropTypes } from '../../shared/propTypes';
import DeliveryDate from '../../containers/DeliveryDateContainer/DeliveryDateContainer';
import DeliveryAddress from '../../containers/DeliveryAddressContainer/DeliveryAddressContainer';
import OrderMemo from '../../containers/OrderMemoContainer/OrderMemoContainer';
import MobileCartStepper from '../MobileCartStepper';
import CartStepper from '../CartStepper';
import CartButtons from '../CartButtons/CartButtons';
import { addressError } from '../../constants/deliveryAddress';

import './LogisticsPage.scss';

export class LogisticsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeoutSet: false,
      errorLocation: addressError.NONE,
    };
  }

  componentDidMount = () => {
    const { history, location } = this.props;
    if (!location.originatedFromCart) {
      history.push('/cart');
    }
  };

  componentWillUnmount = () => {
    const { viewLogistics } = this.props;
    viewLogistics();
  };

  determineError = () => {
    const {
      cart: { addresses, branchAddress },
    } = this.props;
    const error = get(addresses, 'all.error');
    const defaultAddress = get(addresses, 'all.defaultAddress');
    const updatingAddress = get(addresses, 'all.updatingAddress', false);
    const requireCustomerPickup = get(
      branchAddress,
      'address.data.requireCustomerPickup',
      false
    );
    const updatingBranchAddress = get(
      branchAddress,
      'updatingBranchAddress',
      false
    );

    const branchAddressProblem =
      !updatingBranchAddress && !branchAddress.address;

    const deliveryAddressProblem =
      !requireCustomerPickup && !updatingAddress && (error || !defaultAddress);

    let errorLocation = addressError.NONE;

    // all addresses have issues
    if (branchAddressProblem && deliveryAddressProblem) {
      errorLocation = addressError.BOTH;
    }
    // delivery address has issues
    else if (deliveryAddressProblem) {
      errorLocation = addressError.DELIVERY;
    }
    // branch address has issues
    else if (branchAddressProblem) {
      errorLocation = addressError.PICKUP;
    }

    if (this.state.errorLocation !== errorLocation) {
      this.setState({ errorLocation });
    }
    return errorLocation;
  };

  determineNavigationBlocked = (errorLocation) => {
    let blocked = false;

    switch (errorLocation) {
      case addressError.BOTH:
        blocked = true;
        break;
      default:
        break;
    }

    return blocked;
  };

  determineErrorBanner = (errorLocation) => {
    const {
      cart: { addresses },
      getAddress,
      getBranchAddress,
    } = this.props;

    const selectedAccount = get(this.props, 'selectedAccount', {});

    let errorBanner = null;

    switch (errorLocation) {
      case addressError.BOTH:
        errorBanner = {
          preActionText:
            'We are having trouble retrieving your addresses. Please contact your Nutrien Ag Solutions Representative to request an order, or ',
          actionText: 'try again',
          postActionText: '',
          action: () => {
            if (!get(addresses, 'all.updatingAddress', false)) {
              getAddress(selectedAccount.id);
            }
            getBranchAddress(selectedAccount.id, selectedAccount.branchId);
          },
        };
        break;
      case addressError.DELIVERY:
        errorBanner = {
          preActionText:
            'We are having trouble retrieving your shipping address. Please select a branch address for pickup instead, or ',
          actionText: 'try again',
          postActionText: '',
          action: () => {
            if (!get(addresses, 'all.updatingAddress', false)) {
              getAddress(selectedAccount.id);
            }
          },
        };
        break;
      case addressError.PICKUP:
        errorBanner = {
          preActionText:
            'We are having trouble retrieving your branch address. Please select a delivery address instead, or ',
          actionText: 'try again',
          postActionText: '',
          action: () => {
            getBranchAddress(selectedAccount.id, selectedAccount.branchId);
          },
        };
        break;
      case addressError.NONE:
      default:
        break;
    }

    return errorBanner;
  };

  reRetrieveAddresses = () => {
    const {
      cart: { addresses },
      getAddress,
      getBranchAddress,
    } = this.props;

    const { errorLocation } = this.state;

    const selectedAccount = get(this.props, 'selectedAccount', {});

    switch (errorLocation) {
      case addressError.BOTH:
        if (!get(addresses, 'all.updatingAddress', false)) {
          getAddress(selectedAccount.id);
        }
        getBranchAddress(selectedAccount.id, selectedAccount.branchId);
        break;
      case addressError.DELIVERY:
        if (!get(addresses, 'all.updatingAddress', false)) {
          getAddress(selectedAccount.id);
        }
        break;
      case addressError.PICKUP:
        getBranchAddress(selectedAccount.id, selectedAccount.branchId);
        break;
      default:
        break;
    }
    this.setState({ timeoutSet: false });
  };

  triggerTimeout = () => {
    this.setState({ timeoutSet: true });
    window.setTimeout(() => {
      this.reRetrieveAddresses();
    }, 5000);
  };

  render() {
    const { setOrderMemo, history } = this.props;
    const number = get(this.props, 'selectedAccount.number', '');
    const errorLocation = this.determineError();
    const errorBanner = this.determineErrorBanner(errorLocation);
    const waitingForAddress = this.determineNavigationBlocked(errorLocation);

    if (
      this.state.errorLocation !== addressError.NONE &&
      !this.state.timeoutSet
    ) {
      this.triggerTimeout();
    }

    return (
      <div className="logistics-page__wrapper">
        {errorBanner && <ErrorBanner {...errorBanner} />}
        {number && (
          <>
            <CartStepper currentStep="logistics" accountNumber={number} />
            <div>
              <DeliveryAddress errorLocation={errorLocation} />
            </div>
            <div>
              <DeliveryDate />
              <OrderMemo setOrderMemo={setOrderMemo} />
            </div>
            <div className="logistics-page__footer">
              <MobileCartStepper
                currentStep="logistics"
                history={history}
                accountNumber={number}
              />
              <CartButtons
                disabled={waitingForAddress}
                accountNumber={number}
                currentStep="logistics"
                history={history}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

LogisticsPage.propTypes = {
  location: PropTypes.shape({
    originatedFromCart: PropTypes.bool,
  }).isRequired,
  cart: PropTypes.shape({
    preferences: PropTypes.shape({}),
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        defaultAddress: PropTypes.shape({}),
        error: PropTypes.shape({}),
      }),
    }),
    branchAddress: PropTypes.shape({
      address: PropTypes.shape({}),
    }),
    selectedAccount: defaultAccountPropTypes,
  }).isRequired,
  selectedAccount: PropTypes.shape({
    number: PropTypes.string,
  }).isRequired,
  getAddress: PropTypes.func.isRequired,
  getBranchAddress: PropTypes.func.isRequired,
  setOrderMemo: PropTypes.func.isRequired,
  viewLogistics: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default LogisticsPage;
