import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { productActions, productDetailActions } from '../../actions/actions';
import * as cartActions from '../../../Cart/actions/cartActions';
import MiniCart from '../../components/MiniCart/MiniCart';

const mapStateToProps = ({ user, selectedAccount, shop, cart }) => ({
  userId: user.userData.id,
  selectedAccount,
  shop,
  cart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...productDetailActions,
      ...productActions,
      ...cartActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MiniCart);
