import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { AuthenticatedComponent } from '@nutrien/fe-domain-utils';

import BaseHeader from 'shared/ui/Header/Headers/BaseHeader';
import isEmployeeView from 'utils/isEmployeeView';
import AuthenticatedHeader from './AuthenticatedHeader';
import { gtmClickNutrienLogo } from '../../../../../store/middleware/TagManager/gtmActions';

const mapStateToProps = (state) => ({
  browser: state.browser,
  isEmployeeView: isEmployeeView(state),
  accounts: state.accounts.list,
});

const mapDispatchToProps = {
  gtmClickNutrienLogoAction: gtmClickNutrienLogo,
};

const AuthenticatedHeaderContainer = ({ isMirroring, location, ...rest }) => (
  <AuthenticatedComponent
    unAuthenticatedComponent={
      <BaseHeader
        displayForAllScreenSizes
        isMirroring={isMirroring}
        location={location}
      />
    }
  >
    <AuthenticatedHeader
      isMirroring={isMirroring}
      location={location}
      {...rest}
    />
  </AuthenticatedComponent>
);

AuthenticatedHeaderContainer.propTypes = {
  isMirroring: T.bool,
  location: T.shape({
    pathname: T.string,
    search: T.string,
  }).isRequired,
};

AuthenticatedHeaderContainer.defaultProps = {
  isMirroring: false,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer()
)(AuthenticatedHeaderContainer);
