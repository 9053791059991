import { Divider, List, Typography, withStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { Fragment } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MaterialLoadingIcon } from 'shared/ui';
import NotificationsPreferencesItem from '../NotificationsPreferencesItem';
import styles from '../styles';
import {
  categorizedPreferenceSettings as preferenceSettingsPropType,
  preference as preferencePropType,
  preferences as preferencesPropType,
} from './preferencesPropTypes';

const buildNotificationsPreferencesItem = (
  itemProps,
  showEmailToggle,
  showPhoneToggle
) => {
  return (
    <NotificationsPreferencesItem
      {...itemProps}
      showEmailToggle={showEmailToggle}
      showPhoneToggle={showPhoneToggle}
    />
  );
};

const WeatherNotificationsPreferences = ({
  classes,
  isLoading,
  preferenceSettings,
  preferences,
  onPreferenceChange,
  preferenceChanges,
}) => {
  const {
    weatherNotificationSettingsEmail,
    weatherNotificationSettingsSms,
  } = useFlags();

  if (isLoading) {
    return <MaterialLoadingIcon />;
  }

  return (
    <Fragment key="WEATHER">
      <Typography
        id="account-preferences-heading-WEATHER"
        className={classes.sectionTitle}
        gutterBottom
      >
        Weather Notification Settings
      </Typography>
      <List disablePadding>
        {Object.keys(preferenceSettings).map((categoryKey) => {
          const category = preferenceSettings[categoryKey];
          return (
            <Fragment key={`account-preferences-weather-${categoryKey}`}>
              <Typography
                id={`account-preferences-subheading-${categoryKey}`}
                key={`account-preferences-weather-${categoryKey}-Typography`}
                className={classes.sectionSubheading}
                gutterBottom
              >
                {category.label}
              </Typography>
              <Divider />
              <List disablePadding>
                {category.preferences.map((preference) => {
                  const existingPrefs = preference.key in preferences;
                  const emailValue =
                    // eslint-disable-next-line no-nested-ternary
                    preference.key in preferenceChanges &&
                    'emailOptIn' in preferenceChanges[preference.key]
                      ? preferenceChanges[preference.key].emailOptIn
                      : existingPrefs
                      ? preferences[preference.key].emailOptIn
                      : false;
                  const phoneValue =
                    // eslint-disable-next-line no-nested-ternary
                    preference.key in preferenceChanges &&
                    'smsOptIn' in preferenceChanges[preference.key]
                      ? preferenceChanges[preference.key].smsOptIn
                      : existingPrefs
                      ? preferences[preference.key].smsOptIn
                      : false;
                  const inboxValue =
                    // eslint-disable-next-line no-nested-ternary
                    preference.key in preferenceChanges &&
                    'inboxOptOut' in preferenceChanges[preference.key]
                      ? !preferenceChanges[preference.key].inboxOptOut
                      : existingPrefs
                      ? !preferences[preference.key].inboxOptOut
                      : true;

                  const itemProps = {
                    key: preference.key,
                    formKey: preference.key,
                    showInboxToggle: true,
                    inboxValue,
                    emailValue,
                    phoneValue,
                    preferenceKey: preference.label,
                    onPreferenceChange,
                  };

                  /* Feature flags to hide Email and SMS toggles while they're not implemented */
                  const preferenceItemComponent = (
                    <div key={preference.key}>
                      {weatherNotificationSettingsEmail ? (
                        <div key={preference.key}>
                          {weatherNotificationSettingsSms
                            ? buildNotificationsPreferencesItem(
                                itemProps,
                                true,
                                true
                              )
                            : buildNotificationsPreferencesItem(
                                itemProps,
                                true,
                                false
                              )}
                        </div>
                      ) : (
                        <div key={preference.key}>
                          {weatherNotificationSettingsSms
                            ? buildNotificationsPreferencesItem(
                                itemProps,
                                false,
                                false
                              )
                            : buildNotificationsPreferencesItem(
                                itemProps,
                                false,
                                true
                              )}
                        </div>
                      )}
                    </div>
                  );

                  return preferenceItemComponent;
                })}
              </List>
            </Fragment>
          );
        })}
      </List>
    </Fragment>
  );
};

WeatherNotificationsPreferences.defaultProps = {
  classes: {},
};

WeatherNotificationsPreferences.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  isLoading: PropTypes.bool.isRequired,
  preferenceSettings: preferenceSettingsPropType.isRequired,
  preferences: preferencesPropType.isRequired,
  preferenceChanges: PropTypes.objectOf(preferencePropType).isRequired,
  onPreferenceChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(WeatherNotificationsPreferences);
