import { combineReducers } from 'redux';

import {
  GET_CREDIT_APPLICATION,
  GET_CREDIT_APPLICATION_FAILURE,
  GET_CREDIT_APPLICATION_SUCCESS,
} from '../const/credit';

export const status = (state = 'EMPTY', action) => {
  switch (action.type) {
    case GET_CREDIT_APPLICATION:
      return 'LOADING';
    case GET_CREDIT_APPLICATION_SUCCESS:
      return 'SUCCESS';
    case GET_CREDIT_APPLICATION_FAILURE:
      return 'ERROR';
    default:
      return state;
  }
};

export const creditApplications = (state = [], action) => {
  switch (action.type) {
    case GET_CREDIT_APPLICATION_SUCCESS:
      return action.data;
    case GET_CREDIT_APPLICATION_FAILURE:
      return {};
    default:
      return state;
  }
};

export const error = (state = null, action) => {
  switch (action.type) {
    case GET_CREDIT_APPLICATION_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const credit = combineReducers({
  status,
  error,
  creditApplications,
});

export default credit;
