import React from 'react';
import ProductImage from './ProductImage';
import noImageAvailable from '../../../../assets/image-not-available.svg';

export const onError = (element) => {
  element.target.src = noImageAvailable; // eslint-disable-line no-param-reassign
};

const ProductImageContainer = (props) => (
  <ProductImage {...props} onError={onError} />
);

export default ProductImageContainer;
