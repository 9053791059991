import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '17px',
    width: '100%',

    '@media (min-width: 1000px)': {
      padding: '10px 17px 17px',
    },
  },
  tabLabel: {
    color: theme.palette.text.primary,
    fontSize: '22px',
    lineHeight: '1.14',

    '@media (min-width: 1000px)': {
      color: theme.palette.common.black,
      fontSize: '24px',
      lineHeight: 'normal',
    },
  },
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
