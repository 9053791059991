import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import { findUser } from 'actions/userService';
// eslint-disable-next-line import/no-cycle
import {
  handleDebtStatus,
  userFetchSuccess,
  userFetchFailure,
} from 'actions/userActions';
import { getAccountById } from 'services/httpServices/accountService';
import debtStatus from 'shared/utils/debtStatus';
import accountLinkService from './accountLinkService';

import {
  LINKING_ACCOUNT,
  ACCOUNT_LINK_SUCCESS,
  ACCOUNT_LINK_FAILURE,
  ACCOUNT_FETCH_FAILURE,
  RESET_ACCOUNT_LINK,
  DUPLICATE_ACCOUNT,
  VIEW_ACCOUNT,
} from './accountLinkConstants';

export const linkingAccount = () => ({
  type: LINKING_ACCOUNT,
});

export const accountLinkSuccess = (account) => ({
  type: ACCOUNT_LINK_SUCCESS,
  payload: account,
});

export const accountLinkFailure = (error) => ({
  type: ACCOUNT_LINK_FAILURE,
  error,
});

export const setDuplicateAccountStatus = (account) => ({
  type: DUPLICATE_ACCOUNT,
  payload: account,
});

export const accountFetchFailure = (error) => ({
  type: ACCOUNT_FETCH_FAILURE,
  error,
});

export const resetAccountLink = () => ({
  type: RESET_ACCOUNT_LINK,
});

export const duplicateAccount = (number) => async (dispatch) => {
  dispatch(linkingAccount());
  const res = await getAccountById(number);
  dispatch(setDuplicateAccountStatus(res));
};

export const refreshAccountList = () => async (dispatch) => {
  try {
    const response = await findUser();
    if (debtStatus(response.data.userDetails.associatedOrgs)) {
      return dispatch(await handleDebtStatus());
    }
    dispatch(
      fetchAssociatedOrgsAction(response.data.userDetails.associatedOrgs)
    );
    return dispatch(userFetchSuccess(response));
  } catch (error) {
    return dispatch(userFetchFailure(error));
  }
};

export const linkAccount = (accountInfo, finished) => async (dispatch) => {
  dispatch(linkingAccount());
  try {
    await accountLinkService(accountInfo);
  } catch (error) {
    dispatch(accountLinkFailure(error));
    return;
  }

  try {
    const res = await getAccountById(accountInfo.accountId);
    dispatch(accountLinkSuccess(res));
    dispatch(await refreshAccountList());
    finished();
  } catch (error) {
    dispatch(accountFetchFailure(error));
  }
};

export const viewAccount = () => ({
  type: VIEW_ACCOUNT,
});
