/**
 * GTM has very strict rules about how currency values are formatted.
 * Currency numbers must be passed
 * 1. as a string
 * 2. no characters other than numbers and a single decimal
 * 3. exactly two decimal points
 */
export const gtmCurrency = (value = 0) => {
  const parsedVal = Number(value);
  if (Number.isNaN(parsedVal)) {
    // Since this is just event tracking, we don't want to throw any errors
    // that will break the application...
    return '0.00';
  }

  return parsedVal.toFixed(2);
};

export default gtmCurrency;
