import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DownCaret from '@material-ui/icons/KeyboardArrowDown';
import { ref } from 'utils/propTypes';
import { gtmProductListSort } from '../../../store/middleware/TagManager/gtmActions';

import './Dropdown.scss';

const RenderOptions = (
  options,
  placeholder,
  selectedOption,
  shouldHavePlaceHolder,
  createPlaceholderObject,
  selectOptionByKeyPress,
  selectOption
) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.shop);

  let finalOptions;
  if (shouldHavePlaceHolder) {
    const placeholderOption = createPlaceholderObject(placeholder);

    finalOptions = options
      ? [placeholderOption, ...options]
      : [placeholderOption];
  } else {
    finalOptions = options;
  }

  const totalCount = get(state, 'products.meta.totalCount', 0);

  const handleProductListSortKey = (event, option) => {
    dispatch(gtmProductListSort(option.name, totalCount));
    selectOptionByKeyPress(event, option);
  };

  const handleProductListSortClick = (event, option) => {
    dispatch(gtmProductListSort(option.name, totalCount));
    selectOption(event, option);
  };

  return finalOptions.map((option) => (
    <li
      className={classnames('dropdown__item', {
        'dropdown__item--selected': option.selected,
      })}
      key={`Option${option.code}`}
      name={option.code}
      tabIndex="0"
      role="option"
      aria-selected={option.code === selectedOption.code}
      onKeyPress={(event) => handleProductListSortKey(event, option)}
      onClick={(event) => handleProductListSortClick(event, option)}
    >
      <div className="dropdown__option-name">{option.name}</div>
    </li>
  ));
};

const Dropdown = ({
  shouldHavePlaceHolder,
  selectedValue,
  placeholder: placeholderProp,
  errors,
  options: optionsProp,
  selectOptionByKeyPress,
  selectOption,
  wrapperRef,
  toggleDropdown,
  toggleDropdownByKeyPress,
  open,
  additionalDropdownButtonClass,
}) => {
  const createPlaceholderObject = (placeholder) => ({
    code: 'placeholder',
    name: placeholder,
  });

  const dropdownText = get(selectedValue, 'name', placeholderProp);

  return (
    <div
      ref={wrapperRef}
      className="dropdownContainer dropdown-container"
      data-test="dropdown-container"
    >
      <div
        className={classnames('dropdownButton', additionalDropdownButtonClass, {
          dropdown__error: errors && errors.length,
        })}
        data-test="dropdown-button"
        onClick={toggleDropdown}
        onKeyPress={toggleDropdownByKeyPress}
        tabIndex="0"
        role="button"
      >
        <span
          className="dropdownButtonPlaceholder dropdown-button"
          data-test="dropdown-placeholder"
        >
          {dropdownText}
        </span>
        <DownCaret className="dropdownButtonIcon" />
      </div>
      <ul
        className={classnames('dropdownOptions', {
          dropdownOptionsClosed: !open,
        })}
        data-test="dropdown-options"
      >
        {RenderOptions(
          optionsProp,
          placeholderProp,
          selectedValue,
          shouldHavePlaceHolder,
          createPlaceholderObject,
          selectOptionByKeyPress,
          selectOption
        )}
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
      ]),
      code: PropTypes.string,
      selected: PropTypes.bool,
    })
  ),
  placeholder: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
  shouldHavePlaceHolder: PropTypes.bool,
  selectedValue: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    code: PropTypes.string,
  }),
  selectOptionByKeyPress: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
  wrapperRef: ref,
  toggleDropdown: PropTypes.func.isRequired,
  toggleDropdownByKeyPress: PropTypes.func.isRequired,
  additionalDropdownButtonClass: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

Dropdown.defaultProps = {
  options: [],
  errors: [],
  selectedValue: {},
  shouldHavePlaceHolder: true,
  wrapperRef: undefined,
  additionalDropdownButtonClass: undefined,
};

export default Dropdown;
