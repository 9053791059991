import { useEffect } from 'react';
import { queryCache } from 'react-query';
import { useSelector } from 'react-redux';

import { selectMirroredUser } from 'selectors';

// This clears the react-query cache when a new user is mirrored from EXH.
// Query keys, used to determine when to fetch or use cached data, are
// not based on the current user. This means switching to a different
// CXH user when mirroring requires the cache to be cleared.

const useMirroredUserCacheClear = () => {
  const mirroredUser = useSelector(selectMirroredUser);

  useEffect(() => {
    if (mirroredUser?.id) {
      queryCache.clear();
    }
  }, [mirroredUser?.id]);
};

export default useMirroredUserCacheClear;
