import get from 'lodash/get';
import { SAVE_ADDRESS_FAILURE } from '../actionTypes';

// TODO: this should have error set to true, and the payload be the actual error
export default (error) => {
  const err = get(error, 'message', 'There was an error saving the address');
  return {
    type: SAVE_ADDRESS_FAILURE,
    error: err,
  };
};
