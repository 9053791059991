import React from 'react';
import PropTypes from 'prop-types';
import * as PaymentPropTypes from 'components/Payments/PaymentPropTypes';
import PaymentValidationMessageComponent from 'components/Payments/OtherComponents/ValidationMessage/PaymentValidationMessageComponent';
import Dropdown from '../../Dropdown';

import './CPSAccountDropdown.scss';

const CPSAccountDropdownComponent = (props) => (
  <div className="cps-account-dropdown">
    <label className="cps-account-dropdown-label" htmlFor="cpsAccount">
      {props.label || 'Pay Account'}
    </label>
    <Dropdown
      name="dropdownAccounts"
      options={props.accounts}
      dropdownName="cpsAccount"
      placeholder="Choose Account"
      errors={props.errors.cpsAccount}
      validate={props.validate}
      onChange={props.onChange}
      selectedValue={props.value}
      shouldHavePlaceHolder={props.accounts.length !== 1}
    />
    <PaymentValidationMessageComponent
      errors={props.errors}
      className="dropdown-message-container"
      field="cpsAccount"
    />
  </div>
);

CPSAccountDropdownComponent.propTypes = {
  errors: PaymentPropTypes.paymentFormErrors.isRequired, // eslint-disable-line react/no-typos
  label: PropTypes.string,
  value: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    code: PropTypes.string,
  }).isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      code: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

CPSAccountDropdownComponent.defaultProps = {
  label: '',
};

export default CPSAccountDropdownComponent;
