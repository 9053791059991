import PropTypes from 'prop-types';

export const propType = PropTypes.shape({
  invoiceDate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  dueDate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  invoiceId: PropTypes.string,
  invoiceStatus: PropTypes.string,
  grossAmount: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string)]),
});

export const defaultProp = {
  invoiceDate: [],
  invoiceId: undefined,
  invoiceStatus: undefined,
  dueDate: [],
  grossAmount: ['', ''],
};
