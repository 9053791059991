/**
 * creates a reducer which has a default state of 0,
 * and will always set state to the current timestamp when it receives the action for particular actions
 * @param {Object} config
 * @param {Array<string>} [config.setActionTypes=[]] - the action types that will set state to the current timestamp
 * @param {Array<string>} [config.resetActionTypes=[]] - the action types that will set state back to initial 0 value
 */
export default ({ setActionTypes = [], resetActionTypes = [] }) => (
  state = 0,
  action
) => {
  const { type } = action;
  if (setActionTypes.includes(type)) {
    return Date.now();
  }
  if (resetActionTypes.includes(type)) {
    return 0;
  }
  return state;
};
