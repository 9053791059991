import { LOCATION_CHANGE } from 'connected-react-router';

export const pageView = (e) => {
  if (
    // Allows event to fire when back button in browser is used
    e.payload.action === 'POP' ||
    // This prevents the page view event from firing when the acct # or the farmInsights ID # is appended to the URL
    (!window.location.search.split('=').includes('?acct') &&
      !window.location.pathname.split('/').includes('orgs') &&
      window.location.pathname !== '/login')
  ) {
    return {
      event: 'COMMON_PageView',
      eventCallback: () => window.dataLayer.reverse().splice(2),
      eventTimeout: 2000,
    };
  }
  return null;
};

export const pageViewEventsMap = {
  [LOCATION_CHANGE]: pageView,
};
