import { modalContainerStyles } from 'shared/styles';

const styles = {
  cookiesModalTitle: {
    height: '27px',
    fontSize: '20px',
    fontWeight: 600,
    color: '#222222',
    marginLeft: 0,
  },
  cookiesModalDescription: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '26px',
    marginTop: '10px',
    lineHeight: '25px',
  },
  cookiesModalPreferredBrowser: {
    width: '432px',
    fontStyle: 'italic',
    marginTop: '10px',
    lineHeight: '25px',
  },
  cookiesModalButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cookiesModalGetChrome: {
    width: '234px',
    marginBottom: '6px',
    marginRight: '21px',
  },
  cookiesModalAcceptCookies: {
    width: '234px',
    marginBottom: '6px',
  },
  cookiesModalTitleArea: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalContainer: {
    ...modalContainerStyles,
  },
  closeModal: {
    cursor: 'pointer',
  },
};

export default styles;
