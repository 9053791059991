import { connect } from 'react-redux';
import { gtmToggleInvoiceFilter } from 'store/middleware/TagManager/gtmActions';
import { getInvoiceStatuses } from '../../selectors';
import InvoiceStatusFilter from './InvoiceStatusFilter';
import withInvoiceFilters from '../withInvoiceFilters';

export const mapStateToProps = (state) => {
  const invoiceOptions = ['All'].concat(getInvoiceStatuses(state));
  return {
    invoiceOptions,
  };
};

const mapDispatchToProps = {
  gtmToggleInvoiceFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withInvoiceFilters(InvoiceStatusFilter));
