import { createToggleReducer } from 'shared/utils';
import {
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_FAILURE_RESET,
  NOTIFICATION_DELETE_FAILURE,
} from '../actions';

export default createToggleReducer({
  trueActions: [NOTIFICATION_DELETE_FAILURE],
  falseActions: [
    NOTIFICATION_DELETE_SUCCESS,
    NOTIFICATION_DELETE_FAILURE_RESET,
  ],
});
