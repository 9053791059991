export default {
  addPaymentFormLabelSmall: {
    color: '#828282',
    fontSize: '12px',
    marginLeft: '5px',
  },
  billingInfo: {
    width: '100%',
  },
  billingInfoShareText: {
    width: '401px',
    margin: '0 0 19px 0',
    opacity: '0.9',
    fontSize: '12px',
  },
  '@media (max-width: 480px)': {
    billingInfoShareText: {
      width: 'auto',
    },
  },
};
