import { GET_PREFERENCES_SUCCESS, UPDATE_PREFERENCE_SUCCESS } from '../actions';

export default (state = {}, action) => {
  const ret = {};
  switch (action.type) {
    case GET_PREFERENCES_SUCCESS:
      action.payload.preferences.forEach((pref) => {
        const { userId, ...rest } = pref;
        ret[pref.notificationType] = rest;
      });
      return ret;
    case UPDATE_PREFERENCE_SUCCESS: {
      const pref = action.payload;
      return {
        ...state,
        [pref.notification_type]: {
          notificationType: pref.notification_type,
          inboxOptOut: pref.inbox_opt_out,
          emailOptIn: pref.email_opt_in,
          smsOptIn: pref.sms_opt_in,
        },
      };
    }
    default:
      return state;
  }
};
