import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes, { arrayOf, shape } from 'prop-types';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import { Icon } from 'nutrien-common-components-react/dist/mdc/';
import { AuthenticatedComponent, useAuth } from '@nutrien/fe-domain-utils';
import queryString from 'query-string';

import { MOBILE_LOGO } from 'const/logo';
import { HeaderResponsiveMenuIcon } from 'shared/ui/HeaderSubComponents';
import HeaderIconNav from 'shared/ui/HeaderSubComponents/HeaderIconNavComponent';
import UserPropType from 'shared/config/userPropType';
import { withBrowser } from 'shared/ui/Header/withBrowser';

import resolveAgribleUrl from 'shared/config/resolveAgribleUrl';

import useIsInMaintenance from 'hooks/useIsInMaintenance';
import CartHeaderIconContainer from 'components/Cart/containers/CartHeaderIconContainer/CartHeaderIconContainer';
import NotificationMobileMenuItem from './NotificationMobileMenuItem';
import AccountSelector from '../../../HeaderSubComponents/HeaderAccountSelector/HeaderAccountSelector';
import './MobileHeader.scss';

export const pathIncludesProducts = (path) =>
  path.includes('products') ? { fromProductListPage: true } : null;

export const CartNavLink = (location) => (
  <NavLink
    activeClassName="selected"
    data-test="cart-header-icon-container"
    className="menu__item mobile-header__icon-link"
    to={{
      pathname: '/cart',
      state: pathIncludesProducts(location.pathname),
    }}
  >
    <CartHeaderIconContainer location={location} />
  </NavLink>
);

export const mobileIconMenuItems = (
  location,
  accounts,
  globalEcommerce,
  usaSapCart,
  westernRegionRestrictProducts,
  selectedAccount
) => {
  const cartNav =
    !accounts.length ||
    !globalEcommerce ||
    (usaSapCart && selectedAccount.usaSapAccount) ||
    westernRegionRestrictProducts ? null : (
      <CartNavLink {...location} />
    );
  return [
    <NotificationMobileMenuItem
      data-test="mobile-header-notification-mobile-menu-item"
      key="notifications"
    />,
    cartNav,
  ];
};

const MobileHeader = (props) => {
  const {
    history,
    location,
    user,
    isCanada,
    gtmClickNutrienLogoAction,
    selectedAccount,
    accounts,
    isMenuOpen,
  } = props;
  const {
    ecommContracts,
    globalEcommerce,
    isSiteDownForMaintenance,
    canadaBlackOut,
    usaSapCart,
    westernRegionRestrictProducts,
    usaSapRestrictFarmInsights,
    usaSapProducts,
    cxhAgribleSignOnLink,
  } = useFlags();

  const dispatch = useDispatch();
  const auth = useAuth();
  const isInMaintenance = useIsInMaintenance();
  const country = auth.user?.['https://nutrienagsolutions.com/country'];

  // Hub Field Modal
  const queryParams = queryString.parse(window.location.search);
  const menuOpen = queryParams?.showHubModal ? false : isMenuOpen;

  useEffect(() => {
    if (isMenuOpen && queryParams?.showHubModal) {
      props.toggleMenu();
    }
  }, [isMenuOpen, props, queryParams?.showHubModal]);

  const redirect = () => dispatch(push('/'));

  useEffect(() => {
    if (
      location.pathname === '/site-maintenance' &&
      !isSiteDownForMaintenance
    ) {
      redirect();
    }
  }, [location.pathname, isSiteDownForMaintenance]);

  useEffect(() => {
    if (Object.keys(user).length < 1) return;
    if (isInMaintenance) return;
    if ((country === 'CAN' && canadaBlackOut) || isSiteDownForMaintenance) {
      dispatch(push('/site-maintenance'));
    }
  }, [
    canadaBlackOut,
    dispatch,
    location,
    isSiteDownForMaintenance,
    user,
    auth,
    isInMaintenance,
  ]);

  const linkToProfile = () => {
    history.push(
      `/profile/overview${
        selectedAccount.id ? `?acct=${selectedAccount.id}` : ''
      }`
    );
  };

  const handleFeedbackClick = () => {
    // Only send a message if it's wrapped by React Native
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ name: 'feedback', payload: null })
      );
    }
  };

  return (
    <>
      <div
        className="mobile-header__content"
        data-test="mobile-header__content"
      >
        <AuthenticatedComponent>
          {/* this is the hamburger menu */}
          <HeaderResponsiveMenuIcon
            id="mobile-header__hamburger-menu"
            toggleMenu={props.toggleMenu}
            isMenuOpen={menuOpen}
          />
          <span className="header-vertical-line" />
          <div className="mobile-header__link-container">
            <div className="mobile-header__inner-link-container">
              <NavLink
                activeClassName="selected"
                to="/"
                key="/"
                data-test="header-nutrien-logo-link"
                onClick={() => gtmClickNutrienLogoAction()}
              >
                <img src={MOBILE_LOGO} alt="Nutrien Ag Solutions" />
              </NavLink>
              <AccountSelector history={history} location={location} />
            </div>
            <HeaderIconNav
              data-test="mobile-header-header-icon-nav"
              iconMenuItems={mobileIconMenuItems(
                location,
                accounts,
                globalEcommerce,
                usaSapCart,
                westernRegionRestrictProducts,
                selectedAccount
              )}
            />
          </div>
          <div
            className={classNames('mobile-header__drawer-menu', {
              'open-menu': menuOpen,
            })}
          >
            <div className="mobile-header__profile-container">
              <Icon icon="account_circle" className="profile-icon" />
              {(user.firstName || user.userName) && (
                <div className="mobile-header__user-details">
                  <span>
                    <div
                      className="header-user__info__name"
                      data-hj-suppress=""
                      onClick={linkToProfile}
                      onKeyPress={linkToProfile}
                      role="button"
                      tabIndex={-1}
                    >
                      {`${user.firstName || user.userName || ''}
                      ${user.lastName || ''}`}
                    </div>
                    <div className="header-user__info__view-profile">
                      <Link to="/profile">Profile</Link>
                    </div>
                  </span>
                  <br />
                </div>
              )}
            </div>
            <div
              data-test="mobile-header-links-container"
              className="mobile-header__links-container"
            >
              <NavLink
                exact
                strict
                activeClassName="selected"
                className="mobile-header__menu-item mobile-header__menu-item--rippled"
                key="/"
                to="/"
              >
                Overview
              </NavLink>
              <NavLink
                activeClassName="selected"
                className="mobile-header__menu-item mobile-header__menu-item--rippled"
                data-test="accounts-nav-link"
                to="/accounts"
              >
                Accounts
              </NavLink>
              <NavLink
                activeClassName="selected"
                className="mobile-header__menu-item mobile-header__menu-item--rippled"
                data-test="weatherstory-nav-link"
                to="/?showHubModal=true&modalType=weather"
              >
                Weather Story
              </NavLink>
              {accounts.length &&
              globalEcommerce &&
              !westernRegionRestrictProducts &&
              !usaSapProducts ? (
                <NavLink
                  activeClassName="selected"
                  className="mobile-header__menu-item mobile-header__menu-item--rippled"
                  data-test="products-nav-link"
                  to="/products"
                >
                  Products Catalog
                </NavLink>
              ) : null}
              {accounts.length && !usaSapRestrictFarmInsights ? (
                <NavLink
                  activeClassName="selected"
                  className="mobile-header__menu-item mobile-header__menu-item--rippled"
                  to="?showHubModal=true&modalType=insights"
                  data-test="mobile-header-farm-insights-link"
                >
                  Farm Insights
                </NavLink>
              ) : null}
            </div>
            <div
              data-test="mobile-header__sign-out-container"
              className="mobile-header__sign-out-container"
            >
              {accounts.length &&
              globalEcommerce &&
              !usaSapCart &&
              !westernRegionRestrictProducts ? (
                <NavLink
                  activeClassName="selected"
                  className="mobile-header__menu-item mobile-header__menu-item--rippled"
                  to="/orders"
                  data-test="mobile-header-online-orders-link"
                >
                  Online Orders
                </NavLink>
              ) : null}

              {/* remove props.isCanada condition when documents section is enabled to all countries users */}
              {isCanada && ecommContracts && (
                <NavLink
                  activeClassName="selected"
                  className="mobile-header__menu-item mobile-header__menu-item--rippled"
                  to="/documents"
                >
                  Documents
                </NavLink>
              )}
              {window.ReactNativeWebView && (
                <div
                  id="feedback-button--mobile"
                  data-test="mobile-feedback-button"
                  className="mobile-header__menu-item mobile-header__menu-item--rippled"
                  onClick={handleFeedbackClick}
                  onKeyPress={handleFeedbackClick}
                  role="menuitem"
                  tabIndex="0"
                >
                  Give Feedback
                </div>
              )}
              <NavLink
                activeClassName="selected"
                className="mobile-header__menu-item mobile-header__menu-item--rippled"
                to="/contact"
                data-test="contact-nav-link"
              >
                Contact Us
              </NavLink>
              {cxhAgribleSignOnLink && (
                <a
                  activeClassName="selected"
                  className="mobile-header__menu-item mobile-header__menu-item--rippled"
                  href={`${resolveAgribleUrl()}/grower-dashboard`}
                >
                  Go to Agrible
                </a>
              )}
              <button
                id="logout-button--mobile"
                data-test="mobile-logout-button"
                className="mobile-header__menu-item mobile-header__menu-item--rippled mobile-logout-button"
                onClick={() => props.handleLogout(props.isMirroring)}
                onKeyPress={() => props.handleLogout(props.isMirroring)}
                role="menuitem"
                tabIndex="0"
              >
                Sign Out
              </button>
            </div>
          </div>
        </AuthenticatedComponent>
      </div>
      <div
        className={classNames('mobile-header__menu-backdrop', {
          'open-menu': menuOpen,
        })}
        data-test="mobile-overlay-dismisser"
        onClick={props.toggleMenu}
        onKeyPress={props.toggleMenu}
        role="presentation"
        tabIndex={-1}
      />
    </>
  );
};

MobileHeader.propTypes = {
  accounts: arrayOf(shape({})),
  handleLogout: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  state: PropTypes.shape({
    isAccountMenuOpen: PropTypes.bool,
    toolsMenuOpen: PropTypes.bool,
  }).isRequired,
  toggleMenu: PropTypes.func.isRequired,
  user: UserPropType.isRequired,
  isCanada: PropTypes.bool,
  gtmClickNutrienLogoAction: PropTypes.func.isRequired,
  selectedAccount: PropTypes.shape({
    id: PropTypes.string,
    usaSapAccount: PropTypes.bool,
  }).isRequired,
  isMirroring: PropTypes.bool,
};

MobileHeader.defaultProps = {
  accounts: [],
  isCanada: false,
  isMirroring: false,
};

export default withRouter(withBrowser(MobileHeader));
