import * as actionTypes from '../../actions/constants';

const initialOrdersState = {
  orders: [],
  loading: true, // this defaults to true so things view as loading until explicitly changed to false
  branchAddressLoading: true,
  branchAddress: null,
  error: null,
  status: '',
};

export default (state = initialOrdersState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_ORDERS: {
      const { loading } = action;
      return {
        ...state,
        orders: [],
        error: null,
        loading,
        status: 'LOADING',
      };
    }
    case actionTypes.GET_ORDERS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        orders: data.included,
        loading: false,
        status: 'OK',
      };
    }
    case actionTypes.GET_ORDERS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: false,
        status: 'ERROR',
      };
    }
    case actionTypes.GET_BRANCH_ADDRESS_SUCCESS: {
      const { data } = action;
      const address = {
        data,
        error: null,
      };
      return {
        ...state,
        branchAddressLoading: false,
        branchAddress: address,
      };
    }
    case actionTypes.GET_BRANCH_ADDRESS_FAILURE: {
      const { error } = action;
      const address = {
        address: null,
        error,
      };
      return {
        ...state,
        branchAddressLoading: false,
        branchAddress: address,
      };
    }
    default:
      return state;
  }
};
