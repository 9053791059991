import { Icon, List, Checkbox } from 'nutrien-common-components-react/dist/mdc';
import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MaterialLoadingIconInline } from 'shared/ui';
import OnClickOutside from 'shared/ui/OnClickOutside/OnClickOutside';
import './styles.scss';

class InvoicedProductsSearch extends Component {
  state = {
    query: '',
    showResults: false,
  };

  handleOnFocus = () => {
    this.setState({ showResults: true });
    this.props.gtmFocusInvoicedProductsSearch(true);
  };

  handleOnBlur = () => {
    if (this.state.showResults) {
      this.setState({ showResults: false });
      this.props.gtmFocusInvoicedProductsSearch(false);
    }
  };

  handleInputChange = (e) => {
    this.setState({ query: e.target.value });
    this.props.gtmUpdateInvoicedProductsSearch(e.target.value);
  };

  handleInputClearButton = () => {
    this.setState({ query: '' });
  };

  toggleProductSelection = (productSummary) => {
    const {
      selectInvoicedProduct,
      deselectInvoicedProduct,
      selectedInvoicedProducts,
    } = this.props;

    if (
      selectedInvoicedProducts.some(
        (product) => product.productId === productSummary.productId
      )
    ) {
      deselectInvoicedProduct(productSummary);
    } else {
      selectInvoicedProduct(productSummary);
    }
  };

  // Returns results where all words in the query start with any word in the product name
  // or in product id.
  // Ex: given product names list ["prod abc", "prod def", "prod abc def", "prod lgh iii"]
  // 1) query = "abc" returns ["prod abc", "prod abc def"]
  // 2) query = "abc def" returns ["prod abc def"]
  filterInvoicedProductsByQuery = (options, query) =>
    query
      ? options.filter(
          (option) =>
            option.productId.toLowerCase().startsWith(query.toLowerCase()) ||
            query
              .toLowerCase()
              .split(' ')
              .every((queryToken) =>
                option.productName
                  .toLowerCase()
                  .split(' ')
                  .some((productNameToken) =>
                    productNameToken.startsWith(queryToken)
                  )
              )
        )
      : options;

  renderOption = (productSummary, selectedInvoicedProducts) => (
    <div
      className="invoiced-products-search__search-results-item"
      key={productSummary.productId}
      role="button"
      tabIndex={0}
      onKeyUp={() => this.toggleProductSelection(productSummary)}
      onClick={() => this.toggleProductSelection(productSummary)}
    >
      <div className="invoiced-products-search__search-results-item-checkbox-container">
        <Checkbox
          checked={selectedInvoicedProducts.some(
            (product) => product.productId === productSummary.productId
          )}
          value={productSummary.productId}
          className="invoiced-products-search__search-results-item-checkbox"
        />
      </div>
      <div className="invoiced-products-search__search-results-item-text-container">
        <span className="invoiced-products-search__product-name">
          {productSummary.productName}
        </span>
        <span className="invoiced-products-search__product-id">
          {productSummary.productId}
        </span>
      </div>
    </div>
  );

  renderInvoicedProductOptions = (productOptions) => {
    const { selectedInvoicedProducts } = this.props;

    return (
      <List className="invoiced-products-search__search-results-list">
        {productOptions.map((productSummary) =>
          this.renderOption(productSummary, selectedInvoicedProducts)
        )}
      </List>
    );
  };

  renderLoadingSpinner = (isLoading) =>
    isLoading && (
      <div className="invoiced-products-search__loading-spinner-container">
        <MaterialLoadingIconInline size={20} />
        Loading invoiced products...
      </div>
    );

  renderSearchBar = (query) => (
    <div className="invoiced-products-search__action-container">
      <div className="invoiced-products-search__search-input-container">
        <input
          className="invoiced-products-search__input"
          value={query}
          onChange={this.handleInputChange}
        />
        {query && (
          <div className="invoiced-products-search__clear-icon-container">
            <Icon
              icon="clear"
              className="invoiced-products-search__clear-icon light-gray"
              onClick={this.handleInputClearButton}
            />
          </div>
        )}
      </div>
      <div className="invoiced-products-search__search-icon-container">
        <Icon icon="search" />
      </div>
    </div>
  );

  renderFocusableArea = (isLoading, showResults, query, products) => {
    const containerClasses = classNames('invoiced-products-search__container', {
      'invoiced-products-search__container--expanded': showResults && query,
    });
    return (
      <div className={containerClasses} onFocus={this.handleOnFocus}>
        <span className="invoiced-products-search__header">
          Filter Invoices by Product
        </span>
        {this.renderSearchBar(query)}
        {showResults && query && (
          <div className="invoiced-products-search__search-results-list-container">
            {this.renderLoadingSpinner(isLoading)}
            {this.renderInvoicedProductOptions(products)}
            {(products && products.length > 0) || isLoading || (
              <div className="invoiced-products-search__search-results-not-found">
                No Results
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { invoicedProductsOptions, isLoading } = this.props;
    const { query, showResults } = this.state;

    const filteredProductOptions = this.filterInvoicedProductsByQuery(
      invoicedProductsOptions,
      query
    );

    return (
      <OnClickOutside clickOutsideHandler={this.handleOnBlur}>
        {this.renderFocusableArea(
          isLoading,
          showResults,
          query,
          filteredProductOptions
        )}
      </OnClickOutside>
    );
  }
}

InvoicedProductsSearch.propTypes = {
  invoicedProductsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      productName: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedInvoicedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      productName: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectInvoicedProduct: PropTypes.func.isRequired,
  deselectInvoicedProduct: PropTypes.func.isRequired,
  gtmUpdateInvoicedProductsSearch: PropTypes.func.isRequired,
  gtmFocusInvoicedProductsSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InvoicedProductsSearch;
