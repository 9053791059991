export default (theme) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listItemText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  toggles: {
    [theme.breakpoints.down('xs')]: {
      position: 'initial',
      transform: 'initial',
      paddingLeft: '16px',
      paddingBottom: '8px',
    },
  },
  toggleLabel: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  emailToggle: {
    marginRight: 36,
  },
  inboxToggle: {
    marginRight: 36,
  },
  formControl: {
    marginTop: 0,
  },
});
