import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  allLicenses,
  fetchLicensesError,
  isFetchingLicenses,
  licensesFetchedAtTime,
} from './selectors';
import { fetchLicenses } from './actions';
import { licenseArrayPropType } from './licensesPropTypes';

export const mapStateToProps = (state) => ({
  licenses: allLicenses(state),
  fetchLicensesError: fetchLicensesError(state),
  isFetchingLicenses: isFetchingLicenses(state),
  licensesFetchedAtTime: licensesFetchedAtTime(state),
});

export const mapDispatchToProps = {
  fetchLicenses,
};

/**
 * This is a HoC that will request licenses for you if they don't already exist in the store. The goal here is to
 * make it simple for any new components to access licenses without needing to make their own requests.
 * @example `const ComponentWithLicenses = withLicenses(MyComponent)`
 * @param  {React.Component} WrappedComponent The component you want to supply with permit data.
 * @return {React.Component} A React component that renders your component, supplying it with licenses data.
 */
export const withLicenses = (WrappedComponent) =>
  class WithLicenses extends Component {
    static propTypes = {
      licenses: licenseArrayPropType,
      fetchLicenses: PropTypes.func,
      fetchLicensesError: PropTypes.instanceOf(Error),
      isFetchingLicenses: PropTypes.bool,
      licensesFetchedAtTime: PropTypes.number,
    };

    static defaultProps = {
      licenses: [],
      fetchLicenses: () => {},
      fetchLicensesError: undefined,
      isFetchingLicenses: false,
      licensesFetchedAtTime: 0,
    };

    componentDidMount() {
      const { fetchLicenses, licensesFetchedAtTime } = this.props; // eslint-disable-line no-shadow

      if (!licensesFetchedAtTime) {
        fetchLicenses();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

/**
 * This connects your withLicenses component to the redux stor so it can
 * properly dispatch actions.
 * @param  {React.Component} WrappedComponent The component you want to give licenses to.
 * @return {React.Component}                  The wrapped component with licenses.
 */
const connectedWithLicenses = (WrappedComponent) =>
  connect(mapStateToProps, mapDispatchToProps)(withLicenses(WrappedComponent));

export default connectedWithLicenses;
