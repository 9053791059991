import { connect } from 'react-redux';
import NotificationsListItem from './NotificationsListItem';
import {
  toggleNotificationReadStatus,
  deleteNotification,
} from '../../actions';

const mapDispatchToProps = {
  onRead: toggleNotificationReadStatus,
  onDelete: deleteNotification,
};

export default connect(undefined, mapDispatchToProps)(NotificationsListItem);
