import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, withStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';

import CustomerSwitcherItem from './CustomerSwitcherItem';

const styles = {
  base: { zIndex: 99999 },
  menu: { zIndex: 99999 },
  button: {
    minWidth: '295px',
    height: '100%',
    fontWeight: '600',
    backgroundColor: '#464646',
    textTransform: 'capitalize',
    color: 'white',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#464646',
    },
  },
  customerName: { color: '#f9a825' },
  carat: { textAlign: 'right', color: '#f9a825', fontSize: '2.5em' },
};

class CustomerSwitcher extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    customers: PropTypes.arrayOf(PropTypes.object).isRequired,
    mirroredUser: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    fetchCustomers: PropTypes.func.isRequired,
  };

  state = { anchorEl: null };

  componentDidMount() {
    const { customers, fetchCustomers } = this.props;
    if (!customers.length) {
      fetchCustomers();
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  selectCustomer = (customer) => {
    const { mirroredUser, onSelect } = this.props;
    this.handleClose();
    if (customer.id !== mirroredUser.id) {
      onSelect(customer);
    }
  };

  render() {
    const { customers, mirroredUser, classes } = this.props;
    const { anchorEl } = this.state;
    return (
      <div className={classNames('customer-switcher', classes.base)}>
        <Button className={classes.button} onClick={this.handleClick}>
          <span>
            Mirroring: &nbsp;
            <span className={classes.customerName} data-hj-suppress="">
              {` ${mirroredUser.firstName} ${mirroredUser.lastName} `}
            </span>
          </span>
          <KeyboardArrowDown className={classes.carat} />
        </Button>
        <Menu
          className={classes.menu}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {customers.map((customer) => (
            <CustomerSwitcherItem
              key={customer.id}
              customer={customer}
              selected={customer.id === mirroredUser.id}
              onSelect={this.selectCustomer}
            />
          ))}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(CustomerSwitcher);
