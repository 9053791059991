import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoginSwitchButton from 'components/Login/LoginSwitchButton';
import { HeaderBrowserSupport } from 'shared/ui/Header/withBrowser';
import BaseHeader from '../BaseHeader';
import { logout } from '../../../../../components/Authentication/actions/authenticationActions';

const LoginHeader = ({
  browser,
  isMirroring,
  location,
  push,
  isEmployeeView,
}) => {
  const { canadaBlackOut } = useFlags();
  const dispatch = useDispatch();
  useEffect(() => {
    sessionStorage.removeItem('timeout');
    const isCanada = sessionStorage.getItem('isCanada');
    if (isCanada && canadaBlackOut) dispatch(logout('/site-maintenance'));
  });

  return (
    <>
      <HeaderBrowserSupport browser={browser} isEmployeeView={isEmployeeView} />
      <BaseHeader
        browser={browser}
        location={location}
        isMirroring={isMirroring}
        displayForAllScreenSizes
      >
        <LoginSwitchButton location={location} push={push} />
      </BaseHeader>
    </>
  );
};

LoginHeader.propTypes = {
  browser: PropTypes.shape({
    isSupported: PropTypes.bool,
  }).isRequired,
  isMirroring: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  push: PropTypes.func.isRequired,
  isEmployeeView: PropTypes.bool.isRequired,
};

LoginHeader.defaultProps = {
  isMirroring: false,
};

export default LoginHeader;
