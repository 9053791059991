import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InnerContent from './InnerContent';
import useStyles from './styles';

const BuildDesktop = (props) => {
  const account = useSelector((state) => state.selectedAccount);
  const classes = useStyles();
  return (
    account.id && (
      <>
        <div>
          <div
            className={classes.contractInfoAccountName}
            data-test="account-name"
          >
            {account.name}
          </div>
          <div
            className={classes.contractInfoContractNumber}
            data-test="contract-contact-info-desktop"
          >
            Contract #{props.contractDetails.contractNumber}
          </div>
        </div>
        <InnerContent details={props.contractDetails} account={account} />
      </>
    )
  );
};

const BuildMobile = (props) => {
  const account = useSelector((state) => state.selectedAccount);
  const classes = useStyles();
  return (
    account.id && (
      <>
        <div className={classes.contractInfoAccountName}>{account.name}</div>
        <ExpansionPanel
          className={classes.expansionPanel}
          data-test="contract-contact-info-mobile"
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Contract #{props.contractDetails.contractNumber}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <InnerContent details={props.contractDetails} account={account} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </>
    )
  );
};

const ContractInfo = (props) => {
  const classes = useStyles();
  const { history, pageWidth } = props;
  // this should match the width at which the table styles change
  const mobileBreakpoint = 1000;
  let section;

  if (pageWidth > mobileBreakpoint) {
    section = BuildDesktop(props);
  } else {
    section = BuildMobile(props);
  }
  const backToOverview = () => {
    history.push('/documents');
  };

  return (
    <>
      <div
        onKeyPress={backToOverview}
        role="button"
        tabIndex={0}
        className={classes.backToDocuments}
        onClick={backToOverview}
      >
        <Icon
          className={classes.backToDocumentsIcon}
          icon="arrowDown"
          type="success"
        />
        <span className={classes.backToDocumentsText}>Back To Documents</span>
      </div>
      {section}
    </>
  );
};

ContractInfo.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  pageWidth: PropTypes.number.isRequired,
};

export default ContractInfo;
