import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { employeeInfo, isEmployeeSupport } from '../selectors';
import { fetchEmployeeInfo } from '../actions';

export const withEmployee = (WrappedComponent) =>
  class wrappedComponent extends Component {
    static propTypes = { fetchEmployeeInfo: PropTypes.func.isRequired };

    componentDidMount() {
      this.props.fetchEmployeeInfo();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

const mapDispatchToProps = {
  fetchEmployeeInfo,
};

const mapStateToProps = (state) => ({
  employee: employeeInfo(state),
  isSupport: isEmployeeSupport(state),
});

export default (WrappedComponent) =>
  connect(mapStateToProps, mapDispatchToProps)(withEmployee(WrappedComponent));
