import moment from 'moment';
import { NOTIFICATION_TYPES } from '../actions';

const constructRoute = (notification) => {
  const { accountNumber } = notification;
  const {
    variables: { creditApplicationId },
  } = notification;
  switch (notification.type) {
    case NOTIFICATION_TYPES.NEW_STATEMENT:
      return `/accounts/${accountNumber}/statements`;
    case NOTIFICATION_TYPES.PAYMENT_DUE:
    case NOTIFICATION_TYPES.PAYMENT_OVERDUE:
      return `/accounts/${accountNumber}/open-items`;
    case NOTIFICATION_TYPES.PASSWORD_CHANGED:
      return '/profile/overview';
    case NOTIFICATION_TYPES.CREDIT_APP_APPROVED:
      return `/credit-application/${creditApplicationId}`;
    case NOTIFICATION_TYPES.CREDIT_APP_SUBMITTED:
      return `/oca?continue=form/${creditApplicationId}`;
    case NOTIFICATION_TYPES.CREDIT_APP_INCOMPLETE:
      return `/oca?continue=form/${creditApplicationId}`;
    case NOTIFICATION_TYPES.NEW_WEATHER_STORY: {
      const date = moment(notification.variables.publishedTime).format(
        'YYYY-MM-DD'
      );
      return `/weatherstory/${
        notification.entityId
          ? notification.entityId.toLowerCase().replace(/_/g, '-')
          : ''
      }/${date}`;
    }
    case NOTIFICATION_TYPES.PAYMENT_SCHEDULED_SUCCESS:
    case NOTIFICATION_TYPES.RECURRING_PAYMENT_SCHEDULED_SUCCESS:
    case NOTIFICATION_TYPES.RECURRING_PAYMENT_SERIES_ENDING:
    case NOTIFICATION_TYPES.RECURRING_PAYMENT_DELETED:
    case NOTIFICATION_TYPES.SCHEDULED_PAYMENT_DELETED:
      return '/profile/billing';
    case NOTIFICATION_TYPES.NEW_INVOICE:
      return `/accounts/${accountNumber}/invoices/${
        notification.variables.invoiceId || ''
      }`;
    case NOTIFICATION_TYPES.PAYMENT_RECEIVED:
    default:
      return `/accounts/${accountNumber}/overview`;
  }
};

export default constructRoute;
