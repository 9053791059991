import { createSelector } from 'reselect';
import { get } from 'lodash';
import getUserPhoneNumbers from './getUserPhoneNumbers';

const getUserPreferredPhoneNumber = createSelector(
  getUserPhoneNumbers,
  (phoneNumbers) =>
    get(
      phoneNumbers.find((num) => num.preferred === true),
      'value'
    )
);

export default getUserPreferredPhoneNumber;
