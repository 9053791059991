import {
  DELETE_SCHEDULED_PAYMENT,
  STATUS,
  CLEAR_DELETE_STATUS,
} from '../Actions/constants';

export default (state = { status: STATUS.INITIAL }, action) => {
  switch (action.type) {
    case DELETE_SCHEDULED_PAYMENT:
      return action.payload;
    case CLEAR_DELETE_STATUS:
      return { status: STATUS.INITIAL };
    default:
      return state;
  }
};
