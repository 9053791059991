import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { shape, string, func } from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import { gtmFilterProducts } from '../../../../store/middleware/TagManager/gtmActions';
import {
  sanitizeFilterParams,
  getUrlParameters,
  convertComma,
} from '../../utils/filterHelpers';

import './styles.scss';
import styles from './styles';
import { ADD_FILTER, REMOVE_FILTER, nameTypes } from './constants';

export const FilterDropdown = ({
  classes,
  type,
  history,
  location,
  products,
}) => {
  const dispatch = useDispatch();
  const productsLoading = products.loading;
  const handleFilterChecked = (e, name, clickedId) => {
    const filters = get(products, 'meta.filters', []);
    const search = location.search.substring(1);
    const { checked } = e.target;
    const searchParameters = getUrlParameters(search);

    if (checked) {
      dispatch(gtmFilterProducts(ADD_FILTER, `${nameTypes[type]} | ${name}`));
      // Add the id to the url
      searchParameters[type] = searchParameters[type]
        ? (searchParameters[type] += `%2C${clickedId}`)
        : (searchParameters[type] = clickedId);
    } else {
      dispatch(
        gtmFilterProducts(REMOVE_FILTER, `${nameTypes[type]} | ${name}`)
      );
      // Remove the id from the url
      const sanitizedParams = sanitizeFilterParams(
        searchParameters[type],
        filters[type],
        type
      );
      const indexOfClicked = sanitizedParams.indexOf(clickedId);
      if (indexOfClicked !== -1) {
        sanitizedParams.splice(indexOfClicked, 1);
      }
      searchParameters[type] = sanitizedParams.join('%2C');

      if (searchParameters[type] === '') {
        delete searchParameters[type];
      }
    }

    const searchParameterKeys = Object.keys(searchParameters);
    const queryParams = searchParameterKeys.map((key) => {
      const value = searchParameters[key];
      return `${key}=${value}`;
    });

    if (!queryParams.length && checked) {
      queryParams.push(`${type}=${clickedId}`);
    }

    history.push(`/products?${queryParams.join('&')}`);
  };

  const ReturnCheckboxes = () => {
    const filters = get(products, 'meta.filters', []);
    const checkedIds = get(products, 'checkedIds', []);
    const checkedFilters = checkedIds[type];

    // If URL contains contracts param, disable other filter checkboxes
    const contractFilterOnly =
      useSelector((state) => state.router.location.search).split('&')[1] ===
      'contTypes=sku';

    if (!filters[type] || !filters[type].length) {
      return false;
    }

    return filters[type].map((filter) => {
      const { name, count, value } = filter;
      let noProducts = false;
      if (count < 1) noProducts = true;

      const filteredId = convertComma(value);
      const key = `${type}-${filteredId}`;

      let checked = checkedFilters.indexOf(filteredId) !== -1;
      location.search.split('&').forEach((filter) => {
        const value = filter.split('=');
        if (
          filteredId &&
          value[1] &&
          value[1].replace(/%20/g, ' ').split('%2C').includes(filteredId) &&
          value[0] === type
        )
          checked = true;
      });
      return (
        <li
          key={key}
          className="filter-list-item"
          data-test="filter-list-item"
          data-index={filteredId}
        >
          <FormControlLabel
            value={`${name} (${count})`}
            data-index={filteredId}
            classes={{ label: classes.checkboxLabel }}
            control={
              <Checkbox
                color="primary"
                disabled={noProducts || productsLoading || contractFilterOnly}
                onClick={(e) => handleFilterChecked(e, name, filteredId)}
                data-test="filter-checkbox"
                checked={checked}
              />
            }
            label={`${name} (${count})`}
            labelPlacement="end"
          />
        </li>
      );
    });
  };

  return (
    <div className="filter-wrapper--outer">
      <ExpansionPanel className="filter-wrapper--inner">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className="filter-wrapper__icon" />}
        >
          <Typography className={classes.filterWrapperHeader}>
            {nameTypes[type]}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="filter-list-wrapper">
          <ul className="filter-list">{ReturnCheckboxes()}</ul>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

FilterDropdown.propTypes = {
  classes: shape({
    checkboxLabel: string,
    filterWrapperHeader: string,
  }).isRequired,
  type: string.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ search: string }).isRequired,
  products: shape({
    meta: shape({
      filter: shape({
        chemicals: [],
        fertilizers: [],
        activeIngredients: [],
        manufacturers: [],
      }),
    }),
  }).isRequired,
};

export default withRouter(withStyles(styles)(FilterDropdown));
