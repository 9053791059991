const contentPaddingWidth = '16px';

const styles = (theme) => ({
  name: {
    fontSize: 30,
    margin: '30px 0px',
  },
  contentContainer: {
    margin: '10px 15px',
  },
  topContent: {
    padding: '16px 16px 0px',
  },
  marginTop: {
    marginTop: 14,
  },
  paddedContent: {
    padding: `0px ${contentPaddingWidth}`,
  },
  sectionContainer: {
    marginTop: 22,
    paddingBottom: '20px',
  },
  section: {
    marginTop: 22,
    fontSize: 14,
  },
  sectionTitle: {
    fontSize: 22,
  },
  contactContainer: {
    margin: '30px -10px 40px',
  },
  p: {
    color: theme.palette.text.secondary,
  },
  emptyField: {
    fontStyle: 'italic',
  },
  // used when the hr is designed to span the whole width of the sectionContainer
  hrFullWidth: {
    marginRight: `-${contentPaddingWidth}`,
    marginLeft: `-${contentPaddingWidth}`,
  },
  // applies to the tabs container
  profileTabs: {
    marginBottom: 22,
  },
  // applies to the text of each individual tab
  profileTab: {
    '& span': {
      fontSize: 14,
      color: theme.palette.text.support,
    },
  },
  // styles only used for the "selected" tab
  selectedTab: {
    '& span': { color: theme.palette.text.secondary, fontWeight: 600 },
  },
});

export default styles;
