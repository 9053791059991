/* eslint-disable react/prop-types */
/* eslint-disable prefer-template */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import constants from '../constants';

const useStyles = makeStyles(() => ({
  table: {
    margin: 0,
    width: '100%',
  },
  row: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.25)',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '12px',
    width: '100%',

    '@media (min-width: 1000px)': {
      borderBottom: '1px solid #ccc',
      borderRadius: 0,
      boxShadow: 'none',
      display: 'table-row',
      marginTop: 0,
    },
  },
  cell: {
    borderBottom: 'none',
    color: '#222',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    fontSize: '.875rem',
    width: '50%',
    textAlign: 'left',

    '@media (min-width: 1000px)': {
      display: 'table-cell',
      width: '20%',
    },
  },
  cellHeaderDesktop: {
    borderBottom: '1px solid #ccc',
    color: '#222',
    display: 'none',
    fontSize: '.875rem',
    fontWeight: 600,
    padding: '12px 16px 12px 12px',
    whiteSpace: 'nowrap',

    '@media (min-width: 1000px)': {
      display: 'table-cell',
    },
  },
  cellHeaderMobile: {
    color: '#666666',
    display: 'block',
    fontSize: '.8rem',
    fontWeight: 600,
    '@media (min-width: 1000px)': {
      display: 'none',
    },
  },

  cellTotalVolume: {
    '@media (min-width: 1000px)': {
      position: 'relative',
    },
  },

  cellTotalPrice: {
    borderBottom: 'none',
    color: '#222',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    fontSize: '.875rem',
    width: '50%',
    textAlign: 'left',

    '@media (min-width: 1000px)': {
      display: 'table-cell',
      width: '23%',
      textAlign: 'right',
    },
  },

  shelfWrapper: {
    paddingBottom: '70px',
    '@media (max-width: 700px)': {
      paddingBottom: '24px',
    },
  },
}));

const displayShelfName = (name) => {
  switch (name) {
    case 'Chemical':
    case 'Chemical [CHEM]':
      return 'Chemical';
    case 'Fertilizer':
    case 'Fertilizer [FERT]':
      return 'Fertilizer';
    case 'Seed':
    case 'Seed [SEED]':
      return 'Seed';
    default:
      return 'Misc';
  }
};
export default function ProductsTable(props) {
  const classes = useStyles();
  const { shelf, products } = props;
  return (
    <div className={classes.shelfWrapper}>
      <h2>{displayShelfName(shelf)}</h2>
      <Table className={classes.table} data-test="contracted-products-table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHeaderDesktop}>Product</TableCell>
            <TableCell align="left" className={classes.cellHeaderDesktop}>
              Pack Size
            </TableCell>
            <TableCell align="left" className={classes.cellHeaderDesktop}>
              Total Volume
            </TableCell>
            <TableCell align="left" className={classes.cellHeaderDesktop}>
              Unit Price
            </TableCell>
            <TableCell align="right" className={classes.cellHeaderDesktop}>
              Total Price
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow className={classes.row}>
              <TableCell align="left" className={classes.cell}>
                <span className={classes.cellHeaderMobile}>Product</span>
                <span>{product.productName}</span>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <span className={classes.cellHeaderMobile}>Pack Size</span>
                <span>{product.packageSize}</span>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <span className={classes.cellHeaderMobile}>Quantity</span>
                <span className={classes.cellTotalVolume}>
                  {product.totalVolume + ' ' + product.packageUom}
                </span>
              </TableCell>
              <TableCell align="left" className={classes.cell}>
                <span className={classes.cellHeaderMobile}>Unit Price</span>
                <span data-test="contracted-products-table-unitPrice">
                  {product.unitPrice && product.unitPrice !== 0
                    ? numeral(product.unitPrice).format('$0,0.00') +
                      '/' +
                      ' ' +
                      product.packageUom
                    : constants.NO_PRICE}
                </span>
              </TableCell>
              <TableCell align="right" className={classes.cellTotalPrice}>
                <span className={classes.cellHeaderMobile}>Total Price</span>
                <span data-test="contracted-products-table-totalPrice">
                  {product.totalPrice && product.totalPrice !== 0
                    ? numeral(product.totalPrice).format('$0,0.00') +
                      '/' +
                      ' ' +
                      product.packageUom
                    : constants.NO_PRICE}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

ProductsTable.propTypes = {
  shelf: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
