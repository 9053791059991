/**
 * creates a boolean toggle reducer
 * @param {Object} config
 * @param {Array<string>} [config.trueActions=[]] actions that will trigger a true/ON state
 * @param {Array<string>} [config.falseActions=[]] actions that will trigger a false/OFF/default state
 */
export default ({ trueActions = [], falseActions = [] }) => (
  state = false,
  action
) => {
  if (trueActions.includes(action.type)) return true;
  if (falseActions.includes(action.type)) return false;
  return state;
};
