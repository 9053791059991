import React from 'react';
import PropTypes from 'prop-types';

import './AccountingList.css';

const AccountingListHeaderItem = (props) => (
  <th className="accounting-list__header" {...props}>
    <div className="accounting-list__header__inner">{props.children}</div>
  </th>
);

AccountingListHeaderItem.propTypes = {
  // sometimes a header exists with no text, so the children property should not be required
  children: PropTypes.node,
};

AccountingListHeaderItem.defaultProps = {
  children: undefined,
};

export default AccountingListHeaderItem;
