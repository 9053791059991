// TODO: separate fetch status from update status.
import {
  NOTIFICATIONS_PREFERENCES_UPDATE_SUCCESS,
  NOTIFICATIONS_PREFERENCES_UPDATE_FAILURE,
  NOTIFICATIONS_PREFERENCES_UPDATE_START,
} from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case NOTIFICATIONS_PREFERENCES_UPDATE_FAILURE:
      return {
        error: action.payload,
        errorType: action.meta.errorType,
      };
    case NOTIFICATIONS_PREFERENCES_UPDATE_START:
    case NOTIFICATIONS_PREFERENCES_UPDATE_SUCCESS:
      return null;
    default:
      return state;
  }
};
