import { connect } from 'react-redux';
import ConfirmPayment from './ConfirmPayment';
import { clearPaymentState } from '../../Redux/actions/SharedActions';
import {
  clearBankAccountForm,
  clearPaymentFlowType,
} from '../../Redux/actions/BankAccountFormActions';
import { getAccountDataFromForm } from '../../Utils/FormUtils';

const mapStateToProps = (state) => {
  const {
    bankAccountFormReducer: bankAccountForm,
    bankAccountTransactionReducer: bankAccountTransaction,
  } = state.payments;
  const { form, accountCountryCode } = bankAccountForm;
  const {
    notes,
    cpsAccount,
    isRecurring,
    achAccountType,
    bankAccountType,
    discountedAmount,
    scheduledPayment,
    savedPaymentMethod,
    recurringEndDate,
    recurringPaymentFrequency,
  } = form;
  const { bankAccountValue } = getAccountDataFromForm({
    form,
    accountCountryCode,
  });
  const selectedInvoices =
    state.router.location.state && state.router.location.state.invoices
      ? state.router.location.state.invoices
      : [];

  let bankAccountLastThree;
  if (bankAccountValue) {
    bankAccountLastThree = bankAccountValue;
  } else if (savedPaymentMethod) {
    bankAccountLastThree = savedPaymentMethod.accountNumberLastThree;
  }

  return {
    notes,
    isRecurring,
    achAccountType,
    bankAccountType,
    discountedAmount,
    scheduledPayment,
    selectedInvoices,
    account: cpsAccount,
    bankAccountLastThree,
    amount: bankAccountTransaction.amountEntered,
    recurringEndDate,
    recurringPaymentFrequency,
    flowType: bankAccountForm.flowType,
    dateInitiated: bankAccountTransaction.dateInitiated,
    confirmationNumber: bankAccountTransaction.paymentResponse
      ? bankAccountTransaction.paymentResponse.cxhTransactionId
      : null,
    savedPaymentMethod,
  };
};

const mapDispatchToProps = {
  clearPaymentState,
  clearPaymentFlowType,
  clearPaymentForm: clearBankAccountForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPayment);
