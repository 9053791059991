import * as notificationsService from '../../../notificationsService';
import updatePreferenceFailure from './updatePreferenceFailure';
import updatePreferenceSuccess from './updatePreferenceSuccess';

export default (changes) => (dispatch) => {
  return notificationsService
    .putPreference(changes)
    .then((response) => dispatch(updatePreferenceSuccess(response)))
    .catch((error) => dispatch(updatePreferenceFailure(error)));
};
