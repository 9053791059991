import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { HEADER_LOGO, MOBILE_LOGO } from 'const/logo';

const styles = {
  logo: {
    maxWidth: 150,
    display: 'inline',
  },
  titleContainer: {
    height: '2em',
    display: 'flex',
    flex: 1,
  },
  title: {
    display: 'flex',
  },
  titleText: {
    color: '#4c9e00',
    fontWeight: 600,
    fontSize: 16,
  },
  divider: {
    backgroundColor: '#858585',
    height: '1em',
    width: 2,
    margin: '0 0.3em',
    display: 'none',
  },
  '@media (min-width: 720px)': {
    titleText: {
      fontSize: 20,
    },
    logo: {
      maxWidth: 150,
      display: 'inline',
      alignSelf: 'center',
    },
  },
};

const HeaderTitle = ({
  titleText,
  subTitleText,
  path,
  classes,
  menuItems,
  ...rest
}) => (
  <div className={classes.titleContainer} {...rest}>
    <div className={classes.title}>
      <div className="logo logo--medium">
        <img
          className={classes.logo}
          src={HEADER_LOGO}
          alt="Nutrien Ag Solutions Digital Hub"
        />
      </div>
      <div className="logo logo--small">
        <img
          className={classes.logo}
          src={MOBILE_LOGO}
          alt="Nutrien Ag Solutions"
        />
      </div>
      <span className={classes.divider} />{' '}
    </div>
  </div>
);

HeaderTitle.propTypes = {
  titleText: PropTypes.string.isRequired,
  subTitleText: PropTypes.string,
  path: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  menuItems: PropTypes.node,
};

HeaderTitle.defaultProps = {
  subTitleText: '',
  path: '',
  menuItems: null,
};

export default withStyles(styles)(HeaderTitle);
