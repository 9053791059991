import { combineReducers } from 'redux';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import { SET_PRODUCT_SORT } from '../../actions/constants';

export const values = (state = [], action) => {
  if (action.type === SET_PRODUCT_SORT) {
    const nextState = get(action, 'data.values', []);
    return isArray(nextState) ? nextState : [nextState];
  }
  return state;
};

export default combineReducers({
  values,
});
