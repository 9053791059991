import {
  GTM_REORDER_TOP_PRODUCT,
  GTM_VIEW_PAST_TOP_PRODUCT_PURCHASES,
  GTM_VIEW_TOP_PRODUCT_SDS,
} from '../gtmConstants';

export const reorderTopProduct = () => ({
  event: 'CXH_ReorderTopProduct',
});

export const viewPastTopProductPurchases = () => ({
  event: 'CXH_ViewAllTopProductPurchases',
});

export const viewTopProductSDS = () => ({
  event: 'CXH_ViewTopProductSDS',
});

export const topProductsEventsMap = {
  [GTM_REORDER_TOP_PRODUCT]: reorderTopProduct,
  [GTM_VIEW_PAST_TOP_PRODUCT_PURCHASES]: viewPastTopProductPurchases,
  [GTM_VIEW_TOP_PRODUCT_SDS]: viewTopProductSDS,
};
