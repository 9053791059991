import React from 'react';
import Alert from '@nutrien/uet-lab/Alert';
import Box from '@nutrien/uet-react/Box';
import Button from '@nutrien/uet-react/Button';
import Card from '@nutrien/uet-react/Card';
import CardContent from '@nutrien/uet-react/CardContent';
import Snackbar from '@nutrien/uet-react/Snackbar';
import Typography from '@nutrien/uet-react/Typography';

import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';
import useResendEmailVerification from './useResendEmailVerification';

export default function VerifyEmail() {
  const {
    alert,
    clearAlert,
    resendVerification,
  } = useResendEmailVerification();

  return (
    <div>
      <FullPageBackground
        image={bgRegistration}
        className="full-page-background--farmers-pointing"
      />
      <Snackbar
        open={!!alert}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={5 * 1000}
        onClose={clearAlert}
      >
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
      </Snackbar>
      <Box maxWidth="500px" margin="5rem auto" width="100%">
        <Card>
          <CardContent>
            <Typography>
              You must verify your email address before accessing this site.
              Check your email; you will receive an email with a link to verify
              your email address. After clicking the link, reload this page to
              continue.
            </Typography>
            <Box width="100%" marginTop="2rem">
              <Button
                variant="contained"
                fullWidth
                onClick={resendVerification}
              >
                Resend verification
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}
