import {
  FormControlLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const NotificationsPrefencesItem = ({
  classes,
  preferenceKey,
  onPreferenceChange,
  showInboxToggle,
  showEmailToggle,
  showPhoneToggle,
  inboxValue,
  emailValue,
  phoneValue,
  formKey,
}) => {
  const handleTextToggle = () =>
    onPreferenceChange('phone', formKey, !phoneValue);
  const handleEmailToggle = () =>
    onPreferenceChange('email', formKey, !emailValue);
  const handleInboxToggle = () => {
    onPreferenceChange('inbox', formKey, inboxValue);
  };

  return (
    <ListItem id={formKey} ContainerProps={{ className: classes.container }}>
      <ListItemText
        classes={{ primary: classes.listItemText }}
        primary={preferenceKey}
      />
      <ListItemSecondaryAction className={classes.toggles}>
        {showInboxToggle ? (
          <FormControlLabel
            className={classnames({ checked: inboxValue })}
            classes={{
              label: classes.toggleLabel,
              root: classnames(classes.inboxToggle, classes.formControl),
            }}
            control={
              <Switch
                color="primary"
                className="inbox"
                checked={inboxValue}
                value="inboxValue"
                onChange={handleInboxToggle}
              />
            }
            label="Website"
          />
        ) : null}
        {showEmailToggle ? (
          <FormControlLabel
            className={classnames({ checked: emailValue })}
            classes={{
              label: classes.toggleLabel,
              root: classnames(classes.emailToggle, classes.formControl),
            }}
            control={
              <Switch
                color="primary"
                className="email"
                checked={emailValue || false}
                value="emailValue"
                onChange={handleEmailToggle}
              />
            }
            label="Email"
          />
        ) : null}
        {showPhoneToggle ? (
          <FormControlLabel
            className={classnames({ checked: phoneValue })}
            classes={{
              label: classes.toggleLabel,
              root: classes.formControl,
            }}
            control={
              <Switch
                color="primary"
                className="text"
                checked={phoneValue || false}
                value="phoneValue"
                onChange={handleTextToggle}
              />
            }
            label="Text"
          />
        ) : null}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

NotificationsPrefencesItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  formKey: PropTypes.string.isRequired,
  onPreferenceChange: PropTypes.func,
  showInboxToggle: PropTypes.bool,
  showEmailToggle: PropTypes.bool,
  showPhoneToggle: PropTypes.bool,
  inboxValue: PropTypes.bool,
  emailValue: PropTypes.bool,
  phoneValue: PropTypes.bool,
  preferenceKey: PropTypes.string.isRequired,
};

NotificationsPrefencesItem.defaultProps = {
  classes: {},
  onPreferenceChange: () => {},
  showInboxToggle: false,
  showEmailToggle: true,
  showPhoneToggle: true,
  inboxValue: true,
  emailValue: false,
  phoneValue: false,
};
export default withStyles(styles)(NotificationsPrefencesItem);
