import {
  RESET_ADD_USER_FORM,
  UPDATE_ADD_USER_FORM,
  SET_ADD_USER_FORM_ERRORS,
  CLEAR_ADD_USER_FORM_ERRORS,
} from './rbacConstants';

export const updateAddUserFormAction = (formData) => ({
  type: UPDATE_ADD_USER_FORM,
  payload: formData,
});

export const setAddUserFormErrorsAction = (errors) => ({
  type: SET_ADD_USER_FORM_ERRORS,
  payload: errors,
});

export const clearAddUserFormErrorsAction = () => ({
  type: CLEAR_ADD_USER_FORM_ERRORS,
});

export const resetAddUserFormAction = () => ({
  type: RESET_ADD_USER_FORM,
});
