import { errorStyling } from 'shared/styles';

export default {
  container: {
    margin: '1.5em 0 2.75em',
    fontSize: 13,
    fontWeight: 200,
    lineHeight: '1.3',
    textAlign: 'left',
    color: '#828282',
  },
  title: {
    fontWeight: 600,
    marginBottom: '0.75em',
    color: '#464646',
  },
  seeExamples: {
    fontWeight: 'bold',
    color: '#439301',
    cursor: 'pointer',
  },
  error: {
    ...errorStyling,
  },
};
