import React, { Component } from 'react';
import { bool, func, instanceOf, oneOfType, shape, string } from 'prop-types';
import {
  Chip,
  ChipSet,
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from 'nutrien-common-components-react/dist/mdc';
import DateRangePicker from 'shared/ui/DateRangePickerMui/DateRangePicker';
import { ALL_TIME_START_DATE } from '../../ProductHistoryConstants';

class TimeframeDialog extends Component {
  componentDidMount() {
    if (sessionStorage.productHistoryActiveTimeframe) {
      this.activeTimeFrame = sessionStorage.productHistoryActiveTimeframe;
      this.updateTimeFrame(this.activeTimeFrame);
    } else {
      this.activeTimeFrame = 'allavailable';
    }
  }

  activeTimeFrame = null;

  timeframeOptions = [
    {
      name: 'allavailable',
      label: 'All Available',
    },
    {
      name: 'thismonth',
      label: 'This Month',
    },
    {
      name: 'last30days',
      label: 'Last 30 Days',
    },
    {
      name: 'lastmonth',
      label: 'Last Month',
    },
    {
      name: 'thisyear',
      label: 'This Year',
    },
    {
      name: 'lastyear',
      label: 'Last Year',
    },
  ];

  updateTimeFrame = (name) => {
    this.activeTimeFrame = name;
    sessionStorage.setItem('productHistoryActiveTimeframe', name);

    if (name === 'custom') {
      return this.props.handleShowDatePicker();
    }

    return this.props.acceptChosenTimeframe(name);
  };

  renderDialogChips = (timeframe) => {
    const { name, label } = timeframe;
    const isActive = name === this.activeTimeFrame;

    return (
      <Chip
        id={`timeframe-selector-select-${name}`}
        key={`timeframe-${name}`}
        backgroundColor={!isActive ? 'white' : null}
        type="selected"
        data-test={`timeframe-selector-select-${name}`}
        label={`${label}`}
        onClick={() => this.updateTimeFrame(name)}
      />
    );
  };

  render() {
    const {
      dateRange,
      dialogOpen,
      handleCloseTimeframeSelectorDialog,
      handleDialogClose,
      handleRangeChange,
      handleSelectTimeFrame,
      showDatepicker,
    } = this.props;

    return (
      <Dialog
        className="product-history__timeframe"
        open={dialogOpen}
        onClosing={handleDialogClose}
      >
        <DialogTitle>Select Timeframe Filter</DialogTitle>
        <DialogContent className="product-history__timeframe-selector-content">
          <ChipSet>
            {this.timeframeOptions.map((timeframe) =>
              this.renderDialogChips(timeframe)
            )}
            <Chip
              id="timeframe-selector-select-custom"
              backgroundColor={
                this.activeTimeFrame === 'custom' ? null : 'white'
              }
              type="selected"
              data-test="timeframe-selector-select-custom"
              label="Custom"
              onClick={() => this.updateTimeFrame('custom')}
            />
          </ChipSet>

          {showDatepicker && (
            <div className="product-history__datepicker-container">
              <DateRangePicker
                dateRange={dateRange}
                onChange={handleRangeChange}
                autoOk
                disableFuture
                format="MMMM DD, YYYY"
                openTo="year"
                minDate={ALL_TIME_START_DATE}
                maxDate={new Date()}
                DialogProps={{ disableEnforceFocus: true }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <DialogButton
            data-test="close-timeframe-selector-dialog"
            onClick={handleCloseTimeframeSelectorDialog}
            secondary
            className="product-history__timeframe-selector-close"
          >
            Cancel
          </DialogButton>
          <DialogButton
            data-test="accept-timeframe-selector-dialog"
            className="product-history__timeframe-selector-accept"
            isDefaultAction
            onClick={handleSelectTimeFrame}
          >
            Accept
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }
}

TimeframeDialog.propTypes = {
  acceptChosenTimeframe: func.isRequired,
  dateRange: shape({
    start: oneOfType([string, instanceOf(Date)]).isRequired,
    end: oneOfType([string, instanceOf(Date)]).isRequired,
  }).isRequired,
  dialogOpen: bool.isRequired,
  handleCloseTimeframeSelectorDialog: func.isRequired,
  handleDialogClose: func.isRequired,
  handleRangeChange: func.isRequired,
  handleSelectTimeFrame: func.isRequired,
  handleShowDatePicker: func.isRequired,
  showDatepicker: bool.isRequired,
};

export default TimeframeDialog;
