import React from 'react';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import BillingPreferenceItem from './BillingPreferenceItem';
import { togglePreference } from './actions';
import {
  privacyPreference,
  privacyPreferences,
  accountIds,
} from './billingTabPropTypes';
import { openModal } from './ConfirmationModal';

export const generateLabelText = (preferenceObj) =>
  preferenceObj.preference.replace('Receive ', '');

export const BillingPreferenceItemContainer = ({
  preference,
  // will be implemented in later commit
  preferences,
  openModal, // eslint-disable-line no-shadow
  accountIds, // eslint-disable-line no-shadow
  togglePreference, // eslint-disable-line no-shadow
  hasBeenOpened,
}) => {
  const labelText = generateLabelText(preference);
  const labelTextKebabCase = kebabCase(labelText);
  const flags = useFlags();
  const { paperlessBillingNotificationsReviewModal } = useFlags();

  const onChange = () => {
    // the current value of the preference before being toggled
    const { enabled, preference: preferenceTitle } = preference;

    if (
      paperlessBillingNotificationsReviewModal &&
      !enabled && // the modal should only be opened when a preference is being toggled ON
      preferenceTitle === 'Receive Paperless Statement' && // also, the modal should only be opened when the preference being toggled is "new statement"
      !hasBeenOpened // and the modal only opens once per session, so if hasBeenOpened is true, jsut send API call
    ) {
      openModal(preference, accountIds);
    } else {
      togglePreference(preference, !enabled, preferences, accountIds);
    }
  };

  const key = camelCase(`billing-tab-${labelTextKebabCase}-preference`);

  return flags[key] ? (
    <BillingPreferenceItem
      labelText={labelText}
      preference={preference}
      onChange={onChange}
    />
  ) : null;
};

BillingPreferenceItemContainer.propTypes = {
  // properties passed by parent
  preference: privacyPreference.isRequired,
  preferences: privacyPreferences.isRequired,
  accountIds: accountIds.isRequired,
  hasBeenOpened: PropTypes.bool.isRequired,
  // dispatch methods
  openModal: PropTypes.func.isRequired,
  togglePreference: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  const { hasBeenOpened } = state.profile.billingTab.confirmationModal;

  return {
    preferences: state.user.userData.privacy,
    hasBeenOpened,
  };
};

export const mapDispatchToProps = {
  openModal,
  togglePreference,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingPreferenceItemContainer);
