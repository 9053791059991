import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  resetPassword,
  resetPasswordResetStatus,
} from './passwordResetActions';
import PasswordReset from './PasswordResetComponent';

const mapStateToProps = (state) => ({
  passwordReset: state.passwordReset,
});
const mapDispatchToProps = { resetPassword, resetPasswordResetStatus, push };

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
