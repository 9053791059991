import axios from 'axios';
import correlationIdInterceptor from 'shared/lib/cxhApiService/correlationIdInterceptor';
import authenticationInterceptor from 'shared/lib/cxhApiService/authenticationInterceptor';
import mirroringInterceptor from 'shared/lib/cxhApiService/mirroringInterceptor';

import resolveEcommApiUrl from './resolveEcommApiUrl';

// TODO: remove this later (we're creating a story to remove this)
const urlKeys = {
  localhost: '6oGDFukyWL50KaEN2wyJm4YdkszYI2Q2cBKdRMub',
  'my.dev.nutrienagsolutions.com': '6oGDFukyWL50KaEN2wyJm4YdkszYI2Q2cBKdRMub',
  'my.sit.nutrienagsolutions.com': 'SDUu7H5KMBasqzsshzJ5SJB3esjUuad2OfxW1Hsg',
  'my.stg.nutrienagsolutions.com': 'FNGLAtGDdk1b4cP5hUepP1msHuksRDEp9yn0fZa4',
  'my.pre.nutrienagsolutions.com': 'o8mgFVVlUn1B6CHnMvZd91CuDXzMjncz1plWL8Px',
  'my.demo.nutrienagsolutions.com': 'o8mgFVVlUn1B6CHnMvZd91CuDXzMjncz1plWL8Px',
  'my.nutrienagsolutions.com': 'XBRY2prhGf9tixkl3ISWG2Q1io2YOjwmnKEiDKKi',
};
const { hostname } = window.location;
const apiKey = urlKeys[hostname];

// TODO create centralized environment
const headers = {
  'Content-Type': 'application/json',
  'x-api-key': apiKey,
  Authorization: 'allow',
  'Cache-Control': 'no-cache',
};

const service = axios.create({
  baseURL: resolveEcommApiUrl(),
  headers,
});

// Request interceptors
service.interceptors.request.use(authenticationInterceptor);
service.interceptors.request.use(correlationIdInterceptor);
service.interceptors.request.use(mirroringInterceptor);

// axios.all not currently included axios instance methods
service.all = (requestArray) => axios.all(requestArray);

export default service;
