import apiAdapter from 'shared/lib/cxhApiService';

const getTopProductsWithinTwoMonthsLastYear = async (
  accountNumber,
  startDate,
  endDate,
  branch
) =>
  apiAdapter.get(
    `v1/accounts/${accountNumber}/top-products?start_date=${startDate}&end_date=${endDate}&count=4&shelf=chemical${
      branch ? `&branch=${branch}` : ''
    }`
  );

export default getTopProductsWithinTwoMonthsLastYear;
