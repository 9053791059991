import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FilterButton from '../FilterButton';
import InvoiceAmountDueModal from '../InvoiceAmountDueModal';

class InvoiceAmountDueFilter extends Component {
  constructor(props) {
    super(props);
    this.menuAnchor = React.createRef();

    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const {
      value,
      onConfirm,
      onClear,
      maxAmountDue,
      minAmountDue,
    } = this.props;
    const { isModalOpen } = this.state;

    return (
      <>
        <FilterButton
          onClick={() => this.setState({ isModalOpen: !isModalOpen })}
          ref={this.menuAnchor}
          value={value}
          icon="filter"
        />

        {isModalOpen && (
          <InvoiceAmountDueModal
            maxAmountDue={maxAmountDue}
            minAmountDue={minAmountDue}
            onAccept={onConfirm}
            onClear={onClear}
            initialSelection={value}
            open={isModalOpen}
            onClosed={() => {
              this.setState({ isModalOpen: false });
            }}
          />
        )}
      </>
    );
  }
}

InvoiceAmountDueFilter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onConfirm: PropTypes.func,
  onClear: PropTypes.func,
  maxAmountDue: PropTypes.number,
  minAmountDue: PropTypes.number,
};

InvoiceAmountDueFilter.defaultProps = {
  value: ['', ''],
  onConfirm: () => {},
  onClear: () => {},
  maxAmountDue: 100000,
  minAmountDue: 0,
};

export default InvoiceAmountDueFilter;
