import get from 'lodash/get';
import { GET_COUNTIES_FAILURE } from '../actionTypes';

export default (error) => {
  const err = get(error, 'message', 'There was an error getting counties');
  return {
    type: GET_COUNTIES_FAILURE,
    // TODO this should be a boolean flag, and payload should be the error, per FSA structure
    error: err,
  };
};
