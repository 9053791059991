import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.label.fontSize,
  },
  text: {
    fontWeight: theme.typography.label.fontWeight,
  },
}));

const AccountSalesOffice = ({ salesOffice }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      You&#39;re viewing&nbsp;
      <span className={classes.text}>{salesOffice}</span>
    </div>
  );
};

AccountSalesOffice.propTypes = {
  salesOffice: PropTypes.string.isRequired,
};

export default AccountSalesOffice;
