import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DeliverDate from '../../components/DeliverDate';
import * as cartActions from '../../actions/cartActions';

const mapStateToProps = ({ cart }) => ({
  cart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...cartActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeliverDate);
