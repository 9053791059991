import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { gtmViewInvoice } from 'store/middleware/TagManager/gtmActions';
import {
  deselectInvoice,
  selectAllInvoices,
  deselectAllInvoices,
  selectInvoice,
} from '../../actions';
import {
  invoices as invoicesPropType,
  selectedInvoiceIds as selectedInvoiceIdsPropType,
} from '../../invoicesPropTypes';

import { areAllPaginatedInvoicesSelected } from './invoiceTableUtils';
import WithPaginatedInvoices from '../WithPaginatedInvoices';
import InvoicesTable from './InvoicesTable';
import { INVOICE_DOWNLOAD_LIMIT } from './constants';

export class InvoicesTableContainer extends Component {
  /**
   * Solves an issue when a user selects less than 24 invoices and navigates to another page where the select
   * all checkbox is not disabled (allowing the user to potentially download almost 2 pages worth of invoices).
   * Returns an array of previously unselected invoices on the page minus the 24 invoice cap for allSelectedInvoices
   * See: https://agrium.atlassian.net/browse/COMM-10632
   * */
  generatePaginatedInvoicesWithLimit = () => {
    const { allSelectedInvoiceIds, paginatedInvoices } = this.props;

    return paginatedInvoices
      .filter((invoice) => {
        return !allSelectedInvoiceIds.some(
          (invoiceId) => invoiceId === invoice.id
        );
      })
      .slice(0, INVOICE_DOWNLOAD_LIMIT - allSelectedInvoiceIds.length);
  };

  // eslint-disable-next-line no-shadow
  selectAllInvoices = () => {
    const { selectAllInvoicesAction } = this.props;

    const paginatedInvoicesWithLimit =
      this.generatePaginatedInvoicesWithLimit();

    // This select all checkbox was circumvented to only select invoices on single page
    // See https://agrium.atlassian.net/browse/COMM-10632
    selectAllInvoicesAction(paginatedInvoicesWithLimit);
  };

  deselectAllInvoices = () => {
    const { deselectAllInvoicesAction, paginatedInvoices } = this.props;

    deselectAllInvoicesAction(paginatedInvoices);
  };

  toggleInvoice = ({ id, status, amountDue }) => {
    const {
      allSelectedInvoiceIds,
      deselectInvoiceAction,
      selectInvoiceAction,
    } = this.props;
    if (allSelectedInvoiceIds.includes(id)) {
      deselectInvoiceAction({ id, status, amountDue });
    } else {
      selectInvoiceAction({ id, status, amountDue });
    }
  };

  /**
   * Code Climate didn't like the render method being too long,
   * so, the generation of any props that aren't simply passed from container to child
   * was extracted into a helper method
   * */
  generateChildFunctionsAndProps = () => {
    const { allSelectedInvoiceIds } = this.props;

    // See code comments for generatePaginatedInvoicesWithLimit helper function
    const paginatedInvoicesWithLimit =
      this.generatePaginatedInvoicesWithLimit();

    const selectAllChecked = areAllPaginatedInvoicesSelected(
      paginatedInvoicesWithLimit,
      allSelectedInvoiceIds
    );

    return {
      selectAllChecked,
      selectAllInvoices: this.selectAllInvoices,
      deselectAllInvoices: this.deselectAllInvoices,
      toggleInvoice: this.toggleInvoice,
      unselectAllInvoices: this.unselectAllInvoices,
    };
  };

  render() {
    const {
      deselectInvoiceAction,
      selectAllInvoicesAction,
      deselectAllInvoicesAction,
      selectInvoiceAction,
      ...otherProps
    } = this.props;

    const props = this.generateChildFunctionsAndProps();
    return <InvoicesTable {...otherProps} {...props} />;
  }
}

InvoicesTableContainer.propTypes = {
  allSelectedInvoiceIds: PropTypes.arrayOf(PropTypes.string),
  currentPage: PropTypes.number,
  deselectInvoiceAction: PropTypes.func,
  gtmViewInvoiceAction: PropTypes.func,
  handlePaginationClick: PropTypes.func,
  invoices: invoicesPropType,
  isWholePageAllChecked: PropTypes.bool,
  pageSize: PropTypes.number,
  paginatedInvoices: invoicesPropType,
  selectAllInvoicesAction: PropTypes.func.isRequired,
  deselectAllInvoicesAction: PropTypes.func.isRequired,
  selectedInvoiceIds: selectedInvoiceIdsPropType,
  selectInvoiceAction: PropTypes.func,
  showColumnFilters: PropTypes.bool,
};

InvoicesTableContainer.defaultProps = {
  allSelectedInvoiceIds: [],
  currentPage: 1,
  deselectInvoiceAction: () => {},
  gtmViewInvoiceAction: () => {},
  handlePaginationClick: () => {},
  invoices: [],
  isWholePageAllChecked: false,
  pageSize: 24,
  paginatedInvoices: [],
  selectedInvoiceIds: {
    totalAmountDue: 0,
    paid: [],
    unpaid: [],
  },
  selectInvoiceAction: () => {},
  showColumnFilters: false,
};

const mapStateToProps = (state) => {
  const { selectedInvoices } = state.invoices;
  const allSelectedInvoiceIds = selectedInvoices.paid.concat(
    selectedInvoices.unpaid
  );

  return {
    allSelectedInvoiceIds,
    selectedInvoiceIds: selectedInvoices,
    allInvoices: state.invoices.list,
  };
};

const mapDispatchToProps = {
  deselectInvoiceAction: deselectInvoice,
  selectAllInvoicesAction: selectAllInvoices,
  deselectAllInvoicesAction: deselectAllInvoices,
  selectInvoiceAction: selectInvoice,
  gtmViewInvoiceAction: gtmViewInvoice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithPaginatedInvoices(InvoicesTableContainer));
