import React from 'react';

const allowedKeys = [
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Delete',
  'Backspace',
];

const useNumericInputKeyPressHandler = (WrappedComponent) => {
  const EnhancedComponent = (props) => {
    const handleKeyPress = (e) => {
      if (allowedKeys.indexOf(e.code) >= 0) {
        // eslint-disable-next-line no-empty
      } else if (e.key.search(/\d/) === -1) {
        e.preventDefault();
      } else if (e.key === '0' && !e.target.value) {
        e.preventDefault();
      }
    };

    return <WrappedComponent onKeyPress={handleKeyPress} {...props} />;
  };

  return EnhancedComponent;
};

export { useNumericInputKeyPressHandler, allowedKeys };

export default (WrappedComponent) =>
  useNumericInputKeyPressHandler(WrappedComponent);
