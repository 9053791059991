/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import errorIcon from 'assets/error.svg';
import styles from './styles';

const ErrorBanner = (props) => {
  const { preActionText, actionText, postActionText, action } = props;
  const classes = styles();
  return (
    preActionText && (
      <div className={classes.root}>
        <img className={classes.icon} src={errorIcon} alt="warning" />
        <span className={classes.message} data-test="error-banner-message">
          {preActionText}
          {action && actionText && (
            <span
              data-test="error-banner-link"
              tabIndex="0"
              role="button"
              className={classes.link}
              onClick={action}
              onKeyPress={action}
            >
              {actionText}
            </span>
          )}
          {postActionText}
        </span>
      </div>
    )
  );
};

ErrorBanner.propTypes = {
  preActionText: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  postActionText: PropTypes.string,
  action: PropTypes.func,
};

ErrorBanner.defaultProps = {
  actionText: '',
  postActionText: '',
  action: undefined,
};

export default ErrorBanner;
