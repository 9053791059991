import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import chevron from 'assets/chevron--right.svg';
import satisfiedIcon from 'assets/criteria-check--satisfied.svg';
import unsatisfied from 'assets/criteria-check--unsatisfied.svg';

const styles = {
  field: {
    margin: ' 0.25em 0',
  },
  icon: {
    height: 16,
    marginRight: '0.5em',
  },
};

const PasswordCriterion = ({
  altText,
  children,
  classes,
  description,
  formSubmitted,
  isCriteriaSatisfied,
}) => {
  const unsatifiedIcon = formSubmitted ? unsatisfied : chevron;

  return (
    <div className={classes.field}>
      <img
        alt={altText}
        className={classes.icon}
        src={isCriteriaSatisfied ? satisfiedIcon : unsatifiedIcon}
      />
      {description && ` ${description}`}
      {children}
    </div>
  );
};

PasswordCriterion.propTypes = {
  altText: PropTypes.string.isRequired,
  // used for text that needs HTML components (such as a link or a span that wraps bold font)
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
  description: PropTypes.string,
  formSubmitted: PropTypes.bool,
  isCriteriaSatisfied: PropTypes.bool.isRequired,
};

PasswordCriterion.defaultProps = {
  classes: {},
  children: null,
  description: '',
  formSubmitted: false,
};

export default withStyles(styles)(PasswordCriterion);
