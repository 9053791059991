import React from 'react';

import Notification from '@nutrien/uet-react/Notification';
import PropTypes from 'prop-types';
import useStyles from './styles';

const InvalidPaymentWarning = ({ warning, testId }) => {
  const { invalidPaymentWarning } = useStyles();

  return (
    <Notification
      variant="warning"
      data-test={testId}
      className={invalidPaymentWarning}
    >
      {warning}
    </Notification>
  );
};

InvalidPaymentWarning.propTypes = {
  warning: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

InvalidPaymentWarning.defaultProps = {
  testId: '',
}

export default InvalidPaymentWarning;