import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gtmToggleInvoiceFilter } from 'store/middleware/TagManager/gtmActions';
import PropTypes from 'prop-types';
import {
  propType as invoiceFiltersPropType,
  defaultProp as invoiceFiltersDefaultProp,
} from '../../propTypes/invoiceFiltersPropType';
import { formatDateRange } from '../shared/utils/filterHelpers';
import DueDateRangeFilter from './DueDateRangeFilter';
import withInvoiceFilters from '../withInvoiceFilters';

const filterKey = 'dueDate';

export class DueDateRangeFilterContainer extends Component {
  handleConfirmDateFilter = (dateRange) => {
    const filter = formatDateRange(dateRange, 'M/D/YYYY');

    this.props.updateInvoiceFilters({
      [filterKey]: filter,
    });

    if (this.props.currentPage > 1) this.props.handlePaginationClick(1);
  };

  handleClearDateFilter = () => {
    this.props.updateInvoiceFilters({ [filterKey]: [] });
  };

  render() {
    const { invoiceFilters, dispatchGtmToggleInvoiceFilter } = this.props;
    const value = invoiceFilters[filterKey] || 'All';
    return (
      <DueDateRangeFilter
        value={value}
        onConfirm={this.handleConfirmDateFilter}
        onClear={this.handleClearDateFilter}
        gtmToggleInvoiceFilter={dispatchGtmToggleInvoiceFilter}
      />
    );
  }
}

DueDateRangeFilterContainer.propTypes = {
  invoiceFilters: invoiceFiltersPropType,
  updateInvoiceFilters: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  handlePaginationClick: PropTypes.func.isRequired,
  dispatchGtmToggleInvoiceFilter: PropTypes.func.isRequired,
};

DueDateRangeFilterContainer.defaultProps = {
  invoiceFilters: invoiceFiltersDefaultProp,
  currentPage: 1,
};
const mapDispatchToProps = {
  dispatchGtmToggleInvoiceFilter: gtmToggleInvoiceFilter,
};

export default connect(
  null,
  mapDispatchToProps
)(withInvoiceFilters(DueDateRangeFilterContainer));
