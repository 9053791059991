const sortCustomersCompareFunc = (customerA, customerB) => {
  if (
    `${customerA.firstName} ${customerA.lastName}` >
    `${customerB.firstName} ${customerB.lastName}`
  )
    return 1;
  if (
    `${customerA.firstName} ${customerA.lastName}` <
    `${customerB.firstName} ${customerB.lastName}`
  )
    return -1;
  return 0;
};

export default sortCustomersCompareFunc;
