import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'nutrien-common-components-react/dist/mdc';

import { statusMap } from './constants';
import { INVOICE_STATUSES } from '../../actions';

import './invoiceStatusLabel.scss';

const InvoiceStatusLabel = ({ status }) => {
  const { icon, className } = statusMap(status);

  return (
    <span className={className}>
      <Icon icon={icon} />
      {status === INVOICE_STATUSES.PENDING ||
      status === INVOICE_STATUSES.SCHEDULED ? (
        <span className="pending-invoice-text">{status}</span>
      ) : (
        status
      )}
    </span>
  );
};

InvoiceStatusLabel.propTypes = {
  status: PropTypes.string,
};

InvoiceStatusLabel.defaultProps = {
  status: '',
};

export default InvoiceStatusLabel;
