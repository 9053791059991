import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import commonStyles from 'components/Payments/commonPaymentStyles';
import Button from '@nutrien/uet-react/Button';
import PaymentSubHeader from 'components/Payments/OtherComponents/PaymentSubHeader';
import { FullPage, PageNotification } from 'shared/ui';
import WarningIcon from '@material-ui/icons/Warning';
import { NOTIFICATION_TYPES } from 'shared/ui/NotificationHelper';
import ReviewErrorImage from 'assets/review-error-500.svg';
import { ROUTES_MAP } from 'shared/ui/Payments/PaymentHeader/constants';
import PageHeader from 'shared/ui/PageHeader/PageHeader';
import AccountInformation from '../MakeAPayment/SubComponents/AccountInformation';
import BillingInformation from '../MakeAPayment/SubComponents/BillingInformation';
import AddPaymentFormComponent from '../../OtherComponents/PaymentForm/PaymentFormComponent';
import CountryPickerComponent from '../../OtherComponents/CountryPicker/CountryPickerComponent';
import { validateFormField as validateFormFieldUtil } from '../MakeAPayment/Validations/PaymentValidator';
import * as PaymentPropTypes from '../../PaymentPropTypes';
import { errorTitleText } from '../../PaymentConstants';
import {
  genericErrorBody,
  genericErrorTitle,
  serviceUnavailableErrorMessage,
  tokenAlreadySavedText,
} from '../MakeAPayment/MakeAPaymentConstants';
import PaymentProcessingOverlay from '../../OtherComponents/PaymentProcessingOverlay/PaymentProcessingOverlay';
import PaymentMethodAlias from '../../OtherComponents/PaymentMethodAlias';
import ModalComponent from '../../OtherComponents/ModalComponent/ModalComponent';
import {
  getAccountCountryCode,
  getCountryFromCode,
  overPaymentAccountLimit,
} from '../../Utils/PaymentUtil';
import { ERROR_NAMES, ERROR_TYPES } from '../../Redux/actions/ErrorActions';

import styles from './styles';

const finalStyles = (theme) => merge({}, commonStyles(theme), styles);

// TODO (CXH-3507): this component is cumbersome and large, break it down into smaller pieces.
// anything that is conditionally rendered in a function can be moved into its own sub component
class AddBankAccount extends Component {
  constructor(props) {
    super(props);

    const { accounts } = props;

    this.state = {
      hideCountrySelector: true,
    };

    const countryCode = getAccountCountryCode(accounts);

    if (!countryCode) {
      this.state.hideCountrySelector = false;
    }
  }

  componentDidMount() {
    const {
      clearShowFormErrorsNotification,
      clearPaymentForm,
      setAccountCountryCode,
      accounts,
      updatePaymentForm,
      user,
      getPaymentAccounts,
    } = this.props;

    clearPaymentForm();
    clearShowFormErrorsNotification();

    const hasUser = user && user.userDetails && user.userDetails.associatedOrgs;

    if (accounts) {
      const countryCode = getAccountCountryCode(accounts);
      const country = getCountryFromCode(countryCode);

      setAccountCountryCode(countryCode);

      if (hasUser) {
        const associatedOrgIds = user.userDetails.associatedOrgs.map(
          (org) => org.id
        );

        if (countryCode) {
          getPaymentAccounts(associatedOrgIds, countryCode);
        } else {
          getPaymentAccounts(associatedOrgIds);
        }
      }

      updatePaymentForm({
        country,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      user: newUser,
      accounts: newAccounts,
      paymentError: newPaymentError,
      accountCountryCode: newAccountCountryCode,
    } = this.props;
    const { user, accounts, paymentError, accountCountryCode } = prevProps;
    const hasReceivedUser =
      newUser &&
      newUser !== user &&
      newUser.userDetails &&
      newUser.userDetails.associatedOrgs &&
      newUser.userDetails.associatedOrgs.length;

    const hasReceivedAccounts = newAccounts && newAccounts !== accounts;
    const hasReceivedError =
      newPaymentError && newPaymentError !== paymentError;
    const accountCountryCodeChanged =
      newAccountCountryCode && newAccountCountryCode !== accountCountryCode;

    if (hasReceivedError) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    if (accountCountryCodeChanged) {
      this.scrollToSubHeader();
    }

    if (hasReceivedUser) {
      this.getAccounts(prevProps, this.props);
    }

    if (hasReceivedAccounts) {
      this.updateAccountCountryCode(prevProps, this.props);
    }
  }

  componentWillUnmount() {
    const {
      clearShowFormErrorsNotification,
      clearBankName,
      clearPaymentError,
    } = this.props;

    clearBankName();
    clearPaymentError();
    clearShowFormErrorsNotification();
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { updatePaymentForm, location, submitPaymentForm } = this.props;

    updatePaymentForm({
      savedPaymentMethod: 'newBankAccount',
      savePaymentMethod: true,
    });

    submitPaymentForm(location);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  getAccounts = (prevProps, props) => {
    const { getAccounts } = this.props;
    const { getPaymentAccounts } = prevProps;
    const { user: newUser, formValues, accounts: newAccounts } = props;
    const associatedOrgIds = newUser.userDetails.associatedOrgs.map(
      (org) => org.id
    );

    if (formValues.cpsAccount) {
      getPaymentAccounts(associatedOrgIds, formValues.cpsAccount.locationCode);
    }

    if (newAccounts.length === 0) {
      getAccounts(newUser.userDetails.associatedOrgs);
    }
  };

  setCountrySelector = (countrySelector) => {
    this.setState({ hideCountrySelector: countrySelector });
  };

  updateAccountCountryCode = (prevProps, props) => {
    const { updatePaymentForm, setAccountCountryCode } = prevProps;
    const { formValues, accounts: newAccounts } = props;

    const accountCountryCode = formValues.cpsAccount
      ? formValues.cpsAccount.locationCode
      : getAccountCountryCode(newAccounts);
    const country = getCountryFromCode(accountCountryCode);

    setAccountCountryCode(accountCountryCode);

    updatePaymentForm({
      country,
    });
  };

  handleChange = (event) => {
    const { updatePaymentForm } = this.props;
    const { name, value } = event.target;

    updatePaymentForm({
      [name]: value,
    });
  };

  clearHiddenFormErrors = () => {
    const currentState = { ...this.props.formErrors };

    currentState.firstName = [];
    currentState.lastName = [];
    currentState.corporateName = [];

    this.props.setPaymentFormErrors(currentState);
  };

  validateFormField = ({ field, fieldData }) => {
    const {
      formValues,
      formErrors,
      formWarnings,
      setPaymentFormErrors,
    } = this.props;
    const newState = validateFormFieldUtil({
      field,
      fieldData,
      currentState: {
        form: formValues,
        formWarnings,
        formErrors,
      },
    });

    setPaymentFormErrors(newState.formErrors);
  };

  scrollToSubHeader = () => {
    setTimeout(() => {
      const bankAccountInformationTitle = document.getElementById(
        'bank-account-info'
      );

      const subheaderSection = document.getElementById(
        'payment-subheader-section'
      );

      if (subheaderSection) {
        const totalOffsetHeight =
          bankAccountInformationTitle.offsetHeight -
          subheaderSection.offsetHeight;

        window.scrollTo({
          top: totalOffsetHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  conditionallyRenderOverlay() {
    const { isLoading } = this.props;

    if (isLoading) {
      return (
        <PaymentProcessingOverlay processingText="Verifying Information..." />
      );
    }
    return null;
  }

  renderOverMaxAccountsError = (paymentAccounts) => {
    const { classes } = this.props;

    return overPaymentAccountLimit(paymentAccounts) ? (
      <div className={classes.overMaxPaymentAccounts}>
        <WarningIcon
          style={{ color: '#f13b39' }}
          className={classes.warningIcon}
        />
        <div className={classes.maxLimitText}>
          You have reached the limit of 15 stored accounts. Delete at least one
          of the&nbsp;
          <Link to="/manage-payments">stored bank accounts</Link> in order to
          add a new one.
        </div>
      </div>
    ) : null;
  };

  renderHeaderSection = () => {
    let jsx;
    const {
      accountCountryCode,
      classes,
      setAccountCountryCode,
      updatePaymentForm,
      paymentAccounts,
      isLoading,
    } = this.props;

    const hasMaxPaymentAccounts =
      paymentAccounts.length && paymentAccounts.length >= 15;

    if (!accountCountryCode && !isLoading) {
      jsx = (
        <div>
          {this.renderOverMaxAccountsError(paymentAccounts)}
          <CountryPickerComponent
            accountCountryCode={accountCountryCode}
            updatePaymentForm={updatePaymentForm}
            setCountry={setAccountCountryCode}
          />
        </div>
      );
    } else if (
      !isLoading &&
      accountCountryCode &&
      !this.state.hideCountrySelector
    ) {
      jsx = (
        <div>
          {this.renderOverMaxAccountsError(paymentAccounts)}
          <CountryPickerComponent
            accountCountryCode={accountCountryCode}
            updatePaymentForm={updatePaymentForm}
            setCountry={setAccountCountryCode}
          />

          <div id="payment-subheader-section">
            <div className={classes.addNewBankAccountSubTitle}>
              Bank Account Information
            </div>

            <PaymentSubHeader />
          </div>
        </div>
      );
    } else {
      jsx = (
        <div id="payment-subheader-section">
          <div className={classes.addNewBankAccountSubTitle}>
            Bank Account Information
          </div>

          {!!hasMaxPaymentAccounts && (
            <div className={classes.errorSeparator} />
          )}

          {this.renderOverMaxAccountsError(paymentAccounts)}

          {!hasMaxPaymentAccounts && <PaymentSubHeader />}
        </div>
      );
    }

    return jsx;
  };

  renderErrorNotification = () => {
    const { paymentError } = this.props;
    const errorNotificationMessage =
      'There were errors with the information you provided. Please correct these issues and re-submit.';
    const tokenAlreadySaved =
      paymentError && paymentError.name === ERROR_NAMES.TOKEN_ALREADY_SAVED;
    const notificationMessage = tokenAlreadySaved
      ? tokenAlreadySavedText
      : errorNotificationMessage;

    return (
      <PageNotification
        type={NOTIFICATION_TYPES.DANGER}
        message={notificationMessage}
        mobileMessage={notificationMessage}
        className="page-notification__no-padding"
      />
    );
  };

  render() {
    const formName = 'add-payment-form';
    const {
      classes,
      formValues,
      formErrors,
      bankName,
      clearBankName,
      getBankName,
      history,
      routingNumberError,
      paymentError,
      showFormErrors,
      clearPaymentError,
      accountCountryCode,
    } = this.props;

    return (
      <div>
        <ModalComponent
          image={ReviewErrorImage}
          open={
            !!(paymentError && paymentError.errorType === ERROR_TYPES.MODAL)
          }
          close={clearPaymentError}
          titleText={
            paymentError &&
            paymentError.name === ERROR_NAMES.SERVICE_UNAVAILABLE
              ? errorTitleText
              : genericErrorTitle
          }
          bodyText1={
            paymentError &&
            paymentError.name === ERROR_NAMES.SERVICE_UNAVAILABLE
              ? serviceUnavailableErrorMessage
              : genericErrorBody
          }
        />
        <FullPage noCard>
          {/* use classnames here instead of manually computing class string */}
          <div
            className={`${classes.addPaymentHeaderContainer} ${
              this.state.hideCountrySelector ? '' : classes.withSelector
            }`}
          >
            <PageHeader
              title="Add New Bank Account"
              breadcrumbs={ROUTES_MAP.ADD_BANK_ACCOUNT_ROUTES}
            />
            {this.renderHeaderSection()}
            {showFormErrors || paymentError
              ? this.renderErrorNotification()
              : null}
          </div>

          <div id="bank-account-info" />

          {!accountCountryCode ? null : (
            <AddPaymentFormComponent onSubmit={this.onSubmit}>
              <AccountInformation
                bankName={bankName}
                clearHidden={this.clearHiddenFormErrors}
                clearBankName={clearBankName}
                errors={formErrors}
                formName={formName}
                getBankName={getBankName}
                form={formValues}
                onChange={this.handleChange}
                routingNumberError={routingNumberError}
                validate={this.validateFormField}
                countryCode={accountCountryCode}
              />
              <BillingInformation
                errors={formErrors}
                addressLine1={formValues.addressLine1}
                addressLine2={formValues.addressLine2}
                city={formValues.city}
                zip={formValues.zip}
                phone={formValues.phone}
                onChange={this.handleChange}
                selectedCountry={formValues.country}
                selectedState={formValues.state}
                validate={this.validateFormField}
              />
              <PaymentMethodAlias
                errors={formErrors}
                formName={formName}
                paymentMethodAlias={formValues.paymentMethodAlias}
                onChange={this.handleChange}
                validate={this.validateFormField}
              />
              <div className={classes.addBankAccountFooterContainer}>
                <Button
                  onClick={() => history.push('/profile/billing')}
                  name="cancelButton"
                  variant="outlined"
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  id="submit-button"
                  type="submit"
                  variant="contained"
                  className={classes.addAccountButton}
                  name="submitButton"
                >
                  Add Account
                </Button>
              </div>
            </AddPaymentFormComponent>
          )}

          {this.conditionallyRenderOverlay()}
        </FullPage>
      </div>
    );
  }
}

AddBankAccount.propTypes = {
  formValues: PaymentPropTypes.paymentForm.isRequired,
  formErrors: PaymentPropTypes.paymentFormErrors.isRequired,
  formWarnings: PaymentPropTypes.paymentFormWarnings.isRequired,
  classes: PropTypes.shape({
    overMaxPaymentAccounts: PropTypes.string,
    warningIcon: PropTypes.string,
    maxLimitText: PropTypes.string,
    addNewBankAccountSubTitle: PropTypes.string,
    errorSeparator: PropTypes.string,
    addPaymentHeaderContainer: PropTypes.string,
    withSelector: PropTypes.string,
    addBankAccountFooterContainer: PropTypes.string,
    cancelButton: PropTypes.string,
    addAccountButton: PropTypes.string,
  }).isRequired,
  updatePaymentForm: PropTypes.func.isRequired,
  bankName: PropTypes.string,
  getBankName: PropTypes.func.isRequired,
  clearBankName: PropTypes.func.isRequired,
  routingNumberError: PropTypes.bool,
  submitPaymentForm: PropTypes.func.isRequired,
  history: PaymentPropTypes.history,
  setPaymentFormErrors: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  accountCountryCode: PropTypes.string,
  clearPaymentForm: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  setAccountCountryCode: PropTypes.func.isRequired,
  accounts: PaymentPropTypes.accounts,
  paymentAccounts: PaymentPropTypes.paymentAccounts,
  user: PaymentPropTypes.user,
  getPaymentAccounts: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  paymentError: PropTypes.shape({
    statusCode: PropTypes.number,
    name: PropTypes.string,
    message: PropTypes.string,
    errorType: PropTypes.string,
  }),
  showFormErrors: PropTypes.bool.isRequired,
  clearShowFormErrorsNotification: PropTypes.func.isRequired,
  clearPaymentError: PropTypes.func.isRequired,
};

AddBankAccount.defaultProps = {
  routingNumberError: false,
  paymentError: null,
  accounts: [],
  paymentAccounts: [],
  bankName: '',
  history: {},
  user: {},
  accountCountryCode: '',
};

export default withStyles(finalStyles)(AddBankAccount);
