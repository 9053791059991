import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import './MirrorUserLink.css';

const MirrorUserLink = ({ customer, ...rest }) => (
  <Link
    role="button"
    className="mirror-user-link"
    tabIndex="0"
    to={`/employee/mirroring?userId=${customer.id || customer.eid}`}
    {...rest}
  >
    Mirror User »
  </Link>
);

MirrorUserLink.propTypes = {
  customer: PropTypes.shape({}).isRequired,
};

export default MirrorUserLink;
