import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Card from '@nutrien/uet-react/Card';
import { Icon } from 'nutrien-common-components-react/dist/mdc';
import { Link } from 'react-router-dom';
import { formatMMMDYYYY } from 'shared/utils/DateHelper';

import './OrderCard.scss';

export class OrderCard extends Component {
  renderIcon = (orderStatus) => {
    switch (orderStatus.toLowerCase()) {
      case 'submitted':
        return <Icon icon="progresscircleonequarter" type="success" />;
      case 'confirmed':
        return <Icon icon="progresscircleonehalf" type="success" />;
      case 'fulfilled':
        return <Icon icon="progresscirclethreequarters" type="success" />;
      case 'invoiced':
        return <Icon icon="success" type="success" />;
      case 'cancelled':
        return <Icon icon="cancelled" type="danger" />;
      default:
        return <Icon icon="info" type="success" />;
    }
  };

  render() {
    const {
      orderNumber,
      orderStatus,
      orderDate,
      products,
    } = this.props.order.attributes;
    const productName = get(products, '[0].productName', 'No products');
    const numProducts = products.length;

    // Only show 1 card in mobile. Use orders arrays index
    // to determine when to apply display:none style and
    // when to apply margin bottom in mobile.
    const { orderArrayIndex, gtmOnlineOrdersClick } = this.props;
    let mobileDisplayClassname = '';
    if (orderArrayIndex !== 0) {
      mobileDisplayClassname = 'order-card__mobile--no-display';
    } else {
      mobileDisplayClassname = 'order-card__mobile';
    }

    return (
      <Card
        className={`order-card__container ${mobileDisplayClassname}`}
        data-test="order-card"
      >
        <Link
          to={`/orders/${orderNumber}`}
          onClick={() => gtmOnlineOrdersClick('Order Number Link')}
          className="order-card-wrapper"
          data-test="order-card-wrapper"
        >
          <div className="order-card__header-wrapper">
            <div className="order-card__order-number">
              Order #
              <span className="order-card__order-number-value">
                {orderNumber}
              </span>
            </div>

            <div className="order-card__status-wrapper">
              <div className="order-card__status-icon">
                {this.renderIcon(orderStatus)}
              </div>
              <div className="order-card__status">
                STATUS
                <br />
                <span className="order-card__status-value">{orderStatus}</span>
              </div>
            </div>
          </div>
          <div className="order-card__content">
            <div className="order-card__order-date">
              Order placed {formatMMMDYYYY(orderDate)}
            </div>

            <div className="order-card__product-name">{productName}</div>

            <span className="order-card__product-count">
              {numProducts === 1 ? '1 item' : `${numProducts} items`}
            </span>
          </div>
        </Link>
      </Card>
    );
  }
}

OrderCard.propTypes = {
  order: PropTypes.shape({
    attributes: PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      orderStatus: PropTypes.string.isRequired,
      orderDate: PropTypes.string.isRequired,
      products: PropTypes.array.isRequired,
    }),
  }).isRequired,
  orderArrayIndex: PropTypes.number.isRequired,
  gtmOnlineOrdersClick: PropTypes.func.isRequired,
};

export default OrderCard;
