import React from 'react';
import Button from '@nutrien/uet-react/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import resolveQuickPayUrl from '../../../../shared/config/resolveQuickPayUrl';

const QuickPayButton = () => {
  const { quickPayHubRedirectButtonEnabled } = useFlags();

  const quickPayUrl = resolveQuickPayUrl();

  return (
    <>
      {quickPayHubRedirectButtonEnabled && (
        <Button
          variant="outlined"
          component="a"
          href={quickPayUrl}
          style={{ width: '100%' }}
        >
          Quick Pay
        </Button>
      )}
    </>
  );
};

export default QuickPayButton;
