import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import FormLabel from '@nutrien/uet-react/FormLabel';
import Button from '@nutrien/uet-react/Button';
import Box from '@nutrien/uet-react/Box';
import Checkbox from '@nutrien/uet-react/Checkbox';
import Grid from '@nutrien/uet-react/Grid';
import FormControlLabel from '@nutrien/uet-react/FormControlLabel';
import FormControl from '@nutrien/uet-react/FormControl';
import MenuItem from '@nutrien/uet-react/MenuItem';
import Select from '@nutrien/uet-react/Select';
import TextField from '@nutrien/uet-react/TextField';
import makeStyles from '@nutrien/uet-react/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  STREET_ADDRESS_REQUIRED,
  CITY_REQUIRED,
  ZIP_REQUIRED,
  ZIP_NOT_VALID,
  COUNTY_REQUIRED,
  STATE_REQUIRED,
} from 'const/addressErrorConstants';
import { validCanadianOrUSZipCode } from 'utils/zipCodeValidation';
import { STATES_AND_PROVINCES } from 'const/statesAndProvinces';
import { gtmDoNotSellInfo } from 'store/middleware/TagManager/gtmActions';
import SnackbarNotification from 'shared/ui/SnackbarNotification/SnackbarNotification';
import { submitRequest } from '../doNotSellService';
import ConfirmationDialog from '../ConfirmationDialog';

const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;
const PHONE_NUMBER_NOT_VALID =
  'Please enter a phone number in the format 000-000-0000';

const doNotSellSchema = Yup.object().shape({
  addressLine1: Yup.string()
    .max(255, 'Please enter just the first 255 characters of your address')
    .required(STREET_ADDRESS_REQUIRED),
  addressLine2: Yup.string().max(
    255,
    'Please enter just the first 255 characters of your address'
  ),
  city: Yup.string()
    .max(255, 'Please enter just the first 255 characters of your city')
    .required(CITY_REQUIRED),
  country: Yup.string().required(COUNTY_REQUIRED),
  dontSellPersonalInformation: Yup.boolean(),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Your email is required'),
  firstName: Yup.string()
    .max(255, 'Please enter just the first 255 characters of your name')
    .required(FIRST_NAME_REQUIRED),
  lastName: Yup.string()
    .max(255, 'Please enter just the first 255 characters of your name')
    .required(LAST_NAME_REQUIRED),
  phoneNumber: Yup.string()
    .matches(phoneNumberRegex, PHONE_NUMBER_NOT_VALID)
    .required('Your phone number is required'),
  stateOrProvince: Yup.string().required(STATE_REQUIRED),
  zipCode: Yup.string()
    .matches(validCanadianOrUSZipCode, ZIP_NOT_VALID)
    .required(ZIP_REQUIRED),
});

const USA = 'United States';
const CANADA = 'Canada';

const CALIFORNIA = 'California';

const countryValueToStatesKey = {
  [USA]: 'USA',
  [CANADA]: 'CAN',
};

const countryValueToApiValue = {
  [USA]: 'US',
  [CANADA]: 'CAN',
};

const initialValues = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: USA,
  dontSellPersonalInformation: false,
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  stateOrProvince: CALIFORNIA,
  zipCode: '',
};

const useStyles = makeStyles((theme) => ({
  state: {
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
    [theme.breakpoints.down('md')]: {
      width: 150,
    },
  },
  gridItem: {
    height: 100,
  },
}));

export default () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(
    false
  );
  const [errorVisible, setErrorVisible] = useState(false);

  const callSubmitRequest = async ({ country, ...rest }) => {
    try {
      const requestData = {
        ...rest,
        country: countryValueToApiValue[country],
      };
      await submitRequest(requestData);
      setConfirmationDialogIsOpen(true);
      dispatch(gtmDoNotSellInfo());
    } catch (e) {
      setErrorVisible(true);
    }
  };

  return (
    <>
      <ConfirmationDialog
        open={confirmationDialogIsOpen}
        onClose={() => push('/')}
      />
      <SnackbarNotification
        message="There was an error submitting your request. Please try again, and contact technical support if the problem persists."
        onClose={() => setErrorVisible(false)}
        open={errorVisible}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={doNotSellSchema}
        validateOnMount
        validateOnBlur
        onSubmit={async (values) => {
          setIsSubmitting(true);
          await callSubmitRequest(values);
          setIsSubmitting(false);
        }}
      >
        {({ isValid, setValues, touched: touchedFields, values }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item>
                  <Field name="dontSellPersonalInformation">
                    {({ field }) => (
                      <FormControlLabel
                        control={<Checkbox />}
                        label="NO, please do not sell my personal information."
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container item spacing={1}>
                  <Grid className={classes.gridItem} item md={12} xs={12}>
                    <Field name="country">
                      {({
                        field: { onChange, ...rest },
                        meta: { error, touched },
                      }) => (
                        <>
                          <FormControl required>
                            <FormLabel component="legend">Country</FormLabel>
                            <Select
                              required
                              error={Boolean(touched && error)}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                onChange(value);
                                if (value === USA) {
                                  setValues({
                                    ...values,
                                    country: value,
                                    stateOrProvince: CALIFORNIA,
                                  });
                                } else {
                                  setValues({
                                    ...values,
                                    country: value,
                                    // default to the first Canadian province in the list.
                                    stateOrProvince:
                                      STATES_AND_PROVINCES[
                                        countryValueToStatesKey[CANADA]
                                      ][0].name,
                                  });
                                }
                              }}
                              {...rest}
                            >
                              <MenuItem value={CANADA}>{CANADA}</MenuItem>
                              <MenuItem value={USA}>{USA}</MenuItem>
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={12}>
                  <Field name="firstName">
                    {({ field, meta: { error, touched } }) => (
                      <TextField
                        label="First Name"
                        required
                        error={Boolean(touched && error)}
                        helperText={error}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={12}>
                  <Field name="lastName">
                    {({ field, meta: { error, touched } }) => (
                      <TextField
                        label="Last Name"
                        required
                        error={Boolean(touched && error)}
                        helperText={error}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={12}>
                  <Field name="addressLine1">
                    {({ field, meta: { error, touched } }) => (
                      <TextField
                        label="Address Line 1"
                        required
                        error={Boolean(touched && error)}
                        helperText={error}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={12}>
                  <Field name="addressLine2">
                    {({ field, meta: { error, touched } }) => (
                      <TextField
                        label="Address Line 2"
                        error={Boolean(touched && error)}
                        helperText={error}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={12}>
                  <Field name="city">
                    {({ field, meta: { error, touched } }) => (
                      <TextField
                        label="City"
                        required
                        error={Boolean(touched && error)}
                        helperText={error}
                        {...field}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={12}>
                  <Box display="flex" flex={1} flexDirection="row">
                    <Field name="stateOrProvince">
                      {({ field, meta: { error, touched } }) => (
                        <Box mr={2}>
                          <FormControl className={classes.state} required>
                            <FormLabel component="legend">
                              State/Province
                            </FormLabel>
                            <Select
                              required
                              error={Boolean(touched && error)}
                              disabled={!values.country}
                              {...field}
                            >
                              {STATES_AND_PROVINCES[
                                countryValueToStatesKey[values.country]
                              ]?.map(({ name }) => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                    </Field>
                    <Field name="zipCode">
                      {({ field, meta: { error, touched } }) => (
                        <TextField
                          label="Zip Code"
                          type="string"
                          required
                          error={Boolean(touched && error)}
                          helperText={error}
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={12}>
                  <Field name="email">
                    {({ field, meta: { error, touched } }) => (
                      <TextField
                        type="email"
                        label="Email Address"
                        required
                        error={Boolean(touched && error)}
                        {...field}
                        helperText={error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={12}>
                  <Field name="phoneNumber">
                    {({ field, meta: { error, touched } }) => (
                      <TextField
                        label="Phone Number"
                        required
                        error={Boolean(touched && error)}
                        {...field}
                        helperText={error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        !values.dontSellPersonalInformation ||
                        isEmpty(touchedFields)
                      }
                      size="large"
                    >
                      Submit Request
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
