import React from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import CustomerSwitcher from './CustomerSwitcher';
import withEmployee from '../../withEmployee';

const styles = {
  headerWrapper: {
    background: '#656565',
  },
  headerContent: {
    display: 'flex',
    height: '58px',
    margin: '0 auto',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: '600',
    maxWidth: 1280,
  },
  backLink: {
    color: 'white',
    minWidth: '80px',
    display: 'flex',
    flex: 1,
    padding: '0 20px',
    alignItems: 'center',
    '&:hover': {
      color: 'white',
    },
    '@media (max-width: 576px)': {
      paddingLeft: '10px',
    },
  },
  hideDesktop: {
    '@media (min-width: 513px)': {
      display: 'none',
    },
  },
  hideMobile: {
    '@media (max-width: 512px)': {
      display: 'none',
    },
  },
  backArrow: {
    marginRight: '2px',
  },
};

export const EmployeeMirroringHeader = ({ classes, isSupport }) => (
  <div className={classes.headerWrapper} data-test="MirroringHeader">
    <div className={classes.headerContent}>
      <NavLink className={classes.backLink} to="/employee">
        <ArrowBack className={classes.backArrow} />
        <span className={classes.hideDesktop}>BACK</span>
        <span className={classes.hideMobile}>SELECT A DIFFERENT CUSTOMER</span>
      </NavLink>
      {!isSupport && <CustomerSwitcher />}
    </div>
  </div>
);

EmployeeMirroringHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isSupport: PropTypes.bool.isRequired,
};

export default withEmployee(withStyles(styles)(EmployeeMirroringHeader));
