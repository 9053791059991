import bowser from 'bowser';
import get from 'lodash/get';

const browserIsSupported = () => {
  const browserParser = bowser.getParser(window.navigator.userAgent);
  const browserName = get(browserParser, 'parsedResult.browser.name');
  const browserVersion = get(browserParser, 'parsedResult.browser.version');
  // if we cannot determine the browser name or version
  // then allow the user to see and use the site
  // (fail on instead of failing off)
  if (browserName === undefined || browserVersion === undefined) return true;
  let isSupported = browserParser.satisfies({
    Safari: '>9',
    'Microsoft Edge': '>12',
    'Internet Explorer': '>10',
    Firefox: '>47',
    Chrome: '>57',
    Samsung: '>6.1',
  });

  if (!isSupported) {
    if (browserName === 'Amazon Silk' || browserName === 'Android Browser') {
      isSupported = true;
    }
  }

  return isSupported;
};

export default browserIsSupported;
