import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import React, { Component } from 'react';

import { getQueryStringFromRouter } from 'shared/utils/reduxSelectors';

import GeneralExperience from './GeneralExperience';
import { fetchCustomers } from '../../Customers/actions';
import { filterCustomers, sortCustomers } from '../../Customers/selectors';
import { updateCustomerFilter } from '../actions';

export class GeneralExperienceContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    fetchCustomers: PropTypes.func.isRequired,
    updateCustomerFilter: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.props.fetchCustomers();
  }

  handlePaginationClick = (page) => {
    const { push } = this.props; // eslint-disable-line no-shadow
    push(`/employee?page=${page}`);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  handleFilterChange = (filterText) => {
    const { push, updateCustomerFilter, currentPage } = this.props; // eslint-disable-line no-shadow
    if (currentPage !== 1) {
      push(`/employee?page=1`);
    }
    updateCustomerFilter(filterText);
  };

  render() {
    return (
      <GeneralExperience
        onPaginationClick={this.handlePaginationClick}
        onFilterChange={this.handleFilterChange}
        {...this.props}
      />
    );
  }
}

export const mapStateToProps = (state) => {
  const allCustomers = sortCustomers(state.employee.customers.all);
  const filteredCustomers = filterCustomers(
    allCustomers,
    state.employee.landingPage.customerFilter
  );
  return {
    customers: filteredCustomers,
    totalCustomers: allCustomers.length,
    totalFilteredCustomers: filteredCustomers.length,
    currentPage: parseInt(getQueryStringFromRouter(state.router).page || 1, 10),
    gettingCustomers: state.employee.customers.isFetching,
    error: state.employee.customers.error,
    filter: state.employee.landingPage.customerFilter,
  };
};

const mapDispatchToProps = {
  updateCustomerFilter,
  fetchCustomers,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralExperienceContainer);
