import React from 'react';
import T from 'prop-types';
import Button from '@nutrien/uet-react/Button';
import gtmGfaEventPropType from '../gtmGfaEventPropType';

const OpenPdfLink = ({ children, link, analyticsProps, ...rest }) =>
  window.ReactNativeWebView ? (
    <Button
      variant="text"
      onClick={() => {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            name: 'display_file',
            payload: {
              type: 'application/pdf',
              data: link,
              analyticsProps,
            },
          })
        );
      }}
      {...rest}
    >
      {children}
    </Button>
  ) : (
    <a href={link} rel="noopener noreferrer" target="_blank" {...rest}>
      {children}
    </a>
  );

OpenPdfLink.propTypes = {
  children: T.node.isRequired,
  link: T.string.isRequired,
  analyticsProps: gtmGfaEventPropType.isRequired,
};

export default OpenPdfLink;
