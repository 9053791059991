import { connect } from 'react-redux';
import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import AddBankAccount from './AddBankAccount';
import {
  clearBankAccountForm,
  submitACHPaymentFormAction,
  updateBankAccountForm,
  clearBankName,
  bankFromRoutingNumber,
  setBankAccountFormErrors,
  setAccountCountryCode,
} from '../../Redux/actions/BankAccountFormActions';
import { getSavedBankAccounts } from '../../Redux/actions/BankAccountActions';

import * as paymentUtils from '../../Utils/PaymentUtil';
import {
  clearPaymentError,
  clearShowFormErrorsNotification,
} from '../../Redux/actions/ErrorActions';

const mapStateToProps = (state) => {
  const {
    bankAccountReducer: bankAccount,
    bankAccountFormReducer: bankAccountForm,
    bankAccountTransactionReducer: bankAccountTransaction,
    sharedReducer: shared,
    errorReducer: errors,
  } = state.payments;

  const isLoading = paymentUtils.isLoading(state);

  return {
    isLoading,
    states: shared.states,
    user: state.user.userData,
    accounts: state.accounts.list,
    formValues: bankAccountForm.form,
    paymentError: errors.paymentError,
    bankName: bankAccountForm.bankName,
    showFormErrors: errors.showFormErrors,
    formErrors: bankAccountForm.formErrors,
    formWarnings: bankAccountForm.formWarnings,
    paymentAccounts: bankAccount.paymentAccounts,
    routingNumberError: bankAccountForm.bankNameFail,
    accountCountryCode: bankAccountForm.accountCountryCode,
    registerTokenErrorStatus: bankAccountTransaction.registerTokenErrorStatus,
  };
};

const mapDispatchToProps = {
  clearBankName,
  clearPaymentError,
  setAccountCountryCode,
  clearShowFormErrorsNotification,
  getBankName: bankFromRoutingNumber,
  updatePaymentForm: updateBankAccountForm,
  setPaymentFormErrors: setBankAccountFormErrors,
  submitPaymentForm: submitACHPaymentFormAction,
  clearPaymentForm: clearBankAccountForm,
  getPaymentAccounts: getSavedBankAccounts,
  getAccounts: fetchAssociatedOrgsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBankAccount);
