import React from 'react';
import Card from '@nutrien/uet-react/Card';
import CardContent from '@nutrien/uet-react/CardContent';
import Button from '@nutrien/uet-react/Button';

import nutrienFinancialLogo from 'assets/nutrien-financial-logos-stacked.svg';
import './NutrienFinancialCard.scss';
import { selectSelectedAccount } from 'selectors/accountSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { startCreditApplication } from 'actions/creditActions';
import { useLocation } from 'react-router-dom';
import { selectEmployee } from 'selectors/employeeSelectors';
import {
  resolveOcaUrl,
  resolveOcaTemplateId,
} from '../../../CreditApplication/resolveOcaUrl';

const NutrienFinancialCard = () => {
  const ocaUrl = resolveOcaUrl();
  const ocaTemplateId = resolveOcaTemplateId();
  const selectedAccount = useSelector(selectSelectedAccount());
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { mirroring } = useSelector(selectEmployee());
  const disabled = mirroring.isMirroring;

  return (
    <Card
      className="product-financial-banner"
      data-test="product-financial-banner"
    >
      <CardContent>
        <img
          className="product-financial-banner__img"
          data-test="product-financial-banner-image"
          src={nutrienFinancialLogo}
          alt="Nutrien Financial Logo Stacked"
          width="180"
        />
        <p data-test="info-paragraph-1">
          Nutrien Financial is here for your product needs with competitive
          finance rates for qualified growers.
        </p>
        <p data-test="info-paragraph-2">
          Contact your local Nutrien Ag Solutions Crop Consultant to learn about
          programs from leading providers.
        </p>
        <p data-test="info-paragraph-3">
          <small>*Some terms and conditions apply.</small>
        </p>
        <Button
          onClick={() => {
            // track initiate a credit application
            dispatch(startCreditApplication(selectedAccount, pathname));
          }}
          className="product-financial-banner__button"
          data-test="product-financial-banner-button"
          href={`${ocaUrl}?continue=form-new/${ocaTemplateId}`}
          rel="noopener"
          target="_blank"
          variant="outlined"
          disabled={disabled}
        >
          Apply Now
        </Button>
      </CardContent>
    </Card>
  );
};

export default NutrienFinancialCard;
