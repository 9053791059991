/**
 * This component maintains style parity with existing textfields pre-common-components
 * It is not being written as a common component because it does not adhere to
 * the common component UX spec. Any component that uses this will be assuming
 * tech debt to change it at a later date.
 */
import { InputAdornment, TextField, IconButton } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import React from 'react';

const PasswordField = ({ showPassword, handleToggleVisibility, ...props }) => (
  <TextField
    label="Password"
    required
    type={showPassword ? 'text' : 'password'}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={handleToggleVisibility}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    }}
    {...props}
  />
);

PasswordField.defaultProps = {
  showPassword: false,
  handleToggleVisibility: () => {},
};

PasswordField.propTypes = {
  showPassword: PropTypes.bool,
  handleToggleVisibility: PropTypes.func,
};

export default PasswordField;
