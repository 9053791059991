import React from 'react';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { preregisterUser, resetRegistration } from '../registrationActions';
import Register from './Register';

export default function RegisterContainer() {
  const { demoEnv } = useFlags();
  return demoEnv ? <ConnectedRegister isDemoEnv /> : <ConnectedRegister />;
}

export const mapStateToProps = (state) => ({
  user: state.user,
  registration: state.registration,
});

export const mapDispatchToProps = {
  preregisterUser,
  resetRegistration,
};

const ConnectedRegister = connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
