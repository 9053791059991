import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'shared/lib/cxhApiService';
import moment from 'moment';
import {
  setMarketOk,
  setMarketLoading,
  setMarketError,
} from './MarketActionCreators';
import Market from './Market';

export class MarketContainer extends Component {
  static propTypes = {
    fetchMarketData: PropTypes.func.isRequired,
    market: PropTypes.shape({
      commodities: PropTypes.arrayOf(
        PropTypes.shape({
          symbol: PropTypes.string,
          last: PropTypes.string,
          previous: PropTypes.string,
          change: PropTypes.string,
          high: PropTypes.string,
          low: PropTypes.string,
          open: PropTypes.string,
          close: PropTypes.string,
        })
      ),
    }),
  };

  static defaultProps = {
    market: {
      commodities: [],
    },
  };

  componentDidMount() {
    const { fetchMarketData } = this.props;
    fetchMarketData(['@C@1', '@W@1', '@S@1']);
  }

  render() {
    return <Market market={this.props.market} />;
  }
}

const mapStateToProps = (state) => ({
  market: state.market,
});

const fetchMarketDataFunc = (commodities) => (dispatch) => {
  dispatch(setMarketLoading());
  axios
    .get(`v1/markets/commodities?symbols=${commodities.join()}`)
    .then((response) => {
      dispatch(
        setMarketOk({
          commodities: response.data,
          timestamp: moment().format('MMMM Do, YYYY [at] h:mmA'),
        })
      );
    })
    .catch((err) => {
      dispatch(setMarketError(err));
    });
};

const mapDispatchToProps = {
  fetchMarketData: fetchMarketDataFunc,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketContainer);
