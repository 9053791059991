import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'nutrien-common-components-react/dist/mdc';

import './CustomCropCare.scss';

const CustomCropCare = ({ updateCustomCropCare, cart: { preferences } }) => (
  <div className="custom-crop-care-wrapper">
    <h2 className="custom-crop-care__headline">Complete Crop Care</h2>
    <p className="custom-crop-care__blurb">
      Let us know if you&#39;re interested in a complete crop care program.
    </p>
    <div className="crop-care-checkbox">
      <Checkbox
        onChange={(e) => updateCustomCropCare(e.target.checked)}
        data-test="custom-crop-care__checkbox"
        checked={preferences.customCropCare}
        id="customCropCare"
      />
      <label className="crop-care-checkbox__label" htmlFor="customCropCare">
        Yes, I&#39;d like to discuss a Complete Crop Care program with my crop
        consultant.
      </label>
    </div>
  </div>
);

CustomCropCare.propTypes = {
  updateCustomCropCare: PropTypes.func.isRequired,
  cart: PropTypes.shape({}).isRequired,
};

export default CustomCropCare;
