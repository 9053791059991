import { Parser } from 'json2csv';
import downloadFile from 'shared/utils/DownloadFile';

export const convertJsonProductsToCsv = (jsonProducts) => {
  const fields = [
    'productNumber',
    'description',
    'latestInvoiceDate',
    'sdsAndLabels',
    'shelf',
    'quantity',
    'cost',
    'uom',
  ];
  const formattedJsonProducts = jsonProducts.map((product) => {
    const formattedJsonProduct = { ...product };
    formattedJsonProduct.description = product.description.replace(',', '');
    return formattedJsonProduct;
  });
  const json2csvParser = new Parser({ fields, quote: '' });
  const csv = json2csvParser.parse(formattedJsonProducts);
  return csv;
};

export const downloadProductCsv = (productList, startDate, endDate) => {
  const productCsv = convertJsonProductsToCsv(productList);
  let csvFileName = `product-history`;
  csvFileName += `-${startDate}-${endDate}.csv`;
  downloadFile(
    productCsv,
    csvFileName,
    'text/csv',
    'sel-download-csv-generated-link'
  );
};
