import React from 'react';
import propTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'nutrien-common-components-react/dist/mdc';
import { RebrandedMaterialButton as MaterialButton } from 'shared/ui';
import Icon from 'nutrien-common-components-react/dist/mdc/Icon';

import './ScheduledPaymentDeleteModal.scss';

const ScheduledPaymentDeleteModal = (props) => (
  <div className="delete-scheduled">
    <Dialog open={props.open} onClosing={() => props.closeDialog()}>
      <DialogTitle>
        <div className="delete-scheduled__title-container">
          <div>Delete Scheduled Payment</div>
          <button
            className="delete-scheduled__close-button"
            onClick={() => props.closeDialog()}
          >
            <Icon
              icon="close"
              className="cart-item__close-icon"
              size="xs"
              onClick={() => {}}
            />
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete this
        {props.isRecurring ? ' recurring' : ' scheduled'} payment?
      </DialogContent>
      <DialogActions>
        <div className="delete-scheduled__button-container">
          <MaterialButton
            style={{
              border: '1px solid #4c9e00',
              background: 'white',
              color: '#4c9e00',
              marginRight: '10px',
            }}
            value={
              props.isRecurring
                ? 'Keep Recurring Payment'
                : 'Keep Scheduled Payment'
            }
            onClick={() => props.closeDialog()}
          />
          <MaterialButton
            value="Yes, Delete"
            onClick={() => {
              props.deleteScheduledPayment(
                props.paymentId,
                props.amount,
                props.isRecurring
              );
            }}
          />
        </div>
      </DialogActions>
    </Dialog>
  </div>
);

ScheduledPaymentDeleteModal.propTypes = {
  open: propTypes.bool.isRequired,
  closeDialog: propTypes.func.isRequired,
  deleteScheduledPayment: propTypes.func.isRequired,
  paymentId: propTypes.number,
  amount: propTypes.number,
  isRecurring: propTypes.bool,
};

ScheduledPaymentDeleteModal.defaultProps = {
  paymentId: null,
  amount: 0,
  isRecurring: false,
};

export default ScheduledPaymentDeleteModal;
