import * as actionTypes from '../../actions/constants';

const initialFilterState = {
  activeIngredient: null,
  chemical: null,
  fertilizer: null,
  manufacturer: null,
  cropType: null,
  checkedIds: {
    activeIngredient: [],
    chemical: [],
    fertilizer: [],
    manufacturer: [],
    cropType: [],
    contract: [],
  },
  mobileFilterPanelOpen: false,
};

export default (state = initialFilterState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_MOBILE_FILTER_PANEL: {
      return { ...state, mobileFilterPanelOpen: !state.mobileFilterPanelOpen };
    }
    case actionTypes.SET_PRODUCT_FILTERS: {
      const { values, filterType } = action;
      return {
        ...state,
        checkedIds: { ...state.checkedIds, [filterType]: values },
      };
    }
    case actionTypes.GET_PRODUCT_FILTERS_SUCCESS: {
      const {
        data: {
          ActiveIngredient: { data: activeIngredient },
          Manufacturer: { data: manufacturer },
          CropType: { data: cropType },
        },
      } = action;
      return { ...state, activeIngredient, manufacturer, cropType };
    }
    default:
      return state;
  }
};
