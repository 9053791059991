/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/ie11';
import 'core-js';
import 'url-polyfill';
import 'nodelist-foreach-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import resolveLaunchDarklyKey from 'shared/config/resolveLaunchDarklyKey';

import 'nutrien-style-guide/dist';

import App from './components/App';

import('./utils/loadSentry').then((loadSentry) => loadSentry.default());

WebFont.load({
  typekit: { id: 'mhd1zsj' },
  google: {
    families: ['Open Sans:300,400,600,700', 'sans-serif'],
  },
});

// attach a global listener
// move into a useLocalStorage hook pattern
// currently forcing the dashboard on logout to remove
// accountId/other params that get crossed

window.addEventListener('storage', (e) => {
  const userLogout = localStorage.getItem('nutrien:user:logout');
  // handle a simple onLogout event to refresh other tabs
  if (userLogout) {
    window.localStorage.clear();
    // eslint-disable-next-line prefer-destructuring
    window.location = window.location.href.split('?')[0];
  }

  // handle a user that opened login in tab 1 and opened login in tab 2++
  if (e.key === 'nutrien:user:id') {
    if (e.oldValue === e.newValue) return;
    // eslint-disable-next-line prefer-destructuring
    window.location = window.location.href.split('?')[0];
  }
});

(async () => {
  const user = { key: 'list-cps-devops@nutrien.com' };
  const LDProvider = await asyncWithLDProvider({
    clientSideID: resolveLaunchDarklyKey(),
    user,
  });

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root')
  );

  if (module.hot) {
    module.hot.accept('./components/App', () => {
      ReactDOM.render(
        <LDProvider>
          <App />
        </LDProvider>,
        document.getElementById('root')
      );
    });
  }
})();
