import { push } from 'connected-react-router';
import { isInFuture } from 'shared/utils/DateHelper';
import {
  SUBMIT_PAYMENT_IN_PROGRESS,
  SUBMIT_PAYMENT_SUCCESS,
  SUBMIT_PAYMENT_FAILURE,
  PAYMENT_FLOW_TYPES,
  SET_DATE_INITIATED,
  payByPrepaySavedAccount,
} from '../../PaymentConstants';
import {
  buildACHPaymentRequest,
  buildSaveTokenRequest,
  buildPayWithPrepayRequest,
  buildScheduledPaymentRequest,
} from '../../PaymentRequestFactory';
import {
  payWithPrepay,
  saveBankAccount,
  bankAccountPayment,
  scheduledBankAccountPayment,
} from '../../PaymentService';
import { getBankAccountFormState } from '../PaymentSelectors';
import { setSavedPaymentMethod } from './BankAccountFormActions';
import { setPrepayTransaction } from '../../../../actions/actionCreators';
import { handlePaymentFailure } from './ErrorActions';
import { handleAccountUpdate } from '../../../Account/Invoices/actions';
import { NEW_BANK_ACCOUNT } from '../../PageComponents/MakeAPayment/MakeAPaymentConstants';

export const setDateInitiated = (date) => ({
  type: SET_DATE_INITIATED,
  payload: date,
});

export const paymentInProgress = () => ({
  type: SUBMIT_PAYMENT_IN_PROGRESS,
});

export const paymentSuccess = (payload, amountEntered) => ({
  type: SUBMIT_PAYMENT_SUCCESS,
  payload,
  amountEntered,
});

export const paymentFailure = (payload) => ({
  type: SUBMIT_PAYMENT_FAILURE,
  payload,
});

export const submitScheduledPaymentAction =
  (flowType, amountEntered) => async (dispatch, getState) => {
    const state = getState();
    const scheduledPaymentRequest = buildScheduledPaymentRequest(
      state,
      flowType
    );

    dispatch(paymentInProgress());

    try {
      await scheduledBankAccountPayment(scheduledPaymentRequest);

      if (scheduledPaymentRequest.invoices) {
        dispatch(handleAccountUpdate(scheduledPaymentRequest.accountId));
      }

      dispatch(paymentSuccess(null, amountEntered));

      dispatch(
        push({
          pathname: '/confirm-payment',
          search: `?flow=${flowType}`,
          state: {
            invoices: scheduledPaymentRequest.invoices || undefined,
          },
        })
      );
    } catch (error) {
      dispatch(handlePaymentFailure(error));
    }
  };

export const submitBankAccountPaymentAction =
  (flowType, cpsAccountId) => async (dispatch, getState) => {
    const state = getState();
    const { scheduledPayment, amountEntered } =
      getBankAccountFormState(state).form;
    const isFuturePayment = isInFuture(scheduledPayment, 'day');

    if (isFuturePayment) {
      dispatch(submitScheduledPaymentAction(flowType, amountEntered));
    } else {
      const paymentRequest = buildACHPaymentRequest(state, flowType);
      const { amount } = paymentRequest;
      const transaction = {
        accountId: cpsAccountId,
        amountEntered: amount,
        date: new Date().toLocaleDateString(),
      };

      // This is for payments that are adding funds to prepay
      if (flowType === PAYMENT_FLOW_TYPES.PRE_PAY_PAYMENT) {
        dispatch(setPrepayTransaction(transaction));
      }

      dispatch(paymentInProgress());

      try {
        const response = await bankAccountPayment(paymentRequest);

        dispatch(paymentSuccess(response.data, amount));

        if (paymentRequest.invoices) {
          dispatch(handleAccountUpdate(paymentRequest.accountId));
        }

        dispatch(
          push({
            pathname: '/confirm-payment',
            search: `?flow=${flowType}`,
            state: {
              invoices: paymentRequest.invoices
                ? paymentRequest.invoices
                : undefined,
            },
          })
        );
      } catch (error) {
        dispatch(handlePaymentFailure(error));
      }
    }
  };

export const saveAndSubmitBankAccountPaymentAction =
  (flowType, cpsAccountId) => async (dispatch, getState) => {
    const state = getState();
    const saveTokenRequest = buildSaveTokenRequest(state);

    dispatch(paymentInProgress());

    try {
      const saveBankAccountResponse = await saveBankAccount(saveTokenRequest);

      dispatch(setSavedPaymentMethod(saveBankAccountResponse));
      dispatch(submitBankAccountPaymentAction(flowType, cpsAccountId));
    } catch (error) {
      dispatch(handlePaymentFailure(error));
    }
  };

/**
 * For making payments using prepay funds
 */
export const submitPayWithPrepayPaymentAction =
  () => async (dispatch, getState) => {
    const state = getState();
    const requestBody = buildPayWithPrepayRequest(
      state,
      getBankAccountFormState(state).form
    );

    try {
      const response = await payWithPrepay(requestBody);
      dispatch(paymentSuccess(response.data));
      dispatch(
        push({
          pathname: '/confirm-payment',
          search: `?flow=${PAYMENT_FLOW_TYPES.INVOICE_PAYMENT}`,
          state: {
            invoices: requestBody.invoices || undefined,
          },
        })
      );
    } catch (error) {
      dispatch(handlePaymentFailure(error));
    }
  };

/**
 * An action that delegates to the correct ach payment method based on the data that is handed to it and the
 * payment flow type.
 * @param flowType
 */

export const submitCurrentBankAccountPayment =
  (flowType) => (dispatch, getState) => {
    const state = getState();
    const { savedPaymentMethod, cpsAccount } =
      getBankAccountFormState(state).form;

    let result;

    if (
      savedPaymentMethod &&
      savedPaymentMethod.tokenValue === payByPrepaySavedAccount
    ) {
      result = dispatch(submitPayWithPrepayPaymentAction());
    } else if (
      savedPaymentMethod &&
      savedPaymentMethod.tokenValue === NEW_BANK_ACCOUNT
    ) {
      result = dispatch(
        saveAndSubmitBankAccountPaymentAction(flowType, cpsAccount.id)
      );
    } else {
      result = dispatch(
        submitBankAccountPaymentAction(flowType, cpsAccount.id)
      );
    }

    return result;
  };
