import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import UserPropType from 'shared/config/userPropType';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { fetchAssociatedOrgsAction } from 'shared/ui/AccountsTable/actions/AccountActions';
import { setGloballySelectedAccount as setGloballySelectedAccountAction } from 'actions/accountSelectorActions';
import { fetchUser } from 'actions/userActions';
import setDefaultAccountAction from '../../actions/defaultAccountActions/defaultAccountActions';

export function returnClass(ComposedComponent) {
  class WithFetchAccounts extends PureComponent {
    componentDidMount() {
      const { user, fetchUserAction } = this.props;
      if (isEmpty(user)) {
        fetchUserAction();
      }

      this.getAccounts();
      this.setDefaultAccount();
    }

    componentDidUpdate(prevProps) {
      const { user: prevUser } = prevProps;
      const { user } = this.props;

      if (user.id !== prevUser.id) {
        this.getAccounts();
      }

      this.setDefaultAccount();
    }

    getAccounts() {
      const { user, accounts, fetchAssociatedOrgs } = this.props;

      if (accounts && !accounts.list.length) {
        fetchAssociatedOrgs((user.userDetails || {}).associatedOrgs || []);
      }
    }

    setDefaultAccount() {
      const {
        accounts,
        selectedAccount,
        setDefaultAccount,
        setGloballySelectedAccount,
        user,
      } = this.props;

      const userDetailsExists = get(user, 'userDetails', null);

      if (
        isEmpty(selectedAccount) &&
        accounts &&
        accounts.list.length &&
        userDetailsExists
      ) {
        // If defaultAccountNumber exists in userDetails use this account
        const { userDetails } = user;
        if (userDetails.defaultAccountNumber) {
          // Loop through accounts to find correct index
          const { defaultAccountNumber } = userDetails;
          let accountIndex = null;
          accountIndex = accounts.list.findIndex(
            (account) => account.id.toString() === defaultAccountNumber
          );
          if (accountIndex !== null) {
            setDefaultAccount(accounts.list[accountIndex]);
            setGloballySelectedAccount(accounts.list[accountIndex]);
          } else {
            // eslint-disable-next-line no-console
            console.error('No default account set for this account!!!');
          }
        }
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  WithFetchAccounts.displayName = `withFetchAccounts(${
    ComposedComponent.displayName || ComposedComponent.name || 'Component'
  })`;

  WithFetchAccounts.propTypes = {
    accounts: PropTypes.shape({
      status: PropTypes.string,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          balance: PropTypes.number,
          due: PropTypes.number,
          pastDue: PropTypes.number,
          number: PropTypes.string,
        })
      ),
    }),
    selectedAccount: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      balance: PropTypes.number,
      due: PropTypes.number,
      pastDue: PropTypes.number,
      number: PropTypes.string,
    }),
    fetchAssociatedOrgs: PropTypes.func.isRequired,
    fetchUserAction: PropTypes.func.isRequired,
    setDefaultAccount: PropTypes.func.isRequired,
    setGloballySelectedAccount: PropTypes.func.isRequired,
    user: UserPropType,
  };

  WithFetchAccounts.defaultProps = {
    accounts: { list: [] },
    selectedAccount: {},
    user: null,
  };

  return WithFetchAccounts;
}

export default function (ComposedComponent) {
  const WrappedComponent = returnClass(ComposedComponent);

  const mapStateToProps = ({ accounts, user, selectedAccount }) => ({
    accounts,
    selectedAccount,
    user: user.userData,
  });

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        fetchAssociatedOrgs: fetchAssociatedOrgsAction,
        fetchUserAction: fetchUser,
        setDefaultAccount: setDefaultAccountAction,
        setGloballySelectedAccount: setGloballySelectedAccountAction,
      },
      dispatch
    );

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
}
