import get from 'lodash/get';

const addContractedProducts = (variant, contractedProducts) => {
  const productArr = get(contractedProducts, 'data.data.items', []);
  const selectedProduct = productArr.find(
    (item) => item.productId === variant.id
  );

  if (selectedProduct) {
    /* eslint-disable no-param-reassign */
    variant.contractedProduct = selectedProduct;
  }

  return {
    ...variant,
  };
};

const findContractedProducts = (products, contractedProducts) => {
  return products.map((product) => ({
    ...product,
    attriubutes: {
      ...product.attributes,
      variants: product.attributes.variants.map((variant) =>
        addContractedProducts(variant, contractedProducts)
      ),
    },
  }));
};

export default findContractedProducts;
