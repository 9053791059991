import downloadFile from 'shared/utils/DownloadFile';
import * as openItemsService from '../openItemsService';

import exportOpenItemsError from './exportOpenItemsError';
import exportOpenItemsStart from './exportOpenItemsStart';
import exportOpenItemsSuccess from './exportOpenItemsSuccess';

export default (fileType, accountId) => (dispatch) => {
  dispatch(exportOpenItemsStart(fileType));

  return openItemsService
    .getOpenItems(accountId, fileType)
    .then((data) => {
      downloadFile(data, `account-${accountId}-open-items.${fileType}`);
      dispatch(exportOpenItemsSuccess(fileType));
    })
    .catch((error) => {
      dispatch(exportOpenItemsError(error));
      return Promise.reject(error);
    });
};
