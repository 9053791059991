import { connect } from 'react-redux';
import NotificationsList from './NotificationsList';
import { notificationDeleteFailureReset } from '../actions';

const mapStateToProps = (state) => ({
  deleteNotificationFailure: state.notifications.deleteNotificationFailure,
});

const mapDispatchToProps = {
  resetDeleteNotificationFailure: notificationDeleteFailureReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
