// dependencies
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

// shared UI
import AccountsCard from '../AccountsCard';
import { CreditBanner } from '../CreditApplication/CreditBanner';

// components
import FileCabinet from './FileCabinet';
import BannerImages from './BannerImages';
import ProspectLanding from './ProspectLanding';
import TopProducts from './TopProducts';
import RecentOrders from './RecentOrders';
import EcommercePromotion from './EcommercePromotion';
import FeaturedPartner from './FeaturedPartner';

// stylesheets
import './Landing.scss';

const Landing = ({
  browser,
  isEmployeeView,
  isLinked,
  gtmClickFeaturedPartnerLearnMore,
  selectedAccount,
}) => {
  const {
    ecommerceTopBannerAd,
    landingPageFeaturedPartnersProminent,
    landingPageFilingCabinet,
    landingPageFeaturedPartners,
    productsWidgetOnTopOfLandingPage,
    recentOrdersOnLanding,
    usaSapOrders,
    usaSapProducts,
    westernRegionRestrictProducts,
  } = useFlags();

  useEffect(() => {
    sessionStorage.removeItem('timeout');
  });

  return (
    <div>
      <div
        className={classNames('landing__container', {
          'landing__container--browser-not-supported':
            !browser.isSupported && !isEmployeeView,
        })}
      >
        <BannerImages />
        {!isLinked && <ProspectLanding data-test="prospect-landing" />}
        {/* The next two feature flags toggle between the standard Ecomm Ad and a version that includes Featured Partners Ad */}
        {isLinked && (
          <>
            {landingPageFeaturedPartnersProminent && (
              <Grid
                container
                spacing={2}
                className="ecomm-promotion__container"
              >
                <Grid item xs={12} md={9}>
                  <EcommercePromotion data-test="landing-page-featured-partners-prominent" />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FeaturedPartner
                    gtmClickFeaturedPartnerLearnMore={
                      gtmClickFeaturedPartnerLearnMore
                    }
                    isProminent
                  />
                </Grid>
              </Grid>
            )}
            {ecommerceTopBannerAd ? (
              <div className="ecomm-promotion__container">
                <EcommercePromotion data-test="ecommerce-top-banner-ad" />
              </div>
            ) : (
              <div className="no-ecommerce-banner" />
            )}
            {productsWidgetOnTopOfLandingPage &&
              // if account is USA SAP or westernRegionRestrict and feature flag returns true (products restricted), then don't display top products
              ((usaSapProducts && selectedAccount.usaSapAccount) ||
              westernRegionRestrictProducts ? null : (
                <TopProducts data-test="top-products-at-top" />
              ))}
            <AccountsCard
              truncRows={4}
              renderFooter
              data-test="landing-page-accounts-card"
            />
            <CreditBanner data-test="landing-page-credit-banner" />
            {recentOrdersOnLanding &&
              (!usaSapOrders ||
                (usaSapOrders && !selectedAccount.usaSapAccount) ||
                westernRegionRestrictProducts) && (
                <RecentOrders data-test="recent-orders-on-landing" />
              )}
            {landingPageFilingCabinet && (
              <FileCabinet data-test="landing-page-filing-cabinet" />
            )}
            {landingPageFeaturedPartners && (
              <FeaturedPartner
                gtmClickFeaturedPartnerLearnMore={
                  gtmClickFeaturedPartnerLearnMore
                }
                data-test="landing-page-featured-partners"
              />
            )}
            {!productsWidgetOnTopOfLandingPage &&
              // if account is USA SAP or westernRegionRestrict and feature flag returns true (products restricted), then don't display top products
              ((usaSapProducts && selectedAccount.usaSapAccount) ||
              westernRegionRestrictProducts ? null : (
                <TopProducts data-test="top-products-at-bottom" />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

Landing.propTypes = {
  browser: PropTypes.shape({
    isSupported: PropTypes.bool,
  }).isRequired,
  isEmployeeView: PropTypes.bool.isRequired,
  isLinked: PropTypes.bool.isRequired,
  gtmClickFeaturedPartnerLearnMore: PropTypes.func,
  selectedAccount: PropTypes.shape({
    locationCode: PropTypes.string,
    usaSapAccount: PropTypes.bool,
  }).isRequired,
};

Landing.defaultProps = {
  gtmClickFeaturedPartnerLearnMore: () => {},
  gtmClickPLPEcommPromo: () => {},
};

export default Landing;
