import React from 'react';
import { Route } from 'react-router-dom';
import { AuthenticatedRoute } from 'shared/utils/AuthenticatedComponents';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import Page404 from 'components/Page404';

const FlaggedRoute = ({ authenticated, component, flagKey, ...props }) => {
  const flags = useFlags();
  const key = flagKey.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

  const renderedComponent = flags[key] ? component : Page404;
  if (authenticated) {
    return <AuthenticatedRoute {...props} component={renderedComponent} />;
  }
  return <Route {...props} component={renderedComponent} />;
};

FlaggedRoute.propTypes = {
  flagKey: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  authenticated: PropTypes.bool,
  exact: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
};

FlaggedRoute.defaultProps = {
  exact: false,
  authenticated: true,
  component: Page404,
};

/**
 * Alternative to FlaggedRoute where flagKey may be undefined or falsy
 * FeatureFlag checking is only performed when a flagKey is present and truthy
 */
const MaybeFlaggedRoute = ({ flagKey, ...props }) =>
  flagKey ? (
    <FlaggedRoute flagKey={flagKey} {...props} />
  ) : (
    <Route {...props} />
  );

MaybeFlaggedRoute.propTypes = {
  ...FlaggedRoute.propTypes,
  flagKey: PropTypes.string,
};

MaybeFlaggedRoute.defaultProps = {
  ...FlaggedRoute.defaultProps,
  flagKey: '',
};

export default FlaggedRoute;
export { FlaggedRoute, MaybeFlaggedRoute };
