import { DATE_RANGE_FILTERS } from './actions';

const getDateRangeXDaysAgo = (daysAgo) => {
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - daysAgo);
  const ret = { startDate: Number(startDate), endDate: Number(endDate) };
  return ret;
};

const getDateRangePeriod = (filterType) => {
  let ret = 0;
  switch (filterType) {
    case DATE_RANGE_FILTERS.LAST_30:
      ret = 30;
      break;
    case DATE_RANGE_FILTERS.LAST_60:
      ret = 60;
      break;
    case DATE_RANGE_FILTERS.LAST_90:
      ret = 90;
      break;
    default: {
      throw new Error(
        `unknown filterType supplied to getDateRangePeriod: ${filterType}`
      );
    }
  }
  return ret;
};

const getStartOfYearDateRange = () => {
  const startOfYear = new Date();
  const endDate = new Date();
  startOfYear.setDate(0);
  startOfYear.setFullYear(1);
  const ret = { startDate: Number(startOfYear), endDate: Number(endDate) };
  return ret;
};

const getLastYearDateRange = () => {
  const currentDate = new Date();
  const firstDayOfYear = new Date(currentDate.getFullYear() - 1, 0, 1);
  const lastDayOfYear = new Date(currentDate.getFullYear() - 1, 12, 0);
  return {
    startDate: Number(firstDayOfYear),
    endDate: Number(lastDayOfYear),
  };
};

export const getDateRange = (filterType) => {
  let ret = { startDate: undefined, endDate: undefined };
  switch (filterType) {
    case DATE_RANGE_FILTERS.LAST_30:
    case DATE_RANGE_FILTERS.LAST_60:
    case DATE_RANGE_FILTERS.LAST_90: {
      ret = getDateRangeXDaysAgo(getDateRangePeriod(filterType));
      break;
    }
    case DATE_RANGE_FILTERS.YEAR_TO_DATE: {
      ret = getStartOfYearDateRange();
      break;
    }
    case DATE_RANGE_FILTERS.LAST_YEAR: {
      ret = getLastYearDateRange();
      break;
    }
    default:
      break;
  }

  return ret;
};
