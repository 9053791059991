import React from 'react';
import { Grid, Cell, Row } from 'nutrien-common-components-react/dist/mdc/Grid';
import PropTypes from 'prop-types';
import { accountPropType, invoicePropType } from '../invoicePropTypes';
import DueDateCard from './DueDateCard';
import InvoicedDateCard from './InvoicedDateCard';
import AmountDueCard from './AmountDueCard';

import './styles.scss';

const InvoiceCards = ({ account, invoice, handlePaymentClick }) => (
  <Grid className="invoice-cards-container">
    <Row>
      <Cell phoneColumns={4} tabletColumns={8} desktopColumns={4}>
        <InvoicedDateCard invoice={invoice} />
      </Cell>
      <Cell phoneColumns={4} tabletColumns={8} desktopColumns={4}>
        <DueDateCard invoice={invoice} />
      </Cell>
      <Cell phoneColumns={4} tabletColumns={8} desktopColumns={4}>
        <AmountDueCard
          invoice={invoice}
          account={account}
          handlePaymentClick={handlePaymentClick}
        />
      </Cell>
    </Row>
  </Grid>
);

InvoiceCards.propTypes = {
  account: accountPropType.isRequired,
  invoice: invoicePropType.isRequired,
  handlePaymentClick: PropTypes.func,
};

InvoiceCards.defaultProps = {
  handlePaymentClick: () => {},
};

export default InvoiceCards;
