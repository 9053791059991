import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';

import { useAuth } from '@nutrien/fe-domain-utils';

export const LegacyAuthRoute = ({ authenticatedRedirect, ...rest }) => {
  const { isAuth0Active, isAuthenticated, isLoading } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && authenticatedRedirect && isAuthenticated) {
      history.replace(authenticatedRedirect);
    }

    if (
      !isLoading &&
      isAuth0Active &&
      history.location.pathname === rest.path
    ) {
      history.replace('/');
    }
  }, [isAuth0Active, isAuthenticated, isLoading, authenticatedRedirect]);

  return isLoading ? null : <Route {...rest} />;
};

LegacyAuthRoute.propTypes = {
  authenticatedRedirect: PropTypes.string,
};

export default LegacyAuthRoute;
