import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import './Logistics.scss';

const Logistics = (props) => {
  const { cart } = props;
  const address = get(cart, 'addresses.all.defaultAddress', {});

  return (
    <div>
      <div className="logistics-detail-wrapper">
        <div className="logistics__header">Shipping Address</div>
        <div className="logistics__address">{`${address.addressLineOne}, ${address.city}, ${address.provinceStateValue}, ${address.zipPostalCode}`}</div>
      </div>
    </div>
  );
};

Logistics.propTypes = {
  cart: PropTypes.shape({
    submittedCartData: PropTypes.array.isRequired,
    addresses: PropTypes.shape({
      all: PropTypes.shape({
        error: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export default Logistics;
