import { createToggleReducer } from 'shared/utils';

import {
  UPDATE_PASSWORD_SUBMITTING,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
} from '../actions';

export default createToggleReducer({
  trueActions: [UPDATE_PASSWORD_SUCCESS],
  falseActions: [UPDATE_PASSWORD_SUBMITTING, UPDATE_PASSWORD_FAILURE],
});
