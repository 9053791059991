import {
  SET_MARKET_OK,
  SET_MARKET_LOADING,
  SET_MARKET_ERROR,
} from './MarketActions';

const market = (state = { timestamp: '', commodities: [] }, action) => {
  switch (action.type) {
    case SET_MARKET_OK:
      return {
        timestamp: action.payload.timestamp,
        status: 'OK',
        commodities: [...action.payload.commodities],
        error: '',
      };
    case SET_MARKET_LOADING:
      return {
        status: 'LOADING',
        commodities: [],
        error: '',
        timestamp: '',
      };
    case SET_MARKET_ERROR:
      return {
        timestamp: '',
        status: 'ERROR',
        commodities: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default market;
