import resolveApiEnv, {
  DEV,
  SIT,
  PRE,
  PROD,
} from 'shared/config/resolveApiEnv';

/**
 * Returns api key for API env
 * @return {string} apiKey;
 */
const resolveAgribleUrl = () => {
  const env = resolveApiEnv();
  const envLinkMap = {
    // The first DEV key being sit.agrible.com is intentional - agrible doesn't have a dev env
    [DEV]: 'https://sit.agrible.com',
    [SIT]: 'https://sit.agrible.com',
    [PRE]: 'https://pre.agrible.com',
    [PROD]: 'https://www.agrible.com',
  };
  return envLinkMap[env];
};

export default resolveAgribleUrl;
