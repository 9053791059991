import { errorStyling } from 'shared/styles';

const styles = {
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'absolute',
    top: '70px',
    marginBottom: '15px',
  },
  barContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 10px',
  },
  titleText: {
    marginLeft: '0 !important',
  },
  strengthBar: {
    display: 'inline-block',
    height: '2px',
    width: '25px',
    margin: '0 2px',
  },
  grey: {
    background: '#BEBEBE',
    color: '#FFF',
  },
  strong: {
    background: '#659F2E',
    color: '#FFF',
  },
  weak: {
    background: '#EAA844',
    color: '#FFF',
  },
  strengthIcon: {
    borderRadius: '5px',
    padding: '1px 5px',
    fontSize: '10px',
  },
  error: {
    ...errorStyling,
  },
};

export default styles;
