import ecommApiService from '../Shop/utils/ecommApiService';

const getProductLabelsV2 = (agrianId, accountId) => {
  const route = `v2/products/agrian/${agrianId}?includes=documents`;

  return ecommApiService
    .get(route, {
      headers: {
        'account-id': accountId,
      },
    })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
};

export default getProductLabelsV2;
