import apiAdapter from 'shared/lib/cxhApiService';
import isValidAccountNumber from 'shared/utils/isValidAccountNumber';

export const getProductHistory = async (
  accountId,
  branchId,
  queryString = ''
) => {
  if (!accountId) throw new Error('Account number required');
  if (!isValidAccountNumber(accountId))
    throw new Error('Account Number is Not Valid');

  let response;
  try {
    response = await apiAdapter.get(
      `v1/accounts/${accountId}/product-history${queryString}${
        branchId ? `${queryString ? '&' : '?'}branch=${branchId}` : ''
      }`
    );
  } catch (err) {
    sessionStorage.removeItem('productHistoryActiveTimeframe');
    sessionStorage.removeItem('productHistoryActiveShelves');
    throw new Error('Selected Timeframe or Shelves are Not Valid');
  }

  return response.data;
};

export const getAccountForProductHistory = async (accountId) => {
  const response = await apiAdapter.get(`v1/accounts/${accountId}`);
  return response.data;
};
