import { LOCATION_CHANGE } from 'connected-react-router';
import {
  CLEAR_PAYMENT_STATE,
  SET_DATE_INITIATED,
  SUBMIT_PAYMENT_FAILURE,
  SUBMIT_PAYMENT_IN_PROGRESS,
  SUBMIT_PAYMENT_SUCCESS,
} from '../../PaymentConstants';

const initialState = {
  paymentStatus: null,
  dateInitiated: null,
  paymentResponse: null,
  amountEntered: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE_INITIATED:
      return { ...state, dateInitiated: action.payload };
    case SUBMIT_PAYMENT_IN_PROGRESS:
      return { ...state, paymentStatus: SUBMIT_PAYMENT_IN_PROGRESS };
    case SUBMIT_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentStatus: SUBMIT_PAYMENT_SUCCESS,
        paymentResponse: action.payload,
        amountEntered: action.amountEntered,
      };
    case SUBMIT_PAYMENT_FAILURE:
      return { ...state, paymentStatus: SUBMIT_PAYMENT_FAILURE };
    case LOCATION_CHANGE: {
      return action.payload.location.pathname.match(
        /(review-payment)|(confirm-payment)/g
      )
        ? state
        : { ...state, paymentStatus: null };
    }
    case CLEAR_PAYMENT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
