import { VIEW_INDIVIDUAL_PERMIT } from '../../../../components/Permits/actions/constants';
import { EVENT_TRACK } from '../gtmConstants';
import {
  MixpanelProperties,
  MixpanelEvents,
} from '../../MixPanel/mixPanelConstants';

export const viewSinglePermit = ({ payload }) => {
  const { permitActive, issuedBy, expirationDate } = payload;

  window.mixpanel.track(MixpanelEvents.VIEW_PERMIT, {
    [MixpanelProperties.ISSUED]: issuedBy,
    [MixpanelProperties.EXPIRATION]: expirationDate,
    [MixpanelProperties.STATUS]: permitActive ? 'Active' : 'Inactive',
  });

  return {
    event: EVENT_TRACK,
    eventid: 'GTM_VIEW_PERMIT',
    'issued by': issuedBy,
    'expiration date': expirationDate,
    status: permitActive ? 'Active' : 'Inactive',
  };
};

export const permitEventMap = {
  [VIEW_INDIVIDUAL_PERMIT]: viewSinglePermit,
};
