import apiAdapter from 'shared/lib/cxhApiService';

export const submitRequest = (payload) => {
  const config = {
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return apiAdapter
    .post(`v1/terms/sales-opt-out`, payload, config)
    .then((response) => response.data);
};
