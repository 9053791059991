import { get } from 'lodash';
import { DEFAULT_PHONE_TYPE } from '../constants';

/**
 * Updates the user's preferred phone number. Two things are changed:
 * 1. The userData.phoneNumber value is updated.
 * 2. ALL EXISTING OBJECTS IN userData.phoneNumbers ARE DELETED and replaced by a copy of the current
 *    preferred phoneNumber object with the updated phone number.
 * REASON: currently there is no need for multiple phone numbers. There exists a lot of confusion around
 * phone number preferences - so instead of supporting multiple phone numbers on the client, we are now
 * only supporting ONE. In the future this can be updated here fairly easily - but for now, we're
 * locking it down to one.
 * @param {*} preferences an object of shape { phoneNumbers: [], emails: [], privacy: [] }
 * @param {*} phoneNumber the value to use to update the preferred phone Number's "value" field
 */
export default (preferences, phoneNumber) => {
  const updatedPreferences = { ...preferences };
  const elementToUpdate = preferences.phoneNumbers[0];

  // set the user's main phone number to the preferred number.
  updatedPreferences.phoneNumber = phoneNumber;

  // there is no preferred phone number, so we must add it
  if (!elementToUpdate) {
    // for now, a user must have an email, and they can only have one,
    // so it is safe to assume it is fully populated with preferences.
    // however, in the future the logic for grabbing "all" preferences may need to change
    const phonePreferences = {
      ...get(preferences, 'emails[0].preferences', {}),
    };

    updatedPreferences.phoneNumbers = [
      {
        value: phoneNumber,
        preferred: true,
        type: DEFAULT_PHONE_TYPE,
        preferences: phonePreferences,
      },
    ];
  }
  // assume the phoneNumber is supplied only if it needs to be changed
  else {
    elementToUpdate.value = phoneNumber;
    updatedPreferences.phoneNumbers = [
      {
        ...elementToUpdate,
        preferred: true,
        value: phoneNumber,
      },
    ];
  }

  // updatedPreferences.phoneNumbers = [elementToUpdate]; // force only one phoneNumber
  // find all phone numbers and set their 'preferred' state accordingly.
  updatedPreferences.phoneNumbers = updatedPreferences.phoneNumbers.map(
    (numberObj) => ({
      ...numberObj,
      preferred: !!(numberObj.value === phoneNumber),
    })
  );

  return updatedPreferences;
};
