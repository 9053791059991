import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, withStyles } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import DownCaret from '@material-ui/icons/KeyboardArrowDown';
import FILE_TYPES from 'shared/utils/FileTypeEnum';
import styles from './styles';

class DownloadOptionsComponent extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderDropdownOptions = () => {
    const { downloadInvoices } = this.props;
    return Object.keys(FILE_TYPES).map((option) => (
      <MenuItem
        className="Download-Options-File-Type"
        onClick={() => {
          this.handleClose();
          downloadInvoices(option);
        }}
        key={option}
      >
        {option}
      </MenuItem>
    ));
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.DownloadOptionsComponent}>
        <Button
          aria-owns={anchorEl ? 'download-options-list' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.downloadButton}
        >
          <GetApp style={{ marginRight: '10px' }} />
          Download
          <DownCaret
            style={{
              marginLeft: '10px',
              transform: anchorEl ? 'rotate(180deg)' : '',
              transition: 'all 0.25s',
            }}
          />
        </Button>
        <Menu
          id="download-options-list"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="Download-Options-File-Type"
          classes={{ paper: classes.paper }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          getContentAnchorEl={null}
        >
          {this.renderDropdownOptions()}
        </Menu>
      </div>
    );
  }
}

DownloadOptionsComponent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  downloadInvoices: PropTypes.func.isRequired,
};

export default withStyles(styles)(DownloadOptionsComponent);
