import * as notificationsService from '../notificationsService';
import notificationReadStatusToggled from './notificationReadStatusToggled';
import notificationUpdate from './notificationUpdate';

export default (notification) => (dispatch) => {
  const { id, isRead } = notification;
  dispatch(notificationReadStatusToggled(notification));
  return notificationsService
    .put(id, { isRead: !isRead })
    .then((notificationResponse) =>
      dispatch(notificationUpdate(notificationResponse))
    );
};
