import { INVOICE_STATUSES } from '../../actions';

// TODO: once UET-8 is implemented in the Ui Essentials Toolkit repo, CXH-3470 should be implemented-
// this should be moved into the InvoiceStatusLabel component, where it can exist in the render method.
const privateStatusMap = {
  [INVOICE_STATUSES.PAID]: {
    icon: 'check_circle_outline',
    className: 'invoice-status__paid',
  },
  [INVOICE_STATUSES.PENDING]: {
    icon: 'hourglass_empty',
    className: 'invoice-status__pending',
  },
  [INVOICE_STATUSES.SCHEDULED]: {
    icon: 'hourglass_empty',
    className: 'invoice-status__scheduled',
  },
  [INVOICE_STATUSES.DUE_DISCOUNT_AVAILABLE]: {
    icon: 'invoice_due_discount_available',
    className: 'invoice-status__discount-available',
  },
  [INVOICE_STATUSES.DUE_REBATE_AVAILABLE]: {
    icon: 'invoice_due_rebate_available',
    className: 'invoice-status__due-rebate-available',
  },
  [INVOICE_STATUSES.DUE]: {
    icon: 'calendar_today',
    className: 'invoice-status__due',
  },
  [INVOICE_STATUSES.NOT_YET_DUE]: {
    icon: 'access_time',
    className: 'invoice-status__not-yet-due',
  },
  [INVOICE_STATUSES.PAST_DUE]: {
    icon: 'error_outline',
    className: 'invoice-status__pastDue',
  },
};

export const INVOICE_DOWNLOAD_LIMIT = 24;

export const statusMap = (key) =>
  privateStatusMap[key] || {
    icon: '',
    className: '',
  };
