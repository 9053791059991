import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import KEY_ENUM from 'shared/utils/KeyCodes';
import PageNotification from 'shared/ui/NotificationHelper';
import FormCard from 'shared/ui/MaterialFormCard';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import MaterialLoadingIcon from 'shared/ui/RebrandedMaterialLoadingIconInline';
import Helper from 'shared/ui/MaterialFormCard/FormHelper';
import UserPropType from 'shared/config/userPropType';
import closeModal from 'assets/close-modal.svg';
import arrow from 'assets/chevron-right--green.svg';
import check from 'assets/check--green.svg';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from 'nutrien-common-components-react/dist/mdc';

import checkForDuplicateAccount from './checkForDuplicateAccount';
import styles from './styles';
import helpers from '../helperContent';
import {
  ACCOUNT_NICKNAME_LENGTH,
  ACCOUNT_STATEMENT_TOKEN_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  ACCOUNT_NUMBER_MAX_LENGTH,
} from './constants';

class LinkAccountComponent extends Component {
  state = {
    helper: null,
    accountNumber: '',
    accountNickname: '',
    statementToken: '',
    errorArray: [],
  };

  setErrors = (errorArray) => {
    this.setState({
      errorArray,
    });
  };

  clearErrors = () => {
    if (this.state.error !== null) this.setErrors([]);
    if (this.props.accountLink.error) this.props.resetAccountLink();
  };

  validateData = () => {
    const errors = [];
    const { accountNumber } = this.state;
    if (
      !accountNumber ||
      !(
        accountNumber.length >= ACCOUNT_NUMBER_MIN_LENGTH &&
        accountNumber.length <= ACCOUNT_NUMBER_MAX_LENGTH
      )
    ) {
      errors.push([
        'accountNumber',
        'Please enter your 7-8 character account number.',
      ]);
    }

    if (
      this.state.accountNickname &&
      this.state.accountNickname.length > ACCOUNT_NICKNAME_LENGTH
    ) {
      errors.push([
        'accountNickname',
        'Please ensure account nickname is 255 characters or less',
      ]);
    }

    if (
      !this.state.statementToken ||
      this.state.statementToken.length !== ACCOUNT_STATEMENT_TOKEN_LENGTH
    ) {
      errors.push(['statementToken', 'Please enter a valid statement token.']);
    }
    return errors;
  };

  prepareDataForSubmit = (accountId, accountToken) => ({
    accountId: accountId.trim(),
    accountToken: accountToken.trim().toUpperCase(),
  });

  resetForm = () => {
    this.setState({
      accountNumber: '',
      accountNickname: '',
      statementToken: '',
      errorArray: [],
    });
    this.props.resetAccountLink();
  };

  toggleHelper = (e) => {
    if (!this.state.helper) {
      this.setState({
        helper: e.target.id,
      });
    } else {
      this.setState({
        helper: null,
      });
    }
  };

  updateNickname() {
    const { updateAccountNicknames } = this.props;
    const { accountNumber, accountNickname } = this.state;
    const accountNicknameMapping = {};
    accountNicknameMapping[accountNumber] = accountNickname;
    updateAccountNicknames(accountNicknameMapping);
  }

  handleKeyPress = (e) => {
    if (e.key === KEY_ENUM.ENTER) {
      this.handleSubmit(e);
    }
  };

  handleChange = (e) => {
    const { target } = e;
    const { value, name } = target;

    this.clearErrors();

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validateData();

    const accountAlreadyExists = checkForDuplicateAccount(
      this.state.accountNumber,
      this.props.user.userDetails.associatedOrgs
    );

    if (errors.length) {
      return this.setErrors(errors);
    }
    if (accountAlreadyExists) {
      return this.props.duplicateAccount(this.state.accountNumber);
    }

    const data = this.prepareDataForSubmit(
      this.state.accountNumber,
      this.state.statementToken
    );

    return this.props
      .linkAccount(data, this.props.accountLinked)
      .then((response) => {
        if (
          this.state.accountNickname !== '' &&
          (!response || response.type !== 'ACCOUNT_LINK_FAILURE')
        ) {
          this.updateNickname();
        }
        return response;
      });
  };

  generateAccountAddedCopy = () => {
    const accountAddedCopy = {};
    if (this.props.accountLink.status === 'DUPLICATE_ACCOUNT') {
      accountAddedCopy.title = 'Account Already Linked';
      accountAddedCopy.message =
        'The following account was already linked to your profile.';
    } else {
      accountAddedCopy.title = 'Account Added';
      accountAddedCopy.message = "We've linked the following account.";
    }
    return accountAddedCopy;
  };

  renderCloseButton = () => {
    const { classes } = this.props;
    return (
      <div
        role="button"
        className={classes.closeModal}
        onClick={this.props.closeModal}
        onKeyPress={this.props.closeModal}
        tabIndex="0"
      >
        <img src={closeModal} alt="close" />
      </div>
    );
  };

  renderTitle = () => {
    const { classes, accountLink } = this.props;
    if (
      accountLink.status === 'ACCOUNT_LINK_SUCCESS' ||
      accountLink.status === 'DUPLICATE_ACCOUNT'
    ) {
      const accountAddedCopy = this.generateAccountAddedCopy();
      return (
        <DialogTitle>
          <div className={classes.negativeMarginTop}>
            <h3>
              <img src={check} alt="check mark" className={classes.check} />
              {accountAddedCopy.title}
              {this.renderCloseButton()}
            </h3>
          </div>
        </DialogTitle>
      );
    }
    if (this.state.helper) {
      return null;
    }
    return (
      <DialogTitle>
        <div className={classes.negativeMarginTop}>
          <h3>
            Add an Account
            {this.renderCloseButton()}
          </h3>
        </div>
      </DialogTitle>
    );
  };

  renderErrors = () => {
    const { classes } = this.props;
    return this.state.errorArray ? (
      <div className={classes.errorContainer}>
        {this.state.errorArray.map((error) => (
          <div className={classes.error} key={error[0]}>
            {error[1]}
          </div>
        ))}
      </div>
    ) : null;
  };

  renderAccountAdded = () => {
    const { classes, accountLink, user } = this.props;
    const accountAddedCopy = this.generateAccountAddedCopy();
    if (accountLink.status === 'DUPLICATE_ACCOUNT') {
      accountAddedCopy.title = 'Account Already Linked';
      accountAddedCopy.message =
        'The following account was already linked to your profile.';
    } else {
      accountAddedCopy.title = 'Account Added';
      accountAddedCopy.message = "We've linked the following account.";
    }
    return (
      <div className={classes.accountAdded}>
        {accountLink.status === 'ACCOUNT_LINK_SUCCESS' && (
          <h4 className={classes.noSideMargin}>
            Nice work, <span data-hj-suppress="">{user.firstName}!</span>
          </h4>
        )}
        <p className={classes.noSideMargin}>{accountAddedCopy.message}</p>
        <div className={classes.infoDiv}>
          <div className={classes.infoDivTitle}>Account Name and Number</div>
          <span data-hj-suppress="">
            {accountLink.accountInfo.name} - {accountLink.accountInfo.number}
          </span>
          {this.state.accountNickname !== '' && (
            <>
              <div className={classes.infoDivTitle}>Nickname</div>
              <span data-hj-suppress="">{this.state.accountNickname}</span>
            </>
          )}
        </div>
        <div className={classes.buttonContainerSmall}>
          <MaterialButton
            onClick={
              this.props.closeModal
                ? this.props.closeModal
                : () => this.props.push('/')
            }
            value="View Account"
            type="secondary"
          />
          <MaterialButton
            value="Add Another Account"
            onClick={this.resetForm}
          />
        </div>
        <div className={classes.arrowLinkContainer}>
          <div
            onClick={
              this.props.closeModal
                ? this.props.closeModal
                : () => this.props.push('/')
            }
            onKeyPress={
              this.props.closeModal
                ? this.props.closeModal
                : () => this.props.push('/')
            }
            role="button"
            tabIndex="-1"
            className={classes.arrowLink}
          >
            Continue to the Digital Hub <img src={arrow} alt="arrow right" />
          </div>
        </div>
      </div>
    );
  };

  renderAddAnAccount = () => {
    const {
      classes,
      accountLink,
      flags: { accountNickname },
    } = this.props;
    return (
      <div>
        {accountLink.error ? (
          <PageNotification
            className={classes.notification}
            message="There was a problem linking your account. Please confirm that your account number and statement token are correct, or contact our customer support team."
            type="DANGER"
          />
        ) : null}
        <p className={classes.titleParagraph}>
          By adding your accounts to the Digital Hub, you can enjoy an array of
          features, such as making online payments, accessing statements and
          invoices, opting to receive paperless statements, and more.
        </p>
        <p className={classes.titleParagraph}>
          Please provide your account number and statement token number. Your
          token is located at the bottom right hand corner of your statement.
        </p>
        <div
          className={classes.form}
          onKeyPress={this.handleKeyPress}
          tabIndex="0"
          role="button"
        >
          <FormTextField
            name="accountNumber"
            title="Account Number"
            description="Your account number is on your statement."
            helper={this.toggleHelper}
            helperDescription="What's This?"
            value={this.state.accountNumber}
            handleChange={this.handleChange}
          />
          {accountNickname && (
            <FormTextField
              name="accountNickname"
              title="Account Nickname"
              description="Give your account a recognizable and easy-to-remember name."
              value={this.state.accountNickname}
              handleChange={this.handleChange}
            />
          )}
          <FormTextField
            name="statementToken"
            title="Statement Token"
            description="Your token is in the bottom right of your statement.  This is only for statements June 2018 or newer, call your local branch or support with questions."
            helper={this.toggleHelper}
            helperDescription="What's This?"
            value={this.state.statementToken}
            handleChange={this.handleChange}
          />
          <div className={classes.buttonContainer}>
            <MaterialButton
              onClick={
                this.props.closeModal
                  ? this.props.closeModal
                  : () => this.props.push('/')
              }
              value="Not Now"
              type="secondary"
            />
            {accountLink.status === 'LINKING_ACCOUNT' ? (
              <div className={classes.loading}>
                <MaterialLoadingIcon size={30} />
                <span>Adding account...</span>
              </div>
            ) : (
              <MaterialButton value="Add Account" onClick={this.handleSubmit} />
            )}
          </div>
          {this.renderErrors()}
        </div>
      </div>
    );
  };

  render() {
    const { classes, accountLink, modal, isOpen } = this.props;
    let cardContent;
    if (
      accountLink.status === 'ACCOUNT_LINK_SUCCESS' ||
      accountLink.status === 'DUPLICATE_ACCOUNT'
    ) {
      cardContent = this.renderAccountAdded();
    } else if (this.state.helper) {
      const currentHelper = helpers[this.state.helper];
      cardContent = (
        <Helper {...currentHelper} closeModal={this.toggleHelper} />
      );
    } else {
      cardContent = this.renderAddAnAccount();
    }

    if (modal) {
      return (
        <div className={classes.modalContainer}>
          <div className={classes.childContainer}>
            <Dialog open={isOpen} onClosed={this.props.closeModal}>
              {this.renderTitle()}
              <DialogContent>{cardContent}</DialogContent>
            </Dialog>
          </div>
        </div>
      );
    }
    return <FormCard>{cardContent}</FormCard>;
  }
}

LinkAccountComponent.propTypes = {
  modal: PropTypes.bool,
  user: UserPropType.isRequired,
  accountLink: PropTypes.shape({
    accountInfo: PropTypes.shape({
      name: PropTypes.string,
      number: PropTypes.string,
    }),
    status: PropTypes.string,
    error: PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
        message: PropTypes.string,
      }),
      status: PropTypes.number,
    }),
  }).isRequired,
  push: PropTypes.func.isRequired,
  linkAccount: PropTypes.func.isRequired,
  accountLinked: PropTypes.func,
  resetAccountLink: PropTypes.func.isRequired,
  duplicateAccount: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  updateAccountNicknames: PropTypes.func.isRequired,
  flags: PropTypes.shape({ accountNickname: PropTypes.bool }).isRequired,
};

LinkAccountComponent.defaultProps = {
  modal: null,
  closeModal: null,
  isOpen: false,
  accountLinked: () => {},
};

export default withStyles(styles)(LinkAccountComponent);
