import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { bool, shape } from 'prop-types';
import classNames from 'classnames';
import HeaderBrowserSupport from './HeaderBrowserSupport';

const HeaderBrowserSupportContainer = ({ browser, isEmployeeView }) => {
  const { browserSupportHeader } = useFlags();
  return (
    <>
      {browserSupportHeader && (
        <div
          data-test="header-container"
          className={classNames({
            'header__browser-support': browser.isSupported,
          })}
        >
          {!browser.isSupported && !isEmployeeView && <HeaderBrowserSupport />}
        </div>
      )}
    </>
  );
};

HeaderBrowserSupportContainer.propTypes = {
  browser: shape({
    isSupported: bool,
  }).isRequired,
  isEmployeeView: bool.isRequired,
};

export default HeaderBrowserSupportContainer;
