export const getSharedPaymentsState = (state) => state.payments.sharedReducer;
export const getBankAccountState = (state) => state.payments.bankAccountReducer;
export const getBankAccountFormState = (state) =>
  state.payments.bankAccountFormReducer;
export const getBankAccountTransactionState = (state) =>
  state.payments.bankAccountTransactionReducer;
export const getAccountsState = (state) => state.accounts;

export const getOwnerFromState = (reduxState) => {
  const { form, accountCountryCode } = getBankAccountFormState(reduxState);
  const { list: accounts } = getAccountsState(reduxState);
  const {
    bankAccountType,
    firstName,
    lastName,
    corporateName,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    phone,
    country,
    cpsAccount,
  } = form;

  return {
    bankAccountType,
    firstName,
    lastName,
    companyName: corporateName,
    addressLine1,
    addressLine2,
    city,
    state: state.code,
    zip,
    phone,
    country: country.code,
    accountId: cpsAccount ? cpsAccount.id : accounts[0].id,
    accountCountryCode: cpsAccount
      ? cpsAccount.locationCode
      : accountCountryCode,
  };
};

export const getScheduledPayments = (state) =>
  (state.paymentsTab.GetScheduledPayments.data || []).filter(
    (schedulePayment) => !schedulePayment.isRecurring
  );
