import flatten from 'lodash/flatten';
import combineEvents from '@redux-beacon/combine-events';

/**
 * Analogous to combineReducers... allows each eventHandler module
 * to define their own eventMaps without worrying about key conflicts
 *
 * Produces an eventMap according to redux-beacon spec
 * https://rangle.gitbook.io/redux-beacon/index-1/events-map
 *
 * @param eventMaps
 */
export const combineEventMaps = (...eventMaps) => {
  // turn the array of eventMap objects into a flat array of key/val pairs
  // from [ {a: b, y: z}, {a: c} ] to [ [a, b], [y, z], [a, c] ]
  const flatKeyValuePairs = flatten(
    eventMaps.map((map) => Object.entries(map))
  );

  // Combine event handlers that share the same keys
  // { a: [b, c], y: [z] }
  const combinedEventMap = flatKeyValuePairs.reduce(
    (combined, [reduxAction, handler]) => ({
      ...combined,
      [reduxAction]: [...(combined[reduxAction] || []), handler],
    }),
    {}
  );

  // If a redux action (the object key) has multiple eventHandlers,
  // then combine them with the redux-beacon combineEvents function.
  // Otherwise, just map the eventHandler 1:1 with the redux action
  return Object.entries(combinedEventMap)
    .map(([reduxAction, handlers]) => {
      const combinedHandler =
        handlers.length === 1 ? handlers[0] : combineEvents(...handlers);

      return { [reduxAction]: combinedHandler };
    })
    .reduce((a, b) => ({ ...a, ...b }), {});
};

export default combineEventMaps;
