export default {
  paymentMethodAliasContainer: {
    marginTop: '15px',
  },
  paymentMethodAliasLabel: {
    width: '400px',
    height: '20px',
    fontSize: '14px',
    textAlign: 'left',
    color: '#464646',
    display: 'block',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    marginBottom: '5px',
  },
  paymentMethodAliasLabelSmall: {
    marginLeft: '5px',
    color: '#828282',
    fontSize: '12px',
  },
  paymentMethodAliasInput: {
    display: 'block',
    width: '400px',
    height: '40px',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    border: '1px solid #cccccc',
    marginBottom: '20px',
  },
  '@media (max-width: 480px)': {
    paymentMethodAliasInput: {
      width: '100%',
    },
  },
};
