import { connect } from 'react-redux';
import AccountsCard from './AccountsCard';

const mapStateToProps = (state) => ({
  accounts: state.accounts,
  selectedAccount: state.selectedAccount,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsCard);
