export default {
  dateRangeContainer: {
    marginRight: '16px',
    // force all the filter components to have the same explicit height
    height: '35px',
  },
  dateSelectionText: {
    display: 'inline',
  },
  dialogCancelButton: {
    width: '125px',
  },
  dialogConfirmButton: {
    width: '125px',
  },
  dropDownButton: {
    border: '1px solid lightgray',
    minHeight: '32px',
    height: '32px',
    padding: '4px 12px',
    justifyContent: 'space-between',
    minWidth: '125px',
  },
  paper: {
    width: '16%',
  },
  '@media(max-width: 825px)': {
    dateRangeContainer: {
      width: '100%',
      margin: '0 0 10px 0',
    },
    dropDownButton: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
  },
};
