// dependencies
import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

import './SupportInfo.css';

const SupportNumber = () => (
  <div className="support-info__section">
    <PhoneIcon className="support-info__section__icon" />
    <div className="support-info__section__text">
      <p>
        <a href="tel:+1-877-241-4463">1-877-241-4463</a>
      </p>
      <p>8:00am - 8:00pm Eastern</p>
      <p>Monday - Friday</p>
    </div>
  </div>
);

const SupportEmail = () => (
  <div className="support-info__section">
    <MailIcon className="support-info__section__icon" />
    <div className="support-info__section__text">
      <p>
        <a href="mailto:digital.support@nutrien.com">
          digital.support@nutrien.com
        </a>
      </p>
      <p>
        Please provide your name and phone number with a brief description of
        your support request.
      </p>
    </div>
  </div>
);

const SendFeedback = () => (
  <div>
    <h4>Do You Have Feedback?</h4>
    <p>
      We'd like your feedback about this service and how we can make
      improvements to better serve you in the future.
    </p>
    <div className="support-info__section">
      <MailIcon className="support-info__section__icon" />
      <div className="support-info__section__text">
        <p>
          <a href="mailto:digital.feedback@nutrien.com">
            digital.feedback@nutrien.com
          </a>
        </p>
      </div>
    </div>
  </div>
);
export { SupportNumber, SupportEmail, SendFeedback };
