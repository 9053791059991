// dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// shared UI
import PasswordChecker from 'shared/ui/PasswordChecker';
import HelperContent from 'shared/ui/PasswordChecker/HelperContent';
import {
  FullPageBackground,
  bgRegistration,
} from 'shared/ui/FullPageBackground';
import FormCard from 'shared/ui/MaterialFormCard';
import Form from 'shared/ui/MaterialFormCard/Form';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import Helper from 'shared/ui/MaterialFormCard/FormHelper';

// components
import {
  validatePassword,
  validateEmail,
  checkBreachedPassword,
} from 'shared/utils/formValidation';

import styles from './styles';

class PasswordReset extends Component {
  state = {
    password: '',
    passwordConfirm: '',
    showPassword: false,
    showPasswordConfirm: false,
    formSubmitted: false,
    error: null,
    isPasswordSafe: false,
    modal: false,
  };

  setError = (message) => {
    this.setState({
      error: message,
    });
  };

  toggleShowPassword = (field) => {
    if (field === 'password') {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    } else if (field === 'passwordConfirm') {
      this.setState((prevState) => ({
        showPasswordConfirm: !prevState.showPasswordConfirm,
      }));
    }
  };

  clearErrors = () => {
    if (this.props.passwordReset.status) this.props.resetPasswordResetStatus();
    if (this.state.error !== null) this.setError(null);
  };

  handleChange = async (e) => {
    const { target } = e;
    const { value, name } = target;

    if (name === 'password') {
      if (validatePassword(value)) {
        const isPasswordSafe = await checkBreachedPassword(this.state.password);
        this.toggleIsPasswordSafe(isPasswordSafe);
      } else {
        this.toggleIsPasswordSafe(false);
      }
    }

    this.clearErrors();

    this.setState({
      [name]: value,
    });
  };

  toggleIsPasswordSafe = (isPasswordSafe) => {
    this.setState({ isPasswordSafe });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      formSubmitted: true,
    });
    const email = window.atob(this.props.match.params.email);
    if (!validateEmail(email)) {
      this.setError(
        'Something went wrong! Please request a new password reset link.'
      );
    }

    if (this.state.password === '' || this.state.passwordConfirm === '') {
      this.setError('Please enter and confirm a new password.');
    }

    if (this.state.password !== this.state.passwordConfirm) {
      this.setError('Passwords must match.');
    }

    const isPasswordSafe = await checkBreachedPassword(this.state.password);
    this.toggleIsPasswordSafe(isPasswordSafe);

    if (
      (this.state.password !== '' && !validatePassword(this.state.password)) ||
      !this.state.isPasswordSafe
    ) {
      this.setError('Please check that all password requirements are met.');
    }

    if (
      !validatePassword(this.state.password) ||
      this.state.error ||
      !this.state.isPasswordSafe
    ) {
      return;
    }

    const data = {
      username: email.trim(),
      password: this.state.password.trim(),
      verificationCode: this.props.match.params.verificationCode,
    };

    this.props.resetPassword(data);
  };

  render() {
    const { classes, passwordReset } = this.props;

    if (this.state.modal) {
      return (
        <div>
          <FullPageBackground
            image={bgRegistration}
            className="full-page-background--farmers-pointing"
          />
          <FormCard>
            <Helper
              title="Examples of Common Passwords"
              description="To ensure your data is safe, please refrain from choosing a commonly used password such as, but not limited to, the examples below"
              closeModal={this.toggleModal}
              renderContent={() => <HelperContent />}
            />
          </FormCard>
        </div>
      );
    }

    let submissionError;
    if (passwordReset.status) {
      submissionError =
        passwordReset.status === 400
          ? 'Your password reset link is no longer valid. Please request a new link.'
          : 'There was an error resetting your password.';
    }

    return (
      <div>
        <FullPageBackground
          image={bgRegistration}
          className="full-page-background--farmers-pointing"
        />
        <FormCard>
          <h3>Password Reset</h3>
          <hr />
          <h4>Please reset your password</h4>
          <p className={classes.p}>
            Your security is important to us. Please take a moment to reset your
            password.
          </p>
          <Form onSubmit={this.handleSubmit}>
            <PasswordChecker
              password={this.state.password}
              passwordConfirm={this.state.passwordConfirm}
              showPassword={this.state.showPassword}
              showPasswordConfirm={this.state.showPasswordConfirm}
              handleChange={this.handleChange}
              setError={this.setError}
              toggleShowPassword={this.toggleShowPassword}
              formSubmitted={this.state.formSubmitted}
              isPasswordSafe={this.state.isPasswordSafe}
              toggleModal={this.toggleModal}
            />
            <div className={classes.error} id="formError">
              {this.state.error || submissionError}
            </div>
            <MaterialButton
              id="reset-password-button"
              value="Reset Password"
              type="submit"
            />{' '}
          </Form>
        </FormCard>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  passwordReset: PropTypes.shape({
    status: PropTypes.number,
  }),
  resetPassword: PropTypes.func.isRequired,
  resetPasswordResetStatus: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      verificationCode: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

PasswordReset.defaultProps = {
  passwordReset: {},
};

export default withStyles(styles)(PasswordReset);
