export const getUrlParameters = (param) => {
  let urlParameters = {};
  try {
    urlParameters = JSON.parse(
      `{"${decodeURI(param)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  } catch (err) {
    return urlParameters;
  }
  return urlParameters;
};

export const sanitizeFilterParams = (
  params,
  validFilterObjects,
  filterType
) => {
  const isValidParam =
    typeof params === 'string' && /^[0-9%C\-A-Za-z,~ ]*$/.test(params);
  const isCategoricalFilter =
    filterType === 'chemicals' ||
    filterType === 'fertilizers' ||
    filterType === 'activeIngredients' ||
    filterType === 'manufacturers';

  if (isValidParam) {
    // Separate values
    let ids = params.split('%2C');
    // Remove empty strings
    ids = ids.filter((v) => v !== '');
    // Remove duplicates
    ids = ids.filter((v, i) => ids.indexOf(v) === i);
    // Remove ids that don't exist in list
    if (validFilterObjects) {
      const validIds = validFilterObjects.map((v) => {
        if (isCategoricalFilter) {
          return v.value.replace(',', '~');
        }
        return v.id.replace(',', '~');
      });
      ids = ids.filter((id) => validIds.indexOf(id) !== -1);
    }
    return ids;
  }
  return false;
};

export const paramValueToArray = (paramValue) => {
  const inputType = typeof paramValue;
  switch (inputType) {
    case 'number':
      return [paramValue.toString()];
    case 'string':
      return paramValue.split(',');
    case 'object':
      if (Array.isArray(paramValue)) {
        return paramValue;
      }
      return [];
    default:
      return [];
  }
};

export const convertComma = (param) => param.replace(',', '~');
