export default {
  paymentSubHeader: {
    margin: '20px 0 20px 0',
    padding: '9px 0 9px 15px',
    border: 'solid 1px #0271ea',
    borderRadius: '4px',
    backgroundColor: 'rgba(2, 113, 234, 0.1)',
  },
  accountInfoEncryptionText: {
    display: 'inline',
    position: 'relative',
    top: '3px',
    fontSize: '16px',
    fontWeight: '600',
    color: '#0a0a0a',
    marginLeft: '15px',
  },
  accountInfoHelpImage: {
    width: '22px',
    height: '34px',
  },
  '@media (max-width: 480px)': {
    paymentSubHeader: {
      paddingRight: '5px',
    },
  },
};
