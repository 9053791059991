import { v1 as uuid } from 'uuid';

const correlationIdInterceptor = (config) => ({
  ...config,
  headers: {
    ...config.headers,
    'correlation-id': uuid(),
  },
});

export default correlationIdInterceptor;
