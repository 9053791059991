import {
  SUBMIT_PAYMENT_SUCCESS,
  SET_PAYMENT_FLOW_TYPE,
} from 'components/Payments/PaymentConstants';
import get from 'lodash/get';
import { accountLocationCodeToCurrency } from '../utils';
import {
  mixpanelPaymentFlowStart,
  mixpanelPaymentFlowComplete,
} from '../../MixPanel/eventHandlers/paymentFlow';
import { EVENT_TRACK } from '../gtmConstants';

export const paymentStartAnalytics = (action, { selectedAccount }) => {
  mixpanelPaymentFlowStart(selectedAccount);

  return {
    event: EVENT_TRACK,
    eventId: 'CXH_Payments_PaymentStart',
    category: 'Payments',
    action: 'Payments Start',
    branchId: get(selectedAccount, 'branchId', ''),
    branchName: get(selectedAccount, 'branchName', ''),
    accountName: get(selectedAccount, 'nickname', ''),
    accountId: get(selectedAccount, 'id', ''),
  };
};

export const submitPaymentSuccess = (action, _, currentState) => {
  mixpanelPaymentFlowComplete(currentState);

  return {
    event: 'CXH_SubmitPaymentSuccess',
    cxh: {
      payment: {
        account: get(
          currentState,
          'payments.bankAccountFormReducer.form.cpsAccount.number',
          ''
        ),
        confirmation: get(action, 'payload.cxhTransactionId', ''),
        amount: get(
          currentState,
          'payments.bankAccountFormReducer.form.amountEntered',
          ''
        ),
        currentBalance: get(
          currentState,
          'payments.bankAccountFormReducer.form.cpsAccount.balance',
          ''
        ),
        currentBalanceDue: get(
          currentState,
          'payments.bankAccountFormReducer.form.cpsAccount.currentDue',
          ''
        ),
        notes: get(
          currentState,
          'payments.bankAccountFormReducer.form.notes',
          ''
        ),
        amountTypeSelected: get(
          currentState,
          'payments.bankAccountFormReducer.form.amountTypeSelected',
          ''
        ),
        currencyCode: accountLocationCodeToCurrency(
          get(currentState, 'selectedAccount.locationCode', '')
        ),
      },
    },
    paymentAmount: get(
      currentState,
      'payments.bankAccountTransactionReducer.amountEntered'
    ),
    paymentType: get(
      currentState,
      'payments.bankAccountReducer.form.bankAccountType'
    ),
    paymentMethod: get(
      currentState,
      'payments.bankAccountReducer.form.achAccountType'
    ),
    recurringPayment: get(
      currentState,
      'payments.bankAccountFormReducer.form.isRecurring'
    ),
    recurringFrequency: get(
      currentState,
      'payments.bankAccountFormReducer.form.recurringPaymentFrequency?.code'
    ),
    recurringEndDate: get(
      currentState,
      'payments.bankAccountFormReducer.form.recurringEndDate'
    ),
    scheduledDate:
      get(
        currentState,
        'payments.bankAccountFormReducer.form.scheduledPayment'
      ) || new Date().toISOString(),
  };
};

export const paymentEventsMap = {
  [SUBMIT_PAYMENT_SUCCESS]: submitPaymentSuccess,
  [SET_PAYMENT_FLOW_TYPE]: paymentStartAnalytics,
};
