import { createTimestampReducer } from 'shared/utils';

import {
  PERMITS_FETCHING,
  PERMITS_FETCH_FAILURE,
  PERMITS_FETCH_SUCCESS,
} from '../actions';

export default createTimestampReducer({
  setActionTypes: [PERMITS_FETCH_SUCCESS],
  resetActionTypes: [PERMITS_FETCHING, PERMITS_FETCH_FAILURE],
});
