import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import numeral from 'numeral';
import { verifyUsePackageUom } from 'utils/verifyUsePackageUom';
import { getUnitOfMeasureLong } from 'components/Shop/utils/unitMeasuresLong';
import { volumeOrQuantity } from 'shared/utils/VolumeOrQuantity/volumeOrQuantity';
import withLocationCode from 'shared/utils/CountryCode/withLocationCode';
import adjustToFixed from '../../utils/AdjustToFixed/adjustToFixed';
import './styles.scss';

export class ReceiptItem extends Component {
  constructor(props) {
    super(props);
    let totalPrice;
    let displayPrice;
    const { cartItem } = props;
    let pricePer = get(cartItem, 'variant.net_price', 0);
    const { total_volume: totalVolume } = cartItem;

    if (pricePer) {
      pricePer = adjustToFixed(pricePer);
      // adjust totalPrice on the front end to
      // match quantity saved in the redux store
      totalPrice = pricePer * totalVolume;
      totalPrice = adjustToFixed(totalPrice);
      displayPrice = numeral(totalPrice).format('$0,0.00');
    }
    this.state = { totalPrice, displayPrice };
  }

  render() {
    const { selectedAccount, cartItem, isCanada } = this.props;
    const packSize = get(cartItem, 'variant.digital_variant_name', '');
    const uom = cartItem.variant.unit_measure;
    const useLabel = verifyUsePackageUom(selectedAccount);
    const productName = cartItem.product.attributes.name;
    const { total_volume: totalVolume } = cartItem;
    let pricePer = get(cartItem, 'variant.net_price', '');

    if (pricePer && parseFloat(`${pricePer}`, 10) > 0) {
      pricePer = numeral(`${pricePer}`).format('$0,0.00');
    } else {
      pricePer = '';
    }

    return (
      <tr className="receipt-item__wrapper">
        <td className="receipt-item__table-data--first">
          <span
            className="receipt-item__header--mobile"
            data-test="product-header"
          >
            Product Name
          </span>
          <span className="receipt-item__product-text">{productName}</span>
        </td>
        <td className="receipt-item__table-data">
          <span className="receipt-item__header--mobile">Pack Size</span>
          <span className="receipt-item__purchase-text">{packSize}</span>
        </td>
        <td className="receipt-item__table-data">
          <span
            className="receipt-item__header--mobile"
            data-test="receipt-item-volume-or-qty-label"
          >
            {volumeOrQuantity(isCanada)}
          </span>
          <span className="jsQuantity">
            {totalVolume}{' '}
            {useLabel ? getUnitOfMeasureLong(uom, totalVolume) : uom}
          </span>
        </td>
        <td className="receipt-item__table-data">
          <span className="receipt-item__header--mobile">Unit Price</span>
          <div data-test="price-per-unit">
            {pricePer
              ? `${pricePer} / ${useLabel ? getUnitOfMeasureLong(uom) : uom}`
              : '--'}
          </div>
        </td>
        <td className="receipt-item__tabla-data--last">
          <span className="receipt-item__header--mobile">Total Price</span>
          <div className="receipt-item__numeric-data">
            {this.state.totalPrice && this.state.totalPrice > 0 ? (
              `${this.state.displayPrice}`
            ) : (
              <span className="receipt-item__price-unavailable">
                Quote requested
              </span>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

ReceiptItem.propTypes = {
  cartItem: PropTypes.shape({
    product: PropTypes.shape({
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    total_volume: PropTypes.number,
    updatingCart: PropTypes.bool,
    variant: PropTypes.shape({
      unit_size: PropTypes.number,
      unit_qty: PropTypes.number,
      unit_measure: PropTypes.string,
      price: PropTypes.number,
      net_price: PropTypes.number,
      is_bulk: PropTypes.bool,
      digital_variant_name: PropTypes.string,
    }),
  }).isRequired,
  selectedAccount: PropTypes.shape({}).isRequired,
  isCanada: PropTypes.bool.isRequired,
};

export default withLocationCode(ReceiptItem);
