import resolveApiEnv, { DEV, SIT } from './resolveApiEnv';

// TODO: add PRE and PROD urls once those are available
const urls = {
  [DEV]: 'https://hub.dev.nutrienagsolutions.com/quickpay',
  [SIT]: 'https://hub.sit.nutrienagsolutions.com/quickpay',
};

const resolveQuickPayUrl = () => {
  const env = resolveApiEnv();
  return urls[env];
};

export default resolveQuickPayUrl;
