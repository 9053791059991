import downloadInvoicesAsCSV from './downloadInvoicesAsCSV';
import downloadInvoicesAsPDF from './downloadInvoicesAsPDF';
import downloadInvoiceAsOFX from './downloadInvoiceAsOFX';

export default (fileType, accountId, invoiceIds) => {
  switch (fileType) {
    case 'csv':
      return downloadInvoicesAsCSV(accountId, invoiceIds);
    case 'pdf':
      return downloadInvoicesAsPDF(accountId, invoiceIds);
    case 'ofx':
      return downloadInvoiceAsOFX(accountId, invoiceIds);
    default:
      throw new Error('Unsupported File Type');
  }
};
