const ordersArrayTransformer = (orderData) => {
  if (orderData) {
    const included = orderData.data.map((order) => ({
      id: order.orderNumber,
      attributes: order,
    }));
    return {
      included,
      meta: orderData.meta,
    };
  }
  return {};
};

export default ordersArrayTransformer;
