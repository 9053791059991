import React from 'react';
import PropTypes from 'prop-types';
import { Notification } from 'nutrien-common-components-react/dist/mdc';
import ReactRouterPropTypes from 'react-router-prop-types';
import FormCard from 'shared/ui/MaterialFormCard';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import FormCardToggleView from 'shared/ui/MaterialFormCard/FormCardToggleView';
import flow from 'lodash/flow';
import get from 'lodash/get';
import LoginForm from '../LoginForm';
import '../Login.scss';

/* eslint-disable react/destructuring-assignment */
const LoginV1 = (props) => {
  const registrationError = get(props, 'registration.error.response');
  const existentEmailError =
    registrationError && registrationError.status === 409;
  const getAndParse = flow([get, JSON.parse]);
  const registrationData = getAndParse(
    props,
    'registration.error.config.data',
    '{}'
  );

  const existentEmailNotification = existentEmailError ? (
    <Notification type="danger">{registrationError.data.message}</Notification>
  ) : null;

  const loginContent =
    props.fetchingUser === 'FETCHING_USER' ? (
      <MaterialLoadingIcon />
    ) : (
      <div>
        <LoginForm
          title="Sign In"
          location={props.location}
          initialValues={{ email: registrationData.email }}
        />
        <FormCardToggleView
          classes={{ link: 'login__sign-up-link' }}
          title="Not a Customer Portal user?"
          link="/register"
          linkDescription="Sign Up"
        />
      </div>
    );
  // eslint-disable-next-line consistent-return
  const checkSessionStatus = () => {
    if (sessionStorage.timeout === 'true') {
      return (
        <Notification type="warning">
          Your session has timed out due to inactivity.
        </Notification>
      );
    }
  };

  return (
    <FormCard>
      <h3>Customer Login</h3>
      <hr />
      {checkSessionStatus()}
      {existentEmailNotification}
      {loginContent}
    </FormCard>
  );
};

LoginV1.propTypes = {
  fetchingUser: PropTypes.string.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default LoginV1;
