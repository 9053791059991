import * as actionTypes from '../../actions/constants';

export const initialState = {
  orders: {},
  contracts: {},
  ordersLoading: true,
  contractsLoading: true,
  contractDetailsLoading: true,
  contractsError: null,
  ordersError: null,
  selectedDocumentsAccount: {},
  contractDetails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING_CUSTOMER_ORDERS: {
      const { loading } = action;
      return {
        ...state,
        ordersLoading: loading,
        ordersError: null,
        status: 'LOADING',
      };
    }
    case actionTypes.GET_CUSTOMER_ORDERS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        orders: data,
        ordersLoading: false,
        ordersError: null,
        status: 'OK',
      };
    }
    case actionTypes.GET_CUSTOMER_ORDERS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        ordersError: error,
        ordersLoading: false,
        orders: {},
        status: 'ERROR',
      };
    }
    case actionTypes.LOADING_OPEN_CONTRACTS: {
      const { loading } = action;
      return {
        ...state,
        contractsLoading: loading,
        contractsError: null,
        status: 'LOADING',
      };
    }
    case actionTypes.GET_OPEN_CONTRACTS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        contracts: data,
        contractsLoading: false,
        contractsError: null,
        status: 'OK',
      };
    }
    case actionTypes.GET_OPEN_CONTRACTS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        contractsError: error,
        contractsLoading: false,
        contracts: {},
        status: 'ERROR',
      };
    }
    case actionTypes.SET_SELECTED_DOCUMENTS_ACCOUNT: {
      const { account } = action;
      return {
        ...state,
        selectedDocumentsAccount: account,
      };
    }
    case actionTypes.GET_CONTRACT_DETAILS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        contractDetails: data,
        contractDetailsLoading: false,
        status: 'OK',
        contractDetailsStatusCode: null,
      };
    }
    case actionTypes.GET_CONTRACT_DETAILS_FAILURE: {
      const { error, statusCode } = action;
      return {
        ...state,
        contractDetailsError: error,
        contractDetailsLoading: false,
        status: 'ERROR',
        contractDetailsStatusCode: statusCode,
      };
    }
    case actionTypes.LOADING_CONTRACT_DETAILS: {
      return {
        ...state,
        contractDetailsLoading: true,
        status: 'LOADING',
        contractDetailsStatusCode: null,
      };
    }
    default:
      return state;
  }
};
