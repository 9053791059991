import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import BannerImage from './BannerImage';
import './BannerImages.scss';

const BannerImages = ({ browser, isEmployeeView, topNotification }) => {
  const { browserSupportHeader } = useFlags();

  return (
    <div>
      {browserSupportHeader ? (
        <BannerImage
          browserSupportFeatureIsOn
          browser={browser}
          isEmployeeView={isEmployeeView}
          topNotification={topNotification}
        />
      ) : (
        <BannerImage
          browserSupportFeatureIsOn={false}
          browser={browser}
          isEmployeeView={isEmployeeView}
        />
      )}
    </div>
  );
};

BannerImages.propTypes = {
  topNotification: PropTypes.bool,
  userDetails: PropTypes.shape({
    topCropNames: PropTypes.arrayOf(PropTypes.string),
  }),
  browser: PropTypes.shape({
    isSupported: PropTypes.bool,
  }).isRequired,
  isEmployeeView: PropTypes.bool.isRequired,
};

BannerImages.defaultProps = {
  topNotification: false,
  userDetails: {},
};

export default BannerImages;
