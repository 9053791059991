import { GET_RECURRING_PAYMENTS, STATUS } from '../Actions/constants';

const initialState = { status: STATUS.INITIAL, data: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RECURRING_PAYMENTS:
      return action.payload;
    default:
      return state;
  }
};
