import apiAdapter from 'shared/lib/cxhApiService';

/**
 * Gets open items data in a given format for a given account. API supports only CSV format
 * @param accountId - The account id the open items belong to.
 * @param type - The type we want the final data to be in (supported: CSV)
 * @returns {*} - A promise containing a byte array of the open items data
 */
export const getOpenItems = (accountId, type) =>
  apiAdapter
    .get(`v1/accounts/${accountId}/openItems/data?type=${type}`)
    .then((response) => response.data);
