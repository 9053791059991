import apiAdapter from 'shared/lib/cxhApiService';

export const getScheduledPayments = (accountIds, isRecurring = false) =>
  apiAdapter.get(
    `/v1/scheduledPayments${
      isRecurring ? '/recurring' : ''
    }?accountIds=${accountIds}`
  );

export const deleteScheduledPayment = (id, isRecurring = false) =>
  apiAdapter.delete(
    `/v1/scheduledPayments${
      isRecurring ? '/recurring?recurringPaymentId=' : '?scheduledPaymentId='
    }${id}`
  );
