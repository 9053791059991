import React, { useState } from 'react';
import propTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import {
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Table,
} from 'nutrien-common-components-react/dist/mdc/Table';
import customFormat from 'shared/utils/DateHelper/customFormat';
import moment from 'moment';
import ScheduledPaymentRow from './ScheduledPaymentRow';
import ScheduledPaymentDeleteModal from '../ScheduledPaymentDeleteModal/ScheduledPaymentDeleteModal';

const ScheduledPaymentsTable = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [amount, setAmount] = useState();
  const today = new Date();
  const yesterday = today.setDate(today.getDate() - 1);
  const yesterdayText = customFormat(yesterday, 'MM/DD/YYYY');

  const handlePaymentDelete = (paymentDetails) => {
    props.handleSameDayError(false, false);
    // if scheduledDate is on today's date
    if (
      paymentDetails.scheduledDate &&
      moment(paymentDetails.scheduledDate).isSame(moment(), 'date')
    ) {
      props.handleSameDayError(false, true);
      return;
    }
    setDialogOpen(true);
    setPaymentId(paymentDetails.scheduledPaymentId);
    setAmount(paymentDetails.amount);
  };

  return (
    <div className="payments-tab__table-container">
      <ScheduledPaymentDeleteModal
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        deleteScheduledPayment={props.deleteScheduledPayment}
        paymentId={paymentId}
        amount={amount}
        isRecurring={false}
      />
      {!props.scheduledPayments || props.scheduledPayments.length === 0 ? (
        <div className="payments-tab__no-payments">
          You have no Payments scheduled as of {yesterdayText}
        </div>
      ) : (
        <Table className="payments-table">
          <TableHead>
            <TableRow className="payments-table__row">
              <TableCell className="payments-table__header nutrien-table__cell__fixed">
                Scheduled Date
              </TableCell>
              <TableCell className="payments-table__header nutrien-table__cell__fixed">
                Invoice ID
              </TableCell>
              <TableCell className="payments-table__header nutrien-table__cell__fixed">
                Payment Method
              </TableCell>
              <TableCell className="payments-table__header" />
              <TableCell className="payments-table__header payments-table__amount-column">
                Amount
              </TableCell>
              <TableCell className="payments-table__header payments-table__final-col" />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(
              props.scheduledPayments,
              (element) => element.scheduledDate
            ).map((paymentDetails) => (
              <ScheduledPaymentRow
                details={paymentDetails}
                key={paymentDetails.invoiceId}
                deleteScheduledPayment={props.deleteScheduledPayment}
                handlePaymentDelete={handlePaymentDelete}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

ScheduledPaymentsTable.propTypes = {
  scheduledPayments: propTypes.arrayOf(propTypes.shape({})).isRequired,
  deleteScheduledPayment: propTypes.func.isRequired,
  handleSameDayError: propTypes.func.isRequired,
};

export default ScheduledPaymentsTable;
