import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

// Material UI
import { withStyles } from '@material-ui/core';
import Link from '@nutrien/uet-react/Link';
import RadioGroup from '@nutrien/uet-react/RadioGroup';
import Grid from '@nutrien/uet-react/Grid';
import Notification from '@nutrien/uet-react/Notification';
import Typography from '@nutrien/uet-react/Typography';

// Constants
import {
  DEFAULT_PAYMENT_METHODS,
  GET_PAYMENT_METHOD_IN_PROGRESS,
  PAYMENT_FLOW_TYPES,
} from '../../../../PaymentConstants';
import * as PaymentPropTypes from '../../../../PaymentPropTypes';

// Dependent Components
import PaymentProcessingOverlay from '../../../../OtherComponents/PaymentProcessingOverlay/PaymentProcessingOverlay';
import StoredBankAccountDialog from './StoredBankAccountDialog';
import SavedPaymentMethod from './SavedPaymentMethod';
import SavedPaymentMethodError from './SavedPaymentMethodError';

// Actions
import { getSavedBankAccounts } from '../../../../Redux/actions/BankAccountActions';

// Component Specific styles
import styles from './styles';

class SavedPaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    const { cpsAccount, getPaymentMethods, orgIds } = this.props;

    // Get saved bank from user
    getPaymentMethods(orgIds, cpsAccount.locationCode);
    this.updateDefaultPaymentMethod(this.defaultPaymentMethod);
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, 'paymentMethods[0].tokenValue') !==
      get(this.props, 'paymentMethods[0].tokenValue')
    ) {
      this.updateDefaultPaymentMethod(this.defaultPaymentMethod);
    }
  }

  onChange = (event) => {
    const { onChange, paymentMethods } = this.props;
    const { name, value } = event.target;
    const newlySelectedPaymentMethod = paymentMethods
      .concat([
        DEFAULT_PAYMENT_METHODS.PREPAY,
        DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT,
      ])
      .find((paymentMethod) => paymentMethod.tokenValue === value);
    // Lifted state on change prop.
    onChange({
      target: {
        name,
        value: newlySelectedPaymentMethod,
      },
    });
  };

  get defaultPaymentMethod() {
    const { paymentMethods = [] } = this.props;
    // Set default return value to add new bank
    return paymentMethods.length
      ? paymentMethods[0].tokenValue
      : DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT.tokenValue;
  }

  updateDefaultPaymentMethod = (tokenValue) => {
    this.onChange({
      target: {
        name: 'savedPaymentMethod',
        value: tokenValue,
      },
    });
  };

  openPaymentAccountsDialog = (event) => {
    event.preventDefault();
    this.setState({ dialogOpen: true });
  };

  renderNotification = () => {
    const { paymentError } = this.props;

    if (paymentError) {
      // Payment error [ duplicate payment, max limits, adding duplicate saved payment method ]
      return (
        <SavedPaymentMethodError
          error={paymentError}
          openDialog={this.openPaymentAccountsDialog}
        />
      );
    }
    // Default informational notifcation
    return (
      <Notification varient="info">
        All payment transactions utilize encryption to keep your payment and
        account data safe.
      </Notification>
    );
  };

  render() {
    const {
      classes,
      paymentMethods,
      flowType,
      headerTitle,
      hideManageLink,
      loading,
    } = this.props;
    // For bank account dialog only props...
    const {
      clearPaymentError,
      deletePaymentMethod,
      deletePaymentMethodStatus,
      user,
    } = this.props;

    if (loading === GET_PAYMENT_METHOD_IN_PROGRESS) {
      return <PaymentProcessingOverlay processingText="Loading" />;
    }

    return (
      <div
        className={classes.savedPaymentMethodsContainer}
        name="savedPaymentMethodsContainer"
      >
        {/* Saved Payment Method Edit dialog */}
        <StoredBankAccountDialog
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          paymentAccounts={paymentMethods}
          deletePaymentMethod={deletePaymentMethod}
          deletePaymentMethodStatus={deletePaymentMethodStatus}
          user={user}
          clearPaymentError={clearPaymentError}
        />
        <div className={classes.paymentMethodHeader}>
          <Typography
            variant="h2"
            compononent="h2"
            className={classes.paymentMethodH2}
          >
            {headerTitle || 'Payment Method'}
          </Typography>
          {!hideManageLink ? (
            <Typography className={classes.paymentMethodLink}>
              <Link href="/profile/billing">Manage Payment Methods {'>'}</Link>
            </Typography>
          ) : null}
        </div>
        {/* Component's Notification bar */}
        <div className={classes.paymentMehtodNotification}>
          {this.renderNotification()}
        </div>
        <RadioGroup
          name="paymentMethodGroup"
          onChange={this.onChange}
          defaultValue={this.defaultPaymentMethod}
          data-test="saved-payment-methods-radio-group"
        >
          <Grid container>
            {/* List of bank accounts */}
            {paymentMethods.map((paymentMethod) => (
              <SavedPaymentMethod
                key={paymentMethod.tokenValue}
                paymentMethod={paymentMethod}
              />
            ))}

            {/* Default Payment Methods */}
            {/* If the payment is an invoice payment, add the prepay option */}
            {flowType === PAYMENT_FLOW_TYPES.INVOICE_PAYMENT ? (
              <SavedPaymentMethod
                key={DEFAULT_PAYMENT_METHODS.PREPAY.tokenValue}
                paymentMethod={DEFAULT_PAYMENT_METHODS.PREPAY}
              />
            ) : (
              ''
            )}

            {/* New Bank Account */}
            <SavedPaymentMethod
              styles={classes.addNewBank}
              key={DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT.tokenValue}
              paymentMethod={DEFAULT_PAYMENT_METHODS.NEW_BANK_ACCOUNT}
            />
          </Grid>
        </RadioGroup>
      </div>
    );
  }
}

SavedPaymentMethods.propTypes = {
  classes: PropTypes.shape({
    modalLink: PropTypes.string,
    paymentMethodHeader: PropTypes.string,
    paymentMethodH2: PropTypes.string,
    paymentMethodLink: PropTypes.string,
    savedPaymentMethodsContainer: PropTypes.string,
    tokenLimitMessage: PropTypes.string,
    warningIcon: PropTypes.string,
    paymentMehtodNotification: PropTypes.string,
    addNewBank: PropTypes.string,
  }).isRequired,
  clearPaymentError: PropTypes.func.isRequired,
  cpsAccount: PropTypes.shape({
    locationCode: PropTypes.string,
  }).isRequired,
  deletePaymentMethodStatus: PropTypes.string,
  deletePaymentMethod: PropTypes.func.isRequired,
  flowType: PropTypes.string,
  headerTitle: PropTypes.string,
  hideManageLink: PropTypes.bool,
  getPaymentMethods: PropTypes.func.isRequired,
  loading: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  orgIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      tokenValue: PropTypes.string,
      accountNumberLastThree: PropTypes.string,
      bankAccountType: PropTypes.string,
      bankName: PropTypes.string,
      alias: PropTypes.string,
      createdDate: PropTypes.string,
      tokenOwner: PaymentPropTypes.tokenOwner, // eslint-disable-line react/no-typos
    })
  ).isRequired,
  user: PropTypes.shape({
    userDetails: PropTypes.object.isRequired,
  }).isRequired,
  paymentError: PropTypes.shape({
    statusCode: PropTypes.number,
    name: PropTypes.string,
    message: PropTypes.string,
    errorType: PropTypes.string,
  }),
};

SavedPaymentMethods.defaultProps = {
  paymentError: null,
  loading: GET_PAYMENT_METHOD_IN_PROGRESS,
  deletePaymentMethodStatus: null,
  headerTitle: null,
  hideManageLink: false,
  flowType: PAYMENT_FLOW_TYPES.MAKE_A_PAYMENT,
};

const mapStateToProps = ({ user, payments: { bankAccountReducer } }) => {
  return {
    flowType: bankAccountReducer.flowType,
    loading:
      bankAccountReducer.getPaymentMethodStatus ||
      GET_PAYMENT_METHOD_IN_PROGRESS,
    orgIds: user.userData.userDetails.associatedOrgs.map((org) => org.id),
    paymentMethods: bankAccountReducer.paymentAccounts,
    user: user.userData,
  };
};

export default connect(mapStateToProps, {
  getPaymentMethods: getSavedBankAccounts,
})(withStyles(styles)(SavedPaymentMethods));
