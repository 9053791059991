// dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import KEY_ENUM from 'shared/utils/KeyCodes';
import FormCard from 'shared/ui/MaterialFormCard';
import FormTextField from 'shared/ui/MaterialFormCard/Form/FormTextField';
import MaterialButton from 'shared/ui/RebrandedMaterialButton/MaterialButton';
import MaterialLoadingIcon from 'shared/ui/RebrandedMaterialLoadingIconInline';
import PageNotification from 'shared/ui/NotificationHelper';
import closeModal from 'assets/close-modal.svg';

import {
  validateEmail,
  domainIsPermitted,
  formValidationConstants,
} from 'shared/utils/formValidation';
import {
  DELEGATING_ACCESS,
  DELEGATE_ACCESS_SUCCESS,
  DELEGATE_ACCESS_FAILURE,
} from 'components/Profile/rbacRedux/rbacConstants';
import {
  RbacPropType,
  AddUserFormPropType,
} from 'components/Profile/rbacRedux/rbacPropTypes';

import styles from './styles';

class AddUser extends Component {
  state = {
    isOpen: false,
  };

  resetForm = () => {
    this.props.resetForm();
    this.props.resetAccessDelegation();
  };

  toggleModal = () => {
    if (this.state.isOpen) {
      this.props.resetForm();
      this.props.resetAccessDelegation();
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleChange = (e) => {
    this.props.clearErrors();
    this.props.resetAccessDelegation();

    const { onChange } = this.props;
    const { value, name } = e.target;

    const updatedEvent = {
      [name]: value.trim(),
    };

    onChange(updatedEvent);
  };

  validateInput = (e) => {
    this.props.clearErrors();
    const { value, name } = e.target;

    if (name === 'email' && value) {
      if (!validateEmail(value)) {
        this.props.setErrors({
          [name]: formValidationConstants.ENTER_VALID_EMAIL_ADDRESS,
        });
        return false;
      }
      if (!domainIsPermitted(value)) {
        this.props.setErrors({
          [name]: formValidationConstants.ONLY_USE_EMPLOYEE_PORTAL,
        });
        return false;
      }
      return true;
    }
    return true;
  };

  handleKeyPress = (e) => {
    if (e.key === KEY_ENUM.ENTER) {
      this.handleSubmit(e);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { form } = this.props;
    const { email } = form.form;

    if (Object.values(form.formErrors).length > 0) {
      return false;
    }
    if (email === '') {
      const error = {
        email: formValidationConstants.ENTER_VALID_EMAIL_ADDRESS,
      };
      return this.props.setErrors(error);
    }

    if (this.validateInput(e)) {
      return this.props.delegateAccess(email.toLowerCase());
    }

    return false;
  };

  render() {
    const { classes, form } = this.props;
    const { formErrors } = form;
    const { email } = form.form;

    const addUser = (
      <div onKeyPress={this.handleKeyPress} tabIndex="0" role="button">
        <div className={classes.title}>
          <div>Add User</div>
          <div
            role="button"
            onClick={this.toggleModal}
            onKeyPress={this.toggleModal}
            className={classes.closeModal}
            tabIndex="0"
          >
            <img src={closeModal} alt="close" />
          </div>
        </div>
        {this.props.rbac.status === DELEGATE_ACCESS_FAILURE ? (
          <PageNotification
            className={classes.notification}
            message={
              <div>
                You've discovered an upcoming feature! We are unable to send
                this role invitation at this time. For questions or feedback,
                please contact us at{' '}
                <a href="tel:+1-877-241-4463">+1-877-241-4463</a> or{' '}
                <a href="mailto:digital.support@nutrien.com">
                  digital.support@nutrien.com
                </a>
                .
              </div>
            }
            type="WARNING"
          />
        ) : null}
        <br />
        <FormTextField
          name="email"
          title="Invite by Email Address"
          value={email}
          handleChange={(event) => this.handleChange(event)}
          onBlur={(event) => this.validateInput(event)}
          error={formErrors.email}
        />
        <div className={classes.label}>Admin</div>
        <div className={classes.p}>
          Full access to accounting and agronomic data including payment and
          crop planning capabilities. Admin cannot add users.
        </div>
        <div className={classes.spacer} />
        <div className={classes.buttonContainer}>
          <MaterialButton
            onClick={this.toggleModal}
            value="Cancel"
            type="secondary"
          />
          {this.props.rbac.status === DELEGATING_ACCESS ? (
            <div className={classes.loading}>
              <MaterialLoadingIcon size={25} />
              <span>Inviting user...</span>
            </div>
          ) : (
            <MaterialButton value="Send Invite" onClick={this.handleSubmit} />
          )}
        </div>
      </div>
    );

    const invitationSent = (
      <div onKeyPress={this.handleKeyPress} tabIndex="0" role="button">
        <div className={classes.title}>
          <div>Your invitation has been sent!</div>
          <div
            role="button"
            onClick={this.toggleModal}
            onKeyPress={this.toggleModal}
            className={classes.closeModal}
            tabIndex="0"
          >
            <img src={closeModal} alt="close" />
          </div>
        </div>
        <div className={classes.p}>
          An email has been sent to the authorized user with the option to
          accept the Admistrator role. The invite will remain active for 30
          days. We will keep you informed at each step.
        </div>
        <div className={classes.spacer} />
        <div className={classes.buttonContainer}>
          <MaterialButton
            onClick={this.toggleModal}
            value="Return to Profile"
            type="secondary"
          />
          {this.props.rbac.status === DELEGATING_ACCESS ? (
            <div className={classes.loading}>
              <MaterialLoadingIcon size={30} />
              <span>Adding user...</span>
            </div>
          ) : (
            <MaterialButton value="Invite Another" onClick={this.resetForm} />
          )}
        </div>
      </div>
    );

    return (
      <div>
        <MaterialButton
          value="Add User"
          className={classes.addUserButton}
          onClick={this.toggleModal}
        />
        {this.state.isOpen && (
          <div className={classes.modalContainer}>
            <FormCard>
              {this.props.rbac.status === DELEGATE_ACCESS_SUCCESS
                ? invitationSent
                : addUser}
            </FormCard>
          </div>
        )}
      </div>
    );
  }
}

AddUser.propTypes = {
  rbac: RbacPropType.isRequired,
  form: AddUserFormPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  delegateAccess: PropTypes.func.isRequired,
  resetAccessDelegation: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(AddUser);
