import React from 'react';
import Box from '@nutrien/uet-react/Box';
import Typography from '@nutrien/uet-react/Typography';

export default () => (
  <>
    <Box mt={4} mb={2}>
      <Typography variant="h4" gutterBottom>
        Your Rights
      </Typography>
    </Box>
    <Box my={2}>
      <Typography variant="body1" gutterBottom>
        You have the right to control whether we sell your personal information.
        “Sell,” “selling,” “sale,” or “sold,” means selling, renting, releasing,
        disclosing, disseminating, making available, transferring, or otherwise
        communicating orally, in writing,or by electronic or other means, your
        personal information by Nutrien to another business or a third party for
        monetary or other valuable consideration.
      </Typography>
    </Box>
    <Box my={2}>
      <Typography variant="body1" gutterBottom>
        You may restrict our sale of your personal information to such third
        parties by checking the box and providing the information below. Nothing
        in this form prohibits the sharing of information necessary for us to
        follow the law, as permitted by law, or to give you the best service on
        your accounts with us. This includes sending you information about some
        other products or services.
      </Typography>
    </Box>
    <Box my={2}>
      <Typography variant="body1" gutterBottom>
        You may make your privacy choice(s) at any time. Your choice(s) marked
        here will remain in effect unless you state otherwise. However, if you
        do not complete this form, we may sell some of your personal information
        to affiliated companies, service providers, or other third parties.
      </Typography>
    </Box>
    <Box my={2}>
      <Typography variant="h4" gutterBottom>
        Restrict the Sale of Your Personal Information
      </Typography>
    </Box>
    <Typography variant="body1" gutterBottom>
      Unless you say “NO”, we may sell your personal information to third
      parties, including other companies within the Nutrien family of companies,
      service providers, and nonaffiliated third parties.
    </Typography>
  </>
);
