import get from 'lodash/get';

const getLinkedAccounts = (state) => {
  const associatedOrgs = get(
    state,
    'user.userData.userDetails.associatedOrgs',
    []
  );

  return associatedOrgs.reduce(
    (cumulativeArr, associatedOrg) =>
      cumulativeArr.concat(associatedOrg.accounts),
    []
  );
};

export default getLinkedAccounts;
