import {
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CustomerInfo from './CustomerInfo';
import * as EmployeePropTypes from '../../EmployeePropTypes';
import Initial from './InitialComponent';
import MirrorUserLink from './MirrorUserLink';

const styles = {
  gutters: {
    padding: '5px 10px',
  },
};
const CustomerListItem = ({ customer, classes }) => (
  <ListItem classes={{ gutters: classes.gutters }} divider>
    <ListItemAvatar>
      <Initial firstName={customer.firstName} lastName={customer.lastName} />
    </ListItemAvatar>
    <CustomerInfo customer={customer} />
    <ListItemSecondaryAction>
      <MirrorUserLink customer={customer} />
    </ListItemSecondaryAction>
  </ListItem>
);

CustomerListItem.propTypes = {
  customer: EmployeePropTypes.customer.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

// Export
export default withStyles(styles)(CustomerListItem);
