// TODO move to global space
import ecommApiService from '../../../Shop/utils/ecommApiService';
import { ordersTransformer } from '../../../../utils/transformers/ordersTransformer';
import { verifySAPAccount } from '../../../../utils/verifySAP';

import loadingOrder from './loadingOrder';
import postOrderFailure from './postOrderFailure';
import postOrderSuccess from './postOrderSuccess';
import setPickup from './setPickup';

import { REMOVE_CART_ITEMS, SET_SELECTED_ACCOUNT } from '../actionTypes';

export default (orderData, cartData, selectedAccount, user) => (dispatch) => {
  dispatch(loadingOrder(true));
  const { email, emails } = user;
  let emailAddress = email || null;

  if (!emailAddress && emails && emails.length > 0) {
    const [secondaryEmail] = emails;
    emailAddress = secondaryEmail;
  }

  const isSAP = verifySAPAccount(selectedAccount.number);

  const applicationMemoPrefix =
    'Nutrien Ag Solutions Representative: please note this customer is interested in application services. Additional application details provided: ';

  const body = {
    accountNumber: selectedAccount.number,
    salesRepId: null,
    branchId: orderData.selectedBranchId,
    toAddress: '',
    isCustomApplied: false,
    deliveryApplicationDate: orderData.date,
    poNumber: orderData.poNumber,
    orderMemo: orderData.orderMemo,
    customerPickup: orderData.isPickup,
    applicationMemo: applicationMemoPrefix + orderData.applicationMemo,
    shipToAddressId: orderData.defaultAddress.id.toString(),
    shippingAddress: orderData.defaultAddress,
    orderSource: window.ReactNativeWebView ? 'DIG-CXHM' : 'DIG-CXH',
  };
  // links field is not needed
  delete body.shippingAddress.links;

  if (isSAP) {
    delete body.shippingAddress;
  }

  return ecommApiService
    .post('/v2/orders', body, {
      headers: {
        'account-id': orderData.selectedAccountId,
      },
    })
    .then((res) => {
      dispatch(postOrderSuccess(ordersTransformer(res.data), cartData));
      dispatch(setPickup(false));
      // these should be their own actions
      dispatch({
        type: REMOVE_CART_ITEMS,
      });
      dispatch({
        type: SET_SELECTED_ACCOUNT,
        payload: {},
      });
    })
    .catch((error) => {
      dispatch(postOrderFailure(error));
      return error;
    });
};
