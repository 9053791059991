import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const MaterialButton = ({
  type,
  lefticon,
  value,
  righticon,
  ...otherProps
}) => (
  <Button
    {...otherProps}
    type="submit"
    variant={type === 'secondary' ? 'outlined' : 'contained'}
  >
    {lefticon}
    {value}
    {righticon}
  </Button>
);

MaterialButton.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  lefticon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  righticon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

MaterialButton.defaultProps = {
  type: '',
  className: '',
  lefticon: '',
  righticon: '',
};

export default withStyles(styles)(MaterialButton);
