import { makeStyles } from '@material-ui/core/styles';

// TODO: Lots of hard coded colors these definitely need to be removed in favor of theme palette.

const originalStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
    },
  },
  alert: {
    color: '#d0021b',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxContainer: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  deleteBtn: {
    order: 4,
  },
  descriptionText: {
    whiteSpace: 'pre-wrap',
  },
  markReadIcon: {
    color: '#256BA2',
  },
  linkButton: {
    margin: theme.spacing(0, 4),
    display: 'inline-block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
  },
  listItem: {
    marginBottom: theme.spacing(1),
    display: 'block',
    borderLeft: '0.4em solid #fff',
  },
  notificationTypeIcon: {
    marginRight: theme.spacing(2),
    order: 0,
  },
  readBtn: {
    '& svg': {
      fontSize: '0.7em',
    },
  },
  summary: {
    margin: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 1),
      alignItems: 'center',
    },
  },
  summaryContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 2,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  summaryContent: {
    flexGrow: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
  summaryDescription: {
    color: theme.palette.grey.A900,
    fontWeight: 400,
  },
  summaryDate: {
    fontSize: 16,
    fontWeight: 400,
    color: '#828282',
    marginRight: theme.spacing(2),
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginRight: theme.spacing(1),
    },
  },
  // TODO: Use @nutrien/uet-react/Card because it has a "status" prop for the left border coloring
  unread: {
    borderLeft: '0.5em solid #4c9e00',
  },
}));

const menuStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
  },
  alert: {
    color: theme.palette.error.main,
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  deleteBtn: {
    order: 4,
  },
  descriptionText: {
    whiteSpace: 'pre-wrap',
  },
  markReadIcon: {
    color: theme.palette.info.main,
  },
  linkButton: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    marginLeft: 0,
    marginRight: theme.spacing(2),
  },
  listItem: {
    borderLeft: '0.4em solid #fff',
    display: 'block',
    width: '100%',
  },
  notificationTypeIcon: {
    marginRight: theme.spacing(2),
    order: 0,
  },
  readBtn: {
    '& svg': {
      fontSize: '0.7em',
    },
  },
  summary: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2),
  },
  summaryContentContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
  },
  summaryContent: {
    flexGrow: 2,
    fontSize: 14,
    paddingRight: theme.spacing(1),
    width: '100%',
  },
  summaryDescription: {
    color: theme.palette.grey.A900,
    fontWeight: 400,
  },
  summaryDate: {
    color: '#828282',
    fontSize: 14,
    fontWeight: 400,
    marginRight: theme.spacing(1),
  },
  unread: {
    borderLeft: `0.4em solid ${theme.palette.brand.green}`,
  },
}));

// TODO: Lots of duplicate styles, these should be reused.
export const useStyles = (isMenu) => {
  return isMenu ? menuStyles() : originalStyles();
};
