import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import UsersTable from 'components/Organization/Users/UsersTable';
import AuthorizedComponent from 'shared/utils/AuthorizedComponents';

import AddUser from './AddUser';
import styles from '../../styles';

const UsersTab = (props) => {
  const { classes } = props;

  return (
    <div className="profile-users-tab">
      <AuthorizedComponent roles={['owner']}>
        <div className={classes.sectionContainer}>
          <div className={classes.section}>
            <div className={`${classes.sectionTitle} utSharedAccess`}>
              Shared Access
            </div>
            If you share roles and responsibilities within your business, you
            can share secure access to your account information by adding users.
            Added users will have the same access and visibility to information
            and functionality as you do, however they will not be able to add
            additional users.
          </div>
          <AddUser />
        </div>
        <hr />
      </AuthorizedComponent>
      <UsersTable />
    </div>
  );
};

UsersTab.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(UsersTab);
