import { PropTypes } from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import React from 'react';

import ApiErrorMessage from 'shared/ui/ApiErrorMessage';
import MaterialLoadingIcon from 'shared/ui/MaterialLoadingIcon';
import userPropType from 'shared/config/userPropType';

import UsersTableRow from './UsersTableRow';

const TableHeaderCell = withStyles({ root: { fontWeight: 'bold' } })(TableCell);

const UsersTable = ({ users, isLoading, error }) => {
  if (isLoading) return <MaterialLoadingIcon />;
  if (error) return <ApiErrorMessage entityName="Users" />;
  return (
    <Table className="users-table">
      <TableHead className="users-table-head">
        <TableRow>
          <TableHeaderCell>User</TableHeaderCell>
          <TableHeaderCell>E-mail</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody className="users-table-body">
        {users.map((user) => (
          <UsersTableRow key={user.eid} user={user} />
        ))}
      </TableBody>
    </Table>
  );
};

UsersTable.defaultProps = { isLoading: false, error: null };

UsersTable.propTypes = {
  isLoading: PropTypes.bool,
  users: PropTypes.arrayOf(userPropType).isRequired,
  error: PropTypes.instanceOf(Error),
};

export default UsersTable;
