import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MINIMUM_BULK_QUANTITY from '../../../../shared/constants/minimumBulkQuantity';
import { isValidBulkInput } from '../../utils/validateInput';

import './styles.scss';

const ProductTotalVolumeTextbox = ({
  variant,
  value,
  modifiesVariant,
  modifiesCartItem,
  onKeyPress,
  onBlur,
  onChange,
  setVariantVolume,
  setTotalVolume,
  updateCartData,
}) => {
  const [volume, setVolume] = useState(value || `${MINIMUM_BULK_QUANTITY}`);

  const setQuantity = (quantity) => {
    setVolume(quantity);
    if (modifiesVariant && setVariantVolume) {
      setVariantVolume(quantity.toString());
    } else if (modifiesCartItem && setTotalVolume) {
      setTotalVolume(quantity.toString());
    }
  };

  const handleBlur = (e) => {
    let {
      target: { value: newValue },
    } = e;
    const inputValue = newValue.toString();

    if (
      !inputValue.trim().length ||
      inputValue === '0' ||
      newValue < MINIMUM_BULK_QUANTITY
    ) {
      newValue = MINIMUM_BULK_QUANTITY;
    }

    setQuantity(newValue.toString());

    if (
      modifiesCartItem &&
      isValidBulkInput(variant.digital_variant_name, newValue) &&
      updateCartData
    ) {
      updateCartData(newValue.toString());
    }

    if (onBlur) onBlur(newValue);
  };

  const handleChange = (e) => {
    const {
      target: { value: newValue },
    } = e;
    const valid = isValidBulkInput(variant.digital_variant_name, newValue);

    setQuantity(newValue);

    if (modifiesCartItem && valid && updateCartData) {
      updateCartData(newValue);
    }

    if (onChange) onChange(newValue, valid);
  };

  useEffect(() => {
    if (value !== volume) {
      setQuantity(volume);
    }
  }, []);

  return (
    <input
      data-test="total-volume-input"
      className={classNames(
        {
          'total-volume__input--error': !isValidBulkInput(
            variant.digital_variant_name,
            volume
          ),
        },
        'total-volume__input'
      )}
      label="Volume"
      value={volume}
      min="1"
      step="1"
      onKeyPress={onKeyPress}
      onChange={handleChange}
      onBlur={handleBlur}
      validate={null}
      type="text"
      pattern="\d*"
      maxLength="15"
    />
  );
};

ProductTotalVolumeTextbox.propTypes = {
  variant: PropTypes.shape({
    digital_variant_name: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  modifiesVariant: PropTypes.bool,
  modifiesCartItem: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func.isRequired,
  setVariantVolume: PropTypes.func,
  setTotalVolume: PropTypes.func,
  updateCartData: PropTypes.func,
};

ProductTotalVolumeTextbox.defaultProps = {
  value: null,
  modifiesVariant: false,
  modifiesCartItem: false,
  onChange: null,
  onBlur: null,
  setVariantVolume: null,
  setTotalVolume: null,
  updateCartData: null,
};

export default ProductTotalVolumeTextbox;
